app.service("contentSearch", [
    "skyword",
    "setup",
    "call",
    function(skyword, setup, call) {
        return {
            init: function() {
                var removeContentSample,
                    toggleIncludeSelectedImage,
                    loadJson = function() {
                        var imageJson = $("#image-json"),
                            imageList,
                            imgArr = [],
                            json;

                        if (0 < imageJson.length) {
                            imageList = '{"images": ' + imageJson.val() + "}";
                        } else {
                            imageList = '{"images": []}';
                        }
                        json = $.parseJSON(imageList);
                        imgArr = json.images;

                        return imgArr;
                    },
                    loadImageToggle = function() {
                        var imagePicker = $(".imagePicker"),
                            imageJson = $("#image-json"),
                            imgArr = loadJson(),
                            imageToggle = imagePicker.find(".imageToggle"),
                            imageInfo = imagePicker.find(".imageInfo"),
                            imageDisplay = imagePicker.find(".imageDisplay"),
                            image = imageDisplay.find(">img"),
                            json = null,
                            sanDir = "${SANDIR}",
                            sanStartIdx = sanDir.length,
                            j,
                            span = imageToggle.find(">span.n"),
                            selected = false,
                            imgUrl,
                            contentSampleImage = $("#contentSampleImageId"),
                            selectedImageId = -1,
                            sampleImageLi = imageInfo.closest(
                                "div.sampleImage"
                            );

                        //console.log(imgArr);
                        if (0 < imgArr.length) {
                            var hideImages = false;
                            if (1 == imgArr.length) {
                                // determine if it's a pdf
                                if (imgArr[0].filename.indexOf(".pdf") != -1) {
                                    // it's a pdf! hiiide!
                                    hideImages = true;
                                }
                            }

                            if ("object" === typeof imgArr[0]) {
                                for (j = 0; j < imgArr.length; j = j + 1) {
                                    if (true === imgArr[j].selected) {
                                        selectedImageId = j;
                                        break;
                                    }
                                }
                                if (-1 === selectedImageId) {
                                    imgUrl = imgArr[0].imageDTO.finalName.substring(
                                        imgArr[0].sanStartIdx
                                    );
                                    //console.log("49 imgUrl " + imgUrl);
                                    image.attr("src", imgUrl);
                                    $("#selectedImageUrl").val(
                                        imgArr[0].finalName
                                    );
                                    contentSampleImage.val(imgArr[0].id);
                                } else {
                                    imageDisplay
                                        .find("span.n")
                                        .text(selectedImageId + 1);
                                    imageDisplay
                                        .find("span.n")
                                        .attr("data-value", selectedImageId);
                                    //console.log("56 imgArr[selectedImageId].imageDTO.finalName "+ imgArr[selectedImageId].imageDTO.finalName.substring(imgArr[selectedImageId].sanStartIdx));
                                    image.attr(
                                        "src",
                                        imgArr[
                                            selectedImageId
                                        ].imageDTO.finalName.substring(
                                            imgArr[selectedImageId].sanStartIdx
                                        )
                                    );
                                }
                            } else {
                                image.attr(
                                    "src",
                                    imgArr[0].finalName.substring(
                                        imgArr[0].sanDir
                                    )
                                );
                                //console.log("61 imgArr[0].finalName " + imgArr[0].finalName.substring(imgArr[0].sanStartIdx));
                                $("#selectedImageUrl").val(imgArr[0].finalName);
                            }

                            if (1 < imgArr.length) {
                                imageDisplay.find("span.t").text(imgArr.length);
                            } else {
                                imageToggle.hide();
                            }
                            if (!hideImages) {
                                sampleImageLi.show();
                                sampleImageLi.removeClass("hidden");
                                $(".sampleImageHeader").removeClass("hidden");
                            }
                        } else {
                            sampleImageLi.hide();
                        }
                    },
                    contentSampleEnter = function(evt) {
                        evt = evt || window.event;
                        var target = evt.target || evt.srcElement;

                        if (evt.keyCode === 13) {
                            $(
                                "#contentSampleExternalSearch, #btn-content-sample-search"
                            ).click();
                            return false;
                        }
                    },
                    loadContentSample = function(
                        loc,
                        articleUrl,
                        referringPage
                    ) {
                        window.location.href =
                            loc +
                            ".action?&articleUrl=" +
                            articleUrl +
                            referringPage;
                    },
                    editContentSample = function() {
                        var a = $(this),
                            csId = parseInt(a.attr("data-contentSampleId"), 10);

                        if (
                            undefined === csId ||
                            null === csId ||
                            isNaN(csId)
                        ) {
                            return false;
                        } else {
                            window.location.href =
                                a.attr("href") + "?contentSampleId=" + csId;
                        }
                        return false;
                    },
                    addContentSample = function() {
                        var btn = $(this),
                            buttons = $("button.addContentSample"),
                            loader = btn.siblings("img"),
                            searchResults = btn.closest(".searchResults"),
                            url = encodeURIComponent(btn.attr("data-href")),
                            urlAction = "editContentSample.action",
                            referrerPage = btn.attr("data-referrer"),
                            searchKey = btn.attr("data-search"),
                            searchKeyUrl = "",
                            referrerPageUrl = "",
                            currAction = "/addContentSample.action";

                        if (searchResults.hasClass("joinAddContentSample")) {
                            urlAction = "joinEditContentSample.action";
                            currAction = "/joinAddContentSample.action";
                        }
                        if ("" !== referrerPage) {
                            referrerPageUrl = "&referringPage=" + referrerPage;
                        }
                        if ("" !== searchKey) {
                            searchKeyUrl = "&search=" + searchKey;
                            currAction += "?" + searchKeyUrl;
                        }
                        urlAction +=
                            "?articleUrl=" +
                            url +
                            referrerPageUrl +
                            searchKeyUrl;
                        btn.removeAttr("disabled");
                        window.location.href = urlAction;

                        return false;
                    },
                    contentSample = function() {
                        var a = $(this),
                            div = a.closest(".searchContainer"),
                            otherDiv = div.siblings(".searchContainer"),
                            contentSearchResults = $("#content-search-results");
                        div.fadeOut("fast", function() {
                            otherDiv.fadeIn("fast");
                            if (a.hasClass("add-content-sample")) {
                                contentSearchResults.fadeOut();
                            } else {
                                contentSearchResults.fadeIn();
                            }
                        });
                        return false;
                    },
                    createHistoryState = function(html, urlPath, name) {
                        var state = {
                            html: html,
                            page: name
                        };
                        window.history.pushState(state, name, urlPath);
                    },
                    contentSampleExternalSearch = function() {
                        var urlAction =
                                "contentSampleExternalSearchAjax.action",
                            opts,
                            byLine = $("#byLineId"),
                            options,
                            domainNames = $("#domainNamesId"),
                            contentSearchResults = $("#content-search-results"),
                            resultsHead = $("#search-results-head"),
                            formHead = $("#search-form-head"),
                            articleSearch = $("#article-search"),
                            sampleLoader = $("#sampleLoader"),
                            currentSamples = $("#current-samples"),
                            currAction = "/addContentSample.action",
                            btn = $("#contentSampleExternalSearch");

                        if (articleSearch.hasClass("joinAddContentSample")) {
                            currAction = "/joinAddContentSample.action";
                        }

                        formHead.fadeOut("fast", function() {
                            resultsHead.show();
                        });
                        currentSamples.hide();
                        contentSearchResults.hide();
                        sampleLoader.show();
                        opts = {
                            domainNames: domainNames.val(),
                            byLine: byLine.val(),
                            referringPage: $("#referringPageId").val(),
                            search: $("#searchGuid").val()
                        };

                        options = {
                            cache: false,
                            type: "POST",
                            data: opts,
                            dataType: "text",
                            url: urlAction,
                            success: function(data) {
                                contentSearchResults
                                    .html(data)
                                    .closest(".container ")
                                    .show();
                                sampleLoader.hide();
                                contentSearchResults.show();
                                $("#article-search").show();
                            },
                            error: function(data, status, err) {
                                console.log([data, status, err]);
                            },
                            complete: function() {
                                var gooid = null,
                                    guid = "";
                                gooid = contentSearchResults.find(
                                    "ul.google-search-results"
                                );
                                guid = gooid.attr("data-search");
                                currAction += "?search=" + guid;
                                createHistoryState(
                                    $("body").html(),
                                    currAction,
                                    guid
                                );
                                if (
                                    "" !== domainNames.val() &&
                                    "" !== byLine.val()
                                ) {
                                    btn.removeAttr("disabled");
                                }
                            }
                        };
                        call.ajax(urlAction, options);
                        return false;
                    },
                    sampleSearchButton = function() {
                        var text = $(this),
                            byline = $("#byLineId"),
                            domainName = $("#domainNamesId"),
                            button = $("#contentSampleExternalSearch"),
                            searchContainer = text.closest(".searchContainer"),
                            articleUrlId = $("#articleUrlId"),
                            loadButton = $("#loadContentSample");

                        if ("article-search" === searchContainer.attr("id")) {
                            if (
                                "" !== byline.val() &&
                                "" !== domainName.val()
                            ) {
                                button.removeAttr("disabled");
                            } else {
                                button.attr("disabled", "disabled");
                            }
                        } else if (
                            "add-content-sample" === searchContainer.attr("id")
                        ) {
                            if ("" !== articleUrlId.val()) {
                                loadButton.removeAttr("disabled");
                            } else {
                                loadButton.attr("disabled", "disabled");
                            }
                        }
                    },
                    switchImage = function() {
                        var a = $(this),
                            span = a.closest(".imagePicker").find("span.n"),
                            t = a.closest(".imagePicker").find("span.t"),
                            imagePicker = $(".imagePicker"),
                            imageJson = $("#image-json"),
                            imgArr = loadJson(),
                            imageToggle = imagePicker.find(".imageToggle"),
                            imageInfo = imagePicker.find(".imageInfo"),
                            imageDisplay = imagePicker.find(".imageDisplay"),
                            image = imageDisplay.find(">img"),
                            idx = parseInt(span.attr("data-value"), 10),
                            total = parseInt(t.text(), 10),
                            selectedImageUrl = $("#selectedImageUrl"),
                            contentSampleImageId = $("#contentSampleImageId"),
                            imgUrl;

                        if (a.hasClass("icon-previous")) {
                            if (0 === idx) {
                                return false;
                            }
                            idx = idx - 1;
                        } else if (a.hasClass("icon-next")) {
                            if (idx === total - 1) {
                                return false;
                            }
                            idx = idx + 1;
                        }
                        span.attr("data-value", idx);
                        span.text(idx + 1);

                        if ("object" === typeof imgArr[idx]) {
                            imgUrl = imgArr[idx].imageDTO.finalName.substring(
                                imgArr[idx].sanStartIdx
                            );
                            image.attr("src", imgUrl);
                            selectedImageUrl.val(imgArr[idx].finalName);
                            contentSampleImageId.val(imgArr[idx].id);
                        } else {
                            //console.log("src 281 " + imgArr[idx].finalName.substring(imgArr[idx].sanStartIdx));
                            image.attr(
                                "src",
                                imgArr[idx].imageDTO.finalName.substring(
                                    imgArr[idx].sanStartIdx
                                )
                            );
                            selectedImageUrl.val(imgArr[idx].finalName);
                        }

                        // toggle left/right arrow visibility on first / last image
                        if (idx == 0) {
                            $(".imagePicker .prevButton").css(
                                "visibility",
                                "hidden"
                            );
                        } else {
                            $(".imagePicker .prevButton").css(
                                "visibility",
                                "visible"
                            );
                        }
                        if (idx >= imgArr.length - 1) {
                            $(".imagePicker .nextButton").css(
                                "visibility",
                                "hidden"
                            );
                        } else {
                            $(".imagePicker .nextButton").css(
                                "visibility",
                                "visible"
                            );
                        }

                        return false;
                    },
                    tryDifferent = function() {
                        var a = $(this),
                            resultsHead = $("#search-results-head"),
                            formHead = $("#search-form-head"),
                            articleSearch = $("#article-search"),
                            searchResults = $(
                                "#content-search-results"
                            ).closest(".container"),
                            manualSearch = $("#add-content-sample");

                        searchResults.hide();
                        resultsHead.hide();
                        formHead.fadeIn("fast");
                        if (a.hasClass("manuallyAdd")) {
                            manualSearch.slideDown();
                        } else {
                            articleSearch.slideDown();
                        }
                    },
                    loadContentSamples = function() {
                        var urlAction = "loadContentSamplesAjax.action",
                            currentSamples = $("#current-samples"),
                            contributorId = currentSamples.attr("data-account"),
                            opts = {
                                referringAction: currentSamples.attr(
                                    "data-referrer-action"
                                ),
                                referringPage: currentSamples.attr(
                                    "data-referrer-page"
                                ),
                                guid: currentSamples.attr("data-session")
                            },
                            options;

                        if (null !== contributorId && !isNaN(contributorId)) {
                            opts.contributorId = contributorId;
                            urlAction = "loadContributorSamplesAjax.action";
                        }

                        options = {
                            cache: false,
                            type: "GET",
                            data: opts,
                            dataType: "html",
                            url: urlAction,
                            success: function(data) {
                                var d = $(data);
                                d.hide();
                                currentSamples
                                    .find("img")
                                    .fadeOut("fast", function() {
                                        currentSamples.removeClass(
                                            "sampleLoader"
                                        );
                                        currentSamples.append(d);
                                        d.fadeIn("fast");
                                    });
                            },
                            error: function(data, status, err) {
                                console.log([data, status, err]);
                            }
                        };

                        call.ajax(urlAction, options);
                        return false;
                    },
                    checkFormState = function() {
                        var contentSampleExternalSearchElement = $(
                                "#contentSampleExternalSearch"
                            ),
                            contentSearchResults = $("#content-search-results"),
                            hash = window.location.hash;

                        if (
                            0 < contentSearchResults.length &&
                            null !== contentSearchResults.attr("data-search") &&
                            "" !== contentSearchResults.attr("data-search")
                        ) {
                            contentSampleExternalSearchElement.removeAttr(
                                "disabled"
                            );
                            contentSampleExternalSearch();
                        }
                        if ("#content-sample-url" === hash) {
                            $("#add-content-sample").show();
                            $("#article-search").hide();
                        }
                        if (!$("#search-results-head").is(":visible")) {
                            $("#search-form-head").show();
                        }
                    },
                    getContentSample = function() {
                        var contentSampleForm = $("#content-sample-form"),
                            url = contentSampleForm.attr("data-value"),
                            opts = { articleUrl: url },
                            urlAction = "loadContentSampleAjax.action",
                            options = {
                                cache: false,
                                type: "POST",
                                data: opts,
                                dataType: "html",
                                url: urlAction,
                                success: function(data) {
                                    var h = $(data);
                                    $(".sampleLoaderContainer").hide();
                                    contentSampleForm.parent().append(h);
                                    $("form.actionContainer").fadeIn();
                                },
                                error: function(data, status, err) {
                                    console.log([data, status, err]);
                                },
                                complete: function() {
                                    var articleUrlId = $("#articleUrlId"),
                                        displayLinkId = $("#displayLinkId"),
                                        url = articleUrlId.val(),
                                        https = -1 < url.indexOf("https%3A"),
                                        http = -1 < url.indexOf("http%3A");

                                    $(".styledSelect").selectBoxIt();

                                    // make the left arrow invisible upon loading
                                    $(".imagePicker .prevButton").css(
                                        "visibility",
                                        "hidden"
                                    );

                                    $(".imageToggle a").click(switchImage);
                                    document.onkeydown = contentSampleEnter;
                                    //console.log("Load Image Toggle....");
                                    loadImageToggle();
                                    setup.templateDatePicker();

                                    if (http) {
                                        url = url.replace("http%3A", "http:");
                                        articleUrlId.val(url);
                                        displayLinkId.text(url);
                                    } else if (https) {
                                        url = url.replace("https%3A", "https:");
                                        articleUrlId.val(url);
                                        displayLinkId.text(url);
                                    }
                                }
                            };

                        call.ajax(urlAction, options);
                        return false;
                    },
                    evaluateUrl = function() {
                        var btn = $(this),
                            searchContainer = btn.closest(".searchContainer"),
                            loc = "/editContentSample",
                            articleUrl = encodeURIComponent(
                                $("#articleUrlId").val()
                            ),
                            loadButton = $("#loadContentSample"),
                            loader = loadButton.siblings("img"),
                            refPage = $("#referringPageId"),
                            referringPage = "";
                        var urlAction = "evaluateUrlAjax.action",
                            opts = {
                                articleUrl: encodeURIComponent(
                                    $("#articleUrlId").val()
                                )
                            },
                            options;

                        options = {
                            cache: false,
                            type: "GET",
                            data: opts,
                            dataType: "html",
                            url: urlAction,
                            success: function(data) {
                                if ("" === articleUrl) {
                                    loadButton.attr("disabled", "disabled");
                                    return false;
                                }

                                if ("" !== refPage.val()) {
                                    referringPage =
                                        "&referringPage=" + refPage.val();
                                }

                                if (
                                    searchContainer.hasClass(
                                        "joinAddContentSample"
                                    )
                                ) {
                                    loc = "/joinEditContentSample";
                                }
                                btn.removeAttr("disabled");
                                if (!/^http/i.test(articleUrl)) {
                                    articleUrl = "http://" + articleUrl;
                                }
                                loadContentSample(
                                    loc,
                                    articleUrl,
                                    referringPage
                                );
                            },
                            error: function(data, status, err) {
                                $("#badUrlError").show();
                            }
                        };

                        call.ajax(urlAction, options);
                        return false;
                    };

                //onReady
                $(function() {
                    var currentSamples = $("#current-samples"),
                        body = $("body"),
                        bylineDomainArticle = $(
                            "#byLineId, #domainNamesId, #articleUrlId"
                        );

                    if (0 < currentSamples.length) {
                        loadContentSamples();
                    }

                    $("a.contentSample").click(contentSample);
                    $("a.tryDifferent").click(tryDifferent);
                    $("#loadContentSample").click(evaluateUrl);

                    body.on(
                        "click",
                        "#contentSampleExternalSearch",
                        null,
                        function() {
                            $("#success-box.alert").hide();
                            $("div.message.error").hide();
                            contentSampleExternalSearch();
                        }
                    );

                    body.on(
                        "click",
                        "button.addContentSample",
                        null,
                        addContentSample
                    );
                    body.on(
                        "click",
                        "a.editContentSample",
                        null,
                        editContentSample
                    );
                    body.on("click", "a.tryDifferent", null, tryDifferent);
                    body.on("click", "a.contentSample", null, contentSample);

                    bylineDomainArticle.keyup(sampleSearchButton);
                    bylineDomainArticle.blur(sampleSearchButton);
                    bylineDomainArticle.focus(sampleSearchButton);
                    bylineDomainArticle.click(sampleSearchButton);
                    bylineDomainArticle.bind("paste input", sampleSearchButton);

                    $("select.regularChozen").chosen();

                    $(".imageToggle a").click(switchImage);
                    document.onkeydown = contentSampleEnter;
                    loadImageToggle();
                    setup.templateDatePicker();
                    if (window.location.pathname !== "/myProfile.action") {
                        checkFormState();
                    }
                    if (1 === $("#content-sample-form").length) {
                        getContentSample();
                    }

                    body.on("click", "#btn-content-sample-search", function() {
                        var form = $("#content-sample-search-form"),
                            urlAction = form.attr("action"),
                            options = {
                                type: "POST",
                                url: urlAction,
                                data: form.serialize(),
                                dataType: "html",
                                success: function(data) {
                                    $("#search-content-samples").html(data);
                                },
                                complete: function() {
                                    $("#btn-content-sample-search").removeAttr(
                                        "disabled"
                                    );
                                },
                                error: function(data) {
                                    console.log("failed");
                                }
                            };
                        call.skyjax(urlAction, options);
                    });
                });

                $("#add_written").click(function() {
                    $("#search-form-head").hide();
                    $("#add-content-sample").show();
                    return false;
                });
                $("#add_video").click(function() {
                    $("#search-form-head").hide();
                    $("#video-search").show();
                    return false;
                });
                $(".returnToSamples").click(function() {
                    /*
                    $('#search-form-head').show();
                    $('#article-search').hide();
                    $('#video-search').hide();
                    $('#add-content-sample').hide();
                    $('#content-search-results').hide();
*/
                    location.reload();
                    return false;
                });
                $("body").on("click", "#article-link-button", function() {
                    $("#article-link-box").css("display", "inline-block");
                    $("#article-link-button")
                        .addClass("disabled")
                        .attr("disabled", "disabled");
                });
                $("body").on("click", "#article-upload-button", function() {
                    $("#article-link-box").hide();
                    $("#article-link-button")
                        .removeClass("disabled")
                        .removeAttr("disabled");
                });
            }
        };
    }
]);

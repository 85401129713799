app.controller("fileUploadCropController", [
    "$scope",
    "$http",
    "$filter",
    "$timeout",
    "skyword",
    "$sce",
    "$location",
    "$translate",
    "$window",
    "$rootScope",
    "$compile",
    "crop",
    "digitalAsset",
    "search",
    "SkyFactory",
    "assetCrop",
    "sanitizer",
    "contentTemplate",
    "TemplateFactory",
    "TinymceService",
    function(
        $scope,
        $http,
        $filter,
        $timeout,
        skyword,
        $sce,
        $location,
        $translate,
        $window,
        $rootScope,
        $compile,
        crop,
        digitalAsset,
        search,
        SkyFactory,
        assetCrop,
        sanitizer,
        contentTemplate,
        TemplateFactory,
        TinymceService
    ) {
        $scope = $scope.$parent;
        //console.log($scope);

        $scope.cropDTO = {
            cropX1: null,
            cropX2: null,
            cropY1: null,
            cropY2: null,
            cropW: null,
            cropH: null,
            accountId: null,
            attachmentId: null,
            contentTemplateElementDataId:
                $rootScope.contentTemplateElementDataId,
            contentRequestId: $rootScope.contentRequestId,
            csrfToken: $rootScope.csrfToken,
            transferToS3: false,
            imageTitle: null,
            imageAltText: null,
            description: null
        };

        if (!SkyFactory.isUndefinedOrNull($scope.isDigitalAsset)) {
            if (typeof $scope.isDigitalAsset === "string") {
                //console.info("is string, setting to : " + $scope.isDigitalAsset);
                $scope.cropDTO.transferToS3 = "true" === $scope.isDigitalAsset;
            } else if (typeof $scope.isDigitalAsset === "boolean") {
                //console.info("is already a boolean : " + $scope.isDigitalAsset);
                $scope.cropDTO.transferToS3 = $scope.isDigitalAsset;
            }
        }

        $scope.init = function() {
            $scope.prepareImage = false;
            $scope.cropDTO.attachmentId = $scope.ngModel.id;
            crop.check();
            $scope.cropDTO.imageTitle = $scope.imageTitle;
            $scope.cropDTO.description = $scope.description;
            $scope.cropDTO.contentTemplateElementDataId =
                $scope.ngModel.contentTemplateElementDataId;
            $scope.cropDTO.contentRequestId = $scope.ngModel.contentRequestId;
            $scope.cropDTO.csrfToken = $scope.ngModel.csrfToken;
        };

        $scope.cropImage = function(c) {
            $scope.cropDTO.cropX1 = angular.element("#x1").val();
            $scope.cropDTO.cropX2 = angular.element("#x2").val();
            $scope.cropDTO.cropY1 = angular.element("#y1").val();
            $scope.cropDTO.cropY2 = angular.element("#y2").val();
            $scope.cropDTO.cropW = angular.element("#w").val();
            $scope.cropDTO.cropH = angular.element("#h").val();
            jQuery("#colorbox").colorbox.resize({ height: 300 });
            $scope.prepareImage = true;

            var cleanMetaInfoPromise = sanitizer.cleanAttachmentMetaInformation(
                angular.copy($scope.cropDTO)
            );

            cleanMetaInfoPromise.then(function(cleanMeta) {
                //console.info(cleanMeta);
                $scope.cropDTO.imageAltText = cleanMeta.data.imageAltText;
                $scope.cropDTO.imageTitle = cleanMeta.data.imageTitle;
                $scope.cropDTO.attachmentId = cleanMeta.data.attachmentId;
                $scope.cropDTO.contentTemplateElementDataId =
                    cleanMeta.data.contentTemplateElementDataId;

                var cropPromise = assetCrop.crop($scope.cropDTO);

                cropPromise.then(function(crop) {
                    //console.log(crop);
                    if ($scope.showAttachmentInfo == "true") {
                        insertAttachmentInfo(crop.data);
                    } else {
                        insertDigitalAsset(crop.data);
                    }
                    $("body").removeAttr("style");
                    $.colorbox.close();
                    jQuery("#" + $rootScope.uploadContainerId).fadeOut();
                });
            });
        };

        $scope.cancelCropImage = function() {
            $("body").removeAttr("style");
            $.colorbox.close();
        };

        $scope.disableInsertImage = function() {
            return (
                SkyFactory.reallyEmptyCheck($scope.cropDTO.description) ||
                SkyFactory.reallyEmptyCheck($scope.cropDTO.imageTitle)
            );
        };

        function insertDigitalAsset(crop) {
            var redEditor = $(
                    "#cted" + $scope.ngModel.contentTemplateElementDataId
                ),
                altText = SkyFactory.reallyEmptyCheck(
                    $scope.cropDTO.description
                )
                    ? $scope.cropDTO.imageTitle
                    : $scope.cropDTO.description,
                src =
                    crop.fileUrl +
                    "?r=" +
                    (crop.contentTemplateElementDataId + crop.attachmentId),
                image = '<img src="{0}" alt="{1}" title="{2}"/>',
                html = image
                    .replace("{0}", src)
                    .replace("{1}", altText)
                    .replace("{2}", $scope.cropDTO.imageTitle);
            TinymceService.insertHtml(tinyMCE.activeEditor, html);
        }

        function insertAttachmentInfo(crop) {
            //console.log('INSERT INTO ATTACHMENT INFO');
            //attachmentId, contentTemplateElementDataId
            var htmlPromise = TemplateFactory.getAttachmentInfoHtml(
                $scope.ngModel.id,
                $scope.ngModel.contentTemplateElementDataId
            );
            htmlPromise.then(function(html) {
                var ac = $(
                    "div.attachmentContainer[data-cted=" +
                        $scope.ngModel.contentTemplateElementDataId +
                        "]"
                );
                var subContainer = ac.find(">div");
                //console.log($scope.cropDTO.attachmentId);
                //hide file upload span so we can re-init incase they remove the image
                subContainer.find(".fileUploadSpan").hide();
                //append new html into div
                subContainer.append(html.data);
                ac.addClass("attachmentLoaded");
                ac.siblings("input.attachmentId").val(
                    $scope.cropDTO.attachmentId
                );
            });
            //TemplateFactory.loadAttachmentInfo();
            //$scope.$apply();
        }
    }
]);

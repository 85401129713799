/**
 * Controller for calendar events
 */
app.controller("calendarFilterSaveView", [
    "$scope",
    "calendarFilter",
    "CalendarFilterFactory",

    function($scope, calendarFilter, CalendarFilterFactory) {
        $scope.saveFilterForm = {
            saveViewName: ""
        };

        $scope.saveFilterView = function() {
            //console.log("Save Filter View");
            var filterDTO = CalendarFilterFactory.getCalendarFilter();
            filterDTO.displayName = $scope.saveFilterForm.saveViewName;
            filterDTO.isDefaultView =
                $scope.saveFilterForm.saveDefaultValue == true;

            var path = location.pathname;
            filterDTO.calendarType =
                path.indexOf("submissionCalendar.action") >= 0
                    ? "submission"
                    : "";
            var promise = calendarFilter.saveCalendarFilterView(filterDTO);

            promise.then(function() {
                window.location.href = "/calendarViews.action";
            });
        };
    }
]).controller("calendarViews", [
    "$scope",
    "ngTableParams",
    "$filter",
    "calendarView",
    "$sce",
    "$translate",
    function($scope, ngTableParams, $filter, calendarView, $sce, $translate) {
        $scope.calView = {
            guid: "",
            externalURL: "",
            icalURL: ""
        };
        $scope.$watch("programId", function() {
            var dta = calendarView.getViewList(
                $scope.programId,
                "submission",
                function(r) {
                    $scope.tableDataSubmission = r;
                }
            );
        });

        $scope.$watch("programId", function() {
            var dta = calendarView.getViewList(
                $scope.programId,
                "publish",
                function(r) {
                    $scope.tableDataPublish = r;
                }
            );
        });

        $scope.setupTable = function() {
            if (undefined !== $scope.tableParamsSubmission) {
                $scope.tableParamsSubmission.reload();
            } else if (undefined !== $scope.tableDataSubmission) {
                $scope.tableParamsSubmission = new ngTableParams(
                    {
                        page: 1,
                        count: 1000,
                        sorting: { dateCreated: "desc" }
                    },
                    {
                        total: $scope.tableDataSubmission.length,
                        counts: [],
                        getData: function($defer, params) {
                            params.units = "angular.label.numbersamples";
                            params.total($scope.tableDataSubmission.length);
                            var orderedData = params.sorting()
                                ? $filter("orderBy")(
                                      $scope.tableDataSubmission,
                                      params.orderBy()
                                  )
                                : $scope.tableDataSubmission;
                            $defer.resolve(
                                orderedData.slice(
                                    (params.page() - 1) * params.count(),
                                    params.page() * params.count()
                                )
                            );
                        },
                        $scope: $scope
                    }
                );
            }
            if (undefined !== $scope.tableParamsPublish) {
                $scope.tableParamsPublish.reload();
            } else if (undefined !== $scope.tableDataPublish) {
                $scope.tableParamsPublish = new ngTableParams(
                    {
                        page: 1,
                        count: 1000,
                        sorting: { dateCreated: "desc" }
                    },
                    {
                        total: $scope.tableDataPublish.length,
                        counts: [],
                        getData: function($defer, params) {
                            params.units = "angular.label.numbersamples";
                            params.total($scope.tableDataPublish.length);
                            var orderedData = params.sorting()
                                ? $filter("orderBy")(
                                      $scope.tableDataPublish,
                                      params.orderBy()
                                  )
                                : $scope.tableDataPublish;
                            $defer.resolve(
                                orderedData.slice(
                                    (params.page() - 1) * params.count(),
                                    params.page() * params.count()
                                )
                            );
                        },
                        $scope: $scope
                    }
                );
            }
        };
        $scope.$watch("tableDataSubmission", $scope.setupTable);
        $scope.$watch("tableDataPublish", $scope.setupTable);

        $scope.save = function(cal) {
            //code to save to backend
            var copy = angular.copy(cal);
            delete copy.invalid;
            calendarView.saveCalendarViewRecord(copy, function(data) {
                cal.id = data.id;
                cal.displayName = data.displayName;
                cal.isDefaultView = data.isDefaultView;
            });
        };
        $scope.warnDelete = function(cal) {
            $.colorbox({
                width: "680px",
                height: "150px",
                inline: true,
                href: "#popDeleteConfirm"
            });
            $scope.calToDelete = cal;
        };
        $scope.cancelDelete = function() {
            //console.log('cancelDelete');
            $scope.calToDelete = null;
            $.colorbox.close();
        };

        $scope.delete = function() {
            if ($scope.calToDelete.calendarType == 0) {
                $scope.deletePublish();
            }
            if ($scope.calToDelete.calendarType == 1) {
                $scope.deleteSubmission();
            }
        };

        $scope.deleteSubmission = function() {
            calendarView.deleteCalendarViewRecord($scope.calToDelete, function(
                data
            ) {
                var idx = $scope.tableDataSubmission.indexOf(
                    $scope.calToDelete
                );

                $scope.tableDataSubmission.splice(idx, 1);
                $scope.tableParamsSubmission.reload();
                $scope.tableParamsPublish.reload();
                $scope.calToDelete = null;
                $.colorbox.close();
            });
        };

        $scope.deletePublish = function() {
            calendarView.deleteCalendarViewRecord($scope.calToDelete, function(
                data
            ) {
                var idx = $scope.tableDataPublish.indexOf($scope.calToDelete);

                $scope.tableDataPublish.splice(idx, 1);
                $scope.tableParamsSubmission.reload();
                $scope.tableParamsPublish.reload();
                $scope.calToDelete = null;
                $.colorbox.close();
            });
        };

        $translate(
            "angular.calendar.shared.list.calendarlistdescrdefault"
        ).then(function(txt) {
            $scope.pageDescription = $sce.trustAsHtml(txt);
        });

        $(".shared-calendar-link-pane")
            .find("textarea")
            .on("click", function() {
                $(this).select();
            });
        $scope.showURLs = function(g) {
            $scope.calView.guid = g;

            var defaultPorts = { "http:": 80, "https:": 443 };
            var root =
                window.location.protocol +
                "//" +
                window.location.hostname +
                (window.location.port &&
                window.location.port != defaultPorts[window.location.protocol]
                    ? ":" + window.location.port
                    : "");
            $scope.calView.externalURL =
                root + "/externalCalendar.action?guid=" + $scope.calView.guid;
            $scope.calView.icalURL =
                root + "/iCal.action?guid=" + $scope.calView.guid;
            $.colorbox({
                width: "680px",
                height: "445px",
                inline: true,
                href: "#popLinks"
            });
        };

        $scope.checkIfTextEmpty = function(displayName, cal) {
            if (displayName.length === 0 || !displayName.trim()) {
                cal.invalid = true;
            } else {
                cal.invalid = false;
            }
        };

        $scope.selectDefault = function(cal) {
            cal.isDefaultView = !cal.isDefaultView;
            if (cal.isDefaultView) {
                if (cal.calendarType == 0) {
                    angular.forEach($scope.tableParamsPublish.data, function(
                        data,
                        key
                    ) {
                        if (data.id != cal.id && data.isDefaultView) {
                            data.isDefaultView = false;
                        }
                    });
                }
                if (cal.calendarType == 1) {
                    angular.forEach($scope.tableParamsSubmission.data, function(
                        data,
                        key
                    ) {
                        if (data.id != cal.id) {
                            data.isDefaultView = false;
                        }
                    });
                }
            }
            $scope.save(cal);
        };
    }
]);

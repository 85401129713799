(function() {
    app.controller("digitalAssetAttachmentInfo", [
        "$scope",
        "digitalAsset",
        "search",
        "$timeout",
        "crop",
        "SkyFactory",
        "assetCrop",
        "$rootScope",
        "sanitizer",
        function(
            $scope,
            digitalAsset,
            search,
            $timeout,
            crop,
            SkyFactory,
            assetCrop,
            $rootScope,
            sanitizer
        ) {
            $scope.dam = {
                elements: [],
                assets: [],
                allAssets: [],
                noResults: false,
                index: 0,
                pageCount: 0,
                load: 15,
                textSearch: "",
                programId: $scope.programId,
                attachmentId: null,
                resourcePreview: "",
                imageHeight: null,
                imageWidth: null,
                enabled: false,
                reuseDigitalAssetDTO: {
                    contentRequestId:
                        undefined !== $scope.contentRequestId
                            ? $scope.contentRequestId
                            : null,
                    calendarEventId:
                        undefined !== $scope.calendarEventId
                            ? $scope.calendarEventId
                            : null,
                    programId: $scope.programId,
                    attachmentId: null
                },
                contentTypes: [],
                contentType: {}
            };

            $scope.chooseAsset = function(attachmentId) {
                $scope.dam.attachmentId = attachmentId;
                $scope.dam.reuseDigitalAssetDTO.attachmentId = attachmentId;
                var attachmentPromise = digitalAsset.getAssetAttachment(
                    attachmentId
                );

                attachmentPromise.then(function(attachment) {
                    //console.log(attachment);
                    $scope.dam.assetCropPreview =
                        attachment.data.assetCropPreview;
                    $scope.dam.resourcePreview =
                        attachment.data.resourcePreview;
                    $scope.dam.s3Preview = attachment.data.s3Preview;
                    $scope.dam.imageHeight = attachment.data.imageHeight;
                    $scope.dam.imageWidth = attachment.data.imageWidth;
                    var metaPromise = digitalAsset.getDigitalAssetMetaInfo(
                        attachment.data.contentRequestId
                    );
                    metaPromise.then(function(meta) {
                        //console.log(meta);
                        $scope.dam.elements = meta.data;
                    });
                });
            };
        }
    ]);
})();

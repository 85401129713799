app.controller("additionalPayments", [
    "$scope",
    "finance",
    function($scope, finance) {
        finance.init();
    }
])
    .controller("changeMonthlyBudgetAjax", ["$scope", function($scope) {}])
    .controller("addExtraFundsAjax", ["$scope", function($scope) {}])
    .controller("additionalPaymentHistory", ["$scope", function($scope) {}])
    .controller("escrowManagement", [
        "$scope",
        "finance",
        function($scope, finance) {
            finance.init();
        }
    ])
    .controller("editEscrowAjax", ["$scope", function($scope) {}])
    .controller("addEscrowBudgetAjax", ["$scope", function($scope) {}]);

app.directive("writerinfo", [
    "$timeout",
    "call",
    "$translate",
    "$compile",
    "$rootScope",
    "TemplateFactory",
    "SkyFactory",
    "autoComplete",
    "writerInfo",
    function(
        $timeout,
        call,
        $translate,
        $compile,
        $rootScope,
        TemplateFactory,
        SkyFactory,
        autoComplete,
        writerInfo
    ) {
        return {
            restrict: "EA",
            replace: true,
            templateUrl: "/partials/directives/writerInfoModule.html",
            scope: {
                ngModel: "="
            },
            link: function(scope, element, attrs) {
                scope.showContributorSelect = false;
                scope.programId = attrs.programid;
                scope.categoryId = attrs.categoryid;
                scope.contentRequestId = attrs.contentrequestid;
                scope.byline = "";
                scope.showBylineError = false;

                scope.chooseContributor = function() {
                    if (scope.showContributorSelect == false) {
                        scope.showContributorSelect = true;
                    } else {
                        scope.showContributorSelect = false;
                    }
                    scope.removeAssignee();
                };

                scope.getByline = function(byline) {
                    if (byline !== undefined && byline != "") {
                        var promise = writerInfo.getWriterInfo(
                            scope.programId,
                            byline,
                            scope.categoryId
                        );
                        scope.contributors = [];
                        promise.then(function(data) {
                            scope.contributors = data;
                            if (scope.contributors.length <= 0) {
                                scope.showBylineError = true;
                            } else {
                                scope.showBylineError = false;
                            }
                            //console.log(data);
                        });
                    } else {
                        scope.contributors = [];
                        scope.showBylineError = false;
                    }
                };

                scope.selectAssignee = function(assignee, save) {
                    scope.assignee = assignee;
                    scope.showContributorSelect = false;
                    scope.contributors = [];
                    if (save) {
                        //put call to save accountId on CR
                        writerInfo.setAssignee(
                            scope.programId,
                            scope.contentRequestId,
                            assignee.accountId
                        );
                    }
                };

                scope.removeAssignee = function() {
                    scope.assignee = undefined;
                    writerInfo.setAssignee(
                        scope.programId,
                        scope.contentRequestId,
                        -1
                    );
                };

                if (scope.contentRequestId) {
                    //look for contributor on CR
                    writerInfo
                        .getAssignee(scope.programId, scope.contentRequestId)
                        .then(function(data) {
                            scope.selectAssignee(data, false);
                        });
                }
            },
            done: function(e, data) {},
            success: function(result, textStatus, jqXHR) {},
            error: function(jqXHR, textStatus, errorThrown) {},
            progressall: function(e, data) {}
        };
    }
]);

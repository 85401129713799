app.service("calendarFilter", [
    "$http",
    function($http) {
        return {
            getDisplayOptions: getDisplayOptions,
            getContentTypesAndCategories: getContentTypesAndCategories,
            getContentStateGroups: getContentStateGroups,
            saveCalendarFilterView: saveCalendarFilterView,
            getEventTypes: getEventTypes,
            saveSharedCalendarFilterView: saveSharedCalendarFilterView
        };

        function getDisplayOptions(programId, calendarShareId) {
            var url = "/API/program/" + programId + "/calendar-filter/colors";
            if (undefined !== calendarShareId && null !== calendarShareId) {
                url += "?calendarShareId=" + calendarShareId;
            }
            return $http.get(url);
        }

        function getContentTypesAndCategories(programId, calendarShareId) {
            var url =
                "/API/program/" + programId + "/calendar-filter/assignment";
            if (undefined !== calendarShareId && null !== calendarShareId) {
                url += "?calendarShareId=" + calendarShareId;
            }
            return $http.get(url);
        }

        function getContentStateGroups(programId, calendarShareId) {
            var url = "/API/program/" + programId + "/calendar-filter/status";
            if (undefined !== calendarShareId && null !== calendarShareId) {
                url += "?calendarShareId=" + calendarShareId;
            }
            return $http.get(url);
        }

        function getEventTypes(programId, calendarShareId) {
            var url =
                "/API/program/" + programId + "/calendar-filter/eventTypes";
            if (undefined !== calendarShareId && null !== calendarShareId) {
                url += "?calendarShareId=" + calendarShareId;
            }
            return $http.get(url);
        }

        function saveCalendarFilterView(filterDTO) {
            return $http.put(
                "/API/program/" + filterDTO.programId + "/calendar-filter/save",
                filterDTO
            );
        }

        function saveSharedCalendarFilterView(filterDTO, calendarShareId) {
            return $http.put(
                "/API/program/" + filterDTO.programId + "/calendar-filter/save/" + calendarShareId,
                filterDTO
            );
        }
    }
]);

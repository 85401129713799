app.service("assetCrop", [
    "$http",
    function($http) {
        return {
            crop: crop
        };

        function crop(cropDTO) {
            var url = "/API/image/" + cropDTO.attachmentId + "/crop";
            return $http.put(url, cropDTO);
        }
    }
]);

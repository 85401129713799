app.controller("login", [
    "$scope",
    "login",
    "skyword",
    function($scope, login, skyword) {
        login.init();
        var privacyNotice = function() {
            var cook = skyword.getCookie("acceptedPrivacyPolicy");
            if (null === cook) {
                angular.element("#alert-privacy").show();
            }
        };
        privacyNotice();
    }
])
    .controller("privacyNotice", [
        "$scope",
        "skyword",
        function($scope, skyword) {}
    ])
    .controller("twoFactor", [
        "$scope",
        "accountSecurity",
        function($scope, accountSecurity) {
            accountSecurity.init();
        }
    ])
    .controller("twoFactorSmsAjax", ["$scope", function($scope) {}])
    .controller("accountSecuritySetupAjax", ["$scope", function($scope) {}])
    .controller("accountSecurityVerifyAjax", ["$scope", function($scope) {}])
    .controller("accountSecurityCompleteAjax", ["$scope", function($scope) {}])
    .controller("getPhoneNumberExampleAjax", ["$scope", function($scope) {}])
    .controller("accountSecurityReverify", [
        "$scope",
        "login",
        function($scope, login) {
            login.init();
        }
    ])
    .controller("backupCodesGenerateAjax", ["$scope", function($scope) {}])
    .controller("changeLocale", ["$scope", function($scope) {}])
    .controller("logout", ["$scope", function($scope) {}])
    .controller("forgotPassword", [
        "$scope",
        "login",
        function($scope, login) {
            login.init();
        }
    ])
    .controller("resetPassword", [
        "$scope",
        "login",
        function($scope, login) {
            login.init();
        }
    ])
    .controller("updateEmail", ["$scope", function($scope) {}])
    .controller("updatePassword", ["$scope", function($scope) {}])
    .controller("loginPing", ["$scope", function($scope) {}])
    .controller("unLockAccount", [
        "$scope",
        "login",
        function($scope, login) {
            login.init();
        }
    ])
    .controller("newAccountSetup", [
        "$scope",
        "accountSecurity",
        function($scope, accountSecurity) {
            accountSecurity.init();
        }
    ])
    .controller("newAccountSecurity", [
        "$scope",
        "accountSecurity",
        function($scope, accountSecurity) {
            accountSecurity.init();
        }
    ])
    .controller("termsOfService", ["$scope", function($scope) {}])
    .controller("contributorAgreement", ["$scope", function($scope) {}])
    .controller("privacyPolicy", ["$scope", function($scope) {}])
    .controller("browserMessageAjax", ["$scope", function($scope) {}]);

app.service("exportFile", [
    "$http",
    function($http) {
        return {
            checkClickOutside: function() {
                $(document).on("click", function(event) {
                    if (
                        !event.target.matches("#downloadBtn") &&
                        !event.target.matches("#downloadIcon") &&
                        !event.target.matches("#downloadDD")
                    ) {
                        let refMenuOpen = $("#downloadDD").css("display");
                        if (refMenuOpen === "block") {
                            $("#downloadDD").toggle();
                        }
                    }
                });
            },
            getFileExport: getFileExport
        };

        function getFileExport(contentId, fileType) {
            return $http.get(
                "/API/export/" + contentId + "?fileType=" + fileType
            );
        }
    }
]);

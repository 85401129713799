app.service("articleReview", [
    "skyword",
    "call",
    "setup",
    "PlagiarismFactory",
    "$translate",
    "$timeout",
    function(skyword, call, setup, PlagiarismFactory, $translate, $timeout) {
        var setupInlineBox = function() {
                setup.followMenuResize();
                setup.styledDropdowns();
                if ($("#externalReviewerId").prop("tagName") == "SELECT") {
                    $("#externalReviewerId")
                        .on("change", function() {
                            if (
                                this.options[this.selectedIndex].value == "-1"
                            ) {
                                $("#controlsDiv button")
                                    .attr("disabled", "disabled")
                                    .addClass("disabled");
                            } else {
                                $("#controlsDiv button")
                                    .removeAttr("disabled")
                                    .removeClass("disabled");
                            }
                        })
                        .change();
                }
            },
            updateState = function(id) {
                var nsId = id,
                    crId = $("#contentRequestId").val(),
                    token = $("input[name='token']").val(),
                    referrerAction = $("#referrerAction").val(),
                    referrerPage = $("#referrerPage").val(),
                    referrerName = $("#referrerName").val(),
                    referrerContentPackage = $("#referrerContentPackage").val(),
                    urlAction =
                        $("#formSubmit").attr("action") +
                        "?contentRequestId=" +
                        crId +
                        "&newStateId=" +
                        nsId +
                        "&struts.token.name=token&token=" +
                        token +
                        "&referrerAction=" +
                        referrerAction +
                        "&referrerPage=" +
                        referrerPage +
                        "&referrerName=" +
                        referrerName +
                        "&referrerContentPackage=" +
                        referrerContentPackage,
                    options = {
                        cache: false,
                        dataType: "html",
                        type: "get",
                        url: urlAction,
                        success: function(data) {
                            var sidebarBox = $(".sidebarBox"),
                                htmlPage = $("html");

                            $("#loader-module").hide();
                            sidebarBox
                                .removeClass("whiteout")
                                .slideUp("fast", function() {
                                    $("#inline-box")
                                        .html(data)
                                        .slideDown("fast", setupInlineBox);
                                    setup.futureDatePicker();
                                    var id = $("#update-status-box")
                                        .find("input.date-picker-future")
                                        .data("id");
                                    setup.dateAndTimePicker(id);
                                })
                                .slideUp("fast", function() {
                                    setup.followMenuResize();
                                    setup.styledDropdowns();
                                });
                            $("#inline-box")
                                .html(data)
                                .slideDown("fast", setupInlineBox);

                            htmlPage.off("click", "#update-status-box button");
                            htmlPage.on(
                                "click",
                                "#update-status-box button",
                                function() {
                                    // validate fields before submitting
                                    function validateFields() {
                                        var isValid = true;
                                        var reasonValue = document.getElementById("reasonValue");
                                        var otherReasonValue = document.getElementById("otherReasonValue");

                                        // Check if reasonValue is selected
                                        if (reasonValue.value?.length === 0) {
                                            isValid = false;
                                            $("#reasonErrorText").show();
                                        }

                                        // Check if otherReasonValue is filled
                                        if (otherReasonValue.style.display !== "none" && otherReasonValue.value.trim()?.length === 0) {
                                            isValid = false;
                                            $("#reasonErrorText").show();
                                        }

                                        return isValid;
                                    }
                                    const checkRatingAIGenerated = $("#checkRatingAIGenerated").val();
                                    let newStateId = $("#form-submit_newStateId").val();
                                    if(checkRatingAIGenerated === "true" && parseInt(newStateId) === 7 && !validateFields()) {
                                        return;
                                    }
                                    var formSubmit = $("#form-submit"),
                                        urlAction = formSubmit.attr("action"),
                                        options = {
                                            cache: false,
                                            dataType: "html",
                                            data: formSubmit.serialize(),
                                            type: "POST",
                                            url: urlAction,
                                            success: function(data) {
                                                $(".sidebarBox").css(
                                                    "display",
                                                    "none"
                                                );
                                                $(
                                                    "#update-status-box"
                                                ).removeClass("whiteout");
                                                $("#loader-module").hide();
                                                $("#inline-box")
                                                    .slideUp()
                                                    .html(data)
                                                    .slideDown(
                                                        "fast",
                                                        setupInlineBox
                                                    );
                                                $(".selectedText")
                                                    .focus()
                                                    .select();
                                                setup.futureDatePicker();
                                                var id = $("#update-status-box")
                                                    .find(
                                                        "input.date-picker-future"
                                                    )
                                                    .data("id");
                                                setup.dateAndTimePicker(id);
                                            }
                                        };
                                    call.ajax(urlAction, options);
                                    $("#loader-module")
                                        .show()
                                        .find(".saveDraftText")
                                        .hide();
                                    $("#update-status-box").addClass(
                                        "whiteout"
                                    );
                                }
                            );
                            htmlPage.on(
                                "click",
                                "#confirm-submit-box button",
                                function() {
                                    updateState($("#newStateId").val());
                                }
                            );
                        }
                    };
                call.ajax(urlAction, options);
                return false;
            },
            /*SKY-13365

         Somewhere along the line some JS when missing or was changed when dealing with article review buttons.
         */
            articleReviewInit = function() {
                skyword.contentLock(120000);
                // handle the approve / publish button
                var a11 = $("#review-submit-action a#11");

                if (
                    "/articleReview.action" === window.location.pathname ||
                    "/viewContent.action" === window.location.pathname
                ) {
                    if (
                        "true" == $("#enableArticleReviewPublishButton").val()
                    ) {
                        //$("#review-submit-action .btnaction, #review-submit-action .btnaction a, #review-submit-action .btn, #review-submit-action .orangeBtn a").removeClass("disabled").removeAttr("disabled");
                        if ("false" == $("#enableSubmitButton").val()) {
                            a11.addClass("disabled").attr(
                                "disabled",
                                "disabled"
                            );
                            a11.parent().addClass("disabled");
                        } else {
                            a11.removeClass("disabled").removeAttr("disabled");
                            a11.parent().removeClass("disabled");
                        }
                    } else {
                        a11.addClass("disabled").attr("disabled", "disabled");
                        a11.parent().addClass("disabled");
                    }
                } else {
                    if ($("#enableSubmitButton").val() == "false") {
                        a11.addClass("disabled").attr("disabled", "disabled");
                        a11.parent().addClass("disabled");
                    } else {
                        a11.removeClass("disabled").removeAttr("disabled");
                        a11.parent().removeClass("disabled");
                    }
                }
                // handle approve for asset
                var a58 = $("#review-submit-action a#58");
                if (
                    "/articleReview.action" === window.location.pathname ||
                    "/viewContent.action" === window.location.pathname
                ) {
                    if (
                        "true" == $("#enableArticleReviewPublishButton").val()
                    ) {
                        //$("#review-submit-action .btnaction, #review-submit-action .btnaction a, #review-submit-action .btn, #review-submit-action .orangeBtn a").removeClass("disabled").removeAttr("disabled");
                        if ("true" == $("#missingFieldsExist").val()) {
                            a58.addClass("disabled").attr(
                                "disabled",
                                "disabled"
                            );
                            a58.parent().addClass("disabled");
                        } else {
                            a58.removeClass("disabled").removeAttr("disabled");
                            a58.parent().removeClass("disabled");
                        }
                    } else {
                        a58.addClass("disabled").attr("disabled", "disabled");
                        a58.parent().addClass("disabled");
                    }
                } else {
                    if ($("#missingFieldsExist").val() == "true") {
                        a58.addClass("disabled").attr("disabled", "disabled");
                        a58.parent().addClass("disabled");
                    } else {
                        a58.removeClass("disabled").removeAttr("disabled");
                        a58.parent().removeClass("disabled");
                    }
                }
            },
            checkContentRequestAIGenerated = function() {
                let brandRating = parseInt($("#contentRatingBrand").val());
                let subjectDepthRating = parseInt(
                    $("#contentRatingSubjectDepth").val()
                );
                let mechanicsRating = parseInt(
                    $("#contentRatingMechanics").val()
                );
                if (
                    brandRating === 0 ||
                    subjectDepthRating === 0 ||
                    mechanicsRating === 0
                ) {
                    $('#review-submit-action a').each(function() {
                        var id = $(this).attr('id');
                        if (id && id !== "7") {
                          $(this).parent('li').addClass('disabled').attr("disabled", "disabled");
                          $(this).addClass('disabled');
                        }
                    });
                    $timeout(function() {
                    $("#review-submit-action span:first").addClass("disabled");
                    }, 0);
                }
            };
        return {
            unlockContentAjax: function() {
                var urlAction = "/unlockContentAjax.action",
                    crId = $("#contentRequestId").val(),
                    options = {
                        cache: false,
                        type: "POST",
                        data: { contentRequestId: crId },
                        dataType: "html",
                        url: urlAction,
                        async: false,
                        error: function(data, status, err) {
                            console.log([data, status, err]);
                        }
                    };
                call.ajax(urlAction, options);
            },

            init: function() {
                //onReady
                $(function() {
                    var body = $("body"),
                        historyCommentButton = $("#history-comment-btn");

                    historyCommentButton
                        .attr("disabled", "disabled")
                        .addClass("disabled");

                    var selectedTab = skyword.getQstringParamByName(
                        "selectedTab"
                    );
                    if ("commentsTab" === selectedTab) {
                        $(".tabs .tab").removeClass("active");
                        $(".tabs #annotation-module.tab")
                            .click()
                            .addClass("active");
                    }

                    articleReviewInit();

                    $("#comment").keyup(function() {
                        var comment = $(this).val(),
                            historyCommentButton = $("#history-comment-btn");
                        if (null === comment || "" === comment) {
                            historyCommentButton.attr("disabled", "disabled");
                            historyCommentButton.addClass("disabled");
                        } else {
                            historyCommentButton.removeAttr("disabled");
                            historyCommentButton.removeClass("disabled");
                        }
                    });

                    $("html").on("click", "#inline-close", function() {
                        skyword.enableButtons();
                        $(".sidebarBox").slideDown("fast", function() {
                            $("#update-status-box").slideUp("fast", function() {
                                setup.followMenuResize();
                            });
                        });
                        $("#sidebar-articleReview").removeClass("whiteout");
                        $("#lockedContentWarning").removeClass("whiteout")
                        return false;
                    });

                    $("#btn-comment-add").click(showCommentBox);
                    $("#history-comment-btn").click(function() {
                        var externalReviewerName = localStorage.getItem(
                            "externalReviewer"
                        );
                        externalReviewerName =
                            externalReviewerName === null
                                ? ""
                                : externalReviewerName;
                        var accountId = parseInt(
                            $("#attrs-id").data("accountid")
                        );

                        var externalReviewerData = "";
                        if (accountId === 1) {
                            if (externalReviewerName.trim() !== "") {
                                externalReviewerData =
                                    " - " + externalReviewerName;
                            }
                        }
                        var comments =
                                $("#comment").val() + externalReviewerData,
                            crId = $("#contentRequestId").val(),
                            options = {
                                type: "POST",
                                url: "/articleHistory.action",
                                dataType: "html",
                                data: {
                                    comment: comments,
                                    contentRequestId: crId
                                },
                                success: function(data) {
                                    skyword.enableButtons();
                                    //console.log("ul.historyData being replaced... MANAGER");
                                    var filterHtml = $(data).find(
                                        ".historyData"
                                    );
                                    $(".historyData").replaceWith(data);
                                    $("#comment").val("");
                                    app.value("requestActive", false);
                                    hideCommentBox();
                                    skyword.showTimeFrame();
                                },
                                error: function(data) {
                                    app.value("requestActive", false);
                                }
                            };
                        call.ajax("/articleHistory.action", options);
                        return false;
                    });
                    $("#history-comment-close").click(hideCommentBox);
                    function showCommentBox() {
                        $("#btn-comment-add").css("visibility", "hidden");
                        $("#article-history-module .content").slideToggle(
                            "slow",
                            skyword.setupFollowMenuResize
                        );
                        if (
                            $("#comment").val() == null ||
                            $("#comment").val() == ""
                        ) {
                            $("#history-comment-btn").attr(
                                "disabled",
                                "disabled"
                            );
                            $("#history-comment-btn").addClass("disabled");
                        }
                        return false;
                    }

                    function hideCommentBox() {
                        $("#btn-comment-add").css("visibility", "visible");
                        $("#article-history-module .content").slideToggle(
                            "slow",
                            skyword.setupFollowMenuResize
                        );
                        return false;
                    }

                    body.on("click", ".showMore", function() {
                        $(this)
                            .hide()
                            .closest("ul")
                            .addClass("opened")
                            .find("li")
                            .addClass("displayLinks");
                        $(this)
                            .parent()
                            .find(".showLess")
                            .show();
                        return false;
                    });
                    body.on("click", ".showLess", function() {
                        $(this)
                            .hide()
                            .closest("ul")
                            .removeClass("opened")
                            .find("li")
                            .removeClass("displayLinks");
                        $(this)
                            .parent()
                            .find(".showMore")
                            .show();
                        return false;
                    });

                    PlagiarismFactory.togglePlagiarism();

                    let checkRatingAIGenerated = $(
                        "#checkRatingAIGenerated"
                    ).val();
                    if (checkRatingAIGenerated === "true") {
                        checkContentRequestAIGenerated();
                    }
                });
            },
            updateState: function(id) {
                updateState(id);
            }
        };
    }
]);

(function() {
    app.controller("contributors", [
        "$scope",
        function($scope) {
            $("#select-approvedWriters").change(function() {
                window.location.href =
                    "contributors.action?filter=" + $(this).val();
            });
        }
    ])
        .controller("viewContributor", [
            "$scope",
            "writers",
            "compensation",
            "qualityRating",
            "contentSearch",
            "notes",
            "skywordGraphs",
            "skyword",
            "setup",
            "call",
            "finance",
            function(
                $scope,
                writers,
                compensation,
                qualityRating,
                contentSearch,
                notes,
                skywordGraphs,
                skyword,
                setup,
                call,
                finance
            ) {
                finance.init();
                notes.init();
                qualityRating.init();
                contentSearch.init();
                writers.init();
                compensation.init();

                //needed for new moment-datepicker
                $scope.dates = {
                    startDate: null,
                    endDate: null
                };

                //watches start date to make sure end date is not before start date
                $scope.$watch("[dates.startDate, dates.endDate]", function(
                    newValue,
                    oldValue
                ) {
                    $scope.momentDateFormat = $("#momentPickerFormat").val();
                    $scope.dateformat = "MM-DD-YYYY";

                    if ($scope.dates.startDate != null) {
                        $scope.minDate = $scope.dates.startDate.toString();
                    }
                    if ($scope.dates.endDate != null) {
                        $scope.maxDate = $scope.dates.endDate.toString();
                    }

                    if (
                        $scope.dates.startDate == null &&
                        $scope.dates.endDate == null
                    ) {
                        var startDateVal = $(".startDate").val();
                        var endDateVal = $(".endDate").val();
                        $scope.dates.startDate = moment(
                            startDateVal,
                            $scope.dateformat
                        ).format($scope.momentDateFormat);
                        $scope.dates.endDate = moment(
                            endDateVal,
                            $scope.dateformat
                        ).format($scope.momentDateFormat);
                    }

                    if (
                        $scope.dates.startDate != null &&
                        $scope.dates.endDate != null
                    ) {
                        $scope.startDateMoment = moment(
                            $scope.dates.startDate,
                            $scope.momentDateFormat
                        ).format($scope.dateformat);
                        $scope.endDateMoment = moment(
                            $scope.dates.endDate,
                            $scope.momentDateFormat
                        ).format($scope.dateformat);
                        $(".startDate").val($scope.startDateMoment);
                        $(".endDate").val($scope.endDateMoment);
                    }
                });

                //onReady
                $(function() {
                    var body = $("body"),
                        byline = $("#byline").val(),
                        formParams = {
                            programAccountId: $("#programAccountId").val(),
                            jsonPost: true
                        },
                        options = {
                            cache: false,
                            url: "/viewAuthorAjax.action",
                            data: formParams,
                            type: "POST",
                            dataType: "json",
                            success: function(data, textStatus, jqXHR) {
                                var j =
                                        !data instanceof Object
                                            ? $.parseJSON($.trim(data))
                                            : data,
                                    month,
                                    day,
                                    endDate,
                                    date,
                                    startDate,
                                    popupLabel = "views",
                                    noResults = "",
                                    submitted = {
                                        graphType: "area",
                                        type: "json",
                                        xType: "date",
                                        xLabel: "  ",
                                        errorMessage: noResults,
                                        yType: "Number",
                                        yLabel: " ",
                                        xLabelSize: 0,
                                        yLabelSize: 0,
                                        xAxisThickness: 0,
                                        yAxisThickness: 0,
                                        yFormat: "%d",
                                        popupFormat:
                                            "<span class='value'>{y}</span><span>" +
                                            popupLabel +
                                            "</span>"
                                    },
                                    returned = {
                                        graphType: "area",
                                        type: "json",
                                        xType: "date",
                                        xLabel: "  ",
                                        errorMessage: noResults,
                                        yType: "Number",
                                        yLabel: " ",
                                        xLabelSize: 0,
                                        yLabelSize: 0,
                                        xAxisThickness: 0,
                                        yAxisThickness: 0,
                                        yFormat: "%d",
                                        popupFormat:
                                            "<span class='value'>{y}</span><span>" +
                                            popupLabel +
                                            "</span>"
                                    },
                                    declined = {
                                        graphType: "area",
                                        type: "json",
                                        xType: "date",
                                        xLabel: "  ",
                                        errorMessage: noResults,
                                        yType: "Number",
                                        yLabel: " ",
                                        xLabelSize: 0,
                                        yLabelSize: 0,
                                        xAxisThickness: 0,
                                        yAxisThickness: 0,
                                        yFormat: "%d",
                                        popupFormat:
                                            "<span class='value'>{y}</span><span>" +
                                            popupLabel +
                                            "</span>"
                                    },
                                    published = {
                                        graphType: "area",
                                        type: "json",
                                        xType: "date",
                                        xLabel: "  ",
                                        errorMessage: noResults,
                                        yType: "Number",
                                        yLabel: " ",
                                        xLabelSize: 0,
                                        yLabelSize: 0,
                                        xAxisThickness: 0,
                                        yAxisThickness: 0,
                                        yFormat: "%d",
                                        popupFormat:
                                            "<span class='value'>{y}</span><span>" +
                                            popupLabel +
                                            "</span>"
                                    },
                                    paid = {
                                        graphType: "area",
                                        type: "json",
                                        xType: "date",
                                        xLabel: "  ",
                                        errorMessage: noResults,
                                        yType: "Number",
                                        yLabel: " ",
                                        xLabelSize: 0,
                                        yLabelSize: 0,
                                        xAxisThickness: 0,
                                        yAxisThickness: 0,
                                        yFormat: "%d",
                                        popupFormat:
                                            "<span class='value'>{y}</span><span>" +
                                            popupLabel +
                                            "</span>"
                                    };

                                submitted.location = "spark-submitted";
                                returned.location = "spark-returned";
                                declined.location = "spark-declined";
                                published.location = "spark-published";
                                paid.location = "spark-paid";

                                submitted.graphData = j.submitted;
                                returned.graphData = j.returned;
                                declined.graphData = j.declined;
                                published.graphData = j.published;
                                paid.graphData = j.paid;

                                date = new Date();
                                month = date.getMonth() + 1;
                                day = date.getDate();
                                if (month < 10) {
                                    month = "0" + month;
                                }
                                if (day < 10) {
                                    day = "0" + day;
                                }

                                endDate =
                                    month +
                                    "-" +
                                    day +
                                    "-" +
                                    date.getFullYear();
                                date.setDate(date.getDate() + -30);
                                month = date.getMonth() + 1;
                                day = date.getDate();
                                if (month < 10) {
                                    month = "0" + month;
                                }
                                if (day < 10) {
                                    day = "0" + day;
                                }
                                startDate =
                                    month +
                                    "-" +
                                    day +
                                    "-" +
                                    date.getFullYear();
                                $("#total-submitted").html(
                                    skyword.addCommas(j.totalSubmitted)
                                );
                                $("#total-returned").html(
                                    skyword.addCommas(j.totalReturned)
                                );

                                $("#total-published").html(
                                    "<a class='secArticleSearch disableLink' href='searchArticles.action?selectedStateId=10&byline=" +
                                        byline +
                                        "&startDateString=" +
                                        startDate +
                                        "&endDateString=" +
                                        endDate +
                                        "'>" +
                                        skyword.addCommas(j.totalPublished) +
                                        "</a>"
                                );
                                $("#total-declined").html(
                                    "<a class='secArticleSearch disableLink' href='searchArticles.action?selectedStateId=11&byline=" +
                                        byline +
                                        "'>" +
                                        skyword.addCommas(j.totalDeclined) +
                                        "</a>"
                                );
                                $("#total-paid").html(
                                    "<a class='secManagerReports disableLink' href='managerReportArticleResults.action?processReport=true&byline=" +
                                        byline +
                                        "&startdate=" +
                                        startDate +
                                        "&enddate=" +
                                        endDate +
                                        "'>" +
                                        skyword.addCommas(j.totalPaid) +
                                        "</a>"
                                );

                                skyword.enforceLinkSecurity();

                                if ($("#allowPublishSearch").val() == "false") {
                                    $("#total-published a")
                                        .click(function(e) {
                                            e.preventDefault();
                                        })
                                        .css("text-decoration", "none")
                                        .hover(function() {
                                            $(this).css(
                                                "text-decoration",
                                                "none"
                                            );
                                        });
                                }
                                if ($("#allowDeclineSearch").val() == "false") {
                                    $("#total-declined a")
                                        .click(function(e) {
                                            e.preventDefault();
                                        })
                                        .css("text-decoration", "none")
                                        .hover(function() {
                                            $(this).css(
                                                "text-decoration",
                                                "none"
                                            );
                                        });
                                }

                                skywordGraphs.createAreaGraph(submitted);
                                skywordGraphs.createAreaGraph(returned);
                                skywordGraphs.createAreaGraph(declined);
                                skywordGraphs.createAreaGraph(published);
                                skywordGraphs.createAreaGraph(paid);
                            },
                            error: function(jqXHR, textStatus, errorThrown) {
                                //console.log(textStatus);
                            },
                            complete: function() {
                                app.value("requestActive", false);
                            }
                        };

                    body.on("click", "#edit-writer-record", function() {
                        $(".nonEditableField")
                            .fadeOut()
                            .addClass("hidden");
                        $(".editableField")
                            .fadeIn()
                            .removeClass("hidden");
                        $("#reinstate")
                            .prop("disabled", true)
                            .addClass("disabled");
                        setup.followMenuResize();
                        $(this).hide();
                        $("#save-writer-record,#cancel-writer-record").show();
                        return false;
                    });

                    body.on("click", "#cancel-writer-record", function() {
                        $(".nonEditableField")
                            .fadeIn()
                            .removeClass("hidden");
                        $(".editableField")
                            .fadeOut()
                            .addClass("hidden");
                        $("#reinstate")
                            .prop("disabled", false)
                            .removeClass("disabled");
                        setup.followMenuResize();
                        $("#edit-writer-record")
                            .show()
                            .prop("disabled", false)
                            .removeClass("disabled");
                        $("#save-writer-record,#cancel-writer-record").hide();
                        return false;
                    });

                    $("#maxClaimedArticles_RadioBtns").change(function() {
                        var selectedValue = $(
                            "input[name='overrideArticleClaimed']:checked"
                        ).val();

                        if (selectedValue === "false") {
                            $("#maxClaimedArticles").attr(
                                "disabled",
                                "disabled"
                            );
                            $("#maxClaimedArticles").val("");
                        }

                        if (selectedValue === "true") {
                            $("#maxClaimedArticles").removeAttr("disabled");
                        }
                    });

                    if (
                        $(
                            "input[name='overrideArticleClaimed']:checked"
                        ).val() === "false"
                    ) {
                        $("#maxClaimedArticles").attr("disabled", "disabled");
                    }

                    body.on("click", "button.contribReport", function() {
                        if ($(this).hasClass("earnings")) {
                            writers.loadEarningsReport();
                        } else {
                            writers.loadContentResultsReport();
                        }
                    });

                    call.ajax(options);

                    if ($(".errorMessage").length > 0) {
                        $("#edit-writer-record").click();
                        $("#formSubmit select").chosen();
                    }

                    if ($("#plagiarism-module").length > 0) {
                        var plagiarismCount = $("input#plagiarismCount").val();
                        if (plagiarismCount > 0) {
                            $("#plagiarism-module")
                                .addClass("contentFound")
                                .find(".moduleControlsBox .toggle")
                                .show();
                            $("#plagiarism-module .plagiarismNone").hide();
                            $("#plagiarism-module .plagiarismMatch").show();
                        } else {
                            $("#plagiarism-module .plagiarismMatch").hide();
                            $("#plagiarism-module .plagiarismNone").show();
                        }
                    }
                    $("#edit-buttons button.action").click(function() {
                        var urlAction =
                            "remove" ===
                            $(this)
                                .attr("id")
                                .toLowerCase()
                                ? "removeAuthorFromProgramAjax.action"
                                : "reinstateAuthorToProgramAjax.action";
                        return call.overlay(
                            urlAction,
                            {
                                id: $("#programAccountId").val()
                            },
                            650,
                            null
                        );
                    });

                    $("#additionalPaymentLink").click(function() {
                        var a = $(this),
                            paId = parseInt(
                                a.attr("data-programAccountId"),
                                10
                            ),
                            urlAction = "makeAdditionalPaymentAjax.action";
                        return call.overlay(
                            urlAction,
                            {
                                programAccountId: paId
                            },
                            850,
                            null
                        );
                    });

                    // makeAdditionalPayments.jspf
                    body.on(
                        "keyup",
                        "textarea.imposeMaxLength",
                        null,
                        skyword.imposeMaximumLength
                    );
                });
            }
        ])
        .controller("viewAuthorAjax", ["$scope", function($scope) {}])
        .controller("removeAuthorFromProgramAjax", [
            "$scope",
            function($scope) {}
        ])
        .controller("removeAgencyAuthorFromProgramAjax", [
            "$scope",
            function($scope) {}
        ])
        .controller("adminRemoveAgencyAuthorFromProgramAjax", [
            "$scope",
            function($scope) {}
        ])
        .controller("reinstateAuthorToProgramAjax", [
            "$scope",
            function($scope) {}
        ])
        .controller("reinstateAgencyAuthorToProgramAjax", [
            "$scope",
            function($scope) {}
        ])
        .controller("adminReinstateAgencyAuthorToProgramAjax", [
            "$scope",
            function($scope) {}
        ])
        .controller("additionalPayment", ["$scope", function($scope) {}])
        .controller("saveCommentAccountAjax", ["$scope", function($scope) {}])
        .controller("loadContributorNotesAjax", ["$scope", function($scope) {}])
        .controller("saveContributorNoteAjax", ["$scope", function($scope) {}])
        .controller("removeContributorNoteAjax", [
            "$scope",
            function($scope) {}
        ])
        .controller("loadContributorQualityAjax", [
            "$scope",
            function($scope) {}
        ])
        .controller("makeAdditionalPaymentAjax", [
            "$scope",
            function($scope) {}
        ]);
})();

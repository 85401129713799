app.service("autoComplete", [
    "skyword",
    function(skyword) {
        var autoComplete = {
                taxonomyId: 0,
                contentRequestId: $("#contentRequestId").val()
            },
            split = function(val) {
                return val.split(/,\s*/);
            },
            extractLast = function(term) {
                return split(term).pop();
            },
            setupAutoComplete = function() {
                try {
                    $(".skywordAuto")
                        .bind(
                            "keydown",

                            function(event) {
                                // don't navigate away from the field on tab when selecting an item
                                var id = "#" + this.id,
                                    auto = $(id),
                                    tax = auto.siblings(".taxonomyId");
                                if (0 === auto.length) {
                                    auto = $(document.getElementById(this.id));
                                    tax = auto.siblings(".taxonomyId");
                                }
                                    autoComplete.taxonomyId = tax.val();
                                console.log(tax.val());
                                // The first conditional allows tab and enter to work to select the item in the dropdown instead
                                // of changing focus or submitting the form, respectively.
                                if (
                                    (event.keyCode === $.ui.keyCode.TAB &&
                                        $(this).data("autocomplete").menu
                                            .active) ||
                                    ($(id).hasClass("single") &&
                                        -1 < this.value.indexOf(","))
                                ) {
                                    // This conditional only allows input of delete or backspace in the instance of single item, so that a
                                    // value can be cleared out and replaced with another, but a second cannot be added.
                                    if (
                                        event.keyCode !==
                                            $.ui.keyCode.BACKSPACE &&
                                        event.keyCode !== $.ui.keyCode.DELETE
                                    ) {
                                        event.preventDefault();
                                    }
                                }
                            }
                        )
                        .autocomplete({
                            source: function(request, response) {
                                if ("" !== autoComplete.taxonomyId) {
                                    $.getJSON(
                                        "/taxonomyAutoCompleteAjax.action",
                                        {
                                            term: extractLast(request.term),
                                            taxonomyId: autoComplete.taxonomyId,
                                            contentRequestId:
                                                autoComplete.contentRequestId
                                        },
                                        response
                                    );
                                }
                            },
                            search: function() {
                                // custom minLength
                                var term = extractLast(this.value);
                                if (term.length < 2) {
                                    return false;
                                }
                            },
                            focus: function() {
                                // prevent value inserted on focus
                                return false;
                            },
                            select: function(event, ui) {
                                    var terms = split(this.value);
                                    // remove the current input
                                    terms.pop();
                                    // add the selected item
                                    terms.push(ui.item.value);
                                    // add placeholder to get the comma-and-space at the end
                                    terms.push("");

                                    this.value = terms.join(", ");
                                return false;
                            }
                        });
                } catch (e) {}
            };

        return {
            init: function() {
                //onReady
                $(function() {
                    setupAutoComplete();
                });
            }
        };
    }
]);

app.service("creativeBrief", [
    "$http",
    function($http) {
        return {
            get: function(contentRequestId, callback) {
                $http({
                    url: "/API/creativeBrief/" + contentRequestId,
                    method: "GET"
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {});
            },
            saveCreativeBriefSample: function(sample, callback) {
                $http({
                    url: "/API/creativeBrief/saveCreativeBriefSample/",
                    method: "POST",
                    data: sample,
                    cache: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            removeCreativeBriefSample: function(sampleId) {
                $http({
                    url:
                        "/API/creativeBrief/removeCreativeBriefSample/" +
                        sampleId,
                    method: "POST",
                    cache: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(
                            "Problem removing Creative Brief Sample Id : " +
                                sampleId
                        );
                    });
            },
            save: function(brief, callback) {
                $http({
                    url: "/API/creativeBrief/save/" + brief.contentRequestId,
                    method: "POST",
                    data: brief,
                    cache: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            remove: function(brief, callback) {
                $http({
                    url: "/API/creativeBrief/remove/" + brief.contentRequestId,
                    method: "DELETE",
                    cache: false,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            scrape: function(contentRequestId, url, callback) {
                $http({
                    url: "/API/creativeBrief/scrape/" + contentRequestId,
                    method: "POST",
                    data: { sampleUrl: url },
                    cache: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            }
        };
    }
]);

(function() {
    app.controller("publish", [
        "$scope",
        "publish",
        "skyword",
        "$rootScope",
        "$timeout",
        "$compile",
        "TinymceService",
        "$http",
        "overlay",
        function(
            $scope,
            publish,
            skyword,
            $rootScope,
            $timeout,
            $compile,
            TinymceService,
            $http,
            overlay
        ) {
            // Init plain text form elements within a container
            $timeout(function() {
                var tinyMceContainer = $(".tinyMceContainer");
                TinymceService.compile(tinyMceContainer, $scope);
            }, 2000);

            publish.init();
            $scope.videos = [];
            $scope.$on("addVideo", function(e, video) {
                $scope.videos.push(video);
            });
            $scope.$on("removeVideo", function(e, video) {
                $scope.videos.splice(
                    skyword.getArrayIndexByProperty(
                        $scope.videos,
                        "id",
                        video.id
                    ),
                    1
                );
            });

            $(function() {
                function tagCharLength(div) {
                    if (!div) {
                        return;
                    }
                    var counter = div.siblings(".remainingTagLength");
                    var counterSpan = counter.find("span");
                    var maxlength = div.attr("maxlength");
                    var remainingText = maxlength - div.val().length;

                    if (remainingText <= 10 || remainingText < 0) {
                        $(counter).css("color", "#eb5338");
                    } else {
                        $(counter).css("color", "#999999");
                    }

                    return counterSpan.text(remainingText);
                }

                $("input.tagLength").each(function() {
                    tagCharLength($(this));
                });

                $("input.tagLength").on(
                    "input keyup keydown focus",
                    function() {
                        tagCharLength($(this));
                    }
                );
                var contentRequestId = jQuery("#attrs-id").data(
                    "contentrequestid"
                );
                $("#createContentWriterCancelAjax").click(
                    function createContentWriterCancelAjax() {
                        var p = $http.get(
                            "/createContentWriterCancelAjax.action?contentRequestId=" +
                                contentRequestId
                        );
                        p.then(function createContentWriterCancelAjaxThen(
                            response
                        ) {
                            jQuery.colorbox({
                                html: response.data,
                                width: 600
                            });
                            overlay.setupColorbox();
                        });
                    }
                );
            });

            skyword.loginPing(300000, false);
            skyword.contentLock(120000); // In this case articleReview.init() is not invoked so must add this here
            $scope.previousDataCte = "";

            $(document).on('focus', '.articleElement select', function() {
                var previousValue = $(this).val();
                $scope.previousDataCte = $(this).closest('.articleElement').attr('data-cte');
            });

            function hideContentTemplateElements() {
                if($scope.previousDataCte) {
                    var matchingElements = $('.childOf_' + $scope.previousDataCte);
                    matchingElements.each(function() {
                        $(this).css('display', 'none');
                    });
                }
            }
            $(document).on('change', '.articleElement select', function() {
                // hide all child elements
                hideContentTemplateElements();
                var selectedElementId = $(this).val();
                var parentDataCteValue = $(this).closest('.articleElement').attr('data-cte');
                if(selectedElementId) {
                    urlAction = "/taxonomyDisplayValuesAjax.action", getPost = "GET", options;
                    var options = {
                        cache: false,
                        type: getPost, // Corrected to uppercase string
                        data: {parentContentTemplateElementId: parentDataCteValue, taxonomyValueId: selectedElementId},
                        url: urlAction,
                        success: function(data) {
                            data = JSON.parse(data);
                            data.forEach(item => {
                                var cteId = item.contentTemplateElementId;
                                $('div[data-cteid="' + cteId + '"]').css('display', 'block');
                            });
                        },
                        error: function(error) {
                            console.log("error:::", error)
                        }
                    };
                    $.ajax(options); // Corrected to pass `options` directly
                }
            });
        }
    ])
        .controller("publishAjax", ["$scope", function($scope) {}])
        .controller("mgrPublishAjax", ["$scope", function($scope) {}])
        .controller("mgrPublish", [
            "$scope",
            "publish",
            "skyword",
            "articleReview",
            "$timeout",
            "$compile",
            "TinymceService",
            function(
                $scope,
                publish,
                skyword,
                articleReview,
                $timeout,
                $compile,
                TinymceService
            ) {
                // Init plain text form elements within a container
                $timeout(function() {
                    var tinyMceContainer = $(".tinyMceContainer");
                    TinymceService.compile(tinyMceContainer, $scope);
                }, 2000);

                articleReview.init();
                publish.init();
                $scope.videos = [];
                $scope.$on("addVideo", function(e, video) {
                    $scope.videos.push(video);
                });
                $scope.$on("removeVideo", function(e, video) {
                    $scope.videos.splice(
                        skyword.getArrayIndexByProperty(
                            $scope.videos,
                            "id",
                            video.id
                        ),
                        1
                    );
                });

                $(function() {
                    function tagCharLength(div) {
                        if (!div) {
                            return;
                        }
                        var counter = div.siblings(".remainingTagLength");
                        var counterSpan = counter.find("span");
                        var maxlength = div.attr("maxlength");
                        var remainingText = maxlength - div.val().length;

                        if (remainingText <= 10 || remainingText < 0) {
                            $(counter).css("color", "#eb5338");
                        } else {
                            $(counter).css("color", "#999999");
                        }

                        return counterSpan.text(remainingText);
                    }

                    $("input.tagLength").each(function() {
                        tagCharLength($(this));
                    });

                    $("input.tagLength").on(
                        "input keyup keydown focus",
                        function() {
                            tagCharLength($(this));
                        }
                    );
                });
                skyword.loginPing(300000, false);

                $scope.previousDataCte = "";

                 $(document).on('focus', '.articleElement select', function() {
                     var previousValue = $(this).val();
                     $scope.previousDataCte = $(this).closest('.articleElement').attr('data-cte');

                 });

                 function hideContentTemplateElements() {
                     if($scope.previousDataCte) {
                         var matchingElements = $('.childOf_' + $scope.previousDataCte);
                         matchingElements.each(function() {
                             $(this).css('display', 'none');
                         });
                     }
                 }
                 $(document).on('change', '.articleElement select', function() {
                     // hide all child elements
                     hideContentTemplateElements();
                     var selectedElementId = $(this).val();
                     var parentDataCteValue = $(this).closest('.articleElement').attr('data-cte');
                     if(selectedElementId) {
                         urlAction = "/taxonomyDisplayValuesAjax.action", getPost = "GET", options;
                         var options = {
                             cache: false,
                             type: getPost, // Corrected to uppercase string
                             data: {parentContentTemplateElementId: parentDataCteValue, taxonomyValueId: selectedElementId},
                             url: urlAction,
                             success: function(data) {
                                 data = JSON.parse(data);
                                 data.forEach(item => {
                                     var cteId = item.contentTemplateElementId;
                                     $('div[data-cteid="' + cteId + '"]').css('display', 'block');
                                 });
                             },
                             error: function(error) {
                                 console.log("error:::", error)
                             }
                         };
                         $.ajax(options); // Corrected to pass `options` directly
                     }

                });
            }
        ])
        .controller("previewAjax", ["$scope", function($scope) {}])
        .controller("managerPreviewAjax", ["$scope", function($scope) {}])
        .controller("updateProjectAjax", ["$scope", function($scope) {}])
        .controller("updateCalendarEventAjax", ["$scope", function($scope) {}])
        .controller("updatePublishDateAjax", ["$scope", function($scope) {}])
        .controller("updateSkipSeoAjax", ["$scope", function($scope) {}])
        .controller("updateDisplayOrderCTEAjax", [
            "$scope",
            function($scope) {}
        ])
        .controller("loadContainerElementsAjax", [
            "$scope",
            function($scope) {}
        ])
        .controller("loadContainerElementsRevisionAjax", [
            "$scope",
            function($scope) {}
        ])
        .controller("loadContainerDataAjax", ["$scope", function($scope) {}])
        .controller("loadContainerElementAjax", ["$scope", function($scope) {}])
        .controller("loadElementAjax", ["$scope", function($scope) {}])
        .controller("saveElementDataAjax", ["$scope", function($scope) {}])
        .controller("removeConditionalElementsAjax", [
            "$scope",
            function($scope) {}
        ])
        .controller("saveWriterCommentAjax", ["$scope", function($scope) {}])
        .controller("saveDraftAjax", ["$scope", function($scope) {}])
        .controller("autoSaveAjax", ["$scope", function($scope) {}])
        .controller("clearAutoSaveAjax", ["$scope", function($scope) {}])
        .controller("externalPing", ["$scope", function($scope) {}])
        .controller("editArticle", [
            "$scope",
            "skyword",
            "publish",
            "articleReview",
            "setup",
            "$http",
            "overlay",
            "$timeout",
            "$compile",
            "TinymceService",
            function(
                $scope,
                skyword,
                publish,
                articleReview,
                setup,
                $http,
                overlay,
                $timeout,
                $compile,
                TinymceService
            ) {
                app.value("ranOnce", false);

                app.value("searchResultType", $scope.searchResultType);
                app.value("photoLibraryUrl", $scope.photoLibraryUrl);
                var spellcheckLang = $scope.spellcheckLang;

                // Init plain text form elements within a container
                $timeout(function() {
                    var tinyMceContainer = $(".tinyMceContainer");
                    TinymceService.compile(tinyMceContainer, $scope);
                }, 2000);

                publish.init();
                $scope.videos = [];
                $scope.$on("addVideo", function(e, video) {
                    $scope.videos.push(video);
                });
                $scope.$on("removeVideo", function(e, video) {
                    $scope.videos.splice(
                        skyword.getArrayIndexByProperty(
                            $scope.videos,
                            "id",
                            video.id
                        ),
                        1
                    );
                });
                articleReview.init();
                //onReady
                $(function() {
                    function tagCharLength(div) {
                        if (!div) {
                            return;
                        }
                        var counter = div.siblings(".remainingTagLength");
                        var counterSpan = counter.find("span");
                        var maxlength = div.attr("maxlength");
                        var remainingText = maxlength - div.val().length;

                        if (remainingText <= 10 || remainingText < 0) {
                            $(counter).css("color", "#eb5338");
                        } else {
                            $(counter).css("color", "#999999");
                        }

                        return counterSpan.text(remainingText);
                    }

                    $("input.tagLength").each(function() {
                        tagCharLength($(this));
                    });

                    $("input.tagLength").on(
                        "input keyup keydown focus",
                        function() {
                            tagCharLength($(this));
                        }
                    );
                    var contentRequestId = jQuery("#attrs-id").data(
                        "contentrequestid"
                    );
                    $("#editContentCancelAjax").click(
                        function editContentCancelAjax() {
                            var p = $http.get(
                                "/editContentCancelAjax.action?contentRequestId=" +
                                    contentRequestId
                            );
                            p.then(function editContentCancelAjaxThen(
                                response
                            ) {
                                jQuery.colorbox({
                                    html: response.data,
                                    width: 600
                                });
                                overlay.setupColorbox();
                            });
                        }
                    );
                });
                // CT-378 - Disable spell check click until page is fully loaded.
                var windowLoaded = false;
                $(window).bind("load", function() {
                    setTimeout(function() {
                        windowLoaded = true;
                    }, 1000);
                });
                $("#article-suggestions a").on("click", function(e) {
                    if (windowLoaded) {
                        return true;
                    } else {
                        e.stopPropagation();
                    }
                });
                // ping the server every 5 minutes so the user is not logged out
                skyword.loginPing(300000, true);

               $scope.previousDataCte = "";

                $(document).on('focus', '.articleElement select', function() {
                    var previousValue = $(this).val();
                    $scope.previousDataCte = $(this).closest('.articleElement').attr('data-cte');

                });

                function hideContentTemplateElements() {
                    if($scope.previousDataCte) {
                        var matchingElements = $('.childOf_' + $scope.previousDataCte);
                        matchingElements.each(function() {
                            $(this).css('display', 'none');

                        });
                    }
                }
                $(document).on('change', '.articleElement select', function() {
                    // hide all child elements
                    hideContentTemplateElements();
                    var selectedElementId = $(this).val();
                    var parentDataCteValue = $(this).closest('.articleElement').attr('data-cte');
                    if(selectedElementId) {
                        urlAction = "/taxonomyDisplayValuesAjax.action", getPost = "GET", options;
                        var options = {
                            cache: false,
                            type: getPost, // Corrected to uppercase string
                            data: {parentContentTemplateElementId: parentDataCteValue, taxonomyValueId: selectedElementId},
                            url: urlAction,
                            success: function(data) {
                                data = JSON.parse(data);
                                data.forEach(item => {
                                    console.log('contentTemplateElementId:', item.contentTemplateElementId);
                                    var cteId = item.contentTemplateElementId;
                                    $('div[data-cteid="' + cteId + '"]').css('display', 'block');
                                });
                            },
                            error: function(error) {
                                console.log("error:::", error)
                            }
                        };
                        $.ajax(options); // Corrected to pass `options` directly
                    }

                });
            }
        ])
        .controller("editDigitalAsset", [
            "$scope",
            "skyword",
            "publish",
            "articleReview",
            "setup",
            "$http",
            "overlay",
            "TinymceService",
            function(
                $scope,
                skyword,
                publish,
                articleReview,
                setup,
                $http,
                overlay,
                TinymceService
            ) {
                app.value("ranOnce", false);
                app.value("searchResultType", $scope.searchResultType);
                app.value("photoLibraryUrl", $scope.photoLibraryUrl);
                var spellcheckLang = $scope.spellcheckLang;

                publish.init();
                $scope.videos = [];
                $scope.$on("addVideo", function scopeOnAddVideo(e, video) {
                    $scope.videos.push(video);
                });
                $scope.$on("removeVideo", function scopeOnRemoveVideo(
                    e,
                    video
                ) {
                    $scope.videos.splice(
                        skyword.getArrayIndexByProperty(
                            $scope.videos,
                            "id",
                            video.id
                        ),
                        1
                    );
                });
                articleReview.init();

                //onReady
                $(function() {
                    var contentRequestId = jQuery("#attrs-id").data(
                        "contentrequestid"
                    );
                    $("#editContentCancelAjax").click(
                        function editContentCancelAjax() {
                            var p = $http.get(
                                "/editDigitalAssetCancelAjax.action?contentRequestId=" +
                                    contentRequestId
                            );
                            p.then(function(response) {
                                jQuery.colorbox({
                                    html: response.data,
                                    width: 600
                                });
                                overlay.setupColorbox();
                            });
                        }
                    );

                    var tinyMceContainer = $(".tinyMceContainer");
                    TinymceService.compile(tinyMceContainer, $scope);
                });
                // ping the server every 5 minutes so the user is not logged out
                skyword.loginPing(300000, true);
            }
        ])
        .controller("managerPublish", [
            "$scope",
            "publish",
            "setup",
            "articleReview",
            "skyword",
            "$rootScope",
            "$timeout",
            "$compile",
            "TinymceService",
            "$http",
            "overlay",
            function(
                $scope,
                publish,
                setup,
                articleReview,
                skyword,
                $rootScope,
                $timeout,
                $compile,
                TinymceService,
                $http,
                overlay
            ) {
                // Init plain text form elements within a container
                $timeout(function() {
                    var tinyMceContainer = $(".tinyMceContainer");
                    TinymceService.compile(tinyMceContainer, $scope);
                }, 2000);

                $scope.showEmbeddedUpload = false;
                publish.init();
                $scope.videos = [];
                $scope.$on("addVideo", function(e, video) {
                    $scope.videos.push(video);
                });
                $scope.$on("removeVideo", function(e, video) {
                    $scope.videos.splice(
                        skyword.getArrayIndexByProperty(
                            $scope.videos,
                            "id",
                            video.id
                        ),
                        1
                    );
                });
                // if ($scope.dateAndTimePickerId) {
                //     setup.dateAndTimePicker($scope.dateAndTimePickerId);
                // }
                articleReview.init();

                $(function() {
                    function tagCharLength(div) {
                        if (!div) {
                            return;
                        }
                        var counter = div.siblings(".remainingTagLength");
                        var counterSpan = counter.find("span");
                        var maxlength = div.attr("maxlength");
                        var remainingText = maxlength - div.val().length;

                        if (remainingText <= 10 || remainingText < 0) {
                            $(counter).css("color", "#eb5338");
                        } else {
                            $(counter).css("color", "#999999");
                        }

                        return counterSpan.text(remainingText);
                    }

                    $("input.tagLength").each(function() {
                        tagCharLength($(this));
                    });

                    $("input.tagLength").on(
                        "input keyup keydown focus",
                        function() {
                            tagCharLength($(this));
                        }
                    );

                    var contentRequestId = jQuery("#attrs-id").data(
                        "contentrequestid"
                    );
                    $("#createContentCancelAjax").click(
                        function createContentCancelAjax() {
                            var p = $http.get(
                                "/createContentCancelAjax.action?contentRequestId=" +
                                    contentRequestId
                            );
                            p.then(function createContentCancelAjaxThen(
                                response
                            ) {
                                jQuery.colorbox({
                                    html: response.data,
                                    width: 600
                                });
                                overlay.setupColorbox();
                            });
                        }
                    );
                });

                skyword.loginPing(300000, false);
                $scope.previousDataCte = "";

                $(document).on('focus', '.articleElement select', function() {
                    var previousValue = $(this).val();
                    $scope.previousDataCte = $(this).closest('.articleElement').attr('data-cte');

                });

                function hideContentTemplateElements() {
                    if($scope.previousDataCte) {
                        var matchingElements = $('.childOf_' + $scope.previousDataCte);
                        matchingElements.each(function() {
                            $(this).css('display', 'none');

                        });
                    }
                }
                $(document).on('change', '.articleElement select', function() {
                    // hide all child elements
                    hideContentTemplateElements();
                    var selectedElementId = $(this).val();
                    var parentDataCteValue = $(this).closest('.articleElement').attr('data-cte');
                    if(selectedElementId) {
                        urlAction = "/taxonomyDisplayValuesAjax.action", getPost = "GET", options;
                        var options = {
                            cache: false,
                            type: getPost, // Corrected to uppercase string
                            data: {parentContentTemplateElementId: parentDataCteValue, taxonomyValueId: selectedElementId},
                            url: urlAction,
                            success: function(data) {
                                data = JSON.parse(data);
                                data.forEach(item => {
                                    console.log('contentTemplateElementId:', item.contentTemplateElementId);
                                    var cteId = item.contentTemplateElementId;
                                    $('div[data-cteid="' + cteId + '"]').css('display', 'block');
                                });
                            },
                            error: function(error) {
                                console.log("error:::", error)
                            }
                        };
                        $.ajax(options); // Corrected to pass `options` directly
                    }

                });
            }
        ])
        .controller("managerPublishAjax", ["$scope", function($scope) {}])
        .controller("digitalAssetPublish", [
            "$scope",
            "publish",
            "setup",
            "articleReview",
            "skyword",
            "TinymceService",
            function(
                $scope,
                publish,
                setup,
                articleReview,
                skyword,
                TinymceService
            ) {
                publish.init();
                $scope.videos = [];
                $scope.$on("addVideo", function(e, video) {
                    $scope.videos.push(video);
                });
                $scope.$on("removeVideo", function(e, video) {
                    $scope.videos.splice(
                        skyword.getArrayIndexByProperty(
                            $scope.videos,
                            "id",
                            video.id
                        ),
                        1
                    );
                });
                if ($scope.dateAndTimePickerId) {
                    setup.dateAndTimePicker($scope.dateAndTimePickerId);
                }
                articleReview.init(); // Handles content lock functionality
                skyword.loginPing(300000, false);

                var tinyMceContainer = $(".tinyMceContainer");
                TinymceService.compile(tinyMceContainer, $scope);
            }
        ])
        .controller("digitalAssetPublishAjax", ["$scope", function($scope) {}])
        .controller("seoScore", ["$scope", function($scope) {}])
        .controller("getSEORequirementsForKeyword", [
            "$scope",
            function($scope) {}
        ])
        .controller("articleRequirementsScore", ["$scope", function($scope) {}])
        .controller("contentStandardsChecklist", [
            "$scope",
            function($scope) {}
        ])
        .controller("uploadFileAjax", ["$scope", function($scope) {}])
        .controller("uploadEmbededAttachmentAjax", [
            "$scope",
            function($scope) {}
        ])
        .controller("discardEmbeddedImageAjax", ["$scope", function($scope) {}])
        .controller("uploadEmbeddedImageAjax", ["$scope", function($scope) {}])
        .controller("setupCropAttachmentAjax", ["$scope", function($scope) {}])
        .controller("executeImageCropAjax", ["$scope", function($scope) {}])
        .controller("embeddedImageProcessAjax", ["$scope", function($scope) {}])
        .controller("viewAttachment", ["$scope", function($scope) {}])
        .controller("viewThumbnail", ["$scope", function($scope) {}])
        .controller("removeElementAjax", ["$scope", function($scope) {}])
        .controller("removeAttachmentAjax", ["$scope", function($scope) {}])
        .controller("taxonomyFilterValueAjax", ["$scope", function($scope) {}])
        .controller("taxonomyAutoCompleteAjax", ["$scope", function($scope) {}])
        .controller("autoRecoveryAjax", ["$scope", function($scope) {}])
        .controller("plagiarismDetection", [
            "$scope",
            function($scope) {
                //onReady
                $(function() {
                    var plagiarismCount = $("input#plagiarismCount").val();
                    if (plagiarismCount > 0) {
                        $("#plagiarism-module")
                            .addClass("contentFound")
                            .find(".moduleControlsBox .toggle")
                            .show();
                        $("#plagiarism-module .plagiarismNone").hide();
                        $("#plagiarism-module .plagiarismMatch").show();
                    } else {
                        $("#plagiarism-module .plagiarismMatch").hide();
                        $("#plagiarism-module .plagiarismNone").show();
                    }
                });
            }
        ])
        .controller("confirmArticleSubmitAjax", ["$scope", function($scope) {}])
        .controller("recordManagerEditAjax", ["$scope", function($scope) {}])
        .controller("stockPhotoSetupAjax", ["$scope", function($scope) {}])
        .controller("stockPhotoAjax", ["$scope", function($scope) {}])
        .controller("stockPhotoSelectAjax", ["$scope", function($scope) {}])
        .controller("editStockPhotoSelectFileNameAjax", [
            "$scope",
            function($scope) {}
        ])
        .controller("stockPhotoPreviewAjax", ["$scope", function($scope) {}])
        .controller("elementErrorAjax", ["$scope", function($scope) {}])
        .controller("articleScoreAjax", ["$scope", function($scope) {}])
        .controller("loadTemplateAjax", ["$scope", function($scope) {}])
        .controller("compareRevisions", [
            "$scope",
            "publish",
            "revision",
            function($scope, publish, revision) {
                revision.init();
                // publish.init();
            }
        ])
        .controller("compareWriterRevisions", [
            "$scope",
            "publish",
            "revision",
            function($scope, publish, revision) {
                revision.init();
                publish.init();
            }
        ])
        .controller("contentAnnotationsAjax", ["$scope", function($scope) {}])
        .controller("saveAnnotationAjax", ["$scope", function($scope) {}])
        .controller("editAnnotationAjax", ["$scope", function($scope) {}])
        .controller("removeAnnotationAjax", ["$scope", function($scope) {}])
        .controller("removeAllAnnotationsAjax", ["$scope", function($scope) {}])
        .controller("resolveAnnotationAjax", ["$scope", function($scope) {}])
        .controller("reopenAnnotationAjax", ["$scope", function($scope) {}]);
})();

(function() {
    app.controller("viewIdeaContributor", [
        "$scope",
        "$translate",
        "$http",
        "$filter",
        "$timeout",
        "ngTableParams",
        "skyword",
        "idea",
        "taxonomy",
        function(
            $scope,
            $translate,
            $http,
            $filter,
            $timeout,
            ngTableParams,
            skyword,
            idea,
            taxonomy
        ) {
            console.log("IN IDEA VIEW CONTROLLER Contributor");
            var attributes = jQuery("#attrs-id");
            // set content request id on scope
            $scope.contentRequestId = attributes.data("contentrequestid");
            $scope.programId = attributes.data("programid");
            $scope.accountTypeId = attributes.data("accounttypeid");
            $scope.referrerPage = skyword.getQstringParamByName("referrerPage");
            $scope.history = [];
            $scope.activeSidePanel = "history";

            $scope.viewIdea = function() {
                idea.getIdea(
                    $scope.programId,
                    $scope.contentRequestId,
                    function(data) {
                        $scope.contentRequest = data;
                    }
                );
            };

            $scope.getSelectedTaxonomyValues = function(dto, index) {
                if (!dto.contentTemplateElementDataId && !dto.taxonomyId) {
                    return;
                }
                var taxonomyPromise = taxonomy.getSelectedTaxonomyValues(
                    dto.taxonomyId,
                    dto.contentTemplateElementDataId
                );
                // need to update this to return data. assign outside of this method
                taxonomyPromise.then(function(result) {
                    $scope.contentRequest.contentElementList[index].selected =
                        result.data;
                });
            };

            $scope.fetchHistory = function() {
                var historyPromise = idea.getHistory(
                    $scope.programId,
                    $scope.contentRequestId
                );
                historyPromise.then(function(result) {
                    if ($scope.history.length > 0) {
                        $scope.history = null;
                        $scope.history = result.data;
                    } else {
                        $scope.history = result.data;
                    }
                });
            };
        }
    ]);
})();

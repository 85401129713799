app.controller("addContentSample", [
    "$scope",
    "$translate",
    "skyword",
    "FileUploader",
    "SkyFactory",
    "AssetFactory",
    "UUIDFactory",
    "digitalAsset",
    "contentSampleAdd",
    "contentSearch",
    function(
        $scope,
        $translate,
        skyword,
        FileUploader,
        SkyFactory,
        AssetFactory,
        UUIDFactory,
        digitalAsset,
        contentSampleAdd,
        contentSearch
    ) {
        contentSearch.init();

        $scope.maxNumFiles = 100;
        $scope.maxFileMB = 30;
        $scope.okExtensions = [".png", ".jpg", ".jpeg", ".jpe", ".gif", ".pdf", ".mp3", ".wav", ".webp", ".doc", ".docx", ".mp4", ".avi", ".flv", ".mpe", ".mpeg", ".mov", ".mpg"];

        $scope.texts = {
            photo: {},
            design: {},
            written: {}
        };

        $scope.rejectedFiles = [];
        $scope.uploadDone = false;

        $scope.formData = {
            key: "",
            policy: "",
            signature: "",
            acl: "private",
            AWSAccessKeyId: "",
            "Content-Type": ""
        };
        var uploader = ($scope.uploader = new FileUploader({
            formData: [],
            url: "",
            uuid: "",
            finalUrl: ""
        }));

        $scope.init = function() {
            $translate("angular.upload.title.page.uploadphoto").then(function(
                val
            ) {
                console.log('photo-page-title', val)
                $scope.texts["photo"].pageTitle = val;
            });
            $translate("angular.upload.description.page.uploadphoto").then(
                function(val) {
                     console.log('photo-page-description', val)
                    $scope.texts["photo"].pageDescription = val;
                }
            );
            $translate("angular.upload.title.section.uploadphoto").then(
                function(val) {
                     console.log('photo-page-section', val)
                    $scope.texts["photo"].sectionTitle = val;
                }
            );
            $translate("angular.upload.title.uploadrequirements").then(function(
                val
            ) {
                 console.log('photo-page-requirement', val)
                $scope.texts["photo"].requirementsTitle = val;
            });
            $translate("angular.upload.description.section.uploadphoto", {
                filesize: $scope.maxFileMB + "MB"
            }).then(function(val) {
                 console.log('photo-page-reqDescr', val)
                $scope.texts["photo"].requirementsDescription = val;
            });
            $translate("angular.upload.button.addphotos").then(function(val) {
                $scope.texts["photo"].uploadButtonText = val;
            });

            $translate("angular.upload.title.page.uploaddesign").then(function(
                val
            ) {
                 console.log('design-title', val)
                $scope.texts["design"].pageTitle = val;
            });
            $translate("angular.upload.description.page.uploaddesign").then(
                function(val) {
                    console.log('design-descrp', val)
                    $scope.texts["design"].pageDescription = val;
                }
            );
            $translate("angular.upload.title.section.uploaddesign").then(
                function(val) {
                    $scope.texts["design"].sectionTitle = val;
                }
            );
            $translate("angular.upload.title.uploadrequirements").then(function(
                val
            ) {
                $scope.texts["design"].requirementsTitle = val;
            });
            $translate("angular.upload.description.section.uploaddesign", {
                filesize: $scope.maxFileMB + "MB"
            }).then(function(val) {
                $scope.texts["design"].requirementsDescription = val;
            });
            $translate("angular.upload.button.adddesigns").then(function(val) {
                $scope.texts["design"].uploadButtonText = val;
            });

            if ($scope.actionName) {
                $scope.sampleType =
                    $scope.actionName.toLowerCase().indexOf("photo") >= 0
                        ? "photo"
                        : $scope.actionName.toLowerCase().indexOf("design") >= 0
                            ? "design"
                            : $scope.actionName.toLowerCase() ==
                              "addcontentsample"
                                ? "written"
                                : $scope.actionName.toLowerCase() ==
                                  "joinaddcontentsample"
                                    ? "written"
                                    : "";
                $scope.sampleTypeId =
                    $scope.sampleType == "photo"
                        ? 3
                        : $scope.sampleType == "design"
                            ? 4
                            : $scope.sampleType == "written"
                                ? 1
                                : 0;
            } else {
                console.error("actionName is undefined");
            }
            var awsPromise = contentSampleAdd.getAWS();
            awsPromise.then(function(aws) {
                AssetFactory.setAmazonWebService(aws.data);
                $scope.formData = {
                    key: aws.data.filename,
                    policy: aws.data.policy,
                    signature: aws.data.signature,
                    acl: "private",
                    AWSAccessKeyId: aws.data.awsAccessKey
                };
            });
        };

        $scope.isAlreadyRejected = function(item) {
            for (i = 0; i < $scope.rejectedFiles.length; i++) {
                if ($scope.rejectedFiles[i].file.name === item.file.name) {
                    return true;
                }
            }

            return false;
        };

        $scope.$watch("uploader.isUploading", function() {
            if (uploader.isUploading == true) {
                window.addEventListener(
                    "dragover",
                    function(e) {
                        e = e || event;
                        e.preventDefault();
                    },
                    false
                );
                window.addEventListener(
                    "drop",
                    function(e) {
                        e = e || event;
                        e.preventDefault();
                    },
                    false
                );
            }
        });

        $scope.removeFileFromQueue = function(item) {
            AssetFactory.removeUploadedImage(item.uuid);
        };

        $scope.manualUpload = function() {
            if ($scope.uploadDone == false || uploader.isUploading == true) {
                $("#manualUpload").click();
            }
        };

        $scope.manualUploadAndClear = function() {
            if ($scope.uploadDone == false || uploader.isUploading == true) {
                $scope.clearRejected();
                uploader.clearQueue();
                $("#manualUpload").click();
            }
        };

        $scope.clearRejected = function() {
            $scope.rejectedFiles = [];
            $scope.maxFileSizeExceeded = null;
        };

        function processImage(fileItem) {
            var fr = new FileReader();
            fr.onload = function() {
                var img = new Image();

                img.onload = function() {
                    if (fileItem._file.type == "image/jpeg") {
                        var bin = new FileReader();
                        bin.onloadend = function() {
                            parseExif(this.result, this.file.name, fileItem);
                            AssetFactory.addUploadedImages(
                                fileItem.key,
                                fileItem.uuid,
                                fileItem.file.name,
                                fileItem.file.size,
                                fileItem.file.type,
                                fileItem.finalUrl,
                                fileItem.exif,
                                $scope.sampleTypeId,
                                img.width,
                                img.height
                            );
                        };
                        bin.file = fileItem._file;
                        bin.readAsBinaryString(fileItem._file);
                    } else {
                        AssetFactory.addUploadedImages(
                            fileItem.key,
                            fileItem.uuid,
                            fileItem.file.name,
                            fileItem.file.size,
                            fileItem.file.type,
                            fileItem.finalUrl,
                            null,
                            $scope.sampleTypeId,
                            img.width,
                            img.height
                        );
                    }
                };

                img.src = fr.result;
            };

            fr.readAsDataURL(fileItem._file);
        }

        function strComp(a, b) {
            return a > b ? 1 : a == b ? 0 : -1;
        }

        function parseExif(data, filename, fileItem) {
            try {
                var jpeg = new JpegMeta.JpegFile(data, filename);
                var groups = new Array();
                var props;
                var group;
                var prop;
                var keywords = new Array();
                var keywordStr = "";
                var description = "";
                var title = "";

                for (group in jpeg.metaGroups) {
                    if (jpeg.metaGroups.hasOwnProperty(group)) {
                        groups.push(jpeg.metaGroups[group]);
                    }
                }

                groups.sort(function(a, b) {
                    if (a.description == "General") {
                        return -1;
                    } else if (b.description == "General") {
                        return 1;
                    } else {
                        return strComp(a.description, b.description);
                    }
                });

                for (var i = 0; i < groups.length; i++) {
                    group = groups[i];
                    props = new Array();
                    for (prop in group.metaProps) {
                        if (group.metaProps.hasOwnProperty(prop)) {
                            props.push(group.metaProps[prop]);
                        }
                    }

                    for (var j = 0; j < props.length; j++) {
                        prop = props[j];
                        if (prop.length > 1) {
                            for (var k = 0; k < prop.length; k++) {
                                if (prop[k].fieldName == "keywords") {
                                    // keywords.push(prop[k].value);
                                    if (keywordStr.length > 0) {
                                        keywordStr += ",";
                                    }
                                    keywordStr += prop[k].value;
                                }
                                if (prop[k].fieldName == "objectName") {
                                    if (
                                        prop[k].value &&
                                        prop[k].value !== "" &&
                                        title.length <= 0
                                    ) {
                                        title = prop[k].value;
                                    }
                                }
                                if (prop[k].fieldName == "ImageDescription") {
                                    if (
                                        prop[k].value &&
                                        prop[k].value !== "" &&
                                        description.length <= 0
                                    ) {
                                        description = prop[k].value;
                                    }
                                }
                            }
                        }
                        if (prop.fieldName == "ImageDescription") {
                            description = prop.value;
                        }
                        if (prop.fieldName == "keywords") {
                            keywordStr = prop.value;
                        }
                        if (prop.fieldName == "objectName") {
                            title = prop.value;
                        }
                    }
                }

                fileItem.exif = {
                    keywords: cleanSpecialChars(keywordStr),
                    title: cleanSpecialChars(title),
                    description: cleanSpecialChars(description)
                };
            } catch (err) {
                console.error(err);
            }
        }

        function cleanSpecialChars(str) {
            var cleanupRegEx = /["']|\r?\n|\r|[^a-zA-Z0-9 ,\-;:+=_)(}{\]\[@#$%&*~|><.?\/]/g;
            return str == null ? "" : str.replace(cleanupRegEx, "");
        }

        // UPLOADER METHODS
        uploader.onCompleteItem = function(
            fileItem,
            response,
            status,
            headers
        ) {
            //console.info('onCompleteItem',fileItem);
        };
        uploader.onCompleteAll = function() {
            //console.info('onCompleteAll');
            $scope.uploadDone = true;

            var contentSampleDto = {
                stateId: AssetFactory.getStateId(),
                uploadedAssetList: AssetFactory.getUploadedAssets()
            };

            var samplesPromise = contentSampleAdd.createContentSamples(
                contentSampleDto
            );
            samplesPromise.then(function(aws) {
                if ($scope.actionName.toLowerCase() == "joinaddcontentsample") {
                    $(".returnToSamples").click();
                } else {
                    window.location = "/myProfile.action";
                }
            });
        };

        uploader.onBeforeUploadItem = function(item) {
            //console.info('onBeforeUploadItem', item);
        };

        uploader.onAfterAddingAll = function(addedFileItems) {
            // console.info('onAfterAddingAll');
            if ($scope.sampleType == "written") {
                if ($scope.rejectedFiles.length == 0) {
                    uploader.uploadAll();
                } else {
                    console.info("there are rejected written sample files");
                }
            } else {
                setTimeout(function() {
                    skyword.scrollTo(".file-added-status.approved", 20);
                }, 200);
            }
        };

        uploader.onAfterAddingFile = function(fileItem) {
            //console.info('onAfterAddingFile');
            var uuid = UUIDFactory.optimized2();
            fileItem.cleanFileName = SkyFactory.cleanFilename(
                fileItem.file.name
            );
            $scope.formData.key =
                AssetFactory.getAmazonWebService().key +
                "" +
                uuid +
                "_" +
                fileItem.cleanFileName;

            var key = { key: $scope.formData.key };
            var policy = { policy: AssetFactory.getAmazonWebService().policy };
            var signature = {
                signature: AssetFactory.getAmazonWebService().signature
            };
            var AWSAccessKeyId = {
                AWSAccessKeyId: AssetFactory.getAmazonWebService().awsAccessKey
            };
            var acl = { acl: "private" };
            var content_type = { "Content-Type": fileItem.file.type };
            fileItem.uuid = uuid;
            fileItem.formData = [
                key,
                policy,
                signature,
                AWSAccessKeyId,
                acl,
                content_type
            ];
            fileItem.finalUrl =
                AssetFactory.getAmazonWebService().awsUploadUrl +
                $scope.formData.key;
            fileItem.key = $scope.formData.key;
            fileItem.url = AssetFactory.getAmazonWebService().awsUploadUrl;
            if (fileItem.file.type.indexOf("image") >= 0) {
                processImage(fileItem);
            } else {
                console.log('Selected FileItem is ',fileItem)
                //console.info('','not an image','calling AssetFactory.addUploadedImages');
                AssetFactory.addUploadedImages(
                    fileItem.key,
                    fileItem.uuid,
                    fileItem.file.name,
                    fileItem.file.size,
                    fileItem.file.type,
                    fileItem.finalUrl,
                    null,
                    $scope.sampleTypeId,
                    null,
                    null
                );
            }
        };

        // UPLOADER FILTERS
        uploader.filters.push({
            name: "countLimit",
            fn: function(item /*{File|FileLikeObject}*/, options) {
                var isOK =
                    this.queue.length < $scope.maxNumFiles;
                if (!isOK) {
                    var itemToPush = {
                        file: item,
                        reason:
                            $translate.instant(
                                "angular.dam.bulkupld.steps.upload.error.filecount"
                            ) +
                            " (" +
                            $scope.maxNumFiles +
                            ") " +
                            $translate.instant(
                                "angular.dam.bulkupld.steps.upload.error.exceeded"
                            )
                    };
                    if (!$scope.isAlreadyRejected(itemToPush)) {
                        $scope.rejectedFiles.push(itemToPush);
                    }
                }
                return isOK;
            }
        });

        uploader.filters.push({
            name: "maxFileSize",
            fn: function(item /*{File|FileLikeObject}*/, options) {
                var isOK =
                    item.size <
                    $scope.maxFileMB * 1024 * 1024;
                if (!isOK) {
                    $scope.maxFileSizeExceeded = true;
                    var displaySize = (item.size / 1024 / 1024).toFixed(2);
                    var itemToPush = {
                        file: item,
                        reason:
                            displaySize +
                            "MB - " +
                            $translate.instant(
                                "angular.dam.bulkupld.steps.upload.error.exceededmaxfilesize"
                            )
                    };
                    if (!$scope.isAlreadyRejected(itemToPush)) {
                        $scope.rejectedFiles.push(itemToPush);
                    }
                }
                return isOK;
            }
        });

        uploader.filters.push({
            name: "noDups",
            fn: function(item /*{File|FileLikeObject}*/, options) {
                for (var i = 0, len = this.queue.length; i < len; i++) {
                    if (this.queue[i].file.name === item.name) {
                        var itemToPush = {
                            file: item,
                            reason: $translate.instant(
                                "angular.dam.bulkupld.steps.upload.error.alreadyadded"
                            )
                        };
                        if (!$scope.isAlreadyRejected(itemToPush)) {
                            $scope.rejectedFiles.push(itemToPush);
                        }
                        return false;
                    }
                }
                return true;
            }
        });

        uploader.filters.push({
            name: "damFilter",
            fn: function(item /*#<{(|{File|FileLikeObject}|)}>#*/, options) {
                var reject = true;
                var allow = true;
                var filenameExt = item.name
                    .substring(item.name.lastIndexOf(".") + 1)
                    .toLowerCase();
                for (
                    var i = 0;
                    i < $scope.okExtensions.length;
                    i++
                ) {
                    console.log('Checking the extensions', filenameExt);
                    if (
                        $scope.okExtensions[i]
                            .substring(1)
                            .toLowerCase() === filenameExt
                    ) {
                        reject = false;
                        break;
                    }
                }
                if (true === reject) {
                    var itemToPush = {
                        file: item,
                        reason:
                            $translate.instant(
                                "angular.dam.bulkupld.steps.upload.error.restrictedfiletype"
                            ) +
                            " - ." +
                            filenameExt
                    };
                    if (!$scope.isAlreadyRejected(itemToPush)) {
                        $scope.rejectedFiles.push(itemToPush);
                    }
                    allow = false;
                }
                return allow;
            }
        });
    }
])

    .controller("addContentSamplePhoto", ["$scope", function($scope) {}])
    .controller("addContentSampleDesign", ["$scope", function($scope) {}]);

(function() {
    // 1.0 Taxonomy Service/Factory
    app.factory("taxonomy", [
        "$http",
        "$q",
        function($http, $q) {
            return {
                // 2.0 Returns the content types taxonomy
                getTaxonomy: function(contentTypeId) {
                    // 2.1 The $http API is based on the deferred/promise APIs exposed by the $q service. So it returns a promise for us by default
                    return $http
                        .get(
                            "/API/taxonomy/asset?contentTypeId=" + contentTypeId
                        )

                        .then(
                            function(response) {
                                // 2.1 On Success. We're getting back an object
                                if (typeof response.data === "object") {
                                    return response.data.id;
                                    // 2.2 On Invalid type. It's not an object
                                } else {
                                    // invalid response
                                    return $q.reject(response.data);
                                }
                            },
                            function(response) {
                                // 2.3 Something just straight up went wrong
                                return $q.reject(response.data);
                            }
                        );
                },
                // 3.0 Returns taxonomy values for a specific taxonomyId
                getTaxonomyValues: function(taxonomyId) {
                    // 2.1 The $http API is based on the deferred/promise APIs exposed by the $q service. So it returns a promise for us by default
                    return $http
                        .get("/API/taxonomy/" + taxonomyId + "/values")

                        .then(
                            function(response) {
                                // 2.1 On Success. We're getting back an object
                                if (typeof response.data === "object") {
                                    return response.data;
                                    // 2.2 On Invalid type. It's not an object
                                } else {
                                    // invalid response
                                    return $q.reject(response.data);
                                }
                            },
                            function(response) {
                                // 2.3 Something just straight up went wrong
                                return $q.reject(response.data);
                            }
                        );
                },
                // 4.0 Returns taxonomy values for a specific taxonomyId and contentTemplateElementDataId
                getSelectedTaxonomyValues: function(
                    taxonomyId,
                    contentTemplateElementDataId
                ) {
                    // 2.1 The $http API is based on the deferred/promise APIs exposed by the $q service. So it returns a promise for us by default
                    return $http
                        .get(
                            "/API/taxonomy/" +
                                taxonomyId +
                                "/data?contentTemplateElementDataId=" +
                                contentTemplateElementDataId
                        )

                        .then(
                            function(response) {
                                // 2.1 On Success. We're getting back an object
                                if (typeof response.data === "object") {
                                    return response.data;
                                    // 2.2 On Invalid type. It's not an object
                                } else {
                                    // invalid response
                                    return $q.reject(response.data);
                                }
                            },
                            function(response) {
                                // 2.3 Something just straight up went wrong
                                return $q.reject(response.data);
                            }
                        );
                },
                // 5.0 Returns a list of taxonomy options
                autoComplete: function(taxonomyId, searchTerm) {
                    // 3.1 The $http API is based on the deferred/promise APIs exposed by the $q service. So it returns a promise for us by default
                    return $http
                        .get(
                            "/API/taxonomy/" +
                                taxonomyId +
                                "/autocomplete?searchTerm=" +
                                searchTerm
                        )

                        .then(
                            function(response) {
                                // 3.1 On Success. We're getting back an object
                                if (typeof response.data === "object") {
                                    return response.data;
                                    // 3.2 On Invalid type. It's not an object
                                } else {
                                    // invalid response
                                    return $q.reject(response.data);
                                }
                            },
                            function(response) {
                                // 3.3 Something just straight up went wrong
                                return $q.reject(response.data);
                            }
                        );
                },
                // 6.0 Returns taxonomy values for a specific taxonomyId based on parentTaxonomyId
                getChildTaxonomyValues: function(
                    childTaxonomyId,
                    parentTaxonomyValueId
                ) {
                    // 2.1 The $http API is based on the deferred/promise APIs exposed by the $q service. So it returns a promise for us by default
                    return $http
                        .get(
                            "/API/taxonomy/" +
                                childTaxonomyId +
                                "/parentTaxonomyValueId/" +
                                parentTaxonomyValueId +
                                "/values"
                        )

                        .then(
                            function(response) {
                                // 2.1 On Success. We're getting back an object
                                if (typeof response.data === "object") {
                                    return response.data;
                                    // 2.2 On Invalid type. It's not an object
                                } else {
                                    // invalid response
                                    return $q.reject(response.data);
                                }
                            },
                            function(response) {
                                // 2.3 Something just straight up went wrong
                                return $q.reject(response.data);
                            }
                        );
                }
            };
        }
    ]);
})();

app.controller("authorDashboard", [
    "$scope",
    "author",
    "validation",
    "ContributorAgreementService",
    function($scope, author, validation, ContributorAgreementService) {
        author.init();
        $scope.openAgreementDialog = function () {
            ContributorAgreementService.contributorAgreementDialog();
        };
        $("html").on("keyup", "#formSubmit_writerComment", function() {
            if ($(this).val().length > 0) {
                validation.enableButton("releaseBtn");
            } else {
                validation.disableButton("releaseBtn");
            }
        });
    }
])
    .controller("viewProgram", [
        "$scope",
        "author",
        "skywordGraphs",
        "validation",
        "ContributorAgreementService",
        function($scope, author, skywordGraphs, validation, ContributorAgreementService) {
            author.init();

            $scope.openAgreementDialog = function () {
                ContributorAgreementService.contributorAgreementDialog();
            };

            var xMin = $("#x-min").val(),
                xMax = $("#x-max").val(),
                myTitle = $("#graph-outer-title").val() + xMin + " - " + xMax;
            $("#chart").css("height", "175px");
            $("#chart").css("z-index", "20");
            skywordGraphs.getGraph({
                graphType: "area",
                ajaxParams: {},
                action: "/programAccountViewData.action",
                type: "json",
                title: $("#graph-outer-title").val(),
                location: "chart",
                highlight: true,
                zoom: true,
                xType: "date",
                xLabelAngle: -60,
                yType: "Number",
                popupFormat: $("#popup-format").val(),
                addZeroes: true,
                xMin: xMin,
                showTicks: false,
                fill: false,
                xMax: xMax
            });
            $("html").on("keyup", "#formSubmit_writerComment", function() {
                if ($(this).val().length > 0) {
                    validation.enableButton("releaseBtn");
                } else {
                    validation.disableButton("releaseBtn");
                }
            });
        }
    ])
    .controller("pendingStaffWriter", ["$scope", function($scope) {
    }])
    .controller("join", [
        "$scope",
        "account",
        "login",
        function($scope, account, login) {
            account.init();
            login.init();
        }
    ])
    .controller("joinPrograms", [
        "$scope",
        "author",
        function($scope, author) {
            author.init();
        }
    ])
    .controller("similarTopicsLink", [
        "$scope",
        "topics",
        function($scope, topics) {
            topics.init();
        }
    ])
    .controller("recentTopicsModule", ["$scope", function($scope) {
    }])
    .controller("accountFeedModule", ["$scope", function($scope) {
    }])
    .controller("applicationHistory", ["$scope", function($scope) {
    }])
    .controller("applicationHistoryAgencyAjax", ["$scope", function($scope) {
    }])
    .controller("promoteArticles", [
        "$scope",
        "author",
        function($scope, author) {
            author.init();
        }
    ])
    .controller("releaseKeyword", ["$scope", function($scope) {
    }])
    .controller("programAccountViewData", ["$scope", function($scope) {
    }])
    .controller("expireClaim", ["$scope", function($scope) {
    }])
    .controller("viewDeclinedArticle", [
        "$scope",
        "publish",
        function($scope, publish) {
            publish.init();
            app.value("accountId", $scope.accountId);
            app.value("ranOnce", false);
            var spellcheckLang = $scope.spellcheckLang;
        }
    ])
    .controller("viewCompensationTermsAjax", ["$scope", function($scope) {
    }])
    .controller("articlesInProgress", [
        "$scope",
        "validation",
        function($scope, validation) {
            $("html").on("keyup", "#formSubmit_writerComment", function() {
                if ($(this).val().length > 0) {
                    validation.enableButton("releaseBtn");
                } else {
                    validation.disableButton("releaseBtn");
                }
            });
        }
    ])
    .controller("offeredAssignments", [
        "$scope",
        "author",
        "assignment",
        function($scope, author, assignment) {
            author.init();
            assignment.init();
        }
    ])
    .controller("writeIdea", [
        "$rootScope",
        "$scope",
        "$compile",
        "skyword",
        "author",
        "assignment",
        "assignmentAttachments",
        "contentTemplate",
        "common",
        "redactor",
        "assetLibrary",
        "call",
        "formBuilder",
        "setup",
        "ContributorAgreementService",
        function(
            $rootScope,
            $scope,
            $compile,
            skyword,
            author,
            assignment,
            assignmentAttachments,
            contentTemplate,
            common,
            redactor,
            assetLibrary,
            call,
            formBuilder,
            setup,
            ContributorAgreementService
        ) {
            author.init();
            common.init();
            contentTemplate.init();
            assignment.init();
            assignmentAttachments.init();
            $scope.openAgreementDialog = function () {
                ContributorAgreementService.contributorAgreementDialog();
            };
            //writeArticles.jsp
            $("body").on(
                "click",
                "#page.writeIdea a.changeContentType",
                null,
                function() {
                    var a = $(this),
                        li = a.parent(),
                        id = a.attr("id"),
                        options,
                        urlAction,
                        pointer;
                    $("#similar-topics-content").remove();

                    $("a.changeContentType").each(function() {
                        var t = $(this),
                            pli = t.parent(),
                            span = pli.find("span.pointer");
                        if (0 < span.length) {
                            pointer = span.clone();
                            span.remove();
                        }
                        pli.removeClass("active");
                        pli.addClass("notActive");
                    });
                    li.removeClass("notActive")
                        .addClass("active")
                        .append(pointer);
                    urlAction =
                        "/changeArticleContentTypeAjax.action?currentContentTypeId=" +
                        id;
                    if (1 === jQuery("#contributor-write-idea").length) {
                        urlAction += "&createIdea=true";
                    }
                    options = {
                        data: {},
                        cache: false,
                        type: "get",
                        url: urlAction,
                        dataType: "html",
                        success: function(html) {
                            $("#claim-article").html(html);
                            formBuilder.render();
                            if (typeof (Event) === "function") {
                                window.document.dispatchEvent(new Event("DOMContentLoaded", {
                                    bubbles: true,
                                    cancelable: true
                                }));
                            } else {
                                let event = document.createEvent("Event");
                                event.initEvent("DOMContentLoaded",
                                    true,
                                    true);
                                window.document.dispatchEvent(event);
                            }
                            $("textarea.tagLength, input.tagLength").each(function() {
                                tagCharLength($(this));
                                $(this).on(
                                    "input keyup keydown focus",
                                    function() {
                                        tagCharLength($(this));
                                    }
                                );
                            });
                        },
                        complete: function() {
                            var st = $("#claim-article").find(
                                "div#similar-topics-module"
                            );
                            if (0 < st.length) {
                                setupSimilarTopicsDefaults();
                            }
                            skyword.matchColumns();
                            setup.styledDropdowns();
                            $rootScope.$apply(function() {
                                var content = $("#resource-module-wrapper");
                                $compile(content)($scope);
                            });
                            // redactor.setupRedactorElements();
                        }
                    };
                    call.ajax(urlAction, options);

                    return false;
                }
            );
            function tagCharLength(div) {
                if (!div) {
                    return;
                }
                const counter = div.siblings(".remainingTagLength");
                const counterSpan = counter.find("span");
                const maxlength = div.attr("maxlength");
                const remainingText = maxlength - div.val().length;
                const minInput = div.siblings(".minTagLength");
                const minValue = minInput.find(".min-tag-length");
                const minlength = minValue.val();
                const remainingMinText = minlength - div.val().length;
                if (minlength && remainingMinText <= 0){
                    $(minInput).css("display", "none");
                } else {
                    $(minInput).css("display", "inline-block");
                }

                if (remainingText <= 20 || remainingText < 0) {
                    $(counter).css("color", "#eb5338");
                } else {
                    $(counter).css("color", "#999999");
                }

                return counterSpan.text(remainingText);
            }
            $(function() {
                $("textarea.tagLength, input.tagLength").each(function() {
                    tagCharLength($(this));
                    $(this).on(
                        "input keyup keydown focus",
                        function() {
                            tagCharLength($(this));
                        }
                    );
                });
            });
        }
    ])
    .controller("writeArticles", [
        "$scope",
        "skyword",
        "author",
        "assignment",
        "assignmentAttachments",
        "contentTemplate",
        "common",
        "redactor",
        "validation",
        "ContributorAgreementService",
        function(
            $scope,
            skyword,
            author,
            assignment,
            assignmentAttachments,
            contentTemplate,
            common,
            redactor,
            validation,
            ContributorAgreementService
        ) {
            if ("canClaimMore" in $scope) {
                app.value("canClaimMore", $scope.canClaimMore);
            }
            if ("trainingCompleted" in $scope) {
                app.value("trainingCompleted", $scope.trainingCompleted);
            }

            app.value("paypalRequire", $scope.paypalRequire);
            app.value(
                "profileCompletionRequire",
                $scope.profileCompletionRequire
            );

            author.init();
            common.init();
            contentTemplate.init();
            assignment.init();
            assignmentAttachments.init();
            redactor.init();

            $scope.openAgreementDialog = function () {
                ContributorAgreementService.contributorAgreementDialog();
            };

            //onReady
            $(function() {
                $("#similar-topics-content").similarTopics({
                    categorySelect: $("#enteredCategoryId"),
                    contentRequestId: null,
                    contentTypeId: $("#currentContentTypeId").val(),
                    loading: $("#similar-topics-loading").val(),
                    ignoreCategory: true
                });
            });
            $("html").on("keyup", "#formSubmit_writerComment", function() {
                if ($(this).val().length > 0) {
                    validation.enableButton("releaseBtn");
                } else {
                    validation.disableButton("releaseBtn");
                }
            });
        }
    ])
    .controller("managerWriteArticles", [
        "$scope",
        "skyword",
        "author",
        "assignment",
        "assignmentAttachments",
        "common",
        "contentTemplate",
        "redactor",
        "validation",
        function(
            $scope,
            skyword,
            author,
            assignment,
            assignmentAttachments,
            common,
            contentTemplate,
            redactor,
            validation
        ) {
            if ("canClaimMore" in $scope) {
                app.value("canClaimMore", $scope.canClaimMore);
            }
            if ("trainingCompleted" in $scope) {
                app.value("trainingCompleted", $scope.trainingCompleted);
            }

            app.value("paypalRequire", $scope.paypalRequire);
            app.value(
                "profileCompletionRequire",
                $scope.profileCompletionRequire
            );

            author.init();
            common.init();
            contentTemplate.init();
            assignment.init();
            assignmentAttachments.init();
            redactor.init();

            // Initialize an object to keep track of expanded sections
            $scope.expandedSections = JSON.parse(localStorage.getItem('expandedSections')) || {};
            // Toggle the expanded state of a section
            $scope.toggleSection = function(index) {
                if (index !== undefined) {
                    $scope.expandedSections[index] = !$scope.expandedSections[index];
                    localStorage.setItem('expandedSections', JSON.stringify($scope.expandedSections));
                }
            };

            // Check if a section is expanded
            $scope.isSectionExpanded = function(index) {
                return $scope.expandedSections[index] === true;
            };

            // Get the arrow state (expanded or collapsed)
            $scope.getArrowState = function(index) {
                return $scope.isSectionExpanded(index) ? 'collapse' : 'expand';
            };

            // Initialize the expanded state for a section
            $scope.initSectionState = function(index) {
                if (index !== undefined && $scope.expandedSections[index] === undefined) {
                    $scope.expandedSections[index] = false; // Default to collapsed
                }
            };

            //onReady
            $(function() {
                $("#similar-topics-content").similarTopics({
                    categorySelect: $("#enteredCategoryId"),
                    contentRequestId: null,
                    contentTypeId: $("#currentContentTypeId").val(),
                    loading: $("#similar-topics-loading").val(),
                    ignoreCategory: true
                });
            });
            $("html").on("keyup", "#formSubmit_writerComment", function() {
                if ($(this).val().length > 0) {
                    validation.enableButton("releaseBtn");
                } else {
                    validation.disableButton("releaseBtn");
                }
            });
        }
    ])
    .controller("managerCreateDigitalAssets", [
        "$scope",
        "skyword",
        "author",
        "assignment",
        "assignmentAttachments",
        "common",
        "contentTemplate",
        "redactor",
        function(
            $scope,
            skyword,
            author,
            assignment,
            assignmentAttachments,
            common,
            contentTemplate,
            redactor
        ) {
            if ("canClaimMore" in $scope) {
                app.value("canClaimMore", $scope.canClaimMore);
            }
            if ("trainingCompleted" in $scope) {
                app.value("trainingCompleted", $scope.trainingCompleted);
            }

            app.value("paypalRequire", $scope.paypalRequire);
            app.value(
                "profileCompletionRequire",
                $scope.profileCompletionRequire
            );

            author.init();
            common.init();
            contentTemplate.init();
            assignment.init();
            assignmentAttachments.init();
            redactor.init();

            //onReady
            $(function() {
                $("#similar-topics-content").similarTopics({
                    categorySelect: $("#enteredCategoryId"),
                    contentRequestId: null,
                    contentTypeId: $("#currentContentTypeId").val(),
                    loading: $("#similar-topics-loading").val(),
                    ignoreCategory: true
                });
            });
        }
    ])
    .controller("managerReleaseKeywordAjax", ["$scope", function($scope) {
    }])
    .controller("managerArticlesInProgress", [
        "$scope",
        "validation",
        function($scope, validation) {
            $("html").on("keyup", "#formSubmit_writerComment", function() {
                if ($(this).val().length > 0) {
                    validation.enableButton("releaseBtn");
                } else {
                    validation.disableButton("releaseBtn");
                }
            });
        }
    ])
    .controller("managerOfferedAssignments", [
        "$scope",
        "author",
        "validation",
        function($scope, author, validation) {
            author.init();
            $("html").on("keyup", "#formSubmit_writerComment", function() {
                if ($(this).val().length > 0) {
                    validation.enableButton("releaseBtn");
                } else {
                    validation.disableButton("releaseBtn");
                }
            });
        }
    ])
    .controller("removeContentSample", ["$scope", function($scope) {
    }])
    .controller("contentSampleExternalSearchAjax", [
        "$scope",
        "contentSearch",
        function($scope, contentSearch) {
            contentSearch.init();
        }
    ])
    .controller("editContentSample", [
        "$scope",
        "contentSearch",
        function($scope, contentSearch) {
            contentSearch.init();
        }
    ])
    .controller("joinEditContentSample", [
        "$scope",
        "contentSearch",
        function($scope, contentSearch) {
            contentSearch.init();
        }
    ])
    .controller("joinAddContentSample", [
        "$scope",
        "contentSearch",
        "writers",
        function($scope, contentSearch, writers) {
            contentSearch.init();
            writers.contributorSearch();
        }
    ])
    .controller("contentSampleExternalSearch", ["$scope", function($scope) {
    }])
    .controller("removeContentSampleAjax", ["$scope", function($scope) {
    }])
    .controller("searchContentSamples", [
        "$scope",
        "contentSearch",
        function($scope, contentSearch) {
            contentSearch.init();
        }
    ])
    .controller("managerSearchContentSamplesAjax", [
        "$scope",
        function($scope) {
        }
    ])
    .controller("searchContentSamplesAjax", ["$scope", function($scope) {
    }])
    .controller("loadContentSamplesAjax", ["$scope", function($scope) {
    }])
    .controller("loadContentSampleAjax", ["$scope", function($scope) {
    }])
    .controller("loadContributorSamplesAjax", ["$scope", function($scope) {
    }])
    .controller("contributorReportOverallEarningsSummary", [
        "$scope",
        function($scope) {
        }
    ])
    .controller("contentRequestView", [
        "$scope",
        "contentRequestView",
        "author",
        "redactor",
        "contentTemplate",
        "validation",
        "ContributorAgreementService",
        function(
            $scope,
            contentRequestView,
            author,
            redactor,
            contentTemplate,
            validation,
            ContributorAgreementService
        ) {
            contentRequestView.init();
            author.init();
            redactor.init();
            contentTemplate.init();

            $scope.openAgreementDialog = function () {
                ContributorAgreementService.contributorAgreementDialog();
            };

            $(".btnGroup a:not(.noUnderline)").click(function(e) {
                var thisButton = $(this);
                //console.log("clicked");
                // Make sure the article hasn't been locked behind the scenes
                if (thisButton.hasClass("disabled")) {
                    return false;
                }
                $("#loader-module")
                    .show()
                    .find(".loadingText")
                    .hide();
                $(".sidebarBox").addClass("whiteout");

                contentRequestView.updateState(thisButton.attr("id"));
                return false;
            });

            if (
                $("#requestTerminal").val() == "false" &&
                $("#requestInReview").val() == "false"
            ) {
                $("#content-checklist-module .tab").click();
            }

            $("html").on("keyup", "#writerComment", function() {
                if ($(this).val().length > 0) {
                    validation.enableButton("removeBtn");
                } else {
                    validation.disableButton("removeBtn");
                }
            });
        }
    ])
    .controller("rfpView", [
        "$scope",
        "contentRequestView",
        "author",
        "redactor",
        "contentTemplate",
        "ContributorAgreementService",
        function(
            $scope,
            contentRequestView,
            author,
            redactor,
            contentTemplate,
            ContributorAgreementService
        ) {
            $scope.openAgreementDialog = function () {
                ContributorAgreementService.contributorAgreementDialog();
            };
            contentRequestView.init();
            author.init();
            redactor.init();
            contentTemplate.init();
        }
    ])
    .controller("bidProposal", [
        "$scope",
        function($scope) {
            $("#upholdBid").on("change", function() {
                if ($(this).is(":checked")) {
                    $("#btn-bid-proposal")
                        .removeAttr("disabled")
                        .removeClass("disabled");
                } else {
                    $("#btn-bid-proposal")
                        .attr("disabled", "disabled")
                        .addClass("disabled");
                }
            });
        }
    ]);

app.service("apiTest", [
    "call",
    function(call) {
        return {
            init: function() {
                var runTest = function() {
                    var button = $(this),
                        work = button.siblings(".working"),
                        passed = button.siblings(".passed"),
                        failed = button.siblings(".failed"),
                        id = button.attr("id"),
                        options,
                        urlAction = "",
                        programId = $("#programId").val(),
                        contentTemplateId = $("#contentTemplateId").val(),
                        wordpressPost = $("#wordpressPostId"),
                        wordpressPostId,
                        contentTypeId = $("#contentTypeId").val();

                    $("div#" + id + "-msg-success").fadeOut("fast");
                    $("div#" + id + "-msg-fail").fadeOut("fast");
                    failed.fadeOut("fast");
                    passed.fadeOut("fast");

                    if ("api-connect" === id) {
                        urlAction = "apiConnectionTestAjax.action";
                    } else if ("api-publish" === id) {
                        urlAction = "apiPublishTestAjax.action";
                    } else if ("api-update" === id) {
                        urlAction = "apiUpdateTestAjax.action";
                        wordpressPostId = wordpressPost.val();
                    } else if ("api-delete" === id) {
                        urlAction = "apiDeleteTestAjax.action";
                        wordpressPostId = wordpressPost.val();
                    }

                    options = {
                        cache: false,
                        type: "POST",
                        data: {
                            programId: programId,
                            contentTemplateId: contentTemplateId,
                            wordpressPostId: wordpressPostId,
                            contentTypeId: contentTypeId
                        },
                        dataType: "json",
                        url: urlAction,
                        success: function(json) {
                            var msgType = "-msg-success",
                                passfail;
                            work.fadeOut("fast", function() {
                                if (true === json.error) {
                                    msgType = "-msg-fail";
                                    button.fadeIn("fast", function() {
                                        failed
                                            .fadeIn("fast")
                                            .css("display", "inline-block");
                                    });
                                } else {
                                    console.log(
                                        button
                                            .parent()
                                            .parent()
                                            .next()
                                            .find("button.apiTest")
                                    );
                                    button
                                        .parent()
                                        .parent()
                                        .next()
                                        .find("button.apiTest")
                                        .removeAttr("disabled")
                                        .removeClass("disabled");
                                    console.log(
                                        button
                                            .parent()
                                            .parent()
                                            .next()
                                            .find("button.apiTest")
                                    );
                                    button.fadeIn("fast", function() {
                                        passed
                                            .fadeIn("fast")
                                            .css("display", "inline-block");
                                    });
                                }
                                console.log(id);
                                if ("api-connect" === id) {
                                    $("div#" + id + msgType).fadeIn("fast");
                                    console.log($("div#" + id + msgType));
                                } else {
                                    if (undefined !== json.url) {
                                        $("div#" + id + msgType + " a.url")
                                            .text(json.url)
                                            .attr("href", json.url);
                                    }
                                    if (undefined !== json.datetime) {
                                        $(
                                            "div#" +
                                                id +
                                                msgType +
                                                " span.datetime"
                                        ).text(json.datetime);
                                    }
                                    $("div#" + id + msgType).fadeIn("fast");
                                }
                                wordpressPost.val(json.wordpressPostId);
                            });
                        },
                        error: function(data, status, err) {
                            console.log([data, status, err]);
                            work.fadeOut("fast", function() {
                                button.fadeIn("fast", function() {
                                    failed
                                        .fadeIn("fast")
                                        .css("display", "inline-block");
                                });
                            });
                        }
                    };
                    button.fadeOut("fast", function() {
                        work.fadeIn("fast", function() {
                            request = call.ajax(urlAction, options);
                        });
                    });
                    return false;
                };

                //onReady
                $(function() {
                    $("button.apiTest")
                        .on("click", runTest)
                        .click();
                });
            }
        };
    }
]) // end apiTest

    .service("programSettings", [
        "skyword",
        "redactor",
        "call",
        "validationUtil",
        function(skyword, redactor, call, validationUtil) {
            return {
                init: function() {
                    var taxonomySync = function() {
                            var urlAction = "/taxonomySyncAjax.action",
                                getPost = "POST",
                                cteId = $("#contentTemplateElementId").val(),
                                taxId = $("#taxonomyId").val(),
                                sync = $("#syncId"),
                                working = $("#workingId"),
                                notsynced = $("#notsyncedId"),
                                synced = $("#syncedId"),
                                options = {
                                    cache: false,
                                    type: getPost,
                                    data: {
                                        contentTemplateElementId: cteId,
                                        taxonomyId: taxId
                                    },
                                    dataType: "json",
                                    url: urlAction,
                                    success: function(data) {
                                        working.fadeOut("fast", function() {
                                            if (true === data.error) {
                                                notsynced.fadeIn("fast");
                                            } else {
                                                synced.fadeIn("fast");
                                            }
                                        });
                                    },
                                    error: function(data, status, err) {
                                        console.log([data, status, err]);
                                    },
                                    complete: function() {}
                                };
                            sync.fadeOut("fast", function() {
                                working.fadeIn("fast", function() {});
                            });

                            call.ajax(urlAction, options);
                            return false;
                        },
                        doWorkflowBoxRules = function() {
                            // content review requires copy edit to be checked
                            if ($("#reviewedCopyEditorBox").attr("checked")) {
                                $("#reviewedContentReviewerBox").removeAttr(
                                    "disabled"
                                );
                                $("#reviewedContentReviewerBox").removeClass(
                                    "disabled"
                                );
                                $("#reviewedContentReviewerBox")
                                    .parent()
                                    .removeClass("disabled");
                            } else {
                                if (
                                    $("#reviewedContentReviewerBox").is(
                                        ":checked"
                                    )
                                ) {
                                    $("#reviewedContentReviewerBox").click();
                                }
                                $("#reviewedContentReviewerBox").addClass(
                                    "disabled"
                                );
                                $("#reviewedContentReviewerBox").attr(
                                    "disabled",
                                    "disabled"
                                );
                                $("#reviewedContentReviewerBox")
                                    .parent()
                                    .addClass("disabled");
                            }

                            // site owner publish requires site owner review to be checked
                            if ($("#reviewedSiteOwnerBox").attr("checked")) {
                                $("#publishedSiteOwnerBox").removeAttr(
                                    "disabled"
                                );
                                $("#publishedSiteOwnerBox").removeClass(
                                    "disabled"
                                );
                                $("#publishedSiteOwnerBox")
                                    .parent()
                                    .removeClass("disabled");
                            } else {
                                if (
                                    $("#publishedSiteOwnerBox").is(":checked")
                                ) {
                                    $("#publishedSiteOwnerBox").click();
                                }
                                $("#publishedSiteOwnerBox").addClass(
                                    "disabled"
                                );
                                $("#publishedSiteOwnerBox").attr(
                                    "disabled",
                                    "disabled"
                                );
                                $("#publishedSiteOwnerBox")
                                    .parent()
                                    .addClass("disabled");
                            }
                        },
                        setMoveButtons = function(curContainer) {
                            var prevRow = curContainer.prev(),
                                nextRow = curContainer.next(),
                                asc = curContainer.find(".asc.element"),
                                desc = curContainer.find(".desc.element");
                            if (nextRow.length > 0) {
                                desc.html(
                                    '<span class="icon-Descending pointer element"></span>'
                                );
                            } else {
                                desc.html(
                                    '<span class="icon-Descending off element"></span>'
                                );
                            }
                            if (prevRow.length > 0) {
                                asc.html(
                                    '<span class="icon-Ascending pointer element"></span>'
                                );
                            } else {
                                asc.html(
                                    '<span class="icon-Ascending off element"></span>'
                                );
                            }
                        },
                        ascElement = function() {
                            var curContainer = $(this).closest("tr"),
                                prevContainer = curContainer.prev(),
                                curId = curContainer
                                    .find(".categoryOrder")
                                    .val(),
                                adjId = prevContainer
                                    .find(".categoryOrder")
                                    .val(),
                                formParams = {
                                    curId: curId,
                                    adjId: adjId
                                },
                                options = {
                                    cache: false,
                                    type: "POST",
                                    url:
                                        "updateProgramCategoryOrderAjax.action",
                                    data: formParams,
                                    dataType: "html",
                                    success: function(data) {
                                        app.value("requestActive", false);
                                        curContainer.fadeOut("fast");
                                        curContainer
                                            .prev()
                                            .before(curContainer);
                                        setMoveButtons(curContainer);
                                        setMoveButtons(prevContainer);
                                        curContainer.fadeIn("slow");
                                    }
                                };
                            call.ajax(
                                "updateProgramCategoryOrderAjax.action",
                                options
                            );
                        },
                        descElement = function() {
                            var curContainer = $(this).closest("tr"),
                                nextContainer = curContainer.next(),
                                curId = curContainer
                                    .find(".categoryOrder")
                                    .val(),
                                adjId = nextContainer
                                    .find(".categoryOrder")
                                    .val(),
                                formParams = {
                                    curId: curId,
                                    adjId: adjId
                                },
                                options = {
                                    cache: false,
                                    type: "POST",
                                    url:
                                        "updateProgramCategoryOrderAjax.action",
                                    data: formParams,
                                    dataType: "html",
                                    success: function(data) {
                                        app.value("requestActive", false);
                                        curContainer.fadeOut("fast");
                                        curContainer.next().after(curContainer);
                                        setMoveButtons(curContainer);
                                        setMoveButtons(nextContainer);
                                        curContainer.fadeIn("slow");
                                    }
                                };
                            call.ajax(
                                "updateProgramCategoryOrderAjax.action",
                                options
                            );
                        };

                    //onReady
                    $(function() {
                        // redactor.setupRedactorElements();
                        $(".rate, #upfront-payment").keyup(
                            calculateMaxCompensation
                        );
                        function calculateMaxCompensation() {
                            var maxVal = 0;
                            $(".rate").each(function() {
                                if ($(this).val() != "") {
                                    maxVal += parseFloat($(this).val());
                                }
                            });
                            maxVal += parseFloat($("#upfront-payment").val());
                            maxVal =
                                Math.round(maxVal * Math.pow(10, 2)) /
                                Math.pow(10, 2);
                            if (isNaN(maxVal)) {
                                $("#max-val").html(
                                    $("#cannot-calculate").val()
                                );
                            } else {
                                $("#max-val").html(maxVal);
                            }
                        }
                        calculateMaxCompensation();
                        $("body").on("click", ".approve", null, function() {
                            $("form").append(
                                '<input type="hidden" id="approve" name="approve" value="true"/>'
                            );
                            $("form").submit();
                            return false;
                        });
                        $("#buttonSyncId").click(taxonomySync);

                        if (
                            "/editReviewWorkflow.action" ===
                            window.location.pathname
                        ) {
                            doWorkflowBoxRules();

                            $("#reviewedCopyEditorBox").change(function() {
                                doWorkflowBoxRules();
                            });

                            $("#reviewedSiteOwnerBox").change(function() {
                                doWorkflowBoxRules();
                            });
                        }

                        $("html").on(
                            "click",
                            ".icon-Descending.element",
                            null,
                            descElement
                        );
                        $("html").on(
                            "click",
                            ".icon-Ascending.element",
                            null,
                            ascElement
                        );

                        $("#sync-taxonomy").click(function() {
                            var options,
                                urlAction = "syncApiTaxonomyAjax.action",
                                getPost = "POST",
                                apiInfoId = $("#programApiInfoId").val();
                            options = {
                                cache: false,
                                type: getPost,
                                data: {
                                    programApiInfoId: apiInfoId
                                },
                                url: urlAction,
                                success: function(data) {
                                    $("#sync-results")
                                        .html(data)
                                        .fadeIn();
                                    $("#sync-results")
                                        .html(data)
                                        .fadeIn();
                                    skyword.enableButtons();
                                }
                            };
                            $("#sync-results").fadeOut();
                            call.ajax(urlAction, options);
                        });

                        $("body").on(
                            "click",
                            "#change-fb-account",
                            null,
                            function() {
                                window.open(
                                    "http://www.facebook.com",
                                    "_blank"
                                );
                                return true;
                            }
                        );

                        /**
                         * Timeout Success Messages
                         */

                        setTimeout(function() {
                            $("#success-box.alert").fadeOut("slow");
                        }, 3000);
                    });
                }
            };
        }
    ]);

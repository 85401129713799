app.service("stockPhoto", [
    "skyword",
    "validation",
    "call",
    "overlay",
    "contentTemplate",
    "$translate",
    function(skyword, validation, call, overlay, contentTemplate, $translate) {
        var pageCount = 1,
            keyword = "",
            contentTemplateElementId = null,
            contentTemplateElementDataId = null,
            attachmentContainer = null,
            attachmentHidden = null,
            embedded = false,
            editorId = null,
            uploadContainer = null,
            cachedKeyword = "",
            attachment = null,
            searchPhotoCallback = function() {
                pageCount = 1 + pageCount;
                return false;
            },
            searchPhotos = function() {
                pageCount = 1;
                var a = $(this),
                    photoKeyword = $("#stockPhotoKeywordId").val(),
                    options,
                    urlAction = "/stockPhotoSetupAjax.action",
                    contentTemplateElement,
                    contentTemplateElementData,
                    stockPhotoKeywordId = $("#stockPhotoKeywordId"),
                    redactorEditor;
                if (a.hasClass("stock-photo")) {
                    contentTemplate.attachmentContainer = a.closest(
                        ".attachmentContainer"
                    );
                }
                if (0 < stockPhotoKeywordId.length) {
                    if (a.attr("id") === "searchStockPhotoId") {
                        redo_search(photoKeyword);
                    } else {
                        search(photoKeyword);
                    }
                } else {
                    if (a.hasClass("embedded")) {
                        embedded = true;
                        attachmentContainer = a.closest(".attachmentContainer");
                        redactorEditor = attachmentContainer
                            .closest(".articleElement")
                            .find("textarea.templateElementText");
                        editorId = redactorEditor.attr("id");
                    } else {
                        embedded = false;
                        attachmentContainer = a.closest(".attachmentContainer");
                    }

                    contentTemplateElement = attachmentContainer.data("cte");
                    contentTemplateElementData = attachmentContainer.data(
                        "cted"
                    );
                    attachmentHidden = attachmentContainer.siblings(
                        ".attachmentHidden"
                    );
                    attachment = attachmentContainer.siblings(".attachmentId");

                    photoKeyword = $(".keywordNumber1").val();
                    keyword = photoKeyword;
                    cachedKeyword = photoKeyword;
                    options = {
                        stockPhotoKeyword: photoKeyword,
                        pageCount: pageCount,
                        stockContentTemplateElementId: contentTemplateElement,
                        stockContentTemplateElementDataId: contentTemplateElementData
                            .toString()
                            .includes("cted")
                            ? parseInt(contentTemplateElementData.slice(4))
                            : parseInt(contentTemplateElementData, 10),
                        contentRequestId: $("#contentRequestId").val()
                    };
                    if (jQuery(window).width() < 1250) {
                        call.overlay(
                            urlAction,
                            options,
                            940,
                            "75%",
                            "POST",
                            "stockPhoto.searchPhotoCallback",
                            true
                        );
                    } else {
                        call.overlay(
                            urlAction,
                            options,
                            1200,
                            "75%",
                            "POST",
                            "stockPhoto.searchPhotoCallback",
                            true
                        );
                    }
                    setTimeout(function() {
                        var stockPhoto = angular
                            .element("body")
                            .injector()
                            .get("stockPhoto");
                        stockPhoto.scroller();
                    }, 2000);
                }
                searchReturn();
                return false;
            },
            updateStockCarouselOnSearch = function() {
                var updatedPics = $(
                    "div#pics > .stock-images > .stockimage > a"
                );
                let carouselImgs = [];
                let arrowIcons = document.querySelectorAll(
                    ".stock-images-carousel-wrapper i"
                );

                if (updatedPics.length > 1) {
                    for (var i = 0; i < updatedPics.length; i++) {
                        var {
                            formatheight,
                            formatwidth,
                            largesrc,
                            largeheight,
                            largewidth,
                            sizetorequest,
                            description,
                            inuse,
                            insights
                        } = updatedPics[i].dataset;
                        var id = $(updatedPics[i]).attr("id"),
                            href = $(updatedPics[i]).attr("href");

                        let carouselImg = `<div><img class="stock-thumbs carouselImg" id="carouselImg-${id}" src="${href}" title="${description}" alt="${description}" draggable="false"
                    data-formatheight="${formatheight}" data-formatwidth="${formatwidth}" data-largesrc="${largesrc}" data-largeheight="${largeheight}" data-largewidth="${largewidth}" data-sizetorequest="${sizetorequest}" data-description="${description}" data-title="${description}" data-inuse="${inuse}" data-insights="${insights}" /></div>`;
                        carouselImgs.push(carouselImg);
                    }
                    $("#stockCarousel").css("display", "");
                    $("#stockCarousel").html("");
                    $("#stockCarousel").html(carouselImgs);
                    if (updatedPics.length > 4) {
                        arrowIcons[0].style.display = "";
                        arrowIcons[1].style.display = "";
                    } else {
                        arrowIcons[0].style.display = "none";
                        arrowIcons[1].style.display = "none";
                    }
                } else {
                    // $("#noMoreResults").css("display", "block");
                    $("#stockCarousel").css("display", "none");

                    arrowIcons[0].style.display = "none";
                    arrowIcons[1].style.display = "none";
                }
            },
            search = function(photoKeyword) {
                var options,
                    urlAction = "/stockPhotoAjax.action";
                if (cachedKeyword === photoKeyword) {
                    pageCount = 1 + pageCount;
                } else {
                    keyword = photoKeyword;
                    cachedKeyword = photoKeyword;
                    pageCount = 1;
                    $("div#pics > .stock-images").html("");
                }

                $("#searchingForId").val(photoKeyword);
                options = {
                    type: "POST",
                    data: {
                        stockPhotoKeyword: cachedKeyword,
                        pageCount: pageCount,
                        stockContentTemplateElementId: parseInt(
                            $("#stockContentTemplateElementId").val(),
                            10
                        ),
                        stockContentTemplateElementDataId: parseInt(
                            $("#stockContentTemplateElementDataId").val(),
                            10
                        ),
                        shutterStockAiObjective: $("#shutterStockAiObjective")
                            ? $("#shutterStockAiObjective").val()
                            : null,
                        colorPalette: !$("#stockColorControls").hasClass(
                            "hidden"
                        )
                            ? $("#shutterStockColorParam").val()
                            : null
                    },
                    url: urlAction,
                    dataType: "html",
                    success: function(html) {
                        var noResults;
                        $("div#pics > .stock-images").append(html);
                        if (
                            0 <
                                $(".stock-images").find(".noMoreResults")
                                    .length &&
                            1 < pageCount
                        ) {
                            $(".stock-images")
                                .find(".noMoreResults")
                                .hide();
                        }
                        if (
                            0 <
                                $(".stock-images").find(".noMoreResults")
                                    .length &&
                            0 === $(".stockimage").length
                        ) {
                            $("div#pics > .stock-images").html(html);
                        }
                    },
                    error: function(data) {
                        console.log("error occurred", data);
                    }
                };
                call.skyjax(urlAction, options);
            },
            redo_search = function(photoKeyword) {
                var options,
                    urlAction = "/stockPhotoAjax.action";
                keyword = photoKeyword;
                cachedKeyword = photoKeyword;
                pageCount = 1;
                $("div#pics > .stock-images").html("");

                $("#searchingForId").val(photoKeyword);
                options = {
                    type: "POST",
                    data: {
                        stockPhotoKeyword: cachedKeyword,
                        pageCount: pageCount,
                        stockContentTemplateElementId: parseInt(
                            $("#stockContentTemplateElementId").val(),
                            10
                        ),
                        stockContentTemplateElementDataId: parseInt(
                            $("#stockContentTemplateElementDataId").val(),
                            10
                        ),
                        shutterStockAiObjective: $("#shutterStockAiObjective")
                            ? $("#shutterStockAiObjective").val()
                            : null,
                        colorPalette: !$("#stockColorControls").hasClass(
                            "hidden"
                        )
                            ? $("#shutterStockColorParam").val()
                            : null
                    },
                    url: urlAction,
                    dataType: "html",
                    success: function(html) {
                        var noResults;
                        $("div#pics > .stock-images").append(html);
                        if (
                            0 <
                                $(".stock-images").find(".noMoreResults")
                                    .length &&
                            1 < pageCount
                        ) {
                            $(".stock-images")
                                .find(".noMoreResults")
                                .hide();
                        }
                        if (
                            0 <
                                $(".stock-images").find(".noMoreResults")
                                    .length &&
                            0 === $(".stockimage").length
                        ) {
                            $("div#pics > .stock-images").html(html);
                        }
                        $("#searchStockPhotoId")
                            .removeAttr("disabled")
                            .removeClass("disabled");
                    },
                    error: function(data) {
                        console.log("error occurred", data);
                    }
                };
                call.skyjax(urlAction, options);
            },
            stockPhotoImage = function(event) {
                event.preventDefault();
                function slugify(string) {
                    const a =
                        "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
                    const b =
                        "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
                    const p = new RegExp(a.split("").join("|"), "g");

                    return string
                        .toString()
                        .toLowerCase()
                        .replace(/\s+/g, "-") // Replace spaces with -
                        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
                        .replace(/&/g, "-and-") // Replace & with 'and'
                        .replace(/[^\w\-]+/g, "") // Remove all non-word characters
                        .replace(/\-\-+/g, "-") // Replace multiple - with single -
                        .replace(/^-+/, "") // Trim - from start of text
                        .replace(/-+$/, ""); // Trim - from end of text
                }

                var a = $(this),
                    urlAction = "stockPhotoPreviewAjax.action",
                    dataImageId = parseInt(a.attr("id"), 10),
                    dataInUse = a.attr("data-inuse"),
                    dataUserTypeId = $("#attrs-id").attr("data-accounttypeid"),
                    dataLargeWidth = a.attr("data-largewidth"),
                    dataLargeHeight = a.attr("data-largeheight"),
                    dataFormatWidth = a.attr("data-formatwidth"),
                    dataFormatHeight = a.attr("data-formatheight"),
                    dataLargeUrl = a.attr("data-largesrc"),
                    dataDescription = a.attr("data-description"),
                    dataTitle = a.attr("data-title"),
                    dataSizeToRequest = a.attr("data-sizetorequest"),
                    details = $("#selected-photo li.details > div"),
                    stockId = $("#stockPhotoId"),
                    stockPhoto = $("#stockPhotoImage"),
                    stockTitle = $("p#stockTitle"),
                    stockDesc = $("p#stockDesc"),
                    stockPhotoUrl = $("#stockPhotoUrl"),
                    stockHeight = $("#stockPhotoHeight"),
                    stockWidth = $("#stockPhotoWidth"),
                    stockPhotoDescription = $("#stockPhotoDescription"),
                    stockPhotoTitle = $("#stockPhotoTitle"),
                    stockPhotoPurchaseHeight = $("#stockPhotoPurchaseHeight"),
                    stockPhotoPurchaseWidth = $("#stockPhotoPurchaseWidth"),
                    stockPhotoSizeToRequest = $("#stockPhotoSizeToRequest"),
                    userEnteredFilename = $("#userEnteredFilename"),
                    slugTitle = slugify(dataTitle),
                    imageInUseMessage = $("#image-inuse-message"),
                    dataInsight = a.attr("data-insights"),
                    dataPerformance = $("#stockPerformance span"),
                    stockInsight = $("#stockInsight");

                stockId.val(dataImageId);
                stockPhotoUrl.val(dataLargeUrl);
                stockHeight.val(dataLargeHeight);
                stockWidth.val(dataLargeWidth);
                stockPhoto
                    .attr("src", dataLargeUrl)
                    .attr("width", dataLargeWidth)
                    .attr("height", dataLargeHeight)
                    .attr("title", dataTitle)
                    .attr("alt", dataDescription);
                stockPhotoTitle.val(dataTitle);
                stockPhotoDescription.val(dataDescription);
                stockTitle.text(dataTitle);
                stockDesc.text(dataDescription);
                userEnteredFilename.val(slugTitle);
                stockPhotoPurchaseHeight.val(dataFormatHeight);
                stockPhotoPurchaseWidth.val(dataFormatWidth);
                stockPhotoSizeToRequest.val(dataSizeToRequest);

                if (dataInUse) {
                    imageInUseMessage.show();
                } else {
                    imageInUseMessage.hide();
                }

                let insights = dataInsight.replace(/\[|\]/g, "").split(", ");

                if (stockInsight) {
                    if (dataPerformance.length > 0) {
                        let performanceArray = [];

                        dataPerformance.each(function() {
                            let performanceObj = {
                                name: "",
                                percentile: 0
                            };
                            performanceObj.name = $(this).attr("id");
                            performanceObj.percentile = $(this).text();
                            if (insights.includes(performanceObj.name)) {
                                performanceArray.push(performanceObj);
                            }
                        });

                        if (performanceArray.length > 0) {
                            performanceArray.sort(
                                (a, b) => a.percentile > b.percentile
                            );
                            stockInsight.html("");
                            let html = "";
                            let percentile = 0;
                            let name = "";
                            if (performanceArray.length > 0) {
                                percentile = Math.round(
                                    performanceArray[0].percentile
                                ).toFixed(0);
                                name = performanceArray[0].name.trim();
                            }

                            $translate("nucleus.stockphoto.insight", {
                                name: name,
                                percentile: percentile
                            }).then(function(val) {
                                html += val;
                                stockInsight.html(html);
                                $("#stockInsightWrapper").removeClass("hidden");
                            });
                        } else {
                            $("#stockInsightWrapper").addClass("hidden");
                        }
                    }
                }
                /*
                            for (var idx in formats) {
                                var fmt = formats[idx];
                                if (fmt.size_code == "s") {
                                    stockPhotoPurchaseHeight.val(fmt.height);
                                    stockPhotoPurchaseWidth.val(fmt.width);
                                }
                            }
*/
                $(".stock-images, .stock-photos-used").fadeOut(
                    "fast",
                    function() {
                        $("#selected-photo").fadeIn("fast");
                        if (!a.hasClass("used-images")) {
                            $("#stock-images-carousel").fadeIn("fast");
                            showStockCarousel();
                        } else {
                            $("#stock-images-carousel").fadeOut("fast");
                        }
                    }
                );

                return false;
            },
            searchReturn = function() {
                var stockPhoto = $("#stockPhotoImage");
                $("#selected-photo").fadeOut("fast", function() {
                    stockPhoto
                        .removeAttr("src")
                        .removeAttr("title")
                        .removeAttr("alt");
                    $(".stock-images").fadeIn("fast");
                });
                $("#stock-images-carousel").fadeOut("fast");
            },
            stockEmbed = function(
                attachmentId,
                contentRequestId,
                editorId,
                attachmentUrl,
                imgTitle,
                imgDesc,
                contentTemplateElementDataId
            ) {
                var json,
                    cb,
                    urlAction =
                        "uploadEmbeddedImageAjax.action?stockPhoto=true&editorId=" +
                        editorId +
                        "&contentRequestId=" +
                        contentRequestId +
                        "&attachmentId=" +
                        attachmentId +
                        "&attachmentUrl=" +
                        attachmentUrl +
                        "&altTitle=" +
                        encodeURIComponent(imgDesc) +
                        "&imgTitle=" +
                        encodeURIComponent(imgTitle) +
                        "&contentTemplateElementDataId=" +
                        contentTemplateElementDataId;

                call.overlay(urlAction, null, 1090, null, null, cb);
                skyword.validateContinue();
                setTimeout(function() {
                    skyword.validateContinue();
                }, 300);
            },
            showStockCarousel = function() {
                updateStockCarouselOnSearch();

                const carousel = document.querySelector("#stockCarousel"),
                    firstImg = carousel.querySelectorAll("div")[0],
                    arrowIcons = document.querySelectorAll(
                        ".stock-images-carousel-wrapper i"
                    ),
                    carouselImgs = $(
                        ".stock-images-carousel-wrapper .carousel div img"
                    );
                const selectedImg = $("#stockPhotoImage")
                    .attr("src")
                    .toString();

                const showHideIcons = () => {
                    // showing and hiding prev/next icon according to carousel scroll left value
                    let scrollWidth =
                        carousel.scrollWidth - carousel.clientWidth; // getting max scrollable width
                    arrowIcons[0].style.display =
                        carousel.scrollLeft <= 0 ? "none" : "block";
                    arrowIcons[1].style.display =
                        carousel.scrollLeft >= scrollWidth ? "none" : "block";
                };
                if (carouselImgs.length > 0) {
                    $(
                        ".stock-images-carousel-wrapper .carousel div"
                    ).removeClass("active");
                    for (var i = 0; i < carouselImgs.length; i++) {
                        let id = $(carouselImgs[i])
                            .attr("id")
                            .split("-")[1];
                        if (selectedImg.includes(id)) {
                            $("#" + $(carouselImgs[i]).attr("id"))
                                .parent()
                                .addClass("active");

                            //scroll to active image
                            let activeImgTotalWidth =
                                (firstImg.clientWidth + 14) * (i - 1);
                            let scrollWidth =
                                carousel.scrollWidth - carousel.clientWidth;
                            carousel.scrollLeft =
                                activeImgTotalWidth >= scrollWidth
                                    ? scrollWidth
                                    : activeImgTotalWidth;
                            if (carouselImgs.length > 4) {
                                showHideIcons();
                            } else {
                                arrowIcons[0].style.display = "none";
                                arrowIcons[1].style.display = "none";
                            }
                            break;
                        }
                    }
                }

                arrowIcons.forEach((icon) => {
                    icon.addEventListener("click", () => {
                        let firstImgWidth = (firstImg.clientWidth + 14) * 4; // getting first img width & adding 14 margin value
                        // if clicked icon is left, reduce width value from the carousel scroll left else add to it
                        carousel.scrollLeft +=
                            icon.id == "left" ? -firstImgWidth : firstImgWidth;
                        setTimeout(() => showHideIcons(), 60); // calling showHideIcons after 60ms
                    });
                });
            },
            changeSelectedImageFromCarousel = function(elem) {
                if (
                    $(elem)
                        .parent()
                        .hasClass("active")
                ) {
                    $("#stockPhotoImage").css("opacity", 1);
                    return;
                }
                $(".stock-images-carousel-wrapper .carousel div").removeClass(
                    "active"
                );

                $("#stockPhotoImage").css("display", "none");

                var dataset = elem[0].dataset;
                function slugify(string) {
                    const a =
                        "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
                    const b =
                        "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
                    const p = new RegExp(a.split("").join("|"), "g");

                    return string
                        .toString()
                        .toLowerCase()
                        .replace(/\s+/g, "-") // Replace spaces with -
                        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
                        .replace(/&/g, "-and-") // Replace & with 'and'
                        .replace(/[^\w\-]+/g, "") // Remove all non-word characters
                        .replace(/\-\-+/g, "-") // Replace multiple - with single -
                        .replace(/^-+/, "") // Trim - from start of text
                        .replace(/-+$/, ""); // Trim - from end of text
                }
                var a = $(this),
                    urlAction = "stockPhotoPreviewAjax.action",
                    dataImageId = parseInt(
                        $(elem)
                            .attr("id")
                            .split("-")[1],
                        10
                    ),
                    dataInUse = dataset.inuse,
                    dataUserTypeId = $("#attrs-id").attr("data-accounttypeid"),
                    dataLargeWidth = dataset.largewidth,
                    dataLargeHeight = dataset.largeheight,
                    dataFormatWidth = dataset.formatwidth,
                    dataFormatHeight = dataset.formatheight,
                    dataLargeUrl = dataset.largesrc,
                    dataDescription = dataset.description,
                    dataTitle = dataset.title,
                    dataSizeToRequest = dataset.sizetorequest,
                    details = $("#selected-photo li.details > div"),
                    stockId = $("#stockPhotoId"),
                    stockPhoto = $("#stockPhotoImage"),
                    stockTitle = $("p#stockTitle"),
                    stockDesc = $("p#stockDesc"),
                    stockPhotoUrl = $("#stockPhotoUrl"),
                    stockHeight = $("#stockPhotoHeight"),
                    stockWidth = $("#stockPhotoWidth"),
                    stockPhotoDescription = $("#stockPhotoDescription"),
                    stockPhotoTitle = $("#stockPhotoTitle"),
                    stockPhotoPurchaseHeight = $("#stockPhotoPurchaseHeight"),
                    stockPhotoPurchaseWidth = $("#stockPhotoPurchaseWidth"),
                    stockPhotoSizeToRequest = $("#stockPhotoSizeToRequest"),
                    userEnteredFilename = $("#userEnteredFilename"),
                    slugTitle = slugify(dataTitle),
                    imageInUseMessage = $("#image-inuse-message"),
                    dataInsight = dataset.insights,
                    dataPerformance = $("#stockPerformance span"),
                    stockInsight = $("#stockInsight");

                stockId.val(dataImageId);
                stockPhotoUrl.val(dataLargeUrl);
                stockHeight.val(dataLargeHeight);
                stockWidth.val(dataLargeWidth);
                stockPhoto
                    .attr("src", dataLargeUrl)
                    .attr("width", dataLargeWidth)
                    .attr("height", dataLargeHeight)
                    .attr("title", dataTitle)
                    .attr("alt", dataDescription);
                stockPhotoTitle.val(dataTitle);
                stockPhotoDescription.val(dataDescription);
                stockTitle.text(dataTitle);
                stockDesc.text(dataDescription);
                userEnteredFilename.val(slugTitle);
                stockPhotoPurchaseHeight.val(dataFormatHeight);
                stockPhotoPurchaseWidth.val(dataFormatWidth);
                stockPhotoSizeToRequest.val(dataSizeToRequest);

                if (dataInUse) {
                    imageInUseMessage.show();
                } else {
                    imageInUseMessage.hide();
                }

                let insights = dataInsight.replace(/\[|\]/g, "").split(", ");

                if (stockInsight) {
                    if (dataPerformance.length > 0) {
                        let performanceArray = [];

                        dataPerformance.each(function() {
                            let performanceObj = { name: "", percentile: 0 };
                            performanceObj.name = $(this).attr("id");
                            performanceObj.percentile = $(this).text();
                            if (insights.includes(performanceObj.name)) {
                                performanceArray.push(performanceObj);
                            }
                        });

                        if (performanceArray.length > 0) {
                            performanceArray.sort(
                                (a, b) => a.percentile > b.percentile
                            );
                            stockInsight.html("");
                            let html = "";
                            let percentile = 0;
                            let name = "";
                            if (performanceArray.length > 0) {
                                percentile = Math.round(
                                    performanceArray[0].percentile
                                ).toFixed(0);
                                name = performanceArray[0].name.trim();
                            }
                            $translate("nucleus.stockphoto.insight", {
                                name: name,
                                percentile: percentile
                            }).then(function(val) {
                                html += val;
                                stockInsight.html(html);
                                $("#stockInsightWrapper").removeClass("hidden");
                            });
                        } else {
                            $("#stockInsightWrapper").addClass("hidden");
                        }
                    }
                }

                $("#stockPhotoImage").css("opacity", 1);
                $("#stockPhotoImage").css("display", "");
                $("#" + $(elem).attr("id"))
                    .parent()
                    .addClass("active");
            },
            selectStockPhoto = function() {
                var options,
                    useImage,
                    selectStockPhoto = $("#selectStockPhotoId"),
                    urlAction = "/stockPhotoSelectAjax.action",
                    stockId = $("#stockPhotoId"),
                    contentTemplateElementDataId = parseInt(
                        $("#stockContentTemplateElementDataId").val(),
                        10
                    ),
                    buttonText = selectStockPhoto.html(),
                    elapsedSelectionTime = localStorage.getItem(
                        "ImageSelectionStartTime"
                    );

                selectStockPhoto.html(
                    '<span class="alignMid" style="color:#333E48;">' +
                        $("#stockPhotoDownloadingText").val() +
                        "</span>"
                );
                selectStockPhoto.before(
                    '<span class="icon-LoaderWithText" style="width:30px; height:30px; display:inline-block;"></span>'
                );
                $("#searchReturnId").hide();
                $("#usedReturnId").hide();
                $("#cancel-download")
                    .show()
                    .click(function(e) {
                        useImage.abort();
                        $(this).hide();
                        $("#selectStockPhotoId")
                            .html(buttonText)
                            .removeClass("disabled")
                            .removeAttr("disabled");
                        $("#searchReturnId").show();
                        $("#usedReturnId").show();
                        return false;
                    });
                options = {
                    cache: false,
                    type: "get",
                    data: {
                        embeddedImage: embedded,
                        accountId: $("#accountId").val(),
                        programId: $("#program-id").val(),
                        saveInitTime: new Date().getTime(),
                        referrerAction: $("#actionNameId").val(),
                        stockPhotoImageId: stockId.val(),
                        stockPhotoUrl: $("#stockPhotoUrl").val(),
                        contentRequestId: $("#contentRequestId").val(),
                        stockPhotoHeight: parseInt(
                            $("#stockPhotoHeight").val(),
                            10
                        ),
                        stockPhotoWidth: parseInt(
                            $("#stockPhotoWidth").val(),
                            10
                        ),
                        sizeToRequest: $("#stockPhotoSizeToRequest").val(),
                        stockPhotoTitle: $("#stockPhotoTitle").val(),
                        stockPhotoDescription: $(
                            "#stockPhotoDescription"
                        ).val(),
                        stockPhotoPurchaseHeight: $(
                            "#stockPhotoPurchaseHeight"
                        ).val(),
                        stockPhotoPurchaseWidth: $(
                            "#stockPhotoPurchaseWidth"
                        ).val(),
                        contentTemplateElementId: parseInt(
                            $("#stockContentTemplateElementId").val(),
                            10
                        ),
                        userEnteredFilename: $("#userEnteredFilename").val(),
                        contentTemplateElementDataId: contentTemplateElementDataId,
                        elapsedSelectionTime: parseFloat(
                            (new Date().getTime() - elapsedSelectionTime) / 1000
                        ).toFixed(3)
                    },
                    dataType: "text",
                    url: urlAction,
                    success: function(data) {
                        var json,
                            attachmentId,
                            isImage,
                            uploadSpan,
                            js,
                            jsonString = "",
                            attachmentUrl = "",
                            imgTitle = "",
                            imgDesc = "",
                            type = null;
                        if (!validation.parse(data)) {
                            try {
                                attachmentId = $(data)
                                    .find(".attachmentId")
                                    .val();
                                uploadSpan = attachmentContainer.find(
                                    ".fileUploadSpan"
                                );
                                js = $(data).find("div.jsonString");
                                jsonString = $.trim(js.html());

                                js.remove();

                                json = $.parseJSON(jsonString);

                                if (null !== attachmentHidden) {
                                    attachmentHidden.val(json.attachmentId);
                                }

                                if (null !== attachment) {
                                    attachment.val(json.attachmentId);
                                }
                                attachmentUrl =
                                    json.templateInput.attachmentUrl;
                                imgDesc =
                                    json.templateInput.attachmentDescription;
                                imgTitle = json.templateInput.attachmentTitle;

                                if (true === embedded) {
                                    stockEmbed(
                                        attachmentId,
                                        $("#contentRequestId").val(),
                                        editorId,
                                        attachmentUrl,
                                        imgTitle,
                                        imgDesc,
                                        contentTemplateElementDataId
                                    );
                                } else {
                                    contentTemplate.openAttachment(
                                        attachmentId,
                                        contentTemplateElementDataId
                                    );
                                }
                                contentTemplate.attachment = contentTemplate.attachmentContainer.find(
                                    ">div.attachment"
                                );
                                localStorage.removeItem(
                                    "ImageSelectionStartTime"
                                );
                                overlay.init();
                            } catch (e) {
                                console.log("ERROR IN READING " + e);
                            }
                        }
                    },
                    error: function(data, status, err) {
                        var msg = "Error getting: " + urlAction + "\n\n";
                        msg += "Status: " + status + "\n\n";
                        console.log(msg);
                    }
                };
                useImage = call.ajax(urlAction, options);
                return false;
            },
            scroller = function() {
                var win = $(".stock-images");
                if (0 === win.length) {
                    scroller();
                } else {
                    win.scroll(null, function() {
                        if (
                            win.scrollTop() >
                            5 * 190 * pageCount - win.height()
                        ) {
                            search(cachedKeyword);
                        }
                    });
                }
            };
        return {
            init: function() {
                $(function() {
                    var html = $("html"),
                        body = $("body");
                    body.on(
                        "blur",
                        "#altTitle",
                        null,
                        skyword.validateContinue
                    );
                    body.on(
                        "blur",
                        "#imgTitle",
                        null,
                        skyword.validateContinue
                    );
                    body.on("click", "a.stock-photo", null, searchPhotos);
                    body.on("click", "div.stockimage a", null, stockPhotoImage);
                    body.on(
                        "click",
                        "button#searchStockPhotoId",
                        null,
                        searchPhotos
                    );
                    body.on("click", "button#load-stock", null, searchPhotos);
                    body.on("click", "button#do-stock", null, scroller);
                    body.on("click", "#searchReturnId", null, searchReturn);
                    body.on(
                        "click",
                        "#selectStockPhotoId",
                        null,
                        selectStockPhoto
                    );

                    body.on("keydown", "#stockPhotoKeywordId", null, function(
                        e
                    ) {
                        $("#searchStockPhotoId")
                            .removeAttr("disabled")
                            .removeClass("disabled");
                        if (13 === e.which) {
                            $("#searchStockPhotoId").trigger("click");
                            return false;
                        }
                    });

                    body.on("click", "#search-results-btn", function(e) {
                        e.preventDefault();
                        $("#stock-photo-used-btn").removeClass("button-active");
                        $(this).addClass("button-active");
                        $(".stock-images").fadeIn("fast");
                        $(".stock-photos-used").fadeOut("fast");
                        $("#searchStockPhotoId")
                            .removeAttr("disabled")
                            .removeClass("disabled");
                        $("#stockPhotoKeywordId").removeAttr("disabled");
                        $("#searchReturnId").removeClass("hidden");
                        $("#usedReturnId").addClass("hidden");
                    });

                    body.on("click", "#stock-photo-used-btn", function(e) {
                        e.preventDefault();
                        $("#search-results-btn").removeClass("button-active");
                        $(this).addClass("button-active");
                        $(".stock-images").fadeOut("fast");
                        $(".stock-photos-used").fadeIn("fast");
                        $("#searchStockPhotoId")
                            .attr("disabled", "disabled")
                            .addClass("disabled");
                        $("#stockPhotoKeywordId").attr("disabled", "disabled");
                        $("#searchReturnId").addClass("hidden");
                        $("#usedReturnId").removeClass("hidden");
                    });

                    body.on("click", "#usedReturnId", function(e) {
                        e.preventDefault();
                        $("#selected-photo").fadeOut("fast");
                        $("#stock-images-carousel").fadeOut("fast");
                        $(".stock-photos-used").fadeIn("fast");
                        $("#stockPhotoImage")
                            .removeAttr("src")
                            .removeAttr("title")
                            .removeAttr("alt");
                    });

                    body.on("click", ".carouselImg", function(e) {
                        $("#stockPhotoImage").css("opacity", 0);
                        changeSelectedImageFromCarousel($(this));
                    });
                    body.on("input", "#shutterStockColorParam", function(e) {
                        const color = e.target.value;
                        if (color === "rgba(0, 0, 0, 0)") {
                            //checking if rgb is NaN
                            const r = parseInt(color.substr(1, 2), 16);
                            const g = parseInt(color.substr(3, 2), 16);
                            const b = parseInt(color.substr(5, 2), 16);

                            if (isNaN(r) || isNaN(g) || isNaN(b)) {
                                // e.stopPropagation();
                                e.stopImmediatePropagation();
                                $("#shutterStockColorParam").val(
                                    $("#prevColorSelected").val()
                                );
                            }
                        } else {
                            $("#prevColorSelected").val(color);
                        }
                    });
                    $("html").on("keyup", "#userEnteredFilename", function() {
                        if ($(this).val().length > 0) {
                            validation.enableButton("selectStockPhotoId");
                        } else {
                            validation.disableButton("selectStockPhotoId");
                        }
                    });

                    cachedKeyword = $("#stockPhotoKeywordId").val();
                    pageCount = 1;
                });
            },
            stockPhotoFinish: function() {
                try {
                    if (true === embedded) {
                        attachmentContainer.fadeOut("fast");
                    }
                    embedded = false;
                } catch (e) {}
            },
            searchPhotoCallback: function() {
                searchPhotoCallback();
            },
            scroller: function() {
                scroller();
            }
        };
    }
]);

app.controller("help", ["$scope", function($scope) {}])
    .controller("zendesk", ["$scope", function($scope) {}])
    .controller("programGuidelines", [
        "$scope",
        "help",
        function($scope, help) {
            help.init();
        }
    ])
    .controller("editorialGuidelines", [
        "$scope",
        "help",
        function($scope, help) {
            help.init();
        }
    ])
    .controller("programHelp", [
        "$scope",
        "help",
        function($scope, help) {
            help.init();
        }
    ])
    .controller("helpArticleDeleteAjax", ["$scope", function($scope) {}])
    .controller("helpArticleDeleteSubmitAjax", ["$scope", function($scope) {}])
    .controller("programGuidelinesAjax", ["$scope", function($scope) {}])
    .controller("programGuidelinesArticleAjax", ["$scope", function($scope) {}])
    .controller("updateCompletedAjax", ["$scope", function($scope) {}])
    .controller("programReferenceModule", ["$scope", function($scope) {}])
    .controller("programReferenceOverlayAjax", ["$scope", function($scope) {}])
    .controller("programReferenceArticleAjax", ["$scope", function($scope) {}])
    .controller("contentStandardItems", ["$scope", function($scope) {}])
    .controller("platformAnnouncement", ["$scope", function($scope) {}])
    .controller("platformAnnouncementEdit", [
        "$scope",
        "help",
        function($scope, help) {
            help.init();

            //needed for new moment-datepicker
            $scope.dates = {
                startDate: null,
                endDate: null,
                format: null
            };

            //watches start date to make sure end date is not before start date
            $scope.$watch("[dates.startDate, dates.endDate]", function(
                newValue,
                oldValue
            ) {
                $scope.momentDateFormat = $("#momentPickerFormat").val();
                $scope.dateformat = "MM-DD-YYYY h:[00] A";

                $scope.dates.format = $scope.momentDateFormat + " h:[00] A";
                $scope.dates.format = $scope.dates.format.toString();

                if ($scope.dates.startDate != null) {
                    $scope.minDate = $scope.dates.startDate.toString();
                }
                if ($scope.dates.endDate != null) {
                    $scope.maxDate = $scope.dates.endDate.toString();
                }

                if (
                    $scope.dates.startDate == null &&
                    $scope.dates.endDate == null
                ) {
                    var startDateVal = $("#startTimeString").val();
                    var endDateVal = $("#stopTimeString").val();
                    $scope.dates.startDate = moment(
                        startDateVal,
                        $scope.dateformat
                    ).format($scope.dates.format);
                    $scope.dates.endDate = moment(
                        endDateVal,
                        $scope.dateformat
                    ).format($scope.dates.format);
                }

                if (
                    $scope.dates.startDate != null &&
                    $scope.dates.endDate != null
                ) {
                    $scope.startDateMoment = moment(
                        $scope.dates.startDate,
                        $scope.dates.format
                    )
                        .locale("en")
                        .format($scope.dateformat);
                    $scope.endDateMoment = moment(
                        $scope.dates.endDate,
                        $scope.dates.format
                    )
                        .locale("en")
                        .format($scope.dateformat);
                    $("#startTimeString").val($scope.startDateMoment);
                    $("#stopTimeString").val($scope.endDateMoment);
                }
            });
        }
    ])
    .controller("skywriterMessage", ["$scope", function($scope) {}])
    .controller("skywriterMessageEdit", [
        "$scope",
        "help",
        "redactor",
        function($scope, help, redactor) {
            help.init();
            redactor.init();

            //needed for new moment-datepicker
            $scope.dates = {
                startDate: null,
                endDate: null,
                format: null
            };

            //watches start date to make sure end date is not before start date
            $scope.$watch("[dates.startDate, dates.endDate]", function(
                newValue,
                oldValue
            ) {
                $scope.momentDateFormat = $("#momentPickerFormat").val();
                $scope.dateformat = "MM-DD-YYYY hh:[00] a";

                $scope.dates.format = $scope.momentDateFormat + " hh:[00] a";
                $scope.dates.format = $scope.dates.format.toString();

                if ($scope.dates.startDate != null) {
                    $scope.minDate = $scope.dates.startDate.toString();
                }
                if ($scope.dates.endDate != null) {
                    $scope.maxDate = $scope.dates.endDate.toString();
                }

                if (
                    $scope.dates.startDate == null &&
                    $scope.dates.endDate == null
                ) {
                    var startDateVal = $("#startTimeString").val();
                    var endDateVal = $("#stopTimeString").val();
                    $scope.dates.startDate = moment(
                        startDateVal,
                        $scope.dateformat
                    ).format($scope.dates.format);
                    $scope.dates.endDate = moment(
                        endDateVal,
                        $scope.dateformat
                    ).format($scope.dates.format);
                }

                if (
                    $scope.dates.startDate != null &&
                    $scope.dates.endDate != null
                ) {
                    $scope.startDateMoment = moment(
                        $scope.dates.startDate,
                        $scope.dates.format
                    )
                        .locale("en")
                        .format($scope.dateformat);
                    $scope.endDateMoment = moment(
                        $scope.dates.endDate,
                        $scope.dates.format
                    )
                        .locale("en")
                        .format($scope.dateformat);
                    $("#startTimeString").val($scope.startDateMoment);
                    $("#stopTimeString").val($scope.endDateMoment);
                }
            });
        }
    ])

    .controller("programuserMessageEdit", [
        "$scope",
        "help",
        "redactor",
        function($scope, help, redactor) {
            help.init();
            redactor.init();

            //needed for new moment-datepicker
            $scope.dates = {
                startDate: null,
                endDate: null,
                format: null
            };

            //watches start date to make sure end date is not before start date
            $scope.$watch("[dates.startDate, dates.endDate]", function(
                newValue,
                oldValue
            ) {
                $scope.momentDateFormat = $("#momentPickerFormat").val();
                $scope.dateformat = "MM-DD-YYYY hh:[00] a";

                $scope.dates.format = $scope.momentDateFormat + " hh:[00] a";
                $scope.dates.format = $scope.dates.format.toString();

                if ($scope.dates.startDate != null) {
                    $scope.minDate = $scope.dates.startDate.toString();
                }
                if ($scope.dates.endDate != null) {
                    $scope.maxDate = $scope.dates.endDate.toString();
                }

                if (
                    $scope.dates.startDate == null &&
                    $scope.dates.endDate == null
                ) {
                    var startDateVal = $("#startTimeString").val();
                    var endDateVal = $("#stopTimeString").val();
                    $scope.dates.startDate = moment(
                        startDateVal,
                        $scope.dateformat
                    ).format($scope.dates.format);
                    $scope.dates.endDate = moment(
                        endDateVal,
                        $scope.dateformat
                    ).format($scope.dates.format);
                }

                if (
                    $scope.dates.startDate != null &&
                    $scope.dates.endDate != null
                ) {
                    $scope.startDateMoment = moment(
                        $scope.dates.startDate,
                        $scope.dates.format
                    )
                        .locale("en")
                        .format($scope.dateformat);
                    $scope.endDateMoment = moment(
                        $scope.dates.endDate,
                        $scope.dates.format
                    )
                        .locale("en")
                        .format($scope.dateformat);
                    $("#startTimeString").val($scope.startDateMoment);
                    $("#stopTimeString").val($scope.endDateMoment);
                }
            });
        }
    ]);

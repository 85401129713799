(function() {
    app.controller("adminUnlockAccountAjax", ["$scope", function($scope) {}])
        .controller("adminXmlRpcLog", ["$scope", function($scope) {}])
        .controller("adminEnterpriseDivisionRegion", [
            "$scope",
            function($scope) {}
        ])
        .controller("adminXmlRpcLogView", ["$scope", function($scope) {}])
        .controller("adminSSOConfiguration", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
                $("select").each(function() {
                    $("select").selectBoxIt();
                });

                //onReady
                $(function() {
                    if ($(".enableSSO").length <= 0) {
                        $("#enableAccountsBtn").attr("disabled", "disabled");
                        $("label[for=select-all]").css("display", "none");
                    }
                });
                // array of account id's that are checked
                $("body").on("click", ".enableSSO", function() {
                    let accountIdList = $(".enableSSO:checked")
                        .map(function() {
                            return $(this).val();
                        })
                        .toArray();
                    $("#ssoEnableAccounts_selectedAccountIds").val(
                        accountIdList
                    );
                });
                $("body").on("click", "#select-all", function() {
                    let accountIdList = $(".enableSSO")
                        .map(function() {
                            return $(this).val();
                        })
                        .toArray();
                    $("#ssoEnableAccounts_selectedAccountIds").val(
                        accountIdList
                    );
                });
            }
        ])
        .controller("adminDashboard", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
                app.value("requestActive", false);
            }
        ])
        .controller("adminPrograms", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
                app.value("requestActive", false);
            }
        ])
        .controller("adminLogin", ["$scope", function($scope) {}])
        .controller("adminUpdateAccounts", ["$scope", function($scope) {}])
        .controller("adminEditAccount", [
            "$scope",
            "skyword",
            "admin",
            function($scope, skyword, admin) {
                admin.init();
                //onReady
                $(function() {
                    skyword.passwordStrength();
                });
            }
        ])
        .controller("adminAccount", ["$scope", function($scope) {}])
        .controller("adminUserAccounts", ["$scope", function($scope) {}])
        .controller("adminAccessAccounts", ["$scope", function($scope) {}])
        .controller("adminChangePasswordAjax", ["$scope", function($scope) {}])
        .controller("adminCreateAccount", [
            "$scope",
            "skyword",
            "admin",
            function($scope, skyword, admin) {
                //onReady
                $(function() {
                    skyword.passwordStrength();
                });
            }
        ])
        .controller("adminEditProgram", [
            "$scope",
            "admin",
            "crop",
            function($scope, admin, crop) {
                admin.init();
                crop.init();
                //console.log('IN ADMIN EDIT PROGRAM');
                $("select").each(function() {
                    $("select").selectBoxIt();
                });

                $("body").on("change", "#usesEscrow", function() {
                    var escrowBudgetValue = $("#escrowBudget").val();
                    if ($(this).prop("checked")) {
                        if (escrowBudgetValue == "") {
                            $("#escrowBudget").val("0.00");
                        }
                        $("#escrowBudgetWrapper").show();
                    } else {
                        $("#escrowBudgetWrapper").hide();
                    }
                });

                //onReady
                $(function() {
                    if ($("#usesEscrow").is(":checked")) {
                        $("#escrowBudgetWrapper").show();
                    } else {
                        $("#escrowBudgetWrapper").hide();
                    }
                });
            }
        ])
        .controller("adminEditContentType", [
            "$rootScope",
            "$scope",
            "admin",
            "digitalAsset",
            "SkyFactory",
            "AssetFactory",
            "$mdConstant",
            "$translate",
            "autoComplete",
            "taxonomy",
            function(
                $rootScope,
                $scope,
                admin,
                digitalAsset,
                SkyFactory,
                AssetFactory,
                $mdConstant,
                $translate,
                autoComplete,
                taxonomy
            ) {
                admin.init();
                autoComplete.init();

                //onReady
                $(function() {
                    $("#description").maxlength({
                        feedback: ".description-charsLeft"
                    });

                    $("#searchResultTypeId").on("change", function() {
                        switch (true) {
                            case this.value == 5:
                                $("#video-hosting").show();
                                $(".clientContentType").show();
                                $("#clientIdentifierToggle input").attr(
                                    "id",
                                    "clientIdentifier"
                                );
                                $("#clientIdentifierToggle input").attr(
                                    "class",
                                    "clientIdentifier"
                                );
                                break;
                            default:
                                $(".clientContentType").show();
                                $("#video-hosting").hide();
                                $("#clientIdentifierToggle input").attr(
                                    "id",
                                    "clientIdentifier"
                                );
                                $("#clientIdentifierToggle input").attr(
                                    "class",
                                    "clientIdentifier"
                                );
                        }
                    });
                });
            }
        ])
        .controller("adminImageProcessing", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
            }
        ])
        .controller("adminEditCategory", ["$scope", function($scope) {}])
        .controller("adminEditApiInfo", [
            "$scope",
            "admin",
            "programSettings",
            function($scope, admin, programSettings) {
                admin.init();
            }
        ])
        .controller("adminContentIngestion", [
            "$scope",
            "admin",
            function($scope, admin, programSettings) {
                admin.init();

                //needed for new moment-datepicker
                $scope.dates = {
                    startDate: null,
                    endDate: null,
                    format: null
                };

                //watches start date to make sure end date is not before start date
                $scope.$watch("[dates.startDate, dates.endDate]", function(
                    newValue,
                    oldValue
                ) {
                    $scope.momentDateFormat = $("#momentPickerFormat").val();
                    $scope.dateformat = "MM-DD-YYYY";

                    $scope.dates.format = $scope.momentDateFormat;
                    $scope.dates.format = $scope.dates.format.toString();

                    if (
                        $scope.dates.startDate == null &&
                        $scope.dates.endDate == null
                    ) {
                        var startDateVal = new Date();
                        var endDateVal = new Date();
                        $scope.dates.startDate = moment(
                            startDateVal,
                            $scope.dateformat
                        ).format($scope.dates.format);
                        $scope.dates.endDate = moment(
                            endDateVal,
                            $scope.dateformat
                        ).format($scope.dates.format);
                    }

                    if (
                        $scope.dates.startDate != null &&
                        $scope.dates.endDate != null
                    ) {
                        $scope.startDateMoment = moment(
                            $scope.dates.startDate,
                            $scope.dates.format
                        )
                            .locale("en")
                            .format($scope.dateformat);
                        $scope.endDateMoment = moment(
                            $scope.dates.endDate,
                            $scope.dates.format
                        )
                            .locale("en")
                            .format($scope.dateformat);
                        $("#startTimeString").val($scope.startDateMoment);
                        $("#stopTimeString").val($scope.endDateMoment);
                    }
                });
            }
        ])
        .controller("adminContentIngestionHistory", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
            }
        ])
        .controller("adminGenerateApiKeyAjax", ["$scope", function($scope) {}])
        .controller("adminTestApiConnectionAjax", [
            "$scope",
            function($scope) {}
        ])
        .controller("adminSyncApiTaxonomyAjax", ["$scope", function($scope) {}])
        .controller("adminEditPaymentTerms", [
            "$scope",
            function($scope) {
                //needed for new moment-datepicker
                $scope.dates = {
                    asOfDate: null
                };

                //watches start date to make sure end date is not before start date
                $scope.$watch("dates.asOfDate", function(newValue, oldValue) {
                    $scope.momentDateFormat = $("#momentPickerFormat").val();
                    $scope.dateformat = "MM-DD-YYYY";

                    if ($scope.dates.asOfDate == null) {
                        var startDateVal = $("#asOfDate").val();
                        $scope.dates.asOfDate = moment(
                            startDateVal,
                            $scope.dateformat
                        ).format($scope.momentDateFormat);
                    }

                    if ($scope.dates.asOfDate != null) {
                        $scope.startDateMoment = moment(
                            $scope.dates.asOfDate,
                            $scope.momentDateFormat
                        ).format($scope.dateformat);
                        $("#asOfDate").val($scope.startDateMoment);
                    }
                });
            }
        ])

        // credit terms
        .controller("adminEditCreditTerms", [
            "$scope",
            function($scope) {
                //needed for new moment-datepicker
                $scope.dates = {
                    asOfDate: null
                };

                //watches start date to make sure end date is not before start date
                $scope.$watch("dates.asOfDate", function(newValue, oldValue) {
                    $scope.momentDateFormat = $("#momentPickerFormat").val();
                    $scope.dateformat = "MM-DD-YYYY";

                    if ($scope.dates.asOfDate == null) {
                        var startDateVal = $("#asOfDate").val();
                        $scope.dates.asOfDate = moment(
                            startDateVal,
                            $scope.dateformat
                        ).format($scope.momentDateFormat);
                    }

                    if ($scope.dates.asOfDate != null) {
                        $scope.startDateMoment = moment(
                            $scope.dates.asOfDate,
                            $scope.momentDateFormat
                        ).format($scope.dateformat);
                        $("#asOfDate").val($scope.startDateMoment);
                    }
                });
            }
        ])
        // end of credit terms
        .controller("adminEditRole", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
            }
        ])
        .controller("adminViewRoles", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
                $("select").each(function() {
                    $("select").selectBoxIt();
                });
            }
        ])
        .controller("adminDeleteRole", ["$scope", function($scope) {}])
        .controller("adminAPIHistory", [
            "$scope",
            function($scope) {
                //needed for new moment-datepicker
                $scope.dates = {
                    startDate: null,
                    endDate: null
                };

                //watches start date to make sure end date is not before start date
                $scope.$watch("[dates.startDate, dates.endDate]", function(
                    newValue,
                    oldValue
                ) {
                    $scope.momentDateFormat = $("#momentPickerFormat").val();
                    $scope.dateformat = "MM-DD-YYYY";

                    if ($scope.dates.startDate != null) {
                        $scope.minDate = $scope.dates.startDate.toString();
                    }
                    if ($scope.dates.endDate != null) {
                        $scope.maxDate = $scope.dates.endDate.toString();
                    }

                    if (
                        $scope.dates.startDate == null &&
                        $scope.dates.endDate == null
                    ) {
                        var startDateVal = $("#startDateString").val();
                        var endDateVal = $("#endDateString").val();
                        if (startDateVal != "" && endDateVal != "") {
                            $scope.dates.startDate = moment(
                                startDateVal,
                                $scope.dateformat
                            ).format($scope.momentDateFormat);
                            $scope.dates.endDate = moment(
                                endDateVal,
                                $scope.dateformat
                            ).format($scope.momentDateFormat);
                        }
                    }

                    if (
                        $scope.dates.startDate != null &&
                        $scope.dates.endDate != null
                    ) {
                        $scope.startDateMoment = moment(
                            $scope.dates.startDate,
                            $scope.momentDateFormat
                        ).format($scope.dateformat);
                        $scope.endDateMoment = moment(
                            $scope.dates.endDate,
                            $scope.momentDateFormat
                        ).format($scope.dateformat);
                        $("#startDateString").val($scope.startDateMoment);
                        $("#endDateString").val($scope.endDateMoment);
                    }
                });
            }
        ])
        .controller("adminAPIHistoryView", ["$scope", function($scope) {}])
        .controller("adminAgencyToS", [
            "$scope",
            "redactor",
            function($scope, redactor) {
                redactor.init();
            }
        ])
        .controller("adminSelfReferralDomains", ["$scope", function($scope) {}])
        .controller("adminRemoveSelfReferralDomains", [
            "$scope",
            function($scope) {}
        ])
        .controller("adminEditEmailTemplates", ["$scope", function($scope) {}])
        .controller("adminTaxonomies", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
            }
        ])
        .controller("adminEditTaxonomy", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
            }
        ])
        .controller("adminViewTaxonomy", [
            "$scope",
            function($scope) {
                admin.init();
            }
        ])
        .controller("adminDeleteTaxonomy", ["$scope", function($scope) {}])
        .controller("adminEditScorecard", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
            }
        ])
        .controller("adminEditSeoScorecard", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
            }
        ])
        .controller("adminViewWorkflow", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
                app.value("requestActive", false);
            }
        ])
        .controller("adminViewViewableStates", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
            }
        ])
        .controller("adminUpdateCurrentStatesAjax", [
            "$scope",
            function($scope) {}
        ])
        .controller("adminEditWorkflow", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
            }
        ])
        .controller("adminEditViewableStates", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
            }
        ])
        .controller("adminEditContentTemplate", [
            "$scope",
            "admin",
            "adminTemplate",
            function($scope, admin, adminTemplate) {
                admin.init();
                adminTemplate.init();
            }

        ])
        .controller("adminEditContentTemplateElement", [
            "$scope",
            "admin",
            "adminTemplate",
            "geolocation",
            "call",
            "skyword",
            function($scope, admin, adminTemplate, geolocation, call, skyword) {
                admin.init();
                adminTemplate.init();
                geolocation.init(); // conditionally

                //grab inputs from image cropper
                $(document).ready(function() {
                    //hide image cropping inputs
                    $("#exactCropSize").hide();
                    $("#cropMinMax").hide();
                    $("#aspectRatio").hide();

                    //show div if radio is checked on ready
                    if (
                        $(
                            "input[name=imageSizeRestrictionTypeId]:checked"
                        ).val() == 2
                    ) {
                        $("#exactCropSize").show();
                    } else if (
                        $(
                            "input[name=imageSizeRestrictionTypeId]:checked"
                        ).val() == 3
                    ) {
                        $("#cropMinMax").show();
                    } else if (
                        $(
                            "input[name=imageSizeRestrictionTypeId]:checked"
                        ).val() == 4
                    ) {
                        $("#aspectRatio").show();
                    }

                    //disable min/max height for ratio calculation
                    $("#minRatioCropHeight").prop("readonly", true); //Disable
                    $("#maxRatioCropHeight").prop("readonly", true); //Disable

                    $("#imageCropping_RadioBtns").change(function() {
                        var selected_value = $(
                            "input[name='imageSizeRestrictionTypeId']:checked"
                        ).val();
                        console.log(selected_value);

                        if (selected_value == 1) {
                            console.log("no size");
                            $("#exactCropSize").hide();
                            $("#cropMinMax").hide();
                            $("#aspectRatio").hide();
                        } else if (selected_value == 2) {
                            console.log("exact size");
                            $("#exactCropSize").show();
                            $("#cropMinMax").hide();
                            $("#aspectRatio").hide();
                        } else if (selected_value == 3) {
                            console.log("min/ max size");
                            $("#cropMinMax").show();
                            $("#exactCropSize").hide();
                            $("#aspectRatio").hide();
                        } else if (selected_value == 4) {
                            console.log("aspect ratio");
                            $("#aspectRatio").show();
                            $("#exactCropSize").hide();
                            $("#cropMinMax").hide();
                        }
                    });

                    //change event for calculating ratio
                    $(
                        "#aspectRatioX, #aspectRatioY, #minRatioCropWidth, #maxRatioCropWidth"
                    ).on("change", function() {
                        var x = $("#aspectRatioX").val();
                        var y = $("#aspectRatioY").val();

                        if (x.length > 0 && y.length > 0) {
                            var minWidth = $("#minRatioCropWidth").val();
                            var maxWidth = $("#maxRatioCropWidth").val();
                            console.log("RATIO ENTERED: " + x + ":" + y);
                            var ratio = x / y;
                            if (minWidth.length > 0) {
                                console.log("MIN WIDTH ENTERED: " + minWidth);
                                var minHeight = minWidth / ratio;
                                $("#minRatioCropHeight").attr(
                                    "value",
                                    Math.floor(minHeight)
                                );
                            } else {
                                $("#minRatioCropHeight").attr("value", "");
                            }
                            if (maxWidth.length > 0) {
                                console.log("MAX WIDTH ENTERED: " + maxWidth);
                                var maxHeight = maxWidth / ratio;
                                $("#maxRatioCropHeight").attr(
                                    "value",
                                    Math.ceil(maxHeight)
                                );
                            } else {
                                $("#maxRatioCropHeight").attr("value", "");
                            }
                        } else {
                            $("#minRatioCropHeight").attr("value", null);
                            $("#maxRatioCropHeight").attr("value", null);
                        }
                    });

                    //change event for if delete width
                    $(
                        "#aspectRatioX, #aspectRatioY, #minRatioCropWidth, #maxRatioCropWidth"
                    ).on("change", function() {
                        var x = $("#aspectRatioX").val();
                        var y = $("#aspectRatioY").val();
                        var minWidth = $("#minRatioCropWidth").val();
                        var maxWidth = $("#maxRatioCropWidth").val();
                        var minHeight = $("#minRatioCropHeight").val();
                        var maxHeight = $("#maxRatioCropHeight").val();

                        if (!minWidth && minHeight) {
                            $("#minRatioCropHeight").attr("value", "");
                        } else if (!maxWidth && maxHeight) {
                            $("#maxRatioCropHeight").attr("value", "");
                        }
                    });
                });
                $('#parentContentElementId').change(function() {
                    var selectedValue = $(this).val();
                    var parentContentElementId = $("#parentContentElementId").val();
                    console.log("parentContentElementId: "+ parentContentElementId);
                    const urlParams = new URLSearchParams(window.location.search);
                    var elementId = urlParams.get("elementId")
                    if(selectedValue) {
                        console.log('Selected value:', selectedValue);
                        urlAction = "/adminEditContentTemplateElementAjax.action", getPost = "GET", options;
                        var selectedValue = $(this).val();
                        console.log('Selected value:', selectedValue);

                        var options = {
                            cache: false,
                            type: getPost, // Corrected to uppercase string
                            data: {parentContentElementId: parentContentElementId, elementId: elementId},//{ searchPromptTemplate: e.target.value.trim() }, // Uncomment and adjust if needed
                            url: urlAction,
                            success: function(data) {
                                let html = $.parseHTML(data);
                                $("#conditionalMatchTemplateElementWrapper").css("display", "block");
                                $("#conditionalMatchTemplateElementWrapper").html(html);
                            },
                            error: function(data) {
                                $("#conditionalMatchTemplateElementWrapper").css("display", "none");
                                console.log("Error: getActiveProgramsByName---------------: ", data);
                            }
                        };

                        $.ajax(options); // Corrected to pass `options` directly
                    //conditionalMatchTemplateElement
                    } else {
                        $("#conditionalMatchTemplateElementWrapper").css("display", "none");
                    }
                });
                $("body").on("change", ".selectedTaxonomy", function(e){
                    console.log("value", $('#'+e.target.id).is(":checked"));
                    console.log("name", e.target.name)
                    console.log("e: ", e.target.id)
                    var checkedValues = $(".selectedTaxonomy:checked").map(function() {
                        return this.value;
                    }).get();

                    console.log("checkedValues:::", checkedValues)
                    $("#selectedTax").val(checkedValues.join(","))
                });


            }
        ])
        .controller("adminTemplateContainer", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
            }
        ])
        .controller("adminContentTemplates", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
            }
        ])
        .controller("adminDeleteContentTemplate", [
            "$scope",
            function($scope) {}
        ])
        .controller("adminEnableContentTemplateElement", [
            "$scope",
            function($scope) {}
        ])
        .controller("adminDisableContentTemplateElement", [
            "$scope",
            function($scope) {}
        ])
        .controller("adminChangeTemplateDateAjax", [
            "$scope",
            function($scope) {}
        ])
        .controller("adminTemplateGetParentAjax", [
            "$scope",
            function($scope) {}
        ])
        .controller("adminUnlockWorkflow", ["$scope", function($scope) {}])
        .controller("adminTemplateBrowse", ["$scope", function($scope) {}])
        .controller("updateContentTemplateElementOrderAjax", [
            "$scope",
            function($scope) {}
        ])
        .controller("getPromptListAdminAjax", ["$scope", function($scope) {}])
        .controller("adminAgencyList", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
                app.value("requestActive", false);
            }
        ])
        .controller("adminAgencyProgramList", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
                app.value("requestActive", false);
            }
        ])
        .controller("adminAgencyAccountList", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
                app.value("requestActive", false);
            }
        ])
        .controller("adminEditAgency", [
            "$scope",
            "crop",
            function($scope, crop) {
                crop.init();
            }
        ])
        .controller("adminAPITest", ["$scope", function($scope) {}])
        .controller("apiTemplate", ["$scope", function($scope) {}])
        .controller("generateKey", ["$scope", function($scope) {}])
        .controller("adminEmailTest", [
            "$scope",
            "admin",
            "emailTest",
            function($scope, admin, emailTest) {
                admin.init();
                emailTest.init();
            }
        ])
        .controller("adminBackPopulate", [
            "$scope",
            "admin",
            function($scope, admin) {
                admin.init();
                app.value("requestActive", false);
            }
        ])
        .controller("adminBackPopulateAjax", ["$scope", function($scope) {}])
        .controller("adminCustomizeRoles", [
            "$scope",
            "skyword",
            "admin",
            function($scope, skyword, admin) {
                $scope.$watch("programId", function() {
                    admin.getCustomRoles($scope.programId, function(data) {
                        if ("" !== data) {
                            $scope.editableRoles = data.editorial;
                            $scope.approvalRoles = data.approval;
                            // add the file extensions and helpers to the array
                            for (var i = 0; i < data.length; i++) {
                                $scope.evalArrayItem(data[i], data[i]);
                            }
                        } else {
                            $scope.editableRoles = [];
                            $scope.approvalRoles = [];
                        }
                    });
                });

                $scope.save = function(role) {
                    console.log("Saving the following role");
                    console.log(role);
                    admin.saveCustomRole(role, function(data) {
                        console.log(data);
                        role.id = data.id;
                        role.customName = data.customName;
                    });
                };
                $scope.delete = function(role) {
                    if (
                        confirm(
                            'This will reset "' +
                                role.customName +
                                '" (ID: ' +
                                role.accountTypeId +
                                ') to "' +
                                role.originalName +
                                '"'
                        )
                    ) {
                        admin.deleteCustomRole(role, function(data) {
                            role.id = null;
                            role.customName = role.originalName;
                        });
                    }
                };
                $scope.validate = function(editable, role) {
                    if (editable === "" || editable === role.name) {
                        role.invalid = true;
                    } else {
                        role.invalid = false;
                    }
                    console.log(editable);
                    console.log(role.invalid);
                };
            }
        ])
        .controller("adminCustomizeStates", [
            "$scope",
            "skyword",
            "admin",
            function($scope, skyword, admin) {
                $scope.$watch("programId", function() {
                    console.log($scope.programId);
                    admin.getCustomStates($scope.programId, function(data) {
                        if ("" !== data) {
                            $scope.editorialStates = data.editorial;
                            $scope.externalStates = data.externalReview;
                            $scope.managerStates = data.manager;
                            $scope.approvalStates = data.approval;
                            // add the file extensions and helpers to the array
                            for (var i = 0; i < data.length; i++) {
                                $scope.evalArrayItem(data[i], data[i]);
                            }
                        } else {
                            $scope.editorialStates = [];
                            $scope.externalStates = [];
                            $scope.managerStates = [];
                            $scope.approvalStates = [];
                        }
                    });
                });

                $scope.save = function(state) {
                    console.log("Saving the following State");
                    console.log(state);
                    //code to save to backend
                    admin.saveCustomState(state, function(data) {
                        console.log(data);
                        state.id = data.id;
                        state.customButtonText = data.customButtonText;
                        state.customStateName = data.customStateName;
                    });
                };
                $scope.validate = function(editable, state) {
                    if ("" === editable || editable === state.name) {
                        state.invalid = true;
                    } else {
                        state.invalid = false;
                    }
                    console.log(editable);
                    console.log(state.invalid);
                };
                $scope.delete = function(state) {
                    if (
                        confirm(
                            'This will reset "' +
                                state.customStateName +
                                '"/"' +
                                state.customButtonText +
                                '" (ID: ' +
                                state.stateId +
                                ') to "' +
                                state.originalName +
                                '"/"' +
                                state.originalButtonText +
                                '"'
                        )
                    ) {
                        admin.deleteCustomState(state, function(data) {
                            state.id = null;
                            state.customButtonText = state.originalButtonText;
                            state.customStateName = state.originalName;
                        });
                    }
                };
                //$scope.load();
            }
        ])
        .controller("adminCustomizeMessages", ["$scope", function($scope) {}])
        .controller("adminWorkflowDetail", [
            "$scope",
            "skyword",
            "admin",
            "call",
            function($scope, skyword, admin, call) {
                $scope.$watch("programId", function() {
                    console.log("programId=" + $scope.programId);
                    console.log("libraryId=" + $scope.workflowLibraryId);
                    console.log("editable=" + $scope.editable);
                    admin.getWorkflowLibrary(
                        $scope.programId,
                        $scope.workflowLibraryId,
                        function(data) {
                            if ("" !== data) {
                                console.log(data);
                                $scope.workflowLibrary = data;
                                if (data.enableSubmitOverride) {
                                    $("#enableSubmitOverride").prop(
                                        "checked",
                                        true
                                    );
                                }
                                // add the file extensions and helpers to the array
                                for (var i = 0; i < data.length; i++) {
                                    $scope.evalArrayItem(data[i], data[i]);
                                }
                            }
                        }
                    );
                });
                $scope.save = function(workflowLibrary) {
                    console.log("save button clicked...");
                    console.log(workflowLibrary);
                    admin.saveWorkflowLibrary(
                        $scope.programId,
                        workflowLibrary,
                        function(data) {
                            console.log(data);
                            workflowLibrary.id = data.id;
                            workflowLibrary.libraryName = data.libraryName;
                        }
                    );
                };
                $scope.validate = function(libraryName, workflowLibrary) {
                    if (libraryName.length === 0 || !libraryName.trim()) {
                        workflowLibrary.invalid = true;
                    } else {
                        workflowLibrary.invalid = false;
                    }
                };

                $("#deleteWorkflowBtn").on("click", function(e) {
                    e.preventDefault();
                    const targetProgramId = new URLSearchParams(
                        window.location.search
                    ).get("targetProgramId");
                    const workflowLibraryId = new URLSearchParams(
                        window.location.search
                    ).get("workflowLibraryId");

                    var options = {
                        targetProgramId: targetProgramId,
                        workflowLibraryId: workflowLibraryId
                    };
                    var result = call.overlay(
                        "/deleteAdminWorkflowDetailAjax.action",
                        options,
                        700,
                        null
                    );
                    return result;
                });

                $("#enableSubmitOverride").on("change", function(e) {
                    admin.updateEnableSubmitOverride(
                        $scope.programId,
                        $scope.workflowLibraryId,
                        e.target.checked,
                        function(data) {
                            console.log(data);
                        }
                    );
                });
            }
        ])
        .controller("adminCompetitorDomain", ["$scope", function($scope) {}])
        .controller("adminAtomization", ["$scope", function($scope) {}])
        .controller("asyncPromptGen", ["$scope", function($scope) {}]);
})();

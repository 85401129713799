(function() {
    app.controller("digitalAssetSearch", [
        "$scope",
        "digitalAsset",
        "search",
        "$timeout",
        "crop",
        "SkyFactory",
        "assetCrop",
        "$rootScope",
        "sanitizer",
        "contentTemplate",
        "TinymceService",
        function(
            $scope,
            digitalAsset,
            search,
            $timeout,
            crop,
            SkyFactory,
            assetCrop,
            $rootScope,
            sanitizer,
            contentTemplate,
            TinymceService
        ) {
            $scope.noResults = false;
            $scope.agencyId = $("#attrs-id").attr("data-agencyId");
            $scope.accountTypeId = $("#attrs-id").attr("data-accounttypeid");
            console.log("accountTypeId =" + $scope.accountTypeId);

            $scope.data = {
                allAssets: [],
                assets: [],
                load: 15,
                index: 0,
                assetCropPreview: null,
                elements: [],
                textSearch: "",
                agencySearch: false,
                imageToCrop: null,
                attachmentId: null,
                contentRequestId: null,
                redactorId: $rootScope.redactorid,
                prepareImage: false,
                programId: $rootScope.programId,
                pageCount: 1,
                title: "",
                description: "",
                s3Preview: "",
                imageWidth: null,
                imageHeight: null
            };

            $scope.cropDTO = {
                cropX1: null,
                cropX2: null,
                cropY1: null,
                cropY2: null,
                cropW: null,
                cropH: null,
                accountId: null,
                attachmentId: null,
                contentTemplateElementDataId:
                    $rootScope.contentTemplateElementDataId,
                contentRequestId: $rootScope.contentRequestId,
                csrfToken: $rootScope.csrfToken,
                transferToS3: false,
                imageTitle: null,
                imageAltText: null,
                description: null
            };

            //when close button is clicked
            $scope.closeOverlay = function() {
                $("body").removeAttr("style");
                $.colorbox.close();
            };

            //when colorbox corner X is clicked
            $("#cboxClose").click(function() {
                $("body").removeAttr("style");
                $.colorbox.close();
            });

            $("#cboxOverlay").click(function() {
                $("body").removeAttr("style");
                $.colorbox.close();
            });

            //resize height of modal when window size changes
            $(document).ready(function() {
                $(window).bind("resize", function() {
                    if ($("#colorbox").css("display") == "block") {
                        $("#colorbox").colorbox.resize({ height: "75%" });
                        $scope.sizeInner();
                    }
                });
            });

            $scope.assetScroll = function() {
                var assetLoad = $scope.data.load;
                $scope.allAssets = $scope.data.allAssets.length;
                $scope.nextSet = function() {
                    var nextIndex = $scope.data.index + $scope.data.load;
                    var len = Math.min($scope.data.allAssets.length, nextIndex);
                    var arr = $scope.data.allAssets.slice(
                        $scope.data.index,
                        len
                    );

                    if (0 === arr.length) {
                        return;
                    }

                    for (var i = 0; i < arr.length; i++) {
                        $scope.data.assets.push(arr[i]);
                    }
                    $scope.data.index += 15;
                };
                $scope.nextSet();
                $scope.loadMore = function() {
                    $scope.nextSet();
                };
            };

            $scope.chooseAsset = function(attachmentId) {
                $scope.cropDTO.attachmentId = attachmentId;
                var attachmentPromise = digitalAsset.getAssetAttachment(
                    attachmentId
                );
                // show the preloader
                var ldr = $(".assetresult")
                    .find("[data-attachmentid='" + attachmentId + "']")
                    .parent()
                    .find(".loader");
                ldr.show();

                attachmentPromise.then(function(attachment) {
                    // hide the preloader
                    ldr.hide();
                    // FIX = begin SKY-16792
                    $(".articleElement[data-ctet='16'] .attachmentId").val(
                        attachment.data.id
                    );
                    $(".articleElement[data-ctet='16'] .attachmentElement").val(
                        attachment.data.id
                    );
                    // FIX = end SKY-16792
                    $scope.data.assetCropPreview =
                        attachment.data.assetCropPreview;
                    $scope.data.s3Preview = attachment.data.s3Preview;
                    $scope.data.imageHeight = attachment.data.imageHeight;
                    $scope.data.imageWidth = attachment.data.imageWidth;

                    if (
                        $scope.data.imageHeight == "" ||
                        $scope.data.imageHeight === null
                    ) {
                        var img = new Image();
                        img.onload = function() {
                            $scope.data.imageHeight = img.naturalHeight;
                            $scope.data.imageWidth = img.naturalWidth;
                            $scope.$apply();
                        };
                        img.src = attachment.data.assetReviewPreview;
                    }

                    var metaPromise = digitalAsset.getDigitalAssetMetaInfo(
                        attachment.data.contentRequestId
                    );
                    metaPromise.then(function(meta) {
                        $scope.data.elements = meta.data;
                    });
                });
                $("#cboxLoadedContent").animate({ scrollTop: 0 }, 1000);
            };

            $scope.embedded = true;

            $scope.setupCropAsset = function() {
                var cropPromise = digitalAsset.getAssetAttachmentToCrop(
                    $scope.cropDTO.attachmentId,
                    $scope.cropDTO.contentTemplateElementDataId,
                    $scope.cropDTO.contentRequestId
                );
                cropPromise.then(function(attachment) {
                    //console.info(attachment);
                    $scope.embedded = attachment.data.embedded;

                    if (false === attachment.data.embedded) {
                        contentTemplate.openAttachment(
                            attachment.data.id,
                            attachment.data.contentTemplateElementDataId
                        );
                        //$.colorbox({scrolling: true});
                    } else {
                        $scope.data.imageToCrop = attachment.data.local1000Url;
                        $scope.cropDTO.attachmentId = attachment.data.id;
                        crop.check();
                        $scope.data.elements.forEach(function(k, v) {
                            if ("asset-title" === k.apiNodeName) {
                                $scope.data.title = k.displayName;
                                $scope.cropDTO.imageTitle = $scope.cropDTO.imageAltText =
                                    k.digitalAssetValue;
                            } else if ("asset-description" === k.apiNodeName) {
                                $scope.cropDTO.description =
                                    k.digitalAssetValue;
                                $scope.data.description = k.displayName;
                            }
                        });
                    }
                });
            };

            $scope.returnToSearchResults = function() {
                $scope.data.assetCropPreview = null;
                $scope.data.imageToCrop = null;
                $scope.cropDTO.attachmentId = null;
                $scope.data.elements = [];
            };

            $scope.search = function() {
                var searchPromise = search.search({
                    searchResultTypeId: 6,
                    textSearch: $scope.data.textSearch,
                    programId: $scope.data.programId,
                    reuse: true,
                    searchType:
                        null === $scope.dam.searchResultTypeId
                            ? "searchTypeProgram"
                            : null,
                    agencySearch: $scope.data.agencySearch,
                    agencyId: $scope.agencyId
                });

                searchPromise.then(function(searchResults) {
                    $scope.sizeInner();

                    $scope.data.assets = [];
                    $scope.data.allAssets = searchResults.response.docs;
                    $scope.data.pageCount = 0;
                    $scope.data.index = 0;
                    $scope.assetScroll();
                    if ($scope.data.assets.length == 0) {
                        $scope.noResults = true;
                    } else if ($scope.data.assets.length > 0) {
                        $scope.noResults = false;
                    }
                });
            };

            $scope.cropImage = function(c) {
                $scope.cropDTO.cropX1 = angular.element("#x1").val();
                $scope.cropDTO.cropX2 = angular.element("#x2").val();
                $scope.cropDTO.cropY1 = angular.element("#y1").val();
                $scope.cropDTO.cropY2 = angular.element("#y2").val();
                $scope.cropDTO.cropW = angular.element("#w").val();
                $scope.cropDTO.cropH = angular.element("#h").val();
                jQuery("#colorbox").colorbox.resize({ height: 300 });
                $scope.data.prepareImage = true;

                var cleanMetaInfoPromise = sanitizer.cleanAttachmentMetaInformation(
                    angular.copy($scope.cropDTO)
                );

                cleanMetaInfoPromise.then(function(cleanMeta) {
                    //console.info(cleanMeta);
                    $scope.cropDTO.imageAltText = cleanMeta.data.imageAltText;
                    $scope.cropDTO.imageTitle = cleanMeta.data.imageTitle;

                    var cropPromise = assetCrop.crop($scope.cropDTO);

                    cropPromise.then(function(crop) {
                        if (true === crop.data.imageAttachment) {
                            insertDigitalAssetAttachment(crop.data);
                        } else {
                            insertDigitalAsset(crop.data);
                        }
                        $("body").removeAttr("style");
                        $.colorbox.close();
                        jQuery("#" + $rootScope.uploadContainerId).fadeOut();
                    });
                });
            };

            $scope.cancelCropImage = function() {
                $("body").removeAttr("style");
                if (
                    window.localStorage.getItem("uploadUsing") !== null &&
                    window.localStorage.getItem("uploadUsing") === "asset"
                ) {
                    let uploadUsingId = window.localStorage.getItem(
                        "uploadUsingId"
                    );
                    let element = $("#" + uploadUsingId);
                    $(element).click();
                }
            };

            /*******************
             * Show Hide Disable Setup methods
             *******************/
            $scope.showSearch = function() {
                if (
                    (SkyFactory.reallyEmptyCheck($scope.data.imageToCrop) &&
                        !$scope.data.prepareImage) == true
                ) {
                    $("#colorbox").colorbox.resize({ height: "75%" });

                    if ($("#colorbox").css("display") == "block") {
                        // $('body').css({overflow : 'hidden'});
                    }
                }
                return (
                    SkyFactory.reallyEmptyCheck($scope.data.imageToCrop) &&
                    !$scope.data.prepareImage
                );
            };

            $scope.showSearchResults = function() {
                return (
                    SkyFactory.reallyEmptyCheck($scope.data.assetCropPreview) &&
                    SkyFactory.reallyEmptyCheck($scope.data.imageToCrop) &&
                    !$scope.data.prepareImage
                );
            };

            $scope.showSelectedAssetPreview = function() {
                if (
                    (!SkyFactory.reallyEmptyCheck(
                        $scope.data.assetCropPreview
                    ) &&
                        SkyFactory.reallyEmptyCheck($scope.data.imageToCrop) &&
                        !$scope.data.prepareImage) == true
                ) {
                    $("#colorbox").colorbox.resize({ height: "75%" });
                    if ($("#colorbox").css("display") == "block") {
                        // $('body').css({overflow : 'hidden'});
                    }
                }
                return (
                    !SkyFactory.reallyEmptyCheck(
                        $scope.data.assetCropPreview
                    ) &&
                    SkyFactory.reallyEmptyCheck($scope.data.imageToCrop) &&
                    !$scope.data.prepareImage
                );
            };

            $scope.showAssetCrop = function() {
                if (
                    (!SkyFactory.reallyEmptyCheck($scope.data.imageToCrop) &&
                        !$scope.data.prepareImage) == true
                ) {
                    $("#colorbox").colorbox.resize({ height: "85%" });
                    if ($("#colorbox").css("display") == "block") {
                        // $('body').css({overflow : 'hidden'});
                    }
                }
                return (
                    !SkyFactory.reallyEmptyCheck($scope.data.imageToCrop) &&
                    !$scope.data.prepareImage
                );
            };

            $scope.disableInsertImage = function() {
                return (
                    SkyFactory.reallyEmptyCheck($scope.cropDTO.imageAltText) ||
                    SkyFactory.reallyEmptyCheck($scope.cropDTO.imageTitle)
                );
            };

            /*******************
             * Private Methods
             *******************/
            function insertDigitalAsset(crop) {
                var redEditor = $("#" + $scope.data.redactorId),
                    altText = SkyFactory.reallyEmptyCheck(
                        $scope.cropDTO.description
                    )
                        ? $scope.cropDTO.imageTitle
                        : $scope.cropDTO.description,
                    src =
                        crop.fileUrl +
                        "?r=" +
                        (crop.contentTemplateElementDataId + crop.attachmentId),
                    image = '<img src="{0}" alt="{1}" title="{2}"/>',
                    html = image
                        .replace("{0}", src)
                        .replace("{1}", altText)
                        .replace("{2}", $scope.cropDTO.imageTitle);
                TinymceService.insertHtml(tinyMCE.activeEditor, html);
            }

            $scope.dam = {
                elements: [],
                assets: [],
                allAssets: [],
                noResults: false,
                index: 0,
                pageCount: 0,
                load: 15,
                textSearch: "",
                programId: $scope.programId,
                attachmentId: null,
                resourcePreview: "",
                imageHeight: null,
                imageWidth: null,
                enabled: false,
                reuseDigitalAssetDTO: {
                    contentRequestId:
                        undefined !== $scope.contentRequestId
                            ? $scope.contentRequestId
                            : null,
                    calendarEventId:
                        undefined !== $scope.calendarEventId
                            ? $scope.calendarEventId
                            : null,
                    projectId:
                        undefined !== $scope.projectId
                            ? $scope.projectId
                            : null,
                    programId: $scope.programId,
                    attachmentId: null
                },
                contentTypes: [],
                contentType: {}
            };

            $scope.$watch("data.agencySearch", function(newValue, oldValue) {
                $scope.search();
            });

            $scope.sizeInner = function() {
                $("#colorbox .asset-images").height(
                    $("#cboxLoadedContent").height() - 170
                );
            };

            function insertDigitalAssetAttachment(crop) {
                $scope.dam.attachmentId = crop.attachmentId;
                $scope.dam.reuseDigitalAssetDTO.attachmentId =
                    crop.attachmentId;
                var attachmentPromise = digitalAsset.getAssetAttachment(
                    crop.attachmentId
                );

                attachmentPromise.then(function(attachment) {
                    $scope.dam.assetCropPreview =
                        attachment.data.assetCropPreview;
                    $scope.dam.resourcePreview =
                        attachment.data.resourcePreview;
                    $scope.dam.s3Preview = attachment.data.s3Preview;
                    $scope.dam.imageHeight = attachment.data.imageHeight;
                    $scope.dam.imageWidth = attachment.data.imageWidth;
                    var metaPromise = digitalAsset.getDigitalAssetMetaInfo(
                        attachment.data.contentRequestId
                    );
                    metaPromise.then(function(meta) {
                        $scope.dam.elements = meta.data;
                    });
                });
            }
        }
    ]);
})();

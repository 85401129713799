(function() {
    app.directive("googleplace", [
        "$translate",
        function($translate) {
            return {
                require: "ngModel",
                scope: {
                    ngModel: "=",
                    details: "=?"
                },
                link: function(scope, element, attrs, model) {
                    var options = {
                        types: ["(cities)"]
                        // ,componentRestrictions: {}
                    };

                    scope.gPlace = new google.maps.places.Autocomplete(
                        element[0],
                        options
                    );
                    if (null !== scope.$parent) {
                        scope.$parent.validLocation = false;
                    }
                    if (null !== scope.$parent.$parent) {
                        scope.$parent.$parent.validLocation = false;
                    }
                    if (null !== scope.$parent.$parent.$parent) {
                        scope.$parent.$parent.$parent.validLocation = false;
                    }

                    element.keydown(function(e) {
                        if (e.which == 13 || e.which == 9) {
                            e.preventDefault();
                            window.stop();
                            document.execCommand("Stop");
                            return false;
                        }
                        if (null !== scope.$parent) {
                            scope.$parent.validLocation = false;
                        }
                        if (null !== scope.$parent.$parent) {
                            scope.$parent.$parent.validLocation = false;
                        }
                        if (null !== scope.$parent.$parent.$parent) {
                            scope.$parent.$parent.$parent.validLocation = false;
                        }
                        if (null !== scope.$parent) {
                            scope.$parent.loc = {};
                        }
                        if (null !== scope.$parent.$parent) {
                            scope.$parent.$parent.loc = {};
                        }
                        if (null !== scope.$parent.$parent.$parent) {
                            scope.$parent.$parent.$parent.loc = {};
                        }
                    });

                    google.maps.event.addListener(
                        scope.gPlace,
                        "place_changed",
                        function() {
                            var geoComponents = scope.gPlace.getPlace();
                            var latitude = geoComponents.geometry.location.lat();
                            var longitude = geoComponents.geometry.location.lng();
                            var addressComponents =
                                geoComponents.address_components;

                            addressComponents = addressComponents
                                .filter(function(component) {
                                    switch (component.types[0]) {
                                        case "locality": // city
                                            return true;
                                        case "administrative_area_level_1": // state
                                            return true;
                                        case "country": // country
                                            return true;
                                        default:
                                            return false;
                                    }
                                })
                                .map(function(obj) {
                                    return obj.short_name;
                                });

                            var concatenatedAddress = addressComponents.join(
                                ", "
                            );
                            var outPt = {
                                formattedAddress: concatenatedAddress,
                                latitude: latitude,
                                longitude: longitude
                            };
                            //addressComponents.push(latitude, longitude);

                            scope.$apply(function() {
                                scope.details = outPt; // object containing each location component
                                model.$setViewValue(element.val());

                                if (null !== scope.$parent) {
                                    scope.$parent.validLocation = true;
                                }
                                if (null !== scope.$parent.$parent) {
                                    scope.$parent.$parent.validLocation = true;
                                }
                                if (null !== scope.$parent.$parent.$parent) {
                                    scope.$parent.$parent.$parent.validLocation = true;
                                }

                                if (null !== scope.$parent) {
                                    scope.$parent.loc = scope.details;
                                }
                                if (null !== scope.$parent.$parent) {
                                    scope.$parent.$parent.loc = scope.details;
                                }
                                if (null !== scope.$parent.$parent.$parent) {
                                    scope.$parent.$parent.$parent.loc =
                                        scope.details;
                                }

                                if (null !== scope.$parent) {
                                    scope.$parent.locationError = "";
                                }
                                if (null !== scope.$parent.$parent) {
                                    scope.$parent.$parent.locationError = "";
                                }
                                if (null !== scope.$parent.$parent.$parent) {
                                    scope.$parent.$parent.$parent.locationError =
                                        "";
                                }
                            });
                        }
                    );
                }
            };
        }
    ]);
})();

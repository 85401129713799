app.controller("videoupload", [
    "$scope",
    "$translate",
    "$http",
    "$filter",
    "$timeout",
    "skyword",
    "kalturaService",
    "attachmentService",
    "videoUtilService",
    "$cookies",
    function(
        $scope,
        $translate,
        $http,
        $filter,
        $timeout,
        skyword,
        kalturaService,
        attachmentService,
        videoUtilService,
        cookies
    ) {
        // initialize
        $scope.isUploadInProgress = false;
        $scope.isUploadFinished = false;

        //////////////////////////////
        var videoId;
        var isVideoPlaying = false;
        var hasVideoProcessed = false;
        var numberOfTries = 1;

        // Set up the loading overlays for the thumbnail images.
        var spinnerOptions = {
            lines: 13, // The number of lines to draw
            length: 10, // The length of each line
            width: 6, // The line thickness
            radius: 15, // The radius of the inner circle
            corners: 1, // Corner roundness (0..1)
            rotate: 270, // The rotation offset
            direction: 1, // 1: clockwise, -1: counterclockwise
            color: "#fff", // #rgb or #rrggbb or array of colors
            speed: 1.5, // Rounds per second
            trail: 60, // Afterglow percentage
            shadow: false, // Whether to render a shadow
            hwaccel: false, // Whether to use hardware acceleration
            className: "spinner", // The CSS class to assign to the spinner
            zIndex: 2e9, // The z-index (defaults to 2000000000)
            top: "50%", // Top position relative to parent
            left: "50%" // Left position relative to parent
        };
        var loaderPanels = document.getElementsByClassName("loading");
        for (var i = 0; i < loaderPanels.length; i++) {
            var spinner = new Spinner(spinnerOptions).spin(loaderPanels[i]);
        }

        // Set up the "processing" graphic which shows when video is not yet available.
        var spinnerOptionsVideo = {
            lines: 12, // The number of lines to draw
            length: 8, // The length of each line
            width: 4, // The line thickness
            radius: 9, // The radius of the inner circle
            corners: 1, // Corner roundness (0..1)
            rotate: 270, // The rotation offset
            direction: 1, // 1: clockwise, -1: counterclockwise
            color: "#737373", // #rgb or #rrggbb or array of colors
            speed: 1.5, // Rounds per second
            trail: 60, // Afterglow percentage
            shadow: false, // Whether to render a shadow
            hwaccel: false, // Whether to use hardware acceleration
            className: "spinner", // The CSS class to assign to the spinner
            zIndex: 2e9, // The z-index (defaults to 2000000000)
            top: "50%", // Top position relative to parent
            left: "50%" // Left position relative to parent
        };
        var videoLoader = document.getElementById("spinner");
        var videoSpinner = new Spinner(spinnerOptionsVideo).spin(videoLoader);

        // Embed and play the video — or show the custom "processing graphic"

        window.jsCallbackReady = function(playerId) {
            var thePlayer = (window.thePlayer = document.getElementById(
                playerId
            ));
            thePlayer.addJsListener(
                "playerUpdatePlayhead",
                "playerUpdatePlayheadHandler"
            );
            /*
                 NOTE:
                 This call kicks off the dynamic embedding loop (explained below).
                 The 3 second delay prevents the "processing" graphic from appearing
                 if the video was available on the initial call to embed it.
                 */
            setTimeout(function() {
                tryVideoPlayer(thePlayer);
            }, 3000);
        };

        window.playerUpdatePlayheadHandler = function(data, id) {
            if (!hasVideoProcessed) {
                hasVideoProcessed = true;
                isVideoPlaying = true;
                console.log("VIDEO IS PLAYING");
                $("#videoContainer .loading-message").hide();
                $("#videoContainer").css({ height: "352px" });
            }
        };

        window.embedVideo = function(entryId) {
            videoId = entryId;
            console.log("EMBED PLAYER: entry_id  = " + entryId);
            mw.setConfig("Kaltura.LeadWithHTML5", true);
            kWidget.embed({
                targetId: "videoPlayer",
                wid: "_1737042",
                uiconf_id: 23937082,
                flashvars: {
                    allowFullScreen: true,
                    allowNetworking: "all",
                    allowScriptAccess: "always",
                    autoPlay: true
                },
                cache_st: 1406907495,
                entry_id: entryId
            });
        };

        /*
             NOTE:
             The try / reset loop established here was created to dynamically
             embed the video into the player without having to refresh the page
             once Kaltura has finished processing it. For some reason, it does
             not work, even when requesting the video AFTER processing has finished.
             This requires the user to refresh the page before the video will play.
             I am leaving the code as-is because if there is a way to dynamically poll
             the "availaibility" of the video file, this will function as intended.
             For now, it works as people expect.
             */

        function tryVideoPlayer(thePlayer) {
            // NOTE: use this line below to test with a video that you know has finished processing
            // entryId = '1_pkwasf7g';

            setTimeout(function() {
                if (!isVideoPlaying) {
                    console.log(
                        "TRYING to load video ID: " +
                            videoId +
                            " into #" +
                            thePlayer.id +
                            " attempts: " +
                            numberOfTries
                    );
                    $("#videoContainer").css({ height: "66px" });
                    thePlayer.sendNotification("changeMedia", {
                        entryId: videoId
                    });
                    thePlayer.sendNotification("doPlay");
                    numberOfTries = numberOfTries + 1;
                    setTimeout(function() {
                        console.log("RESET PLAYER CALLED");
                        resetVideoPlayer(thePlayer);
                    }, 4000);
                }
            }, 2000);
        }

        function resetVideoPlayer(thePlayer) {
            setTimeout(function() {
                if (!isVideoPlaying) {
                    thePlayer.sendNotification("cleanMedia");
                    console.log("PLAYER HAS RESET");
                    tryVideoPlayer(thePlayer);
                }
            }, 3000);
        }

        //////////////////////////////

        window.addFileToKalturaResponse = function(response) {
            $scope.$apply(function() {
                $scope.addFileToKalturaResponse(response);
            });
        };

        /**
         * A video is being uploaded on Kaltura, keep track of it
         */
        $scope.addFileToKalturaResponse = function(response) {
            if (typeof $scope.listFileUploadProgress == "undefined") {
                $scope.listFileUploadProgress = [];
            }
            // add video record
            $scope.listFileUploadProgress.push({
                fileName: response.fileName,
                isUploadDone: false
            });
        };

        /**
         * Show thumbnail
         */

        $scope.showThumbnail = function(entryId) {
            console.log("entryId = " + entryId);
            if (
                typeof entryId == "undefined" ||
                entryId == null ||
                entryId == ""
            ) {
                return;
            }
            // content sample : get duration
            kalturaService.getDuration(entryId, function(msDuration) {
                // set duration
                $scope.duration = msDuration;
                // set Kaltura entry id
                $scope.entryId = entryId;
                // set 3 thumbnails
                $scope.thumbnail1Second = 2;
                $scope.thumbnail2Second = Math.floor(msDuration / 2000);
                $scope.thumbnail3Second = Math.floor(msDuration / 1000) - 1;
                // set 5 seconds time out (avoid 404)
                $timeout(function() {
                    $scope.thumbnail1 =
                        "https://cdnsecakmi.kaltura.com/p/1737042/thumbnail/entry_id/" +
                        entryId +
                        "/width/165/height/93/type/1/vid_sec/" +
                        $scope.thumbnail1Second;
                    $scope.thumbnail2 =
                        "https://cdnsecakmi.kaltura.com/p/1737042/thumbnail/entry_id/" +
                        entryId +
                        "/width/165/height/93/type/1/vid_sec/" +
                        $scope.thumbnail2Second;
                    $scope.thumbnail3 =
                        "https://cdnsecakmi.kaltura.com/p/1737042/thumbnail/entry_id/" +
                        entryId +
                        "/width/165/height/93/type/1/vid_sec/" +
                        $scope.thumbnail3Second;
                }, 5000);
                // upload done false
                $scope.isUploadFinished = true;
            });
        };

        /**
         * If we deal with an attachment: add attachment entry in database and will return it with an id set
         * If we deal with a content sample: get the duration and set the 3 thumbnails possibilities
         */

        $scope.addVideo = function(entryId, fileName, fileSize) {
            // are we dealing with a content sample or a rought cut (attachment)
            if (
                typeof $scope.action != "undefined" &&
                $scope.action == "addContentSample"
            ) {
                $scope.showThumbnail(entryId);
                // kicks off the embed video loop once Kaltura returns an entryId
                window.embedVideo(entryId);
            } else {
                // attachment
                $scope.attachment = {
                    externalValueId: entryId,
                    fileName: fileName,
                    fileSize: fileSize
                };
                // set cted
                $scope.attachment.contentTemplateElementDataId =
                    $scope.contentTemplateElementDataId;
                $scope.attachment.contentRequestId = angular
                    .element("#contentRequestId")
                    .val();
                $scope.attachment.contentId = angular
                    .element("#contentId")
                    .val();
                attachmentService.addVideo($scope.attachment, function(data) {
                    // set id using the data returned
                    $scope.attachment.id = data.id;
                });
            }
        };

        /**
         * Update thumbnail on Katura given a entryId and a second
         */
        $scope.updateThumbnail = function(entryId, second, thumbnailSelect) {
            $scope.thumbnailSelect = thumbnailSelect;
            // update thumbnail on Kaltura
            kalturaService.updateThumbnail(entryId, second, function() {
                // no specific action to be taken
            });
        };

        /**
         * Initialize project video for Kaltura upload
         */
        $scope.initProjectVideoKaltura = function(
            contentTemplateElementDataId
        ) {
            // assign the content template element id in the scope to be used once saving a video
            $scope.contentTemplateElementDataId = contentTemplateElementDataId;
            $scope.isKalturaUploaderLoading = true;
            var fileSize = 0;
            console.log("before getting KS");
            kalturaService.getKs(function(ks) {
                console.log("after getting KS ***" + ks + "*****");
                $scope.isKalturaUploaderLoading = false;
                var lbd = false;
                $("#fileupload").show();
                $("#fileupload")
                    .kalturaupload({
                        // add
                        add: function(e, data) {
                            console.log(data);
                            console.log(data.originalFiles[0]);
                            var uploadErrors = [];
                            if (
                                !videoUtilService.isVideoFileName(
                                    data.originalFiles[0]["name"]
                                )
                            ) {
                                uploadErrors.push(
                                    $translate.instant(
                                        "angular.videoupload.notanacceptedfiletype"
                                    )
                                );
                            }
                            // check size FIX issue: https://skyword.atlassian.net/browse/SKY-13334
                            // no longer needed : https://skyword.atlassian.net/browse/SKY-14082
                            //                            if(data.originalFiles[0].size > 1500000000) {
                            //                            	uploadErrors.push($translate.instant('angular.videoupload.fileSizeExceedsLimit'));
                            //                            }
                            if (uploadErrors.length > 0) {
                                alert(uploadErrors.join("\n"));
                                $scope.listFileUploadProgress = [];
                            } else {
                                // hide success will fix  https://skyword.atlassian.net/browse/SKY-13262
                                $(".alert.message.success").remove();
                                data.submit();
                            }
                        },
                        apiURL: "//www.kaltura.com/api_v3/",
                        url:
                            "//www.kaltura.com/api_v3/?service=uploadToken&action=upload&format=1",
                        ks: ks,
                        ignoreChunkHeader: true,
                        dataType: "json",
                        autoUpload: true,

                        getFilesFromResponse: function(x) {},

                        done: function(e, data) {
                            console.log("file size is " + fileSize);
                            console.log(data);
                            $.get(
                                data.apiURL +
                                    "?service=baseEntry&action=addfromuploadedfile&format=1&entry:objectType=KalturaBaseEntry&entry:type=-1&entry:name=" +
                                    data.result.fileName +
                                    "&ks=" +
                                    data.ks +
                                    "&uploadTokenId=" +
                                    data.result.id,
                                function(data) {
                                    $scope.$apply(function() {
                                        console.log(data);
                                        console.log("entry id = " + data.id);
                                        $("#kalturaId").val(data.id);
                                        $scope.addVideo(
                                            data.id,
                                            data.name,
                                            fileSize
                                        );
                                    });
                                }
                            );
                        },
                        progressall: function(e, data) {
                            fileSize = data.total;
                            var progress = parseInt(
                                (data.loaded / data.total) * 100,
                                10
                            );
                            $scope.$apply(function() {
                                $scope.isUploadInProgress = true;
                                $scope.progress = progress;

                                // set the data progress var on scope
                                if (data.total < Math.pow(10, 6)) {
                                    $scope.loaded =
                                        Math.round(
                                            data.loaded / Math.pow(10, 2)
                                        ) / 10;
                                    $scope.total =
                                        Math.round(
                                            data.total / Math.pow(10, 2)
                                        ) / 10;
                                    $scope.units = "kB";
                                } else if (data.total < Math.pow(10, 9)) {
                                    $scope.loaded =
                                        Math.round(
                                            data.loaded / Math.pow(10, 5)
                                        ) / 10;
                                    $scope.total =
                                        Math.round(
                                            data.total / Math.pow(10, 5)
                                        ) / 10;
                                    $scope.units = "MB";
                                } else {
                                    $scope.loaded =
                                        Math.round(
                                            data.loaded / Math.pow(10, 8)
                                        ) / 10;
                                    $scope.total =
                                        Math.round(
                                            data.total / Math.pow(10, 8)
                                        ) / 10;
                                    $scope.units = "GB";
                                }
                            });
                            console.log("% progress = " + progress);
                            $("#progressbar").progressbar({
                                value: progress
                            });
                        }
                    })
                    .prop("disabled", !$.support.fileInput)
                    .parent()
                    .addClass($.support.fileInput ? undefined : "disabled");
                if (lbd) {
                    $("#fileupload").fileupload({
                        apiURL: "http://lbd.kaltura.com/api_v3/",
                        url:
                            "http://lbd.kaltura.com/api_v3/?service=uploadToken&action=upload&format=1"
                    });
                }
            });

            (function() {
                $(".kaltura-upload-region").bind("click", function() {
                    console.log("CLICK UPLOAD");
                    $("#fileupload").click();
                });
            })($);
        };
    }
]);

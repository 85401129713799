app.controller("assignmentModify", [
    "$scope",
    "assignment",
    "common",
    "amplify",
    "validationUtil",
    "assignmentAttachments",
    "setup",
    "redactor",
    "formBuilder",
    "$timeout",
    "TinymceService",
    "$translate",
    function(
        $scope,
        assignment,
        common,
        amplify,
        validationUtil,
        assignmentAttachments,
        setup,
        redactor,
        formBuilder,
        $timeout,
        TinymceService,
        $translate
    ) {
        // Init plain text form elements within a container
        $timeout(function() {
            var tinyMceContainer = $(".tinyMceContainer");
            TinymceService.compile(tinyMceContainer, $scope);
        }, 2000);

        assignment.init();
        common.init();
        amplify.init();
        assignmentAttachments.init();
        if ($scope.dateAndTimePickerId) {
            setup.dateAndTimePicker($scope.dateAndTimePickerId);
        }

        redactor.init();
        formBuilder.render(1000);
        formBuilder.setupSelectSingle("#selectedCampaignId", 1000);

        //for new date/time picker in struts tag
        $scope.dates = {
            closeDateString: null,
            submitDeadlineString: null,
            publishDeadlineString: null,
            claimDeadlineString: null,
            availableString: null,
            deliveryDeadlineString: null,
            redeliveryDeadlineString: null
        };

        $scope.momentDateFormat = $("#momentPickerFormat").val();
        $scope.dateformat = "MM-DD-YYYY";
        $scope.dayFormat = "DDDD";
        $scope.contributorLeaveDateFormat = "MMM D, YYYY";

        $scope.$watch("dates.closeDateString", function(newValue, oldValue) {
            if ($scope.dates.closeDateString == null) {
                var closeDateString = $("#closeDateString").val();
                $scope.dates.closeDateString = moment(
                    closeDateString,
                    $scope.dateformat
                ).format($scope.momentDateFormat);
            }

            if ($scope.dates.closeDateString != null) {
                $scope.closeDateString = moment(
                    $scope.dates.closeDateString,
                    $scope.momentDateFormat
                ).format($scope.dateformat);
                $("#sT_date_closeDateString").val($scope.closeDateString);
                $("#sT_date_closeDateString").trigger("change");
            }
        });

        $scope.$watch("dates.submitDeadlineString", function(
            newValue,
            oldValue
        ) {
            if ($scope.dates.submitDeadlineString == null) {
                var submitDeadlineString = $("#submitDeadlineString").val();
                $scope.dates.submitDeadlineString = moment(
                    submitDeadlineString,
                    $scope.dateformat
                ).format($scope.momentDateFormat);
            }

            if ($scope.dates.submitDeadlineString != null) {
                $scope.submitDeadlineString = moment(
                    $scope.dates.submitDeadlineString,
                    $scope.momentDateFormat
                ).format($scope.dateformat);
                $("#sT_date_submitDeadlineString").val(
                    $scope.submitDeadlineString
                );
                $("#sT_date_submitDeadlineString").trigger("change");
            }
            var currentSelectedDate = moment(
                newValue,
                $scope.dateFormat
            ).format($scope.contributorLeaveDateFormat);
            var checkIfContributorOnLeave = assignment.contributorLeavesData(
                currentSelectedDate
            );
            if (checkIfContributorOnLeave) {
                setTimeout(function() {
                    $("#submit-day").html(
                        '<span class ="icon-Warning" style="color:#F28C28"></span><span style="color:#F28C28;font-weight:bold">' +
                            $translate.instant(
                                "sw.message.submissiondeadlinecontributoronleave"
                            ) +
                            "</span>"
                    );
                }, 1000);
            } else {
                $("#submit-day").text("");
            }
        });

        $scope.$watch("dates.deliveryDeadlineString", function(
            newValue,
            oldValue
        ) {
            if ($scope.dates.deliveryDeadlineString == null) {
                var deliveryDeadline = new Date();
                if (
                    $("#deliveryDeadlineString")
                        .val()
                        .toString() ===
                    $("#submitDeadlineString")
                        .val()
                        .toString()
                ) {
                    var businessDaysDeadlineDelivered = isNaN(
                        $("#business-days-deadline-delivered").val()
                    )
                        ? 0
                        : parseInt(
                              $("#business-days-deadline-delivered").val()
                          );
                    var submitDeadline = new Date(
                        moment(
                            $scope.dates.submitDeadlineString,
                            $scope.dateformat
                        ).format($scope.momentDateFormat)
                    );
                    deliveryDeadline = new Date(
                        assignment.addBusinessDays(
                            submitDeadline,
                            businessDaysDeadlineDelivered
                        )
                    );
                } else {
                    deliveryDeadline = $("#deliveryDeadlineString").val();
                }
                $scope.dates.deliveryDeadlineString = moment(
                    deliveryDeadline,
                    $scope.momentDateFormat
                ).format($scope.dateformat);
            }

            if ($scope.dates.deliveryDeadlineString != null) {
                $scope.deliveryDeadlineString = moment(
                    $scope.dates.deliveryDeadlineString,
                    $scope.momentDateFormat
                ).format($scope.dateformat);
                $("#sT_date_deliveryDeadlineString").val(
                    $scope.deliveryDeadlineString
                );
                $("#sT_date_deliveryDeadlineString").trigger("change");
            }
            var selectedDate = moment(
                $scope.dates.deliveryDeadlineString,
                $scope.dateformat
            ).format($scope.momentDateFormat);
            var selectedDay = moment(
                new Date(selectedDate),
                $scope.dayFormat
            ).format("dddd");
            if ($("#delivery-day").length) {
                if (selectedDay === "Saturday" || selectedDay === "Sunday") {
                    setTimeout(function() {
                        $("#delivery-day").html(
                            '<span class ="icon-Warning" style="color:#F28C28"></span><span style="color:#F28C28;font-weight:bold">' +
                                $translate.instant(
                                    "sw.message.deliverydeadlinefallsonweekend"
                                ) +
                                "</span>"
                        );
                    }, 3000);
                } else {
                    $("#delivery-day").text("");
                }
            }
        });

        $scope.$watch("dates.redeliveryDeadlineString", function(
                    newValue,
                    oldValue
                ) {
                    if ($scope.dates.redeliveryDeadlineString == null) {
                        var redeliveryDeadline = new Date();
                        if (
                            $("#redeliveryDeadlineString")
                                .val()
                                ?.toString() ===
                            $("#submitDeadlineString")
                                .val()
                                ?.toString()
                        ) {
                            var businessDaysDeadlineRedelivered = isNaN(
                                $("#business-days-deadline-redelivered").val()
                            )
                                ? 0
                                : parseInt(
                                      $("#business-days-deadline-redelivered").val()
                                  );
                            var submitDeadline = new Date(
                                moment(
                                    $scope.dates.submitDeadlineString,
                                    $scope.dateformat
                                ).format($scope.momentDateFormat)
                            );
                            redeliveryDeadline = new Date(
                                assignment.addBusinessDays(
                                    submitDeadline,
                                    businessDaysDeadlineRedelivered
                                )
                            );
                        } else {
                            redeliveryDeadline = $("#redeliveryDeadlineString").val();
                        }
                        $scope.dates.redeliveryDeadlineString = moment(
                            redeliveryDeadline,
                            $scope.momentDateFormat
                        ).format($scope.dateformat);
                    }

                    if ($scope.dates.redeliveryDeadlineString != null) {
                        $scope.redeliveryDeadlineString = moment(
                            $scope.dates.redeliveryDeadlineString,
                            $scope.momentDateFormat
                        ).format($scope.dateformat);
                        $("#sT_date_redeliveryDeadlineString").val(
                            $scope.redeliveryDeadlineString
                        );
                        $("#sT_date_redeliveryDeadlineString").trigger("change");
                    }
                    var selectedDate = moment(
                        $scope.dates.redeliveryDeadlineString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                    var selectedDay = moment(
                        new Date(selectedDate),
                        $scope.dayFormat
                    ).format("dddd");
                    if ($("#redelivery-day").length) {
                        if (selectedDay === "Saturday" || selectedDay === "Sunday") {
                            setTimeout(function() {
                                $("#redelivery-day").html(
                                    '<span class ="icon-Warning" style="color:#F28C28"></span><span style="color:#F28C28;font-weight:bold">' +
                                        $translate.instant(
                                            "sw.message.deliverydeadlinefallsonweekend"
                                        ) +
                                        "</span>"
                                );
                            }, 3000);
                        } else {
                            $("#redelivery-day").text("");
                        }
                    }
                });

        $scope.$watch("dates.publishDeadlineString", function(
            newValue,
            oldValue
        ) {
            if ($scope.dates.publishDeadlineString == null) {
                var publishDeadlineString = $("#publishDeadlineString").val();
                $scope.dates.publishDeadlineString = moment(
                    publishDeadlineString,
                    $scope.dateformat
                ).format($scope.momentDateFormat);
            }

            if ($scope.dates.publishDeadlineString != null) {
                $scope.publishDeadlineString = moment(
                    $scope.dates.publishDeadlineString,
                    $scope.momentDateFormat
                ).format($scope.dateformat);
                $("#sT_date_publishDeadlineString").val(
                    $scope.publishDeadlineString
                );
                $("#sT_date_publishDeadlineString").trigger("change");
            }
        });

        $scope.$watch("dates.claimDeadlineString", function(
            newValue,
            oldValue
        ) {
            if ($scope.dates.claimDeadlineString == null) {
                var claimDeadlineString = $("#claimDeadlineString").val();
                $scope.dates.claimDeadlineString = moment(
                    claimDeadlineString,
                    $scope.dateformat
                ).format($scope.momentDateFormat);
            }

            if ($scope.dates.claimDeadlineString != null) {
                $scope.claimDeadlineString = moment(
                    $scope.dates.claimDeadlineString,
                    $scope.momentDateFormat
                ).format($scope.dateformat);
                $("#sT_date_claimDeadlineString").val(
                    $scope.claimDeadlineString
                );

                $("#sT_date_claimDeadlineString").trigger("change");
            }
            var currentSelectedDate = moment(
                $scope.dates.claimDeadlineString,
                $scope.dateFormat
            ).format($scope.contributorLeaveDateFormat);
            var checkIfContributorOnLeave = assignment.contributorLeavesData(
                currentSelectedDate
            );
            if (checkIfContributorOnLeave) {
                setTimeout(function() {
                    $("#claimed-day").html(
                        '<span class ="icon-Warning" style="color:#F28C28"></span><span style="color:#F28C28;font-weight:bold"> ' +
                            $translate.instant(
                                "sw.message.claimdeadlinecontributoronleave"
                            ) +
                            "</span>"
                    );
                }, 1000);
            } else {
                $("#claimed-day").text("");
            }
        });

        $scope.$watch("dates.availableString", function(newValue, oldValue) {
            if ($scope.dates.availableString == null) {
                var availableString = $("#availableString").val();
                $scope.dates.availableString = moment(
                    availableString,
                    $scope.dateformat
                ).format($scope.momentDateFormat);
            }

            if ($scope.dates.availableString != null) {
                $scope.availableString = moment(
                    $scope.dates.availableString,
                    $scope.momentDateFormat
                ).format($scope.dateformat);
                $("#sT_date_availableString").val($scope.availableString);
                $("#sT_date_availableString").trigger("change");
            }
        });

        $scope.keywordsSelected = "";
        $scope.keywordsSelected1 = "";
        $scope.keywordsSelected2 = "";
        $("#generate-summary").on("click", function() {
            if ($('#aiSummaryContentLoader').css('display') === 'none') {
                assignment.parametersToGenerateSummary();

            }
        });

        $(document).on('change', "#keywordTemplateElements\\[0\\]\\.inputValue", function() {
            let keyword = $(this).val();
            $scope.keywordsSelected = keyword;
        });
        $(document).on('change', "#keywordTemplateElements\\[1\\]\\.inputValue", function() {
            let keyword = $(this).val();
            $scope.keywordsSelected1 = keyword;
        });

        $(document).on('change', "#keywordTemplateElements\\[2\\]\\.inputValue", function() {
            let keyword = $(this).val();
            $scope.keywordsSelected2 = keyword;
        });
        $(document).on('change', 'h2[name="assignmentTemplateElements\\[1\\]\\.inputValue"]', function() {
            let selectedTitle = $(this).val();
            $("#assignmentSummaryTitle").val(selectedTitle)
        });

        $(document).on("click", "#generateAISummary", function() {
            assignment.generateAISummary();
        });

        getKeywordValues = function() {
            // Initialize an array to hold non-empty keyword values
            let keywords = [];
            // Check if the keyword input fields exist and have a value
            if ($scope.keywordsSelected.length) {
                keywords.push($scope.keywordsSelected);
            }
            if ($scope.keywordsSelected1.length) {
                keywords.push($scope.keywordsSelected1);
            }
            if ($scope.keywordsSelected2.length) {
                keywords.push($scope.keywordsSelected2);
            }

            // Join the array into a comma-separated string
            const keywordString = keywords.join(',');

            return keywordString;
        }

        $(document).on("change", "#cboxLoadedContent #selectedPurposeParameter", function() {
            var selectedPurposeParameter = $(this).val();
            $("#cboxLoadedContent #selectedPurposeParameterValue").val(selectedPurposeParameter);
            if(selectedPurposeParameter !== "" && selectedPurposeParameter !== undefined) {
                $("#cboxLoadedContent #purposeParameterErrorText").hide();
            }
        });

        $(document).on("change", "#cboxLoadedContent #selectedAudienceParameter", function() {
            var selectedAudienceParameter = $(this).val();
            $("#cboxLoadedContent #selectedAudienceParameterValue").val(selectedAudienceParameter);
            if (selectedAudienceParameter !== "" && selectedAudienceParameter !== undefined) {
                $("#cboxLoadedContent #audienceParameterErrorText").hide();
            }
        });

        $(document).on("change", "#selectedKeyword_0", function() {
            var selectedKeyword = $(this).val();
            $("#selectedKeyword_0").val(selectedKeyword);
            $scope.keywordsSelected = selectedKeyword;
            $("#keywordTemplateElements\\[0\\]\\.inputValue").val(selectedKeyword);
        });

        $(document).on("change", "#selectedKeyword_1", function() {
            var selectedKeyword = $(this).val();
            $("#selectedKeyword_1").val(selectedKeyword);
            $scope.keywordsSelected1 = selectedKeyword;
            $("#keywordTemplateElements\\[1\\]\\.inputValue").val(selectedKeyword);
        });

        $(document).on("change", "#selectedKeyword_2", function() {
            var selectedKeyword = $(this).val();
            $("#selectedKeyword_2").val(selectedKeyword);
            $scope.keywordsSelected2 = selectedKeyword;
            $("#keywordTemplateElements\\[2\\]\\.inputValue").val(selectedKeyword);
        });

         $(document).on("change", "#assignmentSummaryTitle", function() {
            var selectedTitle = $(this).val();
            $("#assignmentSummaryTitle").val(selectedTitle);
            $scope.selectedTitle = selectedTitle;
            const targetTitleElement = document.querySelector('h2[name="assignmentTemplateElements[1].inputValue"]')
            targetTitleElement.innerHTML = selectedTitle;
         });

    }
])
    .controller("assignmentCreate", [
        "$scope",
        "$translate",
        "assignment",
        "common",
        "amplify",
        "validationUtil",
        "assignmentAttachments",
        "setup",
        "redactor",
        "formBuilder",
        "$timeout",
        "TinymceService",
        function(
            $scope,
            $translate,
            assignment,
            common,
            amplify,
            validationUtil,
            assignmentAttachments,
            setup,
            redactor,
            formBuilder,
            $timeout,
            TinymceService
        ) {
            // Init plain text form elements within a container
            $timeout(function() {
                var tinyMceContainer = $(".tinyMceContainer");
                TinymceService.compile(tinyMceContainer, $scope);
            }, 2000);

            assignment.init();
            common.init();
            amplify.init();
            assignmentAttachments.init();
            if ($scope.dateAndTimePickerId) {
                setup.dateAndTimePicker($scope.dateAndTimePickerId);
            }
            redactor.init();
            formBuilder.render(1000);
            formBuilder.setupSelectSingle("#selectedCampaignId", 1000);

            //for new date/time picker in struts tag
            $scope.dates = {
                closeDateString: null,
                submitDeadlineString: null,
                publishDeadlineString: null,
                claimDeadlineString: null,
                availableString: null,
                deliveryDeadlineString: null
            };

            $scope.momentDateFormat = $("#momentPickerFormat").val();
            $scope.dateformat = "MM-DD-YYYY";
            $scope.contributorLeaveDateFormat = "MMM D, YYYY";

            $scope.$watch("dates.closeDateString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.closeDateString == null) {
                    var closeDateString = $("#closeDateString").val();
                    $scope.dates.closeDateString = moment(
                        closeDateString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.closeDateString != null) {
                    $scope.closeDateString = moment(
                        $scope.dates.closeDateString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_closeDateString").val($scope.closeDateString);
                    $("#sT_date_closeDateString").trigger("change");
                }
            });

            $scope.$watch("dates.submitDeadlineString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.submitDeadlineString == null) {
                    var submitDeadlineString = $("#submitDeadlineString").val();
                    $scope.dates.submitDeadlineString = moment(
                        submitDeadlineString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.submitDeadlineString != null) {
                    $scope.submitDeadlineString = moment(
                        $scope.dates.submitDeadlineString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_submitDeadlineString").val(
                        $scope.submitDeadlineString
                    );
                    $("#sT_date_submitDeadlineString").trigger("change");
                }
                var currentSelectedDate = moment(
                    $scope.dates.submitDeadlineString,
                    $scope.dayFormat
                ).format($scope.contributorLeaveDateFormat);
                var selectedDate = moment(
                    $scope.dates.submitDeadlineString,
                    $scope.dateformat
                ).format($scope.momentDateFormat);
                var selectedDay = moment(
                    $scope.dates.submitDeadlineString,
                    $scope.dayFormat
                ).format("dddd");
                var checkIfContributorOnLeave = assignment.contributorLeavesData(
                    currentSelectedDate
                );
                if ($("#submit-day").length) {
                    if (checkIfContributorOnLeave) {
                        setTimeout(function() {
                            $("#submit-day").html(
                                '<span class ="icon-Warning" style="color:#F28C28"></span><span style="color:#F28C28;font-weight:bold">' +
                                    $translate.instant(
                                        "sw.message.submissiondeadlinecontributoronleave"
                                    ) +
                                    "</span>"
                            );
                        }, 1000);
                    } else if (
                        selectedDay === "Saturday" ||
                        selectedDay === "Sunday"
                    ) {
                        setTimeout(function() {
                            $("#submit-day").html(
                                '<span class ="icon-Warning" style="color:#F28C28"></span><span style="color:#F28C28;font-weight:bold">' +
                                    $translate.instant(
                                        "sw.message.submissiondeadlinefallsonweekend"
                                    ) +
                                    "</span>"
                            );
                        }, 3000);
                    } else {
                        $("#submit-day").text("");
                    }
                }
            });

            $scope.$watch("dates.deliveryDeadlineString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.deliveryDeadlineString == null) {
                    var businessDaysDeadlineDelivered = isNaN(
                        $("#business-days-deadline-delivered").val()
                    )
                        ? 0
                        : parseInt(
                              $("#business-days-deadline-delivered").val()
                          );
                    var submitDeadline = new Date(
                        moment(
                            $scope.dates.submitDeadlineString,
                            $scope.dateformat
                        ).format($scope.momentDateFormat)
                    );
                    var deliveryDeadline = new Date(
                        assignment.addBusinessDays(
                            submitDeadline,
                            businessDaysDeadlineDelivered
                        )
                    );
                    $scope.dates.deliveryDeadlineString = moment(
                        deliveryDeadline,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                }

                if ($scope.dates.deliveryDeadlineString != null) {
                    $scope.deliveryDeadlineString = moment(
                        $scope.dates.deliveryDeadlineString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_deliveryDeadlineString").val(
                        $scope.deliveryDeadlineString
                    );
                    $("#sT_date_deliveryDeadlineString").trigger("change");
                }
                var selectedDate = moment(
                    $scope.dates.deliveryDeadlineString,
                    $scope.dateformat
                ).format($scope.momentDateFormat);
                var selectedDay = moment(
                    new Date(selectedDate),
                    $scope.dayFormat
                ).format("dddd");
                if ($("#delivery-day").length) {
                    if (
                        selectedDay === "Saturday" ||
                        selectedDay === "Sunday"
                    ) {
                        setTimeout(function() {
                            $("#delivery-day").html(
                                '<span class ="icon-Warning" style="color:#F28C28"></span><span style="color:#F28C28;font-weight:bold">' +
                                    $translate.instant(
                                        "sw.message.deliverydeadlinefallsonweekend"
                                    ) +
                                    "</span>"
                            );
                        }, 3000);
                    } else {
                        $("#delivery-day").text("");
                    }
                }
            });

            $scope.$watch("dates.publishDeadlineString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.publishDeadlineString == null) {
                    var publishDeadlineString = $(
                        "#publishDeadlineString"
                    ).val();
                    $scope.dates.publishDeadlineString = moment(
                        publishDeadlineString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.publishDeadlineString != null) {
                    $scope.publishDeadlineString = moment(
                        $scope.dates.publishDeadlineString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_publishDeadlineString").val(
                        $scope.publishDeadlineString
                    );
                    $("#sT_date_publishDeadlineString").trigger("change");
                }

                var selectedDate = moment(
                    $scope.dates.publishDeadlineString,
                    $scope.dateformat
                ).format($scope.momentDateFormat);
                var selectedDay = moment(
                    $scope.dates.publishDeadlineString,
                    $scope.dayFormat
                ).format("dddd");
                if ($("#publish-day").length) {
                    if (
                        selectedDay === "Saturday" ||
                        selectedDay === "Sunday"
                    ) {
                        setTimeout(function() {
                            $("#publish-day").html(
                                '<span class ="icon-Warning" style="color:#F28C28"></span><span style="color:#F28C28;font-weight:bold">' +
                                    $translate.instant(
                                        "sw.message.publishdatefallsonweekend"
                                    ) +
                                    "</span>"
                            );
                        }, 3000);
                    } else {
                        $("#publish-day").text("");
                    }
                }
            });

            $scope.$watch("dates.claimDeadlineString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.claimDeadlineString == null) {
                    var claimDeadlineString = $("#claimDeadlineString").val();
                    $scope.dates.claimDeadlineString = moment(
                        claimDeadlineString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.claimDeadlineString != null) {
                    $scope.claimDeadlineString = moment(
                        $scope.dates.claimDeadlineString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_claimDeadlineString").val(
                        $scope.claimDeadlineString
                    );
                    $("#sT_date_claimDeadlineString").trigger("change");
                }
                var currentSelectedDate = moment(
                    $scope.dates.claimDeadlineString,
                    $scope.dayFormat
                ).format($scope.contributorLeaveDateFormat);

                var selectedDate = moment(
                    $scope.dates.claimDeadlineString,
                    $scope.dateformat
                ).format($scope.momentDateFormat);
                var selectedDay = moment(
                    $scope.dates.claimDeadlineString,
                    $scope.dayFormat
                ).format("dddd");
                var checkIfContributorOnLeave = assignment.contributorLeavesData(
                    currentSelectedDate
                );
                if ($("#claimed-day").length) {
                    if (checkIfContributorOnLeave) {
                        setTimeout(function() {
                            $("#claimed-day").html(
                                '<span class ="icon-Warning" style="color:#F28C28"></span><span style="color:#F28C28;font-weight:bold"> ' +
                                    $translate.instant(
                                        "sw.message.claimdeadlinecontributoronleave"
                                    ) +
                                    "</span>"
                            );
                        }, 1000);
                    } else if (
                        selectedDay === "Saturday" ||
                        selectedDay === "Sunday"
                    ) {
                        setTimeout(function() {
                            $("#claimed-day").html(
                                '<span class ="icon-Warning" style="color:#F28C28"></span><span style="color:#F28C28;font-weight:bold"> ' +
                                    $translate.instant(
                                        "sw.message.claimdatefallsonweekend"
                                    ) +
                                    "</span>"
                            );
                        }, 3000);
                    } else {
                        $("#claimed-day").text("");
                    }
                }
            });

            $scope.$watch("dates.availableString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.availableString == null) {
                    var availableString = $("#availableString").val();
                    $scope.dates.availableString = moment(
                        availableString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.availableString != null) {
                    $scope.availableString = moment(
                        $scope.dates.availableString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_availableString").val($scope.availableString);
                    $("#sT_date_availableString").trigger("change");
                }
                console.log("open---", $scope.dates.availableString);
                console.log(
                    moment(
                        $scope.dates.availableString,
                        $scope.dayFormat
                    ).format("dddd")
                );
                var selectedDate = moment(
                    $scope.dates.availableString,
                    $scope.dateformat
                ).format($scope.momentDateFormat);
                var selectedDay = moment(
                    $scope.dates.availableString,
                    $scope.dayFormat
                ).format("dddd");
                if ($("#open-day").length) {
                    if (
                        selectedDay === "Saturday" ||
                        selectedDay === "Sunday"
                    ) {
                        setTimeout(function() {
                            $("#open-day").html(
                                '<span class ="icon-Warning" style="color:#F28C28"></span><span style="color:#F28C28;font-weight:bold"> ' +
                                    $translate.instant(
                                        "sw.message.opendatefallsonweekend"
                                    ) +
                                    "</span>"
                            );
                        }, 3000);
                    } else {
                        $("#open-day").text("");
                    }
                }
            });

            $scope.keywordsSelected = "";
            $scope.keywordsSelected1 = "";
            $scope.keywordsSelected2 = "";
            $("#generate-summary").on("click", function() {
                if ($('#aiSummaryContentLoader').css('display') === 'none') {
                    assignment.parametersToGenerateSummary();
                }
            });

            $(document).on('change', "#keywordTemplateElements\\[0\\]\\.inputValue", function() {
                let keyword = $(this).val();
                $scope.keywordsSelected = keyword;
            });
            $(document).on('change', "#keywordTemplateElements\\[1\\]\\.inputValue", function() {
                let keyword = $(this).val();
                $scope.keywordsSelected1 = keyword;
            });

            $(document).on('change', "#keywordTemplateElements\\[2\\]\\.inputValue", function() {
                let keyword = $(this).val();
                $scope.keywordsSelected2 = keyword;
            });
            $(document).on('change', 'h2[name="assignmentTemplateElements\\[1\\]\\.inputValue"]', function() {
                let selectedTitle = $(this).val();
                $("#assignmentSummaryTitle").val(selectedTitle)
            });

            $(document).on("click", "#generateAISummary", function() {
                assignment.generateAISummary();
            });

            getKeywordValues = function() {
                // Initialize an array to hold non-empty keyword values
                let keywords = [];
                // Check if the keyword input fields exist and have a value
                if ($scope.keywordsSelected.length) {
                    keywords.push($scope.keywordsSelected);
                }
                if ($scope.keywordsSelected1.length) {
                    keywords.push($scope.keywordsSelected1);
                }
                if ($scope.keywordsSelected2.length) {
                    keywords.push($scope.keywordsSelected2);
                }

                // Join the array into a comma-separated string
                const keywordString = keywords.join(',');

                return keywordString;
            }

            $(document).on("change", "#cboxLoadedContent #selectedPurposeParameter", function() {
                var selectedPurposeParameter = $(this).val();
                $("#cboxLoadedContent #selectedPurposeParameterValue").val(selectedPurposeParameter);
                if(selectedPurposeParameter !== "" && selectedPurposeParameter !== undefined) {
                    $("#cboxLoadedContent #purposeParameterErrorText").hide();
                }
            });

            $(document).on("change", "#cboxLoadedContent #selectedAudienceParameter", function() {
                var selectedAudienceParameter = $(this).val();
                $("#cboxLoadedContent #selectedAudienceParameterValue").val(selectedAudienceParameter);
                if (selectedAudienceParameter !== "" && selectedAudienceParameter !== undefined) {
                    $("#cboxLoadedContent #audienceParameterErrorText").hide();
                }
            });

            $(document).on("change", "#selectedKeyword_0", function() {
                var selectedKeyword = $(this).val();
                $("#selectedKeyword_0").val(selectedKeyword);
                $scope.keywordsSelected = selectedKeyword;
                $("#keywordTemplateElements\\[0\\]\\.inputValue").val(selectedKeyword);
            });

            $(document).on("change", "#selectedKeyword_1", function() {
                var selectedKeyword = $(this).val();
                $("#selectedKeyword_1").val(selectedKeyword);
                $scope.keywordsSelected1 = selectedKeyword;
                $("#keywordTemplateElements\\[1\\]\\.inputValue").val(selectedKeyword);
            });

            $(document).on("change", "#selectedKeyword_2", function() {
                var selectedKeyword = $(this).val();
                $("#selectedKeyword_2").val(selectedKeyword);
                $scope.keywordsSelected2 = selectedKeyword;
                $("#keywordTemplateElements\\[2\\]\\.inputValue").val(selectedKeyword);
            });
            $(document).on("change", "#assignmentSummaryTitle", function() {
                var selectedTitle = $(this).val();
                $("#assignmentSummaryTitle").val(selectedTitle);
                $scope.selectedTitle = selectedTitle;
                const targetTitleElement = document.querySelector('h2[name="assignmentTemplateElements[1].inputValue"]')
                targetTitleElement.innerHTML = selectedTitle;
             });
        }
    ])
    .controller("rfpEdit", [
        "$scope",
        "assignment",
        "common",
        "amplify",
        "validationUtil",
        "assignmentAttachments",
        "setup",
        "redactor",
        "formBuilder",
        "$timeout",
        "TinymceService",
        function(
            $scope,
            assignment,
            common,
            amplify,
            validationUtil,
            assignmentAttachments,
            setup,
            redactor,
            formBuilder,
            $timeout,
            TinymceService
        ) {
            // Init plain text form elements within a container
            $timeout(function() {
                var tinyMceContainer = $(".tinyMceContainer");
                TinymceService.compile(tinyMceContainer, $scope);
            }, 2000);

            assignment.init();
            common.init();
            amplify.init();
            assignmentAttachments.init();
            if ($scope.dateAndTimePickerId) {
                setup.dateAndTimePicker($scope.dateAndTimePickerId);
            }

            redactor.init();
            formBuilder.render(1000);

            //for new date/time picker in struts tag
            $scope.dates = {
                closeDateString: null,
                submitDeadlineString: null,
                publishDeadlineString: null,
                claimDeadlineString: null,
                availableString: null,
                deliveryDeadlineString: null
            };

            $scope.momentDateFormat = $("#momentPickerFormat").val();
            $scope.dateformat = "MM-DD-YYYY";

            $scope.$watch("dates.closeDateString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.closeDateString == null) {
                    var closeDateString = $("#closeDateString").val();
                    $scope.dates.closeDateString = moment(
                        closeDateString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.closeDateString != null) {
                    $scope.closeDateString = moment(
                        $scope.dates.closeDateString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_closeDateString").val($scope.closeDateString);
                    $("#sT_date_closeDateString").trigger("change");
                }
            });

            $scope.$watch("dates.submitDeadlineString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.submitDeadlineString == null) {
                    var submitDeadlineString = $("#submitDeadlineString").val();
                    $scope.dates.submitDeadlineString = moment(
                        submitDeadlineString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.submitDeadlineString != null) {
                    $scope.submitDeadlineString = moment(
                        $scope.dates.submitDeadlineString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_submitDeadlineString").val(
                        $scope.submitDeadlineString
                    );
                    $("#sT_date_submitDeadlineString").trigger("change");
                }
            });

            $scope.$watch("dates.publishDeadlineString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.publishDeadlineString == null) {
                    var publishDeadlineString = $(
                        "#publishDeadlineString"
                    ).val();
                    $scope.dates.publishDeadlineString = moment(
                        publishDeadlineString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.publishDeadlineString != null) {
                    $scope.publishDeadlineString = moment(
                        $scope.dates.publishDeadlineString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_publishDeadlineString").val(
                        $scope.publishDeadlineString
                    );
                    $("#sT_date_publishDeadlineString").trigger("change");
                }
            });

            $scope.$watch("dates.deliveryDeadlineString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.deliveryDeadlineString === null) {
                    var deliveryDeadlineString = new Date(
                        moment(
                            $("#deliveryDeadlineString").val(),
                            $scope.dateformat
                        ).format($scope.momentDateFormat)
                    );
                    $scope.dates.deliveryDeadlineString = moment(
                        deliveryDeadlineString,
                        $scope.dateFormat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.deliveryDeadlineString !== null) {
                    $scope.deliveryDeadlineString = moment(
                        $scope.dates.deliveryDeadlineString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_deliveryDeadlineString").val(
                        $scope.deliveryDeadlineString
                    );
                    $("#sT_date_deliveryDeadlineString").trigger("change");
                }
            });

            $scope.$watch("dates.claimDeadlineString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.claimDeadlineString !== undefined) {
                    if ($scope.dates.claimDeadlineString == null) {
                        var claimDeadlineString = $("#claimDeadlineString").val();
                        $scope.dates.claimDeadlineString = moment(
                            claimDeadlineString,
                            $scope.dateformat
                        ).format($scope.momentDateFormat);
                    }

                    if ($scope.dates.claimDeadlineString != null) {
                        $scope.claimDeadlineString = moment(
                            $scope.dates.claimDeadlineString,
                            $scope.momentDateFormat
                        ).format($scope.dateformat);
                        $("#sT_date_claimDeadlineString").val(
                            $scope.claimDeadlineString
                        );
                        $("#sT_date_claimDeadlineString").trigger("change");
                    }
                }
            });

            $scope.$watch("dates.availableString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.availableString == null) {
                    var availableString = $("#availableString").val();
                    $scope.dates.availableString = moment(
                        availableString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.availableString != null) {
                    $scope.availableString = moment(
                        $scope.dates.availableString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_availableString").val($scope.availableString);
                    $("#sT_date_availableString").trigger("change");
                }
            });

            $(function() {
                window.localStorage.removeItem("setContributorList");
                var selectedOption = parseInt(
                    $("#contributorSelectionId option:selected").val()
                );
                var selectionContributorListOption = parseInt(
                    $("#contributorListId option:selected").val()
                );
                if (selectedOption === 3) {
                    if (selectionContributorListOption !== -1) {
                        $("#showContributorListLink").css(
                            "display",
                            "inline-block"
                        );
                    } else {
                        $("#showContributorListLink").css("display", "none");
                    }

                    $("#contributorListId").show();
                    $("#writeronlymessage").hide();
                } else {
                    $("#writeronlymessage").hide();
                    $("#contributorListId").hide();
                    $("#showContributorListLink").css("display", "none");
                }
            });
            $("#contributorListId").change(function() {
                var selectedOption = parseInt(
                    $("#contributorListId option:selected").val()
                );
                if (selectedOption !== -1) {
                    $("#showContributorListLink").css(
                        "display",
                        "inline-block"
                    );
                } else {
                    $("#showContributorListLink").css("display", "none");
                }
            });

            $("#contributorSelectionId").change(function() {
                $("#contributorListId").prop("selectedIndex", 0);
                var selectedOption = parseInt(
                    $("#contributorSelectionId option:selected").val()
                );
                var selectionContributorListOption = parseInt(
                    $("#contributorListId option:selected").val()
                );
                if (selectedOption === 3) {
                    if (selectionContributorListOption !== -1) {
                        $("#showContributorListLink").css(
                            "display",
                            "inline-block"
                        );
                    } else {
                        $("#showContributorListLink").css("display", "none");
                    }

                    $("#contributorListId").show();
                    $("#writeronlymessage").hide();
                } else {
                    $("#writeronlymessage").hide();
                    $("#contributorListId").hide();
                    $("#showContributorListLink").css("display", "none");
                }
            });

            getContributorList = function() {
                var selectedContributorListOption = parseInt(
                    $("#contributorListId option:selected").val()
                );
                var selectedContributorSelectionOption = parseInt(
                    $("#contributorSelectionId option:selected").val()
                );
                var displayContributorList = $("#contributorListId").css(
                    "display"
                );
                if (
                    displayContributorList === "none" &&
                    selectedContributorSelectionOption === 2
                ) {
                    assignment.listOfChannelContributors();
                } else if (
                    selectedContributorSelectionOption === 3 &&
                    displayContributorList !== "none"
                ) {
                    assignment.listOfContributorAccounts(
                        selectedContributorListOption
                    );
                }
            };

            $scope.keywordsSelected = "";
            $scope.keywordsSelected1 = "";
            $scope.keywordsSelected2 = "";
            $("#generate-summary").on("click", function() {
                if ($('#aiSummaryContentLoader').css('display') === 'none') {
                    assignment.parametersToGenerateSummary();
                }
            });

            $(document).on('change', "#keywordTemplateElements\\[0\\]\\.inputValue", function() {
                let keyword = $(this).val();
                $scope.keywordsSelected = keyword;
            });
            $(document).on('change', "#keywordTemplateElements\\[1\\]\\.inputValue", function() {
                let keyword = $(this).val();
                $scope.keywordsSelected1 = keyword;
            });

            $(document).on('change', "#keywordTemplateElements\\[2\\]\\.inputValue", function() {
                let keyword = $(this).val();
                $scope.keywordsSelected2 = keyword;
            });

            $(document).on('change', 'h2[name="assignmentTemplateElements\\[1\\]\\.inputValue"]', function() {
                let selectedTitle = $(this).val();
                $("#assignmentSummaryTitle").val(selectedTitle)
            });


            $(document).on("click", "#generateAISummary", function() {
                assignment.generateAISummary();
            });

            getKeywordValues = function() {
                // Initialize an array to hold non-empty keyword values
                let keywords = [];
                // Check if the keyword input fields exist and have a value
                if ($scope.keywordsSelected.length) {
                    keywords.push($scope.keywordsSelected);
                }
                if ($scope.keywordsSelected1.length) {
                    keywords.push($scope.keywordsSelected1);
                }
                if ($scope.keywordsSelected2.length) {
                    keywords.push($scope.keywordsSelected2);
                }

                // Join the array into a comma-separated string
                const keywordString = keywords.join(',');

                return keywordString;
            }

            $(document).on("change", "#cboxLoadedContent #selectedPurposeParameter", function() {
                var selectedPurposeParameter = $(this).val();
                $("#cboxLoadedContent #selectedPurposeParameterValue").val(selectedPurposeParameter);
                if(selectedPurposeParameter !== "" && selectedPurposeParameter !== undefined) {
                    $("#cboxLoadedContent #purposeParameterErrorText").hide();
                }
            });

            $(document).on("change", "#cboxLoadedContent #selectedAudienceParameter", function() {
                var selectedAudienceParameter = $(this).val();
                $("#cboxLoadedContent #selectedAudienceParameterValue").val(selectedAudienceParameter);
                if (selectedAudienceParameter !== "" && selectedAudienceParameter !== undefined) {
                    $("#cboxLoadedContent #audienceParameterErrorText").hide();
                }
            });

            $(document).on("change", "#selectedKeyword_0", function() {
                var selectedKeyword = $(this).val();
                $("#selectedKeyword_0").val(selectedKeyword);
                $scope.keywordsSelected = selectedKeyword;
                $("#keywordTemplateElements\\[0\\]\\.inputValue").val(selectedKeyword);
            });

            $(document).on("change", "#selectedKeyword_1", function() {
                var selectedKeyword = $(this).val();
                $("#selectedKeyword_1").val(selectedKeyword);
                $scope.keywordsSelected1 = selectedKeyword;
                $("#keywordTemplateElements\\[1\\]\\.inputValue").val(selectedKeyword);
            });

            $(document).on("change", "#selectedKeyword_2", function() {
                var selectedKeyword = $(this).val();
                $("#selectedKeyword_2").val(selectedKeyword);
                $scope.keywordsSelected2 = selectedKeyword;
                $("#keywordTemplateElements\\[2\\]\\.inputValue").val(selectedKeyword);
            });
            $(document).on("change", "#assignmentSummaryTitle", function() {
                var selectedTitle = $(this).val();
                $("#assignmentSummaryTitle").val(selectedTitle);
                $scope.selectedTitle = selectedTitle;
                const targetTitleElement = document.querySelector('h2[name="assignmentTemplateElements[1].inputValue"]')
                targetTitleElement.innerHTML = selectedTitle;
             });
        }
    ])
    .controller("rfpCreate", [
        "$scope",
        "assignment",
        "common",
        "amplify",
        "validationUtil",
        "assignmentAttachments",
        "setup",
        "redactor",
        "formBuilder",
        "$timeout",
        "TinymceService",
        function(
            $scope,
            assignment,
            common,
            amplify,
            validationUtil,
            assignmentAttachments,
            setup,
            redactor,
            formBuilder,
            $timeout,
            TinymceService
        ) {
            // Init plain text form elements within a container
            $timeout(function() {
                var tinyMceContainer = $(".tinyMceContainer");
                TinymceService.compile(tinyMceContainer, $scope);
            }, 2000);

            assignment.init();
            common.init();
            amplify.init();
            assignmentAttachments.init();
            if ($scope.dateAndTimePickerId) {
                setup.dateAndTimePicker($scope.dateAndTimePickerId);
            }

            redactor.init();
            formBuilder.render(1000);

            //for new date/time picker in struts tag
            $scope.dates = {
                closeDateString: null,
                submitDeadlineString: null,
                publishDeadlineString: null,
                claimDeadlineString: null,
                availableString: null,
                deliveryDeadlineString: null
            };
            $scope.showListOption = -1;

            $scope.momentDateFormat = $("#momentPickerFormat").val();
            $scope.dateformat = "MM-DD-YYYY";

            $scope.$watch("dates.closeDateString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.closeDateString == null) {
                    var closeDateString = $("#closeDateString").val();
                    $scope.dates.closeDateString = moment(
                        closeDateString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.closeDateString != null) {
                    $scope.closeDateString = moment(
                        $scope.dates.closeDateString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_closeDateString").val($scope.closeDateString);
                    $("#sT_date_closeDateString").trigger("change");
                }
            });

            $scope.$watch("dates.submitDeadlineString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.submitDeadlineString == null) {
                    var submitDeadlineString = $("#submitDeadlineString").val();
                    $scope.dates.submitDeadlineString = moment(
                        submitDeadlineString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.submitDeadlineString != null) {
                    $scope.submitDeadlineString = moment(
                        $scope.dates.submitDeadlineString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_submitDeadlineString").val(
                        $scope.submitDeadlineString
                    );
                    $("#sT_date_submitDeadlineString").trigger("change");
                }
            });

            $scope.$watch("dates.publishDeadlineString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.publishDeadlineString == null) {
                    var publishDeadlineString = $(
                        "#publishDeadlineString"
                    ).val();
                    $scope.dates.publishDeadlineString = moment(
                        publishDeadlineString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.publishDeadlineString != null) {
                    $scope.publishDeadlineString = moment(
                        $scope.dates.publishDeadlineString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_publishDeadlineString").val(
                        $scope.publishDeadlineString
                    );
                    $("#sT_date_publishDeadlineString").trigger("change");
                }
            });

            $scope.$watch("dates.deliveryDeadlineString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.deliveryDeadlineString === null) {
                    var deliveryDeadlineString = new Date(
                        moment(
                            $("#deliveryDeadlineString").val(),
                            $scope.dateformat
                        ).format($scope.momentDateFormat)
                    );
                    $scope.dates.deliveryDeadlineString = moment(
                        deliveryDeadlineString,
                        $scope.dateFormat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.deliveryDeadlineString !== null) {
                    $scope.deliveryDeadlineString = moment(
                        $scope.dates.deliveryDeadlineString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_deliveryDeadlineString").val(
                        $scope.deliveryDeadlineString
                    );
                    $("#sT_date_deliveryDeadlineString").trigger("change");
                }
            });

            $scope.$watch("dates.claimDeadlineString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.claimDeadlineString == null) {
                    var claimDeadlineString = $("#claimDeadlineString").val();
                    $scope.dates.claimDeadlineString = moment(
                        claimDeadlineString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.claimDeadlineString != null) {
                    $scope.claimDeadlineString = moment(
                        $scope.dates.claimDeadlineString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_claimDeadlineString").val(
                        $scope.claimDeadlineString
                    );
                    $("#sT_date_claimDeadlineString").trigger("change");
                }
                console.log("5---", $scope.claimDeadlineString);
            });

            $scope.$watch("dates.availableString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.availableString == null) {
                    var availableString = $("#availableString").val();
                    $scope.dates.availableString = moment(
                        availableString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.availableString != null) {
                    $scope.availableString = moment(
                        $scope.dates.availableString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_availableString").val($scope.availableString);
                    $("#sT_date_availableString").trigger("change");
                }
            });

            $("#contributorListId").change(function() {
                var selectedOption = parseInt(
                    $("#contributorListId option:selected").val()
                );
                if (selectedOption !== -1) {
                    $("#showContributorListLink").css(
                        "display",
                        "inline-block"
                    );
                } else {
                    $("#showContributorListLink").css("display", "none");
                }
            });

            $("#contributorSelectionId").change(function() {
                $("#contributorListId").prop("selectedIndex", 0);
                var selectedOption = parseInt(
                    $("#contributorSelectionId option:selected").val()
                );
                var selectionContributorListOption = parseInt(
                    $("#contributorListId option:selected").val()
                );
                if (selectedOption === 3) {
                    if (
                        window.localStorage.getItem("setContributorList") !==
                        null
                    ) {
                        selectionContributorListOption = parseInt(
                            window.localStorage.getItem("setContributorList")
                        );
                        $(
                            `#contributorListId option[value="${selectionContributorListOption}"]`
                        ).attr("selected", "selected");
                        window.localStorage.removeItem("setContributorList");
                    }
                    if (selectionContributorListOption !== -1) {
                        $("#showContributorListLink").css(
                            "display",
                            "inline-block"
                        );
                    } else {
                        $("#showContributorListLink").css("display", "none");
                    }

                    $("#contributorListId").show();
                    $("#writeronlymessage").hide();
                } else {
                    $("#writeronlymessage").hide();
                    $("#contributorListId").hide();
                    $("#showContributorListLink").css("display", "none");
                }
            });

            getContributorList = function() {
                var selectedContributorListOption = parseInt(
                    $("#contributorListId option:selected").val()
                );
                var selectedContributorSelectionOption = parseInt(
                    $("#contributorSelectionId option:selected").val()
                );
                var displayContributorList = $("#contributorListId").css(
                    "display"
                );

                if (
                    selectedContributorSelectionOption === 3 &&
                    displayContributorList !== "none"
                ) {
                    assignment.listOfContributorAccounts(
                        selectedContributorListOption
                    );
                }
            };

            $(document).on("ready", function() {
                if (
                    window.localStorage.getItem("setContributorList") !== null
                ) {
                    if (
                        parseInt(
                            $("#contributorSelectionId option:selected").val()
                        ) === -1
                    ) {
                        $('#contributorSelectionId option[value="3"]').attr(
                            "selected",
                            "selected"
                        );
                        $("#contributorSelectionId").trigger("change");
                    } else {
                        window.localStorage.removeItem("setContributorList");
                    }
                }
            });

            $scope.keywordsSelected = "";
            $scope.keywordsSelected1 = "";
            $scope.keywordsSelected2 = "";
            $("#generate-summary").on("click", function() {
                if ($('#aiSummaryContentLoader').css('display') === 'none') {
                    assignment.parametersToGenerateSummary();
                }
            });

            $(document).on('change', "#keywordTemplateElements\\[0\\]\\.inputValue", function() {
                let keyword = $(this).val();
                $scope.keywordsSelected = keyword;
            });
            $(document).on('change', "#keywordTemplateElements\\[1\\]\\.inputValue", function() {
                let keyword = $(this).val();
                $scope.keywordsSelected1 = keyword;
            });

            $(document).on('change', "#keywordTemplateElements\\[2\\]\\.inputValue", function() {
                let keyword = $(this).val();
                $scope.keywordsSelected2 = keyword;
            });
            $(document).on('change', 'h2[name="assignmentTemplateElements\\[1\\]\\.inputValue"]', function() {
                let selectedTitle = $(this).val();
                $("#assignmentSummaryTitle").val(selectedTitle)
            });

            $(document).on("click", "#generateAISummary", function() {
                assignment.generateAISummary();
            });

            getKeywordValues = function() {
                // Initialize an array to hold non-empty keyword values
                let keywords = [];
                // Check if the keyword input fields exist and have a value
                if ($scope.keywordsSelected.length) {
                    keywords.push($scope.keywordsSelected);
                }
                if ($scope.keywordsSelected1.length) {
                    keywords.push($scope.keywordsSelected1);
                }
                if ($scope.keywordsSelected2.length) {
                    keywords.push($scope.keywordsSelected2);
                }

                // Join the array into a comma-separated string
                const keywordString = keywords.join(',');


                return keywordString;
            }

            $(document).on("change", "#cboxLoadedContent #selectedPurposeParameter", function() {
                var selectedPurposeParameter = $(this).val();
                $("#cboxLoadedContent #selectedPurposeParameterValue").val(selectedPurposeParameter);
                if(selectedPurposeParameter !== "" && selectedPurposeParameter !== undefined) {
                    $("#cboxLoadedContent #purposeParameterErrorText").hide();
                }
            });

            $(document).on("change", "#cboxLoadedContent #selectedAudienceParameter", function() {
                var selectedAudienceParameter = $(this).val();
                $("#cboxLoadedContent #selectedAudienceParameterValue").val(selectedAudienceParameter);

                if (selectedAudienceParameter !== "" && selectedAudienceParameter !== undefined) {
                    $("#cboxLoadedContent #audienceParameterErrorText").hide();
                }
            });

            $(document).on("change", "#selectedKeyword_0", function() {
                var selectedKeyword = $(this).val();
                $("#selectedKeyword_0").val(selectedKeyword);
                $scope.keywordsSelected = selectedKeyword;
                $("#keywordTemplateElements\\[0\\]\\.inputValue").val(selectedKeyword);
            });

            $(document).on("change", "#selectedKeyword_1", function() {
                var selectedKeyword = $(this).val();
                $("#selectedKeyword_1").val(selectedKeyword);
                $scope.keywordsSelected1 = selectedKeyword;
                $("#keywordTemplateElements\\[1\\]\\.inputValue").val(selectedKeyword);
            });

            $(document).on("change", "#selectedKeyword_2", function() {
                var selectedKeyword = $(this).val();
                $("#selectedKeyword_2").val(selectedKeyword);
                $scope.keywordsSelected2 = selectedKeyword;
                $("#keywordTemplateElements\\[2\\]\\.inputValue").val(selectedKeyword);
            });

            $(document).on("change", "#assignmentSummaryTitle", function() {
                var selectedTitle = $(this).val();
                $("#assignmentSummaryTitle").val(selectedTitle);
                $scope.selectedTitle = selectedTitle;
                const targetTitleElement = document.querySelector('h2[name="assignmentTemplateElements[1].inputValue"]')
                targetTitleElement.innerHTML = selectedTitle;
             });
        }
    ])
    .controller("removeAssignedAjax", ["$scope", function($scope) {}])
    .controller("uploadAssignmentFileAjax", ["$scope", function($scope) {}])
    .controller("assignmentModifyAjax", ["$scope", function($scope) {}])
    .controller("assignmentDeleteAjax", ["$scope", function($scope) {}])
    .controller("assignmentWithdrawAjax", ["$scope", function($scope) {}])
    .controller("assignmentViewOverlayAjax", ["$scope", function($scope) {}])
    .controller("assignmentImport", [
        "$scope",
        "assignment",
        "common",
        "amplify",
        "setup",
        function($scope, assignment, common, amplify, setup) {
            assignment.init();
            common.init();
            amplify.init();
            if ($scope.dateAndTimePickerId) {
                setup.dateAndTimePicker($scope.dateAndTimePickerId);
            }

            //for new date/time picker in struts tag
            $scope.dates = {
                closeDateString: null,
                submitDeadlineString: null,
                publishDeadlineString: null,
                claimDeadlineString: null
            };

            $scope.momentDateFormat = $("#momentPickerFormat").val();
            $scope.dateformat = "MM-DD-YYYY";

            $scope.$watch("dates.closeDateString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.closeDateString == null) {
                    var closeDateString = $("#closeDateString").val();
                    $scope.dates.closeDateString = moment(
                        closeDateString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.closeDateString != null) {
                    $scope.closeDateString = moment(
                        $scope.dates.closeDateString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_closeDateString").val($scope.closeDateString);
                    $("#sT_date_closeDateString").trigger("change");
                }
            });

            $scope.$watch("dates.submitDeadlineString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.submitDeadlineString == null) {
                    var submitDeadlineString = $("#submitDeadlineString").val();
                    $scope.dates.submitDeadlineString = moment(
                        submitDeadlineString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.submitDeadlineString != null) {
                    $scope.submitDeadlineString = moment(
                        $scope.dates.submitDeadlineString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_submitDeadlineString").val(
                        $scope.submitDeadlineString
                    );
                    $("#sT_date_submitDeadlineString").trigger("change");
                }
            });

            $scope.$watch("dates.publishDeadlineString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.publishDeadlineString == null) {
                    var publishDeadlineString = $(
                        "#publishDeadlineString"
                    ).val();
                    $scope.dates.publishDeadlineString = moment(
                        publishDeadlineString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.publishDeadlineString != null) {
                    $scope.publishDeadlineString = moment(
                        $scope.dates.publishDeadlineString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_publishDeadlineString").val(
                        $scope.publishDeadlineString
                    );
                    $("#sT_date_publishDeadlineString").trigger("change");
                }
            });

            $scope.$watch("dates.claimDeadlineString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.claimDeadlineString == null) {
                    var claimDeadlineString = $("#claimDeadlineString").val();
                    $scope.dates.claimDeadlineString = moment(
                        claimDeadlineString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.claimDeadlineString != null) {
                    $scope.claimDeadlineString = moment(
                        $scope.dates.claimDeadlineString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_claimDeadlineString").val(
                        $scope.claimDeadlineString
                    );
                    $("#sT_date_claimDeadlineString").trigger("change");
                }
                console.log("6---", $scope.claimDeadlineString);
            });
        }
    ])
    .controller("assignmentImportAjax", ["$scope", function($scope) {}])
    .controller("assignmentImportSuccess", ["$scope", function($scope) {}])
    .controller("sampleImportFile", ["$scope", function($scope) {}])
    .controller("downloadFile", ["$scope", function($scope) {}])
    .controller("assignmentExport", ["$scope", function($scope) {}])
    .controller("uploadAssignmentsAjax", ["$scope", function($scope) {}])
    .controller("proposals", ["$scope", function($scope) {}])
    .controller("proposalReview", [
        "$scope",
        "assignment",
        "validationUtil",
        "amplify",
        "setup",
        function($scope, assignment, validationUtil, amplify, setup) {
            assignment.init();
            //        validationUtil.init();
            amplify.init();
            if ($scope.dateAndTimePickerId) {
                setup.dateAndTimePicker($scope.dateAndTimePickerId);
            }
        }
    ])
    .controller("proposalApproveAjax", ["$scope", function($scope) {}])
    .controller("proposalDeclineAjax", ["$scope", function($scope) {}])
    .controller("proposalDeclineSuggestAjax", ["$scope", function($scope) {}])
    .controller("trendingSearches", ["$scope", function($scope) {}])
    .controller("assignmentList", [
        "$scope",
        "assignment",
        function($scope, assignment) {
            assignment.init();
        }
    ])
    .controller("rfpList", [
        "$scope",
        "assignment",
        function($scope, assignment) {
            assignment.init();
        }
    ])
    .controller("assignmentUnscheduledList", [
        "$scope",
        "assignment",
        function($scope, assignment) {
            assignment.init();
        }
    ])
    .controller("assignmentIncompleteList", [
        "$scope",
        "assignment",
        function($scope, assignment) {
            assignment.init();
        }
    ])
    .controller("publishingCalendar", [
        "$scope",
        "assignment",
        function($scope, assignment) {
            assignment.init();
        }
    ])
    .controller("publishingCalendarAjax", ["$scope", function($scope) {}])
    .controller("publishingCalendarUpdateAjax", ["$scope", function($scope) {}])
    .controller("submissionCalendar", [
        "$scope",
        "assignment",
        function($scope, assignment) {
            assignment.init();
        }
    ])
    .controller("submissionCalendarAjax", ["$scope", function($scope) {}])
    .controller("submissionCalendarUpdateAjax", ["$scope", function($scope) {}])
    .controller("projectModify", [
        "$scope",
        function($scope) {
            //needed for new moment-datepicker
            $scope.dates = {
                startDate: null,
                endDate: null
            };

            //watches start date to make sure end date is not before start date
            $scope.$watch("[dates.startDate, dates.endDate]", function(
                newValue,
                oldValue
            ) {
                $scope.momentDateFormat = $("#momentPickerFormat").val();
                $scope.dateformat = "MM-DD-YYYY";

                if ($scope.dates.startDate != null) {
                    $scope.minDate = $scope.dates.startDate.toString();
                }
                if ($scope.dates.endDate != null) {
                    $scope.maxDate = $scope.dates.endDate.toString();
                }

                if (
                    $scope.dates.startDate == null &&
                    $scope.dates.endDate == null
                ) {
                    var startDateVal = $("#startString").val();
                    var endDateVal = $("#endString").val();
                    if (startDateVal != "" || endDateVal != "") {
                        $scope.dates.startDate = moment(
                            startDateVal,
                            $scope.dateformat
                        ).format($scope.momentDateFormat);
                        $scope.dates.endDate = moment(
                            endDateVal,
                            $scope.dateformat
                        ).format($scope.momentDateFormat);
                    }
                }

                if (
                    $scope.dates.startDate != null &&
                    $scope.dates.endDate != null
                ) {
                    $scope.startDateMoment = moment(
                        $scope.dates.startDate,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $scope.endDateMoment = moment(
                        $scope.dates.endDate,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#startString").val($scope.startDateMoment);
                    $("#endString").val($scope.endDateMoment);
                }
            });
        }
    ])
    .controller("projectCreate", [
        "$scope",
        function($scope) {
            //needed for new moment-datepicker
            $scope.dates = {
                startDate: null,
                endDate: null
            };

            //watches start date to make sure end date is not before start date
            $scope.$watch("[dates.startDate, dates.endDate]", function(
                newValue,
                oldValue
            ) {
                $scope.momentDateFormat = $("#momentPickerFormat").val();
                $scope.dateformat = "MM-DD-YYYY";

                if ($scope.dates.startDate != null) {
                    $scope.minDate = $scope.dates.startDate.toString();
                }
                if ($scope.dates.endDate != null) {
                    $scope.maxDate = $scope.dates.endDate.toString();
                }

                if (
                    $scope.dates.startDate == null &&
                    $scope.dates.endDate == null
                ) {
                    var startDateVal = $("#startString").val();
                    var endDateVal = $("#endString").val();
                    if (startDateVal != "" || endDateVal != "") {
                        $scope.dates.startDate = moment(
                            startDateVal,
                            $scope.dateformat
                        ).format($scope.momentDateFormat);
                        $scope.dates.endDate = moment(
                            endDateVal,
                            $scope.dateformat
                        ).format($scope.momentDateFormat);
                    }
                }

                if (
                    $scope.dates.startDate != null &&
                    $scope.dates.endDate != null
                ) {
                    $scope.startDateMoment = moment(
                        $scope.dates.startDate,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $scope.endDateMoment = moment(
                        $scope.dates.endDate,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#startString").val($scope.startDateMoment);
                    $("#endString").val($scope.endDateMoment);
                }
            });
        }
    ])
    .controller("projectList", [
        "$scope",
        "assignment",
        function($scope, assignment) {
            assignment.init();
        }
    ])
    .controller("calendarEventList", [
        "$scope",
        "assignment",
        function($scope, assignment) {
            assignment.init();
        }
    ])
    .controller("projectDetails", [
        "$scope",
        "assignment",
        "$window",
        "skyword",
        function($scope, assignment, $window, skyword) {
            assignment.init();

            $window.onload = function() {
                if ($(".displayTime").length) {
                    var lang = $("body").attr("data-language");
                    if (lang !== "en") {
                        $(".displayTime").attr("content24", function(
                            index,
                            val
                        ) {
                            let toDate = val.toString().split(",")[0];
                            $(this).text(toDate);
                        });
                    } else {
                        $(".displayTime").attr("content", function(index, val) {
                            let toDate = val.toString().split(",")[0];
                            $(this).text(toDate);
                        });
                    }
                }

                let view_project_details_as = sessionStorage.getItem(
                    "view-project-details-as"
                );
                if (
                    view_project_details_as === null ||
                    view_project_details_as === "" ||
                    view_project_details_as === undefined
                ) {
                    sessionStorage.setItem("view-project-details-as", "list");
                    $("#show-hide-value").val("list");
                } else {
                    $("#show-hide-value").val(view_project_details_as);
                }

                if ($("#show-hide-value").val() === "list") {
                    $("#show-hide-details").html("Details");
                    showHideDetails("hide");
                } else {
                    $("#show-hide-details").html("List");
                    showHideDetails("show");
                }
            };

            $("#show-hide-details").on("click", function() {
                var a = $(this)[0];
                switch (a.innerText) {
                    case "Details":
                        sessionStorage.setItem(
                            "view-project-details-as",
                            "details"
                        );
                        a.innerText = "List";
                        $("#show-hide-value").val("details");
                        showHideDetails("show");
                        break;
                    default:
                        sessionStorage.setItem(
                            "view-project-details-as",
                            "list"
                        );
                        a.innerText = "Details";
                        $("#show-hide-value").val("list");
                        showHideDetails("hide");
                        break;
                }
            });

            function showHideDetails(type) {
                let detailsTR = $(".show-details");
                for (let i = 0; i < detailsTR.length; i++) {
                    detailsTR[i].style.display =
                        type === "show" ? "table-row" : "none";
                }
            }

            $scope.togglePriority = function(id) {
                const programId = $("#attrs-id").data("programid");
                const token = skyword.getToken("access_token");
                const canSetPriority = $("#setPriority").val();
                const contentRequestId = parseInt(id);
                if(canSetPriority === "true") {
                    // toggle the flag
                    var markedPriority = $('#priorityFlagIcon-'+id).hasClass("light") ? true : false;
                    var data = {
                        markedPriority: markedPriority
                    };
                    const url = skyword.getAPIURL(`review/program/${programId}/content/${contentRequestId}/priority`);
                    jQuery.ajax({
                        cache: false,
                        type: "PUT",
                        headers: {
                            Accept: "application/json;",
                            "Content-Type": "application/json;"
                        },
                        url: url,
                        data: JSON.stringify(data),
                        beforeSend: function(xhr) {
                            xhr.setRequestHeader(
                                "Authorization",
                                `Bearer ${token}`
                            );
                        },
                        success: function(response, status, req) {
                            if(status === "success" && req.status === 200) {
                                $('#priorityFlagIcon-'+id).toggleClass('light', !markedPriority);
                                $("#isMarkedPriority").val(markedPriority)

                            }
                        },
                        error: function(error) {
                            console.log(error);
                        }
                    });
                }
            }
        }
    ])
    .controller("calendarEventDetails", [
        "$scope",
        "assignment",
        function($scope, assignment) {
            assignment.init();
        }
    ])
    .controller("viewBid", [
        "$scope",
        "assignment",
        function($scope, assignment) {
            assignment.init();
            function validateBidUserComment() {
                const bidUserComment = $("#bidUserComment").val();
                if (!bidUserComment || bidUserComment.trim() === "") {
                    $("#bidUserMessageRequired").css("display", "block");
                    return false;
                } else {
                    $("#bidUserMessageRequired").css("display", "none");
                    return true;
                }
            }
            $("body").on("click", "#saveBidComment", function() {
                if (validateBidUserComment()) {
                    assignment.saveBidComment($("#bidUserComment").val());
                }
            });
            $("body").on("click", "#closeBidComment", function() {
                $(this).colorbox.close();
                $("#btn-bid-return").removeClass("disabled");
            });
            // decline comment
            $("body").on("click", "#saveBidDeclineComment", function() {
                if (validateBidUserComment()) {
                    assignment.saveBidDeclineComment(
                        $("#bidUserComment").val(),
                        $("#rfpBidId").val()
                    );
                }
            });
            $("body").on("click", "#closeDeclineComment", function() {
                $(this).colorbox.close();
                $("#btn-bid-decline").removeClass("disabled");
            });

            //for new date/time picker in struts tag
            $scope.dates = {
                submitDeadlineString: null,
                publishDeadlineString: null,
                deliveryDeadlineString: null
            };

            $scope.momentDateFormat = $("#momentPickerFormat").val();
            $scope.dateformat = "MM-DD-YYYY";

            $scope.$watch("dates.submitDeadlineString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.submitDeadlineString == null) {
                    var submitDeadlineString = $("#submitDeadlineString").val();
                    $scope.dates.submitDeadlineString = moment(
                        submitDeadlineString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.submitDeadlineString != null) {
                    $scope.submitDeadlineString = moment(
                        $scope.dates.submitDeadlineString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_submitDeadlineString").val(
                        $scope.submitDeadlineString
                    );
                    $("#sT_date_submitDeadlineString").trigger("change");
                }
            });

            $scope.$watch("dates.publishDeadlineString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.publishDeadlineString == null) {
                    var publishDeadlineString = $(
                        "#publishDeadlineString"
                    ).val();
                    $scope.dates.publishDeadlineString = moment(
                        publishDeadlineString,
                        $scope.dateformat
                    ).format($scope.momentDateFormat);
                }

                if ($scope.dates.publishDeadlineString != null) {
                    $scope.publishDeadlineString = moment(
                        $scope.dates.publishDeadlineString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_publishDeadlineString").val(
                        $scope.publishDeadlineString
                    );
                    $("#sT_date_publishDeadlineString").trigger("change");
                }
            });

            $scope.$watch("dates.deliveryDeadlineString", function(
                newValue,
                oldValue
            ) {
                if ($scope.dates.deliveryDeadlineString == null) {
                    var deliveryDeadline = new Date();
                    var businessDaysDeadlineDelivered = isNaN(
                        $("#business-days-deadline-delivered").val()
                    )
                        ? 0
                        : parseInt(
                              $("#business-days-deadline-delivered").val()
                          );
                    var submitDeadline = new Date(
                        moment(
                            $scope.dates.submitDeadlineString,
                            $scope.dateformat
                        ).format($scope.momentDateFormat)
                    );
                    deliveryDeadline = new Date(
                        assignment.addBusinessDays(
                            submitDeadline,
                            businessDaysDeadlineDelivered
                        )
                    );
                    $scope.dates.deliveryDeadlineString = moment(
                        deliveryDeadline,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                }

                if ($scope.dates.deliveryDeadlineString !== null) {
                    $scope.deliveryDeadlineString = moment(
                        $scope.dates.deliveryDeadlineString,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#sT_date_deliveryDeadlineString").val(
                        $scope.deliveryDeadlineString
                    );
                    $("#sT_date_deliveryDeadlineString").trigger("change");
                }
            });
        }
    ])
    .controller("removeProject", ["$scope", function($scope) {}])
    .controller("projectCreateAjax", ["$scope", function($scope) {}])
    .controller("submitAssignmentAjax", ["$scope", function($scope) {}])
    .controller("submitAssignmentBeamback", ["$scope", function($scope) {}])
    .controller("claimAssignment", ["$scope", function($scope) {}])
    .controller("managerClaimAssignment", ["$scope", function($scope) {}])
    .controller("declineAssignmentAjax", ["$scope", function($scope) {}])
    .controller("managerDeclineAssignmentAjax", ["$scope", function($scope) {}])
    .controller("changeArticleContentTypeAjax", ["$scope", function($scope) {}])
    .controller("createClaimArticleForm", ["$scope", function($scope) {}])
    .controller("offerMultipleAssignments", [
        "$scope",
        "assignment",
        function($scope, assignment) {
            assignment.init();
        }
    ])
    .controller("offerMultipleAssignmentsAjax", ["$scope", function($scope) {}])
    .controller("claimSingleAssignmentAjax", ["$scope", function($scope) {}])
    .controller("managerClaimSingleAssignmentAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("campaignModify", [
        "$scope",
        function($scope) {
            //needed for new moment-datepicker
            $scope.dates = {
                startDate: null,
                endDate: null
            };

            //watches start date to make sure end date is not before start date
            $scope.$watch("[dates.startDate, dates.endDate]", function(
                newValue,
                oldValue
            ) {
                $scope.momentDateFormat = $("#momentPickerFormat").val();
                $scope.dateformat = "MM-DD-YYYY";

                if ($scope.dates.startDate != null) {
                    $scope.minDate = $scope.dates.startDate.toString();
                }
                if ($scope.dates.endDate != null) {
                    $scope.maxDate = $scope.dates.endDate.toString();
                }

                if (
                    $scope.dates.startDate == null &&
                    $scope.dates.endDate == null
                ) {
                    var startDateVal = $("#startString").val();
                    var endDateVal = $("#endString").val();
                    if (startDateVal != "" || endDateVal != "") {
                        $scope.dates.startDate = moment(
                            startDateVal,
                            $scope.dateformat
                        ).format($scope.momentDateFormat);
                        $scope.dates.endDate = moment(
                            endDateVal,
                            $scope.dateformat
                        ).format($scope.momentDateFormat);
                    }
                }

                if (
                    $scope.dates.startDate != null &&
                    $scope.dates.endDate != null
                ) {
                    $scope.startDateMoment = moment(
                        $scope.dates.startDate,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $scope.endDateMoment = moment(
                        $scope.dates.endDate,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#startString").val($scope.startDateMoment);
                    $("#endString").val($scope.endDateMoment);
                }
            });
        }
    ])
    .controller("campaignCreate", [
        "$scope",
        function($scope) {
            //needed for new moment-datepicker
            $scope.dates = {
                startDate: null,
                endDate: null
            };

            //watches start date to make sure end date is not before start date
            $scope.$watch("[dates.startDate, dates.endDate]", function(
                newValue,
                oldValue
            ) {
                $scope.momentDateFormat = $("#momentPickerFormat").val();
                $scope.dateformat = "MM-DD-YYYY";

                if ($scope.dates.startDate != null) {
                    $scope.minDate = $scope.dates.startDate.toString();
                }
                if ($scope.dates.endDate != null) {
                    $scope.maxDate = $scope.dates.endDate.toString();
                }

                if (
                    $scope.dates.startDate == null &&
                    $scope.dates.endDate == null
                ) {
                    var startDateVal = $("#startString").val();
                    var endDateVal = $("#endString").val();
                    if (startDateVal != "" || endDateVal != "") {
                        $scope.dates.startDate = moment(
                            startDateVal,
                            $scope.dateformat
                        ).format($scope.momentDateFormat);
                        $scope.dates.endDate = moment(
                            endDateVal,
                            $scope.dateformat
                        ).format($scope.momentDateFormat);
                    }
                }

                if (
                    $scope.dates.startDate != null &&
                    $scope.dates.endDate != null
                ) {
                    $scope.startDateMoment = moment(
                        $scope.dates.startDate,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $scope.endDateMoment = moment(
                        $scope.dates.endDate,
                        $scope.momentDateFormat
                    ).format($scope.dateformat);
                    $("#startString").val($scope.startDateMoment);
                    $("#endString").val($scope.endDateMoment);
                }
            });
        }
    ])
    .controller("campaignList", [
        "$scope",
        "assignment",
        function($scope, assignment) {
            assignment.init();
        }
    ])
    .controller("campaignDetails", [
        "$scope",
        "assignment",
        function($scope, assignment) {
            assignment.init();
        }
    ])
    .controller("promptTestMulti", [
        "$scope",
        "assignment",
        function($scope, assignment) {
            assignment.init();

            async function generatePrompt(index) {
                $(`#output${index}genLoader`).css("display", "flex");
                let input =
                    index === 1
                        ? $("#input").val()
                        : $(`#output${index - 1}`).val();
                let url = "/generateSeqPromptOutputAjax.action";
                let opt = {
                    cache: false,
                    type: "POST",
                    url: url,
                    data: {
                        csrfToken: $("#csrfToken").val(),
                        prompt: $(`#prompt${index}`).val(),
                        input: input
                    }
                };
                let response = await $.ajax(url, opt);
                if (
                    response !== "No prompt found" &&
                    response !== "An exception occurred"
                ) {
                    $(`#output${index}`).val(JSON.parse(response));
                    $(`#output${index}genLoader`).css("display", "none");
                    $(`#output${index}div`).css("display", "block");
                    return "success";
                } else {
                    $(`#output${index}genLoader`).css("display", "none");
                    let error = "<ul><li>Prompt is required</li></ul>";
                    $("#error-box").html(error);
                    $("#error-box").css("display", "block");
                    return response;
                }
            }

            $("#generateText").on("click", async function() {
                $("#error-box").css("display", "none");
                $("#success-box").css("display", "none");
                $("#warning-box").css("display", "none");
                let counter = 0;
                while (counter < 6) {
                    if (counter == 0) {
                        if (
                            $(`#prompt${counter + 1}`).val() !== "" &&
                            $("#input").val() !== ""
                        ) {
                            let response = await generatePrompt(counter + 1);
                            $("#error-box").css("display", "none");
                            if (response === "success") {
                                counter++;
                            } else {
                                let error = `<ul>`;
                                if (response !== "No prompt found") {
                                    error += `<li>Prompt is required</li>`;
                                } else {
                                    error += `
                                <li>An error occured while generating the output... Please try again later.</li>
                                `;
                                }
                                error += `</ul>`;
                                $("#error-box").html(error);
                                $("#error-box").css("display", "block");
                                break;
                            }
                        } else {
                            let error = `<ul>
                            <li>Input is required</li>
                            <li>Prompt is required</li></ul>`;
                            $("#error-box").html(error);
                            $("#error-box").css("display", "block");
                            break;
                        }
                    } else {
                        if ($(`#prompt${counter + 1}`).val() !== "") {
                            let response = await generatePrompt(counter + 1);
                            $("#error-box").css("display", "none");
                            if (response === "success") {
                                counter++;
                            } else {
                                let error = `<ul>`;
                                if (response !== "No prompt found") {
                                    error += `<li>Prompt is required</li>`;
                                } else {
                                    error += `
                                <li>An error occured while generating the output... Please try again later.</li>
                                `;
                                }
                                error += `</ul>`;
                                $("#error-box").html(error);
                                $("#error-box").css("display", "block");
                                break;
                            }
                        } else {
                            break;
                        }
                    }
                }
            });
        }
    ])
    .controller("promptTest", [
        "$scope",
        "assignment",
        "call",
        "$translate",
        "gpt3Encoder",
        "skyword",
        function($scope, assignment, call, $translate, gpt3Encoder, skyword) {
            assignment.init();
            gpt3Encoder.init();
            var totReferences = 1,
                enteredPrompts = [],
                redoPrompts = [];

            function generatedReferenceBlock(refName) {
                let html = ``;
                if (refName !== "contentBody") {
                    html = `
                    <div class="reference_params_block" id="${refName}Ref">
                        <div class="param_title">
                            <div class="bg-light text-center py-1 text-dark border border-dark prompt-references-title">&lt;${refName}&gt;</div>
                        </div>
                        <div class="param_input">
                        ${
                            refName !== "selfRefSummary"
                                ? refName === "contentPublishedUrl"
                                    ? `
                        <input type="text" class="prompt-references-input" name="contentUrl" id="${refName}" style="width:95%;" />
                        `
                                    : `<input type="text" class="prompt-references-input" name="${refName}" id="${refName}" style="width:95%;" />`
                                : `
                        <textarea style="width:98.3%; height:150px;" class="prompt-references-input" name="${refName}" id="${refName}" cols="30" rows="10"></textarea>
                        `
                        }
                        </div>
                        <div class="param_remove">
                            <a id="remove_${refName}" class="ml-2 prompt-references-remove"><span class="icon-Delete"></span></a>
                        </div>
                    </div>`;
                } else {
                    html = `
                    <div class="reference_params_block" id="${refName}Ref">
                        <div class="param_title">
                            <div class="bg-light text-center py-1 text-dark border border-dark prompt-references-title">&lt;${refName}&gt;</div>
                        </div>
                        <div class="param_input">
                            <textarea style="width:98.3%; height:150px;" class="prompt-references-input" name="articleBody" id="${refName}" cols="30" rows="10"></textarea>
                        </div>
                        <div class="param_remove">
                            <a id="remove_${refName}" class="ml-2 prompt-references-remove"><span class="icon-Delete"></span></a>
                        </div>
                    </div>`;
                }
                return html;
            }

            function showReferenceDD() {
                $("#addRefDD").toggle();
            }

            function addParameterToTextArea(parameter, textArea) {
                let textarea = document.getElementById(textArea);
                let start_position = textarea.selectionStart;
                let end_position = textarea.selectionEnd;

                textarea.value = `${textarea.value.substring(
                    0,
                    start_position
                )}<${parameter}>${textarea.value.substring(
                    end_position,
                    textarea.value.length
                )}`;
            }

            function showParamDD() {
                $("#addParamDD").toggle();
            }

            function showSysParamDD() {
                $("#addSysParamDD").toggle();
            }

            function debounce(func, timeout = 100) {
                let timer;
                return (...args) => {
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                        func.apply(this, args);
                    }, timeout);
                };
            }
            function updatePromptState(e) {
                if (e.target.value.trim() !== "") {
                    if (e.keyCode !== 32) {
                        enteredPrompts.push(e.target.value);
                        checkUndoRedoDisabled();
                        $("#savePrompt").removeAttr("disabled");
                    }
                } else {
                    $("#savePrompt").attr("disabled", "disabled");
                }
            }

            function getPromptLibraryBySearch(e) {
                //ajax call to fetch promptsList
                var urlAction = "/getPromptListAjax.action",
                    getPost = "GET",
                    options;
                options = {
                    cache: false,
                    type: getPost,
                    data: { searchPromptTemplate: e.target.value.trim() },
                    url: urlAction,
                    success: function(data) {
                        let html = $.parseHTML(data);
                        $("#promptSearchLoaderWrapper").css("display", "none");
                        $("#selectPromptOptions").html("");
                        $("#selectPromptOptions").html($(html[3]).html());
                        if (e.target.value.trim() !== "") {
                            $("#selectPromptOptions li")
                                .first()
                                .remove();
                        }
                        if ($("#selectedPrompt").val() !== "") {
                            $(
                                `#selectPromptOptions li[data-id="${$(
                                    "#selectedPrompt"
                                ).val()}"]`
                            ).addClass("active");
                        }
                        $("#selectPromptOptions").css("display", "block");
                    },
                    error: function(data) {
                        $("#promptSearchLoaderWrapper").css("display", "none");
                        $("#selectPromptOptions").css("display", "block");
                        console.log(
                            "Error: getActiveProgramsByName---------------: ",
                            data
                        );
                        $("#selectPromptOptionsWrapper").html(
                            `<li class="noProgram">Error Encountered. Please try again.</li>`
                        );
                    }
                };
                $.ajax(urlAction, options);
                // $("#savePrompt").attr("disabled", "disabled");
            }

            function deletePromptLibraryById(id) {
                //ajax call to fetch promptsList
                let searchPromptTemplate = $("#searchPromptLibrary").val();
                var urlAction = "/deletePromptAjax.action",
                    getPost = "POST",
                    options;
                options = {
                    cache: false,
                    type: getPost,
                    data: {
                        csrfToken: $("#csrfToken").val(),
                        promptId: id,
                        searchPromptTemplate: searchPromptTemplate.trim()
                    },
                    url: urlAction,
                    success: function(data) {
                        let html = $.parseHTML(data);
                        $("#promptSearchLoaderWrapper").css("display", "none");
                        $("#selectPromptOptions").html("");
                        $("#selectPromptOptions").html($(html[3]).html());
                        if (searchPromptTemplate.trim() !== "") {
                            $("#selectPromptOptions li")
                                .first()
                                .remove();
                        }
                        if ($("#selectedPrompt").val() !== "") {
                            if (
                                parseInt($("#selectedPrompt").val()) !==
                                parseInt(id)
                            ) {
                                $(
                                    `#selectPromptOptions li[data-id="${parseInt(
                                        $("#selectedPrompt").val()
                                    )}"]`
                                ).addClass("active");
                            } else {
                                $("#selectedPrompt").val("");
                                $("#selectedPrompt").trigger("change");
                            }
                        }
                        $(this).colorbox.close();
                    },
                    error: function(data) {
                        $("#promptSearchLoaderWrapper").css("display", "none");
                        console.log(
                            "Error: getActiveProgramsByName---------------: ",
                            data
                        );
                        $("#selectPromptOptionsWrapper").html(
                            `<li class="noProgram">Error Encountered. Please try again.</li>`
                        );
                        $(this).colorbox.close();
                    }
                };
                $.ajax(urlAction, options);
                // $("#savePrompt").attr("disabled", "disabled");
            }

            function getProgramListByName(e) {
                if (e.target.value.trim() !== "") {
                    $("#searchChannelList").css("display", "block");
                    if ($("#searchChannelList").find("li").length > 1) {
                        $("#searchChannelLoader").css("display", "block");
                        $(".programItem").remove();
                        $(".noProgram").remove();
                    }
                    var urlAction = "/getActiveProgramsAjax.action",
                        getPost = "GET",
                        options;
                    options = {
                        cache: false,
                        type: getPost,
                        data: { channelName: e.target.value },
                        url: urlAction,
                        success: function(data) {
                            $("#searchChannelLoader").css("display", "none");
                            $("#searchChannelList").append(data);
                        },
                        error: function(data) {
                            $("#searchChannelLoader").css("display", "none");
                            console.log(
                                "Error: getActiveProgramsByName---------------: ",
                                data
                            );
                            $("#searchChannelList").append(
                                `<li class="noProgram">Error Encountered. Please try again.</li>`
                            );
                        }
                    };
                    $.ajax(urlAction, options);
                } else {
                    $("#searchChannelList").css("display", "none");
                }
            }

            function checkUndoRedoDisabled() {
                if ($("#prompt").val() !== "") {
                    $("#savePrompt").removeAttr("disabled");
                } else {
                    $("#savePrompt").attr("disabled", "disabled");
                }
                if (enteredPrompts.length > 0) {
                    $("#undoPrompt").css("cursor", "pointer");
                    $("#undoPrompt").css("background-color", "");
                    $("#undoPrompt").css("border", "");
                } else {
                    $("#undoPrompt").css("cursor", "not-allowed");
                    $("#undoPrompt").css("background-color", "#d1d8dd");
                    $("#undoPrompt").css("border", "1px solid #d1d8dd");
                }

                if (redoPrompts.length > 0) {
                    $("#redoPrompt").css("cursor", "pointer");
                    $("#redoPrompt").css("background-color", "");
                    $("#redoPrompt").css("border", "");
                } else {
                    $("#redoPrompt").css("cursor", "not-allowed");
                    $("#redoPrompt").css("background-color", "#d1d8dd");
                    $("#redoPrompt").css("border", "1px solid #d1d8dd");
                }
            }

            function removeReferenceBlock(id) {
                totReferences -= 1;
                if (totReferences === 16) {
                    $("#addRefBtn").css("display", "none");
                } else {
                    $("#addRefBtn").css("display", "inline-block");
                }
                if (id !== "contentBodyRef") {
                    $("#" + id + "_Link").toggle();

                    $("#" + id + "Ref").remove();
                } else {
                    id = id.replaceAll("Ref", "");
                    $("#" + id + "_Link").toggle();

                    $("#" + id + "Ref").remove();
                }
            }

            function warnReferenceExists(refName) {
                var refName_lowerCase = refName.toLowerCase();
                var warning = "";
                var warningname = "";
                refName_lowerCase =
                    refName_lowerCase === "contentbody"
                        ? "articlebody"
                        : refName_lowerCase === "contentpublishedurl"
                            ? "contenturl"
                            : refName_lowerCase;
                if ($("#" + refName).length !== 0) {
                    warningname =
                        "sw.warning." +
                        refName_lowerCase +
                        "warnreferenceexists";
                    warning =
                        "<li>" + $translate.instant(warningname) + "</li>";
                }

                return warning;
            }

            function checkReferenceExists(refName) {
                var refName_lowerCase = refName.toLowerCase();
                var error = "";
                var errorname = "";
                refName_lowerCase =
                    refName_lowerCase === "contentbody"
                        ? "articlebody"
                        : refName_lowerCase === "contentpublishedurl"
                            ? "contenturl"
                            : refName_lowerCase;
                if ($("#" + refName).length !== 0) {
                    if ($("#" + refName).val() === "") {
                        errorname =
                            "sw.error." +
                            refName_lowerCase +
                            "referencerequired";
                        error =
                            "<li>" + $translate.instant(errorname) + "</li>";
                    }
                } else {
                    errorname =
                        "sw.error.add" + refName_lowerCase + "reference";
                    let errorMsg = $translate.instant(errorname);
                    errorMsg = errorMsg
                        .replaceAll("<", "&lt;")
                        .replaceAll(">", "&gt;");
                    error = "<li>" + errorMsg + "</li>";
                }

                return error;
            }

            function validForm() {
                var isValid = true;
                var hasWaring = false;
                var error = "<ul class='content'>",
                    getError = "",
                    getWarning = "",
                    warning = "<ul class='content'>";
                var prompt = $("#prompt").val();
                if (prompt !== "") {
                    prompt = $("#systemMessage").val() + " " + prompt;
                    if (prompt.indexOf("<contentBody>") != -1) {
                        getError = checkReferenceExists("contentBody");
                        if (getError !== "") {
                            isValid = false;
                            error += getError;
                        } else if ($("#contentBody").val() === "") {
                            isValid = false;
                            let errorMsg = $translate.instant(
                                "sw.error.articlebodytagrequired"
                            );
                            errorMsg = errorMsg
                                .replaceAll("<", "&lt;")
                                .replaceAll(">", "&gt;");
                            error += "<li>" + errorMsg + "</li>";
                        }
                    } else {
                        getWarning = warnReferenceExists("contentBody");
                        if (getWarning !== "") {
                            hasWaring = true;
                            warning += getWarning;
                        }
                    }

                    if (prompt.indexOf("<brandName>") != -1) {
                        getError = checkReferenceExists("brandName");
                        if (getError !== "") {
                            isValid = false;
                            error += getError;
                        }
                    } else {
                        getWarning = warnReferenceExists("brandName");
                        if (getWarning !== "") {
                            promptAfterReplace = prompt.replaceAll(
                                "<brandName>",
                                ""
                            );
                            hasWaring = true;
                            warning += getWarning;
                        }
                    }

                    if (prompt.indexOf("<brandVoice>") != -1) {
                        getError = checkReferenceExists("brandVoice");
                        if (getError !== "") {
                            isValid = false;
                            error += getError;
                        }
                    } else {
                        getWarning = warnReferenceExists("brandVoice");
                        if (getWarning !== "") {
                            hasWaring = true;
                            warning += getWarning;
                        }
                    }

                    if (prompt.indexOf("<contentTitle>") != -1) {
                        getError = checkReferenceExists("contentTitle");
                        if (getError !== "") {
                            isValid = false;
                            error += getError;
                        }
                    } else {
                        getWarning = warnReferenceExists("contentTitle");
                        if (getWarning !== "") {
                            hasWaring = true;
                            warning += getWarning;
                        }
                    }

                    if (prompt.indexOf("<contentSEODesc>") != -1) {
                        getError = checkReferenceExists("contentSEODesc");
                        if (getError !== "") {
                            isValid = false;
                            error += getError;
                        }
                    } else {
                        getWarning = warnReferenceExists("contentSEODesc");
                        if (getWarning !== "") {
                            hasWaring = true;
                            warning += getWarning;
                        }
                    }

                    if (prompt.indexOf("<personaAudience>") != -1) {
                        getError = checkReferenceExists("personaAudience");
                        if (getError !== "") {
                            isValid = false;
                            error += getError;
                        }
                    } else {
                        getWarning = warnReferenceExists("personaAudience");
                        if (getWarning !== "") {
                            hasWaring = true;
                            warning += getWarning;
                        }
                    }

                    if (prompt.indexOf("<personaName>") != -1) {
                        getError = checkReferenceExists("personaName");
                        if (getError !== "") {
                            isValid = false;
                            error += getError;
                        }
                    } else {
                        getWarning = warnReferenceExists("personaName");
                        if (getWarning !== "") {
                            hasWaring = true;
                            warning += getWarning;
                        }
                    }

                    if (prompt.indexOf("<contentPublishedUrl>") != -1) {
                        getError = checkReferenceExists("contentPublishedUrl");
                        if (getError !== "") {
                            isValid = false;
                            error += getError;
                        }
                    } else {
                        getWarning = warnReferenceExists("contentPublishedUrl");
                        if (getWarning !== "") {
                            hasWaring = true;
                            warning += getWarning;
                        }
                    }

                    if (prompt.indexOf("<contentKeyword>") != -1) {
                        getError = checkReferenceExists("contentKeyword");
                        if (getError !== "") {
                            isValid = false;
                            error += getError;
                        }
                    } else {
                        getWarning = warnReferenceExists("contentKeyword");
                        if (getWarning !== "") {
                            hasWaring = true;
                            warning += getWarning;
                        }
                    }

                    if (prompt.indexOf("<audienceName>") != -1) {
                        getError = checkReferenceExists("audienceName");
                        if (getError !== "") {
                            isValid = false;
                            error += getError;
                        }
                    } else {
                        getWarning = warnReferenceExists("audienceName");
                        if (getWarning !== "") {
                            hasWaring = true;
                            warning += getWarning;
                        }
                    }

                    if (prompt.indexOf("<audienceDesc>") != -1) {
                        getError = checkReferenceExists("audienceDesc");
                        if (getError !== "") {
                            isValid = false;
                            error += getError;
                        }
                    } else {
                        getWarning = warnReferenceExists("audienceDesc");
                        if (getWarning !== "") {
                            hasWaring = true;
                            warning += getWarning;
                        }
                    }
                    if (prompt.indexOf("<purpose>") != -1) {
                        getError = checkReferenceExists("purpose");
                        if (getError !== "") {
                            isValid = false;
                            error += getError;
                        }
                    } else {
                        getWarning = warnReferenceExists("purpose");
                        if (getWarning !== "") {
                            hasWaring = true;
                            warning += getWarning;
                        }
                    }
                    if (prompt.indexOf("<selfRefTitle>") != -1) {
                        getError = checkReferenceExists("selfRefTitle");
                        if (getError !== "") {
                            isValid = false;
                            error += getError;
                        }
                    } else {
                        getWarning = warnReferenceExists("selfRefTitle");
                        if (getWarning !== "") {
                            hasWaring = true;
                            warning += getWarning;
                        }
                    }
                    if (prompt.indexOf("<selfRefKeyword>") != -1) {
                        getError = checkReferenceExists("selfRefKeyword");
                        if (getError !== "") {
                            isValid = false;
                            error += getError;
                        }
                    } else {
                        getWarning = warnReferenceExists("selfRefKeyword");
                        if (getWarning !== "") {
                            hasWaring = true;
                            warning += getWarning;
                        }
                    }

                    if (prompt.indexOf("<selfRefSummary>") != -1) {
                        getError = checkReferenceExists("selfRefSummary");
                        if (getError !== "") {
                            isValid = false;
                            error += getError;
                        }
                    } else {
                        getWarning = warnReferenceExists("selfRefSummary");
                        if (getWarning !== "") {
                            hasWaring = true;
                            warning += getWarning;
                        }
                    }
                    if (prompt.indexOf("<brandDomain>") != -1) {
                        getError = checkReferenceExists("brandDomain");
                        if (getError !== "") {
                            isValid = false;
                            error += getError;
                        }
                    } else {
                        getWarning = warnReferenceExists("brandDomain");
                        if (getWarning !== "") {
                            hasWaring = true;
                            warning += getWarning;
                        }
                    }

                    if (hasWaring) {
                        warning += "</ul>";
                        $("#warning-box").html(warning);
                        $("#warning-box").css("display", "block");
                    }

                    if (!isValid) {
                        error += "</ul>";
                        $("#error-box").html(error);
                        $("#error-box").css("display", "block");
                    }
                } else {
                    isValid = false;
                    error +=
                        "<li>" +
                        $translate.instant(
                            "sw.error.promptrequiredtogenerate"
                        ) +
                        "</li></ul>";
                    $("#error-box").html(error);
                    $("#error-box").css("display", "block");
                }
                return isValid;
            }

            function replaceRefsWithValueTokenCounting() {
                let prompt =
                    $("#systemMessage").val() + " " + $("#prompt").val();
                if (prompt.indexOf("<contentBody>") !== -1) {
                    prompt = prompt.replaceAll(
                        "<contentBody>",
                        $("#contentBody").val()
                    );
                }
                if (prompt.indexOf("<contentTitle>") !== -1) {
                    prompt = prompt.replaceAll(
                        "<contentTitle>",
                        $("#contentTitle").val()
                    );
                }
                if (prompt.indexOf("<brandName>") !== -1) {
                    prompt = prompt.replaceAll(
                        "<brandName>",
                        $("#brandName").val()
                    );
                }
                if (prompt.indexOf("<brandVoice>") !== -1) {
                    prompt = prompt.replaceAll(
                        "<brandVoice>",
                        $("#brandVoice").val()
                    );
                }
                if (prompt.indexOf("<contentSEODesc>") !== -1) {
                    prompt = prompt.replaceAll(
                        "<contentSEODesc>",
                        $("#contentSEODesc").val()
                    );
                }
                if (prompt.indexOf("<personaAudience>") !== -1) {
                    prompt = prompt.replaceAll(
                        "<personaAudience>",
                        $("#personaAudience").val()
                    );
                }
                if (prompt.indexOf("<personaName>") !== -1) {
                    prompt = prompt.replaceAll(
                        "<personaName>",
                        $("#personaName").val()
                    );
                }
                if (prompt.indexOf("<contentPublishedUrl>") !== -1) {
                    prompt = prompt.replaceAll(
                        "<contentPublishedUrl>",
                        $("#contentPublishedUrl").val()
                    );
                }
                if (prompt.indexOf("<contentKeyword>") !== -1) {
                    prompt = prompt.replaceAll(
                        "<contentKeyword>",
                        $("#contentKeyword").val()
                    );
                }
                if (prompt.indexOf("<audienceName>") !== -1) {
                    prompt = prompt.replaceAll(
                        "<audienceName>",
                        $("#audienceName").val()
                    );
                }
                if (prompt.indexOf("<audienceDesc>") !== -1) {
                    prompt = prompt.replaceAll(
                        "<audienceDesc>",
                        $("#audienceDesc").val()
                    );
                }
                if (prompt.indexOf("<purpose>") !== -1) {
                    prompt = prompt.replaceAll(
                        "<purpose>",
                        $("#purpose").val()
                    );
                }
                if (prompt.indexOf("<selfRefTitle>") !== -1) {
                    prompt = prompt.replaceAll(
                        "<selfRefTitle>",
                        $("#selfRefTitle").val()
                    );
                }
                if (prompt.indexOf("<selfRefKeyword>") !== -1) {
                    prompt = prompt.replaceAll(
                        "<selfRefKeyword>",
                        $("#selfRefKeyword").val()
                    );
                }
                if (prompt.indexOf("<selfRefSummary>") !== -1) {
                    prompt = prompt.replaceAll(
                        "<selfRefSummary>",
                        $("#selfRefSummary").val()
                    );
                }
                if (prompt.indexOf("<brandDomain>") !== -1) {
                    prompt = prompt.replaceAll(
                        "<brandDomain>",
                        $("#brandDomain").val()
                    );
                }

                let encoded = gpt3Encoder.encode(prompt);
                return encoded.length;
            }

            function savePromptValid() {
                let isValid = true;
                if (
                    $("#promptName")
                        .val()
                        .trim() === ""
                ) {
                    $("#nameErr").css("display", "block");
                    isValid = false;
                } else {
                    $("#nameErr").css("display", "none");
                }

                if (
                    $("#promptValue")
                        .val()
                        .trim() === ""
                ) {
                    $("#promptErr").css("display", "block");
                    isValid = false;
                } else {
                    $("#promptErr").css("display", "none");
                }

                if (
                    $("#overwrite").is(":checked") &&
                    $("#overwritePrompt")
                        .val()
                        .trim() === ""
                ) {
                    $("#overwritePromptErr").css("display", "block");
                    isValid = false;
                } else {
                    $("#overwritePromptErr").css("display", "none");
                }

                return isValid;
            }

            function refreshHistoryListOnGenerateText() {
                var urlAction = "/refreshHistoryList.action",
                    getPost = "GET",
                    options;
                options = {
                    cache: false,
                    type: getPost,
                    data: {},
                    url: urlAction,
                    success: function(data) {
                        let historyList = JSON.parse(data);
                        if (historyList.length > 0) {
                            $("ul#historyWrapper").html("");
                            let html = ``;
                            historyList.forEach(
                                (item) =>
                                    (html += `
                                <li style="margin-bottom: 0 !important; cursor:pointer;" id="${
                                    item.promptHistoryId
                                }">
                                    <h3 style="margin-top: 10px !important;">${
                                        item.promptTitle
                                    }</h3>
                                    <p style="margin:0; padding:0;">
                                        ${new Date(item.dateGenerated).toString(
                                            `MMM dd, yyyy`
                                        )} at ${new Date(
                                        item.dateGenerated
                                    ).toString("hh:mm tt")}
                                    </p>
                                </li>`)
                            );
                            $("ul#historyWrapper").append(html);
                            let listId = $("ul#historyWrapper li:first").attr(
                                "id"
                            );
                            $("#promptHistoryInUse").val(listId);
                            $("ul#historyWrapper li:first").addClass("active");
                        } else {
                            $("ul#historyWrapper").html("");
                            $("ul#historyWrapper").html(
                                `<li class="noProgram">No history of past prompt found.</li>`
                            );
                        }
                    },
                    error: function(data) {
                        $("#history-list").html("");
                        let html = `<input type="hidden" name="promptHistoryInUse" id="promptHistoryInUse" />`;
                        $("#history-list").append(html);
                        console.log(
                            "Error ----------> refresh history list-------------->",
                            data
                        );
                    }
                };
                $.ajax(urlAction, options);
            }

            $(document).on("ready", function() {
                checkUndoRedoDisabled();
                $("#history-list").bind("heightChange", function() {
                    $("#history-list").css(
                        "height",
                        $(".promptInputs").height() - 50
                    );
                    // alert($(".promptInputs").height());
                });

                var refLinks =
                    "#contentBody_Link, #contentTitle_Link, #brandName_Link, #brandVoice_Link, #contentSEODesc_Link, #personaAudience_Link, #personaName_Link, #contentPublishedUrl_Link, #contentKeyword_Link, #audienceName_Link, #audienceDesc_Link, #purpose_Link, #selfRefTitle_Link, #selfRefKeyword_Link, #selfRefSummary_Link, #brandDomain_Link";
                var paramLinks =
                    "#contentBody_param, #contentTitle_param, #brandName_param, #brandVoice_param, #contentSEODesc_param, #personaAudience_param, #personaName_param, #contentPublishedUrl_param, #contentKeyword_param, #audienceName_param, #audienceDesc_param, #purpose_param, #selfRefTitle_param, #selfRefKeyword_param, #selfRefSummary_param, #promptOutput_param, #prompt_param, #brandDomain_param";
                var sysParamLinks =
                    "#contentBody_sysParam, #contentTitle_sysParam, #brandName_sysParam, #brandVoice_sysParam, #contentSEODesc_sysParam, #personaAudience_sysParam, #personaName_sysParam, #contentPublishedUrl_sysParam, #contentKeyword_sysParam, #audienceName_sysParam, #audienceDesc_sysParam, #purpose_sysParam, #selfRefTitle_sysParam, #selfRefKeyword_sysParam, #selfRefSummary_sysParam, #brandDomain_sysParam";
                var body = $("body");

                $("#addRefBtn").on("click", function() {
                    showReferenceDD();
                });

                $("#addParamBtn").on("click", function() {
                    showParamDD();
                });

                $("#addSysParamBtn").on("click", function() {
                    showSysParamDD();
                });

                $(refLinks).on("click", function() {
                    totReferences += 1;
                    let link = $(this),
                        id = link.attr("id");
                    let html = generatedReferenceBlock(id.split("_")[0]);
                    showReferenceDD();
                    $(this).toggle();
                    $("#referencesList").append(html);
                    if (totReferences === 16) {
                        $("#addRefBtn").css("display", "none");
                    }
                });

                $(paramLinks).on("click", function() {
                    let link = $(this),
                        id = link.attr("id");
                    addParameterToTextArea(id.split("_")[0], "prompt");
                    showParamDD();
                    let prompt = $("#prompt").val();
                    enteredPrompts.push(prompt);
                    checkUndoRedoDisabled();
                    // $(this).toggle();
                });

                $(sysParamLinks).on("click", function() {
                    let link = $(this),
                        id = link.attr("id");
                    addParameterToTextArea(id.split("_")[0], "systemMessage");
                    showSysParamDD();
                    // $(this).toggle();
                });

                $(document).on("click", function(event) {
                    if (
                        !event.target.matches("#iconAddContent") &&
                        !event.target.matches("#addRefBtn") &&
                        !event.target.matches("#addParamBtn") &&
                        !event.target.matches("#addSysParamBtn") &&
                        !event.target.matches("#iconMinusContent") &&
                        !event.target.matches("#selectProgram") &&
                        !event.target.matches("#searchChannelList") &&
                        !event.target.matches(".programItem")
                    ) {
                        let refMenuOpen = $("#addRefDD").css("display");
                        if (refMenuOpen === "block") {
                            $("#addRefDD").toggle();
                        }

                        let paramMenuOpen = $("#addParamDD").css("display");
                        if (paramMenuOpen === "block") {
                            $("#addParamDD").toggle();
                        }

                        let sysParamMenuOpen = $("#addSysParamDD").css(
                            "display"
                        );
                        if (sysParamMenuOpen === "block") {
                            $("#addSysParamDD").toggle();
                        }

                        let refChannelOpen = $("#searchChannelList").css(
                            "display"
                        );
                        if (refChannelOpen === "block") {
                            if ($("#searchChannelList").find("li").length > 1) {
                                $("#searchChannelLoader").css(
                                    "display",
                                    "block"
                                );
                                $(".programItem").remove();
                                $(".noProgram").remove();
                            }
                            if ($("#programName").val() === undefined) {
                                $("#brand_name").val("");
                                $("#brand_voice").val("");
                                $("#system_message").val("");
                                $("#selectProgram").val("");
                            } else {
                                $("#selectProgram").val(
                                    $("#programName").val()
                                );
                            }
                            $("#searchChannelList").toggle();
                        }
                    }

                    if (
                        event.target.matches("#selectedPromptWrapper") ||
                        event.target.matches("#selectPrompt") ||
                        event.target.matches("#selectPromptOptionsWrapper") ||
                        event.target.matches("#searchPromptLibrary") ||
                        event.target.matches("#selectPromptOptions") ||
                        event.target.matches("#selectPromptOptions li")
                    ) {
                    } else {
                        $("#selectPromptOptionsWrapper").removeClass("show");
                    }

                    if (
                        event.target.matches("#addParamBtn") ||
                        event.target.matches("#addSysParamBtn")
                    ) {
                        if (event.target.matches("#addParamBtn")) {
                            let sysParamMenuOpen = $("#addSysParamDD").css(
                                "display"
                            );
                            if (sysParamMenuOpen === "block") {
                                $("#addSysParamDD").toggle();
                            }
                        } else {
                            let paramMenuOpen = $("#addParamDD").css("display");
                            if (paramMenuOpen === "block") {
                                $("#addParamDD").toggle();
                            }
                        }
                    }
                });

                $("#cannedParameterSet").on("change", function(e) {
                    let cannedParameterSetId =
                        e.target.value !== ""
                            ? parseInt(e.target.value)
                            : e.target.value;
                    if (cannedParameterSetId !== "") {
                        var urlAction = "/getCannedParameterListAjax.action",
                            getPost = "GET",
                            options;
                        options = {
                            cache: false,
                            type: getPost,
                            data: {
                                cannedParameterSetId: cannedParameterSetId
                            },
                            url: urlAction,
                            success: function(data) {
                                let cannedParameterList = JSON.parse(data);
                                $(
                                    "#remove_brandName, #remove_contentTitle, #remove_brandVoice, #remove_contentSEODesc, #remove_contentPublishedUrl, #remove_personaAudience, #remove_personaName, #remove_contentKeyword, #remove_audienceName, #remove_audienceDesc, #remove_purpose, #remove_selfRefTitle, #remove_selfRefKeyword, #remove_selfRefSummary, #remove_brandDomain"
                                ).trigger("click");
                                for (
                                    let i = 0;
                                    i < cannedParameterList.length;
                                    i++
                                ) {
                                    let attributeName =
                                        cannedParameterList[i].attributeName;
                                    let attributeValue =
                                        cannedParameterList[i].attributeValue;
                                    attributeName = attributeName
                                        .replaceAll("<", "")
                                        .replaceAll(">", "");
                                    if (
                                        $("#" + attributeName + "Ref")
                                            .length === 0
                                    ) {
                                        //generate HTML
                                        $(
                                            "#" + attributeName + "_Link"
                                        ).trigger("click");
                                    }
                                    //replace Value
                                    $("#" + attributeName).val(
                                        attributeValue.trim()
                                    );
                                }
                            },
                            error: function(data) {
                                console.log(
                                    "Error: getActiveProgramsByName---------------: ",
                                    data
                                );
                            }
                        };
                        $.ajax(urlAction, options);
                    }
                });

                $("#selectPrompt").on("click", function() {
                    $("#selectPromptOptionsWrapper").toggleClass("show");
                });

                body.on("click", "#selectPromptOptions li", function(e) {
                    let classes = $(e.target).attr("class");

                    if (!classes.includes("deletePrompt")) {
                        let { id } = e.target.dataset;
                        $("#selectPromptOptionsWrapper").toggleClass("show");
                        $("#selectPromptOptions li").removeClass("active");
                        $(this).addClass("active");
                        $(`#selectedPrompt option[value="${id}"]`).attr(
                            "selected",
                            "selected"
                        );
                        $("#selectPrompt").text(
                            $(`#selectedPrompt option:selected`).text()
                        );
                        if ($("#selectedPrompt").val() !== "") {
                            var desc = $(
                                "#selectedPrompt option:selected"
                            ).attr("data-desc");
                            var prompt = $(
                                "#selectedPrompt option:selected"
                            ).attr("data-prompt");
                            var dateupdated = $(
                                "#selectedPrompt option:selected"
                            ).attr("data-dateupdated");

                            $("#promptDesc").text(desc);
                            $("#prompt").val(prompt);

                            if (dateupdated !== "") {
                                let time = new Date(
                                    dateupdated
                                ).toLocaleTimeString("en-US", {
                                    timeZone: "America/New_York",
                                    hour12: false
                                });
                                let date = moment(
                                    new Date(dateupdated),
                                    "MM-DD-YYYY"
                                ).format("DD MMM YYYY");

                                dateupdated =
                                    "Updated On: " + date + " at " + time;
                                $("#dateUpdated").text(dateupdated);
                            } else {
                                $("#dateUpdated").html("");
                            }
                            enteredPrompts.push(prompt);
                            $("#savePrompt").removeAttr("disabled");
                            checkUndoRedoDisabled();
                        } else {
                            $("#promptDesc").html("");
                            $("#dateUpdated").html("");
                        }
                    } else {
                        let { id } = e.target.dataset;
                        let header = "Please confirm";
                        let msg = `You are about to delete <strong>${$(
                            `#selectedPrompt option[value="${id}"]`
                        ).text()}</strong>.`;
                        let html = `<div class='m-3' style="width:95%;">
                                        <h1>${header}</h1>
                                        <p style="font-size:1.6rem;">${msg}</p>
                                        <div class="d-flex align-items-center mt-3">
                                            <input type="hidden" id="delete_prompt" value="${id}" />
                                            <button class="btn btn-primary btn-sm" id="deletePromptBtn" type="button">Delete Prompt</button>
                                            <a id="closeDeletePrompt" class="text-primary">Cancel</a>
                                        </div>
                                    </div>`;
                        $.colorbox({
                            html: html,
                            width: "30%",
                            height: "auto",
                            maxHeight: "auto",
                            escKey: false,
                            overlayClose: false
                        });
                        $("#cboxClose").css("display", "none");
                    }
                });

                body.on("click", "#closeDeletePrompt", function(e) {
                    $(this).colorbox.close();
                });

                body.on("click", "#deletePromptBtn", function() {
                    let id = $("#delete_prompt").val();
                    deletePromptLibraryById(id);
                });

                body.on(
                    "keyup",
                    "#searchPromptLibrary",
                    debounce(function(e) {
                        $("#selectPromptOptions").css("display", "none");
                        $("#promptSearchLoaderWrapper").css("display", "block");
                        getPromptLibraryBySearch(e);
                    }, 1000)
                );

                $("#selectedPrompt").on("change", function(e) {
                    $("#selectPrompt").text(
                        e.target.options[e.target.selectedIndex].innerText
                    );
                    if ($("#selectedPrompt").val() !== "") {
                        var { desc, prompt, dateupdated } = e.target.options[
                            e.target.selectedIndex
                        ].dataset;
                        $("#promptDesc").text(desc);
                        $("#prompt").val(prompt);

                        if (dateupdated !== "") {
                            let time = new Date(dateupdated).toLocaleTimeString(
                                "en-US",
                                {
                                    timeZone: "America/New_York",
                                    hour12: false
                                }
                            );
                            let date = moment(
                                new Date(dateupdated),
                                "MM-DD-YYYY"
                            ).format("DD MMM YYYY");

                            dateupdated = "Updated On: " + date + " at " + time;
                            $("#dateUpdated").text(dateupdated);
                        } else {
                            $("#dateUpdated").html("");
                        }
                        enteredPrompts.push(prompt);
                        $("#savePrompt").removeAttr("disabled");
                        checkUndoRedoDisabled();
                    } else {
                        $("#promptDesc").html("");
                        $("#dateUpdated").html("");
                    }
                });

                $("#prompt").keyup(
                    debounce(function(e) {
                        updatePromptState(e);
                    })
                );

                $("#selectProgram").keyup(
                    debounce(function(e) {
                        getProgramListByName(e);
                    }, 1000)
                );

                $("#undoPrompt").on("click", function() {
                    let lastEnteredPrompt = enteredPrompts.pop();
                    redoPrompts.push(lastEnteredPrompt);
                    if (enteredPrompts.length > 0) {
                        let lastPrompt =
                            enteredPrompts[enteredPrompts.length - 1];
                        document.getElementById("prompt").value = lastPrompt;
                    } else {
                        document.getElementById("prompt").value = "";
                    }
                    checkUndoRedoDisabled();
                });

                $("#redoPrompt").on("click", function() {
                    if (redoPrompts.length > 0) {
                        let lastEnteredPrompt = redoPrompts.pop();
                        enteredPrompts.push(lastEnteredPrompt);
                        document.getElementById(
                            "prompt"
                        ).value = lastEnteredPrompt;
                    }
                    checkUndoRedoDisabled();
                });

                body.on("click", ".programItem", function(e) {
                    $("#selectProgram").val($(this).text());
                    var {
                        brandname,
                        brandvoice,
                        audience,
                        persona,
                        system_message,
                        programid,
                        programname
                    } = $(this).data();

                    document.getElementById("brand_name").value = brandname;
                    document.getElementById("brand_voice").value = brandvoice;
                    document.getElementById(
                        "system_message"
                    ).value = system_message;
                    document.getElementById("programId").value = programid;
                    document.getElementById("programName").value = programname;
                    if ($("#searchChannelList").find("li").length > 1) {
                        $(".programItem").remove();
                        $(".noProgram").remove();
                        $("#searchChannelLoader").css("display", "block");
                    }
                    $("#searchChannelList").toggle();
                    $("#channelLookupDetails").css("display", "block");

                    //SKYW-914 Get Channel Guidelines and personas for selected programId
                    $("#content-checklist-module").removeClass("active");
                    $("#persona-list-module").removeClass("active");
                    $("#associated-prompts-list-module").removeClass("active");
                    $("#channel-lookup-module").addClass("active");
                    var urlAction = "/getProgramChannelGuidelinesAjax.action",
                        getPost = "GET",
                        options;
                    options = {
                        cache: false,
                        type: getPost,
                        data: { programId: programid },
                        url: urlAction,
                        success: function(data) {
                            $("#contentGuidelinesChecklistDiv").html("");
                            $("#contentGuidelinesChecklistDiv").append(data);
                        },
                        error: function(data) {
                            console.log(
                                "Error: getProgramChannelGuidelinesAjax---------------: ",
                                data
                            );
                            $("#contentGuidelinesChecklistDiv").html("");
                            $("#contentGuidelinesChecklistDiv").append(
                                `<ul id="channelGuidelinesWrapper" style="padding-left: 30px;">
                                    <li class="noGuidelines">No guidelines found for searched channel.</li>
                                </ul>`
                            );
                        }
                    };
                    $.ajax(urlAction, options);

                    var urlAction = "/getProgramPersonasAjax.action",
                        getPost = "GET",
                        options;
                    options = {
                        cache: false,
                        type: getPost,
                        data: { programId: programid },
                        url: urlAction,
                        success: function(data) {
                            $("#personalistDiv").html("");
                            $("#personalistDiv").append(data);
                        },
                        error: function(data) {
                            console.log(
                                "Error: getProgramPersonasAjax---------------: ",
                                data
                            );
                            $("#personalistDiv").html("");
                            $("#personalistDiv").append(
                                `<ul id="personasWrapper" style="padding-left: 30px;">
                                    <li class="noPersona">No personas found for searched channel.</li>
                                </ul>`
                            );
                        }
                    };
                    $.ajax(urlAction, options);

                    var urlAction = "/programAssociatedPromptsListAjax.action",
                        getPost = "GET",
                        options;
                    options = {
                        cache: false,
                        type: getPost,
                        data: { programId: programid },
                        url: urlAction,
                        success: function(data) {
                            $("#associatedPromptsListDiv").html("");
                            $("#associatedPromptsListDiv").append(data);
                        },
                        error: function(data) {
                            console.log(
                                "Error: getAssociatedPromptsList---------------: ",
                                data
                            );
                            $("#associatedPromptsListDiv").html("");
                            $("#associatedPromptsListDiv").append(
                                `<ul id="promptsListWrapper" style="padding-left: 30px;">
                                    <li class="noPersona">No prompts associated with the current channel</li>
                                </ul>`
                            );
                        }
                    };
                    $.ajax(urlAction, options);
                });

                var remove_references =
                    "#remove_brandName, #remove_contentBodyRef, #remove_contentTitle, #remove_brandVoice, #remove_contentSEODesc, #remove_contentPublishedUrl, #remove_personaAudience, #remove_personaName, #remove_contentKeyword, #remove_audienceName, #remove_audienceDesc, #remove_purpose, #remove_selfRefTitle, #remove_selfRefKeyword, #remove_selfRefSummary, #remove_brandDomain";

                body.on("click", remove_references, function(e) {
                    let id = $(this).attr("id");
                    removeReferenceBlock(id.split("_")[1]);
                });
                body.on("click", "#closeSavePrompt", function(e) {
                    $(this).colorbox.close();
                });

                body.on("click", ".audienceItem", function() {
                    let id = $(this).data("id");
                    let name = $(this).data("name");
                    let description = $(this).data("description");

                    let html = `<h2>${name}</h2>
                               <p>
                                   ${description}
                               </p>`;
                    $("#backButton").show();
                    $("#personalistDiv").hide();
                    $("#persona-details-wrapper").html("");
                    $("#persona-details-wrapper").html(html);
                });

                body.on("click", "#savePersonasAudience", function() {
                    let personasId = $("#personasId").val();
                    let personasAudience = $("#personasAudience").val();
                    var urlAction = "/savePersonaAudienceParameterAjax.action",
                        options = {
                            data: {
                                personaId: personasId,
                                audiencePersona: personasAudience
                            },
                            cache: false,
                            type: "post",
                            url: urlAction,
                            success: function(jsonResponse) {
                                $("#success-box").html(
                                    `<div class="content">Persona Audience updated successfully</div>`
                                );
                                $("#success-box").css("display", "block");
                            },
                            complete: function(req, response, error) {}
                        };
                    call.ajax(urlAction, options);
                });

                body.on("click", "#backButton", function() {
                    $("#backButton").hide();
                    $("#personalistDiv").show();
                    $("#persona-details-wrapper").html("");
                });

                body.on("click", "#overwrite", function() {
                    if (!$("#overwrite").is(":checked")) {
                        $("#overwritePrompt option:selected").attr(
                            "selected",
                            null
                        );
                        $(`#overwritePrompt option[value=""]`).attr(
                            "selected",
                            "selected"
                        );
                        $("#prevPromptValue").html("");
                        $("#selectedPromptName").html("");
                        $("#prevPromptWrapper").css("display", "none");
                    }
                });
                body.on("change", "#overwritePrompt", function(e) {
                    if (e.target.value !== "") {
                        $("#overwrite").prop("checked", true);
                        let name = $("#overwritePrompt :selected").text();
                        var desc =
                            e.target.options[e.target.selectedIndex].dataset
                                .desc;
                        var prompt =
                            e.target.options[e.target.selectedIndex].dataset
                                .prompt;
                        $("#promptName").val(name);
                        $("#prevPromptValue").text(prompt);
                        $("#selectedPromptName").html(name);
                        $("#promptDescription").val(desc);
                        $("#prevPromptWrapper").css("display", "block");
                    } else {
                        $("#overwrite").prop("checked", false);
                        $("#prevPromptValue").html("");
                        $("#selectedPromptName").html("");
                        $("#prevPromptWrapper").css("display", "none");
                    }
                });
                body.on("change", "#validationPromptsList", function(e) {
                    $("#validationPrompt").val(
                        e.target.options[e.target.selectedIndex].dataset.prompt
                    );
                    if (e.target.value !== "") {
                        $("#validatePromptBtn").removeAttr("disabled");
                    } else {
                        $("#validatePromptBtn").attr("disabled", "disabled");
                    }
                });
                body.on("click", "#validatePromptBtn", function() {
                    $("#validatePromptBtn").attr("disabled", "disabled");
                    $("#validateContentLoader").css("display", "flex");
                    $("#validationResult").css("display", "none");
                    const validationPrompt = $("#validationPrompt").val();
                    const originalPrompt = validationPrompt.includes("<prompt>")
                        ? $("#prompt").val()
                        : null;
                    let data = {
                        csrfToken: $("#csrfToken").val(),
                        outputBody: $("#generatedContent").val(),
                        validationPrompt: validationPrompt,
                        originalPrompt: originalPrompt,
                        articleBody:
                            $("#articleBody").length > 0
                                ? $("#articleBody").val()
                                : null,
                        brandName:
                            $("#brandName").length > 0
                                ? $("#brandName").val()
                                : null,
                        brandVoice:
                            $("#brandVoice").length > 0
                                ? $("#brandVoice").val()
                                : null,
                        contentTitle:
                            $("#contentTitle").length > 0
                                ? $("#contentTitle").val()
                                : null,
                        contentSEODesc:
                            $("#contentSEODesc").length > 0
                                ? $("#contentSEODesc").val()
                                : null,
                        personaAudience:
                            $("#personaAudience").length > 0
                                ? $("#personaAudience").val()
                                : null,
                        personaName:
                            $("#personaName").length > 0
                                ? $("#personaName").val()
                                : null,
                        contentUrl:
                            $("#contentUrl").length > 0
                                ? $("#contentUrl").val()
                                : null,
                        contentKeyword:
                            $("#contentKeyword").length > 0
                                ? $("#contentKeyword").val()
                                : null,
                        audienceName:
                            $("#audienceName").length > 0
                                ? $("#audienceName").val()
                                : null,
                        audienceDesc:
                            $("#audienceDesc").length > 0
                                ? $("#audienceDesc").val()
                                : null,
                        purpose:
                            $("#purpose").length > 0
                                ? $("#purpose").val()
                                : null,
                        selfRefTitle:
                            $("#selfRefTitle").length > 0
                                ? $("#selfRefTitle").val()
                                : null,
                        selfRefKeyword:
                            $("#selfRefKeyword").length > 0
                                ? $("#selfRefKeyword").val()
                                : null,
                        selfRefSummary:
                            $("#selfRefSummary").length > 0
                                ? $("#selfRefSummary").val()
                                : null,
                        brandDomain:
                            $("#brandDomain").length > 0
                                ? $("#brandDomain").val()
                                : null
                    };
                    var urlAction = "/promptTestValidateAjax.action",
                        getPost = "POST",
                        options;
                    options = {
                        cache: false,
                        type: getPost,
                        data: data,
                        url: urlAction,
                        success: function(data) {
                            $("#validationResult").html(data);
                            $("#validateContentLoader").css("display", "none");
                            $("#validationResult").css("display", "flex");
                            $("#validatePromptBtn").removeAttr("disabled");
                        },
                        error: function(data) {
                            console.log("Error::::::::", data);
                            if (data.status === 504) {
                                $("#validateContentLoader").css(
                                    "display",
                                    "none"
                                );
                                $("#validationResult")
                                    .html(`<div style="margin-top:10px; background: #fee3e5;
                                            border: none;
                                            color: #bd1200;">
                                            <p style="font-size: 16px; color: #bd1200; padding:10px; margin:0;">${$translate.instant(
                                                "sw.error.timeout"
                                            )}</p>
                                            </div>`);
                                $("#validationResult").css("display", "flex");
                                $("#validatePromptBtn").removeAttr("disabled");
                            }
                        }
                    };
                    $.ajax(urlAction, options);
                });
                body.on("click", "#savePromptForm", function() {
                    if (savePromptValid()) {
                        let name = $("#promptName")
                            .val()
                            .trim();
                        let prompt = $("#promptValue")
                            .val()
                            .trim();
                        let promptDesc = $("#promptDescription")
                            .val()
                            .trim();
                        let overwrite = $("#overwrite").is(":checked")
                            ? true
                            : false;
                        let overwritePrompt = $("#overwritePrompt").val();
                        let promptType = $("#promptType").val();
                        var dataPost = {
                            name: name,
                            promptValue: prompt,
                            description: promptDesc,
                            overwritePrompt: overwrite ? overwritePrompt : null,
                            overwrite: overwrite,
                            promptType: promptType
                        };
                        var urlAction = "/savePromptOverwriteAjax.action",
                            getPost = "POST",
                            options;
                        options = {
                            cache: false,
                            type: getPost,
                            data: dataPost,
                            url: urlAction,
                            success: function(data) {
                                var selectedOption = 0;
                                enteredPrompts = [];
                                redoPrompts = [];
                                $("#selectPromptOptionsWrapper").html(data);
                                $("#promptDesc").text(promptDesc);
                                $("#prompt").val(prompt);
                                $("#selectedPrompt option:selected").attr(
                                    "selected",
                                    null
                                );
                                if (overwrite) {
                                    $(
                                        `#selectedPrompt option[value="${overwritePrompt}"]`
                                    ).attr("selected", "selected");
                                    selectedOption = overwritePrompt;
                                } else {
                                    $("#selectedPrompt option").each(
                                        function() {
                                            let option = $(this);
                                            if (
                                                option[0].dataset.desc ===
                                                    promptDesc &&
                                                option[0].dataset.prompt ===
                                                    $("#promptValue").val() &&
                                                option[0].text === name
                                            ) {
                                                selectedOption =
                                                    option[0].value;
                                            }
                                        }
                                    );
                                    $(
                                        `#selectedPrompt option[value="${selectedOption}"]`
                                    ).attr("selected", "selected");
                                }
                                var dateUpdated = $(
                                    `#selectedPrompt option[value="${selectedOption}"]`
                                ).data("dateupdated");

                                let time = new Date(
                                    dateUpdated
                                ).toLocaleTimeString("en-US", {
                                    timeZone: "America/New_York",
                                    hour12: false
                                });
                                let date = moment(
                                    new Date(dateUpdated),
                                    "MM-DD-YYYY"
                                ).format("DD MMM YYYY");

                                dateUpdated =
                                    "Updated On: " + date + " at " + time;
                                $("#dateUpdated").text(dateUpdated);
                                $("#selectPrompt").text(
                                    $("#selectedPrompt :selected").text()
                                );
                                $("#selectPromptOptions li").removeClass(
                                    "active"
                                );
                                $(
                                    `#selectPromptOptions li[data-id="${selectedOption}"]`
                                ).addClass("active");
                                checkUndoRedoDisabled();
                                $(this).colorbox.close();
                            }
                        };
                        call.ajax(urlAction, options);
                    }
                });

                body.on("click", "#savePromptReferenceParameter", function() {
                    $("#success-box").css("display", "none");
                    $("#error-box").css("display", "none");
                    let brand_name = $("#brand_name")
                        .val()
                        .trim();
                    let brand_voice = $("#brand_voice")
                        .val()
                        .trim();
                    let system_message = $("#system_message")
                        .val()
                        .trim();
                    let programId = $("#programId")
                        .val()
                        .trim();

                    var dataPost = {
                        brand_name: brand_name,
                        brand_voice: brand_voice,
                        systemMessage: system_message,
                        programId: programId
                    };
                    var urlAction = "/savePromptReferenceParameterAjax.action",
                        getPost = "POST",
                        options;
                    options = {
                        cache: false,
                        type: getPost,
                        data: dataPost,
                        url: urlAction,
                        success: function(data) {
                            $("#success-box").html(
                                `<div class="content">Channel data updated successfully</div>`
                            );
                            $("#success-box").css("display", "block");
                        }
                    };
                    call.ajax(urlAction, options);
                });

                body.on("click", "#openHistoryChannelBtn", function() {
                    // if ($("#historyChannelLookupWrapper").hasClass("closed")) {
                    $("#historyChannelLookupWrapper").removeClass("closed");
                    $("#historyChannelLookupWrapper").addClass("open");
                    setTimeout(() => {
                        $("#openHistoryChannelBtn").css("display", "none");
                        $("#closeHistoryChannelBtn").css("display", "block");
                        $("#historyChannelTab").css("display", "flex");
                        $("#historyChannelTab").css("width", "100%");
                        $("#historyChannelTab").css("visibility", "visible");
                        if ($("#historyListBtn").hasClass("active")) {
                            $("#history-list").trigger("heightChange");
                            $("#channel-lookup").css("display", "none");
                            $("#channel-lookup").css("width", 0);
                            $("#channel-lookup").css("visibility", "collapse");

                            $("#history-list").css("display", "block");
                            $("#history-list").css("width", "100%");
                            $("#history-list").css("visibility", "visible");
                        } else {
                            $("#history-list").css("display", "none");
                            $("#history-list").css("width", 0);
                            $("#history-list").css("visibility", "collapse");

                            $("#channel-lookup").css("display", "block");
                            $("#channel-lookup").css("width", "100%");
                            $("#channel-lookup").css("visibility", "visible");
                        }
                    }, 500);
                });

                body.on("click", "#closeHistoryChannelBtn", function() {
                    $("#historyChannelTab").css("display", "none");
                    $("#historyChannelTab").css("width", 0);
                    $("#historyChannelTab").css("visibility", "collapse");
                    $("#channel-lookup").css("display", "none");
                    $("#channel-lookup").css("width", 0);
                    $("#channel-lookup").css("visibility", "collapse");
                    $("#history-list").css("display", "none");
                    $("#history-list").css("width", 0);
                    $("#history-list").css("visibility", "collapse");
                    setTimeout(() => {
                        $("#openHistoryChannelBtn").css("display", "block");
                        $("#closeHistoryChannelBtn").css("display", "none");
                    }, 500);
                    $("#historyChannelLookupWrapper").removeClass("open");
                    $("#historyChannelLookupWrapper").addClass("closed");
                });

                body.on("click", "#historyListBtn", function() {
                    if ($(this).hasClass("active")) return;
                    $(this).addClass("active");
                    $("#channelLookupBtn").removeClass("active");
                    $("#channel-lookup").css("display", "none");
                    $("#channel-lookup").css("width", 0);
                    $("#channel-lookup").css("visibility", "collapse");

                    $("#history-list").css("display", "block");
                    $("#history-list").css("width", "100%");
                    $("#history-list").css("visibility", "visible");
                });

                body.on("click", "#channelLookupBtn", function() {
                    if ($(this).hasClass("active")) return;
                    $(this).addClass("active");
                    $("#historyListBtn").removeClass("active");

                    $("#history-list").css("display", "none");
                    $("#history-list").css("width", 0);
                    $("#history-list").css("visibility", "collapse");

                    $("#channel-lookup").css("display", "block");
                    $("#channel-lookup").css("width", "100%");
                    $("#channel-lookup").css("visibility", "visible");
                });

                body.on("click", "#historyWrapper li", function() {
                    let promptHistoryId = $(this).attr("id");
                    if (
                        parseInt($("#promptHistoryInUse").val()) !==
                        parseInt(promptHistoryId)
                    ) {
                        let loader = `<div class="loader flex-column align-items-center" id="genContentLoader" style="margin-top:20px; display:flex;">
                                    <img src="/styles/images/ajax-loader32x32.gif" style="height:32px; width:32px;" />
                                    <p>Loading content from history.... Please wait</p>
                                </div>`;
                        $.colorbox({
                            html: loader,
                            width: "30%",
                            height: "auto",
                            maxHeight: "auto",
                            escKey: false,
                            overlayClose: false
                        });
                        $("#cboxClose").css("display", "none");
                        setTimeout(() => {
                            $("#historyWrapper li").removeClass("active");
                            $(this).addClass("active");

                            var urlAction = "/retrievePromptHistoryAjax.action",
                                getPost = "GET",
                                options;
                            options = {
                                cache: false,
                                type: getPost,
                                data: {
                                    promptHistoryId: promptHistoryId
                                },
                                url: urlAction,
                                success: function(data) {
                                    let promptHistory = JSON.parse(data);
                                    let {
                                        promptDesignHistory,
                                        promptDesignParametersList,
                                        wordCount
                                    } = promptHistory;

                                    //populate prompt details
                                    $("#systemMessage").val(
                                        promptDesignHistory.systemMessage
                                    );
                                    $("#selectedPrompt").val(
                                        promptDesignHistory?.promptLibraryId
                                            ? promptDesignHistory?.promptLibraryId
                                            : ""
                                    );
                                    $("#selectPrompt").text(
                                        $("#selectedPrompt :selected").text()
                                    );
                                    $("#selectPromptOptions li").removeClass(
                                        "active"
                                    );
                                    $(
                                        `#selectPromptOptions li[data-id="${
                                            promptDesignHistory?.promptLibraryId
                                                ? promptDesignHistory?.promptLibraryId
                                                : ""
                                        }"]`
                                    ).addClass("active");
                                    $("#selectedPrompt").trigger("change");
                                    $("#prompt").val(
                                        promptDesignHistory.prompt
                                    );
                                    $("#selectedModel").val(
                                        promptDesignHistory.model
                                    );
                                    $("#temperature").val(
                                        promptDesignHistory.temperature
                                    );
                                    $("#topP").val(promptDesignHistory.topP);
                                    $("#frequencyPenalty").val(
                                        promptDesignHistory.frequencyPenalty
                                    );
                                    $("#presencePenalty").val(
                                        promptDesignHistory.presencePenalty
                                    );

                                    $("#promptHistoryInUse").val(
                                        promptHistoryId
                                    );
                                    $("#wordCount").text(wordCount);
                                    $("#generatedContent").val(
                                        promptDesignHistory.promptOutput
                                    );
                                    $("#generatedContentWrapper").css(
                                        "display",
                                        "block"
                                    );

                                    //populate references

                                    $(
                                        "#remove_brandName, #remove_contentTitle, #remove_brandVoice, #remove_contentSEODesc, #remove_contentUrl, #remove_personaAudience, #remove_personaName, #remove_contentKeyword, #remove_audienceName, #remove_audienceDesc, #remove_purpose, #remove_selfRefTitle, #remove_selfRefKeyword, #remove_selfRefSummary, #remove_brandDomain"
                                    ).trigger("click");

                                    $("#articleBody").val("");
                                    for (
                                        let i = 0;
                                        i < promptDesignParametersList.length;
                                        i++
                                    ) {
                                        let attributeName =
                                            promptDesignParametersList[i]
                                                .attributeName;
                                        let attributeValue =
                                            promptDesignParametersList[i]
                                                .valueOfAttribute;
                                        attributeName = attributeName
                                            .replaceAll("<", "")
                                            .replaceAll(">", "");
                                        if (
                                            $("#" + attributeName + "Ref")
                                                .length === 0
                                        ) {
                                            //generate HTML
                                            $(
                                                "#" + attributeName + "_Link"
                                            ).trigger("click");
                                        }
                                        //replace Value
                                        $("#" + attributeName).val(
                                            attributeValue.trim()
                                        );
                                    }

                                    $(this).colorbox.close();
                                },
                                error: function(data) {
                                    console.log(
                                        "Error: retrievePromptHistoryAjax---------------: ",
                                        data
                                    );
                                }
                            };
                            $.ajax(urlAction, options);
                        }, 2000);
                    }
                });

                $("#generateText").on("click", function() {
                    $("#error-box").css("display", "none");
                    $("#success-box").css("display", "none");
                    $("#warning-box").css("display", "none");
                    if (validForm()) {
                        let tokenUsed = replaceRefsWithValueTokenCounting();
                        $("#tokenUsed").text(tokenUsed);
                        $("#generatedContentWrapper").css("display", "none");
                        $(".reference_block").css("min-height", "auto");
                        $("#genContentLoader").css("display", "flex");
                        $("html, body").animate(
                            {
                                scrollTop: $("#genContentLoader").offset().top
                            },
                            2000
                        );
                        enteredPrompts = [];
                        redoPrompts = [];
                        checkUndoRedoDisabled();
                        var form = $("#formPromptTest"),
                            urlAction = "/promptTestGenerateAjax.action",
                            getPost = form.attr("method"),
                            options;
                        options = {
                            cache: false,
                            type: getPost,
                            data: form.serialize(),
                            url: urlAction,
                            success: function(data) {
                                $("#generatedContentWrapper").html(data);

                                $("#genContentLoader").css("display", "none");
                                $("#generatedContentWrapper").css(
                                    "display",
                                    "block"
                                );

                                refreshHistoryListOnGenerateText();
                            },
                            error: function(data) {
                                if (data.status === 504) {
                                    $("#generatedContentWrapper")
                                        .html(`<div style="margin-top:10px; background: #fee3e5;
                                                border: none;
                                                color: #bd1200;">
                                                <p style="font-size: 16px; color: #bd1200; padding:10px; margin:0;">${$translate.instant(
                                                    "sw.error.timeout"
                                                )}</p>
                                                </div>`);
                                }

                                $("#genContentLoader").css("display", "none");
                                $("#generatedContentWrapper").css(
                                    "display",
                                    "block"
                                );
                                $("#genContentLoader").css("display", "none");
                            }
                        };
                        $.ajax(urlAction, options);
                    } else {
                        window.scrollTo(0, 0);
                    }
                });

                $("#savePrompt").on("click", function() {
                    let html = `<div class='m-3' style="width:95%;">
                        <h1>Save Prompt</h1>
                        <div class="col-md-12 row" style="max-width:98%;">
                            <div class="col-md-6 mb-3">
                                <label class="d-block my-2">Name <span class="required">*</span></label>
                                <input type="text" name="promptName" id="promptName" required style="width:97%" />
                                <span class="text-danger hidden" id="nameErr" style="font-size:14px;">Name is required</span>
                            </div>
                            <div class="col-md-5 mb-3">
                                <label class="d-block my-2">Prompt Type <span class="required">*</span></label>
                                <select id="promptType" name="promptType" class="w-100" style="height:31px;">
                                    <option value="1">Whole</option>
                                    <option value="2">Sequence</option>
                                    <option value="3">Validation</option>
                                </select>
                                <span class="text-danger hidden" id="promptTypeErr" style="font-size:14px;">Prompt type is required</span>
                            </div>
                            <div class="col-md-12 mb-3">
                                <label class="d-block my-2">Description</label>
                                <textarea name="promptDescription" id="promptDescription" style="width:100%; height:120px;">${$(
                                    "#promptDesc"
                                ).html()}</textarea>
                            </div>
                            <div class="col-md-12 mb-3">
                                <label class="d-block my-2">Prompt <span class="required">*</span></label>
                                <textarea name="promptValue" id="promptValue" required style="width:100; height:150px;">${$(
                                    "#prompt"
                                ).val()}</textarea>
                                <span class="text-danger hidden" id="promptErr" style="font-size:14px;">Prompt is required</span>
                                </div>
                            <div class="col-md-12 mb-3 hidden" id="prevPromptWrapper" style="margin-top:20px;">
                                <label class="d-block my-2" style="color: #646464;">Currently Saved Prompt for <span id="selectedPromptName"></span></label>
                                <p name="prevPromptValue" id="prevPromptValue" disabled="disabled" style="width:98%; line-height: 1.5;
                                font-size: 14px;
                                margin-bottom: 0;
                                padding: 5px 10px;
                                border: 1px solid #d1d1d1; border-radius:4px;
                                max-height:120px; overflow-y:auto; background:#f5f5f5;"></p>
                            </div>
                            <div class="col-md-12 d-flex justify-content-between align-items-center" id="overwriteWrapper" style="gap:12px;">
                                <div class="d-flex align-items-center">
                                    <label class="mt-0 mr-2">Overwrite:</label>
                                    <input type="checkbox" name="overwrite" id="overwrite" style="display:inline-block;" />
                                </div>
                                    <select id="overwritePrompt" name="overwritePrompt" class="w-100">
                                        <option value="">Select Prompt</option>
                                    </select>
                            </div>
                            <div class="col-md-12 hidden" id="overwritePromptErr">
                                <span class="text-danger" style="font-size:14px;">Select a prompt to overwrite or uncheck the overwrite option</span>
                            </div>
                            <div class="col-md-12 d-flex align-items-center mt-5">
                                <button class="btn btn-primary btn-sm" id="savePromptForm" type="button">Save Prompt</button>
                                <a id="closeSavePrompt" class="text-primary">Cancel</a>
                            </div>
                        </div>
                    </div>`;
                    $.colorbox({
                        html: html,
                        width: "50%",
                        height: "100%",
                        maxHeight: "76%",
                        escKey: false,
                        overlayClose: false
                    });

                    $("#overwrite").on("change", function() {
                        if ($(this).is(":checked")) {
                            $(
                                `#overwritePrompt option[value=${$(
                                    "#selectedPrompt"
                                ).val()}]`
                            ).attr("selected", "selected");
                        } else {
                            $("#overwritePrompt").val(""); // Reset the value
                        }
                    });

                    $("#overwritePrompt").html($("#selectedPrompt").html());

                    if ($("#selectedPrompt").val() !== "") {
                        $("#promptName").val(
                            $("#selectedPrompt :selected").text()
                        );
                        $("#overwrite").prop("checked", false);
                        $("#overwritePrompt option:selected").attr(
                            "selected",
                            null
                        );
                        $("#prevPromptValue").text(
                            $("#selectedPrompt :selected").attr("data-prompt")
                        );
                        $("#selectedPromptName").html(
                            $("#selectedPrompt :selected").text()
                        );
                        $("#prevPromptWrapper").css("display", "block");
                        if (
                            $("#selectedPrompt :selected").attr(
                                "data-prompttypeid"
                            ) !== "" &&
                            $("#selectedPrompt :selected").attr(
                                "data-prompttypeid"
                            ) !== null
                        ) {
                            $("#promptType").val(
                                $("#selectedPrompt :selected").attr(
                                    "data-prompttypeid"
                                )
                            );
                        } else {
                            $("#promptType").val(1);
                        }
                    } else {
                        $("#prevPromptValue").html("");
                        $("#selectedPromptName").html("");
                        $("#overwrite").prop("checked", false);
                        $("#overwritePrompt option:selected").attr(
                            "selected",
                            null
                        );
                        $('#overwritePrompt option[value=""]').attr(
                            "selected",
                            "selected"
                        );
                        $("#prevPromptWrapper").css("display", "none");
                        $("#promptType").val(1);
                    }
                });

                function showSetOptionModal() {
                    let html = `<div class='m-3' style="max-width:95%;">
                        <h1>Action Required</h1>
                        <div class="col-md-12 row" style="max-width:95%;">
                            <p style="margin:0; padding:0;">Do you want to update the set or save a new set?</p>
                            <div class="col-md-12 d-flex align-items-center mt-4 pl-0">
                                <button class="btn btn-primary btn-sm" id="updateCannedSet" type="button">Update Set</button>
                                <button class="btn btn-primary btn-sm" id="saveAsNewSet" type="button">Save As New Set</button>
                                <a id="closeInfoSet" class="text-primary">Cancel</a>
                            </div>
                        </div>
                    </div>`;
                    return html;
                }

                let updateOptionClicked = "";

                function createSaveSetModal() {
                    let html = `<div class='m-3' style="width:95%;">
                        <h1>${
                            updateOptionClicked === "updateCannedSet"
                                ? "Update"
                                : "Save"
                        } Set</h1>
                        <div class="col-md-12 row" style="max-width:98%;">
                            <div class="col-md-12 mb-3">
                                <label class="d-block my-2">Name <span class="required">*</span></label>
                                <input type="text" name="name" id="cannedSetName" data-1p-ignore required style="width:97%" />
                                <span class="text-danger hidden" style="display: none;" id="cannedSetNameErr" style="font-size:14px;">Name is required</span>
                            </div>
                            <div class="col-md-12 mb-3">
                                <label class="d-block my-2">Description</label>
                                <textarea name="description" id="cannedSetDescription" style="width:100%; height:120px;"></textarea>
                            </div>
                            <div class="col-md-12 d-flex align-items-center mt-5">
                                <button class="btn btn-primary btn-sm" id="saveCannedSetForm" type="button">${
                                    updateOptionClicked === "updateCannedSet"
                                        ? "Update"
                                        : "Save"
                                } Set</button>
                                <a id="closeSaveSet" class="text-primary">Cancel</a>
                            </div>
                        </div>
                    </div>`;
                    return html;
                }

                $("#saveCannedSetBtn").on("click", function() {
                    let cannedSetId = $("#cannedParameterSet").val();
                    let html;
                    if (cannedSetId === "") {
                        updateOptionClicked = "saveAsNewSet";
                        html = createSaveSetModal();
                        $.colorbox({
                            html: html,
                            width: "40%",
                            height: "fit-content",
                            escKey: false,
                            overlayClose: false
                        });
                    } else {
                        html = showSetOptionModal();
                        $.colorbox({
                            html: html,
                            width: "30%",
                            height: "fit-content",
                            escKey: false,
                            overlayClose: false,
                            fadeOut: 200,
                            onClosed: function(e) {
                                let saveHtml = createSaveSetModal();
                                switch (updateOptionClicked) {
                                    case "updateCannedSet":
                                        let cannedSetName = $(
                                            `#cannedParameterSet option:selected`
                                        ).text();
                                        let cannedSetDesc = $(
                                            "#cannedParameterSet option:selected"
                                        ).attr("data-desc");
                                        $.colorbox({
                                            html: saveHtml,
                                            width: "40%",
                                            height: "fit-content",
                                            escKey: false,
                                            overlayClose: false,
                                            fastIframe: false,
                                            speed: 500
                                        });
                                        $("#cannedSetName").val(cannedSetName);
                                        $("#cannedSetDescription").val(
                                            cannedSetDesc
                                        );
                                        break;
                                    case "saveAsNewSet":
                                        $.colorbox({
                                            html: saveHtml,
                                            width: "40%",
                                            height: "fit-content",
                                            escKey: false,
                                            overlayClose: false,
                                            fastIframe: false,
                                            speed: 500
                                        });
                                        break;
                                    case "closeInfoSet":
                                    default:
                                        break;
                                }
                            }
                        });
                    }

                    $("#cboxClose").css("display", "none");
                    if (cannedSetId !== "") {
                    }
                });

                body.on("click", "#closeSaveSet, #closeInfoSet", function(e) {
                    updateOptionClicked = "closeInfoSet";
                    $(this).colorbox.close();
                });

                body.on("click", "#updateCannedSet", function() {
                    updateOptionClicked = "updateCannedSet";
                    $(this).colorbox.close();
                });

                body.on("click", "#saveAsNewSet", function() {
                    updateOptionClicked = "saveAsNewSet";
                    $(this).colorbox.close();
                });

                body.on("click", "#saveCannedSetForm", function() {
                    let cannedSetId = $("#cannedParameterSet").val();
                    let name = $("#cannedSetName").val();
                    let description = $("#cannedSetDescription").val();
                    if (name === "") {
                        $("#cannedSetNameErr").css("display", "block");
                        return;
                    }

                    $("#cannedSetNameErr").css("display", "none");

                    // get all reference list
                    let refList = $("#referencesList").children();
                    let cannedParameters = new Map();
                    for (let i = 0; i < refList.length; i++) {
                        let refId = $(refList[i])
                            .attr("id")
                            .substring(0, $(refList[i]).attr("id").length - 3);
                        cannedParameters.set(
                            `${refId}`.toString(),
                            $(`#${refId}`).val()
                        );
                    }
                    let apiURL = "",
                        type = "";
                    if (
                        cannedSetId !== "" &&
                        updateOptionClicked === "updateCannedSet"
                    ) {
                        apiURL = `cannedParameter/${cannedSetId}`;
                        type = "PUT";
                    } else {
                        apiURL = `cannedParameter/create`;
                        type = "POST";
                    }
                    let data = JSON.stringify({
                        name: name,
                        description: description,
                        cannedParameters: JSON.stringify(
                            Object.fromEntries(cannedParameters)
                        )
                    });
                    const token = skyword.getToken("access_token");

                    $.ajax({
                        cache: false,
                        type: type,
                        contentType: "application/json",
                        url: skyword.getAPIURL(apiURL),
                        data: data,
                        beforeSend: function(xhr) {
                            xhr.setRequestHeader(
                                "Authorization",
                                `Bearer ${token}`
                            );
                        },
                        success: function(data) {
                            $.ajax({
                                cache: false,
                                type: "GET",
                                url: skyword.getAPIURL(
                                    "cannedParameter/getAllPresets"
                                ),
                                beforeSend: function(xhr) {
                                    xhr.setRequestHeader(
                                        "Authorization",
                                        `Bearer ${token}`
                                    );
                                },
                                success: function(response) {
                                    let selectedId = data.id;
                                    let options = ``;
                                    var select = $("#cannedParameterSet");
                                    select
                                        .find("option")
                                        .not(":first")
                                        .remove();
                                    if (response.length > 0) {
                                        for (
                                            let i = 0;
                                            i < response.length;
                                            i++
                                        ) {
                                            if (
                                                parseInt(response[i].id) ===
                                                parseInt(selectedId)
                                            ) {
                                                select.append(
                                                    $("<option></option>")
                                                        .attr(
                                                            "value",
                                                            response[i].id
                                                        )
                                                        .attr(
                                                            "selected",
                                                            "selected"
                                                        )
                                                        .attr(
                                                            "data-desc",
                                                            response[i]
                                                                .description
                                                        )
                                                        .text(response[i].name)
                                                );
                                            } else {
                                                select.append(
                                                    $("<option></option>")
                                                        .attr(
                                                            "value",
                                                            response[i].id
                                                        )
                                                        .attr(
                                                            "data-desc",
                                                            response[i]
                                                                .description
                                                        )
                                                        .text(response[i].name)
                                                );
                                            }
                                        }
                                    }
                                    $("#cannedParameterSet").trigger("change");
                                    $(this).colorbox.close();
                                }
                            });
                        }
                    });
                });
            });
        }
    ])
    .controller("checkAIGeneratedAjax", ["$scope", function($scope) {}]);

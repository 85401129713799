app.controller("unsubscribe", ["$scope", function($scope) {}])
    .controller("contentChecklist", [
        "$scope",
        "help",
        function($scope, help) {
            help.init();
        }
    ])
    .controller("contentChecklistEdit", ["$scope", function($scope) {}])
    .controller("contentChecklistDeleteAjax", ["$scope", function($scope) {}])
    .controller("checklistMoveAjax", ["$scope", function($scope) {}])
    .controller("editProgram", [
        "$scope",
        "crop",
        function($scope, crop) {
            crop.init();
            $("#description").maxlength({
                feedback: ".description-charsLeft"
            });
            $("#longDescription").maxlength({
                feedback: ".longDescription-charsLeft"
            });
        }
    ])
    .controller("editArticleRequirements", [
        "$scope",
        "programSettings",
        function($scope, programSettings) {
            programSettings.init();
        }
    ])
    .controller("editSeoRequirements", [
        "$scope",
        "programSettings",
        function($scope, programSettings) {
            programSettings.init();
        }
    ])
    .controller("editProgramCategory", ["$scope", function($scope) {}])
    .controller("programCategories", [
        "$scope",
        "programSettings",
        function($scope, programSettings) {
            programSettings.init();
        }
    ])
    .controller("viewTemplate", ["$scope", function($scope) {}])
    .controller("approveArticleTemplate", ["$scope", function($scope) {}])
    .controller("editElement", [
        "$scope",
        "programSettings",
        function($scope, programSettings) {
            programSettings.init();
        }
    ])
    .controller("taxonomySyncAjax", ["$scope", function($scope) {}])
    .controller("testPublishSettings", [
        "$scope",
        "apiTest",
        function($scope, apiTest) {
            apiTest.init();
        }
    ])
    .controller("apiPublishTestAjax", ["$scope", function($scope) {}])
    .controller("apiConnectionTestAjax", ["$scope", function($scope) {}])
    .controller("apiUpdateTestAjax", ["$scope", function($scope) {}])
    .controller("apiDeleteTestAjax", ["$scope", function($scope) {}])
    .controller("users", [
        "$scope",
        "user",
        function($scope, user) {
            user.init();
        }
    ])
    .controller("addUser", [
        "$scope",
        "user",
        function($scope, user) {
            user.init();
        }
    ])
    .controller("addUserAjax", ["$scope", function($scope) {}])
    .controller("editUserAjax", ["$scope", function($scope) {}])
    .controller("removeUserAjax", ["$scope", function($scope) {}])
    .controller("externalReviewers", [
        "$scope",
        "user",
        function($scope, user) {
            user.init();
        }
    ])
    .controller("addExternalReviewerAjax", ["$scope", function($scope) {}])
    .controller("addExternalReviewer", [
        "$scope",
        "user",
        function($scope, user) {
            user.init();
        }
    ])
    .controller("removeExternalReviewerAjax", ["$scope", function($scope) {}])
    .controller("channelDictionary", ["$scope", function($scope) {}])

    .controller("writerCompensation", ["$scope", function($scope) {}])
    .controller("editWriterCompensation", [
        "$scope",
        "programSettings",
        function($scope, programSettings) {
            programSettings.init();
        }
    ])
    .controller("articleDelivery", [
        "$scope",
        "programSettings",
        function($scope, programSettings) {
            programSettings.init();

            //onReady
            $(function() {
                $.ajaxSetup({ cache: true });
                $.getScript(
                    "//connect.facebook.net/" +
                        $("#userLocale").val() +
                        "/sdk.js",
                    function() {
                        FB.init({
                            appId: $("#fbAppId").val(),
                            version: "v3.1",
                            status: true,
                            cookie: true,
                            xfbml: true
                        });
                    }
                );
                $(".fbAuth").click(function() {
                    FB.login(
                        function(response) {
                            if (response != undefined) {
                                if (response.error != null) {
                                    if (response.error == "access_denied")
                                        console.log(
                                            "Access Denied - Reason:" +
                                                response.error_reason
                                        );
                                } else {
                                    window.location =
                                        $("#fbCallbackURL").val() +
                                        "&code=" +
                                        response.code;
                                }
                            }
                        },
                        {
                            scope: $("#fbOAuthPermissions").val()
                        }
                    );
                });
            });
        }
    ])
    .controller("changeDeliveryMethod", ["$scope", function($scope) {}])
    .controller("editReviewWorkflow", [
        "$scope",
        "programSettings",
        function($scope, programSettings) {
            programSettings.init();
        }
    ])
    .controller("updateKeywordPopulation", ["$scope", function($scope) {}])
    .controller("programHelpArticles", [
        "$scope",
        "help",
        "$translate",
        "call",
        function($scope, help, $translate, call) {
            help.init();
            $("body").on("click", "#exportGuidelinesBtn", function() {
                help.exportToWord()
                    .success(function() {
                        window.location.href =
                            "/API/export/program/" +
                            $("#page-home").attr("data-programid") +
                            "/guidelines";
                    })
                    .error(function(error) {
                        console.log(
                            "Error while downloading the guidelines for this program"
                        );
                    });
            });
            $("body").on("click", "#resetAcceptanceBtn", function() {
                let header = $translate.instant(
                    "angular.resetacceptance.header"
                );
                let msg = $translate.instant("angular.resetacceptance.message");
                let resetBtn = $translate.instant(
                    "angular.button.resettodefault"
                );
                let cancelBtn = $translate.instant("angular.button.cancel");
                let html = `<div class='m-3' style="width:95%;">
                                <h1>${header}</h1>
                                <p style="font-size:1.6rem;">${msg}</p>
                                <div class="d-flex align-items-center mt-3">
                                    <button class="btn btn-primary btn-sm" id="resetUserAcceptanceBtn" type="button">${resetBtn}</button>
                                    <a id="closeResetAcceptanceBtn" class="text-primary">${cancelBtn}</a>
                                </div>
                            </div>`;
                $.colorbox({
                    html: html,
                    width: "30%",
                    height: "auto",
                    maxHeight: "auto",
                    escKey: false,
                    overlayClose: false
                });
                $("#cboxClose").css("display", "none");
            });
            $("body").on("click", "#resetUserAcceptanceBtn", function() {
                let formParams = {
                    programId: $("#page-home").attr("data-programid")
                };
                let options = {
                    cache: false,
                    type: "POST",
                    url: "resetAcceptanceStatusAjax.action",
                    data: formParams,
                    success: function(data) {
                        $("#resetAcceptanceBtn").addClass("disabled");
                        $("#resetAcceptanceBtn").css("pointer-events", "none");
                        $(this).colorbox.close();
                    }
                };
                call.ajax("resetAcceptanceStatusAjax.action", options);
            });
            $("body").on("click", "#closeResetAcceptanceBtn", function() {
                $(this).colorbox.close();
            });
        }
    ])
    .controller("programHelpArticleEdit", [
        "$scope",
        "skyword",
        "help",
        "programSettings",
        function($scope, skyword, help, programSettings) {
            help.init();
            programSettings.init();
            //onReady
            $(function() {
                skyword.loginPing(300000, true);
            });
        }
    ])
    .controller("helpArticleEditAjax", ["$scope", function($scope) {}])
    .controller("programAnnouncementEdit", [
        "$scope",
        "help",
        "programSettings",
        function($scope, help, programSettings) {
            //        contentTemplate.init();
            help.init();
            app.value("ranOnce", false);
            programSettings.init();
            // redactor.init();

            //needed for new moment-datepicker
            $scope.dates = {
                startDate: null,
                endDate: null,
                format: null
            };

            //watches start date to make sure end date is not before start date
            $scope.$watch("[dates.startDate, dates.endDate]", function(
                newValue,
                oldValue
            ) {
                $scope.momentDateFormat = $("#momentPickerFormat").val();
                $scope.dateformat = "MM-DD-YYYY h:[00] A";

                $scope.dates.format = $scope.momentDateFormat + " h:[00] A";
                $scope.dates.format = $scope.dates.format.toString();

                if ($scope.dates.startDate != null) {
                    $scope.minDate = $scope.dates.startDate.toString();
                }
                if ($scope.dates.endDate != null) {
                    $scope.maxDate = $scope.dates.endDate.toString();
                }

                if (
                    $scope.dates.startDate == null &&
                    $scope.dates.endDate == null
                ) {
                    var startDateVal = $("#startTimeString").val();
                    var endDateVal = $("#stopTimeString").val();
                    $scope.dates.startDate = moment(
                        startDateVal,
                        $scope.dateformat
                    ).format($scope.dates.format);
                    $scope.dates.endDate = moment(
                        endDateVal,
                        $scope.dateformat
                    ).format($scope.dates.format);
                }

                if (
                    $scope.dates.startDate != null &&
                    $scope.dates.endDate != null
                ) {
                    $scope.startDateMoment = moment(
                        $scope.dates.startDate,
                        $scope.dates.format
                    )
                        .locale("en")
                        .format($scope.dateformat);
                    $scope.endDateMoment = moment(
                        $scope.dates.endDate,
                        $scope.dates.format
                    )
                        .locale("en")
                        .format($scope.dateformat);
                    $("#startTimeString").val($scope.startDateMoment);
                    $("#stopTimeString").val($scope.endDateMoment);
                }
            });
        }
    ])
    .controller("editCompetitorDomains", ["$scope", function($scope) {}])
    .controller("updateProgramCategoryOrderAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("syncApiTaxonomyAjax", ["$scope", function($scope) {}])
    .controller("fbChannel", ["$scope", function($scope) {}])
    .controller("selectFbPage", ["$scope", function($scope) {}])
    .controller("setFacebookPage", ["$scope", function($scope) {}])
    .controller("clearFacebookSettings", ["$scope", function($scope) {}])
    .controller("fbSetup", [
        "$scope",
        function($scope) {
            //onReady
            $(function() {
                $.ajaxSetup({ cache: true });
                $.getScript("//connect.facebook.net/en_US/sdk.js", function() {
                    FB.init({
                        appId: $("#fbAppId").val(),
                        version: "v3.1",
                        status: true,
                        cookie: true,
                        xfbml: true
                    });
                });
                $(".fbAuth").click(function() {
                    FB.login(
                        function(response) {
                            if (response != undefined) {
                                if (response.error != null) {
                                    if (response.error == "access_denied")
                                        console.log(
                                            "Access Denied - Reason:" +
                                                response.error_reason
                                        );
                                } else {
                                    window.location =
                                        $("#fbCallbackURL").val() +
                                        "&code=" +
                                        response.code;
                                }
                            }
                        },
                        {
                            scope: $("#fbOAuthPermissions").val()
                        }
                    );
                });
            });
        }
    ])
    .controller("clearFacebookSettingsExternal", [
        "$scope",
        function($scope) {}
    ])
    .controller("setFacebookPageExternal", ["$scope", function($scope) {}])
    .controller("editDestinations", [
        "$scope",
        "amplify",
        function($scope, amplify) {
            amplify.init();
        }
    ])
    .controller("disableDestinationAjax", ["$scope", function($scope) {}])
    .controller("editSoProPrefs", ["$scope", function($scope) {}])
    .controller("addFacebookDestination", [
        "$scope",
        function($scope) {
            //onReady
            $(function() {
                if ($("#fb-root").length) {
                    $(".fbAuth").removeAttr("disabled");
                    $.ajaxSetup({ cache: true });
                    $.getScript(
                        "//connect.facebook.net/" +
                            $("#userLocale").val() +
                            "/sdk.js",
                        function() {
                            FB.init({
                                appId: $("#fbAppId").val(),
                                version: "v3.1",
                                status: true,
                                cookie: true,
                                xfbml: true
                            });
                        }
                    );
                    $("html").delegate(".fbAuth", "click", function() {
                        FB.login(
                            function(response) {
                                console.log(response);
                                if (response != undefined) {
                                    if (response.error != null) {
                                        if (response.error == "access_denied")
                                            console.log(
                                                "Access Denied - Reason:" +
                                                    response.error_reason
                                            );
                                    } else {
                                        window.location =
                                            $("#fbCallbackURL").val() +
                                            "&code=" +
                                            response.code;
                                    }
                                } else {
                                    window.location =
                                        "/editDestinations.action";
                                }
                            },
                            {
                                scope: $("#fbOAuthPermissions").val()
                            }
                        );
                    });
                }
            });
        }
    ])
    .controller("initFacebookConnectAjax", ["$scope", function($scope) {}])
    .controller("completeOAuthFacebook", [
        "$scope",
        function($scope) {
            //onReady
            $(function() {
                if ($("#fb-root").length) {
                    $.ajaxSetup({ cache: true });
                    $.getScript(
                        "//connect.facebook.net/" +
                            $("#userLocale").val() +
                            "/sdk.js",
                        function() {
                            FB.init({
                                appId: $("#fbAppId").val(),
                                version: "v3.1",
                                status: true,
                                cookie: true,
                                xfbml: true
                            });
                        }
                    );
                    $(".fbAuth").click(function() {
                        FB.login(
                            function(response) {
                                console.log(response);
                                if (response != undefined) {
                                    if (response.error != null) {
                                        if (response.error == "access_denied")
                                            console.log(
                                                "Access Denied - Reason:" +
                                                    response.error_reason
                                            );
                                    } else {
                                        window.location =
                                            $("#fbCallbackURL").val() +
                                            "&code=" +
                                            response.code;
                                    }
                                } else {
                                    window.location =
                                        "/editDestinations.action";
                                }
                            },
                            {
                                scope: $("#fbOAuthPermissions").val()
                            }
                        );
                    });
                }
            });
        }
    ])
    .controller("chooseFacebookPages", ["$scope", function($scope) {}])
    .controller("addLinkedInDestination", [
        "$scope",
        function($scope) {
            //onReady
            $(function() {
                if ($("#tw-root").length) {
                    $(".twAuth").click(function() {
                        $.oauthpopup({
                            path: $("#oauth_url").val(),
                            callback: function() {
                                window.location.href =
                                    "/chooseLinkedInPages.action";
                            }
                        });
                    });
                }
            });
        }
    ])
    .controller("initLinkedInConnectAjax", ["$scope", function($scope) {}])
    .controller("completeOAuthLinkedIn", ["$scope", function($scope) {}])
    .controller("chooseLinkedInPages", ["$scope", function($scope) {}])
    .controller("addTwitterDestination", [
        "$scope",
        function($scope) {
            //onReady
            $(function() {
                if ($("#tw-root").length) {
                    $(".twAuth").click(function() {
                        $.oauthpopup({
                            path: $("#oauth_url").val(),
                            callback: function() {
                                window.location.href =
                                    "/editDestinations.action";
                            }
                        });
                    });
                }
            });
        }
    ])
    .controller("initTwitterConnectAjax", ["$scope", function($scope) {}])
    .controller("setSoProTwitterPage", ["$scope", function($scope) {}])
    .controller("soProSetup", ["$scope", function($scope) {}])
    .controller("addFacebookDestinationExt", [
        "$scope",
        function($scope) {
            //onReady
            $(function() {
                if ($("#fb-root").length) {
                    $.ajaxSetup({ cache: true });
                    $.getScript(
                        "//connect.facebook.net/en_US/sdk.js",
                        function() {
                            FB.init({
                                appId: $("#fbAppId").val(),
                                version: "v3.1",
                                status: true,
                                cookie: true,
                                xfbml: true
                            });
                        }
                    );
                    $(".fbAuth").click(function() {
                        FB.login(
                            function(response) {
                                if (response != undefined) {
                                    if (response.error != null) {
                                        if (response.error == "access_denied")
                                            console.log(
                                                "Access Denied - Reason:" +
                                                    response.error_reason
                                            );
                                    } else {
                                        window.location =
                                            $("#fbCallbackURL").val() +
                                            "&code=" +
                                            response.code;
                                    }
                                } else {
                                    window.location =
                                        "/soProSetup.action?key=" +
                                        $("#key").val();
                                }
                            },
                            {
                                scope: $("#fbOAuthPermissions").val()
                            }
                        );
                    });
                }
            });
        }
    ])
    .controller("addLinkedInDestinationExt", [
        "$scope",
        function($scope) {
            //onReady
            $(function() {
                if ($("#tw-root").length) {
                    $(".twAuth").click(function() {
                        $.oauthpopup({
                            path: $("#oauth_url").val(),
                            callback: function() {
                                window.location.href =
                                    "/chooseLinkedInPagesExt.action?key=" +
                                    $("#key").val();
                            }
                        });
                    });
                }
            });
        }
    ])
    .controller("addTwitterDestinationExt", [
        "$scope",
        function($scope) {
            //onReady
            $(function() {
                if ($("#tw-root").length) {
                    $(".twAuth").click(function() {
                        $.oauthpopup({
                            path: $("#oauth_url").val(),
                            callback: function() {
                                window.location.href =
                                    "/soProSetup.action?key=" + $("#key").val();
                            }
                        });
                    });
                }
            });
        }
    ])
    .controller("completeOAuthFacebookExt", [
        "$scope",
        function($scope) {
            //onReady
            $(function() {
                if ($("#fb-root").length) {
                    $.ajaxSetup({ cache: true });
                    $.getScript(
                        "//connect.facebook.net/en_US/sdk.js",
                        function() {
                            FB.init({
                                appId: $("#fbAppId").val(),
                                version: "v3.1",
                                status: true,
                                cookie: true,
                                xfbml: true
                            });
                        }
                    );

                    $(".fbAuth").click(function() {
                        FB.login(
                            function(response) {
                                if (response != undefined) {
                                    if (response.error != null) {
                                        if (response.error == "access_denied")
                                            console.log(
                                                "Access Denied - Reason:" +
                                                    response.error_reason
                                            );
                                    } else {
                                        window.location =
                                            $("#fbCallbackURL").val() +
                                            "&code=" +
                                            response.code;
                                    }
                                } else {
                                    window.location =
                                        "/soProSetup.action?key=" +
                                        $("#key").val();
                                }
                            },
                            {
                                scope: $("#fbOAuthPermissions").val()
                            }
                        );
                    });
                }
            });
        }
    ])
    .controller("completeOAuthLinkedInExt", ["$scope", function($scope) {}])
    .controller("setSoProTwitterPageExt", ["$scope", function($scope) {}])
    .controller("chooseFacebookPagesExt", ["$scope", function($scope) {}])
    .controller("enableDestinationAjax", ["$scope", function($scope) {}])
    .controller("videoDeliveryYouTube", [
        "$scope",
        "call",
        function($scope, call) {
            $(function() {
                if ($("#yt-root").length) {
                    $(".ytAuth").click(function() {
                        $.oauthpopup({
                            path: $("#oauth_url").val(),
                            windowOptions:
                                "location=0,status=0,width=800,height=900",
                            callback: function() {
                                window.location.href =
                                    "/videoDeliveryYouTube.action";
                            }
                        });
                    });
                }
                $(".deleteChannel").on("click", function(e) {
                    e.preventDefault();
                    var options = {
                        openAuthInfoId: $(this)
                            .closest(".channelModule")
                            .find('[name="openAuthInfoId"]')
                            .val()
                    };
                    var result = call.overlay(
                        "/deleteChannelAjax.action",
                        options,
                        700,
                        null
                    );
                    return result;
                });
            });
        }
    ]);

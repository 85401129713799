app.directive("kalturaupload", [
    "$rootScope",
    "$timeout",
    "$translate",
    "skyword",
    "kalturaService",
    "videoUtilService",
    function(
        $rootScope,
        $timeout,
        $translate,
        skyword,
        kalturaService,
        videoUtilService
    ) {
        return {
            restrict: "EA",
            replace: true,
            templateUrl: "/partials/directives/kalturaupload.html",
            scope: true,
            link: function(scope, element, attrs) {
                var jqXHR;
                window.addFileToKalturaResponse = function(response) {
                    scope.$apply(function() {
                        scope.addFileToKalturaResponse(response);
                    });
                };

                /**
                 * A video is being uploaded on Kaltura, keep track of it
                 */
                scope.addFileToKalturaResponse = function(response) {
                    if (typeof scope.listFileUploadProgress == "undefined") {
                        scope.listFileUploadProgress = [];
                    }
                    // add video record
                    scope.listFileUploadProgress.push({
                        fileName: response.fileName,
                        isUploadDone: false
                    });
                };

                scope.addCancel = function() {
                    element.attr("upload-state", "default");
                    $("#seoScore")
                        .removeClass("disabled")
                        .removeAttr("disabled");
                    $("#btn-save-article")
                        .removeClass("disabled")
                        .removeAttr("disabled");
                    jqXHR.abort();
                    if (scope.att.saved) {
                        //delete on backend if data was saved, otherwise just remove from page
                        if (scope.att.id !== -1) {
                            scope.removeConfirm(scope.att);
                        } else {
                            $rootScope.$broadcast("removeVideo", scope.att);
                        }
                    }
                    return false;
                };

                //scope.remove = function() {
                //    scope.att.remove = true;
                //};
                //scope.removeConfirm = function() {
                //    kalturaService.removeVideo({
                //        "assignmentUpload": true,
                //        "attachmentId": scope.att.id,
                //        "contentRequestId": attrs.contentRequestId,
                //        "contentTemplateElementDataId": attrs.contentTemplateElementDataId,
                //        "csrfToken": angular.element('#csrfToken').val()
                //    }, function(){
                //        $rootScope.$broadcast('removeVideo', scope.att);
                //    });
                //}
                //scope.removeCancel = function() {
                //    scope.att.remove = false;
                //};

                /**
                 * Initialize project video for Kaltura upload
                 */
                scope.att = {};
                scope.hasAttachment =
                    attrs.hasAttachment === "true" ? true : false;
                scope.contentTemplateElementDataId =
                    attrs.contentTemplateElementDataId;
                scope.contentRequestId = attrs.contentRequestId;
                scope.contentId = attrs.contentId;
                scope.isDigitalAsset = $(".kaltura").data("digital-asset");
                scope.attachmentExt = "";
                scope.duration = 0;
                scope.maxsize = attrs.maxsize;
                scope.uploadDisabled = true;
                scope.error = null;
                kalturaService.getKs(function(ks) {
                    var lbd = false;
                    scope.uploadDisabled = false;

                    // Start: stolen from [fileupload] directive
                    //scope.filename = attrs.filename;
                    //$timeout(function() {
                    //    if (attrs.filename != undefined && attrs.filename !== '') {
                    //        scope.filename = attrs.filename.substring(attrs.filename.lastIndexOf("/"), attrs.filename.length);
                    //        element.attr('upload-state', 'default');
                    //        scope.$apply()
                    //    }
                    //}, 500, false);
                    var status = $(element).find(".info"),
                        feedback = $(element).find(".progress"),
                        bar = feedback.find(".bar"),
                        cancel = status.find("a.cancel"),
                        bytes = status.find(".bytes");

                    // End: stolen from [fileupload] directive

                    $(element)
                        .find('input[type="file"]')
                        .show();
                    scope.error = null;
                    $(element)
                        .find('input[type="file"]')
                        .kalturaupload({
                            apiURL: "//www.kaltura.com/api_v3/",
                            url:
                                "//www.kaltura.com/api_v3/?service=uploadToken&action=upload&format=1",
                            ks: ks,
                            ignoreChunkHeader: true,
                            dataType: "json",
                            getFilesFromResponse: function(x) {},
                            add: function(e, data) {
                                scope.error = null;
                                console.log(
                                    "attempting to upload %s, MIME type is %s",
                                    data.originalFiles[0]["name"],
                                    data.originalFiles[0]["type"]
                                );
                                //if (data.files[0].size > 1500000000){
                                // https://skyword.atlassian.net/browse/SKY-14082
                                //                                scope.error = {filesizelimit: true};
                                //                                scope.$apply;
                                //} else
                                if (
                                    videoUtilService.isVideoFileName(
                                        data.originalFiles[0]["name"]
                                    )
                                ) {
                                    jqXHR = data.submit();
                                    scope.error = null;
                                } else {
                                    scope.error = {
                                        unanacceptablefiletype: true
                                    };
                                    scope.error.filename =
                                        data.originalFiles[0]["name"];
                                    $translate(
                                        "angular.error.invalidfiletype",
                                        { filename: scope.error.filename }
                                    ).then(function(txt) {
                                        scope.error.translatedfileext = txt;
                                    });
                                }
                                scope.$apply();
                                $(element).removeClass("errorField");
                                $("#seoScore")
                                    .addClass("disabled")
                                    .attr("disabled", "disabled");
                                $("#btn-save-article")
                                    .addClass("disabled")
                                    .attr("disabled", "disabled");
                                $("#pubSubmitBtn")
                                    .addClass("disabled")
                                    .attr("disabled", "disabled");
                            },
                            send: function(e, data) {
                                scope.filename = data.files[0].name;
                                scope.$apply();
                            },
                            done: function(e, data) {
                                scope.error = null;
                                $.get(
                                    data.apiURL +
                                        "?service=baseEntry&action=addfromuploadedfile&format=1&entry:objectType=KalturaBaseEntry&entry:type=-1&entry:name=" +
                                        data.result.fileName +
                                        "&ks=" +
                                        data.ks +
                                        "&uploadTokenId=" +
                                        data.result.id,
                                    function(data) {
                                        kalturaService.addVideo(
                                            {
                                                contentId: scope.contentId,
                                                contentRequestId:
                                                    scope.contentRequestId,
                                                contentTemplateElementDataId:
                                                    scope.contentTemplateElementDataId,
                                                externalValueId: data.id,
                                                fileName: data.name,
                                                fileSize: fileSize
                                            },
                                            function(data) {
                                                element.attr(
                                                    "upload-state",
                                                    "complete"
                                                );
                                                element
                                                    .find(".thumbnail")
                                                    .css(
                                                        "background-image",
                                                        "url('http://cdn.kaltura.com/p/1737042/thumbnail/entry_id/" +
                                                            data.externalValueId +
                                                            "/width/999/height/999/type/1')"
                                                    );

                                                scope.hasAttachment = true;

                                                scope.att.externalValueId =
                                                    data.externalValueId;
                                                scope.att.fileName =
                                                    data.fileName;
                                                scope.att.fileSize =
                                                    data.fileSize;
                                                scope.att.id = data.id;
                                                scope.duration =
                                                    data.formattedVideoDuration;
                                                scope.attachmentExt = data.fileName.substring(
                                                    data.fileName.indexOf("."),
                                                    data.fileName.length
                                                );

                                                setTimeout(function() {
                                                    scope.$apply();
                                                }, 5000);

                                                $("#seoScore")
                                                    .removeClass("disabled")
                                                    .removeAttr("disabled");
                                                $("#btn-save-article")
                                                    .removeClass("disabled")
                                                    .removeAttr("disabled");
                                            }
                                        );
                                    }
                                );
                            },
                            progressall: function(e, data) {
                                fileSize = data.total;
                                var progress = parseInt(
                                    (data.loaded / data.total) * 100,
                                    10
                                );

                                if (progress >= 100) {
                                    element.attr("upload-state", "processing");
                                } else {
                                    element.attr("upload-state", "progress");
                                }

                                // Start: stolen from [fileupload] directive
                                feedback.show();
                                feedback
                                    .addClass("active")
                                    .removeClass("error");
                                bar.find("span").css("width", progress + "%");
                                if (data.total < Math.pow(10, 6)) {
                                    scope.loaded =
                                        Math.round(
                                            data.loaded / Math.pow(10, 2)
                                        ) / 10;
                                    scope.total =
                                        Math.round(
                                            data.total / Math.pow(10, 2)
                                        ) / 10;
                                    scope.units = "kB";
                                } else if (data.total < Math.pow(10, 9)) {
                                    scope.loaded =
                                        Math.round(
                                            data.loaded / Math.pow(10, 5)
                                        ) / 10;
                                    scope.total =
                                        Math.round(
                                            data.total / Math.pow(10, 5)
                                        ) / 10;
                                    scope.units = "MB";
                                } else {
                                    scope.loaded =
                                        Math.round(
                                            data.loaded / Math.pow(10, 8)
                                        ) / 10;
                                    scope.total =
                                        Math.round(
                                            data.total / Math.pow(10, 8)
                                        ) / 10;
                                    scope.units = "GB";
                                }
                                // End: stolen from [fileupload] directive
                                scope.$apply();
                            }
                        })
                        .prop("disabled", !$.support.fileInput)
                        .parent()
                        .addClass($.support.fileInput ? undefined : "disabled");
                    if (lbd) {
                        $(this)
                            .find('input[type="file"]')
                            .fileupload({
                                apiURL: "http://lbd.kaltura.com/api_v3/",
                                url:
                                    "http://lbd.kaltura.com/api_v3/?service=uploadToken&action=upload&format=1"
                            });
                    }
                });
            }
        };
    }
]);

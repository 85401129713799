app.service("accountSecurity", [
    "skyword",
    "call",
    function(skyword, call) {
        return {
            init: function() {
                var updatePhoneNumberExample = function() {
                        var options;
                        var iso = $("#selectedCountryIso").val();

                        if (typeof iso == "undefined") {
                            iso = "US";
                        }
                        var urlAction =
                            "getPhoneNumberExampleAjax.action?selectedCountryIso=" +
                            iso;

                        options = {
                            cache: false,
                            type: "POST",
                            dataType: "json",
                            url: urlAction,
                            success: function(data) {
                                console.log(data);
                                $("#pnExSpan").html(data.message);
                            }
                        };
                        call.ajax(urlAction, options);
                    },
                    accountSecuritySelect = function(btn) {
                        section = btn.closest("section");
                        nextSection = $("#security-mode");
                        nextSection.show();
                        section.hide();
                    },
                    accountSecuritySetupAjax = function(btn) {
                        var urlAction = "accountSecuritySetupAjax.action",
                            tfType = null,
                            retVal = false,
                            auth = $("#authenticator"),
                            sms = $("#sms"),
                            opts,
                            options,
                            section = btn.closest("section"),
                            nextSection = null;

                        if (auth.is(":checked")) {
                            tfType = "authenticator";
                            nextSection = $("#security-authenticator");
                        } else if (sms.is(":checked")) {
                            tfType = "sms";
                            nextSection = $("#security-sms");
                        }
                        validatePhoneNumber();
                        if (null === tfType) {
                            return false;
                        }

                        opts = {
                            twoFactorType: tfType
                        };

                        options = {
                            cache: false,
                            type: "POST",
                            data: opts,
                            dataType: "json",
                            url: urlAction,
                            success: function(data) {
                                console.log(data);
                                if ("authenticator" === tfType) {
                                    $("#authenticator-image").attr(
                                        "src",
                                        data.twoFactor.twoFactorImageUrl
                                    );
                                }
                                $("#authenticator-key").val(
                                    data.twoFactor.twoFactorSecretKey
                                );
                            },
                            complete: function() {
                                section.hide();
                                nextSection.show();
                            }
                        };

                        call.ajax(urlAction, options);

                        return retVal;
                    },
                    accountSecurityVerifyAuthenticator = function(btn) {
                        var urlAction = "accountSecurityVerifyAjax.action",
                            tfType = "authenticator",
                            retVal = false,
                            opts,
                            options,
                            section = btn.closest("section"),
                            nextSection = $("#security-verify-authenticator"),
                            authCode = $("#authenticator-key").val();

                        opts = {
                            twoFactorType: tfType,
                            twoFactorSecretKey: authCode
                        };

                        options = {
                            cache: false,
                            type: "POST",
                            data: opts,
                            dataType: "json",
                            url: urlAction,
                            success: function(data) {
                                console.log(data);
                            },
                            complete: function() {
                                section.hide();
                                nextSection.show();
                            }
                        };
                        $(".helpLink").show();
                        $(".helpContainer").hide();
                        call.ajax(urlAction, options);

                        return retVal;
                    },
                    accountSecurityVerifySMS = function(btn) {
                        var section = btn.closest("section"),
                            nextSection = $("#security-verify-sms"),
                            urlAction =
                                "twoFactorSmsAjax.action?selectedCountryIso=" +
                                $("#selectedCountryIso").val() +
                                "&phoneNumber=" +
                                $("#phoneNumber").val() +
                                "&twoFactorSecretKey=" +
                                $("#authenticator-key").val(),
                            options = {
                                cache: false,
                                type: "POST",
                                dataType: "json",
                                url: urlAction,
                                success: function(data) {
                                    section.hide();
                                    nextSection.show();
                                }
                            };
                        let phNo = $("#selectedCountryIso option:selected").text().split('(')[1].split(')')[0]+ " " + $("#phoneNumber").val()
                        $(".phoneNumberDisplay").html(phNo);
                        $(".helpLink").show();
                        $(".helpContainer").hide();
                        call.skyjax(urlAction, options);
                    },
                    accountSecurityVerifyCode = function(btn) {
                        var enteredCode =
                                $("#authenticator-code").val() == null ||
                                $("#authenticator-code").val() == ""
                                    ? $("#sms-code").val()
                                    : $("#authenticator-code").val(),
                            urlAction =
                                "accountSecurityVerifyAjax.action?enteredCode=" +
                                enteredCode +
                                "&selectedCountryIso=" +
                                $("#selectedCountryIso").val() +
                                "&phoneNumber=" +
                                $("#phoneNumber").val(),
                            tfType = "authenticator",
                            retVal = false,
                            opts,
                            section = btn.closest("section"),
                            nextSection = $("#security-complete"),
                            options = {
                                cache: false,
                                type: "POST",
                                dataType: "json",
                                url: urlAction,
                                success: function(json) {
                                    if (json.error == true) {
                                        $.each(json.errorMessages, function(
                                            k,
                                            err
                                        ) {
                                            $("#error-box .content").append(
                                                "<li>" +
                                                    $("<span/>")
                                                        .html(err)
                                                        .text() +
                                                    "</li>"
                                            );
                                            $("#error-box").show();
                                        });
                                        $(".twoFactorButton")
                                            .removeClass("disabled")
                                            .removeAttr("disabled");
                                    } else {
                                        $("#error-box").hide();
                                        section.hide();
                                        nextSection.show();
                                    }
                                }
                            };
                        call.skyjax(urlAction, options);
                    },
                    accountSecurityComplete = function(btn) {
                        var urlAction =
                                "accountSecurityCompleteAjax.action?trustForThirty=" +
                                $("#trust-for-30").is(":checked"),
                            retVal = false,
                            options = {
                                cache: false,
                                type: "POST",
                                dataType: "json",
                                url: urlAction,
                                success: function(json) {
                                    window.location.href =
                                        json.redirectionTarget;
                                }
                            };

                        call.skyjax(urlAction, options);
                    },
                    twoFactor = function() {
                        var that = this,
                            btn = $(this),
                            mode = btn.attr("data-value");
                        $(".twoFactorButton")
                            .removeClass("disabled")
                            .removeAttr("disabled");
                        if ("init" === mode) {
                            $("#error-box").hide();
                            return accountSecuritySelect(btn);
                        } else if ("selectMode" === mode) {
                            $("#error-box").hide();
                            return accountSecuritySetupAjax(btn);
                        } else if ("enterAuthenticator" === mode) {
                            accountSecurityVerifyAuthenticator(btn);
                        } else if ("enterSMS" === mode) {
                            $("#error-box").hide();
                            accountSecurityVerifySMS(btn);
                        } else if (
                            "verifyAuthenticator" === mode ||
                            "verifySMS" === mode
                        ) {
                            $('li[data-progress="2"]').addClass("passed");
                            accountSecurityVerifyCode(btn);
                        } else if ("continue" === mode) {
                            accountSecurityComplete(btn);
                        }
                    },
                    authToggle = function() {
                        var a = $(this),
                            div = a.closest(".authDiv"),
                            sib = div.siblings(".authDiv");
                        div.hide();
                        sib.show();
                    },
                    authChange = function() {
                        var a = $(this),
                            mode = a.data("value"),
                            div = a.closest(".authDiv"),
                            section = a.closest("section"),
                            nextSection;

                        if (mode == "resetAuth") {
                            nextSection = $("#security-authenticator");
                        } else if (mode == "resetSMS") {
                            nextSection = $("#security-sms");
                        } else {
                            nextSection = $("#security-mode");
                        }
                        section.hide();
                        div.hide();
                        nextSection.show();
                        $(".twoFactorButton")
                            .removeClass("disabled")
                            .removeAttr("disabled");
                    },
                    goToSection = function() {
                        var auth = $("#authenticator"),
                            sms = $("#sms");
                        //from update page, hide step 1 mode selection page
                        if (auth.is(":checked") || sms.is(":checked")) {
                            accountSecuritySetupAjax($("#btn-select-mode"));
                            $(".accountSecuritySteps .step-method")
                                .hide()
                                .next()
                                .hide();
                            $(".updateCancel").show();
                        }
                    },
                    validatePhoneNumber = function() {
                        if (
                            $("#selectedCountryIso").val() !== -1 &&
                            $("#phoneNumber").val() !== ""
                        ) {
                            $("#btn-enter-phone")
                                .removeClass("disabled")
                                .removeAttr("disabled");
                        } else {
                            $("#btn-enter-phone")
                                .addClass("disabled")
                                .attr("disabled", "disabled");
                        }
                    },
                    validateMode = function() {
                        if (
                            $("#sms").is(":checked") ||
                            $("#authenticator").is(":checked")
                        ) {
                            $("#btn-select-mode")
                                .removeClass("disabled")
                                .removeAttr("disabled");
                        } else {
                            $("#btn-select-mode")
                                .addClass("disabled")
                                .attr("disabled", "disabled");
                        }
                    };

                //onReady
                $(function() {
                    $(".twoFactorButton").click(twoFactor);
                    $("a.authToggle").click(authToggle);
                    $("a.qrToggle").click(authToggle);
                    $("a.authChange").click(authChange);
                    goToSection();
                    $(".resendSMS").click(function() {
                        var opts,
                            options,
                            urlAction =
                                "twoFactorSmsAjax.action?" +
                                [
                                    "selectedCountryIso=" +
                                        $("#selectedCountryIso").val(),
                                    "phoneNumber=" + $("#phoneNumber").val(),
                                    "twoFactorSecretKey=" +
                                        $("#authenticator-key").val()
                                ].join("&");
                        options = {
                            cache: false,
                            type: "POST",
                            dataType: "html",
                            url: urlAction,
                            error: function(data, status, err) {
                                console.log([data, status, err]);
                            },
                            complete: function() {
                                setTimeout(function() {
                                    $(".authDiv.helpLink").show();
                                    $("#verifiy-problems")
                                        .removeClass("loading")
                                        .removeClass("success")
                                        .slideUp();
                                }, 1000);
                                $("#verifiy-problems").addClass("success");
                            }
                        };
                        $("#verifiy-problems").addClass("loading");
                        call.skyjax(urlAction, options);
                        return false;
                    });

                    $("#selectedCountryIso").on("change", function() {
                        updatePhoneNumberExample();
                    });
                    $("#selectedCountryIso").on("change", validatePhoneNumber);
                    $("#phoneNumber").keyup(validatePhoneNumber);
                    $("#sms, #authenticator").on("change", validateMode);
                    updatePhoneNumberExample();
                    validateMode();
                });
            }
        };
    }
]);

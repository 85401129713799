app.service("contentRequestView", [
    "skyword",
    "call",
    "setup",
    "validation",
    function(skyword, call, setup, validation) {
        var setupInlineBox = function() {
                setup.followMenuResize();
                setup.styledDropdowns();
            },
            updateState = function(id) {
                var nsId = id,
                    urlAction = "/" + nsId + "AssignmentAjax.action",
                    options = {
                        cache: false,
                        data: {
                            contentRequestId: $("#contentRequestId").val(),
                            returnLinkURL: $("#returnLink").attr("href")
                        },
                        dataType: "html",
                        type: "get",
                        url: urlAction,
                        success: function(data) {
                            var sidebarBox = $(".sidebarBox"),
                                html = $("html");

                            $("#loader-module").hide();
                            sidebarBox
                                .removeClass("whiteout")
                                .slideUp("fast", function() {
                                    $("#inline-box")
                                        .html(data)
                                        .slideDown("fast", setupInlineBox);
                                })
                                .slideUp("fast", function() {
                                    setup.followMenuResize();
                                    setup.styledDropdowns();
                                });
                            $("#inline-box")
                                .html(data)
                                .slideDown("fast", setupInlineBox);

                            html.off("click", "#update-status-box button").on(
                                "click",
                                "#update-status-box button",
                                function() {
                                    var formSubmit = $("#form-submit");
                                    (urlAction = formSubmit.attr("action")),
                                        (options = {
                                            cache: false,
                                            data: {
                                                contentRequestId: $(
                                                    "#contentRequestId"
                                                ).val(),
                                                returnLinkURL: $(
                                                    "#returnLink"
                                                ).attr("href"),
                                                writerComment: $(
                                                    "#writerComment"
                                                ).val()
                                            },
                                            dataType: "json",
                                            type: "POST",
                                            url: urlAction,
                                            success: function(data) {
                                                if (
                                                    validation.parse(data) &&
                                                    data.redirectionTarget
                                                        .length > 0
                                                ) {
                                                    window.location.href =
                                                        data.redirectionTarget;
                                                }
                                            }
                                        }); // jshint ignore:line
                                    call.ajax(urlAction, options);
                                    $("#loader-module")
                                        .show()
                                        .find(".loadingText")
                                        .hide();
                                    $("#update-status-box").addClass(
                                        "whiteout"
                                    );
                                }
                            );
                        }
                    };
                call.ajax(urlAction, options);
                return false;
            };
        return {
            init: function() {
                //onReady
                $(function() {
                    var body = $("body");

                    $("html").on("click", "#inline-close", function() {
                        skyword.enableButtons();
                        $(".sidebarBox").slideDown("fast", function() {
                            $("#update-status-box").slideUp("fast", function() {
                                setup.followMenuResize();
                            });
                        });
                        return false;
                    });
                });
            },
            updateState: function(id) {
                updateState(id);
            }
        };
    }
]);

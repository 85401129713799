app.service("user", [
    "skyword",
    "validation",
    "call",
    function(skyword, validation, call) {
        return {
            init: function() {
                //onReady
                $(function() {
                    /*
                     * addUser.jsp
                     */
                    $("#add-user-button").click(function(event) {
                        $("#error-box .content").empty();
                        $("#error-box").fadeOut();
                        var urlAction = "addUserAjax.action",
                            options = {
                                cache: false,
                                type: $("#formSubmit").attr("method"),
                                data: $("#formSubmit").serialize(),
                                url: urlAction,
                                success: function(text) {
                                    validation.parse(text);
                                }
                            };
                        call.ajax(urlAction, options);
                        return false;
                    });

                    /*
                     * users.jsp
                     */
                    $("html").on("click", "a.edit-user", function() {
                        var urlAction =
                            "/editUserAjax.action?userId=" + $(this).attr("id");
                        var result = call.overlay(urlAction, null, 550, null);
                        return result;
                    });
                    $("html").on("click", "a.remove-user", function() {
                        var urlAction =
                            "/removeUserAjax.action?userId=" +
                            $(this).attr("id");
                        var result = call.overlay(urlAction, null, 550, null);
                        return result;
                    });
                    $("#add-externalreviewer-button").click(function(event) {
                        $("#error-box .content").empty();
                        $("#error-box").fadeOut();
                        var urlAction = "addExternalReviewerAjax.action",
                            options = {
                                cache: false,
                                type: $("#formSubmit").attr("method"),
                                data: $("#formSubmit").serialize(),
                                url: urlAction,
                                success: function(text) {
                                    validation.parse(text);
                                }
                            };
                        call.ajax(urlAction, options);
                        return false;
                    });
                    $("html").on(
                        "click",
                        "a.remove-externalreviewer",
                        function() {
                            var urlAction =
                                "/removeExternalReviewerAjax.action?userId=" +
                                $(this).attr("id");
                            var result = call.overlay(
                                urlAction,
                                null,
                                550,
                                null
                            );
                            return result;
                        }
                    );

                    /*
                     * editUser.jspf
                     */
                    $("html").on("click", "#user-update-button", function() {
                        var urlAction = "/editUserAjax.action";
                        return call.overlay(
                            urlAction,
                            {
                                userId: document.forms[0].userId.value,
                                roleId: document.forms[0].roleId.value,
                                newManagerId: $("#newManagerId").val()
                            },
                            550,
                            null,
                            "post"
                        );
                    });
                    $("html").on("change", "#assignable-roles", function() {
                        var reassignmentPossible = $(
                            "#reassignment-possible"
                        ).val();
                        if (reassignmentPossible == "true") {
                            if (
                                $(this).val() == 2 ||
                                $(this).val() == 13 ||
                                $(this).val() == 14
                            ) {
                                $("#reassign-container").fadeOut();
                            } else {
                                $("#reassign-container").fadeIn();
                            }
                            $(this).colorbox.resize();
                        }
                    });

                    /*
                     * removeUser.jspf
                     */
                    $("html").on("click", "#user-remove-button", function() {
                        var urlAction = "/removeUserAjax.action";
                        return call.overlay(
                            urlAction,
                            {
                                userId: document.forms[0].userId.value,
                                newManagerId:
                                    document.forms[0].newManagerId.value
                            },
                            550,
                            null,
                            "post"
                        );
                    });
                });
            }
        };
    }
]);

(function() {
    app.service("project", [
        "$http",
        function($http) {
            return {
                // load a project by id
                getProject: function(
                    programId,
                    projectId,
                    callback,
                    errorCallback
                ) {
                    $http({
                        url:
                            "/API/v2/projects/" +
                            projectId +
                            "?programId=" +
                            programId,
                        method: "GET"
                    })
                        .success(function(data, status, headers, config) {
                            callback(data);
                        })
                        .error(function(data, status, headers, config) {
                            console.log(data);
                            errorCallback(data);
                        });
                },
                // load all active projects for program
                getActiveProjects: function(
                    programId,
                    includeDefault,
                    calendarFilter,
                    callback
                ) {
                    // var urlString = "/API/program/" + programId + "/project?";
                    var urlString = "/API/v2/projects?programId=" + programId;
                    if (true === includeDefault) {
                        urlString += "&includeDefault=true";
                    }
                    if (true === calendarFilter) {
                        urlString += "&calendarFilter=true";
                        if (
                            undefined !== calendarShareId &&
                            null !== calendarShareId
                        ) {
                            url += "&calendarShareId=" + calendarShareId;
                        }
                    }
                    //                    console.log("GET " + urlString);

                    $http({
                        //                        url: "/API/program/" + programId + "/project?includeDefault=",
                        url: urlString,
                        method: "GET"
                    })
                        .success(function(data, status, headers, config) {
                            callback(data);
                        })
                        .error(function(data, status, headers, config) {
                            console.log(data);
                        });
                },
                // Returns $http promise
                getProjects: function(
                    programId,
                    includeDefault,
                    calendarFilter,
                    calendarShareId
                ) {
                    // var urlString = "/API/program/" + programId + "/project?";
                    var urlString = "/API/v2/projects?programId=" + programId;

                    if (true === includeDefault) {
                        urlString += "&includeDefault=true";
                    }
                    if (true === calendarFilter) {
                        urlString += "&calendarFilter=true";
                        if (
                            undefined !== calendarShareId &&
                            null !== calendarShareId
                        ) {
                            urlString += "&calendarShareId=" + calendarShareId;
                        }
                    }
                    console.log("GET " + urlString);

                    return $http.get(urlString);
                },
                saveNewProject: function(
                    programId,
                    projectParams,
                    callback,
                    errorCallback
                ) {
                    $http({
                        url: "/API/v2/projects",
                        method: "PUT",
                        data: {
                            title: projectParams.projectName,
                            dateStartMillis: projectParams.projectStartDate,
                            dateEndMillis: projectParams.projectEndDate,
                            programId: programId
                        },
                        cache: false,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                        .success(function(data, status, headers, config) {
                            parent.$.colorbox.close();
                            callback(data);
                        })
                        .error(function(data, status, headers, config) {
                            console.log("error status returned: ", status);
                            console.log(data);
                            errorCallback(data);
                        });
                }
            };
        }
    ]);
})();

app.service("calendarView", [
    "$http",
    function($http) {
        return {
            getViewList: function(programId, calendarType, callback) {
                var url =
                    "/API/program/" + programId + "/calendar-filter/filters";
                if (undefined !== calendarType && null !== calendarType) {
                    url += "?calendarType=" + calendarType;
                }
                $http({
                    url: url,
                    method: "GET"
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {});
            },
            saveCalendarViewRecord: function(cal, callback) {
                $http({
                    url:
                        "/API/program/" +
                        cal.programId +
                        "/calendar-filter/share",
                    method: "put",
                    data: cal
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {});
            },
            deleteCalendarViewRecord: function(cal, callback) {
                $http({
                    url:
                        "/API/program/" +
                        cal.programId +
                        "/calendar-filter/" +
                        cal.id,
                    method: "delete"
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {});
            }
        };
    }
]);

/**/

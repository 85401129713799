app.service("recaptcha", [
    "skyword",
    function(skyword) {
        return {
            init: function() {
                //onReady
                $(function() {
                    Recaptcha.create(
                        "6Ld4MgoAAAAAAD9ZVKswo-Ut0exRnTksFaEH-7Qt",
                        "recaptcha_div",
                        {
                            theme: "clean",
                            lang: $("html").attr("lang") || "en",
                            callback: function() {}
                        }
                    );
                    $("#recaptchaSubmit").click(function() {
                        $("#reCaptchaChallenge").val(Recaptcha.get_challenge());
                        $("#reCaptchaResponse").val(Recaptcha.get_response());
                    });
                });
            }
        };
    }
]); // end recaptcha

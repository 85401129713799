(function() {
    app.directive("showMore", [
        function() {
            var html = "<div>";
            html +=
                '<p ng-show="largeText"> {{ summary | limitTo: limit :end }} ';
            html +=
                '<a href="javascript:;" ng-click="showMore(); $event.stopPropagation();" ng-show="isShowMore" ng-hide="summary.length < 300"> ...more</a>';
            html += "</p>";
            html += '<p ng-hide="largeText">{{ summary }}';
            html +=
                ' <a href="javascript:;" ng-click="showLess(); $event.stopPropagation();" ng-hide="isShowMore">...less </a>';
            html += "</p></div>";
            return {
                restrict: "AE",
                replace: true,
                scope: {
                    summary: "=",
                    limit: "="
                },

                template: html,

                link: function(scope, iElement, iAttrs) {
                    scope.isShowMore = true;
                    scope.largeText = true;
                    var defaultLength = scope.limit;

                    scope.showMore = function() {
                        scope.limit = 99999;
                        scope.isShowMore = false;
                        scope.largeText = false;
                    };

                    scope.showLess = function() {
                        scope.limit = defaultLength;
                        scope.isShowMore = true;
                        scope.largeText = true;
                    };
                }
            };
        }
    ]);
})();

app.service("enterprise", [
    "$http",
    function($http) {
        return {
            getPrograms: getPrograms
        };

        function getPrograms(agencyId) {
            var url = "/API/enterprise/" + agencyId + "/programs";
            return $http.get(url);
        }
    }
]);

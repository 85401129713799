/*
eslint-disable
*/
(function() {
    app.controller("IdeaReviewController", [
        "$scope",
        "$rootScope",
        "$compile",
        "$translate",
        "$http",
        "$filter",
        "$timeout",
        "ngTableParams",
        "skyword",
        "setup",
        "idea",
        "TemplateFactory",
        "assignmentAttachments",
        "$mdDialog",
        "$mdToast",
        "contentTypes",
        "$window",
        "SkyFactory",
        "project",
        "ProjectFactory",
        "calendarEvent",
        "formBuilder",
        function(
            $scope,
            $rootScope,
            $compile,
            $translate,
            $http,
            $filter,
            $timeout,
            ngTableParams,
            skyword,
            setup,
            idea,
            TemplateFactory,
            assignmentAttachments,
            $mdDialog,
            $mdToast,
            contentTypes,
            $window,
            SkyFactory,
            project,
            ProjectFactory,
            calendarEvent,
            formBuilder
        ) {
            // 1.0 - Variables
            var attrs = jQuery("#attrs-id");
            $scope.contentRequestId = attrs.data("contentrequestid");
            $scope.programId = attrs.data("programid");
            $scope.accountTypeId = angular
                .element("#attrs-id")
                .attr("data-accounttypeid");
            $scope.accountId = angular
                .element("#attrs-id")
                .attr("data-accountid");
            $scope.referrerPage = skyword.getQstringParamByName("referrerPage");
            $scope.backToPreviousPage = "";
            // IDs
            $scope.contentTypeId = null;
            $scope.categoryId = null;
            $scope.projectId = null;
            $scope.project = null;
            $scope.projects = [];
            $scope.calendarEventId = null;
            $scope.event = null;
            $scope.events = [];
            $scope.enterpriseCampaignList = [];
            $scope.campaignIdList = [];
            $scope.selectedCampaignList = [];
            $scope.campaignId = null;
            $scope.campaign = null;

            $scope.currentStateId = null;
            // Dates
            $scope.targetDate = null;
            $scope.dateOrderError = null;
            $scope.dateExpired = null;
            $scope.revertDateFlag = null;
            $scope.editProjectFlag = false;
            $scope.editCampaignFlag = false;
            $scope.editEventFlag = false;
            $scope.dates = {
                minYear: moment().year(),
                maxYear: moment().year() + 5,
                today: moment(),
                difference: null
            };
            $scope.confirmation = { show: false };
            $scope.activeSidePanel = "history";

            $scope.showTab = function(whichTab) {
                $scope.activeSidePanel = whichTab;
            };

            var selectionFlag = null;

            $scope.history = [];
            $scope.reviewerList = [];

            $scope.optionToggle = function() {
                selectionFlag = true;
                $timeout(function() {
                    selectionFlag = null;
                }, 100);
            };

            $scope.savedFilters = JSON.parse(
                $window.sessionStorage.getItem("ideaListFilters")
            );

            if (
                $scope.savedFilters != null &&
                $scope.savedFilters.programId != $scope.programId
            ) {
                $window.sessionStorage.removeItem("ideaListFilters");
                $window.sessionStorage.removeItem("plannerList");
                $window.sessionStorage.removeItem("pendingList");
            }
            $scope.sessionLists = {
                plannerList: JSON.parse(
                    $window.sessionStorage.getItem("plannerList")
                ),
                pendingList: JSON.parse(
                    $window.sessionStorage.getItem("pendingList")
                )
            };

            $scope.removeFromSessionList = function(whichList) {
                $scope.sessionLists[whichList] = $scope.sessionLists[
                    whichList
                ].filter(function(obj) {
                    return (
                        obj.contentRequestId !==
                        parseInt($scope.contentRequestId)
                    );
                });
                $window.sessionStorage.setItem(
                    whichList,
                    JSON.stringify($scope.sessionLists[whichList])
                );
            };

            $scope.goToPreviousIdea = function() {
                if ($scope.previousIdeaUrl != "")
                    $window.location.href = $scope.previousIdeaUrl;
            };
            $scope.goToNextIdea = function() {
                if ($scope.nextIdeaUrl != "")
                    $window.location.href = $scope.nextIdeaUrl;
            };

            $scope.whichList = "";
            $scope.initIdeasNav = function() {
                var ideaReviewBaseUrl = "/ideaReview.action?contentRequestId=";
                var plannerListPos =
                    $scope.sessionLists.plannerList != null
                        ? $scope.sessionLists.plannerList
                              .map(function(e) {
                                  return e.contentRequestId;
                              })
                              .indexOf(parseInt($scope.contentRequestId))
                        : -1;
                var pendingListPos =
                    $scope.sessionLists.pendingList != null
                        ? $scope.sessionLists.pendingList
                              .map(function(e) {
                                  return e.contentRequestId;
                              })
                              .indexOf(parseInt($scope.contentRequestId))
                        : -1;
                if (plannerListPos >= 0) {
                    $scope.sessionListCount =
                        $scope.sessionLists.plannerList.length;
                    $scope.sessionListIndex = plannerListPos;
                    $scope.nextIdeaUrl =
                        $scope.sessionLists.plannerList.length >
                        plannerListPos + 1
                            ? ideaReviewBaseUrl +
                              $scope.sessionLists.plannerList[
                                  plannerListPos + 1
                              ].contentRequestId
                            : "";
                    $scope.previousIdeaUrl =
                        plannerListPos > 0
                            ? ideaReviewBaseUrl +
                              $scope.sessionLists.plannerList[
                                  plannerListPos - 1
                              ].contentRequestId
                            : "";
                    $scope.sessionListLabel = $translate.instant(
                        "angular.ideas.viewingideas",
                        { label: "" }
                    );
                    var chosenMonth =
                        $scope.sessionLists.plannerList[plannerListPos]
                            .targetDate !== undefined
                            ? new Date(
                                  $scope.sessionLists.plannerList[
                                      plannerListPos
                                  ].targetDate
                              ).getMonth()
                            : 13;
                    var months;
                    $translate("zz_usedin.js.monthname").then(function(val) {
                        months = val.replace(/[;'\]\[]/gi, "").split(",");
                        if (
                            months.length === 12 &&
                            chosenMonth < months.length
                        ) {
                            var chosenMonthName = months[chosenMonth].trim();
                            $translate("angular.ideas.viewingideas", {
                                label: chosenMonthName
                            }).then(function(txt) {
                                $scope.sessionListLabel = txt;
                            });
                        }
                    });
                    $scope.whichList = "plannerList";
                } else if (pendingListPos >= 0) {
                    $scope.sessionListCount =
                        $scope.sessionLists.pendingList.length;
                    $scope.sessionListIndex = pendingListPos;
                    $scope.nextIdeaUrl =
                        $scope.sessionLists.pendingList.length >
                        pendingListPos + 1
                            ? ideaReviewBaseUrl +
                              $scope.sessionLists.pendingList[
                                  pendingListPos + 1
                              ].contentRequestId
                            : "";
                    $scope.previousIdeaUrl =
                        pendingListPos > 0
                            ? ideaReviewBaseUrl +
                              $scope.sessionLists.pendingList[
                                  pendingListPos - 1
                              ].contentRequestId
                            : "";
                    $scope.whichList = "pendingList";

                    $translate("angular.ideas.state.pending").then(function(
                        val
                    ) {
                        $translate("angular.ideas.viewingideas", {
                            label: val
                        }).then(function(txt) {
                            $scope.sessionListLabel = txt;
                        });
                    });

                    $scope.sessionListLabel = $translate.instant(
                        "angular.ideas.viewingideas",
                        {
                            label: $translate.instant(
                                "angular.ideas.state.pending"
                            )
                        }
                    );
                } else {
                    $scope.sessionListCount = 0;
                    $scope.sessionListIndex = 0;
                    $scope.nextIdeaUrl = "";
                    $scope.previousIdeaUrl = "";
                    $scope.whichList = "";
                    $scope.sessionListLabel = "";
                }
            };
            $scope.initIdeasNav();

            // Sorry. I don't know a better way to handle this. Using this massive condition with optionToggle. I'm CERTAIN there is a better way. - mw
            if ($scope.referrerPage === "projectDetails.action") {
                $scope.backToPreviousPage = "projectDetails";
            } else if ($scope.referrerPage === "calendarEventDetails.action") {
                $scope.backToPreviousPage = "calendarEventDetails";
            } else if ($scope.referrerPage && $scope.referrerPage.includes("reviewQueue")) {
                $scope.backToPreviousPage = "reviewQueue";
            } else {
                $scope.backToPreviousPage = "ideas";
            }

            // Sorry. I don't know a better way to handle this. Using this massive condition with optionToggle. I'm CERTAIN there is a better way. - mw
            $(document).bind("click", function(e) {
                // Changing fields. Run save.
                if ($(e.target).hasClass("edit")) {
                    if ($(".saveInlineElement").parents(".active-item")) {
                        $(".saveInlineElement").click();
                    }
                    selectionFlag = null;
                }

                // if not a select element run the save on click
                if (
                    !$(e.target)
                        .parents()
                        .hasClass("active-item") &&
                    !$(e.target).is(".icon-MessageFailed") &&
                    !$(e.target).is(".ms-selection") &&
                    !$(e.target).is(".ms-elem-selectable") &&
                    !$(e.target).is(".ms-elem-selected") &&
                    !$(e.target).is(".ms-list") &&
                    !$(e.target).is("#selectBoxInlineEditSelectBoxIt") &&
                    !$(e.target).is(".selectboxit-option") &&
                    !$(e.target).hasClass("edit") &&
                    !$(e.target).is("select") &&
                    !$(e.target).is(".selectboxit-arrow") &&
                    !$(e.target).is(".selectboxit-arrow-container") &&
                    !$(e.target).is(".selectboxit-options") &&
                    !$(e.target).is(".selectboxit-list") &&
                    !$(e.target).is("option") &&
                    !$(e.target).is(".selectboxit-text")
                ) {
                    // find the active item and save it
                    if (
                        $(".saveInlineElement").parents(".active-item") &&
                        !selectionFlag
                    ) {
                        $(".saveInlineElement").click();
                    }
                }
            });

            // Menu FAB
            $scope.menuFab = {
                workflow: {},
                menuHidden: false,
                menuIsOpen: false,
                menuHover: false
            };
            // Validation
            $scope.isValidTargetDate = null;
            $scope.isValidAssignment = false;
            $scope.validation = {
                errors: [],
                targetDate: {
                    valid: null,
                    value: null,
                    required: null,
                    enabled: null,
                    errors: []
                },
                valid: true,
                message: null
            };
            // Misc
            $scope.contentRequest = {
                // ContentType.enableContributorPaymentRate
                enableContributorPaymentRate: null,
                // ProgramAccount.uncompensated
                uncompensated: null,
                // cr.customUpfrontPayment AS contentRequestPaymentRate - value set for the Assignment / Idea in the DB
                contentRequestPaymentRate: null,
                // PaymentTerms.upFrontPayment
                contentTypeDefaultPayment: null,
                // contributorPaymentRate is contentTypeAccount.suggestedUpfrontPayment
                contributorPaymentRate: null,
                enableSubmitDeadline: null
            };
            $scope.isFirefox = false;
            $scope.showInlineDetails = false;

            // END 1.0 - Variables

            // 2.0 - GET Requests

            //get projects
            $scope.$watch("programId", function() {
                if (undefined !== $scope.programId) {
                    project.getActiveProjects(
                        $scope.programId,
                        true,
                        false,
                        function(data) {
                            $scope.projects = data;
                            if (
                                $scope.projects.length > 0 &&
                                $scope.projectId == null
                            ) {
                                $scope.projectId = -1;
                            }
                            setTimeout(function() {
                                angular.element("select").selectBoxIt();
                                angular
                                    .element("select")
                                    .selectBoxIt("refresh");
                            }, 50);
                        }
                    );

                    //get events
                    calendarEvent.getAllProgramEvents(
                        $scope.programId,
                        function(data) {
                            data.unshift({
                                eventName: $translate.instant(
                                    "angular.ideas.detail.eventDefault"
                                )
                            });
                            $scope.events = data;
                            setTimeout(function() {
                                try {
                                    angular
                                        .element("#selectEvent")
                                        .selectBoxIt();
                                    angular
                                        .element("select")
                                        .selectBoxIt("refresh");
                                    $("#selectEvent").val(
                                        $scope.calendarEventId
                                    );
                                    angular
                                        .element("select")
                                        .selectBoxIt("refresh");
                                } catch (e) {}
                            }, 300);
                        },
                        function(data) {
                            console.log(data);
                        }
                    );
                }
            });

            $scope.$watch("projectId", function() {
                $scope.project = $.grep($scope.projects, function(e) {
                    return e.id == $scope.projectId;
                })[0];
                $scope.editProjectFlag = false;
            });

            $scope.$watch("projects", function() {
                $scope.project = $.grep($scope.projects, function(e) {
                    return e.id == $scope.projectId;
                })[0];
                $scope.editProjectFlag = false;
            });

            $scope.$watch(
                "[calendarEventId,events]",
                function() {
                    $scope.event = $.grep($scope.events, function(e) {
                        return e.id == $scope.calendarEventId;
                    })[0];
                    $scope.editEventFlag = false;
                },
                true
            );

            $scope.loadIdea = function() {
                idea.getIdea(
                    $scope.programId,
                    $scope.contentRequestId,
                    function(data) {
                        $scope.currentStateId = data.stateId;
                        $scope.contentTypeId = data.contentTypeId;
                        $scope.categoryId = data.categoryId;
                        $scope.contentRequest = data;
                        loadAttachments();
                        $scope.calendarEventId = data.calendarEventId;
                        $scope.projectId =
                            data.projectId != null ? data.projectId : -1;
                        // Set Dates
                        $scope.targetDate = $scope.contentRequest.targetDate;
                        // Assign Required fields
                        // $scope.validateIdea().setRequiredFields();

                        $scope.enterpriseCampaignList =
                            data.enterpriseCampaignList;
                        $scope.campaignIdList = data.campaignIdList;
                        $scope.selectedCampaignList = data.selectedCampaignList;
                        $scope.campaignId = data.campaignId;
                        $scope.campaign = data.campaign;
                        console.log($scope.enterpriseCampaignList);
                        setTimeout(function() {
                            angular
                                .element("#selectCampaignListId")
                                .selectBoxIt();
                            angular
                                .element("#selectCampaignListId")
                                .selectBoxIt("refresh");
                        }, 1500);

                        if (
                            $scope.currentStateId < 60 ||
                            $scope.currentStateId > 64
                        ) {
                            location.reload();
                        }
                        // Run initial validation
                        $scope.validateIdea().checkAll();
                        $scope.validateIdea().showErrors("error");
                    }
                );
            };
            var crUnbind = $scope.$watch("contentRequestId", function() {
                // console.log("content request: " + $scope.contentRequest);
                $scope.loadIdea();
                //assignmentAttachments.setupRemoveAttachment();
                crUnbind();
            });

            $scope.fetchKeywordData = function() {
                idea.getKeywords($scope.programId, $scope.categoryId, function(
                    data
                ) {
                    var myEl = angular.element(
                        document.querySelector(".suggestedKeywordModule")
                    );
                    myEl.prepend(data);
                    $scope.suggestedKeywords = data;
                });
            };

            $scope.fetchWorkflow = function() {
                var workflowPromise = idea.getWorkflow(
                    $scope.programId,
                    $scope.contentRequestId
                );
                workflowPromise.then(function(result) {
                    if ($scope.menuFab.workflow.length > 0) {
                        $("#ideas-buttons").fadeOut();
                        $(".ideaActionPanel").slideUp("slow");
                        $scope.menuFab.workflow = null;
                        $scope.menuFab.workflow = result.data;
                        $(".ideaActionPanel").slideDown();
                        $("#ideas-buttons").fadeIn();
                        setup.followMenuResize();
                    } else {
                        $scope.menuFab.workflow = result.data;
                    }

                    angular.forEach($scope.menuFab.workflow, function(
                        value,
                        key
                    ) {
                        // set the text values for the buttons
                        switch (value.stateId) {
                            case 60:
                                value.buttonText = $translate.instant(
                                    "angular.ideas.menu.returnToPending"
                                );
                                value.tooltip = $translate.instant(
                                    "angular.ideas.menu.returnToPending"
                                );
                                break;
                            case 61:
                                value.buttonText = $translate.instant(
                                    "angular.ideas.menu.draft"
                                );
                                value.tooltip = $translate.instant(
                                    "angular.ideas.menu.draft"
                                );
                                break;
                            case 62:
                                value.buttonText = $translate.instant(
                                    "angular.ideas.menu.sendForReview"
                                );
                                value.tooltip = $translate.instant(
                                    "angular.ideas.menu.sendForReview"
                                );
                                break;
                            case 63:
                                value.buttonText = $translate.instant(
                                    "angular.ideas.menu.approve"
                                );
                                value.tooltip = $translate.instant(
                                    "angular.ideas.menu.approve"
                                );
                                break;
                            case 64:
                                value.buttonText = $translate.instant(
                                    "angular.ideas.menu.decline"
                                );
                                value.tooltip = $translate.instant(
                                    "angular.ideas.menu.decline"
                                );
                                break;
                            case 2:
                                if (value.disabledForEscrow) {
                                    if (
                                        $scope.clickHelpRole === "Program Users"
                                    ) {
                                        value.tooltip = $translate.instant(
                                            "angular.ideas.disabled.escrow"
                                        );
                                    } else {
                                        value.tooltip = $translate.instant(
                                            "angular.ideas.disabled.escrowinternaluser"
                                        );
                                    }
                                } else {
                                    value.tooltip = $translate.instant(
                                        "angular.ideas.menu.makeAssignment"
                                    );
                                }
                                value.buttonText = $translate.instant(
                                    "angular.ideas.menu.makeAssignment"
                                );
                                break;
                        }
                    });
                    $timeout(function() {
                        setup.qtipTooltips();
                    }, 1000);
                });
            };

            $scope.fetchHistory = function() {
                var historyPromise = idea.getHistory(
                    $scope.programId,
                    $scope.contentRequestId
                );
                historyPromise.then(function(result) {
                    if ($scope.history.length > 0) {
                        $scope.history = null;
                        $scope.history = result.data;
                    } else {
                        $scope.history = result.data;
                    }
                });
            };

            $scope.fetchReviewerList = function() {
                var reviewerPromise = idea.getReviewers(
                    $scope.programId,
                    $scope.contentRequestId
                );
                reviewerPromise.then(function(result) {
                    if ($scope.reviewerList.length > 0) {
                        $scope.reviewerList = null;
                        $scope.reviewerList = result.data;
                    } else {
                        $scope.reviewerList = result.data;
                    }
                    if (result.data.length > 0) {
                        $scope.showTab("reviewer-list");
                    }
                });
            };
            $scope.toggleIsReviewed = function() {
                var toggleReviewPromise = idea.toggleReviewed(
                    $scope.programId,
                    $scope.contentRequestId
                );
                toggleReviewPromise.then(
                    function(result) {
                        $scope.fetchReviewerList();
                    },
                    function(error) {}
                );
            };

            $scope.getParams = function(dto) {
                return {
                    contentRequestId: $scope.contentRequestId,
                    programId: $scope.programId,
                    fileExtensions: ".jpg"
                };
            };

            $scope.getUploadUrl = function(dto) {
                return (
                    "/API/upload/attachment/" + dto.contentTemplateElementDataId
                );
            };

            // END 2.0 - GET Requests

            // 3.0 - PUT Requests
            $scope.updateIdea = function(contentElementDTO) {
                if ($scope.validateIdea().checkElement(contentElementDTO)) {
                    idea.saveIdea(contentElementDTO);
                }
            };

            $scope.saveWorkflow = function($event, item) {
                // save the data
                var data = {
                    contentRequestId: $scope.contentRequestId, // Pass content request id
                    stateId: item.stateId // Pass new state
                };
                if (item.stateId == 64) {
                    data.declineReason = $scope.confirmation.comments;
                }
                var saveWorkflowPromise = idea.saveWorkflow(
                    $scope.programId,
                    data
                );
                saveWorkflowPromise.then(function(result) {
                    if (item.stateId == 2) {
                        $window.location.href =
                            "/assignmentModify.action?contentRequestId=" +
                            $scope.contentRequestId;
                    } else if (item.stateId == 64) {
                        $scope.removeFromSessionList($scope.whichList);
                        if ($scope.referrerPage == 'reviewQueue') {
                            $window.location.href = "/reviewQueue";
                        } else if ($scope.nextIdeaUrl != "") {
                            $scope.goToNextIdea();
                        } else {
                            $window.location.href = "/ideas.action";
                        }
                    } else {
                        $scope.currentStateId = item.stateId;
                        $scope.fetchWorkflow();
                        $scope.fetchHistory();
                        $scope.loadIdea();
                        $scope.fetchReviewerList();
                        $scope.showStatus($event, item);
                    }
                });
            };

            $scope.saveTargetDate = function(contentRequest) {
                $scope.contentRequest.targetDate = $scope.targetDate;
                $scope.validateIdea().checkTargetDate();

                var month;
                var year;
                var setDate;

                if ($scope.saveTargetDateFromButton == true) {
                    //if we are saving from button overlay
                    month = $scope.chosenMonthNum;
                    year = $scope.chosenYear;
                } else {
                    month = parseInt(jQuery(".month").val());
                    year = parseInt(jQuery(".year").val());
                }

                // To-Do: Following Needs broken out of this function
                // JQuery patch for combodate/firefox issue. Issue: object doesn't update before save promise.. or just doesn't update the model at all.
                if (!$scope.targetDate || $scope.isFirefox === true) {
                    setDate = month + 1 + "/" + 1 + "/" + year;
                    $scope.targetDate = moment(setDate);
                    // Update Object
                    $scope.contentRequest.targetDate = $scope.targetDate;
                    $scope.isFirefox = true;
                }

                // To-Do: Following Needs broken out of this function
                // Validate
                if (isNaN(month) || isNaN(year)) {
                    $scope.targetDate = null;
                    $scope.contentRequest.targetDate = null;
                }

                // Call Service Method
                if (true === $scope.isValidTargetDate) {
                    var saveTargetDatewPromise = idea.saveTargetDate(
                        $scope.programId,
                        contentRequest
                    );
                    saveTargetDatewPromise.then(function(result) {
                        $scope.currentStateId = result.data.stateId;
                        $scope.fetchWorkflow();
                        $scope.fetchHistory();
                    });
                }
            };

            $scope.updateProject = function(pid) {
                $scope.projectId = pid;

                $http.put(
                    "/API/v2/content-requests/" +
                        $scope.contentRequest.contentRequestId +
                        "/project",
                    {
                        projectId: $scope.projectId
                    }
                );
            };

            $scope.updateCampaign = function(campaign) {
                console.log(campaign);
                if (typeof "number" === campaign) {
                    $scope.campaignIdList = [campaign];
                } else {
                    $scope.campaignIdList = [campaign.id];
                }

                $http
                    .post(
                        "/API/v2/content-requests/" +
                            $scope.contentRequest.contentRequestId +
                            "/campaigns",
                        {
                            campaignIdList: $scope.campaignIdList
                        }
                    )
                    .success(function(data) {
                        $scope.campaign = data.campaign;
                        $scope.hideCampaignEdit();
                    });
            };

            $scope.updateEvent = function(eid) {
                $scope.calendarEventId = eid;
                $http.put(
                    "/API/v2/content-requests/" +
                        $scope.contentRequest.contentRequestId +
                        "/event",
                    {
                        calendarEventId: $scope.calendarEventId
                    }
                );
            };

            // END 3.0 - PUT Requests

            // 4.0 - Validation & Checks
            $scope.validateIdea = function() {
                // 4.0 - Validation & Checks
                // Initialize
                return {
                    checkElement: checkElement,
                    checkAll: function() {
                        $scope.validateIdea().reset();
                        $scope.validateIdea().checkTargetDate();
                    },
                    reset: function() {
                        $scope.validation.errors.length = 0;
                        $scope.validation.targetDate.errors.length = 0;
                    },
                    checkMonthOrder: function(date) {
                        var difference = $scope.dates.today.diff(
                            date,
                            "month",
                            true
                        );
                        // Invalid
                        if (difference > 1) {
                            return false;
                        } else {
                            return true;
                        }
                    },
                    checkTargetDate: function(data) {
                        $scope.validation.targetDate.errors.length = 0;
                        var errors = $scope.validation.targetDate.errors;
                        var errorMessage = null;
                        var isFutureDate = this.checkMonthOrder(
                            $scope.targetDate
                        );

                        // Invalid. Date's in the past.
                        if (isFutureDate == false) {
                            $scope.isValidTargetDate = false;
                            errorMessage = $translate.instant(
                                "angular.ideas.validation.publishFuture"
                            );
                            errors.push(errorMessage);
                        }

                        // Valid. All is well...
                        if (isFutureDate == true) {
                            $scope.isValidTargetDate = true;
                        }
                    },

                    checkWorkflow: function($event, item) {
                        if (item.disabledForEscrow) {
                            var btn = $(
                                '*[data-id="stateBtn-' + item.stateId + '"]'
                            );
                            $timeout(function() {
                                btn.removeProp("disabled");
                            }, 500);
                            return false;
                        }
                        if (item.stateId === 64) {
                            $scope.confirmation.show = true;
                            $scope.confirmation.showCommentBox = true;
                        } else if (item.stateId === 2) {
                            $scope.validateIdea().checkAll();
                            //$scope.showConfirm($event, item);
                            $window.location.href =
                                "/assignmentModify.action?contentRequestId=" +
                                $scope.contentRequestId +
                                "&referrerPage=ideaReview.action";
                        } else {
                            $scope.saveWorkflow($event, item);
                        }
                        if ($scope.confirmation.show) {
                            $("#ideaButtonWrapper").slideUp("slow", function() {
                                $("#confirmWrapper").slideDown();
                                $("#ideaButtonWrapper").css("display", "none");
                                setup.followMenuResize();
                            });
                        }
                    },
                    showErrors: function(type) {
                        var errors = $scope.validation.errors;
                        var output = "";
                        errors.push($scope.validation.targetDate.errors);
                        output += "<ul>";
                        angular.forEach(errors, function(value, key) {
                            if (angular.isArray(value)) {
                                angular.forEach(value, function(v, k) {
                                    output += "<li>" + v + "</li>";
                                });
                            }
                        });
                        output += "</ul>";
                        if (0 === $scope.validation.targetDate.errors.length) {
                            return true;
                        } else {
                            $mdToast.show({
                                position: "bottom left",
                                hideDelay: 8000,
                                template:
                                    '<md-toast class="' +
                                    type +
                                    '">' +
                                    '<div class="md-toast-content errorSize' +
                                    errors.length +
                                    '">' +
                                    output +
                                    "</div>" +
                                    "</md-toast>"
                            });
                            return false;
                        }
                    }
                };

                function checkElement(contentElementDTO) {
                    var valid = true;

                    if (true === contentElementDTO.required) {
                        if (SkyFactory.notExists(contentElementDTO.value)) {
                            valid = false;
                        } else {
                            if (
                                null === contentElementDTO.minLength &&
                                null === contentElementDTO.maxLength
                            ) {
                                valid = true;
                            } else if (
                                (null != contentElementDTO.minLength &&
                                    contentElementDTO.minLength >
                                        contentElementDTO.value.length) ||
                                (null != contentElementDTO.maxLength &&
                                    contentElementDTO.maxLength <
                                        contentElementDTO.value.length)
                            ) {
                                valid = false;
                            }
                        }
                    }
                    contentElementDTO.error = false === valid;
                    return valid;
                }
            };
            // END 4.0 - Validation & Checks

            $scope.showProjectEdit = function() {
                $scope.editProjectFlag = true;
            };
            $scope.hideProjectEdit = function() {
                $scope.editProjectFlag = false;
            };

            $scope.showCampaignEdit = function() {
                $scope.editCampaignFlag = true;
            };
            $scope.hideCampaignEdit = function() {
                $scope.editCampaignFlag = false;
            };

            $scope.showEventEdit = function() {
                $scope.editEventFlag = true;
            };
            $scope.hideEventEdit = function() {
                $scope.editEventFlag = false;
            };

            $scope.setTargetDate = function() {
                if (null === $scope.targetDate) {
                    $scope.targetDate = new Date();
                }
                $scope.selectYearDesc();
            };

            $scope.selectYearDesc = function() {
                $timeout(function() {
                    //$(".combodate select").wrap("<div class='select-style'></div>");
                    var select = $(".combodate select.year");

                    // Reverse Years
                    select.find("option").each(function() {
                        jQuery(this).prependTo(select);
                    });
                    // Put "Year" option back at the top
                    select.find('option[value=""]').prependTo(select);
                }, 100);
            };

            // Triggered when user opens inline editing & forms are visible
            $scope.initInlineEdit = function() {
                $scope.showInlineDetails = true;
            };

            // Triggered when user clicks outside of the form or clicks the cancel button.
            $scope.cancelInlineEdit = function() {
                $scope.showInlineDetails = false;
            };

            // TO-DO: Consolidate with other mdToast
            $scope.showStatus = function($event, item) {
                $scope.statusMessage = $translate.instant(
                    "angular.ideas.menu.status"
                );
                $scope.status =
                    $scope.statusMessage + ' "' + item.stateName + '"';
                if (item.stateId == 2) {
                    $timeout(function() {
                        // $window.location.href = '/ideas.action';
                        $window.location.href =
                            "/assignmentModify.action?contentRequestId=" +
                            $scope.contentRequestId;
                    }, 2000);
                } else {
                    $mdToast.show(
                        $mdToast
                            .simple()
                            .content($scope.status)
                            .hideDelay(3000)
                            .position("bottom right")
                    );
                }
            };
            $scope.showConfirm = function($event, item) {
                // Make Assignment
                if (item.stateId == 2) {
                    // not relevant anymore
                    //$scope.modalConfirm = $translate.instant('angular.ideas.menu.assignment.modalConfirm');
                    //$scope.modalConfirmButton = $translate.instant('angular.ideas.menu.assignment.modalConfirmButton');
                }
                // Remove Idea
                else {
                    $scope.modalConfirm = $translate.instant(
                        "angular.ideas.menu.modalConfirm"
                    );
                    $scope.modalConfirmButton = $translate.instant(
                        "angular.ideas.menu.modalConfirmButton"
                    );
                }
                $scope.modalCancel = $translate.instant(
                    "angular.ideas.menu.modalCancel"
                );

                var confirm = $mdDialog
                    .confirm({
                        // Style / Position Adjustments
                        onComplete: function afterShowAnimation() {
                            // Adding some styling
                            var $dialog = angular.element(
                                document.querySelector("md-dialog")
                            );
                            var $actionsSection = $dialog.find(
                                "md-dialog-actions"
                            );
                            var $confirmButton = $actionsSection.children()[1];
                            angular
                                .element($confirmButton)
                                .prependTo($actionsSection);
                            angular
                                .element($confirmButton)
                                .addClass("md-raised md-primary");
                        }
                    })
                    // Idea attributes
                    .clickOutsideToClose(true)
                    .title($scope.modalConfirm)
                    .targetEvent($event)
                    .ok($scope.modalConfirmButton)
                    .cancel($scope.modalCancel);
                // On Success / Confirm
                $mdDialog.show(confirm).then(
                    function() {
                        if (item.stateId == 2) {
                            //$window.location.href = '/assignmentModify.action?contentRequestId=' + $scope.contentRequestId;
                        } else {
                            $scope.saveWorkflow($event, item);
                        }
                        // On Close / Cancel
                    },
                    function() {
                        $scope.status = null;
                        $scope.fetchWorkflow();
                    }
                );
            };

            $scope.cancelConfirm = function() {
                $scope.confirmation.show = false;
                $("#confirmWrapper").slideUp("slow", function() {
                    skyword.enableButtons();
                    $("#ideaButtonWrapper").slideDown();
                    setup.followMenuResize();
                });
            };

            $scope.$watch("menuFab.menuIsOpen", function(isOpen) {
                if (isOpen) {
                    $timeout(function() {
                        $scope.tooltipVisible = false;
                    }, 600);
                } else {
                    $scope.tooltipVisible = $scope.menuIsOpen;
                }
            });

            $scope.showAttachment = function(dto) {
                return 15 === dto.typeId && null !== dto.attachmentId;
            };

            $scope.showUploader = function(dto) {
                return 15 === dto.typeId && null === dto.attachmentId;
            };

            $scope.showTextField = function(dto) {
                return (
                    dto.typeId === 23 || dto.typeId === 10 || dto.typeId === 2
                );
            };

            function loadAttachments() {
                var list = $scope.contentRequest.contentElementList;

                list.forEach(loadAttachment);

                function loadAttachment(dto) {
                    if (15 === dto.typeId && null !== dto.attachmentId) {
                        var promise = TemplateFactory.getIdeaAttachment(
                            dto.attachmentId,
                            dto.contentTemplateElementDataId
                        );
                        promise.then(function(html) {
                            var attachmentContainer = jQuery(
                                ".attachment-" + dto.attachmentId
                            );
                            attachmentContainer.find(".fileUploadSpan").hide();
                            attachmentContainer
                                .find(">.subContainer")
                                .append(html.data);
                        });
                    }
                }
            }

            // END 5.0 - Visibility & View Related

            // Call to render xeditable select boxes
            jQuery(document).on(
                "click",
                "span.edit, p.dateDisplay, #targetDate",
                function(event) {
                    //call to render selectbox for xeditable fields
                    angular.element("select.month").selectBoxIt();
                    angular.element("select.day").selectBoxIt();
                    angular.element("select.year").selectBoxIt();
                    angular.element("select.hour").selectBoxIt();
                    angular.element("select.minute").selectBoxIt();
                    angular.element("select.ampm").selectBoxIt();
                }
            );

            //calling colorbox for removing media attachment
            jQuery(document).on("click", ".removeAttachment", function() {
                jQuery.colorbox({
                    height: 150,
                    width: 450,
                    href: "/partials/directives/remove-attachment.html",
                    onComplete: function() {
                        //items needed for removing attachment
                        $scope.attachmentId = angular
                            .element("#attachment-id")
                            .attr("value");
                        $scope.fileName = angular
                            .element("li.fileInfo > span.url > a")
                            .html();
                        $scope.contentTemplateElementId = angular
                            .element("#content-template-element-id")
                            .attr("value");
                        $scope.contentTemplateElementDataId = jQuery(
                            ".attachmentContainer"
                        ).data("cted");

                        $rootScope.$apply(function() {
                            var content = jQuery("#cboxLoadedContent");
                            $compile(content)($scope);
                        });

                        $rootScope.$broadcast("scopeData", $scope);
                    }
                });
            });

            // END Attachments

            function sticky_ideaActionPanel() {
                try {
                    var window_top = $(window).scrollTop(),
                        ideaPanelAnchor = $("#ideaActionPanelAnchor"),
                        div_top =
                            0 < ideaPanelAnchor.length
                                ? ideaPanelAnchor.offset().top
                                : 0;
                    if (window_top > div_top) {
                        $("#idea-action-panel").addClass(
                            "ideaActionPanelFixed"
                        );
                    } else {
                        $("#idea-action-panel").removeClass(
                            "ideaActionPanelFixed"
                        );
                    }
                    //console.log('sticky panel....');
                } catch (e) {
                    console.error(e);
                }
            } // end of sticky_ideaActionPanel

            function ideaActionPanelResize() {
                try {
                    var ideaActionWindowHeight = $(".ideaActionPanel").height(),
                        windowHeight = $(window).height(),
                        documentHeight = $(document).height(),
                        footerHeight = $("footer").height(),
                        window_top = $(window).scrollTop(),
                        ideaHistoryHeight =
                            windowHeight -
                            ideaActionWindowHeight -
                            footerHeight -
                            220;

                    $(".ideaHistory").css("max-height", ideaHistoryHeight);
                } catch (e) {
                    console.error(e);
                }
            } // end of ideaActionPanelResize

            $(function() {
                $(window).scroll(sticky_ideaActionPanel);
                sticky_ideaActionPanel();
                $(window).scroll(ideaActionPanelResize);
                ideaActionPanelResize();
            });

            /* MONTH PICKER */

            $scope.thisMonth = new Date().getMonth() === 0 ? 12 : new Date().getMonth();
            $scope.thisYear = new Date().getMonth() === 0 ? new Date().getFullYear()-1 : new Date().getFullYear();
            $scope.chosenMonth = new Date().getMonth();
            $scope.chosenYear = new Date().getFullYear();
            $scope.saveTargetDateFromButton = false;

            $scope.toggleMonthPicker = function() {
                $("#monthpicker").toggle();
            };

            $scope.saveMonthPicker = function() {
                // console.log($scope.chosenMonth + " " + $scope.chosenYear);
                $("#monthpicker").hide();
                $("#monthPickerButton").removeAttr("disabled");
                $("#monthPickerButton").removeClass("disabled");
                $("#monthPickerEditButton").removeAttr("disabled");
                $("#monthPickerEditButton").removeClass("disabled");
                $("#monthpicker button").removeClass("disabled");
                if ($scope.currentStateId == 60) {
                    $("#monthpicker button").removeAttr("disabled");
                }

                //find month as number
                for (var i = 0; i < $scope.months.length; i++) {
                    if ($scope.months[i] == $scope.chosenMonth) {
                        $scope.chosenMonthNum = i;
                    }
                }
                //create new date with month and year
                //var d = new Date($scope.chosenYear,$scope.chosenMonthNum);
                var d = moment()
                    .month($scope.chosenMonthNum)
                    .year($scope.chosenYear)
                    .format();
                // console.log("DATE: " + d);
                $scope.targetDate = d;
                //save new target date
                $scope.saveTargetDateFromButton = true;
                $scope.saveTargetDate($scope.contentRequest);
            };

            $scope.closeMonthPicker = function() {
                $("#monthpicker").hide();
                $("#monthPickerButton").removeAttr("disabled");
                $("#monthPickerButton").removeClass("disabled");
                $("#monthPickerEditButton").removeAttr("disabled");
                $("#monthPickerEditButton").removeClass("disabled");
                $("#monthpicker button").removeClass("disabled");
                if ($scope.currentStateId == 60) {
                    $("#monthpicker button").removeAttr("disabled");
                }
                $scope.saveTargetDateFromButton = false;
            };

            $scope.setup = function() {
                var i;

                $scope.prevMonth = moment($scope.targetDate).format("MMM");
                $scope.prevYear = moment($scope.targetDate).year();

                $scope.yearArray = [];
                for (i = 0; i < 5; i++) {
                    $scope.yearArray[i] = $scope.thisYear + i;
                    // console.log($scope.yearArray[i]);
                }

                $scope.months = [];
                $translate("zz_usedin.js.monthnameshort").then(function(val) {
                    $scope.months = val.replace(/[;'\]\[]/gi, "").split(",");
                });

                if ($scope.currentStateId == 60) {
                    $("#monthpicker button").removeAttr("disabled");
                }
                $("#monthpicker button").removeClass("disabled");
            };

            $scope.previousYear = function(chosenYear) {
                if (chosenYear == $scope.thisYear) {
                    //do nothing
                } else {
                    $scope.chosenYear = chosenYear - 1;
                }
            };

            $scope.nextYear = function(chosenYear) {
                $scope.chosenYear = chosenYear + 1;
            };

            $scope.chooseMonth = function(index) {
                // console.log(index);
                $scope.chosenMonth = index;

                //once they choose month, save
                $scope.saveMonthPicker();
            };

            $scope.typeOf = function(v) {
                return typeof v;
            };
        }
    ]).controller("ideaReview", ["$scope", function($scope) {}]);
})();

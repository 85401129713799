app.service("crop", [
    "validation",
    function(validation) {
        var jcropObj,
            cropInterval,
            ranAlready = false,
            init = function() {
                $(function() {
                    var release = null,
                        img = $("#image-crop"),
                        width = "",
                        height = "",
                        imgWidth = img.width(),
                        imgHeight = img.height();
                    var restrictSize =
                        $("#restrict-image-size").val() === "true";
                    var defaultSize = 1000;
                    var maxHeight,
                        maxWidth,
                        disableArrows,
                        aspectRatioX,
                        aspectRatioY;
                    var aspectRatio,
                        min = [0, 0],
                        max,
                        allowResize;
                    $("#btn-crop-overlay, #btn-crop-template-overlay")
                        .removeAttr("disabled")
                        .removeClass("disabled");
                    var imageCropType = $("#cropStatus").val();
                    /*
                    //CROPPPING LOGGING
                    console.log("CROP STATUS: " + $("#cropStatus").val());
                    console.log(
                        "ASPECT RATIO X: " + $("#aspect-ratio-x").val()
                    );
                    console.log(
                        "ASPECT RATIO Y: " + $("#aspect-ratio-y").val()
                    );
                    console.log("MIN WIDTH: " + $("#image-width").val());
                    console.log("MIN HEIGHT: " + $("#image-height").val());
                    console.log("MAX WIDTH: " + $("#image-max-width").val());
                    console.log("MAX HEIGHT: " + $("#image-max-height").val());
                    console.log(
                        "image-alter-ratio: " + $("#image-alter-ratio").val()
                    );
                    console.log(
                        "image-alter-ratio-value: " +
                            $("#image-alter-ratio-value").val()
                    );
                    console.log(
                        "image-alter-ratio-value-orig: " +
                            $("#image-alter-ratio-value-orig").val()
                    );
                    */
                    // test for which radio button was selected in admin
                    if (!imageCropType || imageCropType == 1) {
                        // no crop restrictions
                        release = clearCoords;
                    } else {
                        //options 2, 3, or 4
                        //grab values
                        width = $("#image-width").val();
                        height = $("#image-height").val();
                        maxWidth = $("#image-max-width").val();
                        maxHeight = $("#image-max-height").val();
                        aspectRatioX = $("#aspect-ratio-x").val();
                        aspectRatioY = $("#aspect-ratio-y").val();

                        if (imageCropType == 2) {
                            //minimum and fixed ratio
                            min = [width, height];
                            max = [imgWidth, imgHeight];
                            aspectRatio = width / height;
                            allowResize = true; // ask ariana
                        } else if (imageCropType == 3) {
                            //min max width height
                            if (width && height && (!maxWidth && !maxHeight)) {
                                min = [width, height];
                                max = [imgWidth, imgHeight];
                                aspectRatio = 0;
                                disableArrows = true;
                            } else if (
                                maxWidth &&
                                maxHeight &&
                                (!width && !height)
                            ) {
                                min = [1, 1];
                                width = "";
                                height = "";
                                max = [imgWidth, imgHeight];
                                aspectRatio = 0;
                                disableArrows = true;
                            } else {
                                //min and max set
                                min = [width, height];
                                max = [imgWidth, imgHeight];
                                aspectRatio = 0;
                                disableArrows = true;
                            }
                        } else if (
                            imageCropType == 4 &&
                            (aspectRatioY && aspectRatioX)
                        ) {
                            if (width && height && (!maxWidth && !maxHeight)) {
                                //minimum and fixed ratio
                                min = [width, height];
                                max = [imgWidth, imgHeight];
                                aspectRatio = aspectRatioX / aspectRatioY;
                                allowResize = true; // ask ariana
                            } else if (
                                maxWidth &&
                                maxHeight &&
                                (!width && !height)
                            ) {
                                width = "";
                                height = "";
                                if (maxWidth <= 1000 || maxHeight <= 1000) {
                                    max = [maxWidth, maxHeight];
                                } else {
                                    max = [imgWidth, imgHeight];
                                }
                                aspectRatio = aspectRatioX / aspectRatioY;
                                allowResize = true; // ask ariana
                            } else if (
                                maxWidth &&
                                maxHeight &&
                                width &&
                                height
                            ) {
                                min = [width, height];
                                if (maxWidth <= 1000 || maxHeight <= 1000) {
                                    max = [maxWidth, maxHeight];
                                } else {
                                    max = [imgWidth, imgHeight];
                                }
                                aspectRatio = aspectRatioX / aspectRatioY;
                                allowResize = true; // ask ariana
                            } else if (
                                !maxWidth &&
                                !maxHeight &&
                                !width &&
                                !height
                            ) {
                                width = "";
                                height = "";
                                max = [imgWidth, imgHeight];
                                aspectRatio = aspectRatioX / aspectRatioY;
                                allowResize = true; // ask ariana
                            }
                        }
                    }

                    var cropBoxWidth = $("#crop-box").width();
                    if (defaultSize > cropBoxWidth) {
                        defaultSize = cropBoxWidth;
                    }

                    if (defaultSize < imgWidth) {
                        img.width(defaultSize);
                    }

                    imgWidth = img.width();
                    imgHeight = img.height();
                    if (height > imgHeight) {
                        img.height(height);
                        imgWidth = img.width();
                        imgHeight = img.height();
                    }

                    if (width == "") {
                        width = imgWidth;
                    }
                    if (height == "") {
                        height = imgHeight;
                    }

                    if (
                        imageCropType == 2 ||
                        imageCropType == 3 ||
                        imageCropType == 4
                    ) {
                        jcropObj = jcropObj = $.Jcrop("#image-crop");
                        jcropObj.setOptions({
                            onChange: updateCoords,
                            onSelect: updateCoords,
                            onRelease: release,
                            disableArrows: disableArrows,
                            setSelect: [
                                imgWidth / 2 - width / 2,
                                imgHeight / 2 - height / 2,
                                imgWidth / 2 + width / 2,
                                imgHeight / 2 + height / 2
                            ],
                            allowSelect: false,
                            minSize: min,
                            maxSize: max,
                            aspectRatio: aspectRatio,
                            allowResize: allowResize
                        });
                    } else {
                        jcropObj = jcropObj = $.Jcrop("#image-crop");
                        jcropObj.setOptions({
                            onChange: updateCoords,
                            onSelect: updateCoords,
                            setSelect: [
                                imgWidth / 2 - width / 2,
                                imgHeight / 2 - height / 2,
                                imgWidth / 2 + width / 2,
                                imgHeight / 2 + height / 2
                            ],
                            onRelease: release,
                            allowSelect: false
                        });
                    }
                });
            },
            jcropTarget = function(id) {
                return function(c) {
                    updateCoordsMult(id, c);
                };
            },
            jcropClear = function(id) {
                return function(c) {
                    clearCoordsMult(id, c);
                };
            },
            // Simple event handler, called from onChange and onSelect
            // event handlers, as per the Jcrop invocation above
            updateCoords = function(c) {
                $("#x1").val(c.x);
                $("#y1").val(c.y);
                $("#x2").val(c.x2);
                $("#y2").val(c.y2);
                $("#w").val(c.w);
                $("#h").val(c.h);
            },
            updateCoordsMult = function(id, c) {
                $("." + id + " .x1").val(c.x);
                $("." + id + " .y1").val(c.y);
                $("." + id + " .x2").val(c.x2);
                $("." + id + " .y2").val(c.y2);
            },
            clearCoordsMult = function(id, c) {
                $("." + id + " input").val("");
            },
            clearCoords = function() {
                $(".coords input").val("");
            },
            Settings = function(
                t,
                imgWidth,
                imgHeight,
                width,
                height,
                maxWidth,
                maxHeight,
                imgId
            ) {
                var x1, x2, y1, y2;
                var noMin = false;
                if (width == null) {
                    width = imgWidth;
                    noMin = true;
                }
                if (height == null) {
                    height = imgHeight;
                    noMin = true;
                }
                if (isNaN(maxWidth) && isNaN(maxHeight)) {
                    aspectRatio = width / height;
                    max = [0, 0];
                } else {
                    max = [maxWidth, maxHeight];
                    aspectRatio = 0;
                }
                if (
                    null != $("#prev" + t.id + " .cropX1").val() &&
                    imgId == $(".cropId").val()
                ) {
                    x1 = $("#prev" + t.id + " .cropX1").val();
                    x2 = $("#prev" + t.id + " .cropX2").val();
                    y1 = $("#prev" + t.id + " .cropY1").val();
                    y2 = $("#prev" + t.id + " .cropY2").val();
                } else {
                    x1 = imgWidth / 2 - width / 2;
                    x2 = imgWidth / 2 + width / 2;
                    y1 = imgHeight / 2 - height / 2;
                    y2 = imgHeight / 2 + height / 2;
                }
                if (noMin == true) {
                    width = 0;
                    height = 0;
                }
                return {
                    onChange: jcropTarget(t.id),
                    onSelect: jcropTarget(t.id),
                    allowSelect: false,
                    setSelect: [x1, y1, x2, y2],
                    aspectRatio: aspectRatio,
                    minSize: [width, height],
                    maxSize: max
                };
            },
            check = function() {
                var $this = this;
                if ($("#image-crop").height() > 0) {
                    if ($("#prepare-container").length > 0) {
                        $("#crop-container").css({
                            visibility: "visible",
                            display: "block"
                        });
                        $("#prepare-container").hide();
                        window.setTimeout("$(this).colorbox.resize();", 500);
                    }
                    $("#image-loaded").val(true);
                    init();
                    window.clearInterval(cropInterval);
                    validateContinue();
                }
            },
            validateContinue = function() {
                var attachmentId = parseInt($("#cropAttachmentId").val(), 10),
                    title = $("#imgTitle").val(),
                    altTitle = $("#altTitle").val(),
                    imageLoaded = $("#image-loaded").val();
                if (
                    !isNaN(attachmentId) &&
                    "" !== title &&
                    "" !== altTitle &&
                    imageLoaded
                ) {
                    $("#btn-upload-continue")
                        .removeAttr("disabled")
                        .removeClass("disabled");
                    $("#btn-insert-image")
                        .removeAttr("disabled")
                        .removeClass("disabled");
                } else {
                    $("#btn-upload-continue")
                        .attr("disabled", "disabled")
                        .addClass("disabled");
                    $("#btn-insert-image")
                        .attr("disabled", "disabled")
                        .addClass("disabled");
                }
            };

        return {
            check: function() {
                cropInterval = window.setInterval(function() {
                    check();
                }, 500);
            },

            init: function() {
                init();
            }
        };
    }
]);

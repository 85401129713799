(function() {
    app.service("revision", [
        "skyword",
        "call",
        "overlay",
        "TemplateFactory",
        "$http",
        "SkyFactory",
        function(skyword, call, overlay, TemplateFactory, $http, SkyFactory) {
            var currentAction = $("#attrs-id").data("actionname");
            var revisionMain = {};
            return {
                init: function() {
                    //onReady
                    $(function() {
                        // START: this is a work around for CORE-1435
                        // basically just forcing a click after load
                        // no need to force a click if the compare versions is all ready there.
                        if (0 === $("#compare-revision").length) {
                            // trigger click on last revision
                            $(
                                "#primary-revision .dropdownList li:nth-child(2) a"
                            ).trigger("click");
                        }
                        // END fix for CORE 1435

                        var body = $("body");
                        body.on(
                            "click",
                            ".arrowDropdown .selection",
                            function() {
                                $(this)
                                    .parent()
                                    .find(".dropdownList")
                                    .slideToggle();
                            }
                        );
                        body.on(
                            "click",
                            "#compare-versions-columns #primary-revision > .content, #compare-versions-columns #compare-revision > .content, h1",
                            function() {
                                $(".dropdownList").slideUp();
                            }
                        );
                        body.on("click", ".dropdownList a", function() {
                            var a = $(this),
                                form = $("#compare-revision-form"),
                                urlAction = form.attr("action"),
                                whatAreYouComparingId = a
                                    .closest(".revision-id-name")
                                    .attr("id");
                            if (a.hasClass("primary")) {
                                $("#primary-revision-id").val(a.attr("id"));
                                $("#compare-revision-id").val(-1);
                            } else if (a.hasClass("compare")) {
                                $("#compare-revision-id").val(a.attr("id"));
                            }
                            var options = {
                                cache: false,
                                type: "POST",
                                url: urlAction,
                                data: form.serialize(),
                                dataType: "html",
                                success: function(data) {
                                    var primaryRev = $(data).find(
                                        "#compare-versions-columns"
                                    );
                                    $("#compare-versions-columns").html(
                                        primaryRev.html()
                                    );
                                },
                                complete: function() {
                                    var container = a;
                                    var isPrimary = a.hasClass("primary");
                                    initContainers();
                                    // loadContainerElements(container, null, null, isPrimary);
                                    TemplateFactory.loadAttachmentInfoRevision(
                                        "primary-revision"
                                    );
                                    TemplateFactory.loadAttachmentInfoRevision(
                                        whatAreYouComparingId
                                    );
                                }
                            };
                            call.skyjax(urlAction, options);

                            return false;
                        });
                        body.on(
                            "click",
                            ".arrowDropdown .icon-prevArrow",
                            function() {
                                if (!$(this).hasClass("disabled")) {
                                    $(this)
                                        .closest(".arrowDropdown")
                                        .find(".selected")
                                        .prev()
                                        .find("a")
                                        .click();
                                }
                            }
                        );
                        body.on(
                            "click",
                            ".arrowDropdown .icon-nextArrow",
                            function() {
                                if (!$(this).hasClass("disabled")) {
                                    $(this)
                                        .closest(".arrowDropdown")
                                        .find(".selected")
                                        .next()
                                        .find("a")
                                        .click();
                                }
                            }
                        );
                        function loadContainerElements(
                            container,
                            newElement,
                            newContainer,
                            isPrimary
                        ) {
                            // console.info(container);
                            var whichSide = container.closest(
                                    ".revision-id-name"
                                ),
                                whichSideId = whichSide.attr("id");
                            var containerId = container.attr("id"),
                                rowStatusId = parseInt(
                                    containerId.replace("container-", ""),
                                    10
                                ),
                                articleElement = container.find(
                                    ">div.articleElement"
                                ),
                                contentTemplateElementDataId = articleElement.data(
                                    "cted"
                                ),
                                contentTemplateElementId = articleElement.data(
                                    "cte"
                                ),
                                contentTemplateElementTypeId = articleElement.data(
                                    "ctet"
                                ),
                                contentId = $("#contentId").val(),
                                options,
                                opts,
                                urlAction =
                                    "loadContainerElementsRevisionAjax.action",
                                preview =
                                    "preview.action" ===
                                    window.location.pathname,
                                showForm = true,
                                deliverPage = false,
                                refPage = window.location.pathname;
                            var appendToThisElement = whichSide.find(
                                'div.articleElement[data-cted="' +
                                    contentTemplateElementDataId +
                                    '"]'
                            );
                            showForm = false;
                            preview = false;
                            templateIdx = templateIdx + 100;
                            opts = {
                                idx: templateIdx,
                                contentRequestId: $("#contentRequestId").val(),
                                contentTemplateId: $(
                                    "#content-template-id"
                                ).val(),
                                parentContainerElementId: contentTemplateElementId,
                                parentContainerElementDataId: contentTemplateElementDataId,
                                contentTemplateElementTypeId: contentTemplateElementTypeId,
                                primaryRevisionId: $(
                                    "#primary-revision-id"
                                ).val(),
                                compareRevisionId: $(
                                    "#compare-revision-id"
                                ).val(),
                                isPrimary: isPrimary,
                                deliverPage: deliverPage,
                                rowStatusId: rowStatusId,
                                contentId: contentId,
                                newContainer:
                                    null === newContainer
                                        ? false
                                        : newContainer, // ensures that elements are required within a container
                                previewMode: preview,
                                showForm: showForm,
                                referrerPage: refPage,
                                revisionsPage: true,
                                referrerAction: "compareRevisions"
                            };

                            options = {
                                cache: false,
                                type: "POST",
                                data: opts,
                                dataType: "html",
                                url: urlAction,
                                success: function(html) {
                                    var containerLi = $(html),
                                        loop,
                                        hasAttachment;
                                    appendToThisElement.append(containerLi);
                                    var checkThisContainerId =
                                        "#" +
                                        whichSideId +
                                        " div.container" +
                                        contentTemplateElementDataId;
                                    var blah = jQuery(checkThisContainerId);
                                    // console.log(appendToThisElement);
                                    // console.log(blah);

                                    hasAttachment =
                                        0 <
                                        containerLi.find("div.isAttachment")
                                            .length;

                                    /**
                                     * If more containers are found, then recurs through each level.
                                     */
                                    containerLi
                                        .find(
                                            "div.liContainer>div.loop>div.divContainer.isEmpty"
                                        )
                                        .each(function() {
                                            var subContainer = $(this);
                                            loadContainerElements(
                                                subContainer,
                                                newElement,
                                                newContainer,
                                                isPrimary
                                            );
                                        });
                                    // Flag if loaded.
                                    container
                                        .removeClass("isEmpty")
                                        .addClass("isNotEmpty");
                                    overlay.init();
                                    setupParentChildElements();
                                    if (
                                        "primary-revision" === whichSideId &&
                                        hasAttachment
                                    ) {
                                        TemplateFactory.loaded.push(
                                            "div.container" +
                                                contentTemplateElementDataId
                                        );
                                        TemplateFactory.loadAttachmentInfoRevision(
                                            "primary-revision",
                                            blah.find("div.isAttachment")
                                        );
                                    } else if (
                                        "compare-revision" === whichSideId &&
                                        hasAttachment
                                    ) {
                                        TemplateFactory.loaded.push(
                                            "div.container" +
                                                contentTemplateElementDataId
                                        );
                                        TemplateFactory.loadAttachmentInfoRevision(
                                            "compare-revision",
                                            blah.find("div.isAttachment")
                                        );
                                    }
                                },
                                complete: function() {
                                    resizeElements();
                                }
                            };

                            //contentTemplateElementId
                            if (
                                !SkyFactory.reallyEmptyCheck(
                                    contentTemplateElementId
                                )
                            ) {
                                call.ajax(urlAction, options);
                            }
                        }

                        function setupParentChildElements() {
                            var showHideChildren = function() {
                                var li = $(this),
                                    contentTemplateElementData = li.find(
                                        "input.contentTemplateElementDataId.isParentElement"
                                    ),
                                    templateElement = contentTemplateElementData.siblings(
                                        ".templateElement"
                                    ),
                                    inputValue = "",
                                    attachmentContainer = contentTemplateElementData.siblings(
                                        ".attachmentContainer"
                                    ),
                                    attDiv = attachmentContainer.find(
                                        ">div.attachment"
                                    ),
                                    contentTemplateElementId = contentTemplateElementData
                                        .siblings(".contentTemplateElementId")
                                        .val(),
                                    childOf =
                                        ".childOf_" + contentTemplateElementId;
                                if (
                                    templateElement.hasClass("templateCheckbox")
                                ) {
                                    inputValue = templateElement.is(":checked");
                                } else {
                                    inputValue = templateElement.val();
                                }
                                if ("" === inputValue && 0 === attDiv.length) {
                                    li.siblings(childOf).each(function() {
                                        var l = $(this),
                                            inputRequired = l.find(
                                                "input.elementRequired"
                                            );
                                        inputRequired.val(false);
                                        l.hide();
                                    });
                                }
                            };
                            $("li.isParentElement.liNotRequired").each(
                                showHideChildren
                            );
                        }

                        var templateIdx = parseInt(
                            $(".theIdx")
                                .last()
                                .val()
                        );

                        function resizeElements() {
                            setTimeout(function() {
                                $(".revisionElement").css("min-height", "");
                                $(".revisionElement").each(function() {
                                    var id = $(this).attr("id");
                                    var height = $("#" + id).height();
                                    if (
                                        height <
                                        $("#comparison-element-" + id).height()
                                    ) {
                                        height = $(
                                            "#comparison-element-" + id
                                        ).height();
                                    }
                                    $("#" + id).css("min-height", height);
                                    $("#comparison-element-" + id).css(
                                        "min-height",
                                        height
                                    );
                                });
                            }, 1000);
                        }

                        function initContainers() {
                            var primaryContainers = $(
                                "#primary-revision div.divContainer.isEmpty"
                            );
                            var compareContainers = $(
                                "#compare-revision div.divContainer.isEmpty"
                            );
                            primaryContainers.each(function() {
                                var container = $(this);
                                loadContainerElements(
                                    container,
                                    null,
                                    null,
                                    true
                                );
                            });

                            compareContainers.each(function() {
                                var compare = $(this);
                                loadContainerElements(
                                    compare,
                                    null,
                                    null,
                                    false
                                );
                            });
                            $(".dropdownList").each(function() {
                                var selected = $(this).find(".selected");
                                if (
                                    selected.is(":first-child") ||
                                    selected.prev().hasClass("disabled")
                                ) {
                                    $(this)
                                        .closest(".arrowDropdown")
                                        .find(".icon-prevArrow")
                                        .addClass("disabled");
                                }
                                if (
                                    selected.is(":last-child") ||
                                    selected.next().hasClass("disabled")
                                ) {
                                    $(this)
                                        .closest(".arrowDropdown")
                                        .find(".icon-nextArrow")
                                        .addClass("disabled");
                                }
                            });
                            resizeElements();
                        }

                        if (0 < jQuery("form#compareRevisions").length) {
                            // console.info("Initializing Revision Containers...");
                            initContainers();
                            TemplateFactory.loadAttachmentInfoRevision(
                                "primary-revision"
                            );
                            TemplateFactory.loadAttachmentInfoRevision(
                                "compare-revision"
                            );
                        }

                        $("img").load(function() {
                            resizeElements();
                        });
                        window.addEventListener("resize", function(event) {
                            resizeElements();
                        });
                    }); // end document.ready()
                } // end init
            };
        }
    ]);
})();

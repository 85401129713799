app.factory("httpRequestInterceptor", function($q, $location) {
    return {
        responseError: function(rejection) {
            // do something on error
            if (rejection.status === 403) {
                $location.path("/sorry");
            }
            return $q.reject(rejection);
        }
    };
});

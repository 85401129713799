app.service("qualityRating", [
    "call",
    "$translate",
    function(call, $translate) {
        // var storedStarRating;
        var storedStarRatingBrand;
        var storedStarRatingSubjectDepth;
        var storedStarRatingMechanics;

        var allRatingModules = $(".starRating-module");

        (starRatingInit = function() {
            // storedStarRating = $("#contentRating").val();
            storedStarRatingBrand = $("#contentRatingBrand").val();
            storedStarRatingSubjectDepth = $(
                "#contentRatingSubjectDepth"
            ).val();
            storedStarRatingMechanics = $("#contentRatingMechanics").val();

            // if (storedStarRating != "") {
            //     starRatingHighlight(storedStarRating);
            // }
            if (storedStarRatingBrand != "") {
                starHighlight(
                    storedStarRatingBrand,
                    true,
                    "#starRatingBrandAlignmentModule"
                );
            }
            if (storedStarRatingSubjectDepth != "") {
                starHighlight(
                    storedStarRatingSubjectDepth,
                    true,
                    "#starRatingSubjectDepthModule"
                );
                // starRatingSubjectDepthHighlight(storedStarRatingSubjectDepth);
            }
            if (storedStarRatingMechanics != null) {
                starHighlight(
                    storedStarRatingMechanics,
                    true,
                    "#starRatingMechanicsModule"
                );
                // starRatingMechanicsHighlight(storedStarRatingMechanics);
            }

            loadQualityRating("writer");
        }),
            // highlight star ratings
            (starHighlight = function(rating, highlight, id) {
                for (var i = rating; i > 0; i--) {
                    if (highlight == true) {
                        $(
                            `${id} li.starRating-input[data-value="${i}"]`
                        ).addClass("activeHighlight");
                    } else {
                        $(
                            `${id} li.starRating-input[data-value="${i}"]`
                        ).addClass("active");
                    }
                }
                $(".starRating-text").text(
                    $(
                        `${id} li.starRating-input[data-value="${rating}"] input`
                    ).val()
                );
            }),
            //remove highlight
            (removeStarHighlight = function(id) {
                $(`${id} > .starRating-input`)
                    .removeClass("active")
                    .removeClass("activeHighlight");
                $(`${id} .starRating-text`).text("");
            }),
            //clear star highlight
            (clearStarRatingHighlight = function(id) {
                $(`${id} > .starRating-text`).text(
                    $(`${id} li.starRating-clear input`).val()
                );
            }),
            //return rating on mouseout based on id
            (returnRatingMouseOut = function(id) {
                let rating = null;
                switch (id) {
                    case "#starRatingBrandAlignmentModule":
                        rating = storedStarRatingBrand;
                        break;
                    case "#starRatingSubjectDepthModule":
                        rating = storedStarRatingSubjectDepth;
                        break;
                    case "#starRatingMechanicsModule":
                        rating = storedStarRatingMechanics;
                        break;
                    default:
                        rating = null;
                        break;
                }
                return rating;
            }),
            //return content type on click input
            (returnRatingContentTypeOnClick = function(id) {
                let contentRatingType = "";
                switch (id) {
                    case "#starRatingBrandAlignmentModule":
                        contentRatingType = "brandAlignment";
                        break;
                    case "#starRatingSubjectDepthModule":
                        contentRatingType = "subjectDepth";
                        break;
                    case "#starRatingMechanicsModule":
                        contentRatingType = "mechanics";
                        break;
                    default:
                        contentRatingType = "";
                        break;
                }
                return contentRatingType;
            }),
            (getLastStar = function(diff) {
                var lastStar = "icon-Star";
                if (0.95 <= diff) {
                    lastStar = "icon-Star";
                }
                if (0.75 <= diff && diff < 0.95) {
                    lastStar = "icon-StarThreeQuarter";
                } // 3/4
                if (0.5 <= diff && diff < 0.75) {
                    lastStar = "icon-StarHalf";
                } // Half
                if (0.25 <= diff && diff < 0.5) {
                    lastStar = "icon-StarQuarter";
                } // 1/4
                if (diff < 0.25) {
                    lastStar = "none";
                } // full star is cold
                if (0 === diff) {
                    lastStar = "icon-Star";
                } // 0 is full star
                return lastStar;
            }),
            (qualityRating = function() {
                var a = $(this),
                    ratingMini = a.closest(".rating-mini"),
                    ratingFull = ratingMini.siblings(".rating-full"),
                    sib;

                if (a.hasClass("more")) {
                    ratingFull.slideDown("fast");
                    sib = a.siblings("a.less");
                } else {
                    ratingFull.slideUp("fast");
                    sib = a.siblings("a.more");
                }
                a.hide();
                sib.show();
            }),
            (displayRating = function(qualityRating) {
                var ul = qualityRating.find("ul.ratingul"),
                    program = qualityRating.find("div.rating-stars"),
                    starDisplay = qualityRating.find("div.starDisplay"),
                    totalAverage = 0.0,
                    totalPrograms = 0,
                    finalAverage = 0.0,
                    finalStars = 0,
                    j = 0,
                    starDiv = $("<div/>"),
                    coldStars = 5,
                    maxAvg = 5,
                    diffAvg = 0.0,
                    totalLastStar = "icon-Star",
                    roundedAvg = 0;
                // console.log(ul);
                ul.each(function() {
                    var u = $(this),
                        li = u.children("li"),
                        total = parseInt(u.attr("data-total"), 10);
                    li.each(function() {
                        var rating = $(this),
                            div,
                            inner,
                            stars = parseInt(rating.attr("data-stars"), 10),
                            width = Math.floor((stars / total) * 100.0);
                        div = rating.find(".graphWrapper");
                        inner = $("<div/>");
                        inner.addClass("graphInner");
                        inner.css("width", width + "%");
                        div.html(inner);
                    });
                });

                program.each(function() {
                    var i = 0,
                        p = $(this),
                        avg = 0.0,
                        stars = 0,
                        avgText = p.attr("data-programavg"),
                        programName = p.attr("data-name"),
                        max = 0,
                        diff = 0.0,
                        lastStar = "icon-Star",
                        progColdStars = 5,
                        k = 0,
                        mini = p.closest(".rating-mini"),
                        full = mini.siblings(".rating-full"),
                        spanAvg = full.find(".progAvg");
                    if (null !== avgText && "" !== avgText) {
                        avg = parseFloat(avgText);
                        max = Math.floor(avg);
                        stars = Math.ceil(avg);
                        diff = Math.round((avg - max) * 100) / 100;
                        lastStar = getLastStar(diff);
                        // console.log(programName + ":: avg: " + avg + " max: " + max + " diff; " + diff + " lastStar: " + lastStar);
                    }
                    if (1.0 < avg) {
                        totalAverage = totalAverage + avg;
                        totalPrograms = totalPrograms + 1;
                    }
                    spanAvg.text(avg.toFixed(1));
                    progColdStars = progColdStars - stars;
                    // console.log(programName + ":: stars - " + stars + " | progColdStars " + progColdStars);

                    for (i = 0; i < stars; i++) {
                        var span = $("<span/>");
                        // console.log(programName + ":: " + lastStar + " " + avgText + " " + i);
                        if (i === stars - 1) {
                            if ("none" === lastStar) {
                                span.addClass("icon-Star").addClass(
                                    "star-cold"
                                );
                            } else {
                                span.addClass(lastStar).addClass(
                                    "star-highlight"
                                );
                            }
                        } else {
                            span.addClass("icon-Star").addClass(
                                "star-highlight"
                            );
                        }
                        p.find(".star-display").append(span);
                    }

                    for (k = 0; k < progColdStars; k++) {
                        var span1 = $("<span />");
                        span1.addClass("icon-Star").addClass("star-cold");
                        p.find(".star-display").append(span1);
                    }
                });

                finalAverage = totalAverage / totalPrograms || 0;
                maxAvg = Math.ceil(finalAverage);
                roundedAvg = Math.floor(finalAverage);
                diffAvg = Math.round((finalAverage - roundedAvg) * 100) / 100;
                totalLastStar = getLastStar(diffAvg);
                coldStars = coldStars - maxAvg;
                // console.log("max " + maxAvg + " diffAvg " + diffAvg + " totalLast " + totalLastStar + " coldStar " + coldStars);
                for (j = 0; j < maxAvg; j++) {
                    var span = $("<span />");
                    if (j === maxAvg - 1) {
                        if ("none" === totalLastStar) {
                            span.addClass("icon-Star").addClass("star-cold");
                        } else {
                            span.addClass(totalLastStar).addClass(
                                "star-highlight"
                            );
                        }
                    } else {
                        span.addClass("icon-Star").addClass("star-highlight");
                    }
                    span.attr("title", finalAverage);
                    starDiv.append(span);
                }
                for (j = 0; j < coldStars; j++) {
                    var span1 = $("<span />");
                    span1.addClass("icon-Star").addClass("star-cold");
                    starDiv.append(span1);
                }
                starDisplay.append(starDiv);
            }),
            (loadQualityRating = function(contributorType) {
                var urlAction = "/loadContributorQualityAjax.action",
                    options,
                    opts,
                    qualityModule = $("#quality-module"),
                    qualityRatingSmall = $("#quality-rating-small-writer"),
                    qualityRating = qualityModule.find(".qualityWriter"),
                    meta = $("#quality-metainfo");
                if (0 === meta.length) {
                    return false;
                }

                if (0 === qualityRating.length) {
                    return;
                }
                opts = {
                    contributorId: meta.attr("data-contributorId"),
                    contributorType: contributorType
                };
                options = {
                    cache: false,
                    type: "POST",
                    data: opts,
                    dataType: "html",
                    url: urlAction,
                    success: function(data) {
                        qualityRating.hide();
                        qualityRating.html(data);
                        qualityRating.fadeIn("fast");
                    },
                    error: function(data, status, err) {
                        console.log([data, status, err]);
                    },
                    complete: function(data) {
                        brandRatingDiv = $("#brandRatingsDiv");
                        displayRating(brandRatingDiv);
                        subjectRatingDiv = $("#subjectDepthRatingsDiv");
                        displayRating(subjectRatingDiv);
                        mechanicsRatingDiv = $("#mechanicsRatingsDiv");
                        displayRating(mechanicsRatingDiv);
                    }
                };
                call.ajax(urlAction, options);
            }),
            (loadContributorSearchQualityRating = function() {
                var contributorQuality = $("div.contributor-search-quality");
                if (0 === contributorQuality.length) {
                    return false;
                }
                $(".star-display").each(function(i, el) {
                    var finalAverage = parseFloat($(el).text()) || 0.0,
                        finalStars = 0,
                        j = 0,
                        starDiv = $("<div/>"),
                        maxAvg = Math.ceil(finalAverage),
                        roundedAvg = Math.floor(finalAverage),
                        diffAvg =
                            Math.round((finalAverage - roundedAvg) * 100) / 100,
                        totalLastStar = getLastStar(diffAvg),
                        coldStars = 5 - maxAvg;
                    $(el).empty();
                    for (j = 0; j < maxAvg; j++) {
                        var starSpan = $("<span />");
                        if (j === maxAvg - 1) {
                            if ("none" === totalLastStar) {
                                starSpan
                                    .addClass("icon-Star")
                                    .addClass("star-cold");
                            } else {
                                starSpan
                                    .addClass(totalLastStar)
                                    .addClass("star-highlight");
                            }
                        } else {
                            starSpan
                                .addClass("icon-Star")
                                .addClass("star-highlight");
                        }
                        starSpan.attr("title", finalAverage);
                        $(el).append(starSpan);
                    }
                    for (j = 0; j < coldStars; j++) {
                        var starSpanNew = $("<span />");
                        starSpanNew.addClass("icon-Star").addClass("star-cold");
                        $(el).append(starSpanNew);
                    }
                });
            });

        checkContentRequestAIGenerated = function() {
            let brandRating = isNaN(parseInt($("#contentRatingBrand").val()))
                ? 0
                : parseInt($("#contentRatingBrand").val());
            let subjectDepthRating = isNaN(
                parseInt($("#contentRatingSubjectDepth").val())
            )
                ? 0
                : parseInt($("#contentRatingSubjectDepth").val());
            let mechanicsRating = isNaN(
                parseInt($("#contentRatingMechanics").val())
            )
                ? 0
                : parseInt($("#contentRatingMechanics").val());
            if (
                brandRating === 0 ||
                subjectDepthRating === 0 ||
                mechanicsRating === 0
            ) {
                    let checkRatingAIGenerated = $("#checkRatingAIGenerated").val();
                    if (checkRatingAIGenerated === "true") {
                        $('#review-submit-action a').each(function() {
                            var id = $(this).attr('id');
                            if (id && id !== "7") {
                                $(this).parent('li').addClass('disabled').attr("disabled", "disabled");
                                $(this).addClass('disabled');
                            }
                        });
                        $("#review-submit-action > span:first").addClass("disabled");
                    }
            } else {
                let checkRatingAIGenerated = $("#checkRatingAIGenerated").val();
                if (checkRatingAIGenerated === "true") {
                    $('#review-submit-action a').each(function() {
                        var id = $(this).attr('id');
                        if (id && id !== "7") {
                            $(this).parent('li').removeClass('disabled').removeAttr("disabled", "disabled");
                            $(this).removeClass('disabled');
                        }
                    });
                    $("#review-submit-action > span:first").removeClass("disabled");
                }
            }
        };

        openModalCommentBox = function(id, rating) {
            let contentRating = returnRatingContentTypeOnClick(id);
            var options = {
                type: "GET",
                url: "/getPreviousComment.action",
                data: {
                    contentRequestId: $("#contentRequestId").val(),
                    contentRatingType: contentRating
                },
                dataType: "json",
                success: function(data) {
                    let html = `<div class="flex-column" id="addCommentModal" style="width:90%; margin:20px auto 10px auto; display:flex;">
            <input type="hidden" id="starRatingId" value="${id}" />
            <input type="hidden" id="starRating" value="${rating}" />
                                    <h3 style="font-family: Glober,Calibri,'Gill Sans','Gill Sans MT','Trebuchet MS','Helvetica Neue',Helvetica,sans-serif!important;
                                    font-weight: 700;
                                    color: #003057; font-size:18px; margin-top:0;">${$translate.instant(
                                        "sw.addcommentforrating.header"
                                    )}</h3>
                                    <p class="label no">${$translate.instant(
                                        "sw.addcommentforrating.commentlabel"
                                    )}</p>
                                    <textarea row=10 column=20 name="contentRatingComment" id="contentRatingComment" style="height:120px;"></textarea>
                                    <span id="commentError" style="display:none; color:#bd1200;">${$translate.instant(
                                        "sw.addcommentforrating.commentrequired"
                                    )}</span>
                                    <div class="my-3 d-flex flex-row justify-content-end">
                                    <button type="button" id="cancelAddComment" class="btn btn-link" style="font-size:1.6rem;">Cancel</button>
                                    <button type="button" id="saveComment" class="btn btn-primary" style="margin-right:0;">Save</button>
                                    </div>
                                </div>`;
                    $.colorbox({
                        html: html,
                        width: "30%",
                        height: "300px",
                        maxHeight: "auto",
                        escKey: false,
                        overlayClose: false
                    });
                    $("#cboxClose").css("display", "none");
                    $("#contentRatingComment").val(data);
                },
                error: function(data) {
                    console.log("failed");
                }
            };
            call.ajax("/getPreviousComment.action", options);
        };

        return {
            init: function() {
                //onReady
                $(function() {
                    let checkRatingAIGenerated = $(
                        "#checkRatingAIGenerated"
                    ).val();
                    let canAddRatingComment = $("#canAddRatingComment").val();

                    starRatingInit();

                    $(".stars").each(function() {
                        var count = parseInt($(this).attr("data-number")),
                            originalSpan = $(this);

                        for (var i = 0; i < count; i++) {
                            originalSpan.append(
                                "<span class='icon-Star'></span>"
                            );
                        }
                    });

                    loadContributorSearchQualityRating();
                    $("body").on(
                        "click",
                        "a.qualityRating",
                        null,
                        qualityRating
                    );

                    //All related to star ratings

                    //highlight rating on hover
                    allRatingModules.on(
                        "mouseover",
                        ".starRating-input",
                        function() {
                            var starVal = $(this).data("value");
                            var id =
                                "#" +
                                $(this)
                                    .parent()
                                    .attr("id");
                            removeStarHighlight(id);
                            starHighlight(starVal, true, id);
                        }
                    );

                    //remove highlight rating on hover delete button
                    allRatingModules.on(
                        "mouseover",
                        ".starRating-clear",
                        function() {
                            var id =
                                "#" +
                                $(this)
                                    .parent()
                                    .attr("id");
                            clearStarRatingHighlight(id);
                        }
                    );

                    //remove highlight rating and restore selected highlight value on mouse out of ratings and delete
                    allRatingModules.on(
                        "mouseout",
                        ".starRating-input, .starRating-clear",
                        function() {
                            var id =
                                "#" +
                                $(this)
                                    .parent()
                                    .attr("id");
                            removeStarHighlight(id);

                            let rating = returnRatingMouseOut(id);
                            if (rating != null) {
                                starHighlight(rating, true, id);
                            }
                        }
                    );

                    //save star rating on click, highlight clicked star
                    allRatingModules.on(
                        "click",
                        ".starRating-input",
                        function() {
                            let id =
                                "#" +
                                $(this)
                                    .parent()
                                    .attr("id");
                            let rating = $(this).data("value");

                            if (rating != null) {
                                if (canAddRatingComment === "true") {
                                    //open modal with comment box
                                    openModalCommentBox(id, rating);
                                } else {
                                    let contentRatingType = returnRatingContentTypeOnClick(
                                        id
                                    );
                                    var options = {
                                        type: "POST",
                                        url: "/contentRatingAjax.action",
                                        dataType: "html",
                                        data: {
                                            contentRequestId: $(
                                                "#contentRequestId"
                                            ).val(),
                                            contentRating: rating,
                                            accountId: $("#accountId").val(),
                                            contentRatingType: contentRatingType,
                                            contentRatingComment: null
                                        },
                                        success: function(data) {
                                            removeStarHighlight(id);
                                            starHighlight(rating, true, id);
                                            switch (id) {
                                                case "#starRatingBrandAlignmentModule":
                                                    storedStarRatingBrand = rating;
                                                    $(
                                                        "#contentRatingBrand"
                                                    ).val(rating);
                                                    break;
                                                case "#starRatingSubjectDepthModule":
                                                    storedStarRatingSubjectDepth = rating;
                                                    $(
                                                        "#contentRatingSubjectDepth"
                                                    ).val(rating);
                                                    break;
                                                case "#starRatingMechanicsModule":
                                                    storedStarRatingMechanics = rating;
                                                    $(
                                                        "#contentRatingMechanics"
                                                    ).val(rating);
                                                    break;
                                            }
                                            if (
                                                checkRatingAIGenerated ===
                                                "true"
                                            ) {
                                                checkContentRequestAIGenerated();
                                            }
                                        },
                                        error: function(data) {
                                            console.log("failed");
                                        }
                                    };
                                    call.ajax(
                                        "/contentRatingAjax.action",
                                        options
                                    );
                                }
                            }
                        }
                    );

                    //save star rating on delete click, remove highlighted star
                    allRatingModules.on(
                        "click",
                        ".starRating-clear",
                        function() {
                            let id =
                                "#" +
                                $(this)
                                    .parent()
                                    .attr("id");
                            removeStarHighlight(id);
                            let contentRatingType = returnRatingContentTypeOnClick(
                                id
                            );
                            var options = {
                                type: "POST",
                                url: "/contentRatingAjax.action",
                                dataType: "html",
                                data: {
                                    contentRequestId: $(
                                        "#contentRequestId"
                                    ).val(),
                                    contentRating: 0,
                                    accountId: $("#accountId").val(),
                                    contentRatingType: contentRatingType,
                                    contentRatingComment: null
                                },
                                success: function(data) {
                                    switch (id) {
                                        case "#starRatingBrandAlignmentModule":
                                            storedStarRatingBrand = 0;
                                            $("#contentRatingBrand").val(0);
                                            break;
                                        case "#starRatingSubjectDepthModule":
                                            storedStarRatingSubjectDepth = 0;
                                            $("#contentRatingSubjectDepth").val(
                                                0
                                            );
                                            break;
                                        case "#starRatingMechanicsModule":
                                            storedStarRatingMechanics = 0;
                                            $("#contentRatingMechanics").val(0);
                                            break;
                                    }
                                    if (checkRatingAIGenerated === "true")
                                        checkContentRequestAIGenerated();
                                },
                                error: function(data) {
                                    console.log("failed");
                                }
                            };
                            call.ajax("/contentRatingAjax.action", options);
                        }
                    );

                    $("body").on("click", "#cancelAddComment", function() {
                        $("#contentRatingComment").val("");
                        $(this).colorbox.close();
                    });

                    $("body").on("click", "#saveComment", function() {
                        let contentRatingComment = $(
                            "#contentRatingComment"
                        ).val();
                        let id = $("#starRatingId").val();
                        let rating = $("#starRating").val();
                        if (
                            contentRatingComment === "" ||
                            contentRatingComment === null
                        ) {
                            $("#commentError").css("display", "block");
                        } else {
                            let contentRatingType = returnRatingContentTypeOnClick(
                                id
                            );
                            var options = {
                                type: "POST",
                                url: "/contentRatingAjax.action",
                                dataType: "html",
                                data: {
                                    contentRequestId: $(
                                        "#contentRequestId"
                                    ).val(),
                                    contentRating: rating,
                                    accountId: $("#accountId").val(),
                                    contentRatingType: contentRatingType,
                                    contentRatingComment: contentRatingComment
                                },
                                success: function(data) {
                                    removeStarHighlight(id);
                                    starHighlight(rating, true, id);
                                    $(this).colorbox.close();
                                    switch (id) {
                                        case "#starRatingBrandAlignmentModule":
                                            storedStarRatingBrand = rating;
                                            $("#contentRatingBrand").val(
                                                rating
                                            );
                                            break;
                                        case "#starRatingSubjectDepthModule":
                                            storedStarRatingSubjectDepth = rating;
                                            $("#contentRatingSubjectDepth").val(
                                                rating
                                            );
                                            break;
                                        case "#starRatingMechanicsModule":
                                            storedStarRatingMechanics = rating;
                                            $("#contentRatingMechanics").val(
                                                rating
                                            );
                                            break;
                                    }
                                    if (checkRatingAIGenerated === "true")
                                        checkContentRequestAIGenerated();
                                },
                                error: function(data) {
                                    console.log("failed");
                                }
                            };
                            call.ajax("/contentRatingAjax.action", options);
                        }
                    });
                });
            } // end qualityRating.init();
        };
    }
]);

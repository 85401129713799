(function() {
    app.directive("ideaBacklogItem", function() {
        return {
            templateUrl: "/partials/ideas/listitem-backlog.html",
            replace: false,
            restrict: "EA",
            scope: {
                title: "=title",
                view: "=view",
                formReady: "=formReady",
                contentRequestId: "=contentRequestId",
                contentTypeName: "=contentTypeName",
                stateId: "=stateId",
                id: "=id",
                ngModel: "=ngModel"
            }
        };
    });
})();

app.controller("agencyProgramList", ["$scope", function($scope) {}])
    .controller("agencyCreateProgram", [
        "$scope",
        "agency",
        function($scope, agency) {
            agency.init();
        }
    ])
    .controller("agencySettings", [
        "$scope",
        "agency",
        "crop",
        function($scope, agency, crop) {
            agency.init();
            crop.init();
            app.value("requestActive", false);
        }
    ])
    .controller("agencyWriters", [
        "$scope",
        "agency",
        function($scope, agency) {
            agency.init();
        }
    ])
    .controller("agencyWritersAjax", ["$scope", function($scope) {}])
    .controller("agencyWriter", [
        "$scope",
        "call",
        "overlay",
        function($scope, call, overlay) {
            $(".delete").click(function() {
                //console.log('in agencyWriter');
                var id = $(this).attr("data-programAccountId");
                return call.overlay("deleteContributorInvitationAjax.action", {
                    targetProgramAccountId: id
                });
            });

            $(".withdraw").click(function() {
                //console.log('in agencyWriter');
                var id = $(this).attr("data-programAccountId");
                return call.overlay("deleteContributorInvitationAjax.action", {
                    targetProgramAccountId: id
                });
            });
        }
    ])
    .controller("removeProgramProtectionAjax", ["$scope", function($scope) {}])
    .controller("agencyUserList", ["$scope", function($scope) {}])
    .controller("agencyAddUser", [
        "$scope",
        "agency",
        function($scope, agency) {
            agency.init();
        }
    ])
    .controller("agencyEditUser", [
        "$scope",
        "agency",
        function($scope, agency) {
            agency.init();
        }
    ])
    .controller("agencyAddUserAjax", ["$scope", function($scope) {}])
    .controller("agencyEditAdminPrivilege", ["$scope", function($scope) {}])
    .controller("agencyUserDetail", [
        "$scope",
        "agency",
        function($scope, agency) {
            agency.init();
        }
    ])
    .controller("agencyEditUserRoleAjax", ["$scope", function($scope) {}])
    .controller("agencyRemoveUserRoleAjax", ["$scope", function($scope) {}])
    .controller("agencyRemoveUserAjax", ["$scope", function($scope) {}])
    .controller("agencyAssignNewPrograms", [
        "$scope",
        "agency",
        function($scope, agency) {
            agency.init();
        }
    ])
    .controller("agencyAssignNewProgramsAjax", ["$scope", function($scope) {}])
    .controller("enterpriseUserDetail", ["$scope", function($scope) {}]);

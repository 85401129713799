(function() {
    app.controller("digitalAssetReview", [
        "$scope",
        "SkyFactory",
        "kalturaService",
        function($scope, SkyFactory, kalturaService) {
            $scope.video = {
                links: [],
                downloadLink: "/test.html"
            };

            $scope.downloadUrl = "";

            var unbindWatcher = $scope.$watch("assetAttachmentId", function() {
                if (!SkyFactory.isUndefinedOrNull($scope.assetAttachmentId)) {
                    var kalturaPromise = kalturaService.getKalturaVideos(
                        $scope.assetAttachmentId
                    );

                    kalturaPromise.then(function(kaltura) {
                        //console.info(kaltura);
                        $scope.downloadUrl = kaltura.data.downloadUrl;
                        $scope.video.downloadLink = kaltura.data.downloadUrl;
                        //console.info($scope);
                    });
                    unbindWatcher();
                }
            });

            $scope.download = function() {
                alert($scope.video.downloadLink);
            };
        }
    ]);
})();

app.controller("deliverArticles", ["$scope", function($scope) {}])
    .controller("deliverArticle", [
        "$scope",
        "delivery",
        "publish",
        function($scope, delivery, publish) {
            delivery.init();
            publish.init();
        }
    ])
    .controller("apiTestPage", [
        "$scope",
        "delivery",
        function($scope, delivery) {
            delivery.init();
        }
    ])
    .controller("apiTestPageResults", ["$scope", function($scope) {}])
    .controller("apiTestDetail", ["$scope", function($scope) {}]);

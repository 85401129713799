app.controller("viewKeywordList", [
    "$scope",
    "keywords",
    function($scope, keywords) {
        keywords.init();
    }
])
    .controller("searchKeywordList", [
        "$scope",
        "keywords",
        function($scope, keywords) {
            keywords.init();
        }
    ])
    .controller("searchKeywordListAjax", ["$scope", function($scope) {}])
    .controller("addKeywordAjax", ["$scope", function($scope) {}])
    .controller("retireKeywordAjax", ["$scope", function($scope) {}])
    .controller("deactivateKeywordAjax", ["$scope", function($scope) {}])
    .controller("reinstateKeywordAjax", ["$scope", function($scope) {}])
    .controller("manualKeywords", [
        "$scope",
        "keywords",
        function($scope, keywords) {
            keywords.init();
        }
    ])
    .controller("updateManualKeywordAjax", ["$scope", function($scope) {}]);

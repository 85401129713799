app.factory("writerInfo", [
    "$http",
    "$q",
    function($http, $q) {
        return {
            /**
             * GET WRITER INFO
             */
            getWriterInfo: function(programId, searchTerm, categoryId) {
                return $http
                    .get(
                        "/API/programs/" +
                            programId +
                            "/contributors?byline=" +
                            searchTerm +
                            "&categoryId=" +
                            categoryId
                    )
                    .then(
                        function(response) {
                            // 3.1 On Success. We're getting back an object
                            if (typeof response.data === "object") {
                                return response.data;
                                // 3.2 On Invalid type. It's not an object
                            } else {
                                // invalid response
                                return $q.reject(response.data);
                            }
                        },
                        function(response) {
                            // 3.3 Something just straight up went wrong
                            return $q.reject(response.data);
                        }
                    );
            },

            getAssignee: function(programId, contentRequestId) {
                return $http
                    .get(
                        "/API/programs/" +
                            programId +
                            "/contributors/load?contentRequestId=" +
                            contentRequestId
                    )
                    .then(
                        function(response) {
                            // 3.1 On Success. We're getting back an object
                            if (typeof response.data === "object") {
                                return response.data;
                                // 3.2 On Invalid type. It's not an object
                            } else {
                                // invalid response
                                return $q.reject(response.data);
                            }
                        },
                        function(response) {
                            // 3.3 Something just straight up went wrong
                            return $q.reject(response.data);
                        }
                    );
            },

            setAssignee: function(programId, contentRequestId, accountId) {
                var contentRequest = {
                    programId: programId,
                    contentRequestId: contentRequestId,
                    accountId: accountId
                };
                return $http.put(
                    "/API/programs/" + programId + "/contributors/setAssignee",
                    contentRequest
                );
            }
        };
    }
]);

app.service("upload", [
    "skyword",
    "validation",
    "call",
    "overlay",
    "$translate",
    function(skyword, validation, call, overlay, $translate) {
        var uploadDescription = "",
            uploadDescriptionObj = null,
            fileNameToCrop = "",
            uploadInit = function() {
                $(".uploadFile").each(function() {
                    var formData = $(this)
                            .closest("form")
                            .serializeArray(),
                        minSize = $(this)
                            .find("#min-file-size")
                            .val(),
                        maxSize = $(this)
                            .find("#max-file-size")
                            .val(),
                        callback = $(this).attr("rel"),
                        container = $(this).closest(".uploader"),
                        error = container.find(".messages"),
                        success = container.find(".successMessage"),
                        feedback = container.find(".progress"),
                        status = container.find(".info"),
                        controls = container.find(".controls .upload"),
                        description = container.find(".description");
                    container
                        .find(".hidden")
                        .removeClass("hidden")
                        .hide();
                    uploadDescription = description.text();
                    var xhr = $(this).fileupload({
                        dataType: "html",
                        paramName: "fileData",
                        formData: formData,
                        add: function(e, data) {
                            var jqXHR = data.submit(),
                                filename = data.files[0].name;
                            fileNameToCrop = filename + " | ";
                            uploadDescriptionObj = description;
                            container.find("a.cancel").click(function(e) {
                                jqXHR.abort();
                                removeUploadCallback(container);
                                return false;
                            });
                        },
                        done: function(e, data) {
                            var json = $.parseJSON(data.result),
                                msg,
                                cb,
                                cbClosed;
                            controls.show();
                            if (true === json.success) {
                                feedback.removeClass("active");
                                status.hide();
                                if (json.crop) {
                                    cb = function() {
                                        uploadDescriptionObj.text(
                                            fileNameToCrop
                                        );
                                    };
                                    skyword.colorboxCloseMethod = function() {
                                        uploadDescriptionObj.text(
                                            uploadDescription
                                        );
                                    };
                                    return call.overlay(
                                        json.cropUrl +
                                            "&uploaderId=" +
                                            $(this).attr("id"),
                                        null,
                                        1200,
                                        null,
                                        "GET",
                                        cb,
                                        false
                                    );
                                } else {
                                    if (json.fileUrl != null) {
                                        container
                                            .find(".manage .filename")
                                            .html(json.filename);
                                        $(".uploader .manage .fileUrl").attr(
                                            "href",
                                            json.fileUrl
                                        );
                                    } else if (json.filename != null) {
                                        container
                                            .find(".manage .filenameNoLink")
                                            .html(json.filename);
                                    }

                                    if (json.displayUploadManagement != false) {
                                        container
                                            .find(".uploadControls")
                                            .hide();
                                    } else {
                                        container
                                            .find(".manage .controls")
                                            .hide();
                                    }

                                    if (json.msg != null) {
                                        success.text(json.msg);
                                    }

                                    container.find(".manage").show();
                                }
                            } else {
                                // cancel the currentUpload - show error
                                error.html(json.msg);
                                status.hide();
                                feedback
                                    .addClass("error")
                                    .find(".bar")
                                    .css("width", 0 + "%");
                                console.log(json.msg);
                            }
                        },
                        progressall: function(e, data) {
                            var progress = parseInt(
                                (data.loaded / data.total) * 100,
                                10
                            );
                            error.html("");
                            controls.hide();
                            status.show();
                            feedback
                                .addClass("active")
                                .removeClass("error")
                                .find(".bar")
                                .css("width", progress + "%");
                            status
                                .find(".bytes")
                                .html(
                                    parseInt(data.loaded / 1000) +
                                        " / " +
                                        parseInt(data.total / 1000) +
                                        " KB"
                                );
                        }
                    });
                });
            },
            removeUploadCallback = function(container) {
                if (null == container) {
                    container = $(".uploader");
                }
                var feedback = container.find(".progress");
                if (uploadDescription != "") {
                    container
                        .find(".description")
                        .text(uploadDescription);
                }
                container
                    .find(".uploadControls, .uploadControls .upload")
                    .show();
                feedback.removeClass("active");
                container.find(".manage").hide();
                $(".optOut").show();
                uploadInit();
            };

        return {
            init: function() {
                //onReady
                $(function() {
                    var html = $("html");
                    uploadInit();
                    html.on("click", "#btn-crop-overlay", function() {
                        var formSubmit = $("#formSubmit"),
                            urlAction = formSubmit.attr("action"),
                            container = $("body").find(".uploader"),
                            getPost = formSubmit.attr("method"),
                            img,
                            options = {
                                cache: false,
                                type: getPost,
                                data: formSubmit.serialize(),
                                url: urlAction,
                                success: function(response) {
                                    if (!validation.parse(response)) {
                                        if (
                                            !container.hasClass(
                                                "keepUploadButton"
                                            )
                                        ) {
                                            container
                                                .find(".uploadControls")
                                                .hide();
                                            container
                                                .find(" .manage")
                                                .show()
                                                .html(response);
                                        }
                                        if (
                                            container.find(".imageCol").length >
                                            0
                                        ) {
                                            container
                                                .find(".imageCol")
                                                .html(response);
                                        }
                                        overlay.init();
                                        $(this).colorbox.close();
                                    } else {
                                        msg +=
                                            json.errorMsg +
                                            "<br/><br/>" +
                                            $(
                                                "#file-ext-" +
                                                    json.attachmentDefinitionId
                                            )
                                                .val()
                                                .replace(",", ", ") +
                                            "<br/><br/>";
                                        $(".uploader .messages").html(
                                            json.errorMsg
                                        );
                                    }
                                },
                                error: function(data, status, err) {
                                    var msg =
                                        "Error getting: " + urlAction + "\n\n";
                                    msg += "Status: " + status + "\n\n";
                                    console.log(msg);
                                },
                                complete: function() {
                                    if (
                                        undefined !== skyword.setupSave &&
                                        typeof skyword.setupSave === "function"
                                    ) {
                                        skyword.setupSave();
                                    }
                                }
                            };
                        $("#crop-container").hide();
                        $("#btn-crop-overlay").hide();
                        $("#prepare-container").show();
                        $(this).colorbox.resize();
                        call.ajax(urlAction, options);
                        return false;
                    });
                    html.on("click", "#btn-upload-remove", function() {
                        var urlAction = $(this).attr("href"),
                            container = $(this).closest(".uploader"),
                            options = {
                                data: "requestFrom=" + urlAction,
                                cache: false,
                                type: "POST",
                                url: urlAction,
                                success: function(response) {
                                    removeUploadCallback(container);
                                }
                            };
                        call.ajax(urlAction, options);
                        return false;
                    });
                });
            }
        };
    }
]);

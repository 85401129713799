app.service("vetting", [
    "$http",
    "SkyFactory",
    function($http, SkyFactory) {
        return {
            contributorSearch: function(vettingContributors, callback) {
                if (undefined === vettingContributors.contributorType) {
                    console.log(
                        "vettingContributors.contributorType is undefined???"
                    );
                    vettingContributors.contributorType = "all";
                }

                var urlString =
                    "/API/contributor/" +
                    vettingContributors.contributorType +
                    SkyFactory.buildQueryString(vettingContributors);

                $http({
                    url: urlString,
                    method: "GET",
                    cache: false,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            getTopics: function(callback) {
                $http({
                    url: "/API/topic",
                    method: "GET"
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            getStatuses: function(callback) {
                $http
                    .get("/API/contributor/vetting/states")
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            updateStatus: function(vettingContributor, callback) {
                $http({
                    url: "/API/contributor/vetting/update-status",
                    method: "POST",
                    data: {
                        accountId: vettingContributor.id,
                        writerVettingStateId: vettingContributor.writerStatus,
                        videographerVettingStateId:
                            vettingContributor.videographerStatus,
                        photographerVettingStateId:
                            vettingContributor.photographerStatus,
                        designerVettingStateId:
                            vettingContributor.designerStatus
                    },
                    cache: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            }
        };
    }
]);

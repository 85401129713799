(function() {
    app.controller("IdeasListController", [
        "$scope",
        "$translate",
        "$http",
        "$filter",
        "$timeout",
        "ngTableParams",
        "skyword",
        "SkyFactory",
        "idea",
        "$mdDialog",
        "$window",
        function(
            $scope,
            $translate,
            $http,
            $filter,
            $timeout,
            ngTableParams,
            skyword,
            SkyFactory,
            idea,
            $mdDialog,
            $window
        ) {
            $scope.programId = $("#attrs-id").data("programid");
            $scope.sortOptions = [];
            $scope.sortOption = "-label.dateCreated";
            $scope.state = "-1";
            $scope.states = [];
            $scope.contentTypeFilter = [];
            $scope.contentType = "-1";
            $scope.view = null;
            $scope.isCurrentMonth = false;
            $scope.idea = {
                currentStateId: "60"
            };
            $scope.thisMonth = new Date().getMonth();
            $scope.thisYear = new Date().getFullYear();
            $scope.chosenMonth = $scope.thisMonth;
            $scope.chosenYear = $scope.thisYear;

            $scope.fromList = "";
            $scope.toList = "";
            $scope.itemsToProcess = [];
            $scope.selectedStates = [];
            $scope.backlogDetails = true;
            $scope.backlogCollapsed = false;

            $scope.showSelectNone = {
                pending: false,
                planner: false
            };

            $scope.savedFilters = JSON.parse(
                $window.sessionStorage.getItem("ideaListFilters")
            );

            $scope.closeBacklog = function() {
                $(".idea-pending").css(
                    "min-height",
                    $(".idea-pending").height()
                );
                $(".idea-pending")
                    .children()
                    .not(".backlog-controls, .list-loading")
                    .hide();
                $(".idea-pending").css("width", "30px");
                $(".idea-pending").addClass("collapsed");
                $(".idea-planner").css("width", "95%");
                $(".toggler-text").hide();
            };
            $scope.showBacklog = function() {
                $(".idea-pending").css("width", "34%");
                $(".idea-pending")
                    .children()
                    .not(".backlog-controls, .list-loading")
                    .show();
                $(".toggler-text").show();
                $(".idea-pending").css("min-height", "initial");
                $(".idea-pending").removeClass("collapsed");
                $(".idea-planner").css("width", "64%");
            };

            var qrystrContentType = skyword.getQstringParamByName("ctype");
            var qrystrCategory = skyword.getQstringParamByName("cat");
            var qrystrSortOption = skyword.getQstringParamByName("sort");
            var qrystrState = skyword.getQstringParamByName("state");
            var qrystrMonth = skyword.getQstringParamByName("m");
            var qrystrYear = skyword.getQstringParamByName("y");

            var useQstring = false;

            if (
                SkyFactory.exists(qrystrContentType) &&
                !isNaN(qrystrContentType)
            ) {
                $scope.contentType =
                    "content-type=" + parseInt(qrystrContentType);
                useQstring = true;
            } else if (
                SkyFactory.exists(qrystrCategory) &&
                !isNaN(qrystrCategory)
            ) {
                $scope.contentType = "category=" + parseInt(qrystrCategory);
                useQstring = true;
            }
            if (SkyFactory.exists(qrystrSortOption)) {
                $scope.sortOption = qrystrSortOption;
                useQstring = true;
            }
            if (SkyFactory.exists(qrystrState)) {
                $scope.state = qrystrState;
                useQstring = true;
            }
            if (
                SkyFactory.exists(qrystrMonth) &&
                !isNaN(qrystrMonth) &&
                qrystrMonth < 12 &&
                qrystrMonth >= 0
            ) {
                $scope.chosenMonth = parseInt(qrystrMonth);
                useQstring = true;
            }
            if (SkyFactory.exists(qrystrYear) && !isNaN(qrystrYear)) {
                $scope.chosenYear = parseInt(qrystrYear);
                useQstring = true;
            }

            if (!useQstring) {
                if ($scope.savedFilters == null) {
                    setSessionFilters();
                } else if ($scope.savedFilters.programId != $scope.programId) {
                    setSessionFilters();
                } else {
                    $scope.contentType = $scope.savedFilters.contentType;
                    $scope.sortOption = $scope.savedFilters.sortOption;
                    $scope.state = $scope.savedFilters.state;
                    $scope.chosenMonth = $scope.savedFilters.chosenMonth;
                    $scope.chosenYear = $scope.savedFilters.chosenYear;
                    $scope.backlogDetails = $scope.savedFilters.backlogDetails;
                    $scope.backlogCollapsed =
                        $scope.savedFilters.backlogCollapsed;
                }
            }

            // create buttons
            $scope.buttons = [];
            if ($scope.enableMonthlyIdeation == false) {
                $translate("angular.ideas.menu.makeAssignment").then(function(
                    val
                ) {
                    $scope.buttons.push({
                        stateId: 2,
                        stateName: val,
                        active: false
                    });
                });
            } else {
                $translate("angular.ideas.menu.sendForReview").then(function(
                    val
                ) {
                    $scope.buttons.push({
                        stateId: 62,
                        stateName: val,
                        active: true
                    });
                });
                $translate("angular.ideas.menu.approve").then(function(val) {
                    $scope.buttons.push({
                        stateId: 63,
                        stateName: val,
                        active: true
                    });
                });
            }

            $translate("angular.ideas.menu.decline").then(function(val) {
                $scope.buttons.push({
                    stateId: 64,
                    stateName: val,
                    active: false
                });
            });

            $scope.init = function() {
                var startTime = new Date().getTime();
                var interval = setInterval(function() {
                    if (new Date().getTime() - startTime > 2550) {
                        clearInterval(interval);
                        return;
                    }
                    adjustColumnHeight();
                }, 500);

                // $timeout(function() {adjustColumnHeight();}, 500);
            };

            function adjustColumnHeight() {
                if (
                    $(".idea-planner .list-results ul").height() > 1000 ||
                    $(".idea-backlog .list-results ul").height() > 1000
                ) {
                    if (
                        $(".idea-planner .list-results").height() >
                        $(".idea-backlog .list-results").height()
                    ) {
                        $(".idea-backlog .list-results").height(
                            $(".idea-planner .list-results").height()
                        );
                    } else {
                        $(".idea-planner .list-results").height(
                            $(".idea-backlog .list-results").height()
                        );
                    }
                }
            }

            // Sort Options
            $translate("angular.ideas.index.sort.dateDesc").then(function(val) {
                $scope.sortOptions.push({
                    label: $translate.instant(
                        "angular.ideas.index.sort.dateDesc"
                    ),
                    value: "-label.dateCreated"
                });
            });
            $translate("angular.ideas.index.sort.dateAsc").then(function(val) {
                $scope.sortOptions.push({
                    label: $translate.instant(
                        "angular.ideas.index.sort.dateAsc"
                    ),
                    value: "label.dateCreated"
                });
            });
            $translate("angular.ideas.index.sort.title").then(function(val) {
                $scope.sortOptions.push({
                    label: $translate.instant("angular.ideas.index.sort.title"),
                    value: "label.title"
                });
            });
            $translate("angular.ideas.index.sort.byline").then(function(val) {
                $scope.sortOptions.push({
                    label: $translate.instant(
                        "angular.ideas.index.sort.byline"
                    ),
                    value: "label.byline"
                });
            });

            /**
             * Fetch Ideas (All):  List ideas for a program
             */
            var unbind = $scope.$watch("programId", function() {
                var statePromise = idea.getStates($scope.programId);
                // Load states first via promise to avoid race condition as well as loading states twice when ng-model changes.  Lame, I know.
                statePromise.then(function(result) {
                    $scope.states = result.data;
                    $scope.getContentTypes();
                });
                unbind();
            });

            $scope.nextMonth = function() {
                if ($scope.chosenMonth == 11) {
                    $scope.chosenMonth = 0;
                    $scope.chosenYear++;
                } else {
                    $scope.chosenMonth++;
                }
                $scope.listIdeas("planner");
            };

            $scope.previousMonth = function() {
                if ($scope.chosenMonth == 0) {
                    $scope.chosenMonth = 11;
                    $scope.chosenYear--;
                } else {
                    $scope.chosenMonth--;
                }
                $scope.listIdeas("planner");
            };

            $scope.gotoCurrentMonth = function() {
                $scope.chosenMonth = $scope.thisMonth;
                $scope.chosenYear = $scope.thisYear;
                $scope.listIdeas("planner");
            };

            $scope.$watch("chosenMonth", function() {
                var months;
                $translate("zz_usedin.js.monthname").then(function(val) {
                    months = val.replace(/[;'\]\[]/gi, "").split(",");
                    if (
                        months.length == 12 &&
                        $scope.chosenMonth < months.length
                    ) {
                        $scope.chosenMonthName = months[
                            $scope.chosenMonth
                        ].trim();
                    } else $scope.chosenMonthName = $scope.chosenMonth;
                });
                $scope.isCurrentMonth =
                    $scope.thisMonth == $scope.chosenMonth &&
                    $scope.thisYear == $scope.chosenYear;
            });

            $scope.$watch("programId", function() {
                var stateId = parseInt($scope.state, 10);
                if (stateId !== $scope.idea.currentStateId && !isNaN(stateId)) {
                    $scope.listIdeas();
                    // TO-DO: Need to turn this into a promose.then()
                    $timeout(function() {
                        $scope.formFormat();
                    }, 500);
                }
            });

            $scope.resetForm = function() {
                // Reset Fields
                $scope.sortOption = "-dateCreated";
                $scope.contentType = "-1";
                return false;
            };

            $scope.formFormat = function() {
                $("select").selectBoxIt();
                var elements = $("option");
                for (var i = 0; i < elements.length; i++) {
                    if (
                        elements[i].value == "? object:null ?" ||
                        elements[i].value == "? string:60 ?"
                    ) {
                        $(elements[i]).remove();
                    }
                }
                // Reset any values in select form elements
                $timeout(function() {
                    $("select").selectBoxIt("refresh");
                    $scope.formReady = true;
                    $("section.ideasSection")
                        .css("visibility", "visible")
                        .delay(500);
                }, 500);
            };

            $scope.getContentTypes = function() {
                idea.getContentTypes($scope.programId, function(data) {
                    $scope.contentTypeFilter = data;
                });
            };

            function setSessionFilters() {
                $scope.savedFilters = {
                    contentType: $scope.contentType,
                    sortOption: $scope.sortOption,
                    state: $scope.state == null ? "-1" : $scope.state,
                    backlogDetails: $scope.backlogDetails,
                    backlogCollapsed: $scope.backlogCollapsed,
                    chosenMonth: $scope.chosenMonth,
                    chosenYear: $scope.chosenYear,
                    programId: $scope.programId
                };
                $window.sessionStorage.setItem(
                    "ideaListFilters",
                    JSON.stringify($scope.savedFilters)
                );
            }

            $scope.listIdeas = function(whichSet) {
                setSessionFilters();
                if ($scope.state == -1) $scope.state = null;

                if (
                    whichSet == "planner" ||
                    whichSet == null ||
                    whichSet == ""
                ) {
                    $(".idea-planner .list-loading").show();
                    idea.getIdeas(
                        $scope.chosenMonth + 1,
                        $scope.chosenYear,
                        $scope.state,
                        $scope.programId,
                        $scope.contentType,
                        "planner",
                        function(data) {
                            $scope.listIdeaPlanning = data;
                            $(".idea-planner .list-loading").hide();
                            var sessionPlannerList = [];
                            angular.forEach($scope.models, function(list) {
                                if (list.listName == "Planner") {
                                    list.items.length = 0;
                                    for (
                                        var i = 0;
                                        i <=
                                        $scope.listIdeaPlanning
                                            .contentRequestList.length;
                                        ++i
                                    ) {
                                        list.items.push({
                                            label:
                                                $scope.listIdeaPlanning
                                                    .contentRequestList[i]
                                        });
                                        if (
                                            typeof $scope.listIdeaPlanning
                                                .contentRequestList[i] !==
                                            "undefined"
                                        ) {
                                            sessionPlannerList.push(
                                                $scope.listIdeaPlanning
                                                    .contentRequestList[i]
                                            );
                                        }
                                    }
                                }
                            });
                            var sortField = $scope.sortOption.split(".")[1];
                            var reverseOrder =
                                $scope.sortOption.indexOf("-") >= 0;
                            $window.sessionStorage.setItem(
                                "plannerList",
                                JSON.stringify(
                                    $filter("orderBy")(
                                        sessionPlannerList,
                                        sortField,
                                        reverseOrder
                                    )
                                )
                            );
                            adjustColumnHeight();
                        }
                    );

                    idea.getKpi(
                        $scope.programId,
                        $scope.chosenMonth + 1,
                        $scope.chosenYear,
                        function(kpiData) {
                            $scope.kpi = kpiData;
                        }
                    );
                }

                if (
                    whichSet == "pending" ||
                    whichSet == null ||
                    whichSet == ""
                ) {
                    $(".idea-pending .list-loading").show();
                    idea.getIdeas(
                        null,
                        null,
                        null,
                        $scope.programId,
                        $scope.contentType,
                        "pending",
                        function(data) {
                            $scope.listIdeaPending = data;
                            var sessionPendingList = [];
                            angular.forEach($scope.models, function(list) {
                                if (list.listName == "Pending") {
                                    list.items.length = 0;
                                    for (
                                        var j = 0;
                                        j <=
                                        $scope.listIdeaPending
                                            .contentRequestList.length;
                                        ++j
                                    ) {
                                        list.items.push({
                                            label:
                                                $scope.listIdeaPending
                                                    .contentRequestList[j]
                                        });
                                        if (
                                            typeof $scope.listIdeaPending
                                                .contentRequestList[j] !==
                                            "undefined"
                                        ) {
                                            sessionPendingList.push(
                                                $scope.listIdeaPending
                                                    .contentRequestList[j]
                                            );
                                        }
                                    }
                                }
                            });
                            $(".idea-pending .list-loading").hide();
                            if ($scope.backlogCollapsed) {
                                $scope.closeBacklog();
                            }
                            var sortField = $scope.sortOption.split(".")[1];
                            var reverseOrder =
                                $scope.sortOption.indexOf("-") >= 0;
                            $window.sessionStorage.setItem(
                                "pendingList",
                                JSON.stringify(
                                    $filter("orderBy")(
                                        sessionPendingList,
                                        sortField,
                                        reverseOrder
                                    )
                                )
                            );
                        }
                    );
                }
                $scope.clearSelections();
                $scope.activateButtons();
            };

            $scope.toggleBacklogMoreLess = function() {
                $scope.backlogDetails = !$scope.backlogDetails;
                setSessionFilters();
            };

            var toggleSelectNoneValue = function(whichSet) {
                var chex = $(
                    ".idea-" +
                        whichSet.toLowerCase() +
                        " .list-results .list-results ul li md-checkbox.md-checked"
                );
                if (!$scope.enableMonthlyIdeation) {
                    chex = $(
                        ".idea-full" +
                            " .list-results .list-results ul li md-checkbox.md-checked"
                    );
                }
                if (chex.length > 0) {
                    $scope.showSelectNone[whichSet.toLowerCase()] = true;
                } else $scope.showSelectNone[whichSet.toLowerCase()] = false;
            };

            $scope.toggleSelectAll = function(whichSet) {
                // select none
                //selectNone(whichSet);
                if ($scope.showSelectNone[whichSet.toLowerCase()]) {
                    selectNone(whichSet);
                } else selectAll(whichSet);
            };

            var selectNone = function(whichSet) {
                angular.forEach($scope.models, function(list) {
                    var found = -1;
                    if (list.listName == whichSet) {
                        angular.forEach(list.items, function(listItem) {
                            listItem.selected = false;
                            found = jQuery.inArray(
                                listItem.label,
                                $scope.itemsToProcess
                            );
                            if (found >= 0) {
                                // Element was found, remove it
                                $scope.itemsToProcess.splice(found, 1);
                                $scope.removeStateFromList(
                                    listItem.label.stateId
                                );
                            } else {
                                // Element was not found
                            }
                        });
                        var chex = $(
                            ".idea-" + whichSet.toLowerCase() + " md-checkbox"
                        );
                        if (!$scope.enableMonthlyIdeation) {
                            chex = $(".idea-full" + " md-checkbox");
                        }
                        chex.removeClass("md-checked");
                        $scope.activateButtons();
                        toggleSelectNoneValue(whichSet);
                    }
                });
            };

            var selectAll = function(whichSet) {
                var ul = $(
                    ".idea-" +
                        whichSet.toLowerCase() +
                        " .list-results .list-results ul"
                );
                if (!$scope.enableMonthlyIdeation) {
                    ul = $(".idea-full" + " .list-results .list-results ul");
                }
                ul.children("li").each(function() {
                    var el = $(this);
                    var chkbx = el.find("md-checkbox");
                    if (!el.hasClass("selected")) {
                        el.addClass("selected");
                        chkbx.addClass("md-checked");
                    }
                });

                angular.forEach($scope.models, function(list) {
                    var found = -1;
                    if (list.listName == whichSet) {
                        angular.forEach(list.items, function(listItem) {
                            listItem.selected = true;
                            found = jQuery.inArray(
                                listItem.label,
                                $scope.itemsToProcess
                            );
                            if (found >= 0) {
                                // Element was found
                            } else {
                                // Element was not found, add it.
                                if (
                                    listItem !== undefined &&
                                    listItem.label !== undefined
                                ) {
                                    $scope.itemsToProcess.push(listItem.label);
                                    $scope.addStateToList(
                                        listItem.label.stateId
                                    );
                                }
                            }
                        });
                    }
                });
                $scope.activateButtons();
                toggleSelectNoneValue(whichSet);
            };

            $scope.isOKtoToggle = true;
            $scope.toggleBacklog = function() {
                if ($scope.isOKtoToggle) {
                    $scope.isOKtoToggle = false;
                    // $(".toggler").hide();
                    $(".toggler").css("pointer-events", "none");
                    $(".idea-pending h2").css("pointer-events", "none");
                    setTimeout(function() {}, 700);

                    if ($(".idea-pending").hasClass("collapsed")) {
                        $(".idea-pending").removeClass("collapsed");
                        $(".idea-pending").animate(
                            { width: "34%" },
                            {
                                duration: 350,
                                complete: function() {
                                    $timeout(function() {
                                        $scope.isOKtoToggle = true;
                                        $(".toggler").css(
                                            "pointer-events",
                                            "auto"
                                        );
                                        $(".idea-pending h2").css(
                                            "pointer-events",
                                            "auto"
                                        );
                                        // $(".toggler").fadeIn();
                                    }, 1000);

                                    $(".idea-pending")
                                        .children()
                                        .not(".backlog-controls, .list-loading")
                                        .fadeIn(350, function() {});
                                    $(".toggler-text").fadeIn(150);
                                    $(".idea-pending").css(
                                        "min-height",
                                        "initial"
                                    );
                                }
                            }
                        );
                        $(".idea-planner")
                            .animate({ width: "64%" }, 350)
                            .removeClass("expanded");
                        $scope.backlogCollapsed = false;
                        setSessionFilters();
                    } else {
                        $timeout(function() {
                            $scope.isOKtoToggle = true;
                            $(".toggler").css("pointer-events", "auto");
                            $(".idea-pending h2").css("pointer-events", "auto");
                            // $(".toggler").fadeIn();
                        }, 800);
                        $(".idea-pending").css(
                            "min-height",
                            $(".idea-pending").height()
                        );
                        $(".idea-pending")
                            .children()
                            .not(".backlog-controls, .list-loading")
                            .fadeOut(150, function() {});
                        $(".idea-pending")
                            .delay(150)
                            .animate(
                                { width: "30px" },
                                {
                                    duration: 350,
                                    complete: function() {
                                        $(".idea-pending").addClass(
                                            "collapsed"
                                        );
                                    }
                                }
                            );
                        $(".idea-planner")
                            .delay(150)
                            .animate({ width: "95%" }, 350)
                            .addClass("expanded");

                        $(".toggler-text").fadeOut(150);
                        $scope.backlogCollapsed = true;
                        setSessionFilters();
                    }
                }
            };

            function sticky_ideaActionPanel() {
                try {
                    var window_top = $(window).scrollTop(),
                        ideaPanelAnchor = $("#ideaActionPanelAnchor"),
                        div_top =
                            0 < ideaPanelAnchor.length
                                ? ideaPanelAnchor.offset().top
                                : 0;
                    if (window_top > div_top) {
                        $("#idea-action-panel").addClass(
                            "ideaActionPanelFixed"
                        );
                    } else {
                        $("#idea-action-panel").removeClass(
                            "ideaActionPanelFixed"
                        );
                    }
                } catch (e) {
                    console.error(e);
                }
            } // end of sticky_ideaActionPanel
            $(function() {
                $(window).scroll(sticky_ideaActionPanel);
                sticky_ideaActionPanel();
            });

            $scope.actionButtonClicked = function(evt, btn) {
                if (btn.stateId == 64) {
                    $scope.showConfirm(evt, btn);
                } else if (btn.stateId == 2) {
                    $window.location.href =
                        "/assignmentModify.action?contentRequestId=" +
                        $scope.itemsToProcess[0].contentRequestId +
                        "&referrerPage=ideas.action";
                } else {
                    $scope.updateItemState(evt, btn, null);
                }
            };

            $scope.updateItemState = function(evt, btn, comments) {
                var crs = [];
                angular.forEach($scope.itemsToProcess, function(itm) {
                    var cr = {
                        contentRequestId: itm.contentRequestId,
                        declineReason: comments
                    };
                    crs.push(cr);
                });

                var newStatePromise = idea.saveWorkflowMulti(
                    $scope.programId,
                    crs,
                    btn.stateId
                );
                newStatePromise.then(function(result) {
                    if (btn.stateId == 2) {
                        $window.location.href =
                            "/assignmentModify.action?contentRequestId=" +
                            result.data[0].contentRequestId;
                    } else {
                        $scope.listIdeas();
                        $scope.showFeedback(result);
                    }
                });
            };

            $scope.showConfirm = function($event, btn) {
                $scope.modalConfirm =
                    $scope.itemsToProcess.length > 1
                        ? $translate.instant(
                              "angular.ideas.menu.modalConfirmMultiple"
                          )
                        : $translate.instant("angular.ideas.menu.modalConfirm");
                $scope.modalConfirmButton =
                    $scope.itemsToProcess.length > 1
                        ? $translate.instant(
                              "angular.ideas.menu.modalConfirmButtonMultiple"
                          )
                        : $translate.instant(
                              "angular.ideas.menu.modalConfirmButton"
                          );
                $scope.modalCancel = $translate.instant(
                    "angular.ideas.menu.modalCancel"
                );
                $scope.modalContent =
                    $scope.itemsToProcess.length > 1
                        ? $translate.instant(
                              "angular.ideas.menu.modalDeclineReasonMultiple"
                          )
                        : $translate.instant(
                              "angular.ideas.menu.modalDeclineReason"
                          );
                $mdDialog
                    .show({
                        controller: "DialogController",
                        templateUrl:
                            "/partials/ng-material-default-overlay-comments.html",
                        parent: angular.element(document.body),
                        clickOutsideToClose: true,
                        locals: {
                            title: $scope.modalConfirm,
                            ok: $scope.modalConfirmButton,
                            cancel: $scope.modalCancel,
                            content: $scope.modalContent
                        }
                    })
                    .then(
                        function(comments) {
                            $scope.updateItemState($event, btn, comments);
                        },
                        function() {
                            $scope.activateButtons();
                        }
                    );
            };

            var feedbackTimeout;
            $scope.showFeedback = function(result) {
                var successCount = 0;
                var failureCount = 0;
                angular.forEach(result.data, function(resultItem) {
                    if (resultItem.stateChangeSuccess == true) {
                        successCount++;
                    } else {
                        failureCount++;
                    }
                });

                var outputSuccess = $translate.instant(
                    "angular.ideas.list.feedback.success",
                    { count: successCount }
                );
                var outputFailure = $translate.instant(
                    "angular.ideas.list.feedback.failure",
                    { count: failureCount }
                );

                var hasFeedback = false;
                clearTimeout(feedbackTimeout);
                $("#success-box .content").html("");
                $("#error-box .content").html("");

                if (successCount > 0) {
                    $("#success-box .content").append(
                        "<li>" +
                            $("<span/>")
                                .html(outputSuccess)
                                .text() +
                            "</li>"
                    );
                    $("#success-box").slideDown("slow");
                    hasFeedback = true;
                    feedbackTimeout = $timeout(function() {
                        $("#success-box").slideUp("slow", function() {
                            $("#success-box .content").html("");
                        });
                    }, 6000);
                }
                if (failureCount > 0) {
                    $("#error-box .content").append(
                        "<li>" +
                            $("<span/>")
                                .html(outputFailure)
                                .text() +
                            "</li>"
                    );
                    $("#error-box").slideDown("slow");
                    hasFeedback = true;
                    feedbackTimeout = $timeout(function() {
                        $("#error-box").slideUp("slow", function() {
                            $("#error-box .content").html("");
                        });
                    }, 6000);
                }
                if (hasFeedback) {
                    skyword.scrollTo("#success-box", 20);
                }
                $scope.clearSelections();
            };
            $scope.itemClicked = function(item, evt) {
                var el = $("#" + evt.currentTarget.id);
                var chkbx = el.find("md-checkbox");
                var found = -1;

                if (el.hasClass("selected")) {
                    chkbx.removeClass("md-checked");
                    // remove it
                    found = jQuery.inArray(item.label, $scope.itemsToProcess);
                    if (found >= 0) {
                        // Element was found, remove it
                        $scope.itemsToProcess.splice(found, 1);
                        $scope.removeStateFromList(item.label.stateId);
                    } else {
                        // Element was not found
                    }
                } else {
                    chkbx.addClass("md-checked");
                    // add it
                    found = jQuery.inArray(item.label, $scope.itemsToProcess);
                    if (found >= 0) {
                        // Element was found
                    } else {
                        // Element was not found, add it.
                        $scope.itemsToProcess.push(item.label);
                        $scope.addStateToList(item.label.stateId);
                    }
                }
                $scope.activateButtons();

                var whichSet = "";
                if (el.attr("id").indexOf("planner") >= 0) {
                    whichSet = "Planner";
                } else if (el.attr("id").indexOf("pending") >= 0) {
                    whichSet = "Pending";
                }
                toggleSelectNoneValue(whichSet);
            };

            $scope.activateButtons = function() {
                $(".idea-list-action-button")
                    .prop("disabled", "true")
                    .addClass("disabled");
                if (
                    ($scope.selectedStates.length == 2 &&
                        jQuery.inArray(61, $scope.selectedStates) >= 0 &&
                        jQuery.inArray(62, $scope.selectedStates) >= 0) ||
                    ($scope.selectedStates.length == 1 &&
                        jQuery.inArray(62, $scope.selectedStates) >= 0)
                ) {
                    // show approve and decline
                    $('.idea-list-action-button[data-tostate="' + 63 + '"]')
                        .removeProp("disabled")
                        .removeClass("disabled");
                    $('.idea-list-action-button[data-tostate="' + 64 + '"]')
                        .removeProp("disabled")
                        .removeClass("disabled");
                } else if (
                    $scope.selectedStates.length == 1 &&
                    jQuery.inArray(61, $scope.selectedStates) >= 0
                ) {
                    // show approve, decline, review
                    $('.idea-list-action-button[data-tostate="' + 62 + '"]')
                        .removeProp("disabled")
                        .removeClass("disabled");
                    $('.idea-list-action-button[data-tostate="' + 63 + '"]')
                        .removeProp("disabled")
                        .removeClass("disabled");
                    $('.idea-list-action-button[data-tostate="' + 64 + '"]')
                        .removeProp("disabled")
                        .removeClass("disabled");
                } else if (
                    $scope.enableMonthlyIdeation == false &&
                    $scope.selectedStates.length == 1 &&
                    jQuery.inArray(60, $scope.selectedStates) >= 0
                ) {
                    // show make assignment (for ideas-.html)
                    $('.idea-list-action-button[data-tostate="' + 2 + '"]')
                        .removeProp("disabled")
                        .removeClass("disabled");
                    $('.idea-list-action-button[data-tostate="' + 64 + '"]')
                        .removeProp("disabled")
                        .removeClass("disabled");
                } else if ($scope.selectedStates.length >= 1) {
                    // show just decline
                    $('.idea-list-action-button[data-tostate="' + 64 + '"]')
                        .removeProp("disabled")
                        .removeClass("disabled");
                } else {
                    // show none which was set at top of this function
                }
            };

            $scope.addStateToList = function(stateId) {
                var found = jQuery.inArray(stateId, $scope.selectedStates);
                if (found >= 0 && $scope.enableMonthlyIdeation == true) {
                    // Element was found
                } else {
                    // Element was not found, add it.
                    $scope.selectedStates.push(stateId);
                }
            };

            $scope.isStateInList = function(stateId) {
                var found = false;
                for (var i = 0; i < $scope.itemsToProcess.length; i++) {
                    if ($scope.itemsToProcess[i].stateId == stateId) {
                        found = true;
                        break;
                    }
                }
                return found;
            };

            $scope.removeStateFromList = function(stateId) {
                var found = jQuery.inArray(stateId, $scope.selectedStates);
                var needsToStay = $scope.isStateInList(stateId);
                if (
                    (found >= 0 && !needsToStay) ||
                    $scope.enableMonthlyIdeation == false
                ) {
                    // Element was found, remove it
                    $scope.selectedStates.splice(found, 1);
                } else {
                    // Element was not found
                }
            };

            $scope.clearSelections = function() {
                $("md-checkbox").removeClass("md-checked");
                angular.forEach($scope.models, function(list) {
                    angular.forEach(list.items, function(listItem) {
                        listItem.selected = false;
                    });
                    toggleSelectNoneValue(list.listName);
                });
                $scope.itemsToProcess.length = 0;
                $scope.selectedStates.length = 0;
                $scope.activateButtons();
            };

            /* DRAG AND DROP FUNCTIONALITY */

            $scope.models = [
                { listName: "Pending", items: [], dragging: false },
                { listName: "Planner", items: [], dragging: false }
            ];

            /**
             * dnd-dragging determines what data gets serialized and send to the receiver
             * of the drop. While we usually just send a single object, we send the array
             * of all selected items here.
             */
            $scope.getSelectedItemsIncluding = function(list, item) {
                item.selected = true;
                return list.items.filter(function(item) {
                    return item.selected;
                });
            };

            /**
             * We set the list into dragging state, meaning the items that are being
             * dragged are hidden. We also use the HTML5 API directly to set a custom
             * image, since otherwise only the one item that the user actually dragged
             * would be shown as drag image.
             */

            var img1 = new Image();
            img1.src = "/images/MoveIdea.png";
            var img2 = new Image();
            img2.src = "/images/MoveIdeas.png";

            $scope.onDragstart = function(list, event) {
                $scope.fromList = list.listName;
                list.dragging = true;
                var count = $(
                    ".idea-" + list.listName.toLowerCase() + " li.selected"
                ).length;
                if (event.dataTransfer.setDragImage) {
                    if (count <= 1) {
                        event.dataTransfer.setDragImage(img1, 0, 0);
                    } else {
                        event.dataTransfer.setDragImage(img2, 0, 0);
                    }
                }
            };

            /**
             * In the dnd-drop callback, we now have to handle the data array that we
             * sent above. We handle the insertion into the list ourselves. By returning
             * true, the dnd-list directive won't do the insertion itself.
             */
            $scope.onDrop = function(list, items, index, event) {
                var returnVal = false;
                $scope.toList = list.listName;
                angular.forEach(items, function(item) {
                    item.selected = false;
                });

                if ($scope.fromList != $scope.toList) {
                    var crIds = [];
                    var crs = [];
                    angular.forEach(items, function(item) {
                        if (item.label && item.label.contentRequestId) {
                            crs.push({
                                contentRequestId: item.label.contentRequestId
                            });
                            crIds.push(item.label.contentRequestId);
                        }
                    });
                    var targetDate = new Date(
                        $scope.chosenYear,
                        $scope.chosenMonth
                    ).valueOf();

                    if ($scope.toList == "Planner") {
                        var saveTargetDatesPromise = idea.saveTargetDatesMulti(
                            $scope.programId,
                            crIds,
                            targetDate
                        );
                        saveTargetDatesPromise.then(function(result) {
                            $scope.listIdeas();
                            $scope.showFeedback(result);
                            returnVal = true;
                        });
                    } else if ($scope.toList == "Pending") {
                        var returnToBacklogPromise = idea.saveWorkflowMulti(
                            $scope.programId,
                            crs,
                            60
                        );
                        returnToBacklogPromise.then(function(result) {
                            $scope.listIdeas();
                            $scope.showFeedback(result);
                            returnVal = true;
                        });
                    } else {
                        returnVal = false;
                    }
                } else {
                    returnVal = false;
                }
                $scope.toList = "";
                $scope.fromList = "";
                return returnVal;
            };

            /**
             * Last but not least, we have to remove the previously dragged items in the
             * dnd-moved callback.
             */
            $scope.onMoved = function(list) {
                list.items = list.items.filter(function(item) {
                    return !item.selected;
                });
            };
        }
    ]).filter("ListByState", [
        "SkyFactory",
        function(SkyFactory) {
            return function(input, sid) {
                console.log(input);
                if (SkyFactory.exists(input)) {
                    var out = [];
                    for (var i = 0; i < input.length; i++) {
                        if (input[i].stateId == sid) {
                            out.push(input[i]);
                        }
                    }
                    return out;
                }
            };
        }
    ]);

    app.controller("ideas", ["$scope", function($scope) {}])
        .filter("strongEmHtml", function() {
            return function(text) {
                var s = "<div>" + text + "</div>";
                var $s = jQuery(s);
                $s.find("*")
                    .not("strong,em")
                    .each(function() {
                        jQuery(this).replaceWith(this.innerHTML);
                    });
                //console.log($s.html());
                return $s.html();
            };
        })
        .filter("noHTML", function() {
            return function(text) {
                var s = "<div>" + text + "</div>";
                var $s = jQuery(s);
                //console.log($s.text());
                return $s.text();
            };
        });
})();

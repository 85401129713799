app.service("rfp", [
    "$http",
    function($http) {
        return {
            getBids: function(contentRequestId, callback) {
                $http({
                    url: "/API/rfp/bids/" + contentRequestId,
                    method: "GET"
                })
                .success(function(data, status, headers, config) {
                    callback(data);
                })
                .error(function(data, status, headers, config) {
                    console.log(data);
                });
            },
            getData: function(contentRequestId, callback) {
                $http({
                    url: "/API/rfp/dataFor/" + contentRequestId,
                    method: "GET"
                })
                .success(function(data, status, headers, config) {
                    callback(data);
                })
                .error(function(data, status, headers, config) {
                    console.log(data);
                });
            },
            getAcceptedBids: function(contentRequestId, callback) {
                $http({
                    url: "/API/rfp/accepted-bids/" + contentRequestId,
                    method: "GET"
                })
                .success(function(data, status, headers, config) {
                    callback(data);
                })
                .error(function(data, status, headers, config) {
                    console.log(data);
                });
            }
        };
    }
]);
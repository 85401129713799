app.service("invite", [
    "$http",
    function($http) {
        return {
            checkEmailAddresses: checkEmailAddresses,
            checkEmailAddress: checkEmailAddress,
            submitBulkInvite: submitBulkInvite,
            checkContributorId: checkContributorId
        };

        function checkEmailAddresses(programId, emailAddresses) {
            return $http.put(
                "/API/invite-contributor/check/" + programId,
                emailAddresses
            );
        }

        function submitBulkInvite(bulkInvite, programId) {
            return $http.put(
                "/API/invite-contributor/submit/" + programId,
                bulkInvite
            );
        }

        function checkEmailAddress(programId, emailAddress) {
            return $http.put(
                "/API/invite-contributor/checkAgain/" + programId,
                emailAddress
            );
        }

        function checkContributorId(programId, contributorId) {
            return $http.put(
                "/API/invite-contributor/checkContributorId/" +
                    programId +
                    "/" +
                    contributorId
            );
        }

        function inviteWriters(programId, calendarShareId) {
            var url = "/API/program/" + programId + "/calendar-filter/colors";
            if (undefined !== calendarShareId && null !== calendarShareId) {
                url += "?calendarShareId=" + calendarShareId;
            }
            return $http.get(url);
        }
    }
]);

app.controller("inviteContributors", [
    "$scope",
    "invite",
    "enterprise",
    "program",
    "SkyFactory",
    "$translate",
    "$window",
    "$filter",
    function(
        $scope,
        invite,
        enterprise,
        program,
        skyfactory,
        $translate,
        $window,
        $filter
    ) {
        $scope.returnURL = document.referrer;
        if ($scope.returnURL.indexOf("agencyWriters.action") == -1) {
            var index = $scope.returnURL.lastIndexOf("/");
            $scope.returnURL = $scope.returnURL.substring(0, index);
            $scope.returnURL = $scope.returnURL + "/invitationsWriters.action";
        }
        $scope.programAttempts = 0;

        $scope.invite = {
            selectedProgram: null,
            programs: [],
            contentTypesAndCategories: [],
            emailAddressList: "",
            emailAddresses: []
        };
        let isPopulateEmail = $("#inviteFromProfile").text();
        let contributorEmailAddress = $("#inviteContributorByEmailFromProfile").text();
        if(isPopulateEmail) {
            $scope.invite.emailAddressList = $("#inviteContributorByEmailFromProfile").text();
        }

        $scope.inviteContributors = {
            processed: false
        };
        $scope.errorsList = {
            errorEmailSubmit: {
                show: false,
                message: "angular.message.separatorError"
            },
            missingContributorId: {
                show: false,
                message: "angular.message.customeridmissing"
            },
            missingCategories: {
                show: false,
                message: "angular.message.categorymissing"
            },
            missingCustomContentType: {
                show: false,
                message: "angular.message.invite.contentTypeRequired"
            },
            invitationsContainErrors: {
                show: false,
                message: "angular.message.invite.invalidInvitations"
            },
            invalidCustomPayment: {
                show: false,
                message: "angular.message.compinvalidpaymentrates"
            }
        };
        console.log(invite.selectedProgram + "" + invite.emailAddressList);

        var unbindWatcher = $scope.$watch("agencyId", function() {
            retrievePrograms();
            unbindWatcher();
        });

        var retrievePrograms = function() {
            $scope.programAttempts += 1;
            var strutsAgencyId = angular.element("#agencyId").attr("value");
            console.log("scope agencyId: " + $scope.agencyId);
            console.log("struts agencyId: " + strutsAgencyId);

            var programPromise = enterprise.getPrograms(strutsAgencyId);

            programPromise.then(function(programs) {
                console.log("programs retrieved");
                $scope.invite.programs = angular.copy(programs.data);

                if ($scope.programId) {
                    angular.forEach($scope.invite.programs, function(
                        program,
                        key
                    ) {
                        if (program.id == $scope.programId) {
                            $scope.invite.selectedProgram = program;
                        }
                    });
                }

                if (
                    $scope.invite.programs.length == 0 &&
                    $scope.programAttempts < 6
                ) {
                    retrievePrograms();
                }
            });
        };

        $scope.submitEmails = function() {
            //check to see if list contains , ; or \n
            $scope.disableNextButton = true;
            $scope.errors = false;
            $scope.errorsList.errorEmailSubmit.show = false;
            var valid = false;
            var emails = angular.copy($scope.invite.emailAddressList);

            //make a copy, in case of error

            if (emails.indexOf(" ") > -1) {
                //console.log('Before remove space:' + emails);
                //console.log('Removing space');
                emails = emails.replace(/ /g, "");
                //console.log(emails);
            }

            if (emails.indexOf(",") > -1) {
                //console.log('Contains ,');
                if (emails.indexOf(";") > -1 || emails.indexOf("\n") > -1) {
                    //mixed delimiters
                    valid = false;
                } else {
                    $scope.invite.emailAddresses = emails.split(",");
                    valid = true;
                }
            } else if (emails.indexOf(";") > -1) {
                //console.log('Contains ;');
                if (emails.indexOf(",") > -1 || emails.indexOf("\n") > -1) {
                    //mixed delimiters
                    valid = false;
                } else {
                    $scope.invite.emailAddresses = emails.split(";");
                    valid = true;
                }
            } else if (emails.indexOf("\n") > -1) {
                //console.log('Contains carrage return');
                $scope.invite.emailAddresses = emails.split("\n");
                valid = true;
            } else {
                $scope.invite.emailAddresses = emails.split(" ");
                valid = true;
            }

            if (valid) {
                //console.log($scope.invite.emailAddresses);

                var uniqueEmails = [];

                $.each($scope.invite.emailAddresses, function(i, value) {
                    if ($.inArray(value, uniqueEmails) === -1) {
                        uniqueEmails.push(value);
                    }
                });

                //console.log('Unique emails: ' + uniqueEmails);

                //successful processing, show information
                var processPromise = invite.checkEmailAddresses(
                    $scope.invite.selectedProgram.id,
                    uniqueEmails
                );
                processPromise.then(function(response) {
                    $scope.bulkInvite = response.data;
                    $scope.inviteContributors.processed = true;
                    $scope.disableNextButton = false;
                });
            } else {
                $scope.errors = true;
                $scope.errorsList.errorEmailSubmit.show = true;
                $scope.disableNextButton = false;
            }
        };

        $scope.submitBulkInvite = function() {
            $scope.bulkInviteSubmitted = true;
            console.log("Bulk Invite Obj::::", $scope.bulkInvite);
            var success = validateForm();
            if (!success) {
                $window.scrollTo(0, 0);
                $scope.bulkInviteSubmitted = false;
            } else {
                var processPromise = invite.submitBulkInvite(
                    $scope.bulkInvite,
                    $scope.invite.selectedProgram.id
                );
                processPromise.then(
                    function(result) {
                        $scope.test = result.data;
                        if (success) {
                            $window.location.href = $scope.returnURL;
                            $scope.bulkInviteSubmitted = false;
                        }
                    },
                    function(error) {
                        $window.location.href = "/errorPage.action";
                    }
                );
            }
        };

        $scope.getContentTypesAndCategories = function() {
            var contentTypeCategory = program.contentTypesAndCategories(
                $scope.invite.selectedProgram.id
            );
            console.log($scope.invite.selectedProgram);
            contentTypeCategory.then(function(contentTypesAndCategories) {
                contentTypesAndCategories.data.forEach(item => {
                    item.categoryList.forEach((category) => {
                        category.selected = true;
                    });
                });
                console.info(contentTypesAndCategories.data);
                $scope.invite.contentTypesAndCategories =
                    contentTypesAndCategories.data;
            });
        };

        $scope.atLeastOneCategorySelected = function(contentType) {
            var show = false;
            if(contentType.isDigitalAsset){
                angular.forEach(contentType.categoryList, function(category, key) {
                    category.selected = false
                });
            }else{
            angular.forEach(contentType.categoryList, function(category, key) {
                if (category.selected) {
                    show = true;
                }
            });
        }
            return show;
        };

        $scope.revalidateEmailAddress = function(
            inviteToBeRechecked,
            currentInviteList
        ) {
            inviteToBeRechecked.tempEmail = undefined;
            inviteToBeRechecked.isEditing = undefined;

            var validation = invite.checkEmailAddress(
                $scope.invite.selectedProgram.id,
                inviteToBeRechecked.email
            );
            var validatedInvite;
            validation.then(function(returnedInvite) {
                validatedInvite = returnedInvite.data;
                if (!skyfactory.isUndefinedOrNull(validatedInvite)) {
                    if (
                        !skyfactory.isUndefinedOrNull(
                            validatedInvite.accountId
                        ) ||
                        validatedInvite.errorMessage ===
                            "angular.message.cannotBeUsed"
                    ) {
                        currentInviteList.splice(
                            currentInviteList.indexOf(inviteToBeRechecked),
                            1
                        );
                        if (
                            !skyfactory.isUndefinedOrNull(
                                validatedInvite.errorMessage
                            )
                        ) {
                            if (
                                validatedInvite.errorMessage ==
                                "angular.message.cannotBeUsed"
                            ) {
                                $scope.bulkInvite.invalidInvitesAdmin.push(
                                    validatedInvite
                                );
                            } else {
                                $scope.bulkInvite.invalidInvitesOther.push(
                                    validatedInvite
                                );
                            }
                        } else {
                            $scope.bulkInvite.validExistingInvites.push(
                                validatedInvite
                            );
                        }
                    } else {
                        //$scope.bulkInvite.validNewInvites.push(validatedInvite);
                        inviteToBeRechecked.email = validatedInvite.email;
                        inviteToBeRechecked.errorMessage =
                            validatedInvite.errorMessage;
                    }
                }
            });
        };

        $scope.checkContributorId = function() {
            angular.forEach($scope.bulkInvite.validExistingInvites, function(
                inviteWithContributorId,
                key
            ) {
                if (!isDuplicateContributorId(inviteWithContributorId)) {
                    if (
                        !skyfactory.reallyEmptyCheck(
                            inviteWithContributorId.contributorId
                        )
                    ) {
                        var checkContributorIdPromise = invite.checkContributorId(
                            $scope.invite.selectedProgram.id,
                            inviteWithContributorId.contributorId
                        );
                        checkContributorIdPromise.then(function(response) {
                            var valid = response.data;
                            if (!valid) {
                                inviteWithContributorId.errorMessage =
                                    "angular.message.customeridnotavailable";
                            } else {
                                inviteWithContributorId.errorMessage = undefined;
                            }
                        });
                    } else {
                        inviteWithContributorId.errorMessage = undefined;
                    }
                } else {
                    inviteWithContributorId.errorMessage =
                        "angular.message.customeridnotavailable";
                }
            });
            angular.forEach($scope.bulkInvite.validNewInvites, function(
                inviteWithContributorId,
                key
            ) {
                if (!isDuplicateContributorId(inviteWithContributorId)) {
                    if (
                        !skyfactory.reallyEmptyCheck(
                            inviteWithContributorId.contributorId
                        )
                    ) {
                        var checkContributorIdPromise = invite.checkContributorId(
                            $scope.invite.selectedProgram.id,
                            inviteWithContributorId.contributorId
                        );
                        checkContributorIdPromise.then(function(response) {
                            var valid = response.data;
                            if (!valid) {
                                inviteWithContributorId.errorMessage =
                                    "angular.message.customeridnotavailable";
                            } else if (
                                inviteWithContributorId.errorMessage !=
                                "angular.message.invalidEmail"
                            ) {
                                inviteWithContributorId.errorMessage = undefined;
                            }
                        });
                    } else if (
                        inviteWithContributorId.errorMessage !=
                        "angular.message.invalidEmail"
                    ) {
                        inviteWithContributorId.errorMessage = undefined;
                    }
                } else {
                    inviteWithContributorId.errorMessage =
                        "angular.message.customeridnotavailable";
                }
            });
        };

        $scope.showContributorId = function(validInvite) {
            var enable = true;
            if (validInvite.errorMessage === "angular.message.invalidEmail") {
                //don't enable the id
                enable = false;
            }
            return enable;
        };

        $scope.showDeleteOverlay = function(inviteToDelete, listToDeleteFrom) {
            var translationData = {
                email: inviteToDelete.email
            };

            $scope.emailToDelete = inviteToDelete;
            $scope.listToDeleteFrom = listToDeleteFrom;

            var opts = {
                html:
                    '<div class="email-delete invite-choose-pane"><h1>' +
                    $translate.instant(
                        "angular.contributor.invite.deleteOverlay.areYouSure",
                        translationData
                    ) +
                    "</h1>" +
                    '<button class="standard" onclick="callAngularDelete()"><span>' +
                    $translate.instant("angular.button.delete") +
                    "</span></button>" +
                    '<a onclick="parent.$.colorbox.close();"><span>' +
                    $translate.instant("angular.button.cancel") +
                    "</span></a>" +
                    "</div>"
            };
            $.colorbox(opts);
        };

        window.callAngularDelete = function() {
            $scope.$apply(function(emailToDelete, listToDeleteFrom) {
                $scope.deleteEmail(
                    $scope.emailToDelete,
                    $scope.listToDeleteFrom
                );
            });
        };

        $scope.deleteEmail = function(emailToDelete, listToDeleteFrom) {
            var index = listToDeleteFrom.indexOf(emailToDelete);
            listToDeleteFrom.splice(index, 1);
            parent.$.colorbox.close();
            $scope.emailToDelete = undefined;
            $scope.listToDeleteFrom = undefined;
        };

        var isDuplicateContributorId = function(thisInvite) {
            var existingContributorId = false;
            angular.forEach($scope.bulkInvite.validNewInvites, function(
                thatInvite,
                key
            ) {
                if (
                    !skyfactory.reallyEmptyCheck(thisInvite.contributorId) &&
                    thatInvite.contributorId === thisInvite.contributorId &&
                    thisInvite.email != thatInvite.email
                ) {
                    existingContributorId = true;
                }
            });
            angular.forEach($scope.bulkInvite.validExistingInvites, function(
                thatInvite,
                key
            ) {
                if (
                    !skyfactory.reallyEmptyCheck(thisInvite.contributorId) &&
                    thatInvite.contributorId === thisInvite.contributorId &&
                    thisInvite.email != thatInvite.email
                ) {
                    existingContributorId = true;
                }
            });
            return existingContributorId;
        };

        var validateForm = function() {
            var requiredFieldsFilled = true;
            angular.forEach($scope.errorsList, function(error, key) {
                error.show = false;
            });
            //validNewInvites and validExistingInvites must contain no errors
            angular.forEach($scope.bulkInvite.validExistingInvites, function(
                invite,
                key
            ) {
                if (!skyfactory.isUndefinedOrNull(invite.errorMessage)) {
                    requiredFieldsFilled = false;
                    $scope.errorsList.invitationsContainErrors.show = true;
                }
            });
            angular.forEach($scope.bulkInvite.validNewInvites, function(
                invite,
                key
            ) {
                if (!skyfactory.isUndefinedOrNull(invite.errorMessage)) {
                    requiredFieldsFilled = false;
                    $scope.errorsList.invitationsContainErrors.show = true;
                }
            });
            //confirm all contributors have ids (if required)
            if ($scope.bulkInvite.contributorIdEnabled) {
                angular.forEach($scope.bulkInvite.validNewInvites, function(
                    thatInvite,
                    key
                ) {
                    if (skyfactory.reallyEmptyCheck(thatInvite.contributorId)) {
                        requiredFieldsFilled = false;
                        $scope.errorsList.missingContributorId.show = true;
                    }
                });
                angular.forEach(
                    $scope.bulkInvite.validExistingInvites,
                    function(thatInvite, key) {
                        if (
                            skyfactory.reallyEmptyCheck(
                                thatInvite.contributorId
                            )
                        ) {
                            requiredFieldsFilled = false;
                            $scope.errorsList.missingContributorId.show = true;
                        }
                    }
                );
            }
            var atLeastOneCategorySelected = false;
            angular.forEach(
                $scope.bulkInvite.contentTypesWithCategories,
                function(contentType, key) {
                    if ($scope.atLeastOneCategorySelected(contentType)) {
                        atLeastOneCategorySelected = true;
                    }
                }
            );
            $scope.errorsList.missingCategories.show = !atLeastOneCategorySelected;
            if (!atLeastOneCategorySelected) {
                requiredFieldsFilled = false;
            }
            if (
                $scope.bulkInvite.compensationId === 3 &&
                $scope.errorsList.missingCategories.show === false
            ) {
                var contentTypeSelected = false;
                angular.forEach(
                    $scope.bulkInvite.contentTypesWithCategories,
                    function(contentType, key) {
                        if (
                            contentType.selected &&
                            !skyfactory.isUndefinedOrNull(
                                contentType.customPaymentAmt
                            )
                        ) {
                            contentTypeSelected = true;
                        }
                        if (
                            contentType.selected &&
                            (skyfactory.isUndefinedOrNull(
                                contentType.customPaymentAmt
                            ) ||
                                0 > contentType.customPaymentAmt ||
                                2 < decimalPlaces(contentType.customPaymentAmt))
                        ) {
                            $scope.errorsList.invalidCustomPayment.show = true;
                            requiredFieldsFilled = false;
                        }
                    }
                );
                $scope.errorsList.missingCustomContentType.show = !contentTypeSelected;
                if (!contentTypeSelected) {
                    requiredFieldsFilled = false;
                }
            }
            return requiredFieldsFilled;
        };

        function decimalPlaces(num) {
            var match = ("" + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
            if (!match) {
                return 0;
            }
            return Math.max(
                0,
                (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0)
            );
        }

        $scope.editEmailAddress = function(inviteToEdit) {
            inviteToEdit.isEditing = true;
            inviteToEdit.tempEmail = angular.copy(inviteToEdit.email);
        };

        $scope.cancelEmailEdit = function(inviteBeingEdited) {
            inviteBeingEdited.isEditing = undefined;
            inviteBeingEdited.email = angular.copy(inviteBeingEdited.tempEmail);
            inviteBeingEdited.tempEmail = undefined;
        };

        $scope.cancelInvitation = function() {
            $window.location.reload();
            $window.scroll(0, 0);
            $scope.invite.emailAddressList = undefined;
            $scope.inviteContributors.processed = undefined;
        };

        $scope.disableBulkSubmit = function() {
            return $scope.bulkInviteSubmitted;
        };

        $scope.disableNext = function() {
            if (
                $scope.disableNextButton ||
                null === $scope.invite.selectedProgram ||
                undefined === $scope.invite.selectedProgram.id ||
                $scope.invite.emailAddressList == ""
            ) {
                return true;
            } else {
                return false;
            }
        };
    }
]);

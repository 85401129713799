app.service("delivery", [
    "$translate",
    "skyword",
    "call",
    "requestActive",
    function($translate, skyword, call, requestActive) {
        var runApiTest = function() {
                var options,
                    urlAction,
                    button = $("#runApiTest"),
                    message = $("#loading-message"),
                    results = $("#apiTestResults");

                if (requestActive) {
                    return false;
                }

                app.value("requestActive", true);
                urlAction = "/apiTestPageResults.action";
                options = {
                    data: $("#apiTestPageForm").serialize(),
                    cache: false,
                    dataType: "html",
                    type: "post",
                    url: urlAction,
                    success: function(data) {
                        $("#apiTestResults").html(data);
                    },
                    error: function(message) {
                        message.slideUp("fast");
                        button.fadeIn();
                        results.show();
                        skyword.scrollTo("#apiTestResults", 20);
                    },
                    complete: function() {
                        var apiTest = $("#runApiTest");
                        apiTest.removeAttr("disabled");
                        apiTest.removeClass("disabled");
                        message.slideUp("fast");
                        button.show();
                        results.show();
                        skyword.scrollTo("#apiTestResults", 20);
                        app.value("requestActive", false);
                    }
                };
                message.slideDown("fast");
                button.hide();
                results.hide();
                call.ajax(urlAction, options);
            },
            testApi = function() {
                var url = $.trim($("#url").val()),
                    theTitle = $.trim($("#title").val()),
                    username = $.trim($("#username").val()),
                    password = $.trim($("#password").val()),
                    dummyId = $.trim($("#dummyId").val()),
                    authorName = $.trim($("#authorName").val()),
                    body = $.trim($("#body").val()),
                    programCategoryId = $.trim($("#programCategoryId").val()),
                    method = $.trim(
                        $("input:radio[name=method]:checked").val()
                    ),
                    apiType = $.trim($("#apiType").val()),
                    blogIdentifier = $.trim($("#blogIdentifier").val()),
                    options,
                    urlAction,
                    submitData;

                if (requestActive) {
                    return false;
                }

                submitData = {
                    method: method,
                    apiType: apiType,
                    url: url,
                    username: username,
                    password: password,
                    blogIdentifier: blogIdentifier,
                    dummyId: dummyId,
                    authorName: authorName,
                    title: theTitle,
                    body: body,
                    programCategoryId: programCategoryId
                };
                app.value("requestActive", true);
                urlAction = "/apiTestResults.action";
                options = {
                    data: submitData,
                    cache: false,
                    dataType: "html",
                    type: "post",
                    url: urlAction,
                    success: function(data) {
                        $("#rightColumnResults").html(data);
                    },
                    complete: function() {
                        var apiTest = $("#apiTest");
                        apiTest.removeAttr("disabled");
                        apiTest.removeClass("disabled");
                        app.value("requestActive", false);
                    }
                };
                call.ajax(urlAction, options);
            };

        return {
            init: function() {
                //onReady
                $(function() {
                    $("body").on(
                        "click",
                        '.copy_image',
                        null,
                        function(){
                            copyImage($(this).attr('data-url'), $(this))
                        }
                    );


                    $("#apiTest").click(function() {
                        app.value("requestActive", false);
                        testApi();
                        return false;
                    });

                    $("#runApiTest").click(function() {
                        app.value("requestActive", false);
                        runApiTest();
                        return false;
                    });

                    // Instantiate clipboard
                    var clpd = new Clipboard(".copyModule");
                    clpd.on("success", function(e) {
                        makeCopySuccess(e.trigger);
                    });
                    clpd.on("error", function(e) {
                        makeCopyFailure(e.trigger);
                    });

                    /*                    $('.copyModule').each(function(){
                        console.log("hi");
                        console.log(navigator.userAgent);
                        console.log($(this).closest("section").find("textarea").val());
                            $(this).unbind("click").click(function () {
                                console.log("copy click");
                                if (window.clipboardData) {
                                    var didSucceed = window.clipboardData.setData("Text", $(this).closest("section").find("textarea").val());
                                    $(this).closest("section").find("textarea").addClass("borderGreen");
                                    if (didSucceed) {
                                        (".borderGreen").removeClass("borderGreen");
                                        $(this).closest("section").find("textarea").addClass("borderGreen");
                                        $(".copiedMessage").fadeOut("fast");
                                        $(this).closest("section").find(".copiedMessage").fadeIn("slow");
                                    }
                                }
                            });
                    })
*/

                   
                });
            }
        };

        function showCopySuccessFailure(children, type){
            children.each(function(idx, el){
                if(type === "success"){
                    if(el.classList.contains("copy_success")){
                        el.style.display = 'inline-block'
                    }
                    if(el.classList.contains("copy_failure")){
                        el.style.display = 'none'
                    }
                }else{
                    if(el.classList.contains("copy_success")){
                        el.style.display = 'none'
                    }
                    if(el.classList.contains("copy_failure")){
                        el.style.display = 'inline-block'
                    }
                }
            })
        }

        function copyImageToClipBoardSafari(blob, element) {
            let children = $(element).find('span')

              navigator.clipboard
                .write([
                  new ClipboardItem({
                    "image/png": blob,
                  }),
                ])
                .then(() =>{
                    showCopySuccessFailure(children, 'success');
                })
                .catch((err) =>
                  // Error
                  {console.error("Error:", err)
                  showCopySuccessFailure(children, 'success');
                  alert("You need a secure connection to copy the image")}
                );
          }

          function copyImageToClipBoardOtherBrowsers(blob, element) {
            let children = $(element).find('span')

              navigator?.permissions
                ?.query({ name: "clipboard-write" })
                .then((result) => {
                  if (result.state === "granted") {
                    const type = blob.type;
                    let data = [new ClipboardItem({ [type]: blob })];
                    navigator.clipboard
                      .write(data)
                      .then(() => {
                        // Success
                        showCopySuccessFailure(children, 'success');
                    })
                      .catch((err) => {
                        // Error
                        console.error("Error:", err)
                        showCopySuccessFailure(children,'failure');  
                      });
                  }else{
                    showCopySuccessFailure(children,'failure');    
                    alert("Copy permission denied. You need a secure connection to copy the image")
                  }
              }).catch((err) => {
                console.log(err)
                showCopySuccessFailure(children, 'success');
            });
          }

          function copyImageToClipBoardFirefox(image, element) {
            let img = document.createElement('img');
            img.classList.add('image-class');
            img.src = image;
            document.body.appendChild(img);
            var imageElem = document.querySelector('.image-class');  
            var range = document.createRange();  
            range.selectNode(imageElem);  
            window.getSelection().addRange(range);  
            let children = $(element).find('span')
            try {  
                // Now that we've selected the image, execute the copy command  
                var successful = document.execCommand('copy');  
                var msg = successful ? 'successful' : 'unsuccessful';  
                showCopySuccessFailure(children,'success');  
                // alert("Image copied successfully")
            } catch(err) {  
                console.log('Oops, unable to copy', err);
                showCopySuccessFailure(children,'failure');    
            }  

            // Remove the selections - NOTE: Should use
            // removeRange(range) when it is supported  
            window.getSelection().removeAllRanges();
            document.body.removeChild(img)
          }

        async function drawImage(url, ctx, canvas){
            let img = new Image();
            await new Promise(r => img.onload=r, img.src=url);
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
        }

        async function copyImage(image, element) {
            try {
                $('#copy_success').css('display','none');
                const response = await fetch(image);
                const blob = await response.blob();
                const isNotFirefox = navigator.userAgent.indexOf("Firefox") < 0;
                const isSafari = /^((?!chrome|android).)*safari/i.test(
                    navigator?.userAgent
                  );

                removeCopyMsgs(null)
                $('.copy_success').each(function(idx, el){
                        el.style.display = 'none'
                    
                })  
                $('.copy_failur').each(function(idx,el){
                    el.style.display = 'none'
                })
                if(isSafari){
                    if(blob.type === "image/jpeg" || blob.type === "image/jpg"){
                        let canvas = document.createElement("canvas");
                        let ctx = canvas.getContext("2d");
                        await drawImage(image, ctx, canvas);
                        document.body.appendChild(canvas);
                        canvas.toBlob((blob)=>{
                            copyImageToClipBoardSafari(blob, element);
                            document.body.removeChild(canvas);
                        },"image/png",1)
                    }else{
                        copyImageToClipBoardSafari(blob, element);
                    }
                }else{
                    if(isNotFirefox){
                        if(blob.type === "image/jpeg" || blob.type === "image/jpg"){
                            let canvas = document.createElement("canvas");
                            let ctx = canvas.getContext("2d");
                            await drawImage(image, ctx, canvas);
                            document.body.appendChild(canvas);
                            canvas.toBlob((blob)=>{
                                copyImageToClipBoardOtherBrowsers(blob, element);
                                document.body.removeChild(canvas);
                            },"image/png",1)
                        }else{
                            copyImageToClipBoardOtherBrowsers(blob, element);
                        }
                    }else{
                        copyImageToClipBoardFirefox(image, element)
                    }
                }
            } catch (err) {
                console.error(err.name, err.message);
               let children = $(element).find('span')
               showCopySuccessFailure(children,'failure');    
            }
        };

        function removeCopySuccess(trigger) {
            $(".copy-success").remove();
            $(".fieldSuccess").removeClass("fieldSuccess");

        }

        function removeCopyFailure(trigger) {
            $(".copy-failure").remove();
            $(".fieldFailure").removeClass("fieldFailure");
        }

        function removeCopyMsgs(trigger) {
            removeCopyFailure(trigger);
            removeCopySuccess(trigger);
        }

        function makeCopySuccess(trigger) {
            removeCopyMsgs(trigger);
            var el = document.createElement("div");
            el.className += "icon-MessageSuccess copy-success";

            var txt = document.createElement("span");
            txt.className = "copy-msg";

            var successMsg = '';
            txt.appendChild(document.createTextNode(successMsg));

            el.appendChild(txt);

            $(trigger)
                .parent()
                .parent()
                .append(el);
            $(trigger.dataset.clipboardTarget).addClass("fieldSuccess");
        }

        function makeCopyFailure(trigger) {
            removeCopyMsgs(trigger);

            var el = document.createElement("div");
            el.className += "icon-MessageWarning copy-failure";

            var isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
            var msg = isMac
                ? $translate.instant("angular.copytext.cmdc")
                : $translate.instant("angular.copytext.ctrlc");
            var txt = document.createElement("span");
            txt.className = "copy-msg";
            txt.appendChild(document.createTextNode(msg));

            el.appendChild(txt);

            $(trigger)
                .parent()
                .parent()
                .append(el);
            $(trigger.dataset.clipboardTarget).addClass("fieldFailure");
        }
    }
]);

(function() {
    app.factory("permission", function($http, $q) {
        return {
            all: function() {
                var url = "/API/permission/all";
                return $http.get(url).then(
                    function(response) {
                        // On Success. We're getting back an object
                        if (typeof response.data === "object") {
                            return response.data;
                            // On Invalid type. It's not an object
                        } else {
                            // invalid response
                            return $q.reject(response.data);
                        }
                    },
                    function(response) {
                        // Something just straight up went wrong
                        return $q.reject(response.data);
                    }
                );
            },
            canSeePermission: function(userPermission) {
                var url = "/API/permission/" + userPermission;
                return $http.get(url).then(
                    function(response) {
                        // 2.1 On Success. We're getting back an object
                        if (typeof response.data === "object") {
                            return response.data.permission;
                            // 2.2 On Invalid type. It's not an object
                        } else {
                            // invalid response
                            return $q.reject(response.data);
                        }
                    },
                    function(response) {
                        // 2.3 Something just straight up went wrong
                        return $q.reject(response.data);
                    }
                );
            },
            rfpEnabled: function(programID) {
                var url = "API/program/" + programID + "/rfpEnabled";
                return $http.get(url).then(
                    function(response) {
                        if (typeof response.data === "object") {
                            // 2.2 On Invalid type. It's not an object
                            return $q.reject(response.data);
                            // 2.1 On Success. We're getting back a boolean
                        } else {
                            return response.data;
                        }
                    },
                    function(response) {
                        // 2.3 Something just straight up went wrong
                        return $q.reject(response.data);
                    }
                );
            }
        };
    });
})();

app.service("externalReview", [
    "skyword",
    "call",
    function(skyword, call) {
        return {
            unlockExternalReviewerContent: function() {
                var urlAction = "/unlockExternalReviewerContentAjax.action",
                    opts = {
                        cguid: $("#cguid").val(),
                        aguid: $("#aguid").val()
                    },
                    options = {
                        cache: false,
                        type: "POST",
                        data: opts,
                        dataType: "html",
                        url: urlAction,
                        async: false,
                        error: function(data, status, err) {
                            console.log([data, status, err]);
                        }
                    };
                call.ajax(urlAction, options);
            },

            init: function() {
                var pingId,
                    retryId,
                    pingInterval = 30,
                    retryInterval = 5,
                    retryCount = 0,
                    retryMax = 3;

                $('body').on('click', '#submit-overall-comment',null, function(){
                    let comment = $('#overall-comment').val()
                    if(comment !== ""){
                        comment += getExternalReviewerName()
                    }
                    $('#overall-comment').val(comment);
                    $('#formSubmit').submit();
                })

                var message = {
                    show: function() {
                        $("#loading-message").removeClass("hidden");
                    },
                    hide: function() {
                        $("#loading-message")
                            .addClass("hidden")
                            .html();
                    },
                    success: function(text, s) {
                        $("#loading-message")
                            .removeClass("hidden")
                            .addClass("success")
                            .removeClass("warning")
                            .removeClass("error")
                            .html('<span class="icon-Success"></span> ' + text);
                        setTimeout(function() {
                            message.hide();
                        }, s * 1000);
                    },
                    warning: function(text) {
                        $("#loading-message")
                            .removeClass("hidden")
                            .removeClass("success")
                            .addClass("warning")
                            .removeClass("error")
                            .html('<span class="icon-Warning"></span> ' + text);
                    },
                    error: function(text) {
                        $("#loading-message")
                            .removeClass("hidden")
                            .removeClass("success")
                            .removeClass("warning")
                            .addClass("error")
                            .html('<span class="icon-Error"></span> ' + text);
                    }
                };

                function getExternalReviewerName() {
                    var externalReviewerName = localStorage.getItem("externalReviewer");
                    externalReviewerName = externalReviewerName === null ? "" : externalReviewerName;
                    let currentUrl = window.location.href;
                    var accountId = parseInt($('#attrs-id').data('accountid'))
                    var externalReviewerData = "";
                    if(accountId === 1){
                        if(externalReviewerName.trim() !== "") {
                            externalReviewerData = " - " + externalReviewerName
                        }
                    }
                    return externalReviewerData;
                }

                function getParameterByName(name) {
                    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
                    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                        results = regex.exec(location.search);
                    return results == null
                        ? ""
                        : decodeURIComponent(results[1].replace(/\+/g, " "));
                }

                function enablePage() {
                    // (re-)enable all buttons
                }

                function disablePage() {
                    // disable all buttons, but allow text fields to remain visible and selectable
                }

                function retry(maxTries) {
                    var r = maxTries || retryMax;

                    // Have we hit our retry limit?
                    if (retryCount < r) {
                        var c = retryInterval;
                        var countdownLoop = setInterval(function() {
                            if (c > 0) {
                                console.log(
                                    "External Ping: Connection lost, making attempt (" +
                                        (retryCount + 1) +
                                        " of " +
                                        retryMax +
                                        ") to re-connect in " +
                                        c +
                                        " seconds..."
                                );
                                message.warning(
                                    "Connection lost, attempting to re-connect in " +
                                        c +
                                        " seconds&hellip;"
                                );
                                c--;
                            } else {
                                skyword.pingRequest("externalPing.action", {
                                    beforeSend: function(jqXHR, settings) {
                                        retryCount++;
                                    },
                                    complete: function(jqXHR, textStatus) {
                                        switch (textStatus) {
                                            case "success":
                                            case "notmodified":
                                                console.log(
                                                    "External Ping: Retry Success"
                                                );
                                                message.success(
                                                    "Connection re-established!",
                                                    1
                                                );
                                                enablePage();
                                                retryCount = 0;
                                                break;
                                            default:
                                                console.log(
                                                    "External Ping: Retry Error"
                                                );
                                                retry(r);
                                                break;
                                        }
                                    }
                                });
                                clearInterval(countdownLoop);
                            }
                        }, 1000);
                    } else {
                        console.log(
                            "External Ping: Connection Lost, stop trying"
                        );
                        message.error(
                            "Your connection cannot be re-established, your changes will not be saved. Please reload page to continue working."
                        );
                        clearInterval(pingId);
                    }
                }

                function ping() {
                    pingId = skyword.externalPing(
                        {
                            complete: function(jqXHR, textStatus) {
                                switch (textStatus) {
                                    case "success":
                                    case "notmodified":
                                        console.log("External Ping: Success");
                                        break;
                                    default:
                                        if (jqXHR.status == 400) {
                                            console.log(
                                                "External Ping: Connection is fine, but the data is bad..."
                                            );
                                            message.error(
                                                "We&#38;ve encountered an error. Please reload page to continue working."
                                            );
                                            disablePage();
                                        } else {
                                            console.log(
                                                "External Ping: Connection Error"
                                            );
                                            disablePage();
                                            retry(3);
                                        }
                                        break;
                                }
                            }
                        },
                        pingInterval * 1000
                    );
                }

                function checkAndLoadModalExternalReviewerName(){
                    var opts = {
                        html:
                            '<div class="external-reviewer-pane">'+
                                '<h1>External Reviewer</h1>' +
                                '<div class="input-form-container">'+
                                    '<label class="form-input-label">Your Name <span class="required">*</span></label>'+
                                    '<input type="text" name="external_reviewer" id="external_reviewer" onkeyup="resetValidation()" />'+
                                    '<span class="validation-message" id="validation-message">Please enter your name</span>'+
                                '</div>' +
                                '<div class="save-btn-container">'+
                                    '<button class="standard" onclick="saveExternalReviewerName()"><span>Save</span></button>'+
                                '</div>' +
                            "</div>",
                            closeButton: false,
                        overlayClose: false
                    };
                    $.colorbox(opts);
                    $("#cboxClose").css('display','none')
                    $('#validation-message').css('display','none');
                }

                window.resetValidation = function(){
                    $('#validation-message').css('display','none');
                }

                window.saveExternalReviewerName = function(){
                    let name = $("#external_reviewer").val().trim();
                    if(name === "" || name === null){
                        $('#validation-message').css('display','flex');
                    }else{
                        $('#validation-message').css('display','none');
                        console.log(name);
                        localStorage.setItem("externalReviewer",name);
                        parent.$.colorbox.close();
                        $('#externalReviewerNameFromLocalStorage').val(name);
                    }
                }

                let external_reviewer = localStorage.getItem("externalReviewer");
                var accountId = parseInt($('#attrs-id').data('accountid'));
                if(accountId === 1){
                    if((external_reviewer === null || external_reviewer === '')){
                        checkAndLoadModalExternalReviewerName();
                    }
                }
                ping();
                skyword.contentLock(120000);
            }
        };
    }
]);

app.controller("deliverContent", [
    "$scope",
    "$translate",
    "$sce",
    "$filter",
    "skyword",
    "delivery",
    "publish",
    "$compile",
    "$http",
    "oAuthInfoService",
    "attachmentService",
    function(
        $scope,
        $translate,
        $sce,
        $filter,
        skyword,
        delivery,
        publish,
        $compile,
        $http,
        oAuthInfoService,
        attachmentService
    ) {
        // initialize canSeeProgramSettings
        $scope.canSeeProgramSettings = canSeeProgramSettings;
        // initialize attachment
        $scope.attachment = attachment;
        // can we push to YouTube?
        $scope.hostOnYouTube = hostOnYouTube;
        // initialize YouTube URL
        $scope.youTubeUrl = youTubeUrl;
        $scope.uploadFailedInstruction = "";
        // initialize the list of channels (OAuthInfo)
        oAuthInfoService.getListOauthInfoYoutube($scope.attachment, function(
            listOauthInfo
        ) {
            $scope.listChannel = listOauthInfo;
            // if at least one channel select it by default
            if (listOauthInfo.length > 0) {
                $translate("angular.youTube.selectAYouTubeChannel").then(
                    function(selectAYouTubeChannel) {
                        $scope.channel = {
                            id: -1,
                            name: selectAYouTubeChannel
                        };
                    }
                );
                $scope.listChannel.unshift($scope.channel);
            }
        });
        $translate(
            "angular.youTube.publishContentDeliveryFaileContentSupport"
        ).then(function(txt) {
            $scope.uploadFailedInstruction = $sce.trustAsHtml(txt);
            //console.log('translated txt = ' + txt);
        });

        /**
         * Get YouTube privacy status for an attachment
         */
        $scope.getPrivacyStatus = function() {
            // check if video processed
            if ($scope.attachment.youTubeTranscodingStatus == 2) {
                // fetch status via API
                attachmentService.getPrivacyStatus($scope.attachment, function(
                    data
                ) {
                    // set result in scope
                    $scope.attachment.privacyStatus = data;
                });
            }
        };
        // trigger on load
        $scope.getPrivacyStatus();

        /**
         * Get the YouTube processing status and assign it on the attachment scope
         * for INT-204
         */
        $scope.getProcessingStatus = function() {
            // fetch YouTube processing status
            attachmentService.getProcessingStatus($scope.attachment, function(
                data
            ) {
                // set the fetched status on the scope
                if (isNaN(data)) {
                    $scope.attachment.youTubeTranscodingStatus = 0;
                } else {
                    $scope.attachment.youTubeTranscodingStatus = parseInt(data);
                    // get rejection status if any and YouTube Id  set
                    if ($scope.youTubeUrl != "") {
                        attachmentService.getRejectionStatus(
                            $scope.attachment,
                            function(data) {
                                $scope.rejectionStatus = data;
                            }
                        );
                    }
                }
            });
        };
        // call it on controller loads:
        $scope.getProcessingStatus();

        /**
         * Will show the dialog box to confirm the push to YouTube and ask the
         * privacy option (private, public or unlisted)
         */
        $scope.showChannelPushConfirmation = function(channel) {
            var preCompiledModal = "<div class='choose-pane youtube-publish'>";
            preCompiledModal +=
                "<h1>" +
                $translate.instant("angular.youTube.publishToYouTube") +
                "</h1>";
            preCompiledModal +=
                '<div class="channel-block"><img data-ng-src="' +
                channel.iconURL +
                '" height="40" width="40" /><span class="channel-name">' +
                channel.name +
                "</span></div>";
            preCompiledModal +=
                "<h4>" +
                $translate.instant("angular.youTube.selectPrivacyLevel") +
                "</h4>";
            preCompiledModal += "<ul>";
            preCompiledModal += "<li>";
            preCompiledModal += '<li><label for="youTubeStatudsPublic">';
            preCompiledModal +=
                '<input type="radio" data-ng-model="attachment.youTubeStatus" value="0" id="youTubeStatudsPublic" data-ng-init="attachment.youTubeStatus = 0"><strong>' +
                $translate.instant("angular.youTube.labelPublic") +
                "</strong></label>";
            preCompiledModal += $translate.instant(
                "angular.youTube.labelPublicDescription"
            );
            preCompiledModal += "</li>";
            preCompiledModal += '<li><label for="youTubeStatudsPrivate">';
            preCompiledModal +=
                '<input type="radio" data-ng-model="attachment.youTubeStatus" value="1" id="youTubeStatudsPrivate"><strong>' +
                $translate.instant("angular.youTube.labelPrivate") +
                "</strong></label>";
            preCompiledModal += $translate.instant(
                "angular.youTube.labelPrivateDescription"
            );
            preCompiledModal += "</li>";
            preCompiledModal += '<li><label for="youTubeStatudsUnlisted">';
            preCompiledModal +=
                '<input type="radio" data-ng-model="attachment.youTubeStatus" value="2" id="youTubeStatudsUnlisted"><strong>' +
                $translate.instant("angular.youTube.labelUnlisted") +
                "</strong></label>";
            preCompiledModal += $translate.instant(
                "angular.youTube.labelUnlistedDescription"
            );
            preCompiledModal += "</li>";
            preCompiledModal += "</ul>";
            preCompiledModal +=
                '<div class="cta-block"><button class="standard btn btn-primary" data-ng-click="pushYouTubeConfirmation(' +
                channel.id +
                ')" style="float:left;"><span>Publish</span></button>' +
                '<a onclick="parent.$.colorbox.close();" style="float:left;">' +
                $translate.instant("angular.button.cancel") +
                "</a></div>";
            preCompiledModal += "</div>";
            var modalCompiled = $compile(preCompiledModal)($scope);
            var opts = {
                html: modalCompiled,
                height: 350,
                width: 500
            };
            $.colorbox(opts);
        };

        /**
         * User clicked on publish to YouTube, add the video in the queue to be
         * downloaded from Kaltura then uploaded to YouTube
         */
        $scope.pushYouTubeConfirmation = function(channelId) {
            // close modal
            if (typeof parent.$ != "undefined") {
                parent.$.colorbox.close();
            }
            // set OAuth
            $scope.attachment.oAuthInfo = {
                id: channelId
            };
            // push to YouTube and update attachment YouTube status to in progress
            attachmentService.pushToYouTube($scope.attachment, function(data) {
                $scope.attachment = data;
            });
        };

        $(document).ready(function() {
            $translate("angular.videoCommenting.videoNotTranscodedYet").then(
                function(message) {
                    $scope.message = message;
                    mw.setConfig("Kaltura.LeadWithHTML5", true);
                    kWidget.embed({
                        targetId: "video-player",
                        wid: "_1737042",
                        uiconf_id: 23937082,
                        flashvars: {
                            allowFullScreen: true,
                            allowNetworking: "all",
                            allowScriptAccess: "always",
                            autoPlay: false,
                            download: {
                                plugin: true
                            },
                            strings: {
                                "mwe-embedplayer-missing-source": message
                            }
                        },
                        cache_st: 1406907495,
                        entry_id: $("#kalturaId").val()
                    });
                }
            );
        });
        try {
            delivery.init();
            publish.init();
        } catch (e) {
            // will fail during Jasmine tests
        }

        /**
         * Extract YouTube ID from the short YouTube URL and returns it. This will
         * solve https://skyword.atlassian.net/browse/INT-294
         */
        $scope.getYouTubeIdFromUrl = function(youTubeUrl) {
            return /[^/]*$/.exec(youTubeUrl)[0];
        };
    }
]);

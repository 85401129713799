app.directive("tinymceDirective", [
    "TinymceService",
    "$timeout",
    function(TinymceService, $timeout) {
        return {
            restrict: "AEC",
            replace: false,
            scope: {
                type: "=", // lite, common, admin
                content: "=",
                editorId: "=",
                contentRequestId: "=",
                programId: "=",
                contentTemplateId: "=",
                contentTemplateElementId: "=",
                contentId: "=",
                contentTemplateElementDataId: "=",
                showEmbeddedUpload: "=",
                selector: "="
            },

            link: function(scope, element, attrs) {
                if (scope.type == null || scope.type == undefined) {
                    scope.type = "common";
                }
                if (
                    scope.configOptions == null ||
                    scope.configOptions == undefined
                ) {
                    scope.configOptions = TinymceService.globalConfig(
                        scope.selector,
                        scope.type
                    );
                }
                if (
                    scope.setupOptions == null ||
                    scope.setupOptions == undefined
                ) {
                    scope.setupOptions = {
                        setup: function(editor) {
                            /*
                         Button: Init
                         */
                            editor.on("init", function(editor) {
                                var editors = $(".mce-tinymce");
                                editors.each(function() {
                                    if (
                                        "false" ===
                                        jQuery(this)
                                            .parent()
                                            .attr("data-uploader")
                                    ) {
                                        jQuery(this)
                                            .find(".mce-embeddedUploadBtn")
                                            .hide();
                                    }
                                });
                            });

                            /* Button: Custom TimyMce Help */

                            editor.addButton("helpButton", {
                                title: "Help",
                                icon: "help",
                                shortcut: "Alt+0",
                                classes: "skywordHelpBtn",
                                onPostRender: function() {
                                    var that = this; // reference to the button
                                },
                                onclick: function() {
                                    TinymceService.openHelp(editor);
                                }
                            });

                            /* Button: Custom TimyMce Help */

                            editor.addButton("commentButton", {
                                title: "Comment",
                                icon: "conversation icon-AddComment inline-toolbar-icon",
                                shortcut: "Alt + Shift + C",
                                classes: "mce-addCommentBtn",
                                onPostRender: function() {
                                    var that = this; // reference to the button
                                },
                                onclick: function() {
                                    TinymceService.openAddCommentBox(editor);
                                }
                            });

                            // Button: View Comment
                            editor.addButton('viewCommentButton', {
                                title: 'View Comment',
                                icon: 'preview inline-toolbar-icon',
                                classes: 'mce-viewCommentBtn',
                                shortcut: 'Alt + Shift + V',
                                onPostRender: function() {
                                    var that = this;
                                },
                                onclick: function() {
                                    TinymceService.openViewCommentBox(editor);
                                }
                            });

                            /*
                         Button: Embedded Upload
                         */
                            editor.addButton("embeddedUpload", {
                                title: "Image upload",
                                icon: "image",
                                classes: "embeddedUploadBtn",
                                onclick: function(editor) {
                                    //grab upload container from editor that was clicked
                                    var uploadContainer = $(
                                        "#" + editor.currentTarget.id
                                    ).prev();
                                    //focus editor on one we are passing through
                                    var dataCted = uploadContainer.attr(
                                        "data-cted"
                                    );
                                    //focus on editor
                                    tinyMCE.get(dataCted).focus();

                                    //remove display:none that is getting added on somewhere
                                    uploadContainer.removeAttr("style");
                                    if (uploadContainer.hasClass("hidden")) {
                                        uploadContainer.removeClass("hidden");
                                    } else {
                                        uploadContainer.addClass("hidden");
                                    }
                                    scope.$apply();
                                }
                            });
                            /*
                         Event Handlers: Save(DTO)
                         */
                            editor.on("keyup", function(editor) {
                                var DTO = setupEditor();
                                var savePromise = TinymceService.saveData(DTO);

                                savePromise.then(function() {
                                    TinymceService.updateHiddenFields(
                                        tinyMCE.activeEditor.id,
                                        scope.content
                                    );
                                });
                            });
                            editor.on("change", function(editor) {
                                var DTO = setupEditor();
                                var savePromise = TinymceService.saveData(DTO);

                                savePromise.then(function() {
                                    TinymceService.updateHiddenFields(
                                        tinyMCE.activeEditor.id,
                                        scope.content
                                    );
                                });
                            });
                            editor.on("click", function(editor) {
                                var DTO = setupEditor();
                                var savePromise = TinymceService.saveData(DTO);

                                savePromise.then(function() {
                                    TinymceService.updateHiddenFields(
                                        tinyMCE.activeEditor.id,
                                        scope.content
                                    );
                                });
                            });
                            /*
                         Configure Data Object
                         */
                            function setupEditor() {
                                var DTO = {};
                                var activeEditor = tinymce.activeEditor;
                                scope.content = TinymceService.fetchContent(
                                    activeEditor
                                );
                                /*
                             Build Object
                             */
                                DTO = TinymceService.buildDTO(
                                    scope.content,
                                    scope.contentRequestId,
                                    scope.contentTemplateElementId,
                                    scope.contentTemplateElementDataId,
                                    scope.contentTemplateId,
                                    scope.contentId,
                                    scope.programId,
                                    scope.contentTypeId
                                );

                                if (
                                    DTO.contentTemplateElementDataId ==
                                    undefined
                                ) {
                                    DTO.contentTemplateElementDataId = $(
                                        "#" + activeEditor.id
                                    ).data("cted");
                                }

                                return DTO;
                            }
                        } // End Setup
                    }; // End setupOptions
                } // End If
                /*
             Render / Initialize TinyMCE Editor
             */
                var settings = angular.extend(
                    scope.configOptions,
                    scope.setupOptions
                );
                tinyMCE.init(settings);

                $timeout(function() {
                    var editorInitConfigContainer1 = TinymceService.globalConfig(
                        ".containerContents .tinyMce",
                        "common"
                    );
                    var editorConfigContainer1 = angular.extend(
                        editorInitConfigContainer1,
                        scope.setupOptions
                    );
                    tinyMCE.init(editorConfigContainer1);

                    var editorInitConfigContainer2 = TinymceService.globalConfig(
                        ".containerContents .tinyMceLite",
                        "lite"
                    );
                    var editorConfigContainer2 = angular.extend(
                        editorInitConfigContainer2,
                        scope.setupOptions
                    );
                    tinyMCE.init(editorConfigContainer2);
                }, 3000);
            } // End Link
        }; // End return
    }
]); // End Directive

app.controller("contentPackageCreate", [
    "$scope",
    "$translate",
    function($scope, $translate) {
        // (function() {
        const observer = new MutationObserver(function(
            mutations,
            mutationInstance
        ) {
            const primaryContentHelperTooltip = document.getElementsByClassName(
                "primaryContentHelperTooltip"
            );
            if (primaryContentHelperTooltip.length > 0) {
                handleContentHelperTooltip();
            }

            const assignmentContentHelperTooltip = document.getElementsByClassName(
                "assignmentContentHelperTooltip"
            );
            if (assignmentContentHelperTooltip.length > 0) {
                handleAssignmentContentHelperTooltip();
                // mutationInstance.disconnect();
            }
        });

        observer.observe(document, {
            childList: true,
            subtree: true
        });
        // })();
        function handleContentHelperTooltip() {
            try {
                $translate(
                    "sw.contentpackage.tooltip.primarycontenthelpertext"
                ).then(function(translation) {
                    $("#primaryContentHelperTooltip").prop(
                        "title",
                        translation
                    );
                    $(".primaryContentHelperTooltip").bt({
                        padding: 8,
                        width: 400,
                        shrinkToFit: true,
                        spikeLength: 10,
                        spikeGirth: 10,
                        cornerRadius: 5,
                        overlap: -1,
                        centerPointY: 1,
                        fill: "rgba(255, 255, 255, 1.0)",
                        strokeWidth: 1,
                        strokeStyle: "#dfdfdf",
                        cssStyles: {
                            color: "#333333",
                            fontWeight: "normal"
                        },
                        shadow: true,
                        shadowOffsetX: 2,
                        shadowOffsetY: 2,
                        shadowBlur: 6,
                        shadowColor: "rgba(0,0,0,.6)",
                        shadowOverlap: false,
                        noShadowOpts: {
                            strokeStyle: "#999",
                            strokeWidth: 1
                        },
                        positions: ["right"]
                    });
                });
            } catch (e) {}
        }

        function handleAssignmentContentHelperTooltip() {
            try {
                $translate(
                    "sw.contentpackage.tooltip.assignmentcontenthelpertext"
                ).then(function(translation) {
                    $("#assignmentcontenthelpertext").prop(
                        "title",
                        translation
                    );
                    $(".assignmentContentHelperTooltip").bt({
                        padding: 8,
                        width: 500,
                        shrinkToFit: true,
                        spikeLength: 10,
                        spikeGirth: 10,
                        cornerRadius: 5,
                        overlap: -1,
                        centerPointY: 1,
                        fill: "rgba(255, 255, 255, 1.0)",
                        strokeWidth: 1,
                        strokeStyle: "#dfdfdf",
                        cssStyles: {
                            color: "#333333",
                            fontWeight: "normal"
                        },
                        shadow: true,
                        shadowOffsetX: 2,
                        shadowOffsetY: 2,
                        shadowBlur: 6,
                        shadowColor: "rgba(0,0,0,.6)",
                        shadowOverlap: false,
                        noShadowOpts: {
                            strokeStyle: "#999",
                            strokeWidth: 1
                        },
                        positions: ["right"]
                    });
                });
            } catch (e) {}
        }
    }
]);

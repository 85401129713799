(function() {
    /* --- 0.0 HTML Formatting | Filter --- */
    app.filter("unsafe", function($sce) {
        return function(val) {
            return $sce.trustAsHtml(val);
        };
    });

    app.filter("videoDurationFormat", function($filter) {
        return function(seconds) {
            return $filter(
                "date"
            )(new Date(0, 0, 0).setSeconds(seconds), "HH:mm:ss");
        };
    });

    app.directive("resizer", [
        "$timeout",
        function($timeout) {
            return {
                restrict: "A",
                link: function(scope, elem, attrs) {
                    elem.bind("error", function() {
                        scope.imageSuccess = false;
                    });
                    elem.bind("load", function() {
                        scope.imageSuccess = true;
                        if (elem[0].naturalWidth / elem[0].naturalHeight > 1) {
                            elem.addClass("wide");
                        } else {
                            elem.addClass("tall");
                        }
                        $timeout(function() {
                            scope.preloader = false;
                        }, 800);
                    });
                }
            };
        }
    ]);

    app.filter("convertbytes", function() {
        return function(bytes, searchResultTypeId, flag) {
            // Kaltura returns kB, Otherwise the resp for filesize is returning bytes. Handle accordingly...
            if (flag == true) {
                bytes = bytes * 1000;
            } else {
                bytes = bytes;
            }

            if (bytes >= 1000000000000) {
                bytes = (bytes / 1000000000).toFixed(2) + " TB";
            } else if (bytes >= 1000000000) {
                bytes = (bytes / 1000000).toFixed(2) + " GB";
            } else if (bytes >= 1000000) {
                bytes = (bytes / 1000000).toFixed(2) + " MB";
            } else if (bytes >= 1000) {
                bytes = (bytes / 1000).toFixed(2) + " KB";
            } else if (bytes > 1) {
                bytes = bytes + " bytes";
            } else if (bytes == 1) {
                bytes = bytes + " byte";
            } else {
                bytes = "0 byte";
            }
            return bytes;
        };
    });
    /* --- / 0.0 --- */

    /* --- 1.0 Handles Broken / Missing Images | Filter --- */
    app.directive("fallbackSrc", function() {
        var fallbackSrc = {
            link: function postLink(scope, iElement, iAttrs) {
                scope.details.hideimage = false;
                iElement.bind("error", function() {
                    //console.log('Cannot Find Image ', scope);
                    scope.details.hideimage = true;
                    // angular.element(this).attr("src", iAttrs.fallbackSrc);
                    angular.element(this).style.display = "none";
                    if (!$scope.$$phase) {
                        scope.$apply();
                    }
                });
            }
        };
        return fallbackSrc;
    });
    /* --- / 1.0 --- */

    /*
     eslint-enable indent
     */
    /* --- 2.0 - Search Result Detail Lightbox | Triggers the program's default colorbox and passes scope to detail partial | Directive --- */
    app.directive("searchbox", [
        "$compile",
        "$rootScope",
        "$timeout",
        "$sce",
        "search",
        "$translate",
        "ContentFormatFactory",
        function(
            $compile,
            $rootScope,
            $timeout,
            $sce,
            search,
            $translate,
            ContentFormatFactory
        ) {
            return {
                scope: {},
                link: function(scope, element, attrs) {
                    // Fetch Request for this assets details
                    scope.getResultDetails = function(id) {
                        var searchPromise = search.searchDetail(id);
                        searchPromise.then(function(data) {
                            scope.details = data;
                            scope.injectDetails(scope, data);
                        });
                    };
                    scope.getIconClass = function(id, filetype, thumbnailUrl) {
                        if (id == 8 && (filetype && thumbnailUrl)) {
                            return "icon icon-" + thumbnailUrl;
                        }
                        return ContentFormatFactory.contentFormat(id);
                    };
                    scope.injectDetails = function(scope, data) {
                        scope.details = {
                            // General
                            id: scope.$parent.id,
                            searchResultTypeId: parseInt(
                                scope.$parent.searchResultTypeId
                            ),
                            iconClass: scope.getIconClass(
                                parseInt(scope.$parent.searchResultTypeId),
                                scope.$parent.fileType,
                                scope.$parent.thumbnailUrl
                            ),
                            standardSearchResultTypeIds: [
                                1,
                                2,
                                3,
                                4,
                                9,
                                10,
                                11,
                                12,
                                13,
                                14,
                                15,
                                16,
                                17
                            ],
                            assetSearchResultTypeIds: [6, 7, 8, 18],
                            videoSearchResultTypeIds: [5, 7],
                            eventName: data.eventName,
                            type: scope.$parent.type,
                            byline: scope.$parent.byline,
                            accountId: scope.$parent.accountId,
                            accountProfilePicture: data.accountProfilePicture,
                            category: scope.$parent.category,
                            isCustomState: scope.$parent.isCustomState,
                            projectTitle: data.projectTitle,
                            summary: data.summary,
                            summaryNoHTML: data.summaryNoHTML,
                            title: data.title,
                            contentType: data.contentType,
                            dateCreated: data.dateCreated,
                            dateApproved: scope.$parent.dateApproved,
                            keywords: data.keywords,
                            state: scope.$parent.state,
                            inTerminalState: data.inTerminalState,

                            videoDownloadable: data.videoDownloadable,
                            // Asset
                            isDigitalAsset: data.isDigitalAsset,
                            fileType: scope.$parent.fileType,
                            fileSize: data.fileSize,
                            tags: data.tags,
                            source: data.source,
                            fullSizeVideo: "",
                            videoDownloadUrls: data.videoDownloadUrls,
                            imageHeight: data.imageHeight,
                            thumbnailUrl: scope.$parent.thumbnailUrl,
                            attachmentId: scope.$parent.attachmentId,
                            publishedUrl: scope.$parent.publishedUrl,
                            imageWidth: data.imageWidth,
                            videoDuration: data.videoDuration,
                            // Content
                            contentId: data.contentId,
                            score: scope.$parent.score,
                            reviewable: data.reviewable,
                            programName: data.programName,
                            externalValueId: data.externalValueId,
                            agencySearch: scope.$parent.agencySearch,
                            enableContentExport: data.enableContentExport,
                            stateId: data.stateId,
                            url: data.url,
                            enableContentPackages: data.enableContentPackages,
                            contentPackageId: scope.$parent.contentPackageId,
                            contentPackageName: scope.$parent.contentPackageName
                        };

                        // This is a mess.
                        // We need to simply return the full path of the asset instead of parsing through strings.
                        // Best I can do right now is document all the conditionals.

                        // 1st Scenario: IMAGE ASSET & PHOTOS
                        if (
                            [6, 13].includes(
                                parseInt(scope.details.searchResultTypeId)
                            )
                        ) {
                            // 1st Scenario: IMAGE ASSET & PHOTOS
                            // 150h indicates a scaled down version of the asset. Removing this returns the full size.
                            if (
                                scope.details.thumbnailUrl &&
                                !scope.details.thumbnailUrl.match(
                                    /^150h:\/\/.*/
                                )
                            ) {
                                scope.details.fullSizeImage = scope.details.thumbnailUrl.replace(
                                    "150h",
                                    ""
                                );
                            }
                            // 1st Scenario: IMAGE ASSET & PHOTOS
                            // ????
                            else {
                                scope.details.fullSizeImage =
                                    scope.details.thumbnailUrl;
                                scope.details.fullSizeImage =
                                    scope.details.fullSizeImage.split("/")[0] +
                                    "//" +
                                    scope.details.fullSizeImage.split("/")[2] +
                                    "/" +
                                    scope.details.publishedUrl;
                            }
                            // 1st Scenario: IMAGE ASSET & PHOTOS
                            // Set w & h
                            if (!scope.details.imageHeight) {
                                var img = new Image();
                                img.onload = function() {
                                    scope.details.imageHeight =
                                        img.naturalHeight;
                                    scope.details.imageWidth = img.naturalWidth;
                                };
                                img.src = scope.details.fullSizeImage;
                            }
                        }
                        // 2nd Scenario: Standard Content Types (not video, doc or image)
                        else if (
                            scope.details.standardSearchResultTypeIds.includes(
                                parseInt(scope.details.searchResultTypeId)
                            )
                        ) {
                            // 2nd Scenario: IMAGE ASSET & PHOTOS
                            // 150h indicates a scaled down version of the asset. Removing this returns the full size.
                            if (
                                scope.details.thumbnailUrl &&
                                scope.details.thumbnailUrl.indexOf("150h") != -1
                                // scope.details.thumbnailUrl.match(/^150h:\/\/.*/)
                            ) {
                                scope.details.fullSizeImage =
                                    scope.details.thumbnailUrl;
                                scope.details.fullSizeImage =
                                    scope.details.fullSizeImage.split(
                                        "150h"
                                    )[0] +
                                    scope.details.fullSizeImage.split(
                                        "150h"
                                    )[1];
                            } else {
                                scope.details.fullSizeImage =
                                    scope.details.thumbnailUrl;
                            }
                        }

                        // 3rd Scenario: Video Assets
                        else if (
                            [5, 7].includes(
                                parseInt(scope.details.searchResultTypeId)
                            )
                        ) {
                            // 3rd Scenario: Video Assets
                            // Make sure an asset exists...
                            if (
                                scope.details.thumbnailUrl &&
                                scope.details.thumbnailUrl != ""
                            ) {
                                // 3rd Scenario: Video Assets
                                // Check if asset is from kaltura...
                                if (
                                    scope.details.thumbnailUrl.search(
                                        "kaltura"
                                    ) != -1
                                ) {
                                    scope.details.fullSizeVideo =
                                        scope.details.thumbnailUrl;
                                    scope.details.fullSizeVideo =
                                        scope.details.fullSizeVideo.split(
                                            "width"
                                        )[0] + "width/900/";
                                } else {
                                    scope.details.fullSizeVideo =
                                        scope.details.thumbnailUrl;
                                }
                            } else {
                                scope.details.fullSizeVideo = true;
                            }
                        }
                        var content = $("#cboxLoadedContent");
                        $compile(content)(scope);
                    };

                    element.click("bind", function() {
                        $.colorbox({
                            height: attrs.modalheight,
                            width: attrs.modalwidth,
                            top: attrs.modaltop,
                            href: attrs.searchbox,
                            onLoad: function() {
                                scope.preloader = true;
                                scope.getResultDetails(scope.$parent.id);
                            },
                            onComplete: function() {
                                if (scope.imageSuccess == true) {
                                    // Keep the preloader running until the image has been rendered appro.
                                    scope.preloader = true;
                                } else {
                                    $timeout(function() {
                                        scope.preloader = false;
                                    }, 1000);
                                }
                            },
                            onCleanup: function() {
                                scope.preloader = true;
                            }
                        }); // colorbox
                    }); // element
                } // link
            }; //return
        }
    ]); // directive
    /* --- / 2.0 --- */

    /* --- 3.0 - Cut | Limit's the string | Filter --- */

    /* Options:
     * wordwise (boolean) - if true, cut only by words bounds,
     * max (integer) - max length of the text, cut to this number of chars,
     * tail (string, default: ' …') - add this string to the input string if the string was cut.
     */
    app.filter("cut", function() {
        return function(value, wordwise, max, tail) {
            if (!value) {
                return "";
            }

            max = parseInt(max, 10);
            if (!max) {
                return value;
            }
            if (value.length <= max) {
                return value;
            }

            value = value.substr(0, max);
            if (wordwise) {
                var lastspace = value.lastIndexOf(" ");
                if (lastspace !== -1) {
                    value = value.substr(0, lastspace);
                }
            }

            return value + (tail || " …");
        };
    });
    /* --- / 3.0 --- */

    /* --- 4.0 - Initialize Search Results | Controller --- */
    app.controller("searchResultsCtrl", [
        "$timeout",
        "search",
        "$scope",
        "$http",
        "$filter",
        "skyword",
        "$location",
        "$translate",
        "$window",
        "$rootScope",
        "AssetFactory",
        "ngTableParams",
        "permission",
        function(
            $timeout,
            search,
            $scope,
            $http,
            $filter,
            skyword,
            $location,
            $translate,
            $window,
            $rootScope,
            AssetFactory,
            ngTableParams,
            permission
        ) {
            $scope._ = _;
            $scope.data = null;
            $scope.hideimage = false;
            $scope.imageSuccess = false;
            $scope.sitePermissions = null;
            $scope.rfpEnabled = false;
            $scope.canCreate = false;

            // 4.1 | filter categories
            $scope.contentType = null;
            $scope.categories = null;
            $scope.fileType = null;
            $scope.projects = null;
            $scope.agencySearch = false;
            // NOTE: $scope.fileSize = null;
            $scope.filterTags = null;
            $scope.creationDate = null;
            $scope.creator = null;

            // NOTE: $scope.videoLength = null;
            $scope.events = null;
            $scope.eventType = null;
            $scope.state = null;
            $scope.stateId = null;
            $scope.publishedUrl = null;
            $scope.reviewable = null;
            $scope.fileSize = null;
            $scope.dimensions = null;
            $scope.duration = null;

            // NOTE: Facet value limit to 10
            $scope.facetValueLimitContentType = 10;
            $scope.facetValueLimitCategory = 10;
            $scope.facetValueLimitProject = 10;
            $scope.facetValueLimitFileType = 10;
            $scope.facetValueLimitTag = 10;
            $scope.facetValueLimitSource = 10;
            $scope.facetValueLimitEvent = 10;
            $scope.facetValueLimitEventType = 10;
            $scope.facetValueLimitState = 10;

            // NOTE: Show more link for facetValues
            $scope.showMoreCategory = true;
            $scope.showMoreProject = true;
            $scope.showMoreTag = true;
            $scope.showMoreSource = true;
            $scope.showMoreContentType = true;
            $scope.showMoreFileType = true;
            $scope.showMoreEvent = true;
            $scope.showMoreEventType = true;
            $scope.showMoreState = true;

            // NOTE: Show Less link for facetValues
            $scope.showLessCategory = false;
            $scope.showLessProject = false;
            $scope.showLessTag = false;
            $scope.showLessSource = false;
            $scope.showLessContentType = false;
            $scope.showLessFileType = false;
            $scope.showLessEvent = false;
            $scope.showLessEventType = false;
            $scope.showLessState = false;

            $scope.showRefinements = false;
            $scope.showFacetContentType = false;
            $scope.showFacetCategory = false;
            $scope.showFacetProject = false;
            $scope.showFacetFileType = false;
            $scope.showFacetDates = false;

            // NOTE: $scope.showFacetFileSize = false;
            $scope.showFacetTag = false;
            $scope.showFacetSource = false;

            // NOTE: $scope.showFacetVideoLength = false;
            $scope.showFacetEvent = false;
            $scope.showFacetEventType = false;
            $scope.showFacetState = false;
            $scope.showFacetContentPackageName = false;

            // NOTE: Empty arrays for sorting
            $scope.arrContentType = [];
            $scope.arrCategory = [];
            $scope.arrProject = [];
            $scope.arrFileType = [];
            $scope.arrFacetTag = [];
            $scope.arrFacetSource = [];
            $scope.arrFacetEvent = [];
            $scope.arrFacetEventType = [];
            $scope.arrFacetState = [];
            $scope.arrFacetContentPackageName = [];

            // 4.2 | Request Object
            $scope.searchContentRequestDTO = {
                searchType: $scope.searchType,
                programId: $scope.programId,
                agencyId: $scope.agencyId,
                searchResultTypeId: $scope.searchResultTypeId,
                textSearch: $scope.textSearch,
                facetDateCreatedStart: $scope.facetDateCreatedStart,
                facetDateCreatedEnd: $scope.facetDateCreatedEnd,
                facetContentType: [],
                facetCategory: [],
                facetProject: [],
                facetFileType: [],
                // facetFilesize : [],
                facetTag: [],
                facetSource: [],
                // facetVideoLength : [],
                facetEvent: [],
                facetEventType: [],
                facetState: [],
                agencySearch: $scope.agencySearch,
                // downloadUrls : [],
                // reviewable: $scope.reviewable,
                // fileSize: $scope.fileSize,
                // dimensions: $scope.dimensions,
                // duration: $scope.duration
                facetContentPackageName: []
            };

            //needed for new moment-datepicker
            $scope.refineDateClickable = true;
            // This translate does not work in QA or Prod. Not sure why. Circumventing for now. TT
            //$scope.milliFormat = $translate.instant('angular.dateformat');
            //$scope.dateformat = $translate.instant('angular.momentpicker.format');
            $scope.milliFormat = $("#angularDateFormat").val();
            $scope.dateformat = $("#momentPickerFormat").val();
            $scope.dates = {
                startDate: null,
                endDate: null
            };

            // 4.3 | Fetches a custom search result object
            $scope.fetchResults = function(object) {
                // NOTE: Make sure we have the program and agency id's before we run any requests
                if (
                    $scope.searchContentRequestDTO.programId == null ||
                    $scope.searchContentRequestDTO.agencyId == null
                ) {
                    $scope.searchContentRequestDTO.programId = $scope.programId;
                    $scope.searchContentRequestDTO.agencyId = $scope.agencyId;
                }

                // escaping text because of cross site scripting
                object.textSearch = $scope._.escape(object.textSearch);

                $("#search-process-indicator").addClass("loading");
                var searchPromise = search.search(object);
                searchPromise.then(function(data) {
                    //console.log("page search data", data);
                    $scope.data = data;
                    $scope.setupPagination(data.response.docs);
                    $("#search-process-indicator").removeClass("loading");
                    if (!$scope.$$phase) {
                        $scope.$apply();
                    }
                });
            };

            // 4.4 | Fetches a custom search result object
            $scope.fetchResultDetail = function(id) {
                var searchPromise = search.searchDetail(id);
                searchPromise.then(function(data) {
                    $scope.data = data;
                });
            };

            // 4.5 | Handles facet toggle display/hide
            $scope.toggleDisplay = function($event) {
                // Variables
                var el = $event.currentTarget,
                    $el = jQuery(el);
                $el.toggleClass("icon-Expand").toggleClass("icon-Collapse");
                $el.parent()
                    .toggleClass("")
                    .toggleClass("collapsed");
                $el.parent()
                    .next("ul")
                    .slideToggle("slow", function() {});
            };

            $scope.clearTextSearch = function() {
                $scope.searchContentRequestDTO.textSearch = "";
                $scope.fetchResults($scope.searchContentRequestDTO);
            };

            // 4.6 | Clears all search facets
            $scope.clearFacetSearch = function() {
                $scope.showRefinements = false;
                $scope.showFacetContentType = false;
                $scope.showFacetCategory = false;
                $scope.showFacetProject = false;
                $scope.showFacetFileType = false;
                $scope.showFacetDates = false;
                $scope.showFacetFileSize = false;
                $scope.showFacetTag = false;
                $scope.showFacetSource = false;
                $scope.showFacetVideoLength = false;
                $scope.showFacetEvent = false;
                $scope.showFacetEventType = false;
                $scope.showFacetState = false;
                $scope.showFacetContentPackageName = false;

                $scope.searchContentRequestDTO.facetContentType = [];
                $scope.searchContentRequestDTO.facetCategory = [];
                $scope.searchContentRequestDTO.facetProject = [];
                $scope.searchContentRequestDTO.facetFileType = [];
                //$scope.searchContentRequestDTO.facetFilesize = [];
                $scope.searchContentRequestDTO.facetTag = [];
                $scope.searchContentRequestDTO.facetSource = [];
                //$scope.searchContentRequestDTO.facetVideoLength = [];
                $scope.searchContentRequestDTO.facetEvent = [];
                $scope.searchContentRequestDTO.facetEventType = [];
                $scope.searchContentRequestDTO.facetState = [];
                $scope.searchContentRequestDTO.facetContentPackageName = [];
                $scope.facetDateCreatedStart = null;
                $scope.facetDateCreatedEnd = null;
                $scope.searchContentRequestDTO.facetDateCreatedStart = null;
                $scope.searchContentRequestDTO.facetDateCreatedEnd = null;
                $("#start-date").datepicker("setDate", null);
                $("#end-date").datepicker("setDate", null);

                //console.log('sending object: ' + $scope.searchContentRequestDTO);
                //console.log('searching for: ' + $scope.searchContentRequestDTO.textSearch)

                // Execute Search
                $scope.fetchResults($scope.searchContentRequestDTO);
            };

            // 4.7 | Adds a specific search facet to the users | Event: When filter category link is clicked
            $scope.refineSearch = function(facet, facetValue) {
                if (facetValue === undefined) return;
                if (facet == "contentType") {
                    $scope.showRefinements = true;
                    $scope.showFacetContentType = true;
                    //check for duplicates
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO.facetContentType
                        ) == -1
                    ) {
                        $scope.searchContentRequestDTO.facetContentType.push(
                            facetValue
                        );
                    }
                } else if (facet == "category") {
                    $scope.showRefinements = true;
                    $scope.showFacetCategory = true;
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO.facetCategory
                        ) == -1
                    ) {
                        $scope.searchContentRequestDTO.facetCategory.push(
                            facetValue
                        );
                    }
                } else if (facet == "project") {
                    $scope.showRefinements = true;
                    $scope.showFacetProject = true;
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO.facetProject
                        ) == -1
                    ) {
                        $scope.searchContentRequestDTO.facetProject.push(
                            facetValue
                        );
                    }
                } else if (facet == "fileType") {
                    $scope.showRefinements = true;
                    $scope.showFacetFileType = true;
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO.facetFileType
                        ) == -1
                    ) {
                        $scope.searchContentRequestDTO.facetFileType.push(
                            facetValue
                        );
                    }
                } else if (facet == "tag") {
                    /*else if(facet == 'fileSize'){
             $scope.showRefinements = true;
             $scope.showFacetFileSize = true;
             if(jQuery.inArray(facetValue, $scope.searchContentRequestDTO.facetFilesize) == -1) {
             $scope.searchContentRequestDTO.facetFilesize.push(facetValue);
             }
             }*/
                    $scope.showRefinements = true;
                    $scope.showFacetTag = true;
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO.facetTag
                        ) == -1
                    ) {
                        $scope.searchContentRequestDTO.facetTag.push(
                            facetValue
                        );
                    }
                } else if (facet == "source") {
                    /*else if(facet == 'dateCreated'){
             $scope.showRefinements = true;
             $scope.showFacetDates = true;
             $scope.facetDateCreatedStart;
             $scope.facetDateCreatedEnd;
             }*/
                    $scope.showRefinements = true;
                    $scope.showFacetSource = true;
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO.facetSource
                        ) == -1
                    ) {
                        $scope.searchContentRequestDTO.facetSource.push(
                            facetValue
                        );
                    }
                } else if (facet == "state") {
                    $scope.showRefinements = true;
                    $scope.showFacetState = true;
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO.facetState
                        ) == -1
                    ) {
                        $scope.searchContentRequestDTO.facetState.push(
                            facetValue
                        );
                    }
                } else if (facet == "event") {
                    /*else if(facet == 'videoLength'){
             $scope.showRefinements = true;
             $scope.showFacetVideoLength = true;
             if(jQuery.inArray(facetValue, $scope.searchContentRequestDTO.facetVideoLength) == -1) {
             $scope.searchContentRequestDTO.facetVideoLength.push(facetValue);
             }
             }*/
                    $scope.showRefinements = true;
                    $scope.showFacetEvent = true;
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO.facetEvent
                        ) == -1
                    ) {
                        $scope.searchContentRequestDTO.facetEvent.push(
                            facetValue
                        );
                    }
                } else if (facet == "eventType") {
                    $scope.showRefinements = true;
                    $scope.showFacetEventType = true;
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO.facetEventType
                        ) == -1
                    ) {
                        $scope.searchContentRequestDTO.facetEventType.push(
                            facetValue
                        );
                    }
                } else if (facet == "contentPackageName") {
                    $scope.showRefinements = true;
                    $scope.showFacetContentPackageName = true;
                    //check for duplicates
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO
                                .facetContentPackageName
                        ) == -1
                    ) {
                        $scope.searchContentRequestDTO.facetContentPackageName.push(
                            facetValue
                        );
                    }
                }

                // Execute Search
                $scope.fetchResults($scope.searchContentRequestDTO);
            };

            // 4.8 | Removes a single facet value from facet array
            $scope.removeFacetValue = function(facet, facetValue) {
                var index;

                if (facet == "contentType") {
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO.facetContentType
                        ) > -1
                    ) {
                        index = $scope.searchContentRequestDTO.facetContentType.indexOf(
                            facetValue
                        );
                        if (index > -1) {
                            $scope.searchContentRequestDTO.facetContentType.splice(
                                index,
                                1
                            );
                        }
                    }
                    if (
                        $scope.searchContentRequestDTO.facetContentType
                            .length == 0
                    ) {
                        $scope.showFacetContentType = false;
                    }
                } else if (facet == "category") {
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO.facetCategory
                        ) > -1
                    ) {
                        index = $scope.searchContentRequestDTO.facetCategory.indexOf(
                            facetValue
                        );
                        if (index > -1) {
                            $scope.searchContentRequestDTO.facetCategory.splice(
                                index,
                                1
                            );
                        }
                    }
                    if (
                        $scope.searchContentRequestDTO.facetCategory.length == 0
                    ) {
                        $scope.showFacetCategory = false;
                    }
                } else if (facet == "project") {
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO.facetProject
                        ) > -1
                    ) {
                        index = $scope.searchContentRequestDTO.facetProject.indexOf(
                            facetValue
                        );
                        if (index > -1) {
                            $scope.searchContentRequestDTO.facetProject.splice(
                                index,
                                1
                            );
                        }
                    }
                    if (
                        $scope.searchContentRequestDTO.facetProject.length == 0
                    ) {
                        $scope.showFacetProject = false;
                    }
                } else if (facet == "fileType") {
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO.facetFileType
                        ) > -1
                    ) {
                        index = $scope.searchContentRequestDTO.facetFileType.indexOf(
                            facetValue
                        );
                        if (index > -1) {
                            $scope.searchContentRequestDTO.facetFileType.splice(
                                index,
                                1
                            );
                        }
                    }
                    if (
                        $scope.searchContentRequestDTO.facetFileType.length == 0
                    ) {
                        $scope.showFacetFileType = false;
                    }
                } else if (facet == "tag") {
                    /*else if(facet == 'fileSize'){
             if(jQuery.inArray(facetValue, $scope.searchContentRequestDTO.facetFilesize) > -1) {
             index = $scope.searchContentRequestDTO.facetFilesize.indexOf(facetValue);
             if (index > -1) {
             $scope.searchContentRequestDTO.facetFilesize.splice(index, 1);
             }
             }
             if($scope.searchContentRequestDTO.facetFilesize.length == 0){
             $scope.showFacetFileSize = false;
             }
             }*/
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO.facetTag
                        ) > -1
                    ) {
                        index = $scope.searchContentRequestDTO.facetTag.indexOf(
                            facetValue
                        );
                        if (index > -1) {
                            $scope.searchContentRequestDTO.facetTag.splice(
                                index,
                                1
                            );
                        }
                    }
                    if ($scope.searchContentRequestDTO.facetTag.length == 0) {
                        $scope.showFacetTag = false;
                    }
                } else if (facet == "source") {
                    /*else if(facet == 'dateCreated'){
             $scope.showFacetDates = false;
             $scope.facetDateCreatedStart = null;
             $scope.facetDateCreatedEnd = null;
             }*/
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO.facetSource
                        ) > -1
                    ) {
                        index = $scope.searchContentRequestDTO.facetSource.indexOf(
                            facetValue
                        );
                        if (index > -1) {
                            $scope.searchContentRequestDTO.facetSource.splice(
                                index,
                                1
                            );
                        }
                    }
                    if (
                        $scope.searchContentRequestDTO.facetSource.length == 0
                    ) {
                        $scope.showFacetSource = false;
                    }
                } else if (facet == "state") {
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO.facetState
                        ) > -1
                    ) {
                        index = $scope.searchContentRequestDTO.facetState.indexOf(
                            facetValue
                        );
                        if (index > -1) {
                            $scope.searchContentRequestDTO.facetState.splice(
                                index,
                                1
                            );
                        }
                    }
                    if ($scope.searchContentRequestDTO.facetState.length == 0) {
                        $scope.showFacetState = false;
                    }
                } else if (facet == "event") {
                    /*else if(facet == 'videoLength'){
             if(jQuery.inArray(facetValue, $scope.searchContentRequestDTO.facetVideoLength) > -1) {
             index = $scope.searchContentRequestDTO.facetVideoLength.indexOf(facetValue);
             if (index > -1) {
             $scope.searchContentRequestDTO.facetVideoLength.splice(index, 1);
             }
             }
             if($scope.searchContentRequestDTO.facetVideoLength.length == 0){
             $scope.showFacetVideoLength = false;
             }
             }*/
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO.facetEvent
                        ) > -1
                    ) {
                        index = $scope.searchContentRequestDTO.facetEvent.indexOf(
                            facetValue
                        );
                        if (index > -1) {
                            $scope.searchContentRequestDTO.facetEvent.splice(
                                index,
                                1
                            );
                        }
                    }
                    if ($scope.searchContentRequestDTO.facetEvent.length == 0) {
                        $scope.showFacetEvent = false;
                    }
                } else if (facet == "eventType") {
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO.facetEventType
                        ) > -1
                    ) {
                        index = $scope.searchContentRequestDTO.facetEventType.indexOf(
                            facetValue
                        );
                        if (index > -1) {
                            $scope.searchContentRequestDTO.facetEventType.splice(
                                index,
                                1
                            );
                        }
                    }
                    if (
                        $scope.searchContentRequestDTO.facetEventType.length ==
                        0
                    ) {
                        $scope.showFacetEventType = false;
                    }
                } else if (facet == "contentPackageName") {
                    if (
                        jQuery.inArray(
                            facetValue,
                            $scope.searchContentRequestDTO
                                .facetContentPackageName
                        ) > -1
                    ) {
                        index = $scope.searchContentRequestDTO.facetContentPackageName.indexOf(
                            facetValue
                        );
                        if (index > -1) {
                            $scope.searchContentRequestDTO.facetContentPackageName.splice(
                                index,
                                1
                            );
                        }
                    }
                    if (
                        $scope.searchContentRequestDTO.facetContentPackageName
                            .length == 0
                    ) {
                        $scope.showFacetContentPackageName = false;
                    }
                }

                // Execute Search
                $scope.fetchResults($scope.searchContentRequestDTO);
            };

            //watches start date to make sure end date is not before start date
            $scope.$watch("[dates.startDate, dates.endDate]", function(
                newValue,
                oldValue
            ) {
                if (
                    $scope.dates.startDate != null &&
                    $scope.dates.endDate != null
                ) {
                    $scope.refineDateClickable = false;

                    //convert dates to milliseconds
                    var startDate = $scope.convertDateToMillis(
                        moment(
                            $scope.dates.startDate,
                            $scope.dateformat
                        ).format("MM-DD-YYYY"),
                        $scope.milliFormat
                    );
                    var endDate = $scope.convertDateToMillis(
                        moment($scope.dates.endDate, $scope.dateformat).format(
                            "MM-DD-YYYY"
                        ),
                        $scope.milliFormat
                    );
                    $scope.facetDateCreatedStart = new Date(startDate);
                    $scope.facetDateCreatedEnd = new Date(endDate);

                    if (
                        $scope.facetDateCreatedStart >
                        $scope.facetDateCreatedEnd
                    ) {
                        //start date is after end date, show error border
                        $scope.refineDateClickable = true;

                        $("#end-date").css({
                            border: "1px solid #E52E2E",
                            background: "#FFE2E2"
                        });
                    } else {
                        //dates are correct
                        $("#end-date").css({
                            border: "1px solid #CFCFCF",
                            background: "white"
                        });
                    }
                } else {
                    if ($scope.dates.startDate != null) {
                        $scope.minDate = $scope.dates.startDate.toString();
                    }
                    $scope.refineDateClickable = true;
                }
            });

            $scope.refineDate = function() {
                $scope.showRefinements = true;
                $scope.showFacetDates = true;
                $scope.searchContentRequestDTO.facetDateCreatedEnd =
                    $scope.facetDateCreatedEnd;
                $scope.searchContentRequestDTO.facetDateCreatedStart =
                    $scope.facetDateCreatedStart;

                // Execute Search
                $scope.fetchResults($scope.searchContentRequestDTO);
            };

            $scope.facetIsEmpty = function(facetObject) {
                if (!angular.isUndefined(facetObject)) {
                    var testLengthArray = $scope.makeFacetArray(facetObject);
                }

                if (angular.isUndefined(facetObject)) {
                    return true;
                } else if (testLengthArray.length == 0) {
                    /*else if(Object.keys(facetObject).length == 1 && $scope.data.response.numFound == 1) {
             return true;
             }*/
                    // jshint ignore:line
                    return true;
                } else {
                    return angular.equals({}, facetObject);
                }
            };

            $scope.setPage = function(pageNo) {
                $scope.currentPage = pageNo;
            };

            $scope.pageChanged = function() {
                // console.log('Page changed to: ' + $scope.currentPage);
            };

            $scope.setItemsPerPage = function(num) {
                $scope.itemsPerPage = num;
                $scope.currentPage = 1; //reset to first paghe
            };
            $scope.setupPagination = function(data) {
                $scope.viewby = 20;
                $scope.totalItems = $scope.data.response.docs.length;
                $scope.currentPage = 1;
                $scope.itemsPerPage = $scope.viewby;
                $scope.maxSize = 5; //Number of pager buttons to show
            };

            $scope.$watch("data", function(newValue, oldValue) {
                $timeout(function() {
                    $scope.arrContentType = $scope.makeFacetArray(
                        $scope.data.facet_counts.facet_fields.facetContentType
                    );
                    $scope.arrCategory = $scope.makeFacetArray(
                        $scope.data.facet_counts.facet_fields.facetCategory
                    );
                    $scope.arrProject = $scope.makeFacetArray(
                        $scope.data.facet_counts.facet_fields.facetProject
                    );
                    $scope.arrFileType = $scope.makeFacetArray(
                        $scope.data.facet_counts.facet_fields.facetFileType
                    );
                    $scope.arrFacetTag = $scope.makeFacetArray(
                        $scope.data.facet_counts.facet_fields.facetTag
                    );
                    $scope.arrFacetSource = $scope.makeFacetArray(
                        $scope.data.facet_counts.facet_fields.facetSource
                    );
                    $scope.arrFacetEvent = $scope.makeFacetArray(
                        $scope.data.facet_counts.facet_fields.facetEvent
                    );
                    $scope.arrFacetEventType = $scope.makeFacetArray(
                        $scope.data.facet_counts.facet_fields.facetEventType
                    );
                    $scope.arrFacetState = $scope.makeFacetArray(
                        $scope.data.facet_counts.facet_fields.facetState
                    );
                    $scope.arrFacetContentPackageName = $scope.makeFacetArray(
                        $scope.data.facet_counts.facet_fields
                            .facetContentPackageName
                    );

                    //facet value limit to 10
                    $scope.facetValueLimitContentType = 10;
                    $scope.facetValueLimitCategory = 10;
                    $scope.facetValueLimitProject = 10;
                    $scope.facetValueLimitFileType = 10;
                    $scope.facetValueLimitTag = 10;
                    $scope.facetValueLimitSource = 10;
                    $scope.facetValueLimitEvent = 10;
                    $scope.facetValueLimitEventType = 10;
                    $scope.facetValueLimitState = 10;

                    // NOTE: Hide more link if length of facet data is less than 10
                    if (
                        Object.keys(
                            $scope.data.facet_counts.facet_fields
                                .facetContentType
                        ).length <= 10
                    ) {
                        $scope.showMoreContentType = false;
                    } else {
                        $scope.showMoreContentType = true;
                    }

                    if (
                        Object.keys(
                            $scope.data.facet_counts.facet_fields.facetCategory
                        ).length <= 10
                    ) {
                        $scope.showMoreCategory = false;
                    } else {
                        $scope.showMoreCategory = true;
                    }

                    if (
                        Object.keys(
                            $scope.data.facet_counts.facet_fields.facetProject
                        ).length <= 10
                    ) {
                        $scope.showMoreProject = false;
                    } else {
                        $scope.showMoreProject = true;
                    }

                    if (
                        Object.keys(
                            $scope.data.facet_counts.facet_fields.facetFileType
                        ).length <= 10
                    ) {
                        $scope.showMoreFileType = false;
                    } else {
                        $scope.showMoreFileType = true;
                    }

                    if (
                        Object.keys(
                            $scope.data.facet_counts.facet_fields.facetTag
                        ).length <= 10
                    ) {
                        $scope.showMoreTag = false;
                    } else {
                        $scope.showMoreTag = true;
                    }

                    if (
                        Object.keys(
                            $scope.data.facet_counts.facet_fields.facetSource
                        ).length <= 10
                    ) {
                        $scope.showMoreSource = false;
                    } else {
                        $scope.showMoreSource = true;
                    }

                    if (
                        Object.keys(
                            $scope.data.facet_counts.facet_fields.facetEvent
                        ).length <= 10
                    ) {
                        $scope.showMoreEvent = false;
                    } else {
                        $scope.showMoreEvent = true;
                    }

                    if (
                        Object.keys(
                            $scope.data.facet_counts.facet_fields.facetEvent
                        ).length <= 10
                    ) {
                        $scope.showMoreEventType = false;
                    } else {
                        $scope.showMoreEventType = true;
                    }

                    if (
                        Object.keys(
                            $scope.data.facet_counts.facet_fields.facetState
                        ).length <= 10
                    ) {
                        $scope.showMoreState = false;
                    } else {
                        $scope.showMoreState = true;
                    }
                }, 500);

                if (
                    $scope.showRefinements == true &&
                    $scope.showFacetContentType == false &&
                    $scope.showFacetCategory == false &&
                    $scope.showFacetProject == false &&
                    $scope.showFacetFileType == false &&
                    $scope.showFacetDates == false &&
                    $scope.showFacetTag == false &&
                    $scope.showFacetSource == false &&
                    $scope.showFacetEvent == false &&
                    $scope.showFacetEventType == false &&
                    $scope.showFacetState == false &&
                    $scope.showFacetContentPackageName == false
                ) {
                    $scope.showRefinements = false;
                }
            });

            function capitalizeFirstLetter(selectedHeaderKeys) {
                return selectedHeaderKeys.charAt(0).toUpperCase() + selectedHeaderKeys.slice(1);
            }
            $scope.downloadCsv = function() {
                var csvContent = "data:text/csv;charset=utf-8,";
                var keyHeaderMapping = {
                    title: "Title",
                    byline: "Contributor",
                    campaign: "Campaign",
                    program: "Channel",
                    programId: "ProgramId",
                    category: "Category",
                    project: "Project",
                    contentType: "Content Type",
                    state: "State",
                    dateSubmitted: "Submit Date",
                    datePublished: "Publish Date",
                    link: "Review Link"
                };
                $scope.selectedHeaderKeys = ["title", "byline", "campaign", "program", "category", "contentType", "project", "state", "dateSubmitted", "datePublished", "programId", "link"];

                // Add CSV header with selected keys with first letter capitalized
                var capitalizedHeaders = $scope.selectedHeaderKeys.map(function(key) {
                    // Use the mapping to get the appropriate header
                    return capitalizeFirstLetter(keyHeaderMapping[key] || key);
                });
                csvContent += capitalizedHeaders.join(",") + "\r\n";

                $scope.data.response.docs.forEach(function(item) {
                    var row = [];
                    $scope.selectedHeaderKeys.forEach(function(key) {
                        if (key === "link") {
                            var link = `https://${window.location.hostname}`;
                            link = link+"/articleReview.action?programId="+item["programId"] +"&contentRequestId="+item["id"];
                            console.log("link: "+link)
                            row.push(link);
                        } else if(key === "state") {
                            row.push($translate.instant("angular." + item[key]));
                        } else {
                            var value = item[key];
                            var cleanString = typeof value === 'string' && value.includes(',') ? '"' + value + '"' : value;
                            row.push(cleanString);
                        }
                    });
                    csvContent += row.join(",") + "\r\n";
                });
                var encodedUri = encodeURI(csvContent);
                var link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", "AssetSearchList.csv");
                document.body.appendChild(link);
                link.click();
            };

            $scope.makeFacetArray = function(object) {
                var array = [];

                if (
                    object === $scope.data.facet_counts.facet_fields.facetState
                ) {
                    $.each(object, function(key, value) {
                        if (value != $scope.data.response.numFound) {
                            // NOTE: Check if status array
                            if (key.indexOf("contentrequeststate") != -1) {
                                oldKey = key;
                                translatedKey = $translate.instant(
                                    "angular." + key
                                );
                                array.push([translatedKey, oldKey, value]);
                            } else {
                                array.push([key, key, value]);
                            }
                        }
                    });
                } else {
                    $.each(object, function(key, value) {
                        if (value != $scope.data.response.numFound) {
                            array.push([key, value]);
                        }
                    });
                }

                var arr = array.sort(function(a, b) {
                    return b[1] - a[1];
                });

                return arr;
            };

            $scope.clearDates = function() {
                $scope.dates.startDate = null;
                $scope.dates.endDate = null;
                $scope.refineDateClickable = true;
                $scope.facetDateCreatedStart = null;
                $scope.facetDateCreatedEnd = null;

                //remove red error box if there is one
                $("#end-date").css({
                    border: "1px solid #CFCFCF",
                    background: "white"
                });

                $scope.searchContentRequestDTO.facetDateCreatedEnd =
                    $scope.facetDateCreatedEnd;
                $scope.searchContentRequestDTO.facetDateCreatedStart =
                    $scope.facetDateCreatedStart;
                $scope.fetchResults($scope.searchContentRequestDTO);
            };

            $scope.$watch("searchContentRequestDTO.agencySearch", function(
                newValue,
                oldValue
            ) {
                $scope.clearFacetSearch();
                $scope.fetchResults($scope.searchContentRequestDTO);
            });

            $scope.showMoreFacets = function(facet, facetLimit) {
                if (facet == "contentType") {
                    $scope.showMoreContentType = false;
                    $scope.showLessContentType = true;
                    $scope.facetValueLimitContentType = Object.keys(
                        $scope.data.facet_counts.facet_fields.facetContentType
                    ).length;
                } else if (facet == "category") {
                    $scope.showMoreCategory = false;
                    $scope.showLessCategory = true;
                    $scope.facetValueLimitCategory = Object.keys(
                        $scope.data.facet_counts.facet_fields.facetCategory
                    ).length;
                } else if (facet == "project") {
                    $scope.showMoreProject = false;
                    $scope.showLessProject = true;
                    $scope.facetValueLimitProject = Object.keys(
                        $scope.data.facet_counts.facet_fields.facetProject
                    ).length;
                } else if (facet == "fileType") {
                    $scope.showMoreFileType = false;
                    $scope.showLessFileType = true;
                    $scope.facetValueLimitFileType = Object.keys(
                        $scope.data.facet_counts.facet_fields.facetFileType
                    ).length;
                } else if (facet == "tag") {
                    $scope.showMoreTag = false;
                    $scope.showLessTag = true;
                    $scope.facetValueLimitTag = Object.keys(
                        $scope.data.facet_counts.facet_fields.facetTag
                    ).length;
                } else if (facet == "source") {
                    $scope.showMoreSource = false;
                    $scope.showLessSource = true;
                    $scope.facetValueLimitSource = Object.keys(
                        $scope.data.facet_counts.facet_fields.facetSource
                    ).length;
                } else if (facet == "state") {
                    $scope.showMoreState = false;
                    $scope.showLessState = true;
                    $scope.facetValueLimitState = Object.keys(
                        $scope.data.facet_counts.facet_fields.facetState
                    ).length;
                } else if (facet == "event") {
                    $scope.showMoreEvent = false;
                    $scope.showLessEvent = true;
                    $scope.facetValueLimitEvent = Object.keys(
                        $scope.data.facet_counts.facet_fields.facetEvent
                    ).length;
                } else if (facet == "eventType") {
                    $scope.showMoreEventType = false;
                    $scope.showLessEventType = true;
                    $scope.facetValueLimitEventType = Object.keys(
                        $scope.data.facet_counts.facet_fields.facetEvent
                    ).length;
                }
            };

            $scope.showLessFacets = function(facet, facetLimit) {
                if (facet == "contentType") {
                    $scope.showLessContentType = false;
                    $scope.showMoreContentType = true;
                    $scope.facetValueLimitContentType = 10;
                } else if (facet == "category") {
                    $scope.showLessCategory = false;

                    $scope.showMoreCategory = true;
                    $scope.facetValueLimitCategory = 10;
                } else if (facet == "project") {
                    $scope.showLessProject = false;
                    $scope.showMoreProject = true;
                    $scope.facetValueLimitProject = 10;
                } else if (facet == "fileType") {
                    $scope.showLessFileType = false;
                    $scope.showMoreFileType = true;
                    $scope.facetValueLimitFileType = 10;
                } else if (facet == "tag") {
                    $scope.showLessTag = false;
                    $scope.showMoreTag = true;
                    $scope.facetValueLimitTag = 10;
                } else if (facet == "source") {
                    $scope.showLessSource = false;
                    $scope.showMoreSource = true;
                    $scope.facetValueLimitSource = 10;
                } else if (facet == "state") {
                    $scope.showLessState = false;
                    $scope.showMoreState = true;
                    $scope.facetValueLimitState = 10;
                } else if (facet == "event") {
                    $scope.showLessEvent = false;
                    $scope.showMoreEvent = true;
                    $scope.facetValueLimitEvent = 10;
                } else if (facet == "eventType") {
                    $scope.showLessEventType = false;
                    $scope.showMoreEventType = true;
                    $scope.facetValueLimitEventType = 10;
                }
            };

            var permissionPromise = permission.canSeePermission(
                "articleReview"
            );
            permissionPromise.then(function(data) {
                $scope.canSeeArticleReview = data;
            });

            var sitePermissionsPromise = permission.all();
            sitePermissionsPromise.then(function(data) {
                $scope.sitePermissions = data;
                $scope.canCreate =
                    $scope.rfpEnabled ||
                    $scope.sitePermissions.canSeeAssignmentsWithCalendar ||
                    $scope.sitePermissions.canSeeAssignmentsWithoutCalendar ||
                    $scope.sitePermissions.canSeeAssetCreate ||
                    $scope.sitePermissions.canSeeWriteArticles;
            });

            var rfpEnabledPromise = permission.rfpEnabled($scope.programId);
            rfpEnabledPromise.then(function(data) {
                $scope.rfpEnabled = data;
            });

            $(".assetSearch").on("click", function() {
                window.location.reload(true);
            });

            // NOTE: BEGIN "create new" overlay"
            $("#createNewLink").on("click", function() {
                var numIcons = 0;
                if (
                    $scope.rfpEnabled &&
                    $scope.sitePermissions.canSeeCreateRfps
                )
                    numIcons += 1;
                if ($scope.sitePermissions.canSeeCreateAssignments)
                    numIcons += 2;
                if ($scope.sitePermissions.canSeeAssets) numIcons += 1;
                if ($scope.sitePermissions.canSeeCreateContent) numIcons += 1;
                if ($scope.sitePermissions.canSeePlanning) numIcons += 2;

                var h = "190px";
                var w = "420px";
                if (numIcons < 3) w = "240px";
                if (numIcons > 3) h = "320px";
                if (numIcons > 6) h = "410px";
                var opts = {
                    width: w,
                    height: h,
                    transition: "none",
                    html: createNewActionModal()
                };

                $.colorbox(opts);

                return false;
            });

            function createNewActionModal() {
                var attrs = angular.element("#attrs-id"),
                    programId = attrs.data("programid"),
                    startDate = attrs.data("today"),
                    enableContentPackage = attrs.data("enablecontentpackage");
                var urls = {};
                urls.urlCreateAssignment =
                    "/assignmentContentTypeModify.action?publishDeadlineString=createNewLink&openPublishDateBox=true&referrerPage=search.action#/search";
                urls.urlCreateEvent =
                    "/calendarEventCreate#/" +
                    programId +
                    "/calendarEvent/date/" +
                    startDate +
                    "?referrerPage=search.action#/search";
                urls.urlCreateProject =
                    "/projectCreate.action?referrerPage=search.action#/search";
                urls.urlRequestProposal =
                    "/rfpContentTypeModify.action?referrerPage=search.action#/search";
                urls.urlImportAssignments =
                    "/importContentTypeModify.action?referrerPage=search.action#/search";
                urls.urlCreateAsset =
                    "/uploadDigitalAsset.action?referrerPage=search.action#/search";
                urls.urlCreateContent =
                    "/managerWriteArticles.action?referrerPage=search.action#/search";

                var proposal =
                        $scope.rfpEnabled &&
                        $scope.sitePermissions.canSeeCreateRfps
                            ? '<a href="' +
                              urls.urlRequestProposal +
                              '"><span class="icon-ProposalDraft"></span><span>' +
                              $translate.instant("angular.requestforproposal") +
                              "</span></a>"
                            : "",
                    assignment = $scope.sitePermissions.canSeeCreateAssignments
                        ? '<a href="' +
                          urls.urlCreateAssignment +
                          '"><span class="icon-NavAssignments"></span><span>' +
                          $translate.instant("angular.addassignment") +
                          "</span></a>"
                        : "",
                    event = $scope.sitePermissions.canSeePlanning
                        ? '<a href="' +
                          urls.urlCreateEvent +
                          '"><span class="icon-Date"></span><span>' +
                          $translate.instant("angular.addevent") +
                          "</span></a>"
                        : "",
                    project = $scope.sitePermissions.canSeePlanning
                        ? '<a href="' +
                          urls.urlCreateProject +
                          '"><span class="icon-Projects"></span><span>' +
                          $translate.instant("angular.project") +
                          "</span></a>"
                        : "",
                    importAssignment = $scope.sitePermissions
                        .canSeeCreateAssignments
                        ? '<a href="' +
                          urls.urlImportAssignments +
                          '"><span class="icon-FileImport"></span><span>' +
                          $translate.instant("angular.importassignments") +
                          "</span></a>"
                        : "",
                    asset = $scope.sitePermissions.canSeeAssets
                        ? '<a href="' +
                          urls.urlCreateAsset +
                          '"><span class="icon-Asset"></span><span>' +
                          $translate.instant("angular.asset.button") +
                          "</span></a>"
                        : "",
                    content = $scope.sitePermissions.canSeeCreateContent
                        ? '<a href="' +
                          urls.urlCreateContent +
                          '"><span class="icon-NavReview"></span><span>' +
                          $translate.instant("angular.content") +
                          "</span></a>"
                        : "",
                    createContentPackage = enableContentPackage
                        ? '<a href="/contentPackageCreate"><span class="icon-StatusOffered"></span><span>' +
                          $translate.instant("nucleus.nav.contentPackage") +
                          "</span></a>"
                        : "",
                    html = "";

                html =
                    '<div class="create-new-pane search-create-new-pane">' +
                    "<h2>" +
                    $translate.instant("angular.createnew") +
                    "</h2>" +
                    assignment +
                    event +
                    project +
                    proposal +
                    importAssignment +
                    asset +
                    content +
                    createContentPackage +
                    "</div>";
                return html;
            }

            // END "create new" overlay"

            //#region Filter using asset listing details

            $("body").on("click", ".contentType", null, function() {
                $scope.refineSearch("contentType", $(this).attr("data-id"));
            });

            $("body").on("click", ".categoryType", null, function() {
                $scope.refineSearch("category", $(this).attr("data-id"));
            });

            $("body").on("click", ".stateType", null, function() {
                $scope.refineSearch("state", $(this).attr("data-id"));
            });
            $("body").on("click", ".contentPackageNameType", null, function() {
                $scope.refineSearch(
                    "contentPackageName",
                    $(this).attr("data-id")
                );
            });
            //#endregion
        }
    ]);
})();

app.controller("searchResultsDetailController", [
    "$timeout",
    "search",
    "$scope",
    "$http",
    "$filter",
    "skyword",
    "$location",
    "$translate",
    "$window",
    "$sce",
    function(
        $timeout,
        search,
        $scope,
        $http,
        $filter,
        skyword,
        $location,
        $translate,
        $window,
        $sce
    ) {
        $scope.getVideoIframeSrc = function(externalValueId) {
            return $sce.trustAsResourceUrl(
                "https://cdnapisec.kaltura.com/p/1737042/sp/173704200/embedIframeJs/uiconf_id/29635071/partner_id/1737042?iframeembed=true&playerId=kaltura_player_1459541123&entry_id=" +
                    externalValueId +
                    "&flashvars[streamerType]=auto"
            );
        };
    }
]);

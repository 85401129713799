app.service("videoUtilService", [
    "skyword",
    function(skyword) {
        return {
            /**
             * Check if we are dealing with a valid extension for video
             */
            isVideoFileName: function(filename) {
                var acceptFileTypes = [
                    "avi",
                    "flv",
                    "mpe",
                    "mpeg",
                    "mpg",
                    "mov",
                    "mp4",
                    "mp3",
                    "wav",
                    "ogg"
                ];
                // extract extension
                var ext = filename
                    .split(".")
                    .pop()
                    .toLowerCase();
                // search if correct
                for (var i = 0; i < acceptFileTypes.length; i++) {
                    if (acceptFileTypes[i] == ext) {
                        return true;
                    }
                }
                return false;
            }
        };
    }
]);

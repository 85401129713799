app.service("CampaignService", [
    "$http",
    function campaignService($http) {
        return {
            saveCampaignList: saveCampaignList
        };

        function saveCampaignList(contentRequestId, campaignDTO) {
            $http.post(
                "/API/v2/content-requests/" + contentRequestId + "/campaigns",
                campaignDTO
            );
        }
    }
]);

(function() {
    app.service("contentTypes", [
        "$http",
        function($http) {
            return {
                getDAMContentTypes: getDAMContentTypes,
                getIdeaContentTypes: getIdeaContentTypes,
                getIdeaTemplate: getIdeaTemplate,
                getProgramCategories: getProgramCategories,
                getPaymentTerms: getPaymentTerms
            };

            function getDAMContentTypes(programId) {
                var url = "/API/program/" + programId + "/content-type/dam";
                return $http.get(url);
            }

            function getIdeaContentTypes(programId) {
                var url = "/API/program/" + programId + "/content-type/idea";
                return $http.get(url);
            }

            function getIdeaTemplate(programId, contentTypeId) {
                //console.log("contentTypeId " + contentTypeId);
                var url =
                    "/API/program/" +
                    programId +
                    "/content-type/" +
                    contentTypeId +
                    "/idea-template";
                return $http.get(url);
            }

            function getProgramCategories(programId, contentTypeId) {
                //console.log("contentTypeId " + contentTypeId);
                var url =
                    "/API/program/" +
                    programId +
                    "/content-type/" +
                    contentTypeId +
                    "/categories";
                return $http.get(url);
            }

            function getPaymentTerms(contentTypeId) {
                return $http.get(
                    "/API/v2/content-types/" + contentTypeId + "/payment-terms"
                );
            }
        }
    ]);
})();

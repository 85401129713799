app.controller("invitations", [
    "$scope",
    "call",
    "overlay",
    function($scope, call, overlay) {
        //this and the one in invitations should be the same. page redirection for invitations uses either invatations or invitationsWriters
        $(".delete").click(function() {
            var id = $(this).attr("data-programAccountId");
            return call.overlay("deleteInvitationAjax.action", {
                targetProgramAccountId: id
            });
        });

        $(".withdraw").click(function() {
            var id = $(this).attr("data-programAccountId");
            return call.overlay("deleteInvitationAjax.action", {
                targetProgramAccountId: id
            });
        });
    }
])
    .controller("invitationsWriters", [
        "$scope",
        "call",
        "overlay",
        function($scope, call, overlay) {
            //this and the one in invitations should be the same.
            //page redirection for invitations uses either invatations or invitationsWriters

            $(".delete").click(function() {
                var id = $(this).attr("data-programAccountId");
                return call.overlay("deleteInvitationAjax.action", {
                    targetProgramAccountId: id
                });
            });

            $(".withdraw").click(function() {
                var id = $(this).attr("data-programAccountId");
                return call.overlay("deleteInvitationAjax.action", {
                    targetProgramAccountId: id
                });
            });

            $("#edit-buttons button.action").click(function() {
                var urlAction =
                    "remove" ===
                    $(this)
                        .attr("id")
                        .toLowerCase()
                        ? "removeAuthorFromProgramAjax.action"
                        : "reinstateAuthorToProgramAjax.action";
                return call.overlay(
                    urlAction,
                    {
                        id: $("#programAccountId").val()
                    },
                    650,
                    null
                );
            });
        }
    ])
    .controller("agencyInviteByEmail", ["$scope", function($scope) {}])
    .controller("inviteByEmail", ["$scope", function($scope) {}])
    .controller("agencyInviteWriter", [
        "$scope",
        "compensation",
        function($scope, compensation) {
            compensation.init();
        }
    ])
    .controller("inviteProgramWriter", [
        "$scope",
        "compensation",
        function($scope, compensation) {
            compensation.init();
        }
    ])
    .controller("invite", [
        "$scope",
        "login",
        "author",
        function($scope, login, author) {
            login.init();
            author.init();
            //onReady
            $(function() {
                $(".password_check").passStrength({
                    userid: "#firstName"
                });
            });
        }
    ])
    .controller("acceptInvite", [
        "$scope",
        "author",
        function($scope, author) {
            author.init();

            //onReady
            $(function() {
                if ($("#col-right").height() < $("#col-left").height()) {
                    $("#col-right").height($("#col-left").height());
                }
                // #error-box should be below the header, down inside this div
                if ($("#main-inner").length) {
                    if ($("#success-box").length) {
                        $("#success-box").prependTo("#main-inner");
                    }
                    if ($("#error-box").length) {
                        $("#error-box").prependTo("#main-inner");
                    }
                }
            });
        }
    ])
    .controller("rejectInviteAjax", ["$scope", function($scope) {}]);

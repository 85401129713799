app.service("finance", [
    "skyword",
    "call",
    "accountId",
    function(skyword, call, accountId) {
        return {
            init: function() {
                function formatCurrency(n) {
                    if (typeof n == "string") {
                        n.replace(/[^0-9]/g, "");
                    }
                    return n.toFixed(2);
                }

                function recalcFields(oldValue) {
                    oldValue = oldValue.replace(",", "");
                    if (!isNaN(oldValue)) {
                        $("#reqFunds1").html(
                            formatCurrency(Math.round(oldValue * 0.5))
                        );
                        $("#reqFunds2").html(
                            formatCurrency(Math.round(oldValue * 0.25))
                        );
                    } else {
                        $("#reqFunds").html(0);
                    }
                }

                //onReady
                $(function() {
                    /*
                     * Additional payment screens
                     */

                    $("html").on(
                        "click",
                        "#paymentConfirm button.action, #cancelLink",
                        function() {
                            var a = $(this),
                                urlAction = "makeAdditionalPaymentAjax.action",
                                paId = parseInt(
                                    a.attr("data-programAccountId"),
                                    10
                                );
                            if ($(this).attr("id") == "cancelLink") {
                                $.colorbox.close();
                            }

                            return call.overlay(
                                urlAction,
                                {
                                    id: accountId,
                                    programAccountId: paId,
                                    subActionName: $(this).attr("id"),
                                    step: $(
                                        "#paymentConfirm input[name=step]"
                                    ).val(),
                                    proposedPaymentId: $(
                                        "#paymentConfirm input[name=proposedPaymentId]"
                                    ).val()
                                },
                                850,
                                null
                            );
                        }
                    );

                    /*
                     * Finance budget management screens
                     */
                    $("html").on(
                        "click",
                        "#fundsConfirm button.action",
                        function() {
                            var urlAction = "addExtraFundsAjax.action";
                            return call.overlay(
                                urlAction,
                                {
                                    id: accountId,
                                    subActionName: $(this).attr("id"),
                                    step: $(
                                        "#fundsConfirm input[name=step]"
                                    ).val(),
                                    newAmount: $(
                                        "#fundsConfirm input[name=newAmount]"
                                    ).val(),
                                    programId: $(
                                        "#fundsConfirm input[name=programId]"
                                    ).val()
                                },
                                650,
                                null
                            );
                        }
                    );

                    // Escrow
                    // Initially populate fields.
                    //recalcFields();

                    // Recalculate whenever value changed
                    $("html").on("keyup", "#formSubmit_newAmount", function() {
                        recalcFields($(this).val());
                    });

                    $("html").on(
                        "click",
                        "#escrowFundsConfirm button.action",
                        function() {
                            var urlAction = "addEscrowBudgetAjax.action";
                            return call.overlay(
                                urlAction,
                                {
                                    id: accountId,
                                    subActionName: $(this).attr("id"),
                                    step: $(
                                        "#escrowFundsConfirm input[name=step]"
                                    ).val(),
                                    newAmount: $(
                                        "#escrowFundsConfirm input[name=newAmount]"
                                    ).val(),
                                    programId: $(
                                        "#escrowFundsConfirm input[name=programId]"
                                    ).val()
                                },
                                650,
                                null
                            );
                        }
                    );
                });
            }
        };
    }
]);

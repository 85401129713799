(function() {
    app.service("contentTemplate", [
        "$rootScope",
        "$timeout",
        "$compile",
        "$translate",
        "redactor",
        "skyword",
        "autoComplete",
        "crop",
        "assignmentAttachments",
        "validationUtil",
        "validation",
        "annotator",
        "call",
        "overlay",
        "setup",
        "assetCrop",
        "TemplateFactory",
        "ColorboxFactory",
        "TinymceService",
        function(
            $rootScope,
            $timeout,
            $compile,
            $translate,
            redactor,
            skyword,
            autoComplete,
            attachments,
            assignmentAttachments,
            validationUtil,
            validation,
            annotator,
            call,
            overlay,
            setup,
            assetCrop,
            TemplateFactory,
            ColorboxFactory,
            TinymceService
        ) {
            var currentAction = jQuery("attrs-id").data("actionname"),
                resetVal = false,
                idx = 0,
                currIdx,
                div,
                red,
                tree = {},
                uploadIds = [],
                embedUploadIds = [],
                attachmentImage = "",
                cardinalityControlButtons,
                attachment,
                attachmentHidden,
                request,
                attachmentContainer,
                attachmentContentTemplateElementDataId,
                openAttachment = function(
                    attachmentId,
                    contentTemplateElementDataId
                ) {
                    var attrs = $("#attrs-id");
                    var cropUrl =
                        "digitalAssetPublish" === attrs.data("actionname") ||
                        "editDigitalAsset" === attrs.data("actionname")
                            ? "setupCropDigitalAssetAjax"
                            : "setupCropAttachmentAjax";
                    var urlAction =
                        cropUrl +
                        ".action?contentRequestId=" +
                        $("#contentRequestId").val() +
                        "&attachmentId=" +
                        attachmentId +
                        "&contentTemplateElementDataId=" +
                        contentTemplateElementDataId;
                    return call.overlay(urlAction, null, 1090, null, "GET");
                },
                setupOptions = function(
                    rowStatusId,
                    inputValue,
                    container,
                    input
                ) {
                    return createOptions(
                        rowStatusId,
                        inputValue,
                        container,
                        input
                    );
                },
                doSelect = function() {
                    $(this).change();
                },
                hideSubmitButton = function() {
                    var reqEnabled = $("#req-enabled").val();
                    if (reqEnabled) {
                        $(
                            "#manager-submit-action .dropdown-toggle, #manager-submit-action .btn, #manager-submit-action a, #pubSubmitBtn"
                        )
                            .addClass("disabled")
                            .attr("disabled", "disabled");
                    }
                },
                saveRedactor = function() {
                    var div = $(this),
                        text = div.siblings(".templateElement"),
                        redact;
                    if (div.hasClass("redactor_nonTemplateRedactorText")) {
                        return false;
                    }
                    redact = $("#" + text.attr("id")).redactor("getObject");
                    if (null !== red) {
                        clearTimeout(red);
                        red = null;
                    }
                    saveRedactorEditor(redact);
                },
                redactorSaveTimeout = function(redactor) {
                    if (null !== red) {
                        clearTimeout(red);
                    }
                    red = setTimeout(function() {
                        saveRedactorEditor(redactor);
                    }, 2000);
                },
                redactorSave = function(redactor) {
                    redactorSaveTimeout(redactor);
                };
            (getTaxonomyValues = function(
                currObj,
                cteId,
                taxValId,
                resetVal,
                childTaxonomyIds,
                parentContainerDataId
            ) {
                var urlAction = "/taxonomyFilterValueAjax.action",
                    getPost = "POST",
                    options,
                    valArray = "",
                    childArr = [],
                    contentRequestId = $("#contentRequestId").val();
                window.resetVal = resetVal;
                if (
                    undefined !== childTaxonomyIds.val() &&
                    null !== childTaxonomyIds.val() &&
                    "" !== childTaxonomyIds.val()
                ) {
                    childArr = childTaxonomyIds.val().split(",");
                }
                if (true === $.isArray(taxValId)) {
                    $.each(taxValId, function(k, v) {
                        valArray += "&taxonomyValueIds=" + parseInt(v, 10);
                    });
                }

                options = {
                    cache: false,
                    type: getPost,
                    data:
                        true === $.isArray(taxValId)
                            ? "contentRequestId=" +
                              contentRequestId +
                              "&contentTemplateElementId=" +
                              cteId +
                              valArray
                            : {
                                  contentRequestId: contentRequestId,
                                  contentTemplateElementId: cteId,
                                  taxonomyValueId: taxValId
                              },
                    dataType: "html",
                    url: urlAction,
                    success: function(data, textStatus, jqXHR) {
                        var newHtml = $(data),
                            theOptions = newHtml.html();
                        optionsArr = [];
                        //create array of object with id, taxonomyId and displayName from newHtml - optionsArr
                        let options = newHtml[0].options;
                        if (options) {
                            for (let i = 0; i < options.length; i++) {
                                let { taxonomyid } = options[i].dataset;
                                if (options[i].value !== "") {
                                    optionsArr.push({
                                        id: parseInt(options[i].value),
                                        taxonomyId: parseInt(taxonomyid),
                                        displayName: options[i].text
                                    });
                                }
                            }
                        }
                        $.each(childArr, function(k, v) {
                            var containerId =
                                undefined !== parentContainerDataId &&
                                null !== parentContainerDataId &&
                                !isNaN(parentContainerDataId) &&
                                "" !== parentContainerDataId
                                    ? parentContainerDataId
                                    : -1;
                            var taxonomySelector =
                                -1 !== containerId
                                    ? ".container" + containerId
                                    : "";
                            var children = [];
                            if ("" === taxonomySelector) {
                                children = $(".taxonomy-element-" + v);
                            } else {
                                children = $(
                                    taxonomySelector + " .taxonomy-element-" + v
                                );
                            }

                            /**
                             * For each child, we're going to clone and reinstate the select object.  Then "re"-initialize the multiselect plugin where applicable.
                             */
                            children.each(function() {
                                var o,
                                    select = $(this),
                                    li = getElementLi(select),
                                    required = li.find(
                                        'input[type="hidden"].elementRequired'
                                    ),
                                    chzn = select.siblings(".chzn-container"),
                                    newSelect,
                                    ms = select.next(".ms-container"),
                                    contentTemplateElementType = select.siblings(
                                        "input.contentTemplateElementTypeId"
                                    ),
                                    contentTemplateElementTypeId = parseInt(
                                        contentTemplateElementType.val(),
                                        10
                                    );
                                resetChildTaxonomyFix(select);

                                let selectedValue = select.val();
                                selectedValue = $.isArray(selectedValue)
                                    ? selectedValue.join(", ")
                                    : selectedValue;
                                if (
                                    selectedValue === null ||
                                    selectedValue === undefined
                                ) {
                                    selectedValue = "";
                                }
                                //filter optionsArr based on select.data("taxonomyId") value and taxonomyId
                                let taxonomyID = parseInt(
                                    select[0].dataset.taxonomyid
                                );
                                let filteredTaxonomy = optionsArr.filter(
                                    (el) => el.taxonomyId === taxonomyID
                                );
                                let taxonomyOptions = filteredTaxonomy;

                                // create options html for the filtered array and set it to select and newSelect
                                let filteredOptionsHtml = `<option value="" data-taxonomyId="">${$translate.instant(
                                    "angular.message.selectOne"
                                )}</option>`;
                                for (
                                    let i = 0;
                                    i < taxonomyOptions.length;
                                    i++
                                ) {
                                    filteredOptionsHtml += `<option value="${
                                        taxonomyOptions[i].id
                                    }" data-taxonomyId="${
                                        taxonomyOptions[i].taxonomyId
                                    }"${
                                        selectedValue !== "" &&
                                        selectedValue.includes(
                                            taxonomyOptions[i].id
                                        )
                                            ? ' selected="selected"'
                                            : ""
                                    }>${
                                        taxonomyOptions[i].displayName
                                    }</option>`;
                                }
                                ms.remove();
                                chzn.remove();
                                select.html(filteredOptionsHtml);
                                newSelect = $(select)
                                    .clone()
                                    .wrap("<p>")
                                    .parent()
                                    .html();
                                newSelect = $(newSelect);
                                newSelect.html(filteredOptionsHtml);
                                select.before(newSelect);
                                select.remove();
                                /**
                                 * Reset multiselect plugin, but first remove "Select One" from the first value.
                                 * If length is still greater than zero, enable plugin.
                                 */
                                if (
                                    newSelect.hasClass("skywordMultiple") &&
                                    0 < newSelect.children().length
                                ) {
                                    o = newSelect.find(">option:first");
                                    if ("" === o.val()) {
                                        o.remove();
                                    }
                                    if (0 < newSelect.children().length) {
                                        newSelect.multiSelect({
                                            keepOrder: false,
                                            afterSelect: function() {
                                                $(this).change();
                                            },
                                            afterDeselect: function() {
                                                $(this).change();
                                            }
                                        });
                                    }
                                }

                                if (
                                    (8 === contentTemplateElementTypeId &&
                                        0 < newSelect.children().length) ||
                                    (1 === contentTemplateElementTypeId &&
                                        1 < newSelect.children().length)
                                ) {
                                    if (1 === contentTemplateElementTypeId) {
                                        newSelect.removeClass("chzn-done");
                                        if (
                                            newSelect.hasClass(
                                                "chzn-skyword-single"
                                            )
                                        ) {
                                            newSelect
                                                .chosenV2({
                                                    width: "65%",
                                                    search_contains: true
                                                })
                                                .change(saveElementValue);
                                        }
                                        setup.styledDropdowns();
                                        newSelect.chosen();
                                    }
                                    li.find(".loop")
                                        .find(".chzn-container")
                                        .hide();
                                    var cntr = 0;
                                    var countdownLoop = setInterval(function() {
                                        cntr++;
                                        if (cntr < 20) {
                                            if (li.find(".loop").length > 0) {
                                                li.find(".loop").show();
                                                li.fadeIn(
                                                    "fast",
                                                    function() {}
                                                );
                                                clearInterval(countdownLoop);
                                            }
                                        } else {
                                            clearInterval(countdownLoop);
                                        }
                                    }, 200);
                                    if (newSelect.hasClass("required")) {
                                        required.val(true);
                                    }
                                }
                            });
                        });
                        // check children with no values and hide if necessary.
                        request = null;
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        //console.log([jqXHR, textStatus, errorThrown]);
                    },
                    complete: function() {
                        request = null;
                    }
                };
                request = call.ajax(urlAction, options);
                return false;
            }),
                (resetChildTaxonomyInit = function(parentTaxonomy) {
                    var childTaxonomies,
                        parentContainerDataId = parentTaxonomy.data(
                            "parentcontainerdataid"
                        ),
                        elementCTED = parentTaxonomy.data("cted"),
                        liToHide = [];

                    if (1 > parentTaxonomy.length) {
                        return;
                    }

                    childTaxonomies = $(".parentdata" + elementCTED);

                    childTaxonomies.each(function(i, el) {
                        var child = $(this),
                            li = getElementLi(child),
                            required = child.siblings("input.elementRequired");
                        if (
                            undefined === parentTaxonomy.val() ||
                            null === parentTaxonomy.val() ||
                            "" === parentTaxonomy.val()
                        ) {
                            //if (false === li.hasClass("elementWrapper")) {
                            //    li = li.closest(".elementWrapper");
                            //}
                            li.fadeOut("fast");
                            //liToHide.push(li);  // hack because weird jQuery
                            child.val("");
                            child.change();
                            required.val(false);
                        }
                        if (
                            child.val() === null ||
                            child.val() === undefined ||
                            child.val() === ""
                        ) {
                            li.fadeOut("fast");
                        }
                        resetChildTaxonomyInit(child);
                    });
                }),
                (resetChildTaxonomyFix = function(parentTaxonomy) {
                    var childTaxonomies,
                        parentContainerDataId = parentTaxonomy.data(
                            "parentcontainerdataid"
                        ),
                        elementCTED = parentTaxonomy.data("cted"),
                        liToHide = [];

                    if (1 > parentTaxonomy.length) {
                        return;
                    }

                    childTaxonomies = $(".parentdata" + elementCTED);

                    childTaxonomies.each(function(i, el) {
                        var child = $(this),
                            li = getElementLi(child),
                            required = child.siblings("input.elementRequired");
                        li.fadeOut("fast");
                        child.val("");
                        child.change();
                        required.val(false);
                        resetChildTaxonomyFix(child);
                    });
                }),
                (updateTaxonomy = function(input) {
                    var taxonomyElement = $(input),
                        taxonomyValueId = taxonomyElement.val(),
                        contentTemplateElementList = taxonomyElement.closest(
                            "div.contentTemplateElementList"
                        ),
                        contentTemplateElementDataId = taxonomyElement
                            .siblings("input.contentTemplateElementDataId")
                            .val(),
                        contentTemplateElementId = taxonomyElement
                            .siblings(".contentTemplateElementId")
                            .val(),
                        childTaxonomyIds = taxonomyElement.siblings(
                            ".taxonomyChildren"
                        ),
                        parentContainerDataId = taxonomyElement.data(
                            "parentcontainerdataid"
                        ),
                        ids = [];

                    if (
                        undefined !== childTaxonomyIds.val() &&
                        null !== childTaxonomyIds.val() &&
                        "" !== childTaxonomyIds.val()
                    ) {
                        ids = childTaxonomyIds.val().split(",");
                    }

                    if (null != request) {
                        //request.abort();
                    }
                    resetChildTaxonomyFix(taxonomyElement);

                    if ("" !== taxonomyValueId) {
                        getTaxonomyValues(
                            taxonomyElement,
                            contentTemplateElementId,
                            taxonomyValueId,
                            true,
                            childTaxonomyIds,
                            parentContainerDataId
                        );
                    }
                    return input;
                }),
                // TODO: de-dupe this function, which one should be used?
                (checkCardinality = function(dataElement) {
                    var loop = getElementLoop(dataElement),
                        li = loop.parent();
                    if (0 < li.find(">.cardinalityContainer").length) {
                        updateOrderForCardinalityContainer(loop);
                    }
                }),
                (saveElementSuccess = function(data, retVal, el) {
                    var li = null,
                        err,
                        contentTemplateElementData,
                        containerDataElement,
                        opts = retVal.options;
                    removeError(el);
                    if (null === data || undefined === data || "" === data) {
                        console.log("Bad Response - no data");
                        return;
                    }

                    contentTemplateElementData =
                        retVal.contentTemplateElementData;

                    if (true === data.errorDuplicate) {
                        err =
                            "There has been an error. ContentTemplateElement with contentTemplateElementId " +
                            opts.contentTemplateElementId +
                            " &amp; " +
                            "contentId " +
                            data.contentId +
                            " has Cardinality turned off and already exists in table ContentTemplateElementData.\n\n" +
                            data.errorMessage;
                        console.log(err);
                    }
                    if ("" === contentTemplateElementData.val()) {
                        contentTemplateElementData.val(
                            data.contentTemplateElementDataId
                        );
                    } else {
                        if (
                            parseInt(contentTemplateElementData.val(), 10) !==
                            data.contentTemplateElementDataId
                        ) {
                            console.log(
                                "Something is very, very wrong. sent dataId: " +
                                    contentTemplateElementData.val() +
                                    " returned dataId: " +
                                    data.contentTemplateElementDataId
                            );
                        }
                    }

                    if ("" === $("#contentId").val()) {
                        console.log("214 - contentId should not be missing!");
                    } else {
                        if (
                            data.contentId !==
                            parseInt($("#contentId").val(), 10)
                        ) {
                            console.log(
                                "mismatched contentId " +
                                    data.contentId +
                                    " " +
                                    $("#contentId").val()
                            );
                        }
                    }

                    if (
                        undefined !== data.returnedValue &&
                        null !== data.returnedValue &&
                        "" !== data.returnedValue
                    ) {
                        el.val(data.returnedValue);
                    }
                    showCardinalityButtons();
                }),
                // TODO: de-dupe this function, which one should be used?
                /* jshint ignore:start */
                (showError = function(el) {
                    var mceEditor = el.find(".redactor_box");
                    el.addClass("errorField");
                    if (0 < mceEditor.length) {
                        mceEditor.addClass("errorField");
                    }
                }),
                /* jshint ignore:end */

                // TODO: de-dupe this function, which one should be used?
                /* jshint ignore:start */
                (removeError = function(el) {
                    var mceEditor = el.find(".redactor_box");
                    el.removeClass("errorField");
                    if (0 < mceEditor.length) {
                        mceEditor.removeClass("errorField");
                    }
                }),
                /* jshint ignore:end */

                (removeAttachmentOrg = function() {
                    var retVal,
                        link = $(this),
                        attachmentId = link.data("attachmentid"),
                        attachmentContainer = link.closest(
                            ".attachmentContainer"
                        ),
                        rowStatusId = attachmentContainer
                            .attr("id")
                            .replace("attachmentcontainer-", ""),
                        urlAction = "removeAttachmentAjax.action?",
                        opts,
                        container = getContainer(attachmentContainer);
                    attachment = attachmentContainer.find("div.attachment");
                    attachmentContentTemplateElementDataId = parseInt(
                        attachmentContainer.data("cted"),
                        10
                    );
                    attachmentHidden = $(
                        "#attachment-hidden-" +
                            attachmentContentTemplateElementDataId
                    );

                    retVal = createOptions(
                        rowStatusId,
                        null,
                        container,
                        attachmentContainer
                    );
                    opts = retVal.options;
                    opts.contentTemplateElementDataId = link.data("cted");
                    urlAction =
                        urlAction +
                        "attachmentId=" +
                        attachmentId +
                        "&contentRequestId=" +
                        parseInt(opts.contentRequestId, 10) +
                        "&contentTemplateElementDataId=" +
                        parseInt(opts.contentTemplateElementDataId, 10);
                    return call.overlay(urlAction, null, 500, null, null, null);
                }),
                (recropAttachment = function() {
                    var retVal,
                        link = $(this),
                        attachmentId = parseInt(link.data("attachmentid"), 10),
                        attachmentContainer = link.closest(
                            ".attachmentContainer"
                        ),
                        rowStatusId = attachmentContainer
                            .attr("id")
                            .replace("attachmentcontainer-", ""),
                        urlAction = link.attr("href"),
                        opts,
                        container = getContainer(attachmentContainer),
                        attachmentContentTemplateElementDataId = attachmentContainer.data(
                            "cted"
                        );
                    attachment = attachmentContainer.find("div.attachment");
                    attachmentHidden = $(
                        "#attachment-hidden-" +
                            attachmentContentTemplateElementDataId
                    );

                    retVal = createOptions(
                        rowStatusId,
                        null,
                        container,
                        attachmentContainer
                    );
                    opts = retVal.options;
                    return call.overlay(
                        urlAction,
                        opts,
                        1080,
                        null,
                        null,
                        null
                    );
                }),
                // TODO: this function is never called. deprecated?
                (removeInitEmptyTags = function() {
                    var $this = $(this);
                    if (
                        $.trim($this.html()).replace(/\s|&nbsp;/g, "").length <
                        2
                    ) {
                        $this.remove();
                    }
                }),
                (saveRedactorEditor = function(redactor) {
                    if (
                        undefined === redactor ||
                        undefined === redactor.$editor
                    ) {
                        return;
                    }

                    if ($("#actionNameId").val() === "contentRequestView") {
                        console.log("returning from save.");
                        return;
                    }

                    redactor.$editor.find("img").each(function() {
                        var image = $(this),
                            src = image.attr("src");

                        if (src.indexOf("/attachment") != -1) {
                            var start = src.indexOf("/attachment");
                            image.attr("src", src.substring(start, src.length));
                        }
                    });
                    redactor.$editor
                        .find("b")
                        .contents()
                        .unwrap()
                        .wrap("<strong></strong>");
                    var retVal,
                        cleanedInput = redactor.$editor
                            .find("a:empty")
                            .remove(),
                        inputValue = redactor.$editor.html(),
                        opts,
                        dirty,
                        div = redactor.$box,
                        el = redactor.$element,
                        id = el.attr("id"),
                        urlAction = "saveElementDataAjax.action",
                        ctetId,
                        contentTemplateElementType = div.siblings(
                            ".contentTemplateElementTypeId"
                        ),
                        r = div.siblings(".contentTemplateElementIdx").val(),
                        rowStatusId = parseInt(r, 10),
                        container = getContainer(div),
                        options,
                        firstBody = $(".addToBody").first(),
                        textarea = div.find("textarea"),
                        plainText =
                            textarea.hasClass("redactorText") ||
                            textarea.hasClass("singleLine") ||
                            textarea.hasClass("redactorTitle"),
                        isFirstBody = false,
                        inputValueDiv,
                        stockPhotoInfo = div.siblings(".stockPhotoInfo");

                    if (
                        (0 < redactor.$editor.find("mark.tempMark").length ||
                            0 <
                                redactor.$editor.find("mark.nocomment")
                                    .length) &&
                        redactor.$editor.hasClass("redactor_notEditable")
                    ) {
                        redactorSaveTimeout(redactor);
                        return;
                    }

                    if (plainText) {
                        if (textarea.hasClass("redactorTitle")) {
                            dirty = $("<div/>").html(inputValue);
                            dirty
                                .not("mark.inline-commenting, strong, em, p")
                                .contents()
                                .unwrap();
                            dirty
                                .find(".rangySelectionBoundary")
                                .contents()
                                .unwrap();
                            dirty.find("br").remove();
                        } else {
                            dirty = $("<div/>").html(inputValue);
                            dirty
                                .not("mark")
                                .contents()
                                .unwrap();
                            dirty
                                .find(".rangySelectionBoundary")
                                .contents()
                                .unwrap();
                        }
                        inputValue = $.trim(dirty.html());
                    } else {
                        inputValue = inputValue.replace(/&lt;script&gt;/gi, "");
                        inputValue = inputValue.replace(
                            /&lt;\/script&gt;/gi,
                            ""
                        );
                    }

                    if (
                        undefined !== inputValue &&
                        null !== inputValue &&
                        "" !== inputValue
                    ) {
                        inputValue = redactor.cleanRemoveEmptyTags(inputValue);
                        inputValue = inputValue
                            .replace(/\u00a0]/g, "")
                            .replace(/&nbsp;/g, " ");
                        inputValue = $.trim(inputValue.replace(/\s{2,}/g, " "));
                    }

                    retVal = createOptions(
                        rowStatusId,
                        inputValue,
                        container,
                        div
                    );
                    opts = retVal.options;

                    if (div.hasClass("errorField")) {
                        div.removeClass("errorField");
                    }

                    ctetId = parseInt(contentTemplateElementType.val(), 10);
                    if (
                        undefined === opts.contentTemplateElementDataId ||
                        null === opts.contentTemplateElementDataId
                    ) {
                        return;
                    }

                    options = {
                        cache: false,
                        type: "POST",
                        data: opts,
                        dataType: "json",
                        url: urlAction,
                        success: function(data) {
                            saveElementSuccess(data, retVal, div);
                            if (el.hasClass("isParentElement")) {
                                updateChildrenElements(el, data);
                            } else if (el.hasClass("childElement")) {
                                checkParentAndSiblings(el);
                            }

                            if (
                                null !== stockPhotoInfo &&
                                0 < stockPhotoInfo.length
                            ) {
                                if (
                                    undefined !==
                                        data.stockPhotosToBePurchased &&
                                    null !== data.stockPhotosToBePurchased &&
                                    0 < data.stockPhotosToBePurchased
                                ) {
                                    stockPhotoInfo.html(data.stockPhotoInfo);
                                    if (
                                        true !== stockPhotoInfo.is(":visible")
                                    ) {
                                        stockPhotoInfo.fadeIn("fast");
                                    }
                                } else {
                                    stockPhotoInfo.fadeOut("fast");
                                }
                            }
                            try {
                                // reload comments - highlighted text may have been removed.
                                annotator.contentAnnotationsAjax();
                            } catch (ec) {
                                console.log(ec);
                            }
                        },
                        error: function(data, status, err) {
                            console.log([data, status, err]);
                        },
                        complete: function(data) {
                            var finalString = "",
                                metaTitle,
                                metaTitleType,
                                metaDesc,
                                metaDescType,
                                metaMaxLength,
                                firstBody = $(".addToBody").first(),
                                isFirstBody = false,
                                inputValueDiv,
                                maxLength,
                                suffix,
                                redactorHtmlTitle,
                                redactorDescription,
                                redactorDiv,
                                redactorText,
                                body = $("body");

                            try {
                                if (
                                    10 === ctetId &&
                                    (body.hasClass("manager-publish") ||
                                        body.hasClass("mgr-publish") ||
                                        body.hasClass("publish"))
                                ) {
                                    metaTitleType = $(
                                        'input.contentTemplateElementTypeId:hidden[value="24"]'
                                    );
                                    redactorDiv = metaTitleType.siblings(
                                        ".redactor_box"
                                    );
                                    redactorText = redactorDiv.find("textarea");
                                    redactorHtmlTitle = $(
                                        "#" + redactorText.attr("id")
                                    ).redactor("getObject");
                                    if ("" === $.trim(redactorText.val())) {
                                        suffix = metaTitleType
                                            .siblings(".titleTagSuffix")
                                            .val();
                                        if (
                                            undefined !== suffix &&
                                            "" !== suffix
                                        ) {
                                            maxLength =
                                                parseInt(
                                                    metaTitleType.attr(
                                                        "maxlength"
                                                    ),
                                                    10
                                                ) -
                                                (suffix.length + 1);
                                        } else {
                                            maxLength = parseInt(
                                                metaTitleType.attr("maxlength"),
                                                10
                                            );
                                        }
                                        finalString = skyword.generateSanitizedString(
                                            inputValue,
                                            suffix,
                                            maxLength
                                        );
                                        if (
                                            undefined !==
                                            redactorHtmlTitle.$editor
                                        ) {
                                            redactorHtmlTitle.$editor.html(
                                                finalString
                                            );
                                            redactorHtmlTitle.sync();
                                            saveRedactorEditor(
                                                redactorHtmlTitle
                                            );
                                        }
                                    }
                                }

                                if (
                                    el.attr("id") === firstBody.attr("id") &&
                                    (body.hasClass("manager-publish") ||
                                        body.hasClass("mgr-publish") ||
                                        body.hasClass("publish"))
                                ) {
                                    metaDescType = $(
                                        'input.contentTemplateElementTypeId:hidden[value="26"]'
                                    );
                                    redactorDiv = metaDescType.siblings(
                                        ".redactor_box"
                                    );
                                    redactorText = redactorDiv.find("textarea");
                                    redactorDescription = $(
                                        "#" + redactorText.attr("id")
                                    ).redactor("getObject");

                                    if ("" === $.trim(redactorText.val())) {
                                        maxLength = parseInt(
                                            metaDescType
                                                .siblings(".maxLength")
                                                .val(),
                                            10
                                        );
                                        finalString = skyword.generateSanitizedStringEllipsis(
                                            inputValue,
                                            maxLength
                                        );
                                        if (
                                            undefined !==
                                            redactorDescription.$editor
                                        ) {
                                            redactorDescription.$editor.html(
                                                finalString
                                            );
                                            redactorDescription.sync();
                                            saveRedactorEditor(
                                                redactorDescription
                                            );
                                        }
                                    }
                                }
                            } catch (ex) {
                                console.log(ex);
                            }
                        }
                    };
                    call.ajax(urlAction, options);
                }),
                (saveElementValue = function(thatElement) {
                    // I'm sorry for this unholy JS.
                    // Date really should be refactored out of this, but that requires
                    // some Java time as well and there is not time to do that at the moment.
                    var input =
                        undefined !== thatElement.target
                            ? thatElement.target
                            : thatElement;

                    var d = new Date(),
                        urlAction,
                        opts,
                        options,
                        valArray = "",
                        asQueryString = false,
                        queryString = "",
                        retVal,
                        el,
                        rowStatusId,
                        apiNodeName = null,
                        inputValue,
                        container,
                        ctetId,
                        contentTemplateElementType,
                        elParent,
                        externalOptions,
                        externalTaxonomyValues = "",
                        resourceUriValues = "",
                        displayOrder = null,
                        formattedAddress,
                        elementRequired,
                        geoParent,
                        geoForm;

                    el = $(input);
                    inputValue = el.hasClass("select")
                        ? el.val()
                        : $.trim(el.val());

                    urlAction = "saveElementDataAjax.action";

                    hideSubmitButton();

                    $("#seoScore").removeAttr("disabled");

                    /**
                     * Keywords and Facebook Links break the template rules, need a special case for them.
                     *
                     * In the future, this should just be el.closest(".articleElement").  All the attributes are now
                     * attached to that <div>
                     */
                    if (
                        el.hasClass("assignmentKeyword") ||
                        el.hasClass("facebookLink") ||
                        el.hasClass("geoLocation") ||
                        el.hasClass("buildGeoLocation")
                    ) {
                        if (el.hasClass("assignmentKeyword")) {
                            elParent = el.closest(".suggestedKeywordModule");
                        } else if (
                            el.hasClass("geoLocation") ||
                            el.hasClass("buildGeoLocation")
                        ) {
                            elParent = el.closest(".mapLarge");
                            geoParent = el.closest(".articleElement");
                            geoForm = geoParent.find(".geoForm");
                        } else {
                            elParent = el.closest(".linkContainer");
                        }

                        contentTemplateElementType = elParent.siblings(
                            ".contentTemplateElementTypeId"
                        );
                        rowStatusId = elParent
                            .siblings(".contentTemplateElementIdx")
                            .val();
                        container = getContainer(elParent);
                        elementRequired = elParent.siblings(".elementRequired");
                    } else {
                        contentTemplateElementType = el.siblings(
                            ".contentTemplateElementTypeId"
                        );
                        rowStatusId = el
                            .siblings(".contentTemplateElementIdx")
                            .val();
                        container = getContainer(el);
                        elementRequired = el.siblings(".elementRequired");
                    }
                    ctetId = parseInt(contentTemplateElementType.val(), 10);

                    try {
                        if (el.hasClass("templateCheckbox")) {
                            inputValue = el.is(":checked");
                        }

                        // Force Stock Ticker to be upper case.
                        if (20 === ctetId && "" !== inputValue) {
                            inputValue = inputValue.toUpperCase();
                            el.val(inputValue);
                        }

                        if (
                            el.hasClass("facebookLink") &&
                            undefined !== inputValue &&
                            null !== inputValue &&
                            "" !== inputValue
                        ) {
                            if (
                                inputValue.indexOf("https://") !== 0 &&
                                inputValue.indexOf("http://") !== 0
                            ) {
                                inputValue = "http://" + inputValue;
                                el.val(inputValue);
                                el.blur();
                                return false;
                            }
                        }

                        // Keyword break the template rules, need a special case for them.
                        retVal = createOptions(
                            rowStatusId,
                            inputValue,
                            container,
                            el.hasClass("assignmentKeyword") ||
                            el.hasClass("facebookLink") ||
                            el.hasClass("buildGeoLocation") ||
                            el.hasClass("geoLocation")
                                ? elParent
                                : el
                        );
                        opts = retVal.options;
                        if (el.hasClass("errorField")) {
                            el.removeClass("errorField");
                        }

                        if (
                            undefined === opts.contentTemplateElementDataId ||
                            null === opts.contentTemplateElementDataId
                        ) {
                            return false;
                        }

                        // Select needs to be glommed together differently depending on if multiple or not.
                        if (el.hasClass("select")) {
                            opts.inputValue = null;
                            if (
                                el.hasClass("chosenAutoComplete") ||
                                el.hasClass("chosenSelectStyle")
                            ) {
                                displayOrder = el.siblings(".displayOrder");
                                externalOptions = el.find("option:selected");
                                apiNodeName = el.siblings(".apiNodeName");
                                externalOptions.each(function() {
                                    var o = $(this);
                                    resourceUriValues += "," + o.val();
                                    externalTaxonomyValues += ", " + o.text();
                                });
                                opts.displayOrder = displayOrder.val();
                                opts.apiNodeName = apiNodeName.val();
                                opts.externalTaxonomyUriValues = $.trim(
                                    resourceUriValues.substring(1)
                                );
                                opts.externalTaxonomyValues = $.trim(
                                    externalTaxonomyValues.substring(1)
                                );
                            } else {
                                if (el.hasClass("skywordMultiple")) {
                                    if (true === $.isArray(inputValue)) {
                                        $.each(inputValue, function(k, v) {
                                            valArray +=
                                                "&taxonomyValueIds=" +
                                                parseInt(v, 10);
                                        });
                                        asQueryString = true;
                                        $.each(opts, function(k, v) {
                                            queryString += "&" + k + "=" + v;
                                        });
                                        queryString += valArray;
                                    } else {
                                        opts.taxonomyValueIds = inputValue;
                                    }
                                } else {
                                    opts.taxonomyValueId = isNaN(
                                        parseInt(inputValue, 10)
                                    )
                                        ? -1
                                        : parseInt(inputValue, 10);
                                }
                            }
                        } else if (
                            el.hasClass("geoLocation") ||
                            el.hasClass("buildGeoLocation")
                        ) {
                            geoParent = el.closest(".articleElement");
                            geoForm = geoParent.find(".geoForm");
                            formattedAddress = geoParent.find(
                                "input.formattedAddress"
                            );

                            opts.sitemapAddress = geoParent
                                .siblings("input.sitemapAddress")
                                .val();
                            opts.latitude = geoParent
                                .find("input.latitude")
                                .val();
                            opts.longitude = geoParent
                                .find("input.longitude")
                                .val();
                            opts.geolocationStreet = geoForm
                                .find("input.geolocationStreet")
                                .val();
                            opts.geolocationCity = geoForm
                                .find("input.geolocationCity")
                                .val();
                            opts.geolocationState = geoForm
                                .find("input.geolocationState")
                                .val();
                            opts.geolocationZip = geoForm
                                .find("input.geolocationZip")
                                .val();
                        }

                        if ("primary-cat" === el.attr("id")) {
                            opts.taxonomyValueId = isNaN(parseInt(el.val(), 10))
                                ? -1
                                : parseInt(el.val(), 10);
                            opts.inputValue = $("#primary-cat-descr").val();
                        }
                        var dataBack;
                        options = {
                            cache: false,
                            type: "POST",
                            data: asQueryString ? queryString : opts,
                            dataType: "json",
                            url: urlAction,
                            success: function(data) {
                                dataBack = data;
                            },
                            error: function(data, status, err) {
                                console.log([data, status, err]);
                            },
                            complete: function(a, b, c, d, e, f) {
                                var body = $("body"),
                                    finalString = "",
                                    metaTitle,
                                    metaTitleType,
                                    metaDesc,
                                    metaDescType,
                                    metaMaxLength,
                                    firstBody = $(".addToBody").first(),
                                    isFirstBody = false,
                                    inputValueDiv,
                                    maxLength,
                                    suffix,
                                    redactorEditor,
                                    redactorDiv,
                                    redactorText;

                                try {
                                    saveElementSuccess(dataBack, retVal, el);
                                    if (el.hasClass("hasChildTaxonomy")) {
                                        updateTaxonomy(input);
                                    } else if (el.hasClass("isParentElement")) {
                                        updateChildrenElements(input, dataBack);
                                    } else if (
                                        el.hasClass("childElement") &&
                                        "" === inputValue
                                    ) {
                                        checkParentAndSiblings(input);
                                    }
                                } catch (ex) {
                                    console.log(
                                        "saveelementvalue complete error"
                                    );
                                    console.log(ex);
                                }

                                try {
                                    if (
                                        10 === ctetId &&
                                        (body.hasClass("manager-publish") ||
                                            body.hasClass("mgr-publish") ||
                                            body.hasClass("publish"))
                                    ) {
                                        metaTitleType = $(
                                            'input.contentTemplateElementTypeId:hidden[value="24"]'
                                        );
                                        //metaTitle = metaTitleType.siblings(".templateElement");
                                        redactorDiv = metaTitleType.siblings(
                                            ".redactor_box"
                                        );
                                        redactorText = redactorDiv.find(
                                            "textarea"
                                        );
                                        redactorEditor = $(
                                            "#" + redactorText.attr("id")
                                        ).redactor("getEditor");
                                        if ("" === $.trim(redactorText.val())) {
                                            suffix = metaTitleType
                                                .siblings(".titleTagSuffix")
                                                .val();
                                            if (
                                                undefined !== suffix &&
                                                "" !== suffix
                                            ) {
                                                maxLength =
                                                    parseInt(
                                                        metaTitleType.attr(
                                                            "maxlength"
                                                        ),
                                                        10
                                                    ) -
                                                    (suffix.length + 1);
                                            } else {
                                                maxLength = parseInt(
                                                    metaTitleType.attr(
                                                        "maxlength"
                                                    ),
                                                    10
                                                );
                                            }
                                            finalString = skyword.generateSanitizedString(
                                                inputValue,
                                                suffix,
                                                maxLength
                                            );
                                            redactorEditor.$editor.html(
                                                finalString
                                            );
                                            redactorEditor.sync();
                                            window.skydactorSaveTimeout(
                                                redactorEditor
                                            );
                                        }
                                    }

                                    if (
                                        el.attr("id") ===
                                            firstBody.attr("id") &&
                                        (body.hasClass("manager-publish") ||
                                            body.hasClass("mgr-publish") ||
                                            body.hasClass("publish"))
                                    ) {
                                        metaDescType = $(
                                            'input.contentTemplateElementTypeId:hidden[value="26"]'
                                        );
                                        redactorDiv = metaDescType.siblings(
                                            ".redactor_box"
                                        );
                                        redactorText = redactorDiv.find(
                                            "textarea"
                                        );
                                        redactorEditor = $(
                                            "#" + redactorText.attr("id")
                                        ).redactor("getEditor");

                                        if ("" === $.trim(redactorText.val())) {
                                            maxLength = parseInt(
                                                metaDescType
                                                    .siblings(".maxLength")
                                                    .val(),
                                                10
                                            );
                                            finalString = skyword.generateSanitizedStringEllipsis(
                                                inputValue,
                                                maxLength
                                            );
                                            redactorEditor.$editor.html(
                                                finalString
                                            );
                                            redactorEditor.sync();
                                            window.skydactorSaveTimeout(
                                                redactorEditor
                                            );
                                        }
                                    }
                                } catch (ex) {
                                    console.log("saving error");
                                    console.log(ex);
                                }
                            }
                        };
                        call.ajax(urlAction, options);
                    } catch (e) {
                        console.log(e);
                    }
                }),
                (saveGeoLocation = function(e) {
                    var d = new Date(),
                        urlAction,
                        opts,
                        options,
                        valArray = "",
                        asQueryString = false,
                        queryString = "",
                        retVal,
                        input = this,
                        el,
                        rowStatusId,
                        apiNodeName = null,
                        inputValue,
                        container,
                        ctetId,
                        contentTemplateElementType,
                        elParent,
                        externalOptions,
                        externalTaxonomyValues = "",
                        resourceUriValues = "",
                        displayOrder = null,
                        formattedAddress,
                        elementRequired,
                        geoParent,
                        geoForm;

                    el = $(input);
                    inputValue = $.trim(el.val());
                    urlAction = "saveElementDataAjax.action";

                    hideSubmitButton();
                    $("#seoScore").removeAttr("disabled");
                    retVal = createOptions(
                        rowStatusId,
                        inputValue,
                        container,
                        el.hasClass("assignmentKeyword") ||
                        el.hasClass("facebookLink") ||
                        el.hasClass("geoLocation")
                            ? elParent
                            : el
                    );
                    opts = retVal.options;
                    geoParent = el.closest(".articleElement");
                    geoForm = geoParent.find(".geoForm");
                    formattedAddress = geoParent.find("input.formattedAddress");
                    opts.sitemapAddress = geoParent
                        .find("input.sitemapAddress")
                        .val();
                    opts.latitude = geoParent.find("input.latitude").val();
                    opts.longitude = geoParent.find("input.longitude").val();
                    opts.geolocationStreet = geoForm
                        .find("input.geolocationStreet")
                        .val();
                    opts.geolocationCity = geoForm
                        .find("input.geolocationCity")
                        .val();
                    opts.geolocationState = geoForm
                        .find("input.geolocationState")
                        .val();
                    opts.geolocationZip = geoForm
                        .find("input.geolocationZip")
                        .val();
                    options = {
                        cache: false,
                        type: "POST",
                        data: asQueryString ? queryString : opts,
                        dataType: "json",
                        url: urlAction,
                        success: function(data) {
                            console.log("Saved");
                            saveElementSuccess(data, retVal, el);
                        },
                        error: function(data, status, err) {
                            console.log("Error");
                        },
                        complete: function(a, b, c, d, e, f) {}
                    };
                    console.log("Saving");
                    call.ajax(urlAction, options);
                }),
                (preventEnterSubmit = function(e) {
                    var el;
                    if (13 === e.which) {
                        el = $(this);
                        if (el.hasClass("buildGeoLocation")) {
                            $("button.geoLocationAlt").click();
                        } else if (el.hasClass("geoLocation")) {
                            $("button.geoLocation").click();
                        }

                        return false;
                    }
                }),
                (checkTab = function(e, editorId) {
                    var el =
                            undefined !== editorId &&
                            null !== editorId &&
                            "" !== editorId
                                ? $(document.getElementById(editorId))
                                : $(this),
                        select = el
                            .parent()
                            .parent()
                            .siblings("select.skywordMultiple"),
                        aParent;

                    if (9 === e.keyCode && false === e.shiftKey) {
                        e.stopPropagation();
                        if (el.hasClass("ms-list")) {
                            return gotoNextElement(select);
                        } else if (
                            undefined !== editorId &&
                            null !== editorId &&
                            "" !== editorId
                        ) {
                            return gotoNextElement(el);
                        } else if (el.hasClass("viewAttachmentLink")) {
                            aParent = el.parents().eq(5);
                            return gotoNextElement(
                                aParent.siblings(".templateElement")
                            );
                        } else {
                            return gotoNextElement(this);
                        }
                    } else if (9 === e.keyCode && true === e.shiftKey) {
                        e.stopPropagation();
                        if (el.hasClass("ms-list")) {
                            return gotoPrevElement(select);
                        } else if (
                            undefined !== editorId &&
                            null !== editorId &&
                            "" !== editorId
                        ) {
                            return gotoPrevElement(el);
                        } else if (el.hasClass("viewAttachmentLink")) {
                            aParent = el.parents().eq(5);
                            return gotoPrevElement(
                                aParent.siblings(".templateElement")
                            );
                        } else {
                            return gotoPrevElement(this);
                        }
                    }
                }),
                (gotoPrevElement = function(t) {
                    var el = t instanceof jQuery ? t : $(t),
                        elId = el.attr("id"),
                        getNext = false,
                        prevEl = null,
                        getPrev = false;
                    $(".templateElement").each(function() {
                        var ele = $(this),
                            id = ele.attr("id");
                        if (id === elId) {
                            if (
                                prevEl.hasClass("skywordMCE") ||
                                prevEl.hasClass("captionMCE")
                            ) {
                                prevEl.tinymce().focus();
                                return false;
                            } else if (prevEl.hasClass("skywordMultiple")) {
                                prevEl
                                    .siblings("div.ms-container")
                                    .find("div.ms-selectable>ul.ms-list")
                                    .focus();
                                return false;
                            } else if (prevEl.hasClass("attachmentElement")) {
                                prevEl
                                    .siblings("div.attachmentContainer")
                                    .find("a.viewAttachmentLink")
                                    .focus();
                                return false;
                            } else {
                                prevEl.focus();
                                return false;
                            }
                        }
                        prevEl = ele;
                    });
                    return false;
                }),
                (gotoNextElement = function(t) {
                    var el = t instanceof jQuery ? t : $(t),
                        elId = el.attr("id"),
                        getNext = false;
                    $(".templateElement, .templateElementText").each(
                        function() {
                            var ele = $(this),
                                id = ele.attr("id");
                            if (true === getNext) {
                                if (
                                    ele.hasClass("skywordMCE") ||
                                    ele.hasClass("captionMCE")
                                ) {
                                    ele.tinymce().focus();
                                    return false;
                                } else if (ele.hasClass("skywordMultiple")) {
                                    ele.siblings("div.ms-container")
                                        .find("div.ms-selectable>ul.ms-list")
                                        .focus();
                                    return false;
                                } else if (ele.hasClass("attachmentElement")) {
                                    ele.siblings("div.attachmentContainer")
                                        .find("a.viewAttachmentLink")
                                        .focus();
                                    return false;
                                } else {
                                    ele.focus();
                                    return false;
                                }
                            }
                            if (id === elId) {
                                getNext = true;
                            }
                        }
                    );
                    return false;
                }),
                (addEntityValue = function() {
                    /**
                     * For entities and stock ticker
                     */
                    var a = $(this),
                        parentTd = a.parent(),
                        phrase = a.attr("data-entity"),
                        entityContainer = parentTd.parents().eq(3),
                        element = entityContainer.siblings(
                            ".templateElementText"
                        ),
                        val = element.val(),
                        regex = new RegExp(phrase, "gi"),
                        n,
                        values,
                        newValue = "";

                    if ("" === val) {
                        element.val(phrase);
                        element.blur();
                    } else {
                        n = val.match(regex);
                        if (null === n || undefined === n || 0 === n.length) {
                            val += ", " + phrase;
                            values = val.split(",");
                            $.each(values, function(k, v) {
                                newValue += ", " + $.trim(v);
                            });
                            element.val(newValue.substring(2));
                            element.blur();
                        }
                    }
                    a.fadeOut("fast");
                }),
                (templateCharacterCount = function() {
                    var text = $(this),
                        theNext = text.next(),
                        theCount = theNext.find(">span");
                    theCount.text(text.val().length);
                }),
                (updateEntityTable = function() {
                    /**
                     * If a manager enters a value manually, hide add button if it matches.
                     */
                    var input = $(this),
                        valString = input.val(),
                        vals,
                        newValueString = "",
                        contentTemplateElementType = input.siblings(
                            ".contentTemplateElementTypeId"
                        ),
                        entityContainer = input.siblings(".entityContainer"),
                        entityPhrase = entityContainer.find("a.entityAdd");
                    if (20 === parseInt(contentTemplateElementType.val(), 10)) {
                        valString = valString.replace(" ", "");
                    }

                    vals = valString.split(",");
                    entityPhrase.each(function() {
                        try {
                            var a = $(this),
                                phrase = $.trim(a.attr("data-entity")),
                                regex = new RegExp(phrase, "i"),
                                phraseHidden = false;
                            $.each(vals, function(k, v) {
                                try {
                                    v = $.trim(v);
                                    if ("" !== v && null !== v.match(regex)) {
                                        newValueString += ", " + v;
                                        a.hide();
                                        phraseHidden = true;
                                        return;
                                    } else {
                                        if (false === phraseHidden) {
                                            a.show();
                                        }
                                    }
                                } catch (e) {
                                    console.log("Inner: " + e.message);
                                }
                            });
                        } catch (e) {
                            console.log(e);
                        }
                    });
                }),
                (checkDisableSubmit = function() {
                    var el = $(this);
                    if (
                        el.hasClass("required") ||
                        el.hasClass("redactor_articleTitleElement") ||
                        el.hasClass("redactor_required") ||
                        el.hasClass("addToBody")
                    ) {
                        hideSubmitButton();
                        $("#seoScore").removeAttr("disabled");
                    }
                }),
                (clearCalendar = function() {
                    var cted =
                        ".date-" +
                        this.getAttribute("data-contenttemplateelementdataid");
                    var element = document.querySelector(cted);
                    element.value = "";
                    saveElementValue(element);
                }),
                (saveElementSetup = function() {
                    var body = $("body");
                    body.on(
                        "change",
                        "input.templateCheckbox",
                        null,
                        saveElementValue
                    );
                    body.on(
                        "change",
                        "input.templatePicker",
                        null,
                        saveElementValue
                    );
                    body.on(
                        "blur",
                        "input.templateElementText",
                        null,
                        saveElementValue
                    );
                    body.on(
                        "blur",
                        "input.buildGeoLocation",
                        null,
                        saveElementValue
                    );
                    body.on(
                        "focus",
                        "input.templateElementText",
                        null,
                        checkDisableSubmit
                    );
                    body.on(
                        "focus",
                        "div.redactor_required",
                        null,
                        checkDisableSubmit
                    );
                    body.on(
                        "blur",
                        "input.templateEntity",
                        null,
                        updateEntityTable
                    );
                    body.on("click", "a.entityAdd", null, addEntityValue);
                    body.on(
                        "keypress",
                        "input.templateElement",
                        null,
                        preventEnterSubmit
                    );
                    body.on(
                        "keypress",
                        "input.templateElement",
                        null,
                        checkTab
                    );
                    body.on(
                        "keypress",
                        "div.ms-selectable>ul.ms-list",
                        null,
                        checkTab
                    );
                    body.on(
                        "keypress",
                        "div.attachmentContainer a.viewAttachmentLink",
                        null,
                        checkTab
                    );
                    body.on(
                        "focus",
                        "textarea.templateElement.nocaption",
                        null,
                        checkDisableSubmit
                    );
                    body.on(
                        "blur",
                        "textarea.templateElement.nocaption",
                        null,
                        saveElementValue
                    );
                    body.on(
                        "keypress",
                        "textarea.templateElement.nocaption",
                        null,
                        templateCharacterCount
                    );
                    body.on(
                        "change",
                        "select.templateElement",
                        null,
                        saveElementValue
                    );
                    body.on("click", "span.clearCalendar", null, clearCalendar);
                    $("body.editable").on(
                        "blur",
                        "div.redactor_editor",
                        null,
                        saveRedactor
                    );
                    $("body.notEditable").on(
                        "keypress",
                        "div.redactor_editor",
                        null,
                        function(event) {
                            $(this).blur();
                            return false;
                        }
                    );
                }),
                (saveExternalTaxnomyInput = function() {
                    $("input#primary-cat").each(saveElementValue);
                }),
                (setupSave = function() {
                    $("input.templatePicker").each(function() {
                        saveElementValue($(this));
                    });
                    $("input.templateCheckbox").each(function() {
                        saveElementValue($(this));
                    });
                    $("input.templateElement.meta").each(function() {
                        saveElementValue($(this));
                    });
                    $(
                        ".redactor, .redactorTitle, .redactorCaption, .redactorText, .redactorSingle"
                    ).each(function() {
                        try {
                            var editor = $(this).redactor("getObject");
                            saveRedactorEditor(editor);
                        } catch (e) {
                            console.log("error in setupSave for redactor", e);
                        }
                    });
                }),
                (setupChosen = function() {
                    try {
                        $(".skywordMultiple").multiSelect({
                            keepOrder: false,
                            afterSelect: doSelect,
                            afterDeselect: doSelect
                        });
                    } catch (e) {}

                    $("select.chzn-skyword-multiple").each(function() {
                        var that = $(this);
                        //console.log(that);
                        that.chosenV2({
                            width: "100%",
                            search_contains: true
                        }).change(saveElementValue);
                    });

                    $("select.chzn-skyword-single").each(function() {
                        var that = $(this);
                        //console.log(that);
                        that.chosenV2({
                            width: "65%",
                            search_contains: true
                        }).change(saveElementValue);
                    });

                    $("select.regularChozen").each(function() {
                        var that = $(this);
                        //console.log(that);
                        if (false === that.hasClass("chznComplete")) {
                            that.chosen();
                        }
                    });
                }),
                (facebookToggle = function() {
                    var that = this,
                        a = $(this),
                        linkContainer = null,
                        attachmentContainer = null,
                        urlAction = "removeAttachmentAjax.action?",
                        contentTemplateElementData = null,
                        contentTemplateElementDataId = null,
                        attachment = null,
                        attachmentId = null,
                        options = null,
                        facebookLink = null;

                    if (a.hasClass("fblink")) {
                        attachmentContainer = a.closest(".fbContainer");
                        linkContainer = attachmentContainer.siblings(
                            ".linkContainer"
                        );
                        attachment = attachmentContainer.siblings(
                            ".attachmentHiddenId"
                        );
                        contentTemplateElementData = attachmentContainer.siblings(
                            ".contentTemplateElementDataId"
                        );
                        contentTemplateElementDataId = parseInt(
                            contentTemplateElementData.val(),
                            10
                        );
                        attachmentId = parseInt(attachment.val(), 10);
                        facebookLink = linkContainer.find(".facebookLink");
                        attachment = attachmentContainer.find("div.attachment");
                        attachmentHidden = attachment;

                        options = {
                            cache: false,
                            type: "POST",
                            dataType: "json",
                            url: urlAction,
                            success: function(data) {
                                removeAttachmentOnPage();
                            },
                            error: function(data, status, err) {
                                console.log([data, status, err]);
                            }
                        };
                        urlAction =
                            urlAction +
                            "returnJson=true&attachmentId=" +
                            attachmentId +
                            "&contentRequestId=" +
                            parseInt($("#contentRequestId").val(), 10) +
                            "&contentTemplateElementDataId=" +
                            contentTemplateElementDataId;
                        facebookLink.val("");
                        attachmentContainer.fadeOut("fast", function() {
                            linkContainer.fadeIn("fast");
                            if (
                                undefined !== attachmentId &&
                                null !== attachmentId &&
                                !isNaN(attachmentId)
                            ) {
                                call.ajax(urlAction, options);
                            }
                        });
                    } else {
                        linkContainer = a.closest(".fbContainer");
                        attachmentContainer = linkContainer.siblings(
                            ".attachmentContainer"
                        );
                        facebookLink = linkContainer.find(".facebookLink");

                        linkContainer.fadeOut("fast", function() {
                            facebookLink.val("");
                            facebookLink.blur();
                            attachmentContainer.fadeIn("fast");
                        });
                    }
                }),
                (toggleCardinalityButtonsNew = function() {
                    var that = this,
                        loop = $(this),
                        cardinalityContainer = loop.siblings(
                            ".cardinalityContainer"
                        ),
                        minRequired = parseInt(
                            cardinalityContainer
                                .find(">input.minrequired")
                                .val(),
                            10
                        ),
                        maxAllowed = parseInt(
                            cardinalityContainer
                                .find(">input.maxallowed")
                                .val(),
                            10
                        );
                    toggleCardinality(loop, minRequired, maxAllowed);
                }),
                (showCardinalityButton = function() {
                    var actual = $(this),
                        cardinalityEditButtons = actual.find(
                            ">div.cardinalityEditButtons"
                        ),
                        removeButton = cardinalityEditButtons.find(
                            ">.removeElement"
                        ),
                        cardinalityControl = cardinalityEditButtons.find(
                            ">.cardinalityControl"
                        );
                    cardinalityCount = cardinalityEditButtons.find(
                        ">.cardinalityCount"
                    );
                    cardinalityEditButtons.show();
                    if (actual.hasClass("isContainer")) {
                        cardinalityControl.show();
                        cardinalityCount.show();
                    }
                    removeButton.show();
                }),
                (hideCardinalityButton = function() {
                    var actual = $(this),
                        cardinalityEditButtons = actual.find(
                            ">div.cardinalityEditButtons"
                        ),
                        removeButton = cardinalityEditButtons.find(
                            ">.removeElement"
                        ),
                        cardinalityControl = cardinalityEditButtons.find(
                            ">.cardinalityControl"
                        );
                    cardinalityCount = cardinalityEditButtons.find(
                        ">.cardinalityCount"
                    );
                    if (actual.hasClass("isContainer")) {
                        cardinalityControl.hide();
                        cardinalityCount.hide();
                    } else {
                        cardinalityEditButtons.hide();
                    }
                    removeButton.hide();
                }),
                (displayCardinalityCount = function(elems) {
                    var actionName = $("#actionNameId").val();
                    let cardinalityCountElem;
                    if (elems.length > 1) {
                        for (var i = 0; i < elems.length; i++) {
                            if (
                                actionName !== "articleReview" &&
                                actionName !== "viewContent" &&
                                actionName !== "externalContentReview"
                            ) {
                                if (
                                    elems[i].querySelector(
                                        ".cardinalityEditButtons.containerControls"
                                    )
                                ) {
                                    cardinalityCountElem = elems[
                                        i
                                    ].querySelector(".cardinalityCount");
                                    cardinalityCountElem.innerText = `(${i +
                                        1} of ${elems.length})`;
                                    cardinalityCountElem.classList.remove(
                                        "hidden"
                                    );
                                    elems[i]
                                        .querySelector(
                                            ".cardinalityEditButtons"
                                        )
                                        .classList.remove("hidden");
                                } else {
                                    cardinalityCountElem = elems[
                                        i
                                    ].querySelector(".cardinalityCount");
                                    if (cardinalityCountElem) {
                                        cardinalityCountElem.innerText = `(${i +
                                            1} of ${elems.length})`;
                                        cardinalityCountElem.classList.remove(
                                            "hidden"
                                        );
                                        cardinalityCountElem.style.top =
                                            "-18px";
                                        if (
                                            elems[i].querySelector(
                                                "#markAsRequired"
                                            ).value === "true"
                                        ) {
                                            cardinalityCountElem.style.right =
                                                "0";
                                            elems[i]
                                                .querySelector(
                                                    ".cardinalityEditButtons"
                                                )
                                                .classList.add("hidden");
                                        } else {
                                            cardinalityCountElem.style.right =
                                                "35px";
                                            elems[i]
                                                .querySelector(
                                                    ".cardinalityEditButtons"
                                                )
                                                .classList.remove("hidden");
                                        }
                                    }
                                }
                            } else {
                                let parent = $(elems[i])
                                    .parent()
                                    .parent();
                                if (
                                    parent[0].classList.contains(
                                        "liContainerCardinality"
                                    )
                                ) {
                                    cardinalityCountElem = elems[
                                        i
                                    ].querySelector(".cardinalityCount");
                                    cardinalityCountElem.innerText = `(${i +
                                        1} of ${elems.length})`;
                                    cardinalityCountElem.classList.remove(
                                        "hidden"
                                    );
                                    cardinalityCountElem.style.top = "25px";
                                    cardinalityCountElem.style.right = "25px";
                                } else {
                                    if (
                                        elems[i].querySelector(".isElement") !=
                                        null
                                    ) {
                                        //searching for redactor_box for aligning cardinality count
                                        let child1 = $(elems[i])
                                            .children(
                                                ".articleElement.isElement"
                                            )
                                            .children("div")
                                            .children(".redactor_box");

                                        cardinalityCountElem = elems[
                                            i
                                        ].querySelector(".cardinalityCount");
                                        cardinalityCountElem.innerText = `(${i +
                                            1} of ${elems.length})`;
                                        cardinalityCountElem.classList.remove(
                                            "hidden"
                                        );
                                        cardinalityCountElem.style.top = "15px";
                                        cardinalityCountElem.style.right =
                                            "10px";
                                        if (child1) {
                                            cardinalityCountElem.style.top =
                                                "-10px";
                                        }
                                    } else {
                                        cardinalityCountElem = elems[
                                            i
                                        ].querySelector(".cardinalityCount");
                                        cardinalityCountElem.innerText = `(${i +
                                            1} of ${elems.length})`;
                                        cardinalityCountElem.classList.remove(
                                            "hidden"
                                        );
                                        cardinalityCountElem.style.top =
                                            "-15px";
                                        cardinalityCountElem.style.right =
                                            "0px";
                                    }
                                }
                                if (
                                    elems[i].querySelector(
                                        ".cardinalityEditButtons"
                                    )
                                )
                                    elems[i]
                                        .querySelector(
                                            ".cardinalityEditButtons"
                                        )
                                        .classList.add("hidden");
                            }
                        }
                    } else {
                        cardinalityCountElem = elems[0].querySelector(
                            ".cardinalityCount"
                        );
                        cardinalityCountElem.innerText = "";
                    }
                }),
                (toggleCardinality = function(loop, minrequired, maxallowed) {
                    var actualElements = loop.find(">.actualElement");
                    // show trashcan
                    if (minrequired < actualElements.length) {
                        actualElements.each(showCardinalityButton);
                    } else {
                        actualElements.each(hideCardinalityButton);
                    }
                    if (maxallowed == actualElements.length) {
                        loop.closest(".elementWrapper")
                            .find(".addButton")
                            .hide();
                    } else {
                        loop.closest(".elementWrapper")
                            .find(".addButton")
                            .show();
                    }
                    //if actualElements.length > 1 show cardinality Count
                    displayCardinalityCount(actualElements);
                }),
                (hideShowCardinalityButtons = function() {
                    $(".cardinalLoop").each(toggleCardinalityButtonsNew);
                    $(".cardinalBox").each(function() {
                        setMoveButtonsElement($(this));
                    });
                }),
                (templateOptions = function(rowStatusId, inputValue, input) {
                    var opts,
                        d = new Date();

                    if (undefined === input) {
                        console.log("This should not be undefined");
                    }

                    opts = {
                        referrerAction: $("#actionNameId").val(),
                        accountId: $("#accountId").val(),
                        programId: $("#program-id").val(),
                        saveInitTime: d.getTime(),
                        contentTemplateId: $("#content-template-id").val(),
                        contentTemplateElementId: input
                            .siblings(".contentTemplateElementId")
                            .val(),
                        rowStatusId: rowStatusId,
                        contentTemplateElementDataId: input
                            .siblings(".contentTemplateElementDataId")
                            .val(),
                        displayOrder: input.siblings(".displayOrder").val(),
                        contentRequestId: $("#contentRequestId").val(),
                        contentId: $("#contentId").val(),
                        required: input.siblings(".elementRequired").val(),
                        navigatorUserAgent: navigator.userAgent.toLowerCase()
                    };
                    if (undefined !== input) {
                        opts.parentContentElementId = input
                            .siblings(".parentContentElementId")
                            .val();
                        opts.parentElementDataId = input
                            .siblings(".parentElementDataId")
                            .val();
                        opts.parentContainerElementDataId = input
                            .siblings(".parentContainerElementDataId")
                            .val();
                    }

                    if (null !== inputValue) {
                        opts.inputValue = inputValue;
                    }
                    return opts;
                }),
                (createOptions = function(
                    rowStatusId,
                    inputValue,
                    container,
                    input
                ) {
                    var retVal,
                        opts,
                        contentTemplateElementData = input.siblings(
                            ".contentTemplateElementDataId"
                        ),
                        containerRowStatusId,
                        containerDataElement = null,
                        parentContainer,
                        parentContainerDataElement,
                        grandContainer,
                        grandContainerDataElement,
                        parentContainerRowStatusId,
                        grandContainerRowStatusId;

                    opts = templateOptions(rowStatusId, inputValue, input);

                    try {
                        if (
                            undefined !== container &&
                            null !== container &&
                            container.hasClass("elementContainer")
                        ) {
                            containerRowStatusId = parseInt(
                                container.attr("id").replace("container-", ""),
                                10
                            );
                            containerDataElement = container.find(
                                "#dataid-" + containerRowStatusId
                            );
                            opts.containerContentTemplateElementDataId = containerDataElement.val();
                            opts.containerContentTemplateElementId = container
                                .find(
                                    "#hidden-element-id-" + containerRowStatusId
                                )
                                .val();
                            opts.containerRowStatusId = containerRowStatusId;
                            // send still just in case.  TODO: Check through containerDataElement
                            if (container.hasClass("elementSubContainer")) {
                                parentContainer = getParentContainer(container);
                                parentContainerRowStatusId = parseInt(
                                    parentContainer
                                        .attr("id")
                                        .replace("container-", ""),
                                    10
                                );
                                parentContainerDataElement = parentContainer.find(
                                    "#dataid-" + parentContainerRowStatusId
                                );
                                //opts.parentContainerElementDataId = parentContainerDataElement.val();
                                //opts.parentContainerElementId = parentContainer.find("#hidden-element-id-" + parentContainerRowStatusId).val();
                                opts.parentContainerRowStatusId = parentContainerRowStatusId;
                                if (
                                    parentContainer.hasClass(
                                        "elementSubContainer"
                                    )
                                ) {
                                    grandContainer = getParentContainer(
                                        parentContainer
                                    );
                                    grandContainerRowStatusId = parseInt(
                                        grandContainer
                                            .attr("id")
                                            .replace("container-", ""),
                                        10
                                    );
                                    grandContainerDataElement = grandContainer.find(
                                        "#dataid-" + grandContainerRowStatusId
                                    );
                                    opts.grandContainerElementDataId = grandContainerDataElement.val();
                                    opts.grandContainerElementId = grandContainer
                                        .find(
                                            "#hidden-element-id-" +
                                                grandContainerRowStatusId
                                        )
                                        .val();
                                    opts.grandContainerRowStatusId = grandContainerRowStatusId;
                                }
                            }
                        }
                    } catch (e) {
                        console.log(e);
                    }

                    retVal = {
                        options: opts,
                        contentTemplateElementData: contentTemplateElementData,
                        containerDataElement: containerDataElement
                    };
                    return retVal;
                }),
                (removeTemplateElement = function() {
                    var card = null,
                        currentCardinality,
                        minRequired,
                        maxAllowed,
                        loopId,
                        elementsLoop,
                        actualElements,
                        currentCardinalityLength;
                    if (null !== div) {
                        elementsLoop = div.parent();
                        loopId = elementsLoop.attr("id");
                        card = elementsLoop.siblings(
                            "div.cardinalityContainer"
                        );
                        maxAllowed = parseInt(
                            card.find(">input.maxallowed").val(),
                            10
                        );
                        minRequired = parseInt(
                            card.find(">input.minrequired").val(),
                            10
                        );

                        div.fadeOut("slow", function() {
                            var uploader = $(this)
                                .find(".uploader")
                                .attr("id");
                            $("#file-" + uploader).uploadify("destroy");
                            $(this).remove();
                            hideShowCardinalityButtons();
                            div = null;
                            elementsLoop = $("#" + loopId);
                            currentCardinality = elementsLoop.find(
                                ">div.actualElement"
                            ).length;
                            if (
                                minRequired === currentCardinality ||
                                1 === currentCardinality
                            ) {
                                elementsLoop
                                    .find(
                                        cardinalityControlButtons +
                                            ">span.removeElement"
                                    )
                                    .fadeOut("fast", function() {
                                        var controls = $(this).parent();
                                        if (
                                            !controls.hasClass(
                                                "containerControls"
                                            )
                                        ) {
                                            controls.hide();
                                            resizeCardinalInputFields();
                                        }
                                    });
                                if (
                                    1 === minRequired ||
                                    minRequired === currentCardinality
                                ) {
                                    elementsLoop
                                        .find(
                                            cardinalityControlButtons +
                                                ">span.cardinalityControl"
                                        )
                                        .fadeOut("fast", function() {
                                            var controls = $(this).parent();
                                            if (
                                                !controls.hasClass(
                                                    "containerControls"
                                                )
                                            ) {
                                                controls.hide();
                                                resizeCardinalInputFields();
                                            }
                                        });
                                }
                            }
                        });
                    }
                }),
                /**
                 * Removes attachment and updates conditional elements (slideshows, etc.)
                 * TODO:  This should be refactored into an AttachmentFactory along with all other attachment related methods in this JS
                 */
                (removeAttachmentOnPage = function() {
                    var f;
                    if (null !== attachment && attachment !== undefined) {
                        attachmentContainer = attachment.closest(
                            ".attachmentContainer"
                        );

                        f = attachmentContainer.find(".uploadModule.kaltura");
                        if (f.length) {
                            // Video uploader
                            f.attr("upload-state", "default");

                            angular.element(f).scope().hasAttachment = false;
                            angular
                                .element(f)
                                .scope()
                                .$apply();
                            var uploader = attachmentContainer
                                .find(".subContainer")
                                .children("div")
                                .first();
                            if (uploader.hasClass("attachment")) {
                                uploader.remove();
                            }
                            //remove attachment container with correct attachmentID
                            attachmentContainer
                                .find(
                                    "#attachment-" +
                                        attachmentContainer.attr(
                                            "data-attachmentid"
                                        )
                                )
                                .remove();
                        } else {
                            // Non-video uploader
                            attachmentContainer = jQuery(
                                "div.attachmentContainer[data-cted='" +
                                    attachmentContentTemplateElementDataId +
                                    "']"
                            );
                            attachmentContainer.find(".attachment").remove();
                            attachment = null;
                            f = attachmentContainer.find(".fileUploadSpan");

                            //show fileUploadSpan and contents
                            f.fadeIn("fast");
                            f.find("div.right.stockPhotoNotDam").show();
                            f.find("a.assetLibraryNotDam").show();
                            attachmentContainer.data("loaded", false);
                        }

                        if (attachmentContainer.hasClass("isParentElement")) {
                            console.log("Attachment container");
                            updateChildrenElements(attachmentContainer, null);
                        } else if (
                            attachmentContainer.hasClass("childElement")
                        ) {
                            checkParentAndSiblings(attachmentContainer);
                        }
                    }

                    attachmentContainer = null;

                    if (
                        null !== attachmentHidden &&
                        undefined !== attachmentHidden
                    ) {
                        attachmentHidden.val("");
                        attachmentHidden = null;
                    }
                }),
                // TODO: de-dupe this function, which one should be used?
                /* jshint ignore:start */
                (checkCardinality = function() {
                    checkCardinalityButtons($(this));
                }),
                /* jshint ignore:end */
                (checkCardinalityButtons = function(card) {
                    if (null === card) {
                        $("div.cardinalityContainer").each(function() {
                            checkCardinalityButtons($(this));
                        });
                    } else if (card instanceof jQuery) {
                        var maxAllowed = parseInt(
                                card.find(">input.maxallowed").val(),
                                10
                            ),
                            elementsLoop = card.siblings("div.loop"),
                            actualElements = elementsLoop.find(
                                ">div.actualElement"
                            ),
                            currentCardinalityLength = actualElements.length;
                        if (maxAllowed <= currentCardinalityLength) {
                            card.hide();

                            // FIXME: why are is this an else if, not an else? what other condition could exist?
                        } else if (maxAllowed > currentCardinalityLength) {
                            card.show();
                        }
                    }
                }),
                // TODO: de-dupe this function, which one should be used?
                (showError = function(el) {
                    var containerLi,
                        element,
                        parentContainer,
                        li,
                        container,
                        elParent;
                    if (el.hasClass("assignmentKeyword")) {
                        elParent = el.parent();
                    }
                    container = getContainer(
                        el.hasClass("assignmentKeyword") ? elParent : el
                    );
                    element = container.parent();
                    parentContainer = null;
                    li = getElementLi(
                        el.hasClass("assignmentKeyword") ? elParent : el
                    );

                    li.addClass("errorField");
                    if (element.hasClass("elementContainer")) {
                        containerLi = getElementLi(
                            li
                                .parent()
                                .parent()
                                .parent()
                        );
                        containerLi.addClass("errorField");
                    }
                }),
                /**
                 * Clears error from element and container.
                 * @param el
                 */
                // TODO: de-dupe this function, which one should be used?
                (removeError = function(el) {
                    var containerLi, element, li, container, elParent;
                    if (el.hasClass("assignmentKeyword")) {
                        elParent = el.parent();
                    }
                    container = getContainer(
                        el.hasClass("assignmentKeyword") ? elParent : el
                    );
                    element = container.parent();
                    li = getElementLi(
                        el.hasClass("assignmentKeyword") ? elParent : el
                    );

                    li.removeClass("errorField");
                    if (
                        element.hasClass("elementContainer") ||
                        element.hasClass("tinyMceContainer") ||
                        element.hasClass("mce-tinymce")
                    ) {
                        containerLi = getElementLi(
                            li
                                .parent()
                                .parent()
                                .parent()
                        );
                        if (
                            1 >
                            containerLi.find(".templateElement.errorField")
                                .length
                        ) {
                            containerLi.removeClass("errorField");
                        }
                    }
                }),
                (recompileInjector = function(container, className) {
                    var wrapper = container.find(className);
                    wrapper.each(function() {
                        var attachmentContainer = $(this);
                        var fileupload = attachmentContainer.find(
                            ".uploadContainer"
                        );
                        var subContainer = attachmentContainer.find(
                            ".subContainer"
                        );
                        if (null != fileupload.injector()) {
                            fileupload.injector().invoke(function() {
                                fileupload.replaceWith(
                                    $compile(fileupload.html())(
                                        fileupload.scope()
                                    )
                                );
                                TemplateFactory.loadAttachmentInfoSingle(
                                    subContainer
                                );
                            });
                        } else {
                            TemplateFactory.loadAttachmentInfoSingle(
                                subContainer
                            );
                        }
                    });
                }),
                /**
                 * Re-initializes tinymce editors.
                 * @param element
                 */
                (reinitNewElement = function(element, containerId) {
                    try {
                        //cleanup element if given
                        if (
                            undefined !== element &&
                            null !== element &&
                            element instanceof jQuery
                        ) {
                            element
                                .find(".hasDatetimepicker")
                                .removeClass("hasDatetimepicker");
                            element
                                .find(".hasDatepicker")
                                .removeClass("hasDatepicker");
                        }
                        if (undefined !== containerId && null !== containerId) {
                            if (
                                "articleReview" ===
                                    document
                                        .getElementById("attrs-id")
                                        .getAttribute("data-actionname") ||
                                "externalContentReview" ===
                                    document
                                        .getElementById("attrs-id")
                                        .getAttribute("data-actionname")
                            ) {
                                redactor.setupRedactorElements(containerId);
                            }

                            var container = $("#" + containerId);
                            var kaltura = container.find(
                                ".kalturaUploadContainer"
                            );
                            kaltura.each(function() {
                                var elem = $(this);
                                if (elem.injector() != null) {
                                    elem.injector().invoke(function() {
                                        elem.replaceWith(
                                            $compile(elem.html())(elem.scope())
                                        );
                                    });
                                }
                            });

                            recompileInjector(container, ".colorboxAttachment");
                            recompileInjector(container, ".embeddedUploader");
                        } else {
                            if (
                                "articleReview" ===
                                    document
                                        .getElementById("attrs-id")
                                        .getAttribute("data-actionname") ||
                                "externalContentReview" ===
                                    document
                                        .getElementById("attrs-id")
                                        .getAttribute("data-actionname")
                            ) {
                                redactor.setupRedactorElements();
                            }
                        }

                        setup.templateDatePicker();
                        autoComplete.init();
                    } catch (e) {
                        console.log("error in reinitNewElement " + e.message);
                    }
                }),
                (setMoveButtonsElement = function(curContainer) {
                    var nextRow = curContainer.next(),
                        prevRow = curContainer.prev(),
                        idNext = nextRow.attr("id"),
                        idPrev = prevRow.attr("id"),
                        asc = curContainer.find(".asc.category"),
                        desc = curContainer.find(".desc.category");
                    var icoDn = curContainer.find(".icon-MoveDown");
                    var icoUp = curContainer.find(".icon-MoveUp");
                    if (idPrev !== "start") {
                        asc.html(
                            '<span class="icon-Ascending on left clearNone pointer category"></span>'
                        );
                    } else {
                        asc.html(
                            '<span class="icon-Ascending off left clearNone" style="width:1.25em;"></span>'
                        );
                    }
                    if (idNext !== "end") {
                        desc.html(
                            '<span class="icon-Descending on left clearNone pointer category"></span>'
                        );
                    } else {
                        desc.html(
                            '<span class="icon-Descending off left clearNone" style="width:1.25em;"></span>'
                        );
                    }
                    icoDn.removeClass("off");
                    icoUp.removeClass("off");
                    if (idPrev == undefined) {
                        icoUp.addClass("off");
                    }
                    if (idNext == undefined) {
                        icoDn.addClass("off");
                    }
                }),
                /**
                 * Caller from attachments.js to toggle child elements.
                 * @param attachmentId
                 */
                (attachmentChildElements = function(
                    attachmentId,
                    data,
                    $attachmentContainer
                ) {
                    var attDiv = $("div#attachment-" + attachmentId),
                        attachmentContainer = $attachmentContainer,
                        li = null,
                        contentTemplateElement = null;
                    if (
                        undefined === attachmentContainer ||
                        null === attachmentContainer
                    ) {
                        attachmentContainer = attDiv.closest(
                            ".attachmentContainer"
                        );
                    }
                    li = getElementLi(attachmentContainer);
                    contentTemplateElement = attachmentContainer.siblings(
                        ".contentTemplateElementId"
                    );
                    if (attachmentContainer.hasClass("isParentElement")) {
                        li.siblings(
                            ".childOf_" + contentTemplateElement.val()
                        ).fadeIn("fast");
                        updateChildrenElements(attachmentContainer, data);
                    } else if (attachmentContainer.hasClass("childElement")) {
                        checkParentAndSiblings(attachmentContainer);
                    }
                    hideShowCardinalityButtons();
                    showCardinalityButtons();
                }),
                (setupParentChildElements = function() {
                    function showHideChildrenArticleReview() {
                        function parseBoolean(str) {
                            return /^true$/i.test(str);
                        }

                        var actionName = $("#actionNameId").val(),
                            articleElementList = $(".articleElement");

                        if ("articleReview" !== actionName) {
                            return false;
                        }
                        articleElementList.each(function() {
                            var element = $(this),
                                cted = element.data("cted"),
                                parentElement = parseBoolean(
                                    element.data("isparentelement")
                                ),
                                children = $(".childOfDataId_" + cted);
                            if (parentElement) {
//                                children.show();
                            }
                        });
                    }

                    var showHideChildren = function() {
                        var that = this,
                            cted = $(this),
                            div,
                            attachmentContainer,
                            tempVal = "",
                            redact,
                            hasValue = false,
                            childFields,
                            attachment,
                            attDiv,
                            inputValue = "",
                            childOf,
                            templateElement = cted.siblings(".templateElement");

                        div = cted.closest(".elementWrapper");
                        attachment = cted.siblings(".attachmentId");
                        childOf = ".childOfDataId_" + cted.val();
                        attachmentContainer = cted.siblings(
                            ".attachmentContainer"
                        );
                        attDiv = attachmentContainer.find(
                            ">.subContainer div.attachment"
                        );

                        if (0 === templateElement.length) {
                            templateElement = cted
                                .siblings(".redactor_box")
                                .find("textarea");
                        }
                        if (templateElement.hasClass("templateCheckbox")) {
                            inputValue = templateElement.is(":checked");
                        } else {
                            tempVal =
                                undefined === templateElement.val() ||
                                null === templateElement.val()
                                    ? ""
                                    : templateElement.val();
                            if (
                                templateElement.hasClass("redactor") ||
                                templateElement.hasClass("redactorText") ||
                                templateElement.hasClass("singleLine") ||
                                templateElement.hasClass("redactorCaption")
                            ) {
                                try {
                                    redact = $(
                                        "#" + templateElement.attr("id")
                                    ).redactor("getObject");
                                    if (
                                        undefined === redact ||
                                        null === redact
                                    ) {
                                        if ("" !== tempVal && (typeof tempVal == 'string')) {
                                            inputValue = $.trim(
                                                tempVal.replace(/\s{2,}/g, " ")
                                            );
                                        }
                                    } else {
                                        if ("" !== redact.$editor.text()) {
                                            inputValue = $.trim(
                                                redact.$editor
                                                    .text()
                                                    .replace(/\s{2,}/g, " ")
                                            );
                                        }
                                    }
                                } catch (e) {
                                    console.log(
                                        "Cannot find redactor most likely." +
                                            e.message
                                    );
                                }
                            } else {
                                if (typeof tempVal == 'string') {
                                    inputValue = $.trim(
                                        tempVal.replace(/\s{2,}/g, " ")
                                    );
                                }
                            }
                        }

                        childFields = $(".templateElement" + childOf);

                        childFields.each(function() {
                            var element = $(this),
                                red = null;
                            if (
                                "textarea" === this.tagName.toLowerCase() &&
                                (element.hasClass("redactor") ||
                                    element.hasClass("redactorText") ||
                                    element.hasClass("singleLine") ||
                                    element.hasClass("redactorCaption"))
                            ) {
                                try {
                                    red = $("#" + element.attr("id")).redactor(
                                        "getObject"
                                    );

                                    if (undefined === red || null === red) {
                                        if ("" !== element.val()) {
                                            hasValue = true;
                                        }
                                    } else {
                                        if ("" !== red.$editor.text()) {
                                            hasValue = true;
                                        }
                                    }
                                } catch (e) {
                                    console.log(
                                        "Cannot find redactor most likely." +
                                            e.message
                                    );
                                }
                            }
                        });
                        if (
                            "" === inputValue &&
                            0 === attDiv.length &&
                            false === hasValue
                        ) {
                            div.siblings(childOf).each(function() {
                                var d = $(this),
                                    inputRequired = d.find(
                                        "input.elementRequired"
                                    );
                                inputRequired.val(false);
                                d.hide();
                            });
                        } else {
                            div.siblings(childOf).each(function() {
                                var d = $(this),
                                    inputRequired = d.find(
                                        "input.elementRequired"
                                    );
                                if (inputRequired.hasClass("required")) {
                                    inputRequired.val(true);
                                }
                                d.show();
                            });
                        }
                    };
                    $(
                        "input.contentTemplateElementDataId.isParentElement.liNotRequired"
                    ).each(showHideChildren);
                    $("input.templateEntity").each(updateEntityTable);

                    showHideChildrenArticleReview();
                }),
                /**
                 * Shows child elements on successful update of parent value / validation  INPUT is the attachmentContainer when dealing with attachments.
                 */
                (updateChildrenElements = function(that, data) {
                    var input =
                            undefined !== that.jquery && true === that.jquery
                                ? that
                                : $(that),
                        inputValue = input.val(),
                        li = getElementLi(input),
                        contentTemplateElementId = input
                            .siblings(".contentTemplateElementId")
                            .val(),
                        att = input.find(">.subContainer div.attachment"),
                        children = li.siblings(
                            ".childOf_" + contentTemplateElementId
                        ),
                        hasValue = false,
                        dataValue = [],
                        dataList = [],
                        parentList = [],
                        requiredInputs,
                        subContainer = input.find(">.subContainer"),
                        contentTemplateElementDataId = input.data("cted");

                    if (input.hasClass("attachmentrequired")) {
                        return false;
                    } else if (input.hasClass("required")) {
                        return false;
                    }

                    if (1 > children.length) {
                        children = $(
                            "div.childOfDataId_" + contentTemplateElementDataId
                        );
                    }
                    if ("" !== inputValue || 0 < att.length) {
                        children.each(function() {
                            var div = $(this),
                                inputRequired = div.find(
                                    "input.elementRequired.required"
                                );
                            div.fadeIn("fast");
                            inputRequired.val(true);
                        });
                        setupChosen();
                    } else if ("" === inputValue || 0 === att.length) {
                        hasValue = checkForValue(
                            children,
                            contentTemplateElementDataId
                        );
                        if (false === hasValue && 0 < children.length) {
                            console.log("Hiding");
                            children.hide();
                            requiredInputs = $(
                                "input.elementRequired.required.childOfDataId_" +
                                    contentTemplateElementDataId
                            );
                            //console.log(requiredInputs);
                            requiredInputs.each(function() {
                                $(this).val(false);
                            });
                        }
                    }
                }),
                (checkForValue = function(
                    children,
                    contentTemplateElementDataId
                ) {
                    var hasValue = false;
                    children.each(function() {
                        var div = $(this),
                            element = div.find(
                                ".templateElement.childOfDataId_" +
                                    contentTemplateElementDataId
                            ),
                            red,
                            dataId = element.siblings(
                                ".contentTemplateElementDataId"
                            ),
                            parentData = element.siblings(
                                ".parentElementDataId"
                            ),
                            inputRequired = div.find(
                                "input.elementRequired.required"
                            ),
                            childDataId = element.data("cted"),
                            children = $("div.childOfDataId_" + childDataId);
                        if (0 < element.length && "" !== element.val()) {
                            console.log("value found");
                            console.log(element);
                            console.log(element.val());
                            hasValue = true;
                        }
                        if (
                            false === hasValue &&
                            element.hasClass("isParentElement")
                        ) {
                            hasValue = checkForValue(children, childDataId);
                        }
                    });
                    return hasValue;
                }),
                (checkParentAndSiblings = function(that) {
                    console.log("check parents and siblings");
                    var input =
                            undefined !== that.jquery && true === that.jquery
                                ? that
                                : $(that),
                        li = getElementLi(input),
                        parentContentElementId = input
                            .siblings(".parentContentElementId")
                            .val(),
                        parentLi = li.siblings(
                            ".isParentElement.parent_" + parentContentElementId
                        ),
                        parentInput;
                    console.log(input);
                    if (parentLi.hasClass("AttachmentLi")) {
                        parentInput = parentLi.find(
                            "div.attachmentContainer.parent_" +
                                parentContentElementId
                        );
                    } else {
                        parentInput = parentLi.find(
                            ".templateElement.parent_" + parentContentElementId
                        );
                    }
                    console.log(parentInput);
                    updateChildrenElements(parentInput, null);
                }),
                (updateOrderForCardinalityContainer = function(loop) {
                    var options,
                        currentElementType = null,
                        contentRequestId = $("#contentRequestId").val(),
                        urlAction =
                            "updateDisplayOrderCTEAjax.action?contentRequestId=" +
                            contentRequestId;
                    loop.find(
                        ">.actualElement>.articleElement>.contentTemplateElementDataId"
                    ).each(function() {
                        //for containers, only send the top level id, inner should not be sent
                        var cted = parseInt($(this).val(), 10);
                        console.log("cted " + cted);
                        if (null === currentElementType) {
                            currentElementType = parseInt(
                                $(this)
                                    .siblings(".contentTemplateElementTypeId")
                                    .val(),
                                10
                            );
                        }
                        if (
                            currentElementType ===
                            parseInt(
                                $(this)
                                    .siblings(".contentTemplateElementTypeId")
                                    .val(),
                                10
                            )
                        ) {
                            urlAction +=
                                "&contentTemplateElementDataIds=" + cted;
                        }
                    });
                    //urlAction = urlAction.substring(0, urlAction.length - 1);
                    options = {
                        cache: false,
                        type: "POST",
                        url: urlAction,
                        dataType: "html",
                        success: function(data) {
                            updateCardinalityValues();
                        }
                    };
                    call.ajax(urlAction, options);

                    actualElements = loop.find(">.actualElement");
                }),
                /**
                 * Containers are loaded on the page blank. Due to lack of proper templating engine, nested jsps throw error.
                 * Containers are blank and we load the elements and data into them via ajax.
                 * @param container
                 * @param newElement
                 * @param newContainer
                 */
                (loadContainerElements = function(
                    container,
                    newElement,
                    newContainer
                ) {
                    //console.log("load container elements fired...");
                    var containerId = container.attr("id"),
                        rowStatusId = parseInt(
                            containerId.replace("container-", ""),
                            10
                        ),
                        articleElement = container.find(">div.articleElement"),
                        contentTemplateElementDataId = articleElement
                            .find(">.contentTemplateElementDataId")
                            .val(),
                        contentTemplateElementId = articleElement
                            .find(">.contentTemplateElementId")
                            .val(),
                        contentTemplateElementType = articleElement.find(
                            ">.contentTemplateElementTypeId"
                        ),
                        contentTemplateElementTypeId = parseInt(
                            contentTemplateElementType.val(),
                            10
                        ),
                        contentId = $("#contentId").val(),
                        options,
                        opts,
                        revisionPage,
                        urlAction = "loadContainerElementsAjax.action",
                        preview = "preview.action" === window.location.pathname,
                        showForm = true,
                        deliverPage = false,
                        refPage = window.location.pathname;

                    if (
                        "viewContent.action" === window.location.pathname ||
                        "preview.action" === window.location.pathname ||
                        "articleReview.action" === window.location.pathname ||
                        "viewDeclinedArticle.action" ===
                            window.location.pathname ||
                        "/externalContentReview.action" ===
                            window.location.pathname ||
                        "/contentRequestView.action" ===
                            window.location.pathname
                    ) {
                        showForm = false;
                        deliverPage = false;
                    }
                    if (
                        "viewContent.action" === window.location.pathname ||
                        "preview.action" === window.location.pathname ||
                        "articleReview.action" === window.location.pathname ||
                        "viewDeclinedArticle.action" ===
                            window.location.pathname
                    ) {
                        preview = true;
                    }

                    revisionPage =
                        0 < window.location.pathname.indexOf("Revisions");

                    if (0 < $("#preview-article-container").length) {
                        showForm = false;
                        preview = true;
                    }
                    if ("/deliverArticle.action" === window.location.pathname) {
                        deliverPage = true;
                        showForm = false;
                    }
                    idx = idx + 100;
                    opts = {
                        idx: idx,
                        programId: $("#attrs-id").attr("data-programid"),
                        contentRequestId: $("#contentRequestId").val(),
                        contentTemplateId: $("#content-template-id").val(),
                        parentContainerElementId: contentTemplateElementId,
                        parentContainerElementDataId: contentTemplateElementDataId,
                        contentTemplateElementTypeId: contentTemplateElementTypeId,
                        deliverPage: deliverPage,
                        rowStatusId: rowStatusId,
                        contentId: contentId,
                        newContainer:
                            null === newContainer ? false : newContainer, // ensures that elements are required within a container
                        previewMode: preview,
                        showForm: showForm,
                        referrerPage: refPage,
                        revisionsPage: revisionPage,
                        referrerAction: $("#actionNameId").val()
                    };

                    options = {
                        cache: false,
                        type: "POST",
                        data: opts,
                        dataType: "html",
                        url: urlAction,
                        success: function(html) {
                            //console.log($.trim(html).replace(/\s{2,}/g, " "));
                            var containerLi = $(html),
                                lastIdx,
                                loop,
                                elementList;
                            containerLi.hide();
                            articleElement.append(containerLi);
                            var ctedid = articleElement.data("cted");
                            //if (!containerLi.hasClass("contentTemplateContainer")) {
                            reinitNewElement(containerLi, containerId);
                            //}

                            setupParentChildElements();
                            //console.log("about to taxon``omy init - cted: " + ctedid);
                            taxonomyInit(ctedid);
                            //elementList = containerLi.find(".templateElement");

                            /**
                             * If more containers are found, then recurs through each level.
                             */
                            containerLi
                                .find(
                                    "div.liContainer>div.loop>div.divContainer.isEmpty"
                                )
                                .each(function() {
                                    var subContainer = $(this);
                                    loadContainerElements(
                                        subContainer,
                                        newElement,
                                        newContainer
                                    );
                                });

                            // Flag if loaded.
                            containerLi.delay(100).fadeIn(1000);
                            container
                                .removeClass("isEmpty")
                                .addClass("isNotEmpty");

                            overlay.init();
                        },
                        complete: function() {
                            showCardinalityButtons();
                            checkCardinalityButtons(null);
                            hideShowCardinalityButtons();
                            checkElementAndDataIds();
                            setupChosen();
                            //console.log("setup chosen complete");
                            redactor.nonedit();

                            // Init plain text form elements within a container

                            var plainTextSelector = $(".tinyMceContainer");
                            TinymceService.compile(
                                plainTextSelector,
                                $rootScope
                            );
                            TinymceService.annotateHideAll();
                        }
                    };

                    call.ajax(urlAction, options);
                }),
                (initContainers = function(containerElement) {
                    var actionName = $("#actionNameId").val(),
                        containers =
                            undefined === containerElement ||
                            null === containerElement
                                ? $("div.divContainer.isEmpty")
                                : containerElement;
                    containers.each(function(i, el) {
                        loadContainerElements($(el), null, null);
                    });

                    convertLinks();
                    resizeCardinalInputFields();

                    if (
                        "externalContentReview" === actionName &&
                        $("div.articleEntityModule").length
                    ) {
                        getRecommendations();
                    }
                }),
                (checkElementAndDataIds = function() {
                    var actionName = $("#actionNameId").val(),
                        urlAction = "/elementErrorAjax.action";
                    if (
                        "managerPublish" === actionName ||
                        "publish" === actionName ||
                        "mgrPublish" === actionName ||
                        "editArticle" === actionName
                    ) {
                        $(
                            ".articleElement input.contentTemplateElementDataId"
                        ).each(function() {
                            var opts,
                                el = $(this),
                                cted = parseInt(el.val(), 10),
                                options = {},
                                cte = parseInt(
                                    el
                                        .siblings(".contentTemplateElementId")
                                        .val(),
                                    10
                                ),
                                parentContainerElementData = parseInt(
                                    el
                                        .siblings(
                                            ".parentContainerElementDataId"
                                        )
                                        .val(),
                                    10
                                ),
                                ctet = parseInt(
                                    el
                                        .siblings(
                                            ".contentTemplateElementTypeId"
                                        )
                                        .val(),
                                    10
                                ),
                                displayName = el.siblings(".displayName").val();
                            if (
                                (isNaN(cted) || isNaN(cte)) &&
                                11 !== ctet &&
                                18 !== ctet &&
                                21 !== ctet &&
                                12 !== ctet &&
                                13 !== ctet &&
                                14 !== ctet &&
                                17 !== ctet
                            ) {
                                opts = {
                                    referrerAction: $("#actionNameId").val(),
                                    contentRequestId: $(
                                        "#contentRequestId"
                                    ).val(),
                                    contentId: $("#contentId").val(),
                                    accountId: $("#accountId").val(),
                                    parentContainerElementDataId: isNaN(
                                        parentContainerElementData
                                    )
                                        ? null
                                        : parentContainerElementData,
                                    contentTemplateElementDataId: isNaN(cted)
                                        ? null
                                        : cted,
                                    contentTemplateElementId: isNaN(cte)
                                        ? null
                                        : cte,
                                    contentTemplateElementTypeId: ctet,
                                    displayName: displayName,
                                    navigatorUserAgent: navigator.userAgent.toLowerCase()
                                };
                                options = {
                                    cache: false,
                                    type: "POST",
                                    data: opts,
                                    dataType: "json",
                                    url: urlAction,
                                    success: function(data) {}
                                };
                                call.ajax(urlAction, options);
                            }
                        });
                    }
                }),
                (toggleCardinalityButtons = function(elementsLoopId) {
                    var elementsLoop = $("#" + elementsLoopId);
                    elementsLoop
                        .find(cardinalityControlButtons)
                        .each(function() {
                            $(this).removeClass("hidden");
                            $(this).show();
                        });
                    elementsLoop
                        .find(
                            cardinalityControlButtons +
                                ">span.cardinalityControl"
                        )
                        .each(function() {
                            $(this).removeClass("hidden");
                            $(this).show();
                        });
                    elementsLoop
                        .find(cardinalityControlButtons + ">span.removeElement")
                        .each(function() {
                            $(this).removeClass("hidden");
                            $(this).show();
                        });
                    resizeCardinalInputFields();
                }),
                /**
                 * Dynamically loads an element on to the page.
                 * @param that
                 */
                (loadNewElement = function(that) {
                    var a = $(that),
                        card = a.parent(),
                        contentTemplateElementId = card
                            .find(">input.cte")
                            .val(),
                        elementsLoop = card.siblings("div.loop"),
                        isContainer = card.hasClass("containerHasCardinality"),
                        actualElements = elementsLoop.find(
                            ">div.actualElement"
                        ),
                        currentCardinalityLength = actualElements.length,
                        firstChild = actualElements.eq(0),
                        parentElementData = firstChild.find(
                            "input.parentElementDataId"
                        ),
                        urlAction = "loadElementAjax.action",
                        opts,
                        options,
                        theIdx = parseInt(
                            $(".theIdx")
                                .last()
                                .val(),
                            10
                        ),
                        maxAllowed = parseInt(
                            card.find(">input.maxallowed").val(),
                            10
                        ),
                        containerDataElements,
                        isNewElement = true,
                        isNewContainer = true,
                        hideCard = false,
                        stopCard = false,
                        li = getElementLi(card),
                        parentContainerElementData = li.siblings(
                            ".contentTemplateElementDataId"
                        ),
                        parentContainerElementDataId = parseInt(
                            parentContainerElementData.val(),
                            10
                        );

                    try {
                        if (maxAllowed <= currentCardinalityLength) {
                            throw "Cannot add any more elements.";
                        }

                        idx = idx + 100;
                        opts = {
                            idx: idx,
                            contentTemplateElementId: contentTemplateElementId,
                            showForm: true,
                            newContainer: isContainer,
                            contentId: $("#contentId").val(),
                            contentRequestId: $("#contentRequestId").val(),
                            contentTemplateId: $("#content-template-id").val(),
                            parentContainerElementDataId: parentContainerElementDataId,
                            parentElementDataId: parentElementData.val(),
                            referrerAction: $("#actionNameId").val()
                        };

                        options = {
                            cache: false,
                            type: "POST",
                            data: opts,
                            dataType: "html",
                            url: urlAction,
                            success: function(html) {
                                var newElement = $(html),
                                    container,
                                    newElementId = newElement.attr("id"),
                                    elementsLoopId = elementsLoop.attr("id");
                                idx = idx + 10;
                                var elem = angular.element(elementsLoop);
                                elem.injector().invoke(function() {
                                    elementsLoop.append(
                                        $compile(newElement)(elem.scope())
                                    );
                                });
                                if (
                                    newElement.has(
                                        "select.chzn-skyword-multiple, select.chzn-skyword-single"
                                    )
                                ) {
                                    newElement
                                        .find("select.chzn-skyword-multiple")
                                        .each(function() {
                                            var that = $(this);
                                            that.chosenV2({
                                                width: "100%",
                                                search_contains: true
                                            }).change(saveElementValue);
                                        });
                                    newElement
                                        .find("select.chzn-skyword-single")
                                        .each(function() {
                                            var that = $(this);
                                            that.chosenV2({
                                                width: "65%",
                                                search_contains: true
                                            }).change(saveElementValue);
                                        });
                                }
                                newElement.show();
                                toggleCardinalityButtons(elementsLoopId);
                                // If container, load its elements
                                if (isContainer) {
                                    container = $("#" + newElementId);
                                    loadContainerElements(
                                        container,
                                        isNewElement,
                                        isNewContainer
                                    );
                                } else {
                                    reinitNewElement(newElement);
                                }
                                overlay.init();
                            },
                            complete: function() {
                                $timeout(function() {
                                    checkCardinalityButtons(card);
                                    hideShowCardinalityButtons();
                                    updateCardinalityValues();
                                    var plainTextSelector = $(
                                        ".tinyMceContainer"
                                    );
                                    TinymceService.compile(
                                        plainTextSelector,
                                        $rootScope
                                    );
                                    TinymceService.annotateHideAll();
                                }, 1000); // timeout needed for FF. Safety net.
                            }
                        };
                        call.ajax(urlAction, options);
                    } catch (e) {
                        console.log(e);
                    }
                    resizeCardinalInputFields();
                }),
                /**
                 * Sets up remove element.
                 * @return {*}
                 */
                (removeElement = function() {
                    var container = $(this)
                            .parent()
                            .parent(),
                        articleElement = container.find(">.articleElement"),
                        contentTemplateElementData = articleElement.find(
                            ">input.contentTemplateElementDataId"
                        ),
                        contentTemplateElementDataId = contentTemplateElementData.val(),
                        required = articleElement
                            .find(">input.elementRequired")
                            .val(),
                        contentRequestId = $("#contentRequestId").val();
                    currIdx = container.attr("id").replace("container-", "");
                    div = container;
                    if ("" === contentTemplateElementDataId) {
                        removeTemplateElement();
                        return false;
                    } else {
                        return call.overlay(
                            "removeElementAjax.action?contentRequestId=" +
                                contentRequestId +
                                "&contentTemplateElementDataId=" +
                                contentTemplateElementDataId,
                            null,
                            500,
                            null,
                            null,
                            null
                        );
                    }
                }),
                /**
                 * Looks for errors in Struts-produced messages.  Applies error class to content that's dynamically loaded.
                 * TODO: Is this a dead method?
                 *
                 */
                (loadErrorArray = function() {
                    var div = $("div#element-errors"),
                        spans = div.children();

                    spans.each(function() {
                        var span = $(this),
                            id = span.attr("id"),
                            idx;
                        if (0 < span.length) {
                            id = span.attr("id");
                            idx = id.replace("err-", "");
                        }
                        errorArray.push(idx);
                    });
                }),
                (showCardinalityButtons = function() {
                    resizeCardinalInputFields();
                }),
                (setupCopyElement = function() {
                    if ("/deliverArticle.action" === window.location.pathname) {
                        $("html").one("ajaxStop", function() {
                            copyElementLinks();
                        });
                    }
                    copyElementLinks();
                }),
                (copyElementLinks = function() {
                    try {
                        var clpd = new Clipboard(".icon-Copy");
                        clpd.on("success", function(e) {
                            makeCopySuccess(e.trigger);
                        });
                        clpd.on("error", function(e) {
                            makeCopyFailure(e.trigger);
                        });
                    } catch (e) {
                        //console.log(e);
                    }
                }),
                (removeCopySuccess = function(trigger) {
                    $(".copy-success").remove();
                    $(".fieldSuccess").removeClass("fieldSuccess");
                }),
                (removeCopyFailure = function(trigger) {
                    $(".copy-failure").remove();
                    $(".fieldFailure").removeClass("fieldFailure");
                }),
                (removeCopyMsgs = function(trigger) {
                    removeCopyFailure(trigger);
                    removeCopySuccess(trigger);
                    $(".copy_success").each(function(idx, el) {
                        el.style.display = "none";
                    });
                    $(".copy_failure").each(function(idx, el) {
                        el.style.display = "none";
                    });
                }),
                (makeCopySuccess = function(trigger) {
                    removeCopyMsgs(trigger);
                    var el = document.createElement("div");
                    el.className += "icon-MessageSuccess copy-success";

                    var txt = document.createElement("span");
                    txt.className = "copy-msg";

                    var successMsg =
                        ""; /*$translate.instant(
                        "angular.copytext.success"
                    );*/
                    txt.appendChild(document.createTextNode(successMsg));

                    el.appendChild(txt);

                    $(trigger)
                        .parent()
                        .parent()
                        .append(el);
                    $(trigger.dataset.clipboardTarget).addClass("fieldSuccess");
                }),
                (makeCopyFailure = function(trigger) {
                    removeCopyMsgs(trigger);

                    var el = document.createElement("div");
                    el.className += "icon-MessageWarning copy-failure";

                    var isMac =
                        navigator.platform.toUpperCase().indexOf("MAC") >= 0;
                    var msg = isMac
                        ? $translate.instant("angular.copytext.cmdc")
                        : $translate.instant("angular.copytext.ctrlc");
                    var txt = document.createElement("span");
                    txt.className = "copy-msg";
                    txt.appendChild(document.createTextNode(msg));

                    el.appendChild(txt);

                    $(trigger)
                        .parent()
                        .parent()
                        .append(el);
                    $(trigger.dataset.clipboardTarget).addClass("fieldFailure");
                }),
                (resizeCardinalInputFields = function() {
                    $(".cardinal .templateElementText").each(function() {
                        if (
                            $(this)
                                .parent()
                                .parent()
                                .find(".cardinalityEditButtons")
                                .children(".icon")
                                .is(":visible")
                        ) {
                            console.log(
                                $(this)
                                    .parent()
                                    .parent()
                                    .find(".cardinalityEditButtons")
                                    .children(".icon, .cardinalityControl")
                                    .length
                            );
                            if (
                                $(this)
                                    .parent()
                                    .parent()
                                    .find(".cardinalityEditButtons")
                                    .children(".icon, .cardinalityControl")
                                    .length > 1
                            ) {
                                $(this).addClass("hasButtons");
                            } else {
                                $(this).addClass("hasButton");
                            }
                        } else {
                            $(this)
                                .removeClass("hasButtons")
                                .removeClass("hasButton");
                        }
                    });
                    $(".cardinalBox").each(function() {
                        setMoveButtonsElement($(this));
                    });
                }),
                (taxonomyInit = function(ctedid) {
                    if (
                        undefined === ctedid ||
                        null === ctedid ||
                        "" === ctedid
                    ) {
                        try {
                            var select = $("select.isChild");
                            select.each(function(k, v) {
                                var child = $(this),
                                    childId = $(this)
                                        .attr("id")
                                        .replace("-parent", ""),
                                    li = getElementLi(child),
                                    required = li.find(
                                        'input[type="hidden"].elementRequired'
                                    );
                                if (1 > child.children().length) {
                                    li.hide();
                                    required.val(false);
                                }
                            });
                        } catch (e) {
                            console.error(
                                "Something bad happened " + e.message
                            );
                        }
                    } else {
                        try {
                            var slct = $(".container" + ctedid).find("select");
                            if (1 > slct.length) {
                                return;
                            }
                            slct.each(function(k, v) {
                                var sel = $(this),
                                    parentTaxonomyId = sel.data(
                                        "parenttaxonomyid"
                                    ),
                                    childTaxonomyIds = sel.data(
                                        "childtaxonomyids"
                                    ),
                                    contentTemplateElementId = sel
                                        .siblings(".contentTemplateElementId")
                                        .val(),
                                    taxValue = sel.val(),
                                    parentContainerDataId = sel.data(
                                        "parentcontainerdataid"
                                    ),
                                    childTaxonomyIds = sel.siblings(
                                        ".taxonomyChildren"
                                    );
                                resetChildTaxonomyInit(sel);
                                if (
                                    taxValue === undefined ||
                                    taxValue === null
                                ) {
                                    taxValue = "";
                                }
                                if ("" !== taxValue) {
                                    getTaxonomyValues(
                                        sel,
                                        contentTemplateElementId,
                                        taxValue,
                                        false,
                                        childTaxonomyIds,
                                        parentContainerDataId
                                    );
                                }
                            });
                        } catch (e) {
                            console.error(
                                "Something bad happened " + e.message
                            );
                        }
                    }
                    $(".styledSelect").selectBoxIt();
                }),
                /**
                 * Ordering of Elements
                 */
                (moveElements = function() {
                    var action = $(this),
                        container = action
                            .parent()
                            .parent()
                            .parent(),
                        otherContainer,
                        loop = container.parent(),
                        curId,
                        mce,
                        direction;
                    try {
                        if (action.hasClass("icon-MoveDown")) {
                            if (
                                container.is(loop.find(">.actualElement:last"))
                            ) {
                                throw "I AM THE LAST ELEMENT!";
                            }
                            otherContainer = container.next();
                            direction = "next";
                        } else {
                            if (
                                container.is(loop.find(">.actualElement:first"))
                            ) {
                                throw "I AM THE FIRST ELEMENT!";
                            }
                            otherContainer = container.prev();
                            direction = "down";
                        }

                        container.fadeOut("fast");
                        if ("next" === direction) {
                            container.next().after(container);
                        } else {
                            container.prev().before(container);
                        }

                        setMoveButtonsElement(container);
                        setMoveButtonsElement(otherContainer);
                        container.fadeIn("slow");
                        updateOrderForCardinalityContainer(loop);

                        // reinit tinymce
                        var thisContainer = $("body").find(".tinyMceContainer");
                        thisContainer.each(function() {
                            var thisTextArea = $(this).find("textarea");
                            if (thisTextArea.length > 0) {
                                var cted = thisTextArea.attr("id");
                                var index = TemplateFactory.elementsLoaded.indexOf(
                                    cted
                                );
                                if (index > -1) {
                                    TemplateFactory.elementsLoaded.splice(
                                        index,
                                        1
                                    );
                                }
                                var thisElem = $("body").find(
                                    ".articleElement"
                                );
                                tinyMCE.get(cted).remove();
                                TinymceService.compile(thisElem, $rootScope);
                            }
                        });
                        setTimeout(function() {
                            $("html, body").animate(
                                {
                                    scrollTop: $(container).offset().top
                                },
                                1000,
                                function() {}
                            );
                        }, 500);
                    } catch (e) {
                        console.log(e);
                    }
                }),
                (getContainer = function(input) {
                    return input.parents().eq(8);
                }),
                /**
                 * returns element: div#template-container-[rowIndex] (starts at child container)
                 * @param container
                 */
                (getParentContainer = function(container) {
                    return container.parents().eq(6);
                }),
                (getElementLi = function(element) {
                    return element.parents().eq(3);
                }),
                (getElementLoop = function(input) {
                    return input.parents().eq(2);
                }),
                (replaceURLWithHTMLLinks = function(text) {
                    var exp = /\s(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])\s/gi;
                    return text.replace(
                        exp,
                        " <a href='$1' target='_new'>$1</a> "
                    );
                }),
                (convertLinks = function() {
                    $(".displayed").each(function(i, el) {
                        var html = $(el).html();
                        $(el).html(replaceURLWithHTMLLinks(html));
                    });
                }),
                (editFileName = function() {
                    var retVal,
                        link = $(this),
                        attachmentId = link.data("attachmentid"),
                        attachmentContainer = link.closest(
                            ".attachmentContainer"
                        ),
                        rowStatusId = attachmentContainer
                            .attr("id")
                            .replace("attachmentcontainer-", ""),
                        urlAction = "editStockPhotoSelectFileNameAjax.action?",
                        opts,
                        container = getContainer(attachmentContainer);
                    attachment = attachmentContainer.find("div.attachment");
                    attachmentContentTemplateElementDataId = parseInt(
                        attachmentContainer.data("cted"),
                        10
                    );
                    attachmentHidden = $(
                        "#attachment-hidden-" +
                            attachmentContentTemplateElementDataId
                    );

                    retVal = createOptions(
                        rowStatusId,
                        null,
                        container,
                        attachmentContainer
                    );
                    opts = retVal.options;
                    opts.contentTemplateElementDataId = link.data("cted");
                    urlAction =
                        urlAction +
                        "attachmentId=" +
                        attachmentId +
                        "&contentRequestId=" +
                        parseInt(opts.contentRequestId, 10) +
                        "&contentTemplateElementDataId=" +
                        parseInt(opts.contentTemplateElementDataId, 10);
                    let options = {
                        cache: false,
                        type: "GET",
                        data: {},
                        success: function(response) {
                            let opt = {
                                html: response,
                                height: "auto",
                                width: 500
                            };
                            $.colorbox(opt);
                            $(".editFileNameTooltip").bt({
                                padding: 8,
                                width: 400,
                                shrinkToFit: true,
                                spikeLength: 5,
                                spikeGirth: 5,
                                cornerRadius: 5,
                                overlap: -1,
                                centerPointY: 1,
                                fill: "rgba(255, 255, 255, 1.0)",
                                strokeWidth: 1,
                                strokeStyle: "#dfdfdf",
                                cssStyles: {
                                    color: "#333333",
                                    fontWeight: "normal"
                                },
                                shadow: true,
                                shadowOffsetX: 2,
                                shadowOffsetY: 2,
                                shadowBlur: 6,
                                shadowColor: "rgba(0,0,0,.6)",
                                shadowOverlap: false,
                                noShadowOpts: {
                                    strokeStyle: "#999",
                                    strokeWidth: 1
                                },
                                positions: ["top"]
                            });
                        }
                    };
                    return call.ajax(urlAction, options);
                });

            function updateAssetValues(cropDTO) {
                var fileSize = jQuery("#asset-file-size"),
                    assetWidth = jQuery("#asset-width"),
                    assetHeight = jQuery("#asset-height"),
                    assetImage = jQuery("#asset-image-" + cropDTO.attachmentId);

                fileSize.html(cropDTO.fileSizeKB + " kB");
                assetWidth.html(cropDTO.imageWidth);
                assetHeight.html(cropDTO.imageHeight);

                assetImage.each(function() {
                    this.style.backgroundImage = null;
                    var d = new Date();
                    this.style.backgroundImage =
                        "url(" + cropDTO.fileUrl + "?" + d.getTime() + ")";
                });

                //assetImage.css("background-image", null);
                //assetImage.css("background-image", "url(" + cropDTO.fileUrl + "?" + d.getTime() + ")");
            }

            function updateCardinalityValues() {
                let cardinalElems = document.querySelectorAll(".cardinalLoop");
                if (cardinalElems.length) {
                    cardinalElems.forEach((elem) => {
                        // child elements we care about
                        let targetChildren = Array.from(elem.childNodes).filter(
                            (elem) => elem.className
                        );
                        try {
                            displayCardinalityCount(targetChildren);
                        } catch (err) {
                            console.log(err);
                        }
                    });
                }
            }

            return {
                saveExternalTaxnomyInput: function() {
                    saveExternalTaxnomyInput();
                },

                openAttachment: function(
                    attachmentId,
                    contentTemplateElementDataId
                ) {
                    openAttachment(attachmentId, contentTemplateElementDataId);
                },

                removeAttachmentOnPage: function() {
                    removeAttachmentOnPage();
                },
                setupSave: function() {
                    setupSave();
                },
                getRecommendations: function() {
                    getRecommendations();
                },
                hideSubmitButton: function() {
                    hideSubmitButton();
                },
                init: function() {
                    //onReady
                    $(function() {
                        var html = $("html"),
                            body = $("body");
                        TemplateFactory.loadAttachmentInfo();

                        html.on(
                            "click",
                            "#btn-crop-asset-overlay",
                            null,
                            function() {
                                var button = $(this),
                                    cropDTO = {
                                        cropX1: parseInt($("#x1").val(), 10),
                                        cropX2: parseInt($("#x2").val(), 10),
                                        cropY1: parseInt($("#y1").val(), 10),
                                        cropY2: parseInt($("#y2").val(), 10),
                                        cropW: parseInt($("#w").val(), 10),
                                        cropH: parseInt($("#h").val(), 10),
                                        attachmentId: button.data(
                                            "attachmentid"
                                        ),
                                        contentTemplateElementDataId: button.data(
                                            "contenttemplateelementdataid"
                                        ),
                                        transferToS3: true
                                    };

                                if (
                                    isNaN(cropDTO.cropX1) ||
                                    isNaN(cropDTO.cropX2) ||
                                    isNaN(cropDTO.cropY1) ||
                                    isNaN(cropDTO.cropY2)
                                ) {
                                    if (undefined !== skyword.jcropObj) {
                                        skyword.jcropObj.destroy();
                                        setup.crop();
                                        setTimeout(function() {
                                            button.removeAttr("disabled");
                                        }, 15);
                                    }
                                    return false;
                                }

                                var cropPromise = assetCrop.crop(cropDTO);

                                $("#crop-container").hide();
                                button.hide();
                                $("#prepare-container").show();
                                $(this).colorbox.resize();

                                cropPromise
                                    .then(function(asset) {
                                        updateAssetValues(asset.data);
                                        overlay.init();
                                        $(this).colorbox.close();
                                        $(
                                            "#cropping-required-container"
                                        ).show();
                                        setupSave();
                                    })
                                    .catch(function(args) {
                                        console.log(args);
                                    })
                                    .finally(function(args) {
                                        console.log(args);
                                    });

                                return false;
                            }
                        );

                        var recropCounter = 0;
                        html.on(
                            "click",
                            "#btn-crop-template-overlay",
                            null,
                            function() {
                                window.localStorage.removeItem("uploadUsing");
                                window.localStorage.removeItem("uploadUsingId");
                                recropCounter++;
                                var formSubmit = $("#formSubmit"),
                                    urlAction = formSubmit.attr("action"),
                                    getPost = formSubmit.attr("method"),
                                    options,
                                    x1 = $("#x1").val(),
                                    x2 = $("#x2").val(),
                                    y1 = $("#y1").val(),
                                    y2 = $("#y2").val(),
                                    ctedId = $(
                                        "#crop-contentTemplateElementDataId"
                                    ).val();

                                if (
                                    "" === x1 ||
                                    "" === x2 ||
                                    "" === y1 ||
                                    "" === y2
                                ) {
                                    if (undefined !== skyword.jcropObj) {
                                        skyword.jcropObj.destroy();
                                        setup.crop();
                                        setTimeout(function() {
                                            $(
                                                "#btn-crop-template-overlay"
                                            ).removeAttr("disabled");
                                        }, 15);
                                    }
                                    return false;
                                }
                                options = {
                                    cache: false,
                                    type: getPost,
                                    data: formSubmit.serialize(),
                                    url: urlAction,
                                    success: function(response) {
                                        if (!validation.parse(response)) {
                                            var info = $(response);
                                            if (
                                                undefined !==
                                                    info.data("reuseasset") &&
                                                2 ===
                                                    parseInt(
                                                        info.data("reuseasset"),
                                                        10
                                                    )
                                            ) {
                                                var link = $(
                                                    "#a" + info.data("cted")
                                                );
                                                var wrapper = link.closest(
                                                    ".attachmentContainer"
                                                );
                                                wrapper
                                                    .find("div.fileUploadSpan")
                                                    .hide();
                                                wrapper
                                                    .find(">div.subContainer")
                                                    .prepend(info);
                                            } else {
                                                var attachmentId = info
                                                        .find(".attachmentId")
                                                        .val(),
                                                    contentTemplateElementId = info
                                                        .find(
                                                            "#content-template-element-id"
                                                        )
                                                        .val(),
                                                    currentAttachment = $(
                                                        "#attachment-" +
                                                            attachmentId
                                                    ),
                                                    attachmentContainer = jQuery(
                                                        "div.attachmentContainer[data-cted='" +
                                                            ctedId +
                                                            "']"
                                                    );
                                                attachmentContainer.data(
                                                    "attachmentid",
                                                    attachmentId
                                                );
                                                var subContainer = attachmentContainer.find(
                                                    "div.subContainer"
                                                );
                                                TemplateFactory.loadAttachmentInfoSingle(
                                                    subContainer
                                                );
                                                // bust image cache on recrop for thumbnail
                                                var findImageSrc = $(
                                                    subContainer
                                                )
                                                    .find(".image-crop")
                                                    .attr("src");
                                                $(subContainer)
                                                    .find(".image-crop")
                                                    .attr(
                                                        "src",
                                                        findImageSrc +
                                                            recropCounter
                                                    );

                                                //SKYW-646
                                                var stockPhoto = info.find(
                                                    ".stockcted" + ctedId
                                                )[0];
                                                if (stockPhoto) {
                                                    let {
                                                        desc,
                                                        descid,
                                                        altid
                                                    } = stockPhoto.dataset;
                                                    if (
                                                        altid !== "" &&
                                                        altid !== null
                                                    ) {
                                                        let altFieldId =
                                                            "cted" + altid;
                                                        let tinymax = "";
                                                        let tinylen;
                                                        let tinyEditor = $(
                                                            "#" + altFieldId
                                                        );
                                                        if (
                                                            tinyEditor[0]
                                                                .attributes
                                                                .maxlength
                                                        ) {
                                                            tinymax =
                                                                tinyEditor[0]
                                                                    .attributes
                                                                    .maxlength
                                                                    .nodeValue;
                                                        }
                                                        if (
                                                            !tinymax ||
                                                            tinymax === ""
                                                        ) {
                                                            tinymax =
                                                                desc.length;
                                                        } else {
                                                            tinymax = parseInt(
                                                                tinymax
                                                            );
                                                        }
                                                        tinymce
                                                            .get(altFieldId)
                                                            .setContent(
                                                                desc.substring(
                                                                    0,
                                                                    Math.min(
                                                                        tinymax,
                                                                        desc.length
                                                                    )
                                                                )
                                                            );
                                                        // Update character count associated with alt text
                                                        let editor = tinymce.get(
                                                            altFieldId
                                                        );
                                                        var plainText = editor.getContent(
                                                            { format: "plain" }
                                                        );
                                                        if (
                                                            1 > plainText.length
                                                        ) {
                                                            editor.setContent(
                                                                "",
                                                                {
                                                                    format:
                                                                        "text"
                                                                }
                                                            );
                                                        }
                                                        var text = editor.getContent(
                                                            { format: "text" }
                                                        );
                                                        text = text
                                                            .trim()
                                                            .replace(
                                                                /(\n)+/g,
                                                                " "
                                                            );
                                                        tinylen = text.length;
                                                        editor.settings.charLimit = tinylen;
                                                        editor.settings.charMax = tinymax;
                                                        editor.settings.charLimitReached =
                                                            tinylen >= tinymax;
                                                        editor.settings.charLimitDiff =
                                                            tinymax - tinylen;

                                                        var charLimitContainer = tinyEditor.siblings(
                                                            ".maxLengthTinymce"
                                                        );
                                                        var postsModuleMaxLength = tinyEditor.siblings(
                                                            ".postsModuleMaxLength"
                                                        );
                                                        if (
                                                            postsModuleMaxLength
                                                        ) {
                                                            postsModuleMaxLength.addClass(
                                                                "char-count-visible"
                                                            );
                                                        }
                                                        if (
                                                            charLimitContainer
                                                        ) {
                                                            charLimitContainer.addClass(
                                                                "char-count-visible"
                                                            );
                                                            if (
                                                                20 >=
                                                                editor.settings
                                                                    .charLimitDiff
                                                            ) {
                                                                charLimitContainer.addClass(
                                                                    "error-character-limit"
                                                                );
                                                            } else {
                                                                charLimitContainer.removeClass(
                                                                    "error-character-limit"
                                                                );
                                                            }
                                                        }

                                                        tinyEditor.addClass(
                                                            "char-count-enabled"
                                                        );

                                                        var charLimitContainer = jQuery(
                                                            "#" + editor.id
                                                        ).siblings(
                                                            ".maxLengthTinymce"
                                                        );

                                                        var charLimit =
                                                            editor.settings
                                                                .charMax -
                                                            editor.settings
                                                                .charLimit;
                                                        var limit = $translate.instant(
                                                            "angular.instructions.remaining"
                                                        ); // hello workd
                                                        charLimitContainer[0].innerText =
                                                            limit +
                                                            " " +
                                                            charLimit;
                                                    }
                                                    if (
                                                        descid !== "" &&
                                                        descid !== null
                                                    ) {
                                                        let descFieldId =
                                                            "cted" + descid;
                                                        tinymce
                                                            .get(descFieldId)
                                                            .setContent(desc);
                                                    }
                                                }
                                            }
                                            overlay.init();
                                            $(this).colorbox.close();
                                            $(
                                                "#cropping-required-container"
                                            ).show();
                                        } else {
                                            msg +=
                                                json.errorMsg +
                                                "<br/><br/>" +
                                                $(
                                                    "#file-ext-" +
                                                        json.attachmentDefinitionId
                                                )
                                                    .val()
                                                    .replace(",", ", ") +
                                                "<br/><br/>";
                                            $(this)
                                                .find(".uploadifyError")
                                                .html(msg);
                                        }
                                    },
                                    error: function(data, status, err) {
                                        var msg =
                                            "Error getting: " +
                                            urlAction +
                                            "\n\n";
                                        msg += "Status: " + status + "\n\n";
                                        // msg += "Parameters: " + JSON.stringify(parameters) + "\n\n";
                                        console.log(msg);
                                    },
                                    complete: function() {
                                        setupSave();
                                        // observer.disconnect();
                                    }
                                };
                                $("#crop-container").hide();
                                $("#btn-crop-template-overlay").hide();
                                $("#prepare-container").show();
                                $(this).colorbox.resize();
                                call.ajax(urlAction, options);

                                return false;
                            }
                        );

                        //geolocation.init();
                        taxonomyInit();

                        var assetSearch = function() {
                            // console.info("Asset Search Modal...");
                            window.localStorage.setItem("uploadUsing", "asset");
                            window.localStorage.setItem(
                                "uploadUsingId",
                                $(this).attr("id")
                            );
                            var a = jQuery(this),
                                attrs = {
                                    colorbox: a.attr("colorboxurl"),
                                    dataid: a.attr("dataid"),
                                    contentrequestid: a.attr(
                                        "contentrequestid"
                                    ),
                                    redactorid: a.attr("redactorid"),
                                    containerid: a.attr("containerid"),
                                    modalheight: a.attr("modalheight"),
                                    id: a.attr("id")
                                };
                            ColorboxFactory.loadColorbox(attrs);
                        };

                        body.on("click", "a.damSearch", null, assetSearch);
                        html.on(
                            "click",
                            ".removeAttachment",
                            null,
                            removeAttachmentOrg
                        );
                        html.on(
                            "click",
                            ".recropAttachment",
                            null,
                            recropAttachment
                        );
                        redactor.setRedactorSave(redactorSave);
                        annotator.setRedactorSave(redactorSave);
                        annotator.setSaveRedactorEditor(saveRedactorEditor);
                        redactor.setSaveRedactorEditor(saveRedactorEditor);
                        redactor.init();
                        annotator.init();
                        if (
                            "/deliverArticle.action" !==
                            window.location.pathname
                        ) {
                            saveElementSetup();
                        }

                        /**
                         * Initialize entities and stock ticker
                         */
                        $("input.templateEntity").each(updateEntityTable);

                        if (0 < $(".cmgPreSave").length) {
                            $(".cmgPreSave").each(saveElementValue);
                        }

                        if ("compareRevisions" !== currentAction) {
                            setupChosen();
                            setupParentChildElements();
                            initContainers(null);
                            showCardinalityButtons();
                            hideShowCardinalityButtons();
                        }

                        html.on("click", "a.addCardinality", function() {
                            var a = $(this);
                            loadNewElement(this);
                            return false;
                        });

                        html.on("click", ".toggleContainer", function() {
                            var container = $(this)
                                .parent()
                                .next();

                            //The .toggleContainer event used here is too generic, quick fix per SKY-6228
                            if (
                                $(this)
                                    .parent()
                                    .attr("id") === "similar-topics-module" ||
                                $(this)
                                    .parent()
                                    .attr("id") === "plagiarism-module"
                            ) {
                            } else {
                                if (container.is(":visible")) {
                                    $(this)
                                        .addClass("icon-Expand")
                                        .removeClass("icon-Collapse");
                                    container.slideUp();
                                } else {
                                    $(this)
                                        .addClass("icon-Collapse")
                                        .removeClass("icon-Expand");
                                    container.slideDown();
                                }
                            }
                        });
                        html.on("click", ".containerHeaderLabel", function() {
                            $(this)
                                .parent()
                                .find(".toggleContainer")
                                .click();
                        });

                        //patch for SKY-17924
                        $(window).load(function() {
                            setTimeout(function() {
                                var container = $(".subContainer").find(
                                    "fileupload"
                                );
                                if (container.length > 0) {
                                    container.each(function() {
                                        var attachmentContainer = $(
                                            ".colorboxAttachment"
                                        )
                                            .wrap("<div>")
                                            .parent();
                                        if (
                                            attachmentContainer.injector() !=
                                            null
                                        ) {
                                            attachmentContainer
                                                .injector()
                                                .invoke(function() {
                                                    attachmentContainer.replaceWith(
                                                        $compile(
                                                            attachmentContainer.html()
                                                        )(
                                                            attachmentContainer.scope()
                                                        )
                                                    );
                                                });
                                        }
                                    });
                                }
                            }, 2000);
                        });

                        html.on("click", ".removeElement", removeElement);
                        html.on("click", ".cardinalArrow", moveElements);
                        html.on("click", ".facebookToggle", facebookToggle);
                        html.on("click", "#btn-remove-element", function() {
                            var urlAction = $("#formSubmit").attr("action"),
                                options = {
                                    cache: false,
                                    type: "POST",
                                    data: $("#formSubmit").serialize(),
                                    url: urlAction,
                                    success: function(response) {
                                        if (
                                            "removeAttachmentAjax.action" ===
                                            urlAction
                                        ) {
                                            removeAttachmentOnPage();
                                        } else {
                                            removeTemplateElement();
                                        }
                                        $.colorbox.close();
                                    },
                                    error: function(data, status, err) {
                                        var msg =
                                            "Error getting: " +
                                            urlAction +
                                            "\n\n" +
                                            "Status: " +
                                            status +
                                            "\n\n";
                                        console.log(msg);
                                    }
                                };
                            call.ajax(urlAction, options);
                            return false;
                        });
                        //SKYW-647
                        body.on("click", ".editFileName", null, editFileName);
                        html.on(
                            "keyup",
                            "#userEnteredEditFilename",
                            function() {
                                if ($(this).val().length > 0) {
                                    validation.enableButton(
                                        "btn-edit-file-name-element"
                                    );
                                } else {
                                    validation.disableButton(
                                        "btn-edit-file-name-element"
                                    );
                                }
                            }
                        );
                        html.on(
                            "click",
                            "#btn-edit-filename-close",
                            function() {
                                $.colorbox.close();
                            }
                        );
                        html.on(
                            "click",
                            "#btn-edit-file-name-element",
                            function(event) {
                                event.preventDefault();
                                let attachmentId = $("#attachmentId").val();
                                let ctedId = $(
                                    "#contentTemplateElementDataId"
                                ).val();
                                var urlAction = $("#formSubmit").attr("action"),
                                    options = {
                                        cache: false,
                                        type: "POST",
                                        data: $("#formSubmit").serialize(),
                                        url: urlAction,
                                        success: function(response) {
                                            //update filename
                                            if (!validation.parse(response)) {
                                                var info = $(response);
                                                var attachment = info.find(
                                                    ".imageColumn"
                                                )[0];
                                                $(
                                                    "#attachment-" +
                                                        attachmentId +
                                                        " .attachmentElement .imageColumn"
                                                ).each(function(index) {
                                                    $(this).replaceWith(
                                                        attachment
                                                    );
                                                });
                                                $.colorbox.close();
                                            } else {
                                                msg +=
                                                    json.errorMsg +
                                                    "<br/><br/>" +
                                                    $(
                                                        "#file-ext-" +
                                                            json.attachmentDefinitionId
                                                    )
                                                        .val()
                                                        .replace(",", ", ") +
                                                    "<br/><br/>";
                                                $(this)
                                                    .find(".uploadifyError")
                                                    .html(msg);
                                            }
                                        },
                                        error: function(data, status, err) {
                                            var msg =
                                                "Error getting: " +
                                                urlAction +
                                                "\n\n" +
                                                "Status: " +
                                                status +
                                                "\n\n";
                                            console.log(msg);
                                        }
                                    };
                                call.ajax(urlAction, options);
                                return false;
                            }
                        );
                        //End SKYW-647

                        setupCopyElement();

                        $(".linkNewWindow a").each(function() {
                            var a = $(this);
                            a.attr("target", "_new");
                        });

                        $(".cardinalBox").each(function() {
                            setMoveButtonsElement($(this));
                        });
                        var cardinalityInterval = setInterval(
                            updateCardinalityValues,
                            4000
                        );
                        setTimeout(() => {
                            clearInterval(cardinalityInterval);
                        }, 30000);
                    });
                }
            };
        }
    ]);
})();

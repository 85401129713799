app.service("help", [
    "skyword",
    "call",
    "requestActive",
    "$http",
    function(skyword, call, requestActive, $http) {
        return {
            exportToWord: function() {
                return $http.get(
                    "/API/export/program/" +
                        $("#page-home").attr("data-programid") +
                        "/guidelines"
                );
            },
            init: function() {
                app.value("requestActive", false);
                var updateTraining = function() {
                        var url = $("#update-program-account-form").attr(
                                "action"
                            ),
                            options = {
                                data: {
                                    trainingCompletedBool: $(
                                        "#training-completed"
                                    ).attr("checked"),
                                    programId: $("#programId").val()
                                },
                                dataType: "json",
                                type: "post",
                                success: function(json) {
                                    var d = json;
                                    if (undefined !== d.trainingCompleted) {
                                        $("#complete-button").html(
                                            "<strong>Training completed on " +
                                                d.trainingCompleted +
                                                "</strong><br/> <a href='writeArticles.action'>Write an article now.</a>"
                                        );
                                    }
                                }
                            };
                        call.ajax(url, options);
                    },
                    updateArrows = function() {
                        var firstSpan,
                            lastSpan,
                            ul = $("#help-articles"),
                            first = $("ul.helpArticlesList li").first(),
                            last = $("ul.helpArticlesList li").last();
                        firstSpan = first.find("span.tools a.asc span");
                        lastSpan = last.find("span.tools a.desc span");

                        $("ul.helpArticlesList li span.tools a span")
                            .removeClass("off")
                            .addClass("on")
                            .addClass("article");

                        //console.log($("#help-articles li span.tools a span"));
                        //console.log(firstSpan);
                        //console.log(lastSpan);

                        firstSpan
                            .removeClass("article")
                            .removeClass("on")
                            .addClass("off");
                        lastSpan
                            .removeClass("article")
                            .removeClass("on")
                            .addClass("off");
                    },
                    descArticle = function() {
                        var adjRow,
                            curId,
                            adjId,
                            formParams,
                            options,
                            curRow = $(this)
                                .parent()
                                .parent()
                                .parent();
                        if (!requestActive) {
                            app.value("requestActive", true);
                            adjRow = $(this)
                                .parent()
                                .parent()
                                .parent()
                                .next();
                            curId = curRow.find("input#id").val();
                            adjId = adjRow.find("input#id").val();
                            formParams = {
                                articleId: curId,
                                action: "move",
                                adjId: adjId
                            };
                            options = {
                                cache: false,
                                type: "POST",
                                url: "helpArticleEditAjax.action",
                                data: formParams,
                                dataType: "html",
                                success: function(data) {
                                    app.value("requestActive", false);
                                    curRow.fadeOut("fast");
                                    curRow.next().after(curRow);
                                    updateArrows();
                                    //setMoveButtonsArticle(curRow);
                                    //setMoveButtonsArticle(adjRow);
                                    curRow.fadeIn("slow");
                                }
                            };
                            call.ajax("helpArticleEditAjax.action", options);
                        }
                    },
                    ascArticle = function() {
                        var adjRow,
                            curId,
                            adjId,
                            formParams,
                            options,
                            curRow = $(this)
                                .parent()
                                .parent()
                                .parent();
                        if (!requestActive) {
                            app.value("requestActive", true);
                            adjRow = $(this)
                                .parent()
                                .parent()
                                .parent()
                                .prev();
                            curId = curRow.find("input#id").val();
                            adjId = adjRow.find("input#id").val();
                            formParams = {
                                articleId: curId,
                                action: "move",
                                adjId: adjId
                            };
                            options = {
                                cache: false,
                                type: "POST",
                                url: "helpArticleEditAjax.action",
                                data: formParams,
                                dataType: "html",
                                success: function(data) {
                                    app.value("requestActive", false);
                                    curRow.fadeOut("fast");
                                    curRow.prev().before(curRow);
                                    updateArrows();
                                    //setMoveButtonsArticle(curRow);
                                    //setMoveButtonsArticle(adjRow);
                                    curRow.fadeIn("slow");
                                }
                            };
                            call.ajax("helpArticleEditAjax.action", options);
                        }
                    },
                    ascCategory = function() {
                        var curContainer,
                            prevContainer,
                            catId,
                            adjId,
                            formParams,
                            options;
                        if (!requestActive) {
                            app.value("requestActive", true);
                            curContainer = $(this)
                                .parent()
                                .parent()
                                .parent()
                                .parent()
                                .parent();
                            prevContainer = $(this)
                                .parent()
                                .parent()
                                .parent()
                                .parent()
                                .parent()
                                .prev();
                            catId = curContainer
                                .find("input.categoryInput")
                                .attr("id");
                            adjId = prevContainer
                                .find("input.categoryInput")
                                .attr("id");
                            formParams = {
                                id: catId,
                                action: "move",
                                adjId: adjId
                            };
                            options = {
                                cache: false,
                                type: "POST",
                                url: "helpEditCategoryAjax.action",
                                data: formParams,
                                dataType: "html",
                                success: function(data) {
                                    app.value("requestActive", false);
                                    curContainer.fadeOut("fast");
                                    curContainer.prev().before(curContainer);
                                    setMoveButtonsCategory(curContainer);
                                    setMoveButtonsCategory(prevContainer);
                                    curContainer.fadeIn("slow");
                                }
                            };
                            call.ajax("helpEditCategoryAjax.action", options);
                        }
                    },
                    descCategory = function() {
                        var curContainer,
                            nextContainer,
                            catId,
                            adjId,
                            formParams,
                            options;
                        if (!requestActive) {
                            app.value("requestActive", true);
                            curContainer = $(this)
                                .parent()
                                .parent()
                                .parent()
                                .parent()
                                .parent();
                            nextContainer = $(this)
                                .parent()
                                .parent()
                                .parent()
                                .parent()
                                .parent()
                                .next();
                            catId = curContainer
                                .find("input.categoryInput")
                                .attr("id");
                            adjId = nextContainer
                                .find("input.categoryInput")
                                .attr("id");
                            formParams = {
                                id: catId,
                                action: "move",
                                adjId: adjId
                            };
                            options = {
                                cache: false,
                                type: "POST",
                                url: "helpEditCategoryAjax.action",
                                data: formParams,
                                dataType: "html",
                                success: function(data) {
                                    app.value("requestActive", false);
                                    curContainer.fadeOut("fast");
                                    curContainer.next().after(curContainer);
                                    setMoveButtonsCategory(curContainer);
                                    setMoveButtonsCategory(nextContainer);
                                    curContainer.fadeIn("slow");
                                }
                            };
                            call.ajax("helpEditCategoryAjax.action", options);
                        }
                    },
                    setMoveButtonsCategory = function(curContainer) {
                        var prevRow = curContainer.prev(),
                            nextRow = curContainer.next(),
                            idNext = nextRow.attr("id"),
                            idPrev = prevRow.attr("id"),
                            asc = curContainer.find(".asc.category"),
                            desc = curContainer.find(".desc.category");
                        if (idPrev !== "start") {
                            asc.html(
                                '<span class="icon-Ascending left clearNone pointer category"></span>'
                            );
                            curContainer
                                .find(".icon-Ascending.category")
                                .click(ascCategory);
                        } else {
                            asc.html(
                                '<span class="icon-None left clearNone" style="width:1.25em;"></span>'
                            );
                        }
                        if (idNext !== "end") {
                            desc.html(
                                '<span class="icon-Descending left clearNone pointer category"></span>'
                            );
                            curContainer
                                .find(".icon-Descending.category")
                                .click(descCategory);
                        } else {
                            desc.html(
                                '<span class="icon-None left clearNone" style="width:1.25em;"></span>'
                            );
                        }
                    },
                    setMoveButtonsStandardsChecklist = function(curContainer) {
                        var prevRow = curContainer.prev(),
                            nextRow = curContainer.next(),
                            idNext = nextRow.attr("id"),
                            idPrev = prevRow.attr("id"),
                            asc = curContainer.find(".asc.standards"),
                            desc = curContainer.find(".desc.standards");
                        if (idPrev != "start") {
                            asc.html(
                                '<span class="icon-Ascending standards"></span>'
                            );
                            curContainer
                                .find(".icon-Ascending.standards")
                                .click(ascStandards);
                        } else {
                            asc.html(
                                '<span class="icon-Ascending off"></span>'
                            );
                        }
                        if (idNext != "end") {
                            desc.html(
                                '<span class="icon-Descending standards" ></span>'
                            );
                            curContainer
                                .find(".icon-Descending.standards")
                                .click(descStandards);
                        } else {
                            desc.html(
                                '<span class="icon-Descending off"></span>'
                            );
                        }
                    },
                    descStandards = function() {
                        var curRow, adjRow, curId, adjId, formParams, options;
                        if (!requestActive) {
                            app.value("requestActive", true);
                            curRow = $(this)
                                .parent()
                                .parent()
                                .parent();
                            adjRow = $(this)
                                .parent()
                                .parent()
                                .parent()
                                .next();
                            curId = curRow.find("input#id").val();
                            adjId = adjRow.find("input#id").val();
                            formParams = {
                                itemId: curId,
                                adjId: adjId
                            };
                            options = {
                                cache: false,
                                type: "POST",
                                url: "checklistMoveAjax.action",
                                data: formParams,
                                dataType: "html",
                                success: function(data) {
                                    app.value("requestActive", false);
                                    curRow.fadeOut("fast");
                                    curRow.next().after(curRow);
                                    updateArrows();
                                    curRow.fadeIn("slow");
                                }
                            };
                            call.ajax("checklistMoveAjax.action", options);
                        }
                    },
                    ascStandards = function() {
                        var curRow, adjRow, curId, adjId, formParams, options;
                        if (!requestActive) {
                            app.value("requestActive", true);
                            curRow = $(this)
                                .parent()
                                .parent()
                                .parent();
                            adjRow = $(this)
                                .parent()
                                .parent()
                                .parent()
                                .prev();
                            curId = curRow.find("input#id").val();
                            adjId = adjRow.find("input#id").val();
                            formParams = {
                                itemId: curId,
                                adjId: adjId
                            };
                            options = {
                                cache: false,
                                type: "POST",
                                url: "checklistMoveAjax.action",
                                data: formParams,
                                dataType: "html",
                                success: function(data) {
                                    app.value("requestActive", false);
                                    curRow.fadeOut("fast");
                                    curRow.prev().before(curRow);
                                    updateArrows();
                                    curRow.fadeIn("slow");
                                }
                            };
                            call.ajax("checklistMoveAjax.action", options);
                        }
                    };

                //onReady
                $(function() {
                    /*
                     * helpArticles.jsp
                     */

                    $("#complete-button button").click(function() {
                        updateTraining();
                    });
                    $("#button-edit-category").click(function() {
                        var errorCategory,
                            doneText,
                            editText,
                            editing = $(this).attr("editing");
                        editText = $("#category-edit").val();
                        doneText = $("#category-edit-done").val();
                        if (editing == "false") {
                            $(this)
                                .find("a")
                                .html(
                                    "<span class='icon-EditArticle'></span>" +
                                        editText
                                );
                            $(this).attr("editing", "true");
                            errorCategory = $(".errorCategory");
                            errorCategory.slideToggle();
                            errorCategory.remove();
                        } else {
                            $(this)
                                .find("a")
                                .html(
                                    "<span class='icon-Edited'></span>" +
                                        doneText
                                );
                            $(this).attr("editing", "false");
                        }

                        $("h2").each(function() {
                            $(this)
                                .find(".text")
                                .fadeToggle("fast");
                            $(this)
                                .find("input")
                                .fadeToggle("fast");
                        });

                        $("#button-create-category").fadeToggle("fast");
                    });

                    $("body").on(
                        "click",
                        "#help-articles .icon-Ascending.article",
                        null,
                        ascArticle
                    );
                    $("body").on(
                        "click",
                        "#help-articles .icon-Descending.article",
                        null,
                        descArticle
                    );
                    $("body").on(
                        "click",
                        "#checklist-articles .icon-Ascending.article",
                        null,
                        ascStandards
                    );
                    $("body").on(
                        "click",
                        "#checklist-articles .icon-Descending.article",
                        null,
                        descStandards
                    );

                    updateArrows();

                    $("input.categoryInput").blur(function() {
                        var h2,
                            name,
                            input,
                            span,
                            formParams,
                            options,
                            catId = $(this).attr("id");
                        h2 = $(this).parent();
                        name = $(this).val();
                        input = $(this);
                        span = $(this).prev();
                        formParams = {
                            id: catId,
                            name: name,
                            action: "rename"
                        };
                        options = {
                            cache: false,
                            type: "POST",
                            url: "helpEditCategoryAjax.action",
                            data: formParams,
                            dataType: "json",
                            success: function(json) {
                                var j =
                                        json instanceof Object
                                            ? json
                                            : $.parseJSON($.trim(json)),
                                    errorCategory = $(".errorCategory");
                                errorCategory.slideToggle();
                                errorCategory.remove();

                                if (j.error == null) {
                                    span.html(name);
                                } else {
                                    h2.after(
                                        '<div class="errorCategory red hidden">' +
                                            j.error +
                                            "</div>"
                                    );
                                    h2.next().slideToggle();
                                }
                            }
                        };
                        call.ajax("helpEditCategoryAjax.action", options);
                    });

                    $(".icon-Descending.category").each(function() {
                        $(this).click(descCategory);
                    });
                    $(".icon-Ascending.category").each(function() {
                        $(this).click(ascCategory);
                    });

                    $("a.deleteCategory").click(function() {
                        var formParams,
                            options,
                            catId = $(this).attr("id");
                        formParams = {
                            id: catId
                        };
                        options = {
                            cache: false,
                            type: "POST",
                            url: "helpDeleteCategoryAjax.action",
                            data: formParams,
                            dataType: "html",
                            success: function(data) {
                                window.location.reload();
                            }
                        };
                        call.ajax("helpDeleteCategoryAjax.action", options);
                        return false;
                    });
                });
            }
        };
    }
]);

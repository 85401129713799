app.service("dailyVideoSummaryService", [
    "$http",
    function($http) {
        return {
            getListVideoPerformanceForContent: function(contentId, callback) {
                $http({
                    url:
                        "/API/program/dailyVideoSummary/getListVideoPerformanceForContent/" +
                        contentId,
                    method: "GET"
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                        errorCallback(data);
                    });
            }
        };
    }
]);

app.controller("managerDashboard", [
    "$scope",
    function($scope) {
        var body = $("body");
        body.on("click", ".rollover.activeKeywords", function() {
            window.location = "searchKeywordList.action?selectedStatusId=1";
        });

        body.on("click", ".totalBar.link", function() {
            var filter = $(this).attr("data-filter");
            if (filter == "label.draftassignments") {
                filter = "drafts";
            } else if (filter == "label.misseddeadlineassignments") {
                filter = "missedDeadline";
            } else if (filter == "label.notsubmittedassignments") {
                filter = "notSubmitted";
            } else if (filter == "label.inreviewassignments") {
                filter = "inReview";
            } else if (filter == "label.reviewcompletedassignments") {
                filter = "reviewCompleted";
            } else {
                filter = "all";
            }
            window.location = "assignmentList.action?filter=" + filter;
        });

        body.on("click", ".rollover.copyPaste", function() {
            window.location = "deliverArticles.action";
        });

        body.on("click", ".rollover.exportToFile", function() {
            window.location = "assignmentExport.action";
        });

        body.on("click", ".rollover.applicants", function() {
            window.location = "applications.action?statusId=1";
        });

        body.on("click", ".rollover.invitations", function() {
            window.location = "invitations.action";
        });

        body.on("click", ".rollover.publishError", function() {
            window.location =
                "searchArticles.action?selectedContentTypeId=-1&selectedStateId=122";
        });

        body.on("click", "#activity-report-graph", function() {
            if ($(this).hasClass("isManager")) {
                window.location =
                    "managerReportProgramSummary.action?processReport=true#total";
            } else if ($(this).hasClass("isSiteOwner")) {
                window.location =
                    "siteownerReportProgramSummary.action?processReport=true#total";
            } else {
                return false;
            }
        });

        body.on("click", ".countContainer.socialShare", function() {
            var startDate = $(this).data("startdate"),
                endDate = $(this).data("enddate");
            if ($(this).hasClass("isManager")) {
                window.location =
                    "managerReportSocialResults.action?processReport=true&socialSort=" +
                    $(this).data("socialsort") +
                    "&startdate=" +
                    startDate +
                    "&enddate=" +
                    endDate;
            } else if ($(this).hasClass("isSiteOwner")) {
                window.location =
                    "siteownerReportSocialResults.action?processReport=true&socialSort=" +
                    $(this).data("socialsort") +
                    "&startdate=" +
                    startDate +
                    "&enddate=" +
                    endDate;
            } else {
                return false;
            }
        });

        body.on("click", ".countContainer.contentReport", function() {
            var startDate = $(this).data("startdate"),
                endDate = $(this).data("enddate");
            if ($(this).hasClass("isManager")) {
                window.location =
                    "managerReportArticleSummary.action?processReport=true&startdate=" +
                    startDate +
                    "&enddate=" +
                    endDate;
            } else if ($(this).hasClass("isSiteOwner")) {
                window.location =
                    "siteownerReportArticleSummary.action?processReport=true&startdate=" +
                    startDate +
                    "&enddate=" +
                    endDate;
            } else {
                return false;
            }
        });

        body.on("click", ".rollover.contentPublished", function() {
            if ($(this).hasClass("isManager")) {
                window.location =
                    "managerReportProgramSummary.action?processReport=true&chart=published";
            } else if ($(this).hasClass("isSiteOwner")) {
                window.location =
                    "siteownerReportProgramSummary.action?processReport=true&chart=published";
            } else {
                return false;
            }
        });

        body.on("click", ".rollover.contentReport", function() {
            var startDate = $(this).data("startdate"),
                endDate = $(this).data("enddate");
            if ($(this).hasClass("isManager")) {
                window.location =
                    "managerReportArticleSummary.action?processReport=true&startdate=" +
                    startDate +
                    "&enddate=" +
                    endDate;
            } else if ($(this).hasClass("isSiteOwner")) {
                window.location =
                    "siteownerReportArticleSummary.action?processReport=true" +
                    startDate +
                    "&enddate=" +
                    endDate;
            } else {
                return false;
            }
        });
    }
])
    .controller("programViewData", ["$scope", function($scope) {}])
    .controller("searchArticles", [
        "$scope",
        "skyword",
        function($scope, skyword) {
            if (
                $(".searchPanel ul li:first-child span").hasClass("nonEditable")
            ) {
                skyword.populateCategories(
                    "categoryDiv",
                    "selectedCategoryId",
                    "selectedContentTypeId",
                    $("#programId").val(),
                    false,
                    $("#default-dropdown").val(),
                    "selectedCategoryId",
                    false
                );
            } else {
                $("#selectedContentTypeId").on("change", function() {
                    skyword.populateCategories(
                        "categoryDiv",
                        "selectedCategoryId",
                        "selectedContentTypeId",
                        $("#programId").val(),
                        false,
                        $("#default-dropdown").val(),
                        "selectedCategoryId",
                        false
                    );
                });
            }
            $("#byline").autocomplete({
                source:
                    "completeBylineAjax.action?programId=" +
                    $("#programId").val() +
                    "&removed=true"
            });
        }
    ])
    .controller("searchDigitalAssets", [
        "$scope",
        "skyword",
        function($scope, skyword) {
            $("#selectedContentTypeId").on("change", function() {
                skyword.populateCategories(
                    "categoryDiv",
                    "selectedCategoryId",
                    "selectedContentTypeId",
                    $("#programId").val(),
                    false,
                    $("#default-dropdown").val(),
                    "selectedCategoryId",
                    false,
                    true
                );
            });
        }
    ])
    .controller("recentlyPublishedArticles", ["$scope", function($scope) {}])
    .controller("articleHistory", ["$scope", function($scope) {}])
    .controller("articleHistoryComments", ["$scope", function($scope) {}])
    .controller("viewBadges", ["$scope", function($scope) {}])
    .controller("writerSearch", ["$scope", function($scope) {}])
    .controller("writerSearchWriters", ["$scope", function($scope) {}])
    .controller("agencyWriterSearch", ["$scope", function($scope) {}])
    .controller("contributorSearch", [
        "$scope",
        "qualityRating",
        "writers",
        function($scope, qualityRating, writers) {
            qualityRating.init();
            writers.contributorSearch();
        }
    ])
    .controller("contributorSearchWriters", ["$scope", function($scope) {}])
    .controller("agencyContributorSearch", [
        "$scope",
        "qualityRating",
        "writers",
        function($scope, qualityRating, writers) {
            qualityRating.init();
            writers.contributorSearch();
        }
    ])
    .controller("agencyDashboard", [
        "$scope",
        "agency",
        function($scope, agency) {
            agency.init();
        }
    ])
    .controller("noPermissions", ["$scope", function($scope) {}])
    .controller("agencyCreateProgramNotAllowed", [
        "$scope",
        function($scope) {}
    ])
    .controller("reviewPanelAjax", ["$scope", function($scope) {}])
    .controller("assignmentsPanelAjax", ["$scope", function($scope) {}])
    .controller("createPanelAjax", ["$scope", function($scope) {}])
    .controller("keywordsPanelAjax", ["$scope", function($scope) {}])
    .controller("deliveryPanelAjax", ["$scope", function($scope) {}])
    .controller("contributorDoughnutAjax", ["$scope", function($scope) {}])
    .controller("contributorsPanelAjax", ["$scope", function($scope) {}])
    .controller("reportPanelAjax", ["$scope", function($scope) {}])
    .controller("notificationPrefs", [
        "$scope",
        "call",
        function($scope, call) {
            $("#allNotifsEnabled").on("change", function(e) {
                var urlAction = "updateAllNotificationsAjax";
                options = {
                    cache: false,
                    type: "POST",
                    data: {
                        allNotifsEnabled: e.target.checked
                    },
                    url: urlAction,
                    success: function(data) {
                        $("#notifsDisabledLabel").toggleClass("hidden");
                    },
                    error: function(data, status, err) {}
                };
                call.ajax(urlAction, options);
            });
        }
    ])
    .controller("updateAllNotificationsAjax", ["$scope", function($scope) {}])
    .controller("notificationPrefsEdit", [
        "$scope",
        function($scope) {
            $(document).on("ready", function() {
                //review queue state checkboxes
                if ($("#reviewQueueStates").length > 0) {
                    let reviewQueueCheckboxes = $(
                        "#reviewQueueStates .stateCheckbox"
                    );
                    let totalCheckboxes = reviewQueueCheckboxes.length;
                    let totalChecked = 0;
                    for (let i = 0; i < reviewQueueCheckboxes.length; i++) {
                        let id = $(reviewQueueCheckboxes[i]).attr("id");
                        let numId = id.split("-")[1];

                        if ($(reviewQueueCheckboxes[i]).prop("checked")) {
                            showHideAllContentRadioBtns(
                                true,
                                "#notificationReviewQueueContentType-" + numId
                            );
                        }

                        if ($(reviewQueueCheckboxes[i]).prop("checked")) {
                            totalChecked += 1;
                        }
                    }
                    indeterminateChecking(
                        totalCheckboxes,
                        totalChecked,
                        "#reviewQueueSelectAll"
                    );
                }

                if ($("#assignmentStates").length > 0) {
                    let assignmentCheckboxes = $(
                        "#assignmentStates .stateCheckbox"
                    );
                    let totalCheckboxes = assignmentCheckboxes.length;
                    let totalChecked = 0;
                    for (let i = 0; i < assignmentCheckboxes.length; i++) {
                        if ($(assignmentCheckboxes[i]).prop("checked")) {
                            totalChecked += 1;
                        }
                    }
                    indeterminateChecking(
                        totalCheckboxes,
                        totalChecked,
                        "#assignmentSelectAll"
                    );
                }

                if ($("#discussionStates").length > 0) {
                    let discussionCheckboxes = $(
                        "#discussionStates .stateCheckbox"
                    );
                    let totalCheckboxes = discussionCheckboxes.length;
                    let totalChecked = 0;
                    for (let i = 0; i < discussionCheckboxes.length; i++) {
                        if ($(discussionCheckboxes[i]).prop("checked")) {
                            totalChecked += 1;
                        }
                    }
                    indeterminateChecking(
                        totalCheckboxes,
                        totalChecked,
                        "#discussionsSelectAll"
                    );
                }

                if ($("#ideaStates").length > 0) {
                    let ideaCheckboxes = $("#ideaStates .stateCheckbox");
                    let totalCheckboxes = ideaCheckboxes.length;
                    let totalChecked = 0;
                    for (let i = 0; i < ideaCheckboxes.length; i++) {
                        if ($(ideaCheckboxes[i]).prop("checked")) {
                            totalChecked += 1;
                        }
                    }
                    indeterminateChecking(
                        totalCheckboxes,
                        totalChecked,
                        "#ideasSelectAll"
                    );
                }
            });

            //#region Common check - uncheck function

            const showHideAllContentRadioBtns = (isShow, id) => {
                if (isShow) $(id).removeClass("hidden");
                else $(id).addClass("hidden");
            };

            const indeterminateChecking = (
                totalCheckboxes,
                totalChecked,
                id
            ) => {
                if (totalChecked > 0 && totalChecked < totalCheckboxes) {
                    $(id).prop("indeterminate", true);
                } else if (totalChecked === totalCheckboxes) {
                    $(id).prop("indeterminate", false);
                    $(id).prop("checked", true);
                } else {
                    $(id).prop("indeterminate", false);
                    $(id).prop("checked", false);
                }
            };

            //#region Review Queue Checkbox change functions

            $("#reviewQueueSelectAll").on("change", function(e) {
                $("#reviewQueueStates .stateCheckbox").attr(
                    "checked",
                    e.target.checked
                );
                showHideAllContentRadioBtns(
                    e.target.checked,
                    "#reviewQueueStates .notificationReviewQueueContentType"
                );
            });
            $("#reviewQueueStates .stateCheckbox").on("change", function(e) {
                let id = $(this).attr("id");
                let numId = id.split("-")[1];
                showHideAllContentRadioBtns(
                    e.target.checked,
                    "#notificationReviewQueueContentType-" + numId
                );

                let totalCheckboxes = $("#reviewQueueStates .stateCheckbox")
                    .length;
                let totalChecked = 0;
                for (let i = 0; i < totalCheckboxes; i++) {
                    if (
                        $($("#reviewQueueStates .stateCheckbox")[i]).prop(
                            "checked"
                        )
                    ) {
                        totalChecked += 1;
                    }
                }

                indeterminateChecking(
                    totalCheckboxes,
                    totalChecked,
                    "#reviewQueueSelectAll"
                );
            });

            //#endregion

            //#region Assignments Checkbox change functions

            $("#assignmentSelectAll").on("change", function(e) {
                $("#assignmentStates .stateCheckbox").attr(
                    "checked",
                    e.target.checked
                );
            });

            $("#assignmentStates .stateCheckbox").on("change", function() {
                let totalCheckboxes = $("#assignmentStates .stateCheckbox")
                    .length;
                let totalChecked = 0;
                for (let i = 0; i < totalCheckboxes; i++) {
                    if (
                        $($("#assignmentStates .stateCheckbox")[i]).prop(
                            "checked"
                        )
                    ) {
                        totalChecked += 1;
                    }
                }

                indeterminateChecking(
                    totalCheckboxes,
                    totalChecked,
                    "#assignmentSelectAll"
                );
            });

            //#endregion

            //#region Discussions Checkbox change functions

            $("#discussionsSelectAll").on("change", function(e) {
                $("#discussionStates .stateCheckbox").attr(
                    "checked",
                    e.target.checked
                );
            });

            $("#discussionStates .stateCheckbox").on("change", function() {
                let totalCheckboxes = $("#discussionStates .stateCheckbox")
                    .length;
                let totalChecked = 0;
                for (let i = 0; i < totalCheckboxes; i++) {
                    if (
                        $($("#discussionStates .stateCheckbox")[i]).prop(
                            "checked"
                        )
                    ) {
                        totalChecked += 1;
                    }
                }

                indeterminateChecking(
                    totalCheckboxes,
                    totalChecked,
                    "#discussionsSelectAll"
                );
            });

            //#endregion

            //#region Discussions Checkbox change functions

            $("#ideasSelectAll").on("change", function(e) {
                $("#ideaStates .stateCheckbox").attr(
                    "checked",
                    e.target.checked
                );
            });

            $("#ideaStates .stateCheckbox").on("change", function() {
                let totalCheckboxes = $("#ideaStates .stateCheckbox").length;
                let totalChecked = 0;
                for (let i = 0; i < totalCheckboxes; i++) {
                    if ($($("#ideaStates .stateCheckbox")[i]).prop("checked")) {
                        totalChecked += 1;
                    }
                }

                indeterminateChecking(
                    totalCheckboxes,
                    totalChecked,
                    "#ideasSelectAll"
                );
            });

            //#endregion

            //#region On Submit button set all content
            $("#submitNotificationPrefBtn").on("click", function(e) {
                e.preventDefault();
                let formData = $("form").serializeArray();
                let allContent = [];
                for (let i = 0; i < formData.length; i++) {
                    if (formData[i].name.includes("allContent[")) {
                        if (formData[i].value !== "false") {
                            let stateId = formData[i].name
                                .split("allContent[")[1]
                                .split("]")[0];
                            allContent.push(stateId);
                        }
                    }
                }
                $("#allContent").val(allContent);
                $("form").submit();
            });
            //#endregion
        }
    ]);

app.service("amplify", [
    "skyword",
    "setup",
    "call",
    "redactor",
    "TinymceService",
    "$rootScope",
    "$compile",
    function amplifyService(
        skyword,
        setup,
        call,
        redactor,
        TinymceService,
        $rootScope,
        $compile
    ) {
        var destTypeId, destId;
        return {
            oauthEnablePopup: function oauthEnablePopup() {
                var urlAction;

                if (destTypeId === "1") {
                    urlAction = "/initFacebookConnectAjax.action";
                } else if (destTypeId === "2") {
                    urlAction = "/initTwitterConnectAjax.action";
                } else if (destTypeId === "3") {
                    urlAction = "/initLinkedInConnectAjax.action";
                }

                var optData = {
                        enableDestinationId: destId
                    },
                    options = {
                        cache: false,
                        type: "POST",
                        data: optData,
                        url: urlAction,
                        success: function(message) {
                            var obj = jQuery.parseJSON(message);
                            if (obj.socialNetworkId === 1) {
                                // facebook
                                $.ajaxSetup({ cache: true });
                                $.getScript(
                                    "//connect.facebook.net/" +
                                        obj.userLocale +
                                        "/sdk.js",
                                    function() {
                                        FB.init({
                                            appId: obj.fbAppId,
                                            version: "v3.1",
                                            status: true,
                                            cookie: true,
                                            xfbml: true
                                        });
                                    }
                                );
                                $(".oauthConfirm").click(function() {
                                    FB.login(
                                        function(response) {
                                            if (response != undefined) {
                                                if (response.error != null) {
                                                    if (
                                                        response.error ==
                                                        "access_denied"
                                                    )
                                                        console.log(
                                                            "Access Denied - Reason:" +
                                                                response.error_reason
                                                        );
                                                } else {
                                                    window.location =
                                                        obj.fbCallbackURL +
                                                        "&code=" +
                                                        response.code;
                                                }
                                            }
                                        },
                                        {
                                            scope: $(
                                                "#fbOAuthPermissions"
                                            ).val()
                                        }
                                    );
                                });
                            } else if (
                                obj.socialNetworkId === 2 ||
                                obj.socialNetworkId === 3
                            ) {
                                // twitter / linkedin
                                $(".oauthConfirm").click(function() {
                                    $.oauthpopup({
                                        path: obj.oauthUrl,
                                        callback: function() {
                                            if (
                                                obj.oauthUrl.indexOf(
                                                    "https://www.linkedin.com"
                                                ) == 0
                                            ) {
                                                window.location.href =
                                                    "/chooseLinkedInPages.action?enableDestinationId=" +
                                                    obj.destinationId;
                                            } else if (
                                                obj.oauthUrl.indexOf(
                                                    "https://api.twitter.com"
                                                ) == 0
                                            ) {
                                                window.location.href =
                                                    "/editDestinations.action";
                                            }
                                        }
                                    });
                                });
                            }
                        }
                    };

                call.skyjax(urlAction, options);
                return false;
            },
            init: function() {
                function cleanAssignmentTemplate() {
                    var templateElement = $(
                        "[id^='follow-menu-'] .contentTemplate"
                    );

                    $(templateElement).each(function() {
                        var elementLabel = $(this).find(".label");
                        var displayedElementText = $.trim(
                            $(elementLabel)
                                .siblings(".elementContents")
                                .find(".isElement")
                                .text()
                        );
                        var metaElement = $(elementLabel)
                            .siblings(".elementContents")
                            .find(
                                ".isElement input.contentTemplateElementTypeId:hidden"
                            );

                        if (displayedElementText === "") {
                            $(this).hide();
                        }

                        if (
                            metaElement.val() === "24" ||
                            metaElement.val() === "25" ||
                            metaElement.val() === "26"
                        ) {
                            $(this).hide();
                        }
                    });
                }

                //onReady
                $(function amplifyOnReady() {
                    var html = $("html");
                    var body = $("body");
                    html.on("click", "#graph-pointer", function() {
                        var destinationId = $(this)
                                .closest(".cardGraph")
                                .attr("data-destination-id"),
                            date = $(this)
                                .parent()
                                .find("#date-val")
                                .val();
                        window.location.href =
                            "searchPosts.action?status=all&selectedDestinationId=" +
                            destinationId +
                            "&startDateString=" +
                            date +
                            "&endDateString=" +
                            date;
                    });

                    body.on(
                        "change",
                        "#selectedDestinationId",
                        null,
                        function() {
                            var s = $(this),
                                destinationId = s.val();

                            if (
                                undefined === destinationId ||
                                null === destinationId ||
                                "" === destinationId
                            ) {
                                $("#create-post-btn").addClass("disabled");
                            } else {
                                $("#create-post-btn").removeClass("disabled");
                            }
                        }
                    );

                    body.on("click", "#create-post-btn", null, function() {
                        var destinationId = $("#selectedDestinationId").val();

                        if (
                            undefined === destinationId ||
                            null === destinationId ||
                            "" === destinationId
                        ) {
                            return false;
                        }

                        if ($("#callingAction").val() === "amplifyContent") {
                            var options = {
                                contentRequestId: $("#contentRequestId").val(),
                                selectedDestinationId: $(
                                    "#selectedDestinationId"
                                ).val(),
                                callingAction: "postCreateAjax",
                                isOverlay: true
                            };
                            var momentPickerCallback = function momentPickerCallback() {
                                $compile($(".amplifyDateTime"))($rootScope);
                            };
                            return call.overlay(
                                "/postCreateAjax.action",
                                options,
                                700,
                                null,
                                null,
                                momentPickerCallback
                            );
                        } else {
                            var urlAction = "/postCreateAjax.action",
                                optData = {
                                    selectedDestinationId: $(
                                        "#selectedDestinationId"
                                    ).val(),
                                    callingAction: $("#callingAction").val()
                                },
                                ops = {
                                    cache: false,
                                    type: "POST",
                                    data: optData,
                                    url: urlAction,
                                    success: function(data, textStatus, jqXHR) {
                                        var dds = $($.parseHTML(data))
                                            .find(
                                                'input[name="deliveryDateString"]'
                                            )
                                            .attr("id");
                                        $("#posts-container").prepend(data);
                                        setup.dateTimePicker();
                                        setup.dateAndTimePicker(dds);
                                        // redactor.setupRedactorElements();
                                        var tinymceSelector = $(
                                            ".tinyMceContainer"
                                        );
                                        tinymceSelector.each(function() {
                                            TinymceService.compile(
                                                $(this),
                                                $rootScope
                                            );
                                        });
                                    }
                                };
                            call.skyjax(urlAction, ops);
                            return false;
                        }
                    });

                    html.on("click", ".removePostLink", function() {
                        var options = {
                            destinationId: $(this)
                                .closest(".postModule")
                                .find('[name="destinationId"]')
                                .val(),
                            currentAction: $(this)
                                .closest(".postModule")
                                .find('[name="currentAction"]')
                                .val(),
                            postIdx: $(this)
                                .closest(".postModule")
                                .find('[name="postIdx"]')
                                .val()
                        };
                        var result = call.overlay(
                            "/postDeleteAjax.action",
                            options,
                            700,
                            null
                        );
                        //$(this).closest(".postModule").remove();
                        return false;
                    });

                    html.on("click", ".deletePostLink", function() {
                        var options = {
                            postId: $(this)
                                .closest(".postModule")
                                .find('[name="postId"]')
                                .val(),
                            destinationId: $(this)
                                .closest(".postModule")
                                .find('[name="destinationId"]')
                                .val(),
                            currentAction: $(this)
                                .closest(".postModule")
                                .find('[name="currentAction"]')
                                .val(),
                            postIdx: $(this)
                                .closest(".postModule")
                                .find('[name="postIdx"]')
                                .val()
                        };
                        return call.overlay(
                            "/postDeleteAjax.action",
                            options,
                            700,
                            null
                        );
                    });

                    html.on("click", ".editPostLink", function() {
                        var options = {
                            contentRequestId: $("#contentRequestId").val(),
                            selectedDestinationId: $(this)
                                .closest(".postModule")
                                .find('[name="destinationId"]')
                                .val(),
                            callingAction: "postCreateAjax",
                            postId: $(this)
                                .closest(".postModule")
                                .find('[name="postId"]')
                                .val(),
                            isOverlay: true,
                            postIdx: $(this)
                                .closest(".postModule")
                                .find('[name="postIdx"]')
                                .val()
                        };
                        var cb = function() {
                            var momentPickerCallback = function momentPickerCallback() {
                                $compile($(".amplifyDateTime"))($rootScope);
                            };
                        };
                        return call.overlay(
                            "/postCreateAjax.action",
                            options,
                            700,
                            null,
                            "GET",
                            cb
                        );
                    });

                    html.on("click", ".disableDestinationLink", function() {
                        var options = {
                            destinationId: $(this)
                                .closest(".destinationModule")
                                .find('[name="destinationId"]')
                                .val()
                        };
                        return call.overlay(
                            "/disableDestinationAjax.action",
                            options,
                            700,
                            null
                        );
                    });

                    body.on(
                        "click",
                        ".enableDestinationLink",
                        null,
                        function() {
                            var urlAction = "/enableDestinationAjax.action";
                            destTypeId = $(this)
                                .closest(".destinationModule")
                                .find('[name="destinationTypeId"]')
                                .val();
                            destId = $(this)
                                .closest(".destinationModule")
                                .find('[name="destinationId"]')
                                .val();

                            call.overlay(
                                urlAction,
                                null,
                                null,
                                null,
                                null,
                                "amplify.oauthEnablePopup()"
                            );
                            return false;
                        }
                    );

                    // disable/enable start and end date on searchPosts page
                    $("#status")
                        .on("change", function() {
                            if (
                                $(this).val() == "unscheduled" ||
                                $(this).val() == "misseddeliverydate"
                            ) {
                                $("input#startDateString")
                                    .addClass("disabled")
                                    .attr("disabled", "disabled");
                                $("input#endDateString")
                                    .addClass("disabled")
                                    .attr("disabled", "disabled");
                                $("input#startDateString").val("");
                                $("input#endDateString").val("");
                            } else {
                                $("input#startDateString")
                                    .removeClass("disabled")
                                    .removeAttr("disabled");
                                $("input#endDateString")
                                    .removeClass("disabled")
                                    .removeAttr("disabled");
                            }
                            return false;
                        })
                        .change();

                    cleanAssignmentTemplate();
                });
            }
        };
    }
]);

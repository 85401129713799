(function() {
    app.service("setup", [
        "skyword",
        function(skyword) {
            return {
                timezoneDropdown: function(groupId, id, external) {
                    var tzg = $("#timezoneGroup");
                    skyword.timezoneDropdown(groupId, id, external);
                    tzg.on("change", function(e) {
                        skyword.timezoneDropdown(tzg.val(), id, external);
                    });
                },
                roundDates: function() {
                    Date.prototype.addHours = function(h) {
                        var copiedDate = new Date(this.getTime());
                        return copiedDate.setHours(copiedDate.getHours() + h);
                    };
                    Date.prototype.parseDate = function(dateString) {
                        var mon = dateString.substring(0, 2),
                            dt = dateString.substring(3, 5),
                            yr = dateString.substring(6, 10),
                            temp1 = mon + "/" + dt + "/" + yr,
                            cfd = Date.parse(temp1),
                            curDay = new Date(cfd);
                        return curDay.setHours(0);
                    };
                    Date.prototype.toFormatString = function() {
                        var strTime,
                            month,
                            day,
                            hours = this.getHours(),
                            minutes = this.getMinutes(),
                            ampm = hours >= 12 ? "PM" : "AM";
                        minutes = minutes < 10 ? "0" + minutes : minutes;
                        if (minutes < 30 && minutes > 0) {
                            minutes = 30;
                        } else if (minutes > 30) {
                            minutes = "00";
                            hours += 1;
                            if (hours > 24) {
                                hours = 0;
                                this.setDate(this.getDate() + 1);
                            }
                        }
                        ampm = hours >= 12 ? "PM" : "AM";
                        hours = hours % 12;
                        hours = hours ? hours : 12; // the hour '0' should be '12'
                        hours = hours < 10 ? "0" + hours : hours;
                        strTime = hours + ":" + minutes + " " + ampm;
                        month = this.getMonth() + 1;
                        day = this.getDate();
                        if (month < 10) {
                            month = "0" + month;
                        }
                        if (day < 10) {
                            day = "0" + day;
                        }
                        return (
                            month +
                            "-" +
                            day +
                            "-" +
                            this.getFullYear() +
                            " " +
                            strTime
                        );
                    };
                },
                upload: function() {
                    var $this = this;
                    try {
                        var options = {
                            swf: "/images/uploadify/uploadify.swf",
                            uploader: "/uploadEmbededAttachmentAjax.action",
                            cancelImg: "/images/uploadify/cancel.png",
                            fileObjName: "fileData",
                            multi: false,
                            removeCompleted: false,
                            auto: true,
                            width: 61,
                            height: 18,
                            buttonImage: $("#uploadifyURL").html(),
                            formData: {
                                contentRequestId: $("#contentRequestId").val(),
                                userLocale: $("#userLocale").val()
                            },
                            onUploadError: function(file, errorCode, errorMsg) {
                                console.log(
                                    "error -- ",
                                    file,
                                    errorCode,
                                    errorMsg
                                );
                            },
                            onSelect: function(file, errorCode, errorMsg) {
                                $("#upload-file")
                                    .parent()
                                    .parent()
                                    .parent()
                                    .find(".uploadify-error")
                                    .remove();
                            },
                            onUploadSuccess: function(file, data, response) {
                                var json = $.parseJSON(data),
                                    msg = "",
                                    swModal = $(".sw-modal");
                                $(this).colorbox.resize();
                                if (true === json.success) {
                                    $("#embed-url").val(json.attachmentUrl);

                                    $("#cropAttachmentId").val(
                                        json.attachmentId
                                    );
                                    $("#discard-image").attr(
                                        "href",
                                        "discardEmbeddedImageAjax.action?attachmentId=" +
                                            json.attachmentId
                                    );

                                    swModal.find(".uploadContainer").hide();
                                    swModal.find(".fileContainer").show();
                                    swModal
                                        .find(".fileContainer #filename")
                                        .html(
                                            json.attachmentUrl.substring(
                                                json.attachmentUrl.lastIndexOf(
                                                    "/"
                                                ) + 1,
                                                json.attachmentUrl.length
                                            )
                                        );
                                    swModal.find("#upload-file-button").hide();
                                    validateContinue();
                                } else {
                                    // cancel the currentUpload - show error
                                    msg += json.errorMsg;
                                    $(".sw-modal")
                                        .find(".uploadify-queue-item")
                                        .addClass("uploadify-error")
                                        .html(json.errorMessage);
                                }
                            },
                            onUploadComplete: function() {}
                        };
                        $("#upload-file").uploadify(options);
                        $("#imgTitle").keyup(validateContinue);
                        $("#remove-embed").click(function() {
                            var swModal = $(".sw-modal");
                            swModal.find(".fileContainer").hide();
                            swModal.find(".uploadContainer").show();
                            $(".sw-modal .uploadify-queue-item").hide();
                            swModal.find("#upload-file-button").show();
                            return false;
                        });
                        $("#altTitle").keyup(validateContinue);
                    } catch (e) {}

                    function validateContinue() {
                        skyword.validateContinue();
                    }
                },
                bylineAutocomplete: function(source, options) {
                    $("#writerByline")
                        .autocomplete({
                            source: source,
                            search: function(event, ui) { 
                                //if (options.search != null) {
                                    $("#spinner-li").show();
                                    options.search(event, ui);
                                //}
                            },
                            select: function(event, ui) {
                                //if (options.select != null) {
                                    options.select(event, ui);
                                //}
                            },
                            response: function(event, ui) {
                                $("#spinner-li").hide();
                            }
                        })
                        .focus(function(event, ui){
                            options.search(event, ui);
                        })
                        .data("autocomplete")._renderItem = function(ul, item) {
                        $("#spinner-li").hide();
                        return $("<li>")
                            .append(
                                "<a><img width='65px;' src='" +
                                    item.picture +
                                    "'/>" +
                                    "<span><label>" +
                                    item.bylineLabel +
                                    "</label><span class='data'>" +
                                    item.label +
                                    "</span></span>" +
                                    "<span><label>" +
                                    item.fullNameLabel +
                                    "</label><span class='data'>" +
                                    item.firstName +
                                    " " +
                                    item.lastName +
                                    "</span></span>" +
                                    "<span><label>" +
                                    item.upcomingAssignmentLabel +
                                    "</label><span class='data'>" +
                                    item.upcomingAssignment +
                                    "</span></span>" +
                                    "<span><label>" +
                                    item.recentlySubmittedLabel +
                                    "</label><span class='data'>" +
                                    item.recentlySubmitted +
                                    "</span></span>" +
                                    "<span><label>" +
                                    item.unavailableDaysLabel +
                                    "</label><span class='data'>" +
                                    item.unavailableDays +
                                    "</span></span></a>"
                            )
                            .appendTo(ul);
                    };
                },

                datePicker: function() {
                    var els = $(".date-picker:not(.hasDatePicker)");
                    if (els.length)
                        els.datepicker({
                            maxDate: "+0D",
                            onClose: function(dateText, inst) {
                                $(this).focus();
                            }
                        });
                },

                /**
                 * Called in $(document).ready to search for inputs with class name "date-time-picker" to add Calendar
                 */
                dateTimePicker: function dateTimePicker() {
                    var els = $(".date-time-picker:not(.hasDatePicker)");
                    if (els.length)
                        els.datetimepicker({
                            minDate: "0D",
                            fixFocusIE: false,
                            onClose: function dateTimePickerOnClose(
                                dateText,
                                inst
                            ) {
                                this.fixFocusIE = true;
                                this.focus();
                            },
                            beforeShow: function dateTimePickerBeforeShow(
                                input,
                                inst
                            ) {
                                var result = $.browser.msie
                                    ? !this.fixFocusIE
                                    : true;
                                this.fixFocusIE = false;
                                return result;
                            }
                        });
                },
                dateAndTimePicker: function dateAndTimePicker(id) {
                    var $this = this;
                    var omitMinutes = $("#" + id).attr("data-omit-minutes");
                    $("html").on(
                        "change",
                        "#sT_date_" +
                            id +
                            ", #sT_h_" +
                            id +
                            ", #sT_m_" +
                            id +
                            ", #sT_a_" +
                            id,
                        function dateAndTimePickerHtmlOnChange() {
                            var min =
                                omitMinutes == "true"
                                    ? "00"
                                    : $("#sT_m_" + id).val();
                            $("#" + id)
                                .val(
                                    $("#sT_date_" + id).val() +
                                        " " +
                                        $("#sT_h_" + id).val() +
                                        ":" +
                                        min +
                                        " " +
                                        $("#sT_a_" + id).val()
                                )
                                .change();
                        }
                    );

                    if (
                        0 < $("#" + id).length &&
                        undefined !== $("#" + id).val() &&
                        null !== $("#" + id).val()
                    ) {
                        var hid = $("#" + id).val(),
                            dt = hid.split(/[\s:]/);
                        $("#sT_date_" + id).val(dt[0]);
                        $("#sT_h_" + id)
                            .val(dt[1])
                            .selectBoxIt();
                        if (omitMinutes == "true") {
                            $("#sT_m_" + id).val("00");
                            $("#sT_m_" + id).hide();
                        } else {
                            $("#sT_m_" + id)
                                .val(dt[2])
                                .selectBoxIt();
                        }
                        $("#sT_a_" + id)
                            .val(dt[3])
                            .selectBoxIt();
                        $("#" + id).val(
                            dt[0] +
                                " " +
                                dt[1] +
                                ":" +
                                $("#sT_m_" + id).val() +
                                " " +
                                dt[3]
                        );
                    }
                },

                /**
                 * Automatically called in $(document).ready below to search for inputs with class name "date-picker-future" to add Calendar
                 */
                futureDatePicker: function() {
                    var now = new Date(parseInt($("#now-time").val(), 10));
                    var els = $(".date-picker-future:not(.hasDatePicker)");
                    if (els.length)
                        els.datepicker({
                            minDate: now,
                            fixFocusIE: false,
                            onClose: function(dateText, inst) {
                                this.fixFocusIE = true;
                                $(this).triggerHandler("focus");
                            },
                            beforeShow: function(input, inst) {
                                var result = $.browser.msie
                                    ? !this.fixFocusIE
                                    : true;
                                this.fixFocusIE = false;
                                return result;
                            }
                        });
                },

                /**
                 * Automatically called in $(document).ready below to search for inputs with class name "date-picker-future-plus-one" to add Calendar
                 */
                futurePlusOneDatePicker: function() {
                    var now = new Date(parseInt($("#now-time").val(), 10));
                    now.setDate(now.getDate() + 1);
                    var els = $(
                        ".date-picker-future-plus-one:not(.hasDatePicker)"
                    );
                    if (els.length)
                        els.datepicker({
                            minDate: now,
                            fixFocusIE: false,
                            onClose: function(dateText, inst) {
                                this.fixFocusIE = true;
                                this.focus();
                            },
                            beforeShow: function(input, inst) {
                                var result = $.browser.msie
                                    ? !this.fixFocusIE
                                    : true;
                                this.fixFocusIE = false;
                                return result;
                            }
                        });
                },

                /**
                 * Automatically called in $(document).ready below to search for inputs with class name "date-picker-all" to add Calendar
                 */
                datePickerAll: function() {
                    var els = $(".date-picker-all:not(.hasDatePicker)");
                    if (els.length)
                        els.datepicker({
                            fixFocusIE: false,
                            onClose: function(dateText, inst) {
                                this.fixFocusIE = true;
                                $(this).triggerHandler("focus");
                            },
                            beforeShow: function(input, inst) {
                                var result = $.browser.msie
                                    ? !this.fixFocusIE
                                    : true;
                                this.fixFocusIE = false;
                                return result;
                            }
                        });
                },

                /**
                 * Automatically called in $(document).ready below to search for inputs with class name "template-date-picker" to add Calendar
                 * Handles optional allowing of past, future or present day. Just ugly.
                 */
                templateDatePicker: function() {
                    var setOptions = function(elem) {
                        elem = $(elem);
                        var options = { dateFormat: "mm-dd-yy", autoclose: false, onSelect: function() {
                            $(elem).data('datepicker').inline = true;                               
                        },
                        onClose: function() {
                            $(elem).data('datepicker').inline = false;
                            if(elem[0].value)
                                $("input.templatePicker").change();
                        } };
                        if (elem.has("[class^=offset_]")) {
                            var clas = "" + elem.attr("class"),
                                matc = "" + clas.match(/offset_\d+/);
                            if (
                                elem.hasClass("templateDateTime") ||
                                elem.hasClass("templateDate")
                            ) {
                                options.dateFormat = "mm/dd/yy";
                                options.defaultHour = parseInt(
                                    matc.split("_")[1],
                                    10
                                );
                            } else {
                                options.defaultDate = parseInt(
                                    matc.split("_")[1],
                                    10
                                );
                            }
                            //
                            // if(!options.defaultDate) {
                            //     console.log("No default date.");
                            //     options.defaultDate = new Date();
                            //     console.log("Added:", options);
                            //     console.log("FOr Element:", elem);
                            // }
                        }
                        // ONLY PAST
                        if (
                            elem.hasClass("past") &&
                            !elem.hasClass("present") &&
                            !elem.hasClass("future")
                        ) {
                            options.maxDate = -1;
                        }
                        // PAST & PRESENT
                        else if (
                            elem.hasClass("past") &&
                            elem.hasClass("present") &&
                            !elem.hasClass("future")
                        ) {
                            options.maxDate = +0;
                        }
                        // ONLY FUTURE
                        else if (
                            !elem.hasClass("past") &&
                            !elem.hasClass("present") &&
                            elem.hasClass("future")
                        ) {
                            options.minDate = +1;
                        }
                        // PRESENT & FUTURE
                        else if (
                            !elem.hasClass("past") &&
                            elem.hasClass("present") &&
                            elem.hasClass("future")
                        ) {
                            options.minDate = +0;
                        }

                        //options.onClose = function() {
                        // elem.focus();
                        //};
                        return options;
                    };
                    $(".templateDate:not(.hasDatePicker)").each(function(e) {
                        $(this).datepicker(setOptions(this));
                    });
                    $(".templateDateTime:not(.hasDatePicker)").each(function(
                        e
                    ) {
                        $(this).datetimepicker(setOptions(this));
                    });
                },
                displaytagDrawers: function() {
                    $("body").on("click", ".openDrawer", function() {
                        var drawer = $(this)
                            .parent()
                            .parent()
                            .next();
                        var buttonElement = $(this).parent();
                        drawer.fadeToggle("slow");
                        if (buttonElement.hasClass("lightOrange")) {
                            buttonElement.removeClass("lightOrange");
                            $(this).removeClass("darkGreyText");
                        } else {
                            buttonElement.addClass("lightOrange");
                            $(this).addClass("darkGreyText");
                        }
                        return false;
                    });
                },
                displaytagAjax: function() {
                    /* jshint ignore:start */
                    try {
                        $(".container").displayTagAjax();
                    } catch (e) {}
                    /* jshint ignore:end */
                },

                /*
             * Functionality for drawers
             */
                //Set default open/close settings
                drawers: function() {
                    //On Click
                    $(".drawerTrigger").click(function() {
                        var icon = $(this).find(".toggle"),
                            box = $(this).find(".box"),
                            text = $(this).find(".text");

                        if (icon.hasClass("icon-expand")) {
                            icon.removeClass("icon-expand");
                            icon.addClass("icon-collapse");
                            text.html($("#plagiarism-hide").val());
                            box.removeClass("tableBlue");
                            box.addClass("grey");
                        } else {
                            icon.removeClass("icon-collapse");
                            icon.addClass("icon-expand");
                            text.html($("#plagiarism-show").val());
                            box.removeClass("grey");
                            box.addClass("tableBlue");
                        }
                        $(this)
                            .next()
                            .slideToggle("slow");

                        return false; //Prevent the browser jump to the link anchor
                    });
                },

                /*
             * Functionality for accordions
             * used in Article Scorecard
             */
                //Set default open/close settings
                accordion: function() {
                    $(".accContainer").each(function() {
                        if (!$(this).hasClass("openDefault")) {
                            $(this).hide();
                        } else {
                            $(this)
                                .prev()
                                .addClass("active");
                        }
                    }); //Hide/close all containers that are not opened by default

                    //On Click
                    $(".accordion .heading").click(function() {
                        $(this)
                            .toggleClass("active")
                            .next()
                            .slideToggle(); //Add "active" state to clicked trigger and slide down the immediate next container
                        return false; //Prevent the browser jump to the link anchor
                    });
                },
                tabs: function() {
                    var html = $("html"),
                        $this = this;
                    html.on("click", "a.tab", function() {
                        var a = jQuery(this);
                        var href = a.attr("href");
                        a.closest("ul")
                            .find("li")
                            .removeClass("active");
                        a.closest("li").addClass("active");
                        scrollMenu.resetScrollMenuTop();
                        scrollMenu.resizeScrollMenu();
                        skyword.toggleSuggestions();

                        //Temporary solution for SKY-7764
                        setTimeout("scrollMenu.resizeScrollMenu();", 300);
                        return false;
                    });
                    try {
                        scrollMenu.initScrollMenu();
                    } catch (e) {
                        //SUPPRESS ERRORS - even spelling mistakes?
                    }
                    if ($("li.defaultTab").length > 0) {
                        $("li.defaultTab a.tab").click();
                    } else {
                        $('[id^="follow-menu-"] li a.tab')
                            .first()
                            .click();
                    }
                    html.on("click", "#seo-link", function() {
                        $("#article-seo .tab").click();
                    });
                    html.on("click", "#readability-link", function() {
                        $("#article-readability .tab").click();
                    });
                },
                followMenuResize: function() {
                    //quick remapping to avoid rewriting other code
                    //should refactor in future
                    scrollMenu.resizeScrollMenu();
                },
                toggleContainer: function() {
                    var html = $("html");
                    html.on(
                        "click",
                        "#plagiarism-module label,#similar-topics-module label",
                        function() {
                            if (
                                $(this)
                                    .parent()
                                    .hasClass("contentFound") ||
                                $(this)
                                    .parent()
                                    .hasClass("articlesFound")
                            ) {
                                $(this)
                                    .find(".toggle")
                                    .click();
                            }
                        }
                    );
                    html.on("click", ".toggle", function() {
                        var container = $(this)
                            .parent()
                            .parent()
                            .parent()
                            .find(".toggleContainer");
                        if (container.is(":visible")) {
                            $(this)
                                .addClass("icon-Expand")
                                .removeClass("icon-Collapse");
                            $(this)
                                .parent()
                                .parent()
                                .removeClass("open");
                            $("#view-message").show();
                            $("#hide-message").hide();
                        } else {
                            $(this)
                                .addClass("icon-Collapse")
                                .removeClass("icon-Expand");
                            $(this)
                                .parent()
                                .parent()
                                .addClass("open");
                            $("#view-message").hide();
                            $("#hide-message").show();
                        }
                        container.slideToggle();
                        return false;
                    });
                },
                disableButton: function() {
                    $("body").on(
                        "click",
                        "button.lg, button.md, button.sm",
                        function() {
                            var btn = $(this);
                            if (btn.hasClass("nodisable")) {
                                return;
                            }
                            btn.addClass("disabled");
                            if (btn.attr("type") == "button") {
                                var button = $(this);
                                setTimeout(function() {
                                    button.attr("disabled", "disabled");
                                }, 10);
                            }
                        }
                    );
                },

                liveHelp: function() {
                    var liveHelpAvailable = $(
                        "img[src*='reponline.gif']"
                    ).html();
                    if (liveHelpAvailable !== null) {
                        $(".liveHelpButton").hover(
                            function() {
                                var liveHelpInfo = $(".liveHelpInfo");
                                liveHelpInfo.animate({ width: "210px" }, 400);
                            },
                            function() {}
                        );

                        $(".liveHelp").hover(
                            function() {},
                            function() {
                                setTimeout(function() {
                                    $(".liveHelpInfo").animate(
                                        { width: "0px" },
                                        400
                                    );
                                }, 500);
                            }
                        );
                    }
                },
                localeSelector: function() {
                    $("#locale-selector").change(function() {
                        window.location.href = $(this).val();
                    });
                },

                // AGENCY HEADER DROPDOWN
                // TODO: standardize all selectors once the new topnav is merged into the existing UI

                agencyHeaderDropdown: function() {
                    var $this = this;
                    $(
                        ".dropdownSection, #logo a, [topnav] .topNavMenu, [topnav] .agency, footer .menu, [topnav] .dashboardLink"
                    )
                        .click(function(e) {
                            var $that = $(this);
                            if ($that.is("a") || $that.is(".dashboardLink")) {
                                // handle the link, and quit before dropping the menu
                                //e.preventDefault();
                                //window.location = $that.attr("href");
                                return false;
                            }
                            var curDropdownId = $that.attr("id");

                            // hide other menus if they are currently open

                            $(".dropdownMenu").each(function() {
                                if (
                                    $(this)
                                        .closest(".headerTextSection")
                                        .attr("id") != curDropdownId
                                ) {
                                    $(this).hide();
                                }
                            });

                            // TODO: Add an "open / close" class to my toggle button

                            $(".dropdownBtn").each(function() {
                                if (
                                    $(this)
                                        .closest(".headerTextSection")
                                        .attr("id") != curDropdownId
                                ) {
                                    $(this).removeClass("open");
                                }
                            });

                            if ($that.find(".dropdownBtn").hasClass("open")) {
                                $that.find(".dropdownMenu").hide();
                                $that.find(".dropdownBtn").removeClass("open");
                            } else {
                                $that.find(".dropdownBtn").addClass("open");

                                // show the drop down menu here
                                $that.find(".dropdownMenu").show();

                                if (
                                    $that.find(".dropdownMenu ul").height() >
                                    190
                                ) {
                                    $that
                                        .find(".dropdownMenu")
                                        .css("overflow-y", "scroll");
                                    $that
                                        .find(".dropdownMenu")
                                        .css("overflow-x", "hidden");
                                }
                            }
                        })
                        .mouseleave(function() {
                            var $that = $(this);
                            skyword.dropdownInterval = setTimeout(function() {
                                $($that)
                                    .find(".dropdownMenu")
                                    .hide();
                                $($that)
                                    .find(".dropdownBtn")
                                    .removeClass("open");
                            }, 500);
                        });

                    $(".dropdownMenu li").click(function() {
                        if (null != $(this).attr("data-value")) {
                            if (null != $(this).attr("data-value")) {
                                window.location.href = $(this).attr(
                                    "data-value"
                                );
                            }
                        }
                    });
                    $(".dropdownSection").hover(
                        function() {
                            window.clearInterval(skyword.dropdownInterval);
                        },
                        function() {}
                    );
                    $("#account-menu-language select").on("change", function() {
                        window.location.href =
                            this.value +
                            "&origUrl=" +
                            encodeURIComponent(window.location.href);
                    });
                },
                // END AGENCY HEADER DROPDOWN

                styledDropdowns: function() {
                    //There are certain cases now where chz output is expected, so until we have time to update them we're leaving them as chz by using chzn-select.
                    //StyledSelect is the preffered class for future elements
                    $(".styledSelect").selectBoxIt({ viewport: $("#page") });
                    $(".chzn-select").chosen();
                },
                navigation: function() {
                    $(".agencyDashboard .wrap div").on("click", function(e) {
                        var link = $(this)
                            .children("a")
                            .attr("href");
                        if (link) {
                            window.location = link;
                        }
                    });
                },
                platformAnnouncement: function() {
                    $("#show-system-message").click(function() {
                        $("#platform-message").slideDown();
                        return false;
                    });
                    $("#close-system-message").click(function() {
                        $("#platform-message").slideUp();
                        return false;
                    });
                },
                checkboxes: function() {
                    var $this = this;
                    $("input[type='checkbox']").change(
                        skyword.checkboxMultChange
                    );
                    $("button .singular").show();
                    $("button .plural").hide();
                },
                expandableSummary: function() {
                    var expandableExpandText = $("#expandableExpandText").val();
                    var expandableCollapseText = $(
                        "#expandableCollapseText"
                    ).val();

                    $(".expandableSummary").each(function() {
                        var summary = $(this),
                            fullText1 = summary.find(">.expandable-fullText"),
                            fullHtml = $.trim(fullText1.html()),
                            cleanText = fullText1.text();

                        if (150 < fullHtml.length) {
                            var fullText = $(this).children(
                                ".expandable-fullText"
                            )[0].innerHTML;
                            var summaryText =
                                $.trim(cleanText).substring(0, 150) +
                                " | <a class='expandableSummaryControl-expand'>" +
                                expandableExpandText +
                                "</a>";
                            $(this).append(
                                "<span class='expandable-summaryText'>" +
                                    summaryText +
                                    "</span>"
                            );
                            $(this)
                                .children(".expandable-fullText")
                                .html(
                                    fullHtml +
                                        " | <a class='expandableSummaryControl-collapse'>" +
                                        expandableCollapseText +
                                        "</a>"
                                )
                                .hide();
                        }
                    });

                    $(".expandableSummaryControl-expand").click(function() {
                        $(this)
                            .parent()
                            .hide();
                        $(this)
                            .parent()
                            .siblings(".expandable-fullText")
                            .show();
                    });

                    $(".expandableSummaryControl-collapse").click(function() {
                        $(this)
                            .parent()
                            .hide();
                        $(this)
                            .parent()
                            .siblings(".expandable-summaryText")
                            .show();
                    });
                },
                searchPanel: function() {
                    var searchRight = $(".searchPanel-right"),
                        searchLeft = $(".searchPanel-left");
                    if (searchRight.height() < searchLeft.height()) {
                        var panelHeight = searchLeft.height();
                        searchRight.height(panelHeight);
                        if (searchRight.children().length == 1) {
                            $(".searchPanel-right li.centerText").css(
                                "line-height",
                                panelHeight + "px"
                            );
                        }
                    }
                },
                truncateText: function() {
                    $(".truncate").each(function() {
                        var height = 0;
                        $(this)
                            .contents()
                            .filter(function() {
                                return this.nodeType === 1;
                            })
                            .each(function() {
                                height += $(this).height();
                            });
                        if (height < $(this).height()) {
                            $(this)
                                .parent()
                                .find(".truncateControls")
                                .hide();
                            $(this).addClass("expanded");
                        } else {
                            $(this)
                                .parent()
                                .find(".truncateControls .showLess")
                                .hide();
                        }
                    });
                    $(".truncateControls a").on("click", function() {
                        var truncateObj = $(this)
                            .closest(".truncateControls")
                            .siblings(".truncate");
                        if ($(this).hasClass("showMore")) {
                            var height = 20;
                            truncateObj
                                .contents()
                                .filter(function() {
                                    return this.nodeType === 1;
                                })
                                .each(function() {
                                    height += $(this).height();
                                });
                            truncateObj.animate({ height: height }, 1000);
                            $(this)
                                .closest(".truncateControls")
                                .find(".showMore")
                                .hide();
                            $(this)
                                .closest(".truncateControls")
                                .find(".showLess")
                                .show();
                        } else {
                            //TODO The next line should be passed in as some sort of param, quick fix
                            newHeight = truncateObj.hasClass(
                                "contributorTruncate"
                            )
                                ? 70
                                : 165;

                            truncateObj.animate({ height: newHeight }, 1000);
                            $(this)
                                .closest(".truncateControls")
                                .find(".showLess")
                                .hide();
                            $(this)
                                .closest(".truncateControls")
                                .find(".showMore")
                                .show();
                        }
                        return false;
                    });
                },
                joinProgressStatus: function() {
                    if ($("#joinProgressStatus")) {
                        var status = $("#joinProgressStatus").val();
                        $("#join-progress-module ol li").each(function() {
                            var progressVal = $(this).data("progress");
                            if (progressVal == status) {
                                $(this).addClass("current");
                            } else if (progressVal < status) {
                                $(this).addClass("passed");
                            }
                        });
                    }
                },
                inviteProgressStatus: function() {
                    if ($("#inviteProgressStatus")) {
                        var status = $("#inviteProgressStatus").val();
                        $("#join-progress-module ol li").each(function() {
                            var progressVal = $(this).data("progress");
                            if (progressVal == status) {
                                $(this).addClass("current");
                            } else if (progressVal < status) {
                                $(this).addClass("passed");
                            }
                        });
                    }
                },
                chznErrorField: function() {
                    $(".chzn-container").each(function() {
                        var chznID = $(this)
                            .attr("id")
                            .replace("_chzn", "");
                        if ($("#" + chznID).hasClass("errorField")) {
                            $(this).addClass("errorField");
                        }
                    });
                },
                numericInput: function() {
                    $(".numeric").keydown(function(e) {
                        if (
                            $.inArray(e.keyCode, [
                                46,
                                8,
                                9,
                                27,
                                13,
                                110,
                                190
                            ]) !== -1 ||
                            // Allow: Ctrl+A
                            (e.keyCode == 65 && e.ctrlKey === true) ||
                            // Allow: Ctrl+C
                            (e.keyCode == 67 && e.ctrlKey === true) ||
                            // Allow: Ctrl+X
                            (e.keyCode == 88 && e.ctrlKey === true) ||
                            // Allow: home, end, left, right
                            (e.keyCode >= 35 && e.keyCode <= 39)
                        ) {
                            // let it happen, don't do anything
                            return;
                        }
                        // Ensure that it is a number and stop the keypress
                        if (
                            (e.shiftKey ||
                                (e.keyCode < 48 || e.keyCode > 57)) &&
                            (e.keyCode < 96 || e.keyCode > 105)
                        ) {
                            e.preventDefault();
                        }
                    });
                },
                compensationNumbers: function() {
                    $(".compensation .body .orangeText").each(function() {
                        var text = $(this)
                            .html()
                            .trim();
                        var firstNumber = text.match(/\d/);
                        var firstNumberPos = text.indexOf(firstNumber);
                        var currency = text.substring(0, firstNumberPos);
                        var wholeNumber = text.substring(
                            firstNumberPos,
                            text.lastIndexOf(".") + 1
                        );
                        var cents = text.substring(
                            text.lastIndexOf(".") + 1,
                            text.length
                        );
                        $(this).html(
                            "<span class='currency'>" +
                                currency +
                                "</span><span class='whole'>" +
                                wholeNumber +
                                "</span><span class='cents'>" +
                                cents +
                                "</span>"
                        );
                    });
                },
                socialFootprintNumbers: function() {
                    function addCommas(nStr) {
                        var x, x1, x2;
                        nStr += "";
                        x = nStr.split(".");
                        x1 = x[0];
                        x2 = x.length > 1 ? "." + x[1] : "";
                        var rgx = /(\d+)(\d{3})/;
                        while (rgx.test(x1)) {
                            x1 = x1.replace(rgx, "$1" + "," + "$2");
                        }
                        return x1 + x2;
                    }

                    $.each($(".soFp-value, .statbox"), function() {
                        var type = $(this).hasClass("statbox")
                            ? $(this).data("id")
                            : $(this).data("type");
                        if (type != undefined) {
                            var value = $(this).hasClass("statbox")
                                ? $(this)
                                      .children(".score-lg")
                                      .contents()
                                      .text()
                                : $(this).text();

                            //if scientific notation, convert
                            if (value.indexOf("E") > -1) {
                                value =
                                    value.split("E")[0] *
                                    Math.pow(10, value.split("E")[1]);
                            }

                            //round value to 2 decimal places for cleanliness
                            if (value.toString().indexOf(".") > -1) {
                                value = parseFloat(value).toFixed(2);
                            }

                            //Add commas
                            value = addCommas(value);
                            //Add percentage sign
                            if (type.indexOf("percent") > -1) {
                                value += "%";
                            }

                            //In the case of per X results

                            if (type.indexOf("_per_") > -1) {
                                var denominator;
                                if (type.indexOf("_per_hour") > -1) {
                                    denominator = "/hour";
                                } else if (type.indexOf("_per_tweet") > -1) {
                                    denominator = "/tweet";
                                } else if (type.indexOf("_per_follower") > -1) {
                                    denominator = "/follower";
                                } else {
                                    denominator = "";
                                }

                                value +=
                                    "<span class='denominator'>" +
                                    denominator +
                                    "</span>";
                            }

                            $(".score-label").each(function() {
                                // Splits the text in the score label into words
                                var scoreLabelText = $(this)
                                    .text()
                                    .split(" ");
                                // Loops through the score labels words to find the length of the words
                                for (
                                    var i = 0;
                                    i < scoreLabelText.length;
                                    i++
                                ) {
                                    if (scoreLabelText[i].length > 20) {
                                        // Store all words in array except the last one
                                        var scoreLabelWords = scoreLabelText.slice(
                                            0,
                                            -1
                                        );
                                        // Find the last / in words that have over 20 characters
                                        var lastCharacter = scoreLabelText[
                                            i
                                        ].lastIndexOf("/");
                                        // Store everything in that word before the /
                                        var wordBefore = scoreLabelText[
                                            i
                                        ].substr(0, lastCharacter);
                                        // Store everything in that word after the /
                                        var wordAfter = scoreLabelText[
                                            i
                                        ].substr(lastCharacter + 1);
                                        // Add new string to html
                                        $(this).html(
                                            scoreLabelWords +
                                                " " +
                                                wordBefore +
                                                "/<wbr>" +
                                                wordAfter
                                        );
                                    }
                                }
                            });

                            //Ship new value to screen
                            if ($(this).hasClass("statbox")) {
                                $(this)
                                    .children(".score-lg")
                                    .html(value);
                            } else {
                                $(this).html(value);
                            }
                        }
                    });
                },
                qtipTooltips: function() {
                    $(".qtipTooltip").each(function() {
                        var at = $(this).data("at") || "bottom left";
                        var my = $(this).data("my") || "top left";
                        var text = $(this).attr("title");
                        var width = $(this).data("width") || "auto";
                        var height = $(this).data("height") || "auto";
                        var title = $(this).data("titlebar") || null;
                        var classes =
                            $(this).data("classes") ||
                            "qtip-wiki qtip-light qtip-shadow qtip-nolist";

                        $(this).qtip({
                            content: {
                                text: text,
                                title: title
                            },
                            position: {
                                at: at,
                                my: my
                            },
                            show: {
                                event: "mouseenter",
                                solo: true,
                                delay: 300
                            },
                            hide: {
                                event: "mouseleave",
                                delay: 500,
                                fixed: true
                            },
                            style: {
                                width: width,
                                height: height,
                                classes: classes,
                                tip: {
                                    width: 15,
                                    border: 1,
                                    height: 10
                                }
                            }
                        });
                    });
                },
                zentab: function() {
                    $("#zenbox_tab.ZenboxTabRight").css("right", -30);
                    $(window)
                        .resize(function() {
                            if ($(window).width() < 1000) {
                                $("#zenbox_tab.ZenboxTabRight").css(
                                    "right",
                                    $(window).width() - 1010
                                );
                            }
                            if ($(window).width() >= 1000) {
                                $("#zenbox_tab.ZenboxTabRight").css(
                                    "right",
                                    -30
                                );
                            }
                        })
                        .resize();
                },
                printLinks: function() {
                    $("html").on("click", ".sendToPrinter", function(e) {
                        $(this).removeClass("disabled");
                        if (window.print) {
                            window.print();
                        }
                        return false;
                    });
                },
                runReports: function() {
                    $("#run-report").click(function() {
                        $("#processReport").val(true);
                    });
                }
            };
        }
    ]);
})();

app.directive("searchResult", [
    "search",
    function(search) {
        return {
            templateUrl: "/partials/directives/searchResult.html",
            replace: false,
            restrict: "E", // Example: <search-result title="" description="" date=""></search-result>
            scope: {
                title: "=title",
                description: "=description",
                attachmentId: "=attachmentId",
                dateApproved: "=dateApproved",
                dateCreated: "=dateCreated",
                byline: "=byline",
                language: "=language",
                projectTitle: "=projectTitle",
                category: "=category",
                state: "=state",
                thumbnailUrl: "=thumbnailUrl",
                id: "=id",
                eventName: "=eventName",
                type: "=type",
                program: "=program",
                accountId: "=accountId",
                isDigitalAsset: "=isDigitalAsset",
                isCustomState: "=isCustomState",
                searchResultTypeId: "=searchResultTypeId",
                fileType: "=fileType",
                publishedUrl: "=publishedUrl",
                videoDownloadUrls: "=videoDownloadUrls",
                ngModel: "=ngModel",
                agencySearch: "=agencySearch",
                isReviewArticle: "=isReviewArticle",
                contentId: "=contentId",
                visibleInSalesforce: "=visibleInSalesforce",
                exportChannelToSalesforce: "=exportChannelToSalesforce",

                enableSalesforce: "=enableSalesforce",
                contentPackageId: "=contentPackageId",
                contentPackageName: "=contentPackageName"
            },
            link: function(scope, element, attrs, ngModelCtrl) {
                scope.setStandardFormatIcon = function() {
                    var isStandardFormat = [
                        1,
                        2,
                        3,
                        10,
                        11,
                        12,
                        13,
                        14,
                        15,
                        16
                    ].includes(parseInt(scope.searchResultTypeId));
                    return isStandardFormat;
                };
                scope.toggleSalesforceButton = function(
                    id,
                    visibleInSalesforce
                ) {
                    visibleInSalesforce = !visibleInSalesforce;
                    search.toggleSalesforceVisiblity(id, visibleInSalesforce);
                    return visibleInSalesforce;
                };
            }
        };
    }
]);

app.controller("calendarFilter", [
    "$scope",
    "$http",
    "$filter",
    "$timeout",
    "skyword",
    "$sce",
    "$location",
    "$translate",
    "calendarEvent",
    "project",
    "ProjectFactory",
    "$window",
    "$rootScope",
    "SkyFactory",
    "calendarFilter",
    "assignment",
    "$compile",
    "$q",
    "CalendarFilterFactory",
    function(
        $scope,
        $http,
        $filter,
        $timeout,
        skyword,
        $sce,
        $location,
        $translate,
        calendarEvent,
        project,
        ProjectFactory,
        $window,
        $rootScope,
        SkyFactory,
        calendarFilter,
        assignment,
        $compile,
        $q,
        CalendarFilterFactory
    ) {
        $scope.errorsEventName = false;
        $scope.errorsStartDate = false;
        $scope.errorsEndDate = false;
        $scope.referrerPage = "";
        $scope.calendarCallInProgress = false;
        $scope.calendarCallsDelayed = 0;
        $scope.calendarCall = null;
        $scope.calendarCallInit = false;
        $scope.isFilterOn = false;
        $scope.calendarShareId = null;

        $scope.calendarSettingsDTO = {
            header: {
                showContentTypes: false,
                showCategories: false
            },
            programId: $scope.programId,
            displayProjects: {
                selected: true
            },
            displayEvents: {
                selected: true
            },
            projects: [],
            noProgramProjects: false,
            stateGroups: [],
            contentTypes: [],
            contentTypesCategories: [],
            stateGroupStates: [],
            categories: [],
            projectIds: [],
            categoryIds: [],
            stateIds: [],
            contentTypeIds: [],
            stateGroupIds: [],
            states: [],
            colors: [],
            contentTypeColorKeys: [],
            projectColorKeys: [],
            categoryColorKeys: [],
            eventTypes: [],
            eventTypeIds: []
        };

        $scope.showUpdateCalendarButton = false;
        angular.element(document.querySelector("#success-box")).addClass("hidden");
        var messageText = angular.element(document.querySelector("#success-box"));
        messageText.text("");
            
        if((window.location.href).includes("calendarShareId")) {
            $scope.showUpdateCalendarButton = true;
        }

        $scope.saveFilterForm = {
            saveViewName: ""
        };
        $scope.updateCalendarData = function updateCalendarData() {
            var filterDTO = CalendarFilterFactory.getCalendarFilter();
            filterDTO.displayName = $scope.saveFilterForm.saveViewName;
            filterDTO.isDefaultView =
                $scope.saveFilterForm.saveDefaultValue == true;

            var path = location.pathname;
            filterDTO.calendarType =
                path.indexOf("submissionCalendar.action") >= 0
                    ? "submission"
                    : "";
            var promise = calendarFilter.saveSharedCalendarFilterView(filterDTO, $scope.calendarShareId);

            promise.then(function() {
                $window.scrollTo(0, 0);
                angular.element(document.querySelector("#success-box")).removeClass("hidden");
                messageText.text($translate.instant("angular.calender.shared.update.success"));
            });
        }

        function createCalendar() {
            buildCalendarFilter();
            var settings = angular.copy($scope.calendarSettingsDTO);
            $scope.calendarCallInProgress = true;
            $scope.calendarCallInit = true;
            assignment.createCalendar(settings, function() {
                $scope.calendarCallInProgress = false;
                $scope.calendarCallsDelayed = 0;
                $scope.calendarCallInit = false;
                var test = $compile(
                    "<a data-ng-click='toggleFilter()' data-ng-class='{calendarButtonCustomizeOff: !isFilterOn, calendarButtonCustomize: (isFilterOn || isFilterOn == null)}'><span class='icon-Preferences'></span><span class='smIconCenter'>{{'angular.calendar.customize' | translate}}</span></a>"
                )($scope);
                angular
                    .element(document.getElementsByClassName("fc-header-left"))
                    .prepend(test);
            });
        }

        function updateCalendar() {
            buildCalendarFilter();
            if (
                false === $scope.calendarCallInProgress &&
                false === $scope.calendarCallInit
            ) {
                var settings = angular.copy($scope.calendarSettingsDTO);
                $scope.calendarCallInProgress = true;
                assignment.updateCalendar(settings, function() {
                    $scope.calendarCallInProgress = false;
                    $scope.calendarCallsDelayed = 0;
                });
            } else {
                $scope.calendarCallsDelayed++;

                if (null !== $scope.calendarCall) {
                    $timeout.cancel($scope.calendarCall);
                    $scope.calendarCall = null;
                }
                if (false === $scope.calendarCallInit) {
                    $scope.calendarCall = $timeout(updateCalendar, 1000);
                }
            }
        }

        function buildCalendarFilter() {
            $scope.calendarSettingsDTO.projectIds = [];
            for (
                var i = 0;
                i < $scope.calendarSettingsDTO.projects.length;
                i++
            ) {
                if ($scope.calendarSettingsDTO.projects[i].selected) {
                    $scope.calendarSettingsDTO.projectIds.push(
                        $scope.calendarSettingsDTO.projects[i].id
                    );
                }
            }

            $scope.calendarSettingsDTO.eventTypeIds = [];
            for (
                var et = 0;
                et < $scope.calendarSettingsDTO.eventTypes.length;
                et++
            ) {
                if ($scope.calendarSettingsDTO.eventTypes[et].selected) {
                    $scope.calendarSettingsDTO.eventTypeIds.push(
                        $scope.calendarSettingsDTO.eventTypes[et].id
                    );
                }
            }

            $scope.calendarSettingsDTO.stateGroupIds = [];
            for (
                var j = 0;
                j < $scope.calendarSettingsDTO.stateGroups.length;
                j++
            ) {
                if ($scope.calendarSettingsDTO.stateGroups[j].selected) {
                    $scope.calendarSettingsDTO.stateGroupIds.push(
                        $scope.calendarSettingsDTO.stateGroups[j].id
                    );
                }
            }

            $scope.calendarSettingsDTO.stateGroupIds = [];
            $scope.calendarSettingsDTO.stateIds = [];

            for (
                var k = 0;
                k < $scope.calendarSettingsDTO.stateGroupStates.length;
                k++
            ) {
                var sg = $scope.calendarSettingsDTO.stateGroupStates[k];
                if (true === sg.hyphen) {
                    sg.stateClass = "hyphenated";
                }

                if (undefined === sg.stateId || null === sg.stateId) {
                    // get stateGroups and states
                    if (
                        -1 ===
                            $scope.calendarSettingsDTO.stateGroupIds.indexOf(
                                sg.stateGroupId
                            ) &&
                        true === sg.selected
                    ) {
                        $scope.calendarSettingsDTO.stateGroupIds.push(
                            sg.stateGroupId
                        );
                    }
                    for (var n = 0; n < sg.stateList.length; n++) {
                        if (
                            -1 ===
                                $scope.calendarSettingsDTO.stateIds.indexOf(
                                    sg.stateList[n].stateId
                                ) &&
                            true === sg.stateList[n].selected
                        ) {
                            $scope.calendarSettingsDTO.stateIds.push(
                                sg.stateList[n].stateId
                            );
                        }
                    }
                } else {
                    if (
                        -1 ===
                            $scope.calendarSettingsDTO.stateGroupIds.indexOf(
                                sg.stateGroupId
                            ) &&
                        true === sg.selected
                    ) {
                        $scope.calendarSettingsDTO.stateGroupIds.push(
                            sg.stateGroupId
                        );
                    }
                    if (
                        -1 ===
                            $scope.calendarSettingsDTO.stateIds.indexOf(
                                sg.stateId
                            ) &&
                        true === sg.selected
                    ) {
                        $scope.calendarSettingsDTO.stateIds.push(sg.stateId);
                    }
                }
            }

            $scope.calendarSettingsDTO.contentTypeIds = [];
            $scope.calendarSettingsDTO.categoryIds = [];

            for (
                var m = 0;
                m < $scope.calendarSettingsDTO.contentTypesCategories.length;
                m++
            ) {
                var ct = $scope.calendarSettingsDTO.contentTypesCategories[m];
                if (true === ct.hyphen) {
                    ct.catClass = "hyphenated";
                }

                if (undefined === ct.categoryId || null === ct.categoryId) {
                    // get contentTypes and categories
                    if (
                        -1 ===
                            $scope.calendarSettingsDTO.contentTypeIds.indexOf(
                                ct.contentTypeId
                            ) &&
                        true === ct.selected
                    ) {
                        $scope.calendarSettingsDTO.contentTypeIds.push(
                            ct.contentTypeId
                        );
                    }
                    for (var l = 0; l < ct.categoryList.length; l++) {
                        if (
                            -1 ===
                                $scope.calendarSettingsDTO.categoryIds.indexOf(
                                    ct.categoryList[l].categoryId
                                ) &&
                            true === ct.categoryList[l].selected
                        ) {
                            $scope.calendarSettingsDTO.categoryIds.push(
                                ct.categoryList[l].categoryId
                            );
                        }
                    }
                } else {
                    if (
                        -1 ===
                            $scope.calendarSettingsDTO.contentTypeIds.indexOf(
                                ct.contentTypeId
                            ) &&
                        true === ct.selected
                    ) {
                        $scope.calendarSettingsDTO.contentTypeIds.push(
                            ct.contentTypeId
                        );
                    }
                    if (
                        -1 ===
                            $scope.calendarSettingsDTO.categoryIds.indexOf(
                                ct.categoryId
                            ) &&
                        true === ct.selected
                    ) {
                        $scope.calendarSettingsDTO.categoryIds.push(
                            ct.categoryId
                        );
                    }
                }
            }
            var filterDTO = angular.copy($scope.calendarSettingsDTO);
            CalendarFilterFactory.setCalendarFilter(filterDTO);
        }

        //get projects
        var unbindWatcher = $scope.$watch("programId", function() {
            var queryJson = SkyFactory.queryToJSON();
            $scope.calendarShareId = queryJson.calendarShareId;
            //console.log("$scope.calendarShareId " + queryJson.calendarShareId);
            //console.log(queryJson);
            var fromCalendar = true;
            var projectPromise = project.getProjects(
                $scope.programId,
                null,
                fromCalendar,
                $scope.calendarShareId
            );
            var displayOptionsPromise = calendarFilter.getDisplayOptions(
                $scope.programId,
                $scope.calendarShareId
            );
            var contentTypeCategoryPromise = calendarFilter.getContentTypesAndCategories(
                $scope.programId,
                $scope.calendarShareId
            );
            var stateGroupPromise = calendarFilter.getContentStateGroups(
                $scope.programId,
                $scope.calendarShareId
            );
            var eventTypesPromise = calendarFilter.getEventTypes(
                $scope.programId,
                $scope.calendarShareId
            );

            var promises = $q.all([
                displayOptionsPromise,
                contentTypeCategoryPromise,
                stateGroupPromise,
                projectPromise,
                eventTypesPromise
            ]);

            promises
                .then(function(filterObject) {
                    //console.log(filterObject);
                    // results will be an array of values from resolved promises in the original order
                    $scope.calendarSettingsDTO.keyId =
                        filterObject[0].data.keyId;
                    $scope.calendarSettingsDTO.projectColorKeys =
                        filterObject[0].data.projectColorKeys;
                    $scope.calendarSettingsDTO.contentTypeColorKeys =
                        filterObject[0].data.contentTypeColorKeys;
                    $scope.calendarSettingsDTO.categoryColorKeys =
                        filterObject[0].data.categoryColorKeys;
                    $scope.calendarSettingsDTO.stateColorKeys =
                        filterObject[0].data.stateColorKeys;
                    $scope.calendarSettingsDTO.eventTypeColorKeys =
                        filterObject[0].data.eventTypeColorKeys;
                    if (
                        undefined !== filterObject[0].data.displayEvents &&
                        null !== filterObject[0].data.displayEvents
                    ) {
                        $scope.calendarSettingsDTO.displayEvents.selected =
                            filterObject[0].data.displayEvents;
                    }
                    if (
                        undefined !== filterObject[0].data.displayProjects &&
                        null !== filterObject[0].data.displayProjects
                    ) {
                        $scope.calendarSettingsDTO.displayProjects.selected =
                            filterObject[0].data.displayProjects;
                    }

                    $scope.calendarSettingsDTO.contentTypesCategories =
                        filterObject[1].data.assignmentFilter;
                    $scope.calendarSettingsDTO.contentTypeIds =
                        filterObject[1].data.map.contentTypeIds;
                    $scope.calendarSettingsDTO.categoryIds =
                        filterObject[1].data.map.categoryIds;

                    $scope.calendarSettingsDTO.stateGroupStates =
                        filterObject[2].data.statusFilter;
                    $scope.calendarSettingsDTO.stateGroupIds =
                        filterObject[2].data.map.stateGroupIds;
                    $scope.calendarSettingsDTO.stateIds =
                        filterObject[2].data.map.stateIds;

                    $scope.calendarSettingsDTO.projects = filterObject[3].data;
                    $scope.calendarSettingsDTO.noProgramProjects =
                        0 === filterObject[3].data.length;

                    $scope.calendarSettingsDTO.eventTypes =
                        filterObject[4].data;

                    createCalendar();
                    unbindWatcher();
                })
                .catch(function(err) {
                    // will fail on any rejected promise
                })
                .finally(function(d) {});
            // undefined
        });

        $scope.toggleContentType = function(contentTypeId, categoryId) {
            var contentTypeList = $scope.calendarSettingsDTO.contentTypesCategories.filter(
                function(obj) {
                    return contentTypeId === obj.contentTypeId;
                }
            );

            var index = $scope.calendarSettingsDTO.contentTypesCategories
                .map(function(m) {
                    return m.contentTypeId;
                })
                .indexOf(contentTypeId);

            if (0 < contentTypeList.length) {
                if (
                    undefined === contentTypeList[0].categoryId ||
                    null === contentTypeList[0].categoryId
                ) {
                    var selected = false,
                        categoryList =
                            $scope.calendarSettingsDTO.contentTypesCategories[
                                index
                            ].categoryList;

                    if (contentTypeList[0].selected) {
                        selected = true;
                    }
                    for (var j = 0; j < categoryList.length; j++) {
                        $scope.calendarSettingsDTO.contentTypesCategories[
                            index
                        ].categoryList[j].selected = selected;
                        $scope.calendarSettingsDTO.contentTypesCategories[
                            index
                        ].catClass = "";
                    }
                }
            }
            updateCalendar();
        };

        $scope.toggleCategory = function(contentTypeId, categoryId) {
            var selected = false,
                hyphen = false,
                indeterminate = false;
            var contentTypeList = $scope.calendarSettingsDTO.contentTypesCategories.filter(
                function(m) {
                    return contentTypeId === m.contentTypeId;
                }
            );
            var index = $scope.calendarSettingsDTO.contentTypesCategories
                .map(function(m) {
                    return m.contentTypeId;
                })
                .indexOf(contentTypeId);

            if (0 < contentTypeList.length) {
                var categorySelectedList = contentTypeList[0].categoryList.filter(
                    function(obj) {
                        return true === obj.selected;
                    }
                );

                if (0 === categorySelectedList.length) {
                    selected = false;
                    hyphen = false;
                } else if (
                    categorySelectedList.length ===
                    contentTypeList[0].categoryList.length
                ) {
                    selected = true;
                    hyphen = false;
                } else {
                    selected = true;
                    hyphen = true;
                    indeterminate = true;
                }
                if (
                    contentTypeId ===
                    $scope.calendarSettingsDTO.contentTypesCategories[index]
                        .contentTypeId
                ) {
                    $scope.calendarSettingsDTO.contentTypesCategories[
                        index
                    ].selected = selected;
                    $scope.calendarSettingsDTO.contentTypesCategories[
                        index
                    ].hyphen = hyphen;
                    $scope.calendarSettingsDTO.contentTypesCategories[
                        index
                    ].indeterminate = indeterminate;
                    $scope.calendarSettingsDTO.contentTypesCategories[
                        index
                    ].catClass = hyphen ? "hyphenated" : "";
                }
            }
            updateCalendar();
        };

        $scope.toggleStateGroup = function(stateGroupId, stateId) {
            var stateGroupList = $scope.calendarSettingsDTO.stateGroupStates.filter(
                function(obj) {
                    return stateGroupId === obj.stateGroupId;
                }
            );

            var index = $scope.calendarSettingsDTO.stateGroupStates
                .map(function(m) {
                    return m.stateGroupId;
                })
                .indexOf(stateGroupId);

            if (0 < stateGroupList.length) {
                if (
                    undefined === stateGroupList[0].stateId ||
                    null === stateGroupList[0].stateId
                ) {
                    var selected = false,
                        stateList =
                            $scope.calendarSettingsDTO.stateGroupStates[index]
                                .stateList;

                    if (stateGroupList[0].selected) {
                        selected = true;
                    }
                    for (var j = 0; j < stateList.length; j++) {
                        $scope.calendarSettingsDTO.stateGroupStates[
                            index
                        ].stateList[j].selected = selected;
                        $scope.calendarSettingsDTO.stateGroupStates[
                            index
                        ].stateClass = "";
                    }
                }
            }
            updateCalendar();
        };

        $scope.toggleState = function(stateGroupId, stateId) {
            var selected = false,
                hyphen = false,
                indeterminate = false;
            var stateGroupList = $scope.calendarSettingsDTO.stateGroupStates.filter(
                function(m) {
                    return stateGroupId === m.stateGroupId;
                }
            );
            var index = $scope.calendarSettingsDTO.stateGroupStates
                .map(function(m) {
                    return m.stateGroupId;
                })
                .indexOf(stateGroupId);

            if (0 < stateGroupList.length) {
                var stateSelectedList = stateGroupList[0].stateList.filter(
                    function(obj) {
                        return true === obj.selected;
                    }
                );

                if (0 === stateSelectedList.length) {
                    selected = false;
                    hyphen = false;
                } else if (
                    stateSelectedList.length ===
                    stateGroupList[0].stateList.length
                ) {
                    selected = true;
                    hyphen = false;
                } else {
                    selected = true;
                    hyphen = true;
                    indeterminate = true;
                }
                if (
                    stateGroupId ===
                    $scope.calendarSettingsDTO.stateGroupStates[index]
                        .stateGroupId
                ) {
                    $scope.calendarSettingsDTO.stateGroupStates[
                        index
                    ].selected = selected;
                    $scope.calendarSettingsDTO.stateGroupStates[
                        index
                    ].hyphen = hyphen;
                    $scope.calendarSettingsDTO.stateGroupStates[
                        index
                    ].indeterminate = indeterminate;
                    $scope.calendarSettingsDTO.stateGroupStates[
                        index
                    ].stateClass = hyphen ? "hyphenated" : "";
                }
            }
            updateCalendar();
        };

        $scope.$watch(
            "calendarSettingsDTO.projects",
            function() {
                updateCalendar();
            },
            true
        );

        $scope.$watch(
            "calendarSettingsDTO.eventTypes",
            function() {
                updateCalendar();
            },
            true
        );

        $scope.$watch(
            "calendarSettingsDTO.stateGroups",
            function() {
                updateCalendar();
            },
            true
        );

        $scope.$watch(
            "calendarSettingsDTO.keyId",
            function() {
                updateCalendar();
            },
            true
        );

        $scope.$watch(
            "calendarSettingsDTO.displayProjects",
            function() {
                updateCalendar();
            },
            true
        );

        $scope.$watch(
            "calendarSettingsDTO.displayEvents",
            function() {
                updateCalendar();
            },
            true
        );

        $scope.toggleDisplay = function($event) {
            var el = $event.currentTarget;
            jQuery(el)
                .toggleClass("icon-Expand")
                .toggleClass("icon-Collapse");
            jQuery(el)
                .parent()
                .next("section")
                .slideToggle("slow", function() {});
        };

        /**
         * Initialize the hidden calendar
         */
        jQuery(".thinLeft:eq(1)").removeClass("flexible2ColRight");
        jQuery(".calendarfilter").hide();
        /**
         * Add the 'Customize' button
         */
        //$timeout(function () {
        //    var test = $compile("<a data-ng-click='toggleFilter()' data-ng-class='{calendarButtonCustomizeOff: !isFilterOn, calendarButtonCustomize: (isFilterOn || isFilterOn == null)}'><span class='icon-Preferences'></span><span class='smIconCenter'>{{'angular.calendar.customize' | translate}}</span></a>")($scope);
        //    angular.element(document.getElementsByClassName('fc-header-left')).prepend(test);
        //}, 500);

        /**
         * This function will trigger on click of the 'Customize' button and will show and hide the filter bar,
         * Will implement: https://skyword.atlassian.net/browse/SKY-14690
         */
        $scope.toggleFilter = function() {
            if (typeof $scope.isFilterOn == "undefined") {
                $scope.isFilterOn = true;
            }
            if ($scope.isFilterOn) {
                // hide
                $(".thinLeft:eq(1)").removeClass("flexible2ColRight");
                $(".calendarfilter").hide();
            } else {
                // show
                $(".calendarfilter").show();
                $(".thinLeft:eq(1)").addClass("flexible2ColRight");
            }
            $scope.isFilterOn = !$scope.isFilterOn;
        };
    }
]);

(function() {
    app.service("annotator", [
        "skyword",
        "setup",
        "call",
        "$rootScope",
        "TinymceService",
        function(skyword, setup, call, $rootScope, TinymceService) {
            var redactorDivId;
            var redactorTextareaId;
            var redactorDiv;
            var redactor,
                currentRedactorId,
                currentRedactorDivId,
                mark,
                annotationText,
                showingThread,
                highlightedText,
                redactorSave,
                saveRedactorEditor,
                req,
                clearAnnotationThreads = function() {
                    showingThread = false;
                    $("span.status").fadeOut("fast");
                    $("mark.inline-commenting").removeClass("selected");
                    $("ul.annotations .respondField").hide();
                    $("ul.annotations .responseButtons").show();
                    $("ul.annotations .replybutton").show();
                    if ($("ul.annotations").length > 0) {
                        $("#annotation-module .preConfirmRemove").show();
                        $("ul.annotations").each(function() {
                            var u = $(this),
                                l = u.find("li.annotation-thread");
                            l.fadeOut("fast");
                            u.fadeIn("fast").removeClass("selected");
                        });
                    }
                    $("#annotation-module .removeAll").hide();
                },
                getContainerDataId = function(elm, type) {
                    if (
                    // Be wary of this if our page structure changes!
                        $(elm)
                            .parent()
                            .parent()
                            .parent()
                            .parent()
                            .parent()
                            .parent()
                            .parent()
                            .parent()
                            .data("containerdataid")
                    ) {
                        return $(elm)
                            .parent()
                            .parent()
                            .parent()
                            .parent()
                            .parent()
                            .parent()
                            .parent()
                            .parent()
                            .data("containerdataid");
                    } else {
                        return "";
                    }
                },
                showAnnotationThread = function(that, t) {
                    skyword.showTimeFrame();
                    var ul, li, aid, mark;

                    showingThread = true;

                    if ("mark" === that.tagName.toLowerCase()) {
                        mark = $(that);
                        ul = $(
                            "ul[data-value='" + mark.attr("data-value") + "']"
                        );
                        $("#annotation-module .tab").click();
                    } else {
                        ul = $(that);
                        aid = ul.attr("data-value");
                        mark = $('mark[data-value="' + aid + '"]');
                    }
                    li = ul.find("li.first-annotation");
                    $("ul.annotations").fadeOut("fast");
                    ul.fadeIn().addClass("selected");
                    $(".preConfirmRemove").hide();
                    // console.log("no resolved class?");
                    li.siblings("li.annotation-thread").fadeIn("fast");
                    // console.log(li.siblings("li.annotation-thread"));
                    mark.addClass("selected");
                    if (t === "annotation") {
                        showAnnotationForTinymce(aid, mark);
                    }
                    li.find("span.status").fadeIn("fast");
                },
                showAnnotationForTinymce = function(aid, mark) {
                    // Check rich text fields for annotation id (tinymce)
                    for (var i = 0; i < tinymce.editors.length; i++) {
                        // 0. Find editor with matching annotation id
                        var thisEditorContent = tinymce
                            .get(tinymce.editors[i].id)
                            .getContent({ format: "raw" });
                        if (thisEditorContent.indexOf(aid) >= 0) {
                            var thisEditorId = tinymce.editors[i].id;
                            var editor = tinymce.editors[i];
                            var thisEditorContainer =
                                tinymce.editors[i].editorContainer;

                            // TinyMCE rich text
                            if (thisEditorContainer) {
                                //Check if tinymce is inside a container
                                let containerdataid = getContainerDataId(
                                    thisEditorContainer,
                                    "richText"
                                );
                                if (
                                    containerdataid !== "" &&
                                    !$(
                                        `#container-${containerdataid} .container${containerdataid}`
                                    ).is(":visible")
                                ) {
                                    $(
                                        `#container-${containerdataid} .toggleContainer`
                                    ).click();
                                    tinymce.execCommand(
                                        "mceFocus",
                                        false,
                                        thisEditorId
                                    );

                                    // settimeout to allow the container to open completely
                                    setTimeout(function() {
                                        // 1. Get the mark tags from this editor
                                        var thisEditorsAnnotations = jQuery(
                                            editor.getBody()
                                        ).find("mark");
                                        // 2. Loop through all mark tags for a match
                                        for (
                                            var j = 0;
                                            j < thisEditorsAnnotations.length;
                                            j++
                                        ) {
                                            var thisAid = jQuery(
                                                thisEditorsAnnotations[j]
                                            ).data("value");
                                            // 3. If this annotations data-value matches the request
                                            if (parseInt(aid) === thisAid) {
                                                // 4. Get offsets for this editor div and this annotation offset
                                                var thisRichTextAnnotationOffset =
                                                    thisEditorsAnnotations[j]
                                                        .offsetTop;
                                                var scrollAmount =
                                                    $(
                                                        "#" +
                                                            thisEditorContainer.id
                                                    ).offset().top - 150;
                                                scrollAmount =
                                                    scrollAmount +
                                                    thisRichTextAnnotationOffset;
                                                $("html, body").animate(
                                                    { scrollTop: scrollAmount },
                                                    1000
                                                );
                                                return;
                                            }
                                        }
                                    }, 100);
                                } else {
                                    // 1. Get the mark tags from this editor
                                    var thisEditorsAnnotations = jQuery(
                                        editor.getBody()
                                    ).find("mark");
                                    // 2. Loop through all mark tags for a match
                                    for (
                                        var j = 0;
                                        j < thisEditorsAnnotations.length;
                                        j++
                                    ) {
                                        var thisAid = jQuery(
                                            thisEditorsAnnotations[j]
                                        ).data("value");
                                        // 3. If this annotations data-value matches the request
                                        if (parseInt(aid) === thisAid) {
                                            // 4. Get offsets for this editor div and this annotation offset
                                            var thisRichTextAnnotationOffset =
                                                thisEditorsAnnotations[j]
                                                    .offsetTop;
                                            var scrollAmount =
                                                $(
                                                    "#" + thisEditorContainer.id
                                                ).offset().top - 250;
                                            scrollAmount =
                                                scrollAmount +
                                                thisRichTextAnnotationOffset;
                                            $("html, body").animate(
                                                {
                                                    scrollTop: scrollAmount
                                                },
                                                1000
                                            );
                                            return;
                                        }
                                    }
                                }
                                return;
                            }
                            // TinyMCE plain text
                            else if (thisEditorId) {
                                //Check if TinyMCE plain text is inside a container
                                let containerdataid = getContainerDataId(
                                    "#" + thisEditorId,
                                    "plainText"
                                );

                                if (
                                    containerdataid !== "" &&
                                    !$(
                                        `#container-${containerdataid} .container${containerdataid}`
                                    ).is(":visible")
                                ) {
                                    $(
                                        `#container-${containerdataid} .toggleContainer`
                                    ).click();
                                    setTimeout(function() {
                                        let thisEditorsAnnotations = jQuery(
                                            "#" + thisEditorId
                                        ).find("mark");
                                        for (
                                            var j = 0;
                                            j < thisEditorsAnnotations.length;
                                            j++
                                        ) {
                                            var thisAid = jQuery(
                                                thisEditorsAnnotations[j]
                                            ).data("value");
                                            if (parseInt(aid) === thisAid) {
                                                var thisTextAnnotationOffset =
                                                    thisEditorsAnnotations[j]
                                                        .offsetTop;
                                                var scrollAmount =
                                                    $(
                                                        "#" + thisEditorId
                                                    ).offset().top - 150;
                                                scrollAmount =
                                                    scrollAmount +
                                                    thisTextAnnotationOffset;
                                                $("html, body").animate(
                                                    { scrollTop: scrollAmount },
                                                    1000
                                                );
                                                return;
                                            }
                                        }
                                    }, 100);
                                } else {
                                    $("html, body").animate(
                                        {
                                            scrollTop:
                                                $("#" + thisEditorId).offset()
                                                    .top - 250
                                        },
                                        1000
                                    );
                                }
                                return;
                            } else {
                                $("html, body").animate(
                                    {
                                        scrollTop: mark.offset().top - 350
                                    },
                                    1000
                                );
                                return;
                            }
                        }
                    }

                    //  Adjusting the parameter to scrollTop will adjust the location the comment displays at.
                    // $(window).scrollTop()
                    $("html, body").animate(
                        { scrollTop: mark.offset().top - 350 },
                        1000
                    );
                },
                showAnnotationThreadTinyMce = function(annotationId, t) {
                    // console.log("showAnnotationThreadMCE");
                    skyword.showTimeFrame();
                    showingThread = true;
                    var el = document.getElementById(
                        "annotation-" + annotationId
                    );
                    if (undefined === el || null === el) {
                        return;
                    }
                    jQuery("ul.annotations").hide();

                    setTimeout(function() {
                        // console.log("timeout called....");
                        $("#annotation-module")
                            .find(".preConfirmRemove")
                            .hide();
                        var ul = jQuery("#annotation-" + annotationId);
                        ul.addClass("selected").show();
                        ul.siblings(".annotations").hide(); // we shouldn't have to do this but jquery is all bcfytw
                        var li = ul.find("li.first-annotation");
                        li.siblings("li.annotation-thread").fadeIn("fast");
                        if (t === "annotation") {
                            try {
                                $("body").animate(
                                    { scrollTop: ul.offset().top - 350 },
                                    1000
                                );
                            } catch (e) {
                                console.log(
                                    "Error when attempting to scroll up in showAnnotationThreadTinyMce"
                                );
                            }
                        }
                    }, 100);
                },
                getExternalReviewerName = function() {
                    var externalReviewerName = localStorage.getItem(
                        "externalReviewer"
                    );
                    externalReviewerName =
                        externalReviewerName === null
                            ? ""
                            : externalReviewerName;
                    var externalReviewerData = "";
                    var accountId = parseInt($("#attrs-id").data("accountid"));
                    if (accountId === 1) {
                        if (externalReviewerName.trim() !== "") {
                            externalReviewerData = " - " + externalReviewerName;
                        }
                    }
                    return externalReviewerData;
                },
                annotateReply = function() {
                    if ($(this).hasClass("disabled")) {
                        return false;
                    }

                    $(this).addClass("disabled");

                    var reply = $(this),
                        div = reply.parent(),
                        respondField = div.closest(".liform"),
                        threadValue = div
                            .closest(".liform")
                            .parent()
                            .data("value"),
                        text = respondField.find("textarea"),
                        cted = respondField.find(
                            ".contentTemplateElementDataId"
                        ),
                        parentAnnotationId = respondField.find(
                            ".parentAnnotationId"
                        ),
                        urlAction = "/saveAnnotationAjax.action",
                        options,
                        opts;

                    opts = {
                        contentRequestId: $("#contentRequestId").val(),
                        programId: $("#program-id").val(),
                        contentTemplateElementDataId: cted.val(),
                        parentAnnotationId: parentAnnotationId.val(),
                        annotationText: text.val() + getExternalReviewerName(),
                        lockedBySomebodyElse: $("#commentLocked").val() || false
                    };

                    options = {
                        cache: false,
                        type: "POST",
                        data: opts,
                        dataType: "html",
                        url: urlAction,
                        success: function(data) {
                            var annotationModule = $("#annotation-module");
                            annotationModule.replaceWith(data);
                            annotationModule.find(".preConfirmRemove").show();
                            annotationModule.find(".confirmRemove").hide();
                            annotationModule.find(".replybutton").show();
                            // SKY-12012 - reinit jQuery object
                            $("#annotation-module").addClass("active");
                            $(
                                "ul.annotations[data-value='" +
                                    threadValue +
                                    "'] .first-annotation"
                            ).click();
                        },
                        error: function(data, status, err) {
                            console.log([data, status, err]);
                        },
                        complete: function() {
                            $("textarea.annotation").autosize();
                            setup.followMenuResize();
                        }
                    };

                    call.ajax(urlAction, options);
                    return false;
                },
                showAnnotationEdit = function() {
                    var a = $(this),
                        div = a.parent(),
                        annotationText = div.siblings(".annotationText"),
                        actions = div.siblings(".actions");
                    annotationText.fadeOut("fast", function() {
                        div.siblings(".editAnnotation").fadeIn("fast");
                        div.fadeOut("fast");
                    });
                },
                cancelAnnotationEdit = function() {
                    var a = $(this),
                        div = a.parent(),
                        textarea = div.siblings(".editAnnotation"),
                        actions = div.siblings(".actions"),
                        annotationText = div.siblings(".annotationText");
                    textarea.fadeOut("fast", function() {
                        annotationText.fadeIn("fast");
                    });
                    div.fadeOut("fast", function() {
                        actions.fadeIn("slow");
                    });
                },
                annotateEdit = function() {
                    var button = $(this),
                        div = button.parent(),
                        cted = div.siblings(".contentTemplateElementDataId"),
                        text = div.siblings("textarea"),
                        annotationId = text.siblings(".annotationId"),
                        urlAction = "/editAnnotationAjax.action",
                        options,
                        opts;

                    opts = {
                        contentRequestId: $("#contentRequestId").val(),
                        programId: $("#program-id").val(),
                        annotationId: annotationId.val(),
                        annotationText: text.val(),
                        contentTemplateElementDataId: cted.val()
                    };

                    options = {
                        cache: false,
                        type: "POST",
                        data: opts,
                        dataType: "html",
                        url: urlAction,
                        success: function(data) {},
                        error: function(data, status, err) {
                            console.log([data, status, err]);
                        },
                        complete: function() {}
                    };

                    call.ajax(urlAction, options);

                    return false;
                };

            function saveEditor(myRedactor) {
                var TinymceService = angular
                    .element(document.body)
                    .injector()
                    .get("TinymceService");
                var el = document.getElementById(myRedactor.attr("id"));
                var content = "";

                // if spell check is active, remove suggestions markup
                if (el.classList.contains("spellcheckActive")) {
                    var article = myRedactor;
                    article
                        .find(
                            ".hiddenGrammarError, .hiddenSuggestion, .hiddenSpellError"
                        )
                        .addClass("hide")
                        .contents()
                        .unwrap();
                    article
                        .find(".mceItemHidden")
                        .contents()
                        .unwrap();
                    content = article.html();
                } else {
                    content = myRedactor.html();
                }
                TinymceService.save(
                    TinymceService.buildDTO(
                        content,
                        el.getAttribute("data-contentrequestid"),
                        el.getAttribute("data-contenttemplateelementid"),
                        el.getAttribute("data-contenttemplateelementdataid"),
                        el.getAttribute("data-contenttemplateid"),
                        el.getAttribute("data-contentid"),
                        el.getAttribute("data-programid"),
                        el.getAttribute("data-contenttypeid")
                    )
                );
            }

            var annotateRemoveMarkTags = function() {
                $("mark")
                    .contents()
                    .unwrap();
                $(".redactor_editor").each(function() {
                    var that = $(this);
                    saveEditor(that);
                });
            };
            var annotateRemoveAll = function() {
                var a = $(this),
                    annotationId = a.attr("data-value"),
                    opts,
                    options,
                    urlAction = "/removeAllAnnotationsAjax.action",
                    li = a.closest("li.annotate"),
                    ul = li.parent(),
                    contentRequestId = $("#contentRequestId").val();
                opts = {
                    contentRequestId: contentRequestId,
                    programId: $("#program-id").val()
                };

                options = {
                    cache: false,
                    type: "POST",
                    data: opts,
                    dataType: "html",
                    url: urlAction,
                    success: function(data) {},
                    error: function(data, status, err) {
                        console.log([data, status, err]);
                    },
                    complete: function() {
                        $("#annotation-module .tab .count").html("");
                        $(".annotations").remove();
                        $("#noCommentText").show();
                        $(".removeAll").hide();
                        removeAllMarkTagsFromAllEditors();
                    }
                };
                call.ajax(urlAction, options);
                return false;
            };

            var annotateResolveAll = function() {
                var a = $(this),
                    annotationId = a.attr("data-value"),
                    opts,
                    options,
                    urlAction = "/resolveAllAnnotationsAjax.action",
                    li = a.closest("li.annotate"),
                    ul = li.parent(),
                    contentRequestId = $("#contentRequestId").val();
                opts = {
                    contentRequestId: contentRequestId,
                    programId: $("#program-id").val()
                };

                options = {
                    cache: false,
                    type: "POST",
                    data: opts,
                    dataType: "html",
                    url: urlAction,
                    success: function(data) {},
                    error: function(data, status, err) {
                        console.log([data, status, err]);
                    },
                    complete: function() {
                        removeAllMarkTagsFromAllEditors();
                    }
                };
                call.ajax(urlAction, options);
            };

            function removeCommentsList(link) {
                var li = link.closest(".annotations").find(".first-annotation"),
                    ul = li.parent();
                if (li.hasClass("first-annotation")) {
                    ul.fadeOut("fast", function() {
                        $(this).remove();
                        var commentListCount = $("ul.annotations").length;
                        $("#annotation-count").text(commentListCount);
                        if (commentListCount === 0) {
                            $("#noCommentText").show();
                            $(".preConfirmRemove").hide();
                            $("#annotation-count").text("");
                        }
                    });
                } else {
                    li.fadeOut("fast", function() {
                        $(this).remove();
                    });
                }
                clearAnnotationThreads();
            }

            function removeMarkTagsFromRedactor(annotationId) {
                var mark = $("mark[data-value='" + annotationId + "']"),
                    redactorDiv = mark.closest(".redactor_editor "),
                    textarea = redactorDiv.siblings(".templateElement"),
                    redactor = $("#" + textarea.attr("id")).redactor(
                        "getObject"
                    );
                mark.contents().unwrap();
                saveEditor(redactorDiv);
            }

            function removeAllMarkTagsFromAllEditors() {
                switch ($rootScope.getActionName()) {
                    case "articleReview":
                        annotateRemoveMarkTags();
                        break;
                    default:
                        TinymceService.removeMarkTagsFromAllTinymce();
                        break;
                }
            }

            function removeMarkTagsFromEditor(link) {
                var annotationId = link
                        .closest(".liform")
                        .find(".parentAnnotationId")
                        .val(),
                    contentTemplateElementDataId = link
                        .closest(".liform")
                        .find(".contentTemplateElementDataId")
                        .val();

                switch ($rootScope.getActionName()) {
                    case "articleReview":
                        removeMarkTagsFromRedactor(annotationId);
                        break;
                    default:
                        TinymceService.removeMarkTagsFromTinymce(
                            annotationId,
                            contentTemplateElementDataId
                        );
                        break;
                }
            }

            function sortComments() {
                let dataValueArray = [];
                try {
                    if (window.location.pathname.includes("articleReview")) {
                        let commentList = Array.from(document.getElementsByClassName("inline-commenting"));
                        commentList.forEach((element, index) => {
                            dataValueArray.push(parseInt(element.getAttribute("data-value")));
                        });
                    }
                    tinymce.editors.forEach((editor) => {
                        var iframe = editor.iframeElement;
                        if (iframe) {
                            var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                            let commentList = Array.from(iframeDoc.getElementsByClassName("inline-commenting"));
                            commentList.forEach((element, index) => {
                                dataValueArray.push(parseInt(element.getAttribute("data-value")));
                            });
                        }
                    });
                } catch (error) {
                    console.log(error);
                }

                const container = document.querySelector(".AnnotationListWrapper");
                let ulElementsBeforeSort = Array.from(container.querySelectorAll("ul.annotations"));
                let ulElements = [];
                let resolvedElements = [];
                ulElementsBeforeSort.forEach((ul) => {
                    if(dataValueArray.indexOf(parseInt(ul.getAttribute("data-value"))) === -1) {
                        resolvedElements.push(ul);
                    }
                    else ulElements.push(ul);
                });

                // Sort the UL elements based on the order in dataValueArray
                ulElements.sort((a, b) => {
                    return dataValueArray.indexOf(parseInt(a.getAttribute("data-value"))) - dataValueArray.indexOf(parseInt(b.getAttribute("data-value")));
                });
                ulElements = [...ulElements, ...resolvedElements];

                ulElements.forEach((ul) => container.appendChild(ul));
            }

            var annotateRemove = function() {
                    var link = $(this),
                        annotationId = link
                            .closest(".liform")
                            .find(".parentAnnotationId")
                            .val(),
                        opts,
                        options,
                        urlAction = "/removeAnnotationAjax.action";
                    opts = {
                        contentRequestId: $("#contentRequestId").val(),
                        programId: $("#program-id").val(),
                        annotationId: annotationId
                    };
                    options = {
                        cache: false,
                        type: "POST",
                        data: opts,
                        dataType: "html",
                        url: urlAction,
                        success: function(data) {
                            removeCommentsList(link);
                        },
                        error: function(data, status, err) {
                            console.log([data, status, err]);
                        },
                        complete: function() {
                            removeMarkTagsFromEditor(link);
                        }
                    };

                    call.ajax(urlAction, options);

                    return false;
                },
                annotateResolve = function() {
                    var link = $(this),
                        annotationId = link
                            .closest(".liform")
                            .find(".parentAnnotationId")
                            .val(),
                        opts,
                        options,
                        urlAction = "/resolveAnnotationAjax.action",
                        opts = {
                            contentRequestId: $("#contentRequestId").val(),
                            programId: $("#program-id").val(),
                            annotationId: annotationId
                        };
                    options = {
                        cache: false,
                        type: "POST",
                        data: opts,
                        dataType: "html",
                        url: urlAction,
                        success: function(data) {
                            jQuery("#annotation-module").replaceWith(data);
                            jQuery("#annotation-module > a").click();
                            sortComments();
                        },
                        error: function(data, status, err) {
                            console.log([data, status, err]);
                        },
                        complete: function() {
                            removeMarkTagsFromEditor(link);
                        }
                    };

                    call.ajax(urlAction, options);
                },
                annotateReopen = function() {
                    var a = $(this),
                        annotationId = a.attr("data-value"),
                        opts,
                        options,
                        urlAction = "/reopenAnnotationAjax.action",
                        li = a.closest("li.annotate"),
                        ul = li.parent();
                },
                cancelCommentAction = function(button) {
                    var form = $(button).closest(".liform");
                    form.find(".respondField").fadeOut("fast");
                    form.find(".responseButtons").fadeIn("fast");
                    form.find(".replybutton").fadeIn("fast");
                };

            return {
                setRedactorDivId: function(id) {
                    redactorDivId = id;
                },
                getRedactorDivId: function() {
                    return redactorDivId;
                },
                setRedactorTextareaId: function(id) {
                    redactorTextareaId = id;
                },
                getRedactorTextareaId: function() {
                    return redactorTextareaId;
                },
                setRedactorDiv: function(div) {
                    redactorDiv = div;
                },
                getRedactorDiv: function() {
                    return redactorDiv;
                },
                showAnnotationThread: showAnnotationThread,
                showAnnotationThreadTinyMce: showAnnotationThreadTinyMce,
                editableRedactorDiv: null,
                setRedactorSave: function(givenFunction) {
                    redactorSave = givenFunction;
                },
                setSaveRedactorEditor: function(givenFunction) {
                    saveRedactorEditor = givenFunction;
                },
                setMark: function(givenMark) {
                    mark = givenMark;
                },
                setReq: function(givenReq) {
                    req = givenReq;
                },
                setHighlightedText: function(givenHighlighted) {
                    highlightedText = givenHighlighted;
                },
                setAnnotationText: function(givenAnnotation) {
                    annotationText = givenAnnotation;
                },
                setCurrentRedactorId: function(givenRedactorId) {
                    currentRedactorId = givenRedactorId;
                },
                getCurrentRedactorId: function() {
                    return currentRedactorId;
                },
                setRedactor: function(givenRedactor) {
                    redactor = givenRedactor;
                },
                init: function() {
                    //onReady
                    $(function() {
                        //only display annotations functionality if flag is set to true

                        if (
                            0 < $("#annotations-enabled").length &&
                            $("#annotations-enabled").val() != "false"
                        ) {
                            var body = $("body");
                            body.delegate(
                                ".cancelCommentAction",
                                "click",
                                function() {
                                    cancelCommentAction(this);
                                }
                            );
                            $("textarea.annotation").autosize();
                            $("textarea.annotation").keyup(function() {
                                var text = $(this),
                                    div = text.siblings(".replybutton"),
                                    button = div.find("button");
                                if ("" === text.val()) {
                                    div.fadeOut("fast");
                                } else {
                                    button
                                        .removeClass("disabled")
                                        .removeAttr("disabled");
                                    div.fadeIn("fast");
                                }
                            });

                            body.delegate(
                                ".respondComment",
                                "click",
                                annotateReply
                            );

                            body.delegate(
                                "ul.annotations .first-annotation",
                                "click",
                                function() {
                                    showAnnotationThread(
                                        $(this).parent()[0],
                                        "annotation"
                                    );
                                }
                            );
                            body.delegate(".showThread", "click", function() {
                                console.log(".showThread");
                                var targetValue = $(this).data("target");
                                $("mark.inline-commenting").removeClass(
                                    "selected"
                                );
                                $("#annotation-module .tab").click();
                                showAnnotationThread(
                                    $(
                                        "ul.annotations[data-value='" +
                                            targetValue +
                                            "']"
                                    )[0],
                                    "mark"
                                );
                            });
                            body.delegate(
                                "ul.annotations .removeComment",
                                "click",
                                annotateRemove
                            );
                            body.delegate(
                                "ul.annotations .resolveComment",
                                "click",
                                annotateResolve
                            );

                            body.delegate(
                                "ul.annotations .respondSetup",
                                "click",
                                function() {
                                    $(this)
                                        .parent()
                                        .fadeOut("fast", function() {
                                            $(this)
                                                .parent()
                                                .hide();
                                            $(this)
                                                .closest(".liform")
                                                .find(".confirmRespond")
                                                .fadeIn("fast");
                                            $(
                                                ".confirmRespond textarea"
                                            ).focus();
                                        });
                                }
                            );
                            body.delegate(
                                "ul.annotations .removeSetup",
                                "click",
                                function() {
                                    $(this)
                                        .parent()
                                        .fadeOut("fast", function() {
                                            $(this)
                                                .closest(".liform")
                                                .find(".responseButtons")
                                                .hide();
                                            $(this)
                                                .closest(".liform")
                                                .find(".confirmRemove")
                                                .fadeIn("fast");
                                        });
                                }
                            );
                            body.delegate(
                                "ul.annotations .resolveSetup",
                                "click",
                                function() {
                                    $(this)
                                        .parent()
                                        .fadeOut("fast", function() {
                                            $(this)
                                                .closest(".liform")
                                                .find(".responseButtons")
                                                .hide();
                                            $(this)
                                                .closest(".liform")
                                                .find(".confirmResolve")
                                                .fadeIn("fast");
                                        });
                                }
                            );
                            body.on("click", ".backButton", null, function() {
                                clearAnnotationThreads();
                                return false;
                            });
                            body.delegate(
                                "a#btn-remove-all-comments-pre",
                                "click",
                                function() {
                                    $(this)
                                        .parent()
                                        .fadeOut("fast", function() {
                                            $(this)
                                                .siblings(".confirmRemove")
                                                .fadeIn("fast");
                                        });
                                    return false;
                                }
                            );
                            body.delegate(
                                "a#remove-comments-cancel",
                                "click",
                                function() {
                                    $(this)
                                        .parent()
                                        .fadeOut("fast", function() {
                                            $(this)
                                                .siblings(".preConfirmRemove")
                                                .fadeIn("fast");
                                        });
                                    return false;
                                }
                            );

                            body.delegate(
                                "a#btn-remove-all-comments",
                                "click",
                                annotateRemoveAll
                            );

                            body.click(function(e) {
                                var target = jQuery(e.target);
                                if (
                                    "articleReview" ===
                                        document
                                            .getElementById("attrs-id")
                                            .getAttribute("data-actionname") &&
                                    target.parents(".annotationList").length ===
                                        0 &&
                                    target.parents(".qtip").length === 0 &&
                                    !(
                                        target.hasClass("tab") ||
                                        target.hasClass("showThread") ||
                                        target.hasClass("annotationList") ||
                                        target.hasClass("confirmRemove")
                                    )
                                ) {
                                    clearAnnotationThreads();
                                }
                            });
                            body.on("click", ".tabs li.tab", null, function() {
                                var editorId = null;
                                if (
                                    "annotation-module" === $(this).attr("id")
                                ) {
                                    $(
                                        ".tinyMceContainer, .reviewAnnotate"
                                    ).addClass("annotationsOn");
                                    for (
                                        var i = 0;
                                        i < tinymce.editors.length;
                                        i++
                                    ) {
                                        editorId = tinymce.editors[i].id;
                                        try {
                                            tinymce
                                                .get(editorId)
                                                .dom.addClass(
                                                    tinymce
                                                        .get(editorId)
                                                        .dom.select("body"),
                                                    "annotationsOn"
                                                );
                                        } catch (e) {}
                                    }
                                } else {
                                    $("mark.inline-commenting")
                                        .not(".nocomment")
                                        .qtip("destroy", true);
                                    $(
                                        ".tinyMceContainer, .reviewAnnotate"
                                    ).removeClass("annotationsOn");
                                    for (
                                        var i = 0;
                                        i < tinymce.editors.length;
                                        i++
                                    ) {
                                        editorId = tinymce.editors[i].id;
                                        try {
                                            tinymce
                                                .get(editorId)
                                                .dom.removeClass(
                                                    tinymce
                                                        .get(editorId)
                                                        .dom.select("body"),
                                                    "annotationsOn"
                                                );
                                        } catch (e) {}
                                    }
                                }
                            });

                            body.delegate(
                                "[id^='annotation-reply-']:visible",
                                "keyup",
                                function() {
                                    if (!$(this).val()) {
                                        $(this)
                                            .siblings(".respondComment")
                                            .addClass("disabled");
                                    } else {
                                        $(this)
                                            .siblings(".respondComment")
                                            .removeClass("disabled");
                                    }
                                }
                            );
                            $("#annotation-module>div").resize(function() {
                                if ($(this).height() < 300) {
                                    setup.followMenuResize();
                                }
                            });
                        }
                    });
                },
                sortCommentsOrder: sortComments,
                addComment: function(event) {
                    var selectionRes = window.getSelection().toString();
                    currentRedactorId =
                        "#" +
                        $(this)
                            .parent()
                            .find("textarea")
                            .attr("id");
                    currentRedactorDivId =
                        "#" +
                        $(currentRedactorId)
                            .parent()
                            .find(".redactor_templateElement")
                            .attr("id");

                    if (selectionRes !== "") {
                        $(
                            ".hiddenGrammarError,.hiddenSuggestion,.hiddenSpellError"
                        )
                            .addClass("hide")
                            .contents()
                            .unwrap();
                        $(".mceItemHidden")
                            .contents()
                            .unwrap();
                        $(currentRedactorId).redactor("addTempMark");
                    }
                },
                removeTempMarker: function(event) {
                    if (window.getSelection().toString() === "") {
                        $(currentRedactorId).redactor("removeTempMark");
                    }
                },
                checkAnnotations: function() {
                    var ul = $("ul.annotations"),
                        annotations = [],
                        mark = $("mark.inline-commenting"),
                        marks = [];
                    try {
                        mark.each(function() {
                            var id = parseInt($(this).attr("data-value"), 10);
                            if (!isNaN(id) && -1 === $.inArray(id, marks)) {
                                marks.push(id);
                            }
                        });
                        ul.each(function() {
                            var u = $(this),
                                id = parseInt(u.attr("data-value"), 10),
                                first,
                                actions,
                                remove;
                            if (!isNaN(id) && -1 === $.inArray(id, marks)) {
                                try {
                                    first = u.find("li.first-annotation");
                                    actions = first.find("div.actions");
                                    remove = actions.find("a.remove");
                                    remove.click();
                                } catch (e) {}
                            }
                        });
                    } catch (e) {}
                },
                contentAnnotationsAjax: function() {
                    var urlAction = "contentAnnotationsAjax.action",
                        opts,
                        options;
                    if (showingThread === true) {
                        return false;
                    }

                    opts = {
                        contentRequestId: $("#contentRequestId").val(),
                        programId: $("#program-id").val(),
                        enableDeletion: $("#enable-deletion").val(),
                        lockedBySomebodyElse: $("#commentLocked").val() || false
                    };
                    options = {
                        cache: false,
                        type: "POST",
                        data: opts,
                        dataType: "html",
                        url: urlAction,
                        success: function(data) {
                            // show annotation thread.
                            //console.log("contentAnnotationsAjax just fired...");
                            var annotationModule = $("#annotation-module"),
                                hasActiveClass = annotationModule.hasClass(
                                    "active"
                                );
                              
                            annotationModule.replaceWith(data);
                            sortComments();
                            if (hasActiveClass) {
                                $("#annotation-module").addClass("active");
                                setup.followMenuResize();
                            }
                            return false;
                        },
                        error: function(data, status, err) {
                            console.log([data, status, err]);
                        },
                        complete: function() {
                            $("textarea.annotation").autosize();
                            $("#annotation-module>div").resize(function() {
                                if ($(this).height() < 300) {
                                    setup.followMenuResize();
                                }
                            });
                        }
                    };

                    call.ajax(urlAction, options);
                    return false;
                },

                annotate: function() {
                    var box = mark.closest("div.redactor_box"),
                        markId = mark.attr("data-timestamp"),
                        cted = box.siblings(".contentTemplateElementDataId"),
                        urlAction = "/saveAnnotationAjax.action",
                        opts = {
                            contentRequestId: $("#contentRequestId").val(),
                            programId: $("#program-id").val(),
                            enableDeletion: $("#enable-deletion").val(),
                            contentTemplateElementDataId: cted.val(),
                            annotationText:
                                $.trim(annotationText) +
                                getExternalReviewerName(),
                            highlightedText: $.trim(highlightedText)
                        },
                        options = {
                            cache: false,
                            type: "POST",
                            data: opts,
                            dataType: "html",
                            url: urlAction,
                            success: function(data) {
                                // show annotation thread.
                                var annotation = $(data),
                                    annotationId = annotation
                                        .find("input.parentAnnotationId")
                                        .val(),
                                    mark = $(
                                        "mark[data-timestamp='" + markId + "']"
                                    );
                                mark.attr("data-value", annotationId);
                                $(".redactor").redactor("sync");

                                $("#annotation-module").replaceWith(data);
                                $("#annotation-module > a").click();
                                redactorSave(redactor);
                                if (
                                    redactor.$editor.hasClass(
                                        "redactor_notEditable"
                                    )
                                ) {
                                    redactor.$editor.attr(
                                        "contenteditable",
                                        false
                                    );
                                }
                                $("#noCommentText").hide();
                            },
                            error: function(data, status, err) {
                                console.log([data, status, err]);
                            },
                            complete: function() {
                                $("textarea.annotation").autosize();
                                req = null;
                            }
                        },
                        req = call.ajax(urlAction, options);
                    return false;
                }
            };
        }
    ]);
})();

app.service("account", [
    "skyword",
    "validation",
    "call",
    "setup",
    function(skyword, validation, call, setup) {
        return {
            init: function() {
                //onReady
                $(function() {
                    var html = $("html");
                    //$("#location").autocomplete({
                    //    minLength: 2,
                    //    source: "findLocationsAjax.action",
                    //    select: function (event, ui) {
                    //    }
                    //});

                    html.on("click", ".addTextfield", null, function() {
                        var textfields = $(this).prev(),
                            textfield = textfields
                                .find("li")
                                .first()
                                .clone();
                        textfields
                            .find("li")
                            .last()
                            .after(textfield);
                        textfield.find("input").val("");
                        return false;
                    });

                    html.on("click", "#btn-crop-overlay", null, function() {
                        var formSubmit = $("#formSubmit"),
                            urlAction = formSubmit.attr("action"),
                            getPost = formSubmit.attr("method"),
                            options = {
                                cache: false,
                                type: getPost,
                                data: formSubmit.serialize(),
                                url: urlAction,
                                success: function(response) {
                                    if (!validation.parse(response)) {
                                        $("#profile-picture").replaceWith(
                                            response
                                        );
                                        setup.overlays();
                                        $(this).colorbox.close();
                                    } else {
                                        msg +=
                                            json.errorMsg +
                                            "<br/><br/>" +
                                            $(
                                                "#file-ext-" +
                                                    json.attachmentDefinitionId
                                            )
                                                .val()
                                                .replace(",", ", ") +
                                            "<br/><br/>";
                                        $(this)
                                            .find(".uploadifyError")
                                            .html(msg);
                                    }
                                },
                                error: function(data, status, err) {
                                    var msg =
                                        "Error getting: " +
                                        urlAction +
                                        "\n\nStatus: " +
                                        status +
                                        "\n\n";
                                    // msg += "Parameters: " + JSON.stringify(parameters) + "\n\n";
                                    console.log(msg);
                                }
                            };
                        $("#crop-container").hide();
                        $("#btn-crop-overlay").hide();
                        $("#prepare-container").show();
                        $(this).colorbox.resize();
                        call.ajax(urlAction, options);
                        return false;
                    });

                    html.on("click", "#btn-banner-remove", function() {
                        var urlAction = $(this).attr("href"),
                            options = {
                                cache: false,
                                type: "POST",
                                url: urlAction,
                                success: function(response) {
                                    $("#banner-upload").show();
                                    $(".banner").html("");
                                }
                            };
                        call.ajax(urlAction, options);
                        return false;
                    });

                    html.on("click", "#invite-writer", function() {
                        $("#invite-writer-form").submit();
                        return false;
                    });
                    /*
                     * taxInfo.jsp - sets up the hide / show of the LLC and "Other" elements
                     */
                    function taxStatusChange() {
                        if (
                            $("ul#tax-status-list input#limited").is(":checked")
                        ) {
                            $("#limited-type_chzn").show();
                        } else {
                            $("#limited-type_chzn").hide();
                        }
                        if (
                            $("ul#tax-status-list input#other").is(":checked")
                        ) {
                            $("#other-type").show();
                        } else {
                            $("#other-type").hide();
                        }
                    }

                    $('ul#tax-status-list input[type="radio"]')
                        .on("change", taxStatusChange)
                        .change();

                    $(".profileForm input:text").on("keyup", hideOptOut);
                    $(".profileForm input:checkbox").on("change", hideOptOut);
                    $(".profileForm select").on("change", hideOptOut);

                    skyword.passwordStrength();

                    function hideOptOut() {
                        var show = true,
                            countChecks = 0;
                        $(".profileForm input, .profileForm select").each(
                            function() {
                                var $this = $(this);
                                if (
                                    $this.attr("id") != "optOut" &&
                                    $this.attr("id") != "__checkbox_optOut"
                                ) {
                                    if (
                                        $this.attr("type") == "checkbox" &&
                                        $this.is(":checked")
                                    ) {
                                        $(".optOut").hide();
                                        show = false;
                                        countChecks++;
                                    } else if (
                                        $this.attr("type") != "hidden" &&
                                        $this.attr("type") != "checkbox" &&
                                        $this.val() != "" &&
                                        $this.val() != -1 &&
                                        $this.val() != 0
                                    ) {
                                        $(".optOut").hide();
                                        show = false;
                                    } else {
                                    }
                                }
                            }
                        );
                        if (show) {
                            $(".optOut").show();
                        } else {
                            if ($("#optOut").is(":checked")) {
                                $("#optOut").click();
                            }
                        }
                        if (countChecks > 19) {
                            $(".profileForm input:checkbox").each(function() {
                                var $this = $(this);
                                if (!$this.is(":checked")) {
                                    $this.addClass("disabled");
                                    var label = $this.next();
                                    label
                                        .addClass("disabled")
                                        .attr(
                                            "for",
                                            label.attr("for") + "disabled"
                                        );
                                }
                            });
                        } else {
                            $(".profileForm input:checkbox").removeClass(
                                "disabled"
                            );
                            $(".profileForm label.checkboxLabel").removeClass(
                                "disabled"
                            );
                            $(".profileForm input:checkbox").each(function() {
                                var label = $(this).next();
                                label.attr("for", label.prev().attr("id"));
                            });
                        }
                    }

                    hideOptOut();
                    $("#show-more-social").click(function() {
                        $("#hidden-social-drawer").slideDown();
                        $("#show-more-social").fadeOut();
                        $("#show-less-social").fadeIn();
                        return false;
                    });
                    $("#show-less-social").click(function() {
                        $("#hidden-social-drawer").slideUp();
                        $("#show-less-social").fadeOut();
                        $("#show-more-social").fadeIn();
                        return false;
                    });
                });
            }
        };
    }
]);

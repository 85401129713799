app.directive("exportword", [
    "$timeout",
    "call",
    "$translate",
    "$compile",
    "$rootScope",
    "exportFile",
    function($timeout, call, $translate, $compile, $rootScope, exportFile) {
        let actionName = jQuery("#attrs-id").attr("data-actionname");
        let template =
            actionName === "search"
                ? `<div class="position-relative d-inline ml-2">
                        <a id="downloadBtn" class="secManagerReports secOwnerReports ng-binding" style="padding: 4px 10px;" onclick="$('#downloadDD').toggle()">
                            <span class="icon-FileImport" id="downloadIcon" style="font-size: 15px;"></span>
                            Download
                        </a>
                        <ul id="downloadDD" class="position-absolute float-left bg-white py-2 text-dark text-left"
                        style="top: -205%;
                            right: -5px;
                            z-index: 1000;
                            padding: 0.5rem 0;
                            margin: 0 0 0 0.125rem;
                            font-size: 1.5rem;
                            list-style: none;
                            background-clip: padding-box;
                            border: 1px solid rgba(0, 0, 0, 0.15);
                            border-radius: 0.25rem;
                            width:max-content;
                            display: none;">

                            <li style="cursor:pointer; clear:both;" onclick="$('#downloadDD').toggle()">
                                <a
                                    style="padding:3px 5px;"
                                    ng-if="(state != 5) && (state != 11) && (state != 23)" ng-click="getFileExport(e, 'docx')" class="qtipTooltip " data-at="right center" data-my="left center" data-width="300px" title='{{"angular.exportword.draft" | translate}}'>
                                    <span class="icon-MediaWord"></span>{{"angular.exportword.draft" | translate}}
                                </a>
                                <a
                                    style="padding:3px 5px;"
                                    ng-if="(state == 5) || (state == 11) || (state == 23)" ng-click="getFileExport(e, 'docx')" class=" qtipTooltip " data-at="right center" data-my="left center" data-width="300px" title='{{"angular.exportword.final" | translate}}'>
                                    <span class="icon-MediaWord"></span>{{"angular.exportword.final" | translate}}
                                </a>
                            </li>
                            <li style="cursor:pointer; clear:both;" onclick="$('#downloadDD').toggle()">
                                <a
                                    style="padding:3px 5px;"
                                    ng-if="(state != 5) && (state != 11) && (state != 23)" ng-click="getFileExport(e, 'pdf')" class="qtipTooltip " data-at="right center" data-my="left center" data-width="300px" title='{{"angular.exportpdf.draft" | translate}}'>
                                    <span class="icon-MediaPDF"></span>{{"angular.exportpdf.draft" | translate}}
                                </a>
                                <a
                                    style="padding:3px 5px;"
                                    ng-if="(state == 5) || (state == 11) || (state == 23)" ng-click="getFileExport(e, 'pdf')" class=" qtipTooltip " data-at="right center" data-my="left center" data-width="300px" title='{{"angular.exportpdf.final" | translate}}'>
                                    <span class="icon-MediaPDF"></span>{{"angular.exportpdf.final" | translate}}
                                </a>
                            </li>
                        </ul>
                    </div>`
                : `<div class="position-relative d-inline">
                        <a id="downloadBtn" class="qtipTooltip clearNavigationLock icon-FileImport" style="font-size: 20px;" onclick="$('#downloadDD').toggle()"
                            data-placement="bottom" title='{{"angular.searchresult.detailmodal.download" | translate}}'>
                            <span class="qtipTooltip" style="height:20px">
                            </span>
                        </a>
                        <ul id="downloadDD" class="position-absolute float-left bg-white py-2 text-dark text-left"
                        style="top: 100%;
                            right: -40px;
                            z-index: 1000;
                            padding: 0.5rem 0;
                            margin: 0 0 0 0.125rem;
                            font-size: 1.5rem;
                            list-style: none;
                            background-clip: padding-box;
                            border: 1px solid rgba(0, 0, 0, 0.15);
                            border-radius: 0.25rem;
                            width:max-content;
                            display: none;">

                            <li style="cursor:pointer; clear:both;" onclick="$('#downloadDD').toggle()">
                                <a
                                    style="padding:3px 5px;"
                                    ng-if="(state != 5) && (state != 11) && (state != 23)" ng-click="getFileExport(e, 'docx')" class="qtipTooltip " data-at="right center" data-my="left center" data-width="300px" title='{{"angular.exportword.draft" | translate}}'>
                                    <span class="icon-MediaWord"></span>{{"angular.exportword.draft" | translate}}
                                </a>
                                <a
                                    style="padding:3px 5px;"
                                    ng-if="(state == 5) || (state == 11) || (state == 23)" ng-click="getFileExport(e, 'docx')" class=" qtipTooltip " data-at="right center" data-my="left center" data-width="300px" title='{{"angular.exportword.final" | translate}}'>
                                    <span class="icon-MediaWord"></span>{{"angular.exportword.final" | translate}}
                                </a>
                            </li>
                            <li style="cursor:pointer; clear:both;" onclick="$('#downloadDD').toggle()">
                                <a
                                    style="padding:3px 5px;"
                                    ng-if="(state != 5) && (state != 11) && (state != 23)" ng-click="getFileExport(e, 'pdf')" class="qtipTooltip " data-at="right center" data-my="left center" data-width="300px" title='{{"angular.exportpdf.draft" | translate}}'>
                                    <span class="icon-MediaPDF"></span>{{"angular.exportpdf.draft" | translate}}
                                </a>
                                <a
                                    style="padding:3px 5px;"
                                    ng-if="(state == 5) || (state == 11) || (state == 23)" ng-click="getFileExport(e, 'pdf')" class=" qtipTooltip " data-at="right center" data-my="left center" data-width="300px" title='{{"angular.exportpdf.final" | translate}}'>
                                    <span class="icon-MediaPDF"></span>{{"angular.exportpdf.final" | translate}}
                                </a>
                            </li>
                        </ul>
                    </div>`;
        template =
            template +
            `<div ng-if="downloadError" class="progressMsgWarning"><span class="icon-MessageWarning"></span>{{"angular.exportword.downloadError" | translate}}</div>
         <div ng-if="buildingExport" class="progressMsg"><img src="/styles/images/ajax-loader.gif"/>{{"angular.exportword.buildingExport" | translate}}</div>`;

        return {
            restrict: "EA",
            replace: false,
            template: template,
            scope: {
                ngModel: "="
            },
            link: function(scope, element, attrs) {
                scope.state = attrs.state;
                scope.contentId = attrs.contentId;
                scope.downloadError = false;
                scope.buildingExport = false;
                scope.data = "";
                exportFile.checkClickOutside();
                scope.getFileExport = function(event, docType) {
                    scope.buildingExport = true;
                    exportFile
                        .getFileExport(scope.contentId, docType)
                        .success(function(data, status, headers) {
                            scope.data = data;
                            scope.buildingExport = false;
                            var filenameMatch = headers('X-Suggested-Filename');
                            var filename = filenameMatch ? filenameMatch : "";
                            var encodedUri = encodeURI("/API/export/" + scope.contentId + "?fileType=" + docType);
                            var link = document.createElement("a");
                            link.setAttribute("href", encodedUri);
                            link.setAttribute("download", filename);
                            document.body.appendChild(link);
                            link.click();
//                            window.location.href =
//                                "/API/export/" +
//                                scope.contentId +
//                                "?fileType=" +
//                                docType;
                        })
                        .error(function(error) {
                            scope.buildingExport = false;
                            scope.downloadError = true;
                        });
                };
            }
        };
    }
]);

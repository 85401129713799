app.service("author", [
    "skyword",
    "setup",
    "validationUtil",
    "call",
    "redactor",
    "container",
    "photoLibraryUrl",
    "assignment",
    "formBuilder",
    "AssetFactory",
    function(
        skyword,
        setup,
        validationUtil,
        call,
        redactor,
        container,
        photoLibraryUrl,
        assignment,
        formBuilder,
        AssetFactory
    ) {
        var articleResponseData;
        var articleIndex = 0;
        var disableNext = function() {
                console.log(
                    "hasScrollBar " + $("#help-contents").hasScrollBar()
                );
            },
            colorActivityFeed = function() {
                $(".recentActivity .content").each(function() {
                    $(this).html(
                        $(this)
                            .html()
                            .replace(
                                " accepted",
                                "<span class='greenText'> accepted</span>"
                            )
                    );
                    $(this).html(
                        $(this)
                            .html()
                            .replace(
                                " approved",
                                "<span class='greenText'> approved</span>"
                            )
                    );
                    $(this).html(
                        $(this)
                            .html()
                            .replace(
                                " proposed",
                                "<span class='greenText'> proposed</span>"
                            )
                    );
                    $(this).html(
                        $(this)
                            .html()
                            .replace(
                                " claimed",
                                "<span class='greenText'> claimed</span>"
                            )
                    );
                    $(this).html(
                        $(this)
                            .html()
                            .replace(
                                " declined",
                                "<span class='redText'> declined</span>"
                            )
                    );
                    $(this).html(
                        $(this)
                            .html()
                            .replace(
                                " expired",
                                "<span class='redText'> expired</span>"
                            )
                    );
                    $(this).html(
                        $(this)
                            .html()
                            .replace(
                                " removed",
                                "<span class='redText'> removed</span>"
                            )
                    );
                    $(this).html(
                        $(this)
                            .html()
                            .replace(
                                " reinstated",
                                "<span class='greenText'> reinstated</span>"
                            )
                    );
                    $(this).html(
                        $(this)
                            .html()
                            .replace(
                                " submitted",
                                "<span class='greenText'> submitted</span>"
                            )
                    );
                    $(this).html(
                        $(this)
                            .html()
                            .replace(
                                " paid",
                                "<span class='greenText'> paid</span>"
                            )
                    );
                    $(this).html(
                        $(this)
                            .html()
                            .replace(
                                " performance earning",
                                "<span class='greenText'> performance earning</span>"
                            )
                    );
                    $(this).html(
                        $(this)
                            .html()
                            .replace(
                                " bonus earning",
                                "<span class='greenText'> bonus earning</span>"
                            )
                    );
                });
            },
            setupSimilarTopicsDefaults = function() {
                $("#similar-topics-content").similarTopics({
                    categorySelect: $("#enteredCategoryId"),
                    contentRequestId: null,
                    contentTypeId: $("#currentContentTypeId").val(),
                    loading: $("#similar-topics-loading").val(),
                    button: null,
                    ignoreCategory: true
                });
            },
            similarTopicsCheck = function() {
                var doCheck = false;

                $(".includeForSummary").each(function() {
                    var el = $(this);
                    if ("" != el.val()) {
                        doCheck = true;
                    }
                });
                $(".redactor_includeForSummary").each(function() {
                    var el = $(this);
                    if ("" != el.val()) {
                        doCheck = true;
                    }
                });

                if (
                    doCheck &&
                    -1 !== parseInt($("#selectedCategoryId").val(), 10)
                ) {
                    try {
                        $("#similar-topics-content").similarTopics.check();
                    } catch (e) {}
                }
            },
            termsCheckCallback = function() {
                console.log("TEST");
            },
            programGuidelinesCallback = function() {
                container.init();

                setTimeout(" $(this).colorbox.resize(); ", 150);
                var categoryId = $("#category-id").val(),
                    articleId = $("#article-id").val();
                $(".loadAjax").click(function() {
                    $(".loadAjax")
                        .parent()
                        .removeClass("current");
                    $(this)
                        .parent()
                        .addClass("current");
                });
                $("#help-sidebar .accTrigger").click(function() {
                    var categoryToOpen = $(this).next();
                    if (!categoryToOpen.is(":visible")) {
                        categoryToOpen.slideDown();
                        if ($("#browse").val() == "true") {
                            categoryToOpen
                                .find(".loadAjax")
                                .first()
                                .click();
                        }
                        $(this)
                            .find(".buttonBox span")
                            .removeClass("icon-Expand")
                            .addClass("icon-Collapse");
                    } else {
                        categoryToOpen.slideUp();
                        $(this)
                            .find(".buttonBox span")
                            .removeClass("icon-Collapse")
                            .addClass("icon-Expand");
                    }
                    return false;
                });
                $(".sw-modal").on("click", "#begin-button", null, function() {
                    $(this).hide();
                    $("#next-button").show();
                });

                $(".sw-modal").on(
                    "click",
                    "#next-button, #begin-button",
                    null,
                    function() {
                        var urlAction = "programGuidelinesArticleAjax.action",
                            options = {
                                data: $("#formSubmit").serialize(),
                                cache: false,
                                type: "post",
                                url: urlAction,
                                success: function(text) {
                                    $("#help-contents")
                                        .html(text)
                                        .removeClass("disabled");
                                    var curArticleId = $("#article-id").val();
                                    $(".current")
                                        .find("a")
                                        .show();
                                    $(".current")
                                        .find("span")
                                        .hide();
                                    $(".links").removeClass("current");
                                    $("#article-" + curArticleId)
                                        .addClass("current")
                                        .find("a")
                                        .show();
                                    $("#article-" + curArticleId)
                                        .find("span")
                                        .hide();
                                    programGuidelinesArticleCallback();
                                }
                            };
                        $("#help-contents")
                            .html(" ")
                            .addClass("disabled");
                        call.skyjax(urlAction, options);
                        return false;
                    }
                );
                $("html").on("click", "#help-complete", null, function() {
                    var urlAction = "updateCompletedAjax.action",
                        options = {
                            data: $("#formSubmit").serialize(),
                            cache: false,
                            type: "get",
                            url: urlAction,
                            success: function(text) {
                                window.location = "/writeArticles.action";
                            }
                        };
                    call.ajax(urlAction, options);
                    return false;
                });
            },
            getUnorderedListLength = function(element, offset) {
                var length = 0;
                element.find("ul").each(function() {
                    length += $(this).find("li").length;
                });
                length += offset;
                return length;
            },
            programGuidelinesArticleCallback = function() {
                setTimeout(" $(this).colorbox.resize(); ", 150);
                var curArticleId = $("#article-id").val();
                var foundNext = $("#foundNext").val();
                var curPage = $("#curPage").val();
                // Offset by 2 list items to account for the "Welcome!" page prepended to the guidelines as well as another +1 for the "Conclusion" page appended to the list.
                var programGuidelinesLength = getUnorderedListLength(
                    $("#help-sidebar"),
                    2
                );
                if (
                    foundNext == "false" &&
                    curPage >= programGuidelinesLength
                ) {
                    $("#next-button").hide();
                    $("#completion").css({ display: "inline-block" });
                } else {
                    $("#next-button").show();
                    $("#completion").hide();
                }
                setTimeout(function() {
                    disableNext();
                }, 20);
            };

        getContentStandardChecklistGuidelines = function(categoryId) {
            articleResponseData = [];
            $("#backButton").show();
            $("#programReferenceModuleDiv").hide();
            $("#contentGuidelinesChecklistDiv").hide();
            $("#next").attr("articleId", "");
            $("#prev").attr("articleId", "");
            enableDisablePrevNextButton(articleIndex);
            var urlAction =
                    "programReferenceJsonAjax.action?categoryId=" + categoryId,
                options = {
                    data: {},
                    cache: false,
                    type: "get",
                    url: urlAction,
                    dataType: "json",
                    success: function(jsonResponse) {
                        let id = -1;
                        if (
                            jsonResponse["categories"] &&
                            jsonResponse["categories"][0]
                        ) {
                            articleResponseData = jsonResponse["categories"][0];
                        }
                        if (articleResponseData) {
                            if (articleResponseData["articles"]) {
                                articleResponseData["articles"].forEach((article, indx) => {
                                  if (article && article["id"]) {
                                      id = article["id"];
                                      if (id !== -1 && id === categoryId) {
                                          setGuidelinesResponse(id);
                                          articleIndex = indx
                                          // Set the next article to article + 1 if it exists
                                          if (
                                              articleResponseData["articles"]
                                                  .length > 0 &&
                                              articleResponseData["articles"][articleIndex + 1]
                                          ) {
                                              $("#next").attr(
                                                  "articleId",
                                                  articleResponseData["articles"][
                                                      articleIndex + 1
                                                  ]["id"]
                                              );
                                              $("#nextPrevButtons").show();
                                              $("#next").css("display", "block");
                                          }
                                          if (
                                              articleResponseData["articles"]
                                                  .length > 0 &&
                                              articleIndex - 1 >= 0 &&
                                              articleResponseData["articles"][articleIndex - 1]
                                          ) {
                                              $("#prev").attr(
                                                  "articleId",
                                                  articleResponseData["articles"][
                                                      articleIndex - 1
                                                  ]["id"]
                                              );
                                              $("#nextPrevButtons").show();
                                              $("#prev").css("display", "block");
                                          }
                                          enableDisablePrevNextButton(
                                              articleIndex
                                          );
                                      }
                                    }
                                })
                            }
                        }
                    },
                    complete: function(req, response, error) {}
                };
            call.ajax(urlAction, options);
        };

        getCompetitorDomains = function() {
            let programId = $("#attrs-id").data("programid");
            var url =
                "competitorDomainReferenceJsonAjax.action?programId=" +
                programId;
            options = {
                data: {},
                cache: false,
                url: url,
                dataType: "json",
                success: function(response) {
                    let html = `<h2>${response.headerVal}</h2>`;
                    if (response.competitorDomains.length > 0) {
                        html += `<span class="text12 bold clear">${
                            response.instructionVal
                        }</span>
                            <div class="marginTop10 marginBottom10">
                                <ul class="borderLightGrey">`;
                        for (
                            let i = 0;
                            i < response.competitorDomains.length;
                            i++
                        ) {
                            if (i === response.competitorDomains.length - 1) {
                                html +=
                                    `<li class="padding5 paddingLeft10">` +
                                    response.competitorDomains[i].domain +
                                    "</li>";
                            } else {
                                html +=
                                    `<li class="padding5 paddingLeft10" style="border-bottom: 1px solid #dfdfdf;">` +
                                    response.competitorDomains[i].domain +
                                    "</li>";
                            }
                        }
                        html += `</ul></div>`;
                    } else {
                        html += `<p>${response.noCompetitorDomains}</p>`;
                    }
                    $("#backButton").show();
                    $("#programReferenceModuleDiv").hide();
                    $("#contentGuidelinesChecklistDiv").hide();
                    $("#help-guideline-contents").html(html);
                    $("#help-guideline-contents").show();
                },
                complete: function(req, response, error) {}
            };
            call.ajax(url, options);
        };

        openContentChecklistTab = function() {
            getCompetitorDomains();
            setTimeout(() => {
                $("#content-checklist-module .tab").click();
            }, 1000);
        };

        getProgramGuidelines = function(categoryId) {
            $("#backButton").show();
            $("#programReferenceModuleDiv").hide();
            $("#contentGuidelinesChecklistDiv").hide();
            $("#next").attr("articleId", "");
            $("#prev").attr("articleId", "");
            articleIndex = 0;
            articleResponseData = [];
            enableDisablePrevNextButton(articleIndex);
            var urlAction =
                    "programReferenceJsonAjax.action?categoryId=" + categoryId,
                options = {
                    data: {},
                    cache: false,
                    type: "get",
                    url: urlAction,
                    dataType: "json",
                    success: function(jsonResponse) {
                        let id = -1;
                        jsonResponse["categories"].forEach((res) => {
                            if (res.id === categoryId) {
                                articleResponseData = res;
                            }
                        });
                        console.log(articleResponseData);
                        if (articleResponseData) {
                            if (articleResponseData["articles"]) {
                                let article =
                                    articleResponseData["articles"][0];
                                if (article && article["id"]) {
                                    id = article["id"];
                                    if (id !== -1) {
                                        setGuidelinesResponse(id);
                                        if (
                                            articleResponseData["articles"]
                                                .length > 1
                                        ) {
                                            $("#next").attr(
                                                "articleId",
                                                articleResponseData["articles"][
                                                    articleIndex + 1
                                                ]["id"]
                                            );
                                            $("#nextPrevButtons").show();
                                            $("#next").css("display", "block");
                                            enableDisablePrevNextButton(
                                                articleIndex
                                            );
                                        }
                                    }
                                }
                            }
                        }
                    },
                    complete: function(req, response, error) {}
                };
            call.ajax(urlAction, options);
        };

        backToContentChecklistGuideLines = function() {
            $("#programReferenceModuleDiv").show();
            $("#contentGuidelinesChecklistDiv").show();
            $("#help-guideline-contents").hide();
            $("#help-guideline-contents").html("");
            $("#backButton").hide();
            $("#nextPrevButtons").hide();
            articleIndex = 0;
            articleResponseData = [];
        };

        enableDisablePrevNextButton = function(articleIndex) {
            let totalHelpArticles = 0;
            if (articleResponseData["articles"]) {
                totalHelpArticles = articleResponseData["articles"].length;
            }
            if (articleIndex >= 1) {
                // enable prev button
                $("#prev").attr("disabled", false);
            } else {
                $("#prev").attr("disabled", true);
            }
            if (articleIndex + 1 < totalHelpArticles) {
                $("#next").attr("disabled", false);
            } else {
                $("#next").attr("disabled", true);
            }
        };
        nextArticleGuideLines = function() {
            articleIndex++;
            $("#prev").css("display", "block");
            $("#prev").attr(
                "articleId",
                articleResponseData["articles"][articleIndex - 1]["id"]
            );
            $("#next").attr(
                "articleId",
                articleResponseData["articles"][articleIndex]?.id
            );
            setGuidelinesResponse($("#next").attr("articleId"));
            enableDisablePrevNextButton(articleIndex);
        };

        prevArticleGuideLines = function() {
            $("#next").attr(
                "articleId",
                articleResponseData["articles"][articleIndex]["id"]
            );
            $("#prev").attr(
                "articleId",
                articleResponseData["articles"][articleIndex - 1]["id"]
            );
            articleIndex--;
            $("#next").css("display", "block");
            setGuidelinesResponse($("#prev").attr("articleId"));
            enableDisablePrevNextButton(articleIndex);
        };
        setGuidelinesResponse = function(articleId) {
            var urlAction =
                    "programReferenceArticleAjax.action?articleId=" + articleId,
                options = {
                    data: {},
                    cache: false,
                    type: "get",
                    url: urlAction,
                    dataType: "html",
                    success: function(html) {
                        $("#help-guideline-contents").html(html);
                        $("#help-guideline-contents").show();
                    },
                    complete: function() {}
                };
            call.ajax(urlAction, options);
        };

        return {
            termsCheckCallback: function() {
                termsCheckCallback();
            },
            programGuidelinesCallback: function() {
                programGuidelinesCallback();
            },
            programGuidelinesArticleCallback: function() {
                programGuidelinesArticleCallback();
            },
            programReferenceCallback: function() {
                container.init();
                var categoryId = $("#category-id").val(),
                    articleId = $("#article-id").val();
                $(".loadAjax").click(function() {
                    $(".loadAjax")
                        .parent()
                        .removeClass("current");
                    $(this)
                        .parent()
                        .addClass("current");
                });
                $("#help-sidebar .accTrigger").click(function() {
                    var categoryToOpen = $(this).next();
                    if (!categoryToOpen.is(":visible")) {
                        categoryToOpen
                            .slideDown()
                            .find(".loadAjax")
                            .first()
                            .click();
                        $(this)
                            .find(".buttonBox span")
                            .removeClass("icon-Expand")
                            .addClass("icon-Collapse");
                    } else {
                        categoryToOpen.slideUp();
                        $(this)
                            .find(".buttonBox span")
                            .removeClass("icon-Collapse")
                            .addClass("icon-Expand");
                    }
                    return false;
                });

                if (categoryId != "") {
                    //Open link to first article in selected category, and open category accordion of that category
                    $("#help-sidebar")
                        .find("#" + categoryId)
                        .show()
                        .find(".loadAjax")
                        .first()
                        .click();
                } else if (articleId != "") {
                    //Open link to given article, and open category accordion of that article's category
                    $("#help-sidebar")
                        .find("#article-" + articleId)
                        .click()
                        .parent()
                        .parent()
                        .show();
                }
            },
            disableContributorType: function(contributorType) {
                var urlAction =
                    "disableContributorTypeAjax.action?contributorType=" +
                    contributorType;
                return call.overlay(urlAction, null, 850, null, "GET");
            },

            init: function() {
                var simTops = null;

                //onReady
                $(function() {
                    if ($("#similar-topics-content").length > 0) {
                        $("#similar-topics-content").similarTopics.check();
                    }

                    var body = $("body");

                    body.on(
                        "blur",
                        ".requiredForOpen",
                        skyword.enableDisableButton
                    );
                    body.on(
                        "keyup",
                        ".requiredForOpen",
                        skyword.enableDisableButton
                    );
                    body.on(
                        "change",
                        ".requiredForOpen",
                        skyword.enableDisableButton
                    );
                    body.on(
                        "keyup",
                        "textarea.imposeMaxLength",
                        skyword.imposeMaximumLength
                    );
                    body.on(
                        "change",
                        "select.requiredForOpen",
                        skyword.enableDisableButton
                    );
                    body.on(
                        "change",
                        "#programCategoryId",
                        assignment.keywordAutocomplete
                    );
                    setupSimilarTopicsDefaults();

                    $("#tasAgree.errorField")
                        .parent()
                        .css("border-color", "#e52e2e");

                    /*
                     * publish.jsp
                     */
                    $(".articleTitleElement, .addToBody.required").focus(
                        function() {
                            // skywordMce.hideSubmitButton();
                            $("#seoScore").removeAttr("disabled");
                        }
                    );

                    $("#photoLibraryButton").click(function() {
                        window.open(
                            photoLibraryUrl,
                            "PopUp",
                            "width=525,height=950,toolbar=no,resizable=yes,scrollbars=yes,top=20,left=20"
                        );
                        return false;
                    });

                    colorActivityFeed();

                    if (
                        ($("#showSEOScore").val() != "false" ||
                            $("#enableSubmitButton").val() == "false") &&
                        0 < $("button#seoScore").length
                    ) {
                        $("#pubSubmitBtn").addClass("disabled");
                    }

                    $("#attachment-name-id").live("blur", function() {
                        var art = $(this).val();
                        // TODO: remove ^ and test
                        art = art.replace(/[^A-Za-z0-9-_.]/gi, "-");
                        $(this).val(art);
                    });

                    $("#validArticles .odd, #validArticles .even").hover(
                        function() {
                            $(this)
                                .next()
                                .css("background-color", "#efefef");
                        },
                        function() {
                            $(this)
                                .next()
                                .css("background-color", "white");
                        }
                    );

                    $("#acceptInvite").click(function() {
                        window.location.href =
                            "acceptInvite.action?id=" +
                            $("#programAccountId").val();
                    });

                    $("#declineInvite").click(function() {
                        var urlAction =
                            "rejectInviteAjax.action?progAcctId=" +
                            $("#programAccountId").val();
                        return call.overlay(urlAction, null, 850, null);
                    });

                    $("#releaseBtn").click(function() {
                        jQuery.colorbox.close();
                        window.location.reload(true);
                    });

                    $("#add_written").click(function() {
                        $("#search-form-head").hide();
                        $("#article-search").show();
                        return false;
                    });
                    $("#add_video").click(function() {
                        $("#search-form-head").hide();
                        $("#video-search").show();
                        return false;
                    });

                    //writeArticles.jsp
                    body.on(
                        "click",
                        "#page:not(.writeIdea) a.changeContentType",
                        null,
                        function() {
                            var a = $(this),
                                li = a.parent(),
                                id = a.attr("id"),
                                options,
                                urlAction,
                                pointer;
                            $("#similar-topics-content").remove();

                            $("a.changeContentType").each(function() {
                                var t = $(this),
                                    pli = t.parent(),
                                    span = pli.find("span.pointer");
                                if (0 < span.length) {
                                    pointer = span.clone();
                                    span.remove();
                                }
                                pli.removeClass("active");
                                pli.addClass("notActive");
                            });
                            li.removeClass("notActive")
                                .addClass("active")
                                .append(pointer);
                            urlAction =
                                "/changeArticleContentTypeAjax.action?currentContentTypeId=" +
                                id;
                            if (
                                1 === jQuery("#contributor-write-idea").length
                            ) {
                                urlAction += "&createIdea=true";
                            }
                            options = {
                                data: {},
                                cache: false,
                                type: "get",
                                url: urlAction,
                                dataType: "html",
                                success: function(html) {
                                    $("#claim-article").html(html);
                                    formBuilder.render();
                                },
                                complete: function() {
                                    var st = $("#claim-article").find(
                                        "div#similar-topics-module"
                                    );
                                    if (0 < st.length) {
                                        setupSimilarTopicsDefaults();
                                    }
                                    skyword.matchColumns();
                                    setup.styledDropdowns();
                                    // redactor.setupRedactorElements();
                                }
                            };
                            call.ajax(urlAction, options);

                            return false;
                        }
                    );

                    body.on("click", "#enteredButton", function() {
                        // TODO: Uncomment out the following for SimilarTopics
                        if (undefined !== simTops || null !== simTops) {
                            $("#simTopicIds").val(simTops);
                        }

                        var topicForm = $("#newTopicForm"),
                            urlAction = "/submitAssignmentAjax.action",
                            getPost = topicForm.attr("method"),
                            options,
                            button = $(this);
                        //button.addClass("disabled").attr("disabled", "disabled");
                        var formData = topicForm.serialize();

                        if (1 === jQuery("#contributor-write-idea").length) {
                            formData += "&createIdea=true";
                            formData +=
                                "&ideaAssetIds=" + AssetFactory.getAssetList();
                            formData +=
                                "&personaContentUUID=" +
                                $("#personaContentUUID").data(
                                    "personacontentuuid"
                                );
                        }
                        options = {
                            cache: false,
                            type: getPost,
                            data: formData,
                            url: urlAction,
                            success: function(data) {
                                if (
                                    window.location.pathname !==
                                    "/writeIdea.action"
                                ) {
                                    $(".changeContentType.active").click();
                                    try {
                                        if (validationUtil.parse(data)) {
                                            $("#programCategoryId")
                                                .val(-1)
                                                .trigger("liszt:updated");
                                            $(
                                                "input.articleTitle, input.assignmentKeyword, input.assignmentElement, textarea.assignmentElement, .attachmentHiddenId"
                                            ).each(function(k, v) {
                                                var t = $(this),
                                                    ac = t.siblings(
                                                        ".attachmentContainer"
                                                    ),
                                                    a = null,
                                                    fu = null;
                                                t.val("");
                                                if (
                                                    t.hasClass(
                                                        "attachmentHiddenId"
                                                    )
                                                ) {
                                                    a = ac.find(
                                                        ">div.attachment"
                                                    );
                                                    fu = ac.find(
                                                        ">div.fileUploadSpan"
                                                    );
                                                    a.hide();
                                                    fu.show();
                                                }
                                            });
                                        }
                                    } catch (e) {}
                                } else {
                                    window.open(
                                        window.location.href.split("#")[0],
                                        "_self"
                                    );
                                }
                            }
                        };
                        call.ajax(urlAction, options);
                        return false;
                    });

                    if (
                        window.location.pathname !== "/publish.action" &&
                        window.location.pathname !== "/managerPublish.action"
                    ) {
                        //$('body').on('blur', '.includeForSummary', function() {
                        //    similarTopicsCheck();
                        //    return false;
                        //});
                        $("body").on(
                            "keyup",
                            ".includeForSummary",
                            skyword.delay(function() {
                                similarTopicsCheck();
                                return false;
                            }, 1000)
                        );
                        $("body").on(
                            "keyup",
                            ".redactor_includeForSummary",
                            skyword.delay(function() {
                                similarTopicsCheck();
                                return false;
                            }, 1000)
                        );
                    }

                    /*
                     *  joinPrograms.jsp
                     */
                    $(".joinOverlay").height($(".joinGridBox").height());
                    if ($(".joinGridBox").resize()) {
                        $(".joinOverlay").height($(".joinGridBox").height());
                    }
                    $("#joinBox-button").click(function() {
                        window.location.href = "myProfile.action";
                    });
                    if (window.location.pathname == "/authorDashboard.action") {
                        //MAGIC NUMBERS HO: 107 is the calculated extras not present on glowbackground that are on the gridbox. - mike
                        $(".glowBackground").height(
                            $(".joinGridBox").height() - 107
                        );
                        $(".joinGridBox").resize(function() {
                            $(".glowBackground").height(
                                $(".joinGridBox").height() - 107
                            );
                        });
                    }

                    $("#tabs-row li").each(function(i, el) {
                        $(el).click(function() {
                            var id = $(this).attr("id");
                            $(".panels").fadeOut("fast");
                            if ("channelView" === id) {
                                //alert(id)
                                $("#channelView").addClass("active");
                                $("#calendarView").removeClass("active");
                                $("#channelView-panel").show();
                                $("#calendarView-panel").hide();
                            } else if ("calendarView" === id) {
                                $("#channelView").removeClass("active");
                                $("#calendarView").addClass("active");
                                $("#channelView-panel").hide();
                                $("#calendarView-panel").show();
                            }
                        });
                    });
                });
            }
        };
    }
]);

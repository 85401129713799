app.service("assetLibrary", [
    "$http",
    function($http) {
        function getCampaignResources(campaignId, enterpriseId, callback) {
            $http({
                url: "/API/asset/campaigns/" + enterpriseId + "/" + campaignId,
                method: "GET"
            })
                .success(function(data, status, headers, config) {
                    callback(data);
                })
                .error(function(data, status, headers, config) {});
        }
        function getProjectResources(projectId, callback) {
            $http({
                url: "/API/asset/projects/" + projectId,
                method: "GET"
            })
                .success(function(data, status, headers, config) {
                    callback(data);
                })
                .error(function(data, status, headers, config) {});
        }

        return {
            getCampaignResources: getCampaignResources,
            getProjectResources: getProjectResources,
            getEventAssets: function(calendarEventId, programId, callback) {
                if (
                    null === calendarEventId ||
                    -1 === parseInt(calendarEventId, 10)
                ) {
                    return;
                }
                $http({
                    url:
                        "/API/program/" +
                        programId +
                        "/event-asset/" +
                        calendarEventId,
                    method: "GET"
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {});
            },
            getAssets: function(contentRequestId, callback) {
                $http({
                    url: "/API/asset/" + contentRequestId,
                    method: "GET"
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {});
            },
            getAssetsNoId: function(assetIdList, callback) {
                $http({
                    url: "/API/asset/list?ids=" + assetIdList,
                    method: "GET"
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {});
            },
            save: function(asset, callback) {
                $http({
                    url: "/API/asset/update/" + asset.id,
                    method: "POST",
                    data: {
                        id: asset.id,
                        assetTitle: asset.assetTitle,
                        userDescription: asset.userDescription,
                        assetUrl: asset.assetUrl
                    },
                    cache: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            toggleEnableResourceSharing: function(objectType, objectId, enableResourceSharing, callback) {
                $http({
                    url: "/API/asset/enableSharedResources/" + objectType + "/" + objectId ,
                    method: "PUT",
                    data: new Boolean(enableResourceSharing),
                    cache: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            remove: function(asset, callback) {
                $http({
                    url: "/API/asset/remove/" + asset.id,
                    method: "DELETE",
                    cache: false,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            scrape: function(url, contentRequestId, programId, callback) {
                $http({
                    url: "/API/asset/scrape",
                    method: "POST",
                    data: {
                        assetUrl: url,
                        assetTitle: url,
                        userDescription: url,
                        contentRequestId: contentRequestId,
                        programId: programId
                    },
                    cache: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            scrapeCampaign: function(url, campaignId, enterpriseId, callback) {
                $http({
                    url: "/API/asset/scrape",
                    method: "POST",
                    data: {
                        assetUrl: url,
                        assetTitle: url,
                        userDescription: url,
                        campaignId: campaignId,
                        enterpriseId: enterpriseId
                    },
                    cache: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            scrapeProject: function(url, projectId, programId, callback) {
                $http({
                    url: "/API/asset/scrape",
                    method: "POST",
                    data: {
                        assetUrl: url,
                        assetTitle: url,
                        userDescription: url,
                        projectId: projectId,
                        programId: programId
                    },
                    cache: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            scrapeEvent: function(url, calendarEventId, programId, callback) {
                if (null === calendarEventId) {
                    calendarEventId = -1;
                }
                $http({
                    url:
                        "/API/program/" +
                        programId +
                        "/event-asset/event-scrape/" +
                        calendarEventId,
                    method: "POST",
                    data: {
                        assetUrl: url,
                        assetTitle: url,
                        userDescription: url
                    },
                    cache: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            damEnabled: damEnabled,
            reuse: reuse
        };

        function damEnabled(programId) {
            return $http.get("/API/program/" + programId + "/dam");
        }

        function reuse(dto) {
            return $http.put("/API/asset/reuse", dto);
        }
    }
]);

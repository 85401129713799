(function() {
    app.service("publish", [
        "skyword",
        "validationUtil",
        "author",
        "stockPhoto",
        "common",
        "amplify",
        "assignment",
        "geolocation",
        "contentTemplate",
        "call",
        "setup",
        "requestActive",
        "TinymceService",
        "CampaignService",
        function(
            skyword,
            validationUtil,
            author,
            stockPhoto,
            common,
            amplify,
            assignment,
            geolocation,
            contentTemplate,
            call,
            setup,
            requestActive,
            TinymceService,
            CampaignService
        ) {
            var actionName = null;
            if (document.getElementById("attrs-id")) {
                actionName = document
                    .getElementById("attrs-id")
                    .getAttribute("data-actionName");
            }

            var scoreArticle = function scoreArticle() {
                    var body = "",
                        theTitle = $.trim($("textarea.redactorTitle").val()),
                        nonBodyText = "",
                        options,
                        urlAction,
                        submitData,
                        geo = $("button.geoLocation");

                    $("#score-drawer")
                        .slideUp()
                        .html("");

                    if (requestActive) {
                        return false;
                    }

                    try {
                        if (0 < geo.length) {
                            skylocation.buildGeoLocation();
                            geo.click();
                        }
                    } catch (e) {}

                    $(".atb-true").each(function() {
                        var atbText =
                            "TEXTAREA" === this.nodeName
                                ? $(this).val()
                                : $(this).html();
                        body = body + "\n" + atbText;
                    });

                    $(".atb-false").each(function() {
                        var nonAtbText =
                            "TEXTAREA" === this.nodeName
                                ? $(this).val()
                                : $(this).html();
                        nonBodyText = nonBodyText + "\n" + nonAtbText;
                    });

                    tinyMCE.triggerSave();

                    // serialize the form to a string
                    var mainForm = $("#articleFormMain").serialize();

                    // find the postBody{n}, add the text and replace the postbody
                    var postDivList = document.getElementsByName("postBody");
                    for (i = 0; i < postDivList.length; i++) {
                        var childList = postDivList[i].parentElement.children;
                        for (j = 0; j < childList.length; j++) {
                            childItem = childList[j];
                            if (
                                childItem.className.includes(
                                    "tinyMceInliteTextarea"
                                )
                            ) {
                                var tmpValue =
                                    "&postBody=" +
                                    encodeURIComponent(childItem.innerText);
                                mainForm = mainForm + tmpValue;
                            }
                        }
                    }

                    // NOTE: body encoding required in case there are escaped html tags in there. An ampersand in the middle
                    // of text doesn't play well in a url. duh.
                    submitData =
                        mainForm +
                        "&" +
                        $("#articleFormMeta").serialize() +
                        "&nonBodyText=" +
                        encodeURIComponent(nonBodyText) +
                        "&title=" +
                        encodeURIComponent(theTitle) +
                        "&body=" +
                        encodeURIComponent(body) +
                        ($("body").hasClass("manager-publish")
                            ? "&submitType=manager-publish"
                            : "");
                    app.value("requestActive", true);
                    urlAction = "/seoScore.action";
                    options = {
                        cache: false,
                        data: submitData,
                        dataType: "html",
                        type: "post",
                        url: urlAction,
                        success: function(data) {
                            var requirements = $(data).filter(
                                    "#article-requirements"
                                ),
                                seo = $(data).filter("#article-seo"),
                                suggestions = $(data).filter(
                                    "#article-suggestions"
                                ),
                                readability = $(data).filter(
                                    "#article-readability"
                                ),
                                curActive = $(".active").attr("id"),
                                messengerJson = $(data).filter(
                                    ".messengerJson"
                                ),
                                messengerJsonString = "";

                            $("#loader-module")
                                .hide()
                                .find(".saveDraftText")
                                .show();
                            $(".sidebarBox").removeClass("whiteout");

                            if (0 < messengerJson.length) {
                                messengerJsonString = messengerJson.text();
                            }
                            validationUtil.parse(messengerJsonString);
                            if (
                                $("html")
                                    .find("#editDigitalAsset")
                                    .val() == "true"
                            ) {
                                if (
                                    messengerJsonString == null ||
                                    messengerJsonString == ""
                                ) {
                                    if ($("#managerEdit").val() == "true") {
                                        recordManagerEdit();
                                    }
                                    $(
                                        ".redactor_toolbar .redactor_btn_html.redactor_act"
                                    ).click();
                                    $(this).focus();
                                    contentTemplate.setupSave(); // Double-checks datePickers
                                    saveDraft(false, true);
                                } else {
                                    skyword.scrollTo("#error-box", 100);
                                }
                            }

                            $("#article-requirements").replaceWith(
                                requirements
                            );
                            $("#article-seo").replaceWith(seo);
                            $("#article-suggestions").replaceWith(suggestions);
                            $("#article-readability").replaceWith(readability);
                            $("#" + curActive).addClass("active");
                            $("#article-requirements .tab").click();
                        },
                        error: function(message) {
                            console.log(
                                "Error in running evaluation: " + message
                            );
                            $("#loader-module")
                                .hide()
                                .find(".saveDraftText")
                                .show();
                            $(".sidebarBox").removeClass("whiteout");
                        },
                        complete: function() {
                            app.value("requestActive", false);

                            if (
                                "/mgrPublish.action" ===
                                window.location.pathname
                            ) {
                                if (
                                    "true" ==
                                    $("#enableArticleReviewPublishButton").val()
                                ) {
                                    $(
                                        "#manager-submit-action .btnaction, " +
                                            "#manager-submit-action .btnaction a, " +
                                            "#manager-submit-action .btn, " +
                                            "#manager-submit-action .orangeBtn a, " +
                                            "#manager-submit-action .dropdownMenu a"
                                    )
                                        .removeClass("disabled")
                                        .removeAttr("disabled");

                                    if (
                                        "false" ==
                                        $("#enableSubmitButton").val()
                                    ) {
                                        $(
                                            "#manager-submit-action .orangeBtn a#11, #manager-submit-action .dropdownMenu a#11"
                                        )
                                            .addClass("disabled")
                                            .attr("disabled", "disabled");
                                    } else {
                                        $(
                                            "#manager-submit-action .orangeBtn a#11, #manager-submit-action .dropdownMenu a#11"
                                        )
                                            .removeClass("disabled")
                                            .removeAttr("disabled");
                                    }
                                } else {
                                    $(
                                        "#manager-submit-action .btnaction a, " +
                                            "#manager-submit-action .btnaction,  " +
                                            "#manager-submit-action .btn, " +
                                            "#manager-submit-action .orangeBtn a, " +
                                            "#manager-submit-action .dropdownMenu a"
                                    )
                                        .addClass("disabled")
                                        .attr("disabled", "disabled");
                                }
                            } else {
                                if (
                                    $("#enableSubmitButton").val() == "false" &&
                                    $("#disReq").val() == "false"
                                ) {
                                    $("#pubSubmitBtn")
                                        .addClass("disabled")
                                        .attr("disabled", "disabled");
                                    $(
                                        "#manager-submit-action .dropdown-toggle, " +
                                            "#manager-submit-action .btn, " +
                                            "#manager-submit-action a"
                                    )
                                        .addClass("disabled")
                                        .attr("disabled", "disabled");
                                } else {
                                    if (
                                        $("#seoScore").hasClass(
                                            "skipEvaluation"
                                        )
                                    ) {
                                        $("#pubSubmitBtn").click();
                                    } else {
                                        $("#pubSubmitBtn")
                                            .removeClass("disabled")
                                            .removeAttr("disabled");
                                        $(
                                            "#manager-submit-action .dropdown-toggle, " +
                                                "#manager-submit-action .btn, " +
                                                "#manager-submit-action a"
                                        )
                                            .removeClass("disabled")
                                            .removeAttr("disabled");
                                    }
                                }
                            }
                        }
                    };
                    call.ajax(urlAction, options);
                },
                reloadArticleHistory = function reloadArticleHistory() {
                    var crId = $("#contentRequestId").val(),
                        options = {
                            type: "GET",
                            url: "/articleHistory.action",
                            dataType: "html",
                            data: {
                                contentRequestId: crId
                            },
                            success: function(data) {
                                var filterHtml = $(data).find(".historyData");
                                $(".historyData").replaceWith(data);
                                skyword.showTimeFrame();
                            },
                            error: function(data) {
                                app.value("requestActive", false);
                            }
                        };
                    call.ajax("/articleHistory.action", options);
                },
                suppressBackspace = function suppressBackspace(evt) {
                    evt = evt || window.event;
                    var target = evt.target || evt.srcElement;
                    if (
                        evt.keyCode == 13 &&
                        ($(target).hasClass("tinyMceInliteTextarea") ||
                            $(target).hasClass("tinyMceInlite"))
                    ) {
                        console.log("bad enter bad!");
                        return false;
                    }
                },
                autoRecovery = function autoRecovery() {
                    var options,
                        contentRequestId,
                        suppressAutoRecovery = $("#suppressAutoRecovery").val(),
                        programId = parseInt($("#program-id").val(), 10);

                    if (
                        155 === programId ||
                        (null !== suppressAutoRecovery &&
                            suppressAutoRecovery == "true")
                    ) {
                        return false;
                    }

                    $(".postContentPreview").addClass("hidden");

                    contentRequestId = $("#contentRequestId").val();
                    options = {
                        cache: false,
                        type: "GET",
                        success: function(data) {}
                    };
                    call.ajax(
                        "autoRecoveryAjax.action?contentRequestId=" +
                            contentRequestId,
                        options
                    );
                },
                restoreDraft = function restoreDraft(type) {
                    var referrerContentPackage = $("#referrerContentPackage").val();
                    var referrerPage = $("#referrerPage").val();
                    var referrerName = $("#referrerName").val();
                    var options,
                        contentRequestId = $("#contentRequestId").val(),
                        referrerAction = $("#referrerAction").val(),
                        urlAction =
                            "autoRecoveryAjax.action?contentRequestId=" +
                            contentRequestId +
                            "&useSaved=false";
                    options = {
                        cache: false,
                        type: "POST",
                        success: function(message) {
                            if ("managerPublish" === type) {
                                window.location = "managerWriteArticles.action";
                            } else if ("managerEdit" === type) {
                                if("contentPackage" === referrerAction) {
                                    window.location =
                                        "articleReview.action?contentRequestId=" +
                                        contentRequestId +
                                        "&referrerAction=" +
                                        referrerAction +"&referrerContentPackage="+referrerContentPackage;
                                } else if((referrerPage !== undefined && referrerPage!== "" && referrerPage.includes("projectDetails.action")) || referrerName === "Project"){
                                    var programId = $("#program-id").val();
                                    window.location =
                                        "articleReview.action?programId="+programId+"&contentRequestId=" +
                                        contentRequestId +
                                        "&referrerpage="+referrerPage+"&referrerName=Project";
                                } else {
                                    window.location =
                                        "articleReview.action?contentRequestId=" +
                                        contentRequestId +
                                        "&referrerAction=" +
                                        referrerAction;
                                }
                            } else if ("mgrPublish" === type) {
                                window.location = "managerDashboard.action";
                            } else if ("managerPublishAsset" === type) {
                                window.location = "uploadDigitalAsset.action";
                            } else {
                                window.location =
                                    "viewProgram.action?programId=" +
                                    $("#program-id").val();
                            }
                        },
                        error: function(message) {
                            console.log(
                                "Error encountered in attempting to set recover article option: " +
                                    message
                            );
                        }
                    };
                    call.ajax(urlAction, options);
                    return false;
                },
                saveDraftBegin = function saveDraftBegin() {
                    $("#loader-module").show();
                    $(".sidebarBox").addClass("whiteout");
                },
                saveDraftFinish = function saveDraftFinish() {
                    $("#loader-module")
                        .find(".icon")
                        .addClass("success");
                    $("#loader-module")
                        .delay(3000)
                        .fadeOut("slow", function() {
                            $(this)
                                .find(".icon")
                                .removeClass("success");
                            $(".sidebarBox").removeClass("whiteout");
                        });
                },
                saveDraft = function saveDraft(hideMessage, isManager) {
                    var referrerContentPackage = $("#referrerContentPackage").val();
                    var referrerPage = $("#referrerPage").val();
                    var opts,
                        options,
                        urlAction = "saveDraftAjax.action",
                        hideMsg = hideMessage,
                        referrerAction = $("#referrerAction").val();
                    saveDraftBegin();
                    var creativeBrief;
                    if ($("#creative-brief").length > 0) {
                        creativeBrief = angular
                            .element($("#creative-brief"))
                            .scope();
                        creativeBrief.save();
                    }

                    tinyMCE.triggerSave();

                    // serialize the form to a string
                    var mainForm = $("#articleFormMain").serialize();

                    // find the postBody{n}, add the text and replace the postbody
                    var postDivList = document.getElementsByName("postBody");
                    for (i = 0; i < postDivList.length; i++) {
                        var childList = postDivList[i].parentElement.children;
                        for (j = 0; j < childList.length; j++) {
                            childItem = childList[j];
                            if (
                                childItem.className.includes(
                                    "tinyMceInliteTextarea"
                                )
                            ) {
                                var tmpValue =
                                    "&postBody=" +
                                    encodeURIComponent(childItem.innerText);
                                mainForm = mainForm + tmpValue;
                            }
                        }
                    }

                    try {
                        contentTemplate.setupSave();
                        var programId = $("#program-id").val();
                        var referrerPage = $("#referrerPage").val();
                        var referrerName = $("#referrerName").val();
                        opts = {
                            contentRequestId: $("#contentRequestId").val(),
                            contentTemplateId: $("#content-template-id").val()
                        };

                        options = {
                            cache: false,
                            type: "POST",
                            data:
                                mainForm +
                                "&" +
                                $("#articleFormMeta").serialize(),
                            dataType: "html",
                            url: urlAction,
                            success: function(json) {
                                if (!validationUtil.parse(json)) {
                                    $(".sidebarBox").replaceWith(json);
                                    $("#loader-module").hide();
                                } else {
                                    var updateMessage = json.message,
                                        err = json.error,
                                        errMsg = json.errorMessage,
                                        warn = json.warning,
                                        warnMsg = json.warningMessage;
                                    if (true === err) {
                                        updateMessage =
                                            '<span style="color:red;font-weight:bold;">' +
                                            errMsg +
                                            "</span>";
                                    }
                                    if (true !== hideMsg) {
                                        saveDraftFinish();
                                    }

                                    if (true === isManager && true !== warn) {
                                        console.log($("#referrerAction").val());

                                        if (
                                            "assetCreate" ===
                                            $("#referrerAction").val()
                                        ) {
                                            window.location.href =
                                                "/managerCreateDigitalAssets.action";
                                        } else if (
                                            "searchDigitalAssets" ===
                                            $("#referrerAction").val()
                                        ) {
                                            window.location.href =
                                                "/searchDigitalAssets.action";
                                        } else if("contentPackage" === $("#referrerAction").val()) {
                                            window.location =
                                                "articleReview.action?contentRequestId=" + $("#contentRequestId").val() +
                                                "&referrerAction=" + referrerAction + "&referrerContentPackage="+referrerContentPackage;
                                        } else if((referrerPage !== undefined && referrerPage!== "" && $("#referrerPage").val().includes("projectDetails.action")) || referrerName === "Project") {
                                            window.location = "articleReview.action?programId="+programId+"&contentRequestId=" +
                                                $("#contentRequestId").val()  +
                                                "&referrerPage="+referrerPage+"&referrerName=Project";
                                        }else {
                                            window.location.href =
                                                "/articleReview.action?managerEdit=true&suppressAutoRecovery=true&contentRequestId=" +
                                                $("#contentRequestId").val() +
                                                "&referrerAction=" +
                                                referrerAction;
                                        }
                                    }
                                }
                                reloadArticleHistory();
                            },
                            error: function(data) {
                                $(".sidebarBox").replaceWith(data.responseText);
                                $("#loader-module").hide();
                            }
                        };

                        call.ajax(urlAction, options);
                    } catch (e) {
                        console.log(e);
                        $("#status-message")
                            .html(
                                "NO ContentId exists. No way to save content or create snapshot"
                            )
                            .fadeIn("fast", function() {
                                $(this)
                                    .delay(3000)
                                    .fadeOut("slow");
                            });
                    }
                },
                saveWriterComment = function saveWriterComment() {
                    var urlAction = "saveWriterCommentAjax.action",
                        opts = {
                            contentId: $("#contentId").val(),
                            contentRequestId: $("#contentRequestId").val(),
                            writerComment: $("#writerComment").val()
                        },
                        options = {
                            cache: false,
                            type: "POST",
                            data: opts,
                            dataType: "json",
                            url: urlAction
                        };
                    call.ajax(urlAction, options);
                },
                recordManagerEdit = function recordManagerEdit() {
                    var urlAction = "recordManagerEditAjax.action",
                        opts = {
                            contentRequestId: $("#contentRequestId").val(),
                            programCategoryId: $("#programCategoryId").val()
                        },
                        options = {
                            cache: false,
                            type: "POST",
                            data: opts,
                            dataType: "json",
                            url: urlAction
                        };
                    call.ajax(urlAction, options);
                },
                setupPublishDateView = function setupPublishDateView() {
                    var publishDate = skyword.toBool(
                            $(
                                "input[name=enablePublishDate]:checked",
                                "#articleFormMain"
                            ).val()
                        ),
                        publish = $("#publish-date");
                    if (true === publishDate) {
                        publish.show();
                    } else {
                        publish.hide();
                    }
                },
                updatePublishDate = function publishUpdatePublishDate() {
                    var enablePublish = $("#use-publish-date"),
                        urlAction = "/updatePublishDateAjax.action",
                        publishDate = skyword.toBool(enablePublish.val()),
                        publish = $("#publishDeadlineString"),
                        options = {
                            cache: false,
                            type: "POST",
                            data: {
                                contentRequestId: $("#contentRequestId").val(),
                                publishDeadlineString: $(
                                    "#publishDeadlineString"
                                ).val(),
                                enablePublishDate: publishDate
                            },
                            url: urlAction,
                            dataType: "json",
                            success: function(data) {
                                //visual validation is not necessary here since most of the time it will show up with an error initially since you haven't filled anything in
                                validationUtil.parse(data);
                            }
                        };
                    // console.trace();
                    call.ajax(urlAction, options);
                    return true;
                },
                requestState = function requestState() {
                    var isValid = true,
                        button = $("#controlsDiv button");

                    if (0 < $("#declinedReasonId").length) {
                        if (-1 === parseInt($("#declinedReasonId").val(), 10)) {
                            isValid = false;
                        }
                    }

                    $(".revisionComments").each(function() {
                        var ta = $(this);
                        if ("" === ta.val().trim()) {
                            isValid = false;
                        }
                    });

                    $(".hasDatepicker").each(function() {
                        var ta = $(this);
                        if (ta) {
                            if (
                                "" === ta.val().trim() &&
                                ta.hasClass("required")
                            ) {
                                isValid = false;
                            }
                        }
                    });
                    var resubmitDeadlineString = $("#resubmitDeadlineString");
                    var now = new Date(parseInt($("#now-time").val(), 10));
                    var resubmissionDate =
                        0 < resubmitDeadlineString.length
                            ? new Date(resubmitDeadlineString.val())
                            : now;
                    if (resubmissionDate < now) {
                        isValid = false;
                    }
                    if (isValid) {
                        button.removeClass("disabled").removeAttr("disabled");
                    } else {
                        button
                            .addClass("disabled")
                            .attr("disabled", "disabled");
                    }
                },
                skywordPublish = function skywordPublish() {
                    var newstateString = "",
                        urlAction,
                        options,
                        comments = "",
                        extRevIdString = "";

                    mPubUrl = "managerPublishAjax.action";

                    if (
                        "/digitalAssetPublish.action" ===
                        window.location.pathname
                    ) {
                        mPubUrl = "managerDigitalPublishAjax.action";
                    }

                    urlAction = $("body").hasClass("manager-publish")
                        ? mPubUrl
                        : "mgrPublishAjax.action";
                    if ($("body").hasClass("mgr-publish")) {
                        newstateString =
                            "&newStateId=" + $("#newStateId").val();
                    }
                    if ($("#newStateId").val() == "41") {
                        extRevIdString =
                            "&externalReviewerId=" +
                            $("#externalReviewerId").val();
                    }
                    if (0 < $("#comments").length) {
                        comments = "&writerComment=" + $("#comments").val();
                    }

                    tinyMCE.triggerSave();

                    // serialize the form to a string
                    var mainForm = $("#articleFormMain").serialize();

                    // find the postBody{n}, add the text and replace the postbody
                    var postDivList = document.getElementsByName("postBody");
                    for (i = 0; i < postDivList.length; i++) {
                        var childList = postDivList[i].parentElement.children;
                        for (j = 0; j < childList.length; j++) {
                            childItem = childList[j];
                            if (
                                childItem.className.includes(
                                    "tinyMceInliteTextarea"
                                )
                            ) {
                                var tmpValue =
                                    "&postBody=" +
                                    encodeURIComponent(childItem.innerText);
                                mainForm = mainForm + tmpValue;
                            }
                        }
                    }

                    options = {
                        cache: false,
                        dataType: "html",
                        data:
                            mainForm +
                            "&" +
                            $("#articleFormMeta").serialize() +
                            newstateString +
                            comments +
                            extRevIdString,
                        type: "POST",
                        url: urlAction,
                        success: function(data) {
                            $("#confirm-submit-box").removeClass("whiteout");
                            $("#loader-module").hide();
                            if (!validationUtil.parse(data)) {
                                $("#inline-confirm-box")
                                    .html(data)
                                    .slideDown("slow", function() {
                                        setup.followMenuResize();
                                        setup.styledDropdowns();
                                    });
                            } else {
                                $("#inline-confirm-box").slideUp();
                                $(".sidebarBox").slideDown();
                            }
                        }
                    };
                    $("#loader-module")
                        .show()
                        .find(".saveDraftText")
                        .hide();
                    $("#confirm-submit-box").addClass("whiteout");
                    call.ajax(urlAction, options);
                },
                setupSimilarTopicsDefaults = function setupSimilarTopicsDefaults() {
                    try {
                        if (
                            "function" ===
                            typeof $("#similar-topics-content").similarTopics
                        ) {
                            $("#similar-topics-content").similarTopics({
                                categorySelect:
                                    0 === $("#selectedCategoryId")
                                        ? $("#programCategoryId")
                                        : $("#selectedCategoryId"),
                                contentRequestId:
                                    0 === $("#contentRequestId")
                                        ? null
                                        : $("#contentRequestId").val(),
                                contentTypeId: $("#content-type-id").val(),
                                loading: $("#similar-topics-loading").val(),
                                button: $("#enteredButton"),
                                ignoreCategory: true
                            });
                        }
                    } catch (e) {
                        console.log(e);
                    }
                },
                similarTopicsCheck = function similarTopicsCheck() {
                    var doCheck = false;

                    $(".includeForSummary").each(function() {
                        var el = $(this);
                        if ("" != el.val()) {
                            doCheck = true;
                        }
                    });

                    $(".redactor_includeForSummary").each(function() {
                        var el = $(this);
                        if ("" != el.val()) {
                            doCheck = true;
                        }
                    });

                    if (doCheck) {
                        $("#similar-topics-content").similarTopics.check();
                    }
                },
                setupPublishControls = function setupPublishControls() {
                    var reqEnabled = $("#req-enabled").val(),
                        seoEnabled = $("#seo-scorecard-enabled").val(),
                        suggestionEnabled = $("#suggestions-enabled").val(),
                        readabilityEnabled = $("#readability-enabled").val();
                    //console.log(reqEnabled);
                    if (reqEnabled) {
                        //evaulate displayed and Submit button enabled when passing req, otherwise disabled
                        contentTemplate.hideSubmitButton();
                    } else if (
                        seoEnabled ||
                        suggestionEnabled ||
                        readabilityEnabled
                    ) {
                        //evaluate displayed and submit always enabled
                    } else {
                        //evaluate hidden and submit always enabled
                        $("#seoScore").hide();
                    }
                },
                disableEnterKey = function disableEnterKey(e) {
                    var key;
                    // windows uses keyCode, firefox which
                    key = window.event ? window.event.keyCode : e.which;
                    return 13 !== key;
                };

            function saveCampaignValues() {
                var contentRequestId = document
                    .getElementById("attrs-id")
                    .getAttribute("data-contentrequestid");
                var el = $(this);
                var inputValue = el.val();
                var campaignDTO = {
                    campaignIdList: $.isArray(inputValue)
                        ? inputValue
                        : [inputValue]
                };
                CampaignService.saveCampaignList(contentRequestId, campaignDTO);
            }

            return {
                init: function() {
                    author.init();
                    common.init();
                    amplify.init();
                    assignment.init();
                    contentTemplate.init();
                    geolocation.init();
                    stockPhoto.init();
                    skyword.showTimeFrame();

                    TinymceService.setupStockPhotoEmbed();

                    //onReady
                    $(function() {
                        var html = $("html");
                        var body = $("body");

                        body.on(
                            "change",
                            "select.enterpriseCampaignList",
                            null,
                            saveCampaignValues
                        );

                        similarTopicsCheck();
                        autoRecovery();
                        setupPublishDateView();
                        document.onkeydown = suppressBackspace;
                        $("#seoScore").click(function() {
                            $(
                                ".redactor_toolbar .redactor_btn_html.redactor_act"
                            ).click();
                            contentTemplate.setupSave();
                            app.value("requestActive", false);
                            setTimeout(function() {
                                scoreArticle();
                            }, 600);
                            $("#articleTextarea_AtD").hide();
                            $("#loader-module")
                                .show()
                                .find(".saveDraftText")
                                .hide();
                            $(".sidebarBox").addClass("whiteout");
                            return false;
                        });

                        //mgr save
                        $("#btn-save-article").click(function() {
                            var currentStateId = $("#crStateId").val();
                            if (
                                html.find("#editDigitalAsset").val() ==
                                    "true" &&
                                currentStateId != 57
                            ) {
                                scoreArticle();
                            } else {
                                if (
                                    $("#managerEdit").val() == "true" &&
                                    window.location.href.includes("articleEdit")
                                ) {
                                    recordManagerEdit();
                                }
                                $(
                                    ".redactor_toolbar .redactor_btn_html.redactor_act"
                                ).click();
                                $(this).focus();
                                contentTemplate.setupSave(); // Double-checks datePickers
                                saveDraft(false, true);
                            }
                        });

                        $("#btn-manager-cancel").click(function() {
                            $(this).focus();
                            if (
                                "digitalAssetPublish" ==
                                $("#attrs-id").attr("data-actionname")
                            ) {
                                restoreDraft("managerPublishAsset");
                            } else {
                                restoreDraft("managerPublish");
                            }
                            return false;
                        });

                        $("#btn-writer-cancel").click(function() {
                            var t = $(this),
                                action = t.hasClass("manager")
                                    ? "mgrPublish"
                                    : "publish";
                            t.focus();
                            restoreDraft(action);
                            return false;
                        });
                        html.on("click", "#cboxOverlay", function() {
                            window.localStorage.removeItem("uploadUsing");
                            window.localStorage.removeItem("uploadUsingId");
                            $("#discard-attachment").click();
                        });

                        $("#btn-save-draft").click(function() {
                            $(
                                ".redactor_toolbar .redactor_btn_html.redactor_act"
                            ).click();
                            $(this).focus();
                            saveDraft(false, false);
                        });

                        //writer save
                        $("#pubSubmitBtn").click(function() {
                            tinyMCE.triggerSave();

                            // serialize the form to a string
                            var mainForm = $("#articleFormMain").serialize();

                            // find the postBody{n}, add the text and replace the postbody
                            var postDivList = document.getElementsByName(
                                "postBody"
                            );
                            for (i = 0; i < postDivList.length; i++) {
                                var childList =
                                    postDivList[i].parentElement.children;
                                for (j = 0; j < childList.length; j++) {
                                    childItem = childList[j];
                                    if (
                                        childItem.className.includes(
                                            "tinyMceInliteTextarea"
                                        )
                                    ) {
                                        var tmpValue =
                                            "&postBody=" +
                                            encodeURIComponent(
                                                childItem.innerText
                                            );
                                        mainForm = mainForm + tmpValue;
                                    }
                                }
                            }

                            var btn = $(this),
                                comments =
                                    0 < $("#comments").length
                                        ? "&writerComment=" +
                                          $("#comments").val()
                                        : "",
                                urlAction = btn.hasClass("manager")
                                    ? "mgrPublishAjax.action"
                                    : "publishAjax.action",
                                options = {
                                    cache: false,
                                    dataType: "html",
                                    data:
                                        mainForm +
                                        "&" +
                                        $("#articleFormMeta").serialize() +
                                        comments,
                                    type: "POST",
                                    url: urlAction,
                                    success: function(data) {
                                        if (!validationUtil.parse(data)) {
                                            var content = $(data).filter(
                                                ".publishCompleteInfo"
                                            );

                                            if (content.length == 1) {
                                                //leave this as innerHtml for IE issue
                                                document.getElementById(
                                                    "main"
                                                ).innerHTML = content.html();
                                            } else {
                                                $(".sidebarBox").replaceWith(
                                                    data
                                                );
                                            }
                                        } else {
                                            $("#inline-confirm-box").slideUp();
                                            $(".sidebarBox").slideDown();
                                        }
                                    }
                                };
                            call.ajax(urlAction, options);
                        });

                        body.on(
                            "keypress",
                            "textarea.revisionComments",
                            null,
                            requestState
                        );
                        body.on(
                            "focus",
                            "textarea.revisionComments",
                            null,
                            requestState
                        );
                        body.on(
                            "blur",
                            "textarea.revisionComments",
                            null,
                            requestState
                        );
                        body.on(
                            "change",
                            "textarea.revisionComments",
                            null,
                            requestState
                        );

                        body.on(
                            "focus",
                            "input.hasDatepicker",
                            null,
                            requestState
                        );
                        body.on(
                            "blur",
                            "input.hasDatepicker",
                            null,
                            requestState
                        );
                        body.on(
                            "change",
                            "input.hasDatepicker",
                            null,
                            requestState
                        );
                        body.on("keydown", "input.hasDatepicker", function(e) {
                            e.preventDefault();
                        });

                        body.on(
                            "keypress",
                            "#resubmitDeadline-on select",
                            null,
                            requestState
                        );
                        body.on(
                            "focus",
                            "#resubmitDeadline-on select",
                            null,
                            requestState
                        );
                        body.on(
                            "blur",
                            "#resubmitDeadline-on select",
                            null,
                            requestState
                        );
                        body.on(
                            "change",
                            "#resubmitDeadline-on select",
                            null,
                            requestState
                        );
                        body.on(
                            "change",
                            "#declinedReasonId",
                            null,
                            requestState
                        );

                        $("#manager-submit-action.btnGroup a").click(function(
                            event
                        ) {
                            var thisButton = this,
                                id = $(thisButton).attr("id"),
                                crId = $("#contentRequestId").val(),
                                urlAction =
                                    "confirmArticleSubmitAjax.action?contentRequestId=" +
                                    crId +
                                    "&newStateId=" +
                                    id,
                                options;
                            if ($(this).hasClass("disabled")) {
                                return false;
                            } else {
                                $("#loader-module")
                                    .show()
                                    .find(".saveDraftText")
                                    .hide();
                                $(".sidebarBox").addClass("whiteout");
                                options = {
                                    cache: false,
                                    dataType: "html",
                                    type: "get",
                                    url: urlAction,
                                    success: function(data) {
                                        $("#loader-module").hide();
                                        $(".sidebarBox").removeClass(
                                            "whiteout"
                                        );
                                        $("#inline-confirm-box").html(data);
                                        $("#inline-confirm-box").slideDown(
                                            "fast",
                                            function() {
                                                $(".sidebarBox").slideUp(
                                                    "fast",
                                                    function() {
                                                        setup.followMenuResize();
                                                        setup.styledDropdowns();
                                                    }
                                                );
                                            }
                                        );
                                        $("#hiddenNewStateId").val(id);
                                        html.undelegate(
                                            "#update-status-box button",
                                            "click"
                                        );
                                        html.on(
                                            "click",
                                            "#confirm-submit-box button",
                                            null,
                                            skywordPublish
                                        );
                                    }
                                };
                                call.ajax(urlAction, options);
                            }
                            return false;
                        });

                        html.on(
                            "click",
                            "#inline-confirm-close",
                            null,
                            function() {
                                skyword.enableButtons();
                                $(".sidebarBox").slideDown("fast", function() {
                                    $("#confirm-submit-box").slideUp(
                                        "fast",
                                        function() {
                                            setup.followMenuResize();
                                            setup.styledDropdowns();
                                        }
                                    );
                                });
                                return false;
                            }
                        );

                        html.on(
                            "click",
                            "#review-cancel-link",
                            null,
                            function() {
                                $(this).focus();
                                restoreDraft("managerEdit");
                                return false;
                            }
                        );

                        html.on(
                            "click",
                            "#create-cancel-link",
                            null,
                            function() {
                                $(this).focus();
                                restoreDraft("managerPublish");
                                return false;
                            }
                        );

                        html.on(
                            "click",
                            "#create-writer-cancel-link",
                            null,
                            function() {
                                action = $(this).hasClass("manager")
                                    ? "mgrPublish"
                                    : "publish";
                                $(this).focus();
                                restoreDraft(action);
                                return false;
                            }
                        );

                        $("#writerComment").blur(function() {
                            saveWriterComment();
                        });

                        body.on(
                            "click",
                            "#discard-attachment",
                            null,
                            function() {
                                var urlAction = $(this).attr("href"),
                                    options = {
                                        type: "POST",
                                        url: urlAction,
                                        success: function(data) {
                                            contentTemplate.removeAttachmentOnPage();
                                        }
                                    };
                                call.ajax(urlAction, options);
                                return false;
                            }
                        );
                        body.on("click", "#discard-image", null, function() {
                            var urlAction = $(this).attr("href"),
                                options;
                            options = {
                                type: "POST",
                                url: urlAction,
                                success: function(data) {
                                    // jQuery.colorbox.close();
                                }
                            };
                            call.ajax(urlAction, options);
                            return false;
                        });

                        body.on(
                            "change",
                            "#selectedProjectId",
                            null,
                            function() {
                                var urlAction = "/updateProjectAjax.action",
                                    options;
                                options = {
                                    cache: false,
                                    type: "POST",
                                    data: {
                                        contentRequestId: $(
                                            "#contentRequestId"
                                        ).val(),
                                        selectedProjectId: $(
                                            "#selectedProjectId"
                                        ).val()
                                    },
                                    url: urlAction,
                                    dataType: "json",
                                    success: function(data) {
                                        validationUtil.parse(data);
                                    }
                                };
                                call.ajax(urlAction, options);
                                return false;
                            }
                        );

                        body.on(
                            "change",
                            "#selectedCalendarEventId",
                            null,
                            function() {
                                var urlAction =
                                        "/updateCalendarEventAjax.action",
                                    options;
                                options = {
                                    cache: false,
                                    type: "POST",
                                    data: {
                                        contentRequestId: $(
                                            "#contentRequestId"
                                        ).val(),
                                        selectedCalendarEventId: $(
                                            "#selectedCalendarEventId"
                                        ).val()
                                    },
                                    url: urlAction,
                                    dataType: "json",
                                    success: function(data) {
                                        validationUtil.parse(data);
                                    }
                                };
                                call.ajax(urlAction, options);
                                return false;
                            }
                        );

                        if (actionName && "editArticle" !== actionName) {
                            console.log("Action Name : " + actionName);
                            html.on(
                                "change",
                                "#publishDeadlineString",
                                function htmlChangePublishDeadlineString() {
                                    updatePublishDate();
                                }
                            );
                        }

                        $("div.useModule > a#btn-remove-publish").click(
                            function() {
                                $("#use-publish-date").val(false);
                                updatePublishDate();
                            }
                        );
                        $("div.useModule > a#btn-set-submit").click(function() {
                            $("#use-publish-date").val(true);
                        });
                        $("div.noModule > a#btn-set-submit").click(function() {
                            $("#use-publish-date").val(true);
                            updatePublishDate();
                        });

                        $("#btn-remove-seo, #btn-set-seo").click(function() {
                            var urlAction = "/updateSkipSeoAjax.action",
                                skip = $(this),
                                li = skip.parent().parent(),
                                liKeyword = li.siblings(".liKeyword"),
                                options = {
                                    cache: false,
                                    type: "POST",
                                    data: {
                                        contentRequestId: $(
                                            "#contentRequestId"
                                        ).val(),
                                        skipSEO:
                                            "btn-remove-seo" === skip.attr("id")
                                    },
                                    url: urlAction,
                                    dataType: "json",
                                    success: function(data) {
                                        validationUtil.parse(data);
                                    },
                                    complete: function() {
                                        if (
                                            window.location.pathname.indexOf(
                                                "editArticle"
                                            ) > -1
                                        ) {
                                            window.location.reload();
                                        }
                                        if (
                                            "btn-remove-seo" === skip.attr("id")
                                        ) {
                                            liKeyword.fadeOut(
                                                "fast",
                                                function() {}
                                            );
                                        } else {
                                            liKeyword.fadeIn(
                                                "fast",
                                                function() {}
                                            );
                                        }
                                    }
                                };
                            call.ajax(urlAction, options);
                            return true;
                        });

                        /*
                     * Article Entity Detection
                     */

                        //On Load: if any of these values exist, set them and make the detection modules active
                        if (
                            $(
                                "#namedEntitiesCount,#articleFormMain_namedEntitiesCount"
                            ).val() > 0
                        ) {
                            $(".articleEntityModule.named").addClass(
                                "entitiesFound"
                            );
                            $(".articleEntityModule.named label").addClass(
                                "open"
                            );
                            $(
                                ".entityContainer.named, .entityContainer.namedLinks"
                            ).slideToggle();
                            $(
                                ".articleEntityModule.named .moduleControlsBox .toggle"
                            )
                                .removeClass("icon-Expand")
                                .addClass("icon-Collapse");
                            $("#entityCountId").text(
                                $(
                                    "#namedEntitiesCount,#articleFormMain_namedEntitiesCount"
                                ).val()
                            );
                            $(".articleEntityModule.named a.checkEntity").text(
                                $("#entityRecheck").val()
                            );
                        }

                        if (
                            $(
                                "#stockEntitiesCount, #articleForm_stockEntitiesCount"
                            ).val() > 0
                        ) {
                            $(".entityContainer.stocks").slideToggle();
                            $(".recommendationsModule").addClass("open");
                        }

                        //Button controls box
                        body.on(
                            "click",
                            ".articleEntityModule.entitiesFound",
                            function() {
                                $(this)
                                    .find(".toggle")
                                    .click();
                            }
                        );

                        body.on(
                            "click",
                            ".articleEntityModule.entitiesFound .moduleControlsBox .toggle",
                            function() {
                                $(this)
                                    .parent()
                                    .parent()
                                    .parent()
                                    .siblings(".entityContainer")
                                    .slideToggle();
                                $(this).toggleClass("icon-Expand");
                                $(this).toggleClass("icon-Collapse");
                                $(this)
                                    .parent()
                                    .parent()
                                    .toggleClass("open");
                                return false;
                            }
                        );

                        $(
                            "a.checkEntity.clickable, a.getRecommendations.clickable"
                        ).click(contentTemplate.getRecommendations);

                        if (
                            !(
                                window.location.pathname ===
                                    "/publish.action" ||
                                window.location.pathname ===
                                    "/mgrPublish.action" ||
                                window.location.pathname ===
                                    "/assignmentModify.action"
                            )
                        ) {
                            setupSimilarTopicsDefaults();

                            body.on(
                                "blur",
                                ".includeForSummary",
                                null,
                                function() {
                                    similarTopicsCheck();
                                    return false;
                                }
                            );

                            body.on(
                                "blur",
                                ".redactor_includeForSummary",
                                null,
                                function() {
                                    similarTopicsCheck();
                                    return false;
                                }
                            );
                        }

                        body.on(
                            "click",
                            "button.sync-article",
                            null,
                            function() {
                                var urlAction = window.location.href;

                                if (
                                    0 >
                                    urlAction.indexOf("suppressAutoRecovery")
                                ) {
                                    urlAction += "&suppressAutoRecovery=true";
                                }

                                window.location.href = urlAction;
                            }
                        );

                        //Button controls box
                        body.on("change", "#externalReviewerId", function() {
                            if ($(this).val() == "-1") {
                                $(".confirmSubmitBtn")
                                    .addClass("disabled")
                                    .attr("disabled", "disabled");
                            } else {
                                $(".confirmSubmitBtn")
                                    .removeClass("disabled")
                                    .removeAttr("disabled");
                            }
                        });

                        $("#title")
                            .focus(function() {
                                if (isAuthor) {
                                    contentTemplate.hideSubmitButton();
                                }
                            })
                            .keypress(function(event) {
                                return disableEnterKey(event);
                            });

                        $("#draftButton").click(function() {
                            $("#articleFormMain").submit();
                        });

                        setupPublishControls();

                        html.on("click", "#review-seo", null, function() {
                            skyword.scrollTo("#article-seo", 0);
                            return false;
                        });
                        html.on("click", "#review-req", null, function() {
                            skyword.scrollTo("#article-requirements", 0);
                            return false;
                        });
                        html.on(
                            "click",
                            "#btn-upload-continue",
                            null,
                            function() {
                                $("#image-crop").attr(
                                    "src",
                                    $("#embed-url").val()
                                );
                                $("#upload-container").hide();
                                $("#crop-container").show();
                                $(this).colorbox.resize({ width: 1100 });
                                $("#upload-file").uploadify("destroy");
                                return false;
                            }
                        );
                        html.on("click", ".mce_link", null, function() {
                            $("#seoScore")
                                .removeClass("disabled")
                                .removeAttr("disabled");
                            return false;
                        });

                        html.on(
                            "mouseenter",
                            ".redactor_templateElement, .uploadify-button",
                            function() {
                                if (
                                    html.find("#editDigitalAsset").val() ==
                                    "true"
                                ) {
                                    $("#btn-save-article")
                                        .removeClass("disabled")
                                        .removeAttr("disabled");
                                }
                            }
                        );
                    });

                    $(window).load(function() {
                        if (
                            $("#annotation-module").hasClass("defaultTab") &&
                            $("#annotation-module").hasClass("active")
                        ) {
                            $("#annotation-module")
                                .find(".tab")
                                .click();
                        }
                    });
                }
            };
        }
    ]);
})();

app.factory("videoCommentService", [
    "$http",
    function($http) {
        return {
            /**
             * Add video comment in database and return the element in JSON with id
             * and user and date created set
             */
            getExternalReviewerName: function(){
                var externalReviewerName = localStorage.getItem("externalReviewer");
                externalReviewerName = externalReviewerName === null ? "" : externalReviewerName;
                let currentUrl = window.location.href;
                var accountId = parseInt($('#attrs-id').data('accountid'))
                var externalReviewerData = "";
                if(accountId === 1){
                    if(externalReviewerName.trim() !== "") {
                        externalReviewerData = " - " + externalReviewerName
                    }
                }
                return externalReviewerData;
            },
            videoCommentAdd: function(videoComment, callback) {
                var xsrf = $.param({
                    videoComment: JSON.stringify(videoComment)
                });
                var externalReviewerName = this.getExternalReviewerName();
                videoComment.enteredText += externalReviewerName;
                $http({
                    url: "/API/template/video-comments",
                    method: "POST",
                    data: videoComment,
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            /**
             * Update video comment entered text and/or done/not done
             */
            videoCommentUpdate: function(videoComment, callback) {
                // example of setting the visual state of the comment here
                // setting the "completed" state

                // if (videoComment.done = true) {
                // 	videoComment.commentState = "completed";
                // 	}
                // else {
                // 	videoComment.commentState = "";
                // 	}

                var xsrf = $.param({
                    videoComment: JSON.stringify(videoComment)
                });
                var externalReviewerName = this.getExternalReviewerName();
                if(videoComment.enteredTextEditVersion !== undefined){
                    if(!videoComment.enteredTextEditVersion.includes(externalReviewerName)){
                        videoComment.enteredTextEditVersion += externalReviewerName;
                    }
                }
                if(!videoComment.enteredText.includes(externalReviewerName)){
                    videoComment.enteredText += externalReviewerName;
                }
                $http({
                    url: "/API/template/video-comments",
                    method: "PUT",
                    data: videoComment,
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .success(function(data, status, headers, config) {
                        if (status != 200) {
                            console.log(data);
                        }
                        callback();
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            /**
             * Delete video comment in database through the API (actually setting a
             * flag to delete is true only)
             */
            videoCommentDelete: function(videoComment, callback) {
                $http({
                    url: "/API/template/video-comments/" + videoComment.id,
                    method: "DELETE"
                })
                    .success(function(data, status, headers, config) {
                        // line bellow to be removed
                        if (status != 200) {
                            console.log(data);
                        }
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            /**
             * Get the attachment with the video comments set
             */
            getAttachment: function(attachment, callback, errorCallback) {
                $http({
                    url: "/API/template/video/" + attachment.id,
                    method: "GET"
                })
                    .success(function(data, status, headers, config) {
                        // line bellow to be removed
                        if (status != 200) {
                            console.log(data);
                        }
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log("bad data");
                        console.log(data);
                        errorCallback(data);
                    });
            }
        };
    }
]);

app.service("assignment", [
    "skyword",
    "validation",
    "validationUtil",
    "setup",
    "call",
    "$translate",
    "formBuilder",
    "TinymceService",
    "$window",
    "permission",
    function(
        skyword,
        validation,
        validationUtil,
        setup,
        call,
        $translate,
        formBuilder,
        TinymceService,
        $window,
        permission
    ) {
        var callWriterInfoMiniModule = function(byline, callback) {
                var $this = this,
                    urlAction =
                        "writerInfoMiniModuleAjax.action?writerByline=" +
                        byline,
                    contentTypeId = $("#selectedContentTypeId").val(),
                    options = {
                        cache: false,
                        type: "GET",
                        url: urlAction,
                        success: function(data, textStatus, jqXHR) {
                            $("#writerInfoDiv").html(data);
                            formBuilder.render();
                            if (
                                $(
                                    "#program-account-allow-compensation"
                                ).val() === "true" &&
                                !(contentTypeId === undefined)
                            ) {
                                var accountId = $(
                                    "#program-account-contributor-id"
                                ).val();
                                $("#not-compensated-module").hide();
                                $("#compensated-module").show();

                                updatePayrate(accountId, contentTypeId);
                            } else {
                                $("#not-compensated-module").show();
                                $("#compensated-module").hide();
                                $("#use-custom-compensation").val("false");
                                $("#use-suggested-compensation").val("false");
                            }
                            if (callback != null) {
                                callback();
                            }
                        },
                        error: function(jqXHR, textStatus, errorThrown) {
                            console.log(textStatus);
                        }
                    };
                call.skyjax(urlAction, options);
            },
            listOfContributorAccounts = function(selectedOption) {
                var $this = this,
                    urlAction =
                        "listOfContributorAccountsAjax.action?contributorListId=" +
                        selectedOption,
                    options = {
                        cache: false,
                        type: "GET",
                        url: urlAction,
                        success: function(data, textStatus, jqXHR) {
                            console.log("data: ", data)
                            $("#contributorListOverlay").empty();
                            $("#contributorListOverlay").append(data);
                            $.colorbox({
                                html: angular
                                    .element("#contributorListOverlay")
                                    .html(),
                                width: "650px",
                                maxHeight: "500px",
                                escKey: false,
                                overlayClose: false
                            });
                        },
                        error: function(jqXHR, textStatus, errorThrown) {
                            console.log("error::::", errorThrown);
                        }
                    };
                call.skyjax(urlAction, options);
            },

            generateAISummary = function() {
                const url = "API/content/generateAssignmentSummary";
                let programId = $("#attrs-id").data("programid");
                let contentRequestId = $("#attrs-id").data("contentrequestid");
                let audienceParameterId = $("#cboxLoadedContent #selectedAudienceParameterValue").val();
                let purposeParameterId = $("#cboxLoadedContent #selectedPurposeParameterValue").val();
                let keywords = getKeywordValues();
                let targetTextAreaId = $("#assignmentSummaryTextAreaId").val();
                const token = skyword.getToken("access_token");
                let title = $("#cboxLoadedContent #assignmentSummaryTitle").val();
                let additionalInstructions = $("#cboxLoadedContent #assignmentSummaryAdditionalInstructions").val();
                if(audienceParameterId === "" || purposeParameterId === "") {
                    if(!audienceParameterId) {
                        $("#cboxLoadedContent #audienceParameterErrorText").show();
                    }
                    if(!purposeParameterId) {
                        $("#cboxLoadedContent #purposeParameterErrorText").show();
                    }
                    return;
                }
                let data = {
                    programId: programId,
                    audienceParameterId: parseInt(audienceParameterId),
                    purposeParameterId: parseInt(purposeParameterId),
                    keywords: keywords,
                    title: title,
                    additionalInstructions: additionalInstructions,
                    contentRequestId: contentRequestId
                };

                $("#aiSummaryContentLoader").css("display", "flex");
                $.colorbox.close();
                jQuery.ajax({
                    cache: false,
                    type: "POST",
                    headers: {
                        Accept: "application/json;",
                        "Content-Type": "application/json;"
                    },
                    url: url,
                    data: JSON.stringify(data),
                    beforeSend: function(xhr) {
                        xhr.setRequestHeader(
                            "Authorization",
                            `Bearer ${token}`
                        );
                    },
                    success: function(response, status, req) {
                        if (typeof response === "string") {
                            response = JSON.parse(response);
                        }
                        const summary = response.summary;
                        $("#generateSummaryPopup").empty();
                        $("#generateSummaryPopup").append(summary);

                        // logic to show the response based on diff content type and add/remove loader
                        const iframe = $("#cted" + targetTextAreaId + "_ifr")?.length && $("#cted" + targetTextAreaId + "_ifr")[0];
                        if (iframe) {
                            const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                            const pTag = iframeDocument.querySelector('p');
                            if (pTag) {
                                pTag.textContent = summary;
                            }
                        }
                        else {
                            let inputElement = $("#cted" + targetTextAreaId);
                            var tagName = inputElement.prop("tagName").toLowerCase();
                            if (tagName === "textarea") {
                                $(inputElement).val(summary);
                            } else {
                                $(inputElement).text(summary);
                            }
                            $(inputElement).text(summary);
                        }
                        $("#aiSummaryContentLoader").css("display", "none");
                    },
                    error: function(error) {
                        console.log("error::: ",error);
                        $("#aiSummaryContentLoader").css("display", "none");
                    }
                });
            },
            parametersToGenerateSummary = function() {
                var selectedContentTypeId = $("#selectedContentTypeId").val();
                const targetTitleElement = document.querySelector('h2[name="assignmentTemplateElements[1].inputValue"]');
                let contentRequestId = $("#attrs-id").data("contentrequestid");
                var $this = this,
                urlAction =
                    "parametersToGenerateSummaryAjax.action",
                options = {
                    cache: false,
                    type: "GET",
                    url: urlAction,
                    data: {selectedContentTypeId: selectedContentTypeId, contentRequestId: contentRequestId},
                    success: function(data, textStatus, jqXHR) {
                        $("#generateSummaryPopup").empty();
                        $("#generateSummaryPopup").append(data);
                        $.colorbox({
                            html: angular
                                .element("#generateSummaryPopup")
                                .html(),
                            width: "60%",
                            maxHeight: "80%",
                            height: "50%",
                            closeButton: false,
                            escKey: false,
                            overlayClose: false
                        });

                        const keyword = $("#keywordTemplateElements\\[0\\]\\.inputValue").val();
                        const keyword2 = $("#keywordTemplateElements\\[1\\]\\.inputValue").val();
                        const keyword3 = $("#keywordTemplateElements\\[2\\]\\.inputValue").val();
                        $("#cboxLoadedContent #selectedKeyword_0").val(keyword);
                        $("#cboxLoadedContent #selectedKeyword_1").val(keyword2);
                        $("#cboxLoadedContent #selectedKeyword_2").val(keyword3);
                        if(targetTitleElement){
                            $("#cboxLoadedContent #assignmentSummaryTitle").val(targetTitleElement.textContent)
                        }
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        console.log("error::::", errorThrown);
                    }
                };
                call.skyjax(urlAction, options);
            },
            contributorLeaveModule = function(byline, categoryId) {
                var contributorId = $("#program-account-contributor-id").val();
                if (contributorId) {
                    contributorId = parseInt(contributorId);
                }
                var options,
                    urlAction =
                        "completeBylineAjax.action?categoryId=" +
                        categoryId +
                        "&noManagers=false&term=" +
                        byline;

                options = {
                    cache: false,
                    type: "GET",
                    dataType: "json",
                    url: urlAction,
                    success: function(response) {
                        if (response) {
                            var obj = response.find((obj) => {
                                return obj["accountId"] === contributorId;
                            });
                            if (obj && obj !== undefined) {
                                setContributorLeaveData(obj);
                                showClaimDateWarning(obj);
                                showSubmitDeadlineWarning(obj);
                            }
                        }
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        console.log(textStatus);
                        console.log("errorThrown::", errorThrown);
                    }
                };
                call.skyjax(urlAction, options);
            },
            getAssignmentsForWriter = function(programAccountId, projectId) {
                var options,
                    urlAction =
                        "assignmentsAvailableForWriterAjax.action?programAccountId=" +
                        programAccountId +
                        "&selectedProjectId=" +
                        projectId;
                if (
                    "/assignmentModify.action" !== window.location.pathname &&
                    "/assignmentCreate.action" !== window.location.pathname
                ) {
                    $("#writerByline").blur(); // IF still focused on this, can cause assignments and bio to disappear.
                }
                options = {
                    cache: false,
                    type: "GET",
                    url: urlAction,
                    success: function(message) {
                        $(".hiddenWhileEmpty").show();
                        $("#assignment-list").html(message);
                        if ($("#assignments-found").val() == "true") {
                            $(".showOnResults").show();
                            $("#offerMult-button").show();
                            $("#writerLookup-button").hide();
                        } else {
                            $("#offerMult-button").hide();
                            $("#writerLookup-button").show();
                        }
                        skyword.checkboxMultChange();
                        formBuilder.render();
                    }
                };
                call.skyjax(urlAction, options);
            },
            keywordAutocomplete = function() {
                var field = $(".assignmentKeyword");

                field.keydown(function() {
                    //$(this).parent().find('.keywordData, .keywordInfo').remove();
                    var categoryId = $("#programCategoryId").val();
                    if (categoryId == null) {
                        categoryId = $("#selectedCategoryId").val();
                    }
                });
            },
            projectTooltips = function() {
                $(".projectCompletionGraph .bars").each(function() {
                    var tooltip = $(this)
                        .closest(".projectCompletionGraph")
                        .find(".qTip")
                        .html();
                    $(this).qtip({
                        content: tooltip,
                        position: {
                            viewport: $(window),
                            at: "top middle",
                            my: "bottom middle"
                        },
                        show: {
                            event: "mouseenter"
                        },
                        hide: {
                            event: "mouseleave"
                        },
                        style: {
                            width: 150,
                            classes: "qtip-wiki qtip-light qtip-shadow",
                            tip: {
                                width: 30,
                                border: 2,
                                height: 15
                            }
                        }
                    });
                });
            },
            eventTooltips = function() {
                $(".eventCompletionGraph .bars").each(function() {
                    var tooltip = $(this)
                        .closest(".eventCompletionGraph")
                        .find(".qTip")
                        .html();
                    $(this).qtip({
                        content: tooltip,
                        position: {
                            viewport: $(window),
                            at: "top middle",
                            my: "bottom middle"
                        },
                        show: {
                            event: "mouseenter"
                        },
                        hide: {
                            event: "mouseleave"
                        },
                        style: {
                            width: 150,
                            classes: "qtip-wiki qtip-light qtip-shadow",
                            tip: {
                                width: 30,
                                border: 2,
                                height: 15
                            }
                        }
                    });
                });
            },
            displayKeywordData = function(id, message) {
                var box,
                    tooltip,
                    el = document.getElementById(id);

                $(el)
                    .closest("li")
                    .find(".keywordInfo")
                    .remove();
                $(el)
                    .closest("li")
                    .find(".btnKeywordSuggestion")
                    .after(message);

                box = $(el)
                    .parent()
                    .find(".keywordData");
                box.css("left", 0 - $(".btnKeywordSuggestion").width() - 55);
                tooltip = $(el)
                    .parent()
                    .find(".keywordData .data")
                    .html();
                $(el)
                    .parent()
                    .find(".keywordData .icon")
                    .qtip({
                        content: tooltip,
                        position: {
                            at: "top middle",
                            my: "bottom middle"
                        },
                        show: {
                            event: "mouseenter"
                        },
                        hide: {
                            event: "mouseleave"
                        },
                        style: {
                            width: 150,
                            classes:
                                "qtip-wiki qtip-light qtip-shadow qtip-nolist",
                            tip: {
                                width: 30,
                                border: 2,
                                height: 15
                            }
                        }
                    });
            },
            getKeywordData = function(keyword, id, crId) {
                var options, ci, category;
                category =
                    0 === $("#selectedCategoryId").length
                        ? $("#programCategoryId")
                        : $("#selectedCategoryId");
                ci = category.val();
                if (ci === null) {
                    ci = $("#programCategoryId option:selected").val();
                }
                options = {
                    cache: false,
                    type: "GET",
                    url: "getkeywordDataAjax.action",
                    data: {
                        term: keyword,
                        categoryId: ci,
                        contentRequestId: crId
                    },
                    success: function(message) {
                        displayKeywordData(id, message);
                    }
                };

                call.ajax(options);
                return false;
            },
            resetIdeaAssignee = function() {
                var contentRequestId = $("#contentRequestId").val(),
                    originalAccountId = $("#originalAccountId").val(),
                    urlAction =
                        "resetIdeaAssignee.action?contentRequestId=" +
                        contentRequestId +
                        "&originalAccountId=" +
                        originalAccountId;
                options = {
                    cache: false,
                    type: "POST",
                    url: urlAction,
                    success: function() {}
                };
                call.skyjax(urlAction, options);
            },
            updatePayrate = function(accountId, contentTypeId) {
                var options,
                    urlAction =
                        "payrateGetAjax.action?accountId=" +
                        accountId +
                        "&contentTypeId=" +
                        contentTypeId;
                options = {
                    cache: false,
                    type: "GET",
                    dataType: "json",
                    url: urlAction,
                    success: function(json) {
                        if (
                            $("#program-account-allow-compensation").val() ===
                                "false" &&
                            $("#use-assigned").val() === "true"
                        ) {
                            // someone is assigned and they are uncompensated in the system
                            $("#not-compensated-module").show();
                            $("#compensated-module").hide();
                        } else {
                            // either no one is assigned or the assigned person is compensated
                            $("#not-compensated-module").hide();
                            $("#compensated-module").show();
                        }
                        if (json.suggested == "true") {
                            // if cta != null and custom payment != null
                            $("#suggested-payrate-display").html(json.payrate); // holds value of custom contributor pay rate
                            $("#suggested-compensation").show(); // if !custom contributor payrate enabled, hidden ifcontains the

                            if (
                                $("#change-suggested-compensation").val() ==
                                "true"
                            ) {
                                $("#payrate-input").val(json.payrate);
                            }

                            $("#compensated-module .useModule").show();
                            $("#compensated-module .noModule").hide();
                            $("#change-suggested-compensation").val("false");
                            $("#use-custom-compensation").val("true");
                            $("#payrate-input").val(json.payrate);
                        } else {
                            // if (cta is null || cta != null but custom payment is null)
                            $("#payrate-display").html(json.payrate);
                            $("#suggested-compensation").hide();
                            $("#payrate-display-ecpr-unassigned").hide();
                            $("#payrate-display-ecpr-assigned").show();

                            $("#compensated-module .useModule").hide();
                            $("#compensated-module .noModule").show();
                            $("#use-custom-compensation").val("false");
                            $("#change-suggested-compensation").val("true");
                        }
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        console.log(textStatus);
                    }
                };
                call.skyjax(urlAction, options);
            },
            getCurrentEventSource = function(calendarSettingsDTO) {
                var obj = {},
                    settings = {},
                    NON_PROJECT_ASSIGNMENTS = -2;

                settings.projectIds = calendarSettingsDTO.projectIds;
                settings.eventTypeIds = calendarSettingsDTO.eventTypeIds;
                settings.contentTypeIds = calendarSettingsDTO.contentTypeIds;
                settings.categoryIds = calendarSettingsDTO.categoryIds;
                settings.stateGroupIds = calendarSettingsDTO.stateGroupIds;
                settings.stateIds = calendarSettingsDTO.stateIds;
                settings.keyId = calendarSettingsDTO.keyId;
                // May need to update the CaledarFilterDTO, but for now:
                settings.displayProjects =
                    calendarSettingsDTO.displayProjects.selected;
                settings.displayEvents =
                    calendarSettingsDTO.displayEvents.selected;
                if (true === calendarSettingsDTO.noProgramProjects) {
                    settings.projectIds.push(NON_PROJECT_ASSIGNMENTS);
                }
                var urlAction,
                    programId = $("#programId").val(),
                    actionName = $("#action-name").val();
                if ("publishingCalendar" === actionName) {
                    urlAction =
                        "/API/program/" + programId + "/calendar/publish";
                    //urlAction = 'publishingCalendarAjax.action';
                } else if ("submissionCalendar" === actionName) {
                    //urlAction = 'submissionCalendarAjax.action';
                    urlAction =
                        "/API/program/" + programId + "/calendar/submission";
                }
                obj.url = urlAction;
                obj.data = settings;
                return obj;
            };
        showClaimDateWarning = function(contributorData) {
            if (
                contributorData &&
                contributorData.contributorLeaves.length < 1
            ) {
                return;
            }
            var selectedContributorResponse = contributorData.contributorLeaves;
            var $this = this;
            var dateFormat = "MM-DD-YYYY";
            var contributorLeaveDateFormat = "MMM D, YYYY";
            var claimDeadlineString = $("#claimDeadlineString").val();
            var momentDateFormat = $("#momentPickerFormat").val();
            var claimDeadlineString = moment(
                claimDeadlineString,
                momentDateFormat
            ).format(dateFormat);
            var currentSelectedDate = moment(
                claimDeadlineString,
                dateFormat
            ).format(contributorLeaveDateFormat);
            var showWarning = $("#use-claim-date").val();

            if (
                showWarning === "true" &&
                selectedContributorResponse.indexOf(currentSelectedDate) > -1
            ) {
                setTimeout(function() {
                    $("#claimed-day").html(
                        '<span class ="icon-Warning" style="color:#F28C28"></span><span style="color:#F28C28;font-weight:bold"> ' +
                            $translate.instant(
                                "sw.message.claimdeadlinecontributoronleave"
                            ) +
                            "</span>"
                    );
                }, 500);
            } else {
                $("#claimed-day").text("");
            }
        };
        showSubmitDeadlineWarning = function(contributorData) {
            var $this = this;
            var selectedContributorResponse = contributorData.contributorLeaves;
            var $this = this;
            var dateFormat = "MM-DD-YYYY";
            var contributorLeaveDateFormat = "MMM D, YYYY";
            var submitDeadlineString = $("#submitDeadlineString").val();
            var momentDateFormat = $("#momentPickerFormat").val();
            var submitDeadlineString = moment(
                submitDeadlineString,
                momentDateFormat
            ).format(dateFormat);
            var currentSelectedDate = moment(
                submitDeadlineString,
                dateFormat
            ).format(contributorLeaveDateFormat);
            var showWarning = $("#use-submit-date").val();
            if (
                showWarning === "true" &&
                selectedContributorResponse.indexOf(currentSelectedDate) > -1
            ) {
                setTimeout(function() {
                    $("#submit-day").html(
                        '<span class ="icon-Warning" style="color:#F28C28"></span><span style="color:#F28C28;font-weight:bold">' +
                            $translate.instant(
                                "sw.message.submissiondeadlinecontributoronleave"
                            ) +
                            "</span>"
                    );
                }, 500);
            } else {
                $("#submit-day").text("");
            }
        };
        setContributorLeaveData = function(contributorData) {
            var $this = this;
            $this.contributorLeaveDates = [];
            $this.contributorLeaveDates = contributorData.contributorLeaves;
        };
        contributorLeavesData = function(selectedDate) {
            var $this = this;
            var isPresent =
                ($this.contributorLeaveDates &&
                    $this.contributorLeaveDates.indexOf(selectedDate)) > -1
                    ? true
                    : false;
            return isPresent;
        };
        addBusinessDays = function(startDate, days) {
            var newDeliveryDate = new Date(startDate);
            newDeliveryDate.setDate(newDeliveryDate.getDate() + 1);
            if (newDeliveryDate.getDay() === 0) {
                newDeliveryDate.setDate(newDeliveryDate.getDate() + 1);
            }
            if (newDeliveryDate.getDay() === 6) {
                newDeliveryDate.setDate(newDeliveryDate.getDate() + 2);
            }
            var i = days - 1;
            while (i > 0) {
                newDeliveryDate.setDate(newDeliveryDate.getDate() + 1);
                if (newDeliveryDate.getDay() === 0) {
                    newDeliveryDate.setDate(newDeliveryDate.getDate() + 1);
                    i--;
                }
                if (newDeliveryDate.getDay() === 6) {
                    newDeliveryDate.setDate(newDeliveryDate.getDate() + 2);
                    i - 2;
                }
                i--;
            }
            return newDeliveryDate;
        };
        return {
            listOfContributorAccounts: function(selectedOption) {
                listOfContributorAccounts(selectedOption);
            },
            parametersToGenerateSummary: function() {
                parametersToGenerateSummary();
            },
            generateAISummary: function() {
                generateAISummary();
            },
            contributorLeavesData: function(selectedDate) {
                return contributorLeavesData(selectedDate);
            },
            addBusinessDays: function(startDate, days) {
                return addBusinessDays(startDate, days);
            },
            updateCalendar: function(calendarSettingsDTO, callback) {
                var cal = $("#calendar");
                cal.fullCalendar(
                    "removeEventSource",
                    getCurrentEventSource(calendarSettingsDTO)
                );

                cal.fullCalendar(
                    "addEventSource",
                    getCurrentEventSource(calendarSettingsDTO)
                );

                callback();
            },

            createCalendar: function(calendarSettingsDTO, callback) {
                $("#calendar").fullCalendar({
                    header: {
                        left: "today",
                        center: "title,prev,next ",
                        right: "basicWeek,month"
                    },
                    monthNames: fullcalendarMonthName,
                    monthNamesShort: fullcalendarMonthNameShort,
                    dayNames: fullcalendarDayName,
                    dayNamesShort: fullcalendarDayNameShort,
                    buttonText: fullcalendarButtonText,
                    titleFormat: {
                        week: $("#calendar-format").val()
                    },
                    disableResizing: true,
                    defaultView: "month",
                    headerId: "calendar-heading",
                    editable: true,
                    timeFormat: "h:mm TT",
                    events: getCurrentEventSource(calendarSettingsDTO),
                    eventDrop: function(
                        event,
                        dayDelta,
                        minuteDelta,
                        allDay,
                        revertFunc
                    ) {
                        var today = (new Date().getTime() / 1000.0) | 0,
                            eventStart =
                                new Date(event.start).getTime() / 1000.0,
                            programId = $("#programId").val();

                        if (
                            eventStart > event.dragLowerBound &&
                            eventStart >= today &&
                            eventStart < event.dragUpperBound
                        ) {
                            if (event.type == "publish") {
                                call.ajax(
                                    "/API/program/" +
                                        programId +
                                        "/calendar/publish/update?contentRequestId=" +
                                        event.contentRequestId +
                                        "&newDeadline=" +
                                        event.start.getTime() / 1000,
                                    { type: "put" }
                                );
                            } else {
                                call.ajax(
                                    "/API/program/" +
                                        programId +
                                        "/calendar/submission/update?contentRequestId=" +
                                        event.contentRequestId +
                                        "&newDeadline=" +
                                        event.start.getTime() / 1000,
                                    { type: "put" }
                                );
                            }
                        } else {
                            revertFunc();
                        }
                    },
                    eventDragStart: function(event, jsEvent, ui, view) {
                        var today = new Date();
                        $(this).addClass("dragger");

                        today.setHours("0");
                        today.setMinutes("00");
                        today.setSeconds("00");
                        today = (today.getTime() / 1000.0) | 0; //The bitwise operation is in case it yields a timestamp + 3 decimal places (GMT offset) making later logic wrong

                        $(".fc-widget-content").each(function() {
                            var dateString = $(this)
                                .find("a")
                                .attr("id")
                                .trim();
                            var mon = dateString.substring(0, 2),
                                dt = dateString.substring(3, 5),
                                yr = dateString.substring(6, 10),
                                temp1 = mon + "/" + dt + "/" + yr;
                            var cfd = Date.parse(temp1);
                            var curDay = new Date(cfd).getTime() / 1000.0;

                            if (
                                curDay < event.dragLowerBound ||
                                curDay > event.dragUpperBound ||
                                curDay < today
                            ) {
                                $(this).addClass("invalidDate");
                            }
                        });
                    },
                    eventDragStop: function(event, jsEvent, ui, view) {
                        $(this).removeClass("dragger");
                        $(".fc-widget-content").removeClass("invalidDate");
                    },

                    eventClick: function(event, element) {
                        var actionName = $("#action-name").val(),
                            referrerPage = actionName + ".action",
                            referrerName = encodeURI($("#page-title").val());

                        if (event.displayType == "event") {
                            //console.log("event");
                            call.overlay(
                                "calendarEventOverlayAjax.action?referrerPage=" +
                                    referrerPage +
                                    "&calendarEventId=" +
                                    event.eventId
                            );
                        } else if (event.displayType == "project") {
                            //console.log("project");
                            call.overlay(
                                "calendarProjectOverlayAjax.action?referrerPage=" +
                                    referrerPage +
                                    "&projectId=" +
                                    event.projectId
                            );
                        } else if (event.displayType == "assignment") {
                            //console.log("assignment");
                            call.overlay(
                                "calendarAssignmentOverlayAjax.action?referrerName=" +
                                    referrerName +
                                    "&referrerPage=" +
                                    referrerPage +
                                    "&contentRequestId=" +
                                    event.contentRequestId
                            );
                        }
                    },

                    eventRender: function(event, element) {
                        /* var corner, position, targetPage;

                        if (!event.allDay) {
                            if (event.day == 1) {
                                position = {
                                    viewport : $(window),
                                    my : 'left middle',
                                    at : 'right middle'
                                };
                            } else if (event.day == 7) {
                                position = {
                                    viewport : $(window),
                                    my : 'right middle',
                                    at : 'left middle'
                                };
                            } else {
                                position = {
                                    viewport : $(window),
                                    my : 'bottom middle',
                                    at : 'top middle'
                                };
                            }
                            if ('/publishingCalendar.action' == window.location.pathname) {
                                targetPage = 'publishingCalendar.action';
                            } else if ('/submissionCalendar.action' == window.location.pathname) {
                                targetPage = 'submissionCalendar.action';
                            }
                            element.qtip({
                                content : {
                                    text : '&nbsp;',
                                    ajax : {
                                        url : 'assignmentViewOverlayAjax.action?contentRequestId=' + event.contentRequestId + '&referrerPage=' + targetPage
                                    }
                                },
                                show : {
                                    event : 'click',
                                    solo : true
                                },
                                events : {
                                    show : function (event, api) {
                                        setTimeout(function () {
                                            skyword.scrollTo('.qtip-focus', 30);
                                            skyword.enforceLinkSecurity();
                                        }, 1000);
                                    }
                                },
                                hide : 'unfocus',
                                position : position,
                                style : {
                                    width : 620,
                                    classes : 'qtip-wiki qtip-light qtip-shadow',
                                    tip : {
                                        width : 30,
                                        border : 2,
                                        height : 15,
                                        color : '#f7f7f7'
                                    }
                                }

                            });

                        }*/

                        if (event.eventClass === "unscheduled") {
                            element.qtip({
                                content: $("#unscheduled-tooltip").val(),
                                position: {
                                    viewport: $(window),
                                    at: "top middle",
                                    my: "bottom middle"
                                },
                                show: {
                                    event: "mouseenter"
                                },
                                hide: {
                                    target: $(".fc-day-content"),
                                    delay: 500
                                },
                                style: {
                                    width: 150,
                                    classes: "qtip-wiki qtip-light qtip-shadow",
                                    tip: {
                                        width: 10,
                                        border: 1,
                                        height: 10
                                    }
                                }
                            });
                        }

                        $(".calendarFilterCol").height(
                            $(".displayTableContainer").height()
                        );
                    },

                    viewDisplay: function(event, element) {
                        $(".fc-widget-content").hover(
                            function() {
                                $(this)
                                    .find(".addEventButton.enableAdd")
                                    .css("display", "block");
                            },
                            function() {
                                $(this)
                                    .find(".addEventButton.enableAdd")
                                    .css("display", "none");
                            }
                        );
                        $(".tooltip").bt({
                            padding: 10,
                            width: 100,
                            shrinkToFit: true,
                            spikeLength: 10,
                            spikeGirth: 10,
                            cornerRadius: 5,
                            fill: "rgba(255, 255, 255, 1.0)",
                            strokeWidth: 1,
                            strokeStyle: "#dfdfdf",
                            cssStyles: { color: "#333", fontWeight: "normal" },
                            positions: ["left"]
                        });
                        $(".calendarFilterCol").height(
                            $(".displayTableContainer").height()
                        );
                    }
                });
                setTimeout(callback, 250);
            },

            keywordAutocomplete: function() {
                keywordAutocomplete();
            },
            saveBidComment: function(commentForWriter) {
                var options = {};
                let contentRequestId = document
                    .getElementById("attrs-id")
                    .getAttribute("data-rfpbidcontentrequestid");
                options = {
                    cache: false,
                    type: "POST",
                    data: {
                        rfpBidId: $("#rfpBidId").val(),
                        comments: commentForWriter
                    },
                    url: "/returnBidAjax.action",
                    success: function(data) {
                        window.location = `/rfpEdit.action?contentRequestId=${contentRequestId}`;
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        console.log("Bid Return Error: ", errorThrown);
                    }
                };
                call.skyjax("/returnBidAjax.action", options);
            },

            saveBidDeclineComment: function(commentForWriter) {
                var options = {
                    rfpBidId: $("#rfpBidId").val(),
                    comments: commentForWriter
                };
                let contentRequestId = document
                    .getElementById("attrs-id")
                    .getAttribute("data-rfpbidcontentrequestid");
                options = {
                    cache: false,
                    type: "POST",
                    data: {
                        rfpBidId: $("#rfpBidId").val(),
                        comments: commentForWriter
                    },
                    url: "/declineBidAjax.action",
                    success: function(data) {
                        window.location = `/rfpEdit.action?contentRequestId=${contentRequestId}`;
                        $(this).colorbox.close();
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        console.log("Bid Return Error: ", errorThrown);
                    }
                };
                call.skyjax("/declineBidAjax.action", options);
                return false;
            },
            init: function() {
                function enableDisableButton() {
                    var enable = true;
                    $("#contributorListId").hide();
                    $("#writeronlymessage").hide();
                    $(".requiredForOpen").each(function() {
                        var el = $(this),
                            minLengthObj = el.siblings(".minLength"),
                            minLength =
                                0 === minLengthObj.length
                                    ? 0
                                    : isNaN(minLengthObj.val())
                                        ? 0
                                        : minLengthObj.val();
                        if (
                            el.hasClass("textInput") &&
                            ("" === el.val().trim() ||
                                el.val().length < minLength)
                        ) {
                            enable = false;
                        }
                    });
                    if (enable) {
                        $("button#approve")
                            .removeClass("disabled")
                            .removeAttr("disabled");
                    } else {
                        $("button#approve")
                            .addClass("disabled")
                            .attr("disabled", "disabled");
                    }
                }

                function emptyWriterBylineCallback() {
                    var writerInfoDiv = $("#writerInfoDiv");
                    if (
                        $("#writerByline").val() !== "" &&
                        !$.trim(writerInfoDiv.html())
                    ) {
                        writerInfoDiv.html(
                            '<div class="message warning relative"><span class="icon"></span><div class="content">' +
                                $("#invalidByline").val() +
                                "</div></div>"
                        );
                        $("#spinner-li").hide();
                        writerInfoDiv.show();
                        $(".showOnResults").hide();
                        $("#offerMult-button").hide();
                        $("#writerLookup-button")
                            .show()
                            .removeClass("disabled")
                            .removeAttr("disabled");
                    } else {
                        getAssignmentsForWriter(
                            $("#program-account-id").val(),
                            null
                        );
                    }
                }

                function lookupWriter() {
                    var options,
                        urlAction =
                            "writerInfoMiniModuleAjax.action?writerByline=" +
                            $("#writerByline")
                                .val()
                                .trim();
                    $(".showOnResults").hide();

                    options = {
                        cache: false,
                        type: "GET",
                        url: urlAction,
                        success: function(message) {
                            $("#writerInfoDiv")
                                .html(message)
                                .show();
                            $("#offerMult-button").show();
                            $("#writerLookup-button").hide();
                            emptyWriterBylineCallback();
                        },
                        error: function(message) {
                            console.log(message);
                            emptyWriterBylineCallback();
                            $("#offerMult-button").show();
                            $("#writerLookup-button").hide();
                        },
                        complete: function(m) {
                            setup.checkboxes();
                        }
                    };
                    call.ajax(urlAction, options);
                }

                function updateHistoryLink(lastSearch, linkDiv) {
                    console.log("lastSearch is: " + lastSearch);
                    if ("" !== lastSearch) {
                        linkDiv.html(lastSearch);
                        linkDiv
                            .parent()
                            .parent()
                            .show();
                    } else {
                        linkDiv.html("");
                        linkDiv
                            .parent()
                            .parent()
                            .hide();
                    }
                }

                //onReady
                $(function() {
                    var body = $("body"),
                        html = $("html"),
                        cal = $("#calendar"),
                        keywordToSuggest = $("input.keywordToSuggest"),
                        // only using position 1,2,3, not 0
                        contentTypeId = -1,
                        programId = $("#programId").val(),
                        categoryId = -1,
                        projectId = -1,
                        minHeight,
                        stateId = -1,
                        stateGroupId = -1,
                        keyId = 2,
                        similarTopicsEnabled = false,
                        hideProjects = false,
                        hideEvents = false,
                        kwSuggestionHistoryStack = [],
                        actionName = $("#action-name").val();
                    //console.log("Program Id: " + programId);
                    kwSuggestionHistoryStack[1] = [];
                    kwSuggestionHistoryStack[2] = [];
                    kwSuggestionHistoryStack[3] = [];

                    body.on(
                        "blur",
                        ".requiredForOpen",
                        null,
                        enableDisableButton
                    );
                    body.on(
                        "keyup",
                        ".requiredForOpen",
                        null,
                        enableDisableButton
                    );
                    body.on(
                        "keyup",
                        "textarea.imposeMaxLength",
                        null,
                        skyword.imposeMaximumLength
                    );
                    body.on(
                        "change",
                        'input[type="checkbox"]',
                        null,
                        skyword.checkboxMultChange
                    );

                    // Set Button Default.
                    enableDisableButton();

                    projectTooltips();
                    eventTooltips();
                    keywordAutocomplete();
                    skyword.checkboxMultChange();

                    $(".seoRequirements").each(function() {
                        var tooltip = $(this)
                            .next()
                            .html();
                        $(this).qtip({
                            content: tooltip,
                            position: {
                                at: "right middle",
                                my: "left middle"
                            },
                            show: {
                                event: "mouseenter"
                            },
                            hide: {
                                event: "mouseleave"
                            },
                            style: {
                                width: 500,
                                classes: "qtip-wiki qtip-light qtip-shadow",
                                tip: {
                                    width: 30,
                                    border: 2,
                                    height: 15
                                }
                            }
                        });
                    });

                    var sitePermissions;
                    var rfpEnabled;
                    var createNewButtons =
                        "#createNewLinkProjects, #createNewLinkAssignment, #createNewLinkProjectDetails, #createNewLinkCalendarEventDetails, #createNewLink";

                    var showCreateNewLink = function(
                        sitePermissions,
                        rfpEnabled
                    ) {
                        if (
                            (sitePermissions.canSeeCreateRfps && rfpEnabled) ||
                            sitePermissions.canSeeCreateAssignments ||
                            sitePermissions.canSeePlanning
                        ) {
                            $(createNewButtons).css("visibility", "visible");
                        } else {
                            $(createNewButtons).css("visibility", "hidden");
                        }
                    };

                    var getPermissions = function(sitePermissions) {
                        var programId = $("#attrs-id").attr("data-programid");
                        var rfpEnabledPromise = permission.rfpEnabled(
                            programId
                        );
                        rfpEnabledPromise.then(
                            function(data) {
                                rfpEnabled = data;
                                showCreateNewLink(sitePermissions, rfpEnabled);
                            },
                            function(error) {
                                console.log(
                                    "Rfp Enabled Promise error: ",
                                    error
                                );
                            }
                        );
                    };

                    var sitePermissionsPromise = permission.all();
                    sitePermissionsPromise.then(
                        function(data) {
                            sitePermissions = data;
                            getPermissions(sitePermissions);
                        },
                        function(error) {
                            console.log(
                                "Site Permissions Promise error: ",
                                error
                            );
                        }
                    );

                    // ADD EVENT BUTTON CLICK EVENT For each of the locations.
                    $(createNewButtons).click(function() {
                        var that = $(this),
                            attrs = angular.element("#attrs-id"),
                            startDate,
                            varName = "",
                            targetPage = "",
                            id = that.attr("id"),
                            actionName = attrs.data("actionname"),
                            urls = {},
                            programId = null,
                            projectId = $(this).data("projectid"),
                            rfp = that.data("program"),
                            calendarEventId = $(this).data("calendareventid"),
                            query = "?referrerPage=" + actionName + ".action";
                        var pairs = location.search.slice(1).split("&");

                        var result = {};
                        pairs.forEach(function(pair) {
                            pair = pair.split("=");
                            result[pair[0]] = decodeURIComponent(pair[1] || "");
                        });

                        if (undefined !== result.filter) {
                            query += "&filter=" + result.filter;
                        }

                        if ("publishingCalendar" === actionName) {
                            varName = "publishDeadlineString";
                            targetPage = "publishingCalendar.action";
                        } else if ("submissionCalendar" === actionName) {
                            varName = "submitDeadlineString";
                            targetPage = "submissionCalendar.action";
                            query += "&cal=s";
                        }

                        startDate = attrs.data("today");
                        programId = attrs.data("programid");
                        // TODO: Still need project ID but won't have it on the projectList.jsp page...
                        //console.log("ID: " + id);
                        if (
                            "createNewLinkProjects" === id ||
                            "createNewLink" === id ||
                            "createNewLinkAssignment" === id ||
                            "createNewLinkContentPackage" === id
                        ) {
                            urls = createNewLinkAssignmentsAndProjects(
                                programId,
                                startDate,
                                actionName,
                                query
                            );
                            if ("createNewLink" === id) {
                                urls.urlCreateAssignment =
                                    "/assignmentContentTypeModify.action?" +
                                    varName +
                                    "=" +
                                    encodeURIComponent(
                                        that.closest("a").attr("id")
                                    ) +
                                    "&openPublishDateBox=true&referrerPage=" +
                                    targetPage;
                            }
                        } else if ("createNewLinkProjectDetails" === id) {
                            urls = createNewLinkProjectDetails(
                                programId,
                                actionName,
                                projectId,
                                query
                            );
                        } else if ("createNewLinkCalendarEventDetails" === id) {
                            urls = createNewLinkCalendarEventDetails(
                                programId,
                                actionName,
                                calendarEventId,
                                query
                            );
                        }

                        urls.programRfp = parseInt(rfp, 10);
                        //console.log(urls);
                        var opts = {
                            width: "420px",
                            height: "320px",
                            transition: "none",
                            html: createNewActionModal(urls)
                        };

                        $.colorbox(opts);

                        return false;
                    });

                    function createNewLinkProjectDetails(
                        programId,
                        actionName,
                        projectId,
                        query
                    ) {
                        var urls = {};

                        urls.urlCreateAssignment =
                            "assignmentContentTypeModify.action?selectedProjectId=" +
                            projectId +
                            "&referrerPage=" +
                            actionName +
                            ".action?projectId=" +
                            projectId;
                        urls.urlCreateEvent =
                            "/calendarEventCreate#/" +
                            programId +
                            "/calendarEvent/projectId/" +
                            projectId +
                            "/ref/project" +
                            query;
                        urls.urlCreateProject =
                            "projectCreate.action?referrerPage=" +
                            actionName +
                            ".action?projectId=" +
                            projectId;
                        urls.urlRequestProposal =
                            "rfpContentTypeModify.action?selectedProjectId=" +
                            projectId +
                            "&referrerPage=" +
                            actionName +
                            ".action?projectId=" +
                            projectId;
                        urls.urlImportAssignments =
                            "importContentTypeModify.action?selectedProjectId=" +
                            projectId +
                            "&referrerPage=" +
                            actionName +
                            ".action?projectId=" +
                            projectId;

                        return urls;
                    }

                    function createNewLinkCalendarEventDetails(
                        programId,
                        actionName,
                        calendarEventId,
                        query
                    ) {
                        var urls = {};

                        urls.urlCreateAssignment =
                            "assignmentContentTypeModify.action?selectedCalendarEventId=" +
                            calendarEventId +
                            "&referrerPage=" +
                            actionName +
                            ".action?calendarEventId=" +
                            calendarEventId;
                        urls.urlCreateEvent =
                            "/calendarEventCreate#/" +
                            programId +
                            "/calendarEvent/?referrerPage=calendarEventList.action";
                        urls.urlCreateProject =
                            "projectCreate.action?referrerPage=" +
                            actionName +
                            ".action?calendarEventId=" +
                            calendarEventId;
                        urls.urlRequestProposal =
                            "rfpContentTypeModify.action?selectedCalendarEventId=" +
                            calendarEventId +
                            "&referrerPage=" +
                            actionName +
                            ".action?calendarEventId=" +
                            calendarEventId;
                        urls.urlImportAssignments =
                            "importContentTypeModify.action?selectedCalendarEventId=" +
                            calendarEventId +
                            "&referrerPage=" +
                            actionName +
                            ".action?calendarEventId=" +
                            calendarEventId;

                        return urls;
                    }

                    function createNewLinkAssignmentsAndProjects(
                        programId,
                        startDate,
                        actionName,
                        query
                    ) {
                        var urls = {};
                        urls.urlCreateEvent =
                            "/calendarEventCreate#/" +
                            programId +
                            "/calendarEvent/date/" +
                            startDate +
                            query;
                        urls.urlCreateAssignment =
                            "assignmentContentTypeModify.action?referrerPage=" +
                            actionName +
                            ".action";
                        urls.urlCreateProject =
                            "projectCreate.action?referrerPage=" +
                            actionName +
                            ".action";
                        urls.urlRequestProposal =
                            "rfpContentTypeModify.action?referrerPage=" +
                            actionName +
                            ".action";
                        urls.urlImportAssignments =
                            "importContentTypeModify.action?referrerPage=" +
                            actionName +
                            ".action";
                        return urls;
                    }

                    function createNewActionModal(urls) {
                        attrs = angular.element("#attrs-id");
                        enableContentPackage = attrs.data(
                            "enablecontentpackage"
                        );
                        var proposal =
                                sitePermissions.canSeeCreateRfps && rfpEnabled
                                    ? '<a href="' +
                                      urls.urlRequestProposal +
                                      '"><span class="icon-ProposalDraft"></span><span>' +
                                      $translate.instant(
                                          "angular.requestforproposal"
                                      ) +
                                      "</span></a>"
                                    : "",
                            assignment = sitePermissions.canSeeCreateAssignments
                                ? '<a href="' +
                                  urls.urlCreateAssignment +
                                  '"><span class="icon-NavAssignments"></span><span>' +
                                  $translate.instant("angular.addassignment") +
                                  "</span></a>"
                                : "",
                            event = sitePermissions.canSeePlanning
                                ? '<a href="' +
                                  urls.urlCreateEvent +
                                  '"><span class="icon-Date"></span><span>' +
                                  $translate.instant("angular.addevent") +
                                  "</span></a>"
                                : "",
                            project = sitePermissions.canSeePlanning
                                ? '<a href="' +
                                  urls.urlCreateProject +
                                  '"><span class="icon-Projects"></span><span>' +
                                  $translate.instant("angular.project") +
                                  "</span></a>"
                                : "",
                            importAssignment = sitePermissions.canSeeCreateAssignments
                                ? '<a href="' +
                                  urls.urlImportAssignments +
                                  '"><span class="icon-FileImport"></span><span>' +
                                  $translate.instant(
                                      "angular.importassignments"
                                  ) +
                                  "</span></a>"
                                : "",
                            createContentPackage = enableContentPackage
                                ? '<a href="/contentPackageCreate"><span class="icon-StatusOffered"></span><span>' +
                                  $translate.instant(
                                      "nucleus.nav.contentPackage"
                                  ) +
                                  "</span></a>"
                                : "",
                            html = "";

                        html =
                            '<div class="create-new-pane">' +
                            "<h2>" +
                            $translate.instant("angular.createnew") +
                            "</h2>" +
                            assignment +
                            event +
                            project +
                            proposal +
                            importAssignment +
                            createContentPackage +
                            "</div>";
                        return html;
                    }

                    /* ==========================================================================
                 Assignment Calendar JS - TODO:  This JS should really be in its own file somewhere not clogging up other assignment related logic.
                 ========================================================================== */

                    if (
                        RegExp("((C|c)alendar).(action)").test(
                            window.location.pathname
                        )
                    ) {
                        // Initial hiding of other colorkey groups
                        $(".projectKey").hide();
                        $(".eventKey").hide();
                        $(".contentTypeKey").hide();

                        // ADD EVENT BUTTON CLICK EVENT
                        html.on(
                            "click",
                            "span.addEventButton.enableAdd",
                            function() {
                                var varName,
                                    targetPage,
                                    urlCreateAssignment,
                                    urlCreateEvent,
                                    a = $(this).parent(),
                                    startDate = a.attr("id"),
                                    cal = "",
                                    actionName = $("#action-name").val(),
                                    query =
                                        "?referrerPage=" +
                                        actionName +
                                        ".action";
                                if ("publishingCalendar" === actionName) {
                                    varName = "publishDeadlineString";
                                    targetPage = "publishingCalendar.action";
                                } else if (
                                    "submissionCalendar" === actionName
                                ) {
                                    varName = "submitDeadlineString";
                                    targetPage = "submissionCalendar.action";
                                    query += "&cal=s";
                                }

                                urlCreateAssignment =
                                    "/assignmentContentTypeModify.action?" +
                                    varName +
                                    "=" +
                                    encodeURIComponent(
                                        $(this)
                                            .closest("a")
                                            .attr("id")
                                    ) +
                                    "&openPublishDateBox=true&referrerPage=" +
                                    targetPage;

                                // Construct the URL of the new event here

                                //console.log(startDate);
                                //console.log(moment(startDate).isValid());
                                startDate = moment(
                                    startDate,
                                    "MM-DD-YYYY hh:mm a"
                                );
                                //console.log(startDate);

                                urlCreateEvent =
                                    "/calendarEventCreate#/" +
                                    programId +
                                    "/calendarEvent/date/" +
                                    startDate +
                                    query;

                                var assignment = sitePermissions.canSeeCreateAssignments
                                        ? '<a href="' +
                                          urlCreateAssignment +
                                          '"><span class="icon-NavAssignments"></span><span>' +
                                          $translate.instant(
                                              "angular.addassignment"
                                          ) +
                                          "</span></a>"
                                        : "",
                                    event = sitePermissions.canSeePlanning
                                        ? '<a href="' +
                                          urlCreateEvent +
                                          '"><span class="icon-Date"></span><span>' +
                                          $translate.instant(
                                              "angular.addevent"
                                          ) +
                                          "</span></a>"
                                        : "";

                                var opts = {
                                    transition: "none",
                                    html:
                                        '<div class="choose-pane">' +
                                        "<h2>" +
                                        $translate.instant(
                                            "angular.createnew"
                                        ) +
                                        "</h2>" +
                                        "<div>" +
                                        assignment +
                                        event +
                                        "</div>" +
                                        "</div>"
                                };

                                $.colorbox(opts);

                                return false;
                            }
                        );
                        // END ADD EVENT BUTTON CLICK EVENT

                        //For resize purposes. Find min height needed for the left col to not overflow
                        minHeight = 0;
                        $(".calendarFilterCol")
                            .children()
                            .each(function() {
                                minHeight += $(this).height();
                            });

                        //Resize calendar container
                        $("#calendar").resize(function() {
                            if ($("#calendar").height() < minHeight) {
                                $(".displayTableContainer").height(
                                    minHeight + 50
                                ); //A little extra so it doesnt look so cramped
                            } else {
                                $(".displayTableContainer").height(
                                    $("#calendar").height()
                                );
                            }
                        });

                        //Resize filter column
                        $(".displayTableContainer").resize(function() {
                            $(".calendarFilterCol").height(
                                $(".displayTableContainer").height()
                            );
                        });
                    }
                    /* ==========================================================================
                 Create/Edit Assignment
                 ========================================================================== */
                    html.on("click", "a.useToggle", function() {
                        $(this)
                            .closest(".inputModule")
                            .find(".useModule")
                            .fadeOut("fast", function() {
                                $(this)
                                    .closest(".inputModule")
                                    .find(".noModule")
                                    .fadeIn("fast", function() {
                                        setTimeout(
                                            "$(this).colorbox.resize();$('#cboxLoadedContent').css('overflow','hidden');",
                                            500
                                        );
                                    });
                            });

                        $(this)
                            .closest(".inputModule")
                            .find(".enableValue")
                            .val(false);
                        $(this)
                            .closest(".inputModule")
                            .find(".disableValue")
                            .val(true);

                        return false;
                    });

                    html.on("click", "a.noToggle", function() {
                        $(this)
                            .closest(".inputModule")
                            .find(".noModule")
                            .fadeOut("fast", function() {
                                $(this)
                                    .closest(".inputModule")
                                    .find(".useModule")
                                    .fadeIn("fast", function() {
                                        setTimeout(
                                            "$(this).colorbox.resize();$('#cboxLoadedContent').css('overflow','hidden');",
                                            500
                                        );
                                    });
                            });
                        $(this)
                            .closest(".inputModule")
                            .find(".enableValue")
                            .val(true);
                        $(this)
                            .closest(".inputModule")
                            .find(".disableValue")
                            .val(false);
                        setTimeout("$(this).colorbox.resize();", 500);
                        return false;
                    });

                    $("#btn-set-open").on("click", function() {
                        $("#create-assignment-button .create").show();
                        $("#create-assignment-button .open").hide();
                    });
                    $("#btn-remove-open").on("click", function() {
                        $("#create-assignment-button .create").hide();
                        $("#create-assignment-button .open").show();
                    });

                    $("#cancel-return-idea").on("click", function() {
                        resetIdeaAssignee();
                        return;
                    });

                    $("a.removeAssigned").on("click", function() {
                        $(this)
                            .closest(".inputModule")
                            .find("#assigned-module")
                            .remove();

                        var contentRequestId = $("#contentRequestId").val(),
                            showAssignModule = $(this).hasClass("reopenLink")
                                ? false
                                : true,
                            urlAction =
                                "removeAssignedAjax.action?contentRequestId=" +
                                contentRequestId +
                                "&showAssignModule=" +
                                showAssignModule;
                        options = {
                            cache: false,
                            type: "POST",
                            url: urlAction,
                            success: function() {}
                        };
                        call.skyjax(urlAction, options);
                        return false;
                    });

                    $("#btn-bid-decline").on("click", function() {
                        showCommentBox("decline");
                        return false;
                    });
                    function showCommentBox(type) {
                        let html = `<div class='m-3' style="width:95%;">
                            <h1>${
                                type === "decline"
                                    ? $translate.instant(
                                          "angular.message.biduserdeclinecommenttitle"
                                      )
                                    : $translate.instant(
                                          "angular.message.biduserreturncommenttitle"
                                      )
                            }</h1>
                            <div class="col-md-12" style="padding:0; max-width:98%;">

                                <div class="col-md-12 mb-3">
                                    <label class="d-block my-2">${
                                        type === "decline"
                                            ? $translate.instant(
                                                  "angular.message.biduserdeclinecommentlabel"
                                              )
                                            : $translate.instant(
                                                  "angular.message.biduserreturncommentlabel"
                                              )
                                    } <span class="required">*</span></label>
                                    <textarea name="bidUserComment" id="bidUserComment" style="width:100%; height:120px;"></textarea>
                                    <p id="bidUserMessageRequired" style="display:none; color:#bd1200; padding-bottom:0;">${$translate.instant(
                                        "angular.message.bidusercommentrequired"
                                    )}</p>
                                    <p> ${
                                        type === "decline"
                                            ? $translate.instant(
                                                  "angular.message.confirmbiddecline"
                                              )
                                            : ""
                                    } </p>
                                </div>
                                <div class="col-md-12 d-flex align-items-center mt-5">
                                    <button class="btn btn-primary btn-sm" id=${
                                        type === "decline"
                                            ? "saveBidDeclineComment"
                                            : "saveBidComment"
                                    } type="button">${$translate.instant(
                            "angular.button.submit"
                        )}</button>
                                    <a id=${
                                        type === "decline"
                                            ? "closeDeclineComment"
                                            : "closeBidComment"
                                    } class="text-primary">${$translate.instant(
                            "angular.button.cancel"
                        )}</a>
                                </div>
                            </div>
                        </div>`;
                        $.colorbox({
                            html: html,
                            width: "50%",
                            height: "35%",
                            maxHeight: "50%",
                            escKey: false,
                            overlayClose: false
                        });
                        $("#cboxClose").css("display", "none");
                    }

                    $("#btn-bid-return").on("click", function(e) {
                        e.preventDefault();
                        showCommentBox("returnComment");
                    });

                    $("#btn-bid-accept-without-closing").on("click", function(
                        e
                    ) {
                        e.preventDefault();
                        var options = {};
                        let contentRequestId = document
                            .getElementById("attrs-id")
                            .getAttribute("data-rfpbidcontentrequestid");
                        options = {
                            cache: false,
                            type: "POST",
                            data: {
                                rfpBidId: $("#rfpBidId").val(),
                                approvedPrice: $("#approvedPrice").val(),
                                submitDeadlineDate: $(
                                    "#submitDeadlineString"
                                ).val(),
                                publishDeadlineDate: $(
                                    "#publishDeadlineString"
                                ).val(),
                                deliveryDeadlineDate: $(
                                    "#deliveryDeadlineString"
                                ).val()
                            },
                            url: "/acceptWithoutClosingAjax.action",
                            success: function(data) {
                                var messengerJson = $(data).filter(
                                    ".error"
                                ),
                                messengerJsonString = "";
                                if (0 < messengerJson.length) {
                                    messengerJsonString = messengerJson.text();
                                }
                                validationUtil.parse(messengerJsonString);

                                window.location = `/assignmentModify.action?contentRequestId=${contentRequestId}`;
                            },
                            error: function(jqXHR, textStatus, errorThrown) {
                                console.log("Bid Accept Error: ", errorThrown);
                            }
                        };
                        call.skyjax(
                            "/acceptWithoutClosingAjax.action",
                            options
                        );
                        return false;
                    });

                    keywordToSuggest.on("keyup", function(e) {
                        if (13 === e.keyCode) {
                            $(this)
                                .next()
                                .click();
                        }
                    });
                    keywordToSuggest.on("blur", function() {
                        var k = $(this),
                            keyword = k.val().replace(/(<([^>]+)>)/gi, ""),
                            keywordDiv = $("<div/>"),
                            cleanKeyword;
                        k.val(keyword);
                    });

                    body.on("click", ".btnKeywordSuggestion", null, function() {
                        var options,
                            module,
                            keyword,
                            urlAction,
                            button,
                            suggestedKeywordModule,
                            position,
                            kwNum,
                            keywordDiv = $("<div/>"),
                            categoryId = $("#programCategoryId").val();
                        if (undefined === categoryId || null === categoryId) {
                            categoryId = $("#selectedCategoryId").val();
                        }
                        module = $(this).closest("li");
                        keyword = module
                            .find(".keywordToSuggest")
                            .val()
                            .replace(/(<([^>]+)>)/gi, "");
                        module.find(".keywordToSuggest").val(keyword);
                        urlAction =
                            "suggestedKeywordModuleAjax.action?keyword=" +
                            encodeURIComponent(keyword) +
                            "&categoryId=" +
                            categoryId;
                        suggestedKeywordModule = module.find(
                            ".suggestedKeywordModule"
                        );
                        button = $(this);
                        if (!suggestedKeywordModule.hasClass("active")) {
                            $(this)
                                .closest("li")
                                .find(".suggestedKeywordModule")
                                .hide()
                                .fadeIn("slow")
                                .addClass("active");
                        }
                        position = 20 + module.find(".results").height() / 2;

                        kwNum = suggestedKeywordModule
                            .siblings(".keywordNumber")
                            .val();

                        if (module.find(".lastSearched").html() != undefined) {
                            if (
                                module.find(".lastSearched").html() != keyword
                            ) {
                                kwSuggestionHistoryStack[kwNum].push(
                                    module.find(".lastSearched").html()
                                );
                                updateHistoryLink(
                                    module.find(".lastSearched").html(),
                                    module.find(".lastSearchValue")
                                );
                            } else {
                                module
                                    .find(".lastSearchValue")
                                    .parent()
                                    .parent()
                                    .show();
                            }
                        }

                        module
                            .find(".loaderModule")
                            .css("top", position)
                            .show();
                        module
                            .find(".results")
                            .show()
                            .addClass("whiteout");
                        button.addClass("disabled");
                        options = {
                            cache: false,
                            type: "POST",
                            url: urlAction,
                            success: function(message) {
                                module
                                    .find(".results")
                                    .removeClass("whiteout")
                                    .html(message);
                                module.find(".loaderModule").hide();
                                button.removeClass("disabled");
                                setup.qtipTooltips();
                            }
                        };
                        call.skyjax(urlAction, options);
                        return false;
                    });
                    body.on(
                        "click",
                        ".selectKeyword, .rerun",
                        null,
                        function() {
                            var keiVal,
                                searchVolumeVal,
                                keywordId,
                                box,
                                prevKeyword,
                                module = $(this).closest(
                                    ".suggestedKeywordModule"
                                ),
                                item = $(this).closest("li"),
                                keyword = item.find(".keyword").html();

                            if ($(this).hasClass("rerun")) {
                                prevKeyword = module
                                    .find(".assignmentKeyword")
                                    .val();
                                module.find(".assignmentKeyword").val(keyword);
                                module.find(".btnKeywordSuggestion").click();
                                module
                                    .find(".assignmentKeyword")
                                    .val(prevKeyword)
                                    .blur();
                            } else {
                                keiVal = $(this)
                                    .closest("li")
                                    .find(".kei")
                                    .text();
                                searchVolumeVal = $(this)
                                    .closest("li")
                                    .find(".volume")
                                    .text();
                                keywordId = module
                                    .find(".assignmentKeyword")
                                    .attr("id");
                                box = $(this)
                                    .closest(".suggestedKeywordModule")
                                    .find(".keywordData");
                                box.find(".keiVal").html(keiVal);
                                box.find(".searchVolumeVal").html(
                                    searchVolumeVal
                                );

                                module
                                    .find(".assignmentKeyword")
                                    .val(keyword)
                                    .blur();
                                module
                                    .find(".results")
                                    .fadeOut("fast", function() {
                                        module
                                            .hide()
                                            .fadeIn("fast", function() {
                                                displayKeywordData(
                                                    keywordId,
                                                    box.html()
                                                );
                                            })
                                            .removeClass("active");
                                        module.find(".historyLink").hide();
                                    });
                            }

                            getKeywordData(
                                module.find(".assignmentKeyword").val(),
                                module.find(".assignmentKeyword").attr("id"),
                                $("#contentRequestId").val()
                            );

                            return false;
                        }
                    );
                    body.on("click", ".lastKeywordLink", null, function() {
                        var module,
                            options,
                            urlAction,
                            kwNum,
                            keyword,
                            suggestedKeywordModule,
                            button,
                            position,
                            categoryId = $("#programCategoryId").val();
                        if (categoryId == null) {
                            categoryId = $("#selectedCategoryId").val();
                        }
                        module = $(this).closest(".suggestedKeywordModule");
                        keyword = module.find(".lastSearchValue").html();
                        urlAction =
                            "suggestedKeywordModuleAjax.action?keyword=" +
                            keyword +
                            "&categoryId=" +
                            categoryId;
                        kwNum = module.siblings(".keywordNumber").val();

                        kwSuggestionHistoryStack[kwNum].pop();
                        console.log("kwh: " + kwSuggestionHistoryStack);

                        suggestedKeywordModule = module.find(
                            ".suggestedKeywordModule"
                        );
                        button = $(this);
                        if (!suggestedKeywordModule.hasClass("active")) {
                            $(this)
                                .closest("li")
                                .find(".suggestedKeywordModule")
                                .hide()
                                .fadeIn("slow")
                                .addClass("active");
                        }
                        position = 20 + module.find(".results").height() / 2;

                        if (kwSuggestionHistoryStack[kwNum].length > 0) {
                            updateHistoryLink(
                                kwSuggestionHistoryStack[kwNum][
                                    kwSuggestionHistoryStack[kwNum].length - 1
                                ],
                                module.find(".lastSearchValue")
                            );
                        } else {
                            updateHistoryLink(
                                "",
                                module.find(".lastSearchValue")
                            );
                        }

                        module
                            .find(".loaderModule")
                            .css("top", position)
                            .show();
                        module
                            .find(".results")
                            .show()
                            .addClass("whiteout");
                        button.addClass("disabled");
                        options = {
                            cache: false,
                            type: "POST",
                            url: urlAction,
                            success: function(message) {
                                module
                                    .find(".results")
                                    .removeClass("whiteout")
                                    .html(message);
                                module.find(".loaderModule").hide();
                                button.removeClass("disabled");
                            }
                        };
                        call.skyjax(urlAction, options);
                        return false;
                    });

                    if ("/writeArticles.action" !== window.location.pathname) {
                        body.on("blur", ".assignmentKeyword", function() {
                            getKeywordData(
                                $(this).val(),
                                $(this).attr("id"),
                                $("#contentRequestId").val()
                            );
                            return false;
                        });

                        body.on("click", ".btnKeywordSuggestion", function() {
                            getKeywordData(
                                $(this)
                                    .parent()
                                    .find(".assignmentKeyword")
                                    .val(),
                                $(this)
                                    .parent()
                                    .find(".assignmentKeyword")
                                    .attr("id"),
                                $("#contentRequestId").val()
                            );
                            return false;
                        });

                        $(".assignmentKeyword").each(function() {
                            getKeywordData(
                                $(this).val(),
                                $(this).attr("id"),
                                $("#contentRequestId").val()
                            );
                        });
                    }

                    if ("/proposalReview.action" == window.location.pathname) {
                        $(
                            "#similar-topics-content"
                        ).similarTopics.decorateResults(
                            $("#similar-topics-content")
                        );
                    }

                    $("#writerByline")
                        .focus(function(e) {
                            $(this).val(" ");
                            $(this).keydown();
                        })
                        .keydown(function(e) {
                            if (13 === e.keyCode) {
                                e.preventDefault();
                            }
                            $("#writerInfoDiv").empty();
                            $(".hiddenWhileEmpty").hide();
                            $(".showOnResults").hide();
                            $("#writerLookup-button").show();
                        })
                        .focusout(function() {
                            if (
                                "/assignmentModify.action" ===
                                    window.location.pathname ||
                                "/assignmentCreate.action" ===
                                    window.location.pathname
                            ) {
                                console.log("lookupWriter - focusOut");
                                lookupWriter();
                                return false;
                            }
                        });

                    $("#writerLookup-button").on("click", function() {
                        lookupWriter();
                    });
                    $(".includeForSummary").blur(similarTopicsCheck);
                    $(".redactor_includeForSummary").blur(similarTopicsCheck);

                    function similarTopicsCheck() {
                        var doCheck = true;

                        $(".includeForSummary").each(function() {
                            var el = $(this);
                            if ("" === el.val()) {
                                doCheck = false;
                            }
                        });

                        $(".redactor_includeForSummary").each(function() {
                            var el = $(this);
                            if ("" === el.val()) {
                                doCheck = false;
                            }
                        });

                        if (
                            doCheck &&
                            -1 !== parseInt($("#selectedCategoryId").val(), 10)
                        ) {
                            $("#similar-topics-content").similarTopics.check();
                        }
                    }

                    var gotoNextidea = function(data) {
                        if ($("#create-assignment-button").length) {
                            // Will have sent the AJAX request, if there is a validation error, make sure they can re-submit
                            // If is there for safety on pages that may not have this button
                            $("#create-assignment-button").attr(
                                "disabled",
                                false
                            );
                        }
                        var text = validation.decode(data);
                        var json = $.parseJSON(text);
                        if (
                            json.redirectionTarget != null &&
                            json.redirectionTarget.indexOf(
                                "ideaReview.action"
                            ) >= 0
                        ) {
                            var crId = $("#crId").val();
                            var savedFilters = JSON.parse(
                                $window.sessionStorage.getItem(
                                    "ideaListFilters"
                                )
                            );
                            var attrs = $("#attrs-id");
                            var programId = attrs.data("programid");
                            var whichList = "";
                            var nextIdeaCrId = -1;

                            if (
                                savedFilters != null &&
                                savedFilters.programId != programId
                            ) {
                                $window.sessionStorage.removeItem(
                                    "ideaListFilters"
                                );
                                $window.sessionStorage.removeItem(
                                    "plannerList"
                                );
                                $window.sessionStorage.removeItem(
                                    "pendingList"
                                );
                                data.replace(
                                    "ideaReview.action",
                                    "ideas.action"
                                );
                                validation.parse(data);
                            } else {
                                var sessionLists = {
                                    plannerList: JSON.parse(
                                        $window.sessionStorage.getItem(
                                            "plannerList"
                                        )
                                    ),
                                    pendingList: JSON.parse(
                                        $window.sessionStorage.getItem(
                                            "pendingList"
                                        )
                                    )
                                };
                                var plannerListPos =
                                    sessionLists.plannerList != null
                                        ? sessionLists.plannerList
                                              .map(function(e) {
                                                  return e.contentRequestId;
                                              })
                                              .indexOf(parseInt(crId))
                                        : -1;
                                var pendingListPos =
                                    sessionLists.pendingList != null
                                        ? sessionLists.pendingList
                                              .map(function(e) {
                                                  return e.contentRequestId;
                                              })
                                              .indexOf(parseInt(crId))
                                        : -1;
                                if (plannerListPos >= 0) {
                                    nextIdeaCrId =
                                        sessionLists.plannerList.length >
                                        plannerListPos + 1
                                            ? sessionLists.plannerList[
                                                  plannerListPos + 1
                                              ].contentRequestId
                                            : -1;
                                    whichList = "plannerList";
                                } else if (pendingListPos >= 0) {
                                    nextIdeaCrId =
                                        sessionLists.pendingList.length >
                                        pendingListPos + 1
                                            ? sessionLists.pendingList[
                                                  pendingListPos + 1
                                              ].contentRequestId
                                            : -1;
                                    whichList = "pendingList";
                                } else {
                                    whichList = "";
                                }

                                if (whichList != "") {
                                    sessionLists[whichList] = sessionLists[
                                        whichList
                                    ].filter(function(obj) {
                                        return (
                                            obj.contentRequestId !==
                                            parseInt(crId)
                                        );
                                    });
                                    sessionStorage.setItem(
                                        whichList,
                                        JSON.stringify(sessionLists[whichList])
                                    );
                                    if (nextIdeaCrId > 0) {
                                        window.location.href =
                                            "/" +
                                            json.redirectionTarget +
                                            "?contentRequestId=" +
                                            nextIdeaCrId;
                                    } else {
                                        window.location.href = "/ideas.action";
                                    }
                                } else {
                                    data.replace(
                                        "ideaReview.action",
                                        "ideas.action"
                                    );
                                    validation.parse(data);
                                }
                            }
                        } else {
                            data.replace("ideaReview.action", "ideas.action");
                            validation.parse(data);
                        }
                    };

                    html.on("click", "#create-assignment-button", function() {
                        if(!$("#payrate-input").is(":visible") && $("search-result-type-id").val() === "5") {
                    	    $("#payrate-input").val("");
                        }
                        $("#create-assignment-button").attr("disabled", true);
                        var form = $("#assignmentFormTemplate"),
                            urlAction = "/assignmentModifyAjax.action",
                            getPost = form.attr("method"),
                            options;

                        // Trim off space added to trigger focus event
                        var bylineElement = $("#writerByline");
                        var trimmedByline = $.trim(bylineElement.val());
                        bylineElement.val(trimmedByline);

                        tinyMCE.triggerSave();

                        // get the plain text fields
                        divList = document.querySelectorAll(
                            "[tinymce-plain-text]"
                        );
                        // for each, store the value into a hidden form element so it gets sent back in the form serialize.
                        for (i = 0; i < divList.length; ++i) {
                            currIdx = divList[i].getAttribute("idxValue");
                            var elementValue = TinymceService.sanitize(
                                divList[i].innerHTML
                            );
                            if (divList[i].nodeName === "TEXTAREA") {
                                elementValue = TinymceService.sanitize(
                                    divList[i].value
                                );
                            }
                            $(
                                'input[name="assignmentTemplateElements[' +
                                    currIdx +
                                    '].inputValue"]'
                            ).val(elementValue);
                        }

                        // serialize the form to a string
                        var mainForm = $("#assignmentMeta").serialize();

                        console.log("Main Form::::", mainForm);
                        // find the postBody{n}, add the text and replace the postbody
                        var postDivList = document.getElementsByName(
                            "postBody"
                        );
                        for (i = 0; i < postDivList.length; i++) {
                            var childList =
                                postDivList[i].parentElement.children;
                            for (j = 0; j < childList.length; j++) {
                                childItem = childList[j];
                                if (
                                    childItem.className.includes(
                                        "tinyMceInliteTextarea"
                                    )
                                ) {
                                    var tmpValue =
                                        "&postBody=" +
                                        encodeURIComponent(childItem.innerText);
                                    mainForm = mainForm + tmpValue;
                                }
                            }
                        }

                        options = {
                            cache: false,
                            type: getPost,
                            data:
                                form.serialize() +
                                "&" +
                                mainForm +
                                "&" +
                                $("#rfpFormTemplate").serialize(),
                            url: urlAction,
                            success: function(data) {
                                gotoNextidea(data);
                            }
                        };
                        var creativeBrief;
                        if ($("#creative-brief").length > 0) {
                            creativeBrief = angular
                                .element($("#creative-brief"))
                                .scope();
                            creativeBrief.save();
                        }

                        call.ajax(urlAction, options);
                        return false;
                    });

                    body.on(
                        "click",
                        "#draft-assignment-button",
                        null,
                        function() {
                            var form = $("#assignmentFormTemplate"),
                                urlAction =
                                    "/assignmentModifyAjax.action?onHold=true",
                                getPost = form.attr("method"),
                                options;

                            // Trim off space added to trigger focus event
                            var bylineElement = $("#writerByline");
                            var trimmedByline = $.trim(bylineElement.val());
                            bylineElement.val(trimmedByline);

                            tinyMCE.triggerSave();

                            // get the plain text fields
                            divList = document.querySelectorAll(
                                "[tinymce-plain-text]"
                            );
                            // for each, store the value into a hidden form element so it gets sent back in the form serialize.
                            for (i = 0; i < divList.length; ++i) {
                                currIdx = divList[i].getAttribute("idxValue");
                                var elementValue = TinymceService.sanitize(
                                    divList[i].innerHTML
                                );
                                if (divList[i].nodeName === "TEXTAREA") {
                                    elementValue = TinymceService.sanitize(
                                        divList[i].value
                                    );
                                }
                                $(
                                    'input[name="assignmentTemplateElements[' +
                                        currIdx +
                                        '].inputValue"]'
                                ).val(elementValue);
                            }

                            // serialize the form to a string
                            var mainForm = $("#assignmentMeta").serialize();
                            // find the postBody{n}, add the text and replace the postbody
                            var postDivList = document.getElementsByName(
                                "postBody"
                            );
                            for (i = 0; i < postDivList.length; i++) {
                                var childList =
                                    postDivList[i].parentElement.children;
                                for (j = 0; j < childList.length; j++) {
                                    childItem = childList[j];
                                    if (
                                        childItem.className.includes(
                                            "tinyMceInliteTextarea"
                                        )
                                    ) {
                                        var tmpValue =
                                            "&postBody=" +
                                            encodeURIComponent(
                                                childItem.innerText
                                            );
                                        mainForm = mainForm + tmpValue;
                                    }
                                }
                            }

                            console.log("Main Form::::", mainForm);
                            options = {
                                cache: false,
                                type: getPost,
                                data:
                                    form.serialize() +
                                    "&" +
                                    mainForm +
                                    "&" +
                                    $("#rfpFormTemplate").serialize(),
                                url: urlAction,
                                success: function(data) {
                                    gotoNextidea(data);
                                }
                            };
                            var creativeBrief;
                            if ($("#creative-brief").length > 0) {
                                creativeBrief = angular
                                    .element($("#creative-brief"))
                                    .scope();
                                creativeBrief.save();
                            }
                            call.ajax(urlAction, options);
                            return false;
                        }
                    );

                    // These used to re-run updatePayrate. That was not necessary. TT - SKY-18781
                    body.on("click", "#btn-remove-comp", function() {
                        $("#payrate-input").val("0.00");
                        $("#payrate-display").text(
                            $("#default-content-type-payment-rate").val()
                        );
                    });

                    // Reset Comments for Contributors CT-726
                    function resetCommentFields() {
                        var getCommentFields = document.querySelectorAll(
                            "#comment"
                        );
                        $(getCommentFields).each(function() {
                            $(this).val("");
                            $(this).attr("disabled", false);
                        });
                    }

                    // Reset payment module - SKY-18790 / SKY-18781
                    body.on("click", "#btn-offer-all", function() {
                        resetCommentFields();
                        var contentTypeId = $("#selectedContentTypeId").val();
                        updatePayrate(null, contentTypeId);
                    });

                    // Reset payment module - SKY-18790 / SKY-18781
                    body.on("click", "#btn-offer-writer", function() {
                        resetCommentFields();
                        var contentTypeId = $("#selectedContentTypeId").val(),
                            accountId = $(
                                "#program-account-contributor-id"
                            ).val();
                        if ($("#use-assigned").val() === "false") {
                            accountId = null;
                        }
                        updatePayrate(accountId, contentTypeId);
                    });

                    body.on("click", "#import-assignments-button", function() {
                        var form = $("form"),
                            urlAction = "/assignmentImportAjax.action",
                            getPost = form.attr("method"),
                            options;

                        tinyMCE.triggerSave();

                        // serialize the form to a string
                        var mainForm = form.serialize();

                        // find the postBody{n}, add the text and replace the postbody
                        var postDivList = document.getElementsByName(
                            "postBody"
                        );
                        for (i = 0; i < postDivList.length; i++) {
                            var childList =
                                postDivList[i].parentElement.children;
                            for (j = 0; j < childList.length; j++) {
                                childItem = childList[j];
                                if (
                                    childItem.className.includes(
                                        "tinyMceInliteTextarea"
                                    )
                                ) {
                                    var tmpValue =
                                        "&postBody=" +
                                        encodeURIComponent(childItem.innerText);
                                    mainForm = mainForm + tmpValue;
                                }
                            }
                        }

                        options = {
                            cache: false,
                            type: getPost,
                            data: mainForm,
                            url: urlAction,
                            success: function(data) {
                                validation.parse(data);
                            }
                        };

                        call.ajax(urlAction, options);
                        return false;
                    });

                    body.on(
                        "click",
                        "#import-assignments-draft-button",
                        function() {
                            var form = $("form"),
                                urlAction =
                                    "/assignmentImportAjax.action?onHold=true",
                                getPost = form.attr("method"),
                                options;

                            tinyMCE.triggerSave();

                            // serialize the form to a string
                            var mainForm = form.serialize();

                            // find the postBody{n}, add the text and replace the postbody
                            var postDivList = document.getElementsByName(
                                "postBody"
                            );
                            for (i = 0; i < postDivList.length; i++) {
                                var childList =
                                    postDivList[i].parentElement.children;
                                for (j = 0; j < childList.length; j++) {
                                    childItem = childList[j];
                                    if (
                                        childItem.className.includes(
                                            "tinyMceInliteTextarea"
                                        )
                                    ) {
                                        var tmpValue =
                                            "&postBody=" +
                                            encodeURIComponent(
                                                childItem.innerText
                                            );
                                        mainForm = mainForm + tmpValue;
                                    }
                                }
                            }

                            options = {
                                cache: false,
                                type: getPost,
                                data: mainForm,
                                url: urlAction,
                                success: function(data) {
                                    validation.parse(data);
                                }
                            };

                            call.ajax(urlAction, options);
                            return false;
                        }
                    );

                    scoreAIText = function(selectedOption) {
                        var $this = this,
                            urlAction = "checkAIGeneratedAjax.action",
                            options = {
                                cache: false,
                                type: "POST",
                                data: {
                                    textToCheck: $("#generatedContent").val()
                                },
                                url: urlAction,
                                success: function(response) {
                                    $("#aiScoreField").val(response);
                                },
                                error: function(
                                    jqXHR,
                                    textStatus,
                                    errorThrown
                                ) {
                                    $("#aiScoreField").value("ERR");
                                    console.log("error::::", errorThrown);
                                }
                            };
                        call.skyjax(urlAction, options);
                    };

                    if ($("#writerAccountId").val() != null) {
                        $("#offer-assignment-module").show();
                    }
                    if ($("#show-assign-module").val() == "true") {
                        $("#btn-offer-writer").click();
                    }

                    if (
                        "/assignmentModify.action" ===
                            window.location.pathname ||
                        "/assignmentCreate.action" === window.location.pathname
                    ) {
                        initAssignment();
                    }
                    function initAssignment() {
                        contentTypeId = $("#selectedContentTypeId").val();
                        categoryId = $("#selectedCategoryId").val();
                        var contributorByLine = $("#writerByline").val();

                        var options = {
                            source:
                                "completeBylineAjax.action?categoryId=" +
                                categoryId,
                            select: function(event, ui) {
                                callWriterInfoMiniModule(ui.item.label);
                                setContributorLeaveData(ui.item);
                                showClaimDateWarning(ui.item);
                                showSubmitDeadlineWarning(ui.item);
                            },
                            search: function(event, ui) {
                                console.log("search started");
                            }
                        };
                        if (
                            "/assignmentModify.action" ===
                            window.location.pathname
                        ) {
                            contributorLeaveModule(
                                contributorByLine,
                                categoryId
                            );
                        }
                        setup.bylineAutocomplete(
                            "completeBylineAjax.action?categoryId=" +
                                categoryId +
                                "&noManagers=false",
                            options
                        );

                        $("#similar-topics-content").similarTopics({
                            categorySelect: $("#selectedCategoryId"),
                            contentRequestId: $("#contentRequestId"),
                            contentTypeId: $("#selectedContentTypeId").val(),
                            loading: $("#similar-topics-loading").val()
                        });
                    }

                    function setupSimilarTopicsDefaults() {
                        try {
                            if (
                                "function" ===
                                typeof $("#similar-topics-content")
                                    .similarTopics
                            ) {
                                $("#similar-topics-content").similarTopics({
                                    categorySelect:
                                        0 === $("#selectedCategoryId")
                                            ? $("#programCategoryId")
                                            : $("#selectedCategoryId"),
                                    contentRequestId:
                                        0 === $("#contentRequestId")
                                            ? null
                                            : $("#contentRequestId").val(),
                                    contentTypeId: $("#content-type-id").val(),
                                    loading: $("#similar-topics-loading").val(),
                                    button: $("#enteredButton"),
                                    ignoreCategory: true
                                });
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }

                    /* ==========================================================================
                 Review Proposals
                 ========================================================================== */

                    html.on("click", "#inline-close", function() {
                        $("#update-status-box").slideUp();
                        skyword.enableButtons();
                        $("#proposal-button-row").slideDown();
                        return false;
                    });

                    html.on("click", "#proposal-button-row button", function() {
                        var type = $(this).attr("id"),
                            urlAction,
                            getPost = $("#form-submit").attr("method");
                        if (type === "approve") {
                            urlAction = "proposalApproveAjax.action";
                        } else if (type === "decline") {
                            urlAction = "proposalDeclineAjax.action";
                        } else if (type === "declineOffer") {
                            urlAction = "proposalDeclineSuggestAjax.action";
                        }
                        console.log(urlAction);
                        options = {
                            cache: false,
                            type: "GET",
                            data: $("#form-submit").serialize(),
                            url: urlAction,
                            success: function(data) {
                                $("#proposal-button-row").slideUp();
                                $("#inline-box")
                                    .html(data)
                                    .show();
                                skyword.scrollTo("#inline-box", 0);
                                $("#update-status-box button").click(
                                    function() {
                                        var options = {
                                            cache: false,
                                            dataType: "html",
                                            data: $("#form-submit").serialize(),
                                            type: "POST",
                                            url: urlAction,
                                            success: function(data) {
                                                if (!validation.parse(data)) {
                                                    if (
                                                        type == "declineOffer"
                                                    ) {
                                                        //go to new page
                                                    } else {
                                                        $(
                                                            "#error-box"
                                                        ).slideUp();
                                                        $(
                                                            ".errorField"
                                                        ).removeClass(
                                                            "errorField"
                                                        );
                                                        $("#inline-box")
                                                            .fadeOut("fast")
                                                            .html(data)
                                                            .fadeIn("slow");
                                                        skyword.scrollTo(
                                                            "#inline-box",
                                                            0
                                                        );
                                                    }
                                                }
                                                customIcons.init();
                                            }
                                        };
                                        call.ajax(urlAction, options);
                                    }
                                );
                            },
                            error: function(data, status, err) {
                                console.log("failed in loading box");
                            }
                        };
                        call.ajax(urlAction, options);
                    });

                    if (
                        RegExp("(offerMultipleAssignments).(action)").test(
                            window.location.pathname
                        )
                    ) {
                        $(".hiddenWhileEmpty").hide();

                        var options = {
                            source:
                                "completeBylineAjax.action?programId=" +
                                $("#programId").val(),
                            select: function(event, ui) {
                                callWriterInfoMiniModule(ui.item.label);
                                $("#writerByline")
                                    .val(ui.item.label)
                                    .trim();
                                var paid = ui.item.programAccountId;
                                getAssignmentsForWriter(paid, null);
                                $("#programAccountId").val(paid);
                            }
                        };

                        setup.bylineAutocomplete(
                            "completeBylineAjax.action?programId=" +
                                $("#programId").val(),
                            options
                        );

                        html.on("click", "#offerMult-button", null, function() {
                            // the success box appends. wipe it clean.
                            $("#success-message-box div").html("");
                            $("#success-message-box").hide();
                            $("#error-box").hide();
                            var urlAction = $("#multiple-offer-form").attr(
                                    "action"
                                ),
                                options = {
                                    cache: false,
                                    type: "POST",
                                    url: urlAction,
                                    data: $("#multiple-offer-form").serialize(),
                                    dateType: "json",
                                    success: function(data) {
                                        validationUtil.parse(data);
                                        getAssignmentsForWriter(
                                            $("#programAccountId").val(),
                                            null
                                        );
                                    }
                                };
                            call.skyjax(urlAction, options);
                        });
                    }
                });
            }
        };
    }
]);

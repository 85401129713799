app.controller("calendarEventController", [
    "$scope",
    "$http",
    "$filter",
    "$timeout",
    "skyword",
    "$routeParams",
    "$sce",
    "$location",
    "$translate",
    "calendarEvent",
    "project",
    "ProjectFactory",
    "$window",
    "$rootScope",
    "AssetFactory",
    function(
        $scope,
        $http,
        $filter,
        $timeout,
        skyword,
        $routeParams,
        $sce,
        $location,
        $translate,
        calendarEvent,
        project,
        ProjectFactory,
        $window,
        $rootScope,
        AssetFactory
    ) {
        /*
                NOTE: We need a class on the body tag because the LESS file was written 
                with a slew of global selectors affecting all other pages of the site.
                - Adam B.
                */
        $("body").addClass("create-calendar-event");

        $scope.calEventForm = {};
        $scope.errorsEventName = false;
        $scope.errorsStartDate = false;
        $scope.errorsEndDate = false;
        $scope.calEventForm.projects = [];
        $scope.eventTypes = [];
        $scope.calEventForm.project = {};
        $scope.calEventForm.eventType = {};
        $scope.referrerPage = "";
        $scope.filter = "";

        // line bellow should fix:
        // https://skyword.atlassian.net/browse/SKY-14771
        $translate("angular.momentpicker.format").then(function(dateformat) {
            //$timeout(function () { // selectBoxIt was commented out.  Leaving snippet here just incase so it's not forgotten until confirming it's not needed.
            //    //$('#select-project').selectBoxIt();
            //}, 500);
            $scope.milliFormat = $translate.instant("angular.dateformat");

            $scope.dateformat = dateformat;
            var startDate = moment().format($scope.dateformat.toUpperCase());
            var endDate = startDate;
            var cal = $location.search().cal;
            $scope.referrerPage = skyword.getQstringParamByName("referrerPage");
            $scope.filter = $location.search().filter;
            $scope.calendarEventId = $scope.emptyCheck(
                $routeParams.calendarEventId
            );
            $scope.startDate = $scope.emptyCheck($routeParams.startDate);

            if (-1 !== $scope.startDate) {
                endDate = startDate = $scope.convertMillisToDate(
                    $scope.startDate,
                    $scope.dateformat.toUpperCase()
                );
            }
            $scope.calEventForm.startDate = startDate;
            $scope.calEventForm.endDate = endDate;
            $scope.programId = $scope.emptyCheck($routeParams.programId);
            $scope.publishCalendar =
                false === (!$scope.isUndefinedOrNull(cal) && "s" === cal);

            //set min date on end date field
            $scope.minDate = $scope.calEventForm.startDate.toString();
        });

        //watches start date to make sure end date is not before start date
        $scope.$watch("calEventForm.startDate", function(newValue, oldValue) {
            $scope.minDate = $scope.calEventForm.startDate.toString();
        });

        // default proj id from routeParams
        $scope.noProjMsg = $translate.instant("angular.donotassign");
        $scope.noEventTypeMsg = $translate.instant("angular.donotassign");
        $scope.calEventForm.projectId = $scope.emptyCheck(
            $routeParams.projectId
        );
        $scope.calEventForm.taxonomyValueId = $scope.emptyCheck(
            $routeParams.taxonomyValueId
        );
        $scope.calEventForm.calendarEventId = $scope.emptyCheck(
            $routeParams.calendarEventId
        );

        $scope.ref = function() {
            var ref = $scope.referrerPage;
            if ("projectDetails.action" === ref) {
                ref = ref + "?projectId=" + $scope.calEventForm.projectId;
            } else if (
                "assignmentList.action" === ref &&
                undefined !== $scope.filter &&
                null !== $scope.filter
            ) {
                ref = ref + "?filter=" + $scope.filter;
            } else if ("calendarEventDetails.action" === ref) {
                ref =
                    ref +
                    "?calendarEventId=" +
                    $scope.calEventForm.calendarEventId;
            }
            return ref;
        };

        //save calendar event
        $scope.saveCalendarEvent = function(calEvent) {
            // Clean up cal event.  First copy it, add dates.
            var cal = angular.copy(calEvent); // avoid scope clashes when converting the dates, because reasons

            cal.startDate = $scope.convertDateToMillis(
                moment(calEvent.startDate, $scope.dateformat).format(
                    "MM-DD-YYYY"
                ),
                $scope.milliFormat
            );
            cal.endDate = $scope.convertDateToMillis(
                moment(calEvent.endDate, $scope.dateformat).format(
                    "MM-DD-YYYY"
                ),
                $scope.milliFormat
            );
            cal.projectId = $scope.emptyCheckToNull(
                $scope.calEventForm.project.id
            );
            cal.taxonomyValueId = $scope.emptyCheckToNull(
                $scope.calEventForm.eventType.id
            );

            // copy programId from scope/routeParams if cal param obj value is null
            if ($scope.isUndefinedOrNull(cal.programId)) {
                cal.programId = $scope.programId;
            }

            $scope.errors = $scope.checkErrors();

            if (
                null === $scope.calendarEventId ||
                -1 === $scope.calendarEventId
            ) {
                cal.assetIdList = AssetFactory.getAssetList();
            }

            if (false === $scope.errors) {
                calendarEvent.saveCalendarEvent(
                    cal,
                    $scope.programId,
                    $scope.publishCalendar,
                    $scope.calendarEventId,
                    function(data) {
                        $window.location.href = $scope.ref();
                    },
                    function(data) {
                        $location.path("/sorry");
                    }
                );
            }
        };

        $rootScope.$on("projectsChanged", function(event, data) {
            //                console.log("projects changed");
            $scope.calEventForm.projects = data;
            $scope.calEventForm.project = ProjectFactory.getProject();
        });

        //get projects
        $scope.$watch("programId", function() {
            //                console.log($scope.programId);
            if (undefined !== $scope.programId) {
                var eventTypePromise = calendarEvent.getEventTypes(
                    $scope.programId
                );
                eventTypePromise.then(function(response) {
                    //                    console.log("foundevnettypes");
                    $scope.eventTypes = response.data;
                    if (null !== $scope.emptyCheckToNull($scope.eventTypes)) {
                        if ($scope.calEventForm.taxonomyValueId != -1) {
                            $scope.calEventForm.eventType = skyword.getObjByProperty(
                                $scope.eventTypes,
                                "id",
                                parseInt($scope.calEventForm.taxonomyValueId)
                            );
                        }
                    }
                });

                project.getActiveProjects(
                    $scope.programId,
                    true,
                    false,
                    function(data) {
                        //                console.log("foundprojects");
                        $scope.calEventForm.projects = data;
                        // set default project
                        if (
                            null !==
                            $scope.emptyCheckToNull(
                                $scope.calEventForm.projects
                            )
                        ) {
                            ProjectFactory.setDonotAssign({
                                id: -1,
                                title: $scope.noProjMsg
                            });
                            if ($scope.calEventForm.projectId != -1) {
                                $scope.calEventForm.project = skyword.getObjByProperty(
                                    $scope.calEventForm.projects,
                                    "id",
                                    parseInt($scope.calEventForm.projectId)
                                );
                            }
                        }
                    }
                );
            }
        });

        //get calendar event
        $scope.$watch("calendarEventId", function() {
            if (null === $scope.emptyCheckToNull($scope.calendarEventId)) {
                return;
            }

            if (-1 == $scope.calendarEventId) {
                $scope.calEventForm.project.id = -1;
                $scope.calEventForm.eventType.id = -1;
            }

            calendarEvent.getCalendarEvent(
                $scope.programId,
                $scope.calendarEventId,
                function(data) {
                    //                    console.log(data);
                    //                    console.log("in the method");
                    $scope.calEventForm.eventName = data.eventName;
                    $scope.calEventForm.eventDescription =
                        data.eventDescription;
                    $scope.calEventForm.startDate = $scope.convertMillisToDate(
                        data.startDate,
                        $translate.instant("angular.dateformat").toUpperCase()
                    );
                    $scope.calEventForm.endDate = $scope.convertMillisToDate(
                        data.endDate,
                        $translate.instant("angular.dateformat").toUpperCase()
                    );
                    $scope.calEventForm.programId = data.programId;
                    $scope.calEventForm.active = data.active;
                    $scope.calEventForm.enableResourceSharing =
                        data.enableResourceSharing;
                    $scope.calEventForm.taxonomyValueId = $scope.emptyCheckToNull(
                        data.taxonomyValueId
                    );
                    if (null === $scope.calEventForm.taxonomyValueId) {
                        $scope.calEventForm.taxonomyValueId = -1;
                    }

                    $scope.calEventForm.projectId = $scope.emptyCheckToNull(
                        data.projectId
                    );
                    if (null === $scope.calEventForm.projectId) {
                        $scope.calEventForm.projectId = -1;
                    }

                    if (
                        null !==
                            $scope.emptyCheckToNull(
                                $scope.calEventForm.projects
                            ) &&
                        $scope.calEventForm.projects.length > 0
                    ) {
                        $scope.calEventForm.project = skyword.getObjByProperty(
                            $scope.calEventForm.projects,
                            "id",
                            parseInt($scope.calEventForm.projectId)
                        );
                        if (false === $scope.calEventForm.project) {
                            // this project is archived (or possibly deleted, but we shouldn't see that)
                            // we need to load this project and add it to the top of the dropdown
                            // a user can't add events to an archived project, but events already associated
                            // will not be disassociated
                            project.getProject(
                                $scope.programId,
                                $scope.calEventForm.projectId,
                                function(data) {
                                    $scope.calEventForm.projects.unshift(data);
                                    $scope.calEventForm.project = data;
                                },
                                function(data) {
                                    //                                console.log(data);
                                }
                            );
                        }
                    } else {
                        $scope.calEventForm.project = { id: -1 };
                    }

                    if (
                        null !== $scope.emptyCheckToNull($scope.eventTypes) &&
                        $scope.eventTypes.length > 0
                    ) {
                        $scope.calEventForm.eventType = skyword.getObjByProperty(
                            $scope.eventTypes,
                            "id",
                            parseInt($scope.calEventForm.taxonomyValueId)
                        );
                    } else {
                        $scope.calEventForm.eventType = { id: -1 };
                    }
                },
                function(data) {
                    $location.path("/sorry");
                }
            );
        });

        $scope.checkErrors = function() {
            $scope.errors = false;
            //return $scope.checkDate() || $scope.checkEventName();
            return $scope.checkEventName();
        };

        $scope.checkEventName = function() {
            var eventName = $scope.calEventForm.eventName,
                error = false;
            if (
                "" === eventName ||
                null === $scope.emptyCheckToNull(eventName)
            ) {
                error = true;
                $scope.errorsEventName = true;
            } else {
                error = false;
            }
            return error;
        };

        $scope.checkDate = function() {
            var error = false;
            $scope.errorsStartDate = false;
            $scope.errorsEndDate = false;
            //if start date is after end date
            var startDate = $scope.emptyCheck(
                $scope.convertDateToMillis(
                    $scope.calEventForm.startDate,
                    $scope.dateformat
                )
            );
            var endDate = $scope.emptyCheck(
                $scope.convertDateToMillis(
                    $scope.calEventForm.endDate,
                    $scope.dateformat
                )
            );
            if (-1 === startDate) {
                $scope.calEventForm.startDate = moment().format(
                    $scope.dateformat.toUpperCase()
                );
            }
            if (-1 === endDate) {
                $scope.calEventForm.endDate = $scope.calEventForm.startDate;
            }
            if (startDate > endDate) {
                $scope.calEventForm.endDate = $scope.calEventForm.startDate;
                error = false;
            } else if (startDate === endDate) {
                error = false;
            } else {
                $scope.errorsStartDate = -1 === startDate;
                $scope.errorsEndDate = -1 === endDate;
                if ($scope.errorsStartDate || $scope.errorsEndDate) {
                    //console.log("Date Errors. " + startDate + " " + endDate);
                    error = true;
                }
            }
            return error;
        };

        $scope.showDeleteOverlay = function() {
            var opts = {
                html:
                    '<div class="event-delete choose-pane"><h1>' +
                    $translate.instant("angular.eventdeleteconfirmation") +
                    "</h1>" +
                    '<button class="standard" onclick="callAngularDelete()"><span>' +
                    $translate.instant("angular.button.delete") +
                    "</span></button>" +
                    '<a onclick="parent.$.colorbox.close();"><span>' +
                    $translate.instant("angular.button.cancel") +
                    "</span></a>" +
                    "</div>"
            };
            $.colorbox(opts);
        };

        window.callAngularDelete = function() {
            $scope.$apply(function() {
                $scope.deleteEvent();
            });
        };

        $scope.deleteEvent = function() {
            calendarEvent.deleteCalendarEvent(
                $scope.programId,
                $scope.calendarEventId,
                $scope.publishCalendar,
                function(data) {
                    $window.location.href = $scope.ref();
                },
                function(data) {
                    $location.path("/sorry");
                }
            );
        };
    }
]);

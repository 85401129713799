app.controller("creativeBrief", [
    "$scope",
    "creativeBrief",
    function($scope, creativeBrief) {
        $scope.get = function() {
            $scope.state = "view";
            creativeBrief.get($scope.contentRequestId, function(data) {
                if ("" !== data && null !== data) {
                    $scope.brief = data;
                } else {
                    $scope.initBrief();
                }

                if ($scope.brief.id === undefined) {
                    $scope.isShowingSaveButton = false;
                } else {
                    $scope.isShowingSaveButton = true;
                }

                $scope.brief.contentRequestId = $scope.contentRequestId;
                if ($scope.brief.scriptChoice == 2) {
                    $scope.brief.scriptText = $scope.brief.script;
                }
                $scope.remove = false;
                $scope.getSelecting();
            });
        };
        $scope.resetBrief = function() {
            $scope.state = "remove";
        };
        $scope.initBrief = function() {
            $scope.brief = {};
            $scope.brief.creativeBriefSamples = [];
            $scope.brief.scriptChoice = 0;
            $scope.brief.contentRequestId = $scope.contentRequestId;
            $scope.state = "view";
        };
        $scope.removeConfirm = function() {
            creativeBrief.remove($scope.brief, function() {
                $scope.initBrief();
            });
        };
        $scope.removeCancel = function() {
            $scope.state = "view";
        };
        $scope.save = function() {
            //db uses same field for both script options, so this needs to be set to the single field when saving
            if ($scope.brief.scriptChoice == 2) {
                $scope.brief.script = $scope.brief.scriptText;
            }
            if (
                $scope.brief.results != null &&
                $scope.brief.results != undefined
            ) {
                $scope.results = $scope.brief.results;
                if ($scope.brief.scriptChoice == 1) {
                    $scope.brief.script = $scope.brief.results.localUrl;
                }
                delete $scope.brief.results;
            }
            delete $scope.brief.scriptText;
            delete $scope.brief.scriptLocalUrl;

            creativeBrief.save($scope.brief, function(data) {
                $scope.brief.id = data.id;
                $scope.saved = true;
                $scope.brief.scriptText = $scope.brief.script;
                $scope.brief.results = $scope.results;
            });
        };
        $scope.$watch("brief.workTypeId", function(newValue, oldValue) {
            $scope.getSelecting();
        });
        $scope.$watch("brief.videoTypeId", function(newValue, oldValue) {
            $scope.getSelecting();
        });
        $scope.getSelecting = function() {
            if (
                undefined !== $scope.brief &&
                $scope.editable &&
                ($scope.brief.workTypeId != null &&
                    $scope.brief.videoTypeId != null)
            ) {
                $scope.selecting = true;
            } else {
                $scope.selecting = false;
            }
        };

        $scope.toggleDisplay = function() {
            $scope.briefOpen =
                $scope.briefOpen == "Expand" ? "Collapse" : "Expand";
        };
        $scope.toggleInstructionsDisplay = function() {
            $scope.instructionsOpen =
                $scope.instructionsOpen == "Expand" ? "Collapse" : "Expand";
        };
        $scope.briefOpen = $scope.open === false ? "Collapse" : "Expand";
        $scope.instructionsOpen = $scope.open === false ? "Collapse" : "Expand";
        $scope.get();
    }
])
    .controller("creativeBriefScript", [
        "$scope",
        "creativeBrief",
        function($scope, creativeBrief) {}
    ])
    .controller("creativeDirection", [
        "$scope",
        "creativeBrief",
        "skyword",
        function($scope, creativeBrief, skyword) {
            $scope.addSample = function() {
                $scope.$parent.brief.creativeBriefSamples.unshift({
                    typeId: null
                });
                $scope.creating = true;
            };
            $scope.remove = function(sample) {
                sample.remove = true;
            };
            $scope.removeConfirm = function(sample) {
                $scope.$parent.brief.creativeBriefSamples.splice(
                    skyword.getArrayIndexByProperty(
                        $scope.$parent.brief.creativeBriefSamples,
                        "linkId",
                        sample.linkId
                    ),
                    1
                );
                creativeBrief.removeCreativeBriefSample(sample.id);
            };

            $scope.removeCancel = function(sample) {
                delete sample.remove;
            };

            $scope.saveCreativeBriefSample = function(sample) {
                if (sample.creativeBriefId === undefined) {
                    sample.creativeBriefId = $scope.$parent.brief.id;
                }

                creativeBrief.saveCreativeBriefSample(sample, function(data) {
                    sample.id = data.id;
                });
            };

            $scope.addCancel = function(asset) {
                if (asset.linkId == null) {
                    $scope.$parent.brief.creativeBriefSamples.splice(0, 1);
                }
                $scope.creating = false;
            };
            $scope.scrapeLink = function(sample) {
                sample.state = "loading";
                delete sample.invalid;
                creativeBrief.scrape(
                    $scope.$parent.contentRequestId,
                    sample.url,
                    function(data) {
                        delete sample.state;
                        if (data != "" && data.typeId !== null) {
                            sample.typeId = data.typeId;
                            sample.linkId = data.linkId;
                            delete sample.url;
                            delete sample.invalid;
                            $scope.creating = false;
                        } else {
                            sample.invalid = true;
                        }
                    }
                );
            };

            $scope.toggleDisplay = function() {
                $scope.open = $scope.open == "Expand" ? "Collapse" : "Expand";
            };
            $scope.open = $scope.open === false ? "Collapse" : "Expand";
        }
    ]);

app.controller("reportsHome", [
    "$scope",
    function($scope) {
        $(function() {
            var body = $("body");
            body.on("click", "#content-analytics-link", function() {
                body.find("#content-analytics-link").addClass("active");
                body.find("#content-analytics").show();
                body.find("#sitewide-analytics-link").removeClass("active");
                body.find("#sitewide-analytics").hide();
                body.find("#lead-analytics-link").removeClass("active");
                body.find("#lead-analytics").hide();
            });

            body.on("click", "#sitewide-analytics-link", function() {
                body.find("#content-analytics-link").removeClass("active");
                body.find("#content-analytics").hide();
                body.find("#sitewide-analytics-link").addClass("active");
                body.find("#sitewide-analytics").show();
                body.find("#lead-analytics-link").removeClass("active");
                body.find("#lead-analytics").hide();
            });

            body.on("click", "#lead-analytics-link", function() {
                body.find("#content-analytics-link").removeClass("active");
                body.find("#content-analytics").hide();
                body.find("#sitewide-analytics-link").removeClass("active");
                body.find("#sitewide-analytics").hide();
                body.find("#lead-analytics-link").addClass("active");
                body.find("#lead-analytics").show();
            });
        });
    }
])
    .controller("financeReports", ["$scope", function($scope) {}])
    .controller("reports", ["$scope", function($scope) {}])
    .controller("selectReport", ["$scope", function($scope) {}])
    .controller("managerReportVideoSummary", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("siteownerReportVideoSummary", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("writerReportArticle", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("individualArticleReport", [
        "$scope",
        "cards",
        "reports",
        function($scope, cards, reports) {
            reports.init();

            //onReady
            $(function() {
                reports.performanceCardWidth();
                cards.cardTotalMeters();
            });
        }
    ])
    .controller("individualArticleReportArticleViewAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("individualArticleReportAjax", ["$scope", function($scope) {}])
    .controller("getKeywordRankDataAjax", ["$scope", function($scope) {}])
    .controller("getKeywordRankedReportGraphAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("writerReportMonthlySummary", ["$scope", function($scope) {}])
    .controller("managerReportProgramSummary", [
        "$scope",
        "reportProgramSummary",
        "reports",
        function($scope, reportProgramSummary, reports) {
            reportProgramSummary.init();
            reports.datepicker($scope);

            $(function() {
                var body = $("body");
                body.on("click", "#content-analytics-link", function() {
                    body.find("#content-analytics-link").addClass("active");
                    body.find("#content-analytics").show();
                    body.find("#sitewide-analytics-link").removeClass("active");
                    body.find("#sitewide-analytics").hide();
                    body.find("#lead-analytics-link").removeClass("active");
                    body.find("#lead-analytics").hide();
                });

                body.on("click", "#sitewide-analytics-link", function() {
                    body.find("#content-analytics-link").removeClass("active");
                    body.find("#content-analytics").hide();
                    body.find("#sitewide-analytics-link").addClass("active");
                    body.find("#sitewide-analytics").show();
                    body.find("#lead-analytics-link").removeClass("active");
                    body.find("#lead-analytics").hide();
                });

                body.on("click", "#lead-analytics-link", function() {
                    body.find("#content-analytics-link").removeClass("active");
                    body.find("#content-analytics").hide();
                    body.find("#sitewide-analytics-link").removeClass("active");
                    body.find("#sitewide-analytics").hide();
                    body.find("#lead-analytics-link").addClass("active");
                    body.find("#lead-analytics").show();
                });
            });
        }
    ])
    .controller("engagementSummaryReport", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.init();
            reports.datepicker($scope);
        }
    ])
    .controller("managerReportSocialReach", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("videoPaymentsReport", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("managerReportArticleSummary", [
        "$scope",
        "skyword",
        "reports",
        function($scope, skyword, reports) {
            $("#contenttype").on("change", function() {
                skyword.populateCategories(
                    "categoryDiv",
                    "selectedCategoryId",
                    "contenttype",
                    $("#programId").val(),
                    false,
                    $("#programCategory").val(),
                    "selectedCategoryId",
                    false
                );
            });

            reports.datepicker($scope);
        }
    ])
    .controller("managerReportSocialResults", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("siteownerReportSocialResults", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("managerReportFacebookInsights", [
        "$scope",
        "reports",
        function($scope, reports) {
            //FYI - injecting reportFacebookInsights is not working need to fix
            //reportFacebookInsights.init();
            reports.datepicker($scope);
        }
    ])
    .controller("siteownerReportFacebookInsights", [
        "$scope",
        "reports",
        function($scope, reports) {
            //FYI - injecting reportFacebookInsights is not working need to fix
            //reportFacebookInsights.init();
            reports.datepicker($scope);
        }
    ])
    .controller("managerReportFacebookInsightSummary", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("siteownerReportFacebookInsightSummary", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("managerReportContentResultsContributor", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("siteownerReportContentResultsContributor", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("managerReportSocialContributors", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("siteownerReportSocialContributors", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("managerReportWriterSummary", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("managerReportRefererSummary", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.init();
            reports.datepicker($scope);

            //onReady
            $(function() {
                reports.createReferralPie();
                reports.createReferralLine();
            });
        }
    ])
    .controller("siteownerReportRefererSummary", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.init();
            reports.datepicker($scope);

            //onReady
            $(function() {
                reports.createReferralPie();
                reports.createReferralLine();
            });
        }
    ])
    .controller("managerReportKeywordRankResults", [
        "$scope",
        "reports",
        "keywords",
        function($scope, reports, keywords) {
            reports.keywordRankGraph();
            keywords.init();
            $("#find-button").click(function() {
                var theForm = document.forms["writerReportArticle"],
                    input = document.createElement("input");
                input.type = "hidden";
                input.name = "phrase";
                input.value = $("#phrase").val();
                theForm.appendChild(input);
                theForm.action = theForm.action + "#searchField";
                theForm.processReport.value = true;
                theForm.submit();
            });
        }
    ])
    .controller("managerReportCategoryResults", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("managerReportPaymentSummary", ["$scope", function($scope) {}])
    .controller("earningsSummaryReportAjax", ["$scope", function($scope) {}])
    .controller("managerReportArticleResults", ["$scope", function($scope) {}])
    .controller("contentResultsReportAjax", ["$scope", function($scope) {}])
    .controller("siteownerReportProgramSummary", [
        "$scope",
        "reportProgramSummary",
        "reports",
        function($scope, reportProgramSummary, reports) {
            reportProgramSummary.init();
            reports.datepicker($scope);
        }
    ])
    .controller("siteownerReportArticleSummary", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("siteownerReportWriterSummary", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("siteownerReportKeywordRankResults", [
        "$scope",
        "reports",
        "keywords",
        function($scope, reports, keywords) {
            reports.keywordRankGraph();
            keywords.init();
        }
    ])
    .controller("siteownerReportCategoryResults", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("siteownerReportArticleResults", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("siteownerReportSocialReach", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("contributorReportOverallEarningsSummary", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("financeExtractReport", ["$scope", function($scope) {}])
    .controller("financeReconciliationReport", ["$scope", function($scope) {}])
    .controller("financeNewWriterReport", ["$scope", function($scope) {}])
    .controller("financeResidencyReport", ["$scope", function($scope) {}])
    .controller("financeAdditionalEarningsReport", [
        "$scope",
        function($scope) {}
    ])
    .controller("financeEscrowHistoryReport", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("financeReportEscrowBalance", ["$scope", function($scope) {}])
    .controller("financeReportPhotosPurchased", ["$scope", function($scope) {}])
    .controller("managerReportArticlesDelivered", [
        "$scope",
        "reports",
        function($scope, reports) {
            reports.datepicker($scope);
        }
    ])
    .controller("viewPanelProgramInfoAjax", ["$scope", function($scope) {}])
    .controller("viewPanelProgramInfoGraphAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("viewPanelRefererTypeAjax", ["$scope", function($scope) {}])
    .controller("viewPanelSocialActivityAjax", ["$scope", function($scope) {}])
    .controller("viewPanelSocialContentAjax", ["$scope", function($scope) {}])
    .controller("viewPanelSocialContributorsAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("viewPanelTopContentAjax", ["$scope", function($scope) {}])
    .controller("viewPanelTopReferersAjax", ["$scope", function($scope) {}])
    .controller("viewPanelKeywordRankStatsAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("viewPanelKeywordRankGraphAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("viewProgramSummaryTotalViewsGraphAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("viewFacebookInsightActivityGraphAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("viewFacebookInsightViewsGraphAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("viewPanelFBInsightsAjax", ["$scope", function($scope) {}])
    .controller("viewPanelFBImpressionsByDateAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("viewPanelFBImpressionsByContentTypeAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("viewPanelFBLikesByDateAjax", ["$scope", function($scope) {}])
    .controller("viewPanelFBLikesByContentTypeAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("viewPanelFBSharesByDateAjax", ["$scope", function($scope) {}])
    .controller("viewPanelFBSharesByContentTypeAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("viewPanelFBCommentsByDateAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("viewPanelFBCommentsByContentTypeAjax", [
        "$scope",
        function($scope) {}
    ]);

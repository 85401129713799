app.controller("assetLibrary", [
    "$http",
    "$scope",
    "assetLibrary",
    "skyword",
    "AssetFactory",
    "digitalAsset",
    "search",
    "content",
    "SkyFactory",
    "$translate",
    "videoCommentService",
    "$timeout",
    function(
        $http,
        $scope,
        assetLibrary,
        skyword,
        AssetFactory,
        digitalAsset,
        search,
        content,
        SkyFactory,
        $translate,
        videoCommentService,
        $timeout
    ) {
        //mock data for now
        var pageHome = document.getElementById("page-home");
        $scope.loading = false;
        $scope.creating = false;
        $scope.open = "collapse";
        $scope.arrow = "expand";
        $scope.assets = [];
        $scope.assetIdList = [];
        $scope.removeAsset = false;
        $scope.enteredKeyword = "";
        $scope.isRfpView = false;
        $scope.agencyId = pageHome.getAttribute("data-enterpriseid");
        $scope.enterpriseId = pageHome.getAttribute("data-enterpriseid");
        $scope.accountTypeId = pageHome.getAttribute("data-accounttypeid");
        $scope.actionName = pageHome.getAttribute("data-actionname");
        $scope.userFileDescription = [];
        $scope.userFileName = [];

        function load() {
            if ($("#page").hasClass("writeIdea")) {
                skyword.matchColumns();
            }

            $scope.isRfpView = $scope.actionName === "rfpView";

            if (undefined !== $scope.contentRequestId) {
                assetLibrary.getAssets($scope.contentRequestId, function(data) {
                    if ("" !== data) {
                        $scope.assets = data;
                        // add the file extensions and helpers to the array
                        for (var i = 0; i < data.length; i++) {
                            $scope.evalArrayItem(data[i], data[i]);
                        }
                    } else {
                        $scope.assets = [];
                    }
                    $scope.initAsset();
                });
            } else if (undefined !== $scope.calendarEventId) {
                $scope.$watch("calendarEventId", function() {
                    if (
                        null === $scope.calendarEventId ||
                        undefined === $scope.calendarEventId
                    ) {
                        $scope.assets = [];
                        return;
                    }
                    assetLibrary.getEventAssets(
                        $scope.calendarEventId,
                        $scope.programId,
                        function(data) {
                            if ("" !== data) {
                                $scope.assets = data;
                                // add the file extensions and helpers to the array
                                for (var i = 0; i < data.length; i++) {
                                    $scope.evalArrayItem(data[i], data[i]);
                                }
                            } else {
                                $scope.assets = [];
                            }
                            $scope.initAsset();
                        }
                    );
                });
            } else if (undefined !== $scope.projectId) {
                $scope.$watch("projectId", function() {
                    if (
                        null === $scope.projectId ||
                        undefined === $scope.projectId
                    ) {
                        $scope.assets = [];
                        return;
                    }
                    assetLibrary.getProjectResources($scope.projectId, function(
                        data
                    ) {
                        if ("" !== data) {
                            $scope.assets = data;
                            // add the file extensions and helpers to the array
                            for (var i = 0; i < data.length; i++) {
                                $scope.evalArrayItem(data[i], data[i]);
                            }
                        } else {
                            $scope.assets = [];
                        }
                        $scope.initAsset();
                    });
                });
            } else if (undefined !== $scope.campaignId) {
                $scope.$watch("campaignId", function() {
                    if (
                        null === $scope.campaignId ||
                        undefined === $scope.campaignId
                    ) {
                        $scope.assets = [];
                        return;
                    }
                    assetLibrary.getCampaignResources(
                        $scope.campaignId,
                        $scope.enterpriseId,
                        function(data) {
                            if ("" !== data) {
                                $scope.assets = data;
                                // add the file extensions and helpers to the array
                                for (var i = 0; i < data.length; i++) {
                                    $scope.evalArrayItem(data[i], data[i]);
                                }
                            } else {
                                $scope.assets = [];
                            }
                            $scope.initAsset();
                        }
                    );
                });
            } else {
                if (
                    undefined !== AssetFactory.getAssetList() &&
                    AssetFactory.getAssetList().length > 0
                ) {
                    assetLibrary.getAssetsNoId(
                        AssetFactory.getAssetList(),
                        function(data) {
                            if ("" !== data) {
                                $scope.assets = data;
                                // add the file extensions and helpers to the array
                                for (var i = 0; i < data.length; i++) {
                                    $scope.evalArrayItem(data[i], data[i]);
                                }
                            } else {
                                $scope.assets = [];
                            }
                            $scope.initAsset();
                        }
                    );
                }
            }
        }

        load();

        $scope.toggleResourceSharing = function() {
            let objectType;
            let objectId;
            if (undefined !== $scope.projectId) {
                objectType = "project";
                objectId = $scope.projectId;
            } else if (undefined !== $scope.campaignId) {
                objectType = "campaign";
                objectId = $scope.campaignId;
            } else if (undefined !== $scope.calendarEventId) {
                objectType = "calendarEvent";
                objectId = $scope.calendarEventId;
            }
            $scope.enableResourceSharing =
                $scope.enableResourceSharing === false ? true : false;
            assetLibrary.toggleEnableResourceSharing(
                objectType,
                objectId,
                $scope.enableResourceSharing,
                function(data) {
                    console.log("success", data);
                }
            );
        };

        $scope.editResource = function editResource(e, isOk) {
            var parent = angular
                .element(e.target)
                .parent()
                .parent();
            var btn = parent.find("button");
            if (isOk) {
                btn.removeClass("disabled");
            }
        };

        $scope.isContentRequest = function isContentRequest() {
            return undefined !== $scope.contentRequestId;
        };

        $scope.isCalendarEvent = function isCalendarEvent() {
            return (
                undefined !== $scope.calendarEventId &&
                undefined === $scope.contentRequestId &&
                undefined === $scope.campaignId &&
                undefined === $scope.projectId
            );
        };

        $scope.isCampaign = function isCampaign() {
            return (
                undefined !== $scope.campaignId &&
                undefined === $scope.calendarEventId &&
                undefined === $scope.contentRequestId &&
                undefined === $scope.projectId
            );
        };

        $scope.isProject = function isProject() {
            return (
                undefined !== $scope.projectId &&
                undefined === $scope.campaignId &&
                undefined === $scope.calendarEventId &&
                undefined === $scope.contentRequestId
            );
        };

        $scope.isIdea = function isIdea() {
            return (
                undefined === $scope.projectId &&
                undefined === $scope.campaignId &&
                undefined === $scope.calendarEventId &&
                undefined === $scope.contentRequestId
            );
        };

        $scope.evalArrayItem = function(sourceArrayItem, destinationArrayItem) {
            var extension = /[0-9a-z]+$/i;
            // if assetUrl is NULL then it is a file and not a link
            if (
                sourceArrayItem.assetUrl == null ||
                (null !== sourceArrayItem.assetTypeId &&
                    (6 === sourceArrayItem.assetTypeId ||
                        7 === sourceArrayItem.assetTypeId ||
                        8 === sourceArrayItem.assetTypeId ||
                        9 === sourceArrayItem.assetTypeId))
            ) {
                if (
                    8 !== sourceArrayItem.assetTypeId &&
                    9 !== sourceArrayItem.assetTypeId
                ) {
                    destinationArrayItem.assetUrl = sourceArrayItem.localUrl;
                    destinationArrayItem.localUrl = sourceArrayItem.localUrl;
                }
                destinationArrayItem.assetThumbnailUrl = SkyFactory.reallyEmptyCheck(
                    sourceArrayItem.assetThumbnailUrl
                )
                    ? sourceArrayItem.thumbnailImageUrl
                    : sourceArrayItem.assetThumbnailUrl;

                if (sourceArrayItem.assetFileName != null) {
                    var ext = sourceArrayItem.assetFileName.match(extension);
                    // add the file extension to the array to determine the type of icon
                    destinationArrayItem.ext = ext[0];
                    // determine whether it is an image or icon thumbnail
                    if (
                        destinationArrayItem.ext === "jpg" ||
                        destinationArrayItem.ext === "jpeg" ||
                        destinationArrayItem.ext === "gif" ||
                        destinationArrayItem.ext === "png"
                    ) {
                        destinationArrayItem.thumbnailType = "image";
                    } else {
                        destinationArrayItem.thumbnailType = "icon";
                    }
                } else if (9 === sourceArrayItem.assetTypeId) {
                    destinationArrayItem.thumbnailType = "external-asset";
                }
            } else {
                //destinationArrayItem.localUrl = sourceArrayItem.assetUrl;
                if (destinationArrayItem.type === "file") {
                    if (
                        8 !== sourceArrayItem.assetTypeId &&
                        9 !== sourceArrayItem.assetTypeId
                    ) {
                        destinationArrayItem.assetUrl =
                            sourceArrayItem.localUrl;
                        destinationArrayItem.localUrl =
                            sourceArrayItem.localUrl;
                    }
                    destinationArrayItem.assetThumbnailUrl = SkyFactory.reallyEmptyCheck(
                        sourceArrayItem.assetThumbnailUrl
                    )
                        ? sourceArrayItem.thumbnailImageUrl
                        : sourceArrayItem.assetThumbnailUrl;

                    if (sourceArrayItem.assetFileName != null) {
                        var ext = sourceArrayItem.assetFileName.match(
                            extension
                        );
                        // add the file extension to the array to determine the type of icon
                        destinationArrayItem.ext = ext[0];
                        // determine whether it is an image or icon thumbnail
                        if (
                            destinationArrayItem.ext === "jpg" ||
                            destinationArrayItem.ext === "jpeg" ||
                            destinationArrayItem.ext === "gif" ||
                            destinationArrayItem.ext === "png"
                        ) {
                            destinationArrayItem.thumbnailType = "image";
                        } else {
                            destinationArrayItem.thumbnailType = "icon";
                        }
                    } else if (9 === sourceArrayItem.assetTypeId) {
                        destinationArrayItem.thumbnailType = "external-asset";
                    }
                } else {
                    destinationArrayItem.ext = "url";
                    // if assetUrl is NOT NULL and assetFileName is NULL then it gets a link icon
                    if (
                        sourceArrayItem.assetFileName === null ||
                        typeof sourceArrayItem.assetFileName === "undefined"
                    ) {
                        destinationArrayItem.thumbnailType = "icon";
                        // otherwise show the OG:IMAGE asset scraped from the link
                    } else {
                        destinationArrayItem.thumbnailType = "image";
                    }
                }
            }
        };
        $scope.remove = function(asset) {
            asset.remove = true;
        };
        $scope.removeConfirm = function(asset) {
            $scope.removeAsset = true;
            assetLibrary.remove(asset, function() {
                $scope.assets.splice(
                    skyword.getArrayIndexByProperty(
                        $scope.assets,
                        "id",
                        asset.id
                    ),
                    1
                );
                AssetFactory.removeAsset(asset.id);
                $scope.removeAsset = false;
            });
        };
        $scope.removeCancel = function(asset) {
            asset.remove = false;
            $scope.removeAsset = false;
        };
        $scope.save = function(asset) {
            asset.saved = true;
            if (asset.type === "file") {
                let userDescription = $scope.userFileDescription.filter(
                    (item) => item.id === asset.id
                );
                let userFilename = $scope.userFileName.filter(
                    (item) => item.id === asset.id
                );
                if (userDescription.length > 0) {
                    asset.userDescription = userDescription[0].value;
                    $scope.userFileDescription = $scope.userFileDescription.filter(
                        (item) => item.id !== asset.id
                    );
                }
                if (userFilename.length > 0) {
                    if (userFilename[0].value === "") {
                        asset.invalid = true;
                        return;
                    }
                    asset.assetTitle = userFilename[0].value;
                    $scope.userFileName = $scope.userFileName.filter(
                        (item) => item.id !== asset.id
                    );
                }
            }

            /**
             * SKY-15067  onsaveafter on the form in asset-library.html continues to call this method
             * when deleting an asset and sometimes causes a race condition in which the asset will
             * update at the same time and not be deleted.
             */

            if ($scope.removeAsset) {
                $scope.removeAsset = false;
                return;
            }

            if ("dam" === asset.type) {
                var assetPromise = assetLibrary.reuse(
                    $scope.dam.reuseDigitalAssetDTO
                );
                assetPromise.then(function(assetTemp) {
                    console.log("assetTemp", assetTemp);
                    $scope.assets = [];
                    $scope.dam = angular.copy(reset);
                    /**
                     * TODO: The following isn't ideal and treats dam objects differently.  However getting through the shift / unshift madness of adding an asset is a timesink at the moment.
                     * This should be revisited in the future and / or The Asset Library completely rewritten to avoid this trap of extending it.
                     */
                    $scope.updateAssetIdList(assetTemp.data);
                    load();
                });
                asset.type = null;
            } else {
                assetLibrary.save(asset, function(data) {
                    asset.fullName = data.fullName;
                    asset.uploadDate = data.uploadDate;
                    asset.assetDescription = data.assetDescription;
                    asset.assetTitle = data.assetTitle;
                    $scope.evalArrayItem(data, asset);
                    $scope.updateAssetIdList(asset);
                });
            }
        };
        function cleanURL(url) {
            return url.replace(
                /[^A-Za-z0-9_.\-~:\/?#\[\]@!$&'()*+,;=%]+/g,
                " "
            );
        }
        $scope.scrapeLink = function(url, form, asset) {
            $scope.loading = false;
            if ("" !== $.trim(url)) {
                if (-1 === asset.id) {
                    //turn on loading button
                    if (undefined !== $scope.contentRequestId) {
                        $scope.loading = true;
                        assetLibrary.scrape(
                            cleanURL(url),
                            $scope.contentRequestId,
                            $scope.programId,
                            function(data) {
                                //turn off loader gif
                                $scope.loading = false;
                                if (data.errorMap != null) {
                                    asset.error = data.errorMap.assetUrl;
                                } else {
                                    if (null != data.id) {
                                        asset.error = null;
                                        asset.id = data.id;
                                        asset.assetDescription =
                                            data.assetDescription;
                                        asset.localUrl = data.localUrl;
                                        $scope.validate(form, asset);
                                    }
                                }
                            }
                        );
                    } else if (undefined !== $scope.calendarEventId) {
                        $scope.loading = true;
                        assetLibrary.scrapeEvent(
                            url,
                            $scope.calendarEventId,
                            $scope.programId,
                            function(data) {
                                //turn off loader gif
                                $scope.loading = false;
                                if (data.errorMap != null) {
                                    asset.error = data.errorMap.assetUrl;
                                } else {
                                    if (null != data.id) {
                                        asset.error = null;
                                        asset.id = data.id;
                                        asset.assetDescription =
                                            data.assetDescription;
                                        asset.localUrl = data.localUrl;
                                        $scope.validate(form, asset);
                                        $scope.updateAssetIdList(asset);
                                    }
                                }
                            }
                        );
                    } else if (undefined !== $scope.campaignId) {
                        $scope.loading = true;
                        assetLibrary.scrapeCampaign(
                            url,
                            $scope.campaignId,
                            $scope.enterpriseId,
                            function(data) {
                                //turn off loader gif
                                $scope.loading = false;
                                if (data.errorMap != null) {
                                    asset.error = data.errorMap.assetUrl;
                                } else {
                                    if (null != data.id) {
                                        asset.error = null;
                                        asset.id = data.id;
                                        asset.assetDescription =
                                            data.assetDescription;
                                        asset.localUrl = data.localUrl;
                                        $scope.validate(form, asset);
                                        $scope.updateAssetIdList(asset);
                                    }
                                }
                            }
                        );
                    } else if (undefined !== $scope.projectId) {
                        $scope.loading = true;
                        assetLibrary.scrapeProject(
                            url,
                            $scope.projectId,
                            $scope.programId,
                            function(data) {
                                //turn off loader gif
                                $scope.loading = false;
                                if (data.errorMap != null) {
                                    asset.error = data.errorMap.assetUrl;
                                } else {
                                    if (null != data.id) {
                                        asset.error = null;
                                        asset.id = data.id;
                                        asset.assetDescription =
                                            data.assetDescription;
                                        asset.localUrl = data.localUrl;
                                        $scope.validate(form, asset);
                                        $scope.updateAssetIdList(asset);
                                    }
                                }
                            }
                        );
                    } else {
                        $scope.loading = true;
                        assetLibrary.scrape(
                            url,
                            null,
                            $scope.programId,
                            function(data) {
                                //turn off loader gif
                                $scope.loading = false;
                                if (data.errorMap != null) {
                                    asset.error = data.errorMap.assetUrl;
                                } else {
                                    if (null != data.id) {
                                        asset.error = null;
                                        asset.id = data.id;
                                        asset.assetDescription =
                                            data.assetDescription;
                                        asset.localUrl = data.localUrl;
                                        $scope.validate(form, asset);
                                        $scope.updateAssetIdList(asset);
                                    }
                                }
                            }
                        );
                    }
                }
            }
        };
        $scope.changeType = function(form, type) {
            $scope.assets.splice(0, 1);
            //if ("dam" !== type) {
            $scope.initAsset();
            //}

            $scope.assets[0].type = type;
            $scope.selectedTab = type;
            $scope.validate(form, $scope.assets[0]);
            $scope.dam = angular.copy(reset);
            if (
                document.getElementById("keywordTemplateElements[0].inputValue")
            ) {
                $scope.dam.textSearch = document.getElementById(
                    "keywordTemplateElements[0].inputValue"
                ).value;
            }

            if (type === "dam") {
                setTimeout(function() {
                    $("#select-contentType").selectBoxIt();
                }, 500);
            }
        };

        $scope.handlePaste = function(e, url, form, asset) {
            var clipboardData, pastedData, cleanedUrl;
            clipboardData =
                e.originalEvent.clipboardData ||
                window.originalEvent.clipboardData;
            pastedData = clipboardData.getData("text/plain");
            cleanedUrl = cleanURL(pastedData);
            //clipboardData.setData('text/plain', cleanedUrl);
            e.stopPropagation();
            e.preventDefault();
            e.originalEvent.currentTarget.value = cleanedUrl;
            $scope.scrapeLink(cleanedUrl, form, asset);
        };

        $scope.updateAssetIdList = function(asset) {
            if (
                undefined == $scope.calendarEventId &&
                undefined == $scope.contentRequestId &&
                undefined == $scope.projectId
            ) {
                AssetFactory.addUnique(asset.id);
            }
        };

        $scope.changeUserFilename = function(event, asset) {
            if (asset.type === "file") {
                if (event.target.value === "") {
                    asset.invalid = true;
                    return;
                } else {
                    asset.invalid = false;
                }
                let userFilenames = [...$scope.userFileName];
                let userFilename = userFilenames.filter(
                    (item) => item.id === asset.id
                );
                if (userFilename.length > 0) {
                    userFilename[0].value = event.target.value;
                } else {
                    $scope.userFileName.push({
                        id: asset.id,
                        value: event.target.value
                    });
                }
            }
        };

        $scope.changeUserDescription = function(event, asset) {
            if (asset.type === "file") {
                let userDescriptions = [...$scope.userFileDescription];
                let userDescription = userDescriptions.filter(
                    (item) => item.id === asset.id
                );
                if (userDescription.length > 0) {
                    userDescription[0].value = event.target.value;
                } else {
                    $scope.userFileDescription.push({
                        id: asset.id,
                        value: event.target.value
                    });
                }
            }
        };

        $scope.uploadFile = async function(
            asset,
            uploadUrl,
            file,
            currentCount,
            totalCount
        ) {
            $("#uplodingFile").text(
                `${$translate.instant(
                    "angular.contributorjoin.message.uploading"
                )} ${file.name} (${currentCount} / ${totalCount})`
            );
            let newFormData = new FormData();
            newFormData.append("type", asset.type);
            newFormData.append("id", asset.id);
            newFormData.append("editable", asset.editable);
            newFormData.append("formState", asset.formState);
            newFormData.append("invalid", asset.invalid);
            newFormData.append("saved", asset.saved);
            newFormData.append("programId", asset.programId);
            newFormData.append("contentRequestId", asset.contentRequestId);
            newFormData.append("file", file);

            let response = await $http({
                url: uploadUrl,
                method: "POST",
                data: newFormData,
                cache: false,
                headers: {
                    "Content-Type": undefined
                }
            });
            if (response.status === 200) {
                const { data } = response;
                let uploadedAsset = {
                    ...data
                };
                uploadedAsset.assetTitle = uploadedAsset.assetFileName.split(
                    "."
                )[0];
                uploadedAsset.type = $scope.assets[0].type;
                uploadedAsset.$$hashKey = $scope.assets[0].$$hashKey;
                uploadedAsset.assetUrl = uploadedAsset.localUrl;
                var extension = /[0-9a-z]+$/i;
                var ext = data.assetFileName.match(extension);
                // add the file extension to the array to determine the type of icon
                uploadedAsset.ext = ext[0];
                // determine whether it is an image or icon thumbnail
                if (
                    uploadedAsset.ext === "jpg" ||
                    uploadedAsset.ext === "jpeg" ||
                    uploadedAsset.ext === "gif" ||
                    uploadedAsset.ext === "png"
                ) {
                    uploadedAsset.thumbnailType = "image";
                } else {
                    uploadedAsset.thumbnailType = "icon";
                }

                $scope.assets[0].id = data.id;
                $scope.assets[0].localUrl = data.localUrl;
                $scope.assets[0].formState = "item";
                $scope.assets[0].saved = true;
                $scope.assets[0].ext = uploadedAsset.ext;
                $scope.assets[0].thumbnailType = uploadedAsset.thumbnailType;
                $scope.assets[0].assetTitle = uploadedAsset.assetTitle;

                $scope.addAsset();
                $timeout(function() {
                    var formObject = angular
                        .element(`#assetForm_${data.id}`)
                        .controller("form");
                    $scope.createForm = formObject;
                    $scope.createForm.$visible = false;
                });

                let saveAssetTitleResponse = await $http({
                    url: "/API/asset/update/" + uploadedAsset.id,
                    method: "POST",
                    data: {
                        id: uploadedAsset.id,
                        assetTitle: uploadedAsset.assetTitle,
                        userDescription: null,
                        assetUrl: uploadedAsset.assetUrl
                    },
                    cache: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                if (saveAssetTitleResponse.status === 200) {
                    uploadedAsset.fullName =
                        saveAssetTitleResponse.data.fullName;
                    uploadedAsset.uploadDate =
                        saveAssetTitleResponse.data.uploadDate;
                    uploadedAsset.assetDescription =
                        saveAssetTitleResponse.data.assetDescription;
                    uploadedAsset.assetTitle =
                        saveAssetTitleResponse.data.assetTitle;
                    $scope.updateAssetIdList(uploadedAsset);
                    uploadedAsset.type = "file";
                    uploadedAsset.formState = "item";
                    uploadedAsset.editable = true;
                    uploadedAsset.removed = false;
                    let assetIndex = $scope.assets.findIndex(
                        (item) =>
                            parseInt(item.id) === parseInt(uploadedAsset.id)
                    );
                    $scope.assets[assetIndex] = uploadedAsset;
                }
            }
        };

        $scope.uploadFiles = async function(currentAsset) {
            let uploads = $(`#upload-${currentAsset.id}`).prop("files");
            let uploadUrl = "";
            if ($scope.isContentRequest()) {
                uploadUrl = `/API/asset/upload/${$scope.contentRequestId}`;
            } else if ($scope.isCalendarEvent()) {
                uploadUrl = `/API/program/${
                    $scope.programId
                }/event-asset/upload-calendar-asset/${$scope.calendarEventId}`;
            } else if ($scope.isCampaign()) {
                uploadUrl = `/API/asset/upload/${$scope.enterpriseId}/${
                    $scope.campaignId
                }`;
            } else if ($scope.isProject()) {
                uploadUrl = `/API/asset/upload/projects/${$scope.programId}/${
                    $scope.projectId
                }`;
            } else {
                uploadUrl = `/API/asset/upload/`;
            }

            if (uploads.length > 0) {
                let html = `<div class='m-3' style="width:95%;">
                                        <div class="d-flex align-items-center">
                                            <p class="w-100" style="margin: 0; font-size:1.6rem; text-align:center; padding:0; font-weight: bold;" id="uplodingFile"></p>
                                        </div>
                                    </div>`;
                $.colorbox({
                    html: html,
                    width: "30%",
                    height: "50px",
                    escKey: false,
                    overlayClose: false
                });
                $("#cboxClose").css("display", "none");
                for (var i = 0; i < uploads.length; i++) {
                    let asset = $scope.assets[0];
                    await $scope.uploadFile(
                        asset,
                        uploadUrl,
                        uploads[i],
                        i + 1,
                        uploads.length
                    );
                }
                $(this).colorbox.close();
            }
        };

        $scope.$watch(
            "assets[0].results",
            async function(newValue, oldValue) {
                var results = newValue;
                if (results != null) {
                    $scope.evalArrayItem(results, $scope.assets[0]);
                    $scope.assets[0].id = results.id;
                    $scope.assets[0].localUrl = results.localUrl;
                    $scope.validate($scope.createForm, $scope.assets[0]);
                } else {
                    if (
                        undefined !== $scope.assets &&
                        undefined !== $scope.assets[0]
                    ) {
                        $scope.assets[0].localUrl = null;
                        $scope.validate($scope.createForm, $scope.assets[0]);
                    }
                }
            },
            true
        );

        $scope.addCancel = function(asset) {
            asset.formState = "item";
            if (asset.type === "file") {
                $scope.userFileDescription = $scope.userFileDescription.filter(
                    (item) => item.id !== asset.id
                );
                $scope.userFileName = $scope.userFileName.filter(
                    (item) => item.id !== asset.id
                );
                $(`#resource-file-description-file_${asset.id}`).val(
                    asset.userDescription
                );

                $(`#resource-file-name-file_${asset.id}`).val(asset.assetTitle);
            }
            if (asset.saved != null && !asset.saved) {
                //delete on backend if data was saved, otherwise just remove from page
                if (asset.id !== -1) {
                    $scope.removeConfirm(asset);
                } else {
                    $scope.assets.splice(
                        skyword.getArrayIndexByProperty(
                            $scope.assets,
                            "id",
                            asset.id
                        ),
                        1
                    );
                }
                $scope.addAsset();
            }
        };
        $scope.validate = function(form, asset) {
            console.log("Validate called");
            asset.invalid = false;
            if (form) {
                if ($scope.creating) {
                    $scope.createForm = form;
                }
                for (var i = 0; i < form.$editables.length; i++) {
                    var editable = form.$editables[i];

                    if (
                        (editable.attrs.eRequire === "all" ||
                            editable.attrs.eRequire === asset.type) &&
                        editable.inputEl[0].value === ""
                    ) {
                        asset.invalid = true;
                        $(editable.inputEl[0]).addClass("error");
                    } else {
                        $(editable.inputEl[0]).removeClass("error");
                    }
                }
                if (asset.localUrl === undefined) {
                    asset.invalid = true;
                }
            }
        };

        $scope.keypress = function($event) {
            // our function body
            if (13 === $event.keyCode) {
                $event.preventDefault();
            }
        };

        $scope.addAsset = function() {
            console.log("Add asset called");
            $scope.initAsset();
            console.log("Init asset called");
            $scope.creating = true;
            $scope.selectedTab = "file";
            $scope.open = "expand";
            $scope.arrow = "collapse";
            console.log("After Init asset called");
        };
        $scope.initAsset = function() {
            if (!$scope.isRfpView) {
                $scope.selectedTab = "file";
                $scope.assets.unshift({
                    type: "file",
                    id: -1,
                    editable: true,
                    formState: "add",
                    invalid: true,
                    saved: false,
                    programId: $scope.programId,
                    contentRequestId: $scope.contentRequestId
                });
            }
        };
        $scope.toggleDisplay = function() {
            $scope.open = $scope.open == "collapse" ? "expand" : "collapse";
            $scope.arrow = $scope.arrow == "expand" ? "collapse" : "expand";
            if ($scope.creating === false) {
                if ($scope.isIdea()) {
                    $scope.addAsset();
                } else {
                    $scope.creating = true;
                    $scope.selectedTab = "file";
                }
            }
        };

        /************************
         *
         * DIGITAL ASSET INTEGRATION
         *
         ************************/
        var IMAGE_ASSET = 6,
            VIDEO_ASSET = 7,
            DOCUMENT_ASSET = 8;
        $scope.dam = {
            elements: [],
            assets: [],
            allAssets: [],
            noResults: false,
            index: 0,
            pageCount: 0,
            load: 15,
            textSearch: "",
            agencySearch: false,
            programId: $scope.programId,
            attachmentId: null,
            resourcePreview: "",
            imageHeight: null,
            imageWidth: null,
            enabled: false,
            reuseDigitalAssetDTO: {
                contentRequestId:
                    undefined !== $scope.contentRequestId
                        ? $scope.contentRequestId
                        : null,
                calendarEventId:
                    undefined !== $scope.calendarEventId
                        ? $scope.calendarEventId
                        : null,
                projectId:
                    undefined !== $scope.projectId ? $scope.projectId : null,
                programId: $scope.programId,
                attachmentId: null,
                publishedUrl: null,
                fileTitle: "",
                fileDescription: ""
            },
            contentTypes: [],
            contentType: {},
            content: {
                title: "",
                publishedUrl: "",
                contentType: "",
                project: "",
                event: "",
                datePublished: "",
                summary: "",
                keywords: "",
                byline: "",
                bylinePhoto: ""
            },
            isAsset: false,
            searchResultTypeId: 0,
            contentOnly: false,
            useAsset: false,
            embedVideo: false
        };

        $scope.contentTypes = [];
        $scope.damEnabled = false;
        var contentPromise = $translate("angular.resources.alltypes");
        $scope.allAssetTypes = "";
        contentPromise.then(function(data) {
            $scope.allAssetTypes = data;
        });

        var reset = null;

        var unbindDamWatcher = $scope.$watch("programId", function() {
            if (undefined === $scope.programId) {
                unbindDamWatcher();
            }
            var enableDamPromise = assetLibrary.damEnabled($scope.programId);

            enableDamPromise.then(function(dam) {
                //console.log(dam);
                $scope.damEnabled = dam.data.enableDam;
                reset = angular.copy($scope.dam);
                if (true === $scope.damEnabled) {
                    var damPromise = digitalAsset.getContentTypes(
                        $scope.programId
                    );
                    damPromise.then(function(data) {
                        $scope.contentTypes = data.data;
                        //console.log($translate("angular.resources.content"));
                        $scope.contentTypes.unshift({
                            contentTypeId: null,
                            searchResultTypeId: null,
                            contentType: $scope.allAssetTypes
                        });
                    });
                }
            });

            unbindDamWatcher();
        });

        $scope.damSearch = function() {
            $scope.dam.useAsset = false;
            $scope.dam.searchResultTypeId = null;
            $scope.dam.resourcePreview = "";

            if (SkyFactory.isUndefinedOrNull($scope.dam.contentType)) {
                $scope.dam.contentOnly = false;
                $scope.dam.searchResultTypeId = null;
            } else {
                $scope.dam.contentOnly = false;
                $scope.dam.searchResultTypeId =
                    $scope.dam.contentType.searchResultTypeId;
            }

            var searchPromise = search.search({
                searchResultTypeId: $scope.dam.searchResultTypeId,
                textSearch: $scope.dam.textSearch,
                agencySearch: $scope.dam.agencySearch,
                agencyId: $scope.agencyId,
                programId: $scope.dam.programId,
                resourceReuse: true,
                searchType:
                    null === $scope.dam.searchResultTypeId
                        ? "searchTypeProgram"
                        : null,
                contentOnly: false // Content... $scope.dam.contentOnly
            });

            searchPromise.then(function(searchResults) {
                //console.info(searchResults);
                $scope.dam.assets = [];
                $scope.dam.allAssets = searchResults.response.docs;
                $scope.dam.pageCount = 1;
                $scope.dam.index = 0;
                $scope.assetScroll();

                if ($scope.dam.assets.length === 0) {
                    $scope.dam.noResults = true;
                } else if ($scope.dam.assets.length > 0) {
                    $scope.dam.noResults = false;
                }
            });
            return false;
        };

        $scope.assetScroll = function() {
            var assetLoad = $scope.dam.load;
            $scope.allAssets = $scope.dam.allAssets.length;
            $scope.nextSet = function() {
                var nextIndex = $scope.dam.index + $scope.dam.load;
                var len = Math.min($scope.dam.allAssets.length, nextIndex);
                var arr = $scope.dam.allAssets.slice($scope.dam.index, len);

                if (0 === arr.length) {
                    return;
                }

                for (var i = 0; i < arr.length; i++) {
                    $scope.dam.assets.push(arr[i]);
                }
                $scope.dam.index += 15;
            };
            $scope.nextSet();
            $scope.loadMore = function() {
                $scope.nextSet();
            };
        };

        $scope.chooseAsset = function(asset) {
            $scope.dam.searchResultTypeId = parseInt(asset.searchResultTypeId);
            var item = $(".asset-images-resource").find(
                "[data-attachmentid='" + asset.attachmentId + "']"
            );
            var ldr = item.parent().find(".loader");
            if (true === $scope.isContent(asset)) {
                var contentPromise = content.getContentOverlay(asset.id);
                $scope.dam.isAsset = false;

                // show the preloader
                ldr.show();
                //item.hide();

                contentPromise.then(function(content) {
                    // hide the preloader
                    ldr.hide();
                    //item.show();

                    $scope.dam.content.title = content.data.title;
                    $scope.dam.resourcePreview = content.data.featuredImage;
                    $scope.dam.reuseDigitalAssetDTO.publishedUrl = $scope.dam.content.publishedUrl =
                        content.data.publishedUrl;
                    $scope.dam.content.contentType = content.data.contentType;
                    $scope.dam.content.category = content.data.category;
                    if (!SkyFactory.reallyEmptyCheck(content.data.project)) {
                        $scope.dam.content.project = content.data.project;
                    }
                    if (!SkyFactory.reallyEmptyCheck(content.data.event)) {
                        $scope.dam.content.event = content.data.event;
                    }
                    $scope.dam.content.datePublished =
                        content.data.datePublished;
                    $scope.dam.content.summary = content.data.summary;
                    $scope.dam.content.keywords = content.data.keywords;
                    $scope.dam.content.byline = content.data.byline;
                    $scope.dam.content.bylinePhoto = content.data.bylinePhoto;
                });
            } else {
                $scope.dam.isAsset = true;
                $scope.dam.attachmentId = asset.attachmentId;
                $scope.dam.reuseDigitalAssetDTO.attachmentId =
                    asset.attachmentId;
                var attachmentPromise = digitalAsset.getAssetAttachment(
                    asset.attachmentId
                );

                // show the preloader
                ldr.show();

                attachmentPromise.then(function(attachment) {
                    // hide the preloader

                    ldr.hide();

                    //console.log(attachment);
                    $scope.dam.assetCropPreview =
                        attachment.data.assetCropPreview;
                    $scope.dam.resourcePreview =
                        attachment.data.resourcePreview;
                    $scope.dam.s3Preview = attachment.data.s3Preview;
                    $scope.dam.imageHeight = attachment.data.imageHeight;
                    $scope.dam.imageWidth = attachment.data.imageWidth;

                    if (
                        $scope.dam.imageHeight == "" ||
                        $scope.dam.imageHeight === null
                    ) {
                        var img = new Image();
                        img.onload = function() {
                            $scope.dam.imageHeight = img.naturalHeight;
                            $scope.dam.imageWidth = img.naturalWidth;
                            $scope.$apply();
                        };
                        img.src = attachment.data.assetReviewPreview;
                    }

                    var metaPromise = digitalAsset.getDigitalAssetMetaInfo(
                        attachment.data.contentRequestId
                    );
                    $scope.dam.fileTypeIcon = asset.thumbnailUrl;
                    // Resizes Kaltura thumbnail images for videos on the resource details page
                    if (
                        $scope.dam.searchResultTypeId == 5 ||
                        $scope.dam.searchResultTypeId == 7
                    ) {
                        if (asset.thumbnailUrl && asset.thumbnailUrl != "") {
                            if (asset.thumbnailUrl.search("kaltura") != -1) {
                                $scope.dam.videoThumbnailUrl =
                                    asset.thumbnailUrl;
                                $scope.dam.videoThumbnailUrl =
                                    $scope.dam.videoThumbnailUrl.split(
                                        "width"
                                    )[0] + "width/800/height/999/type/1";
                            } else {
                                $scope.dam.videoThumbnailUrl =
                                    asset.thumbnailUrl;
                            }
                        } else {
                            $scope.dam.videoThumbnailUrl = true;
                        }
                    }

                    if ($scope.isAssetVideo(asset)) {
                        $scope.fakeAttachment.id = attachment.data.id;
                        getVideo();
                    }

                    metaPromise.then(function(meta) {
                        //console.log(meta);
                        $scope.dam.elements = meta.data;
                    });
                });
            }
        };

        $scope.cancelAsset = function() {
            $scope.dam = angular.copy(reset);
            $scope.creating = true;
        };

        $scope.useAsset = function() {
            $scope.dam.useAsset = true;
            for (var i = 0; i < $scope.dam.elements.length; i++) {
                if (10 === $scope.dam.elements[i].typeId) {
                    $scope.dam.reuseDigitalAssetDTO.fileTitle =
                        $scope.dam.elements[i].digitalAssetValue;
                } else if (
                    "asset-description" === $scope.dam.elements[i].apiNodeName
                ) {
                    $scope.dam.reuseDigitalAssetDTO.fileDescription =
                        $scope.dam.elements[i].digitalAssetValue;
                }
            }
        };

        $scope.reuseAsset = function() {
            var assetPromise = assetLibrary.reuse(
                $scope.dam.reuseDigitalAssetDTO
            );

            assetPromise.then(function(asset) {
                $scope.dam = angular.copy(reset);
                $scope.assets.unshift(asset.data);
            });
        };

        $scope.returnToSearchResults = function() {
            $scope.dam.resourcePreview = "";
            $scope.dam.attachmentId = null;
            $scope.dam.elements = [];
            $scope.dam.useAsset = false;
        };

        $scope.isAssetImage = function(asset) {
            return IMAGE_ASSET === parseInt(asset.searchResultTypeId, 10);
        };

        $scope.isAssetVideo = function(asset) {
            var video = false;
            if (!SkyFactory.isUndefinedOrNull(asset)) {
                video = VIDEO_ASSET === parseInt(asset.searchResultTypeId, 10);
            }
            return video;
        };

        $scope.isAssetDocument = function(asset) {
            return DOCUMENT_ASSET === parseInt(asset.searchResultTypeId, 10);
        };

        $scope.isContent = function(asset) {
            return (
                DOCUMENT_ASSET !== parseInt(asset.searchResultTypeId, 10) &&
                IMAGE_ASSET !== parseInt(asset.searchResultTypeId, 10) &&
                VIDEO_ASSET !== parseInt(asset.searchResultTypeId, 10)
            );
        };

        $scope.fakeAttachment = {
            id: null,
            externalValueId: null
        };

        /**
         * Get attachment AKA the video
         */
        function getVideo() {
            // flag loading attachment
            $scope.isAttachmentLoading = true;
            videoCommentService.getAttachment($scope.fakeAttachment, function(
                attachment
            ) {
                if (!SkyFactory.reallyEmptyCheck(attachment.externalValueId)) {
                    $scope.dam.embedVideo = true;
                    //$scope.attachment = attachment;
                    var kaltura = {
                        targetId: "videoPlayer",
                        wid: "_1737042",
                        uiconf_id: 23937082,
                        flashvars: {
                            allowFullScreen: true,
                            allowNetworking: "all",
                            allowScriptAccess: "always",
                            autoPlay: true,
                            strings: {
                                "mwe-embedplayer-missing-source": $translate.instant(
                                    "angular.videoCommenting.videoNotTranscodedYet"
                                )
                            }
                        },
                        cache_st: 1406907495,
                        entry_id: attachment.externalValueId
                    };
                    // set player
                    mw.setConfig("Kaltura.LeadWithHTML5", true);
                    kWidget.embed(kaltura);
                    // build the comment list
                    //  $scope.buildComment();
                    // watch comment box and pause if text entered
                    //$scope.$watch('videoComment.enteredText', function () {
                    //    if (typeof $('#videoPlayer')[0] != "undefined") {
                    //        try {
                    //            $('#videoPlayer')[0].sendNotification('doPause');
                    //        } catch (e) {
                    //            // not initialized yet
                    //        }
                    //    }
                    //});
                }
            });
        }
    }
]);

app.service("call", [
    "skyjax",
    "ajax",
    "overlay",
    function(skyjax, ajax, overlay) {
        return {
            skyjax: function(urlAction, givenOptions, request) {
                return skyjax(urlAction, givenOptions, request);
            },
            ajax: function(urlAction, givenOptions, request) {
                return ajax(urlAction, givenOptions, request);
            },
            overlay: function(
                urlAction,
                params,
                w,
                h,
                postGet,
                callback,
                overlayClose
            ) {
                return overlay.load(
                    urlAction,
                    params,
                    w,
                    h,
                    postGet,
                    callback,
                    overlayClose
                );
            }
        };
    }
])
    .service("ajax", function() {
        return function(urlAction, givenOptions, request) {
            Object.prototype.hasOwnProperty = function(property) {
                return typeof this[property] !== "undefined";
            };
            //because we had to name it this
            if (undefined !== request && null !== request) {
                request.abort();
            }
            if (undefined === givenOptions) {
                givenOptions = {};
            }
            if (undefined === givenOptions.data || null === givenOptions.data) {
                givenOptions.data = {};
            }
            if (null === givenOptions.dataType) {
                givenOptions.dataType = "html";
            }
            if (null === givenOptions.async) {
                givenOptions.async = true;
            }

            if (typeof givenOptions.data === "string") {
                if (givenOptions.data.indexOf("csrfToken") == -1) {
                    givenOptions.data += "&csrfToken=" + $("#cToken").val();
                }
                if (givenOptions.data.indexOf("pageLoadTime") == -1) {
                    givenOptions.data +=
                        "&pageLoadTime=" + $("#pageLoadTime").val();
                }
            } else if (givenOptions.data instanceof Object) {
                if (!givenOptions.data.hasOwnProperty("csrfToken")) {
                    givenOptions.data["csrfToken"] = $("#cToken").val();
                }
                if (!givenOptions.data.hasOwnProperty("pageLoadTime")) {
                    givenOptions.data["pageLoadTime"] = $(
                        "#pageLoadTime"
                    ).val();
                }
            }

            var options = {
                cache: givenOptions.cache,
                type: givenOptions.type,
                data: givenOptions.data,
                dataType: givenOptions.dataType,
                async: givenOptions.async,
                url: urlAction,
                success: function(data, textStatus, jqXHR) {
                    if (
                        givenOptions.success !== null &&
                        givenOptions.success !== undefined &&
                        typeof givenOptions.success === "function"
                    ) {
                        givenOptions.success(data, textStatus, jqXHR);
                    }
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    if (
                        givenOptions.error !== null &&
                        givenOptions.error !== undefined &&
                        typeof givenOptions.error === "function"
                    ) {
                        givenOptions.error(jqXHR, textStatus, errorThrown);
                    }
                },
                complete: function(jqXHR, textStatus) {
                    if (
                        givenOptions.complete !== null &&
                        givenOptions.complete !== undefined &&
                        typeof givenOptions.complete === "function"
                    ) {
                        givenOptions.complete(jqXHR, textStatus);
                    }
                }
            };
            options.headers = {
                csrfToken: $("#cToken").val(),
                pageLoadTime: $("#pageLoadTime").val()
            };
            return $.ajax(urlAction, options);
        };
    })
    .service("skyjax", [
        "skyword",
        "setup",
        "validation",
        "TinymceService",
        "$rootScope",
        function(skyword, setup, validation, TinymceService, $rootScope) {
            return function(urlAction, givenOptions, request, csrf) {
                Object.prototype.hasOwnProperty = function(property) {
                    return typeof this[property] !== "undefined";
                };
                if (undefined !== request && null !== request) {
                    request.abort();
                }
                if (undefined === givenOptions) {
                    givenOptions = {};
                }
                if (
                    undefined === givenOptions.data ||
                    null === givenOptions.data
                ) {
                    givenOptions.data = {};
                }
                if (null === givenOptions.dataType) {
                    givenOptions.dataType = "html";
                }
                if (!csrf) {
                    if (typeof givenOptions.data === "string") {
                        if (givenOptions.data.indexOf("csrfToken") == -1) {
                            givenOptions.data +=
                                "&csrfToken=" + $("#cToken").val();
                        }
                        if (givenOptions.data.indexOf("pageLoadTime") == -1) {
                            givenOptions.data +=
                                "&pageLoadTime=" + $("#pageLoadTime").val();
                        }
                    } else if (givenOptions.data instanceof Object) {
                        if (!givenOptions.data.hasOwnProperty("csrfToken")) {
                            givenOptions.data["csrfToken"] = $("#cToken").val();
                        }
                        if (!givenOptions.data.hasOwnProperty("pageLoadTime")) {
                            givenOptions.data["pageLoadTime"] = $(
                                "#pageLoadTime"
                            ).val();
                        }
                    }
                }
                var $this = this,
                    options = {
                        cache: givenOptions.cache,
                        type: givenOptions.type,
                        data: givenOptions.data,
                        dataType: givenOptions.dataType,
                        url: urlAction,
                        success: function skyjaxsuccess(
                            data,
                            textStatus,
                            jqXHR
                        ) {
                            if (!validation.parse(data)) {
                                var loginPage = $(data)
                                    .find("#login-page")
                                    .val();
                                if (null != loginPage && loginPage) {
                                    window.location.href = "/login.action";
                                }
                            }
                            if (
                                givenOptions.success !== null &&
                                givenOptions.success !== undefined &&
                                typeof givenOptions.success === "function"
                            ) {
                                givenOptions.success(data, textStatus, jqXHR);
                            }
                        },
                        error: function skyjaxerror(
                            jqXHR,
                            textStatus,
                            errorThrown
                        ) {
                            if (
                                givenOptions.error !== null &&
                                givenOptions.error !== undefined &&
                                typeof givenOptions.error === "function"
                            ) {
                                givenOptions.error(
                                    jqXHR,
                                    textStatus,
                                    errorThrown
                                );
                            }
                        },
                        complete: function skyjaxcomplete(jqXHR, textStatus) {
                            try {
                                if (
                                    givenOptions.complete !== null &&
                                    givenOptions.complete !== undefined &&
                                    typeof givenOptions.complete === "function"
                                ) {
                                    givenOptions.complete(jqXHR, textStatus);
                                }
                                setup.styledDropdowns();
                                setup.displaytagAjax();
                                setup.futureDatePicker();
                                setup.futurePlusOneDatePicker();
                                setup.datePicker();
                                setup.datePickerAll();
                                skyword.enforceLinkSecurity();

                                // Recompile any new angular treatments.
                                var tinymceSelectorName = $(".sw-modal");
                                if (tinymceSelectorName.length === 0) {
                                    tinymceSelectorName = $("#posts-container");
                                }
                                tinymceSelectorName.each(
                                    function tinymceSelectorNameEach() {
                                        TinymceService.compile(
                                            $(this),
                                            $rootScope
                                        );
                                    }
                                );

                                if ($(".sw-modal").length) {
                                    $(".sw-modal").colorbox.resize();
                                }
                            } catch (e) {
                                // console.trace(e);
                            }
                        }
                    };
                return $.ajax(urlAction, options);
            };
        }
    ])
    .service("skyjax2", [
        "skyword",
        "setup",
        "validation",
        function(skyword, setup, validation) {
            return function(urlAction, givenOptions, request) {
                var $this = this,
                    config = {
                        cache: givenOptions.cache,
                        data: givenOptions.data,
                        method: givenOptions.type,
                        responseType: givenOptions.dateType,
                        url: urlAction
                    },
                    success = function(
                        data,
                        status,
                        headers,
                        config,
                        statusText
                    ) {
                        if (!validation.parse(data)) {
                            var loginPage = $(data)
                                .find("#login-page")
                                .val();
                            if (null != loginPage && loginPage) {
                                window.location.href = "/login.action";
                            }
                        }
                        if (
                            givenOptions.success !== null &&
                            givenOptions.success !== undefined &&
                            typeof givenOptions.success === "function"
                        ) {
                            givenOptions.success(
                                data,
                                status,
                                headers,
                                config,
                                statusText
                            );
                        }
                    },
                    error = function(
                        data,
                        status,
                        headers,
                        config,
                        statusText
                    ) {
                        if (
                            givenOptions.error !== null &&
                            givenOptions.error !== undefined &&
                            typeof givenOptions.error === "function"
                        ) {
                            givenOptions.error(
                                data,
                                status,
                                headers,
                                config,
                                statusText
                            );
                        }
                    },
                    complete = function(
                        data,
                        status,
                        headers,
                        config,
                        statusText
                    ) {
                        if (
                            givenOptions.complete !== null &&
                            givenOptions.complete !== undefined &&
                            typeof givenOptions.complete === "function"
                        ) {
                            givenOptions.complete(
                                data,
                                status,
                                headers,
                                config,
                                statusText
                            );
                        }
                        setup.styledDropdowns();
                        setup.displaytagAjax();
                        setup.futureDatePicker();
                        setup.futurePlusOneDatePicker();
                        setup.datePicker();
                        setup.datePickerAll();
                        skyword.enforceLinkSecurity();
                        if ($(".sw-modal").length) {
                            $(".sw-modal").colorbox.resize();
                        }
                    };

                return givenOptions.type.match(/post/i)
                    ? $http
                          .post(urlAction, config)
                          .success(success)
                          .error(error)
                          .then(complete)
                    : $http
                          .get(urlAction, config)
                          .success(success)
                          .error(error)
                          .then(complete);
            };
        }
    ])
    .service("container", [
        "call",
        function(call) {
            return {
                init: function() {
                    var $this = this;
                    $(".loadAjax").each(function() {
                        var useHistory = false;

                        if ($(this).attr("href") !== null) {
                            if ($(this).hasClass("history")) {
                                useHistory = true;
                            }
                            var urlAction = $(this).attr("href"),
                                container = $("#" + $(this).attr("target")),
                                callback = $(this).attr("rel");

                            $(this).removeAttr("href");

                            $(this).click(function() {
                                var options = {
                                    cache: false,
                                    type: "GET",
                                    success: function(data, textStatus, jqXHR) {
                                        container
                                            .html(data)
                                            .removeClass("disabled")
                                            .removeClass("loading");
                                        if (
                                            undefined !== callback &&
                                            null !== callback &&
                                            "" !== callback
                                        ) {
                                            var serviceString = callback.split(
                                                "."
                                            );
                                            eval(
                                                "var " +
                                                    serviceString[0] +
                                                    " = angular.element('body').injector().get('" +
                                                    serviceString[0] +
                                                    "'); " +
                                                    serviceString[0] +
                                                    "." +
                                                    serviceString[1]
                                            ); // jshint ignore:line
                                        }
                                    },
                                    error: function(
                                        jqXHR,
                                        textStatus,
                                        errorThrown
                                    ) {
                                        console.log(
                                            "Error encountered in loading ajax call: " +
                                                textStatus
                                        );
                                    },
                                    complete: function(
                                        data,
                                        textStatus,
                                        jqXHR
                                    ) {
                                        $(".sw-modal").colorbox.resize();
                                    }
                                };

                                container.addClass("loading disabled");
                                //skyword.setLastAjaxCall(urlAction);
                                if (true === useHistory) {
                                    $.history.load(urlAction);
                                } else {
                                    call.ajax(urlAction, options);
                                }
                                return false;
                            });
                        }
                    });
                }
            };
        }
    ])
    .service("overlay", [
        "$translate",
        "skyword",
        "setup",
        "validation",
        "skyjax",
        "ajax",
        "crop",
        "TinymceService",
        "$rootScope",
        function(
            $translate,
            skyword,
            setup,
            validation,
            skyjax,
            ajax,
            crop,
            TinymceService,
            $rootScope
        ) {
            var load = function loadOverlay(
                    urlAction,
                    params,
                    w,
                    h,
                    postGet,
                    callback,
                    overlayClose
                ) {
                    //console.log(h + " | " + w);
                    if (urlAction.indexOf("stockPhotoSetupAjax") !== -1) {
                        $.colorbox({
                            html: `<div class="loader flex-column align-items-center" id="genContentLoader" style="margin-top:20px; display:flex; height:96%; justify-content: center;">
                            <img src="/styles/images/ajax-loader32x32.gif" style="height:32px; width:32px;" />
                            <p>${$translate.instant(
                                "stockphoto.loadingstockphotos"
                            )}</p>
                        </div>`,
                            width: w,
                            height: h,
                            escKey: false,
                            overlayClose: overlayClosed,
                            fastIframe: false
                        });
                    }
                    var $this = this,
                        overlayClosed =
                            null === overlayClose ? false : overlayClose,
                        getPost =
                            null === postGet
                                ? $(".sw-modal #formSubmit").attr("method")
                                : postGet,
                        options = {
                            cache: false,
                            type: getPost,
                            url: urlAction,
                            data: params,
                            dataType: "html",
                            success: function(data, textStatus, jqXHR) {
                                if (!validation.parse(data)) {
                                    $.colorbox({
                                        html: $.trim(data),
                                        width: w,
                                        height: h,
                                        escKey: false,
                                        overlayClose: overlayClosed,
                                        onClosed: function() {
                                            if (
                                                urlAction ===
                                                    "/postDeleteAjax.action" ||
                                                urlAction ===
                                                    "/postCreateAjax.action"
                                            ) {
                                                var editorId = null;
                                                if (tinymce.activeEditor) {
                                                    editorId =
                                                        tinymce.activeEditor.id;
                                                }
                                                if (null === editorId) {
                                                    editorId = $(
                                                        "body .tinyMceContainer > div:first-child"
                                                    ).attr("id");
                                                }
                                                skyword.closeTinyMce(editorId);
                                                $(
                                                    ".postContentPreview"
                                                ).removeClass("hidden");
                                                $(".flashContent").removeClass(
                                                    "hidden"
                                                );
                                                console.log("onClosed");
                                            }

                                            if (
                                                urlAction.indexOf(
                                                    "uploadEmbeddedImageAjax"
                                                ) !== -1 ||
                                                urlAction.indexOf(
                                                    "setupCropAttachmentAjax"
                                                ) !== -1
                                            ) {
                                                if (
                                                    window.localStorage.getItem(
                                                        "uploadUsing"
                                                    ) !== null
                                                ) {
                                                    let uploadUsing = window.localStorage.getItem(
                                                        "uploadUsing"
                                                    );
                                                    let uploadUsingId = window.localStorage.getItem(
                                                        "uploadUsingId"
                                                    );
                                                    $(
                                                        `#${uploadUsingId}`
                                                    ).click();
                                                }
                                            }
                                        }
                                    });
                                }
                                console.log("Success");
                            },
                            error: function(jqXHR, textStatus, errorThrown) {
                                var msg =
                                    "Error getting: " + urlAction + "\n\n";
                                msg += "Status: " + textStatus + "\n\n";
                                console.log(msg);
                            },
                            complete: function(jqXHR, textStatus) {
                                setupColorbox();
                                skyword.passwordStrength();
                                try {
                                    if (
                                        null !== callback &&
                                        undefined !== callback
                                    ) {
                                        if ("function" === typeof callback) {
                                            callback();
                                        } else {
                                            var serviceString = callback.split(
                                                "."
                                            );
                                            eval(
                                                "var " +
                                                    serviceString[0] +
                                                    " = angular.element('body').injector().get('" +
                                                    serviceString[0] +
                                                    "'); " +
                                                    serviceString[0] +
                                                    "." +
                                                    serviceString[1]
                                            ); // jshint ignore:line
                                        }
                                    }
                                    console.log(
                                        "Completion Time :",
                                        new Date().getTime()
                                    );
                                } catch (e) {
                                    console.log(e);
                                }
                                setTimeout(function() {
                                    $(this).colorbox.resize();
                                }, 50);
                                console.log("Complete");
                            }
                        };
                    $(".flashContent").addClass("hidden");
                    skyjax(urlAction, options);
                    return false;
                },
                init = function() {
                    var $this = this;
                    $("a.overlay").each(function() {
                        var a = $(this),
                            urlAction = a.attr("href"),
                            callback = a.attr("rel"),
                            height = null,
                            width = null,
                            overlayClose;
                        if (a.hasClass("encodeURL")) {
                            urlAction = encodeURI(urlAction);
                        }
                        if (null != urlAction) {
                            //undefined !== || null !==
                            a.removeAttr("href");
                            a.unbind("click").click(function() {
                                var ti = $(this).attr("tabindex"),
                                    width;
                                if (!!ti) {
                                    if (isNaN(ti) && -1 !== ti.indexOf(",")) {
                                        ti = ti.split(",");
                                        width = ti[0];
                                    } else {
                                        width = ti;
                                    }
                                } else {
                                    width = 650;
                                }
                                return load(
                                    urlAction,
                                    null,
                                    width,
                                    height,
                                    null,
                                    callback
                                );
                            });
                        }
                    });
                    $("a.checkAgreementOverlay").each(function(i, el) {
                        const urlAction = $(el).attr("href");
                        const processUrl = $(el).attr("data-row-process");

                        $(el)
                            .removeAttr("href")
                            .click(function() {
                                var options = {
                                    cache: false,
                                    type: "GET",
                                    success: function(data) {
                                        const shouldShowModal =
                                            data.includes("sw-modal") &&
                                            data.includes("accept-button");

                                        if (shouldShowModal) {
                                            $.colorbox({
                                                html: $.trim(data),
                                                width: 600,
                                                height: 600,
                                                escKey: false
                                            });

                                            $("#accept-button").attr(
                                                "disabled",
                                                true
                                            );

                                            $("#tasAgree")
                                                .unbind("change")
                                                .change(function() {
                                                    $("#accept-button").attr(
                                                        "disabled",
                                                        function(_, attr) {
                                                            return !attr;
                                                        }
                                                    );
                                                });

                                            $("#accept-button")
                                                .unbind("click")
                                                .click(function(ev) {
                                                    ev.preventDefault();
                                                    $("#formSubmit")
                                                        .attr(
                                                            "action",
                                                            processUrl
                                                        )
                                                        .submit();
                                                });

                                            return;
                                        }

                                        window.location.href = urlAction;
                                    },
                                    error: function(textStatus) {
                                        console.log(textStatus);
                                    }
                                };
                                skyjax(urlAction, options);
                            });
                    });
                    $(".ajaxLoadOverlay").each(function(i, el) {
                        var urlAction = $(el).attr("href");
                        $(el)
                            .removeAttr("href")
                            .click(function() {
                                var options = {
                                    cache: false,
                                    type: "GET",
                                    success: function(data, textStatus, jqXHR) {
                                        $(".sw-modal").html(data);
                                        $this.colorbox();
                                        setTimeout(
                                            " $(this).colorbox.resize(); ",
                                            150
                                        );
                                    },
                                    error: function(
                                        jqXHR,
                                        textStatus,
                                        errorThrown
                                    ) {
                                        console.log(textStatus);
                                    }
                                };
                                skyjax(urlAction, options);
                            });
                    });
                },
                controlButtons = function() {
                    var $this = this;

                    /*
             * updateContentRequestState.jspf
             */
                    if (!$(".sw-modal button").hasClass("action")) {
                        if (
                            $(".sw-modal button").attr("id") ==
                            "removeProfilePictureBtn"
                        ) {
                            return;
                        }
                        $(".sw-modal button").click(function() {
                            $(this)
                                .addClass("disabled")
                                .attr("disabled", "disabled");
                            return colorboxForm();
                        });
                    }
                    skyword.resizeButtons();
                },
                colorboxForm = function(parameters) {
                    var formSubmit = $(".sw-modal #formSubmit"),
                        urlAction = formSubmit.attr("action"),
                        getPost = formSubmit.attr("method"),
                        outputDiv = formSubmit.find("#outputLocation").val();

                    if (
                        $(".sw-modal button").attr("id") == "post-create-button"
                    ) {
                        tinyMCE.triggerSave();
                        var tinymceEditorId = formSubmit
                            .find("#postBody")
                            .attr("id");
                        var tmpContent = tinyMCE
                            .get(tinymceEditorId)
                            .getContent();
                        $('input[name="postBody"]').val(tmpContent);
                    }

                    var options = {
                        cache: false,
                        type: getPost,
                        data: formSubmit.serialize(),
                        url: urlAction,
                        success: function(data, textStatus, jqXHR) {
                            if (!validation.parse(data)) {
                                if (
                                    null == outputDiv ||
                                    $(data).find(".errorMessage").length > 0
                                ) {
                                    $("#cboxLoadedContent").html(data);
                                    var tinymceSelectorName = $(".sw-modal");
                                    var tinymceEditorId = formSubmit
                                        .find("#postBody")
                                        .attr("id");
                                    TinymceService.reinitializeTinyMce(
                                        tinymceEditorId,
                                        tinymceSelectorName,
                                        $rootScope
                                    );
                                } else {
                                    $(outputDiv).html(data);
                                    $(this).colorbox.close();
                                    init();
                                    setup.styledDropdowns();
                                }
                            } else {
                                console.log("parse succeed");
                            }
                        },
                        error: function(jqXHR, textStatus, errorThrown) {
                            var msg = "Error getting: " + urlAction + "\n\n";
                            msg += "Status: " + textStatus + "\n\n";
                            // msg += "Parameters: " + JSON.stringify(parameters) + "\n\n";
                            console.log(msg);
                        },
                        complete: function() {
                            setupColorbox();
                            skyword.passwordStrength();
                            setTimeout(" $(this).colorbox.resize(); ", 2000);
                        }
                    };
                    ajax(urlAction, options);
                    return false;
                },
                setupColorbox = function() {
                    var $this = this;
                    /*
             * lightbox popups
             */
                    var linkInfo = $(
                            ".sw-modal a[rel=deactivate].lightwindow_action"
                        ),
                        isClose = linkInfo.hasClass("close"),
                        urlAction = linkInfo.attr("href"),
                        isReloadAjax = linkInfo.hasClass("reloadAjax"),
                        isReload = linkInfo.hasClass("reload"),
                        hideClose = linkInfo.hasClass("noCloseButton"),
                        current = $(".current");
                    if ($("#no-close-button").length > 0) {
                        hideClose = $("#no-close-button").val() === "true";
                    }
                    //$this.overlayLinks();
                    if (current.width() !== null) {
                        try {
                            if ($("#help-sidebar").length > 0) {
                                $("#help-sidebar").scrollTo(current, 200);
                            }
                        } catch (e) {}
                    }
                    linkInfo.click(function() {
                        skyword.enableButtons();
                        if (isClose) {
                            try {
                                $("#upload-file").uploadify("destroy");
                            } catch (e) {
                                console.log(e);
                            }
                            $.colorbox.close();
                            if (isReload) {
                                window.location.reload();
                            }
                        } else if (isReload) {
                            window.location.reload();
                        } else if (isReloadAjax) {
                            skyword.reloadLastAjaxCall();
                        } else {
                            window.location.href = urlAction;
                        }
                        return false;
                    });
                    if (isClose) {
                        $("#cboxClose").click(function() {
                            $.colorbox.close();
                        });
                    } else if (isReload) {
                        $("#cboxClose").click(function() {
                            window.location.reload();
                        });
                    } else if (isReloadAjax) {
                        $("#cboxClose").click(function() {
                            skyword.reloadLastAjaxCall();
                        });
                    } else if (!!urlAction && "#" !== urlAction) {
                        $("#cboxClose").click(function() {
                            window.location.href = urlAction;
                        });
                    }
                    if (hideClose) {
                        $("#cboxClose").hide();
                    }
                    $(".sw-modal input").bind("keypress", function(e) {
                        if (e.keyCode === 13) {
                            return false;
                        }
                    });
                    $("div.sw-modal img").load(function() {
                        if ($("#prepare-container").length > 0) {
                            $("#crop-container").css({
                                visibility: "hidden",
                                display: "block"
                            });
                            $("#prepare-container").show();
                        }
                        if ("image-crop" === $(this).attr("id")) {
                            crop.check();
                        }
                    });
                    setup.upload();
                    setup.futureDatePicker();
                    setup.futurePlusOneDatePicker();
                    setup.datePicker();
                    setup.datePickerAll();
                    skyword.enforceLinkSecurity();
                    skyword.enforceViewArticleSecurity();
                    controlButtons();
                    skyword.removeWhitespaceAdditionalPayments();
                    skyword.addTooltips();
                    if ($(".redactorSearchReplace").length > 0) {
                        //redactor.setupRedactorElements();
                    }
                };

            return {
                load: function(
                    urlAction,
                    params,
                    w,
                    h,
                    postGet,
                    callback,
                    overlayClose
                ) {
                    return load(
                        urlAction,
                        params,
                        w,
                        h,
                        postGet,
                        callback,
                        overlayClose
                    );
                },
                colorboxForm: function(parameters) {
                    return colorboxForm(parameters);
                },
                init: function() {
                    init();
                },
                setupColorbox: setupColorbox
            };
        }
    ])
    .service("cards", [
        "skyword",
        "setup",
        "validation",
        "skyjax",
        "skywordGraphs",
        function(skyword, setup, validation, skyjax, skywordGraphs) {
            return {
                init: function() {
                    var $this = this;
                    $(".cardAjax").each(function(i, el) {
                        var card = $(el),
                            urlAction = card.attr("data-action"),
                            type = card.attr("data-type"),
                            graphType = card.attr("data-graphtype"),
                            ctId = card.attr("data-contentTypeId"),
                            noResults = card.attr("data-noresult"),
                            labelType = card.attr("data-labeltype"),
                            subTitle = card.attr("data-subtitle"),
                            popupLabel = card.attr("data-popuplabel"),
                            displayAxis = card.attr("data-displayaxis"),
                            dateFormat = card.attr("data-dateformat"),
                            graphScreen = card.attr("data-graphScreen");
                        options = {
                            cache: false,
                            type: "GET",
                            url: urlAction,
                            data: { currentContentTypeId: ctId },
                            success: function(data, textStatus, jqXHR) {
                                card.removeClass("loading");
                                card.html(data);
                                if (
                                    card
                                        .find("li")
                                        .hasClass("no-traackr-posts") == false
                                ) {
                                    $this.init();
                                }
                            },
                            error: function(jqXHR, textStatus, errorThrown) {
                                card.removeClass("loading");

                                //Apply error classes if needed
                                if (noResults != null) {
                                    card.html("<p>" + noResults + "</p>");
                                }
                                console.log(
                                    "Issue building card: " + textStatus
                                );
                            },
                            complete: function() {
                                $this.cardTotalMeters();
                            }
                        };
                        card.removeClass("cardAjax");

                        if (type == "graph" && graphScreen != "engagement") {
                            //STANDARD GRAPHS
                            skywordGraphs.getGraph({
                                graphType: graphType,
                                action: urlAction,
                                errorMessage: noResults,
                                location: card.attr("id"),
                                subTitle: subTitle,
                                labelType: labelType,
                                xType: "date",
                                xLabel: "  ",
                                yType: "Number",
                                yLabel: " ",
                                yTitle: " ",
                                xLabelSize: displayAxis != "true" ? 0 : 10,
                                yLabelSize: displayAxis != "true" ? 0 : 10,
                                xAxisThickness: displayAxis != "true" ? 0 : 1,
                                yAxisThickness: displayAxis != "true" ? 0 : 1,
                                yFormat: "%d",
                                popupFormat:
                                    "<span class='value'>{y}</span> <span>" +
                                    popupLabel +
                                    "</span>"
                            });
                        } else if (
                            type == "graph" &&
                            graphScreen == "engagement"
                        ) {
                            // AREA GRAPH ON ENGAGEMENT SCREEN
                            console.log("in " + type + " " + graphScreen);
                            skywordGraphs.getGraph({
                                graphType: graphType,
                                action: urlAction,
                                errorMessage: noResults,
                                location: card.attr("id"),
                                subTitle: subTitle,
                                labelType: labelType,
                                xType: "date",
                                xLabel: "  ",
                                yType: "dateTime",
                                yLabel: "    ",
                                yTitle: "Seconds",
                                xLabelSize: displayAxis != "true" ? 0 : 10,
                                yLabelSize: displayAxis != "true" ? 0 : 10,
                                xAxisThickness: displayAxis != "true" ? 0 : 1,
                                yAxisThickness: displayAxis != "true" ? 0 : 1,
                                yFormat: "%d",
                                xFormat: "MMM DD",
                                popupFormat:
                                    "<span class='value'>{y}</span> <span>" +
                                    popupLabel +
                                    "</span>"
                            });
                        } else {
                            skyjax(urlAction, options, null, true);
                        }
                        skyword.enforceLinkSecurity();
                    });
                    $(".cardGraph").hover(
                        function() {},
                        function() {
                            $(this)
                                .find("#graph-tooltip-content")
                                .parent()
                                .hide();
                        }
                    );
                },
                cardTotalMeters: function() {
                    $(".totalBar").each(function() {
                        var total = $(this)
                                .siblings(".totalIndicator")
                                .find(".totalIndicator-number")
                                .text()
                                .replace(/\,/g, ""),
                            count = $(this)
                                .find(".totalBar-label .number")
                                .text()
                                .replace(/\,/g, "");

                        if (isNaN(parseInt(total))) {
                            total = 0;
                            $(this)
                                .closest(".cardMod-content")
                                .find(".number")
                                .each(function() {
                                    total += parseInt(
                                        $(this)
                                            .text()
                                            .replace(/\,/g, "")
                                    );
                                });
                        }

                        if (count > 9999 && count < 1000000) {
                            $(this)
                                .find(".totalBar-label .number")
                                .addClass("size18px");
                        } else if (count > 1000000) {
                            $(this)
                                .find(".totalBar-label .number")
                                .addClass("size15px");
                        } else if (count >= 10000000) {
                            $(this)
                                .find(".totalBar-label .number")
                                .addClass("size13px");
                        }

                        $(this)
                            .find(".totalBar-count")
                            .css("width", (count / total) * 100 + "%");
                    });
                }
            };
        }
    ]);

/* UPLOAD PROFILE PICTURE FOR CONTRIBUTOR ENHANCEMENTS*/

app.controller("ProfilePictureController", [
    "$scope",
    "$translate",
    "setup",
    "$mdDialog",
    "contributorJoin",
    "accountId",
    "finalPictureUrl",
    function(
        $scope,
        $translate,
        setup,
        $mdDialog,
        contributorJoin,
        accountId,
        finalPictureUrl
    ) {
        $scope.addPhotoBtn = true;
        $scope.initialState = true;
        $scope.cropState = false;
        $scope.uploadState = false;
        $scope.showErrorMsg = false;
        $scope.accountId = accountId;
        console.log($scope.accountId);
        $scope.maxsize = 5000000; //5MB in bytes
        $scope.fileExtensions = ".png, .jpg, .gif, .jpeg";
        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.fileChosen = function(event) {
            $scope.showErrorMsg = false;

            //grab image height here
            var img = new Image();
            img.src = window.URL.createObjectURL(event.target.files[0]);
            img.onload = function() {
                $scope.imageWidth = img.naturalWidth;
                $scope.imageHeight = img.naturalHeight;

                console.log(
                    "width/height: " +
                        $scope.imageWidth +
                        " " +
                        $scope.imageHeight
                );
            };

            console.log("FILE CHOSEN");
            $scope.fileSize = event.target.files[0].size;
            console.log("File Size: " + $scope.fileSize);

            var fname = event.target.files[0].name.toLowerCase();
            var extStart = fname.lastIndexOf(".");
            var extEnd = fname.length;
            var fileExt = fname.slice(extStart, extEnd);
            console.log("FILE EXTENSION:" + fileExt);

            //set timeout because we have to grab image dimensions
            //because img.onload gets called after if statementd
            setTimeout(function() {
                //check size of file in bytes
                if ($scope.fileSize > $scope.maxsize) {
                    console.log(
                        "ERROR: file size too large " +
                            $scope.fileSize +
                            " " +
                            $scope.maxsize
                    );
                    $scope.showErrorMsg = true;
                    $scope.errorMsg = $translate.instant(
                        "angular.contributorjoin.error.filesize"
                    );
                    $scope.$apply();
                } else if ($scope.fileExtensions.indexOf(fileExt) === -1) {
                    //incorrect file type
                    console.log("INCORRECT FILE TYPE: " + fileExt);
                    $scope.showErrorMsg = true;
                    $scope.errorMsg = $translate.instant(
                        "angular.contributorjoin.error.filetype"
                    );
                    $scope.$apply();
                } else if (
                    $scope.imageWidth <= 130 ||
                    $scope.imageHeight <= 130
                ) {
                    //too small image dimensions
                    $scope.showErrorMsg = true;
                    $scope.errorMsg = $translate.instant(
                        "angular.contributorjoin.error.imagedimensions"
                    );
                    $scope.$apply();
                } else {
                    $scope.initialState = false;
                    $scope.uploadState = true;
                    //size and file type are ok
                    //so create FormData to send to backend
                    var formData = new FormData();
                    formData.append("profilePicture", event.target.files[0]);
                    formData.append("accountId", $scope.accountId);

                    var promise = contributorJoin.uploadProfilePicture(
                        formData
                    );

                    promise.then(
                        function(response) {
                            $(".crop-frame").croppie("destroy");
                            formData = undefined;
                            $scope.imageUrl = response.data;
                            console.log($scope.imageUrl);
                            $scope.addPhotoBtn = false;
                            $scope.initialState = false;
                            $scope.uploadState = false;
                            $scope.cropState = true;

                            var basic = $(".crop-frame").croppie({
                                viewport: {
                                    width: 130,
                                    height: 130
                                },
                                boundary: {
                                    width: 600,
                                    height: 260
                                },
                                url: $scope.imageUrl
                            });
                        },
                        function() {
                            $scope.errorMsg = "An error has occurred";
                        }
                    );
                }
            }, 500);
        }; //end fileChosen()

        $scope.cropAndSave = function() {
            $(".crop-frame")
                .croppie("result", {
                    type: "blob",
                    size: { height: 130, width: 130 },
                    format: "jpeg"
                })
                .then(function(blob) {
                    var croppedImageForm = new FormData();
                    croppedImageForm.append("croppedProfilePicture", blob);
                    croppedImageForm.append("accountId", $scope.accountId);
                    var savePromise = contributorJoin.saveProfilePicture(
                        croppedImageForm
                    );
                    $(".crop-frame").croppie("destroy");
                    $scope.initialState = false;
                    $scope.cropState = false;
                    $scope.uploadState = true;
                    savePromise.then(function(result) {
                        $scope.$parent.finalPictureUrl = result.data;
                        $mdDialog.hide();
                    });
                });
        };
    }
]);

app.service("reportProgramSummary", [
    "skywordGraphs",
    function(skywordGraphs) {
        var generateTotalChart = function() {
            var popupLabel = $("#popupLabelViews").val();
            skywordGraphs.getGraph({
                graphType: "stacked",
                action:
                    "viewProgramSummaryTotalViewsGraphAjax.action?programId=" +
                    $("#program-id").val() +
                    "&startdate=" +
                    $("#start-date").val() +
                    "&enddate=" +
                    $("#end-date").val(),
                location: "chart-div",
                xType: "date",
                xLabel: "  ",
                errorMessage: "",
                yType: "Number",
                yLabel: " ",
                yFormat: "%d",
                popupFormat:
                    "<span class='value'>{y}</span><span>" +
                    popupLabel +
                    "</span>"
            });
        };

        return {
            init: function() {
                //onReady
                $(function() {
                    var r = {
                        total: [],
                        writers: [],
                        published: [],
                        plays: []
                    };

                    /*
                     * Loop through table and get date and values.
                     */
                    var dataFound = false;
                    $("#swRow tr").each(function() {
                        dataFound = true;
                        var d = $(this)
                            .children("td.dateval")
                            .text();
                        /*
                         * Last row doesn't have any dates inside
                         */
                        if ("" !== d) {
                            r.writers.push({
                                x: d,
                                y: parseInt(
                                    $(this)
                                        .children("td.writers")
                                        .text()
                                        .replace(/\,/g, ""),
                                    10
                                )
                            });
                            r.published.push({
                                x: d,
                                y: parseInt(
                                    $(this)
                                        .children("td.published")
                                        .text()
                                        .replace(/\,/g, ""),
                                    10
                                )
                            });
                            r.plays.push({
                                x: d,
                                y: parseInt(
                                    $(this)
                                        .children("td.plays")
                                        .text()
                                        .replace(/\,/g, ""),
                                    10
                                )
                            });
                        }
                    });

                    $("#chart-select-div a").click(function() {
                        $("#chart-select-div a").removeClass("bold");
                        $(this).addClass("bold");
                        var chart = $(this)
                            .attr("href")
                            .substring(1);
                        var textLabel;
                        if (chart == "writers") {
                            textLabel =
                                "<span class='value'>{y}</span><span>" +
                                $("#column-writers").val() +
                                "</span>";
                        } else if (chart == "published") {
                            textLabel =
                                "<span class='value'>{y}</span><span>" +
                                $("#label-articles").val() +
                                "</span>";
                        } else if (chart == "plays") {
                            textLabel =
                                "<span class='value'>{y}</span><span>" +
                                $("#video-plays").val() +
                                "</span>";
                        }
                        var parameters = {
                            type: "json",
                            location: "chart-div",
                            graphData: r["total"],
                            title: "",
                            highlight: true,
                            zoom: true,
                            xType: "date",
                            xLabel: "",
                            xLabelAngle: 0,
                            yType: "Number",
                            yLabel: "      ",
                            yFormat: "%d",
                            legendDisplay: false,
                            legendLocation: "",
                            popupFormat: textLabel,
                            keyLabel: ""
                        };
                        $("#chart-div").html("");
                        if (chart == "total") {
                            generateTotalChart();
                        } else {
                            parameters.graphData = r[chart];
                            skywordGraphs.createAreaGraph(parameters);
                        }
                        return false;
                    });

                    /* Chart Drop Down Menu On Program Activity page - managerReportProgramSummary.jsp  */
                    $("#programActivityChartSelect").bind("change", function() {
                        var chart = $(this).val();
                        var programActivityChartIds;
                        $("#chart-div").html("");
                        switch (chart) {
                            case "total":
                                generateTotalChart();
                                return false;
                            //break;
                            case "writers":
                                programActivityChartIds = "#column-writers";
                                break;
                            case "published":
                                programActivityChartIds = "#label-articles";
                                break;
                            case "plays":
                                programActivityChartIds = "#video-plays";
                                break;
                        }
                        var textLabel =
                            "<span class='value'>{y}</span><span>" +
                            $(programActivityChartIds).val() +
                            "</span>";
                        var parameters = {
                            type: "json",
                            location: "chart-div",
                            graphData: r["total"],
                            title: "",
                            highlight: true,
                            zoom: true,
                            xType: "date",
                            xLabel: "",
                            xLabelAngle: 0,
                            yType: "Number",
                            yLabel: "      ",
                            yFormat: "%d",
                            legendDisplay: false,
                            legendLocation: "",
                            popupFormat: textLabel,
                            keyLabel: ""
                        };
                        parameters.graphData = r[chart];
                        skywordGraphs.createAreaGraph(parameters);
                        return false;
                    });

                    $("#chart-select-div a[href=#total]").addClass("bold");

                    if (dataFound) {
                        $("#chart-div").css("height", "400px");
                    } else {
                        $(
                            "#dateRangeDiv, #chart-select-div, #chart-div, .cardMod"
                        ).hide();
                    }

                    if ($("#chart").val() != null) {
                        $("#" + $("#chart").val()).click();
                    } else {
                        generateTotalChart();
                    }
                });
            }
        };
    }
]);

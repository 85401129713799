app.service("discussions", [
    "$http",
    function($http) {
        return {
            get: function(reqData, callback) {
                $http
                    .get("/API/discussion/" + reqData.contentRequestId)
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            createDiscussion: function(reqData, callback) {
                $http
                    .post(
                        "/API/discussion/create",
                        {
                            contentRequestId: reqData.contentRequestId,
                            parentDiscussionId: reqData.parentDiscussionId,
                            enteredText: reqData.enteredText,
                            discussionMessage: reqData.discussionMessage
                        },
                        {
                            headers: { "Content-Type": "application/json" }
                        }
                    )
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            deleteDiscussion: function(reqData, callback) {
                $http
                    .delete("/API/discussion/delete/" + reqData.id)
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            }
        };
    }
]);

app.controller("DialogController", [
    "$scope",
    "$translate",
    "setup",
    "$mdDialog",
    "title",
    "ok",
    "cancel",
    "content",
    function($scope, $translate, setup, $mdDialog, title, ok, cancel, content) {
        $scope.title = title;
        $scope.ok = ok;
        $scope.cancel = cancel;
        $scope.content = content;

        $scope.hideDialog = function(data) {
            $mdDialog.hide(data);
        };

        $scope.cancelDialog = function() {
            $mdDialog.cancel();
        };
    }
]);

app.controller("setLoginMode", ["$scope", function($scope) {}])
    .controller("uploadBannerAjax", ["$scope", function($scope) {}])
    .controller("cropProgramBannerAjax", ["$scope", function($scope) {}])
    .controller("removeProgramBannerAjax", ["$scope", function($scope) {}])
    .controller("adminUploadBannerAjax", ["$scope", function($scope) {}])
    .controller("adminCropProgramBannerAjax", ["$scope", function($scope) {}])
    .controller("adminRemoveProgramBannerAjax", ["$scope", function($scope) {}])
    .controller("addContentSampleVideo", ["$scope", function($scope) {}])
    .controller("editContentSampleVideo", ["$scope", function($scope) {}])
    .controller("joinAddContentSampleVideo", ["$scope", function($scope) {}])
    .controller("jsTag", ["$scope", function($scope) {}])
    .controller("timezonePicker", [
        "$scope",
        "setup",
        function($scope, setup) {
            var timezoneGroupId = $("#timezone-group-id").val(),
                timezoneId = $("#timezone-id").val();
            //console.log("timezonePicker");
            setup.timezoneDropdown(timezoneGroupId, timezoneId, false);
        }
    ])
    .controller("externalCalendarSettings", [
        "$scope",
        "setup",
        function($scope, setup) {
            var timezoneGroupId = $("#timezone-group-id").val(),
                timezoneId = $("#timezone-id").val();
            setup.timezoneDropdown(timezoneGroupId, timezoneId, true);
            var defaultPorts = { "http:": 80, "https:": 443 };
            var root =
                window.location.protocol +
                "//" +
                window.location.hostname +
                (window.location.port &&
                window.location.port != defaultPorts[window.location.protocol]
                    ? ":" + window.location.port
                    : "");

            var qs = (function(a) {
                if (a == "") return {};
                var b = {};
                for (var i = 0; i < a.length; ++i) {
                    var p = a[i].split("=", 2);
                    if (p.length == 1) b[p[0]] = "";
                    else b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
                }
                return b;
            })(window.location.search.substr(1).split("&"));
            $scope.iCalHref = root + "/iCal.action?guid=" + qs["guid"];
        }
    ])
    .controller("timezonePickerAjax", ["$scope", function($scope) {}])
    .controller("uploadDigitalAsset", ["$scope", function($scope) {}])
    .controller("similarTopics", ["$scope", function($scope) {}])
    .controller("similarTopicsModule", ["$scope", function($scope) {}])
    .controller("managerContentTypeModify", ["$scope", function($scope) {}])
    .controller("managerContentTypeModifyModule", [
        "$scope",
        function($scope) {}
    ])
    .controller("digitalAssetContentTypeModifyModule", [
        "$scope",
        "common",
        function($scope, common) {
            common.init();
        }
    ])
    .controller("assignmentContentTypeModify", [
        "$scope",
        "common",
        function($scope, common) {
            common.init();
        }
    ])
    .controller("digitalAssetContentTypeModify", [
        "$scope",
        "common",
        function($scope, common) {
            common.init();
        }
    ])
    .controller("importContentTypeModify", [
        "$scope",
        "common",
        function($scope, common) {
            common.init();
        }
    ])
    .controller("rfpContentTypeModify", [
        "$scope",
        "common",
        function($scope, common) {
            common.init();
        }
    ])
    .controller("contentTypeModifyAjax", ["$scope", function($scope) {}])
    .controller("managerContentTypeModifyAjax", ["$scope", function($scope) {}])
    .controller("contentTypeChangeAjax", ["$scope", function($scope) {}])
    .controller("getContentTypeAjax", ["$scope", function($scope) {}])
    .controller("projectSelectAjax", ["$scope", function($scope) {}])
    //.controller('findLocationsAjax', ['$scope', function($scope) {}])
    .controller("completeKeywordAjax", ["$scope", function($scope) {}])
    .controller("getkeywordDataAjax", ["$scope", function($scope) {}])
    .controller("completeBylineAjax", ["$scope", function($scope) {}])
    .controller("findCategories", ["$scope", function($scope) {}])
    .controller("assignmentsAvailableForWriterAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("writerInfoMiniModuleAjax", ["$scope", function($scope) {}])
    .controller("suggestedKeywordModuleAjax", ["$scope", function($scope) {}])
    .controller("randomProgramModule", ["$scope", function($scope) {}])
    .controller("confirmModalAjax", ["$scope", function($scope) {}])
    .controller("writerCompensationOverlayAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("payrateGetAjax", ["$scope", function($scope) {}])
    .controller("contentPerformanceModule", ["$scope", function($scope) {}])
    .controller("profileCompletionModule", ["$scope", function($scope) {}])
    .controller("invitingContributorsModuleLO", [
        "$scope",
        function($scope) {
            //onReady
            $(function() {
                $(".slides").slidesjs({
                    width: 600,
                    navigation: {
                        active: false,
                        effect: "slide"
                    },
                    play: {
                        auto: true,
                        effect: "slide",
                        interval: 5000
                    }
                });
            });
        }
    ])
    .controller("invitingContributorsModuleLI", [
        "$scope",
        function($scope) {
            //onReady
            $(function() {
                $(".slides").slidesjs({
                    width: 600,
                    navigation: {
                        active: false,
                        effect: "slide"
                    },
                    play: {
                        auto: true,
                        effect: "slide",
                        interval: 5000
                    }
                });
            });
        }
    ])
    .controller("contributorContentSamplesAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("agencyContributorContentSamplesAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("profileContentSamplesAjax", ["$scope", function($scope) {}])
    .controller("profilePostsAjax", ["$scope", function($scope) {}]);

app.factory("attachmentService", [
    "$http",
    function($http) {
        // This method saves a video as an attachment in the database and then
        // return it with the id of the new attachment set
        return {
            addVideo: function(attachment, callback) {
                var xsrf = $.param({
                    attachment: JSON.stringify(attachment)
                });
                $http
                    .post("/API/template/video/add", attachment, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            // push to YouTube
            pushToYouTube: function(attachment, callback) {
                $http({
                    url: "/API/youtube/upload/",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    },
                    data:
                        "attachmentId=" +
                        attachment.id +
                        "&oAuthInfoId=" +
                        attachment.oAuthInfo.id +
                        "&privacySetting=" +
                        attachment.youTubeStatus
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            // get processing status YouTube
            getProcessingStatus: function(attachment, callback) {
                $http
                    .get("/API/youtube/processing-status/" + attachment.id, {})
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            // get privacy status YouTube
            getPrivacyStatus: function(attachment, callback) {
                $http
                    .get("/API/youtube/privacy-status/" + attachment.id, {})
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            getRejectionStatus: function(attachment, callback) {
                $http
                    .get("/API/youtube/rejection-status/" + attachment.id, {})
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            }
        };
    }
]);

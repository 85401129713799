app.controller("applications", ["$scope", function($scope) {}])
    .controller("viewApplication", [
        "$scope",
        "contentSearch",
        "notes",
        "qualityRating",
        "writers",
        "call",
        function($scope, contentSearch, notes, qualityRating, writers, call) {
            contentSearch.init();
            qualityRating.init();
            notes.init();
            writers.init();

            //onReady
            $(function() {
                var applicationId = $("#application-id").val();
                $(".rejectApplication").click(function() {
                    var urlAction = "rejectApplicationAjax.action";
                    return call.overlay(
                        urlAction,
                        { id: applicationId },
                        750,
                        null
                    );
                });
                $(".approveApplication").click(function() {
                    var urlAction = "approveApplicationAjax.action";
                    return call.overlay(
                        urlAction,
                        { id: applicationId },
                        null,
                        null,
                        null,
                        "skyword.setupPayrate"
                    );
                });

                $("#application a.tab").click();
                if ($("#plagiarism-module").length > 0) {
                    var plagiarismCount = $("input#plagiarismCount").val();
                    if (plagiarismCount > 0) {
                        $("#plagiarism-module")
                            .addClass("contentFound")
                            .find(".moduleControlsBox .toggle")
                            .show();
                        $("#plagiarism-module .plagiarismNone").hide();
                        $("#plagiarism-module .plagiarismMatch").show();
                    } else {
                        $("#plagiarism-module .plagiarismMatch").hide();
                        $("#plagiarism-module .plagiarismNone").show();
                    }
                }
            });
        }
    ])
    .controller("approveApplicationAjax", ["$scope", function($scope) {}])
    .controller("agencyApproveApplicationAjax", ["$scope", function($scope) {}])
    .controller("adminAgencyApproveApplicationAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("rejectApplicationAjax", ["$scope", function($scope) {}])
    .controller("agencyRejectApplicationAjax", ["$scope", function($scope) {}])
    .controller("adminAgencyRejectApplicationAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("saveCommentAjax", ["$scope", function($scope) {}]);

app.controller("bids", [
    "$scope",
    "$translate",
    "$filter",
    "ngTableParams",
    "rfp",
    function($scope, $translate, $filter, ngTableParams, rfp) {
        $scope.state = "dataloading";
        $scope.data = [];

        $scope.acceptedBids = [];

        rfp.getAcceptedBids($scope.contentRequestId, function(data) {
            $scope.acceptedBids = data;
        });

        rfp.getBids($scope.contentRequestId, function(data) {
            $scope.data = data;
            $scope.state = "dataready";
        });

        rfp.getData($scope.contentRequestId, function(rfpData) {
            $scope.numberInterested = rfpData.numberInterested;
        });

        switch ($scope.stateId) {
            case 43:
                $scope.stateType = "draft";
                $scope.stateName = "angular.rfp.state.draft";
                break;
            case 44:
                $scope.stateType = "open";
                $scope.stateName = "angular.rfp.state.open";
                $scope.stateIn = "angular.rfp.description.closes";
                break;
            case 45:
                $scope.stateType = "closed";
                $scope.stateName = "angular.rfp.state.closed";
                $scope.stateIn = "angular.rfp.description.closed";
                break;
            case 46:
                $scope.stateType = "cancelled";
                $scope.stateName = "angular.rfp.state.cancelled";
                break;
            default:
                $scope.stateType = "other";
                $scope.stateName = "angular.rfp.state.other";
                break;
        }

        $scope.setupTable = function() {
            if (undefined !== $scope.tableParams) {
                $scope.tableParams.reload();
            } else if (undefined !== $scope.data) {
                $scope.tableParams = new ngTableParams(
                    {
                        page: 1,
                        count: 10,
                        sorting: {
                            byline: "asc" // initial sorting
                        }
                    },
                    {
                        total: $scope.data.length,
                        getData: function($defer, params) {
                            params.total($scope.data.length);
                            var orderedData = params.filter()
                                ? $filter("filter")(
                                      $scope.data,
                                      params.filter()
                                  )
                                : $scope.data;
                            $scope.filteredData = orderedData.slice(
                                (params.page() - 1) * params.count(),
                                params.page() * params.count()
                            );
                            $defer.resolve($scope.filteredData);
                        },
                        $scope: $scope
                    }
                );
            }
        };
        $scope.$watch("data", $scope.setupTable);
    }
]);

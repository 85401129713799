app.service("login", [
    "skyword",
    "call",
    function(skyword, call) {
        return {
            init: function() {
                var BrowserDetect = {
                    init: function() {
                        this.browser =
                            this.searchString(this.dataBrowser) ||
                            "An unknown browser";
                        this.version =
                            this.searchVersion(navigator.userAgent) ||
                            this.searchVersion(navigator.appVersion) ||
                            "an unknown version";
                        this.OS =
                            this.searchString(this.dataOS) || "an unknown OS";
                    },
                    searchString: function(data) {
                        for (var i = 0; i < data.length; i++) {
                            var dataString = data[i].string;
                            var dataProp = data[i].prop;
                            this.versionSearchString =
                                data[i].versionSearch || data[i].identity;
                            if (dataString) {
                                if (dataString.indexOf(data[i].subString) != -1)
                                    return data[i].identity;
                            } else if (dataProp) return data[i].identity;
                        }
                    },
                    searchVersion: function(dataString) {
                        var index = dataString.indexOf(
                            this.versionSearchString
                        );
                        if (index == -1) return;
                        return parseFloat(
                            dataString.substring(
                                index + this.versionSearchString.length + 1
                            )
                        );
                    },
                    dataBrowser: [
                        {
                            string: navigator.userAgent,
                            subString: "OPR",
                            identity: "Opera"
                        },
                        {
                            string: navigator.userAgent,
                            subString: "Chrome",
                            identity: "Chrome"
                        },
                        {
                            string: navigator.userAgent,
                            subString: "Firefox",
                            identity: "Firefox"
                        },
                        {
                            string: navigator.userAgent,
                            subString: "MSIE",
                            identity: "Explorer",
                            versionSearch: "MSIE"
                        },
                        {
                            string: navigator.userAgent,
                            subString: "Trident",
                            identity: "Explorer",
                            versionSearch: "rv"
                        },
                        {
                            string: navigator.userAgent,
                            subString: "Safari",
                            identity: "Safari",
                            versionSearch: "Version"
                        }
                    ],
                    dataOS: [
                        {
                            string: navigator.platform,
                            subString: "Win",
                            identity: "Windows"
                        },
                        {
                            string: navigator.platform,
                            subString: "Mac",
                            identity: "Mac"
                        },
                        {
                            string: navigator.platform,
                            subString: "Linux",
                            identity: "Linux"
                        }
                    ]
                };

                //onReady
                $(function() {
                    /*
                     * login.jsp, resetPassword.jsp
                     */
                    $("#username").focus();
                    skyword.passwordStrength();

                    /*
                     * brandedJoin.jsp
                     */
                    $("html").on("click", "#create-account-button", function() {
                        window.location.href =
                            $("#progApplyForm").attr("action") +
                            "?programId=" +
                            $('input[name="programId"]').val() +
                            "&isNewUser=true";
                        return false;
                    });

                    if (
                        "/login.action" === window.location.pathname ||
                        "/" === window.location.pathname
                    ) {
                        BrowserDetect.init();
                        // Base supported version
                        var baseChrome = 60,
                            baseFF = 50,
                            baseSafari = 8;
                        var urlAction = "/browserMessageAjax.action";
                        if (
                            !(
                                ("Chrome" === BrowserDetect.browser &&
                                    BrowserDetect.version >= baseChrome) ||
                                ("Firefox" === BrowserDetect.browser &&
                                    BrowserDetect.version >= baseFF) ||
                                ("Safari" === BrowserDetect.browser &&
                                    BrowserDetect.version >= baseSafari)
                            ) || ("Explorer" === BrowserDetect.browser ||
                            window.navigator.userAgent.indexOf("Edge") > -1)
                        ) {
                            call.overlay(
                                urlAction,
                                null,
                                650,
                                375,
                                null,
                                function() {
                                    if(BrowserDetect.OS === 'Windows'){$('.browser-safari').hide();}
                                    if(BrowserDetect.browser === "Explorer" || window.navigator.userAgent.indexOf("Edge") > -1){
                                        $(".ie-browsers").css("display", "block");
                                        $(".default-browsers").hide();
                                    }
                                }
                            );
                            $(".sw-modal").colorbox.resize();
                        }
                    }
                    var forms = document.getElementsByTagName("form");
                    for (var i = 0; i < forms.length; i++) {
                        /* jshint ignore:start */
                        forms[i].addEventListener(
                            "invalid",
                            function(e) {
                                $("button").removeClass("disabled");
                            },
                            true
                        );
                        /* jshint ignore:end */
                    }
                    $("html").on("click", "#reverify-problems", function() {
                        $(".reverifyProblems").hide();
                        $("#reverify-problems-message").slideDown();
                        return false;
                    });
                    $("html").on("click", "#resend-sms-code", function() {
                        $("#reverify-problems-message").addClass("loading");
                        var urlAction = "twoFactorSmsAjax.action";
                        call.skyjax(urlAction, {
                            cache: false,
                            type: "POST",
                            dataType: "html",
                            url: urlAction,
                            complete: function() {
                                setTimeout(function() {
                                    $(".reverifyProblems").show();
                                    $("#reverify-problems-message")
                                        .removeClass("loading")
                                        .removeClass("success")
                                        .slideUp();
                                }, 500);
                                $("#reverify-problems-message").addClass(
                                    "success"
                                );
                            },
                            error: function(data, status, err) {
                                console.log([data, status, err]);
                            }
                        });
                        return false;
                    });
                    $("html").on("click", "#send-one-time-code", function() {
                        $("#reverify-problems-message").addClass("loading");
                        var urlAction = "sendOneTimeCode.action";
                        call.skyjax(urlAction, {
                            cache: false,
                            type: "GET",
                            dataType: "html",
                            url: urlAction,
                            complete: function() {
                                setTimeout(function() {
                                    $(".reverifyProblems").show();
                                    $("#reverify-problems-message")
                                        .removeClass("loading")
                                        .slideUp();
                                    $("#reverify-problems-message").hide();
                                    $("#single-use-code-sent").show();
                                }, 500);
                            },
                            error: function(data, status, err) {
                                console.log([data, status, err]);
                            }
                        });
                        return false;
                    });
                    $("html").on("click", ".learnMorePassword", function() {
                        $(".learnMorePassword").hide();
                        $(".learnMore").show();
                        return false;
                    });
                    $("html").on("click", ".closeLearnMore", function() {
                        $(".learnMorePassword").show();
                        $(".learnMore").hide();
                        return false;
                    });

                    $("html").on("click", "#togglePassword", function() {
                        const password = document.querySelector('#password');
                        const togglePass = document.querySelector('#togglePassword');
                        const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
                        password.setAttribute('type', type);
                        //password.attr('prop', 'text');
                        togglePass.classList.toggle('fa-eye-slash');
                        if(type === 'text') {
                            togglePass.setAttribute('data-icon', 'eye-slash')
                        } else {
                            togglePass.setAttribute('data-icon', 'eye')
                        }
                    })
                });
            }
        };
    }
]); // end login

app.service("imageAnnotations", [
    "$http",
    function($http) {
        return {
            init: init,
            loadAll: loadAll,
            create: save,
            update: save,
            remove: remove
        };

        function init() {
            anno.setProperties({
                outline: "", //outline: outline color for annotation and selection shapes
                stroke: "#EB5338", //stroke color for annotation and selection shapes
                fill: "", //fill color for annotation and selection shapes
                hi_stroke: "#333f48", //stroke color for highlighted annotation shapes
                hi_outline: "#2ECC71", //outline color for highlighted annotation shapes
                hi_fill: "rgba(46,204,113,.15)", //fill color for highlighted annotation shapes
                outline_width: 0, //outline width for annotation and selection shapes
                stroke_width: 2, //stroke width for annotation and selection shapes
                hi_outline_width: 1, //outline width for highlighted annotation shapes
                hi_stroke_width: 2 //stroke width for highlighted annotation shapes
            });
        }

        function loadAll(attachmentId) {
            if (null === attachmentId) {
                return;
            }
            return $http.get("/API/template/image-annotation/" + attachmentId);
        }

        function save(annotation) {
            if (null === annotation) {
                return;
            }
            return $http.put("/API/template/image-annotation/", annotation);
        }

        function remove(annotationId) {
            if (null === annotationId || -1 === parseInt(annotationId, 10)) {
                return;
            }
            return $http.delete(
                "/API/template/image-annotation/" + annotationId
            );
        }
    }
]);

app.service("kalturaService", [
    "$http",
    function($http) {
        return {
            // get Kaltura secret
            getKs: function(callback) {
                $http
                    .get("/API/template/kaltura-key-get-ks")
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            updateThumbnail: function(entryId, second, callback) {
                $http
                    .get(
                        "/API/template/updateThumbnail/kalturaId/" +
                            entryId +
                            "/second/" +
                            second
                    )
                    .success(function(data, status, headers, config) {
                        callback();
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            /**
             * Get the duration of a video in millisecond using a Kaltura entry id
             */
            getDuration: function(entryId, callback) {
                $http
                    .get("/API/template/getDuration/kalturaId/" + entryId)
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            /**
             * Add video (attachment) in database and returns it
             */
            addVideo: function(data, callback) {
                var xsrf = $.param({
                    data: JSON.stringify(data)
                });
                $http
                    .post("/API/template/video/add", data, {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        timeout: 600000 // Give the server a long time to respond, as calculating duration can happen long after the data is transferred
                    })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            removeVideo: function(data, callback) {
                $http
                    .post("/removeAttachmentAjax.action", $.param(data), {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                        }
                    })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            addPlayer: function(kalturaId, targetId) {
                mw.setConfig("Kaltura.LeadWithHTML5", true);
                kWidget.embed({
                    targetId: targetId,
                    wid: "_1737042",
                    uiconf_id: 23937082,
                    flashvars: {
                        allowFullScreen: true,
                        allowNetworking: "all",
                        allowScriptAccess: "always",
                        autoPlay: false
                    },
                    cache_st: 1406907495,
                    entry_id: kalturaId
                });
            },
            getKalturaVideos: function(attachmentId) {
                return $http.get(
                    "/API/content/attachment/" + attachmentId + "/kaltura"
                );
            }
        };
    }
]);

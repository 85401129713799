app.service("program", [
    "$http",
    function($http) {
        return {
            contentTypesAndCategories: contentTypesAndCategories
        };

        function contentTypesAndCategories(programId, calendarShareId) {
            var url = "/API/program/" + programId + "/content-type";
            return $http.get(url);
        }
    }
]);

app.service("calendar", [
    "$http",
    function($http) {
        return {
            getCalendar: function(
                calendarSettings,
                urlAction,
                callback,
                errorCallback
            ) {
                $http({
                    url: urlAction,
                    method: "POST",
                    data: calendarSettings
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                        errorCallback(data);
                    });
            }
        };
    }
]);

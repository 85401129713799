(function() {
    app.factory("idea", [
        "$http",
        function($http) {
            return {
                saveIdea: saveIdea,
                saveTargetDate: saveTargetDate,
                saveTargetDatesMulti: saveTargetDatesMulti,
                savePublishDate: savePublishDate,
                saveSubmissionDate: saveSubmissionDate,
                getStates: getStates,
                getWorkflow: getWorkflow,
                saveWorkflow: saveWorkflow,
                saveWorkflowMulti: saveWorkflowMulti,
                createIdea: createIdea,
                getHistory: getHistory,
                getReviewers: getReviewers,
                toggleReviewed: toggleReviewed,

                /**
                 * Get list of ideas for a program
                 */
                getIdeas: function(
                    month,
                    year,
                    stateId,
                    programId,
                    contentType,
                    view,
                    callback,
                    errorCallback
                ) {
                    if (!contentType) {
                        contentType = "";
                    } else {
                        contentType = "/?" + contentType;
                    }
                    $http
                        .get(
                            "/API/programs/" +
                                programId +
                                "/ideas" +
                                contentType,
                            {
                                params: {
                                    month: month,
                                    year: year,
                                    "state-id": stateId,
                                    view: view
                                }
                            }
                        )
                        .success(function(data, status, headers, config) {
                            //console.log("service data: ", data);
                            callback(data);
                        })
                        .error(function(data, status, headers, config) {
                            console.log("bad data");
                            console.log(data);
                        });
                },
                /**
                 *
                 */
                getIdea: function(
                    programId,
                    contentRequestId,
                    callback,
                    errorCallback
                ) {
                    $http({
                        url:
                            "/API/programs/" +
                            programId +
                            "/ideas/" +
                            contentRequestId,
                        method: "GET"
                    })
                        .success(function(data, status, headers, config) {
                            //console.log("service data: ", data);
                            callback(data);
                        })
                        .error(function(data, status, headers, config) {
                            //console.log("bad data");
                            //console.log(data);
                        });
                },
                getKeywords: function(
                    keyword,
                    categoryId,
                    callback,
                    errorCallback
                ) {
                    $http({
                        url:
                            "suggestedKeywordModuleAjax.action?keyword=" +
                            keyword +
                            "&categoryId=" +
                            categoryId,
                        method: "GET"
                    })
                        .success(function(data, status, headers, config) {
                            callback(data);
                            //console.log("keyword data: ", data);
                        })
                        .error(function(data, status, headers, config) {
                            //console.log("bad data");
                            //console.log(data);
                        });
                },
                getContentTypes: function(programId, callback, errorCallback) {
                    $http({
                        url:
                            "/API/programs/" +
                            programId +
                            "/ideas/content-types/",
                        method: "GET"
                    })
                        .success(function(data, status, headers, config) {
                            callback(data);
                            //console.log("keyword data: ", data);
                        })
                        .error(function(data, status, headers, config) {
                            //console.log("bad data");
                            //console.log(data);
                        });
                },
                getKpi: function(
                    programId,
                    month,
                    year,
                    callback,
                    errorCallback
                ) {
                    $http
                        .get("/API/programs/" + programId + "/ideas/kpi", {
                            params: {
                                month: month,
                                year: year
                            }
                        })
                        .success(function(data, status, headers, config) {
                            callback(data);
                            //console.log("kpi data: ", data);
                        })
                        .error(function(data, status, headers, config) {
                            //console.log("bad kpi data");
                            //console.log(data);
                        });
                }
            };

            function getReviewers(programId, contentRequestId) {
                return $http.get(
                    "/API/programs/" +
                        programId +
                        "/ideas/reviewers?contentRequestId=" +
                        contentRequestId
                );
            }

            function toggleReviewed(programId, contentRequestId) {
                return $http.put(
                    "/API/programs/" + programId + "/ideas/toggle-is-reviewed",
                    contentRequestId
                );
            }

            function getHistory(programId, contentRequestId) {
                return $http.get(
                    "/API/programs/" +
                        programId +
                        "/ideas/" +
                        contentRequestId +
                        "/history"
                );
            }

            function getWorkflow(programId, contentRequestId) {
                return $http.get(
                    "/API/programs/" +
                        programId +
                        "/ideas/" +
                        contentRequestId +
                        "/next-states"
                );
            }

            function getStates(programId) {
                return $http.get(
                    "/API/programs/" +
                        programId +
                        "/ideas/states?include-all=true"
                );
            }

            function saveWorkflow(programId, updatedWorkflow) {
                return $http.put(
                    "/API/programs/" + programId + "/ideas/next-states",
                    updatedWorkflow
                );
            }
            function saveWorkflowMulti(programId, crs, newStateId) {
                return $http.put(
                    "/API/programs/" +
                        programId +
                        "/ideas/next-states-multi?newStateId=" +
                        newStateId,
                    crs
                );
            }

            function saveIdea(elementDTO) {
                return $http.put("/API/content/save-data", elementDTO);
            }

            function saveTargetDate(programId, elementDTO) {
                return $http.put(
                    "/API/programs/" + programId + "/ideas/target-dates/",
                    elementDTO
                );
            }
            function saveTargetDatesMulti(
                programId,
                contentRequestIds,
                targetDate
            ) {
                return $http.put(
                    "/API/programs/" +
                        programId +
                        "/ideas/target-dates-multi?targetDate=" +
                        targetDate,
                    contentRequestIds
                );
            }
            function saveSubmissionDate(contentRequestId, submissionDeadline) {
                return $http.put(
                    "/API/v2/content-requests/" +
                        contentRequestId +
                        "/submit-deadline",
                    {
                        dateSubmitDeadline: submissionDeadline
                    }
                );
            }
            function savePublishDate(contentRequestId, publishDate) {
                return $http.put(
                    "/API/v2/content-requests/" +
                        contentRequestId +
                        "/publish-on",
                    {
                        datePublishOn: publishDate
                    }
                );
            }
            function createIdea(programId, sharedDTO) {
                return $http.put(
                    "/API/programs/" + programId + "/ideas/create",
                    sharedDTO
                );
            }
            function getKpi(programId, month, year) {
                return $http.get("/API/programs/" + programId + "/ideas/kpi");
            }
        }
    ]);
})();

app.controller("home", ["$scope", function($scope) {}])
    .controller("myAccount", [
        "$scope",
        "call",
        function($scope, call) {
            $("#allNotifsEnabled").on("change", function(e) {
                var urlAction = "updateAllNotificationsAccountAjax";
                options = {
                    cache: false,
                    type: "POST",
                    data: {
                        allNotifsEnabled: e.target.checked
                    },
                    url: urlAction,
                    success: function(data) {
                        $("#notifsDisabledLabel").toggleClass("hidden");
                    },
                    error: function(data, status, err) {}
                };
                call.ajax(urlAction, options);
            });
        }
    ])
    .controller("myProfile", [
        "$scope",
        "account",
        "contentSearch",
        "ContributorAgreementService",
        function($scope, account, contentSearch, ContributorAgreementService) {
            account.init();
            contentSearch.init();
            $scope.openAgreementDialog = function() {
                ContributorAgreementService.contributorAgreementDialog();
            };
        }
    ])
    .controller("viewProfile", [
        "$scope",
        "account",
        "notes",
        "qualityRating",
        "contentSearch",
        "redactor",
        function(
            $scope,
            account,
            notes,
            qualityRating,
            contentSearch,
            redactor
        ) {
            account.init();
            notes.init();
            qualityRating.init();
            contentSearch.init();
            redactor.init();
        }
    ])
    .controller("agencyProfile", [
        "$scope",
        "account",
        "notes",
        "qualityRating",
        "contentSearch",
        "agency",
        "redactor",
        "cards",
        function(
            $scope,
            account,
            notes,
            qualityRating,
            contentSearch,
            agency,
            redactor,
            cards
        ) {
            account.init();
            qualityRating.init();
            contentSearch.init();
            agency.init();
            notes.init();
            redactor.init();
        }
    ])
    .controller("programProfile", [
        "$scope",
        "account",
        "notes",
        "qualityRating",
        "contentSearch",
        "$translate",
        "skyword",
        function(
            $scope,
            account,
            notes,
            qualityRating,
            contentSearch,
            $translate,
            skyword
        ) {
            account.init();
            notes.init();
            qualityRating.init();
            contentSearch.init();

            $("body")
                .on("click", "#addToList", function() {
                    console.log(
                        $(".viewBanner")
                            .find("img")
                            .attr("src")
                    );
                    var programId = $("#attrs-id").data("programid");
                    const agencyId = $("body").attr("data-enterpriseid");
                    const token = skyword.getToken("access_token");
                    const getContribListURL = skyword.getAPIURL(
                        "contributors/agency/" +
                            agencyId +
                            "/program/" +
                            programId +
                            "/search"
                    );
                    jQuery.ajax({
                        cache: false,
                        type: "GET",
                        headers: {
                            Accept: "application/json;",
                            "Content-Type": "application/json;"
                        },
                        url: getContribListURL,
                        beforeSend: function(xhr) {
                            xhr.setRequestHeader(
                                "Authorization",
                                `Bearer ${token}`
                            );
                        },
                        success: function(data) {
                            let html = `<div class="add-to-list-pane" style="width:650px;">
                                    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start;">
                                        <div style="display: flex; flex-direction:column; flex:1">
                                            <h1>${$translate.instant(
                                                "sw.contributorsearch.addcontributor"
                                            )}</h1>
                                        </div>
                                        <div style="display:flex; flex-direction: row; gap:0px 5px;">
                                            <button class="add-to-list-btns" id="createNewList" style="margin-right:0;"><span class="icon-plus" style="color: white;"></span>&nbsp;${$translate.instant(
                                                "sw.contributorsearch.createnewlist"
                                            )}</button>
                                        </div>
                                    </div>`;
                            if (data.length > 0) {
                                html += `<div style="display: flex; gap:10px; flex-direction: row;">
                                        <div class="contributor-lists-created" style="display:flex; flex-direction:column; flex:1; text-align: left;">
                                            <label style="left: 18px;
                                            position: relative;
                                            font-size: 16px;
                                            margin: 5px 0 15px 0;">${$translate.instant(
                                                "sw.contributorsearch.lists"
                                            )}</label>
                                            <ul style="max-height:325px; border:1px solid #787878; padding:15px 0 10px 10px;">`;
                                for (let i = 0; i < data.length; i++) {
                                    html += `       <li style="border: 1px solid #878787; padding: 10px; width: 90%; margin-bottom: 10px;">
                                                    <label class="checkboxContainer" style="margin: 5px 0;">${
                                                        data[i].name
                                                    }
                                                        <input type="checkbox" class="contributorListCheckbox" value="${
                                                            data[i].id
                                                        }" />
                                                        <span class="contribSearchCheckmark"></span>
                                                    </label>
                                                    <p style="margin: 0; padding-bottom: 0;">${
                                                        data[i].description
                                                    }</p>
                                                </li>`;
                                }
                                html += `       </ul>
                                            <div style="margin-top:10px;">
                                                <button class="add-to-list-btns disabled" id="addContributorsToList" disabled="disabled">${$translate.instant(
                                                    "sw.contributorsearch.addtolist"
                                                )}</button>
                                            </div>
                                        </div>
                                    </div>
                             </div>`;
                                var opts = {
                                    html: html
                                };
                                $.colorbox(opts);
                            } else {
                                html += `<div style="display: flex; gap:10px; flex-direction: row;">
                                        <div style="display:flex; flex-direction:column; flex:1; text-align: left;">
                                        <label style="left: 18px;
                                        position: relative;
                                        font-size: 16px;
                                        margin: 5px 0 15px 0;">${$translate.instant(
                                            "sw.contributorsearch.lists"
                                        )}</label>
                                        <ul>`;
                                html += `       <li style="width: 90%; margin-bottom: 10px;">
                                                <p style="margin: 5px 0; font-size: 1.6rem;">${$translate.instant(
                                                    "nucleus.panel.nodata"
                                                )}
                                                </p>
                                            </li>`;
                                html += `
                                        </ul>
                                        </div>
                                        </div>
                             </div>`;
                                var opts = {
                                    html: html
                                };
                                $.colorbox(opts);
                            }
                        },
                        error: function(error) {
                            console.log(error);
                        }
                    });
                })
                .on("click", ".contributorListCheckbox", function() {
                    var checkboxes = document.getElementsByClassName(
                        "contributorListCheckbox"
                    );

                    var createListBtn = document.getElementById(
                        "createNewList"
                    );
                    var addToListBtn = document.getElementById(
                        "addContributorsToList"
                    );
                    var contribChecked = 0;
                    for (let i = 0; i < checkboxes.length; i++) {
                        if (checkboxes[i].checked) {
                            contribChecked = 1;
                            break;
                        }
                    }

                    if (contribChecked === 0) {
                        createListBtn.removeAttribute("disabled");
                        createListBtn.classList.remove("disabled");
                        if (addToListBtn) {
                            addToListBtn.setAttribute("disabled", "disabled");
                            addToListBtn.classList.add("disabled");
                        }
                    } else {
                        createListBtn.setAttribute("disabled", "disabled");
                        createListBtn.classList.add("disabled");
                        if (addToListBtn) {
                            addToListBtn.removeAttribute("disabled");
                            addToListBtn.classList.remove("disabled");
                        }
                    }
                })
                .on("click", "#createNewList", function() {
                    var talentPoolAccess = document.getElementById(
                        "talentPoolAccess"
                    );
                    let html = `<div class="create-list-pane" style="width:650px;">
                                    <div style="display: flex; flex-direction: column;">
                                        <h1>${$translate.instant(
                                            "sw.contributorsearch.createlist"
                                        )}</h1>
                                        <h3>${$translate.instant(
                                            "sw.contributorsearch.requiredinfoadd"
                                        )}</h3>
                                    </div>
                                    <div class="create-list-wrapper" style="width:97%;">

                                        <form id="create-list-form" class="create-list-form">
                                        <div id="error-box-alreadyexists" className="hidden" style="background: #fee3e5;
                                        border: none;
                                        color: #BD1200;">
                                            <ul className="content">
                                                <li id="createErrorMsg"></li>
                                            </ul>
                                        </div>
                                        <label>${$translate.instant(
                                            "sw.label.title"
                                        )}&nbsp;<span class="required">*</span></label>
                                        <input type="text" name="listTitle" id="listTitle" style="font-size:16px; margin-bottom:10px;" />
                                        <span style="color: #f71515; font-size:14px; margin-bottom:5px; display:none;" id="validation-message-title">${$translate.instant(
                                            "sw.contributorsearch.titlemessage"
                                        )}</span>
                                        <label>${$translate.instant(
                                            "sw.label.description"
                                        )}&nbsp;<span class="required">*</span></label>
                                        <textarea name="listDescription" id="listDescription" style="resize:none; height:120px; font-size:16px; margin-bottom:10px;"></textarea>
                                        <span style="color: #f71515; font-size:14px; margin-bottom:5px; display:none;" id="validation-message-desc">${$translate.instant(
                                            "sw.contributorsearch.descriptionmessage"
                                        )}</span>
                                        <label>${$translate.instant(
                                            "sw.label.accesslevel"
                                        )}&nbsp;<span class="required">*</span></label>
                                        <select id="accessLevel" name="accessLevel>
                                        <option value="">${$translate.instant(
                                            "sw.label.accesslevelselect"
                                        )}</option>
                                            <option value="0">${$translate.instant(
                                                "angular.youTube.labelPrivate"
                                            )}</option>
                                            <option value="1">${$translate.instant(
                                                "nucleus.nav.enterprise"
                                            )}</option>
                                            ${talentPoolAccess.value ===
                                                "true" &&
                                                `<option value="2">${$translate.instant(
                                                    "angular.youTube.labelPublic"
                                                )}</option>`}
                                        </select>
                                        </form>
                                    </div>
                                    <div style="display:flex; justify-content:end; margin-top: 25px;">
                                        <button class="create-list-btns" style="margin-right:-18px !important;" id="createList"><span class="icon-plus" style="color: white;"></span>&nbsp;${$translate.instant(
                                            "sw.contributorsearch.createnewlist"
                                        )}</button>
                                    </div>
                                </div>`;
                    var opts = {
                        html: html
                    };
                    $.colorbox(opts);
                    $("#accessLevel").selectBoxIt();
                })
                .on("click", "#createList", function() {
                    let listTitle = document.getElementById("listTitle");
                    let listTitleMsg = document.getElementById(
                        "validation-message-title"
                    );
                    let listDescription = document.getElementById(
                        "listDescription"
                    );
                    let listDescMsg = document.getElementById(
                        "validation-message-desc"
                    );

                    let accessLevel = document.getElementById("accessLevel");

                    listTitleMsg.style.display = "none";
                    listDescMsg.style.display = "none";

                    if (
                        listTitle.value === "" ||
                        listTitle.value === null ||
                        listDescription.value === "" ||
                        listDescription.value === null
                    ) {
                        if (
                            listTitle.value === "" ||
                            listTitle.value === null
                        ) {
                            listTitle.style.border = "1px solid #f71515";
                            listTitle.style.marginBottom = "0";
                            listTitleMsg.style.display = "block";
                        } else {
                            listTitle.style.border = "1px solid #cfcfcf";
                            listTitle.style.marginBottom = "10px";
                            listTitleMsg.style.display = "none";
                        }
                        if (
                            listDescription.value === "" ||
                            listDescription.value === null
                        ) {
                            listDescription.style.border = "1px solid #f71515";
                            listDescMsg.style.display = "block";
                        } else {
                            listDescription.style.border = "1px solid #cfcfcf";
                            listDescMsg.style.display = "none";
                        }
                        return false;
                    } else {
                        listTitle.style.border = "1px solid #cfcfcf";
                        listTitle.style.marginBottom = "10px";
                        listDescription.style.border = "1px solid #cfcfcf";
                        listTitleMsg.style.display = "none";
                        listDescMsg.style.display = "none";

                        let contributorIDs = [];
                        let id = $("#contributorIdValue").text();
                        contributorIDs.push(parseInt(id));

                        //AJAX Call save contributor list api
                        //on success call add to contributor list api with response id
                        // on success uncheck all checked contributors

                        let data = JSON.stringify({
                            name: listTitle.value,
                            description: listDescription.value,
                            accessLevel: accessLevel.value
                        });

                        const programId = parseInt(
                            document
                                .getElementById("page-home")
                                .getAttribute("data-programid")
                        );
                        let url = skyword.getAPIURL(
                            "contributors/program/" + programId + "/create-list"
                        );
                        const token = skyword.getToken("access_token");
                        // config.headers.Authorization = `Bearer ${token}`;

                        jQuery.ajax({
                            cache: false,
                            type: "POST",
                            data: data,
                            headers: {
                                Accept: "application/json;",
                                "Content-Type": "application/json;"
                            },
                            url: url,
                            beforeSend: function(xhr) {
                                xhr.setRequestHeader(
                                    "Authorization",
                                    `Bearer ${token}`
                                );
                            },
                            success: function(data) {
                                if (data?.id !== undefined) {
                                    let addURL = skyword.getAPIURL(
                                        "contributors-account-list"
                                    );
                                    let addData = JSON.stringify({
                                        contributorListId: [data.id],
                                        accountId: contributorIDs
                                    });
                                    jQuery.ajax({
                                        cache: false,
                                        type: "POST",
                                        data: addData,
                                        headers: {
                                            Accept: "application/json;",
                                            "Content-Type": "application/json;"
                                        },
                                        url: addURL,
                                        beforeSend: function(xhr) {
                                            xhr.setRequestHeader(
                                                "Authorization",
                                                `Bearer ${token}`
                                            );
                                        },
                                        success: function(data) {
                                            document
                                                .getElementById(
                                                    "create-list-form"
                                                )
                                                .reset();
                                            let html = `<ul id="successMessageContent" class="content">
                                            <li>
                                                ${$translate.instant(
                                                    "sw.contributorsearch.createsuccessmessage"
                                                )}
                                                </li>
                                            </ul>`;
                                            $("#success-box").removeClass(
                                                "hidden"
                                            );
                                            $("#success-box").html(html);
                                            $("#error-box").addClass("hidden");
                                            $.colorbox.close();
                                        },
                                        error: function(
                                            jqXHR,
                                            status,
                                            errorThrown
                                        ) {
                                            $(
                                                "#error-box-alreadyexists"
                                            ).removeClass("hidden");
                                            let response = JSON.parse(
                                                jqXHR.responseText
                                            );
                                            $("#createErrorMsg").html(
                                                response.description
                                            );
                                        }
                                    });
                                }
                            },
                            error: function(jqXHR, status, errorThrown) {
                                console.log(errorThrown);
                                $("#error-box-alreadyexists").removeClass(
                                    "hidden"
                                );
                                let response = JSON.parse(jqXHR.responseText);
                                $("#createErrorMsg").html(response.description);
                            }
                        });
                    }
                })
                .on("click", "#addContributorsToList", function() {
                    let selectedLists = document.getElementsByClassName(
                        "contributorListCheckbox"
                    );
                    let contributorIDs = [],
                        listIDs = [];
                    let id = $("#contributorIdValue").text();
                    contributorIDs.push(parseInt(id));

                    for (let i = 0; i < selectedLists.length; i++) {
                        if (selectedLists[i].checked) {
                            listIDs.push(parseInt(selectedLists[i].value));
                        }
                    }

                    //AJAX Call save contributor list api
                    //on success call add to contributor list api with response id
                    // on success uncheck all checked contributors
                    const token = skyword.getToken("access_token");
                    let addURL = skyword.getAPIURL("contributors-account-list");
                    let addData = JSON.stringify({
                        contributorListId: listIDs,
                        accountId: contributorIDs
                    });
                    jQuery.ajax({
                        cache: false,
                        type: "POST",
                        data: addData,
                        headers: {
                            Accept: "application/json;",
                            "Content-Type": "application/json;"
                        },
                        url: addURL,
                        beforeSend: function(xhr) {
                            xhr.setRequestHeader(
                                "Authorization",
                                `Bearer ${token}`
                            );
                        },
                        success: function(data) {
                            // document.getElementById("create-list-form").reset();
                            let html = `<ul id="successMessageContent" class="content">
                                            <li>
                                                ${$translate.instant(
                                                    "sw.contributorsearch.updatesuccessmessage"
                                                )}
                                                </li>
                                            </ul>`;
                            $("#success-box").removeClass("hidden");
                            $("#success-box").html(html);
                            $("#error-box").addClass("hidden");

                            $.colorbox.close();
                        },
                        error: function(jqXHR, status, errorThrown) {
                            console.log(errorThrown);
                            $("#success-box").addClass("hidden");
                            let html = `<ul class="content">
                                ${$translate.instant(
                                    "sw.contributorsearch.updateerrormessage"
                                )}
                            </ul>`;
                            $("#error-box").removeClass("hidden");
                            $("#error-box").html(html);
                            $.colorbox.close();
                            throw Error(
                                "Fixture could not be loaded: " +
                                    addURL +
                                    " (status: " +
                                    status +
                                    ", message: " +
                                    errorThrown +
                                    ")"
                            );
                        }
                    });
                })
                .on("click", "#inviteContributor", function() {
                    let contributorId = $("#contributorIdValue").text();
                    let actionUrl = "inviteByEmail.action?contributorId=" + encodeURIComponent(contributorId);
                    window.location.href = actionUrl;
                });
        }
    ])
    .controller("vettingProfile", [
        "$scope",
        "account",
        "notes",
        "qualityRating",
        "contentSearch",
        function($scope, account, notes, qualityRating, contentSearch) {
            account.init();
            notes.init();
            qualityRating.init();
            contentSearch.init();
        }
    ])
    .controller("updateProgramAccountAjax", ["$scope", function($scope) {}])
    .controller("updateName", [
        "$scope",
        "account",
        "crop",
        "overlay",
        function($scope, account, crop, overlay) {
            account.init();
            crop.init();

            if (
                $(".profilePictureUpload .uploadControls li.imageCol img").attr(
                    "src"
                ) == "/styles/images/BlankProfile.png"
            ) {
                $(
                    ".profilePictureUpload .uploadControls .profilePictureRemoveBtn"
                ).hide();
            }

            $("html").on("click", "#removeProfilePictureBtn", function() {
                jQuery.colorbox.close();
                $(".profilePictureUpload .uploadControls li.imageCol img").attr(
                    "src",
                    "/styles/images/BlankProfile.png"
                );
                $(
                    ".profilePictureUpload .uploadControls li.controls .description"
                ).text("Image files up to 5 MB | ");
                $(
                    ".profilePictureUpload .uploadControls .profilePictureRemoveBtn"
                ).hide();
                return overlay.colorboxForm();
            });

            $("html").on("click", "#btn-crop-overlay", function() {
                $(
                    ".profilePictureUpload .uploadControls .profilePictureRemoveBtn"
                ).show();
            });
        }
    ])
    .controller("updateWriterBio", "redactor", [
        "$scope",
        "redactor",
        function($scope) {
            redactor.init();
        }
    ])
    .controller("backupCodes", ["$scope", function($scope) {}])
    .controller("updateTwoFactor", ["$scope", function($scope) {}])
    .controller("updateTwoFactorCancel", ["$scope", function($scope) {}])
    .controller("resetTrustedComputers", ["$scope", function($scope) {}])
    .controller("updateSocialProfile", [
        "$scope",
        "account",
        function($scope, account) {
            account.init();
        }
    ])
    .controller("updateEducation", [
        "$scope",
        "account",
        function($scope, account) {
            account.init();
        }
    ])
    .controller("removeEducationAjax", ["$scope", function($scope) {}])
    .controller("updateWriterExperience", [
        "$scope",
        "account",
        function($scope, account) {
            account.init();
        }
    ])
    .controller("removeExperienceAjax", ["$scope", function($scope) {}])
    .controller("updateWriterOrganization", [
        "$scope",
        "account",
        function($scope, account) {
            account.init();
        }
    ])
    .controller("removeOrganizationAjax", ["$scope", function($scope) {}])
    .controller("updateWriterCertification", [
        "$scope",
        "account",
        function($scope, account) {
            account.init();
        }
    ])
    .controller("removeCertificationAjax", ["$scope", function($scope) {}])
    .controller("updateRegionalExpertise", [
        "$scope",
        "$translate",
        "account",
        "$timeout",
        function($scope, $translate, account, $timeout) {
            account.init();
            $scope.loc = {};
            $scope.isInit = false;
            $scope.validLocation = false;
            $scope.locationBound = null;
            var sbmt = $("#formattedAddress")
                .closest("form")
                .find(":submit");
            $scope.$watch("loc", function(newValue, oldValue) {
                $scope.location = newValue;
                if ($scope.isInit) {
                    $scope.setLocation(newValue);
                    if (jQuery.isEmptyObject(newValue)) {
                        sbmt.attr("disabled", "disabled");
                    } else {
                        sbmt.removeAttr("disabled");
                    }
                }
                $scope.isInit = true;
            });
            $scope.initLocation = function(obj) {
                $scope.locationBound = obj.formattedAddress;
                $scope.setLocation(obj);
                if (
                    undefined !== obj.formattedAddress &&
                    obj.formattedAddress !== ""
                ) {
                    sbmt.removeAttr("disabled");
                } else {
                    sbmt.attr("disabled", "disabled");
                }
            };
            $scope.setLocation = function(obj) {
                $("#latitude").val(obj.latitude);
                $("#longitude").val(obj.longitude);
                $("#formattedAddress").val(obj.formattedAddress);
            };

            $scope.checkLocation = function() {
                $scope.locationError = "";
                $timeout(function() {
                    if (!$scope.validLocation) {
                        $scope.locationError = $scope.errorMessages.location;
                        $scope.locationBound = "";
                    }
                }, 200);
            };

            $scope.locationError = "";
            $scope.errorMessages = {
                location: ""
            };

            $translate("angular.contributorjoin.error.location").then(function(
                txt
            ) {
                $scope.errorMessages.location = txt;
            });
        }
    ])
    .controller("removeRegionalExpertiseAjax", ["$scope", function($scope) {}])
    .controller("enterTaxInfo", [
        "$scope",
        "account",
        function($scope, account) {
            account.init();
        }
    ])
    .controller("updateTaxInfo", ["$scope", function($scope) {}])
    .controller("updateEmail", ["$scope", function($scope) {}])
    .controller("updateEquipment", [
        "$scope",
        function($scope) {
            function removeOptionsButDefault(selectObj) {
                selectObj.each(function() {
                    if ($(this).val() != 0) {
                        $(this).remove();
                    }
                });
            }

            $("#categorySelect").change(function() {
                var selectedValue = this.value,
                    brandSelect = $("#brandSelect");
                //disable brandSelect if default option for categorySelect is selected
                if (selectedValue == 0) {
                    brandSelect.attr("disabled", "disabled");
                    brandSelect.val(0);
                } else {
                    //todo Standardize the output on this
                    $.ajax({
                        type: "GET",
                        url: "retrieveBrandAjax?categoryId=" + this.value,
                        dataType: "json",
                        success: function(data) {
                            var brands = data.brands,
                                brandSelect = $("#brandSelect");
                            //reset existing brandSelect and build brand options if there is at least one brand
                            if (brands.length >= 1) {
                                brandSelect.removeAttr("disabled");
                                removeOptionsButDefault(
                                    $("#brandSelect option")
                                );
                                $.each(brands, function(index, obj) {
                                    $("#brandSelect").append(
                                        $("<option>", {
                                            value: obj.id,
                                            text: obj.name
                                        })
                                    );
                                });
                                brandSelect
                                    .data("selectBox-selectBoxIt")
                                    .refresh();
                            }
                        }
                    });
                }
            });
        }
    ])
    .controller("updatePreferences", ["$scope", function($scope) {}])
    .controller("updateTimezone", ["$scope", function($scope) {}])
    .controller("updateNewsAndEventsPrefs", ["$scope", function($scope) {}])
    .controller("updateProfileVisibility", ["$scope", function($scope) {}])
    .controller("updatePassword", [
        "$scope",
        "account",
        function($scope, account) {
            account.init();
        }
    ])
    .controller("updatePaypalEmail", ["$scope", function($scope) {}])
    .controller("uploadResumeAjax", ["$scope", function($scope) {}])
    .controller("removeResumeAjax", ["$scope", function($scope) {}])
    /*
    .controller('updateInformation', ['$scope', 'account', 'redactor', function($scope, account, redactor) {
        account.init();
        redactor.init();
    }])
*/
    .controller("uploadProfilePictureAjax", ["$scope", function($scope) {}])
    .controller("cropProfilePictureAjax", ["$scope", function($scope) {}])
    .controller("removeProfilePictureAjax", ["$scope", function($scope) {}])
    .controller("updateEmailPreferencesAjax", ["$scope", function($scope) {}])
    .controller("managerWriterProfile", ["$scope", function($scope) {}])
    .controller("updateInformation", [
        "$scope",
        "account",
        "crop",
        "redactor",
        "author",
        "$timeout",
        "$translate",
        function(
            $scope,
            account,
            crop,
            redactor,
            author,
            $timeout,
            $translate
        ) {
            account.init();
            crop.init();
            redactor.init();

            $scope.loc = {};
            $scope.isInit = false;
            $scope.validLocation = false;
            $scope.locationBound = null;
            var sbmt = $("#formattedAddress")
                .closest("form")
                .find(":submit");
            $scope.$watch("loc", function(newValue, oldValue) {
                $scope.location = newValue;
                if ($scope.isInit) {
                    $scope.setLocation(newValue);
                    if (jQuery.isEmptyObject(newValue)) {
                        sbmt.attr("disabled", "disabled");
                    } else {
                        sbmt.removeAttr("disabled");
                    }
                }
                $scope.isInit = true;
            });
            $scope.initLocation = function(obj) {
                $scope.locationBound = obj.formattedAddress;
                $scope.setLocation(obj);
                if (
                    undefined !== obj.formattedAddress &&
                    obj.formattedAddress !== ""
                ) {
                    sbmt.removeAttr("disabled");
                } else {
                    sbmt.attr("disabled", "disabled");
                }
            };
            $scope.setLocation = function(obj) {
                $("#latitude").val(obj.latitude);
                $("#longitude").val(obj.longitude);
                $("#formattedAddress").val(obj.formattedAddress);
            };

            $scope.checkLocation = function() {
                $scope.locationError = "";
                $timeout(function() {
                    if (!$scope.validLocation) {
                        $scope.locationError = $scope.errorMessages.location;
                        $scope.locationBound = "";
                    }
                }, 600);
            };

            $scope.locationError = "";
            $scope.errorMessages = {
                location: ""
            };

            $translate("angular.contributorjoin.error.location").then(function(
                txt
            ) {
                $scope.errorMessages.location = txt;
            });

            $("body").on(
                "click",
                "#btn-disable-contributor-type",
                null,
                function() {
                    //console.log('disable contributor type fired...');
                    jQuery.colorbox.close();
                }
            );
            $("body").on("click", "#cancel-disable-writer", null, function() {
                $("#contributorTypeWriter").attr("checked", true);
                jQuery.colorbox.close();
            });
            $("body").on(
                "click",
                "#cancel-disable-videographer",
                null,
                function() {
                    $("#contributorTypeVideographer").attr("checked", true);
                    jQuery.colorbox.close();
                }
            );
            $("body").on(
                "click",
                "#cancel-disable-photographer",
                null,
                function() {
                    $("#contributorTypePhotographer").attr("checked", true);
                    jQuery.colorbox.close();
                }
            );
            $("body").on("click", "#cancel-disable-designer", null, function() {
                $("#contributorTypeDesigner").attr("checked", true);
                jQuery.colorbox.close();
            });

            $("#contributorTypeWriter").click(function(e) {
                if (!$("#contributorTypeWriter:checked").val()) {
                    var result = author.disableContributorType("writer");
                }
            });
            $("#contributorTypeVideographer").click(function(e) {
                if (!$("#contributorTypeVideographer:checked").val()) {
                    var result = author.disableContributorType("videographer");
                }
            });
            $("#contributorTypePhotographer").click(function(e) {
                if (!$("#contributorTypePhotographer:checked").val()) {
                    var result = author.disableContributorType("photographer");
                }
            });
            $("#contributorTypeDesigner").click(function(e) {
                if (!$("#contributorTypeDesigner:checked").val()) {
                    var result = author.disableContributorType("designer");
                }
            });
        }
    ]);

(function() {
    app.service("skyword", [
        "ajax",
        "requestActive",
        "SpellCheckFactory",
        function(ajax, requestActive, SpellCheckFactory) {
            return {
                htmlEscape: function(str) {
                    return String(str)
                        .replace(/&/g, "&amp;")
                        .replace(/"/g, "&quot;")
                        .replace(/'/g, "&#39;")
                        .replace(/</g, "&lt;")
                        .replace(/>/g, "&gt;");
                },
                htmlUnescape: function(value) {
                    return String(value)
                        .replace(/&quot;/g, '"')
                        .replace(/&#39;/g, "'")
                        .replace(/&lt;/g, "<")
                        .replace(/&gt;/g, ">")
                        .replace(/&amp;/g, "&");
                },
                getQstringParamByName: function(name, url) {
                    if (!url) url = window.location.href;
                    name = name.replace(/[\[\]]/g, "\\$&");
                    var regex = new RegExp(
                            "[?&]" + name + "(=([^&]*)|&|$)",
                            "i"
                        ),
                        results = regex.exec(url);
                    if (!results) return null;
                    if (!results[2]) return "";
                    return decodeURIComponent(results[2].replace(/\+/g, " "));
                },
                setContentTypeId: function(id) {
                    this.contentTypeId = id;
                },
                getContentTypeId: function() {
                    return this.contentTypeId;
                },
                setPaypalRequire: function(paypalRequire) {
                    this.paypalRequire = paypalRequire;
                },
                getPaypalRequire: function() {
                    return this.paypalRequire;
                },
                setProfileCompletionRequire: function(
                    profileCompletionRequire
                ) {
                    this.profileCompletionRequire = profileCompletionRequire;
                },
                getProfileCompletionRequire: function() {
                    return this.profileCompletionRequire;
                },
                setLastAjaxCall: function(ajaxCall) {
                    this.lastAjaxCall = ajaxCall;
                },
                getLastAjaxCall: function() {
                    return this.lastAjaxCall;
                },
                setAjaxContainer: function(ajaxContainer) {
                    this.ajaxContainer = ajaxContainer;
                },
                getAjaxContainer: function() {
                    return this.ajaxContainer;
                },
                getFocusAtt: function() {
                    return this.focusAtt;
                },
                setFocusAtt: function(focusAtt) {
                    this.focusAtt = focusAtt;
                },
                getLanguage: function() {
                    var returnLang = "",
                        overrideLang = $("#overrideLanguage").val(),
                        userLang = $("#userLanguage").val();
                    if (overrideLang != null) {
                        returnLang = overrideLang;
                    } else if (userLang != null) {
                        returnLang = userLang;
                    } else {
                        returnLang = "en";
                    }
                    //most js requires the language just be es
                    if (returnLang === "es_US") {
                        returnLang = "es";
                    }
                    //also strip dialect from Portuguese
                    if (returnLang === "pt_BR") {
                        returnLang = "pt";
                    }
                    //if language not recognized, default to english
                    if (
                        returnLang !== "es" &&
                        returnLang !== "en" &&
                        returnLang !== "pt"
                    ) {
                        returnLang = "en";
                    }
                    return returnLang;
                },
                getProgramLanguage: function() {
                    var returnLang = $("#program-language").val();
                    return returnLang;
                },
                /**
                 * safer to use when logging to the console
                 */
                log: function() {
                    //return window.console && console.log && Function.apply.call(console.log, console, arguments);
                    if (window.console) {
                        console.log(Array.prototype.slice.call(arguments));
                    }
                },
                error: function(msg) {
                    var opts = {
                        html:
                            '<div class="sw-modal"><h1>Error</h1>' +
                            msg +
                            '<br/><br/><a href="#" onclick="$.colorbox.close()">cancel</a><br/></div>'
                    };
                    $.colorbox(opts);
                },
                timezoneDropdown: function(groupId, id, external) {
                    var tz = $("#timezone-final");

                    var url = "timezonePickerAjax.action";
                    var guid = $("#calendar-guid").val();
                    var timezone = jstz.determine().name();
                    // also used for external calendar - user will not be logged in

                    if (true === external) {
                        url = "extLoadTimezonesByGroupIdAjax.action";
                    }

                    $("#timezone-finalSelectBoxItContainer").remove();
                    tz.html("");

                    ajax(url, {
                        method: "POST",
                        data: {
                            timezoneGroupId: groupId,
                            guid: $("#calendar-guid").val(),
                            detectedTimezone: timezone
                        },
                        success: function(data, textStatus, jqXHR) {
                            data = $.parseJSON(data);
                            var output =
                                "<select id='timezone-final' name='timezone'>";
                            for (var i = 0, l = data.length; i < l; i++) {
                                var selected = "";
                                if (data[i].id == id) {
                                    selected = ' selected="selected" ';
                                }
                                if (data[i].selected !== undefined) {
                                    selected = ' selected="selected" ';
                                    $("#timezoneGroup").val(
                                        data[i].timezoneGroupId
                                    );
                                    $("#timezoneGroup")
                                        .data("selectBox-selectBoxIt")
                                        .refresh();
                                }
                                tz.append(
                                    '<option value="' +
                                        data[i].id +
                                        '"' +
                                        selected +
                                        ">" +
                                        data[i].displayName +
                                        "</option>"
                                );
                            }
                            if (
                                tz.data("selectBox-selectBoxIt") === undefined
                            ) {
                                tz.selectBoxIt();
                            } else {
                                tz.data("selectBox-selectBoxIt").refresh();
                            }
                        }
                    });
                },
                pingRequest: function(url, opts) {
                    var options = {
                        data:
                            opts && opts.data
                                ? opts.data
                                : { param: new Date().getTime() },
                        dataType: opts && opts.data ? opts.dataType : "html",
                        beforeSend:
                            opts && opts.beforeSend
                                ? opts.beforeSend
                                : function(jqXHR, settings) {},
                        complete:
                            opts && opts.complete
                                ? opts.complete
                                : function(jqXHR, textStatus) {},
                        success:
                            opts && opts.success
                                ? opts.success
                                : function(data, textStatus, jqXHR) {},
                        error:
                            opts && opts.error
                                ? opts.error
                                : function(jqXHR, textStatus, errorThrown) {}
                    };
                    ajax(url, options);
                },
                loginPing: function(requestInterval, neverRedirect) {
                    crId = $("#contentRequestId").val();

                    var opts = {
                        data: {
                            crId: crId,
                            neverRedirect: neverRedirect
                        },
                        dataType: "json",
                        success: function(json, textStatus) {
                            var respText = json.pingerResponse;
                            switch (respText) {
                                case "invalid":
                                    window.location = json.targetUrl;
                                    break;
                                case "deleted":
                                    window.location = json.targetUrl;
                                    break;
                                case "expired":
                                    window.location = json.targetUrl;
                                    break;
                                case "wrongperson":
                                    window.location = json.targetUrl;
                                    break;
                                case "success":
                                    break;
                                default:
                                    break;
                            }
                        }
                    };

                    var $this = this;
                    return window.setInterval(function() {
                        $this.pingRequest("loginPing.action", opts);
                    }, requestInterval);
                },
                externalPing: function(opts, requestInterval) {
                    var $this = this;
                    return window.setInterval(function() {
                        $this.pingRequest("externalPing.action", opts);
                    }, requestInterval);
                },

                contentLock: function(requestInterval) {
                    if (
                        window.location.href.includes("publish.action") ||
                        window.location.href.includes("managerPublish.action")
                    ) {
                        // Per Nate, exclude these URLs from locking activity - use this mechanism because the call to loginPing can appear in some cases where the URL varies
                        return;
                    }
                    crId = $("#contentRequestId").val();
                    console.log("Content Lock ping tried with " + crId);
                    console.log(
                        "Lock flag shows " +
                            ($("#commentLocked").val() || false)
                    );

                    if ($("#commentLocked").val() || false) {
                        // If somebody else has the content locked, just abandon
                        return;
                    }
                    console.log("Ping functionality will continue");
                    var inactiveCycles = 0;

                    var opts = {
                        data: {
                            crId: crId
                        },
                        dataType: "json",
                        success: function(json, textStatus) {
                            var respText = json.pingerResponse;
                            switch (respText) {
                                case "success":
                                    break;
                                default:
                                    break;
                            }
                        }
                    };
                    // Zero the idle timer on mouse movement.
                    window.addEventListener("mousemove", (e) => {
                        inactiveCycles = 0;
                    });
                    window.addEventListener("keypress", (e) => {
                        inactiveCycles = 0;
                    });

                    var $this = this;
                    return window.setInterval(function() {
                        inactiveCycles++;
                        if (inactiveCycles > 15) {
                            alert(
                                "Due to inactivity, the page must be refreshed."
                            );
                            window.location.reload();
                            inactiveCycles = 0;
                        }
                        $this.pingRequest("contentLock.action", opts);
                    }, requestInterval);
                },

                /*
             * Impose a maximum character count on a textarea
             */
                imposeMaxLength: function(id, MaxLen) {
                    var obj = id instanceof jQuery ? id : $("#" + id);
                    if (obj.val().length > MaxLen) {
                        obj.val(obj.val().substring(0, MaxLen));
                    }
                },
                /*
             * Impose a maximum character count on a textarea
             */
                imposeMaximumLength: function(that) {
                    var obj = that instanceof jQuery ? that : $(this),
                        maxLengthObj = obj.siblings(".maxLength"),
                        MaxLen = maxLengthObj.val();
                    if (obj.val().length > MaxLen) {
                        obj.val(obj.val().substring(0, MaxLen));
                    }
                    return obj.val();
                },
                generateSanitizedStringEllipsis: function(
                    inputString,
                    maxLength
                ) {
                    var input = $.trim(
                            $("<div>" + inputString + "</div>").text()
                        ),
                        trimmed,
                        finalString,
                        endIndex,
                        pat;

                    try {
                        pat = /(\r\n|\n|\r)/gm;
                        trimmed = "";
                        input = input.replace(pat, " ");
                        if (input.length > maxLength) {
                            trimmed = input.substring(0, maxLength - 3);
                            if (
                                input.charAt(maxLength - 4) === " " ||
                                input.charAt(maxLength - 3) === " "
                            ) {
                                trimmed = $.trim(trimmed);
                                finalString = $.trim(trimmed + "...");
                            } else {
                                endIndex = trimmed.lastIndexOf(" ");
                                trimmed = $.trim(
                                    trimmed.substring(0, endIndex)
                                );
                                finalString = trimmed + "...";
                            }
                        } else {
                            finalString = input;
                        }
                    } catch (e) {
                        finalString = input;
                    }

                    return finalString;
                },
                generateSanitizedString: function(
                    inputString,
                    suffixString,
                    maxLength
                ) {
                    var input = $.trim(
                            $("<div>" + inputString + "</div>").text()
                        ),
                        trimmed,
                        finalString,
                        suffixLen = 0,
                        endIndex;

                    input = input.replace(
                        /<script\b[^>]*>([\s\S]*?)<\/script>/gm,
                        ""
                    );
                    try {
                        trimmed = "";
                        if (
                            undefined !== suffixString &&
                            null !== suffixString &&
                            "" !== suffixString
                        ) {
                            suffixString = $.trim(suffixString);
                            suffixLen = suffixString.length + 1;
                        }
                        if (input.length > maxLength - suffixLen) {
                            trimmed = $.trim(
                                input.substring(0, maxLength - suffixLen)
                            );
                            endIndex = trimmed.lastIndexOf(" ");

                            trimmed = $.trim(trimmed.substring(0, endIndex));
                            finalString = $.trim(trimmed + " " + suffixString);
                        } else {
                            finalString = input;
                        }
                    } catch (e) {
                        finalString = input;
                    }

                    return finalString;
                },
                toBool: function(string) {
                    var retVal = false;
                    if (
                        undefined === string ||
                        null === string ||
                        "" === string
                    ) {
                        retVal = false;
                    } else {
                        switch (string.toLowerCase()) {
                            case "false":
                            case "no":
                            case "0":
                                retVal = false;
                                break;
                            case "true":
                            case "yes":
                            case "1":
                                retVal = true;
                                break;
                            default:
                                retVal = Boolean(string);
                        }
                    }
                    return retVal;
                },
                disableClaim: function() {
                    var obj = $("#summary"),
                        createButton = $("#create-button");
                    var s = obj.val().replace(/\n/g, "\r\n");
                    if (s.length >= 255) {
                        s = s.substring(0, 253);
                        s = s.replace(/\r\n/g, "\n");
                        obj.val(s);
                    }
                    if (obj.val().length > 48) {
                        createButton.attr("disabled", false);
                        createButton.removeClass("disabled");
                    } else {
                        createButton.attr("disabled", "disabled");
                        createButton.addClass("disabled");
                    }
                },
                disableEnterSubmit: function() {
                    $(window).keydown(function(event) {
                        /*  Prevent return key from submitting form  */
                        if (event.keyCode === 13) {
                            event.preventDefault();
                            return false;
                        }
                    });
                },
                clearTextfield: function(id) {
                    var el = $("#" + id);
                    if (el.attr("clear") === null) {
                        el.attr("clear", false);
                        el.val("");
                    }
                },
                passwordStrength: function() {
                    try {
                        $(".password_check").passStrength({
                            userid: "#email"
                        });
                    } catch (e) {}
                },
                validateContinue: function() {
                    var attachmentId = parseInt(
                            $("#cropAttachmentId").val(),
                            10
                        ),
                        title = $("#imgTitle").val(),
                        altTitle = $("#altTitle").val(),
                        imageLoaded = $("#image-loaded").val();
                    if (
                        !isNaN(attachmentId) &&
                        "" !== title &&
                        "" !== altTitle &&
                        imageLoaded
                    ) {
                        $("#btn-upload-continue")
                            .removeAttr("disabled")
                            .removeClass("disabled");
                        $("#btn-insert-image")
                            .removeAttr("disabled")
                            .removeClass("disabled");
                    } else {
                        $("#btn-upload-continue")
                            .attr("disabled", "disabled")
                            .addClass("disabled");
                        $("#btn-insert-image")
                            .attr("disabled", "disabled")
                            .addClass("disabled");
                    }
                },
                createReferralPie: function() {
                    var r = { total: [] },
                        parameters = {
                            location: "referrers-pie-graph",
                            type: "pie",
                            enableTooltip: false,
                            popupFormat:
                                '<span class="value">{y}</span><span> Views </span>',
                            graphType: "pie"
                        };
                    $("#referrers-percent-table tbody tr").each(function() {
                        var title = $(this).find(".title"),
                            value = title.next().next();
                        value = parseInt(value.text().replace(",", ""), 10);
                        r.total.push({
                            label: title.text(),
                            color: title
                                .find("span.key")
                                .css("background-color"),
                            y: value
                        });
                    });
                    parameters.graphData = r.total;
                    graph.createPieChart(parameters);
                },
                closeTinyMce: function(id) {
                    if (!id) {
                        return;
                    }
                    tinymce.get(id).remove();
                },
                colorboxCloseMethod: function() {},
                /*
             * styles need to be applied to the popup.
             */
                getCookie: function(c_name) {
                    var c_value = document.cookie;
                    var c_start = c_value.indexOf(" " + c_name + "=");
                    if (c_start == -1) {
                        c_start = c_value.indexOf(c_name + "=");
                    }
                    if (c_start == -1) {
                        c_value = null;
                    } else {
                        c_start = c_value.indexOf("=", c_start) + 1;
                        var c_end = c_value.indexOf(";", c_start);
                        if (c_end == -1) {
                            c_end = c_value.length;
                        }
                        c_value = unescape(c_value.substring(c_start, c_end));
                    }
                    return c_value;
                },
                setCookie: function(c_name, value, exdays, isDate) {
                    var exdate = new Date();
                    var c_value = null;
                    // We're passing a date
                    if (isDate) {
                        exdate = exdays;
                        c_value =
                            escape(value) +
                            (exdays == null
                                ? ""
                                : "; expires=" + exdate.toUTCString());
                    }
                    // We're passing a number of days
                    else {
                        exdate.setDate(exdate.getDate() + exdays);
                        c_value =
                            escape(value) +
                            (exdays == null
                                ? ""
                                : "; expires=" + exdate.toUTCString());
                    }
                    document.cookie =
                        c_name + "=" + c_value + "; " + "Path=/; ";
                },
                deleteCookie: function(c_name) {
                    document.cookie =
                        c_name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                },
                applySpellcheckMarks: function(textArea) {
                    // $('body').append('<div class="disable-screen"></div>');
                    var $this = this,
                        displaySuggestions;
                    try {
                        if ($(".nonEditable").length > 0) {
                            displaySuggestions = false;
                        } else {
                            displaySuggestions = true;
                        }
                        var div = $("#" + textArea);
                        // console.log(textArea);

                        var html = div
                            .html()
                            .replace(/<span>([\w\W]*?)<\/span>/gi, "$1");

                        // remove zero width-space
                        html = html.replace(/[\u200B-\u200D\uFEFF]/g, "");

                        var etagsInline = [
                            "<b>\\s*</b>",
                            "<b>&nbsp;</b>",
                            "<em>\\s*</em>"
                        ];
                        var etags = [
                            "<pre></pre>",
                            "<blockquote>\\s*</blockquote>",
                            "<dd></dd>",
                            "<dt></dt>",
                            "<ul></ul>",
                            "<ol></ol>",
                            "<li></li>",
                            "<table></table>",
                            "<tr></tr>",
                            "<span>\\s*<span>",
                            "<span>&nbsp;<span>",
                            "<p>\\s*</p>",
                            "<p></p>",
                            "<p>&nbsp;</p>",
                            "<p>\\s*<br>\\s*</p>",
                            "<div>\\s*</div>",
                            "<div>\\s*<br>\\s*</div>"
                        ];
                        etags = etags.concat(etagsInline);
                        var len = etags.length;
                        for (var i = 0; i < len; ++i) {
                            html = html.replace(new RegExp(etags[i], "gi"), "");
                        }

                        div.html(html.replace(/\u00a0]/g, ""));

                        AtD.rpc =
                            "atdProxy.xml?lang=" +
                            $this.getProgramLanguage() +
                            "&url=";
                        AtD.rpc_key = "skyword-one";
                        AtD.displaySuggestions = displaySuggestions;
                        AtD.check(textArea, {
                            success: function(errorCount) {
                                // $timeout(function() { $('.disable-screen').remove(); }, 250);
                            },
                            error: function(reason) {
                                // alert(reason);
                                alert(
                                    "Spell check is not available at this time. Please try again later."
                                );
                            }
                        });
                    } catch (e) {
                        console.log("error " + e);
                    }
                },
                reloadLastAjaxCall: function() {
                    $this = this;
                    var urlAction = $this.lastAjaxCall,
                        options = {
                            cache: false,
                            type: "GET",
                            url: urlAction,
                            success: function(data, textStatus, jqXHR) {
                                $($this.getAjaxContainer()).html(data);
                                $($this.getAjaxContainer()).removeClass(
                                    "disabled"
                                );
                                $this.setupDisplaytagAjax();
                            }
                        };
                    $($this.getAjaxContainer()).addClass("disabled");
                    $.colorbox.close();
                    ajax(urlAction, options);
                },
                scrollTo: function(object, additionalOffset) {
                    $("html, body").animate(
                        {
                            scrollTop: $(object).offset().top - additionalOffset
                        },
                        1000
                    );
                },
                matchColumns: function() {
                    $(".equalColumns").each(function() {
                        var height = 0;
                        $(this)
                            .find(".column")
                            .each(function() {
                                if ($(this).outerHeight() > height) {
                                    height = $(this).outerHeight();
                                }
                            });
                        $(this)
                            .find(".column")
                            .each(function() {
                                if ($(this).outerHeight() < height) {
                                    var newHeight =
                                        height -
                                        parseInt(
                                            $(this).css("borderTopWidth"),
                                            10
                                        ) -
                                        parseInt(
                                            $(this).css("borderBottomWidth"),
                                            10
                                        );
                                    if ($(this).is("table")) {
                                        $(this).attr(
                                            "style",
                                            "height: " +
                                                newHeight +
                                                "px!important"
                                        );
                                    } else {
                                        $(this).css(
                                            "min-height",
                                            height -
                                                parseInt(
                                                    $(this).css(
                                                        "borderTopWidth"
                                                    ),
                                                    10
                                                ) -
                                                parseInt(
                                                    $(this).css(
                                                        "borderBottomWidth"
                                                    ),
                                                    10
                                                )
                                        );
                                    }
                                }
                            });
                    });
                },
                toggleSuggestions: function() {
                    SpellCheckFactory.spellcheck();
                },
                /**
                 * Automatically called in $(document).ready below to search for inputs with class name "date-picker" to add Calendar
                 */
                displayWriterCompensationData: function() {
                    $(".paymentRate").each(function() {
                        var crId = $(this)
                                .find("#crId")
                                .val(),
                            ctId = $(this)
                                .find("#ctId")
                                .val();
                        $(this).qtip({
                            content: {
                                text: "&nbsp;",
                                ajax: {
                                    url:
                                        "writerCompensationOverlayAjax.action?contentRequestId=" +
                                        crId +
                                        "&contentTypeId=" +
                                        ctId
                                }
                            },
                            position: {
                                at: "top left",
                                my: "bottom left"
                            },
                            show: {
                                event: "click"
                            },
                            hide: "unfocus",
                            style: {
                                width: 480,
                                height: 300,
                                classes:
                                    "qtip-wiki qtip-light qtip-shadow qtip-nolist",
                                tip: {
                                    width: 30,
                                    border: 2,
                                    height: 15
                                }
                            }
                        });
                    });
                },

                removeWhitespaceAdditionalPayments: function() {
                    $("#writerComments, #formSubmit_financeComments").change(
                        function() {
                            $(this).val(
                                $(this)
                                    .val()
                                    .replace(/(<([^>]+)>)/gi, "")
                            );
                            $(this).val(
                                $(this)
                                    .val()
                                    .replace(/\s+/g, " ")
                            );
                        }
                    );
                },

                /**
                 * Show/hide drawer in displaytag
                 */
                addTooltips: function() {
                    try {
                        $(".similarDuplicateTooltip").bt({
                            padding: 8,
                            width: 400,
                            shrinkToFit: true,
                            spikeLength: 10,
                            spikeGirth: 10,
                            cornerRadius: 5,
                            overlap: -1,
                            centerPointY: 1,
                            fill: "rgba(255, 255, 255, 1.0)",
                            strokeWidth: 1,
                            strokeStyle: "#dfdfdf",
                            cssStyles: {
                                color: "#333333",
                                fontWeight: "normal"
                            },
                            shadow: true,
                            shadowOffsetX: 2,
                            shadowOffsetY: 2,
                            shadowBlur: 6,
                            shadowColor: "rgba(0,0,0,.6)",
                            shadowOverlap: false,
                            noShadowOpts: {
                                strokeStyle: "#999",
                                strokeWidth: 1
                            },
                            positions: ["right"]
                        });
                        $(".tooltip").bt({
                            padding: 10,
                            width: 200,
                            shrinkToFit: true,
                            spikeLength: 10,
                            spikeGirth: 10,
                            cornerRadius: 5,
                            fill: "rgba(255, 255, 255, 1.0)",
                            strokeWidth: 1,
                            strokeStyle: "#dfdfdf",
                            offsetParent: "body",
                            cssStyles: {
                                color: "#333333",
                                fontWeight: "normal"
                            },
                            shadow: true,
                            shadowOffsetX: 2,
                            shadowOffsetY: 2,
                            shadowBlur: 6,
                            shadowColor: "rgba(0,0,0,.6)",
                            shadowOverlap: false,
                            noShadowOpts: {
                                strokeStyle: "#999",
                                strokeWidth: 1
                            },
                            positions: ["top", "right"]
                        });

                        $(".contentPackageStatusTooltip").bt({
                            padding: 10,
                            width: 650,
                            shrinkToFit: true,
                            spikeLength: 10,
                            spikeGirth: 10,
                            cornerRadius: 5,
                            fill: "rgba(255, 255, 255, 1.0)",
                            strokeWidth: 1,
                            strokeStyle: "#dfdfdf",
                            offsetParent: "body",
                            cssStyles: {
                                color: "#333333",
                                fontWeight: "normal"
                            },
                            shadow: true,
                            shadowOffsetX: 2,
                            shadowOffsetY: 2,
                            shadowBlur: 6,
                            shadowColor: "rgba(0,0,0,.6)",
                            shadowOverlap: false,
                            noShadowOpts: {
                                strokeStyle: "#999",
                                strokeWidth: 1
                            },
                            positions: ["top", "left"]
                        });

                        $(".clickTooltip").bt({
                            padding: 10,
                            width: 200,
                            trigger: "click",
                            shrinkToFit: true,
                            spikeLength: 10,
                            spikeGirth: 10,
                            cornerRadius: 5,
                            fill: "rgba(255, 255, 255, 1.0)",
                            closeWhenOthersOpen: false,
                            strokeWidth: 1,
                            strokeStyle: "#dfdfdf",
                            cssStyles: { color: "#333", fontWeight: "normal" },
                            shadow: true,
                            shadowOffsetX: 2,
                            shadowOffsetY: 2,
                            shadowBlur: 6,
                            shadowColor: "rgba(0,0,0,.6)",
                            shadowOverlap: false,
                            noShadowOpts: {
                                strokeStyle: "#999",
                                strokeWidth: 1
                            }
                        });

                        $(".ratingTooltip").bt({
                            padding: 10,
                            width: 250,
                            shrinkToFit: true,
                            spikeLength: 10,
                            spikeGirth: 10,
                            cornerRadius: 5,
                            fill: "rgba(255, 255, 255, 1.0)",
                            strokeWidth: 1,
                            strokeStyle: "#dfdfdf",
                            offsetParent: "body",
                            cssStyles: {
                                color: "#333333",
                                fontWeight: "normal"
                            },
                            shadow: true,
                            shadowOffsetX: 2,
                            shadowOffsetY: 2,
                            shadowBlur: 6,
                            shadowColor: "rgba(0,0,0,.6)",
                            shadowOverlap: false,
                            noShadowOpts: {
                                strokeStyle: "#999",
                                strokeWidth: 1
                            },
                            positions: ["top", "left"]
                        });

                        $(".infoTooltip").bt({
                            padding: 10,
                            width: 250,
                            shrinkToFit: true,
                            spikeLength: 10,
                            spikeGirth: 10,
                            cornerRadius: 5,
                            fill: "rgba(255, 255, 255, 1.0)",
                            strokeWidth: 1,
                            strokeStyle: "#dfdfdf",
                            offsetParent: "body",
                            cssStyles: {
                                color: "#333333",
                                fontWeight: "normal"
                            },
                            shadow: true,
                            shadowOffsetX: 2,
                            shadowOffsetY: 2,
                            shadowBlur: 6,
                            shadowColor: "rgba(0,0,0,.6)",
                            shadowOverlap: false,
                            noShadowOpts: {
                                strokeStyle: "#999",
                                strokeWidth: 1
                            },
                            positions: ["top", "left"]
                        });
                        $(".rankgraphoverlay").on("click", function() {
                            var data = $(this).attr("data-value");
                            $.colorbox({ html: data });
                        });
                        $(".divTooltip").bt({
                            padding: 10,
                            width: 500,
                            shrinkToFit: true,
                            contentSelector:
                                "$(this).next('.divTooltip.content')",
                            spikeLength: 10,
                            spikeGirth: 10,
                            cornerRadius: 5,
                            fill: "rgba(255, 255, 255, 1.0)",
                            strokeWidth: 1,
                            strokeStyle: "#dfdfdf",
                            offsetParent: "body",
                            cssStyles: {
                                color: "#333333",
                                fontWeight: "normal"
                            },
                            shadow: true,
                            shadowOffsetX: 2,
                            shadowOffsetY: 2,
                            shadowBlur: 6,
                            shadowColor: "rgba(0,0,0,.6)",
                            shadowOverlap: false,
                            noShadowOpts: {
                                strokeStyle: "#999",
                                strokeWidth: 1
                            },
                            positions: ["right", "middle"]
                        });

                        /* View Results Tooltip - managerReportProgramSummary.jsp */
                        $(".viewResultsContent").hide();
                        $(window).resize(function() {
                            $(".viewResults-ToolTip").hide();
                        });
                        $(".viewResultsTooltip").bt({
                            trigger: "click",
                            padding: 10,
                            width: 500,
                            shrinkToFit: true,
                            contentSelector:
                                "$(this).next(function(){$(this).html();})",
                            spikeLength: 10,
                            spikeGirth: 10,
                            cornerRadius: 5,
                            fill: "rgba(255, 255, 255, 1.0)",
                            strokeWidth: 1,
                            strokeStyle: "#dfdfdf",
                            cssStyles: {
                                color: "#333333",
                                fontWeight: "normal"
                            },
                            cssClass: "viewResults-ToolTip",
                            closeWhenOthersOpen: true
                        });
                    } catch (e) {}
                },
                addCardTooltips: function() {
                    try {
                        $(".goalsTooltip").bt({
                            padding: 10,
                            width: 225,
                            shrinkToFit: true,
                            spikeLength: 10,
                            spikeGirth: 10,
                            cornerRadius: 5,
                            fill: "rgba(255, 255, 255, 1.0)",
                            strokeWidth: 1,
                            strokeStyle: "#dfdfdf",
                            offsetParent: "body",
                            cssStyles: {
                                color: "#333333",
                                fontWeight: "normal",
                                lineHeight: "1.8rem",
                                fontSize: "16px"
                            },
                            positions: ["top", "left"]
                        });
                    } catch (e) {}
                },
                addCommas: function(nStr) {
                    nStr += "";
                    var x = nStr.split("."),
                        x1 = x[0],
                        x2 = x.length > 1 ? "." + x[1] : "",
                        rgx = /(\d+)(\d{3})/;
                    while (rgx.test(x1)) {
                        x1 = x1.replace(rgx, "$1" + "," + "$2");
                    }
                    return x1 + x2;
                },
                resizeButtons: function() {
                    $("form").submit(function() {
                        // On submit disable its submit button
                        $("button.lg, button.md, button.sm", this).attr(
                            "onClick",
                            "return false"
                        );
                    });
                },
                enableButtons: function() {
                    setTimeout(function() {
                        var buttons = $("button.lg, button.md, button.sm");
                        buttons.removeClass("disabled");
                        buttons.removeAttr("disabled");
                    }, 12);
                },
                disableButtons: function() {
                    $("button.lg, button.md, button.sm").addClass("disabled");
                    $(
                        "button.lg .disabled, button.md .disabled, .sm .disabled"
                    ).attr("disabled", "disabled");
                },
                clone: function(obj) {
                    if (null === obj || "object" !== typeof obj) {
                        return obj;
                    }
                    var copy = obj.constructor();
                    for (var attr in obj) {
                        if (obj.hasOwnProperty(attr)) {
                            copy[attr] = obj[attr];
                        }
                    }
                    return copy;
                },
                getObjByProperty: function(array, prop, val) {
                    for (var i = 0, l = array.length; i < l; i++) {
                        if (typeof array[i][prop] === "undefined") continue;
                        if (array[i][prop] === val) return array[i];
                    }
                    return false;
                },
                getArrayIndexByProperty: function(array, prop, val) {
                    for (var i = 0, l = array.length; i < l; i++) {
                        if (typeof array[i][prop] === "undefined") continue;
                        if (array[i][prop] === val) return i;
                    }
                    return false;
                },
                enforceLinkSecurity: function() {
                    var $this = this;
                    try {
                        var permArray = permissions.split(" ");
                        for (var i = 0, l = permArray.length; i < l; i++) {
                            if (permArray[i] === "") {
                                continue;
                            }
                            if (
                                RegExp("((C|c)alendar).(action)").test(
                                    window.location.pathname
                                ) &&
                                (permArray[i] == "secArticleReview" ||
                                    permArray[i] == "secManagerReports")
                            ) {
                                $this.enforceViewArticleSecurity();
                            } else {
                                $("a." + permArray[i])
                                    .not(".disableLink")
                                    .show();
                                $("span." + permArray[i] + ".display").hide();
                                $("div." + permArray[i])
                                    .not(".disableLink")
                                    .show();
                            }
                        }
                        var nonPermArray = nonPermissions.split(" ");
                        for (var c = 0, ln = nonPermArray.length; c < ln; c++) {
                            if (nonPermArray[c] === "") {
                                continue;
                            }
                            $("a." + nonPermArray[c])
                                .filter(".disableLink")
                                .contents()
                                .unwrap();
                        }
                    } catch (e) {
                        console.log("Error in permissions");
                    }
                },
                enforceViewArticleSecurity: function() {
                    try {
                        var ssArray = searchableStates.split(" "),
                            tooltipArticle = $(
                                "div.tooltipLinks a.secArticleReview"
                            ),
                            tooltipReports = $(
                                "div.tooltipLinks a.secManagerReports"
                            );
                        for (var i = 0, l = ssArray.length; i < l; i++) {
                            if (ssArray[i] === "") {
                                continue;
                            }
                            $("div.ss" + ssArray[i])
                                .filter(".secArticleReview")
                                .not(".disableLink")
                                .show();
                            $(
                                "div.ss" + ssArray[i] + " a.secArticleReview"
                            ).show();
                            $(
                                "div.ss" + ssArray[i] + " a.secManagerReports"
                            ).show();
                        }

                        //For assignment view overlay on calendar
                        if (!tooltipArticle.is(":visible")) {
                            tooltipArticle.remove();
                        }
                        if (!tooltipReports.is(":visible")) {
                            tooltipReports.remove();
                        }
                    } catch (e) {
                        console.log("Enforce security exception: " + e);
                    }
                },
                checkLocked: function(callback) {
                    var $this = this,
                        crId = $("#contentRequestId").val(),
                        urlAction = "lockStatusAjax.action",
                        options = {
                            data: { contentRequestId: crId },
                            cache: false,
                            type: "post",
                            url: urlAction,
                            success: function(text) {
                                var json = $.parseJSON(text);
                                if (json.locked === "true") {
                                    $this.colorboxAjax(
                                        "lockedOverlayAjax.action",
                                        null,
                                        650,
                                        null,
                                        null,
                                        null,
                                        false
                                    );
                                } else {
                                    if (callback != null) {
                                        callback();
                                    }
                                }
                            }
                        };
                    ajax(urlAction, options);
                },
                reloadAddthis: function() {
                    if (window.addthis) {
                        window.addthis = null;
                        window._adr = null;
                        window._atc = null;
                        window._atd = null;
                        window._ate = null;
                        window._atr = null;
                        window._atw = null;
                    }
                    if ($(".addthis_toolbox").length > 0) {
                        $.getScript(
                            "https://s7.addthis.com/js/250/addthis_widget.js#pubid=ra-4d7a320e422b977f"
                        );
                    }
                },
                checkboxMultChange: function() {
                    var check = $(this),
                        checkable = $("input[type='checkbox'].checkForAll"),
                        curChecked = $(
                            "input[type='checkbox'].checkForAll:checked"
                        );

                    if ("select-all" === check.attr("id")) {
                        if (check.is(":checked")) {
                            checkable.prop("checked", true);
                        } else {
                            checkable.prop("checked", false);
                        }
                        curChecked = $(
                            "input[type='checkbox'].checkForAll:checked"
                        );
                    }
                    if (0 === curChecked.length) {
                        $(".singular").show();
                        $(".plural").hide();
                        $(".checkboxButton")
                            .attr("disabled", "disabled")
                            .addClass("disabled");
                    } else {
                        if (1 < curChecked.length) {
                            $(".plural").show();
                            $(".singular").hide();
                        } else {
                            $(".singular").show();
                            $(".plural").hide();
                        }
                        $(".checkboxButton")
                            .removeAttr("disabled")
                            .removeClass("disabled");
                    }

                    //console.log("checkable: " + checkable.length + " | curChecked: " + curChecked.length);
                    if ("select-all" !== check.attr("id")) {
                        if (
                            0 < curChecked.length &&
                            checkable.length === curChecked.length
                        ) {
                            $("#select-all").prop("checked", true);
                        } else {
                            $("#select-all").prop("checked", false);
                        }
                    }

                    curChecked = $(
                        "input[type='checkbox'].checkForAll:checked"
                    );
                    $(".checkedNumber").html(curChecked.length);
                },
                /**
                 * TODO: Can this be simplified after eggnog?
                 * @param elem
                 * @param contentTypeIdElem
                 * @param progId
                 * @param inactives
                 * @param headStr
                 * @param newCatElemName
                 * @return {Boolean}
                 */
                populateCategories: function(
                    elem,
                    selectorElem,
                    contentTypeIdElem,
                    progId,
                    inactives,
                    headStr,
                    newCatElemName,
                    required,
                    assetContentTypesOnly
                ) {
                    var $this = this,
                        selValue,
                        options,
                        withInactives = false,
                        container = $("#" + elem),
                        withAssetContentTypesOnly = false;

                    // Additional work to hide content types dropdown if unnecessary; if so, just prepopulate
                    // the category dropdown from the passed-in content type ID. Otherwise use the dropdown selection.
                    if (!isNaN(parseFloat(contentTypeIdElem))) {
                        selValue = contentTypeIdElem;
                    } else {
                        selValue = jQuery(
                            "#" + contentTypeIdElem + " option:selected"
                        ).val();
                    }
                    if (assetContentTypesOnly == true) {
                        withAssetContentTypesOnly = true;
                    }
                    if (inactives == true) {
                        withInactives = true;
                    }
                    if (requestActive) {
                        return false;
                    }
                    app.value("requestActive", true);
                    options = {
                        type: "POST",
                        url: "/findCategories.action",
                        dataType: "html",
                        data: {
                            contentTypeId: selValue,
                            programId: progId,
                            includeInactives: withInactives,
                            required: required,
                            withAssetContentTypesOnly: assetContentTypesOnly
                        },
                        success: function(data, textStatus, jqXHR) {
                            var newSelect = $(data);
                            data = newSelect.html();

                            if (
                                "/searchDigitalAssets.action" ===
                                window.location.pathname
                            ) {
                                data = data.replace(
                                    ">Select a category<",
                                    ">" + headStr + "<"
                                );
                            } else if (null !== headStr) {
                                data = data
                                    .replace(">all<", ">" + headStr + "<")
                                    .replace("width:200px", "width:212px")
                                    .replace(
                                        /selectedCategoryId/g,
                                        "programCategory"
                                    );
                            }
                            if (null !== newCatElemName) {
                                data = data.replace(
                                    /programCategory/g,
                                    newCatElemName
                                );
                                if ("categoryId" === newCatElemName) {
                                    data = data.replace(
                                        /lgTextField/g,
                                        "lgTextField categoryIds"
                                    );
                                }
                                if (
                                    "/viewKeywordList.action" ===
                                        window.location.pathname ||
                                    "/searchKeywordList.action" ===
                                        window.location.pathname
                                ) {
                                    $("span#categoryDiv").css({
                                        overflow: "visible",
                                        "text-overflow": "ellipsis",
                                        "text-align": "left",
                                        "white-space": "nowrap"
                                    });
                                }
                            }
                            container.html(data);
                            var selectedCat = $("#" + selectorElem);
                            selectedCat
                                .removeAttr("disabled")
                                .removeClass("chzn-done");

                            if (
                                selectedCat.find("option").length == 2 ||
                                selectedCat.val() != -1
                            ) {
                                selectedCat
                                    .find("option")
                                    .last()
                                    .attr("selected", "selected");
                                $("#contentTypeModify-button")
                                    .removeAttr("disabled")
                                    .removeClass("disabled");
                                if (
                                    "/viewKeywordList.action" ===
                                        window.location.pathname ||
                                    "/searchKeywordList.action" ===
                                        window.location.pathname
                                ) {
                                    $("span#categoryDiv").css({
                                        position: "relative",
                                        width: "33%",
                                        "margin-top": "1px",
                                        overflow: "hidden",
                                        "text-overflow": "ellipsis",
                                        display: "inline-block",
                                        "text-align": "center",
                                        "white-space": "nowrap"
                                    });
                                }
                            }
                            $("#" + selectorElem + "_chzn").remove();
                            selectedCat.selectBoxIt();

                            app.value("requestActive", false);
                        },
                        error: function(jqXHR, textStatus, errorThrown) {
                            app.value("requestActive", false);
                        }
                    };
                    ajax("/findCategories.action", options);
                },
                delay: function(fn, delay) {
                    var timer = null;
                    return function() {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = setTimeout(function() {
                            fn.apply(context, args);
                        }, delay);
                    };
                },
                createHistoryState: function(html, urlPath, name) {
                    var state = { html: html, page: name };
                    window.history.pushState(state, name, urlPath);
                },
                hideZendeskToolTipIcon: function() {
                    $(".qtipTooltip").each(function() {
                        if (
                            $(this)
                                .attr("title")
                                .substring(0, 14) == "zendesktooltip"
                        ) {
                            $(this).hide();
                        }
                    });
                },
                enableDisableButton: function() {
                    var enable = true;
                    if ($("body").hasClass("publishing")) {
                        return false;
                    }
                    try {
                        $(
                            "input.requiredForOpen, select.requiredForOpen, textarea.requiredForOpen"
                        ).each(function() {
                            var el = $(this),
                                minLengthObj = el.siblings(".minLength"),
                                minLength =
                                    0 === minLengthObj.length
                                        ? 0
                                        : isNaN(minLengthObj.val())
                                            ? 0
                                            : minLengthObj.val(),
                                inputValue = el.val();

                            if (
                                (-1 === parseInt(el.val(), 10) &&
                                    "programCategoryId" === el.attr("id")) ||
                                (el.hasClass("textInput") &&
                                    ("" === inputValue ||
                                        inputValue.length < minLength))
                            ) {
                                enable = false;
                            }

                            if (
                                el.hasClass("multiple") &&
                                !Array.isArray(el.val())
                            ) {
                                enable = false;
                            }
                            if (
                                el.hasClass("single-select") &&
                                (typeof parseInt(el.val(), 10) !== "number" ||
                                    isNaN(parseInt(el.val(), 10)))
                            ) {
                                //    // &&  typeof parseInt(el.val(), 10) !== "number" && -1 !== parseInt(el.val(), 10)
                                enable = false;
                            }
                        });
                        if (enable) {
                            $("#enteredButton")
                                .removeClass("disabled")
                                .removeAttr("disabled");
                        } else {
                            $("#enteredButton")
                                .addClass("disabled")
                                .attr("disabled", "disabled");
                        }
                    } catch (e) {
                        console.log(e);
                    }
                },
                osCheck: function() {},
                showTimeFrame: function() {
                    $(".timeago").each(function() {
                        var t = $(this),
                            timeAgo = t.attr("data-timeago");
                        if (
                            undefined === timeAgo ||
                            null === timeAgo ||
                            "" === timeAgo
                        ) {
                            return true;
                        }
                        var seconds = parseInt(timeAgo, 10) / 1000;
                        var momunix = moment.unix(seconds);
                        var tz = $("input#timezoneVal").val();
                        var myTime = momunix.clone();
                        if (tz != "") {
                            myTime = myTime.tz(tz);
                        }
                        t.text(moment(myTime).fromNow());
                        var userLang = $("#userLanguage").val();
                        if (
                            userLang != null &&
                            (userLang === "th_TH" || userLang === "th")
                        ) {
                            myTime.year(myTime.year() + 543);
                        }
                        var timeString =
                            myTime.format($("#moment-date").val()) +
                            " " +
                            $("#js-at").val() +
                            " " +
                            myTime.format($("#js-time").val());
                        t.attr("title", timeString);
                        t.bt({
                            padding: 10,
                            width: 175,
                            shrinkToFit: true,
                            spikeLength: 10,
                            spikeGirth: 10,
                            cornerRadius: 5,
                            fill: "rgba(255, 255, 255, 1.0)",
                            strokeWidth: 1,
                            strokeStyle: "#dfdfdf",
                            offsetParent: "body",
                            cssStyles: {
                                color: "#333333",
                                fontWeight: "normal"
                            },
                            positions: ["top", "right"]
                        });
                    });
                    $(".timeagoDateFormat").each(function() {
                        var t = $(this),
                            timeAgo = t.attr("data-timeago"),
                            writtenby = t.attr("data-writtenby");
                        if (
                            undefined === timeAgo ||
                            null === timeAgo ||
                            "" === timeAgo
                        ) {
                            return true;
                        }
                        var seconds = parseInt(timeAgo, 10) / 1000;
                        var momunix = moment.unix(seconds);
                        var tz = $("input#timezoneVal").val();
                        var myTime = momunix.clone();
                        if (tz != "") {
                            myTime = myTime.tz(tz);
                        }
                        var userLang = $("#userLanguage").val();
                        if (
                            userLang != null &&
                            (userLang === "th_TH" || userLang === "th")
                        ) {
                            myTime.year(myTime.year() + 543);
                        }
                        var timeString =
                            myTime.format($("#moment-date").val()) +
                            " " +
                            $("#js-at").val() +
                            " " +
                            myTime.format($("#js-time").val());
                        t.text(
                            myTime.format($("#moment-date").val()) +
                                " " +
                                writtenby
                        );
                        t.attr("title", timeString);
                        t.bt({
                            padding: 10,
                            width: 250,
                            shrinkToFit: true,
                            spikeLength: 10,
                            spikeGirth: 10,
                            cornerRadius: 5,
                            fill: "rgba(255, 255, 255, 1.0)",
                            strokeWidth: 1,
                            strokeStyle: "#dfdfdf",
                            offsetParent: "body",
                            cssStyles: {
                                color: "#333333",
                                fontWeight: "normal"
                            },
                            positions: ["top", "right"]
                        });
                    });
                },
                getAPIURL: function(pathOrURL) {
                    if (!new RegExp("^(http(s)?[:]//)", "i").test(pathOrURL)) {
                        return (
                            document
                                .getElementById("page-home")
                                .getAttribute("data-apibaseurl") + pathOrURL
                        );
                    }

                    return pathOrURL;
                },
                getToken: function(name) {
                    let cookies = document.cookie;
                    return (
                        cookies
                            .match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")
                            ?.pop() || ""
                    );
                }
            };
        }
    ]);
})();

app.service("externalCalendar", [
    "$http",
    function($http) {
        return {
            getCalendarFilter: getCalendarFilter
        };

        function getCalendarFilter(guid) {
            return $http.get("/API/external-calendar/" + guid);
        }
    }
]);

app.controller("articlesReview", [
    "$scope",
    "skyword",
    "call",
    "setup",
    function($scope, skyword, call, setup) {
        var option = $("#select-category option:selected"),
            contentTypeId,
            categoryId;
        if (option.hasClass("contentTypeId")) {
            contentTypeId = $("#select-category").val();
            categoryId = -1;
        } else {
            contentTypeId = -1;
            categoryId = $("#select-category").val();
        }
        var urlAction =
                "/articlesReviewAjax.action?d-5433-p=" +
                $("#pageNum").val() +
                "&contentTypeId=" +
                contentTypeId +
                "&categoryId=" +
                categoryId,
            options = {
                cache: false,
                dataType: "html",
                type: "POST",
                url: urlAction,
                success: function(data) {
                    var articlesReview = $("#articles-review");
                    articlesReview.html(data);
                    articlesReview.displayTagAjax();
                    skyword.enforceLinkSecurity();
                    $("#loading-message").fadeOut();
                    setup.qtipTooltips();
                    if ($(".comment")[0]) {
                        $("#show-expanded").css({
                            color: "#333F48",
                            "pointer-events": "none",
                            cursor: "default"
                        });
                    } else {
                        $("#show-list.labelList").css({
                            color: "#333F48",
                            "pointer-events": "none",
                            cursor: "default"
                        });
                    }
                }
            };
        call.ajax(urlAction, options);
        $("#select-priority, #select-category  ").change(function() {
            var option = $("#select-category option:selected"),
                contentTypeId,
                categoryId;
            if (option.hasClass("contentTypeId")) {
                contentTypeId = $("#select-category").val();
                categoryId = -1;
            } else {
                contentTypeId = -1;
                categoryId = $("#select-category").val();
            }

            window.location.href =
                "articlesReview.action?s=" +
                $("#select-priority").val() +
                "&contentTypeId=" +
                contentTypeId +
                "&categoryId=" +
                categoryId;
        });

        $("#list-views-module a").click(function() {
            var a = $(this),
                urlAction = "setViewModeAjax.action",
                b = a.siblings("a");
            if (a.attr("id") === "show-list") {
                $(".comment").fadeOut();
                urlAction += "?view=list";
            } else {
                urlAction += "?view=expand";
            }
            var options = {
                cache: false,
                dataType: "html",
                data: null,
                type: "POST",
                url: urlAction,
                complete: function() {
                    window.location.href = "articlesReview.action";
                }
            };
            call.ajax(urlAction, options);
            return false;
        });

        if ($("#view-type").val() == "expand") {
            $("#articles-review .odd, #articles-review .even").on(
                "hover",
                function() {
                    if (!$(this).hasClass("comment")) {
                        $(this).addClass("greyHover");
                        $(this)
                            .next()
                            .addClass("greyHover");
                    }
                }
            );
            $("#articles-review .odd, #articles-review .even").on(
                "mouseout",
                function() {
                    if (!$(this).hasClass("comment")) {
                        $(this).removeClass("greyHover");
                        $(this)
                            .next()
                            .removeClass("greyHover");
                    }
                }
            );
            $("#articles-review .comment").on("hover", function() {
                $(this).addClass("greyHover");
                $(this)
                    .prev()
                    .addClass("greyHover");
            });
            $("#articles-review .comment").on("mouseout", function() {
                $(this).removeClass("greyHover");
                $(this)
                    .prev()
                    .removeClass("greyHover");
            });
        }
    }
])
    .controller("articlesReviewAjax", ["$scope", function($scope) {}])
    .controller("setViewModeAjax", ["$scope", function($scope) {}])
    .controller("articleReview", [
        "$scope",
        "skyword",
        "publish",
        "articleReview",
        "qualityRating",
        "$compile",
        "$http",
        "call",
        function(
            $scope,
            skyword,
            publish,
            articleReview,
            qualityRating,
            $compile,
            $http,
            call,
        ) {
            app.value("ranOnce", false);
            app.value("requestActive", false);

            var spellcheckLang = $scope.spellcheckLang;

            publish.init();
            qualityRating.init();
            articleReview.init();

            $("#metaEdit, #articleEdit").click(function(e) {
                var thisLink = this;
                e.preventDefault();
                skyword.checkLocked(function() {
                    location.href = thisLink.href;
                });
            });

            $(function() {
                var contentRequestId = jQuery("#attrs-id").data(
                    "contentrequestid"
                );
                $("#removeHoldAjax").click(function removeHoldAjaxAjax() {
                    var p = $http.get(
                        "/removeHoldAjax.action?contentRequestId=" +
                            contentRequestId
                    );
                    p.then(function removeHoldAjaxThen(response) {
                        jQuery.colorbox({
                            html: response.data,
                            width: 600
                        });
                        overlay.setupColorbox();
                    });
                });
            });

            $(".article-review-action .btnGroup a").click(function(e) {
                var thisButton = $(this);
                if (thisButton.hasClass("disabled")) {
                    return false;
                }
                $("#loader-module")
                    .show()
                    .find(".saveDraftText")
                    .hide();
                $(".sidebarBox").addClass("whiteout");
                if ($("#update-status-box").is(":visible")) {
                    $(".sidebarBox").css("display", "none");
                }
                // Make sure the article hasn't been locked behind the scenes
                if (thisButton.hasClass("disabled")) {
                    return false;
                }
                skyword.checkLocked(function() {
                    articleReview.updateState(thisButton.attr("id"));
                });
                return false;
            });

            // CT-550 Prevent double clicking of spell check tab to avoid resetting suggestion counts
            $("#article-suggestions a").on("click", function(e) {
                if (
                    $(this)
                        .parent()
                        .hasClass("active")
                ) {
                    e.stopPropagation();
                }
            });

            //needed for new moment-datepicker
            $scope.dates = {
                resubmitDeadline: null,
                redeliveryDeadline: null,
                format: null
            };

            //watches start date to make sure end date is not before start date
            $scope.$watch("[dates.resubmitDeadline]", function(
                newValue,
                oldValue
            ) {
                $scope.momentDateFormat = $("#momentPickerFormat").val();
                $scope.dateformat = "MM-DD-YYYY hh:[00] A";

                $scope.dates.format = $scope.momentDateFormat + " hh:[00] A";
                $scope.dates.format = $scope.dates.format.toString();
                //dont let them choose a date before current date/time + 1 hour
                $scope.minDate = moment(moment().add(1, "hour"))
                    .format($scope.dates.format)
                    .toString();

                if ($scope.dates.resubmitDeadline == null) {
                    var resubmitDeadline = $("#resubmitDeadlineString").val();
                    $scope.dates.resubmitDeadline = moment(
                        resubmitDeadline,
                        $scope.dateformat
                    ).format($scope.dates.format);
                }

                if ($scope.dates.resubmitDeadline != null) {
                    $scope.resubmitDeadline = moment(
                        $scope.dates.resubmitDeadline,
                        $scope.dates.format
                    )
                        .locale("en")
                        .format($scope.dateformat);
                    $("#resubmitDeadlineString").val($scope.resubmitDeadline);
                }
            });

            // Reason options for return for revision
            $scope.reasonOptions;
            $scope.selectedReason;
            function getReasonValues() {
                const token = skyword.getToken("access_token");
                const url = skyword.getAPIURL(`review/revisionReasons`);
                jQuery.ajax({
                    cache: false,
                    type: "GET",
                    headers: {
                        Accept: "application/json;",
                        "Content-Type": "application/json;"
                    },
                    url: url,
                    beforeSend: function(xhr) {
                        xhr.setRequestHeader(
                            "Authorization",
                            `Bearer ${token}`
                        );
                    },
                    success: function(data) {
                        $scope.selectedReason = {};
                        const updatedOption = [...data, { id: data.length + 1, name: "Other" }];
                        $scope.reasonOptions = updatedOption.map(function(item) {
                            return { id: item.id, name: item.name };
                        });
                        var selectElement = document.getElementById("reasonValue");
                        selectElement.innerHTML = "";
                        var defaultOption = document.createElement("option");
                        defaultOption.value = "";
                        defaultOption.text = "Select the reason";
                        selectElement.appendChild(defaultOption);
                        $scope.reasonOptions.forEach(function(option) {
                            var newOption = document.createElement("option");
                            newOption.value = option.id;
                            newOption.text = option.name;
                            selectElement.appendChild(newOption);
                        });
                        let optionSelected = $("#reasonText").text().trim();

                        $scope.selectedReason = $scope.reasonOptions.find(function(option) {
                            return option.name === optionSelected
                        });
                        var selectElement = document.getElementById("reasonValue");
                    },
                    error: function(error) {
                        console.log(error);
                    }
                });
            }
            $(document).on("change", "#reasonValue", function() {
                var selectedOption = $(this).val();
                var selectedObject = {};
                $("#updatedOtherReasonValue").val('');
                $("#updatedReasonValue").val('');
                if(selectedOption) {
                    selectedObject = $scope.reasonOptions.find(function(obj) {
                        return obj.id === parseInt(selectedOption);
                    });
                }
                if(selectedObject.name !== "Select the reason") {
                    $("#reasonErrorText").hide();
                    $("#formSubmitBtn").prop('disabled', false).removeClass('disabled');
                    $("#updatedReasonValue").val(selectedObject.name);
                }
                if(selectedObject.name === "Other") {
                    $("#otherReasonValue").show();
                } else {
                    $("#otherReasonValue").hide();
                }
            });

            $(document).on("change", "#otherReasonValue", function() {
                var otherReasonValue = $(this).val();
                if(otherReasonValue?.trim()?.length > 0) {
                    $("#reasonErrorText").hide();
                    $("#formSubmitBtn").prop('disabled', false).removeClass('disabled');
                    $("#updatedOtherReasonValue").val(otherReasonValue);
                }
            });

            //watches start date to make sure end date is not before start date
            $scope.$watch("[dates.redeliveryDeadline]", function(
                newValue,
                oldValue
            ) {
                $scope.momentDateFormat = $("#momentPickerFormat").val();
                $scope.dateformat = "MM-DD-YYYY hh:[00] A";

                $scope.dates.format = $scope.momentDateFormat + " hh:[00] A";
                $scope.dates.format = $scope.dates.format.toString();
                //dont let them choose a date before current date/time + 1 hour
                $scope.minDate = moment(moment().add(1, "hour"))
                    .format($scope.dates.format)
                    .toString();

                if ($scope.dates.redeliveryDeadline == null) {
                    var redeliveryDeadline = $("#redeliveryDeadlineString").val();
                    $scope.dates.redeliveryDeadline = moment(
                        redeliveryDeadline,
                        $scope.dateformat
                    ).format($scope.dates.format);
                }

                if ($scope.dates.redeliveryDeadline != null) {
                    $scope.redeliveryDeadline = moment(
                        $scope.dates.redeliveryDeadline,
                        $scope.dates.format
                    )
                        .locale("en")
                        .format($scope.dateformat);
                    $("#redeliveryDeadlineString").val($scope.redeliveryDeadline);
                }
            });

            //when return for revision is clicked we need to recompile scope
            $("#review-submit-action a#7").click(function() {
                setTimeout(function() {
                    if ($("#update-status-box").is(":visible")) {
                        $compile($("#update-status-box"))($scope);
                        $scope.dates.resubmitDeadline = moment(
                            $("#resubmitDeadlineString").val(),
                            $scope.dateformat
                        ).format($scope.dates.format);
                        $scope.dates.redeliveryDeadline = moment(
                            $("#redeliveryDeadlineString").val(),
                            $scope.dateformat
                        ).format($scope.dates.format);
                    }
                }, 2000);
                setTimeout(getReasonValues, 1000);
            });
            $scope.showFields = true;
            $scope.audienceOptions;
            $scope.persistUpdatedData = {};
            $scope.openEditParametersPopup = function() {
                $("#confirmParameterEditBtn").prop('disabled', false).removeClass('disabled');
                const programId = $("#attrs-id").data("programid");
                const token = skyword.getToken("access_token");
                const url = skyword.getAPIURL(`promptParameter/${programId}/typeId/1`);
                jQuery.ajax({
                    cache: false,
                    type: "GET",
                    headers: {
                        Accept: "application/json;",
                        "Content-Type": "application/json;"
                    },
                    url: url,
                    beforeSend: function(xhr) {
                        xhr.setRequestHeader(
                            "Authorization",
                            `Bearer ${token}`
                        );
                    },
                    success: function(data) {
                        $scope.selectedAudience = {};
                         $scope.audienceOptions = data.map(function(item) {
                           return { id: item.id, name: item.name };
                        });
                        var selectElement = document.getElementById("audienceValue");
                        selectElement.innerHTML = "";
                        var defaultOption = document.createElement("option");
                        defaultOption.value = "";
                        defaultOption.text = "Select Audience";
                        selectElement.appendChild(defaultOption);
                        $scope.audienceOptions.forEach(function(option) {
                            var newOption = document.createElement("option");
                            newOption.value = option.id;
                            newOption.text = option.name;
                            selectElement.appendChild(newOption);
                        });
                        let optionSelected = $("#audienceText").text().trim();
                        $scope.persistUpdatedData.audienceId = optionSelected;

                        $scope.selectedAudience = $scope.audienceOptions.find(function(option) {
                            return option.name === optionSelected
                        });
                        var selectElement = document.getElementById("audienceValue");
                        for (var i = 0; i < selectElement.options.length; i++) {
                            if (selectElement.options[i].value == $scope.selectedAudience.id) {
                                selectElement.selectedIndex = i;
                                break;
                            }
                        }
                        // clear persisted data on click of confirm
                        $scope.persistUpdatedData = {};
                    },
                    error: function(error) {
                        console.log(error);
                    }
                });
                $("#titleValue").val($("#titleText").text().trim())
                $("#additionalInstructionsValue").val($("#additionalInstructionsText").text().trim())
                $("#keywordsValue").val($("#keywordsText").text().trim())
                // persist the data if user clicks on cancel.
                $scope.persistUpdatedData.title = $("#titleValue").val().trim();
                $scope.persistUpdatedData.additionalInstructions = $("#additionalInstructionsValue").val().trim();
                $scope.persistUpdatedData.title = $("#keywordsValue").val().trim();
                $.colorbox({
                    inline: true,
                    href: "#editParametersPopup",
                    width: "30%",
                    height: "auto",
                });
            };

            // Function to confirm edit
            $scope.confirmEdit = function() {

                let selectedAudienceId = $("#audienceValue").val().trim();
                let updatedKeywords = $("#keywordsValue").val().trim().split(",");
                let updatedData = JSON.stringify({
                    title: $("#titleValue").val().trim(),
                    audienceId: selectedAudienceId,
                    additionalInstructions: $("#additionalInstructionsValue").val().trim(),
                    keyword: updatedKeywords[0] || "",
                    keyword2: updatedKeywords[1] || "",
                    keyword3: updatedKeywords[2] || ""
                });

                var selectedAudienceOption = $scope.audienceOptions.find(function(option) {
                    return option.id === parseInt(selectedAudienceId);
                });
                let populateUpdatedData = {};
                if (Object.keys($scope.persistUpdatedData).length < 1 ) {
                    populateUpdatedData = {
                        title: $("#titleValue").val().trim(),
                        additionalInstructions: $("#additionalInstructionsValue").val().trim(),
                        audienceId: selectedAudienceOption ? selectedAudienceOption.name.trim() : "",
                        keywords: $("#keywordsValue").val().trim(),
                    }
                } else {
                    populateUpdatedData = Object.assign({}, $scope.persistUpdatedData);
                }

                const token = skyword.getToken("access_token");
                contentRequestId = $("#attrs-id").data("contentrequestid");
                const url = skyword.getAPIURL(`review/content/${contentRequestId}`);
                jQuery.ajax({
                    cache: false,
                    type: "PUT",
                    data: updatedData,
                    headers: {
                        Accept: "application/json;",
                        "Content-Type": "application/json;"
                    },
                    url: url,
                    beforeSend: function(xhr) {
                        xhr.setRequestHeader(
                            "Authorization",
                            `Bearer ${token}`
                        );
                    },
                    success: function(data) {
                        $("#titleText").text(populateUpdatedData.title);
                        $("#audienceText").text(populateUpdatedData.audienceId);
                        $("#additionalInstructionsText").text(populateUpdatedData.additionalInstructions);
                        $("#keywordsText").text(populateUpdatedData.keywords);
                        $("#contentRequestTitleValue").text(populateUpdatedData.title);
                        $('[data-contentrequestid='+contentRequestId+']').text(populateUpdatedData.title);
                        $("#keyword1Value").text(updatedKeywords[0] || "");
                        $("#keyword2Value").text(updatedKeywords[1] || "");
                        $("#keyword3Value").text(updatedKeywords[2] || "");
                    },
                    error: function(error) {
                        console.log(error);
                    }
                });
                // Close colorbox popup
                $.colorbox.close();
            };

            $(document).on("change", "#audienceValue", function() {
                var selectedOption = $(this).val();
                var selectedObject = {};
                if(selectedOption) {
                    selectedObject = $scope.audienceOptions.find(function(obj) {
                        return obj.id === parseInt(selectedOption);
                    });
                }
                $scope.persistUpdatedData.audienceId = selectedObject.name;
                $scope.persistUpdatedData.title = $("#titleValue").val().trim();
                $scope.persistUpdatedData.additionalInstructions = $("#additionalInstructionsValue").val().trim();
                $scope.persistUpdatedData.keywords = $("#keywordsValue").val().trim();
            });

            // Function to cancel edit
            $scope.cancelEdit = function() {
                // Close colorbox popup
                $.colorbox.close();
            };

            $scope.togglePriority = function() {
                var priorityFlagIconValue = $("#isMarkedPriority").val();
                const programId = $("#attrs-id").data("programid");
                const token = skyword.getToken("access_token");
                const contentRequestId = $("#contentRequestId").val();
                // toggle the flag
                var markedPriority = (priorityFlagIconValue == "true") ? false : true;
                var data = {
                    markedPriority: markedPriority
                };
                const url = skyword.getAPIURL(`review/program/${programId}/content/${contentRequestId}/priority`);
                jQuery.ajax({
                    cache: false,
                    type: "PUT",
                    headers: {
                        Accept: "application/json;",
                        "Content-Type": "application/json;"
                    },
                    url: url,
                    data: JSON.stringify(data),
                    beforeSend: function(xhr) {
                        xhr.setRequestHeader(
                            "Authorization",
                            `Bearer ${token}`
                        );
                    },
                    success: function(response, status, req) {
                        if(status === "success" && req.status === 200) {
                            $('#priorityFlagIcon').toggleClass('light', !markedPriority);
                            $("#isMarkedPriority").val(markedPriority)

                        }
                    },
                    error: function(error) {
                        console.log(error);
                    }
                });
            }
        }
    ])
    .controller("assetReview", [
        "$scope",
        "skyword",
        "publish",
        "articleReview",
        "qualityRating",
        "$compile",
        function(
            $scope,
            skyword,
            publish,
            articleReview,
            qualityRating,
            $compile
        ) {
            app.value("ranOnce", false);
            app.value("requestActive", false);

            var spellcheckLang = $scope.spellcheckLang;

            publish.init();
            qualityRating.init();
            articleReview.init();

            $("#metaEdit, #articleEdit").click(function(e) {
                var thisLink = this;
                e.preventDefault();
                skyword.checkLocked(function() {
                    location.href = thisLink.href;
                });
            });

            $(".article-review-action .btnGroup a").click(function(e) {
                var thisButton = $(this);
                if (thisButton.hasClass("disabled")) {
                    return false;
                }
                $("#loader-module")
                    .show()
                    .find(".saveDraftText")
                    .hide();
                $(".sidebarBox").addClass("whiteout");
                if ($("#update-status-box").is(":visible")) {
                    $(".sidebarBox").css("display", "none");
                }
                // Make sure the article hasn't been locked behind the scenes
                if (thisButton.hasClass("disabled")) {
                    return false;
                }
                skyword.checkLocked(function() {
                    articleReview.updateState(thisButton.attr("id"));
                });
                return false;
            });

            //needed for new moment-datepicker
            $scope.dates = {
                resubmitDeadline: null,
                format: null
            };

            //watches start date to make sure end date is not before start date
            $scope.$watch("[dates.resubmitDeadline]", function(
                newValue,
                oldValue
            ) {
                $scope.momentDateFormat = $("#momentPickerFormat").val();
                $scope.dateformat = "MM-DD-YYYY hh:[00] A";

                $scope.dates.format = $scope.momentDateFormat + " hh:[00] A";
                $scope.dates.format = $scope.dates.format.toString();
                //dont let them choose a date before current date/time + 1 hour
                $scope.minDate = moment(moment().add(1, "hour"))
                    .format($scope.dates.format)
                    .toString();

                if ($scope.dates.resubmitDeadline == null) {
                    var resubmitDeadline = $("#resubmitDeadlineString").val();
                    $scope.dates.resubmitDeadline = moment(
                        resubmitDeadline,
                        $scope.dateformat
                    ).format($scope.dates.format);
                }

                if ($scope.dates.resubmitDeadline != null) {
                    $scope.resubmitDeadline = moment(
                        $scope.dates.resubmitDeadline,
                        $scope.dates.format
                    )
                        .locale("en")
                        .format($scope.dateformat);
                    $("#resubmitDeadlineString").val($scope.resubmitDeadline);
                }
            });

            //when return for revision is clicked we need to recompile scope
            $("#review-submit-action a#7").click(function() {
                setTimeout(function() {
                    if ($("#update-status-box").is(":visible")) {
                        $compile($("#update-status-box"))($scope);
                        $scope.dates.resubmitDeadline = moment(
                            $("#resubmitDeadlineString").val(),
                            $scope.dateformat
                        ).format($scope.dates.format);
                    }
                }, 2000);
            });
        }
    ])
    .controller("rateContent", ["$scope", function($scope) {}])
    .controller("viewContent", [
        "$scope",
        "skyword",
        "publish",
        "articleReview",
        "qualityRating",
        "$compile",
        "$http",
        function(
            $scope,
            skyword,
            publish,
            articleReview,
            qualityRating,
            $compile,
            $http
        ) {
            app.value("ranOnce", false);
            app.value("requestActive", false);

            publish.init();
        }
    ])
    .controller("lockStatusAjax", ["$scope", function($scope) {}])
    .controller("lockedOverlayAjax", ["$scope", function($scope) {}])
    .controller("editMetaInfo", ["$scope", function($scope) {}])
    .controller("updateContentRequestState", ["$scope", function($scope) {}])
    .controller("updateContentRequestStateAjax", [
        "$scope",
        function($scope) {}
    ])
    .controller("editCommentAjax", ["$scope", function($scope) {}])
    .controller("contentRatingAjax", ["$scope", function($scope) {}])
    .controller("updateExternalReviewState", ["$scope", function($scope) {}])
    .controller("externalContentReview", [
        "$scope",
        "author",
        "externalReview",
        "publish",
        "articleReview",
        function($scope, author, externalReview, publish, articleReview) {
            app.value("ranOnce", false);
            app.value("requestActive", false);

            var spellcheckLang = $scope.spellcheckLang;

            author.init();
            externalReview.init();
            publish.init();
            articleReview.init();

            $(window).on("beforeunload", function() {
                externalReview.unlockExternalReviewerContent();
                return;
            });
        }
    ])
    .controller("unlockExternalReviewerContentAjax", [
        "$scope",
        function($scope) {}
    ]);

app.service("reports", [
    "skyword",
    "call",
    "skywordGraphs",
    function(skyword, call, skywordGraphs) {
        var drawBar = function(curVal, maxVal, width, color, element) {
                var currentLength = (curVal / maxVal) * 99.8;
                currentLength = currentLength < 2 ? 2 : currentLength;
                var extraLength = 99.8 - currentLength;
                if ($(element).html() == "") {
                    $(element).html(
                        '<span class="slideOut"><span class="value bar ' +
                            color +
                            ' left" style="width:' +
                            currentLength +
                            '%"></span><span class="extra bar lightGrey left" style="width:' +
                            extraLength +
                            '%"></span>'
                    );
                } else {
                    var value = $(element).find(".value"),
                        extra = $(element).find(".extra");
                    value.width(currentLength + "%");
                    extra.width(extraLength + "%");
                }
            },
            drawBarGraph = function(typeToShow) {
                var total = findTotal(typeToShow),
                    totalWidth = $(".barGraph").width() - 20;
                $("#swRow tbody tr").each(function() {
                    var type = $(this).find(".type"),
                        views = type.next(),
                        barGraph = views.next();
                    type = type.text();
                    views = views.text().replace(",", "");
                    $(this)
                        .find(".type")
                        .addClass(type);
                    type = type
                        .replace("search", "graphSalmon")
                        .replace("social", "graphDarkSalmon")
                        .replace("graphDarkSalmonViews", "graphDarkSalmon")
                        .replace("email", "graphPurple")
                        .replace("self", "graphBlue")
                        .replace("other", "graphYellow")
                        .replace("graphSalmonOrganic", "graphSalmon")
                        .replace("graphSalmonPaid", "graphCharcoal")
                        .replace("direct", "graphDarkYellow")
                        .replace("displayViews", "graphCharcoalLight")
                        .replace("referralViews", "graphBlueB");
                    views = parseInt(views.replace(",", ""));
                    drawBar(views, total, totalWidth, type, barGraph);
                });
            },
            showHideRows = function(typeShow) {
                showNoRecordTable(findTotal(typeShow));
                $("ul#tab-row li").removeClass("active");
                $("ul#tab-row li .tabButton").removeClass("current");
                if (typeShow == "all") {
                    $("#swRow tbody tr .type")
                        .parent()
                        .show();
                    $("ul#tab-row li#all")
                        .addClass("active")
                        .find(".tabButton")
                        .addClass("current");
                } else {
                    $("#swRow tbody tr .type")
                        .parent()
                        .hide();
                    $("#swRow tbody tr .type." + typeShow)
                        .parent()
                        .show();
                    $("ul#tab-row li#" + typeShow)
                        .addClass("active")
                        .find(".tabButton")
                        .addClass("current");
                }
            },
            findTotal = function(typeShow) {
                var total = 0;
                $("#swRow tbody tr").each(function() {
                    var type = $(this)
                        .find(".type")
                        .text();
                    if (type == typeShow || typeShow == "all") {
                        var curVal = $(this)
                            .find(".totalViews")
                            .text()
                            .replace(",", "");
                        curVal = parseInt(curVal.replace(",", ""));
                        if (total < curVal) {
                            total = curVal;
                        }
                    }
                });
                return total;
            },
            showNoRecordTable = function(total) {
                // Hide the referrer table and display no record found message.
                $("#referrer-data-table")
                    .find(".no-referrer-table-data")
                    .hide();
                if (total < 1) {
                    $("#referrer-data-table")
                        .find("table")
                        .hide();
                    $("#referrer-data-table")
                        .find(".no-referrer-table-data")
                        .show();
                } else {
                    $("#referrer-data-table")
                        .find("table")
                        .show();
                }
            };

        return {
            init: function() {
                //onReady
                $(function() {
                    $(".noMgr").html($("#noMgrLbl").val());

                    //Fix for SKY-1807
                    if ($("body").find(".noMgr") != "") {
                        $(".noMgr").html($("#noMgrLbl").val());
                    }

                    var exportLink1 = $(".rankExportlinks a")
                            .first()
                            .attr("href"),
                        exportLink2 = $(".rankExportlinks a")
                            .last()
                            .attr("href");

                    $("ul#tab-row li").each(function(i, el) {
                        $(el).click(function() {
                            var id = $(this).attr("id");

                            drawBarGraph(id);
                            showHideRows(id);

                            $(".rankExportlinks a")
                                .first()
                                .attr(
                                    "href",
                                    exportLink1 + "&limit=100&category=" + id
                                );
                            $(".rankExportlinks a")
                                .last()
                                .attr(
                                    "href",
                                    exportLink2 + "&limit=100&category=" + id
                                );
                        });
                    });
                });
            },
            keywordRankGraph: function() {
                if ($("#managerReportKeywordRankResultsEmpty").length) {
                    app.value("requestActive", false);
                    skywordGraphs.getGraph({
                        graphType: "stacked",
                        ajaxParams: { searchEngine: $("#search-engine").val() },
                        action: "/getKeywordRankedReportGraphAjax.action",
                        type: "json",
                        location: "chart",
                        title: $("#keyword-rank-report-graph-title").val(),
                        highlight: true,
                        zoom: true,
                        xType: "date",
                        xLabel: "  ",
                        xLabelAngle: 0,
                        xFormat: "%b %#d",
                        errorMessage: $("#no-data").val(),
                        yType: "Number",
                        yLabel: " ",
                        yFormat: "%d",
                        popupFormat:
                            "<span class='value'>{y}</span><span>" +
                            $("#keywords").val() +
                            "</span>"
                    });
                }
                $("html").on("click", ".keywordRankGraph", function() {
                    var submitted;
                    var form = $(this).find("form"),
                        rankId = $(this)
                            .find("#rankId")
                            .val(),
                        xMin = new Date(
                            $(this)
                                .find("#date")
                                .val()
                                .replace(/-/g, "/")
                        ),
                        xMax = new Date(
                            $(this)
                                .find("#date")
                                .val()
                                .replace(/-/g, "/")
                        );
                    xMin.setDate(xMin.getDate() - 190);
                    xMax.setDate(xMax.getDate() + 5);
                    var options = {
                        cache: false,
                        type: "POST",
                        url: form.attr("action"),
                        dataType: "json",
                        data: form.serialize(),
                        success: function(data) {
                            submitted = {
                                action: "",
                                graphType: "line",
                                type: "json",
                                highlight: false,
                                zoom: false,
                                showPoints: true,
                                xType: "date",
                                xLabel: "",
                                yTicks: [60, 50, 40, 30, 20, 10, 1, ""],
                                setXMin: xMin.toDateString(),
                                setXMax: xMax.toDateString(),
                                graphData: data,
                                invert: "true",
                                showTicks: true,
                                xLabelAngle: 0,
                                xFormat: "%b %#d",
                                yType: "Number",
                                yLabel: "       ",
                                yFormat: "%d",
                                location: rankId,
                                borderColor: "#fff"
                            };
                        },
                        error: function(data, status, err) {
                            var msg =
                                "Error getting: " +
                                form.attr("action") +
                                "\n\n";
                            msg += "Status: " + status + "\n\n";
                            console.log(msg);
                        },
                        complete: function() {
                            if ($("#" + rankId).length > 0) {
                                console.log("tooltip open for " + rankId);
                                $("#" + rankId).html("");
                                skywordGraphs.createLineGraph(submitted);
                            } else {
                                console.log("tooltip closed" + rankId);
                            }
                        }
                    };
                    setTimeout(function() {
                        call.ajax(form.attr("action"), options);
                        $(".bt-wrapper").each(function() {
                            if ($(this).find("#" + rankId).length != 1) {
                                console.log("removing");
                                $(this).remove();
                            }
                        });
                    }, 200);

                    return false;
                });
            },
            drawBarGraph: function(typeToShow) {
                drawBarGraph(typeToShow);
            },
            createReferralLine: function() {
                var totalHeight = $("#referrer-data-table").height();
                $("body").css("min-height", totalHeight);
                var currentTab = $("#all");
                drawBarGraph("all");

                $("#find-button").click(function() {
                    var theForm = document.forms["writerReportArticle"],
                        input = document.createElement("input");
                    input.type = "hidden";
                    input.name = "phrase";
                    input.value = $("#phrase").val();
                    theForm.appendChild(input);
                    theForm.action = theForm.action + "#searchField";
                    theForm.processReport.value = true;
                    theForm.submit();
                });
            },
            createReferralPie: function() {
                var r = {
                        total: []
                    },
                    parameters = {
                        graphType: "pie",
                        location: "referrers-pie-graph",
                        type: "pie",
                        enableTooltip: false,
                        popupFormat:
                            '<span class="value">{y}</span><span> Views </span>'
                    };
                $("td.referrer").each(function() {
                    var title = $(this),
                        data = title.siblings("td.data").text(),
                        value = title.data("views"),
                        totalValue = 0;
                    if ("string" === typeof value) {
                        value = value.replace(/,/g, "");
                    }
                    totalValue =
                        undefined === value || null === value || "" === value
                            ? 0
                            : parseInt(value, 10);
                    r.total.push({
                        label: title.text(),
                        color: title.find("span.key").css("background-color"),
                        y: totalValue,
                        percentage: data
                    });
                });
                //console.log(r);
                parameters.graphData = r.total;
                skywordGraphs.createChannelPieChart(parameters);
            },
            articleViewsOverlayCallback: function() {
                var parameters = {
                    graphType: "area",
                    action: $("#index-graph").attr("data-action"),
                    location: "chart-daily-overlay",
                    xType: "date",
                    xLabel: "",
                    xLabelAngle: -0,
                    xFormat: "%b %#d, %Y",
                    yType: "Number",
                    yLabel: "   ",
                    yFormat: "%d",
                    popupFormat:
                        '<span class="value">{y}</span><span> views</span>'
                };
                skywordGraphs.getGraph(parameters);
            },
            showHideRows: function(id) {
                showHideRows(id);
            },
            rollupTimes: function() {
                $(".view-date-times").each(function() {
                    var text = $(this).text();

                    if (!text.includes(",")) {
                        return;
                    }

                    var arrayOfTimes = text.split(",");

                    var html = '<div class="time">' + arrayOfTimes[0];
                    arrayOfTimes.shift();

                    var remaining = arrayOfTimes.length;

                    html += " <a>(+" + remaining + " more)</a>";

                    html +=
                        '<div class="extra-times" data-remaining="' +
                        remaining +
                        '" style="display: none;">';
                    arrayOfTimes.forEach(function(time) {
                        html += time + "<br/>";
                    });

                    html += "</div></div>";

                    $(this).html(html);
                });
            },
            performanceCardWidth: function() {
                var performanceSection = $("#performance-results");
                var numOfCards = performanceSection
                    .find(".countContainer")
                    .size();

                if (numOfCards === 6) {
                    performanceSection.addClass("column6");
                }

                if (numOfCards === 7) {
                    performanceSection.addClass("column7");
                }
            },
            datepicker: function($scope) {
                //needed for new moment-datepicker
                $scope.dates = {
                    startDate: null,
                    endDate: null
                };

                //watches start date to make sure end date is not before start date
                $scope.$watch("[dates.startDate, dates.endDate]", function(
                    newValue,
                    oldValue
                ) {
                    $scope.momentDateFormat = $("#momentPickerFormat").val();
                    $scope.dateformat = "MM-DD-YYYY";

                    if ($scope.dates.startDate != null) {
                        $scope.minDate = $scope.dates.startDate.toString();
                    }
                    if ($scope.dates.endDate != null) {
                        $scope.maxDate = $scope.dates.endDate.toString();
                    }

                    if (
                        $scope.dates.startDate == null &&
                        $scope.dates.endDate == null
                    ) {
                        var startDateVal = $("#startdate").val();
                        var endDateVal = $("#enddate").val();
                        $scope.dates.startDate = moment(
                            startDateVal,
                            $scope.dateformat
                        ).format($scope.momentDateFormat);
                        $scope.dates.endDate = moment(
                            endDateVal,
                            $scope.dateformat
                        ).format($scope.momentDateFormat);
                    }

                    if (
                        $scope.dates.startDate != null &&
                        $scope.dates.endDate != null
                    ) {
                        $scope.startDateMoment = moment(
                            $scope.dates.startDate,
                            $scope.momentDateFormat
                        ).format($scope.dateformat);
                        $scope.endDateMoment = moment(
                            $scope.dates.endDate,
                            $scope.momentDateFormat
                        ).format($scope.dateformat);
                        $("#startdate").val($scope.startDateMoment);
                        $("#enddate").val($scope.endDateMoment);
                    }
                });
            }
        };
    }
]);

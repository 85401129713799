app.service("contributorList", [
    "$rootScope",
    "$timeout",
    "$compile",
    "$translate",
    "skyword",
    "call",
    "setup",
    function($rootScope, $timeout, $compile, $translate, skyword, call, setup) {
        var sortBy = function(contributorList, id, listType) {
                let data;
                let html = ``;
                let pageSize = 20;
                let pageNo = 1;
                let endIndex =
                    pageNo * Math.min(contributorList.length, pageSize);
                goToPage(contributorList, 1, listType);
                switch (id) {
                    case "title":
                        $("#desc").removeClass("sorted order1 order2");
                        $("#accessLevel").removeClass("sorted order1 order2");
                        $("#contributors").removeClass("sorted order1 order2");
                        if (
                            $("#title").hasClass("order1") ||
                            $("#title").hasClass("order2")
                        )
                            $("#title").toggleClass("order1 order2");
                        else $("#title").addClass("sorted order1");
                        if ($("#title").hasClass("order1")) {
                            contributorList = contributorList.sort(
                                (a, b) => (a.title > b.title ? 1 : -1)
                            );
                        } else {
                            contributorList = contributorList.sort(
                                (a, b) => (a.title > b.title ? -1 : 1)
                            );
                        }
                        // Sort By Title
                        data = contributorList;

                        break;
                    case "desc":
                        $("#title").removeClass("sorted order1 order2");
                        $("#accessLevel").removeClass("sorted order1 order2");
                        $("#contributors").removeClass("sorted order1 order2");
                        if (
                            $("#desc").hasClass("order1") ||
                            $("#desc").hasClass("order2")
                        )
                            $("#desc").toggleClass("order1 order2");
                        else $("#desc").addClass("sorted order1");
                        if ($("#desc").hasClass("order1")) {
                            contributorList = contributorList.sort(
                                (a, b) =>
                                    a.description > b.description ? 1 : -1
                            );
                        } else {
                            contributorList = contributorList.sort(
                                (a, b) =>
                                    a.description > b.description ? -1 : 1
                            );
                        }
                        data = contributorList;

                        break;
                    case "accessLevel":
                        $("#title").removeClass("sorted order1 order2");
                        $("#desc").removeClass("sorted order1 order2");
                        $("#contributors").removeClass("sorted order1 order2");
                        if (
                            $("#accessLevel").hasClass("order1") ||
                            $("#accessLevel").hasClass("order2")
                        )
                            $("#accessLevel").toggleClass("order1 order2");
                        else $("#accessLevel").addClass("sorted order1");
                        if ($("#accessLevel").hasClass("order1")) {
                            contributorList = contributorList.sort(
                                (a, b) =>
                                    a.accessLevel > b.accessLevel ? 1 : -1
                            );
                        } else {
                            contributorList = contributorList.sort(
                                (a, b) =>
                                    a.accessLevel > b.accessLevel ? -1 : 1
                            );
                        }
                        data = contributorList;

                        break;
                    case "contributors":
                        $("#title").removeClass("sorted order1 order2");
                        $("#desc").removeClass("sorted order1 order2");
                        $("#accessLevel").removeClass("sorted order1 order2");
                        if (
                            $("#contributors").hasClass("order1") ||
                            $("#contributors").hasClass("order2")
                        )
                            $("#contributors").toggleClass("order1 order2");
                        else $("#contributors").addClass("sorted order1");
                        if ($("#contributors").hasClass("order1")) {
                            contributorList = contributorList.sort(
                                (a, b) => a.contributors - b.contributors
                            );
                        } else {
                            contributorList = contributorList.sort(
                                (a, b) => b.contributors - a.contributors
                            );
                        }
                        data = contributorList;

                        break;
                    default:
                        $("#desc").removeClass("sorted order1 order2");
                        $("#accessLevel").removeClass("sorted order1 order2");
                        $("#contributors").removeClass("sorted order1 order2");
                        if (
                            $("#title").hasClass("order1") ||
                            $("#title").hasClass("order2")
                        )
                            $("#title").toggleClass("order1 order2");
                        else $("#title").addClass("sorted order1");
                        if ($("#title").hasClass("order1")) {
                            contributorList = contributorList.sort(
                                (a, b) => (a.title > b.title ? 1 : -1)
                            );
                        } else {
                            contributorList = contributorList.sort(
                                (a, b) => (a.title > b.title ? -1 : 1)
                            );
                        }
                        data = contributorList;

                        break;
                }
                for (var i = 0; i < endIndex; i++) {
                    html += `<tr class=${i % 2 === 0 ? "odd" : "even"}>
                    <td>${data[i].title}</td>
                    <td>${data[i].description}</td>
                    ${listType != 2 ? `<td>${data[i].accessLevel}</td>` : ``}
                    <td>${data[i].contributors}</td>
                    <td><a class="viewList" data-id="${
                        data[i].id
                    }" data-listtype="${listType}" data-pgNo="${pageNo}"><span class="icon-View"></span> ${$translate.instant(
                        "sw.label.currentview"
                    )}</a></td>
                    </tr>`;
                }
                $("#contributorListBody").html(html);
            },
            goToPage = function(contributorList, pageNo, listType) {
                let pageSize = 20;
                let totalPages = Math.ceil(
                    parseInt(contributorList.length) / pageSize
                );
                let pagination = ``;
                for (var i = 0; i < totalPages; i++) {
                    if (i + 1 === pageNo) {
                        pagination += `<strong class="active">${i +
                            1}</strong>`;
                    } else {
                        pagination += `<a class="goToPage" title="Go to page ${i +
                            1}">${i + 1}</a> `;
                    }
                }
                var html = ``;
                var startIndex = (pageNo - 1) * pageSize;
                var endIndex =
                    pageNo * Math.min(contributorList.length, pageSize);
                endIndex = Math.min(contributorList.length, endIndex);
                for (var i = startIndex; i < endIndex; i++) {
                    html += `<tr class=${i % 2 === 0 ? "odd" : "even"}>
                            <td>${contributorList[i].title}</td>
                            <td>${contributorList[i].description}</td>
                            ${
                                listType != 2
                                    ? `<td>${
                                          contributorList[i].accessLevel
                                      }</td>`
                                    : ``
                            }
                            <td>${contributorList[i].contributors}</td>
                            <td><a class="viewList" data-id="${
                                contributorList[i].id
                            }" data-listtype="${listType}" data-pgNo="${pageNo}"><span class="icon-View"></span> View</a></td>
                            </tr>`;
                }
                $("#contributorListBody").html(html);
                $("#pagination-top").html(pagination);
                $("#pagination-bottom").html(pagination);
                if (pageNo !== 1) {
                    $(".previous").removeClass("disabled");
                } else {
                    $(".previous").addClass("disabled");
                }
                if (pageNo === totalPages) {
                    $(".next").addClass("disabled");
                } else {
                    $(".next").removeClass("disabled");
                }
            },
            createDisplayTable = function(contributorList, listType) {
                contributorList = contributorList.sort(
                    (a, b) => (a.title > b.title ? 1 : -1)
                );
                let title = $translate.instant("sw.label.title"),
                    desc = $translate.instant("sw.label.description"),
                    accesslevel = $translate.instant("sw.label.accesslevel"),
                    contributors = $translate.instant(
                        "nucleus.nav.contributors"
                    ),
                    tableHead;
                tableHead = `<thead>
                                <tr>
                                <th class="sortable sorted order1" id="title"><a>${title}</a></th>
                                <th class="sortable" id="desc"><a>${desc}</a></th>
                                ${
                                    listType != 2
                                        ? `<th class="sortable" id="accessLevel"><a>${accesslevel}</a></th>`
                                        : ``
                                }
                                <th class="sortable" id="contributors"><a>${contributors}</a></th>
                                <th></th>
                                </tr></thead>`;

                let pageSize = 20;
                let totalPages = Math.ceil(
                    parseInt(contributorList.length) / pageSize
                );
                let pagination = `<strong class="active">1</strong>​`;
                for (var i = 1; i < totalPages; i++) {
                    pagination += `<a class="goToPage" title="Go to page ${i +
                        1}">${i + 1}</a> `;
                }
                if (contributorList.length === 0) {
                    let noData = $translate.instant("nucleus.panel.nodata");
                    $("#loader").addClass("hidden");
                    let html = `<div class="nothingToDisplay">
                            <p><span class="icon-NavAssignments"></span></p>
                            <p>${noData}</p>
                        </div>`;
                    $("#contributorListTable").html(html);
                    $("#contributorListTable").removeClass("hidden");
                } else {
                    let html = `
                <span class="pagebanner italic">${
                    contributorList.length > 1 ? contributorList.length : "One"
                } Items Found</span>
                <span class="paginationNav"><a class="paginationControl disabled previous"></a>
                <span id="pagination-top">${pagination}</span>
                <a class="paginationControl next ${
                    totalPages <= 1 ? "disabled" : ""
                }"></a></span>
                <table class="displayTag" id="contributorListInfo">
                ${tableHead}
                <tbody id="contributorListBody">`;
                    for (
                        var i = 0;
                        i < Math.min(contributorList.length, pageSize);
                        i++
                    ) {
                        html += `<tr class=${i % 2 === 0 ? "odd" : "even"}>
                    <td>${contributorList[i].title}</td>
                    <td>${contributorList[i].description}</td>
                    ${
                        listType != 2
                            ? `<td>${contributorList[i].accessLevel}</td>`
                            : ``
                    }
                    <td>${contributorList[i].contributors}</td>
                    <td><a class="viewList" data-id="${
                        contributorList[i].id
                    }" data-listtype="${listType}" data-pgNo="${1}"><span class="icon-View"></span> View</a></td>
                    </tr>`;
                    }
                    html += `</tbody></table>
                <span class="pagebanner italic">${
                    contributorList.length > 1 ? contributorList.length : "One"
                } Items Found</span>
                <span class="paginationNav"><a class="paginationControl disabled previous"></a>
                <span id="pagination-bottom">${pagination}</span>
                <a class="paginationControl next ${
                    totalPages <= 1 ? "disabled" : ""
                }"></a></span>`;
                    $("#contributorListTable").html(html);
                }
            };
        return {
            sortBy: function(contributorList, id, listType) {
                sortBy(contributorList, id, listType);
            },
            goToPage: function(contributorList, pageNo, listType) {
                goToPage(contributorList, pageNo, listType);
            },
            createDisplayTable: function(contributorList, listType) {
                createDisplayTable(contributorList, listType);
            },
            init: function() {
                let contributorList;
                let listType;
                let listPgNo = window.sessionStorage.getItem("listPgNo");
                let listtype = window.sessionStorage.getItem("listType");
                const token = skyword.getToken("access_token");
                const programId = $("body").attr("data-programid");

                const agencyId = $("body").attr("data-enterpriseid");
                const agencyName = $("body").attr("data-enterprisename");
                const getAgencyNameURL = skyword.getAPIURL(
                    `contributors/agency/${agencyId}`
                );
                $("#loader").removeClass("hidden");
                $("#contributorListTable").addClass("hidden");
                $translate("sw.contributorsearch.lists").then(function(val) {
                    let selectEnterpriseText = agencyName + " " + val;
                    $("#listType")
                        .find('option[value="1"]')
                        .text(selectEnterpriseText);
                    $("#listType")
                        .selectBoxIt()
                        .data("selectBox-selectBoxIt")
                        .refresh();
                    if (listPgNo !== null && listtype !== null) {
                        $("#loader").removeClass("hidden");
                        $("#contributorListTable").addClass("hidden");

                        listType = listtype;
                        $("#listType")
                            .selectBoxIt()
                            .data("selectBoxIt")
                            .selectOption(listType);
                        $.ajax({
                            cache: false,
                            type: "GET",
                            url: skyword.getAPIURL(
                                `contributors/agency/${agencyId}/program/${programId}/listType/${listtype}`
                            ),
                            beforeSend: function(xhr) {
                                xhr.setRequestHeader(
                                    "Authorization",
                                    `Bearer ${token}`
                                );
                            },
                            success: function(data) {
                                contributorList = data;
                                createDisplayTable(
                                    contributorList,
                                    parseInt(listType)
                                );
                                goToPage(
                                    contributorList,
                                    parseInt(listPgNo),
                                    parseInt(listType)
                                );
                                $("#loader").addClass("hidden");
                                $("#contributorListTable").removeClass(
                                    "hidden"
                                );
                                window.sessionStorage.removeItem("listPgNo");
                                window.sessionStorage.removeItem("listType");
                            }
                        });
                    } else {
                        $.ajax({
                            cache: false,
                            type: "GET",
                            url: skyword.getAPIURL(
                                `contributors/agency/${agencyId}/program/${programId}/listType/1`
                            ),
                            beforeSend: function(xhr) {
                                xhr.setRequestHeader(
                                    "Authorization",
                                    `Bearer ${token}`
                                );
                            },
                            success: function(data) {
                                contributorList = data;
                                listType = 1;
                                createDisplayTable(contributorList, 1);
                                $("#loader").addClass("hidden");
                                $("#contributorListTable").removeClass(
                                    "hidden"
                                );
                            }
                        });
                    }
                });

                $(function() {
                    var body = $("body");
                    body.on("click", "#title", function() {
                        sortBy(contributorList, "title", listType);
                    });
                    body.on("click", "#desc", function() {
                        sortBy(contributorList, "desc", listType);
                    });
                    body.on("click", "#accessLevel", function() {
                        // Sort By Access Level
                        sortBy(contributorList, "accessLevel", listType);
                    });
                    body.on("click", "#contributors", function() {
                        // Sort By Access Level
                        sortBy(contributorList, "contributors", listType);
                    });
                    body.on("click", ".goToPage", function(e) {
                        goToPage(
                            contributorList,
                            parseInt(e.target.innerText),
                            listType
                        );
                    });
                    body.on("click", ".previous", function() {
                        let activePage = parseInt(
                            document.getElementsByClassName("active")[0]
                                .innerText
                        );
                        goToPage(contributorList, activePage - 1, listType);
                    });
                    body.on("click", ".next", function() {
                        let activePage = parseInt(
                            document.getElementsByClassName("active")[0]
                                .innerText
                        );
                        goToPage(contributorList, activePage + 1, listType);
                    });
                    body.on("click", ".viewList", function() {
                        let viewBtn = $(this);
                        let { id, listtype, pgno } = viewBtn[0].dataset;
                        window.sessionStorage.setItem("listType", listtype);
                        window.sessionStorage.setItem("listPgNo", pgno);
                        window.location.href =
                            "contributorListView?listId=" + id;
                    });
                    $("#listType")
                        .selectBoxIt()
                        .change(function() {
                            let value = this.value;
                            listType = value;
                            const token = skyword.getToken("access_token");
                            const programId = $("body").attr("data-programid");
                            const agencyId = $("body").attr(
                                "data-enterpriseid"
                            );
                            $("#loader").removeClass("hidden");
                            $("#contributorListTable").addClass("hidden");
                            $.ajax({
                                cache: false,
                                type: "GET",
                                url: skyword.getAPIURL(
                                    `contributors/agency/${agencyId}/program/${programId}/listType/${value}`
                                ),
                                beforeSend: function(xhr) {
                                    xhr.setRequestHeader(
                                        "Authorization",
                                        `Bearer ${token}`
                                    );
                                },
                                success: function(data) {
                                    contributorList = data;
                                    createDisplayTable(contributorList, value);
                                    $("#loader").addClass("hidden");
                                    $("#contributorListTable").removeClass(
                                        "hidden"
                                    );
                                }
                            });
                        });
                });
            }
        };
    }
]);

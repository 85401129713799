(function() {
    app.controller("discussions", [
        "$scope",
        "discussions",
        "skyword",
        "SkyFactory",
        "$translate",
        function($scope, discussions, skyword, SkyFactory, $translate) {
            $scope.mode = null;
            $scope.discussionId = null;
            $scope.discussions = [];

            $scope.discussionText = "";

            var defaultManagerText = $translate.instant(
                "angular.discussion.placeholder.managerMsg"
            );
            var defaultContributorText = $translate.instant(
                "angular.discussion.placeholder.contributorMsg"
            );
            $scope.defaultCommentText = "";

            if ($scope.accountTypeId != 1) {
                $scope.defaultCommentText = defaultManagerText;
            } else {
                $scope.defaultCommentText = defaultContributorText;
            }

            // Front-end Operations
            $scope.addDiscussion = function() {
                $scope.mode = "adding.discussion";
                $scope.newDiscussion.$setPristine();
            };
            $scope.addDiscussionCancel = function() {
                $scope.mode = null;
                $scope.discussionId = null;
            };
            $scope.addReply = function(id) {
                $scope.mode = "adding.reply";
                $scope.discussionId = id;
                $('[value="' + id + '"]')
                    .parent("[discussion]")
                    .find('[name="enteredText"]')
                    .val("");
            };
            $scope.addReplyCancel = function() {
                $scope.mode = null;
                $scope.discussionId = null;
            };
            $scope.removeDiscussion = function(id) {
                $scope.mode = "removing.discussion";
                $scope.discussionId = id;
            };
            $scope.removeDiscussionCancel = function() {
                $scope.mode = null;
                $scope.discussionId = null;
            };

            var crid =
                0 === $("#contentRequestId").length
                    ? jQuery("#attrs-id").data("contentrequestid")
                    : $("#contentRequestId").val();

            // Backend Operations
            $scope.get = function() {
                discussions.get(
                    {
                        contentRequestId: crid
                    },
                    function(data) {
                        // Loop through JSON
                        for (var i = 0; i < data.length; i++) {
                            // Loop through each discussion
                            for (var j = 0; j < data[i].length; j++) {
                                var item = data[i][j];
                                // If it's a reply, add it to topic.replies
                                if (
                                    item.parentDiscussionId &&
                                    item.parentDiscussionId > -1
                                ) {
                                    for (
                                        var k = 0;
                                        k < $scope.discussions.length;
                                        k++
                                    ) {
                                        if (
                                            $scope.discussions[k].id ==
                                            item.parentDiscussionId
                                        ) {
                                            // If replies array doesn't already exist,
                                            // create it
                                            $scope.discussions[k].replies =
                                                $scope.discussions[k].replies ||
                                                [];
                                            $scope.discussions[k].replies.push(
                                                item
                                            ); //
                                            $scope.discussions[
                                                k
                                            ].replies.dateCreated =
                                                item.dateCreated;
                                        }
                                    }
                                    // If it's a topic, add it to $scope.discussions
                                } else {
                                    item.collapsed = true;
                                    $scope.discussions.push(item);
                                }
                            }
                        }
                        // Expand the first discussion
                        if ($scope.discussions.length > 0) {
                            $scope.discussions[0].collapsed = false;
                        }
                    }
                );
            };

            /**
             * Add a new discussion
             */
            $scope.createDiscussion = function() {
                discussions.createDiscussion(
                    {
                        contentRequestId: crid,
                        parentDiscussionId: null,
                        enteredText: $scope.discussionText,
                        discussionMessage: $scope.discussionMessage
                    },
                    function(data) {
                        $scope.mode = null;
                        $scope.discussionId = null;
                        // add children if any in replies
                        if (data.childDiscussion != "undefined") {
                            data.replies = [data.childDiscussion];
                        }
                        $scope.discussions.unshift(data);
                        $scope.discussionText = null;
                        $scope.discussionMessage = "";
                    }
                );
            };

            /**
             * Get number of replies on a discussion, deal with the case that the first
             * message is not from the user who created the discussion, for
             * compatibility with the previous version - https://skyword.atlassian.net/browse/SKY-14424?filter=-1
             */
            $scope.discussionGetNumberOfReplies = function(discussion) {
                if (
                    !SkyFactory.isUndefinedOrNull(discussion) &&
                    !SkyFactory.isUndefinedOrNull(discussion.replies) &&
                    discussion.replies.length > 0
                ) {
                    if (
                        discussion.accountId == discussion.replies[0].accountId
                    ) {
                        return discussion.replies.length - 1;
                    }
                    return discussion.replies.length;
                }
                return 0;
            };

            $scope.createReply = function(id) {
                // loading flag on
                $scope.isLoadingAddingDiscussion = true;
                discussions.createDiscussion(
                    {
                        contentRequestId: crid,
                        parentDiscussionId: id,
                        enteredText: $('[value="' + id + '"]')
                            .parent("[discussion]")
                            .find('[name="enteredText"]')
                            .val()
                    },
                    function(data) {
                        // loading flag off
                        $scope.isLoadingAddingDiscussion = false;
                        $scope.mode = null;
                        $scope.discussionId = null;
                        for (var i = 0; i < $scope.discussions.length; i++) {
                            if (
                                $scope.discussions[i].id ==
                                data.parentDiscussionId
                            ) {
                                $scope.discussions[i].replies =
                                    $scope.discussions[i].replies || [];
                                $scope.discussions[i].replies.push(data);
                                $scope.discussions[i].replies.dateCreated =
                                    data.dateCreated;
                            }
                        }
                    }
                );
            };
            $scope.deleteDiscussion = function(id) {
                discussions.deleteDiscussion(
                    {
                        id: id
                    },
                    function(data) {
                        $scope.mode = null;
                        $scope.discussionId = null;
                        $scope.discussions = $scope.discussions.filter(function(
                            discussion
                        ) {
                            return discussion.id != id;
                        });
                    }
                );
            };
            $scope.get();
        }
    ]);
})();

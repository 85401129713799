app.service("validation", [
    "skyword",
    function(skyword) {
        return {
            // TODO: Get rid of Multiple return points - they just create confusion.
            parse: function(text, id) {
                var $this = this;
                function hasChannelMenu() {
                    return $(".channel-menu").length > 0;
                }
                try {
                    var json,
                        previousError = "";

                    if ("object" === typeof text) {
                        json = text;
                    } else {
                        text = $this.decode(text);
                        json = $.parseJSON(text);
                    }
                    $(".errorField").removeClass("errorField");
                    if (json.type != null) {
                        id = json.type;
                    }
                    if (id != null && id != "") {
                        $("#" + id + " .content").html("");
                        $("#" + id + "-error-box .content").html("");
                        $("#" + id + "-success-box .content").html("");
                    } else {
                        $("#error-box .content").html("");
                        $("#success-box .content").html("");
                    }

                    if (json.reloadAjax == "true") {
                        skyword.reloadLastAjaxCall();
                    }
                    if (
                        json.redirectionTarget != null &&
                        json.redirectionTarget != ""
                    ) {
                        window.location.href = "/" + json.redirectionTarget;
                    }
                    if (json.closeWindow == "true") {
                        jQuery.colorbox.close();
                    }

                    if (json.message != null) {
                        //Generic message. Used on assignmentView.action, we really need improve this message thing
                        if (id != null) {
                            $("#" + id + "-message-box .content").append(
                                json.message
                            );
                            $("#" + id + "-message-box").show();
                        } else {
                            $("#message-box .content").append(json.message);
                            $("#message-box").show();
                            skyword.enableButtons();
                        }
                    } else if (
                        undefined !== json.success &&
                        null !== json.success &&
                        (json.success.message !== null || true === json.success)
                    ) {
                        if (id != null) {
                            $("#" + id + "-success-box .content").append(
                                "<li>" +
                                    $("<span/>")
                                        .html(json.success.message)
                                        .text() +
                                    "</li>"
                            );
                            $("#" + id + "-success-box").show();
                            if (hasChannelMenu) {
                                skyword.scrollTo(
                                    "#" + id + "-success-box",
                                    100
                                );
                            } else {
                                skyword.scrollTo("#" + id + "-success-box", 75);
                            }
                        } else {
                            $("#success-box .content").append(
                                "<li>" +
                                    $("<span/>")
                                        .html(json.success.message)
                                        .text() +
                                    "</li>"
                            );
                            $("#success-box").show();
                            if (hasChannelMenu) {
                                skyword.scrollTo("#success-box", 100);
                            } else {
                                skyword.scrollTo("#success-box", 75);
                            }
                        }
                        skyword.enableButtons();
                    } else {
                        if (
                            undefined !== json.error &&
                            null !== json.error &&
                            json.error instanceof Array
                        ) {
                            for (var i = 0; i < json.error.length; i++) {
                                try {
                                    if (
                                        json.error[i].message != previousError
                                    ) {
                                        if (id != null) {
                                            $(
                                                "#" + id + "-error-box .content"
                                            ).append(
                                                "<li>" +
                                                    $("<span/>")
                                                        .html(
                                                            json.error[i]
                                                                .message
                                                        )
                                                        .text() +
                                                    "</li>"
                                            );
                                        } else {
                                            $("#error-box .content").append(
                                                "<li>" +
                                                    $("<span/>")
                                                        .html(
                                                            json.error[i]
                                                                .message
                                                        )
                                                        .text() +
                                                    "</li>"
                                            );
                                        }
                                    }

                                    if (
                                        0 <
                                        json.error[i].field.indexOf(
                                            "taxonomyValueId"
                                        )
                                    ) {
                                        var select = jQuery(
                                            'select[name="' +
                                                json.error[i].field +
                                                '"]'
                                        );
                                        var container = select
                                            .siblings(".ms-container")
                                            .find(">div");
                                        var span = select.siblings(
                                            ".selectboxit-container"
                                        );
                                        container.addClass("errorField");
                                        if (0 < span.length) {
                                            span.closest(
                                                ".form-builder"
                                            ).addClass("errorField");
                                        }
                                    } else {
                                        if (
                                            0 <
                                            $("#" + json.error[i].field).length
                                        ) {
                                            $(
                                                "#" + json.error[i].field
                                            ).addClass("errorField");
                                        } else {
                                            var el = document.getElementById(
                                                json.error[i].field
                                            );
                                            $(el).addClass("errorField");
                                        }
                                    }

                                    previousError = json.error[i].message;
                                    skyword.enableButtons();
                                } catch (e) {
                                    console.error(e);
                                }
                            }
                            if (id != null) {
                                $("#" + id + "-error-box").fadeIn();
                                if (hasChannelMenu) {
                                    skyword.scrollTo(
                                        "#" + id + "-error-box",
                                        100
                                    );
                                } else {
                                    skyword.scrollTo(
                                        "#" + id + "-error-box",
                                        75
                                    );
                                }
                            } else {
                                $("#error-box").fadeIn();
                                if (hasChannelMenu) {
                                    skyword.scrollTo("#error-box", 100);
                                } else {
                                    skyword.scrollTo("#error-box", 75);
                                }
                            }
                        }
                    }
                    return true;
                } catch (e) {
                    // console.log("Ajax request returned html " + e);
                    return false;
                }
            },
            decode: function(s) {
                for (
                    var a,
                        b,
                        i = -1,
                        l = (s = s.split("")).length,
                        o = String.fromCharCode,
                        c = "charCodeAt";
                    ++i < l;
                    (a = s[i][c](0)) & 0x80 &&
                    ((s[i] =
                        (a & 0xfc) == 0xc0 &&
                        ((b = s[i + 1][c](0)) & 0xc0) == 0x80
                            ? o(((a & 0x03) << 6) + (b & 0x3f))
                            : o(128)),
                    (s[++i] = ""))
                ) {}
                return s.join("");
            },
            enableButton: function(buttonId) {
                $("#" + buttonId)
                    .removeAttr("disabled")
                    .removeClass("disabled");
            },
            disableButton: function(buttonId) {
                $("#" + buttonId)
                    .attr("disabled", "disabled")
                    .addClass("disabled");
            }
        };
    }
]);

//externalCalendar
app.controller(
    "externalCalendar",

    [
        "$scope",
        "CalendarFactory",
        "$location",
        "setup",
        "$compile",
        "call",
        function($scope, CalendarFactory, $location, setup, $compile, call) {
            /*
//////// mshaheen 10.26.2015
//////// trying to force a reload if IE11 and querystring param present then remove the qstring param to avoid infinite loop
//////// will only work IF struts-calendar.xml is tacking on the qstring param which it gets from CalendarAction.java
//////// The line in struts-calendar.xml was in the success node of externalCalendarSettings node was /externalCalendar.action?guid=${guid}&amp;r=${rnd} (added the &amp;r=${rnd})
        var qs = (function(a) {
            if (a == "") return {};
            var b = {};
            for (var i = 0; i < a.length; ++i)
            {
                var p=a[i].split('=', 2);
                if (p.length == 1)
                    b[p[0]] = "";
                else
                    b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
            }
            return b;
        })(window.location.search.substr(1).split('&'));
        if (!(window.ActiveXObject) && "ActiveXObject" in window) {
            if (qs['r']) {
                var alteredURL = removeParam( "r" , window.location + '' );
                //window.location = alteredURL;
            }
        }

        function removeParam(key, sourceURL) {
            var rtn = sourceURL.split("?")[0],
                param,
                params_arr = [],
                queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
            if (queryString !== "") {
                params_arr = queryString.split("&");
                for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                    param = params_arr[i].split("=")[0];
                    if (param === key) {
                        params_arr.splice(i, 1);
                    }
                }
                rtn = rtn + "?" + params_arr.join("&");
            }
            return rtn;
        }
*/
            $scope.guid = angular.element("#calendar-guid").val();
            $scope.settingsUrl =
                "externalCalendarSettings.action?guid=" + $scope.guid;

            $scope.callSettingsOverlay = function() {
                //console.log("opening settings overlay");
                $window.location.href =
                    "externalCalendarSettings.action?guid=" + $scope.guid;
            };

            $scope.iCalURL = function() {
                var defaultPorts = { "http:": 80, "https:": 443 };
                var root =
                    window.location.protocol +
                    "//" +
                    window.location.hostname +
                    (window.location.port &&
                    window.location.port !=
                        defaultPorts[window.location.protocol]
                        ? ":" + window.location.port
                        : "");
                $scope.iCalHref = root + "/iCal.action?guid=" + $scope.guid;
            };
            $scope.iCalURL();

            $scope.filterDTO = {
                header: {
                    showContentTypes: false,
                    showCategories: false
                },
                programId: null,
                displayProjects: {
                    selected: true
                },
                displayEvents: {
                    selected: true
                },
                projects: [],
                noProgramProjects: false,
                stateGroups: [],
                contentTypes: [],
                contentTypesCategories: [],
                categories: [],
                projectIds: [],
                categoryIds: [],
                contentTypeIds: [],
                stateGroupIds: [],
                colors: [],
                contentTypeColorKeys: [],
                projectColorKeys: [],
                categoryColorKeys: []
            };

            var unbindWatcher = $scope.$watch("guid", function() {
                //console.log("watcher...." + $scope.guid);
                CalendarFactory.createCalendar($scope.guid);

                var settingsBtn = $compile(
                    "<a ng-href='{{settingsUrl}}' class='calendarButtonCustomizeOff'><span class='icon-Preferences'></span><span class='smIconCenter'>{{'angular.button.settings' | translate}}</span></a>"
                )($scope);
                angular
                    .element(document.getElementsByClassName("fc-header-left"))
                    .prepend(settingsBtn);
                unbindWatcher();
            });
        }
    ]
);

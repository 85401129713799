app.controller("individualVideoReport", [
    "$scope",
    "kalturaService",
    "dailyVideoSummaryService",
    function($scope, kalturaService, dailyVideoSummaryService) {
        kalturaService.addPlayer($("#kaltura-id").val(), "video-player");

        dailyVideoSummaryService.getListVideoPerformanceForContent(
            contentId,
            function(data) {
                $scope.listDailyVideoPerformance = data;
            }
        );
    }
]);

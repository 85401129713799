(function() {
    app.factory("contributorJoin", [
        "$http",
        function($http) {
            var controllerDirectory = "/API/contributor-join/";

            return {
                // page 1

                // page 2
                // validate username and captcha
                validateUsername: validateUsername,
                // get ContributorAgreement
                getContributorAgreement: getContributorAgreement,
                // create account - email, password, creativeTypes
                createAccount: createAccount,

                // page 3
                getCountries: getCountries,
                // upload profile photo; initial
                uploadProfilePicture: uploadProfilePicture,
                saveProfilePicture: saveProfilePicture,
                // save function for continuing to page 4
                aboutYou: aboutYou,
                // zip code JS

                // page 4
                getSocialTypes: getSocialTypes,
                getProfessionalTypes: getProfessionalTypes,
                socialNetworks: socialNetworks
            };

            function getContributorAgreement() {
                return $http.get(controllerDirectory + "contributor-agreement");
            }

            function getCountries() {
                return $http.get(controllerDirectory + "countries");
            }

            function getSocialTypes() {
                return $http.get(controllerDirectory + "social-account-types");
            }

            function getProfessionalTypes() {
                return $http.get(
                    controllerDirectory + "professional-account-types"
                );
            }

            function validateUsername(userSignup) {
                return $http.post(
                    controllerDirectory + "validate-username/", {
                        username: userSignup.username,
                        captcha: userSignup.captcha
                    }
                );
            }

            function createAccount(userSignup) {
                return $http.put(controllerDirectory + "create-account", {
                    username: userSignup.username,
                    userPassword: userSignup.userPassword,
                    confirmPassword: userSignup.confirmPassword,
                    captcha: userSignup.captcha,
                    isWriter: userSignup.creativeTypes.writer,
                    isVideographer: userSignup.creativeTypes.videographer,
                    isPhotographer: userSignup.creativeTypes.photographer,
                    isDesigner: userSignup.creativeTypes.designer
                });
            }

            function aboutYou(user) {
                return $http.put(controllerDirectory + "about-you", {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    country: user.country.iso,
                    location: user.location,
                    bio: user.bio,
                    accountId: user.accountId
                });
            }

            function socialNetworks(user) {
                return $http.put(controllerDirectory + "social-networks", {
                    accountId: user.accountId,
                    socialAccounts: user.socialAccounts,
                    professionalAccounts: user.professionalAccounts
                });
            }

            //function that uploads profile picture
            function uploadProfilePicture(file) {
                return $http.post(
                    controllerDirectory + "upload-profile-photo",
                    file,
                    {
                        transformRequest: angular.identity,
                        headers: { "Content-Type": undefined },
                        uploadEventHandlers: {
                            progress: function(e) {
                                console.log("UploadProgress -> " + e);
                                console.log(e);
                            }
                        }
                    }
                );
            }

            //function that saves profile picture
            function saveProfilePicture(file) {
                return $http.post(
                    controllerDirectory + "crop-profile-photo-upload",
                    file,
                    {
                        transformRequest: angular.identity,
                        headers: { "Content-Type": undefined },
                        uploadEventHandlers: {
                            progress: function(e) {
                                console.log("UploadProgress -> " + e);
                                console.log(e);
                            }
                        }
                    }
                );
            }
        }
    ]);
})();

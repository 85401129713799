app.controller("videoCommentingController", [
    "$scope",
    "$translate",
    "$http",
    "$filter",
    "$timeout",
    "skyword",
    "kalturaService",
    "videoCommentService",
    "$routeParams",
    "$sce",
    "$location",
    "AccountFactory",
    function(
        $scope,
        $translate,
        $http,
        $filter,
        $timeout,
        skyword,
        kalturaService,
        videoCommentService,
        $routeParams,
        $sce,
        $location,
        AccountFactory
    ) {
        // set account
        $scope.account = account;
        $scope.account.profilePicture = AccountFactory.profilePictureBlankCheck(
            account
        );

        // This is just work around to hide the top menu bar. Later on implement it from backend and remove this
        if ((window.location.href).indexOf("externalVideoCommenting") > -1 ) {
            var rightMenu = angular.element(document.querySelector(".dashboardLink"));
            rightMenu.css('display', 'none');
            angular.element('#help-button').css('display', 'none');
            angular.element("#user-dropdown").css('display', 'none');
            let external_reviewer = localStorage.getItem("externalReviewer");
            var accountId = parseInt($('#attrs-id').data('accountid'));
            if(accountId === 1){
                if((external_reviewer === null || external_reviewer === '')){
                    checkAndLoadModalExternalReviewerName();
                }
            }
        }
        // initialize value
        $scope.timestamp = 0;

        // set comment to be on right now by default
        $scope.isEntireVideo = "false";

        /**
         * Update the placeholder in the comment box if the user clicks on
         * the radio button
         */
        $scope.$watch("isEntireVideo", function() {
            $scope.placeholerCommentBox();
        });

        // show the list of general comment by default once loading the page
        $scope.isShowGeneralComment = true;

        //this checks for duplicates when printing
        $scope.checkTimeDuplicates = function(videoComment, nextVideoComment) {
            if (nextVideoComment != null && videoComment != 0) {
                if (videoComment.timecode == nextVideoComment.timecode) {
                    return false;
                }
            }
            return true;
        };

        /**
         * Format second
         */
        $scope.formatSecond = function(sec, showOneTenthOfASecond) {
            // do we need on tenth of a second
            var fixed = 1;
            if (
                typeof showOneTenthOfASecond != "undefined" &&
                !showOneTenthOfASecond
            ) {
                fixed = 0;
            }
            var hours = Math.floor(sec / 3600);
            var minutes = Math.floor((sec - hours * 3600) / 60);
            var seconds = sec - hours * 3600 - minutes * 60;

            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            if (seconds < 10) {
                seconds = "0" + seconds;
            }
            // 0 hours
            var secondStringVal =
                (seconds < 10 ? "0" : "") + parseFloat(seconds).toFixed(fixed);
            if (hours * 1 < 10) {
                if (minutes * 1 < 10) {
                    return minutes * 1 + ":" + secondStringVal;
                }
                return minutes + ":" + secondStringVal;
            }
            return hours + ":" + minutes + ":" + secondStringVal;
        };

        /**
         * Kaltura time change update
         */
        window.playerUpdatePlayheadHandler = function(data, id) {
            $scope.$apply(function() {
                $scope.timestamp = Math.floor(data * 10) / 10;
                // update placeholder
                $scope.placeholerCommentBox();
            });
        };

        /**
         * Set the height and width of the video using angular
         */

        // ADAM B: This is probably un-necessary
        $scope.getNgStyleForVideo = function() {
            return {
                width: "100%",
                textAlign: "center"
            };
        };

        /**
         * Kaltura specific
         */

        var notificationslist = [
            "startUp",
            "durationChange",
            "initiatApp",
            "changeMedia",
            "cleanMedia",
            "skinLoaded",
            "skinLoadFailed",
            "entryReady",
            "entryFailed",
            "sourceReady",
            "loadMedia",
            "mediaLoading",
            "mediaReady",
            "mediaUnloaded",
            "mediaLoadError",
            "mediaError",
            "rootResize",
            "mediaViewableChange",
            "pre1start",
            "post1start",
            "doPause",
            "doPlay",
            "doPlayEntry",
            "doStop",
            "doSeek",
            "doIntelligentSeek",
            "doSwitch",
            "kdpReady",
            "kdpEmpty",
            "layoutReady",
            "playerStateChange",
            "playerReady",
            "playerContainerReady",
            "playerPaused",
            "playerPlayed",
            "playerSeekStart",
            "playerSeekEnd",
            "playerPlayEnd",
            "playerUpdatePlayhead",
            "playerDimensionChange",
            "openFullScreen",
            "closeFullScreen",
            "changeVolume",
            "volumeChanged",
            "enableGui",
            "fastForward",
            "stopFastForward",
            "bytesDownloadedChange",
            "bytesTotalChange",
            "bufferProgress",
            "bufferChange",
            "playerDownloadComplete",
            "endEntrySession",
            "endPreSession",
            "endPostSession",
            "durationChange",
            "hasCloseFullScreen",
            "hasOpenedFullScreen",
            "switchingChange",
            "scrubberDragStart",
            "scrubberDragEnd",
            "alert",
            "showUiElement",
            "cancelAlerts",
            "enableAlerts",
            "freePreviewEnd"
        ];

        window.jsCallbackReady = function(playerId) {
            var player = (window.player = $("#" + playerId)[0]);
            player.addJsListener(
                "playerStateChange",
                "playerStateChangeHandler"
            );
            player.addJsListener(
                "playerUpdatePlayhead",
                "playerUpdatePlayheadHandler"
            );
            for (var i = 0; i < notificationslist.length; ++i) {
                /* jshint ignore:start */
                (function(notificationName) {
                    if (notificationName == "playerUpdatePlayhead") {
                        return;
                    }
                    var callbackName = notificationName + "_Handler2";
                    player.addJsListener(notificationName, callbackName);
                    window[callbackName] = function(data, id) {
                        if (!$("#stopListeners").is(":checked")) {
                            $("#notifications").text(
                                $("#notifications").text() +
                                    notificationName +
                                    ": "
                            );
                        }
                    };
                })(notificationslist[i]);
                /* jshint ignore:end */
            }
        };

        window.statisticsHandler = function(data, id) {
            $("#notifications").text($("#notifications").text() + "\n" + data);
        };

        window.playerStateChangeHandler = function(data, id) {
            $("#playerstate").text(data);
        };

        /**
         * Kaltura time change update
         */
        window.playerUpdatePlayheadHandler = function(data, id) {
            $scope.$apply(function() {
                $scope.timestamp = Math.floor(data * 10) / 10;
                // update placeholder
                $scope.placeholerCommentBox();
            });
        };

        /**
         * Placeholder for comment box
         */
        $scope.placeholerCommentBox = function() {
            if ($scope.isEntireVideo == "true") {
                $scope.placeholderCommentBox =
                    "Type a comment on the entire video";
            } else {
                $scope.placeholderCommentBox =
                    "Type a timestamp comment at " +
                    $scope.formatSecond($scope.timestamp);
            }
        };

        /**
         * Pause the video
         */
        $scope.pauseVideoIfPlaying = function() {
            try {
                $("#videoPlayer")[0].sendNotification("doPause");
            } catch (e) {
                // player not initialized
            }
        };

        /**
         * Add a video comment in database, set the id and date The comment
         * can either be a general comment or at a specific time
         * (isEntireVideo specifies this)
         */
        $scope.videoCommentAdd = function(
            videoComment,
            isEntireVideo,
            timecode
        ) {
            // text blank?
            if (videoComment.enteredText == "") {
                return;
            }
            // loading flag
            $scope.isVideoCommentAddLoading = true;
            // set not done by default
            videoComment.done = false;
            // set attachment
            videoComment.attachmentId = $scope.attachment.id;
            // general comment or timestamp comment? or time code already
            // set
            if (typeof timecode != "undefined") {
                videoComment.timecode = timecode;
            } else {
                if (isEntireVideo == "true") {
                    videoComment.timecode = -1;
                } else {
                    videoComment.timecode = $scope.timestamp;
                }
            }
            // set comment on scope (for testing ease)
            $scope.videoComment = videoComment;
            // save comment through API
            videoCommentService.videoCommentAdd(videoComment, function(data) {
                // flag loading to false
                $scope.isVideoCommentAddLoading = false;
                // add video comment to the list of comments on the
                // attachment
                if (typeof $scope.attachment.videoComments == "undefined") {
                    $scope.attachment.videoComments = [];
                }
                $scope.attachment.videoComments.push(data);
                // set the entered text to blank (reset awaiting a new
                // comment to be posted)
                $scope.videoComment.enteredText = "";
                // build the comments
                $scope.buildComment();
                // select proper second if not a general comment
                if ($scope.videoComment.timecode != -1) {
                    for (var i = 0; i < $scope.arrSecond.length; i++) {
                        if (
                            $scope.arrSecond[i].second ==
                            Math.floor($scope.videoComment.timecode)
                        ) {
                            $scope.showSecond(i, false);
                            break;
                        }
                    }
                } else {
                    // general comment
                    $scope.selectSecond = -1;
                    $scope.isShowGeneralComment = true;
                }
            });
        };

        /**
         * Convert break point \r\n to <br />
         */
        $scope.nl2br = function(str, is_xhtml) {
            var breakTag = "</p><p>";
            return $sce.trustAsHtml(
                ("<p>" + str + "</p>").replace(/[\n\r]/g, breakTag)
            );
        };

        /** Remove external reviewer name from comment on edit comment */
        function getExternalReviewerName(){
            var externalReviewerName = localStorage.getItem("externalReviewer");
            externalReviewerName = externalReviewerName === null ? "" : externalReviewerName;
            let currentUrl = window.location.href;
            var accountId = parseInt($('#attrs-id').data('accountid'))
            var externalReviewerData = "";
            if(accountId === 1){
                if(externalReviewerName.trim() !== "") {
                    externalReviewerData = " - " + externalReviewerName
                }
            }
            return externalReviewerData;
        }

        function checkAndLoadModalExternalReviewerName(){
            var opts = {
                html:
                    '<div class="external-reviewer-pane">'+
                        '<h1>External Reviewer</h1>' +
                        '<div class="input-form-container">'+
                            '<label class="form-input-label">Your Name <span class="required">*</span></label>'+
                            '<input type="text" name="external_reviewer" id="external_reviewer" onkeyup="resetValidation()" />'+
                            '<span class="validation-message" id="validation-message">Please enter your name</span>'+
                        '</div>' +
                        '<div class="save-btn-container">'+
                            '<button class="standard" onclick="saveExternalReviewerName()"><span>Save</span></button>'+
                        '</div>' +
                    "</div>",
                    closeButton: false,
                overlayClose: false
            };
            $.colorbox(opts);
            $("#cboxClose").css('display','none')
            $('#validation-message').css('display','none');
        }

        window.resetValidation = function(){
            $('#validation-message').css('display','none');
        }

        window.saveExternalReviewerName = function(){
            let name = $("#external_reviewer").val().trim();
            if(name === "" || name === null){
                $('#validation-message').css('display','flex');
            }else{
                $('#validation-message').css('display','none');
                console.log(name);
                localStorage.setItem("externalReviewer",name);
                parent.$.colorbox.close();
                // $('#externalReviewerNameFromLocalStorage').val(name);
            }
        }

        $scope.removeExternalReviewer = function(editableComment){
            let externalReviewer = getExternalReviewerName();
            if(editableComment.includes(externalReviewer))
            {
                return editableComment.replace(externalReviewer,"");
            }else{
                return editableComment;
            }
        }

        /**
         * update video comment, entered text or/and done status
         */
        $scope.updateVideoComment = function(videoComment) {
            // flag loading
            $scope.isLoadingVideoCommentUpdate = true;
            // set attachment id on it for the update
            if (typeof $scope.attachment != "undefined") {
                videoComment.attachment = {
                    id: $scope.attachment.id
                };
            }
            // update in database through the API
            videoCommentService.videoCommentUpdate(videoComment, function() {
                // flag loading
                $scope.isLoadingVideoCommentUpdate = false;
            });
        };

        /**
         * Delete a video comment using the API
         */
        $scope.videoCommentDelete = function(videoComment) {
            // flag loading
            $scope.isLoadingVideoCommentDelete = true;
            // delete video comment in the back-end (actually setting the
            // flag the removed)
            videoCommentService.videoCommentDelete(videoComment, function() {
                // flag loading
                $scope.isLoadingVideoCommentDelete = false;
                // delete in attachment
                if (typeof $scope.attachment.videoComments != "undefined") {
                    for (
                        var i = 0;
                        i < $scope.attachment.videoComments.length;
                        i++
                    ) {
                        if (
                            $scope.attachment.videoComments[i].id ==
                            videoComment.id
                        ) {
                            $scope.attachment.videoComments.splice(i, 1);
                            break;
                        }
                    }
                }
                // build the comment list
                $scope.buildComment();
                // do we still have at least a comment at that second
                var isFound = false;
                if (
                    typeof $scope.attachment != "undefined" &&
                    typeof $scope.attachment.videoComments != "undefined"
                ) {
                    for (
                        var c = 0;
                        c < $scope.attachment.videoComments.length;
                        c++
                    ) {
                        if (
                            Math.floor(
                                $scope.attachment.videoComments[c].timecode
                            ) == Math.floor(videoComment.timecode)
                        ) {
                            isFound = true;
                            break;
                        }
                    }
                }
                // not found show general comment
                if (!isFound) {
                    $scope.selectSecond = -1;
                    $scope.isShowGeneralComment = true;
                }
            });
        };

        /**
         * Get attachment AKA the video
         */
        $scope.getAttachment = function() {
            // flag loading attachment
            $scope.isAttachmentLoading = true;
            videoCommentService.getAttachment(
                $scope.attachment,
                function(attachment) {
                    if (attachment.externalValueId != undefined) {
                        $scope.attachment = attachment;
                        // set player
                        mw.setConfig("Kaltura.LeadWithHTML5", true);
                            kWidget.embed({
                                "targetId": "videoPlayer",
                                "wid": "_1737042",
                                "uiconf_id": 23937082,
                                flashvars: {
                                    allowFullScreen: true,
                                    allowNetworking: "all",
                                    allowScriptAccess: "always",
                                    download: {
                                        plugin: true
                                    },
                                    strings: {
                                        "mwe-embedplayer-missing-source": $translate.instant(
                                            "angular.videoCommenting.videoNotTranscodedYet"
                                        )
                                    }
                                },
                                "cache_st": 1649432482,
                                "entry_id": $scope.attachment.externalValueId
                            });
                        // build the comment list
                        $scope.buildComment();
                        // Done commenting button was disabled for external user. here set it by default true for all users.
                        // in future, if want to hide it for some users, modify this flag
                        $scope.showVideoComment = true;
                        // watch comment box and pause if text entered
                        $scope.$watch("videoComment.enteredText", function() {
                            if (typeof $("#videoPlayer")[0] != "undefined") {
                                try {
                                    $("#videoPlayer")[0].sendNotification(
                                        "doPause"
                                    );
                                } catch (e) {
                                    // not initialized yet
                                }
                            }
                        });
                    } else {
                        $location.path("/sorry");
                    }
                },
                function(attachment) {
                    $location.path("/sorry");
                }
            );
        };

        if (typeof $routeParams.attachmentId != "undefined") {
            $scope.attachment = {
                id: $routeParams.attachmentId
            };
            // launch it
            $scope.getAttachment();
        }

        /**
         * Building the array of comments in to separate arrays,
         * $scope.arrSecond contains all the time stamped comments organized
         * by second. And $scope.arrGeneralComment for the the comment with
         * a time code of -1
         */
        $scope.buildComment = function() {
            // build the array of seconds loop over the comment
            $scope.arrSecond = [];
            $scope.arrGeneralComment = [];
            if (typeof $scope.attachment.videoComments == "undefined") {
                return null;
            }
            for (var i = 0; i < $scope.attachment.videoComments.length; i++) {
                // if timestamp is not set we don't care
                if ($scope.attachment.videoComments[i].timecode == -1) {
                    // add then in a special list
                    $scope.arrGeneralComment.push(
                        $scope.attachment.videoComments[i]
                    );
                } else {
                    var y;
                    // check that the seconds is not in the array already
                    for (y = 0; y < $scope.arrSecond.length; y++) {
                        // stop the loop if
                        if (
                            $scope.arrSecond[y].second >
                            Math.floor(
                                $scope.attachment.videoComments[i].timecode
                            )
                        ) {
                            break;
                        }
                    }
                    var isExist = false;
                    try {
                        if (
                            undefined !== $scope.arrSecond[y - 1] &&
                            undefined !== $scope.arrSecond[y - 1].second &&
                            $scope.arrSecond[y - 1].second ==
                                Math.floor(
                                    $scope.attachment.videoComments[i].timecode
                                )
                        ) {
                            isExist = true;
                            y--;
                        }
                    } catch (e) {
                        console.error(e);
                        // normal out of bond or something could happen on
                        // the 1st
                        // elements
                    }
                    // add second if it doesn't already exist
                    if (!isExist) {
                        // if we are at the end of the array just insert
                        if ($scope.arrSecond.length == y) {
                            $scope.arrSecond[y] = {
                                second: Math.floor(
                                    $scope.attachment.videoComments[i].timecode
                                )
                            };
                        } else {
                            // back up
                            var backup = $scope.arrSecond[y];
                            // insert
                            $scope.arrSecond[y] = {
                                second: Math.floor(
                                    $scope.attachment.videoComments[i].timecode
                                )
                            };
                            if (typeof $scope.arrSecond[y + 1] == "undefined") {
                                $scope.arrSecond[y + 1] = backup;
                            } else {
                                // need to push all them back
                                for (
                                    var z = y;
                                    z < $scope.arrSecond.length - 1;
                                    z++
                                ) {
                                    var back2 = $scope.arrSecond[z + 1];
                                    $scope.arrSecond[z + 1] = backup;
                                    backup = back2;
                                }
                                $scope.arrSecond[z + 1] = backup;
                            }
                        }
                    }
                    // insert the project video comment there ==== y
                    // if undefined create it
                    if (typeof $scope.arrSecond[y].listComment == "undefined") {
                        $scope.arrSecond[y].listComment = [];
                    }
                    $scope.arrSecond[y].listComment.push(
                        $scope.attachment.videoComments[i]
                    ); // do
                    // something
                    // complicated
                    // there
                    // as
                    // well
                    // is there a previous comment with the same time stamp
                    // 9 is it
                    // a reply
                    if (
                        $scope.arrSecond[y].listComment.length > 1 &&
                        $scope.arrSecond[y].listComment[
                            $scope.arrSecond[y].listComment.length - 2
                        ].timestamp ==
                            $scope.attachment.videoComments[i].timestamp
                    ) {
                        $scope.arrSecond[y].listComment[
                            $scope.arrSecond[y].listComment.length - 1
                        ].isReply = true;
                    } else {
                        $scope.arrSecond[y].listComment[
                            $scope.arrSecond[y].listComment.length - 1
                        ].isReply = false;
                    }
                }
            }
            // add reply button to general comment
            if ($scope.arrGeneralComment.length > 0) {
                $scope.arrGeneralComment[
                    $scope.arrGeneralComment.length - 1
                ].isReply = false;
            }
        };

        /**
         * Show a particular second on the right side panel
         */
        $scope.showSecond = function(index, isRedirectPlayer) {
            // general comment
            if (index == -1) {
                $scope.isShowGeneralComment = true;
                $scope.selectSecond = -1;
                return;
            }
            // timestamp comment
            $scope.secondIndex = index;
            if (
                typeof $scope.arrSecond != "undefined" &&
                $scope.arrSecond[index] != "undefined"
            ) {
                $scope.selectSecond = $scope.arrSecond[index].second;
                // condition bellow to fix : https://skyword.atlassian.net/browse/SKY-13936
                if (
                    typeof isRedirectPlayer == "undefined" ||
                    isRedirectPlayer
                ) {
                    $scope.videoSetTimeAndPause($scope.arrSecond[index].second);
                }
            }
            $scope.isShowGeneralComment = false;
        };

        /**
         * Seek the right time then pause the video
         */
        $scope.videoSetTimeAndPause = function(time) {
            $timeout(function() {
                $scope.timestamp = time;
            }, 100);

            try {
                var videoPlayer = $("#videoPlayer");
                if (typeof videoPlayer[0] != "undefined") {
                    videoPlayer[0].sendNotification("doSeek", time);
                    videoPlayer[0].sendNotification("doPause");
                }
            } catch (e) {
                console.error(e);
            }
        };

        /**
         * Return all the CSS classes needed to print a comment
         */
        $scope.getClassComment = function(
            videoComment,
            index,
            last,
            listComment
        ) {
            // border flag initialize
            var isVideoCommentTopBorder,
                isVideoCommentBottonBorder,
                isVideoCommentBottomDottedBorderCompleted;
            isVideoCommentTopBorder = isVideoCommentBottonBorder = isVideoCommentBottomDottedBorderCompleted = true;
            // list comment is defined
            if (typeof listComment != "undefined" && listComment.length > 0) {
                // top border
                if (
                    index > 0 &&
                    listComment[index - 1].timecode == videoComment.timecode
                ) {
                    isVideoCommentTopBorder = false;
                }
                // bottom border
                if (
                    listComment.length - 1 > index &&
                    listComment[index + 1].timecode == videoComment.timecode
                ) {
                    isVideoCommentBottonBorder = false;
                }
            }
            // hide dotted border completed
            if (isVideoCommentBottonBorder || !videoComment.done) {
                isVideoCommentBottomDottedBorderCompleted = false;
            }
            // build CSS
            return {
                videoComment: true,
                completed: videoComment.done,
                videoCommentTopBorder: isVideoCommentTopBorder,
                videoCommentBottomBorder: isVideoCommentBottonBorder,
                videoCommentBottomDottedBorder: !isVideoCommentBottonBorder,
                videoCommentBottomDottedBorderCompleted: isVideoCommentBottomDottedBorderCompleted
            };
        };

        /**
         * Kaltura specific
         */

        notificationslist = [
            "startUp",
            "durationChange",
            "initiatApp",
            "changeMedia",
            "cleanMedia",
            "skinLoaded",
            "skinLoadFailed",
            "entryReady",
            "entryFailed",
            "sourceReady",
            "loadMedia",
            "mediaLoading",
            "mediaReady",
            "mediaUnloaded",
            "mediaLoadError",
            "mediaError",
            "rootResize",
            "mediaViewableChange",
            "pre1start",
            "post1start",
            "doPause",
            "doPlay",
            "doPlayEntry",
            "doStop",
            "doSeek",
            "doIntelligentSeek",
            "doSwitch",
            "kdpReady",
            "kdpEmpty",
            "layoutReady",
            "playerStateChange",
            "playerReady",
            "playerContainerReady",
            "playerPaused",
            "playerPlayed",
            "playerSeekStart",
            "playerSeekEnd",
            "playerPlayEnd",
            "playerUpdatePlayhead",
            "playerDimensionChange",
            "openFullScreen",
            "closeFullScreen",
            "changeVolume",
            "volumeChanged",
            "enableGui",
            "fastForward",
            "stopFastForward",
            "bytesDownloadedChange",
            "bytesTotalChange",
            "bufferProgress",
            "bufferChange",
            "playerDownloadComplete",
            "endEntrySession",
            "endPreSession",
            "endPostSession",
            "durationChange",
            "hasCloseFullScreen",
            "hasOpenedFullScreen",
            "switchingChange",
            "scrubberDragStart",
            "scrubberDragEnd",
            "alert",
            "showUiElement",
            "cancelAlerts",
            "enableAlerts",
            "freePreviewEnd"
        ];

        window.jsCallbackReady = function(playerId) {
            var player = (window.player = $("#" + playerId)[0]);
            player.addJsListener(
                "playerStateChange",
                "playerStateChangeHandler"
            );
            player.addJsListener(
                "playerUpdatePlayhead",
                "playerUpdatePlayheadHandler"
            );
            // player seek, reference
            // #https://skyword.atlassian.net/browse/SKY-12892
            player.addJsListener("doSeek", "doSeekHandler");

            for (var i = 0; i < notificationslist.length; ++i) {
                /* jshint ignore:start */
                (function(notificationName) {
                    if (notificationName == "playerUpdatePlayhead") {
                        return;
                    }
                    var callbackName = notificationName + "_Handler2";
                    player.addJsListener(notificationName, callbackName);
                    window[callbackName] = function(data, id) {
                        if (!$("#stopListeners").is(":checked")) {
                            $("#notifications").text(
                                $("#notifications").text() +
                                    notificationName +
                                    ": "
                            );
                        }
                    };
                })(notificationslist[i]);
                /* jshint ignore:end */
            }
        };

        /**
         * Seek new time with the Kaltura player, update the timestamp on
         * the scope
         */
        window.doSeekHandler = function(data, id) {
            // console.log("doSeekHandler - " + data);

            setTimeout(function() {
                $scope.$apply(function() {
                    $scope.timestamp = Math.floor(data * 10) / 10;
                });
            }, 0);
        };

        window.playerUpdatePlayheadHandler = function(data, id) {
            setTimeout(function() {
                $scope.$apply(function() {
                    $scope.timestamp = Math.floor(data * 10) / 10;
                });
            }, 0);
        };
    }
]);

function sticky_relocate() {
    try {
        var window_top = $(window).scrollTop(),
            stickyAnchor = $("#sticky-anchor");
        //        console.log("Video Commenting Controller");
        var div_top = 0 < stickyAnchor.length ? stickyAnchor.offset().top : 0;
        if (window_top > div_top) {
            $("#sticky").addClass("stick");
        } else {
            $("#sticky").removeClass("stick");
        }
    } catch (e) {
        // Jasmine UT
        console.error(e);
    }
}

$(function() {
    $(window).scroll(sticky_relocate);
    sticky_relocate();
});

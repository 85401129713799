(function() {
    app.service("content", [
        "$http",
        function($http) {
            return {
                getContentOverlay: getContentOverlay,
                deleteAttachment: deleteAttachment
            };

            function getContentOverlay(contentRequestId) {
                var url = "/API/content/" + contentRequestId + "/overlay";
                return $http.get(url);
            }

            function deleteAttachment(elementDTO) {
                var url = "/API/content/remove-attachment";
                return $http.put(url, elementDTO);
            }
        }
    ]);
})();

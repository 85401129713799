app.controller("CreateIdeaController", [
    "$scope",
    "$rootScope",
    "$compile",
    "$translate",
    "$http",
    "$filter",
    "$timeout",
    "ngTableParams",
    "skyword",
    "contentTypes",
    "idea",
    "$window",
    "SkyFactory",
    "TemplateFactory",
    "formBuilder",
    "AssetFactory",
    function(
        $scope,
        $rootScope,
        $compile,
        $translate,
        $http,
        $filter,
        $timeout,
        ngTableParams,
        skyword,
        contentTypes,
        idea,
        $window,
        SkyFactory,
        TemplateFactory,
        formBuilder,
        AssetFactory
    ) {
        function resetIdeaDTO() {
            $scope.ideaDTO = {
                // This is SharedDTO in Javaland
                programCategoryId: null,
                contentTypeId: null,
                contentElementList: []
            };
            $scope.contentElementList = [];
            $scope.programCategoryList = [];
        }

        resetIdeaDTO();

        $scope.contentTypeId = null;
        $scope.contentTypeList = [];

        $scope.months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];

        $scope.programId = angular.element("#attrs-id").data("programid");
        $scope.csrfToken = angular.element("#cToken").attr("value");
        $scope.uuid = angular
            .element("#personaContentUUID")
            .data("personaContentUUID");
        $scope.submitClicked = false;

        function qs(name) {
            let url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) {
                return null;
            }
            if (!results[2]) {
                return "";
            }
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        }

        $scope.showSuccess = qs("success") == "true";

        //call to render selectbox
        setTimeout(function() {
            angular.element("#selectCategory").selectBoxIt({
                defaultText: $translate.instant("angular.label.selectCategory"),
                showFirstOption: false
            });
            angular.element("select").selectBoxIt("refresh");
        }, 300);

        let unbind = $scope.$watch("programId", function() {
            let contentTypePromise = contentTypes.getIdeaContentTypes(
                $scope.programId
            );

            contentTypePromise.then(function(result) {
                let what = result.data;
                $scope.contentTypeList = what;
                $scope.selectContentType(what[0]);
            });

            unbind();
        });

        $scope.showTextField = function(dto) {
            return dto.typeId === 23 || dto.typeId === 10 || dto.typeId === 2;
        };

        // creates new idea
        $scope.createNewIdea = function() {
            $scope.submitClicked = true;
            $scope.ideaDTO.contentElementList = angular.copy(
                $scope.contentElementList
            );
            $scope.ideaDTO.assetIds = AssetFactory.getAssetList();
            $scope.ideaDTO.uuid = $scope.personaContentUUID;
            console.info($scope.contentElementList);
            console.dir($scope.ideaDTO);
            let createPromise = idea.createIdea(
                $scope.programId,
                $scope.ideaDTO
            );

            createPromise.then(function() {
                window.location.href = "/ideas.action";
            });
        };

        $scope.submitAgain = function() {
            $scope.submitClicked = true;
            if (!$scope.fieldsBlank) {
                $scope.ideaDTO.contentElementList = angular.copy(
                    $scope.contentElementList
                );
                $scope.ideaDTO.assetIds = AssetFactory.getAssetList();
                $scope.ideaDTO.uuid = $scope.personaContentUUID;
                console.info($scope.contentElementList);
                console.dir($scope.ideaDTO);
                let createPromise = idea.createIdea(
                    $scope.programId,
                    $scope.ideaDTO
                );
                // TODO: Why are we refreshing the entire page?  We should either A: Blank out fields or B: Just reload the current content type.
                createPromise.then(function() {
                    window.location.href =
                        window.location.pathname +
                        "?" +
                        $.param({ success: "true" });
                });
            }
            return false;
        };

        $scope.selectContentType = function(ct, event) {
            //when content type is clicked
            if (event !== undefined) {
                let a = jQuery("#contentTypeList > li > a");
                a.removeAttr("style");
                a.removeClass("arrow");

                //styling for selected link
                jQuery(event.target).css({
                    "background-color": "#CFCFCF",
                    "text-decoration": "none",
                    color: "#555555"
                });
                jQuery(event.target).addClass("arrow");
            }

            resetIdeaDTO();

            $scope.ideaDTO.contentTypeId = ct.contentTypeId;
            $scope.selectedContentType = ct;

            let templatePromise = contentTypes.getIdeaTemplate(
                $scope.programId,
                ct.contentTypeId
            );
            templatePromise.then(function(result) {
                $scope.contentElementList = result.data;
                formBuilder.setupSelectSingle();
                formBuilder.setupSelectMultiple();
            });

            let categoryPromise = contentTypes.getProgramCategories(
                $scope.programId,
                ct.contentTypeId
            );
            $scope.ideaDTO.programCategoryId = null;
            categoryPromise.then(function(result) {
                $scope.programCategoryList = result.data;
                if (1 === $scope.programCategoryList.length) {
                    $scope.ideaDTO.programCategoryId =
                        $scope.programCategoryList[0].id;
                } else {
                    setTimeout(function() {
                        angular.element("#selectCategory").selectBoxIt();
                        angular.element("#selectCategory").selectBoxIt({
                            defaultText: $translate.instant(
                                "angular.label.selectCategory"
                            ),
                            showFirstOption: false
                        });
                        angular
                            .element("#selectCategory")
                            .selectBoxIt("refresh");
                    }, 50);
                }
            });
        };

        //on load show styling for selected first content type in list
        $window.onload = setTimeout(function() {
            let a = jQuery("#contentTypeList > li:nth-child(1) > a");
            //add css select to first li in list
            a.css({
                "background-color": "#CFCFCF",
                "text-decoration": "none",
                color: "#555555"
            });
            a.addClass("arrow");
        }, 500);

        //calling colorbox for removing media attachment
        jQuery(document).on("click", ".removeAttachment", function() {
            jQuery.colorbox({
                height: 150,
                width: 450,
                href: "/partials/directives/remove-attachment.html",
                onComplete: function() {
                    //items needed for removing attachment
                    $scope.attachmentId = angular
                        .element(".attachmentId")
                        .attr("value");
                    $scope.fileName = angular
                        .element("li.fileInfo > span.url > a")
                        .html();
                    $scope.contentTemplateElementDataId = null;
                    $scope.contentRequestId = null;

                    $rootScope.$apply(function() {
                        let content = $("#cboxLoadedContent");
                        $compile(content)($scope);
                    });

                    $rootScope.$broadcast("scopeData", $scope);
                }
            });
        });
        // END Attachments

        //checks if all required fields are not blank
        $scope.checkRequired = function() {
            $scope.fieldsBlank = false;
            // console.log("Check Required");
            if (
                $scope.ideaDTO.programCategoryId == null ||
                $scope.ideaDTO.programCategoryId == ""
            ) {
                //if program category is blank
                // console.log("Program Category");
                $scope.fieldsBlank = true;
            }
            // angular.forEach($scope.contentElementList, function (element, key) {
            //     if ((element.required === true || 10 === element.typeId) && SkyFactory.notExists(element.value)) {
            //         $scope.fieldsBlank = true;
            //     }
            // });
            // Since we're not displaying errors at the moment, let's just check what we have to and fail as quickly as possible.
            // If we need the elements, we can do the above commented out code or use contentElementList.filter().
            if (false === $scope.fieldsBlank) {
                for (let i = 0; i < $scope.contentElementList.length; i++) {
                    let element = $scope.contentElementList[i];
                    // console.log(element);
                    $scope.fieldsBlank = checkElement(element);
                    if (true === $scope.fieldsBlank) {
                        break;
                    }
                }
            }
            return $scope.fieldsBlank;
        };

        function checkElement(element) {
            let error = false;
            if (TemplateFactory.ARTICLE_TITLE === element.typeId) {
                element.required = true;
            }
            if (element.required === true) {
                if (TemplateFactory.MULTIPLE_SELECT === element.typeId) {
                    error = 0 === element.taxonomyValueIds.length;
                } else if (TemplateFactory.SINGLE_SELECT === element.typeId) {
                    error =
                        undefined === element.taxonomyValueId ||
                        null === element.taxonomyValueId;
                } else if (TemplateFactory.ARTICLE_TITLE === element.typeId) {
                    error = SkyFactory.notExists(element.value);
                } else {
                    error = SkyFactory.notExists(element.value);
                }
                if (error) {
                    return error;
                }
            }
            if (
                element.minLength !== null &&
                (element.value || element.required)
            ) {
                error = element.minLength > element.value.length;
            }
            if (
                element.maxLength !== null &&
                (element.value || element.required)
            ) {
                error = element.maxLength < element.value.length;
            }
            if (
                element.maxLength !== null &&
                element.minLength !== null &&
                (element.value || element.required)
            ) {
                error =
                    element.maxLength < element.value.length ||
                    element.minLength > element.value.length;
            }
            return error;
        }
    }
]).controller("createNewIdea", ["$scope", function($scope) {}]);

(function() {
    //RemoveAttachment
    app.controller("RemoveAttachment", [
        "$scope",
        "$rootScope",
        "content",
        "$translate",
        function($scope, $rootScope, content, $translate) {
            //grab scope from previous controller so we have attachment data
            $scope.$on("scopeData", function(event, data) {
                $scope = data;
                $scope.attachment = {
                    attachmentId: $scope.attachmentId,
                    contentTemplateElementDataId:
                        $scope.contentTemplateElementDataId,
                    contentRequestId: $scope.contentRequestId,
                    programId: $scope.programId
                };
            });

            $scope.removeAttachment = function() {
                //console.log($scope.attachment);
                var promise = content.deleteAttachment($scope.attachment);

                promise.then(function(result) {
                    console.log(result);
                    if (
                        undefined !== result.data.success &&
                        true === result.data.success
                    ) {
                        //on success remove attachment
                        //close colorbox
                        $.colorbox.close();
                        // remove attachmentContainer.
                        attachmentContainer = jQuery(
                            "div.attachmentContainer"
                        ).find(".attachment");
                        attachmentContainer.remove();
                        $(".fileUploadSpan").show();
                        $(".fileUploadSpan").removeClass("ng-hide");
                        $("div.attachmentContainer> div.subContainer").css({
                            "margin-bottom": "40px"
                        });
                    }
                });
            };
        }
    ]);
})();

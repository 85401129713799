app.directive("fieldValidationIndicator", [
    "$parse",
    function($parse) {
        return {
            templateUrl:
                "/partials/contributorJoin/field-validation-indicator.html",
            replace: false,
            restrict: "EA", // Example: <field-validation-indicator title="" summary="" date=""></field-validation-indicator>
            link: function(scope, element, attrs) {
                scope.fld = $parse(attrs.fieldname)(scope);
            },
            scope: {
                fieldname: "=fieldname"
            }
        };
    }
]);

(function() {
    app.service("digitalAsset", [
        "$http",
        function($http) {
            return {
                getContentElements: getContentElements,
                getContentTypes: getContentTypes,
                getContentTypeCategories: getContentTypeCategories,
                getAWS: getAWS,
                createContent: createContent,
                getMimeTypes: getMimeTypes,
                getAssetAttachment: getAssetAttachment,
                getDigitalAssetMetaInfo: getDigitalAssetMetaInfo,
                getAssetAttachmentToCrop: getAssetAttachmentToCrop
            };

            function getContentElements(contentTypeId) {
                var url = "/API/digital-asset/" + contentTypeId;
                return $http.get(url);
            }

            function getDigitalAssetMetaInfo(contentRequestId) {
                var url = "/API/content/" + contentRequestId + "/asset-meta";
                return $http.get(url);
            }

            function getAWS(contentTypeId) {
                var url = "/API/digital-asset/" + contentTypeId + "/aws";
                return $http.get(url);
            }

            function getMimeTypes(contentTypeId) {
                var url = "/API/digital-asset/" + contentTypeId + "/mime-type";
                return $http.get(url);
            }

            function getContentTypes(programId) {
                var url = "/API/program/" + programId + "/content-type/dam";
                return $http.get(url);
            }

            function getContentTypeCategories(programId) {
                var url = "/API/program/" + programId + "/content-type";
                return $http.get(url);
            }

            function createContent(contentTypeId, digitalAssetDTO) {
                var url = "/API/digital-asset/" + contentTypeId + "/new";
                return $http.put(url, digitalAssetDTO);
            }

            function getAssetAttachment(attachmentId) {
                var url = "/API/content/attachment/" + attachmentId;
                return $http.get(url);
            }

            function getAssetAttachmentToCrop(
                attachmentId,
                dataId,
                contentRequestId
            ) {
                console.log("dataId: " + dataId);
                var url =
                    "/API/image/" +
                    attachmentId +
                    "/transfer?contentTemplateElementDataId=" +
                    dataId +
                    "&contentRequestId=" +
                    contentRequestId;
                return $http.get(url);
            }
        }
    ]);
})();

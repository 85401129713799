(function() {
    // 1.0 Taxonomy Service/Factory
    app.factory("search", [
        "$http",
        "$q",
        function($http, $q) {
            return {
                // 2.0 Returns all search results if null. Search Detail Object parameter accepted for search text and facets
                search: function(searchDTO) {
                    // 2.1 The $http API is based on the deferred/promise APIs exposed by the $q service. So it returns a promise for us by default
                    var url = "/API/search/";
                    return $http
                        .post(url, searchDTO)

                        .then(
                            function(response) {
                                // 2.1 On Success. We're getting back an object
                                if (typeof response.data === "object") {
                                    return response.data;
                                    // 2.2 On Invalid type. It's not an object
                                } else {
                                    // invalid response
                                    return $q.reject(response.data);
                                }
                            },
                            function(response) {
                                // 2.3 Something just straight up went wrong
                                return $q.reject(response.data);
                            }
                        );
                },

                // 2.0 Returns additional details for a search result
                searchDetail: function(id) {
                    // 2.1 The $http API is based on the deferred/promise APIs exposed by the $q service. So it returns a promise for us by default
                    var url = "/API/search/" + id;
                    return $http
                        .get(url)

                        .then(
                            function(response) {
                                // 2.1 On Success. We're getting back an object
                                if (typeof response.data === "object") {
                                    return response.data;
                                    // 2.2 On Invalid type. It's not an object
                                } else {
                                    // invalid response
                                    return $q.reject(response.data);
                                }
                            },
                            function(response) {
                                // 2.3 Something just straight up went wrong
                                return $q.reject(response.data);
                            }
                        );
                },
                toggleSalesforceVisiblity: function(id, visibility) {
                    var url = "/API/search/toggle-salesforce-visibility/" + id;
                    return $http
                        .put(url, new Boolean(visibility))
                        .then(
                            function(response) {
                                console.log("success: ", response);
                            },
                            function(response) {
                                // 2.3 Something just straight up went wrong
                                return $q.reject(response);
                            }
                        );
                }
            };
        }
    ]);
})();

app.service("assignmentAttachments", [
    "skyword",
    "crop",
    "validation",
    "call",
    "overlay",
    "setup",
    "TemplateFactory",
    function(skyword, crop, validation, call, overlay, setup, TemplateFactory) {
        var uploadIds = [],
            embedUploadIds = [],
            attachment,
            attachmentContainer,
            attachmentHidden,
            contentTemplateElementDataId,
            openAttachment = function(attachmentId) {
                var urlAction =
                    "setupCropAttachmentAjax.action?contentRequestId=" +
                    $("#contentRequestId").val() +
                    "&attachmentId=" +
                    attachmentId +
                    "&contentTemplateElementDataId=" +
                    template.contentTemplateElementData.val();
                return call.overlay(urlAction, null, 1090, null, "GET");
            },
            removeAssignmentAttachment = function() {
                var f;
                //console.log(contentTemplateElementDataId);
                attachmentContainer = jQuery(
                    "div.attachmentContainer[data-cted='" +
                        contentTemplateElementDataId +
                        "']"
                );
                //console.log(attachmentContainer);
                attachment = attachmentContainer.find(".attachment");
                //console.log(attachment);
                attachment.remove();
                f = attachmentContainer.find(".fileUploadSpan");
                //console.log(f);
                f.fadeIn("fast");

                attachmentContainer = null;

                if (null !== attachmentHidden) {
                    attachmentHidden.val("");
                }
            },
            removeAttachment = function() {
                var crId,
                    ctedId,
                    a = this,
                    link = $(a),
                    urlAction =
                        "removeAttachmentAjax.action?assignmentUpload=true&attachmentId=",
                    attach = null,
                    attachmentId = parseInt(link.data("attachmentid"), 10),
                    attachHidden = null,
                    opts = null;
                attachmentContainer = link.closest(".attachmentContainer");

                attachHidden = attachmentContainer.siblings(
                    ".attachmentHiddenId"
                );
                attachment = attachmentContainer.find(">div.attachment");
                //attachmentContainer = attachmentContainer;
                attachmentHidden = attachHidden;

                crId = $("#contentRequestId").val();
                ctedId = attachmentContainer
                    .siblings(".contentTemplateElementDataId")
                    .val();
                contentTemplateElementDataId = ctedId;
                urlAction = urlAction + attachmentId;
                if (undefined !== crId && null !== crId && !isNaN(crId)) {
                    urlAction += "&contentRequestId=" + crId;
                }
                if (undefined !== ctedId && null !== ctedId && !isNaN(ctedId)) {
                    urlAction += "&contentTemplateElementDataId=" + ctedId;
                }
                return call.overlay(urlAction, null, 500, null, null, null);
            },
            setupOptions = function(input) {
                var opts,
                    d = new Date();

                if (undefined === input) {
                    console.log("This should not be undefined");
                }
                opts = {
                    referrerAction: $("#actionNameId").val(),
                    accountId: $("#accountId").val(),
                    programId: $("#programId").val(),
                    attachmentId: input.siblings(".attachmentId").val(),
                    saveInitTime: d.getTime(),
                    contentTemplateId: $("#content-template-id").val(),
                    contentTemplateElementId: input
                        .siblings(".contentTemplateElementId")
                        .val(),
                    contentTemplateElementDataId: input
                        .siblings(".contentTemplateElementDataId")
                        .val(),
                    displayOrder: input.siblings(".displayOrder").val(),
                    contentRequestId: $("#contentRequestId").val(),
                    contentId: $("#contentId").val(),
                    required: input.siblings(".elementRequired").val(),
                    navigatorUserAgent: navigator.userAgent.toLowerCase(),
                    userLocale: $("#userLocale").val(),
                    assignmentUpload: true
                };
                return opts;
            };

        function setupRemoveAttachment() {
            var html = jQuery("html");
            html.on("click", ".removeAttachment", null, removeAttachment);
            // TODO: THIS DOESN'T DO ANYTHING - see lighwindowSuccess.jspf <c:when test="${'removeAttachmentAjax' == actionName}"> case.  GAH
            html.on("click", "#btn-remove-element", null, function() {
                var formSubmit = $("#formSubmit"),
                    urlAction = formSubmit.attr("action"),
                    getPost = "POST",
                    options = {
                        cache: false,
                        type: getPost,
                        data: formSubmit.serialize(),
                        url: urlAction,
                        success: function(response) {
                            if ("removeAttachmentAjax.action" === urlAction) {
                                removeAssignmentAttachment();
                            }
                            $.colorbox.close();
                        },
                        error: function(data, status, err) {
                            var msg = "Error getting: " + urlAction + "\n\n";
                            msg += "Status: " + status + "\n\n";
                            // msg += "Parameters: " + JSON.stringify(parameters) + "\n\n";
                            console.log(msg);
                        }
                    };
                call.ajax(urlAction, options);
                return false;
            });
        }

        return {
            removeAssignmentAttachment: function() {
                removeAssignmentAttachment();
            },
            openAttachment: function(attachmentId) {
                openAttachment(attachmentId);
            },
            setupRemoveAttachment: function() {
                setupRemoveAttachment();
            },
            init: function() {
                //onReady
                $(function() {
                    var curItem,
                        curDef,
                        html = $("html");

                    TemplateFactory.loadAttachmentInfo();

                    html.on("click", "#btn-crop-overlay", null, function() {
                        var formSubmit = $("#formSubmit"),
                            urlAction = formSubmit.attr("action"),
                            getPost = formSubmit.attr("method"),
                            options,
                            x1 = $("#x1").val(),
                            x2 = $("#x2").val(),
                            y1 = $("#y1").val(),
                            y2 = $("#y2").val();

                        if ("" === x1 || "" === x2 || "" === y1 || "" === y2) {
                            if (undefined !== skyword.jcropObj) {
                                skyword.jcropObj.destroy();
                                setup.crop();
                                setTimeout(function() {
                                    $("#btn-crop-overlay").removeAttr(
                                        "disabled"
                                    );
                                }, 15);
                            }
                            return false;
                        }
                        options = {
                            cache: false,
                            type: getPost,
                            data: formSubmit.serialize(),
                            url: urlAction,
                            success: function(response) {
                                if (!validation.parse(response)) {
                                    var attachmentId = $(response)
                                            .find(".attachmentId")
                                            .val(),
                                        contentTemplateElementId = $(response)
                                            .find(
                                                "#content-template-element-id"
                                            )
                                            .val(),
                                        currentAttachment = $(
                                            "#attachment-" + attachmentId
                                        ),
                                        attachmentContainer = currentAttachment.parent(),
                                        att = attachmentContainer.siblings(
                                            ".attachmentId"
                                        );
                                    // console.log("what" + attachmentId);
                                    att.val(attachmentId);
                                    currentAttachment.replaceWith(response);
                                    overlays.init();
                                    $(this).colorbox.close();
                                    $("#cropping-required-container").show();
                                } else {
                                    msg +=
                                        json.errorMsg +
                                        "<br/><br/>" +
                                        $(
                                            "#file-ext-" +
                                                json.attachmentDefinitionId
                                        )
                                            .val()
                                            .replace(",", ", ") +
                                        "<br/><br/>";
                                    $(this)
                                        .find(".uploadifyError")
                                        .html(msg);
                                }
                            },
                            error: function(data, status, err) {
                                var msg =
                                    "Error getting: " + urlAction + "\n\n";
                                msg += "Status: " + status + "\n\n";
                                // msg += "Parameters: " + JSON.stringify(parameters) + "\n\n";
                                console.log(msg);
                            },
                            complete: function() {
                                skyword.setupSave();
                            }
                        };
                        $("#crop-container").hide();
                        $("#btn-crop-overlay").hide();
                        $("#prepare-container").show();
                        $(this).colorbox.resize();
                        call.ajax(urlAction, options);
                        return false;
                    });

                    setupRemoveAttachment();
                });
            }
        };
    }
]);

app.directive("searchResultDetail", function() {
    return {
        templateUrl: "/partials/directives/searchResultDetail.html",
        replace: false,
        restrict: "E", // Example: <search-result-detail title="" description="" date=""></search-result-detail>
        scope: {
            title: "=title",
            description: "=description",
            date: "=date",
            author: "=author",
            project: "=project",
            topic: "=topic",
            language: "=language",
            category: "=category",
            image: "=image",
            source: "=source",
            tags: "=tags",
            review: "=review",
            download: "=download",
            size: "=size",
            dimensions: "=dimensions",
            fileType: "=fileType",
            searchResultTypeId: "=searchResultTypeId",
            ngModel: "=ngModel",
            programName: "=programName",
            enableContentExport: "=enableContentExport",
            stateId: "=stateId",
            contentPackageId: "=contentPackageId",
            contentPackageName: "=contentPackageName"
        }
    };
});

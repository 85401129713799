/*
eslint-disable
*/
app.controller("newProjectModalController", [
    "$scope",
    "$http",
    "$filter",
    "$timeout",
    "skyword",
    "$routeParams",
    "$sce",
    "$location",
    "$translate",
    "calendarEvent",
    "$window",
    "$rootScope",
    "project",
    "ProjectFactory",
    function(
        $scope,
        $http,
        $filter,
        $timeout,
        skyword,
        $routeParams,
        $sce,
        $location,
        $translate,
        calendarEvent,
        $window,
        $rootScope,
        project,
        ProjectFactory
    ) {
        $scope.milliFormat = $translate.instant("angular.dateformat");
        // line bellow should fix:
        // https://skyword.atlassian.net/browse/SKY-14771
        $translate("angular.momentpicker.format").then(function(dateformat) {
            $scope.dateformat = dateformat;

            var startDate = moment().format($scope.dateformat.toUpperCase());
            var endDate = startDate;
            $scope.calEventForm = {};
            $scope.createProjectForm = {};
            //$scope.calendarEventId = $scope.emptyCheckToNull($routeParams.calendarEventId);
            $scope.startDate = $scope.emptyCheck($routeParams.startDate);
            // console.log("start date: " + $scope.startDate);
            //
            if (-1 !== $scope.startDate) {
                endDate = startDate = $scope.convertMillisToDate(
                    $scope.startDate,
                    $scope.dateformat.toUpperCase()
                );
            }

            $scope.createProjectForm.projectStartDate = startDate;
            $scope.createProjectForm.projectEndDate = endDate;
            $scope.programId = $scope.emptyCheck($routeParams.programId);

            //set min date on end date field
            $scope.minDate = $scope.createProjectForm.projectStartDate.toString();
        });

        //watches start date to make sure end date is not before start date
        $scope.$watch("createProjectForm.projectStartDate", function(
            newValue,
            oldValue
        ) {
            $scope.minDate = $scope.createProjectForm.projectStartDate.toString();
        });

        /*$scope.checkProjectDate = function () {
                var error = false;
                //$scope.errorsStartDate = false;
                //$scope.errorsEndDate = false;
                //if start date is after end date
                var startDate = $scope.emptyCheck($scope.convertDateToMillis($scope.createProjectForm.projectStartDate, $scope.dateformat));
                var endDate = $scope.emptyCheck($scope.convertDateToMillis($scope.createProjectForm.projectEndDate, $scope.dateformat));
                if (-1 === startDate) {
                    $scope.createProjectForm.projectStartDate = moment().format($scope.dateformat.toUpperCase());
                }
                if (-1 === endDate) {
                    $scope.createProjectForm.projectEndDate = $scope.createProjectForm.projectStartDate;
                }
                if (startDate > endDate) {
                    $scope.createProjectForm.projectEndDate = $scope.createProjectForm.projectStartDate;
                    error = false;
                } else if (startDate === endDate) {
                    error = false;
                } else {
                    var errorsStartDate = (-1 === startDate);
                    var errorsEndDate = (-1 === endDate);
                    if (errorsStartDate || errorsEndDate) {
                        //console.log("Project Date Errors. " + startDate + " " + endDate);
                        error = true;
                    }
                }
                return error;
            };*/

        /**
         * Will resize a modal after errors appeared, will fix issue: https://skyword.atlassian.net/browse/SKY-14871?filter=-1
         */
        function resizeModal() {
            $timeout(function() {
                $("#colorbox").colorbox.resize({});
            }, 1);
        }

        /**
         * Create a new project by first validating the form and then adding it in database
         */
        $scope.createNewProject = function(projectForm) {
            // initialize
            $scope.isErrorProjectName = false;
            //TOFIX: @Adam this has issues: $scope.checkProjectDate();
            if (
                angular.isUndefined(projectForm.projectName) ||
                "" === projectForm.projectName ||
                null === $scope.emptyCheckToNull(projectForm.projectName)
            ) {
                $scope.isErrorProjectName = true;
                resizeModal();
                return;
            }

            var newProject = angular.copy(projectForm); // avoid scope clashes when converting the dates, because reasons
            newProject.projectStartDate = $scope.convertDateToMillis(
                moment(projectForm.projectStartDate, $scope.dateformat).format(
                    "MM-DD-YYYY"
                ),
                $scope.milliFormat
            );
            newProject.projectEndDate = $scope.convertDateToMillis(
                moment(projectForm.projectEndDate, $scope.dateformat).format(
                    "MM-DD-YYYY"
                ),
                $scope.milliFormat
            );
            // save in database
            project.saveNewProject($scope.programId, newProject, function(
                data
            ) {
                if (data.programId) {
                    ProjectFactory.setProject(data); // To carry data over to "projectChanged" receiver.
                    //console.log(data);
                    //console.log("in success");
                    project.getActiveProjects(
                        data.programId,
                        true,
                        false,
                        function(data) {
                            $scope.calEventForm.projects = data;
                            //console.log("-->PROJECTS ", $scope.calEventForm.projects);
                            $rootScope.$emit(
                                "projectsChanged",
                                $scope.calEventForm.projects
                            );
                        }
                    );
                }
            });
        };
    }
]).factory("ProjectFactory", function() {
    // Added to pass along data between the Projects and other controllers.

    var data = {
        project: {},
        donotAssign: {}
    };

    return {
        setDonotAssign: function(project) {
            data.donotAssign = project;
        },
        getDonotAssign: function() {
            return data.donotAssign;
        },
        setProject: function(project) {
            data.project = project;
        },
        getProject: function() {
            return data.project;
        }
    };
});

(function() {
    app.directive("ideaPlannerItem", function() {
        return {
            templateUrl: "/partials/ideas/listitem-planner.html",
            replace: false,
            restrict: "EA", // Example: <idea-result title="" summary="" date=""></idea-result>
            scope: {
                title: "=title",
                summary: "=summary",
                attachmentId: "=attachmentId",
                view: "=view",
                formReady: "=formReady",
                targetDate: "=targetDate",
                category: "=category",
                dateApproved: "=dateApproved",
                dateIdeaSubmitted: "=dateIdeaSubmitted",
                programCategoryName: "=programCategoryName",
                dateCreated: "=dateCreated",
                contentRequestId: "=contentRequestId",
                accountFirstNameAndLastName: "=accountFirstNameAndLastName",
                byline: "=byline",
                contentTypeName: "=contentTypeName",
                stateId: "=stateId",
                id: "=id",
                accountId: "=accountId",
                publishedUrl: "=publishedUrl", // ?
                accountProfilePicture: "=accountProfilePicture",
                ngModel: "=ngModel"
            }
        };
    });
})();

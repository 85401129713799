app.controller("contentSamples", [
    "$scope",
    "$translate",
    "$filter",
    "contentSamples",
    "skyword",
    "call",
    "setup",
    "$mdDialog",
    function(
        $scope,
        $translate,
        $filter,
        contentSamples,
        skyword,
        call,
        setup,
        $mdDialog
    ) {
        $scope.init = function() {
            $scope.keyword = "";

            $scope.isUserSearch = $scope.userSearch === "true" ? true : false;
            if ($scope.isUserSearch) {
                $scope.countViewableSamples();
            }
            $scope.getContentSamples();
            $scope.getSampleTypes();
            if ($scope.contributorTypeWriter === "true") {
                $scope.contributorTypes.push({
                    id: 1,
                    contributorType: "writer",
                    displayName:
                        "angular.contributorjoin.sampletab.title.writer",
                    url: "addContentSample.action"
                });
            }
            if ($scope.contributorTypeVideographer === "true") {
                $scope.contributorTypes.push({
                    id: 2,
                    contributorType: "videographer",
                    displayName:
                        "angular.contributorjoin.sampletab.title.videographer",
                    url: "addContentSample.action?type=video"
                });
            }
            if ($scope.contributorTypePhotographer === "true") {
                $scope.contributorTypes.push({
                    id: 3,
                    contributorType: "photographer",
                    displayName:
                        "angular.contributorjoin.sampletab.title.photographer",
                    url: "addContentSamplePhoto.action"
                });
            }
            if ($scope.contributorTypeDesigner === "true") {
                $scope.contributorTypes.push({
                    id: 4,
                    contributorType: "designer",
                    displayName:
                        "angular.contributorjoin.sampletab.title.designer",
                    url: "addContentSampleDesign.action"
                });
            }

            $scope.currentContentType = $scope.contributorTypes[0].id;

            $("#profile").on("click", function() {
                $("md-tab-item")
                    .first()
                    .click();
            });
            $("#contentSampleSearchForm").submit(function(e) {
                e.preventDefault();
            });
            $("#sample-keyword").keydown(function(e) {
                if (e.which == 13) {
                    $("form#contentSampleSearchForm").submit();
                    e.preventDefault();
                    return false;
                }
            });
            $scope.incompleteSamples = [false, false, false, false];
        };
        $scope.currentAction = location.pathname.substring(1);
        $scope.editAction =
            "joinAddContentSample.action" === $scope.referringPage
                ? "joinEditContentSample"
                : "editContentSample";

        $scope.searchParams = [];

        $scope.sampleTypes = [];

        $scope.filteredData = [];

        $scope.contributorTypes = [];

        /**
         * Get samples type available for this particular user
         */
        $scope.getSampleTypes = function() {
            //console.log("xxxx" +  $scope.contributorId);
            $scope.prepSearchParams();
            contentSamples.getViewableSampleTypes($scope.searchParams, function(
                viewableSampleTypes
            ) {
                $scope.sampleTypes = viewableSampleTypes;
                $scope.sampleType = skyword.getObjByProperty(
                    $scope.sampleTypes,
                    "id",
                    parseInt(-1)
                );
            });
        };

        $scope.prepSearchParams = function() {
            $scope.searchParams.isUserSearch = $scope.isUserSearch;
            //  $scope.searchParams.keyword = $scope.keyword;
            $scope.searchParams.sampleType = $scope.sampleType
                ? $scope.sampleType.id
                : undefined;
            $scope.searchParams.contributorId = $scope.contributorId;
            $scope.searchParams.programId = $scope.programId;
            $scope.searchParams.agencyId = $scope.agencyId;
        };

        $scope.countViewableSamples = function() {
            $scope.prepSearchParams();
            contentSamples.countViewableSamples($scope.searchParams, function(
                viewableCount
            ) {
                $scope.viewableContentSamplesCount = viewableCount;
                if ($scope.numberOfAllSamples === undefined) {
                    $scope.numberOfAllSamples = viewableCount;
                }
            });
            return false;
        };

        $scope.filterResults = function() {
            var outArry = [];
            angular.forEach($scope.originalData, function(itm, idx) {
                // domain
                if (itm.domain && itm.domain.indexOf($scope.keyword) >= 0) {
                    if (outArry.indexOf(itm) == -1) {
                        outArry.push(itm);
                    }
                }
                // title
                if (
                    itm.title &&
                    itm.title
                        .toLowerCase()
                        .indexOf($scope.keyword.toLowerCase()) >= 0
                ) {
                    if (outArry.indexOf(itm) == -1) {
                        outArry.push(itm);
                    }
                }
                // description
                if (
                    itm.description &&
                    itm.description
                        .toLowerCase()
                        .indexOf($scope.keyword.toLowerCase()) >= 0
                ) {
                    if (outArry.indexOf(itm) == -1) {
                        outArry.push(itm);
                    }
                }
                //TODO: filter on sampleTopic
                //TODO: filter on language
                // tags
                if (
                    itm.tags &&
                    itm.tags
                        .toLowerCase()
                        .indexOf($scope.keyword.toLowerCase()) >= 0
                ) {
                    if (outArry.indexOf(itm) == -1) {
                        outArry.push(itm);
                    }
                }

                if (!itm.isComplete) {
                    $scope.incompleteSamples[
                        itm.contentSampleTypeId - 1
                    ] = true;
                }

                if (itm.contentSampleTypeId == 1) {
                    itm.isPdf = false;
                    if (itm.selectedImage) {
                        itm.isPdf =
                            itm.selectedImage.filename.indexOf(".pdf") != -1;
                    }
                } else if (
                    itm.contentSampleTypeId == 3 ||
                    itm.contentSampleTypeId == 4
                ) {
                    itm.isPdf =
                        itm.selectedImage.filename.indexOf(".pdf") != -1;
                    itm.imageUrl = itm.selectedImage.thumbFilename;
                }
            });
            $scope.data = outArry;
            $scope.populateResults($scope.currentContentType);
        };

        $scope.getContentSamples = function() {
            $scope.prepSearchParams();
            if ($scope.isUserSearch) {
                contentSamples.getSamples($scope.searchParams, function(data) {
                    $scope.processData(data);
                });
            } else {
                contentSamples.getSamplesContributor(
                    $scope.searchParams,
                    function(data) {
                        $scope.processData(data);
                        $scope.viewableContentSamplesCount = data.length;
                        if ($scope.numberOfAllSamples == undefined) {
                            $scope.numberOfAllSamples = data.length;
                        }
                    }
                );
            }
            return false;
        };

        $scope.processData = function(data) {
            $scope.state = "dataloading";
            $scope.originalData = data;
            $scope.data = data;
            $scope.state = "dataready";
            $scope.filterResults();
        };

        $scope.videoOverlay = function(id) {
            call.overlay(
                "loadContentSampleVideoAjax.action?contentSampleId=" + id,
                null,
                650,
                null,
                "GET",
                function() {
                    mw.setConfig("Kaltura.LeadWithHTML5", true);
                    kWidget.embed({
                        targetId: "video-player",
                        wid: "_1737042",
                        uiconf_id: 23937082,
                        flashvars: {
                            allowFullScreen: true,
                            allowNetworking: "all",
                            allowScriptAccess: "always",
                            autoPlay: true,
                            strings: {
                                "mwe-embedplayer-missing-source": $translate.instant(
                                    "angular.videoCommenting.videoNotTranscodedYet"
                                )
                            }
                        },
                        cache_st: 1406907495,
                        entry_id: $("#kaltura-id").val()
                    });
                }
            );
        };

        $scope.populateResults = function(typeId) {
            $scope.filteredData = $filter("filter")($scope.data, {
                contentSampleTypeId: typeId
            });
            $scope.currentContentType = typeId;
            $scope.setupPagination();
        };

        $scope.setupPagination = function() {
            $scope.totalItems = $scope.filteredData.length;
            $scope.paginationHideShow = false;
            if (
                $scope.currentContentType === 3 ||
                $scope.currentContentType === 4
            ) {
                $scope.viewby = 20;
            } else {
                $scope.viewby = 5;
            }

            if ($scope.totalItems > $scope.viewby) {
                $scope.paginationHideShow = true;
            }
            $scope.currentPage = 1;
            $scope.itemsPerPage = $scope.viewby;
            $scope.maxSize = 5; //Number of pager buttons to show
        };

        $scope.setPage = function(pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.getCount = function(sampleTypeId) {
            if ($scope.data) {
                return $filter("filter")($scope.data, {
                    contentSampleTypeId: sampleTypeId
                }).length;
            }
        };

        $scope.init();

        /**
         * Return the content sample selected image URL
         */
        $scope.getWrittenContentSampleSelectedImage = function(contentSample) {
            // get selected picture
            if (
                typeof contentSample != "undefined" &&
                typeof contentSample.contentSampleImages != "undefined"
            ) {
                for (
                    var i = 0;
                    i < contentSample.contentSampleImages.length;
                    i++
                ) {
                    if (contentSample.contentSampleImages[i].selected) {
                        return contentSample.contentSampleImages[i].localUrl;
                    }
                }
            }
            // return default picture
            return "/styles/images/ContentItemDefaultImage.jpg";
        };

        $scope.showRemoveConfirm = function(contentSample) {
            $mdDialog
                .show({
                    controller: "DialogController",
                    templateUrl: "/partials/ng-material-default-overlay.html",
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    locals: {
                        title: $translate.instant(
                            "angular.contentsample.header.confirmremove"
                        ),
                        ok: $translate.instant("angular.button.remove"),
                        cancel: $translate.instant("angular.button.cancel"),
                        content: undefined
                    }
                })
                .then(
                    function() {
                        var promise = contentSamples.removeContentSample(
                            contentSample.id
                        );
                        promise.then(function(result) {
                            var index = $scope.data.indexOf(contentSample);
                            $scope.data.splice(index, 1);
                            $scope.populateResults($scope.currentContentType);
                        });
                    },
                    function() {
                        console.log("error removing contentSample");
                    }
                );
        };

        $scope.showPhotoDesignDetailProgramUser = function(item) {
            $mdDialog.show({
                controller: "DialogController",
                templateUrl:
                    "/partials/contentSample/content-sample-photo-design-detail.html",
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                locals: {
                    title: "",
                    ok: "",
                    cancel: "",
                    content: { contentSample: item }
                }
            });
        };
    }
]);

app.directive("contributorSampleListItemWrittenVideo", function() {
    return {
        templateUrl:
            "/partials/directives/contributor-sample-list-item-written-video.html",
        restrict: "E"
    };
});

app.directive("contributorSampleListItemPhotoDesign", function() {
    return {
        templateUrl:
            "/partials/directives/contributor-sample-list-item-photo-design.html",
        restrict: "E"
    };
});

app.directive("imageonload", function() {
    return {
        restrict: "A",
        link: function(scope, element) {
            element.bind("load", function() {
                $(".photo-design-image-loader").hide();
                $(".photo-design-detail-image-wrapper").show();
            });
        }
    };
});

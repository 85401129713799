app.service("validationUtil", [
    "skyword",
    function(skyword) {
        return {
            isBlank: function(str) {
                var retVal = false;
                try {
                    retVal = undefined === str || null === str || "" === str;
                } catch (e) {
                    retVal = true;
                }
                return retVal;
            },

            // TODO: Get rid of Multiple return points - they just create confusion.
            parse: function(text, id) {
                var $this = this,
                    i;
                try {
                    var json,
                        previousError = "";
                    $("#error-box .content").html("");
                    $("#error-box").hide();
                    $("#manager-error-box").hide();
                    $("#success-box .content").html("");
                    $("#success-box").hide();
                    if ("object" === typeof text) {
                        json = text;
                    } else {
                        text = $this.decode(text);
                        json = $.parseJSON(text);
                    }
                    $(".errorField").removeClass("errorField");
                    if (!$this.isBlank(json.type)) {
                        id = json.type;
                    }
                    if (!$this.isBlank(id)) {
                        $("#" + id + " .content").html("");
                        $("#" + id + "-error-box .content").html("");
                        $("#" + id + "-success-box .content").html("");
                    } else {
                        $("#error-box .content").html("");
                        $("#success-box .content").html("");
                    }
                    if (true === json.reloadAjax) {
                        skyword.reloadLastAjaxCall();
                        return true;
                    }
                    if (!$this.isBlank(json.redirectionTarget)) {
                        window.location.href = "/" + json.redirectionTarget;
                        return true;
                    }
                    //console.log("typeof " + (typeof json.closeWindow));
                    //console.log("true === json.closeWindow " + (true === json.closeWindow));
                    if (true === json.closeWindow) {
                        jQuery.colorbox.close();
                        //return true;
                    }
                    if (!$this.isBlank(json.message)) {
                        //Generic message. Used on assignmentView.action, we really need improve this message thing
                        if (true === json.globalMessage) {
                            if (null !== id) {
                                $("#" + id + "-message-box .content").append(
                                    json.message
                                );
                                $("#" + id + "-message-box").show();
                            } else {
                                $("#message-box .content").append(json.message);
                                $("#message-box").show();
                            }
                        }
                        return true;
                    } else if (
                        (true === json.success ||
                            0 < json.successMessages.length) &&
                        true === json.globalMessage
                    ) {
                        if (0 < json.successMessages.length) {
                            $.each(json.successMessages, function(k, message) {
                                if (!$this.isBlank(id)) {
                                    $(
                                        "#" + id + "-success-box .content"
                                    ).append(
                                        "<li>" +
                                            $("<span/>")
                                                .html(message)
                                                .text() +
                                            "</li>"
                                    );
                                } else {
                                    $("#success-box .content").append(
                                        "<li>" +
                                            $("<span/>")
                                                .html(message)
                                                .text() +
                                            "</li>"
                                    );
                                    $("#success-box").show();
                                    skyword.scrollTo("#success-box", 20);
                                }
                            });
                        } else {
                            if (!$this.isBlank(id)) {
                                $("#" + id + "-success-box .content").append(
                                    "<li>" + json.success.message + "</li>"
                                );
                                $("#" + id + "-success-box").show();
                                skyword.scrollTo("#" + id + "-success-box", 20);
                            } else {
                                $("#success-box .content").append(
                                    "<li>" + json.success.message + "</li>"
                                );
                                $("#success-box").show();
                                skyword.scrollTo("#success-box", 20);
                            }
                        }
                        return true;
                    } else {
                        if ($this.isBlank(json.errorMessage)) {
                            if (true === json.globalMessage) {
                                if (1 < json.errorMessages.length) {
                                    if (!$this.isBlank(id)) {
                                        $(
                                            "#" + id + "-error-box .content"
                                        ).removeClass("single");
                                    } else {
                                        $("#error-box .content").removeClass(
                                            "single"
                                        );
                                    }
                                } else {
                                    if (!$this.isBlank(id)) {
                                        $(
                                            "#" + id + "-error-box .content"
                                        ).addClass("single");
                                    } else {
                                        $("#error-box .content").addClass(
                                            "single"
                                        );
                                    }
                                }
                                $.each(json.errorMessages, function(k, err) {
                                    if (!$this.isBlank(id)) {
                                        $(
                                            "#" + id + "-error-box .content"
                                        ).append(
                                            "<li>" +
                                                $("<span/>")
                                                    .html(err)
                                                    .text() +
                                                "</li>"
                                        );
                                    } else {
                                        $("#error-box .content").append(
                                            "<li>" +
                                                $("<span/>")
                                                    .html(err)
                                                    .text() +
                                                "</li>"
                                        );
                                        $("#error-box").show();
                                    }
                                });
                            }
                        } else {
                            if (true === json.globalMessage) {
                                if (
                                    undefined !== id &&
                                    null !== id &&
                                    "" !== id
                                ) {
                                    $("#" + id + "-error-box .content").append(
                                        "<li>" +
                                            $("<span/>")
                                                .html(json.errorMessage)
                                                .text() +
                                            "</li>"
                                    );
                                } else {
                                    $("#error-box .content").append(
                                        "<li>" +
                                            $("<span/>")
                                                .html(json.errorMessage)
                                                .text() +
                                            "</li>"
                                    );
                                    $("#error-box").show();
                                }
                            }
                        }
                        $.each(json.errorFields, function(k, field) {
                            var el = document.getElementsByName(field)[0],
                                redactor,
                                ele = null,
                                msContainer,
                                spanMce,
                                redactorEl;
                            if ($(el).hasClass("tinyMceContainer")) {
                                el = $(el)
                                    .children()
                                    .first();
                            }
                            ele = $(el);
                            //console.log(field);
                            redactorEl = $(
                                document.getElementsByName(field)[0]
                            );
                            ele.addClass("errorField");

                            if (
                                redactorEl.hasClass("redactor") ||
                                redactorEl.hasClass("redactorText") ||
                                redactorEl.hasClass("redactorCaption") ||
                                redactorEl.hasClass("titleField") ||
                                redactorEl.hasClass("singleLine") ||
                                redactorEl.hasClass("plainText") ||
                                redactorEl.hasClass("mce-tinymce") ||
                                redactorEl.hasClass("tinyMce") ||
                                redactorEl.hasClass("tinyMceLite")
                            ) {
                                //console.log('redactorEl.hasClass("singleLine") ' + redactorEl.hasClass("singleLine"));
                                redactor = redactorEl.closest(".redactor_box");
                                //console.log(redactorEl);
                                //console.log(redactor);
                                redactor.addClass("errorField");
                                if (redactor.prev().hasClass("mce-tinymce")) {
                                    redactor.prev().addClass("errorField");
                                }
                                //console.log("redactorbox has error: " + redactor.hasClass("errorField"));
                            } else if (redactorEl.hasClass("skywordSingle")) {
                                msContainer = redactorEl.siblings(
                                    ".chzn-container"
                                );
                                msContainer.addClass("errorField");
                            } else if (ele.hasClass("attachmentElement")) {
                                spanMce = ele.siblings(
                                    "div.attachmentContainer"
                                );
                                var kalturaUploader = spanMce.find(
                                    ".kaltura.uploadModule"
                                );
                                if (kalturaUploader != null) {
                                    kalturaUploader.addClass("errorField");
                                }
                                spanMce.addClass("errorField");
                            } else if (ele.hasClass("croppedImage")) {
                                ele.addClass("errorField");
                            } else if (ele.hasClass("forSkywordMultiple")) {
                                msContainer = ele.siblings("div.ms-container");
                                msContainer.find(">div").addClass("errorField");
                            }
                        });
                        try {
                            if (true === json.globalMessage) {
                                if (!$this.isBlank(id)) {
                                    $("#" + id + "-error-box").fadeIn();
                                    skyword.scrollTo(
                                        "#" + id + "-error-box",
                                        20
                                    );
                                } else {
                                    if ($("#error-box").length > 0) {
                                        $("#error-box").fadeIn();
                                        skyword.scrollTo("#error-box", 20);
                                    }
                                }
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                    return true;
                } catch (e) {
                    console.log("Ajax request returned html " + e);
                    return false;
                }
            },
            decode: function(s) {
                for (
                    var a,
                        b,
                        i = -1,
                        l = (s = s.split("")).length,
                        o = String.fromCharCode,
                        c = "charCodeAt";
                    ++i < l;
                    (a = s[i][c](0)) & 0x80 &&
                    ((s[i] =
                        (a & 0xfc) == 0xc0 &&
                        ((b = s[i + 1][c](0)) & 0xc0) == 0x80
                            ? o(((a & 0x03) << 6) + (b & 0x3f))
                            : o(128)),
                    (s[++i] = ""))
                ) {}
                return s.join("");
            }
        };
    }
]);

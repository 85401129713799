(function contributorAgreementService() {
    app.service("ContributorAgreementService", [
        "$mdDialog",
        "$http",
        "$window",
        function contributoragreementserviceinit(
            $mdDialog,
            $http,
            $window
        ) {
            var contributorAgreement = false;
            var privacyPolicy = false;
            var accountId = document
                .getElementById("attrs-id")
                .getAttribute("data-accountid");
            var actionName = document
                .getElementById("attrs-id")
                .getAttribute("data-actionname");

            return {
                contributorAgreementDialog: contributorAgreementDialog,
                contributorAgreementCompleted: contributorAgreementCompleted
            };

            function contributorAgreementDialog(ev) {
                contributorAgreement = false;
                $mdDialog.show({
                    templateUrl:
                        "/partials/contributorJoin/contributor-agreement-dialog.html",
                    parent: angular.element(document.body),
                    targetEvent: ev,
                    clickOutsideToClose: false,
                    escapeToClose: false,
                    locals: {
                        callback: contributorAgreementCompleted,
                        contributorAgreement: contributorAgreement
                    },
                    controller: function($scope, callback, contributorJoin, $sce) {
                        var contributorAgreementPromise = contributorJoin.getContributorAgreement();
                        contributorAgreementPromise.then(function(result) {
                            $scope.contributorAgreementText = result.data;
                        });
                        $scope.to_trusted = function(html_code) {
                            return $sce.trustAsHtml(html_code);
                        };
                        $scope.contributorAgreementCompleted = function(){
                            callback();
                        };
                        $scope.cancelAgreement = function() {
                            $mdDialog.hide();
                            privacyPolicy = false;
                            contributorAgreement = false;
                        };
                    }
                });
            }

            function contributorAgreementCompleted() {
                contributorAgreement = true;
                $mdDialog.hide();
                setTimeout(function(){
                    privacyPolicyDialog();
                }, 500);
            }

            function privacyPolicyDialog(ev) {
                privacyPolicy = false;
                $mdDialog.show({
                    templateUrl:
                        "/partials/contributorJoin/privacy-policy-dialog.html",
                    parent: angular.element(document.body),
                    targetEvent: ev,
                    clickOutsideToClose: false,
                    escapeToClose: false,
                    locals: {
                        callback: privacyPolicyCompleted,
                        privacyPolicy: privacyPolicy
                    },
                    controller: function($scope, callback) {
                        $scope.privacyPolicyCompleted = function(){
                            callback();
                        };
                        $scope.cancelAgreement = function() {
                            $mdDialog.hide();
                            privacyPolicy = false;
                            contributorAgreement = false;
                        };
                    }
                });
            }

            function privacyPolicyCompleted() {
                privacyPolicy = true;
                if(accountId && actionName !== "contributorJoin") {
                    saveContributorAgreements(Number(accountId));
                }
                $mdDialog.hide();
            }

            function saveContributorAgreements(accountId) {
                $http.post("/API/contributor/agreements-signed", accountId).then(function () {
                    $window.location.reload(true);
                });
            }

        }
    ]);
})();

app.controller("styleguide", ["$scope", function($scope) {}])
    .controller("sg-universalPageElements", ["$scope", function($scope) {}])
    .controller("sg-layout", ["$scope", function($scope) {}])
    .controller("sg-dates", ["$scope", function($scope) {}])
    .controller("sg-buttons", ["$scope", function($scope) {}])
    .controller("sg-radio", ["$scope", function($scope) {}])
    .controller("sg-onOff", ["$scope", function($scope) {}])
    .controller("sg-checkbox", ["$scope", function($scope) {}])
    .controller("sg-selectbox", ["$scope", function($scope) {}])
    .controller("sg-icons", ["$scope", function($scope) {}])
    .controller("sg-components", ["$scope", function($scope) {}])
    .controller("sg-taglib", ["$scope", function($scope) {}])
    .controller("sg-graphs", ["$scope", function($scope) {}])
    .controller("sg-graphs-lineAjax", ["$scope", function($scope) {}])
    .controller("sg-graphs-stackedAjax", ["$scope", function($scope) {}])
    .controller("sg-graphs-pieAjax", ["$scope", function($scope) {}])
    .controller("sg-cards", ["$scope", function($scope) {}])
    .controller("demo-tables", ["$scope", function($scope) {}])
    .controller("demo-contentContainer", ["$scope", function($scope) {}])
    .controller("demo-dashboard", ["$scope", function($scope) {}])
    .controller("demo-messages", ["$scope", function($scope) {}]);

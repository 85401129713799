app.service("contentSamples", [
    "$http",
    function($http) {
        return {
            buildUrlQueryParams: function(searchParams) {
                console.log("building query param string...");
                var queryString;
                if (searchParams.sampleType === 1) {
                    queryString = "filter=article";
                } else if (searchParams.sampleType === 2) {
                    queryString = "filter=video";
                } else if (searchParams.sampleType === 3) {
                    queryString = "filter=incomplete";
                }

                if (searchParams.keyword) {
                    if (queryString) {
                        queryString += "&";
                    }
                    queryString += "searchPhrase=" + searchParams.keyword;
                }

                if (queryString) {
                    queryString = "?" + queryString;
                }

                return queryString;
            },
            getSamplesContributor: function(contentSampleTypes, callback) {
                console.log("getting content samples, contributor profile...");
                var filterParams = "";
                var searchPhraseParams = "";
                var queryString = "";

                if (contentSampleTypes.sampleType === 1) {
                    queryString = "filter=article";
                } else if (contentSampleTypes.sampleType === 2) {
                    queryString = "filter=video";
                } else if (contentSampleTypes.sampleType === 3) {
                    queryString = "filter=incomplete";
                }

                if (contentSampleTypes.keyword) {
                    if (queryString) {
                        queryString +=
                            "&" + "searchPhrase=" + contentSampleTypes.keyword;
                    } else {
                        queryString =
                            "searchPhrase=" + contentSampleTypes.keyword;
                    }
                }

                if (queryString) {
                    queryString = "?" + queryString;
                }

                console.log("queryString=" + queryString);
                $http({
                    url: "/API/contentSample/contributor" + queryString, // filterParams + searchPhraseParams,
                    method: "GET"
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            getSamples: function(searchParams, callback) {
                console.log("searching content samples, user...");
                var pathString =
                    searchParams.contributorId +
                    "/" +
                    (searchParams.agencyId ? searchParams.agencyId : -1) +
                    "/" +
                    (searchParams.programId ? searchParams.programId : -1);
                var queryString;
                if (searchParams.sampleType === 1) {
                    queryString = "filter=article";
                } else if (searchParams.sampleType === 2) {
                    queryString = "filter=video";
                } else if (searchParams.sampleType === 3) {
                    queryString = "filter=incomplete";
                }

                if (searchParams.keyword) {
                    if (queryString) {
                        queryString +=
                            "&" + "searchPhrase=" + searchParams.keyword;
                    } else {
                        queryString = "searchPhrase=" + searchParams.keyword;
                    }
                }

                if (queryString) {
                    queryString = "?" + queryString;
                }

                console.log("queryString=" + queryString);
                $http({
                    url:
                        "/API/contentSample/user/" +
                        pathString +
                        (queryString ? queryString : ""),
                    method: "GET"
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            countViewableSamples: function(searchParams, callback) {
                console.log("determining number of samples this user can view");
                var pathString =
                    searchParams.contributorId +
                    "/" +
                    (searchParams.agencyId ? searchParams.agencyId : -1) +
                    "/" +
                    (searchParams.programId ? searchParams.programId : -1);

                console.log("building query param string...");
                var queryString;
                if (searchParams.sampleType === 1) {
                    queryString = "filter=article";
                } else if (searchParams.sampleType === 2) {
                    queryString = "filter=video";
                } else if (searchParams.sampleType === 3) {
                    queryString = "filter=incomplete";
                }

                if (searchParams.keyword) {
                    if (queryString) {
                        queryString += "&";
                        queryString += "searchPhrase=" + searchParams.keyword;
                    } else {
                        queryString = "searchPhrase=" + searchParams.keyword;
                    }
                }

                if (queryString) {
                    queryString = "?" + queryString;
                }
                $http({
                    url:
                        "/API/contentSample/user/viewableCount/" +
                        pathString +
                        (queryString ? queryString : ""),
                    method: "GET"
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            getViewableSampleTypes: function(searchParams, callback) {
                var pathString =
                    (searchParams.agencyId ? searchParams.agencyId : -1) +
                    "/" +
                    (searchParams.programId ? searchParams.programId : -1);
                // if contributor id defined
                if (typeof searchParams.contributorId != "undefined") {
                    pathString +=
                        "/contributorId/" + searchParams.contributorId;
                }
                var queryString;
                if (searchParams.isUserSearch === true) {
                    queryString = "?isProgramUser=true";
                }
                console.log(
                    "getViewableSampleTypes: queryString=" + queryString
                );
                $http({
                    url:
                        "/API/contentSample/sampleTypes/" +
                        pathString +
                        (queryString ? queryString : ""),
                    method: "GET"
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                    });
            },
            removeContentSample: function(contentSampleId) {
                return $http.put(
                    "/API/contentSample/remove-sample",
                    contentSampleId
                );
            }
        };
    }
]);

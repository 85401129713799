app.service("skywordGraphs", [
    "call",
    function(call) {
        return {
            getGraph: function(parameters) {
                var graph = this,
                    options = {
                        url: parameters.action,
                        data: parameters.ajaxParams,
                        cache: false,
                        dataType: "json",
                        success: function(data) {
                            $("#" + parameters.location).removeClass("loading");
                            if (data instanceof Object) {
                                parameters.graphData = data;
                            } else {
                                parameters.graphData = $.parseJSON(
                                    $.trim(data)
                                );
                            }
                            if (null !== parameters.graphData) {
                                switch (parameters.graphType) {
                                    case "line":
                                        graph.createLineGraph(parameters);
                                        break;
                                    case "area":
                                        graph.createAreaGraph(parameters);
                                        break;
                                    case "pie":
                                        graph.createPieChart(parameters);
                                        break;
                                    case "doughnut":
                                        graph.createPieChart(parameters);
                                        break;
                                    case "bar":
                                        graph.createBarChart(parameters);
                                        break;
                                    case "stacked":
                                        graph.createStackedLineChart(
                                            parameters
                                        );
                                        break;
                                    case "gauge":
                                        graph.createGauge(parameters);
                                        break;
                                    case "multiline":
                                        graph.createMultiLineGraph(parameters);
                                        break;
                                }
                            } else {
                                if (
                                    null !== parameters.errorMessage &&
                                    undefined !== parameters.errorMessage
                                ) {
                                    $("#" + parameters.location).html(
                                        '<p class="no-results">' +
                                            parameters.errorMessage +
                                            "</p>"
                                    );
                                    $("#" + parameters.location).height(55);
                                } else {
                                    $("#" + parameters.location).height(0);
                                }
                            }
                        },
                        complete: function() {
                            app.value("requestActive", false);
                        }
                    };

                if (parameters.graphType == "gauge") {
                    graph.createGauge(parameters);
                } else {
                    call.ajax(options);
                }
            },
            createLineGraph: function(parameters) {
                parameters.graphType = "line";
                var graph = this,
                    xData = [],
                    yData = [],
                    jsonObj,
                    lines,
                    values,
                    i,
                    libArray = graph.getLibraries(parameters);
                switch (parameters.type) {
                    case "json":
                        jsonObj =
                            parameters.graphData instanceof Object
                                ? parameters.graphData
                                : $.parseJSON(parameters.graphData);
                        $.each(jsonObj, function(i, data) {
                            xData.push(data.x);
                            yData.push(data.y);
                        });
                        break;
                    case "iterator":
                        lines = parameters.graphData.split("\n");
                        for (i = 0; i < lines.length; i++) {
                            values = lines[i].split(";");
                            if ("" !== values[0].trim()) {
                                xData.push(values[0].trim());
                                yData.push(values[1].trim());
                            }
                        }
                        break;
                }
                parameters.xData = xData;
                parameters.yData = yData;
                graph.generateLineGraph(parameters);
            },
            createAreaGraph: function(parameters) {
                var graph = this,
                    xData = [],
                    yData = [],
                    jsonObj,
                    lines,
                    values,
                    i,
                    language = $("#userLanguage").val();
                parameters.graphType = "line";
                parameters.total = 0;
                jsonObj =
                    parameters.graphData instanceof Object
                        ? parameters.graphData
                        : $.parseJSON(parameters.graphData);
                $.each(jsonObj, function(i, data) {
                    xData.push(data.x);
                    yData.push(data.y);
                    data.x = new Date(data.x);
                    parameters.total += data.y;
                });

                if ("pt" == language) {
                    language = "pt-br";
                }
                numeral.language(language);
                $("#" + parameters.location)
                    .parent()
                    .find(".lineTotalFooter")
                    .show()
                    .find(".total")
                    .append(numeral(parameters.total).format("0,0"));
                $("#" + parameters.location)
                    .parent()
                    .find(".graphIcon")
                    .show();
                parameters.xData = xData;
                parameters.yData = yData;
                parameters.data = jsonObj;
                graph.generateAreaGraph(parameters);
            },
            createPieChart: function(parameters) {
                var graph = this,
                    jsonObj =
                        parameters.graphData instanceof Object
                            ? parameters.graphData
                            : $.parseJSON(parameters.graphData);
                parameters.total = 0;
                $.each(jsonObj, function(i, data) {
                    parameters.total += data.y;
                });
                $.each(jsonObj, function(i, data) {
                    if (data.usePrecaculatedPercent) {
                        data.label =
                            data.label +
                            " <span>" +
                            (data.percent * 1000) / 10 +
                            "%</span>";
                    } else if (data.y > 0) {
                        var percent = Math.round(
                            (data.y / parameters.total) * 100
                        );
                        //var percent = Math.round((data.y / parameters.total) * 1000) / 10;
                        if (percent == 0) {
                            percent = ">1";
                        }
                        if (parameters.labelType == "integer") {
                            data.label =
                                data.label + " <span>" + data.y + "</span>";
                        } else {
                            data.label =
                                data.label + " <span>" + percent + "%</span>";
                        }
                    }
                });
                graph.generatePieChart(parameters);
            },
            createChannelPieChart: function(parameters) {
                var graph = this,
                    jsonObj =
                        parameters.graphData instanceof Object
                            ? parameters.graphData
                            : $.parseJSON(parameters.graphData);

                $.each(jsonObj, function(i, data) {
                    data.label =
                        data.label +
                        " <span>" +
                        $.trim(data.percentage) +
                        "</span>";
                });
                graph.generatePieChart(parameters);
            },

            createStackedLineChart: function(parameters) {
                var graph = this,
                    line = [],
                    xData = [],
                    yData = [],
                    lineLabel = [],
                    jsonObj,
                    lines,
                    values,
                    lineTemp = [];
                parameters.dataPoints = [];
                parameters.yMax = 0;
                if ("[{}]" !== parameters.graphData) {
                    jsonObj =
                        parameters.graphData instanceof Object
                            ? parameters.graphData
                            : $.parseJSON(parameters.graphData);
                    $.each(jsonObj, function(i, data) {
                        var datas = data.data;
                        $.each(datas, function(i, datas) {
                            xData.push(datas.x);
                            yData.push(datas.y);
                            datas.x = new Date(datas.x);
                        });
                        parameters.yMax += graph.getMax(yData, "number", false);
                        var dataPoint = {
                            toolTipContent: parameters.popupFormat,
                            type: "stackedArea",
                            showInLegend: true,
                            legendText: data.label,
                            markerSize: 5,
                            markerType: "circle",
                            dataPoints: datas
                        };
                        parameters.dataPoints.push(dataPoint);
                    });
                    parameters.xMin = graph.getMin(xData, "date", false);
                    parameters.xMax = graph.getMax(xData, "date", false);
                }
                parameters.lineLabel = lineLabel;
                graph.generateStackedLineChart(parameters);
            },

            createMultiLineGraph: function(parameters) {
                var graph = this,
                    line = [],
                    xData = [],
                    yData = [],
                    lineLabel = [],
                    jsonObj,
                    lines,
                    values,
                    i,
                    lineTemp = [];
                parameters.dataPoints = [];
                parameters.yMax = 0;
                if ("[{}]" !== parameters.graphData) {
                    jsonObj =
                        parameters.graphData instanceof Object
                            ? parameters.graphData
                            : $.parseJSON(parameters.graphData);
                    $.each(jsonObj, function(i, data) {
                        var datas = data.data;
                        $.each(datas, function(i, datas) {
                            xData.push(datas.x);
                            yData.push(datas.y);
                            datas.x = new Date(datas.x);
                        });
                        parameters.yMax += graph.getMax(yData, "number", false);
                        var dataPoint = {
                            toolTipContent: parameters.popupFormat,
                            type: "line",
                            showInLegend: true,
                            legendText: data.label,
                            markerSize: 5,
                            markerType: "circle",
                            dataPoints: datas
                        };
                        parameters.dataPoints.push(dataPoint);
                    });
                    parameters.xMin = graph.getMin(xData, "date", false);
                    parameters.xMax = graph.getMax(xData, "date", false);
                }
                parameters.lineLabel = lineLabel;
                graph.generateMultiLineGraph(parameters);
            },
            createGauge: function(p) {
                try {
                    var graph = this,
                        container = document.getElementById(p.location),
                        location =
                            container.firstChild.nextSibling.nextSibling
                                .nextSibling.firstChild.nextSibling,
                        lineCan = location.nextSibling.nextSibling,
                        ctx = location.getContext("2d"),
                        percent = parseFloat(
                            $("#" + p.location)
                                .find("#percent")
                                .val()
                        ),
                        width = location.offsetWidth / 2,
                        height = width,
                        radius = width,
                        lineLength = width / 1.5,
                        centerX = width - 2.5,
                        centerY = p.percent < 0.5 ? width : width - 5,
                        degrees = graph.getDegrees(percent);

                    p.lineCan = lineCan;
                    p.ltx = lineCan.getContext("2d");
                    p.percent = percent;

                    //background
                    ctx.fillStyle = "rgb(9,167,88)";
                    ctx.beginPath();
                    ctx.moveTo(width, height);
                    ctx.arc(width, height, radius, Math.PI, 0);
                    ctx.fill();

                    ctx.beginPath();
                    ctx.fillStyle = "rgb(255,255,0)";
                    ctx.arc(width, height, radius, Math.PI, 1.5 * Math.PI);
                    ctx.lineTo(width, height);
                    ctx.fill();

                    ctx.beginPath();
                    ctx.fillStyle = "rgb(255,0,0)";
                    ctx.arc(width, height, radius, Math.PI, 1.15 * Math.PI);
                    ctx.lineTo(width, height);
                    ctx.fill();

                    ctx.beginPath();
                    ctx.moveTo(width, height);
                    ctx.fillStyle = "rgb(0,0,0)";
                    ctx.arc(width, height, 5, Math.PI, 0);

                    ctx.fill();

                    ctx.beginPath();
                    ctx.save();

                    centerX = width - 2.5;
                    centerY = width - 2.5;

                    //arrow
                    ctx.translate(centerX, centerY);
                    ctx.rotate(degrees * 0.0174532925);
                    ctx.moveTo(0, 0);
                    ctx.rect(0, -lineLength, 2.5, lineLength);

                    ctx.fill();

                    ctx.arc(1.25, -lineLength, 1.25, Math.PI, 0);

                    ctx.fill();
                } catch (e) {
                    console.log("Error in generating Gauge");
                }
            },
            getDegrees: function(percent) {
                return percent > 0.5 ? 180 * percent - 90 : 180 * percent + 270;
            },
            generateLineGraph: function(p) {
                var graph = this,
                    xRenderer,
                    yRenderer,
                    xMin,
                    xMax,
                    lineData,
                    plot1,
                    yMin,
                    yMax,
                    seriesColors,
                    shadow,
                    sparkYBuff;
                p.seriesColors = graph.getSeriesColors(p.seriesColors);
                $.jqplot.config.enablePlugins = true;
                graph.setupErrors();

                if (p.style === "spark") {
                    seriesColors = ["#00B0E5"];
                    shadow = false;
                    p.buffer = false;
                    sparkYBuff = false;
                } else {
                    seriesColors = ["#005682"];
                    shadow = true;
                    sparkYBuff = true;
                }
                p.showLabel = p.showTicks === false ? false : true;

                if (2 > p.xData.length) {
                    p.fill = false;
                    p.fillAndStroke = false;
                    p.buffer = p.style == "spark" ? false : true;
                } else {
                    p.fillAndStroke = p.fill == true ? true : false;
                    p.buffer = p.fill == true ? false : true;
                }
                if (p.fillAndStroke == true) {
                    p.highlight = false;
                    p.zoom = false;
                }

                if (1 > p.xData.length || 1 > p.yData.length) {
                    $("#" + p.location).html(
                        '<div class="no-results">' +
                            $("#chart-no-data").val() +
                            "</div>"
                    );
                    $("#" + p.location).height(55);
                } else {
                    xRenderer = graph.getRenderer(p.xType);
                    yRenderer = graph.getRenderer(p.yType);
                    xMin = graph.getMin(p.xData, p.xType, p.buffer);
                    xMax = graph.getMax(p.xData, p.xType, p.buffer);

                    if (true === p.addZeroes) {
                        lineData = graph.fillEmptyDates({
                            x: p.xData,
                            y: p.yData
                        });
                        p.xData = lineData.x;
                        p.yData = lineData.y;
                    }

                    yMin = graph.getMin(p.yData, p.yType, true);
                    yMax = graph.getMax(p.yData, p.yType, true);

                    console.log(xMin + " " + xMax);
                    console.log(yMin + " " + yMax);

                    lineData = graph.generateLine(p.xData, p.yData);
                    if (yMin == yMax) {
                        yMax = yMin + 20;
                    }

                    if (p.invert == "true") {
                        var yMinTemp = yMin;
                        yMin = yMax;
                        yMax = yMinTemp;
                    }
                    if (p.setXMin != null) {
                        xMin = p.setXMin;
                    }
                    if (p.setXMax != null) {
                        xMax = p.setXMax;
                    }
                    if (p.setYMin != null) {
                        yMin = p.setYMin;
                    }
                    if (p.setYMax != null) {
                        yMax = p.setYMax;
                    }
                    plot1 = $.jqplot(p.location, [lineData], {
                        title: p.title,
                        grid: {
                            background: "#FFFFFF",
                            borderColor: p.borderColor,
                            shadow: false
                        },
                        seriesDefaults: {
                            fill: p.fill,
                            fillAndStroke: p.fillAndStroke,
                            shadow: shadow,
                            fillColor: "#C8E7F0",
                            markerOptions: {
                                show: true
                            }
                        },
                        seriesColors: seriesColors,
                        axesDefaults: {
                            tickRenderer: $.jqplot.CanvasAxisTickRenderer,
                            numberTicks: 5,
                            tickOptions: {
                                showGridline: true,
                                showLabel: p.showLabel,
                                show: p.showTicks
                            },
                            showTickMarks: false
                        },
                        axes: {
                            xaxis: {
                                renderer: xRenderer,
                                tickOptions: {
                                    formatString: p.xFormat,
                                    angle: p.xLabelAngle,
                                    showGridline: false
                                },
                                label: p.xLabel,
                                min: xMin,
                                max: xMax
                            },
                            yaxis: {
                                renderer: yRenderer,
                                ticks: p.yTicks,
                                tickOptions: {
                                    formatString: "%'d",
                                    angle: p.yLabelAngle
                                },
                                label: p.yLabel,
                                min: yMin,
                                max: yMax
                            }
                        },
                        legend: {
                            show: p.legendDisplay,
                            placement: p.legendLocation,
                            rendererOptions: {
                                numberRows: 1
                            },
                            location: "s",
                            marginTop: "15px"
                        },
                        series: p.keyLabel,
                        highlighter: {
                            show: p.highlight,
                            tooltipAxes: "both",
                            formatString: p.popupFormat
                        },
                        cursor: {
                            zoom: false,
                            show: p.zoom,
                            showTooltip: false
                        }
                    });
                }
                $(window).resize(function() {
                    plot1.replot();
                });
            },
            generateAreaGraph: function(p) {
                var graph = this,
                    xRenderer,
                    yRenderer,
                    xMin,
                    xMax,
                    lineData,
                    plot1,
                    yMin,
                    yMax,
                    seriesColors,
                    shadow,
                    legendData;
                p.seriesColors = graph.getSeriesColors(p.seriesColors);

                if (p.style === "spark") {
                    seriesColors = ["#00B0E5"];
                } else {
                    seriesColors = ["#47afd2"];
                }
                if (p.showTicks === false) {
                    p.showLabel = false;
                    p.xAxisThickness = 0;
                    p.xLabelSize = 0;
                    p.yAxisThickness = 0;
                    p.yLabelSize = 0;
                } else {
                    p.showLabel = true;
                }
                if (p.yLabelSize == null) {
                    p.yLabelSize = 12;
                }
                if (p.xLabelSize == null) {
                    p.xLabelSize = 12;
                }
                if (p.yAxisThickness == null) {
                    p.yAxisThickness = 1;
                }
                if (p.xAxisThickness == null) {
                    p.xAxisThickness = 1;
                }

                xMin = graph.getMin(p.xData, "date", false);
                xMax = graph.getMax(p.xData, "date", false);

                if (true === p.addZeroes) {
                    lineData = graph.fillEmptyDates({ x: p.xData, y: p.yData });
                    p.xData = lineData.x;
                    p.yData = lineData.y;
                }

                yMin = graph.getMin(p.yData, p.yType, true);
                yMax = graph.getMax(p.yData, p.yType, true);

                if (yMin == yMax) {
                    yMax = yMin + 20;
                }
                if (p.setXMin != null) {
                    xMin = new Date(p.setXMin);
                }

                if (p.legendDisplay) {
                    legendData = {
                        horizontalAlign: "right", // left, center ,right
                        verticalAlign: "center", // top, center, bottom
                        fontSize: 12,
                        fontFamily: "Arial",
                        fontStyle: "italic",
                        fontWeight: "lighter",
                        fontColor: "#808080"
                    };
                }

                try {
                    var chart = new CanvasJS.Chart(p.location, {
                        culture: $("#userLanguage").val(),
                        legend: legendData,
                        backgroundColor: "transparent",
                        axisY: {
                            gridThickness: 0,
                            minimum: yMin,
                            maximum: yMax,
                            title: p.yTitle,
                            tickLength: p.yAxisThickness,
                            lineThickness: p.yAxisThickness,
                            labelFontSize: p.yLabelSize
                        },
                        axisX: {
                            gridThickness: 0,
                            minimum: xMin,
                            maximum: xMax,
                            valueFormatString: p.xFormat,
                            tickLength: p.xAxisThickness,
                            lineThickness: p.xAxisThickness,
                            labelFontSize: p.yLabelSize
                        },
                        data: [
                            {
                                toolTipContent: p.popupFormat,
                                type: "area",
                                showInLegend: p.legendDisplay,
                                markerSize: 5,
                                color: "#333E48",
                                markerType: "circle",
                                dataPoints: p.data
                            }
                        ]
                    });
                    chart.render();
                } catch (e) {}
            },
            generatePieChart: function(p) {
                var graph = this;
                if (p.graphData.length == 0) {
                    $("#" + p.location).html(p.errorMessage);
                    $("#" + p.location).height(55);
                } else {
                    p.graphData.sort(compare);
                    var colors = graph.getSeriesColors();
                    for (i = 0; i < p.graphData.length; ++i) {
                        p.graphData[i].color =
                            p.graphData[i].color || colors[i];
                    }
                    if (p.enableTooltip == null) {
                        p.enableTooltip = true;
                    }
                    if (p.title == null) {
                        p.title = " ";
                    }
                    for (var i = 0; i < p.graphData.length; i++) {
                        if (p.graphData[i].y == 0) {
                            p.graphData.splice(i, 1);
                            i--;
                        }
                    }
                    // console.log(p.graphData);
                    var chartP = new CanvasJS.Chart(p.location, {
                        culture: $("#userLanguage").val(),
                        title: {
                            text: p.title,
                            subTitle: p.subTitle,
                            total: p.total
                        },
                        toolTip: {
                            enabled: p.enableTooltip
                        },
                        data: [
                            {
                                type: p.graphType,
                                startAngle: 270,
                                toolTipContent: p.popupFormat,
                                dataPoints: p.graphData
                            }
                        ]
                    });
                    chartP.render();
                }

                function compare(a, b) {
                    if (a.y < b.y) return -1;
                    if (a.y > b.y) return 1;
                    return 0;
                }
            },

            generateStackedLineChart: function(p) {
                /*
                 this sets an indent on the left/right side of the chart 
                 to keep dates from being cut off
                */
                //p.xMin.setDate(p.xMin.getDate() - 1);
                p.xMax.setDate(p.xMax.getDate() + 1);
                /* ----- */

                var graph = this,
                    legendData;
                p = graph.fillMissingStackedDates(p);
                if (p.legendDisplay) {
                    legendData = {
                        horizontalAlign: "right",
                        verticalAlign: "center",
                        fontSize: 12,
                        fontFamily: "Arial",
                        fontStyle: "italic",
                        fontWeight: "lighter",
                        fontColor: "#808080"
                    };
                }
                var chart = new CanvasJS.Chart(p.location, {
                    culture: $("#userLanguage").val(),
                    legend: legendData,
                    backgroundColor: "transparent",
                    axisY: {
                        minimum: 0,
                        maximum: p.yMax,
                        gridThickness: 0
                    },
                    axisX: {
                        gridThickness: 0,
                        minimum: p.xMin,
                        maximum: p.xMax,
                        valueFormatString: "MMM D"
                    },
                    data: p.dataPoints
                });
                chart.render();
            },

            generateMultiLineGraph: function(p) {
                var graph = this,
                    legendData;
                if (p.legendDisplay) {
                    legendData = {
                        horizontalAlign: "right",
                        verticalAlign: "center",
                        fontSize: 12,
                        fontFamily: "Arial",
                        fontStyle: "italic",
                        fontWeight: "lighter",
                        fontColor: "#808080"
                    };
                }
                var chart = new CanvasJS.Chart(p.location, {
                    culture: $("#userLanguage").val(),
                    legend: legendData,
                    backgroundColor: "transparent",
                    axisY: {
                        minimum: 0,
                        maximum: p.yMax,
                        gridThickness: 1
                    },
                    axisX: {
                        gridThickness: 0,
                        minimum: p.xMin,
                        maximum: p.xMax,
                        valueFormatString: "MMM D"
                    },
                    data: p.dataPoints
                });
                chart.render();
            },
            getSeriesColors: function(seriesColors) {
                if (!seriesColors) {
                    // check for "truthiness", not null equality, because it might be undefined, or an empty string
                    return [
                        "#5ec8e5",
                        "#e04e38",
                        "#dccd23",
                        "#333e48",
                        "#965e78",
                        "#192a30",
                        "#3f8699",
                        "#953425",
                        "#8e8632",
                        "#455461",
                        "#c97fa2",
                        "#1f4e8a"
                    ];
                } else {
                    return seriesColors;
                }
            },
            generateLine: function(xData, yData) {
                var graph = this,
                    lineData = [],
                    i,
                    newData,
                    len;
                for (i = 0, len = xData.length; i < len; ++i) {
                    newData = [];
                    newData.push(xData[i]);
                    newData.push(yData[i]);
                    lineData.push(newData);
                }
                return lineData;
            },
            fillMissingStackedDates: function(parameters) {
                var graph = this,
                    dateGroups = parameters.dataPoints,
                    dates,
                    curDate,
                    allDates,
                    completeDates = 0;
                //get complete date list
                for (var i = 0, len = dateGroups.length; i < len; ++i) {
                    dates = dateGroups[i].dataPoints;
                    if (
                        dateGroups[completeDates].dataPoints.length <
                        dates.length
                    ) {
                        completeDates = i;
                        allDates = [];
                        for (j = 0, lenD = dates.length; j < lenD; ++j) {
                            allDates.push(dates[j].x);
                        }
                    }
                }
                for (var m = 0, leng = dateGroups.length; m < leng; ++m) {
                    dates = dateGroups[m].dataPoints;
                    if (
                        dateGroups[completeDates].dataPoints.length >
                        dates.length
                    ) {
                        for (k = 0, lenAll = allDates.length; k < lenAll; ++k) {
                            var dateFound = false;
                            curDate = allDates[k];
                            for (
                                j = 0, lenD = dates.length;
                                j < lenD && !dateFound;
                                ++j
                            ) {
                                if (curDate.getTime() == dates[j].x.getTime()) {
                                    dateFound = true;
                                }
                            }
                            if (!dateFound) {
                                var obj = {};
                                obj.x = curDate;
                                obj.y = 0;
                                dates.push(obj);
                            }
                        }
                    }
                }
                return parameters;
            },
            fillEmptyDates: function(parameters) {
                var graph = this,
                    start = new Date(parameters.x[0]),
                    end = new Date(parameters.x[parameters.x.length - 1]),
                    cur = start,
                    curArr = 0,
                    date;
                while (cur.toString() !== end.toString()) {
                    date = new Date(parameters.x[curArr]);
                    if (cur.toString() === date.toString()) {
                        curArr++;
                    } else {
                        parameters.x.push(
                            cur.getMonth() +
                                1 +
                                "/" +
                                cur.getDate() +
                                "/" +
                                cur.getFullYear()
                        );
                        parameters.y.push(0);
                    }
                    cur.setDate(cur.getDate() + 1);
                }
                return parameters;
            },
            fillEmptyDatesChart: function(parameters) {
                var graph = this,
                    date,
                    start = new Date(parameters.start),
                    end = new Date(parameters.end),
                    cur = start,
                    curArr = 0,
                    monthNames = [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec"
                    ];
                parameters.xNew = [];
                parameters.yNew = [];
                parameters.yNew[0] = [];
                while (cur.toString() !== end.toString()) {
                    date = new Date(parameters.x[curArr]);
                    parameters.xNew.push(
                        monthNames[cur.getMonth()] +
                            " " +
                            cur.getDate() +
                            ", " +
                            cur.getFullYear()
                    );
                    if (cur.toString() === date.toString()) {
                        parameters.yNew[0].push(
                            parseInt(parameters.y[0][curArr], 10)
                        );
                        curArr++;
                    } else {
                        parameters.yNew[0].push(parseInt(0, 10));
                    }
                    cur.setDate(cur.getDate() + 1);
                }
                date = new Date(parameters.x[curArr]);
                parameters.xNew.push(
                    monthNames[cur.getMonth()] +
                        " " +
                        cur.getDate() +
                        ", " +
                        cur.getFullYear()
                );
                if (cur.toString() === date.toString()) {
                    parameters.yNew[0].push(
                        parseInt(parameters.y[0][curArr], 10)
                    );
                    curArr++;
                } else {
                    parameters.yNew[0].push(parseInt(0, 10));
                }
                return parameters;
            },
            getLibraries: function(parameters) {
                var graph = this,
                    libraries = [];
                if (navigator.appName == "Microsoft Internet Explorer") {
                    libraries = graph.addToLibrary("js/excanvas.js", libraries);
                }
                switch (parameters.graphType) {
                    case "line":
                        break;
                    case "pie":
                        libraries = graph.addToLibrary(
                            "js/jqplot/plugins/jqplot.pieRenderer.min.js",
                            libraries
                        );
                        break;
                    case "bar":
                        libraries = graph.addToLibrary(
                            "js/jqplot/plugins/jqplot.barRenderer.min.js",
                            libraries
                        );
                        libraries = graph.addToLibrary(
                            "js/jqplot/plugins/jqplot.categoryAxisRenderer.min.js",
                            libraries
                        );
                        break;
                }
                return libraries;
            },
            setupErrors: function() {
                $.jqplot.config.catchErrors = true;
                $.jqplot.config.errorMessage = $("#chart-error").val();
                $.jqplot.config.errorBackground = "#fbeddf";
                $.jqplot.config.errorBorder = "2px solid #aaaaaa";
                $.jqplot.config.errorFontFamily = "Courier New";
                $.jqplot.config.errorFontSize = "16pt";
            },
            getRenderer: function(type) {
                if ("date" === type) {
                    return $.jqplot.DateAxisRenderer;
                } else {
                    return $.jqplot.LinearAxisRenderer;
                }
            },
            getMin: function(data, type, buffer) {
                var graph = this,
                    minDate,
                    minVal;
                try {
                    if ("date" === type) {
                        minDate = new Date(data[0]);
                        var i = 0;
                        while (i < data.length) {
                            var testDate = new Date(data[i]);
                            if (testDate < minDate) {
                                minDate = testDate;
                            }
                            i++;
                        }
                        if (buffer) {
                            minDate.setDate(minDate.getDate() - 1);
                        }
                        return minDate;
                    } else {
                        minVal = Math.min.apply(null, data);
                        minVal = minVal - minVal / 5;
                        return 0;
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            getMax: function(data, type, buffer) {
                var maxDate, maxVal;
                try {
                    if ("date" === type) {
                        maxDate = new Date(data[0]);
                        var i = 0;
                        while (i < data.length) {
                            var testDate = new Date(data[i]);
                            if (testDate > maxDate) {
                                maxDate = testDate;
                            }
                            i++;
                        }
                        if (buffer) {
                            maxDate.setDate(maxDate.getDate() + 1);
                        }
                        return maxDate;
                    } else {
                        maxVal = Math.max.apply(null, data);
                        var firstDigit =
                            parseInt(maxVal.toString().charAt(0), 10) + 1;
                        var zeroes = Math.pow(10, maxVal.toString().length - 1);
                        return firstDigit * zeroes;
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        };
    }
]);

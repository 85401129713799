app.service("geolocation", function() {
    var disableEnterKey = function(e) {
        var key;
        // windows uses keyCode, firefox which
        key = window.event ? window.event.keyCode : e.which;
        return 13 !== key;
    };
    return {
        init: function() {
            var map,
                geocoder,
                marker = null,
                markers = [],
                markerListener = null,
                skylocation = {
                    mapRequest: false,
                    toDefer: null,
                    googleStatus: null
                };
            (function($) {
                var buildGeoLocation;

                function splitGeoValues(mapResults) {
                    try {
                        console.log(mapResults);
                        var ac = mapResults.address_components,
                            mapLocation = mapResults.geometry.location,
                            mapCanvas = $("#map-canvas"),
                            coords = mapCanvas.siblings(".coords"),
                            longitude = mapCanvas.siblings(".longitude"),
                            latitude = mapCanvas.siblings(".latitude"),
                            formattedAddress = mapCanvas.siblings(
                                ".formattedAddress"
                            ),
                            geoLocation = $("input.geoLocation"),
                            geolocationStreet = $("input.geolocationStreet"),
                            geolocationState = $("input.geolocationState"),
                            geolocationZip = $("input.geolocationZip"),
                            geolocationCity = $("input.geolocationCity"),
                            sitemapAddress = mapCanvas.siblings(
                                ".sitemapAddress"
                            ),
                            loc = getGoogleSitemapsLocation(ac, true);

                        $("#sitemapAddress").val(loc.location);
                        formattedAddress.val(loc.location);
                        //formattedAddress.val(mapResults.formatted_address);
                        geoLocation.val(mapResults.formatted_address);
                        longitude.val(mapLocation.lng());
                        latitude.val(mapLocation.lat());
                        try {
                            coords.find("span.lat").text(latitude.val());
                            coords.find("span.lng").text(longitude.val());
                            geolocationStreet.val(
                                loc.street_number + " " + loc.route
                            );
                            geolocationCity.val(loc.city);
                            geolocationState.val(loc.state);
                            geolocationZip.val(loc.postal_code);
                            coords.show();
                        } catch (e) {}

                        if (geoLocation.hasClass("templateElementText")) {
                            geoLocation.blur();
                        }

                        $("button.geoLocation, button.geoLocationAlt")
                            .removeClass("disabled")
                            .removeAttr("disabled");
                    } catch (e) {
                        console.log(e);
                    }
                }

                function splitGeoValuesAdmin(mapResults) {
                    var mapLocation = mapResults.geometry.location,
                        longitude = $("#longitude"),
                        latitude = $("#latitude");

                    longitude.val(mapLocation.lng());
                    latitude.val(mapLocation.lat());
                }

                function splitGeoValuesProfile(mapResults, update) {
                    var mapLocation = mapResults.geometry.location,
                        longitude = $("#longitude"),
                        latitude = $("#latitude"),
                        sitemapAddress = $("#location"),
                        formattedAddress = $("#formattedAddress"),
                        loc = getGoogleSitemapsLocation(
                            mapResults.address_components,
                            false
                        );

                    if (undefined !== update && true === update) {
                        sitemapAddress.val(loc.location);
                    }
                    formattedAddress.val(loc.location);
                    longitude.val(mapLocation.lng());
                    latitude.val(mapLocation.lat());
                }

                skylocation.codeAddress = function() {
                    var button = $(this),
                        el = button.siblings("input.geoLocation"),
                        address = el.val(),
                        lat = $("#latitude"),
                        lng = $("#longitude"),
                        formattedAddress = button.siblings(".formattedAddress");
                    if (
                        undefined === address ||
                        null === address ||
                        "" === address
                    ) {
                        return;
                    }
                    geocoder.geocode({ address: address }, function(
                        results,
                        status
                    ) {
                        var mapResults = results[0],
                            location = mapResults.geometry.location,
                            newAddress = mapResults.formatted_address;

                        skylocation.status = status;
                        if (status === google.maps.GeocoderStatus.OK) {
                            map.setCenter(results[0].geometry.location);
                            if (null !== marker) {
                                marker.setMap(null);
                            }
                            marker = new google.maps.Marker({
                                map: map,
                                position: map.getCenter(),
                                draggable: false,
                                animation: google.maps.Animation.DROP
                            });

                            if (button.hasClass("geoLocationAdmin")) {
                                splitGeoValuesAdmin(mapResults);
                            } else if (
                                button.hasClass("geoLocationExpertise")
                            ) {
                                splitGeoValuesProfile(mapResults, false);
                            } else {
                                splitGeoValues(mapResults);
                            }

                            if (el.hasClass("templateElementText")) {
                                el.blur();
                            }
                        } else {
                            alert(
                                "Geocode was not successful for the following reason: " +
                                    status
                            );
                        }
                    });
                };

                function setGeoValues(name, sitemapLocation, lat, lng) {
                    var el = $("input.geoLocation"),
                        latitude = $("#latitude"),
                        longitude = $("#longitude"),
                        formattedAddress = el.siblings(".formattedAddress"),
                        sitemapAddress = el.siblings(".sitemapAddress");
                    el.val(name);
                    formattedAddress.val(name);
                    sitemapAddress.val(sitemapLocation);
                    latitude.val(lat);
                    longitude.val(lng);
                    if (el.hasClass("templateElementText")) {
                        el.blur();
                    }
                }

                function placeMarker(position, map, isMarker) {
                    if (null !== marker) {
                        marker.setMap(null);
                        google.maps.event.removeListener(markerListener);
                    }

                    marker = new google.maps.Marker({
                        position: position,
                        map: map,
                        draggable: false,
                        animation: google.maps.Animation.DROP
                    });

                    map.panTo(position);
                    try {
                        if (
                            undefined !== isMarker &&
                            null !== isMarker &&
                            true === isMarker
                        ) {
                            geocodePosition(position);
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }

                function getGoogleSitemapsLocation(
                    addressComponents,
                    completeAddress
                ) {
                    var city = "",
                        state = "",
                        country = "",
                        location = "",
                        postal_code = "",
                        street_number = "",
                        route = "",
                        obj = {
                            street_number: "",
                            route: "",
                            city: "",
                            state: "",
                            country: "",
                            postal_code: "",
                            location: ""
                        };
                    //console.log("completeAddress " + completeAddress);
                    $.each(addressComponents, function(k, v) {
                        var types = v.types,
                            i = 0;

                        for (i = 0; i < types.length; i++) {
                            if (true === completeAddress) {
                                if ("sublocality" === types[i]) {
                                    city = v.long_name;
                                    break;
                                }

                                if ("route" === types[i]) {
                                    route = v.long_name;
                                    break;
                                }

                                if ("street_number" === types[i]) {
                                    street_number = v.long_name;
                                    break;
                                }

                                if ("postal_code" === types[i]) {
                                    postal_code = v.short_name;
                                    break;
                                }
                            }

                            if ("locality" === types[i]) {
                                city = v.long_name;
                                break;
                            }

                            if ("administrative_area_level_1" === types[i]) {
                                state = v.short_name;
                                break;
                            }

                            if ("country" === types[i]) {
                                country = v.short_name;
                                break;
                            }
                        }
                    });

                    if ("" !== street_number) {
                        obj.street_number = street_number;
                        //location = street_number;
                    }

                    if ("" !== route) {
                        obj.route = route;
                        //location += " " + route;
                    }

                    if ("" !== city) {
                        obj.city = city;
                        location += " " + city;
                    }
                    if ("" !== state) {
                        obj.state = state;
                        location += ", " + state;
                    }

                    if ("" !== postal_code) {
                        obj.postal_code = postal_code;
                        //location += " " + postal_code;
                    }
                    if ("" !== country) {
                        obj.country = country;
                        location += ", " + country;
                    }
                    obj.location = $.trim(location);
                    //console.log("loc");
                    //console.log(location);
                    return obj;
                }

                function geocodePosition(pos) {
                    var location,
                        sitemapLocation = "";
                    geocoder.geocode(
                        {
                            latLng: pos
                        },
                        function(responses) {
                            var mapResults;
                            if (responses && 0 < responses.length) {
                                console.log(responses);
                                mapResults = responses[0];
                                location = responses[0].geometry.location;
                                splitGeoValues(mapResults);
                            } else {
                                //                updateMarkerAddress('Cannot determine address at this location.');
                            }
                        }
                    );
                }

                function initialize() {
                    var infowindow,
                        pos,
                        lng,
                        lat,
                        mapOptions = {
                            zoom: 13,
                            streetViewControl: false,
                            mapTypeControl: false,
                            panControl: false,
                            zoomControl: true,
                            scaleControl: true,
                            zoomControlOptions: {
                                style: google.maps.ZoomControlStyle.SMALL
                            },
                            mapTypeId: google.maps.MapTypeId.ROADMAP
                        };
                    geocoder = new google.maps.Geocoder();
                    map = new google.maps.Map(
                        document.getElementById("map-canvas"),
                        mapOptions
                    );

                    lng = parseFloat($("#longitude").val());
                    lat = parseFloat($("#latitude").val());

                    //console.log("Long: " + lng);
                    //console.log("lat: " + lat);

                    if ($.isNumeric(lng) && $.isNumeric(lat)) {
                        pos = new google.maps.LatLng(lat, lng);
                        map.setCenter(pos);
                        placeMarker(pos, map);
                    } else if (navigator.geolocation) {
                        // Try HTML5 geolocation
                        navigator.geolocation.getCurrentPosition(
                            function(position) {
                                lat = position.coords.latitude;
                                lng = position.coords.longitude;
                                pos = new google.maps.LatLng(lat, lng);

                                map.setCenter(pos);
                                placeMarker(pos, map);
                            },
                            function() {
                                handleNoGeolocation(true);
                            }
                        );
                    } else {
                        // Browser doesn't support Geolocation
                        handleNoGeolocation(false);
                    }

                    google.maps.event.addListener(map, "click", function(e) {
                        placeMarker(e.latLng, map, true);
                    });

                    google.maps.event.addListener(
                        map,
                        "zoom_changed",
                        function() {
                            var zoomLevel = map.getZoom();
                            map.setCenter(marker.getPosition());
                            //            console.log(map);
                        }
                    );
                }

                function handleNoGeolocation(errorFlag) {
                    var content, options, infowindow;
                    if (errorFlag) {
                        content = "Error: The Geolocation service failed.";
                    } else {
                        content =
                            "Error: Your browser doesn't support geolocation.";
                    }

                    options = {
                        map: map,
                        position: new google.maps.LatLng(
                            42.3584308,
                            -71.0597732
                        ),
                        content: content
                    };

                    map.setCenter(options.position);
                }

                skylocation.buildGeoLocation = function() {
                    var geoLocate = true,
                        location = "",
                        geoLocation = $("input.geoLocation"),
                        street = $("input.geolocationStreet").val(),
                        city = $("input.geolocationCity").val(),
                        state = $("input.geolocationState").val(),
                        zip = $("input.geolocationZip").val();

                    if (
                        undefined !== street &&
                        null !== street &&
                        "" !== street
                    ) {
                        location += street + " ";
                    }
                    if (undefined !== city && null !== city && "" !== city) {
                        location += " " + city + " ";
                    }

                    if (
                        undefined !== city &&
                        null !== city &&
                        "" !== city &&
                        undefined !== state &&
                        null !== state &&
                        "" !== state
                    ) {
                        location += ", ";
                    }

                    if (undefined !== state && null !== state && "" !== state) {
                        location += " " + state + " ";
                    }
                    if (undefined !== zip && null !== zip && "" !== zip) {
                        location += " " + zip;
                    }

                    geoLocation.val(location);
                };

                //onReady
                $(function() {
                    $("input#location").keypress(function(event) {
                        return disableEnterKey(event);
                    });
                    $("input#location").blur(function() {
                        $("a.geoLocationExpertise").click();
                    });
                    $(
                        "button.geoLocation, a.geoLocationAdmin, a.geoLocationExpertise"
                    ).click(skylocation.codeAddress);
                    $("button.geoLocationAlt").click(function() {
                        skylocation.buildGeoLocation();
                        $("button.geoLocation").click();
                    });

                    if (typeof google !== "undefined") {
                        google.maps.event.addDomListener(
                            window,
                            "load",
                            initialize
                        );
                    }
                });
            })(jQuery);
        }
    };
});

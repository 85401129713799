app.controller("vetting", [
    "$scope",
    "$translate",
    "$http",
    "$filter",
    "$timeout",
    "$routeParams",
    "ngTableParams",
    "skyword",
    "vetting",
    "SkyFactory",
    function(
        $scope,
        $translate,
        $http,
        $filter,
        $timeout,
        $routeParams,
        ngTableParams,
        skyword,
        vetting,
        SkyFactory
    ) {
        $scope.vettingContributors = {};
        $scope.contributorType = $routeParams.contributorType;
        $scope.name =
            null !== $routeParams.name && -1 !== $routeParams.name
                ? $routeParams.name
                : "";
        $scope.status = !$routeParams.status ? -2 : $routeParams.status;
        $scope.initial = true;

        //need date object for moment-picker
        $scope.dates = {
            startDate: null,
            endDate: null,
            maxDate: null
        };
        $scope.dates.maxDate = moment();
        $scope.datesAreNoGood = false;
        $scope.buttonActive = false;
        $scope.milliFormat = $translate.instant("angular.dateformat");
        $scope.dateformat = $translate.instant("angular.momentpicker.format");

        $translate("angular.momentpicker.format").then(function(val) {
            //turn dates in milliseconds back into date format
            $scope.dates.startDate = hasValue($routeParams.startDate)
                ? moment(Number($routeParams.startDate)).format(val)
                : "";
            $scope.dates.endDate = hasValue($routeParams.endDate)
                ? moment(Number($routeParams.endDate)).format(val)
                : "";
            $scope.searchVettingContributors();
        });

        function hasValue(inputVal) {
            return null !== inputVal && undefined !== inputVal;
        }

        function millisToDate(millisVal, format) {
            return new Date(Number(millisVal)).toString(format);
        }

        // console.log($routeParams);

        $scope.states = [
            { name: "angular.vettingstate.all", id: -1 },
            { name: "angular.vettingstate.readyorinprogress", id: -2 },
            { name: "angular.vettingstate.readyforvetting", id: 2 },
            { name: "angular.vettingstate.inprogress", id: 3 },
            { name: "angular.vettingstate.unqualified", id: 5 },
            { name: "angular.vettingstate.qualified", id: 4 },
            { name: "angular.vettingstate.notready", id: 1 }
        ];

        $scope.status = skyword.getObjByProperty(
            $scope.states,
            "id",
            parseInt($scope.status)
        );

        /*
        $scope.$watch('name', function() {


			// if the dates have value and are ok then button should be active
            // if the dates have NO value and the name field is empty, button should NOT be active

			if(!SkyFactory.reallyEmptyCheck($scope.dates.startDate) && !SkyFactory.reallyEmptyCheck($scope.dates.endDate)) {
				if (!$scope.datesAreNoGood) {
					$scope.buttonActive =  true;
				}
			} else {
				$scope.buttonActive = !SkyFactory.reallyEmptyCheck($scope.name);
            }
        });
*/

        //watches start date to make sure end date is not before start date
        $scope.$watch("[dates.startDate, dates.endDate, name]", function(
            newValue,
            oldValue
        ) {
            $scope.buttonActive = false;

            if (
                !SkyFactory.reallyEmptyCheck($scope.dates.startDate) &&
                !SkyFactory.reallyEmptyCheck($scope.dates.endDate)
            ) {
                //convert dates to milliseconds
                var startDate = $scope.convertDateToMillis(
                    moment($scope.dates.startDate, $scope.dateformat).format(
                        "MM-DD-YYYY"
                    ),
                    $scope.milliFormat
                );
                var endDate = $scope.convertDateToMillis(
                    moment($scope.dates.endDate, $scope.dateformat).format(
                        "MM-DD-YYYY"
                    ),
                    $scope.milliFormat
                );
                $scope.facetDateCreatedStart = new Date(startDate);
                $scope.facetDateCreatedEnd = new Date(endDate);

                if ($scope.facetDateCreatedStart > $scope.facetDateCreatedEnd) {
                    //start date is after end date, show error border
                    $scope.buttonActive = false;

                    $("#end-date").css({
                        border: "1px solid #E52E2E",
                        background: "#FFE2E2"
                    });
                } else {
                    //dates are correct
                    $scope.buttonActive = true;
                    $("#end-date").css({
                        border: "1px solid #CFCFCF",
                        background: "white"
                    });
                }
            } else {
                if (
                    SkyFactory.reallyEmptyCheck($scope.dates.startDate) &&
                    SkyFactory.reallyEmptyCheck($scope.dates.endDate)
                ) {
                    $scope.buttonActive = !SkyFactory.reallyEmptyCheck(
                        $scope.name
                    );
                }

                if ($scope.dates.startDate != null) {
                    $scope.minDate = $scope.dates.startDate.toString();
                }
            }
        });

        /**
         * updates contributor list with unfiltered results
         */
        $scope.searchVettingContributors = function() {
            $scope.milliFormat = $translate.instant("angular.dateformat");
            $scope.dateformat = $translate.instant(
                "angular.momentpicker.format"
            );

            $scope.isSearchVettingContributors = true;
            $scope.vettingContributors.name = $scope.emptyCheck($scope.name);
            $scope.vettingContributors.contributorType = $scope.emptyCheck(
                $scope.contributorType
            );
            $scope.vettingContributors.status = $scope.emptyCheck(
                $scope.status.id
            );

            //convert dates to milliseconds
            $scope.vettingContributors.startDate = $scope.convertDateToMillis(
                moment($scope.dates.startDate, $scope.dateformat).format(
                    "MM-DD-YYYY"
                ),
                $scope.milliFormat
            );
            $scope.vettingContributors.endDate = $scope.convertDateToMillis(
                moment($scope.dates.endDate, $scope.dateformat).format(
                    "MM-DD-YYYY"
                ),
                $scope.milliFormat
            );

            vetting.contributorSearch($scope.vettingContributors, function(
                data
            ) {
                $scope.computeDataResult(data);
            });
            return false;
        };

        $scope.computeDataResult = function(localData) {
            $scope.isSearchVettingContributors = false;
            $scope.initial = false;
            $scope.size = localData.length;
            for (var i = 0; i < localData.length; i++) {
                if (
                    undefined === localData[i].lastName ||
                    null === localData[i].lastName
                ) {
                    localData[i].lastName = "";
                }
                if (
                    undefined === localData[i].firstName ||
                    null === localData[i].firstName
                ) {
                    localData[i].firstName = "";
                }
                localData[i].name =
                    localData[i].firstName.trim() +
                    " " +
                    localData[i].lastName.trim();
                localData[i].name = localData[i].name.trim();
            }
            $scope.data = localData;
        };
        $scope.setupTable = function() {
            if (undefined !== $scope.tableParams) {
                $scope.tableParams.reload();
            } else if (undefined !== $scope.data) {
                $scope.tableParams = new ngTableParams(
                    {
                        page: 1,
                        count: 25,
                        sorting: {
                            name: "asc" // initial sorting
                        }
                    },
                    {
                        total: $scope.data.length,
                        getData: function($defer, params) {
                            params.total($scope.data.length);
                            params.units = "angular.label.numbercontributors";
                            var orderedData = params.sorting()
                                ? $filter("orderBy")(
                                      $scope.data,
                                      params.orderBy()
                                  )
                                : $scope.data;
                            if (0 < orderedData.length) {
                                // console.log(orderedData[0].name);
                                $defer.resolve(
                                    orderedData.slice(
                                        (params.page() - 1) * params.count(),
                                        params.page() * params.count()
                                    )
                                );
                            }
                        },
                        $scope: $scope
                    }
                );
            }
        };
        $scope.$watch("data", $scope.setupTable);
    }
]).controller("vettingStatusModule", [
    "$scope",
    "$timeout",
    "vetting",
    "$http",
    "skyword",
    function($scope, $timeout, vetting, $http, skyword) {
        $scope.load = "";
        $scope.states = [];
        $scope.id = angular.element("#contributorId").val();
        $scope.vettingWriterStatusId = angular
            .element("#vettingWriterStatusId")
            .val();
        $scope.vettingVideographerStatusId = angular
            .element("#vettingVideographerStatusId")
            .val();
        $scope.vettingPhotographerStatusId = angular
            .element("#vettingPhotographerStatusId")
            .val();
        $scope.vettingDesignerStatusId = angular
            .element("#vettingDesignerStatusId")
            .val();
        $scope.updateStatus = function() {
            $scope.isUpdating = true;
            $scope.load = "statusLoading";
            vetting.updateStatus(
                {
                    id: $scope.id,
                    writerStatus: $scope.writerStatus.id,
                    videographerStatus: $scope.videographerStatus.id,
                    photographerStatus: $scope.photographerStatus.id,
                    designerStatus: $scope.designerStatus.id
                },
                function(status) {
                    if (status.writerDateLastVetted !== null) {
                        var writerDateLastVetted = changeTimeZone(
                            new Date(status.writerDateLastVetted)
                        );
                        let writerDateLastVettedElement = document.getElementById(
                            "writerDateLastVetted"
                        );
                        if (writerDateLastVettedElement !== null) {
                            document.getElementById(
                                "writerDate"
                            ).innerHTML = writerDateLastVetted;

                            writerDateLastVettedElement.classList.remove(
                                "hidden"
                            );
                        }
                    }

                    if (status.videographerDateLastVetted !== null) {
                        var videographerDateLastVetted = changeTimeZone(
                            new Date(status.videographerDateLastVetted)
                        );
                        let videographerDateLastVettedElement = document.getElementById(
                            "videographerDateLastVetted"
                        );
                        if (videographerDateLastVettedElement !== null) {
                            document.getElementById(
                                "videographerDate"
                            ).innerHTML = videographerDateLastVetted;

                            videographerDateLastVettedElement.classList.remove(
                                "hidden"
                            );
                        }
                    }

                    if (status.photographerDateLastVetted !== null) {
                        var photographerDateLastVetted = changeTimeZone(
                            new Date(status.photographerDateLastVetted)
                        );
                        let photographerDateLastVettedElement = document.getElementById(
                            "photographerDateLastVetted"
                        );
                        if (photographerDateLastVettedElement !== null) {
                            document.getElementById(
                                "photographerDate"
                            ).innerHTML = photographerDateLastVetted;

                            photographerDateLastVettedElement.classList.remove(
                                "hidden"
                            );
                        }
                    }

                    if (status.designerDateLastVetted !== null) {
                        var designerDateLastVetted = changeTimeZone(
                            new Date(status.designerDateLastVetted)
                        );
                        let designerDateLastVettedElement = document.getElementById(
                            "designerDateLastVetted"
                        );
                        if (designerDateLastVettedElement !== null) {
                            document.getElementById(
                                "designerDate"
                            ).innerHTML = designerDateLastVetted;

                            designerDateLastVettedElement.classList.remove(
                                "hidden"
                            );
                        }
                    }

                    $scope.isUpdating = false;
                    $scope.load = "statusSuccess";
                    $timeout(function() {
                        $(".overlay.statusSuccess").fadeOut(function() {
                            $(this)
                                .removeClass("statusSuccess")
                                .removeAttr("style");
                            $("#btn-vetting-state-update")
                                .removeAttr("disabled")
                                .removeClass("disabled");
                        });
                    }, 1000);
                }
            );
        };

        function changeTimeZone(date) {
            let locale = $("body")
                .attr("data-locale")
                .replace("_", "-");

            const arEG = new Intl.Locale(locale);
            let timeZone = arEG.timeZones[0];

            switch (locale) {
                case "en-US":
                    timeZone =
                        arEG.timeZones[
                            arEG.timeZones.indexOf("America/New_York")
                        ];
                    break;
                default:
                    timeZone = arEG.timeZones[0];
                    break;
            }

            let localeDate = date.toLocaleDateString(locale, {
                timeZone,
                day: "2-digit",
                month: "2-digit",
                year: "numeric"
            });

            let localeTime = date.toLocaleTimeString(locale, {
                timeZone,
                hour12: false,
                hour: "2-digit",
                minute: "2-digit",
                second: "numeric"
            });

            let localeDateArr = new Date(localeDate);
            localeDate = localeDateArr.toString("MMM dd, yyyy");

            return localeDate + ")";
        }

        function formatDate(date) {
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        }

        vetting.getStatuses(function(data) {
            data.shift();
            $scope.states = data;
            $scope.writerStatus = skyword.getObjByProperty(
                $scope.states,
                "id",
                parseInt($scope.vettingWriterStatusId)
            );
            $scope.videographerStatus = skyword.getObjByProperty(
                $scope.states,
                "id",
                parseInt($scope.vettingVideographerStatusId)
            );
            $scope.photographerStatus = skyword.getObjByProperty(
                $scope.states,
                "id",
                parseInt($scope.vettingPhotographerStatusId)
            );
            $scope.designerStatus = skyword.getObjByProperty(
                $scope.states,
                "id",
                parseInt($scope.vettingDesignerStatusId)
            );
        });
    }
]);

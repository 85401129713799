app.controller("imageAnnotationsController", [
    "$scope",
    "$translate",
    "skyword",
    "imageAnnotations",
    "$routeParams",
    "digitalAsset",
    "$rootScope",
    "$compile",
    "filterFilter",
    function(
        $scope,
        $translate,
        skyword,
        imageAnnotations,
        $routeParams,
        digitalAsset,
        $rootScope,
        $compile,
        filterFilter
    ) {
        $scope.fullAnnotations = null;
        $scope.formattedAnnotations = [];

        $scope.referrerPage = referrer;
        $scope.backButtonText = "";

        $scope.imageLoaded = false;
        $scope.annotationsLoaded = false;
        $scope.currentAccountId = angular
            .element("#attrs-id")
            .data("accountid");
        $scope.replyFormToShow = -1;
        $scope.totalCommentCount = 0;
        $scope.isDisabled = false;

        $scope.getImageAttachment = function() {
            var attachmentPromise = digitalAsset.getAssetAttachment(
                $scope.attachment.id
            );
            attachmentPromise.then(function(attachment) {
                $scope.attachment = attachment.data;
                var img = document.getElementById("annotatedImage");
                imageAnnotations.init();
                setHandlers();
                anno.destroy();
                $scope.imageLoaded = true;
                // This is adjustment to hide the options in the menu
                // This is just work around. Later on implement it from backend and remove this
                if ((window.location.href).indexOf("externalImageCommenting") > -1 ) {
                    var rightMenu = angular.element(document.querySelector(".dashboardLink"));
                    rightMenu.css('display', 'none');
                    angular.element('#help-button').css('display', 'none');
                    angular.element("#user-dropdown").css('display', 'none');
                    let external_reviewer = localStorage.getItem("externalReviewer");
                    var accountId = parseInt($('#attrs-id').data('accountid'));
                    if(accountId === 1){
                        if((external_reviewer === null || external_reviewer === '')){
                            checkAndLoadModalExternalReviewerName();
                        }
                    }
                }
                img.onload = function() {
                    anno.makeAnnotatable(img);
                    loadAnnotationsForImageAttachment($scope.attachment.id);
                    var h = img.clientHeight;
                    //$('.image-attachment-comments').css('height',h+20);
                    setTimeout(sticky_commentsPanel, 300);
                };
            });
        };

        function checkAndLoadModalExternalReviewerName(){
            var opts = {
                html:
                    '<div class="external-reviewer-pane">'+
                        '<h1>External Reviewer</h1>' +
                        '<div class="input-form-container">'+
                            '<label class="form-input-label">Your Name <span class="required">*</span></label>'+
                            '<input type="text" name="external_reviewer" id="external_reviewer" onkeyup="resetValidation()" />'+
                            '<span class="validation-message" id="validation-message">Please enter your name</span>'+
                        '</div>' +
                        '<div class="save-btn-container">'+
                            '<button class="standard" onclick="saveExternalReviewerName()"><span>Save</span></button>'+
                        '</div>' +
                    "</div>",
                    closeButton: false,
                overlayClose: false
            };
            $.colorbox(opts);
            $("#cboxClose").css('display','none')
            $('#validation-message').css('display','none');
        }

        window.resetValidation = function(){
            $('#validation-message').css('display','none');
        }

        window.saveExternalReviewerName = function(){
            let name = $("#external_reviewer").val().trim();
            if(name === "" || name === null){
                $('#validation-message').css('display','flex');
            }else{
                $('#validation-message').css('display','none');
                console.log(name);
                localStorage.setItem("externalReviewer",name);
                parent.$.colorbox.close();
                // $('#externalReviewerNameFromLocalStorage').val(name);
            }
        }

        function setHandlers() {
            anno.addHandler("onAnnotationCreated", function(annotation) {
                annotation.assetId = $scope.attachment.id;
                saveAnnotation(annotation);
            });

            anno.addHandler("onAnnotationUpdated", function(annotation) {
                annotation.assetId = $scope.attachment.id;
            });

            anno.addHandler("onAnnotationRemoved", function(annotation) {
                annotation.assetId = $scope.attachment.id;
            });
            anno.addHandler("onMouseOverAnnotation", function(event) {
                $scope.highlightAnnotationInList(event.C);
            });
            anno.addHandler("onMouseOutOfAnnotation", function(event) {
                $scope.highlightAnnotationInList();
            });
            anno.addHandler("onMouseOutOfItem", function(event) {
                $scope.highlightAnnotationInList();
            });
        }

        $scope.deleteAnnotation = function(annotation) {
            if (undefined != annotation && null != annotation) {
                $scope.showDeleteConfirm(annotation);
            }
        };

        function getExternalReviewerName() {
            var externalReviewerName = localStorage.getItem("externalReviewer");
            externalReviewerName = externalReviewerName === null ? "" : externalReviewerName;
            let currentUrl = window.location.href;
            var accountId = parseInt($('#attrs-id').data('accountid'))
            var externalReviewerData = "";
            if(accountId === 1){
                if(externalReviewerName.trim() !== "") {
                    externalReviewerData = " - " + externalReviewerName
                }
            }
            return externalReviewerData;
        };

        function saveAnnotation(annotation) {
            annotation.editable = false;
            var data = {
                xpos: annotation.shapes[0].geometry.x,
                ypos: annotation.shapes[0].geometry.y,
                width: annotation.shapes[0].geometry.width,
                height: annotation.shapes[0].geometry.height,
                enteredText: annotation.text + getExternalReviewerName(),
                id: annotation.id,
                attachmentId: annotation.assetId
            };
            var saveAnnotationPromise = imageAnnotations.create(data);
            saveAnnotationPromise.then(function(result) {
                annotation.id = result.data.id;
                var thisAnno = result.data;
                thisAnno.formattedAnnotation = annotation;
                $scope.fullAnnotations.push(thisAnno);
                $scope.formattedAnnotations.push(formatResultData(thisAnno));
                $scope.recalculateTotalCommentCount();
            });
        }

        $scope.reply = function(parentAnnotation, text) {
            $scope.isDisabled = true;
            var annotation = angular.copy(parentAnnotation);
            annotation.parentImageAnnotationId = annotation.id;
            annotation.id = null;
            annotation.enteredText = text + getExternalReviewerName();
            saveReply(parentAnnotation, annotation);
        };

        function saveReply(parentAnnotation, annotation) {
            var data = {
                xpos: annotation.xpos,
                ypos: annotation.ypos,
                width: annotation.width,
                height: annotation.height,
                enteredText: annotation.enteredText,
                parentImageAnnotationId: annotation.parentImageAnnotationId,
                attachmentId: annotation.attachmentId
            };
            var saveAnnotationPromise = imageAnnotations.create(data);
            saveAnnotationPromise.then(function(result) {
                var thisReply = result.data;
                thisReply.formattedAnnotation = formatResultData(result.data);
                thisReply.formattedAnnotation.id = result.data.id;
                parentAnnotation.replies.push(thisReply);
                $scope.recalculateTotalCommentCount();
                if (parentAnnotation.replies.length > 3) {
                    $scope.showMoreReplies(parentAnnotation, true);
                } else {
                    setTimeout(function() {
                        var containerAsText =
                            ".image-attachment-comment-replies[data-replies-id='" +
                            parentAnnotation.id +
                            "']";
                        $(
                            containerAsText +
                                " .image-attachment-comment-reply:last"
                        )
                            .addClass("activeReply", 400)
                            .removeClass("activeReply", 400);
                    }, 800);
                }
                $scope.hideReplyForm(parentAnnotation.id);
                $scope.isDisabled = false;
            });
        }

        $scope.showDeleteConfirm = function(annotation) {
            $scope.annotationToDelete = annotation;
            //$scope.annotationToDelete.formattedAnnotation = formatResultData(annotation);
            if (
                null != $scope.annotationToDelete.replies &&
                $scope.annotationToDelete.replies.length > 0
            ) {
                $translate(
                    "angular.imageannotation.confirm.title.delete.commentwithreplies"
                ).then(function(val) {
                    $scope.confirmationTitle = val;
                });
            } else if (
                $scope.annotationToDelete.parentImageAnnotationId != null &&
                $scope.annotationToDelete.parentImageAnnotationId > 0
            ) {
                $translate(
                    "angular.imageannotation.confirm.title.delete.reply"
                ).then(function(val) {
                    $scope.confirmationTitle = val;
                });
            } else {
                $translate(
                    "angular.imageannotation.confirm.title.delete.comment"
                ).then(function(val) {
                    $scope.confirmationTitle = val;
                });
            }
            $.colorbox({
                height: "200px",
                width: "400px",
                href: "/partials/directives/remove-image-annotation.html",
                onComplete: function() {
                    $rootScope.$apply(function() {
                        var content = jQuery("#cboxLoadedContent");
                        $compile(content)($scope);
                    });
                    $rootScope.$broadcast("imageAnnotationDelete", $scope);
                },
                onCleanup: function() {},
                onClosed: function() {
                    $scope.confirmationTitle = "";
                    $scope.annotationToDelete = null;
                }
            });
        };

        $scope.showReplyForm = function(annoId) {
            $scope.replyFormToShow = annoId;
            setTimeout(function() {
                $("textarea[data-annotation-id='" + annoId + "']").focus();
            }, 500);
        };
        $scope.hideReplyForm = function(annoId) {
            $scope.replyFormToShow = -1;
        };

        $scope.highlightAnnotationOnImage = function(annotation) {
            anno.highlightAnnotation(annotation);
            $(".annotorious-annotationlayer")
                .find(".annotorious-item-unfocus")
                .each(function() {
                    $(this)
                        .removeClass("annotorious-item-unfocus")
                        .addClass("annotorious-item-focus");
                });
        };

        $scope.scrollToAnimationOnImage = function(annotation) {
            var annoYpos = annotation.shapes[0].geometry.y,
                imgHeight = $(".annotorious-annotationlayer").height(),
                annoPicPos = annoYpos * imgHeight,
                imgTop = $(".annotorious-annotationlayer").offset().top,
                annoPagePos = imgTop + annoPicPos;
            $("html, body")
                .stop()
                .animate({ scrollTop: annoPagePos - 20 }, 1000);
        };

        $scope.unHighlightAnnotationOnImage = function(annotation) {
            anno.highlightAnnotation(annotation);
            $(".annotorious-annotationlayer")
                .find(".annotorious-item-focus")
                .each(function() {
                    $(this)
                        .removeClass("annotorious-item-focus")
                        .addClass("annotorious-item-unfocus");
                });
        };

        $scope.highlightAnnotationInList = function(annotation) {
            if (undefined != annotation && null != annotation) {
                if (undefined != annotation.id && null != annotation.id) {
                    var annoId = annotation.id;
                    $(
                        ".image-attachment-comment-box[data-image-annotation-id='" +
                            annoId +
                            "']"
                    ).addClass("activeAnnotation");
                    var scrollToValue = 0;
                    var theIndex =
                        $(
                            ".image-attachment-comment-box[data-image-annotation-id='" +
                                annoId +
                                "']"
                        ).index() - 1;
                    $(".image-attachment-comment-box").each(function(i) {
                        if (i < theIndex) {
                            scrollToValue += $(this).height() + 20;
                        } else return false;
                    });

                    $(".image-attachment-comments").animate(
                        { scrollTop: scrollToValue },
                        1000,
                        function() {}
                    );
                }
            } else {
                $(".image-attachment-comment-box").removeClass(
                    "activeAnnotation"
                );
            }
        };

        function loadAnnotationsForImageAttachment(attchId) {
            var annotationsPromise = imageAnnotations.loadAll(attchId);

            annotationsPromise.then(function(result) {
                var what = result.data;
                $scope.totalCommentCount = what.length;
                for (var cntr = 0; cntr < what.length; cntr++) {
                    var thisAnno = formatResultData(what[cntr]);
                    $scope.formattedAnnotations.push(thisAnno);
                    if ($scope.isIE()) {
                        draw(
                            thisAnno.shapes[0].geometry.x,
                            thisAnno.shapes[0].geometry.y,
                            thisAnno.shapes[0].geometry.width,
                            thisAnno.shapes[0].geometry.height,
                            thisAnno.id
                        );
                    } else {
                        anno.addAnnotation(thisAnno);
                    }
                }
                $scope.fullAnnotations = what;
                $scope.recalculateTotalCommentCount();
                angular.element(".image-attachment-comments h2").show();
                $scope.annotationsLoaded = true;
            });
        }

        $scope.recalculateTotalCommentCount = function() {
            $scope.totalCommentCount = $scope.fullAnnotations.length;
            for (var cntr = 0; cntr < $scope.fullAnnotations.length; cntr++) {
                if (
                    undefined != $scope.fullAnnotations[cntr].replies &&
                    null != $scope.fullAnnotations[cntr].replies
                ) {
                    $scope.totalCommentCount += filterFilter(
                        $scope.fullAnnotations[cntr].replies,
                        { removed: "false" }
                    ).length;
                }
            }
        };

        function formatResultData(resultData) {
            var thisAnno = {
                src: $scope.attachment.fullUrl,
                text: resultData.enteredText,
                id: resultData.id,
                shapes: [
                    {
                        type: "rect",
                        geometry: {
                            x: resultData.xpos,
                            y: resultData.ypos,
                            width: resultData.width,
                            height: resultData.height
                        }
                    }
                ]
            };
            thisAnno.editable = false;
            resultData.formattedAnnotation = thisAnno;
            if (null == resultData.profilePicture) {
                resultData.profilePicture = "/styles/images/BlankProfile.png";
            }
            return thisAnno;
        }

        if (typeof $routeParams.attachmentId != "undefined") {
            $scope.attachment = {
                id: $routeParams.attachmentId
            };
            // launch it
            $scope.getImageAttachment();
        }

        $(window).resize(function() {
            anno.destroy();
            var img = document.getElementById("annotatedImage");
            anno.makeAnnotatable(img);

            if ($scope.isIE()) {
                var indicators = document.getElementsByClassName(
                    "ieAnnotationIndicator"
                );
                while (indicators[0]) {
                    indicators[0].parentNode.removeChild(indicators[0]);
                }
            }

            $scope.formattedAnnotations.forEach(function(item) {
                if ($scope.isIE()) {
                    draw(
                        item.shapes[0].geometry.x,
                        item.shapes[0].geometry.y,
                        item.shapes[0].geometry.width,
                        item.shapes[0].geometry.height,
                        item.id
                    );
                } else {
                    anno.addAnnotation(item);
                }
            });
            sticky_commentsPanel();
        });

        function sticky_commentsPanel() {
            try {
                var window_top = $(window).scrollTop(),
                    window_scroll_position = $(document).scrollTop(),
                    document_height = $(document).height(),
                    window_height = $(window).height(),
                    imagePanel = $(".image-attachment"),
                    commentsPanelAnchor = $(
                        "#image-attachment-comments-panel-anchor"
                    ),
                    commentsPanel = $("#image-attachment-comments-panel"),
                    commentsScroller = $(".image-attachment-comments");
                div_top =
                    0 < commentsPanelAnchor.length
                        ? commentsPanelAnchor.offset().top
                        : 0;

                if (
                    $("#image-attachment-comments-panel-anchor").offset().top +
                        imagePanel.height() >
                    window_height
                ) {
                    if (window_top > div_top) {
                        commentsPanel.addClass(
                            "imageAttachmentCommentsPanelFixed"
                        );
                        if (
                            window_top + window_height <
                            document_height - ($("footer").height() + 10)
                        ) {
                            commentsPanel.height(window_height);
                        } else {
                            commentsPanel.height(
                                window_height -
                                    (window_top +
                                        window_height -
                                        (document_height -
                                            ($("footer").height() + 10)) + 125)
                            );
                        }
                    } else {
                        if (
                            commentsPanel.height() <=
                            imagePanel.height() + window_top
                        ) {
                            commentsPanel.removeClass(
                                "imageAttachmentCommentsPanelFixed"
                            );
                            commentsPanel.height(
                                window_height - div_top + window_scroll_position
                            );
                        }
                    }
                    commentsScroller.height(
                        commentsPanel.height() -
                            ($(
                                "#image-attachment-comments-panel .column-header"
                            ).height() +
                                60)
                    );
                } else {
                    commentsScroller.height(
                        imagePanel.height() -
                            $(
                                "#image-attachment-comments-panel .column-header"
                            ).height() -
                            60
                    );
                }
            } catch (e) {
                console.error(e);
            }
        } // end of sticky_commentsPanel
        $(function() {
            $(window).scroll(sticky_commentsPanel);
            sticky_commentsPanel();
        });

        var referrerPageNamePropkey = "";
        if (referrer.indexOf("managerPublish") > -1) {
            referrerPageNamePropkey = "angular.createcontent";
        } else if (referrer.indexOf("editArticle") > -1) {
            referrerPageNamePropkey = "angular.editcontent";
        } else {
            referrerPageNamePropkey = "angular.reviewcontent";
        }
        $translate(referrerPageNamePropkey).then(function(nm) {
            $translate("angular.returntoreferrer", { referrer: nm }).then(
                function(val) {
                    $scope.backButtonText = val;
                }
            );
        });

        $scope.showMoreReplies = function(annotation, highlightLast) {
            var containerAsText =
                ".image-attachment-comment-replies[data-additional-replies-id='" +
                annotation.id +
                "']";
            var container = angular.element(containerAsText);
            container.find(".icon-Delete").css("visibility", "hidden");
            container.slideDown(800, function() {
                container.find(".icon-Delete").css("visibility", "visible");
                $("html, body").animate(
                    {
                        scrollTop:
                            $(
                                containerAsText +
                                    " .image-attachment-comment-reply:last"
                            ).offset().top - 40
                    },
                    1000,
                    function() {
                        if (highlightLast) {
                            $(
                                containerAsText +
                                    " .image-attachment-comment-reply:last"
                            )
                                .addClass("activeReply", 400)
                                .removeClass("activeReply", 400);
                        }
                    }
                );
            });
            container.removeClass("replies-hidden");
            container
                .parent()
                .find(".show-more-replies-link")
                .hide();
            container
                .parent()
                .find(".show-less-replies-link")
                .show();
        };

        function draw(x, y, w, h, id) {
            var img = document.getElementById("annotatedImage");
            img.style.zIndex = -6;
            var canvasOriginal = document.getElementsByClassName(
                "annotorious-item-unfocus"
            )[0];
            var holder = document.getElementsByClassName(
                "annotorious-annotationlayer"
            )[0];
            var canvas = document.createElement("canvas");
            canvas.className = "ieAnnotationIndicator";
            canvas.setAttribute("data-annoid", id);
            canvas.width = canvasOriginal.width;
            canvas.height = canvasOriginal.height;

            if (canvas && canvas.getContext) {
                var ctx = canvas.getContext("2d");
                ctx.strokeStyle = "#EB5338";
                ctx.lineWidth = 1.5;
                ctx.strokeRect(
                    x * canvas.width,
                    y * canvas.height,
                    w * canvas.width,
                    h * canvas.height
                );
            }
            holder.appendChild(canvas);

            var annoCanvases = document.getElementsByClassName(
                "annotorious-item"
            );
            for (var i = 0; i < annoCanvases.length; i++) {
                annoCanvases[i].style.zIndex = 2;
            }
        }

        $scope.isIE = function() {
            var ua = window.navigator.userAgent;
            var msie = ua.indexOf("MSIE ");
            return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
        };

        $scope.showLessReplies = function(annotationid) {
            var container = angular.element(
                ".image-attachment-comment-replies[data-additional-replies-id='" +
                    annotationid +
                    "']"
            );
            container.slideUp(800, function() {});
            container.find(".icon-Delete").css("visibility", "hidden");
            container.addClass("replies-hidden");
            container
                .parent()
                .find(".show-less-replies-link")
                .hide();
            container
                .parent()
                .find(".show-more-replies-link")
                .show();
            $("html, body").animate(
                { scrollTop: container.parent().offset().top - 10 },
                1000,
                function() {}
            );
        };
    }
])

    .controller("RemoveAnnotation", [
        "$scope",
        "$rootScope",
        "imageAnnotations",
        "$translate",
        function($scope, $rootScope, imageAnnotations, $translate) {
            $scope.$on("imageAnnotationDelete", function(event, data) {
                $scope = data;
            });

            $scope.removeAnno = function() {
                var deleteAnnotationPromise = imageAnnotations.remove(
                    $scope.annotationToDelete.id
                );
                deleteAnnotationPromise.then(function(result) {
                    if (
                        null !=
                            $scope.annotationToDelete.parentImageAnnotationId &&
                        0 < $scope.annotationToDelete.parentImageAnnotationId
                    ) {
                        var theParentAnno = $.grep(
                            $scope.fullAnnotations,
                            function(e) {
                                return (
                                    e.id ==
                                    $scope.annotationToDelete
                                        .parentImageAnnotationId
                                );
                            }
                        );
                        if (null != theParentAnno) {
                            var newParentAnno = theParentAnno;
                            var thisAnno = $.grep(
                                newParentAnno[0].replies,
                                function(e) {
                                    return e.id == $scope.annotationToDelete.id;
                                }
                            );
                            if (null != thisAnno) {
                                var idx = newParentAnno[0].replies.indexOf(
                                    thisAnno[0]
                                );
                                newParentAnno[0].replies.splice(idx, 1);
                            }
                            var idxOfParent = $scope.fullAnnotations.indexOf(
                                theParentAnno[0]
                            );
                            $scope.fullAnnotations[idxOfParent] =
                                newParentAnno[0];
                            $scope.recalculateTotalCommentCount();
                        }
                    } else {
                        if ($scope.isIE()) {
                            $(
                                "[data-annoid='" +
                                    $scope.annotationToDelete
                                        .formattedAnnotation.id +
                                    "']"
                            ).remove();
                        } else {
                            anno.removeAnnotation(
                                $scope.annotationToDelete.formattedAnnotation
                            );
                        }

                        var idxFull = $scope.fullAnnotations.indexOf(
                            $scope.annotationToDelete
                        );
                        $scope.fullAnnotations.splice(idxFull, 1);
                        $scope.recalculateTotalCommentCount();
                        var idxFormatted = $scope.formattedAnnotations.indexOf(
                            $scope.annotationToDelete.formattedAnnotation
                        );
                        $scope.formattedAnnotations.splice(idxFormatted, 1);
                    }
                    $scope.annotationToDelete = null;
                    parent.$.colorbox.close();
                });
            };
        }
    ])
    .filter("conditionalTimeAgo", function(amTimeAgoFilter, $filter) {
        return function(dt) {
            var today = Math.round(new Date().getTime());
            var yesterday = today - 24 * 3600000;
            if (dt - yesterday < 0) {
                return $filter("date")(dt, "dd MMM, yyyy");
            }
            return amTimeAgoFilter(dt);
        };
    });

app.service("agency", [
    "skyword",
    "validation",
    "call",
    "setup",
    function(skyword, validation, call, setup) {
        return {
            init: function() {
                function contributorSearch() {
                    var btn = $(this),
                        searchVal = $(".writersSearch").val(),
                        urlAction = "agencyWritersAjax.action",
                        options = {
                            cache: false,
                            type: "POST",
                            data: { writerSearch: searchVal },
                            dataType: "HTML",
                            url: urlAction,
                            success: function(text) {
                                $(".container .body").html(text);
                                $(".contribSearch")
                                    .removeAttr("disabled")
                                    .removeClass("disabled");
                                setup.displaytagAjax();
                            }
                        };
                    call.ajax(urlAction, options);
                    return false;
                }
                function applyAgencyHeader() {
                    var todo = 0,
                        monthResults = 0,
                        budget = 0,
                        banner = 0,
                        productivity = 0,
                        totalViews = 0,
                        funds = 0;

                    $(".agencyDashboard li.body")
                        .first()
                        .find(".agCol")
                        .each(function(i, el) {
                            if ($(el).hasClass("todo")) {
                                todo += $(el).outerWidth(true);
                            } else if ($(el).hasClass("totalViews")) {
                                totalViews += $(el).outerWidth(true);
                            } else if ($(el).hasClass("funds")) {
                                funds += $(el).outerWidth(true);
                            } else if ($(el).hasClass("banner")) {
                                banner += $(el).outerWidth(true);
                            } else if ($(el).hasClass("productivity")) {
                                productivity += $(el).outerWidth(true);
                            }
                        });
                    $(".agencyDashboard .header .banner").width(banner);
                    $(".agencyDashboard .header .review").width(todo - 5);
                    $(".agencyDashboard .header .funds").width(funds - 8);
                    $(".agencyDashboard .header .productivity").width(
                        productivity - 4
                    );
                    $(".agencyDashboard .header .results").width(totalViews);
                }

                // Agency dashboard announcements RRT-245
                function setProgramAnnouncementCookie(bool) {
                    var announcementStopTime = $("#formattedStopTime").val();
                    var date = new Date(announcementStopTime);
                    skyword.setCookie(
                        "programAnnouncementCookie",
                        true,
                        date,
                        true
                    );
                }
                function getProgramAnnouncementCookie() {
                    var programAnnouncementCookie = skyword.getCookie(
                        "programAnnouncementCookie"
                    );
                    return programAnnouncementCookie;
                }

                //onReady
                $(function() {
                    applyAgencyHeader();
                    window.onresize = function(event) {
                        applyAgencyHeader();
                    };

                    /*
                     * agencyAddUser.jsp
                     */
                    $("#add-user-button").click(function(event) {
                        $("#error-box .content").empty();
                        $("#error-box").fadeOut();
                        var urlAction = "agencyAddUserAjax.action",
                            options = {
                                cache: false,
                                type: $("#formSubmit").attr("method"),
                                data: $("#formSubmit").serialize(),
                                url: urlAction,
                                success: function(text) {
                                    validation.parse(text);
                                }
                            };
                        call.ajax(urlAction, options);
                        return false;
                    });

                    /*
                     * agencyAssignNewPrograms.jsp
                     */
                    $("#assign-programs-button").click(function(event) {
                        $("#error-box .content").empty();
                        $("#error-box").fadeOut();
                        var urlAction = "agencyAssignNewProgramsAjax.action",
                            options = {
                                cache: false,
                                type: $("#programRoles").attr("method"),
                                data: $("#programRoles").serialize(),
                                url: urlAction,
                                success: function(text) {
                                    validation.parse(text);
                                }
                            };
                        call.ajax(urlAction, options);
                        return false;
                    });

                    /*
                     * agencyUserDetail.jsp
                     */
                    $("html").on("click", "a.edit-role", function() {
                        var urlAction =
                            "/agencyEditUserRoleAjax.action?" +
                            $(this).attr("id");
                        var result = call.overlay(urlAction, null, 550, null);
                        return result;
                    });
                    $("html").on("click", "a.remove-role", function() {
                        var urlAction =
                            "/agencyRemoveUserRoleAjax.action?" +
                            $(this).attr("id");
                        var result = call.overlay(urlAction, null, 550, null);
                        return result;
                    });
                    $("html").on("click", ".remove-user", function() {
                        var urlAction =
                            "/agencyRemoveUserAjax.action?userId=" +
                            $(this).attr("id");
                        var result = call.overlay(urlAction, null, 550, null);
                        return result;
                    });

                    /*
                     * agencyEditUserRole.jspf
                     */
                    $("html").on("click", "#role-update-button", function() {
                        var urlAction = "/agencyEditUserRoleAjax.action";
                        return call.overlay(
                            urlAction,
                            {
                                userId: document.forms[0].userId.value,
                                roleId: document.forms[0].roleId.value,
                                newManagerId: $("#newManagerId").val(),
                                programId: document.forms[0].programId.value
                            },
                            550,
                            null,
                            "post"
                        );
                    });
                    $("html").on("click", "#assignable-roles", function() {
                        var reassignmentPossible = $(
                            "#reassignment-possible"
                        ).val();
                        if (reassignmentPossible == "true") {
                            if (
                                $(this).val() == 2 ||
                                $(this).val() == 13 ||
                                $(this).val() == 14
                            ) {
                                $("#reassign-container").fadeOut();
                            } else {
                                $("#reassign-container").fadeIn();
                            }
                            $(this).colorbox.resize();
                        }
                    });

                    /*
                     * agencyRemoveUserRole.jspf
                     */
                    $("html").on("click", "#role-remove-button", function() {
                        var urlAction = "/agencyRemoveUserRoleAjax.action";
                        return call.overlay(
                            urlAction,
                            {
                                userId: document.forms[0].userId.value,
                                newManagerId:
                                    document.forms[0].newManagerId.value,
                                programId: document.forms[0].programId.value
                            },
                            550,
                            null,
                            "post"
                        );
                    });

                    $("html").on("click", ".contribSearch", function() {
                        contributorSearch();
                    });

                    $("a.useToggle").on("click", function() {
                        $(this)
                            .closest("td")
                            .find(".useModule")
                            .fadeOut();
                        $(this)
                            .closest("td")
                            .find(".noModule")
                            .fadeIn();
                        $(this)
                            .closest("td")
                            .find(".enableValue")
                            .attr("disabled", "disabled");
                        return false;
                    });

                    $("a.noToggle").on("click", function() {
                        $(this)
                            .closest("td")
                            .find(".noModule")
                            .fadeOut();
                        $(this)
                            .closest("td")
                            .find(".useModule")
                            .fadeIn();
                        $(this)
                            .closest("td")
                            .find(".enableValue")
                            .removeAttr("disabled");
                        return false;
                    });

                    $(".programRoles").attr("disabled", "disabled");

                    $("html").on("keypress", ".writersSearch", function(event) {
                        if (event.which == 13) {
                            $(".contribSearch").click();
                        }
                    });

                    /**
                     * agencyDashboard
                     */
                    $(".financeTooltip").each(function() {
                        var tooltip = $(this)
                            .next()
                            .html();
                        $(this).qtip({
                            content: tooltip,
                            position: {
                                my: "top middle",
                                at: "bottom middle"
                            },
                            show: {
                                event: "mouseenter"
                            },
                            hide: {
                                event: "mouseleave"
                            },
                            style: {
                                width: 250,
                                classes: "qtip-wiki qtip-light qtip-shadow",
                                tip: {
                                    width: 10,
                                    border: 2,
                                    height: 8
                                }
                            }
                        });
                    });

                    // Agency dashboard announcements RRT-245
                    var hideProgramAnnouncement = getProgramAnnouncementCookie();
                    var announcementContainer = $(
                        "#message-for-programusers"
                    ).find(".body");
                    if (!hideProgramAnnouncement) {
                        announcementContainer.show();
                        $(".hide-programuser-message").show();
                        $(".show-programuser-message").hide();
                    } else {
                        announcementContainer.hide();
                        $(".hide-programuser-message").hide();
                        $(".show-programuser-message").show();
                    }
                    if (announcementContainer.is(":visible")) {
                        setProgramAnnouncementCookie(true);
                    }

                    $("html").on("click", ".togglePanel", function() {
                        var container = $(this)
                            .parent()
                            .parent()
                            .parent()
                            .find(".body");
                        if (container.is(":visible")) {
                            $(".hide-programuser-message").hide();
                            $(".show-programuser-message").show();
                        } else {
                            $(".hide-programuser-message").show();
                            $(".show-programuser-message").hide();
                        }
                        container.slideToggle();
                        return false;
                    });

                    /**
                     * agencyProfile
                     */
                    $("select#programs").change(function() {
                        if ($(this).val() != "") {
                            $("#agencyInviteNext")
                                .removeClass("disabled")
                                .removeAttr("disabled");
                        } else {
                            $("#agencyInviteNext")
                                .addClass("disabled")
                                .attr("disabled", "disabled");
                        }
                    });
                });
            }
        };
    }
]);

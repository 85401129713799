angular.module("xeditable").directive("editableFile", [
    "editableDirectiveFactory",
    function(editableDirectiveFactory) {
        return editableDirectiveFactory({
            directiveName: "editableFile",
            inputTpl: '<input type="file" fileupload>',
            render: function() {
                this.parent.render.call(this);
                this.inputEl.attr("minSize", this.attrs.min);
                this.inputEl.attr("maxSize", this.attrs.max);
                this.inputEl.attr("callback", this.attrs.callback);
                this.inputEl.attr("data-url", this.attrs.url);
            }
        });
    }
]);

app.directive("styledselect", [
    function() {
        return {
            restrict: "A",
            scope: {
                type: "@",
                model: "=ngModel"
            },
            link: function(scope, elem, attrs) {
                scope.$watch("model", function(v) {
                    if (undefined !== v) {
                        $(elem).chosen();
                    }
                });
            }
        };
    }
])
    .directive("dateAndTimePicker", [
        "setup",
        function(setup) {
            return {
                restrict: "C",
                scope: { title: "@" },
                link: function(scope, elem, attrs) {
                    setup.dateAndTimePicker(attrs.id);
                }
            };
        }
    ])
    .directive("gauge", [
        "skywordGraphs",
        function(skywordGraphs) {
            return {
                restrict: "C",
                scope: { title: "@" },
                link: function(scope, elem, attrs) {
                    skywordGraphs.getGraph({
                        graphType: "gauge",
                        location: attrs.id
                    });
                }
            };
        }
    ])
    .directive("enforcemaxlength", [
        function() {
            return {
                require: "ngModel",
                link: function(scope, element, attrs, ngModelCtrl) {
                    var maxlength = parseInt(attrs.enforcemaxlength);

                    function validate(text) {
                        if (text.length > maxlength) {
                            var transformedInput = text.substring(0, maxlength);
                            ngModelCtrl.$setViewValue(transformedInput);
                            ngModelCtrl.$render();
                            return transformedInput;
                        }
                        return text;
                    }

                    ngModelCtrl.$parsers.push(validate);
                }
            };
        }
    ])
    .directive("error", [
        function() {
            return {
                restrict: "E",
                scope: {},
                template:
                    '<div class="message error"><ul class="content" ng-transclude></ul></div>',
                transclude: true,
                replace: true
            };
        }
    ])
    .directive("selectbox", [
        "$timeout",
        "$interval",
        function($timeout, $interval) {
            return {
                restrict: "A",
                link: function(scope, elem, attrs) {
                    // translation must be ready
                    var setup = $interval(function() {
                        //only run on the select if the translation has completed
                        if (!$(elem).find("option:contains(angular.)").length) {
                            $timeout(function() {
                                if (attrs.fixedwidth) {
                                    elem.selectBoxIt({ autoWidth: false });
                                } else {
                                    elem.selectBoxIt();
                                }
                                scope.$watch(attrs.ngModel, function() {
                                    elem.selectBoxIt("refresh");
                                });
                                $interval.cancel(setup);
                            }, 0);
                        }
                    }, 200);
                }
            };
        }
    ])
    .directive("datePicker", [
        "$translate",
        "setup",
        function($translate, setup) {
            return {
                restrict: "AC",
                scope: {
                    model: "=ngModel"
                },
                link: function(scope, elem, attrs) {
                    elem.datepicker({
                        dateFormat: "mm-dd-yy",
                        maxDate: "+0D",
                        onClose: function(dateText, inst) {
                            $(this).focus();
                        }
                    });
                }
            };
        }
    ])
    .directive("validate", [
        function() {
            return {
                restrict: "A",
                require: "ngModel",
                link: function(scope, elem, attrs, ctrl) {
                    if (attrs.vtype === "date") {
                        var format = attrs.format;
                        ctrl.$parsers.unshift(function(value) {
                            // parsers fire when the model changes via input
                            var valid = true,
                                matcher = Date.parseExact(value, format, null);
                            if (undefined !== value && "" !== value) {
                                if (null === matcher) {
                                    valid = false;
                                }
                            }
                            ctrl.$setValidity("validate", valid);
                            console.log(elem);
                            return valid ? value : undefined;
                        });
                        ctrl.$formatters.unshift(function(value) {
                            // formatters fire when the model directly changes
                            var valid = true,
                                matcher = Date.parseExact(value, format);
                            if (undefined !== value && "" !== value) {
                                if (null === matcher) {
                                    valid = false;
                                }
                            }
                            ctrl.$setValidity("validate", valid);
                            return value;
                        });
                    } else if (attrs.vtype === "text") {
                        /*
                     ctrl.$parsers.unshift(function(value) {
                     console.log(attrs.requiredwhen);
                     var valid = true;
                     if (attrs.requiredwhen) {
                     if (value === '') {
                     valid = false;
                     }
                     } else {
                     valid = true;
                     }
                     console.log(value);

                     ctrl.$setValidity('validate', valid);
                     return value;
                     });
                     */
                    }
                }
            };
        }
    ])
    .directive("topnav", [
        "skyword",
        "setup",
        function(skyword, setup) {
            return {
                restrict: "A",
                scope: { title: "@" },
                link: function(scope, elem, attrs) {
                    setup.platformAnnouncement();
                    $("html").delegate("main, [topnav]", "hover", function(
                        event
                    ) {
                        if (event.type == "mouseenter") {
                            skyword.navDelay = setTimeout(function() {
                                if (
                                    skyword.currentSubnav != null &&
                                    skyword.currentSubnav.hasClass("hoverOff")
                                ) {
                                    skyword.currentSubnav.removeClass(
                                        "showDelay"
                                    );
                                }
                            }, 500);
                        } else {
                            clearTimeout(skyword.navDelay);
                            if (skyword.currentSubnav != null)
                                skyword.currentSubnav.addClass("hoverOff");
                        }
                    });
                    $("#programNav > ul > li").hover(
                        function() {
                            if (skyword.currentSubnav != null)
                                skyword.currentSubnav.removeClass("showDelay");
                        },
                        function() {}
                    );
                    $(".subnav").hover(
                        function() {
                            if (skyword.currentSubnav != null)
                                skyword.currentSubnav.removeClass("hoverOff");
                            skyword.currentSubnav = $(this);
                            skyword.currentSubnav.addClass("showDelay");
                            $(".subnav").removeClass("showDelay");
                            clearTimeout(skyword.navDelay);
                        },
                        function() {
                            skyword.currentSubnav.addClass("showDelay");
                            skyword.currentSubnav.addClass("hoverOff");
                        }
                    );
                }
            };
        }
    ])
    .directive("inlineForm", [
        "skyword",
        function(skyword) {
            return {
                restrict: "AE",
                link: function(scope, elem, attrs) {}
            };
        }
    ])
    .directive("inline", [
        "skyword",
        function(skyword) {
            return {
                restrict: "AE",
                scope: {
                    model: "=inlineEdit",
                    handleSave: "&onSave",
                    handleCancel: "&onCancel"
                },
                link: function(scope, elem, attrs) {
                    var previousValue;

                    scope.edit = function() {
                        scope.editMode = true;
                        previousValue = scope.model;

                        $timeout(
                            function() {
                                elem.find("input")[0].focus();
                            },
                            0,
                            false
                        );
                    };
                    scope.save = function() {
                        scope.editMode = false;
                        scope.handleSave({ value: scope.model });
                    };
                    scope.cancel = function() {
                        scope.editMode = false;
                        scope.model = previousValue;
                        scope.handleCancel({ value: scope.model });
                    };
                }
            };
        }
    ])
    .directive("dateTooltip", [
        function() {
            return {
                restrict: "C",
                link: function(scope, elem, attrs) {
                    if(attrs.timezone != null){
                        var prettyDateTime = moment(
                            parseInt(attrs.amTimeAgo)
                        ).tz(attrs.timezone).format("MMMM Do YYYY, h:mm a");
                    } else{
                        var prettyDateTime = moment(
                            parseInt(attrs.amTimeAgo)
                        ).format("MMMM Do YYYY, h:mm a");
                    }
                    attrs.$set("title", prettyDateTime);
                    attrs.$set("datetime", prettyDateTime);
                }
            };
        }
    ])
     .directive("dateTimeTool", [
        function () {
          return {
            restrict: "C",
            link: function (scope, elem, attrs) {

                if(attrs.amTimeAgo !== ""){
                 if (attrs.timezone != null) {
                    var prettyDateTime = moment(
                        parseInt(attrs.amTimeAgo)
                    ).tz(attrs.timezone).format("MMMM Do YYYY, h:mm a");

                    var prettyDateTime24 = moment(

                        parseInt(attrs.amTimeAgo)
                    ).tz(attrs.timezone).format("MMMM Do YYYY, HH:mm");

                 } else {
                   var prettyDateTime = moment(parseInt(attrs.amTimeAgo)).format("MMMM Do YYYY, h:mm a");
                   var prettyDateTime24 = moment(parseInt(attrs.amTimeAgo)).format("MMMM Do YYYY, HH:mm");

                 }
                 } else {
                   var prettyDateTime = "--";
                   var prettyDateTime24 = "--";
                 }
              attrs.$set("title", prettyDateTime);
              attrs.$set("content", prettyDateTime);
              attrs.$set("content24", prettyDateTime24);
              attrs.$set("datetime", prettyDateTime);
            },
          };
        },
      ])
      .directive("colorbox", [
        "$compile",
        "$rootScope",
        "$timeout",
        "SkyFactory",
        function($compile, $rootScope, $timeout, SkyFactory) {
            return {
                link: function(scope, element, attrs) {
                    angular.element(element).click("bind", function() {
                        $.colorbox({
                            height: attrs.modalheight + "px",
                            href: attrs.colorbox,
                            onClosed: function() {
                                $("body").css({ overflow: "" });
                            },
                            onComplete: function() {
                                $("body").css({ overflow: "" });
                                $rootScope.$apply(function() {
                                    var csrfToken = angular
                                            .element("#cToken")
                                            .val(),
                                        programId = angular
                                            .element("#attrs-id")
                                            .data("programid"),
                                        pageLoadTime = angular
                                            .element("#pageLoadTime")
                                            .val();

                                    /**
                                 * Would the following be better with something like:
                                 *
                                 if (undefined !== attrs) {
                                     $rootScope.attrs = JSON.parse(attrs);
                                 }
                                 */

                                    if (
                                        !SkyFactory.reallyEmptyCheck(csrfToken)
                                    ) {
                                        $rootScope.csrfToken = csrfToken;
                                    }

                                    if (
                                        !SkyFactory.reallyEmptyCheck(
                                            pageLoadTime
                                        )
                                    ) {
                                        $rootScope.pageLoadTime = pageLoadTime;
                                    }

                                    if (
                                        !SkyFactory.reallyEmptyCheck(
                                            attrs.containerid
                                        )
                                    ) {
                                        $rootScope.uploadContainerId =
                                            attrs.containerid;
                                    }

                                    if (
                                        !SkyFactory.isUndefinedOrNull(programId)
                                    ) {
                                        $rootScope.programId = programId;
                                    }

                                    console.log(
                                        "programId: " +
                                            attrs.programid +
                                            " | " +
                                            angular.isUndefined(programId) +
                                            " | " +
                                            (null === programId)
                                    );
                                    if (
                                        SkyFactory.reallyEmptyCheck(
                                            $rootScope.programId
                                        )
                                    ) {
                                        $rootScope.programId = attrs.programid;
                                    }

                                    if (
                                        !SkyFactory.reallyEmptyCheck(
                                            attrs.dataid
                                        )
                                    ) {
                                        $rootScope.contentTemplateElementDataId =
                                            attrs.dataid;
                                    }

                                    if (
                                        !SkyFactory.reallyEmptyCheck(
                                            attrs.contentrequestid
                                        )
                                    ) {
                                        $rootScope.contentRequestId =
                                            attrs.contentrequestid;
                                    }

                                    if (
                                        !SkyFactory.reallyEmptyCheck(
                                            attrs.attachmentid
                                        )
                                    ) {
                                        $rootScope.attachmentId =
                                            attrs.attachmentid;
                                    }

                                    if (
                                        !SkyFactory.reallyEmptyCheck(
                                            attrs.imagetitle
                                        )
                                    ) {
                                        $rootScope.imageTitle =
                                            attrs.imagetitle;
                                    }

                                    if (
                                        !SkyFactory.reallyEmptyCheck(
                                            attrs.redactorid
                                        )
                                    ) {
                                        $rootScope.redactorid =
                                            attrs.redactorid;
                                    }

                                    if (
                                        !SkyFactory.reallyEmptyCheck(
                                            attrs.uploadid
                                        )
                                    ) {
                                        $rootScope.uploadid = attrs.uploadid;
                                    }
                                    var content = $("#cboxLoadedContent");
                                    $compile(content)($rootScope);
                                });
                            }
                        });
                    });
                }
            };
        }
    ])
    .directive("enterDirective", function() {
        return {
            link: function(scope, element, attrs) {
                $(element).keypress(function(e) {
                    if (13 === e.keyCode) {
                        console.log("Enter pressed " + element.val());
                        e.preventDefault();
                        return;
                    }
                });
            }
        };
    })
    .directive("myDir", function($compile) {
        return {
            terminal: true,
            priority: 1001,
            compile: function(el) {
                el.removeAttr("my-dir");
                el.attr("ng-click", "fxn()");
                var fn = $compile(el);
                return function(scope) {
                    fn(scope);
                };
            }
        };
    });

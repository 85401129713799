app.service("notes", [
    "redactor",
    "call",
    function(redactor, call) {
        return {
            init: function() {
                var initContributorNotes,
                    removeContributorNote,
                    saveContributorNote,
                    cancelContributorNote,
                    editContributorNote,
                    addContributorNote,
                    checkContributorNote;
                window.contributorNoteDataId = null;
                window.removeContributorNote = function() {
                    var tmpNoteId = window.contributorNoteDataId;
                    var tmpSelector = '[data-contributornoteid=' + tmpNoteId + ']';
                    var lis = $(tmpSelector);
                    if (
                        null !== lis &&
                        0 < lis.length
                    ) {
                        lis.fadeOut("fast", function() {
                            lis.remove();
                        });
                    }
                };
                checkContributorNote = function() {
                    var div = $(this),
                        noteText = div.closest(".noteText"),
                        actions = noteText.siblings(".noteActions"),
                        a = actions.find("a.saveContributorNote"),
                        text = $.trim(div.text().replace(/\s{2,}/g, " "));
                    if (null === text || "" === text) {
                        a.addClass("disabled");
                    } else {
                        a.removeClass("disabled");
                    }
                };
                initContributorNotes = function() {
                    var noteModule = $("#skywordnotes-module"),
                        options,
                        opts,
                        urlAction,
                        notesMeta,
                        displaySkywordNote = $(".displaySkywordNote"),
                        aId = null,
                        pId = null;
                    if (0 === noteModule.length) {
                        return;
                    }
                    notesMeta = $('[name="skywordnotes-metainfo"]');
                    urlAction = "/loadContributorNotesAjax.action";
                    pId = notesMeta.attr("data-programId");
                    aId = notesMeta.attr("data-agencyId");
                    opts = {
                        contributorId: (notesMeta).attr("data-contributorId")
                    };
                    if (undefined !== pId && null !== pId) {
                        opts.programId = pId;
                    }
                    if (undefined !== aId && null !== aId) {
                        opts.agencyId = aId;
                    }
                    options = {
                        cache: false,
                        type: "POST",
                        data: opts,
                        dataType: "html",
                        url: urlAction,
                        success: function(data) {
                            displaySkywordNote.each(function( i ) {
                                $(this).html(data);
                                $(this).fadeIn("fast");
                            })

                        },
                        error: function(data, status, err) {
                            console.log([data, status, err]);
                        },
                        complete: function() {
                            redactor.init();
                        }
                    };
                    call.ajax(urlAction, options);
                };
                saveContributorNote = function() {
                    var a = $(this),
                        noteActions = a.parent(),
                        opts,
                        options,
                        noteText = noteActions.siblings(".noteText"),
                        textarea = noteText.find("textarea"),
                        redactorId = "#" + textarea.attr("id"),
                        red = noteText.find(redactorId).redactor("getObject"),
                        noteModule = noteText.closest("#skywordnotes-module"),
                        noteMeta = $('[name="skywordnotes-metainfo"]'),
                        urlAction = "/saveContributorNoteAjax.action",
                        pId = null,
                        aId = null,
                        noteId = null,
                        note = "",
                        nText = "",
                        displaySkywordNote = $(".displaySkywordNote"),
                        editContributorNoteDiv = a.closest(
                            ".editContributorNoteDiv"
                        ),
                        displayContributorNote = editContributorNoteDiv.siblings(
                            ".displayContributorNote"
                        ),
                        redactorEditor = textarea.siblings(".redactor_editor");
                    if (0 === noteModule.length) {
                        return;
                    }
                    nText = $.trim(red.$editor.text().replace(/\s{2,}/g, " "));
                    if (
                        null === nText ||
                        "" === nText ||
                        a.hasClass("disabled")
                    ) {
                        a.addClass("disabled");
                        return false;
                    }
                    a.addClass("disabled");
                    redactorEditor.attr("contenteditable", false);
                    noteId = a.attr("data-contributorNoteId");
                    pId = noteMeta.attr("data-programId");
                    aId = noteMeta.attr("data-agencyId");
                    note = $.trim(red.$editor.html().replace(/\s{2,}/g, " "));
                    opts = {
                        contributorId: noteMeta.attr("data-contributorId"),
                        noteText: note
                    };
                    if (undefined !== pId && null !== pId) {
                        opts.programId = pId;
                    }
                    if (undefined !== aId && null !== aId) {
                        opts.agencyId = aId;
                    }
                    if (undefined !== noteId && null !== noteId) {
                        opts.contributorNoteId = noteId;
                    }
                    options = {
                        cache: false,
                        type: "POST",
                        data: opts,
                        dataType: "html",
                        url: urlAction,
                        success: function(data) {
                            if (
                                displayContributorNote.hasClass(
                                    "addSkywordNote"
                                )
                            ) {
                                editContributorNoteDiv.fadeOut(
                                    "fast",
                                    function() {
                                        displayContributorNote.fadeIn("fast");
                                        redactorEditor.html("");
                                        textarea.val("");
                                    }
                                );
                            }
                            displaySkywordNote.each(function( i ) {
                                $(this).html(data);
                            })
                        },
                        error: function(data, status, err) {
                            console.log([data, status, err]);
                        },
                        complete: function() {
                            redactorEditor.attr("contenteditable", true);
                            redactor.init();
                        }
                    };
                    call.ajax(urlAction, options);
                    return false;
                }; // end saveContributorNote
                cancelContributorNote = function() {
                    var editContributorNoteDiv = $(this).closest(
                            ".editContributorNoteDiv"
                        ),
                        editContributorNoteDiv2 = editContributorNoteDiv.siblings(
                            ".editContributorNoteDiv"
                        ),
                        addSkywordNote = editContributorNoteDiv.siblings(
                            ".addSkywordNote"
                        ),
                        displayContributorNote = editContributorNoteDiv.siblings(
                            ".displayContributorNote"
                        );
                    if (0 < editContributorNoteDiv2.length) {
                        editContributorNoteDiv.fadeOut("fast");
                        editContributorNoteDiv2.fadeOut("fast", function() {
                            displayContributorNote.fadeIn("fast");
                            if (0 < addSkywordNote.length) {
                                addSkywordNote.fadeIn("fast");
                            }
                        });
                    } else {
                        editContributorNoteDiv.fadeOut("fast", function() {
                            displayContributorNote.fadeIn("fast");
                            if (0 < addSkywordNote.length) {
                                addSkywordNote.fadeIn("fast");
                            }
                        });
                    }
                    return false;
                };
                addContributorNote = function() {
                    var a = $(this),
                        addSkywordNote = a.closest(".addSkywordNote"),
                        editContributorNoteDiv = addSkywordNote.siblings(
                            ".editContributorNoteDiv"
                        ),
                        textarea = editContributorNoteDiv.find(
                            ".redactor_contributorNote"
                        );
                    textarea.html("");
                    addSkywordNote.fadeOut("fast", function() {
                        editContributorNoteDiv.fadeIn("fast", function() {
                            textarea.focus();
                        });
                    });
                    return false;
                };
                editContributorNote = function() {
                    var a = $(this),
                        displayContributorNote = a.closest(
                            ".displayContributorNote"
                        ),
                        editContributorNoteDiv = displayContributorNote.siblings(
                            ".editContributorNoteDiv"
                        ),
                        textarea = editContributorNoteDiv.find(
                            ".redactor_contributorNote"
                        ),
                        noteInfo = displayContributorNote.siblings(".noteInfo");
                    editContributorNoteDiv.show();
                    noteInfo.fadeOut("fast");
                    displayContributorNote.fadeOut("fast", function() {
                        editContributorNoteDiv.fadeIn("fast", function() {
                            textarea.focus();
                        });
                    });
                };
                removeContributorNote = function() {
                    var a = $(this),
                        li = a.closest(".noteWrapper"),
                        urlAction = "/removeContributorNoteAjax.action",
                        opts,
                        options,
                        noteId = null;
                    noteId = a.attr("data-contributorNoteId");
                    if (null === noteId) {
                        return false;
                    }
                    window.contributorNoteDataId = noteId;
                    return call.overlay(
                        "removeContributorNoteAjax.action?contributorNoteId=" +
                            noteId,
                        null,
                        500,
                        null,
                        null,
                        null
                    );
                };

                //onReady
                $(function() {
                    initContributorNotes();
                    $("body").on(
                        "click",
                        "a.addContributorNote",
                        null,
                        addContributorNote
                    );
                    $("body").on(
                        "click",
                        "a.editContributorNote",
                        null,
                        editContributorNote
                    );
                    $("body").on(
                        "click",
                        "a.saveContributorNote",
                        null,
                        saveContributorNote
                    );
                    $("body").on(
                        "click",
                        "a.cancelSkywordNote",
                        null,
                        cancelContributorNote
                    );
                    $("body").on(
                        "click",
                        "a.removeContributorNote",
                        null,
                        removeContributorNote
                    );
                    $("body").on(
                        "keypress",
                        "div.redactor_contributorNote",
                        null,
                        checkContributorNote
                    );
                    // btn-remove-note
                    $("html").on("click", "#btn-remove-note", null, function() {
                        var btn = $(this),
                            form = btn.closest("form"),
                            urlAction = form.attr("action"),
                            getPost = "POST",
                            options = {
                                cache: false,
                                type: getPost,
                                data: form.serialize(),
                                url: urlAction,
                                success: function(response) {
                                    window.removeContributorNote();
                                    $.colorbox.close();
                                },
                                error: function(data, status, err) {
                                    var msg =
                                        "Error getting: " + urlAction + "\n\n";
                                    msg += "Status: " + status + "\n\n";
                                    // msg += 'Parameters: ' + JSON.stringify(parameters) + "\n\n";
                                    console.log(msg);
                                }
                            };
                        call.ajax(urlAction, options);
                        return false;
                    });
                });
            } // end notes.init()
        };
    }
]);

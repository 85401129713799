app.service("writers", [
    "skyword",
    "setup",
    "call",
    "$translate",
    "validation",
    function(skyword, setup, call, $translate, validation) {
        var setupPayrate = function() {
                $(".categoryList").each(function() {
                    var inputs = $(this)
                        .parent()
                        .find("input[type='checkbox']");
                    checkContentTypePayrate(inputs);
                });
            },
            checkContentTypePayrate = function(inputs) {
                var contentTypePicked = false,
                    contentTypeId = inputs
                        .closest(".categoryList")
                        .attr("data-value"),
                    contentTypeCheckbox = $(
                        "input[value='" + contentTypeId + "']"
                    )
                        .parent()
                        .find(".enableCompensation"),
                    customPayment = contentTypeCheckbox
                        .closest("tr")
                        .find(".customApprovalAmount input");
                inputs.each(function() {
                    if ($(this).is(":checked")) {
                        contentTypePicked = true;
                    }
                });
                if (!contentTypePicked) {
                    contentTypeCheckbox
                        .attr("disabled", "disabled")
                        .addClass("disabled");
                    customPayment
                        .attr("disabled", "disabled")
                        .addClass("disabled");
                } else {
                    contentTypeCheckbox
                        .removeAttr("disabled")
                        .removeClass("disabled");
                    customPayment
                        .removeAttr("disabled")
                        .removeClass("disabled");
                }
            },
            loadEarningsReport = function(theTab) {
                var urlAction = "earningsSummaryReportAjax.action",
                    earningsPanel = $("#earnings-panel"),
                    opts = {
                        contributorAccountId: $("#contributorAccountId").val(),
                        month: earningsPanel.find(".currentMonth").val(),
                        year: earningsPanel.find(".currentYear").val(),
                        payPeriod: parseInt(
                            earningsPanel.find(".payPeriod").val(),
                            10
                        )
                    },
                    options = {
                        cache: false,
                        type: "POST",
                        data: opts,
                        dataType: "HTML",
                        url: urlAction,
                        success: function(text) {
                            $("#earnings-panel .container .body").html(text);
                        },
                        complete: function() {
                            $(".contribReport")
                                .removeAttr("disabled")
                                .removeClass("disabled");
                            if (
                                undefined !== theTab &&
                                null !== theTab &&
                                theTab instanceof jQuery
                            ) {
                                theTab
                                    .find(
                                        ".tableHeader, .displayTableContainer"
                                    )
                                    .show();
                            }
                        }
                    };
                if (
                    undefined !== theTab &&
                    null !== theTab &&
                    theTab instanceof jQuery
                ) {
                    theTab.fadeIn("fast");
                    theTab.find(".tableHeader, .displayTableContainer").hide();
                }
                call.skyjax(urlAction, options);
                return false;
            },
            loadContentResultsReport = function(theTab) {
                var urlAction = "contentResultsReportAjax.action",
                    startDate,
                    endDate,
                    contentResultsPanel = $("#content-results-panel"),
                    opts = {
                        contributorAccountId: $("#contributorAccountId").val(),
                        startdate: contentResultsPanel.find(".startDate").val(),
                        enddate: contentResultsPanel.find(".endDate").val()
                    },
                    options = {
                        cache: false,
                        type: "POST",
                        data: opts,
                        dataType: "HTML",
                        url: urlAction,
                        success: function(text) {
                            $("#content-results-panel .container .body").html(
                                text
                            );
                        },
                        complete: function() {
                            $(".contribReport")
                                .removeAttr("disabled")
                                .removeClass("disabled");
                            if (
                                undefined !== theTab &&
                                null !== theTab &&
                                theTab instanceof jQuery
                            ) {
                                theTab
                                    .find(
                                        ".tableHeader, .displayTableContainer"
                                    )
                                    .show();
                            }
                        }
                    };
                if (
                    undefined !== theTab &&
                    null !== theTab &&
                    theTab instanceof jQuery
                ) {
                    theTab.fadeIn("fast");
                    theTab.find(".tableHeader, .displayTableContainer").hide();
                }
                call.skyjax(urlAction, options);
                return false;
            };

        return {
            init: function() {
                //onReady
                $(function() {
                    setupPayrate();

                    $("#btn-edit-comment").click(function() {
                        $("#comments-author").hide();
                        $("#comments").show();
                        $("#btn-edit-comment").hide();
                        $("#btn-save-comment").show();
                        return false;
                    });

                    $("#btn-save-comment").click(function() {
                        var a = $(this),
                            urlAction = a.attr("href"),
                            getPost = "POST",
                            options,
                            opts,
                            paId = parseInt(
                                a.attr("data-programAccountId"),
                                10
                            );

                        opts = {
                            programAccountId: paId,
                            comments: $("#comments").val()
                        };

                        options = {
                            cache: false,
                            type: getPost,
                            data: opts,
                            url: urlAction,
                            success: function(data) {
                                if (!validation.parse(data)) {
                                    $("#comments-author")
                                        .html(data)
                                        .show();
                                    $("#comments").hide();
                                    $("#btn-edit-comment").show();
                                    $("#btn-save-comment").hide();
                                }
                            },
                            error: function(data, status, err) {}
                        };
                        call.ajax(urlAction, options);
                        return false;
                    });

                    $("#tabs-row li").each(function(i, el) {
                        $(el).click(function() {
                            var id = $(this).attr("id");
                            setup.followMenuResize();
                            $("#contributor-record > div").removeClass(
                                "view-profile-background-color"
                            );

                            $(".panels").fadeOut("fast");

                            if ("application" === id) {
                                $("#article-suggestions .tab").click();
                            }
                            if ("profile" === id) {
                                $("#content-performance-module .tab").click();
                                $("#contributor-record > div").addClass(
                                    "view-profile-background-color"
                                );
                            }
                            if ("earnings" === id) {
                                loadEarningsReport($("#" + id + "-panel"));
                            } else if ("content-results" === id) {
                                // managerContentResultsReportAjax
                                loadContentResultsReport(
                                    $("#" + id + "-panel")
                                );
                            } else {
                                $("#" + id + "-panel").fadeIn("fast");
                            }
                        });
                    });

                    setTimeout(function() {
                        var hash = window.location.hash;
                        if (
                            $("#show-content-samples").val() === "true" ||
                            "#contributor-profile" === hash
                        ) {
                            $("#profile.tab a").click();
                        }
                    }, 50);
                });
            },
            loadEarningsReport: function() {
                loadEarningsReport();
            },
            loadContentResultsReport: function() {
                loadContentResultsReport();
            },
            checkContentTypePayrate: function(inputs) {
                checkContentTypePayrate(inputs);
            },
            contributorSearch: function() {
                $("#contributorSearchButton").click(function() {
                    let contentSamplescheckbox = document.getElementById(
                        "contentSamples"
                    );
                    let contributorBiocheckbox = document.getElementById(
                        "contributorBio"
                    );
                    let searchNamecheckbox = document.getElementById(
                        "searchNames"
                    );
                    let searchTagscheckbox = document.getElementById(
                        "searchTags"
                    );

                    window.sessionStorage.setItem(
                        "contentSampleChecked",
                        contentSamplescheckbox.checked
                    );
                    window.sessionStorage.setItem(
                        "contributorBioChecked",
                        contributorBiocheckbox.checked
                    );
                    window.sessionStorage.setItem(
                        "searchNameChecked",
                        searchNamecheckbox.checked
                    );
                    window.sessionStorage.setItem(
                        "searchTagsChecked",
                        searchTagscheckbox.checked
                    );

                    var talentPoolAccess = document.getElementById(
                        "talentPoolAccess"
                    );

                    if (talentPoolAccess.value === "true") {
                        let notReadyCheckbox = document.getElementById(
                            "notReady"
                        );
                        let unvettedCheckbox = document.getElementById(
                            "unvetted"
                        );
                        let qualifiedCheckbox = document.getElementById(
                            "qualified"
                        );
                        let unqualifiedCheckbox = document.getElementById(
                            "unqualified"
                        );

                        window.sessionStorage.setItem(
                            "notReady",
                            notReadyCheckbox.checked
                        );
                        window.sessionStorage.setItem(
                            "unvetted",
                            unvettedCheckbox.checked
                        );
                        window.sessionStorage.setItem(
                            "qualified",
                            qualifiedCheckbox.checked
                        );
                        window.sessionStorage.setItem(
                            "unqualified",
                            unqualifiedCheckbox.checked
                        );

                        let vettingState = document.getElementById(
                            "vettingStatus"
                        );

                        if (
                            vettingState.value === "" ||
                            vettingState.value === null
                        ) {
                            vettingState.value = "4";
                        }
                    }

                    $(".filterBar, .filterContent").hide();
                    $(".sampleLoader").show();
                    setTimeout(
                        $("#contributorSearchForm")
                            .get(0)
                            .submit(),
                        500
                    );
                });

                $(document).on("ready", function() {
                    let contentSamplescheckbox = document.getElementById(
                        "contentSamples"
                    );
                    let contributorBiocheckbox = document.getElementById(
                        "contributorBio"
                    );
                    let searchNamecheckbox = document.getElementById(
                        "searchNames"
                    );
                    let searchTagscheckbox = document.getElementById(
                        "searchTags"
                    );

                    let contentSampleChecked = window.sessionStorage.getItem(
                        "contentSampleChecked"
                    );
                    let contributorBioChecked = window.sessionStorage.getItem(
                        "contributorBioChecked"
                    );
                    let searchNameChecked = window.sessionStorage.getItem(
                        "searchNameChecked"
                    );
                    let searchTagsChecked = window.sessionStorage.getItem(
                        "searchTagsChecked"
                    );

                    if (contentSampleChecked !== null) {
                        contentSamplescheckbox.checked =
                            contentSampleChecked === "false" ? false : true;
                    }

                    if (contributorBioChecked !== null) {
                        contributorBiocheckbox.checked =
                            contributorBioChecked === "false" ? false : true;
                    }

                    if (searchNameChecked !== null) {
                        searchNamecheckbox.checked =
                            searchNameChecked === "false" ? false : true;
                    }

                    if (searchTagsChecked !== null) {
                        searchTagscheckbox.checked =
                            searchTagsChecked === "false" ? false : true;
                    }

                    if (
                        !contentSamplescheckbox.checked &&
                        !contributorBiocheckbox.checked &&
                        !searchNamecheckbox.checked &&
                        !searchTagscheckbox.checked
                    ) {
                        $("#searchByContentSamples").removeAttr("disabled");
                        $("#searchByContributorBio").removeAttr("disabled");
                        $("#searchByName").removeAttr("disabled");
                        $("#searchByTag").removeAttr("disabled");
                    } else {
                        if (contentSamplescheckbox.checked) {
                            $("#searchByContentSamples").removeAttr("disabled");
                        } else {
                            $("#searchByContentSamples").attr(
                                "disabled",
                                "disabled"
                            );
                        }
                        if (contributorBiocheckbox.checked) {
                            $("#searchByContributorBio").removeAttr("disabled");
                        } else {
                            $("#searchByContributorBio").attr(
                                "disabled",
                                "disabled"
                            );
                        }
                        if (searchNamecheckbox.checked) {
                            $("#searchByName").removeAttr("disabled");
                        } else {
                            $("#searchByName").attr("disabled", "disabled");
                        }
                        if (searchTagscheckbox.checked) {
                            $("#searchByTag").removeAttr("disabled");
                        } else {
                            $("#searchByTag").attr("disabled", "disabled");
                        }
                    }

                    contentSamplescheckbox.addEventListener("change", () => {
                        if (contentSamplescheckbox.checked) {
                            $("#searchByContentSamples").removeAttr("disabled");
                            if (!contributorBiocheckbox.checked)
                                $("#searchByContributorBio").attr(
                                    "disabled",
                                    "disabled"
                                );
                            if (!searchNamecheckbox.checked)
                                $("#searchByName").attr("disabled", "disabled");
                            if (!searchTagscheckbox.checked)
                                $("#searchByTag").attr("disabled", "disabled");
                        } else {
                            if (
                                !contentSamplescheckbox.checked &&
                                !contributorBiocheckbox.checked &&
                                !searchNamecheckbox.checked &&
                                !searchTagscheckbox.checked
                            ) {
                                $("#searchByContentSamples").removeAttr(
                                    "disabled"
                                );
                                $("#searchByContributorBio").removeAttr(
                                    "disabled"
                                );
                                $("#searchByName").removeAttr("disabled");
                                $("#searchByTag").removeAttr("disabled");
                            } else {
                                $("#searchByContentSamples").attr(
                                    "disabled",
                                    "disabled"
                                );
                            }
                        }
                    });

                    contributorBiocheckbox.addEventListener("change", () => {
                        if (contributorBiocheckbox.checked) {
                            $("#searchByContributorBio").removeAttr("disabled");
                            if (!contentSamplescheckbox.checked)
                                $("#searchByContentSamples").attr(
                                    "disabled",
                                    "disabled"
                                );
                            if (!searchNamecheckbox.checked)
                                $("#searchByName").attr("disabled", "disabled");
                            if (!searchTagscheckbox.checked)
                                $("#searchByTag").attr("disabled", "disabled");
                        } else {
                            if (
                                !contentSamplescheckbox.checked &&
                                !contributorBiocheckbox.checked &&
                                !searchNamecheckbox.checked &&
                                !searchTagscheckbox.checked
                            ) {
                                $("#searchByContentSamples").removeAttr(
                                    "disabled"
                                );
                                $("#searchByContributorBio").removeAttr(
                                    "disabled"
                                );
                                $("#searchByName").removeAttr("disabled");
                                $("#searchByTag").removeAttr("disabled");
                            } else {
                                $("#searchByContributorBio").attr(
                                    "disabled",
                                    "disabled"
                                );
                            }
                        }
                    });

                    searchNamecheckbox.addEventListener("change", () => {
                        if (searchNamecheckbox.checked) {
                            $("#searchByName").removeAttr("disabled");
                            if (!contentSamplescheckbox.checked)
                                $("#searchByContentSamples").attr(
                                    "disabled",
                                    "disabled"
                                );
                            if (!contributorBiocheckbox.checked)
                                $("#searchByContributorBio").attr(
                                    "disabled",
                                    "disabled"
                                );
                            if (!searchTagscheckbox.checked)
                                $("#searchByTag").attr("disabled", "disabled");
                        } else {
                            if (
                                !contentSamplescheckbox.checked &&
                                !contributorBiocheckbox.checked &&
                                !searchNamecheckbox.checked &&
                                !searchTagscheckbox.checked
                            ) {
                                $("#searchByContentSamples").removeAttr(
                                    "disabled"
                                );
                                $("#searchByContributorBio").removeAttr(
                                    "disabled"
                                );
                                $("#searchByName").removeAttr("disabled");
                                $("#searchByTag").removeAttr("disabled");
                            } else {
                                $("#searchByName").attr("disabled", "disabled");
                            }
                        }
                    });

                    searchTagscheckbox.addEventListener("change", () => {
                        if (searchTagscheckbox.checked) {
                            $("#searchByTag").removeAttr("disabled");
                            if (!contentSamplescheckbox.checked)
                                $("#searchByContentSamples").attr(
                                    "disabled",
                                    "disabled"
                                );
                            if (!contributorBiocheckbox.checked)
                                $("#searchByContributorBio").attr(
                                    "disabled",
                                    "disabled"
                                );
                            if (!searchNamecheckbox.checked)
                                $("#searchByName").attr("disabled", "disabled");
                        } else {
                            if (
                                !contentSamplescheckbox.checked &&
                                !contributorBiocheckbox.checked &&
                                !searchNamecheckbox.checked &&
                                !searchTagscheckbox.checked
                            ) {
                                $("#searchByContentSamples").removeAttr(
                                    "disabled"
                                );
                                $("#searchByContributorBio").removeAttr(
                                    "disabled"
                                );
                                $("#searchByName").removeAttr("disabled");
                                $("#searchByTag").removeAttr("disabled");
                            } else {
                                $("#searchByTag").attr("disabled", "disabled");
                            }
                        }
                    });

                    var talentPoolAccess = document.getElementById(
                        "talentPoolAccess"
                    );

                    var enableRecruitingTools = document.getElementById(
                        "enableRecruitingTools"
                    );

                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);

                    if (urlParams.get("contentSampleTypeId") !== null) {
                        $("#sortBy").removeAttr("disabled", "disabled");
                    }
                    const sortBy = $("#sortBy")
                        .selectBoxIt()
                        .data("selectBoxIt");

                    if (urlParams.get("sortByName") !== null) {
                        sortBy.selectOption("nameSort");
                        $("#sortByName").removeAttr("disabled");
                        $("#sortByProfileLastUpdated").attr(
                            "disabled",
                            "disabled"
                        );
                    } else if (
                        urlParams.get("sortByProfileLastUpdated") !== null
                    ) {
                        sortBy.selectOption("dateSort");
                        $("#sortByName").attr("disabled", "disabled");
                        $("#sortByProfileLastUpdated").removeAttr("disabled");
                    } else {
                        $("#sortByName").attr("disabled", "disabled");
                        $("#sortByProfileLastUpdated").attr(
                            "disabled",
                            "disabled"
                        );
                    }

                    const radiusMiles = $("#radius")
                        .selectBoxIt()
                        .data("selectBoxIt");

                    if (
                        urlParams.get("facetLocation") !== null ||
                        urlParams.get("facetLocation") !== ""
                    ) {
                        radiusMiles.selectOption(urlParams.get("radius"));
                        $("#locationName").val(urlParams.get("locationName"));
                        $("#facetLocation").val(urlParams.get("facetLocation"));
                    }

                    if (talentPoolAccess.value === "true") {
                        let notReadyCheckbox = document.getElementById(
                            "notReady"
                        );
                        let unvettedCheckbox = document.getElementById(
                            "unvetted"
                        );
                        let qualifiedCheckbox = document.getElementById(
                            "qualified"
                        );
                        let unqualifiedCheckbox = document.getElementById(
                            "unqualified"
                        );
                        let vettingStatus = document.getElementById(
                            "vettingStatus"
                        );
                        let vettingArr = [];

                        let notReadyChecked = window.sessionStorage.getItem(
                            "notReady"
                        );
                        let unvettedChecked = window.sessionStorage.getItem(
                            "unvetted"
                        );
                        let qualifiedChecked = window.sessionStorage.getItem(
                            "qualified"
                        );
                        let unqualifiedChecked = window.sessionStorage.getItem(
                            "unqualified"
                        );

                        if (
                            urlParams.get("vettingState") !== null &&
                            notReadyChecked === null
                        ) {
                            const vettingStates = urlParams.get("vettingState");
                            notReadyCheckbox.checked = vettingStates.includes(
                                "1"
                            )
                                ? true
                                : false;
                            unvettedCheckbox.checked = vettingStates.includes(
                                "2"
                            )
                                ? true
                                : false;
                            qualifiedCheckbox.checked = vettingStates.includes(
                                "4"
                            )
                                ? true
                                : false;
                            unqualifiedCheckbox.checked = vettingStates.includes(
                                "5"
                            )
                                ? true
                                : false;
                        }

                        if (notReadyChecked !== null) {
                            notReadyCheckbox.checked =
                                notReadyChecked === "false" ? false : true;
                        }

                        if (unvettedChecked !== null) {
                            unvettedCheckbox.checked =
                                unvettedChecked === "false" ? false : true;
                        }

                        if (qualifiedChecked !== null) {
                            qualifiedCheckbox.checked =
                                qualifiedChecked === "false" ? false : true;
                        }

                        if (unqualifiedChecked !== null) {
                            unqualifiedCheckbox.checked =
                                unqualifiedChecked === "false" ? false : true;
                        }

                        if (notReadyCheckbox.checked) {
                            vettingArr.push(parseInt(notReadyCheckbox.value));
                        }

                        if (unvettedCheckbox.checked) {
                            vettingArr.push(parseInt(unvettedCheckbox.value));
                        }

                        if (qualifiedCheckbox.checked) {
                            vettingArr.push(parseInt(qualifiedCheckbox.value));
                        }

                        if (unqualifiedCheckbox.checked) {
                            vettingArr.push(
                                parseInt(unqualifiedCheckbox.value)
                            );
                        }

                        vettingStatus.value = vettingArr.toString();

                        notReadyCheckbox.addEventListener("change", () => {
                            if (notReadyCheckbox.checked) {
                                if (
                                    !vettingArr.includes(
                                        parseInt(notReadyCheckbox.value)
                                    )
                                ) {
                                    vettingArr.push(
                                        parseInt(notReadyCheckbox.value)
                                    );
                                }
                            } else {
                                vettingArr = vettingArr.filter(
                                    (elem) =>
                                        elem !==
                                        parseInt(notReadyCheckbox.value)
                                );
                            }
                            vettingStatus.value = vettingArr.toString();
                        });

                        unvettedCheckbox.addEventListener("change", () => {
                            if (unvettedCheckbox.checked) {
                                if (
                                    !vettingArr.includes(
                                        parseInt(unvettedCheckbox.value)
                                    )
                                ) {
                                    vettingArr.push(
                                        parseInt(unvettedCheckbox.value)
                                    );
                                }
                            } else {
                                vettingArr = vettingArr.filter(
                                    (elem) =>
                                        elem !==
                                        parseInt(unvettedCheckbox.value)
                                );
                            }
                            vettingStatus.value = vettingArr.toString();
                        });

                        qualifiedCheckbox.addEventListener("change", () => {
                            if (qualifiedCheckbox.checked) {
                                if (
                                    !vettingArr.includes(
                                        parseInt(qualifiedCheckbox.value)
                                    )
                                ) {
                                    vettingArr.push(
                                        parseInt(qualifiedCheckbox.value)
                                    );
                                }
                            } else {
                                vettingArr = vettingArr.filter(
                                    (elem) =>
                                        elem !==
                                        parseInt(qualifiedCheckbox.value)
                                );
                            }
                            vettingStatus.value = vettingArr.toString();
                        });

                        unqualifiedCheckbox.addEventListener("change", () => {
                            if (unqualifiedCheckbox.checked) {
                                if (
                                    !vettingArr.includes(
                                        parseInt(unqualifiedCheckbox.value)
                                    )
                                ) {
                                    vettingArr.push(
                                        parseInt(unqualifiedCheckbox.value)
                                    );
                                }
                            } else {
                                vettingArr = vettingArr.filter(
                                    (elem) =>
                                        elem !==
                                        parseInt(unqualifiedCheckbox.value)
                                );
                            }
                            vettingStatus.value = vettingArr.toString();
                        });
                    }

                    //Back to Top button
                    var backToTop = document.getElementById("backToTop");

                    if (backToTop != null) {
                        $(window).scroll(function() {
                            if ($(window).scrollTop() > 200) {
                                if (enableRecruitingTools.value === "true") {
                                    $("#floatingActionWrapper").addClass(
                                        "show"
                                    );
                                } else {
                                    $("#addToListFloating").addClass("show");
                                }
                                backToTop.classList.add("show");
                            } else {
                                if (enableRecruitingTools.value === "true") {
                                    $("#floatingActionWrapper").removeClass(
                                        "show"
                                    );
                                } else {
                                    $("#addToListFloating").removeClass("show");
                                }
                                backToTop.classList.remove("show");
                            }
                        });

                        backToTop.addEventListener("click", function() {
                            $("html, body").animate({ scrollTop: 0 }, "300");
                        });
                    }

                    //Floating Action button on click
                    if (document.getElementById("floatingAction") != null) {
                        $(".floatingAction").on("click", function(e) {
                            e.preventDefault();
                            $(this).toggleClass("open");
                            if (
                                $(this)
                                    .children(".icon-default")
                                    .hasClass("icon-Plus")
                            ) {
                                $(this)
                                    .children(".icon-default")
                                    .removeClass("icon-Plus");
                                $(this)
                                    .children(".icon-default")
                                    .addClass("icon-Close");
                            } else if (
                                $(this)
                                    .children(".icon-default")
                                    .hasClass("icon-Close")
                            ) {
                                $(this)
                                    .children(".icon-default")
                                    .removeClass("icon-Close");
                                $(this)
                                    .children(".icon-default")
                                    .addClass("icon-Plus");
                            }
                            $(".floatingActionMenu")
                                .stop()
                                .slideToggle();
                        });
                        $(this).on("click", function(e) {
                            var container = $(".floatingAction");
                            // if the target of the click isn't the container nor a descendant of the container
                            if (
                                !container.is(e.target) &&
                                $(".floatingActionWrapper").has(e.target)
                                    .length === 0
                            ) {
                                if (container.hasClass("open")) {
                                    container.removeClass("open");
                                }
                                if (
                                    container
                                        .children(".icon-default")
                                        .hasClass("icon-Close")
                                ) {
                                    container
                                        .children(".icon-default")
                                        .removeClass("icon-Close");
                                    container
                                        .children(".icon-default")
                                        .addClass("icon-Plus");
                                }
                                $(".floatingActionMenu").hide();
                            }

                            // if the target of the click isn't the container and a descendant of the menu
                            if (
                                !container.is(e.target) &&
                                $(".floatingActionMenu").has(e.target).length >
                                    0
                            ) {
                                $(".floatingAction").removeClass("open");
                                $(".floatingActionMenu")
                                    .stop()
                                    .slideToggle();
                            }
                            if (
                                e.target.id ===
                                    "addToListTalentAccessFloating" ||
                                e.target.id === "applyTagsFloating"
                            ) {
                                if (container.hasClass("open")) {
                                    container.removeClass("open");
                                }
                                if (
                                    container
                                        .children(".icon-default")
                                        .hasClass("icon-Close")
                                ) {
                                    container
                                        .children(".icon-default")
                                        .removeClass("icon-Close");
                                    container
                                        .children(".icon-default")
                                        .addClass("icon-Plus");
                                }
                                $(".floatingActionMenu").hide();
                                document
                                    .getElementById("floatingAction")
                                    .blur();
                            }
                        });
                    }

                    $("#locationName").keyup(function(e) {
                        document.getElementById("facetLocation").value = "";

                        if (e.keyCode == 13 || e.keyCode == 9) {
                            e.preventDefault();
                            window.stop();
                            document.execCommand("Stop");
                            validLocation = false;
                            return false;
                        }
                        var options = { types: ["(cities)"] };
                        // ,componentRestrictions: {}

                        let element = document.getElementById("locationName");

                        let places = new google.maps.places.Autocomplete(
                            element,
                            options
                        );

                        google.maps.event.addListener(
                            places,
                            "place_changed",
                            function() {
                                var geoComponents = places.getPlace();
                                var latitude = geoComponents.geometry.location.lat();
                                var longitude = geoComponents.geometry.location.lng();
                                var addressComponents =
                                    geoComponents.address_components;
                                addressComponents = addressComponents
                                    .filter(function(component) {
                                        switch (component.types[0]) {
                                            case "locality": // city
                                                return true;
                                            case "administrative_area_level_1": // state
                                                return true;
                                            case "country": // country
                                                return true;
                                            default:
                                                return false;
                                        }
                                    })
                                    .map(function(obj) {
                                        return obj.short_name;
                                    });

                                document.getElementById(
                                    "facetLocation"
                                ).value = `${latitude}, ${longitude}`;
                            }
                        );
                    });

                    const resultCount = document.getElementById("resultCount");
                    if (resultCount) {
                        if (parseInt(resultCount.innerText) === 0) {
                            $(".filterBar").addClass("empty");
                        } else {
                            $(".filterBar").removeClass("empty");
                        }
                    }

                    $("#sortBy")
                        .selectBoxIt()
                        .change(function() {
                            let value = this.value;
                            let currentUrl = queryString;
                            if (currentUrl.includes("sortByName")) {
                                currentUrl = currentUrl.replace(
                                    "&sortByName=true",
                                    ""
                                );
                            }
                            if (
                                currentUrl.includes("sortByProfileLastUpdated")
                            ) {
                                currentUrl = currentUrl.replace(
                                    "&sortByProfileLastUpdated=true",
                                    ""
                                );
                            }
                            if (urlParams.get("contentSampleTypeId") !== null) {
                                $(".filterBar, .filterContent").hide();
                                $(".sampleLoader").show();
                            }
                            switch (value) {
                                case "relevance":
                                    if (
                                        urlParams.get("contentSampleTypeId") !==
                                        null
                                    ) {
                                        window.location.href = currentUrl;
                                    } else {
                                        $("#sortByName").attr(
                                            "disabled",
                                            "disabled"
                                        );
                                        $("#sortByProfileLastUpdated").attr(
                                            "disabled",
                                            "disabled"
                                        );
                                    }
                                    break;
                                case "nameSort":
                                    if (
                                        urlParams.get("contentSampleTypeId") !==
                                        null
                                    ) {
                                        currentUrl += "&sortByName=true";
                                        window.location.href = currentUrl;
                                    } else {
                                        $("#sortByName").removeAttr("disabled");
                                        $("#sortByProfileLastUpdated").attr(
                                            "disabled",
                                            "disabled"
                                        );
                                    }
                                    break;
                                case "dateSort":
                                    if (
                                        urlParams.get("contentSampleTypeId") !==
                                        null
                                    ) {
                                        currentUrl +=
                                            "&sortByProfileLastUpdated=true";
                                        window.location.href = currentUrl;
                                    } else {
                                        $("#sortByName").attr(
                                            "disabled",
                                            "disabled"
                                        );
                                        $(
                                            "#sortByProfileLastUpdated"
                                        ).removeAttr("disabled");
                                    }
                                    break;
                                default:
                                    if (
                                        urlParams.get("contentSampleTypeId") !==
                                        null
                                    ) {
                                        window.location.href = currentUrl;
                                    } else {
                                        $("#sortByName").attr(
                                            "disabled",
                                            "disabled"
                                        );
                                        $("#sortByProfileLastUpdated").attr(
                                            "disabled",
                                            "disabled"
                                        );
                                    }
                                    break;
                                case "relevance":
                                    break;
                            }
                        });

                    var currentPage = parseInt($("#currentPage").text()),
                        itemsMax = parseInt($("#accountSize").text());

                    //Show More Button Functions
                    var pagination = Math.min(15, itemsMax);
                    if (!isNaN(itemsMax) && itemsMax > 0) {
                        var isWrapperEmpty = !$.trim(
                            $("#searchCardWrapper").html()
                        );
                        if (isWrapperEmpty) {
                            const windowSize = window.innerWidth;
                            if (windowSize >= 4000) {
                                pagination = Math.min(44, itemsMax);
                            } else if (
                                windowSize >= 3884 &&
                                windowSize <= 3999
                            ) {
                                pagination = Math.min(36, itemsMax);
                            } else if (
                                windowSize >= 2970 &&
                                windowSize <= 3883
                            ) {
                                pagination = Math.min(32, itemsMax);
                            } else if (
                                windowSize >= 2684 &&
                                windowSize <= 2969
                            ) {
                                pagination = Math.min(30, itemsMax);
                            } else if (
                                windowSize >= 2284 &&
                                windowSize <= 2683
                            ) {
                                pagination = Math.min(25, itemsMax);
                            } else if (
                                windowSize >= 1637 &&
                                windowSize <= 2283
                            ) {
                                pagination = Math.min(20, itemsMax);
                            } else if (
                                windowSize >= 1195 &&
                                windowSize <= 1636
                            ) {
                                pagination = Math.min(15, itemsMax);
                            } else {
                                pagination = Math.min(10, itemsMax);
                            }
                            showPreloadedList(currentPage, pagination);
                        }
                    }
                    let viewingProfileAtPage =
                        window.sessionStorage.getItem("contribSearchPgNo") !==
                        null
                            ? parseInt(
                                  window.sessionStorage.getItem(
                                      "contribSearchPgNo"
                                  )
                              )
                            : 1;
                    sessionStorage.removeItem("contribSearchPgNo");

                    var viewingProfile = window.sessionStorage.getItem(
                        "scrollPos"
                    );
                    //#region Check if on viewing profile if viewing element is in different page than 1
                    if (viewingProfile != null && viewingProfileAtPage !== 1) {
                        $("#currentPage").text(viewingProfileAtPage);
                        showPreloadedList(viewingProfileAtPage, pagination);
                    } else if (
                        viewingProfile != null &&
                        viewingProfileAtPage === 1
                    ) {
                        if (parseInt(viewingProfile) > 300)
                            $("html, body").animate(
                                { scrollTop: viewingProfile },
                                3500
                            );
                        sessionStorage.removeItem("scrollPos");
                    } else if (
                        viewingProfile === null &&
                        viewingProfileAtPage !== 1
                    ) {
                        $("#currentPage").text(viewingProfileAtPage);
                        showPreloadedList(viewingProfileAtPage, pagination);
                    }

                    //#endregion

                    //#region If pagination size is less than 10 hide show more button
                    $("#showMoreContainer").hide();
                    $("#showMore").hide();

                    if (pagination < 10) {
                        $("#showMoreContainer").hide();
                        $("#showMore").hide();
                    }

                    //#endregion

                    function showNextItems(data) {
                        var viewingProfile = window.sessionStorage.getItem(
                            "scrollPos"
                        );
                        if (viewingProfile != null) {
                            currentPage = viewingProfileAtPage;
                        }

                        let updatedCurrentPage = parseInt(
                            $("#currentPage").text()
                        );
                        if (currentPage !== updatedCurrentPage) {
                            currentPage = updatedCurrentPage;
                        }

                        if (currentPage !== 1) {
                            $("#showMoreContainer").hide();
                            $("#showMore").hide();
                            $("#showMoreLoader").css("display", "flex");
                        }

                        var totalWithPage =
                            pagination * currentPage <= itemsMax
                                ? pagination * currentPage
                                : itemsMax;

                        $("#searchCardWrapper").append(data);

                        $("#showMoreContainer").show();
                        $("#showMore").show();
                        $("#showMoreLoader").css("display", "none");

                        $(".tooltip").bt({
                            padding: 10,
                            width: 100,
                            shrinkToFit: true,
                            spikeLength: 10,
                            spikeGirth: 10,
                            cornerRadius: 5,
                            fill: "rgba(255, 255, 255, 1.0)",
                            strokeWidth: 1,
                            strokeStyle: "#dfdfdf",
                            offsetParent: "body",
                            cssStyles: {
                                color: "#333333",
                                fontWeight: "normal"
                            },
                            shadow: true,
                            shadowOffsetX: 2,
                            shadowOffsetY: 2,
                            shadowBlur: 6,
                            shadowColor: "rgba(0,0,0,.6)",
                            shadowOverlap: false,
                            noShadowOpts: {
                                strokeStyle: "#999",
                                strokeWidth: 1
                            },
                            positions: ["top", "right"]
                        });

                        if (totalWithPage >= itemsMax) {
                            $("#showMoreContainer").hide();
                            $("#showMore").hide();
                        } else {
                            $("#showMoreContainer").show();
                            $("#showMore").show();
                        }

                        //scroll to the profile card that was viewed earlier
                        if (viewingProfile != null) {
                            $("html, body").animate(
                                { scrollTop: viewingProfile },
                                3500
                            );
                            sessionStorage.removeItem("scrollPos");
                        }
                    }

                    $("#showMore").on("click", function(e) {
                        $("#showMoreContainer").hide();
                        $("#showMore").hide();
                        $("#showMoreLoader").css("display", "flex");
                        var accountIdsString = $("#accountIds").text();
                        var accountIds = accountIdsString.split(",");
                        let pgNo = parseInt($("#currentPage").text()) + 1;
                        let accountSize = parseInt($("#accountSize").text());
                        let startIndex = Math.min(
                            (pgNo - 1) * pagination,
                            accountSize
                        );
                        let endIndex = Math.min(pgNo * pagination, accountSize);
                        const accounts = accountIds.slice(startIndex, endIndex);

                        accountIdsString = accounts.join(",");
                        var urlAction = "contributorSearchShowNextItemAjax";
                        $.ajax({
                            type: "GET",
                            url: urlAction,
                            dataType: "html",
                            data: {
                                accountIdsString: accountIdsString,
                                contentSampleTypeId: urlParams.get(
                                    "contentSampleTypeId"
                                )
                            },
                            success: function(data) {
                                let currentPage =
                                    parseInt($("#currentPage").text()) + 1;
                                $("#currentPage").text(currentPage);
                                showNextItems(data);
                            }
                        });
                    });

                    function showPreloadedList(currentPage, pagination) {
                        $("#showMoreContainer").hide();
                        $("#showMore").hide();
                        $("#showMoreLoader").css("display", "flex");
                        let pgNo = currentPage;
                        let accountSize = parseInt($("#accountSize").text());
                        let startIndex = 0;
                        let endIndex = Math.min(pgNo * pagination, accountSize);
                        var accountIdsString = $("#accountIds").text();
                        var accountIds = accountIdsString.split(",");
                        const accounts = accountIds.slice(startIndex, endIndex);
                        accountIdsString = accounts.join(",");
                        var urlAction = "contributorSearchShowNextItemAjax";
                        $.ajax({
                            type: "GET",
                            url: urlAction,
                            dataType: "html",
                            data: {
                                accountIdsString: accountIdsString,
                                contentSampleTypeId: urlParams.get(
                                    "contentSampleTypeId"
                                )
                            },
                            success: function(data) {
                                showNextItems(data);
                            }
                        });
                    }
                });

                $(document).on(
                    "keydown",
                    "#contributorSearchForm input#textSearch",
                    function(ev) {
                        if (ev.which === 13) {
                            $("#contributorSearchButton").click();
                            return false;
                        }
                    }
                );
                $("body")
                    .on("click", ".searchCardBtns", function() {
                        {
                            var e = $(this);
                            var classNames = e[0].className;
                            if (!classNames.includes("emailBtn")) {
                                let dataset = e[0].dataset;
                                var scrollPos =
                                    window.scrollY ||
                                    window.scrollTop ||
                                    document.getElementsByTagName("html")[0]
                                        .scrollTop;
                                var id = dataset.accid;
                                var textSearch = dataset.textsearch;
                                var keywords = dataset.keywords;
                                var viewUrl = dataset.url;

                                viewUrl += id;
                                viewUrl +=
                                    textSearch !== null && textSearch !== ""
                                        ? keywords
                                        : "";

                                window.open(viewUrl, "_blank");
                                // window.location.href = viewUrl;
                            }
                        }
                    })
                    .on("click", ".showMore", function() {
                        $(this)
                            .hide()
                            .closest("ul")
                            .addClass("opened")
                            .find("li")
                            .addClass("displayLinks");
                        $(this)
                            .parent()
                            .find(".showLess")
                            .show();
                        return false;
                    })
                    .on("click", ".showLess", function() {
                        $(this)
                            .hide()
                            .closest("ul")
                            .removeClass("opened")
                            .find("li")
                            .removeClass("displayLinks");
                        $(this)
                            .parent()
                            .find(".showMore")
                            .show();
                        return false;
                    })
                    .on("click", ".loadKaltura", function() {
                        call.overlay(
                            "loadContentSampleVideoAjax.action?contentSampleId=" +
                                $(this).attr("id"),
                            null,
                            650,
                            null,
                            "GET",
                            function() {
                                mw.setConfig("Kaltura.LeadWithHTML5", true);
                                kWidget.embed({
                                    targetId: "video-player",
                                    wid: "_1737042",
                                    uiconf_id: 23937082,
                                    flashvars: {
                                        allowFullScreen: true,
                                        allowNetworking: "all",
                                        allowScriptAccess: "always",
                                        autoPlay: true
                                    },
                                    cache_st: 1406907495,
                                    entry_id: $("#kaltura-id").val()
                                });
                            }
                        );
                        return false;
                    })
                    .on("click", ".icon-Collapse, .icon-Expand", function(
                        event
                    ) {
                        // Variables
                        var el = event.currentTarget;
                        var $el = jQuery(el);
                        $el.toggleClass("icon-Expand").toggleClass(
                            "icon-Collapse"
                        );
                        $el.parent()
                            .toggleClass("")
                            .toggleClass("collapsed");
                        $el.parent()
                            .next("ul")
                            .slideToggle("slow", function() {});

                        if ($el.hasClass("icon-Collapse")) {
                            jQuery("html,body").animate(
                                {
                                    scrollTop: $el.offset().top - 60
                                },
                                1000
                            );
                        }
                    })
                    .on("click", ".addToListCheckbox", function() {
                        let checkboxes = document.getElementsByClassName(
                            "addToListCheckbox"
                        );
                        var floatingActionBtn = document.getElementById(
                            "floatingAction"
                        );
                        var addListBtn = document.getElementById("addToList");
                        var addListBtnFloating = document.getElementById(
                            "addToListFloating"
                        );
                        for (let i = 0; i < checkboxes.length; i++) {
                            if (!checkboxes[i].checked) {
                                addListBtn.setAttribute("disabled", "disabled");
                                addListBtn.classList.add("disabled");
                                if (enableRecruitingTools.value === "true") {
                                    floatingActionBtn.classList.add("disabled");
                                    document
                                        .getElementById("applyTagsBtn")
                                        .setAttribute("disabled", "disabled");
                                    document
                                        .getElementById("applyTagsBtn")
                                        .classList.add("disabled");
                                } else {
                                    addListBtnFloating.setAttribute(
                                        "disabled",
                                        "disabled"
                                    );
                                    addListBtnFloating.classList.add(
                                        "disabled"
                                    );
                                }
                            } else {
                                addListBtn.removeAttribute("disabled");
                                addListBtn.classList.remove("disabled");
                                if (enableRecruitingTools.value === "true") {
                                    floatingActionBtn.classList.remove(
                                        "disabled"
                                    );
                                    document
                                        .getElementById("applyTagsBtn")
                                        .removeAttribute("disabled");
                                    document
                                        .getElementById("applyTagsBtn")
                                        .classList.remove("disabled");
                                } else {
                                    addListBtnFloating.removeAttribute(
                                        "disabled"
                                    );
                                    addListBtnFloating.classList.remove(
                                        "disabled"
                                    );
                                }
                                break;
                            }
                        }
                    })
                    .on("click", "#addToListFloating", function() {
                        $("#addToList").click();
                    })
                    .on("click", "#addToListTalentAccessFloating", function() {
                        alert("add to list clicked");
                        $("#addToList").click();
                        $(".floatingActionMenu").css("display", "none");
                    })
                    .on("click", "#applyTagsBtn", function() {
                        $("#applyTags").click();
                    })
                    .on("click", "#addToList", function() {
                        let addedContributors = document.getElementsByClassName(
                            "addToListCheckbox"
                        );
                        var programId = $("#attrs-id").data("programid");
                        const agencyId = $("body").attr("data-enterpriseid");
                        const token = skyword.getToken("access_token");
                        const getContribListURL = skyword.getAPIURL(
                            "contributors/agency/" +
                                agencyId +
                                "/program/" +
                                programId +
                                "/search"
                        );
                        jQuery.ajax({
                            cache: false,
                            type: "GET",
                            headers: {
                                Accept: "application/json;",
                                "Content-Type": "application/json;"
                            },
                            url: getContribListURL,
                            beforeSend: function(xhr) {
                                xhr.setRequestHeader(
                                    "Authorization",
                                    `Bearer ${token}`
                                );
                            },
                            success: function(data) {
                                let html = `<div class="add-to-list-pane">
                                            <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start;">
                                                <div style="display: flex; flex-direction:column;">
                                                    <h1>${$translate.instant(
                                                        "sw.contributorsearch.addcontributors"
                                                    )}</h1>
                                                    <h3>${$translate.instant(
                                                        "sw.contributorsearch.selectoneormorelist"
                                                    )}</h3>
                                                </div>
                                                <div style="display:flex; flex-direction: row; gap:0px 5px;">
                                                    <button class="add-to-list-btns" id="createNewList"><span class="icon-plus" style="color: white;"></span>&nbsp;${$translate.instant(
                                                        "sw.contributorsearch.createnewlist"
                                                    )}</button>
                                                </div>
                                            </div>`;
                                if (data.length > 0) {
                                    html += `<div style="display: flex; gap:10px; flex-direction: row;">
                                                <div style="display:flex; flex-direction:column; flex:1; text-align: left;">
                                                    <label style="left: 18px;
                                                    position: relative;
                                                    font-size: 16px;
                                                    margin: 5px 0 15px 0;">${$translate.instant(
                                                        "sw.contributorsearch.lists"
                                                    )}</label>
                                                    <ul>`;
                                    for (let i = 0; i < data.length; i++) {
                                        html += `       <li style="border: 1px solid #878787; padding: 10px; width: 90%; margin-bottom: 10px;">
                                                            <label class="checkboxContainer" style="margin: 5px 0;">${
                                                                data[i].name
                                                            }
                                                                <input type="checkbox" class="contributorListCheckbox" value="${
                                                                    data[i].id
                                                                }" />
                                                                <span class="contribSearchCheckmark"></span>
                                                            </label>
                                                            <p style="margin: 0; padding-bottom: 0;">${
                                                                data[i]
                                                                    .description
                                                            }</p>
                                                        </li>`;
                                    }
                                    html += `       </ul>
                                                    <div style="margin-top:10px;">
                                                        <button class="add-to-list-btns disabled" id="addContributorsToList" disabled="disabled">${$translate.instant(
                                                            "sw.contributorsearch.addtolist"
                                                        )}</button>
                                                    </div>
                                                </div>
                                                <div class="create-list-contributors" style="text-align:left; width:40%;">
                                                    <label class="contributor-label">${$translate.instant(
                                                        "sw.contributorsearch.selectedcontributors"
                                                    )}</label>
                                                    <ul class="selected-contributor-container" style="margin-top: 10px;">`;
                                    for (
                                        let i = 0;
                                        i < addedContributors.length;
                                        i++
                                    ) {
                                        if (addedContributors[i].checked) {
                                            let firstName =
                                                addedContributors[i].dataset[
                                                    "firstname"
                                                ];
                                            let lastName =
                                                addedContributors[i].dataset[
                                                    "lastname"
                                                ];
                                            let profile =
                                                addedContributors[i].dataset[
                                                    "profile"
                                                ] !== null
                                                    ? addedContributors[i]
                                                          .dataset["profile"]
                                                    : "/styles/images/BlankProfile.png";
                                            let id = addedContributors[i].value;
                                            html += `   <li>
                                                            <span style="display:flex;">
                                                                <img width="90" class="image-crop searchCardImg" style="width:80px; height:80px;" src=${profile} onError="this.src='/styles/images/BlankProfile.png'"/>
                                                                <h2 class="searchCardName">${firstName} ${lastName}</h2>
                                                            </span>
                                                            <label class="checkboxContainer" style="margin-top:5px;">
                                                                <input type="checkbox" checked="checked" class="selectedContributors" data-profile="${profile}" data-firstName="${firstName}" data-lastName="${lastName}" value="${id}" />
                                                                <span class="contribSearchCheckmark"></span>
                                                            </label>

                                                        </li>`;
                                        }
                                    }
                                    html += `       </ul>
                                                </div>
                                            </div>
                                     </div>`;
                                    var opts = { html: html };
                                    $.colorbox(opts);
                                } else {
                                    html += `<div style="display: flex; gap:10px; flex-direction: row;">
                                                <div style="display:flex; flex-direction:column; flex:1; text-align: left;">
                                                <label style="left: 18px;
                                                position: relative;
                                                font-size: 16px;
                                                margin: 5px 0 15px 0;">${$translate.instant(
                                                    "sw.contributorsearch.lists"
                                                )}</label>
                                                <ul>`;
                                    html += `       <li style="width: 90%; margin-bottom: 10px;">
                                                        <p style="margin: 5px 0; font-size: 1.6rem;">${$translate.instant(
                                                            "nucleus.panel.nodata"
                                                        )}
                                                        </p>
                                                    </li>`;
                                    html += `
                                                </ul>
                                                </div>
                                                <div class="create-list-contributors" style="text-align:left; width:40%;">
                                                <label class="contributor-label">${$translate.instant(
                                                    "sw.contributorsearch.selectedcontributors"
                                                )}</label>
                                                <ul class="selected-contributor-container" style="margin-top: 10px;">`;
                                    for (
                                        let i = 0;
                                        i < addedContributors.length;
                                        i++
                                    ) {
                                        if (addedContributors[i].checked) {
                                            let firstName =
                                                addedContributors[i].dataset[
                                                    "firstname"
                                                ];
                                            let lastName =
                                                addedContributors[i].dataset[
                                                    "lastname"
                                                ];
                                            let profile =
                                                addedContributors[i].dataset[
                                                    "profile"
                                                ] !== null
                                                    ? addedContributors[i]
                                                          .dataset["profile"]
                                                    : "/styles/images/BlankProfile.png";
                                            let id = addedContributors[i].value;
                                            html += `<li>
                                 <span style="display:flex;">
                                    <img width="90" class="image-crop searchCardImg" style="width:80px; height:80px;" src=${profile} onError="this.src='/styles/images/BlankProfile.png'"/>
                                    <h2 class="searchCardName">${firstName} ${lastName}</h2>
                                </span>
                                <label class="checkboxContainer" style="margin-top:5px;">
                                    <input type="checkbox" checked="checked" class="selectedContributors" data-profile="${profile}" data-firstName="${firstName}" data-lastName="${lastName}" value="${id}" />
                                    <span class="contribSearchCheckmark"></span>
                                </label>

                                </li>`;
                                        }
                                    }
                                    html += `</ul>
                                            </div></div>
                                     </div>`;
                                    var opts = { html: html };
                                    $.colorbox(opts);
                                }
                            },
                            error: function(error) {
                                console.log(error);
                            }
                        });
                    })
                    .on("click", ".contributorListCheckbox", function() {
                        var checkboxes = document.getElementsByClassName(
                            "contributorListCheckbox"
                        );
                        var selectedCheckbox = document.getElementsByClassName(
                            "selectedContributors"
                        );

                        var createListBtn = document.getElementById(
                            "createNewList"
                        );
                        var addToListBtn = document.getElementById(
                            "addContributorsToList"
                        );
                        var contribChecked = 0,
                            selectedContributors = 0;
                        for (let i = 0; i < checkboxes.length; i++) {
                            if (checkboxes[i].checked) {
                                contribChecked = 1;
                                break;
                            }
                        }

                        for (let i = 0; i < selectedCheckbox.length; i++) {
                            if (selectedCheckbox[i].checked) {
                                selectedContributors = 1;
                                break;
                            }
                        }
                        if (selectedContributors === 0) {
                            createListBtn.setAttribute("disabled", "disabled");
                            createListBtn.classList.add("disabled");
                            if (addToListBtn) {
                                addToListBtn.setAttribute(
                                    "disabled",
                                    "disabled"
                                );
                                addToListBtn.classList.add("disabled");
                            }
                        } else {
                            if (contribChecked === 0) {
                                createListBtn.removeAttribute("disabled");
                                createListBtn.classList.remove("disabled");
                                if (addToListBtn) {
                                    addToListBtn.setAttribute(
                                        "disabled",
                                        "disabled"
                                    );
                                    addToListBtn.classList.add("disabled");
                                }
                            } else {
                                createListBtn.setAttribute(
                                    "disabled",
                                    "disabled"
                                );
                                createListBtn.classList.add("disabled");
                                if (addToListBtn) {
                                    addToListBtn.removeAttribute("disabled");
                                    addToListBtn.classList.remove("disabled");
                                }
                            }
                        }
                    })
                    .on("click", ".selectedCreateContributors", function() {
                        var checkboxes = document.getElementsByClassName(
                            "selectedCreateContributors"
                        );
                        var checked = 0;
                        for (let i = 0; i < checkboxes.length; i++) {
                            if (checkboxes[i].checked) {
                                checked = 1;
                                break;
                            }
                        }

                        if (checked === 0) {
                            document
                                .getElementById("createList")
                                .setAttribute("disabled", "disabled");
                            document
                                .getElementById("createList")
                                .classList.add("disabled");
                        } else {
                            document
                                .getElementById("createList")
                                .removeAttribute("disabled");
                            document
                                .getElementById("createList")
                                .classList.remove("disabled");
                        }
                    })
                    .on("click", ".selectedContributors", function() {
                        var checkboxes = document.getElementsByClassName(
                            "selectedContributors"
                        );
                        var checked = 0;
                        var createListBtn = document.getElementById(
                            "createNewList"
                        );
                        var addToListBtn = document.getElementById(
                            "addContributorsToList"
                        );
                        for (let i = 0; i < checkboxes.length; i++) {
                            if (checkboxes[i].checked) {
                                checked = 1;
                                break;
                            }
                        }
                        if (checked === 0) {
                            createListBtn.setAttribute("disabled", "disabled");
                            createListBtn.classList.add("disabled");
                            if (addToListBtn) {
                                addToListBtn.setAttribute(
                                    "disabled",
                                    "disabled"
                                );
                                addToListBtn.classList.add("disabled");
                            }
                        } else {
                            var contribCheckbox = document.getElementsByClassName(
                                "contributorListCheckbox"
                            );
                            var contribChecked = 0;
                            for (let i = 0; i < contribCheckbox.length; i++) {
                                if (contribCheckbox[i].checked) {
                                    contribChecked = 1;
                                    break;
                                }
                            }
                            if (contribChecked === 1) {
                                createListBtn.setAttribute(
                                    "disabled",
                                    "disabled"
                                );
                                createListBtn.classList.add("disabled");
                                if (addToListBtn) {
                                    addToListBtn.removeAttribute("disabled");
                                    addToListBtn.classList.remove("disabled");
                                }
                            } else {
                                createListBtn.removeAttribute("disabled");
                                createListBtn.classList.remove("disabled");
                                if (addToListBtn) {
                                    addToListBtn.setAttribute(
                                        "disabled",
                                        "disabled"
                                    );
                                    addToListBtn.classList.add("disabled");
                                }
                            }
                        }
                    })
                    .on("click", "#createNewList", function() {
                        var addedContributors = document.getElementsByClassName(
                            "selectedContributors"
                        );
                        var talentPoolAccess = document.getElementById(
                            "talentPoolAccess"
                        );
                        let html = `<div class="create-list-pane">
                                        <div style="display: flex; flex-direction: column;">
                                            <h1>${$translate.instant(
                                                "sw.contributorsearch.createlist"
                                            )}</h1>
                                            <h3>${$translate.instant(
                                                "sw.contributorsearch.requiredinfoadd"
                                            )}</h3>
                                        </div>
                                        <div class="create-list-wrapper">

                                            <form id="create-list-form" class="create-list-form">
                                            <div id="error-box-alreadyexists" className="hidden" style="background: #fee3e5;
                                            border: none;
                                            color: #BD1200;">
                                                <ul className="content">
                                                    <li id="createErrorMsg"></li>
                                                </ul>
                                            </div>
                                                <label>${$translate.instant(
                                                    "sw.label.title"
                                                )}&nbsp;<span class="required">*</span></label>
                                                <input type="text" name="listTitle" id="listTitle" style="font-size:16px; margin-bottom:10px;" />
                                                <span style="color: #f71515; font-size:14px; margin-bottom:5px; display:none;" id="validation-message-title">${$translate.instant(
                                                    "sw.contributorsearch.titlemessage"
                                                )}</span>
                                                <label>${$translate.instant(
                                                    "sw.label.description"
                                                )}&nbsp;<span class="required">*</span></label>
                                                <textarea name="listDescription" id="listDescription" style="resize:none; height:120px; font-size:16px; margin-bottom:10px;"></textarea>
                                                <span style="color: #f71515; font-size:14px; margin-bottom:5px; display:none;" id="validation-message-desc">${$translate.instant(
                                                    "sw.contributorsearch.descriptionmessage"
                                                )}</span>
                        <label>${$translate.instant(
                            "sw.label.accesslevel"
                        )}&nbsp;<span class="required">*</span></label>
                        <select id="accessLevel" name="accessLevel>
                        <option value="">${$translate.instant(
                            "sw.label.accesslevelselect"
                        )}</option>
                            <option value="0">${$translate.instant(
                                "angular.youTube.labelPrivate"
                            )}</option>
                            <option value="1">${$translate.instant(
                                "nucleus.nav.enterprise"
                            )}</option>
                            ${talentPoolAccess.value === "true" &&
                                `<option value="2">${$translate.instant(
                                    "angular.youTube.labelPublic"
                                )}</option>`}
                        </select>
                                                </form>
                                            <div class="create-list-contributors">
                                                <label class="contributor-label">${$translate.instant(
                                                    "sw.contributorsearch.selectedcontributors"
                                                )}</label>
                                                <ul class="selected-contributor-container">`;
                        for (let i = 0; i < addedContributors.length; i++) {
                            if (addedContributors[i].checked) {
                                let firstName =
                                    addedContributors[i].dataset["firstname"];
                                let lastName =
                                    addedContributors[i].dataset["lastname"];
                                let profile =
                                    addedContributors[i].dataset["profile"] !==
                                    null
                                        ? addedContributors[i].dataset[
                                              "profile"
                                          ]
                                        : "/styles/images/BlankProfile.png";
                                let id = addedContributors[i].value;
                                html += `<li>
                                 <span style="display:flex;">
                                    <img width="90" class="image-crop searchCardImg" style="width:80px; height:80px;" src=${profile} onError="this.src='/styles/images/BlankProfile.png'"/>
                                    <h2 class="searchCardName">${firstName} ${lastName}</h2>
                                </span>
                                <label class="checkboxContainer" style="margin-top:5px;">
                                    <input type="checkbox" checked="checked" class="selectedCreateContributors" data-profile="${profile}" data-firstName="${firstName}" data-lastName="${lastName}" value="${id}" />
                                    <span class="contribSearchCheckmark"></span>
                                </label>

                                </li>`;
                            }
                        }
                        html += `</ul>
                            </div>
                        </div>
                        <div style="display:flex; justify-content:end; position: relative; right: -3px; margin-top: 25px;">
                            <button class="create-list-btns" style="margin-right:0px !important;" id="createList"><span class="icon-plus" style="color: white;"></span>&nbsp;${$translate.instant(
                                "sw.contributorsearch.createnewlist"
                            )}</button>
                        </div>
                        </div>`;
                        var opts = { html: html };
                        $.colorbox(opts);
                        $("#accessLevel").selectBoxIt();
                    })
                    .on("click", "#createList", function() {
                        let listTitle = document.getElementById("listTitle");
                        let listTitleMsg = document.getElementById(
                            "validation-message-title"
                        );
                        let listDescription = document.getElementById(
                            "listDescription"
                        );
                        let listDescMsg = document.getElementById(
                            "validation-message-desc"
                        );

                        let accessLevel = document.getElementById(
                            "accessLevel"
                        );

                        listTitleMsg.style.display = "none";
                        listDescMsg.style.display = "none";

                        if (
                            listTitle.value === "" ||
                            listTitle.value === null ||
                            listDescription.value === "" ||
                            listDescription.value === null
                        ) {
                            if (
                                listTitle.value === "" ||
                                listTitle.value === null
                            ) {
                                listTitle.style.border = "1px solid #f71515";
                                listTitle.style.marginBottom = "0";
                                listTitleMsg.style.display = "block";
                            } else {
                                listTitle.style.border = "1px solid #cfcfcf";
                                listTitle.style.marginBottom = "10px";
                                listTitleMsg.style.display = "none";
                            }
                            if (
                                listDescription.value === "" ||
                                listDescription.value === null
                            ) {
                                listDescription.style.border =
                                    "1px solid #f71515";
                                listDescMsg.style.display = "block";
                            } else {
                                listDescription.style.border =
                                    "1px solid #cfcfcf";
                                listDescMsg.style.display = "none";
                            }
                            return false;
                        } else {
                            listTitle.style.border = "1px solid #cfcfcf";
                            listTitle.style.marginBottom = "10px";
                            listDescription.style.border = "1px solid #cfcfcf";
                            listTitleMsg.style.display = "none";
                            listDescMsg.style.display = "none";
                            let addedToListContributors = document.getElementsByClassName(
                                "addToListCheckbox"
                            );
                            let addedContributors = document.getElementsByClassName(
                                "selectedCreateContributors"
                            );
                            let contributorIDs = [];
                            for (let i = 0; i < addedContributors.length; i++) {
                                if (addedContributors[i].checked) {
                                    contributorIDs.push(
                                        parseInt(addedContributors[i].value)
                                    );
                                }
                            }

                            //AJAX Call save contributor list api
                            //on success call add to contributor list api with response id
                            // on success uncheck all checked contributors

                            let data = JSON.stringify({
                                name: listTitle.value,
                                description: listDescription.value,
                                accessLevel: accessLevel.value
                            });

                            const programId = parseInt(
                                document
                                    .getElementById("page-home")
                                    .getAttribute("data-programid")
                            );
                            let url = skyword.getAPIURL(
                                "contributors/program/" +
                                    programId +
                                    "/create-list"
                            );
                            const token = skyword.getToken("access_token");
                            // config.headers.Authorization = `Bearer ${token}`;

                            jQuery.ajax({
                                cache: false,
                                type: "POST",
                                data: data,
                                headers: {
                                    Accept: "application/json;",
                                    "Content-Type": "application/json;"
                                },
                                url: url,
                                beforeSend: function(xhr) {
                                    xhr.setRequestHeader(
                                        "Authorization",
                                        `Bearer ${token}`
                                    );
                                },
                                success: function(data) {
                                    if (data?.id !== undefined) {
                                        let addURL = skyword.getAPIURL(
                                            "contributors-account-list"
                                        );
                                        let addData = JSON.stringify({
                                            contributorListId: [data.id],
                                            accountId: contributorIDs
                                        });
                                        jQuery.ajax({
                                            cache: false,
                                            type: "POST",
                                            data: addData,
                                            headers: {
                                                Accept: "application/json;",
                                                "Content-Type":
                                                    "application/json;"
                                            },
                                            url: addURL,
                                            beforeSend: function(xhr) {
                                                xhr.setRequestHeader(
                                                    "Authorization",
                                                    `Bearer ${token}`
                                                );
                                            },
                                            success: function(data) {
                                                for (
                                                    let i = 0;
                                                    i <
                                                    addedContributors.length;
                                                    i++
                                                ) {
                                                    if (
                                                        addedContributors[i]
                                                            .checked
                                                    ) {
                                                        addedContributors[
                                                            i
                                                        ].checked = false;
                                                    }
                                                }
                                                for (
                                                    let i = 0;
                                                    i <
                                                    addedToListContributors.length;
                                                    i++
                                                ) {
                                                    if (
                                                        addedToListContributors[
                                                            i
                                                        ].checked
                                                    ) {
                                                        addedToListContributors[
                                                            i
                                                        ].checked = false;
                                                    }
                                                }
                                                if (
                                                    document.getElementById(
                                                        "enableRecruitingTools"
                                                    ).value === "true"
                                                ) {
                                                    document
                                                        .getElementById(
                                                            "applyTagsBtn"
                                                        )
                                                        .setAttribute(
                                                            "disabled",
                                                            "disabled"
                                                        );
                                                    document
                                                        .getElementById(
                                                            "applyTagsBtn"
                                                        )
                                                        .classList.add(
                                                            "disabled"
                                                        );
                                                    document
                                                        .getElementById(
                                                            "floatingAction"
                                                        )
                                                        .classList.add(
                                                            "disabled"
                                                        );
                                                } else {
                                                    document
                                                        .getElementById(
                                                            "addToListFloating"
                                                        )
                                                        .setAttribute(
                                                            "disabled",
                                                            "disabled"
                                                        );
                                                    document
                                                        .getElementById(
                                                            "addToListFloating"
                                                        )
                                                        .classList.add(
                                                            "disabled"
                                                        );
                                                }
                                                document
                                                    .getElementById("addToList")
                                                    .setAttribute(
                                                        "disabled",
                                                        "disabled"
                                                    );
                                                document
                                                    .getElementById("addToList")
                                                    .classList.add("disabled");
                                                document
                                                    .getElementById(
                                                        "create-list-form"
                                                    )
                                                    .reset();
                                                let html = `<ul class="content">
                                                    ${$translate.instant(
                                                        "sw.contributorsearch.createsuccessmessage"
                                                    )}
                                                </ul>`;
                                                $(
                                                    "#success-box-list"
                                                ).removeClass("hidden");
                                                $("#success-box-list").html(
                                                    html
                                                );
                                                $("#error-box").addClass(
                                                    "hidden"
                                                );
                                                $.colorbox.close();
                                            },
                                            error: function(
                                                jqXHR,
                                                status,
                                                errorThrown
                                            ) {
                                                // console.log(errorThrown);
                                                // $("#success-box-list").addClass(
                                                //     "hidden"
                                                // );
                                                // let html = `<ul class="content">
                                                //     ${$translate.instant(
                                                //         "sw.contributorsearch.createerrormessage"
                                                //     )}
                                                // </ul>`;
                                                // $("#error-box").removeClass(
                                                //     "hidden"
                                                // );
                                                // $("#error-box").html(html);
                                                // $.colorbox.close();
                                                $(
                                                    "#error-box-alreadyexists"
                                                ).removeClass("hidden");
                                                let response = JSON.parse(
                                                    jqXHR.responseText
                                                );
                                                $("#createErrorMsg").html(
                                                    response.description
                                                );
                                                // throw Error(
                                                //     "Fixture could not be loaded: " +
                                                //         addURL +
                                                //         " (status: " +
                                                //         status +
                                                //         ", message: " +
                                                //         errorThrown +
                                                //         ")"
                                                // );
                                            }
                                        });
                                    }
                                },
                                error: function(jqXHR, status, errorThrown) {
                                    console.log(errorThrown);
                                    $("#error-box-alreadyexists").removeClass(
                                        "hidden"
                                    );
                                    let response = JSON.parse(
                                        jqXHR.responseText
                                    );
                                    $("#createErrorMsg").html(
                                        response.description
                                    );
                                }
                            });
                        }
                    })
                    .on("click", "#addContributorsToList", function() {
                        let addedToListContributors = document.getElementsByClassName(
                            "addToListCheckbox"
                        );

                        let addedContributors = document.getElementsByClassName(
                            "selectedContributors"
                        );
                        let selectedLists = document.getElementsByClassName(
                            "contributorListCheckbox"
                        );
                        let contributorIDs = [],
                            listIDs = [];
                        for (let i = 0; i < addedContributors.length; i++) {
                            if (addedContributors[i].checked) {
                                contributorIDs.push(
                                    parseInt(addedContributors[i].value)
                                );
                            }
                        }

                        for (let i = 0; i < selectedLists.length; i++) {
                            if (selectedLists[i].checked) {
                                listIDs.push(parseInt(selectedLists[i].value));
                            }
                        }

                        //AJAX Call save contributor list api
                        //on success call add to contributor list api with response id
                        // on success uncheck all checked contributors
                        const token = skyword.getToken("access_token");
                        const programId = parseInt(
                            document
                                .getElementById("page-home")
                                .getAttribute("data-programid")
                        );
                        let addURL = skyword.getAPIURL(
                            "contributors-account-list"
                        );
                        let addData = JSON.stringify({
                            contributorListId: listIDs,
                            accountId: contributorIDs
                        });
                        jQuery.ajax({
                            cache: false,
                            type: "POST",
                            data: addData,
                            headers: {
                                Accept: "application/json;",
                                "Content-Type": "application/json;"
                            },
                            url: addURL,
                            beforeSend: function(xhr) {
                                xhr.setRequestHeader(
                                    "Authorization",
                                    `Bearer ${token}`
                                );
                            },
                            success: function(data) {
                                document
                                    .getElementById("addToList")
                                    .setAttribute("disabled", "disabled");
                                document
                                    .getElementById("addToList")
                                    .classList.add("disabled");
                                if (
                                    document.getElementById(
                                        "enableRecruitingTools"
                                    ).value === "true"
                                ) {
                                    document
                                        .getElementById("applyTagsBtn")
                                        .classList.add("disabled");
                                    document
                                        .getElementById("applyTagsBtn")
                                        .setAttribute("disabled", "disabled");
                                    document
                                        .getElementById("floatingAction")
                                        .classList.add("disabled");
                                } else {
                                    document
                                        .getElementById("addToListFloating")
                                        .setAttribute("disabled", "disabled");
                                    document
                                        .getElementById("addToListFloating")
                                        .classList.add("disabled");
                                }
                                for (
                                    let i = 0;
                                    i < addedToListContributors.length;
                                    i++
                                ) {
                                    if (addedToListContributors[i].checked) {
                                        addedToListContributors[
                                            i
                                        ].checked = false;
                                    }
                                }
                                for (
                                    let i = 0;
                                    i < addedContributors.length;
                                    i++
                                ) {
                                    if (addedContributors[i].checked) {
                                        addedContributors[i].checked = false;
                                    }
                                }
                                // document.getElementById("create-list-form").reset();
                                let html = `<ul class="content">
			                    ${$translate.instant(
                                    "sw.contributorsearch.updatesuccessmessage"
                                )}
                                </ul>`;
                                $("#success-box-list").removeClass("hidden");
                                $("#success-box-list").html(html);
                                $("#error-box").addClass("hidden");
                                $.colorbox.close();
                            },
                            error: function(jqXHR, status, errorThrown) {
                                console.log(errorThrown);
                                $("#success-box-list").addClass("hidden");
                                let html = `<ul class="content">
                                    ${$translate.instant(
                                        "sw.contributorsearch.updateerrormessage"
                                    )}
                                </ul>`;
                                $("#error-box").removeClass("hidden");
                                $("#error-box").html(html);
                                $.colorbox.close();
                                throw Error(
                                    "Fixture could not be loaded: " +
                                        addURL +
                                        " (status: " +
                                        status +
                                        ", message: " +
                                        errorThrown +
                                        ")"
                                );
                            }
                        });
                    });
            }
        };
    }
]);

(function() {
    // 0.0 - Initialize DAM Uploader | Controller
    app.controller("initCtrl", [
        "$rootScope",
        "$scope",
        "$translate",
        "AssetFactory",
        function($rootScope, $scope, $translate, AssetFactory) {
            $scope.globalData = [];
            $scope.CREATE = 1;
            $scope.DETAILS = 2;
            $scope.WORKFLOW = 3;
            $scope.UPLOAD = 4;
            $scope.DONE = 5;

            $scope.digitalAsset = {
                step: $scope.CREATE,
                filenamePrepend: ""
            };
            $scope.steps = [];

            $translate("angular.dam.bulkupld.navigation.type").then(function(
                val
            ) {
                $scope.steps.push({
                    id: 1,
                    text: $translate.instant(
                        "angular.dam.bulkupld.navigation.type"
                    )
                });
            });
            $translate("angular.dam.bulkupld.navigation.details").then(function(
                val
            ) {
                $scope.steps.push({
                    id: 2,
                    text: $translate.instant(
                        "angular.dam.bulkupld.navigation.details"
                    )
                });
            });
            $translate("angular.dam.bulkupld.navigation.workflow").then(
                function(val) {
                    $scope.steps.push({
                        id: 3,
                        text: $translate.instant(
                            "angular.dam.bulkupld.navigation.workflow"
                        )
                    });
                }
            );
            $translate("angular.dam.bulkupld.navigation.upload").then(function(
                val
            ) {
                $scope.steps.push({
                    id: 4,
                    text: $translate.instant(
                        "angular.dam.bulkupld.navigation.upload"
                    )
                });
            });
            $translate("angular.dam.bulkupld.navigation.completion").then(
                function(val) {
                    $scope.steps.push({
                        id: 5,
                        text: $translate.instant(
                            "angular.dam.bulkupld.navigation.completion"
                        )
                    });
                }
            );

            $scope.max = 5;
            $scope.selectedIndex = 0;
            $scope.activeStep = 1;

            // 0.2 - Handle Progress bar state
            $scope.goToStep = function(newStepId) {
                $scope.activeStep = newStepId;
                var index =
                    $scope.selectedIndex == $scope.max
                        ? 0
                        : $scope.activeStep - 1;
                $scope.selectedIndex = index;
            };

            $scope.subNavClick = function(stepIndex) {
                // $scope.goToStep(stepIndex);
            };

            $scope.reloadPage = function() {
                window.location.reload();
            };

            // 0.3 - Go Back Function
            $scope.goToPrevious = function() {
                if ($scope.activeStep <= 1) return;
                $scope.goToStep($scope.activeStep - 1);
            };

            // 0.4 - Progress forward Function
            $scope.goToNext = function() {
                if ($scope.activeStep >= $scope.max) return;
                $scope.goToStep($scope.activeStep + 1);
            };

            $scope.skipToWorkflow = function() {
                $scope.goToStep($scope.activeStep + 2);
            };

            // 0.5 - Update Progress
            $scope.addData = function(formData) {
                // $scope.globalData.splice(0, 0, formData);
                $scope.globalData.push(formData);
                // AssetFactory.setElements($scope.)
            };

            // 0.6 - Revert Progress
            $scope.removeData = function(stepIndex) {
                // console.log("stepIndex", stepIndex);
                $scope.globalData.splice(stepIndex);
            };

            $rootScope.$on("skipToWorkflow", function(event, data) {
                if (data.toString() === "18") {
                    $(".content-circles").find(".item:nth-child(2)").fadeOut(500);
                    $(".content-progress-bars").find(".progress-bar:nth-child(2)").fadeOut(500);
                    $(".step-labels").find(".item:nth-child(2)").fadeOut(500);
                } else {
                    $(".content-circles").find(".item:nth-child(2)").fadeIn(500);
                    $(".content-progress-bars").find(".progress-bar:nth-child(2)").fadeIn(500);
                    $(".step-labels").find(".item:nth-child(2)").fadeIn(500);
                }
            });

            $rootScope.$on("contentTypeUpdated", function(event, data) {
                // console.log("Received contentTypeUpdated, sending setup aws and Templates");
                if (data.toString() !== "18") {
                    $rootScope.$broadcast("awsAndMimeTypes", {});
                }
            });

            $rootScope.$on("contentDataUpdated", function() {
                // console.log("Received content element data, sending bulk asset details");
                $rootScope.$broadcast("setBulkUploadAssetDetails", {});
            });

            $rootScope.$on("stateIdSelected", function() {
                // console.log("Received content element data, sending bulk asset details");
                $rootScope.$broadcast("setupStateId", {});
            });
        }
    ]);

    // 1.0 - Asset Type | Controller
    app.controller("assetTypeCtrl", [
        "$rootScope",
        "$scope",
        "digitalAsset",
        "SkyFactory",
        "AssetFactory",
        function($rootScope, $scope, digitalAsset, SkyFactory, AssetFactory) {
            $scope.dam = {
                programId: $scope.programId,
                exportChannel: $scope.exportChannel,
                enableSalesforce: $scope.enableSalesforce,
                contentType: {},
                contentTypes: [],
                programCategories: [],
                programCategory: ""
            };

            var programId = $scope.programId;
            var unbindWatcher = $scope.$watch("programId", function() {
                loadContentTypes();
                unbindWatcher();
            });

            $scope.$watch("dam.contentType", function(newValue) {
                if (
                    false ===
                    SkyFactory.isUndefinedOrNull($scope.dam.contentType)
                ) {
                    if (
                        false ===
                        SkyFactory.isUndefinedOrNull($scope.dam.contentType.id)
                    ) {
                        AssetFactory.setContentTypeId(
                            $scope.dam.contentType.id
                        );
                        AssetFactory.setSearchResultTypeId(
                            $scope.dam.contentType.searchResultTypeId
                        );
                        if ($scope.dam.contentType.searchResultTypeId !== 18) {
                            loadContentTypeCats();
                        }
                        $scope.dam.contentType.contentType =
                            $scope.dam.contentType.name;
                        $rootScope.$emit("skipToWorkflow", $scope.dam.contentType.searchResultTypeId);
                        $rootScope.$emit("contentTypeUpdated", $scope.dam.contentType.searchResultTypeId);
                        $rootScope.$emit("setStateToApproved", $scope.dam.contentType.searchResultTypeId);
                    }
                } else {
                    $scope.dam.programCategories = [];
                }
            });

            $scope.$watch("dam.contentType.programCategory", function(
                newValue
            ) {
                if (newValue != null) {
                    var selectedCat = $.grep(
                        $scope.dam.programCategories,
                        function(n, i) {
                            return n.categoryId === newValue.categoryId;
                        }
                    );
                    if (selectedCat && selectedCat.length > 0) {
                        $scope.dam.contentType.programCategoryId =
                            selectedCat[0].categoryId;
                        $scope.dam.contentType.programCategory =
                            selectedCat[0].displayName;
                    }
                }
            });

            $scope.$watch("dam.contentType.programCategoryId", function(
                newValue
            ) {
                AssetFactory.setProgramCategoryId(newValue);
                var selectedCat = $.grep($scope.dam.programCategories, function(
                    n,
                    i
                ) {
                    return n.categoryId === newValue;
                });
                if (selectedCat && selectedCat.length > 0) {
                    $scope.dam.contentType.programCategory =
                        selectedCat[0].displayName;
                }
            });

            $scope.$watch("dam.contentTypes", function(newValue) {
                if (newValue.length > 0) {
                    setTimeout(function() {
                        $("#select-contentType").selectBoxIt();
                    }, 500);
                }
            });

            $scope.$watch("dam.programCategories", function(newValue) {
                var selectBox = $("#select-contentTypeCats").data(
                    "selectBox-selectBoxIt"
                );
                if (selectBox) {
                    selectBox.destroy();
                    $("#selectHolder-contentTypeCats").hide();
                }
                if (newValue.length > 0) {
                    $scope.dam.contentType.programCategory = null;

                    setTimeout(function() {
                        $("#select-contentTypeCats").selectBoxIt();
                        $("#selectHolder-contentTypeCats").show();
                    }, 500);
                }
            });

            function loadContentTypes() {
                var damPromise = digitalAsset.getContentTypes($scope.programId);
                damPromise.then(function(data) {
                    const contentTypes = data.data;
                    if (!$scope.exportChannel || !$scope.enableSalesforce) {
                        $scope.dam.contentTypes = contentTypes.filter((type) => type.searchResultTypeId !== 18);
                    } else {
                        $scope.dam.contentTypes = contentTypes;
                    }
                });
            }

            function sortJSON(arr, prop, asc) {
                arr = arr.sort(function(a, b) {
                    if (asc) return a[prop] > b[prop];
                    else return b[prop] > a[prop];
                });
                return arr;
            }

            function loadContentTypeCats() {
                var damPromise = digitalAsset.getContentTypeCategories(
                    $scope.programId
                );
                damPromise.then(function(data) {
                    var thisContentType = $.grep(data.data, function(n, i) {
                        return n.contentTypeId === $scope.dam.contentType.id;
                    });
                    $scope.dam.programCategories = sortJSON(
                        thisContentType[0].categoryList,
                        "displayName",
                        true
                    );
                });
            }
        }
    ]);

    // 2.0 - Asset Meta | Controller
    app.controller("assetMetaCtrl", [
        "$rootScope",
        "$scope",
        "digitalAsset",
        "SkyFactory",
        "AssetFactory",
        "$mdConstant",
        "$translate",
        "taxonomy",
        function(
            $rootScope,
            $scope,
            digitalAsset,
            SkyFactory,
            AssetFactory,
            $mdConstant,
            $translate,
            taxonomy
        ) {
            // 2.1 - Meta Form Fields
            this.useFileName = true;
            this.description = "";
            this.date = new Date();
            $scope.defaultDate = new Date();
            this.source = "";
            this.searchInput = null;
            $scope.dateformat = "";
            $scope.taxonomyId = null;
            $scope.tags = [];

            function getTaxonomyId() {
                taxonomy
                    .getTaxonomy(AssetFactory.getContentTypeId())
                    .then(function(data) {
                        $scope.taxonomyId = data;
                        $scope.getTags(data, "");
                    });
            }

            $scope.getTags = function(id, searchTerm) {
                taxonomy
                    .autoComplete(id, searchTerm.split(", ").pop(-1))
                    .then(function(data) {
                        $scope.tags = data;
                    });
            };
            $scope.meta = {
                elements: [],
                TEXTAREA: 3,
                TEXT: 2,
                TAGS: 5,
                DATEPICKER: 6
            };

            $translate("angular.dateformat").then(function(dateformat) {
                $scope.dateformat = dateformat;
                var startDate = moment().format(
                    $scope.dateformat.toUpperCase()
                );
            });

            $scope.assetMeta = {
                useFileName: true
            };
            $scope.assetWorkflow = {
                draft: true,
                review: false,
                approve: false,
                selected: null
            };

            $scope.$watch("meta.elements", function() {
                AssetFactory.setElements($scope.meta.elements);
                $rootScope.$emit("contentDataUpdated", {});
            });

            $rootScope.$on("contentTypeUpdated", function(args) {
                renderTemplateElements();
            });

            $rootScope.$on("assetUploadComplete", function(args) {
                //renderTemplateElements();
                submitMetaInformation();
            });

            function submitMetaInformation() {
                // console.log($scope.assetWorkflow);
                var DTOCopy = angular.copy($scope.meta.elements);
                DTOCopy.forEach(function(entry) {
                    if (entry.typeId === 6) {
                        //datepicker
                        //put date in format MM-DD-YYYY for submitting
                        $scope.momentPickerFormat = $translate.instant(
                            "angular.momentpicker.format"
                        );
                        entry.digitalAssetValue = moment(
                            entry.digitalAssetValue,
                            $scope.momentPickerFormat
                        ).format($scope.dateformat.toUpperCase());
                    }
                });

                var digitalAssetDTO = {
                    stateId: AssetFactory.getStateId(),
                    useFileNameAsTitle: $scope.assetMeta.useFileName,
                    uploadedAssetList: AssetFactory.getUploadedAssets(),
                    templateElementDTOList: DTOCopy,
                    programCategoryId: AssetFactory.getProgramCategoryId()
                };

                for (
                    var counter =
                        digitalAssetDTO.templateElementDTOList.length - 1;
                    counter >= 0;
                    counter--
                ) {
                    if (
                        $scope.meta.TAGS ===
                        digitalAssetDTO.templateElementDTOList[counter].typeId
                    ) {
                        digitalAssetDTO.templateElementDTOList[
                            counter
                        ].digitalAssetValue = $scope.assetMeta.searchInput;
                        break;
                    }
                }
                // console.log(" Content Type Updated to: " + AssetFactory.getContentTypeId());
                // console.log(digitalAssetDTO);

                var contentPromise = digitalAsset.createContent(
                    AssetFactory.getContentTypeId(),
                    digitalAssetDTO
                );
                contentPromise.then(function(elementData) {
                    // $scope.$parent.goToStep(5);
                });
            }

            $scope.checkErrors = function(cte) {
                var damDate = SkyFactory.emptyCheck(
                    SkyFactory.convertDateToMillis(cte.digitalAssetValue, [
                        $scope.dateformat,
                        "YYYY-MM-DD"
                    ])
                );
                if (-1 === damDate) {
                    cte.digitalAssetValue = moment().format(
                        $scope.dateformat.toUpperCase()
                    );
                }
            };

            function renderTemplateElements() {
                // console.log(" Content Type Updated to: " + AssetFactory.getContentTypeId());
                var elementPromise = digitalAsset.getContentElements(
                    AssetFactory.getContentTypeId()
                );
                elementPromise.then(function(elementData) {
                    $scope.meta.elements = elementData.data;
                    // console.log($scope.meta.elements);
                    getTaxonomyId();
                });
            }

            $scope.addCompletionDate = function(meta) {
                var theDate;
                var result = meta.filter(function(obj) {
                    if (
                        $scope.meta.DATEPICKER ===
                        obj.contentTemplateElementTypeId
                    ) {
                        //put in format MM-DD-YYYY for submitting
                        $scope.momentPickerFormat = $translate.instant(
                            "angular.momentpicker.format"
                        );
                        theDate = moment(
                            obj.digitalAssetValue,
                            $scope.momentPickerFormat
                        ).format($scope.dateformat.toUpperCase());
                        return;
                    }
                });
                $scope.$parent.addData({ dateCreated: theDate });
                return true;
            };
        }
    ]);

    // 3.0 - Asset Workflow | Controller
    app.controller("assetWorkflowCtrl", [
        "$scope",
        "AssetFactory",
        "$rootScope",
        "$translate",
        function($scope, AssetFactory, $rootScope, $translate) {
            $scope.state = {
                DRAFT: 57,
                APPROVED: 58,
                SUBMITTED: 3,
                stateId: 3
            };
            $scope.assetWorkflowOptions = [
                {
                    label: $translate.instant(
                        "angular.dam.bulkupld.steps.workflow.options.review"
                    ),
                    value: $scope.state.SUBMITTED
                },
                {
                    label: $translate.instant(
                        "angular.dam.bulkupld.steps.workflow.options.approve"
                    ),
                    value: $scope.state.APPROVED
                }
            ];

            $rootScope.$on("setStateToApproved", function(event, data) {
                if (data.toString() === "18") {
                    $scope.state.stateId = 58;
                }
            });

            $scope.addStateId = function(state) {
                AssetFactory.setStateId(state.stateId);
                $rootScope.$emit("stateIdSelected", {});
            };
            this.selectedWorkflow = null;
        }
    ]);

    // 4.0 - Asset Upload | Controller
    app.controller("assetUploadCtrl", [
        "$rootScope",
        "$scope",
        "digitalAsset",
        "SkyFactory",
        "AssetFactory",
        "FileUploader",
        "UUIDFactory",
        "$translate",
        function(
            $rootScope,
            $scope,
            digitalAsset,
            SkyFactory,
            AssetFactory,
            FileUploader,
            UUIDFactory,
            $translate
        ) {
            $scope.meta = {
                elements: [],
                stateId: null
            };
            $scope.formData = {
                key: "",
                policy: "",
                signature: "",
                acl: "private",
                AWSAccessKeyId: "",
                "Content-Type": ""
            };

            $scope.rejectedFiles = [];
            $scope.uploadDone = false;
            var maxFiles = 100;
            $scope.maxFileSize = 2147483648;

            $scope.stop = function() {
                return false;
            };

            // 4.1 - DEFINE UPLOADER
            var uploader = ($scope.uploader = new FileUploader({
                // 4.1.1 - Handle upload...
                // Example: https://github.com/nervgh/angular-file-upload/blob/master/examples/simple/upload.php
                formData: [],
                url: "",
                uuid: "",
                finalUrl: ""
            }));

            // 4.2 - Custom Filters
            uploader.filters.push({
                name: "countLimit",
                fn: function(item /*{File|FileLikeObject}*/, options) {
                    var isOK = this.queue.length < maxFiles;
                    if (!isOK) {
                        $scope.rejectedFiles.push({
                            file: item,
                            reason:
                                $translate.instant(
                                    "angular.dam.bulkupld.steps.upload.error.filecount"
                                ) +
                                " (" +
                                maxFiles +
                                ") " +
                                $translate.instant(
                                    "angular.dam.bulkupld.steps.upload.error.exceeded"
                                )
                        });
                    }
                    return isOK;
                }
            });

            // 4.3 - Custom Filters
            uploader.filters.push({
                name: "maxFileSize",
                fn: function(item /*{File|FileLikeObject}*/, options) {
                    var isOK = item.size < $scope.maxFileSize;
                    if (!isOK) {
                        $scope.maxFileSizeExceeded = true;
                        $scope.rejectedFiles.push({
                            file: item,
                            reason: $translate.instant(
                                "angular.dam.bulkupld.steps.upload.error.exceededmaxfilesize"
                            )
                        });
                    }
                    return isOK;
                }
            });

            uploader.filters.push({
                name: "noDups",
                fn: function(item /*{File|FileLikeObject}*/, options) {
                    for (var i = 0, len = this.queue.length; i < len; i++) {
                        if (this.queue[i].file.name === item.name) {
                            $scope.rejectedFiles.push({
                                file: item,
                                reason: $translate.instant(
                                    "angular.dam.bulkupld.steps.upload.error.alreadyadded"
                                )
                            });
                            return false;
                        }
                    }
                    return true;
                }
            });

            $scope.$watch("uploader.isUploading", function() {
                if (uploader.isUploading == true) {
                    window.addEventListener(
                        "dragover",
                        function(e) {
                            e = e || event;
                            e.preventDefault();
                        },
                        false
                    );
                    window.addEventListener(
                        "drop",
                        function(e) {
                            e = e || event;
                            e.preventDefault();
                        },
                        false
                    );
                }
            });

            $scope.manualUpload = function() {
                if (
                    $scope.uploadDone == false ||
                    uploader.isUploading == true
                ) {
                    $("#manualUpload").click();
                }
            };
            $scope.clearRejected = function() {
                $scope.rejectedFiles = [];
                $scope.maxFileSizeExceeded = null;
            };

            $scope.editSection = function(stepIndex) {
                if (!uploader.isUploading && $scope.uploadDone != true) {
                    $scope.$parent.goToStep(stepIndex);
                }
            };

            $scope.cancelSession = function() {
                // console.info('cancelSession');
                location.reload(true);
            };

            // 4.3 - Callbacks
            uploader.onWhenAddingFileFailed = function(
                item /*{File|FileLikeObject}*/,
                filter,
                options
            ) {
                //console.info('onWhenAddingFileFailed', item, filter, options);
                //console.log($scope.rejectedFiles);
            };
            uploader.onAfterAddingFile = function(fileItem) {
                var uuid = UUIDFactory.optimized2();
                fileItem.cleanFileName = SkyFactory.cleanFilename(
                    fileItem.file.name
                );
                $scope.formData.key =
                    AssetFactory.getAmazonWebService().key +
                    "" +
                    uuid +
                    "_" +
                    fileItem.cleanFileName;

                var key = { key: $scope.formData.key };
                var policy = {
                    policy: AssetFactory.getAmazonWebService().policy
                };
                var signature = {
                    signature: AssetFactory.getAmazonWebService().signature
                };
                var AWSAccessKeyId = {
                    AWSAccessKeyId: AssetFactory.getAmazonWebService()
                        .awsAccessKey
                };
                var acl = { acl: "private" };
                var content_type = { "Content-Type": fileItem.file.type };
                fileItem.uuid = uuid;
                fileItem.formData = [
                    key,
                    policy,
                    signature,
                    AWSAccessKeyId,
                    acl,
                    content_type
                ];
                fileItem.finalUrl =
                    AssetFactory.getAmazonWebService().awsUploadUrl +
                    $scope.formData.key;
                fileItem.key = $scope.formData.key;
                //console.log(fileItem.formData);
                fileItem.url = AssetFactory.getAmazonWebService().awsUploadUrl;
                // console.info('onAfterAddingFile', fileItem, UUIDFactory.optimized(), uuid, UUIDFactory.generate());
            };
            uploader.onAfterAddingAll = function(addedFileItems) {
                // console.info('onAfterAddingAll', addedFileItems);
            };
            uploader.onBeforeUploadItem = function(item) {
                // console.info('onBeforeUploadItem', item);
            };
            uploader.onProgressItem = function(fileItem, progress) {
                // console.info('onProgressItem', fileItem, progress);
            };
            uploader.onProgressAll = function(progress) {
                // console.info('onProgressAll', progress);
            };
            uploader.onSuccessItem = function(
                fileItem,
                response,
                status,
                headers
            ) {
                // console.info('onSuccessItem');
                //console.info(fileItem);
                //console.info(response);
                //console.info(status);
                //console.info(headers);
            };
            uploader.onErrorItem = function(
                fileItem,
                response,
                status,
                headers
            ) {
                // console.info('onErrorItem', fileItem, response, status, headers);
            };
            uploader.onCancelItem = function(
                fileItem,
                response,
                status,
                headers
            ) {
                // console.info('onCancelItem', fileItem, response, status, headers);
            };
            uploader.onCompleteItem = function(
                fileItem,
                response,
                status,
                headers
            ) {
                AssetFactory.addUploadedAssets(
                    fileItem.key,
                    fileItem.uuid,
                    fileItem.file.name,
                    fileItem.file.size,
                    fileItem.file.type
                );
                // console.info('onCompleteItem', fileItem, response, status, headers);
            };
            uploader.onCompleteAll = function() {
                $scope.uploadDone = true;
                // console.info('onCompleteAll -> now get form data and send');
                // console.info('AssetFactory.addUploadedAssets');
                // console.info(AssetFactory.getUploadedAssets());
                $rootScope.$emit("assetUploadComplete", {});
                $scope.$parent.goToStep(5);
            };

            $rootScope.$on("setBulkUploadAssetDetails", function() {
                // console.log("Received content element data in assetMetaCtrl");
                $scope.meta.elements = AssetFactory.getElements();
            });

            $rootScope.$on("setupStateId", function() {
                // console.log("Received state Id");
                //$rootScope.$broadcast("setupStateId", {});
                $scope.meta.stateId = AssetFactory.getStateId();
            });

            $rootScope.$on("awsAndMimeTypes", function(args) {
                var awsPromise = digitalAsset.getAWS(
                    AssetFactory.getContentTypeId()
                );
                var mimeTypePromise = digitalAsset.getMimeTypes(
                    AssetFactory.getContentTypeId()
                );
                // console.log("Uploader setup received $emit.");
                awsPromise.then(function(aws) {
                    AssetFactory.setAmazonWebService(aws.data);
                    $scope.formData = {
                        key: aws.data.filename,
                        policy: aws.data.policy,
                        signature: aws.data.signature,
                        acl: "private",
                        AWSAccessKeyId: aws.data.awsAccessKey
                    };
                });

                mimeTypePromise.then(function(mime) {
                    var mimeTypes =
                        mime.data.extensions.map(function(x) {
                            return x.replace(/\./g, "|");
                        }) + "|";
                    mimeTypes = mimeTypes.replace(/,/gi, "");

                    for (var i = 0; i < mime.data.mimetypes.length; i++) {
                        mimeTypes += mime.data.mimetypes[i] + "|";
                    }
                    uploader.filters = uploader.filters.filter(function(el) {
                        return el.name !== "damFilter";
                    });
                    uploader.filters.push({
                        name: "damFilter",
                        fn: function(
                            item /*#<{(|{File|FileLikeObject}|)}>#*/,
                            options
                        ) {
                            var type =
                                "|" +
                                item.type.slice(
                                    item.type.lastIndexOf("/") + 1
                                ) +
                                "|";
                            var reject = true;
                            var allow = true;
                            var filenameExt = item.name
                                .substring(item.name.lastIndexOf(".") + 1)
                                .toLowerCase();
                            //console.log("filenameExt " + filenameExt);
                            for (
                                var i = 0;
                                i < mime.data.extensions.length;
                                i++
                            ) {
                                if (
                                    mime.data.extensions[i]
                                        .substring(1)
                                        .toLowerCase() === filenameExt
                                ) {
                                    reject = false;
                                    break;
                                }
                            }

                            if (true === reject) {
                                $scope.rejectedFiles.push({
                                    file: item,
                                    reason:
                                        $translate.instant(
                                            "angular.dam.bulkupld.steps.upload.error.restrictedfiletype"
                                        ) +
                                        " - " +
                                        item.type +
                                        ", " +
                                        $translate.instant(
                                            "angular.dam.bulkupld.steps.upload.error.filename"
                                        ) +
                                        " " +
                                        item.name
                                });
                                allow = false;
                            }
                            return allow;
                        }
                    });
                    $scope.bulkuploadFileRequirements = [];
                    for (var i = 0; i < mime.data.extensions.length; i++) {
                        $scope.bulkuploadFileRequirements +=
                            mime.data.extensions[i] + ", ";
                    }
                    $scope.bulkuploadFileRequirements = $scope.bulkuploadFileRequirements.replace(
                        /,\s*$/,
                        ""
                    );
                });
            });
        }
    ]);

    // 5.0 - Asset Upload Complete | Controller
    app.controller("assetCompleteCtrl", ["$scope", function($scope) {}]);

    // 6.0 Asset Meta Tags | Directive
    // NOTE: Need to move this to directive folder
    app.directive("tagInput", [
        "$timeout",
        function($timeout) {
            return {
                restrict: "E",
                scope: {
                    inputTags: "=taglist",
                    autocomplete: "=autocomplete",
                    ngModel: "="
                },
                link: function($scope, element, attrs, ngModel) {
                    $scope.defaultWidth = 825;
                    $scope.ngModel = "";
                    $scope.placeholder = attrs.placeholder;

                    if ($scope.autocomplete) {
                        $scope.autocompleteFocus = function(event, ui) {
                            return false;
                        };
                        $scope.autocompleteSelect = function(event, ui) {
                            var str = $(element)
                                .find("input")
                                .val();
                            var countTags = ($scope.ngModel.match(/,/g) || [])
                                .length;
                            var checkString = null;
                            if (countTags == 0) {
                                checkString = true;
                            }
                            var arr = str.split(",");
                            arr.splice(arr.length - 1, 1);
                            arr.join(",");
                            $(element)
                                .find("input")
                                .val(arr + ui.item.value);
                            if (checkString == true) {
                                $scope.$apply(
                                    "ngModel='" + ui.item.value + ", " + "'"
                                );
                                checkString = null;
                            } else {
                                $scope.$apply(
                                    "ngModel='" +
                                        arr +
                                        ", " +
                                        ui.item.value +
                                        ", " +
                                        "'"
                                );
                            }

                            $scope.inputTags = $scope.ngModel;
                            return false;
                        };
                        $(element)
                            .find("input")
                            .autocomplete({
                                minLength: 2,
                                source: function(request, response) {
                                    request.term = request.term
                                        .split(", ")
                                        .pop(-1);
                                    var item;
                                    return response(
                                        (function() {
                                            var i, len, ref, results;
                                            ref = $scope.autocomplete;
                                            results = [];
                                            for (
                                                i = 0, len = ref.length;
                                                i < len;
                                                i++
                                            ) {
                                                item = ref[i];
                                                if (
                                                    item
                                                        .toLowerCase()
                                                        .indexOf(
                                                            request.term.toLowerCase()
                                                        ) !== -1
                                                ) {
                                                    results.push(item);
                                                }
                                            }
                                            return results;
                                        })()
                                    );
                                },
                                focus: (function(_this) {
                                    return function(event, ui) {
                                        return $scope.autocompleteFocus(
                                            event,
                                            ui
                                        );
                                    };
                                })(this),
                                select: (function(_this) {
                                    return function(event, ui) {
                                        return $scope.autocompleteSelect(
                                            event,
                                            ui
                                        );
                                    };
                                })(this)
                            });
                    }
                },
                template:
                    "<div class='tag-input-ctn'><div class='input-tag' data-ng-repeat=\"tag in tagArray()\">{{tag}},</div><input type='text' data-ng-style='{width: inputWidth}' data-ng-model='ngModel'/></div>"
            };
        }
    ]);

    // 7.0 - jQueryUI Calendar | Directive
    // Need to move this to directive folder
    // "link" option to register DOM listeners as well as update the DOM. It is executed after the template has been cloned and is where directive logic will be put.
    app.directive("calendar", function() {
        return {
            require: "ngModel",
            link: function(scope, el, attr, ngModel) {
                $(el).datepicker({
                    dateFormat: "mm-dd-yy",
                    onSelect: function(dateText) {
                        scope.$apply(function() {
                            ngModel.$setViewValue(dateText);
                        });
                    }
                });
                $(el).blur(function() {
                    var date;
                    $(this)
                        .parent()
                        .find(".datepicker-error")
                        .hide();
                    try {
                        var pickerEl = document.getElementById(
                            $(this).attr("id")
                        );
                        var inst = $.datepicker._getInst(pickerEl);

                        date =
                            $(this).val() == "" ||
                            $.datepicker.parseDate(
                                inst.settings.dateFormat ||
                                    $.datepicker._defaults.dateFormat,
                                $(this).val(),
                                inst.settings
                            );
                    } catch (e) {
                        //alert("$.datepicker.parseDate failed");
                    }
                    if (!date) {
                        $(this)
                            .parent()
                            .find(".datepicker-error")
                            .show();
                        $(this).val("");
                    }
                });
            }
        };
    });
})();

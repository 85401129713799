app.controller("packageList", [
    "$scope",
    "$translate",
    "assignment",
    function($scope, $translate, assignment) {
        assignment.init();
        var onDelete = window.sessionStorage.getItem("removeContentPackage");
        if (onDelete != null) {
            $translate("sw.package.deletecontentpackagemessage").then(
                (value) => {
                    $("#success-box .content").append(
                        "<li>" +
                            $("<span/>")
                                .html(value)
                                .text() +
                            "</li>"
                    );
                    $("#success-box").slideDown("slow");
                    window.sessionStorage.removeItem("removeContentPackage");
                }
            );
        } else {
            window.sessionStorage.removeItem("removeContentPackage");
        }
    }
]).controller("contentPackageDetails", [
    "$scope",
    "$translate",
    "overlay",
    function($scope, $translate, overlay) {
        console.log("Calling contentPackageDetails Controller");
        var body = $("body");
        var contentPackageBodyHeight = 0;

        $(function() {
            var contentPackageBody = $("#contentPackageBody");
            if (contentPackageBody) {
                contentPackageBodyHeight = $("#contentPackageBody").height();
                $("#contentPackageBody").css("overflow", "hidden");
                if (contentPackageBodyHeight > 200) {
                    $("#contentPackageBody").animate({ height: 200 }, 200);
                    $("#showMoreContentPackageBody").css("display", "flex");
                } else {
                    $("#contentPackageBody").animate(
                        { height: contentPackageBodyHeight },
                        200
                    );
                    $("#showMoreContentPackageBody").css("display", "none");
                }
                $(".contentPackageBodyWrapper").css("opacity", "1");
            }
        });

        body.on("click", "#showMore", function() {
            $("#contentPackageBody").css("overflow", "hidden");
            $("#contentPackageBody").animate(
                { height: contentPackageBodyHeight },
                200
            );
            $("#showMore").css("display", "none");
            $("#showLess").css("display", "inline");
        });

        body.on("click", "#showLess", function() {
            $("#contentPackageBody").css("overflow", "hidden");
            $("#contentPackageBody").animate({ height: 200 }, 300);
            $("#showLess").css("display", "none");
            $("#showMore").css("display", "inline");
        });

        body.on("click", ".deleteContentPackage", function(e) {
            let deleteBtn = $(this);
            let { url } = deleteBtn[0].dataset;
            var opts = {
                html:
                    '<div class="delete-content-package-pane">' +
                    "<h1>" +
                    $translate.instant("sw.title.deletecontentpackage") +
                    "</h1>" +
                    '<div class="info-container">' +
                    $translate.instant("sw.info.deletecontentpackage") +
                    "</div>" +
                    '<div class="delete-btn-container">' +
                    '<a id="create-cancel-link" class="clearNavigationLock lg orange" href="' +
                    url +
                    '">' +
                    $translate.instant("sw.label.yes") +
                    "</a>" +
                    '<a rel="deactivate" id="closeContentDeleteModal" class="lightwindow_action close noCloseButton" href="#">' +
                    $translate.instant("sw.label.no") +
                    "</a>" +
                    "</div>" +
                    "</div>",
                closeButton: false,
                overlayClose: false
            };
            $.colorbox(opts);
            $("#cboxClose").css("display", "none");
            overlay.setupColorbox();
            // console.log("delete button clicked", deleteA);
        })
            .on("click", "#closeContentDeleteModal", function() {
                parent.$.colorbox.close();
            })
            .on("click", "#create-cancel-link", function() {
                window.sessionStorage.setItem("removeContentPackage", "true");
            });
    }
]);

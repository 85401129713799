app.service("calendarEvent", [
    "$http",
    function($http) {
        return {
            getCalendarEvent: function(
                programId,
                calendarEventId,
                callback,
                errorCallback
            ) {
                if (-1 === parseInt(calendarEventId, 10)) {
                    return; // invalid event id
                }
                $http({
                    url:
                        "/API/program/" +
                        programId +
                        "/calendar-event/" +
                        calendarEventId,
                    method: "GET"
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                        errorCallback(data);
                    });
            },
            getAllProgramEvents: function(programId, callback, errorCallback) {
                $http({
                    url: "/API/program/" + programId + "/calendar-event/",
                    method: "GET"
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                        errorCallback(data);
                    });
            },
            saveCalendarEvent: function(
                calendarEventParams,
                programId,
                publishCalendar,
                calendarEventId,
                callback,
                errorCallback
            ) {
                $http({
                    url: "/API/program/" + programId + "/calendar-event",
                    method: "PUT",
                    data: {
                        taxonomyValueId: calendarEventParams.taxonomyValueId,
                        eventName: calendarEventParams.eventName,
                        eventDescription: calendarEventParams.eventDescription,
                        startDate: calendarEventParams.startDate,
                        endDate: calendarEventParams.endDate,
                        dateStartLong: calendarEventParams.startDate,
                        dateEndLong: calendarEventParams.endDate,
                        projectId: calendarEventParams.projectId,
                        programId: calendarEventParams.programId,
                        id: calendarEventId,
                        assetIdList: calendarEventParams.assetIdList,
                        active: calendarEventParams.active,
                        enableResourceSharing: calendarEventParams.enableResourceSharing
                    },
                    cache: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log("error status returned: ", status);
                        console.log(data);
                        errorCallback(data);
                    });
            },
            deleteCalendarEvent: function(
                programId,
                calendarEventId,
                publishCalendar,
                callback,
                errorCallback
            ) {
                $http({
                    url:
                        "/API/program/" +
                        programId +
                        "/calendar-event/" +
                        calendarEventId,
                    method: "DELETE",
                    cache: false,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                        errorCallback(data);
                    });
            },
            getEventTypes: getEventTypes
        };

        function getEventTypes(programId) {
            return $http.get(
                "/API/program/" + programId + "/calendar-event/types"
            );
        }
    }
]);

app.service("oAuthInfoService", [
    "$http",
    function($http) {
        return {
            getListOauthInfoYoutube: function(attachment, callback) {
                $http({
                    url:
                        "/API/program/OAuthInfo/contentRequest/" +
                        attachment.contentRequestId +
                        "/youtube",
                    method: "GET"
                })
                    .success(function(data, status, headers, config) {
                        callback(data);
                    })
                    .error(function(data, status, headers, config) {
                        console.log(data);
                        errorCallback(data);
                    });
            }
        };
    }
]);

(function($) {
    app.controller("amplifyHome", [
        "$scope",
        "amplify",
        function($scope, amplify) {
            amplify.init();
        }
    ])
        .controller("amplifySearchContent", [
            "$scope",
            "amplify",
            "$translate",
            function($scope, amplify, $translate) {
                //needed for new moment-datepicker
                $scope.dates = {
                    startDate: null,
                    endDate: null
                };

                //watches start date to make sure end date is not before start date
                $scope.$watch("[dates.startDate, dates.endDate]", function(
                    newValue,
                    oldValue
                ) {
                    $scope.momentDateFormat = $("#momentPickerFormat").val();
                    $scope.dateformat = "MM-DD-YYYY";

                    if ($scope.dates.startDate != null) {
                        $scope.minDate = $scope.dates.startDate.toString();
                    }
                    if ($scope.dates.endDate != null) {
                        $scope.maxDate = $scope.dates.endDate.toString();
                    }

                    if (
                        $scope.dates.startDate == null &&
                        $scope.dates.endDate == null
                    ) {
                        var startDateVal = $("#startDateString").val();
                        var endDateVal = $("#endDateString").val();
                        $scope.dates.startDate = moment(
                            startDateVal,
                            $scope.dateformat
                        ).format($scope.momentDateFormat);
                        $scope.dates.endDate = moment(
                            endDateVal,
                            $scope.dateformat
                        ).format($scope.momentDateFormat);
                    }

                    if (
                        $scope.dates.startDate != null &&
                        $scope.dates.endDate != null
                    ) {
                        $scope.startDateMoment = moment(
                            $scope.dates.startDate,
                            $scope.momentDateFormat
                        ).format($scope.dateformat);
                        $scope.endDateMoment = moment(
                            $scope.dates.endDate,
                            $scope.momentDateFormat
                        ).format($scope.dateformat);
                        $("#startDateString").val($scope.startDateMoment);
                        $("#endDateString").val($scope.endDateMoment);
                    }
                });
            }
        ])
        .controller("searchPosts", [
            "$scope",
            "amplify",
            function($scope, amplify) {
                amplify.init();

                //needed for new moment-datepicker
                $scope.dates = {
                    startDate: null,
                    endDate: null
                };

                //watches start date to make sure end date is not before start date
                $scope.$watch("[dates.startDate, dates.endDate]", function(
                    newValue,
                    oldValue
                ) {
                    $scope.momentDateFormat = $("#momentPickerFormat").val();
                    $scope.dateformat = "MM-DD-YYYY";

                    if ($scope.dates.startDate != null) {
                        $scope.minDate = $scope.dates.startDate.toString();
                    }
                    if ($scope.dates.endDate != null) {
                        $scope.maxDate = $scope.dates.endDate.toString();
                    }

                    if (
                        $scope.dates.startDate == null &&
                        $scope.dates.endDate == null
                    ) {
                        var startDateVal = $("#startDateString").val();
                        var endDateVal = $("#endDateString").val();
                        $scope.dates.startDate = moment(
                            startDateVal,
                            $scope.dateformat
                        ).format($scope.momentDateFormat);
                        $scope.dates.endDate = moment(
                            endDateVal,
                            $scope.dateformat
                        ).format($scope.momentDateFormat);
                    }

                    if (
                        $scope.dates.startDate != null &&
                        $scope.dates.endDate != null
                    ) {
                        $scope.startDateMoment = moment(
                            $scope.dates.startDate,
                            $scope.momentDateFormat
                        ).format($scope.dateformat);
                        $scope.endDateMoment = moment(
                            $scope.dates.endDate,
                            $scope.momentDateFormat
                        ).format($scope.dateformat);
                        $("#startDateString").val($scope.startDateMoment);
                        $("#endDateString").val($scope.endDateMoment);
                    }
                });
            }
        ])
        .controller("postCreateAjax", ["$scope", function($scope) {}])
        .controller("postDeleteAjax", ["$scope", function($scope) {}])
        .controller("amplifyContent", [
            "$scope",
            "amplify",
            "assignment",
            "common",
            "assignmentAttachments",
            function(
                $scope,
                amplify,
                assignment,
                common,
                assignmentAttachments
            ) {
                amplify.init();
                assignment.init();
                common.init();
                assignmentAttachments.init();
            }
        ]);
})(jQuery);

/**
 * Created by aoliveri on 1/22/16.
 */
(function() {
    app.service("sanitizer", [
        "$http",
        function($http) {
            return {
                cleanAttachmentMetaInformation: cleanAttachmentMetaInformation
            };

            function cleanAttachmentMetaInformation(cropDTO) {
                var url = "/API/sanitize/embedded-attachment";
                return $http.put(url, cropDTO);
            }
        }
    ]);
})();

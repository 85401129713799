(function() {
    app.service("redactor", [
        "skyword",
        "call",
        "TemplateFactory",
        function(skyword, call, TemplateFactory) {
            var currentEditor,
                language =
                    $.Redactor != null
                        ? $.Redactor.opts.langs[skyword.getLanguage()]
                        : null,
                redactorSave = function() {
                    //function is overwritten by content template etc
                },
                saveRedactorEditor = function() {};
            return {
                nonedit: function() {
                    $("div.notEditable, div.redactor_notEditable").each(
                        function(i, el) {
                            $(el)
                                .siblings("ul.redactor_toolbar")
                                .remove();
                            $(el).attr("contenteditable", false);
                        }
                    );
                },
                setupRedactorElements: function(containerId) {
                    var attrs = jQuery("#attrs-id");
                    if (
                        "articleReview" !== attrs.data("actionname") &&
                        "viewContent" !== attrs.data("actionname") &&
                        "viewContributor" !== attrs.data("actionname") &&
                        "programProfile" !== attrs.data("actionname") &&
                        "agencyProfile" !== attrs.data("actionname") &&
                        "vettingProfile" !== attrs.data("actionname") &&
                        "externalContentReview" !== attrs.data("actionname")
                    ) {
                        return false;
                    }

                    var redactorClass = ".redactor",
                        redactorTitleClass = ".redactorTitle",
                        redactorTextClass = ".redactorText",
                        redactorTextSmallClass = ".redactorTextSmall",
                        redactorNonTemplateClass = ".nonTemplateRedactorText",
                        redactorNonTemplateRichClass = ".nonTemplateRedactor",
                        redactorSingleLineClass = ".singleLine",
                        redactorCaptionClass = ".redactorCaption",
                        isContainer = false,
                        paste = false,
                        tempText,
                        buttonList,
                        buttonsUpload = [
                            "undo",
                            "redo",
                            "orderedlist",
                            "unorderedlist",
                            "alignleft",
                            "alignright",
                            "aligncenter",
                            "justify",
                            "indent",
                            "outdent",
                            "link",
                            "bold",
                            "italic",
                            "deleted",
                            "underline",
                            "formatting",
                            "html",
                            "upload",
                            "spellcheck",
                            "superscript",
                            "subscript"
                        ],
                        buttonsNoUpload = [
                            "undo",
                            "redo",
                            "orderedlist",
                            "unorderedlist",
                            "alignleft",
                            "alignright",
                            "aligncenter",
                            "justify",
                            "indent",
                            "outdent",
                            "link",
                            "bold",
                            "italic",
                            "deleted",
                            "underline",
                            "formatting",
                            "html",
                            "spellcheck",
                            "superscript",
                            "subscript"
                        ],
                        buttonsCaption = [
                            "undo",
                            "redo",
                            "orderedlist",
                            "unorderedlist",
                            "indent",
                            "outdent",
                            "link",
                            "bold",
                            "italic",
                            "deleted",
                            "underline",
                            "html",
                            "spellcheck",
                            "superscript",
                            "subscript"
                        ],
                        buttonsCustomObj = {
                            superscript: {
                                title:
                                    language && language.superscript
                                        ? language.superscript
                                        : null,
                                callback: function(obj) {
                                    if (this.checkIfCurrentEditor() === false) {
                                        return false;
                                    }
                                    this.execCommand("superscript", false);
                                }
                            },
                            subscript: {
                                title:
                                    language && language.subscript
                                        ? language.subscript
                                        : null,
                                callback: function(obj) {
                                    if (this.checkIfCurrentEditor() === false) {
                                        return false;
                                    }
                                    this.execCommand("subscript", false);
                                }
                            },
                            spellcheck: {
                                title:
                                    language && language.spellcheck
                                        ? language.spellcheck
                                        : null,
                                callback: function(obj) {
                                    // SKY-18661 & SKY-18703 - Do not allow multiple clicks of spell check method.
                                    if (
                                        false ===
                                        this.$box.hasClass("spellcheckActive")
                                    ) {
                                        this.$box.addClass("spellcheckActive");
                                        var r = this.$box.find(
                                                "> div.redactor_editor"
                                            ),
                                            id = r.attr("id");
                                        TemplateFactory.cleanHTML(r);
                                        skyword.applySpellcheckMarks(id);
                                    } else {
                                        $(
                                            ".hiddenGrammarError,.hiddenSuggestion,.hiddenSpellError"
                                        )
                                            .addClass("hide")
                                            .contents()
                                            .unwrap();
                                        $(".mceItemHidden")
                                            .contents()
                                            .unwrap();
                                        this.$box.removeClass(
                                            "spellcheckActive"
                                        );
                                    }
                                }
                            },
                            upload: {
                                title:
                                    language && language.upload
                                        ? language.upload
                                        : null,
                                callback: function(obj) {
                                    var el = this.$element,
                                        embeddedUploader = el
                                            .closest(".articleElement")
                                            .find(".embeddedUploader"),
                                        urlAction =
                                            "uploadEmbeddedImageAjax.action?editorId=" +
                                            el.attr("id") +
                                            "&contentRequestId=" +
                                            $("#contentRequestId").val();
                                    this.selectionSave();
                                    embeddedUploader.fadeToggle("fast");
                                }
                            },
                            undo: {
                                title:
                                    language && language.undo
                                        ? language.undo
                                        : null,
                                callback: function(obj) {
                                    if (this.opts.buffer.length) {
                                        this.bufferUndo();
                                    } else {
                                        //console.log("execCommand undo");
                                        this.document.execCommand(
                                            "undo",
                                            false,
                                            false
                                        );
                                    }
                                    return;
                                }
                            },
                            redo: {
                                title:
                                    language && language.redo
                                        ? language.redo
                                        : null,
                                callback: function() {
                                    //console.log("I should be redoing!");
                                    //event.preventDefault();
                                    if (this.opts.rebuffer.length) {
                                        //console.log("buffer redo");
                                        this.bufferRedo();
                                    } else {
                                        //console.log("execCommand redo");
                                        this.document.execCommand(
                                            "redo",
                                            false,
                                            false
                                        );
                                    }
                                    return;
                                }
                            }
                        };

                    if (
                        undefined !== containerId &&
                        null !== containerId &&
                        0 < containerId.length
                    ) {
                        redactorClass = "#" + containerId + " " + redactorClass;
                        redactorTitleClass =
                            "#" + containerId + " " + redactorTitleClass;
                        redactorTextClass =
                            "#" + containerId + " " + redactorTextClass;
                        redactorNonTemplateClass =
                            "#" + containerId + " " + redactorNonTemplateClass;
                        redactorSingleLineClass =
                            "#" + containerId + " " + redactorSingleLineClass;
                        redactorCaptionClass =
                            "#" + containerId + " " + redactorCaptionClass;
                        isContainer = true;
                    }

                    buttonList =
                        $("#allow-image-upload").val() == "true"
                            ? buttonsUpload
                            : buttonsNoUpload;

                    if ($(redactorTitleClass).length > 0) {
                        $(redactorTitleClass).redactor({
                            lang: skyword.getLanguage(),
                            plugins: ["skytator"],
                            allowedTags: ["mark"],
                            initCallback: function() {
                                this.$box
                                    .find("> div")
                                    .attr("spellcheck", false)
                                    .attr(
                                        "id",
                                        "redactor-" +
                                            this.$box
                                                .closest(".actualElement")
                                                .attr("id")
                                    );
                                wordCount(this.get(), this, true, true);
                                this.bufferSet();
                            },
                            keyupCallback: function(obj, event) {
                                //skycache.updateCache = true;
                            },
                            changeCallback: function(html) {
                                wordCount(html, this, true, true);
                                paste = false;
                                redactorSave(this);
                            },
                            pasteBeforeCallback: function(html) {
                                this.bufferSet();
                                this.selectionSave();
                                //console.log("before: "  + html);
                                html = html.replace(/<(?:.|\n)*?>/gm, "");
                                //console.log("replaced: "  + html);
                                tempText = this.get();
                                paste = true;
                                return html;
                            },
                            pasteAfterCallback: function(html) {
                                html = html.replace(/<(?:.|\n)*?>/gm, "");
                                tempText = this.get();
                                paste = true;
                                return html;
                            },
                            linebreaks: true,
                            toolbar: false,
                            shortcuts: false
                        });
                    }

                    if ($(redactorTextClass).length > 0) {
                        $(redactorTextClass).redactor({
                            lang: skyword.getLanguage(),
                            plugins: ["skytator"],
                            allowedTags: ["mark", "br"],
                            initCallback: function() {
                                this.$box
                                    .find("> div")
                                    .attr("spellcheck", false)
                                    .attr(
                                        "id",
                                        "redactor-" +
                                            this.$box
                                                .closest(".actualElement")
                                                .attr("id")
                                    );
                                wordCount(this.get(), this, true, true);
                            },
                            keyupCallback: function(obj, event) {
                                //skycache.updateCache = true;
                            },
                            changeCallback: function(html) {
                                wordCount(html, this, true, true);
                                paste = false;
                                redactorSave(this);
                            },
                            pasteAfterCallback: function(html) {
                                this.bufferSet();
                                this.selectionSave();
                                html = html.replace(/<(?:.|\n)*?>/gm, "");
                                tempText = this.get();
                                paste = true;
                                return html;
                            },
                            toolbar: false,
                            linebreaks: true,
                            convertDivs: false,
                            shortcuts: false,
                            autoresize: false
                        });
                    }

                    if ($(redactorTextSmallClass).length > 0) {
                        $(redactorTextSmallClass).redactor({
                            lang: skyword.getLanguage(),
                            allowedTags: ["br"],
                            plugins: [],
                            initCallback: function() {},
                            keyupCallback: function(obj, event) {},

                            changeCallback: function(html) {},
                            pasteBeforeCallback: function(html) {
                                this.bufferSet();
                                this.selectionSave();
                                //console.log("before: "  + html);
                                html = html.replace(/<(?:.|\n)*?>/gm, "");
                                //console.log("replaced: "  + html);
                                tempText = this.get();
                                paste = true;
                                return html;
                            },
                            pasteAfterCallback: function(html) {
                                html = html.replace(/<(?:.|\n)*?>/gm, "");
                                tempText = this.get();
                                paste = true;
                                return html;
                            },
                            toolbar: false,
                            linebreaks: true,
                            convertDivs: false,
                            shortcuts: false,
                            autoresize: true
                        });
                    }

                    if ($(redactorNonTemplateClass).length > 0) {
                        $(redactorNonTemplateClass).redactor({
                            lang: skyword.getLanguage(),
                            allowedTags: ["mark", "br"],
                            initCallback: function() {
                                this.$box
                                    .find("> div")
                                    .attr("spellcheck", false)
                                    .attr(
                                        "id",
                                        "redactor-" +
                                            this.$box
                                                .closest(".actualElement")
                                                .attr("id")
                                    );
                                wordCount(this.get(), this, true, false);
                                $(this).colorbox.resize();
                            },
                            keyupCallback: function(obj, event) {
                                //skycache.updateCache = true;
                            },
                            changeCallback: function(html) {
                                wordCount(html, this, true, false);
                                paste = false;
                            },
                            pasteBeforeCallback: function(html) {
                                this.bufferSet();
                                this.selectionSave();
                                html = html.replace(/&nbsp;/gm, " ");
                                html = html.replace(/<(?:.|\n)*?>/gm, "");
                                tempText = this.get();
                                paste = true;
                                return html;
                            },
                            toolbar: false,

                            linebreaks: true,
                            shortcuts: false,
                            autoresize: true
                        });
                    }

                    if ($(redactorNonTemplateRichClass).length > 0) {
                        $(redactorNonTemplateRichClass).redactor({
                            lang: skyword.getLanguage(),
                            plugins: ["searchreplace"],
                            deniedTags: [
                                "html",
                                "head",
                                "link",
                                "body",
                                "span",
                                "meta",
                                "script",
                                "style",
                                "applet"
                            ],
                            convertLinks: false,
                            initCallback: function() {
                                var editor, timeout;
                                wordCount(this.get(), this, false, true);
                                try {
                                    // Toggles are needed to fix a bug with single characters with formatting being stripped (SKY-7288)
                                    this.skyContainer = isContainer;
                                    this.toggleCode(true);
                                } catch (e) {
                                    //console.log(e.message + " >> this.$element.attr(id) " + this.$element.attr("id"));
                                }

                                editor = this;
                                timeout = setTimeout(function() {
                                    toggleThatVisual(editor);
                                }, 500);

                                this.$box
                                    .find(".redactor_editor")
                                    .find("p, strong, em, span")
                                    .each(removeInitEmptyPTags);
                                this.bufferSet();
                            },
                            changeCallback: function(html) {
                                wordCount(html, this, false, true);
                            },
                            keyupCallback: function(obj, event) {
                                var p = null,
                                    ctrl = obj.ctrlKey || obj.metaKey;
                                if (
                                    this.$editor.hasClass(
                                        "redactor_notEditable"
                                    )
                                ) {
                                    return false;
                                }
                                $(".redactor-search-replace").slideUp(
                                    function() {
                                        $(this).remove();
                                    }
                                );
                                this.sync();
                            },
                            pasteBeforeCallback: function(html) {
                                html = html
                                    .replace(/<mark[^>]*>/g, "")
                                    .replace(/<\/mark>/g, "");
                                var cleanHtml = $("<div/>").html(html);
                                this.sync();
                                this.$box
                                    .find(".redactor_editor")
                                    .find("p, strong, em, span")
                                    .each(removeInitEmptyPTags);
                                cleanHtml
                                    .find("a:not([href])")
                                    .contents()
                                    .unwrap();
                                html = cleanHtml.html();
                                return html;
                            },
                            toolbarFixedBox: true,
                            buttons: buttonList,
                            convertDivs: false,
                            buttonsCustom: buttonsCustomObj,
                            observeLinks: true
                        });
                    }

                    if ($(redactorSingleLineClass).length > 0) {
                        $(redactorSingleLineClass).redactor({
                            lang: skyword.getLanguage(),
                            plugins: ["skytator"],
                            allowedTags: ["mark"],
                            initCallback: function() {
                                this.$box
                                    .find("> div")
                                    .attr("spellcheck", false)
                                    .attr(
                                        "id",
                                        "redactor-" +
                                            this.$box
                                                .closest(".actualElement")
                                                .attr("id")
                                    );
                                wordCount(this.get(), this, true, true);
                            },
                            keyupCallback: function(obj, event) {
                                //skycache.updateCache = true;
                                redactorSave(this);
                            },
                            changeCallback: function(html) {
                                wordCount(html, this, true, true);
                                paste = false;
                            },
                            pasteBeforeCallback: function(html) {
                                this.bufferSet();
                                this.selectionSave();
                                tempText = this.get();
                                paste = true;
                                return html;
                            },
                            toolbar: false,
                            linebreaks: true,
                            shortcuts: false
                        });
                    }

                    if ($(redactorClass).length > 0) {
                        $(redactorClass).redactor({
                            lang: skyword.getLanguage(),
                            plugins: [
                                "skytator",
                                "searchreplace",
                                "specialchars"
                            ],
                            deniedTags: [
                                "html",
                                "head",
                                "link",
                                "body",
                                "span",
                                "meta",
                                "script",
                                "style",
                                "applet"
                            ],
                            convertLinks: false,
                            initCallback: function() {
                                var editor, timeout;
                                //console.log("this.$element.attr(id) " + this.$element.attr("id"));
                                this.$box
                                    .find("> div")
                                    .attr("spellcheck", false)
                                    .attr(
                                        "id",
                                        "redactor-" + this.$element.attr("id")
                                    );
                                wordCount(this.get(), this, false, true);
                                try {
                                    // Toggles are needed to fix a bug with single characters with formatting being stripped (SKY-7288)
                                    this.skyContainer = isContainer;
                                    this.toggleCode(true);
                                } catch (e) {
                                    //console.log(e.message + " >> this.$element.attr(id) " + this.$element.attr("id"));
                                }

                                editor = this;
                                timeout = setTimeout(function() {
                                    toggleThatVisual(editor);
                                }, 500);

                                this.$box
                                    .find(".redactor_editor")
                                    .find("p, strong, em, span")
                                    .each(removeInitEmptyPTags);
                                this.bufferSet();
                            },
                            changeCallback: function(html) {
                                wordCount(html, this, false, true);
                                redactorSave(this);
                                skyword.enableDisableButton();
                            },
                            keyupCallback: function(obj, event) {
                                var p = null,
                                    ctrl = obj.ctrlKey || obj.metaKey;
                                if (
                                    this.$editor.hasClass(
                                        "redactor_notEditable"
                                    )
                                ) {
                                    //event.stopPropagation();
                                    return false;
                                }
                                //skycache.updateCache = true;
                                $(".redactor-search-replace").slideUp(
                                    function() {
                                        $(this).remove();
                                    }
                                );
                                this.sync();
                            },
                            pasteBeforeCallback: function(html) {
                                html = html
                                    .replace(/<mark[^>]*>/g, "")
                                    .replace(/<\/mark>/g, "");
                                var cleanHtml = $("<div/>").html(html);
                                this.sync();
                                this.$box
                                    .find(".redactor_editor")
                                    .find("p, strong, em, span")
                                    .each(removeInitEmptyPTags);
                                cleanHtml
                                    .find("a:not([href])")
                                    .contents()
                                    .unwrap();
                                html = cleanHtml.html();
                                return html;
                            },
                            clickCallback: function(obj) {
                                var str = obj.currentTarget.innerHTML,
                                    div1,
                                    div2,
                                    text1,
                                    text2,
                                    j1,
                                    j2,
                                    str1,
                                    str2,
                                    hash1,
                                    hash2,
                                    nextHtml;
                                if (0 < this.opts.buffer.length) {
                                    div1 = document.createElement("div");
                                    div2 = document.createElement("div");

                                    div2.innerHTML = str;
                                    div1.innerHTML = this.opts.buffer[
                                        this.opts.buffer.length - 1
                                    ];
                                    div1.normalize();
                                    div2.normalize();

                                    j1 = $(div1);
                                    j2 = $(div2);

                                    nextHtml = j1.html();

                                    str1 = $.trim(
                                        j1
                                            .text()
                                            .replace(/\s{1,}/gi, "")
                                            .replace(
                                                /[\u200B-\u200D\uFEFF]/g,
                                                ""
                                            )
                                            .replace(/\t{1,}/g, "")
                                            .replace(/(?:\r\n|\r|\n)/gi, "")
                                    );
                                    str2 = $.trim(
                                        j2
                                            .text()
                                            .replace(/\s{1,}/gi, "")
                                            .replace(
                                                /[\u200B-\u200D\uFEFF]/g,
                                                ""
                                            )
                                            .replace(/\t{1,}/g, "")
                                            .replace(/(?:\r\n|\r|\n)/gi, "")
                                    );

                                    hash1 = CryptoJS.MD5(str1);
                                    hash2 = CryptoJS.MD5(str2);

                                    if (hash1.toString() !== hash2.toString()) {
                                        this.bufferSet();
                                        if (null !== window.skydactorBuffer) {
                                            clearTimeout(
                                                window.skydactorBuffer
                                            );
                                            window.skydactorBuffer = null;
                                        }
                                    }
                                }
                            },
                            toolbarFixedBox: true,
                            buttons: buttonList,
                            convertDivs: false,
                            buttonsCustom: buttonsCustomObj,
                            observeLinks: true
                        });
                    }

                    $(".redactor_box").bind("drop", drop);

                    if ($(redactorCaptionClass).length > 0) {
                        $(redactorCaptionClass).redactor({
                            lang: skyword.getLanguage(),
                            plugins: ["skytator", "searchreplace"],
                            initCallback: function() {
                                //Toggles are needed to fix a bug with single characters with formatting being stripped SKY-7288
                                this.toggleCode(true);
                                var editor = this;
                                var timeout = setTimeout(function() {
                                    toggleThatVisual(editor);
                                }, 500);

                                this.$box
                                    .find("> div")
                                    .attr("spellcheck", false)
                                    .attr(
                                        "id",
                                        "redactor-" +
                                            this.$box
                                                .closest(".actualElement")
                                                .attr("id")
                                    );
                                this.$box
                                    .find(".redactor_editor")
                                    .find("p, strong, em, span")
                                    .each(removeInitEmptyPTags);
                            },
                            changeCallback: function(html) {
                                wordCount(html, this, false, true);
                                redactorSave(this);
                            },
                            keyupCallback: function(e) {
                                //skycache.updateCache = true;
                                $(".redactor-search-replace").slideUp(
                                    function() {
                                        $(this).remove();
                                    }
                                );
                                this.sync();
                            },
                            pasteBeforeCallback: function(html) {
                                html = html
                                    .replace(/<mark[^>]*>/g, "")
                                    .replace(/<\/mark>/g, "");
                                this.$box
                                    .find(".redactor_editor")
                                    .find("p, strong, em, span")
                                    .each(removeInitEmptyPTags);
                                return html;
                            },
                            toolbarFixedBox: true,
                            convertDivs: false,
                            buttons: buttonsCaption,
                            buttonsCustom: buttonsCustomObj,
                            observeLinks: true
                        });
                    }

                    function toggleThatVisual(that) {
                        that.toggleVisual();
                    }

                    function cleanWordHtml(html) {
                        html = html.replace(/<!--[\s\S]*?-->/g, "");
                        html = html.replace(
                            /\u2018|\u2019|\u201A|\uFFFD/g,
                            "'"
                        );
                        html = html.replace(/\u201c|\u201d|\u201e/g, '"');
                        html = html.replace(/\u02C6/g, "^");
                        html = html.replace(/\u2039/g, "<");
                        html = html.replace(/\u203A/g, ">");
                        html = html.replace(/\u2013/g, "-");
                        html = html.replace(/\u2026/g, "...");
                        html = html.replace(/\u00BC/g, "1/4");
                        html = html.replace(/\u00BD/g, "1/2");
                        html = html.replace(/\u00BE/g, "3/4");
                        html = html.replace(/[\u02DC|\u00A0]/g, " ");

                        html = $("<div/>")
                            .html(html)
                            .contents()
                            .html();
                        return html;
                    }

                    (function(cursorManager) {
                        var voidNodeTags = [
                            "AREA",
                            "BASE",
                            "BR",
                            "COL",
                            "EMBED",
                            "HR",
                            "IMG",
                            "INPUT",
                            "KEYGEN",
                            "LINK",
                            "MENUITEM",
                            "META",
                            "PARAM",
                            "SOURCE",
                            "TRACK",
                            "WBR",
                            "BASEFONT",
                            "BGSOUND",
                            "FRAME",
                            "ISINDEX"
                        ];

                        Array.prototype.contains = function(obj) {
                            var i = this.length;
                            while (i--) {
                                if (this[i] === obj) {
                                    return true;
                                }
                            }
                            return false;
                        };

                        function canContainText(node) {
                            if (node.nodeType == 1) {
                                //is an element node
                                return !voidNodeTags.contains(node.nodeName);
                            } else {
                                //is not an element node
                                return false;
                            }
                        }

                        function getLastChildElement(el) {
                            var lc = el.lastChild;
                            while (lc.nodeType != 1) {
                                if (lc.previousSibling) lc = lc.previousSibling;
                                else break;
                            }
                            return lc;
                        }

                        cursorManager.setEndOfContenteditable = function(
                            contentEditableElement
                        ) {
                            while (
                                getLastChildElement(contentEditableElement) &&
                                canContainText(
                                    getLastChildElement(contentEditableElement)
                                )
                            ) {
                                contentEditableElement = getLastChildElement(
                                    contentEditableElement
                                );
                            }

                            var range, selection;
                            range = document.createRange(); //Create a range (a range is a like the selection but invisible)
                            range.selectNodeContents(contentEditableElement); //Select the entire contents of the element with the range
                            range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
                            selection = window.getSelection(); //get the selection object (allows you to change selection)
                            selection.removeAllRanges(); //remove any selections already made
                            selection.addRange(range); //make the range you have just created the visible selection
                        };
                    })((window.cursorManager = window.cursorManager || {}));

                    function wordCount(html, object, enableMax, charCount) {
                        var currentWords,
                            currentLength,
                            $box,
                            maxText = "",
                            max,
                            $indicator,
                            $charCount,
                            strHtml;
                        //console.log(html);
                        strHtml =
                            undefined === html || null === html || "" === html
                                ? ""
                                : html;

                        strHtml = strHtml
                            .replace(/<\/p>/gm, "</p> ")
                            .replace(/&nbsp;/g, "");
                        currentWords = !strHtml
                            ? 0
                            : strHtml
                                  .replace(/<(?:.|\n)*?>/gm, "")
                                  .split(/^\s+$/).length === 2
                                ? 0
                                : 2 +
                                  strHtml
                                      .replace(/<(?:.|\n)*?>/gm, "")
                                      .split(/\s+/).length -
                                  html
                                      .replace(/<(?:.|\n)*?>/gm, "")
                                      .split(/^\s+/).length -
                                  strHtml
                                      .replace(/<(?:.|\n)*?>/gm, "")
                                      .split(/\s+$/).length;

                        if (charCount) {
                            currentLength = strHtml
                                .replace(/<(?:.|\n)*?>/gm, "")
                                .replace(/&nbsp;/g, " ").length;
                        } else {
                            currentLength = utf8.encode(
                                strHtml
                                    .replace(/<(?:.|\n)*?>/gm, "")
                                    .replace(/&nbsp;/g, " ")
                            ).length;
                        }

                        $box = object.$box;
                        maxText = "";
                        currentWords = currentWords < 0 ? 0 : currentWords;
                        //console.log($box);
                        //console.log($box.find("textarea"));
                        //console.log($box.find("textarea").attr("maxLength"));
                        max = parseInt(
                            $box.find("textarea").attr("maxLength"),
                            10
                        );

                        $indicator = $(".redactor_footer", $box);
                        $charCount = $(".redactor_char_footer", $box);
                        if (!enableMax && $indicator.size() === 0) {
                            $box.append(
                                $(
                                    "<div class='redactor_footer'> <span class='current'>" +
                                        currentWords +
                                        "</span></div>"
                                )
                            );
                        } else {
                            $(".current", $indicator).text(currentWords);
                        }

                        if (
                            undefined === max ||
                            null === max ||
                            isNaN(max) ||
                            0 === max
                        ) {
                            enableMax = false;
                        }
                        if (enableMax && currentLength > max) {
                            var newMax = max + 1;
                            while (currentLength >= max) {
                                strHtml = cutString(strHtml, newMax);
                                console.log("count is " + currentLength);
                                if (charCount) {
                                    currentLength = strHtml.length;
                                } else {
                                    currentLength = utf8.encode(strHtml).length;
                                }

                                if (currentLength <= max) {
                                    break;
                                }
                                newMax = newMax - 1;
                            }

                            if (paste) {
                                object.set(strHtml);
                            } else {
                                //object.selectionSave();
                                object.set(strHtml);
                                var editableDiv = document.getElementById(
                                    object.$editor.attr("id")
                                );
                                try {
                                    cursorManager.setEndOfContenteditable(
                                        editableDiv
                                    );
                                } catch (e) {
                                    console.log(e);
                                }
                            }
                        }
                        if (enableMax && $charCount.size() === 0) {
                            $box.append(
                                $(
                                    "<div class='redactor_char_footer'> " +
                                        $("#char-remaining").val() +
                                        " <span class='currentChar'>" +
                                        (max - currentLength) +
                                        "</span></div>"
                                )
                            );
                        } else {
                            $(".currentChar", $charCount).text(
                                max - currentLength
                            );
                        }
                    }

                    function cutString(html, max) {
                        return html.substring(0, max - 1);
                    }

                    function drop(e) {
                        var skydactor = e.currentTarget;
                        skydactor = $(skydactor).find("textarea");
                        redactorSave(skydactor.redactor("getObject"));
                    }

                    function removeInitEmptyPTags() {
                        var $this = $(this);
                        //console.log(this.tagName + " " + $.trim($this.html()).replace(/\s|&nbsp;/g, '').length);
                        if (
                            $.trim($this.html()).replace(/\s|&nbsp;/g, "")
                                .length < 1
                        ) {
                            $this.remove();
                        }
                    }
                },
                setRedactorSave: function(givenFunction) {
                    redactorSave = givenFunction;
                },
                setSaveRedactorEditor: function(givenFunction) {
                    saveRedactorEditor = givenFunction;
                },
                init: function() {
                    var $this = this;

                    //onReady
                    $(function() {
                        $this.setupRedactorElements();

                        $("body").attr("spellcheck", false);
                        $this.setupRedactorElements();
                        $this.nonedit();

                        var Selector = {};
                        Selector.getSelected = function() {
                            var t = "";
                            if (window.getSelection) {
                                t = window.getSelection();
                            } else if (document.getSelection) {
                                t = document.getSelection();
                            } else if (document.selection) {
                                t = document.selection.createRange().text;
                            }
                            return t;
                        };
                        Selector.mouseup = function() {
                            var st = $.trim(Selector.getSelected());
                            // if (undefined !== st && null !== st && "" !== st) {
                            //
                            // }
                        };
                        function getSelectionCoords() {
                            var sel = document.selection,
                                range,
                                rect,
                                xx = 0,
                                yy = 0,
                                t = "";
                            if (sel) {
                                if (sel.type != "Control") {
                                    range = sel.createRange();
                                    range.collapse(true);
                                    xx = range.boundingLeft;
                                    yy = range.boundingTop;
                                }
                            } else if (window.getSelection) {
                                sel = window.getSelection();
                                if (sel.rangeCount) {
                                    range = sel.getRangeAt(0).cloneRange();
                                    if (range.getClientRects) {
                                        range.collapse(true);
                                        rect = range.getClientRects()[0];
                                        xx = rect.left;
                                        yy = rect.top;
                                    }
                                }
                            }

                            if (window.getSelection) {
                                t = window.getSelection();
                            } else if (document.getSelection) {
                                t = document.getSelection();
                            } else if (document.selection) {
                                t = document.selection.createRange().text;
                            }
                            return { x: xx, y: yy, text: t };
                        }

                        $("body").on(
                            "mouseup",
                            "div.redactor_editor",
                            null,
                            Selector.mouseup
                        );
                    });
                }
            };
        }
    ]);
})();

app.controller("contributorJoin", [
    "$scope",
    "$translate",
    "setup",
    "contributorJoin",
    "$mdDialog",
    "$timeout",
    "$mdToast",
    "$sce",
    function(
        $scope,
        $translate,
        setup,
        contributorJoin,
        $mdDialog,
        $timeout,
        $mdToast,
        $sce
    ) {
        $scope.data = {
            selectedIndex: 0
        };

        $(document).ready(function() {
            $scope.contributorAgreementInit();
            $(document).on("keyup", "#secretkey", function() {
                $scope.userSignup.confirmPassword = "";
            });

            setTimeout(function() {
                $("md-ink-bar").css("display", "block");
            }, 300);

            $(document).on("blur", "#secretkey", function() {
                var tip = $("#secretKeyTip");

                $(tip)
                    .qtip({
                        hide: {
                            ready: true,
                            delay: 500,
                            fixed: true,
                            leave: false
                        }
                    })
                    .qtip({
                        content: {
                            text: $(tip).attr("title"),
                            title: $(tip).data("titlebar")
                        },
                        position: {
                            at: $(tip).data("at"),
                            my: $(tip).data("my")
                        },
                        show: {
                            event: "mouseenter",
                            solo: false,
                            fixed: true,
                            delay: 300
                        },
                        hide: {
                            event: "mouseleave",
                            delay: 1000,
                            leave: false
                        },
                        style: {
                            width: $(tip).data("width"),
                            height: $(tip).data("height"),
                            classes:
                                "qtip-wiki qtip-light qtip-shadow qtip-nolist qtip-left-border",
                            tip: {
                                width: 15,
                                border: 1,
                                height: 10
                            }
                        }
                    });
            });

            $(document).on("focus", "#secretkey", function() {
                var tip = $("#secretKeyTip");
                $(tip).qtip({
                    content: {
                        text: $(tip).attr("title"),
                        title: $(tip).data("titlebar")
                    },
                    position: {
                        at: $(tip).data("at"),
                        my: $(tip).data("my")
                    },
                    show: {
                        solo: false,
                        ready: true,
                        fixed: true
                    },
                    style: {
                        width: $(tip).data("width"),
                        height: $(tip).data("height"),
                        classes:
                            "qtip-wiki qtip-light qtip-shadow qtip-nolist qtip-left-border",
                        tip: {
                            width: 15,
                            border: 1,
                            height: 10
                        }
                    }
                });
            });
        });

        $scope.loc = {};
        $scope.validLocation = false;
        $scope.locationBound = null;
        $scope.contributorAgreementSigned = false;
        $scope.privacyPolicySigned = false;
        $scope.$watch("loc", function(newValue, oldValue) {
            $scope.user.location = newValue;
        });

        $scope.user = {
            accountId: null,
            firstName: null,
            lastName: null,
            country: null,
            location: {},
            bio: null,
            linkedIn: "",
            portfolioWebsite: "",
            socialAccounts: [],
            professionalAccounts: []
        };

        $scope.userSignup = {
            username: null,
            userPassword: null,
            confirmPassword: null,
            captcha: null,
            creativeTypes: {
                writer: false,
                videographer: false,
                photographer: false,
                designer: false
            }
        };

        $scope.toolTip = {
            password: "",
            country: "",
            zip: "",
            bio: ""
        };

        $scope.currentValues = {
            socialNetworkType: null,
            socialNetworkURL: "",
            professionalNetworkType: null,
            professionalNetworkURL: ""
        };

        $scope.socialNetworks = {};
        $scope.socialNetworks.original = [];
        $scope.socialNetworks.available = [];

        $scope.removeAvailableSocialNetwork = function() {
            var index = $scope.socialNetworks.available.indexOf(
                $scope.currentValues.socialNetworkType
            );
            $scope.socialNetworks.available.splice(index, 1);
        };

        $scope.addSocialNetwork = function() {
            $scope.user.socialAccounts.push({
                id: $scope.currentValues.socialNetworkType.id,
                name: $scope.currentValues.socialNetworkType.name,
                url: $scope.currentValues.socialNetworkURL,
                displayOrder:
                    $scope.currentValues.socialNetworkType.displayOrder
            });
            $scope.removeAvailableSocialNetwork();
            $scope.clearSocialFields();
        };

        $scope.addProfessionalNetwork = function() {
            $scope.user.professionalAccounts.push({
                id: $scope.currentValues.professionalNetworkType.id,
                name: $scope.currentValues.professionalNetworkType.name,
                url: $scope.currentValues.professionalNetworkURL
            });
            $scope.clearProfessionalFields();
        };

        $scope.removeSocialNetwork = function(ntwk) {
            var index = $scope.user.socialAccounts.indexOf(ntwk);
            $scope.user.socialAccounts.splice(index, 1);
            $scope.addAvailableSocialNetwork(ntwk);
            $scope.clearSocialFields();
        };

        $scope.removeProfessionalNetwork = function(ntwk) {
            var index = $scope.user.professionalAccounts.indexOf(ntwk);
            $scope.user.professionalAccounts.splice(index, 1);
            $scope.clearProfessionalFields();
        };

        $scope.addAvailableSocialNetwork = function(ntwk) {
            ntwk.url = null;
            $scope.socialNetworks.available.push(ntwk);
            $timeout(function() {
                $("select.sel-social").selectBoxIt("refresh");
            }, 500);
        };

        $scope.clearSocialFields = function() {
            $scope.currentValues.socialNetworkType = null;
            $scope.currentValues.socialNetworkURL = "";
        };

        $scope.clearProfessionalFields = function() {
            $scope.currentValues.professionalNetworkType = null;
            $scope.currentValues.professionalNetworkURL = "";
        };

        $scope.socialLinkValid = function() {
            if (
                !(
                    $scope.currentValues.socialNetworkType &&
                    $scope.currentValues.socialNetworkType != null
                )
            ) {
                return false;
            }
            if (
                !(
                    $scope.currentValues.socialNetworkURL &&
                    $scope.currentValues.socialNetworkURL != ""
                )
            ) {
                return false;
            } else {
                var patt = new RegExp("^" + $scope.urlFormat + "$", "i");
                if (!patt.test($scope.currentValues.socialNetworkURL)) {
                    return false;
                }
            }
            return true;
        };

        $scope.professionalLinkValid = function() {
            if (
                !(
                    $scope.currentValues.professionalNetworkType &&
                    $scope.currentValues.professionalNetworkType != null
                )
            ) {
                return false;
            }
            if (
                !(
                    $scope.currentValues.professionalNetworkURL &&
                    $scope.currentValues.professionalNetworkURL != ""
                )
            ) {
                return false;
            } else {
                var patt = new RegExp("^" + $scope.urlFormat + "$", "i");
                if (!patt.test($scope.currentValues.professionalNetworkURL)) {
                    return false;
                }
            }
            return true;
        };

        $scope.emailFormat = /^[\w-']+(\.[\w-']+)*@[a-z0-9\-]+\.[a-z.]{2,5}$/i;

        $scope.urlFormat =
            "(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]{0,}\\.[a-z]{2,}(/[-\\w@\\+\\.~#\\?&/=%]*)?";

        $scope.handleUrlValidation = function(url) {
            var patt = new RegExp("^" + $scope.urlFormat + "$", "i");
            return patt.test(url);
        };

        $scope.removeDisabledAttr = function() {
            if (
                ($scope.handleUrlValidation($scope.user.linkedIn) ||
                    !$scope.user.linkedIn) &&
                ($scope.handleUrlValidation($scope.user.portfolioWebsite) ||
                    !$scope.user.portfolioWebsite)
            ) {
                $(".continueJoinBtn").removeAttr("disabled");
            }
        };

        $scope.disableContinueBtn = function($event) {
            var currentValue = $event.currentTarget.value;
            if (!$scope.handleUrlValidation(currentValue)) {
                $(".continueJoinBtn").attr("disabled", "disabled");
            }
            $scope.removeDisabledAttr();
        };

        $scope.addLinkedInPortfolioValues = function($event) {
            var currentTarget = $event.currentTarget.value;
            var currentTargetId = $event.currentTarget.id;
            if (currentTargetId === "linkIn") {
                $scope.user.linkedIn = currentTarget;
            } else if (currentTargetId === "portfolio") {
                $scope.user.portfolioWebsite = currentTarget;
            }
            $scope.removeDisabledAttr();
        };

        $scope.enableAgreementCheckbox = function(checkboxId, agreementType) {
            var agreementCheckbox = document.getElementById(checkboxId);
            $(agreementType).scroll(function() {
                if (
                    $(this).scrollTop() + 50 >=
                    $(this)[0].scrollHeight - $(this).height()
                ) {
                    $(agreementCheckbox)
                        .removeAttr("disabled")
                        .removeClass("disabled");
                }
            });
        };

        $scope.next = async function() {
            $("html, body").animate({ scrollTop: 0 }, "slow");
            if ($scope.data.selectedIndex === 0) {
                $scope.enableAgreementCheckbox(
                    "contributorAgreementCheckbox",
                    "#acct-contributor-agreement"
                );
                $scope.enableAgreementCheckbox(
                    "privacyPolicyCheckbox",
                    "#acct-privacy-policy"
                );
            } else if ($scope.data.selectedIndex === 1) {
                var indicator = $(
                    "#fs-email .field-validation-indicator .validation-indicator"
                )
                    .removeClass("valid")
                    .removeClass("invalid")
                    .removeClass("icon-MessageFailed")
                    .removeClass("icon-MessageSuccess");
                var usernameData = await contributorJoin.validateUsername(
                    $scope.userSignup
                );

                indicator.addClass(
                    usernameData.data
                        ? "icon-MessageSuccess valid"
                        : "icon-MessageFailed invalid"
                );
                if (!usernameData.data) {
                    $translate(
                        "angular.contributorjoin.error.emailexists"
                    ).then(function(txt) {
                        $scope.emailError = txt;
                    });
                } else {
                    $scope.emailError = "";
                }
                if (usernameData.data) {
                    var createPromise = contributorJoin.createAccount(
                        $scope.userSignup
                    );
                    createPromise.then(function(result) {
                        $scope.user.accountId = result.data;
                        $scope.userSignup = null;
                    });
                    $("select#country").selectBoxIt("refresh");
                } else {
                    $scope.data.selectedIndex = 1;
                    return false;
                }
            } else if ($scope.data.selectedIndex === 2) {
                if ($scope.aboutYouPartTwoValid()) {
                    var aboutYouPromise = contributorJoin.aboutYou($scope.user);
                    aboutYouPromise.then(function(result) {});
                }
            } else if ($scope.data.selectedIndex === 3) {
                if ($scope.user.linkedIn) {
                    $scope.user.socialAccounts.push({
                        id: 5,
                        name: "LinkedIn",
                        url: $scope.user.linkedIn
                    });
                }
                if ($scope.user.portfolioWebsite) {
                    $scope.user.professionalAccounts.push({
                        id: 3,
                        name: "Portfolio",
                        url: $scope.user.portfolioWebsite
                    });
                }
                var socialPromise = contributorJoin.socialNetworks($scope.user);
                socialPromise.then(function(result) {
                    if ($scope.user.portfolioWebsite) {
                        if ($scope.user.professionalAccounts.length <= 1) {
                            $("#professionalAccountList").remove();
                        } else {
                            $(
                                "#professionalAccountList li:first-child"
                            ).remove();
                        }
                    }
                    window.location.href = $("#endingUrl").val();
                });
            }

            if (
                $scope.aboutYouPartOneValid() &&
                !$scope.aboutYouPartTwoValid()
            ) {
                // go to second about you page
                $scope.ctrl.selectedTab = 1;
            } else {
                $scope.data.selectedIndex = Math.min(
                    $scope.data.selectedIndex + 1,
                    3
                );
            }
        };

        $scope.previous = function() {
            $scope.data.selectedIndex = Math.max(
                $scope.data.selectedIndex - 1,
                0
            );
        };

        $scope.countriesInit = function() {
            var countriesPromise = contributorJoin.getCountries();
            countriesPromise.then(function(result) {
                $scope.countriesList = result.data;
                $scope.renderTooltips();
                $timeout(function() {
                    $("select#country").selectBoxIt("refresh");
                }, 500);
            });
        };

        $scope.socialTypeInit = function() {
            var socialTypesPromise = contributorJoin.getSocialTypes();
            socialTypesPromise.then(function(result) {
                $scope.socialNetworks.original = result.data;
                $scope.socialNetworks.available = angular.copy(
                    $scope.socialNetworks.original
                );
                $timeout(function() {
                    $("select#socialtype").selectBoxIt("refresh");
                }, 500);
            });
        };

        $scope.contributorAgreementInit = function() {
            var contributorAgreementPromise = contributorJoin.getContributorAgreement();
            contributorAgreementPromise.then(function(result) {
                $scope.contributorAgreement = result.data;
            });
        };

        $scope.to_trusted = function(html_code) {
            return $sce.trustAsHtml(html_code);
        };

        $scope.professionalTypeInit = function() {
            var professionalTypesPromise = contributorJoin.getProfessionalTypes();
            professionalTypesPromise.then(function(result) {
                $scope.professionalNetworks = result.data;
                $timeout(function() {
                    $("select#professionaltype").selectBoxIt("refresh");
                }, 500);
            });
        };

        $scope.renderTooltips = function() {
            $translate("angular.contributorjoin.tooltip.password").then(
                function(txt) {
                    $scope.toolTip.password = txt;
                    $translate("angular.contributorjoin.tooltip.country").then(
                        function(txt) {
                            $scope.toolTip.country = txt;
                            $translate(
                                "angular.contributorjoin.tooltip.location"
                            ).then(function(txt) {
                                $scope.toolTip.zip = txt;
                                $translate(
                                    "angular.contributorjoin.tooltip.bio"
                                ).then(function(txt) {
                                    $scope.toolTip.bio = txt;
                                    setTimeout(function() {
                                        setup.qtipTooltips();
                                    }, 3000);
                                });
                            });
                        }
                    );
                }
            );
        };

        $scope.someSelected = function(object) {
            var selected = false;
            if (
                $scope.data.selectedIndex == 0 ||
                $scope.data.selectedIndex == 1
            ) {
                angular.forEach(Object.keys(object), function(value, key) {
                    if (object[value] == true) {
                        selected = true;
                    }
                });
            }
            return selected;
        };

        $scope.formValid = function() {
            if (!$scope.ctrl.storyForm) {
                return false;
            }
            switch ($scope.data.selectedIndex) {
                case 0:
                    return $scope.ctrl.storyForm.$valid;
                case 1:
                    return $scope.ctrl.accountForm.$valid;
                case 2:
                    if (
                        $scope.data.selectedIndex == 2 &&
                        $scope.ctrl.selectedTab == 0
                    ) {
                        return $scope.aboutYouPartOneValid();
                    } else {
                        return $scope.aboutYouPartTwoValid();
                    }
                case 3:
                    return $scope.socialFormValid();
                default:
                    return false;
            }
        };

        /*
	// display a reminder to upload a profile pic

	$scope.$watch('[ctrl.aboutForm.fname.$valid, ctrl.aboutForm.lname.$valid, ctrl.aboutForm.country.$valid, ctrl.aboutForm.bio.$valid, ctrl.aboutForm.profilepic.$valid, validLocation]', function () {
		if ($scope.ctrl.aboutForm.fname.$valid
			&& $scope.ctrl.aboutForm.lname.$valid
			&& $scope.ctrl.aboutForm.country.$valid
			&& $scope.ctrl.aboutForm.bio.$valid
			&& $scope.validLocation
			//&& !$scope.ctrl.aboutForm.profilepic.$valid
		) {
			$mdToast.show({
				position : "bottom left",
				hideDelay : 8000,
				parent: $('.right-panel'),
				template : '<md-toast class="">' + '<div class="md-toast-content">' + 'remember to upload a pic!' + '</div>' + '</md-toast>'
			});
		}
	});

*/
        $scope.socialFormValid = function() {
            return (
                $scope.ctrl.socialForm.linkIn.$valid &&
                $scope.ctrl.socialForm.portfolio.$valid
            );
        };

        $scope.aboutYouPartOneValid = function() {
            return (
                $scope.ctrl.aboutForm.fname.$valid &&
                $scope.ctrl.aboutForm.lname.$valid &&
                $scope.ctrl.aboutForm.country.$valid &&
                $scope.validLocation
            );
        };

        $scope.aboutYouPartTwoValid = function() {
            return $scope.ctrl.aboutForm.bio.$valid;
        };

        $scope.errorMessages = {
            alreadyTaken: "",
            pattern: "",
            fourofour: "There was a network error. Please try again later.",
            location: ""
        };
        $scope.emailError = "";
        $scope.locationError = "";

        $translate("angular.contributorjoin.error.emailexists").then(function(
            txt
        ) {
            $scope.errorMessages.alreadyTaken = txt;
            $translate("angular.contributorjoin.error.emailinvalid").then(
                function(txt) {
                    $scope.errorMessages.pattern = txt;
                    $translate("angular.contributorjoin.error.location").then(
                        function(txt) {
                            $scope.errorMessages.location = txt;
                        }
                    );
                }
            );
        });

        $scope.checkUsername = function(username) {
            $scope.emailError = "";
            var indicator = $(
                "#fs-email .field-validation-indicator .validation-indicator"
            )
                .removeClass("valid")
                .removeClass("invalid")
                .removeClass("icon-MessageFailed")
                .removeClass("icon-MessageSuccess");
            if (
                $scope.ctrl.accountForm.uid.$error.pattern &&
                !$scope.ctrl.accountForm.uid.$error.required
            ) {
                indicator.addClass("icon-MessageFailed invalid");
                $scope.emailError = $scope.errorMessages.pattern;
            }
        };

        $scope.checkLocation = function() {
            $scope.locationError = "";
            $timeout(function() {
                if (!$scope.validLocation) {
                    $scope.locationError = $scope.errorMessages.location;
                }
            }, 200);
        };

        /* UPLOAD PROFILE PICTURE */
        $scope.uploadProfilePicture = function(ev) {
            $mdDialog.show({
                controller: "ProfilePictureController",
                templateUrl:
                    "/partials/contributorJoin/picture-upload-overlay.html",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                locals: {
                    accountId: $scope.user.accountId,
                    finalPictureUrl: $scope.finalPictureUrl
                }
            });
        };
        /* END UPLOAD PROFILE PICTURE */
    }
]).filter("reverse", function() {
    return function(items) {
        return items.slice().reverse();
    };
});

app.directive("pwCheck", function() {
    return {
        require: "ngModel",
        link: function(scope, elem, attrs, ctrl) {
            var firstPassword = "#" + attrs.pwCheck;
            elem.add(firstPassword).on("keyup", function() {
                scope.$apply(function() {
                    var v = elem.val() === $(firstPassword).val();
                    ctrl.$setValidity("pwmatch", v);
                });
            });
        }
    };
});

app.directive("pwValidate", function($q, $http) {
    return {
        require: "ngModel",
        link: function(scope, elem, attrs, ctrl) {
            var minMaxLength = /^[\s\S]{8,32}$/;
            var upper = /[A-Z]/;
            var lower = /[a-z]/;
            var number = /[0-9]/;

            var checkPwned = function(passwordToCheck) {
                function passwordOk(password) {
                    var controllerDirectory = "/API/contributor-join/";

                    return $http.post(
                        controllerDirectory + "password-ok/",
                        password
                    );
                }

                var passwordPromise = passwordOk(passwordToCheck);

                return passwordPromise;
            };

            ctrl.$asyncValidators.passwordAcceptable = function(
                modelValue,
                viewValue
            ) {
                if (ctrl.$isEmpty(modelValue)) {
                    // consider empty models to be valid
                    return $q.when();
                }

                var promise = $q.defer();

                if (
                    viewValue.length &&
                    minMaxLength.test(viewValue) &&
                    upper.test(viewValue) &&
                    lower.test(viewValue) &&
                    number.test(viewValue)
                ) {
                    checkPwned(viewValue).then(
                        function(ok) {
                            promise.resolve();
                        },
                        function(notOk) {
                            promise.reject();
                        }
                    );
                } else {
                    promise.reject();
                }

                return promise.promise;
            };
        }
    };
});

app.service("compensation", [
    "skyword",
    "writers",
    function(skyword, writers) {
        return {
            init: function() {
                var body = $("body");

                //onReady
                $(function() {
                    body.on("change", ".compensationTypes", function() {
                        var curRadio = $(this),
                            showCompensation = $(
                                "#existing-compensation"
                            ).val(),
                            customCompensation = $(".customCompensation");
                        if (
                            curRadio.is(":checked") &&
                            curRadio.attr("id") !== "custom-compensation"
                        ) {
                            if (showCompensation === "false") {
                                customCompensation.hide();
                            } else {
                                customCompensation
                                    .addClass("disabled")
                                    .attr("disabled", "disabled");
                                customCompensation
                                    .find(
                                        "input[type='checkbox'], input[type='text']"
                                    )
                                    .addClass("disabled")
                                    .attr("disabled", "disabled");
                            }
                        } else {
                            customCompensation
                                .show()
                                .removeClass("disabled")
                                .removeAttr("disabled");
                            customCompensation
                                .find(
                                    "input[type='checkbox'], input[type='text']"
                                )
                                .removeClass("disabled")
                                .removeAttr("disabled");
                            $(".categoryList")
                                .parent()
                                .each(function() {
                                    var inputs = $(this).find(
                                        "input[type='checkbox']"
                                    );
                                    writers.checkContentTypePayrate(inputs);
                                });
                        }
                    });

                    body.on("change", ".enableCompensation", function() {
                        var that = $(this);
                        if (that.is(":checked")) {
                            that.closest("tr")
                                .find(".customApprovalAmount")
                                .show();
                        } else {
                            that.closest("tr")
                                .find(".customApprovalAmount")
                                .hide();
                        }
                    });
                });
            }
        };
    }
]);

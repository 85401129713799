(function() {
    app.directive("platformModal", [
        "$cookies",
        "skyword",
        function($cookies, skyword) {
            return {
                link: function(scope, element, attrs) {
                    function getAnnouncementModalCookie() {
                        // Retrieving cookie
                        var announcementModalCookie =
                            $cookies.announcementModalCookie;
                        return announcementModalCookie;
                    }

                    function setAnnouncementModalCookie(bool) {
                        //we are getting an invalid date format from backend - 5/31/17 11:00:00 PM.000
                        var announcementStopTime = $("#announcementStopTime")
                            .val()
                            .split(".")[0];
                        var date = new Date(announcementStopTime);
                        skyword.setCookie(
                            "announcementModalCookie",
                            true,
                            date,
                            true
                        );
                        sessionStorage.setItem("platformMessageShown", true)
                    }

                    function announcementModal() {
                        $.colorbox({
                            height: "auto",
                            width: "80%",
                            href: attrs.partial,
                            html: element[0].innerHTML
                        }); // colorbox

                        $("#cboxLoadedContent").addClass(
                            "platformModalOverlay"
                        );

                        // Update cookie when modal is acknowledged
                        $("#cboxClose").on("click", function() {
                            setAnnouncementModalCookie(true);
                        });
                        $("#cboxOverlay").on("click", function() {
                            setAnnouncementModalCookie(true);
                        });
                    }

                    $(window).load(function() {
                        const isPlatformMessageShown = sessionStorage
                        .getItem("platformMessageShown")
                        ;
                         if (isPlatformMessageShown === "false" || isPlatformMessageShown === null) {
                            announcementModal();
                        }
                    }); // element
                } // link
            }; //return
        }
    ]); // directive
    /* --- / 2.0 --- */
})();

(function() {
    app.service("admin", [
        "skyword",
        "validation",
        "validationUtil",
        "call",
        "overlay",
        "requestActive",
        "$http",
        function(
            skyword,
            validation,
            validationUtil,
            call,
            overlay,
            requestActive,
            $http
        ) {
            return {
                getCustomStates: function(programId, callback) {
                    $http({
                        url: "/API/admin/program/" + programId + "/states",
                        method: "GET"
                    })
                        .success(function(data, status, headers, config) {
                            callback(data);
                        })
                        .error(function(data, status, headers, config) {});
                },

                getCustomRoles: function(programId, callback) {
                    $http({
                        url:
                            "/API/admin/program/" +
                            programId +
                            "/account-types",
                        method: "GET"
                    })
                        .success(function(data, status, headers, config) {
                            callback(data);
                        })
                        .error(function(data, status, headers, config) {});
                },

                saveCustomState: function(state, callback) {
                    $http({
                        url:
                            "/API/admin/program/" + state.programId + "/states",
                        method: "PUT",
                        data: {
                            id: state.id,
                            stateId: state.stateId,
                            programId: state.programId,
                            customButtonText: state.customButtonText,
                            customStateName: state.customStateName
                        },
                        cache: false,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                        .success(function(data, status, headers, config) {
                            callback(data);
                        })
                        .error(function(data, status, headers, config) {
                            console.log(data);
                        });
                },

                saveCustomRole: function(role, callback) {
                    $http({
                        url:
                            "/API/admin/program/" +
                            role.programId +
                            "/account-types",
                        method: "PUT",
                        data: {
                            id: role.id,
                            programId: role.programId,
                            accountTypeId: role.accountTypeId,
                            customName: role.customName
                        },
                        cache: false,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                        .success(function(data, status, headers, config) {
                            callback(data);
                        })
                        .error(function(data, status, headers, config) {
                            console.log(data);
                        });
                },

                deleteCustomRole: function(role, callback) {
                    $http({
                        url:
                            "/API/admin/program/" +
                            role.programId +
                            "/account-types/" +
                            role.id,
                        method: "DELETE",
                        data: {},
                        cache: false,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                        .success(function(data, status, headers, config) {
                            callback(data);
                        })
                        .error(function(data, status, headers, config) {
                            console.log(data);
                        });
                },
                deleteCustomState: function(state, callback) {
                    $http({
                        url:
                            "/API/admin/program/" +
                            state.programId +
                            "/states/" +
                            state.id,
                        method: "DELETE",
                        data: {},
                        cache: false,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                        .success(function(data, status, headers, config) {
                            callback(data);
                        })
                        .error(function(data, status, headers, config) {
                            console.log(data);
                        });
                },

                getWorkflowLibrary: function(programId, libraryId, callback) {
                    $http({
                        url:
                            "/API/admin/program/" +
                            programId +
                            "/workflow-library/" +
                            libraryId,
                        method: "GET"
                    })
                        .success(function(data, status, headers, config) {
                            callback(data);
                        })
                        .error(function(data, status, headers, config) {});
                },

                saveWorkflowLibrary: function(programId, library, callback) {
                    $http({
                        url:
                            "/API/admin/program/" +
                            programId +
                            "/workflow-library",
                        method: "PUT",
                        data: {
                            id: library.id,
                            libraryName: library.libraryName,
                            dateCreated: library.dateCreated,
                            removed: library.removed,
                            isDamLibrary: library.isDamLibrary
                        },
                        cache: false,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                        .success(function(data, status, headers, config) {
                            callback(data);
                        })
                        .error(function(data, status, headers, config) {
                            console.log(data);
                        });
                },
                updateEnableSubmitOverride: function(
                    programId,
                    workflowLibraryId,
                    enableSubmitOverride,
                    callback
                ) {
                    $http({
                        url:
                            "/API/admin/program/" +
                            programId +
                            "/workflow-library/updateEnableSubmitOverride",
                        method: "PUT",
                        data: {
                            id: workflowLibraryId,
                            enableSubmitOverride: enableSubmitOverride
                        },
                        cache: false,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                        .success(function(data, status, headers, config) {
                            callback(data);
                        })
                        .error(function(data, status, headers, config) {
                            console.log(data);
                        });
                },

                init: function() {
                    app.value("targetAccountId", 0);
                    var request,
                        updateCurrentStates = function(
                            elem,
                            accountTypeIdElem,
                            progIdElem
                        ) {
                            if (requestActive) {
                                return;
                            }
                            app.value("requestActive", true);
                            var accTypeId = $("#" + accountTypeIdElem).val(),
                                progId = $("#" + progIdElem).val(),
                                urlAction =
                                    "adminUpdateCurrentStatesAjax.action",
                                options = {
                                    cache: false,
                                    type: "get",
                                    data: {
                                        selectedAccountTypeId: accTypeId,
                                        programId: progId
                                    },
                                    url: urlAction,
                                    success: function(data) {
                                        $("#" + elem).html(data);
                                        app.value("requestActive", false);
                                    },
                                    error: function(data, status, err) {
                                        var msg =
                                            "Error getting: " +
                                            urlAction +
                                            "\n\n";
                                        msg += "Status: " + status + "\n\n";
                                        console.log(msg);
                                    }
                                };
                            call.ajax(urlAction, options);
                        },
                        setMoveButtons = function(curContainer) {
                            var prevRow = curContainer.prev(),
                                nextRow = curContainer.next(),
                                asc = curContainer.find(".asc.element"),
                                desc = curContainer.find(".desc.element");
                            if (nextRow.length > 0) {
                                desc.html(
                                    '<span class="icon-Descending left clearNone pointer element"></span>'
                                );
                            } else {
                                desc.html(
                                    '<span class="icon-None left clearNone" style="width:1.25em;"></span>'
                                );
                            }
                            if (prevRow.length > 0) {
                                asc.html(
                                    '<span class="icon-Ascending left clearNone pointer element"></span>'
                                );
                            } else {
                                asc.html(
                                    '<span class="icon-None left clearNone" style="width:1.25em;"></span>'
                                );
                            }
                        },
                        ascElement = function() {
                            var curContainer = $(this)
                                    .parent()
                                    .parent()
                                    .parent(),
                                prevContainer = $(this)
                                    .parent()
                                    .parent()
                                    .parent()
                                    .prev(),
                                curId = curContainer.find("#element-id").val(),
                                adjId = prevContainer.find("#element-id").val(),
                                formParams = {
                                    curId: curId,
                                    adjId: adjId
                                },
                                options = {
                                    cache: false,
                                    type: "POST",
                                    url:
                                        "updateContentTemplateElementOrderAjax.action",
                                    data: formParams,
                                    dataType: "html",
                                    success: function(data) {
                                        app.value("requestActive", false);
                                        curContainer.fadeOut("fast");
                                        curContainer
                                            .prev()
                                            .before(curContainer);
                                        setMoveButtons(curContainer);
                                        setMoveButtons(prevContainer);
                                        curContainer.fadeIn("slow");
                                    }
                                };
                            call.ajax(
                                "updateContentTemplateElementOrderAjax.action",
                                options
                            );
                        },
                        descElement = function() {
                            var curContainer = $(this)
                                    .parent()
                                    .parent()
                                    .parent(),
                                nextContainer = $(this)
                                    .parent()
                                    .parent()
                                    .parent()
                                    .next(),
                                curId = curContainer.find("#element-id").val(),
                                adjId = nextContainer.find("#element-id").val(),
                                formParams = {
                                    curId: curId,
                                    adjId: adjId
                                },
                                options = {
                                    cache: false,
                                    type: "POST",
                                    url:
                                        "updateContentTemplateElementOrderAjax.action",
                                    data: formParams,
                                    dataType: "html",
                                    success: function(data) {
                                        app.value("requestActive", false);
                                        curContainer.fadeOut("fast");
                                        curContainer.next().after(curContainer);
                                        setMoveButtons(curContainer);
                                        setMoveButtons(nextContainer);
                                        curContainer.fadeIn("slow");
                                    }
                                };
                            call.ajax(
                                "updateContentTemplateElementOrderAjax.action",
                                options
                            );
                        },
                        toggleButtons = function() {
                            var val = $("#selectedAccount").val();
                            console.log(val);
                            if (val === -1 || val === null) {
                                $(".loginActions").attr("disabled", "disabled");
                                $(".loginActions").addClass("disabled");
                            } else {
                                $(".loginActions").removeAttr("disabled");
                                $(".loginActions").removeClass("disabled");
                            }
                        },
                        toggleWorkflowNextButton = function() {
                            var selected = $("#selectedAccountTypeId").val(),
                                current = $("#currentStateId").val();
                            console.log("sati: " + selected);
                            console.log("currStateId: " + current);
                            if (
                                selected === -1 ||
                                current === null ||
                                current === -1
                            ) {
                                $("#add-workflow-currentstate").attr(
                                    "disabled",
                                    "disabled"
                                );
                                $("#add-workflow-currentstate").addClass(
                                    "disabled"
                                );
                            } else {
                                $("#add-workflow-currentstate").removeAttr(
                                    "disabled"
                                );
                                $("#add-workflow-currentstate").removeClass(
                                    "disabled"
                                );
                            }
                        },
                        adminBackPopulate = function() {
                            var btn = $(this),
                                cId = parseInt($("#selectedAccount").val(), 10),
                                pId = parseInt($("#selectedProgram").val(), 10),
                                urlAction = "adminBackPopulateAjax.action",
                                backPopulated = $("#back-populated"),
                                opts = {
                                    selectedProgramId: pId,
                                    contributorId: cId
                                },
                                options = {
                                    cache: false,
                                    type: "POST",
                                    data: opts,
                                    dataType: "html",
                                    url: urlAction,
                                    success: function(data) {
                                        backPopulated.html(data);
                                        btn.removeClass("disabled").removeAttr(
                                            "disabled"
                                        );
                                    },
                                    error: function(data, status, err) {
                                        console.log([data, status, err]);
                                        btn.removeClass("disabled").removeAttr(
                                            "disabled"
                                        );
                                    }
                                };
                            call.ajax(urlAction, options);
                            return false;
                        },
                        recruitingTools = function() {
                            var allowed =
                                $(this)
                                    .val()
                                    .indexOf(
                                        "@skyword.com",
                                        $(this).val().length -
                                            "@skyword.com".length
                                    ) !== -1;

                            if (allowed) {
                                $("li.recruitingTools").show();
                                $("p.recruitingTools").show();
                            } else {
                                $("li.recruitingTools").hide();
                                $("p.recruitingTools").hide();
                            }
                        },
                        showHideCardinality = function() {
                            var input = $(this),
                                el = $(".showHideCardinality");

                            if (input.is(":checked")) {
                                el.hide();
                            } else {
                                el.show();
                            }

                            return false;
                        },
                        contentTemplateAddCreateNew = function() {
                            $("#copy-content-template").append(
                                '<option value="">Create New</option>'
                            );
                        };

                    function enableContributorKeyword() {
                        var t = $("#proposeAssignment"),
                            val,
                            li,
                            checkbox;

                        if (0 === t.length) {
                            return;
                        }
                        var CANNOT_SUBMIT = 0;
                        var CAN_SUBMIT = 1;
                        var CAN_SUBMIT_AND_CLAIM = 2;
                        val = parseInt(t.val(), 10);
                        li = $("#enableContributorKeywordLi");
                        checkbox = $("#enableContributorKeyword");
                        if (
                            CANNOT_SUBMIT === val ||
                            CAN_SUBMIT_AND_CLAIM === val
                        ) {
                            li.hide();
                            if (CAN_SUBMIT_AND_CLAIM === val) {
                                checkbox.prop("checked", true);
                            } else {
                                checkbox.prop("checked", false);
                            }
                        } else {
                            li.show();
                        }
                    }

                    //onReady
                    $(function() {
                        var html = $("html"),
                            body = $("body");
                        /*
                             * adminDashboard.jsp
                             */

                        if (1 === parseInt($("#proposeAssignment").val(), 10)) {
                            $("#enableContributorKeywordLi").show();
                            $("#enableContributorKeyword").prop(
                                "checked",
                                $("#enableContributorKeyword").checked
                            );
                        }

                        $("#selectedAccountTypeId").change(function() {
                            updateCurrentStates(
                                "currentStatesDiv",
                                "selectedAccountTypeId",
                                "programId"
                            );
                            toggleWorkflowNextButton();
                        });

                        body.on("change", "#currentStateId", function() {
                            toggleWorkflowNextButton();
                        });

                        body.on(
                            "change",
                            "#proposeAssignment",
                            enableContributorKeyword
                        );

                        //enableContributorKeyword();

                        $("#add-workflow-currentstate").click(function() {
                            window.location.href =
                                "/adminEditWorkflow.action?programId=" +
                                $("#programId").val() +
                                "&selectedAccountTypeId=" +
                                $("#selectedAccountTypeId").val() +
                                "&currentStateId=" +
                                $("#currentStateId").val();
                        });

                        $("#selectedProgram, #selectedAccountType").change(
                            function() {
                                updateAccounts(
                                    "accountsDiv",
                                    "selectedAccountType",
                                    "selectedProgram"
                                );
                                toggleButtons();
                            }
                        );

                        $("#selectedAccount")
                            .change(function() {
                                if ($(this).val() === -1) {
                                    $(".loginActions").attr(
                                        "disabled",
                                        "disabled"
                                    );
                                    $(".loginActions").addClass("disabled");
                                } else {
                                    $(".loginActions").removeAttr("disabled");
                                    $(".loginActions").removeClass("disabled");
                                }
                            })
                            .trigger("change");

                        $("#selectedAccount")
                            .change(function() {
                                if ($(this).val() === -1) {
                                    $(".loginActions").attr(
                                        "disabled",
                                        "disabled"
                                    );
                                    $(".loginActions").addClass("disabled");
                                } else {
                                    $(".loginActions").removeAttr("disabled");
                                    $(".loginActions").removeClass("disabled");
                                }
                            })
                            .trigger("change");

                        $("#delete-role").click(function() {
                            var performDelete = confirm(
                                "Are you sure you want to delete this role?"
                            );
                            if (performDelete) {
                                window.location.href = $(this).attr("href");
                            }
                            return false;
                        });

                        html.on("click", "#btn-crop-overlay", null, function() {
                            var forumSubmit = $("#formSubmit"),
                                urlAction = forumSubmit.attr("action"),
                                getPost = forumSubmit.attr("method"),
                                options = {
                                    cache: false,
                                    type: getPost,
                                    data: forumSubmit.serialize(),
                                    url: urlAction,
                                    success: function(response) {
                                        if (!validation.parse(response)) {
                                            $(".uploader .manage")
                                                .show()
                                                .html(response);
                                            $(".uploader .upload").hide();
                                            overlay.init();
                                            $(this).colorbox.close();
                                        } else {
                                            msg +=
                                                json.errorMsg +
                                                "<br/><br/>" +
                                                $(
                                                    "#file-ext-" +
                                                        json.attachmentDefinitionId
                                                )
                                                    .val()
                                                    .replace(",", ", ") +
                                                "<br/><br/>";
                                            $(this)
                                                .find(".uploadifyError")
                                                .html(msg);
                                        }
                                    },
                                    error: function(data, status, err) {
                                        var msg =
                                            "Error getting: " +
                                            urlAction +
                                            "\n\n";
                                        msg += "Status: " + status + "\n\n";
                                        // msg += "Parameters: " + JSON.stringify(parameters) + "\n\n";
                                        console.log(msg);
                                    }
                                };
                            $("#crop-container").hide();
                            $("#btn-crop-overlay").hide();
                            $("#prepare-container").show();
                            $(this).colorbox.resize();
                            call.ajax(urlAction, options);
                            return false;
                        });

                        html.on("click", "#btn-banner-remove", function() {
                            var urlAction = $(this).attr("href"),
                                options = {
                                    cache: false,
                                    type: "POST",
                                    url: urlAction,
                                    success: function(response) {
                                        $(".uploader .upload").show();
                                        $(".uploader .manage").hide();
                                        setup.htmlUpload();
                                    }
                                };
                            call.ajax(urlAction, options);

                            return false;
                        });

                        $(".confirmTemplateDelete").click(function() {
                            var performDelete = confirm(
                                "Deleting an article template will result in article content not being accessible in the system.\n\nThis cannot be undone. Seriously, there is no undo."
                            );
                            if (performDelete) {
                                window.location.href = $(this).attr("href");
                            }
                            return false;
                        });

                        // $('#link-unlock-workflow').click(function() {
                        //     var urlAction = "adminUnlockWorkflowAjax.action?"+$(this).data('params');
                        //     return colorboxAjax.call(urlAction, null, 600, null);
                        // });

                        $(".confirmContainerDelete").click(function() {
                            var performDelete = confirm(
                                "Removing a container will remove all the elements contained in this container."
                            );
                            if (performDelete) {
                                window.location.href = $(this).attr("href");
                            }
                            return false;
                        });
                        $(".confirmContainerEnable").click(function() {
                            window.location.href = $(this).attr("href");
                            return false;
                        });

                        $(".confirmElementDelete").click(function() {
                            var el = $(this),
                                elementDisplayName = el
                                    .parent()
                                    .find(".elementDisplayName")
                                    .val();
                            var performDelete = el.hasClass("elementDisable")
                                ? confirm(
                                      "Are you sure you want to disable " +
                                          elementDisplayName +
                                          "?"
                                  )
                                : confirm(
                                      "Are you sure you want to enable " +
                                          elementDisplayName +
                                          "?"
                                  );
                            if (performDelete) {
                                window.location.href = $(this).attr("href");
                            }
                            return false;
                        });

                        /*
                             adminEditAccount.jsp
                             */

                        $("#change-password").click(function() {
                            var urlAction =
                                "/adminChangePasswordAjax.action?targetAccountId=" +
                                document.forms[0].targetAccountId.value;
                            console.log(urlAction);
                            call.overlay(urlAction, null, 600, null);

                            return false;
                        });

                        $("#change-template-date").click(function() {
                            var urlAction = $(this).attr("href");
                            console.log(urlAction);
                            call.overlay(urlAction, null, 400, null);

                            return false;
                        });

                        $("#link-logout-join").click(function() {
                            window.location = "/logout.action";
                            window.open("/join.action");
                            return false;
                        });

                        $("#admin-login").click(function() {
                            window.location.href =
                                "/adminLogin.action?selectedAccountId=" +
                                document.forms[0].targetAccountId.value;
                        });

                        $("#single-use-verification-code").click(function() {
                            $("#adminEditAccountForm #singleUseCode").val(
                                "true"
                            );
                            $("#adminEditAccountForm").submit();
                        });

                        $("#admin-unlock").click(function() {
                            var urlAction = "/adminUnlockAccountAjax.action",
                                getPost = "POST",
                                options,
                                button = $(this);
                            options = {
                                cache: false,
                                type: getPost,
                                data: {
                                    targetAccountId: $("#targetAccountId").val()
                                },
                                url: urlAction,
                                dataType: "json",
                                success: function(data) {
                                    validationUtil.parse(data);
                                    $("#accountStatusId").val(1);
                                    button.hide();
                                }
                            };

                            call.ajax(urlAction, options);
                            return false;
                        });

                        $("#accountTypeId").change(function() {
                            if (this.value === "11") {
                                $("#programBoxes11").removeClass("hidden");
                            } else {
                                $("#programBoxes11").addClass("hidden");
                            }
                            if (this.value === "12") {
                                $("#programBoxes12").removeClass("hidden");
                            } else {
                                $("#programBoxes12").addClass("hidden");
                            }
                        });

                        $("#editAccountBtn").click(function() {
                            var val = $("#accountTypeId").val();
                            if (val === "11") {
                                $("#programBoxes12").remove();
                            } else if (val === "12") {
                                $("#programBoxes11").remove();
                            } else {
                                $("#programBoxes11").remove();
                                $("#programBoxes12").remove();
                            }
                            $("#editAccountForm").submit();
                        });

                        $(".loginActions").attr("disabled", "disabled");
                        $(".loginActions").addClass("disabled");
                        skyword.passwordStrength();

                        contentTemplateAddCreateNew();
                        $("#test-connection").click(function() {
                            var urlAction = "adminTestApiConnectionAjax.action",
                                getPost = "POST",
                                options = {
                                    cache: false,
                                    type: getPost,
                                    data: $("#adminEditApiInfo").serialize(),
                                    url: urlAction,
                                    success: function(data) {
                                        $("#connection-results")
                                            .html(data)
                                            .fadeIn();
                                        skyword.enableButtons();
                                    }
                                };
                            $("#connection-results").fadeOut();
                            $("#connection-results-api").fadeOut();
                            call.ajax(urlAction, options);
                        });
                        $("#test-connection-api-v2").click(function() {
                            var urlAction =
                                    "adminTestApiConnectionApiV2Ajax.action",
                                getPost = "POST",
                                options = {
                                    cache: false,
                                    type: getPost,
                                    data: $("#adminEditApiInfo").serialize(),
                                    url: urlAction,
                                    success: function(data) {
                                        $("#connection-results-api")
                                            .html(data)
                                            .fadeIn();
                                        skyword.enableButtons();
                                    }
                                };
                            $("#connection-results-api").fadeOut();
                            $("#connection-results").fadeOut();
                            call.ajax(urlAction, options);
                        });
                        $("#ci-test-connection-api-v2").click(function() {
                            var urlAction =
                                    "adminContentIngestionTestApiConnectionApiV2Ajax.action",
                                getPost = "POST",
                                options = {
                                    cache: false,
                                    type: getPost,
                                    data: $(
                                        "#adminContentIngestion"
                                    ).serialize(),
                                    url: urlAction,
                                    success: function(data) {
                                        $("#connection-results-api")
                                            .html(data)
                                            .fadeIn();
                                        skyword.enableButtons();
                                        var urlAction =
                                                "adminContentIngestionUpdateTagsAndCategories.action",
                                            getPost = "POST",
                                            options = {
                                                cache: false,
                                                type: getPost,
                                                data: $(
                                                    "#adminContentIngestion"
                                                ).serialize(),
                                                url: urlAction,
                                                async: false,
                                                success: function(data) {
                                                    console.log(data);
                                                    skyword.enableButtons();
                                                    data = JSON.parse(data);
                                                    var cats =
                                                        data["categoriesList"];
                                                    var tags = data["tagsList"];
                                                    function notEmpty(str) {
                                                        return "" != str;
                                                    }
                                                    if (
                                                        null != cats &&
                                                        "" != cats
                                                    )
                                                        $(
                                                            "#ci-category"
                                                        ).autocomplete({
                                                            source: cats
                                                                .split(",")
                                                                .filter(
                                                                    notEmpty
                                                                )
                                                        });
                                                    if (
                                                        null != tags &&
                                                        "" != tags
                                                    ) {
                                                        $(
                                                            "#ci-tag"
                                                        ).autocomplete({
                                                            source: tags
                                                                .split(",")
                                                                .filter(
                                                                    notEmpty
                                                                )
                                                        });
                                                    }
                                                }
                                            };
                                        call.ajax(urlAction, options);
                                    }
                                };
                            $("#connection-results-api").fadeOut();
                            $("#connection-results").fadeOut();
                            call.ajax(urlAction, options);
                        });
                        $(".ci-collapse-filters").click(function() {
                            if (
                                $("#ci-collapse-filters-icon").hasClass(
                                    "icon-collapse"
                                )
                            ) {
                                $("#ci-collapse-filters-icon").removeClass(
                                    "icon-collapse"
                                );
                                $("#ci-collapse-filters-icon").addClass(
                                    "icon-expand"
                                );
                            } else {
                                $("#ci-collapse-filters-icon").removeClass(
                                    "icon-expand"
                                );
                                $("#ci-collapse-filters-icon").addClass(
                                    "icon-collapse"
                                );
                            }
                        });
                        $(".ci-collapse-regex").click(function() {
                            if (
                                $("#ci-collapse-regex-icon").hasClass(
                                    "icon-collapse"
                                )
                            ) {
                                $("#ci-collapse-regex-icon").removeClass(
                                    "icon-collapse"
                                );
                                $("#ci-collapse-regex-icon").addClass(
                                    "icon-expand"
                                );
                            } else {
                                $("#ci-collapse-regex-icon").removeClass(
                                    "icon-expand"
                                );
                                $("#ci-collapse-regex-icon").addClass(
                                    "icon-collapse"
                                );
                            }
                        });
                        $(".ci-collapse-settings").click(function() {
                            if (
                                $("#ci-collapse-settings-icon").hasClass(
                                    "icon-collapse"
                                )
                            ) {
                                $("#ci-collapse-settings-icon").removeClass(
                                    "icon-collapse"
                                );
                                $("#ci-collapse-settings-icon").addClass(
                                    "icon-expand"
                                );
                            } else {
                                $("#ci-collapse-settings-icon").removeClass(
                                    "icon-expand"
                                );
                                $("#ci-collapse-settings-icon").addClass(
                                    "icon-collapse"
                                );
                            }
                        });
                        $("#ci-confirm-ingestion").click(function() {
                            var theData = $(
                                "#adminContentIngestion"
                            ).serializeArray();

                            call.overlay(
                                "contentIngestionConfirmationOverlay.action",
                                theData,
                                800,
                                null,
                                null,
                                null,
                                function() {
                                    skyword.enableButtons();
                                }
                            );
                        });
                        $(".ci-undo-overlay").click(function() {
                            var targetProgramId = $(this).attr(
                                "data-targetprogramid"
                            );
                            var importId = $(this).attr("data-importid");
                            var baseUrl = $(this).attr("data-baseurl");
                            call.overlay(
                                "contentIngestionUndoOverlay.action",
                                {
                                    targetProgramId: targetProgramId,
                                    importId: importId,
                                    baseUrl: baseUrl
                                },
                                800,
                                null,
                                null,
                                null,
                                function() {
                                    skyword.enableButtons();
                                }
                            );
                        });

                        $(".ci-retry-overlay").click(function() {
                            var targetProgramId = $(this).attr(
                                "data-targetprogramid"
                            );
                            var importId = $(this).attr("data-importid");
                            var baseUrl = $(this).attr("data-baseurl");
                            call.overlay(
                                "contentIngestionRetryOverlay.action",
                                {
                                    targetProgramId: targetProgramId,
                                    importId: importId,
                                    baseUrl: baseUrl
                                },
                                800,
                                null,
                                null,
                                null,
                                function() {
                                    skyword.enableButtons();
                                }
                            );
                        });

                        $("#test-saml-connection").click(function() {
                            var urlAction = "ssoTestConnection.action",
                                getPost = "POST",
                                options = {
                                    cache: false,
                                    type: getPost,
                                    data: $("#ssoImportIPSettings").serialize(),
                                    url: urlAction,
                                    success: function(data) {
                                        $("#connection-results")
                                            .html(data)
                                            .fadeIn();
                                        skyword.enableButtons();
                                    }
                                };
                            $("#connection-results").fadeOut();
                            $("#connection-results-api").fadeOut();
                            call.ajax(urlAction, options);
                        });

                        $("#generate-apikey").click(function() {
                            var urlAction = "adminGenerateApiKeyAjax.action",
                                getPost = "POST",
                                options = {
                                    cache: false,
                                    type: getPost,
                                    data: $("#adminEditApiInfo").serialize(),
                                    url: urlAction,
                                    success: function(data) {
                                        $(
                                            "#pullApiKeyDiv, #drupalApiKeyDiv, #publishApiKeyDiv"
                                        ).html(data);
                                        skyword.enableButtons();
                                    }
                                };
                            $("#connection-results").fadeOut();
                            call.ajax(urlAction, options);
                        });
                        $("#generate-publish-apikey").click(function() {
                            var urlAction =
                                    "adminGeneratePublishApiKeyAjax.action",
                                getPost = "POST",
                                options = {
                                    cache: false,
                                    type: getPost,
                                    data: $("#adminEditApiInfo").serialize(),
                                    url: urlAction,
                                    success: function(data) {
                                        if ($("apiKeyDiv").length > 0) {
                                            $("#apiKeyDiv").html(data);
                                        } else {
                                            $("#apiKey").replaceWith(data);
                                        }
                                        skyword.enableButtons();
                                    }
                                };
                            $("#connection-results-api").fadeOut();
                            $("#generate-publish-key-results").fadeOut();
                            call.ajax(urlAction, options);
                        });
                        $("#generate-clientinfo").click(function() {
                            var urlAction =
                                    "adminGenerateClientInfoAjax.action",
                                getPost = "POST",
                                options = {
                                    cache: false,
                                    type: getPost,
                                    data: $("#adminEditApiInfo").serialize(),
                                    url: urlAction,
                                    success: function(data) {
                                        var json = JSON.parse(data);
                                        if ($("#clientId").val() === "") {
                                            $("#clientId").val(json.clientId);
                                            $("#clientId").trigger("input");
                                        }
                                        $("#clientSecret").text(
                                            json.clientSecret
                                        );
                                        skyword.enableButtons();
                                    }
                                };
                            $("#connection-results").fadeOut();
                            call.ajax(urlAction, options);
                        });
                        $("#clientId").bind("input", function() {
                            if ($(this).val().length > 0) {
                                $("#generate-clientinfo").text(
                                    "Create New Secret"
                                );
                            } else {
                                $("#generate-clientinfo").text(
                                    "Create New ID & Secret"
                                );
                            }
                        });
                        $("#sync-taxonomy").click(function() {
                            var urlAction = "adminSyncApiTaxonomyAjax.action",
                                getPost = "POST",
                                options = {
                                    cache: false,
                                    type: getPost,
                                    data: $("#adminEditApiInfo").serialize(),
                                    url: urlAction,
                                    success: function(data) {
                                        $("#sync-results")
                                            .html(data)
                                            .fadeIn();
                                        skyword.enableButtons();
                                    }
                                };
                            $("#sync-results").fadeOut();
                            call.ajax(urlAction, options);
                        });
                        $("#sync-taxonomy-via-publishapi").click(function() {
                            var urlAction =
                                    "adminSyncApiViaPublishApiTaxonomyAjax.action",
                                getPost = "POST",
                                options = {
                                    cache: false,
                                    type: getPost,
                                    data: $("#adminEditApiInfo").serialize(),
                                    url: urlAction,
                                    success: function(data) {
                                        $("#sync-results")
                                            .html(data)
                                            .fadeIn();
                                        skyword.enableButtons();
                                    }
                                };
                            $("#sync-results").fadeOut();
                            call.ajax(urlAction, options);
                        });
                        hideApiSettings();
                        $("#feedType").change(function() {
                            hideApiSettings();
                        });
                        $("#authent-method").change(function() {
                            toggleAuthSettings();
                        });

                        function toggleAuthSettings() {
                            if ($("#authent-method").val() === "true") {
                                var curApi = $("#feedType").val();
                                $(".apiField").removeClass("hiddenImportant");
                                if (curApi !== "wordpress") {
                                    $(".usernameFields").addClass(
                                        "hiddenImportant"
                                    );
                                }
                            } else {
                                $(".apiField").addClass("hiddenImportant");
                                $(".usernameFields").removeClass(
                                    "hiddenImportant"
                                );
                            }
                        }

                        function showAuthSettings() {
                            $(".apiField").removeClass("hiddenImportant");
                            $(".usernameFields").removeClass("hiddenImportant");
                        }

                        function hideApiSettings() {
                            var curApi = $("#feedType").val();
                            showAuthSettings();

                            $("#createTemplate").val("");
                            if (curApi === $("#origFeedType").val()) {
                                $("#createTemplate").val(
                                    $("#origTemplate").val()
                                );
                            }

                            if (curApi === "wordpress") {
                                $(
                                    ".pullapi, .pullapiJSON, .copypaste, .export, .drupal, .facebook, .publishapi, .email"
                                ).fadeOut();
                                $(".wordpress").fadeIn();
                                toggleAuthSettings();
                            }
                            if (curApi === "pullapi") {
                                $(
                                    ".wordpress,.pullapiJSON,.copypaste, .export, .drupal, .facebook, .publishapi, .email"
                                ).fadeOut();
                                $(".pullapi").fadeIn();
                                $("#createTemplate").val(
                                    $("#defaultPullApiValue").val()
                                );
                            }
                            if (curApi === "pullapiJSON") {
                                $(
                                    ".wordpress,.pullapi,.copypaste, .export, .drupal, .facebook, .publishapi, .email"
                                ).fadeOut();
                                $(".pullapiJSON").fadeIn();
                                $("#createTemplate").val(
                                    $("#defaultPullApiJsonValue").val()
                                );
                            }
                            if (curApi === "copypaste") {
                                $(
                                    ".pullapi, .pullapiJSON , .wordpress, .export, .drupal, .facebook, .publishapi, .email"
                                ).fadeOut();
                                $(".copypaste").fadeIn();
                            }
                            if (curApi === "export") {
                                $(
                                    ".pullapi, .pullapiJSON , .wordpress, .copypaste, .drupal, .facebook, .publishapi, .email"
                                ).fadeOut();
                                $(".export").fadeIn();
                            }
                            if (curApi === "drupal") {
                                $(
                                    ".pullapi, .pullapiJSON, .wordpress, .copypaste, .export, .facebook, .publishapi, .email"
                                ).fadeOut();
                                $(".drupal").fadeIn();
                            }
                            if (curApi === "facebook") {
                                $(
                                    ".pullapi, .pullapiJSON, .wordpress, .copypaste, .export, .drupal, .publishapi, .email"
                                ).fadeOut();
                                $(".facebook").fadeIn();
                            }
                            if (curApi === "publishapi") {
                                $(
                                    ".pullapi, .pullapiJSON, .wordpress, .copypaste, .export, .drupal, .facebook, .email"
                                ).fadeOut();
                                $(".publishapi").fadeIn();
                            }
                            if (curApi === "email") {
                                $(
                                    ".wordpress,.pullapiJSON,.copypaste, .export, .drupal, .facebook, .publishapi, .pullapi"
                                ).fadeOut();
                                $(".email").fadeIn();
                            }
                        }

                        function updateAccounts(
                            elem,
                            accountTypeIdElem,
                            progIdElem
                        ) {
                            var accTypeId = $("#" + accountTypeIdElem).val(),
                                progId = $("#" + progIdElem).val(),
                                urlAction = "adminUpdateAccounts.action";

                            var options = {
                                cache: false,
                                type: "get",
                                data: {
                                    selectedProgramId: progId,
                                    selectedAccountTypeId: accTypeId,
                                    updateAccounts: true
                                },
                                url: urlAction,
                                success: function(data) {
                                    $("#" + elem).html(data);
                                    app.value("requestActive", false);
                                },
                                error: function(data, status, err) {
                                    var msg =
                                        "Error getting: " + urlAction + "\n\n";
                                    msg += "Status: " + status + "\n\n";
                                    console.log(msg);
                                }
                            };
                            request = call.skyjax(urlAction, options, request);
                        }

                        html.on(
                            "click",
                            ".icon-Descending.element",
                            descElement
                        );
                        html.on("click", ".icon-Ascending.element", ascElement);

                        /*
                             adminPrograms.jsp
                             */
                        $("#btn-create-program").click(function() {
                            window.location.href =
                                "adminEditProgram.action?agencyId=" +
                                $("#agencyId").val();
                        });

                        $("#btn-create-agency").click(function() {
                            window.location.href =
                                "adminEditAgency.action?createAgency=true";
                        });

                        body.on("keyup", "#formSubmit_password", function() {
                            if (
                                $("#formSubmit_password").val() !== "" &&
                                ($(".testresult").hasClass("goodPass") ||
                                    $(".testresult").hasClass("strongPass"))
                            ) {
                                $("#updateEmail_0").removeAttr("disabled");
                                $("#updateEmail_0").removeClass("disabled");
                            } else {
                                $("#updateEmail_0").attr(
                                    "disabled",
                                    "disabled"
                                );
                                $("#updateEmail_0").addClass("disabled");
                            }
                        });

                        body.on("change", "#aiGenerationEnabled", function() {
                            if (
                                $("#aiGenerationEnabled:checked").val() ===
                                "true"
                            ) {
                                $("#ai-length-min").show();
                                $("#ai-prompt-template").show();
                                $("#temperatureWrapper").show();
                                $("#modelWrapper").show();
                                $("#modelWrapper").css("display", "flex");
                            } else {
                                $("#ai-length-min").hide();
                                $("#ai-prompt-template").hide();
                                $("#temperatureWrapper").hide();
                                $("#modelWrapper").hide();
                                $("#modelWrapper").css("display", "none");
                            }
                        });
                        body.on("change", "#linkToOriginalPrompts", function() {
                            let prompt = $(
                                "#selectedPrompt option:selected"
                            ).attr("data-prompt");
                            console.log(
                                $("#selectedPrompt option:selected").attr(
                                    "data-prompt"
                                )
                            );
                            $("#aiPrompt").val(prompt);

                            if (
                                $("#linkToOriginalPrompts:checked").val() ===
                                "true"
                            ) {
                                $("#aiPrompt").attr("disabled", "disabled");
                                $("#aiPrompt").css("background", "#999");
                                $("#aiPrompt").css("color", "#dfdfdf");
                            } else {
                                $("#aiPrompt").removeAttr("disabled");
                                $("#aiPrompt").css("background", "#fff");
                                $("#aiPrompt").css("color", "#333");
                            }
                        });
                        body.on("click", "#selectPrompt", function() {
                            $("#selectPromptOptionsWrapper").toggleClass(
                                "show"
                            );
                        });
                        body.on("click", "#selectPromptOptions li", function(
                            e
                        ) {
                            let { id } = e.target.dataset;
                            $("#selectPromptOptionsWrapper").toggleClass(
                                "show"
                            );
                            $("#selectPromptOptions li").removeClass("active");
                            $(this).addClass("active");
                            $(`#selectedPrompt option[value="${id}"]`).attr(
                                "selected",
                                "selected"
                            );
                            $("#selectedPrompt").trigger("change");
                        });

                        $(document).on("click", function(event) {
                            if (
                                event.target.matches(
                                    "#selectedPromptWrapper"
                                ) ||
                                event.target.matches("#selectPrompt") ||
                                event.target.matches(
                                    "#selectPromptOptionsWrapper"
                                ) ||
                                event.target.matches("#searchPromptLibrary") ||
                                event.target.matches("#selectPromptOptions") ||
                                event.target.matches("#selectPromptOptions li")
                            ) {
                            } else {
                                $("#selectPromptOptionsWrapper").removeClass(
                                    "show"
                                );
                            }
                        });

                        function debounce(func, timeout = 100) {
                            let timer;
                            return (...args) => {
                                clearTimeout(timer);
                                timer = setTimeout(() => {
                                    func.apply(this, args);
                                }, timeout);
                            };
                        }

                        function getPromptLibraryBySearch(e) {
                            //ajax call to fetch promptsList
                            var urlAction = "/getPromptListAdminAjax.action",
                                getPost = "GET",
                                options;
                            options = {
                                cache: false,
                                type: getPost,
                                data: {
                                    searchPromptTemplate: e.target.value.trim()
                                },
                                url: urlAction,
                                success: function(data) {
                                    let html = $.parseHTML(data);
                                    $("#promptSearchLoaderWrapper").css(
                                        "display",
                                        "none"
                                    );
                                    $("#selectPromptOptions").html("");
                                    $("#selectPromptOptions").html(
                                        $(html[1]).html()
                                    );
                                    if (e.target.value.trim() !== "") {
                                        $("#selectPromptOptions li")
                                            .first()
                                            .remove();
                                    }
                                    if ($("#selectedPrompt").val() !== "") {
                                        $(
                                            `#selectPromptOptions li[data-id="${$(
                                                "#selectedPrompt"
                                            ).val()}"]`
                                        ).addClass("active");
                                    }
                                    $("#selectPromptOptions").css(
                                        "display",
                                        "block"
                                    );
                                },
                                error: function(data) {
                                    $("#promptSearchLoaderWrapper").css(
                                        "display",
                                        "none"
                                    );
                                    $("#selectPromptOptions").css(
                                        "display",
                                        "block"
                                    );
                                    console.log(
                                        "Error: getActiveProgramsByName---------------: ",
                                        data
                                    );
                                    $("#selectPromptOptionsWrapper").html(
                                        `<li class="noProgram">Error Encountered. Please try again.</li>`
                                    );
                                }
                            };
                            $.ajax(urlAction, options);
                            // $("#savePrompt").attr("disabled", "disabled");
                        }

                        body.on(
                            "keyup",
                            "#searchPromptLibrary",
                            debounce(function(e) {
                                $("#selectPromptOptions").css(
                                    "display",
                                    "none"
                                );
                                $("#promptSearchLoaderWrapper").css(
                                    "display",
                                    "block"
                                );
                                getPromptLibraryBySearch(e);
                            }, 1000)
                        );

                        body.on("change", "#selectedPrompt", function(e) {
                            $("#selectPrompt").text(
                                e.target.options[e.target.selectedIndex]
                                    .innerText
                            );
                            var desc =
                                e.target.options[e.target.selectedIndex].dataset
                                    .desc;
                            var prompt =
                                e.target.options[e.target.selectedIndex].dataset
                                    .prompt;

                            $("#ai-prompt-desc-text").html(desc);
                            if (e.target.value === "") {
                                prompt = "";
                                $("#linkToOriginalPromptsWrapper").hide();
                                $("#linkToOriginalPrompts").prop(
                                    "checked",
                                    false
                                );
                                $("#aiPrompt").removeAttr("disabled");
                                $("#aiPrompt").css("background", "#fff");
                                $("#aiPrompt").css("color", "#333");
                                $("#ai-prompt-desc").css("display", "none");
                            } else {
                                $("#linkToOriginalPromptsWrapper").show();
                                if (desc !== "" && desc !== null)
                                    $("#ai-prompt-desc").css("display", "flex");
                                else
                                    $("#ai-prompt-desc").css("display", "none");
                            }
                            $("#aiPrompt").val(prompt);
                        });
                        body.on("change", "#en-scheduled", function() {
                            if ($("#en-scheduled:checked").val() === "true") {
                                $("#scheduled-hours").show();
                            } else {
                                $("#scheduled-hours").hide();
                            }
                        });
                        body.on("change", "#enableSkipSeo", function() {
                            if ($("#enableSkipSeo:checked").val() === "true") {
                                $("#seo-default").show();
                            } else {
                                $("#enableSkipSeoDefault").prop(
                                    "checked",
                                    false
                                );
                                $("#removeSeo").prop(
                                    "checked",
                                    false
                                );
                                $("#seo-default").hide();
                            }
                        });
                        body.on("change", "#en-claim", function() {
                            if ($("#en-claim:checked").val() === "true") {
                                $("#claim-hours").show();
                            } else {
                                $("#claim-hours").hide();
                            }
                        });
                        body.on("change", "#en-submit", function() {
                            if ($("#en-submit:checked").val() === "true") {
                                $("#submit-hours").show();
                            } else {
                                $("#submit-hours").hide();
                            }
                        });
                        body.on(
                            "change",
                            "#enableDeliveryDeadline",
                            function() {
                                if (
                                    $(
                                        "#enableDeliveryDeadline:checked"
                                    ).val() === "true"
                                ) {
                                    $("#businessDaysDeadline").show();
                                } else {
                                    $("#businessDaysDeadline").hide();
                                }
                            }
                        );
                        body.on("change", "#publish-scheduled", function() {
                            if (
                                $("#publish-scheduled:checked").val() === "true"
                            ) {
                                $("#publish-scheduled-hours").show();
                            } else {
                                $("#publish-scheduled-hours").hide();
                            }
                        });
                        body.on("change", "#en-resubmit", function() {
                            if ($("#en-resubmit:checked").val() === "true") {
                                $("#resubmit-hours").show();
                            } else {
                                $("#resubmit-hours").hide();
                            }
                        });

                        $("a.viewCMGDefaults").click(function() {
                            var a = $(this),
                                div = a.siblings(".cmgDefault");
                            console.log(a);
                            console.log(div);
                            if (div.hasClass("hidden")) {
                                div.slideDown("fast", function() {
                                    $(this).removeClass("hidden");
                                });
                            } else {
                                div.slideUp("fast", function() {
                                    $(this).addClass("hidden");
                                });
                            }
                        });

                        $("#email").on("change", recruitingTools);
                        $("#email").change();
                        $("#assignmentElementId").each(showHideCardinality);
                        $("#assignmentElementId").change(showHideCardinality);
                        $("button.adminBackPopulate").click(adminBackPopulate);
                    });
                }
            };
        }
    ])
        .service("adminTemplate", [
            "skyword",
            "call",
            "overlay",
            function(skyword, call, overlay) {
                return {
                    init: function() {
                        var request;

                        function updateParentTaxonomyElements(
                            taxonomyId,
                            setNull
                        ) {
                            var contentTemplateElementTypeId = $(
                                    "#elementTypeId"
                                ).val(),
                                contentTemplateId = $(
                                    "#contentTemplateId"
                                ).val(),
                                contentTypeId = $("#contentTypeId").val(),
                                options,
                                opts,
                                urlAction =
                                    "/adminTemplateGetParentAjax.action",
                                select = $(
                                    "select#taxonomyParentContentTemplateElementId"
                                ),
                                parentContentTemplateElementId = setNull
                                    ? null
                                    : 0 <
                                      $("input#parentContentTemplateElementId")
                                          .length
                                        ? $(
                                              "input#parentContentTemplateElementId"
                                          ).val()
                                        : null;
                            // parentContentTemplateElementId = null;
                            opts = {
                                contentTemplateId: contentTemplateId,
                                taxonomyId: taxonomyId,
                                parentContentTemplateElementId: parentContentTemplateElementId
                            };
                            select.html("");
                            options = {
                                cache: false,
                                type: "POST",
                                data: opts,
                                dataType: "json",
                                url: urlAction,
                                success: function(data) {
                                    var initHTML =
                                            '<option value="">Select One</option>',
                                        el = data.elements;
                                    $.each(el, function(k, v) {
                                        if (
                                            data.selectedId !== null &&
                                            data.selectedId === v.id
                                        ) {
                                            initHTML +=
                                                '<option value="' +
                                                v.id +
                                                '" selected="selected">' +
                                                v.displayName +
                                                " - " +
                                                v.name +
                                                "</option>";
                                        } else {
                                            initHTML +=
                                                '<option value="' +
                                                v.id +
                                                '">' +
                                                v.displayName +
                                                " - " +
                                                v.name +
                                                "</option>";
                                        }
                                    });
                                    select.html(initHTML);
                                }
                            };
                            if (taxonomyId && taxonomyId.length > 0) {
                                request = call.ajax(urlAction, options);
                            }
                        }

                        //onReady
                        $(function() {
                            console.log("on ready");
                            let aiPromptId = $("#aiPromptId").val();
                            if (aiPromptId) {
                                $("#selectedPrompt").trigger("change");
                                $("#aiPrompt").val($("#aiPromptValue").val());
                                if (
                                    $(
                                        "#linkToOriginalPrompts:checked"
                                    ).val() === "true"
                                ) {
                                    $("#aiPrompt").attr("disabled", "disabled");
                                    $("#aiPrompt").css("background", "#999");
                                    $("#aiPrompt").css("color", "#dfdfdf");
                                } else {
                                    $("#aiPrompt").removeAttr("disabled");
                                    $("#aiPrompt").css("background", "#fff");
                                    $("#aiPrompt").css("color", "#333");
                                }
                            } else {
                                $("#linkToOriginalPromptsWrapper").hide();
                                $("#linkToOriginalPrompts").prop(
                                    "checked",
                                    false
                                );
                                $("#aiPrompt").removeAttr("disabled");
                                $("#aiPrompt").css("background", "#fff");
                                $("#aiPrompt").css("color", "#333");
                                $("#ai-prompt-desc").css("display", "none");
                            }

                            $("select#taxonomyId").change(function() {
                                updateParentTaxonomyElements(
                                    $(this).val(),
                                    true
                                );
                            });
                            if ("" !== $("select#taxonomyId").val()) {
                                updateParentTaxonomyElements(
                                    $("select#taxonomyId").val(),
                                    false
                                );
                            }
                            $("#elementGroupId").change(function() {
                                var sel = $(this);
                                window.location =
                                    "adminEditContentTemplate.action?contentTypeId=" +
                                    $("#contentTypeId").val() +
                                    "&contentTemplateId=" +
                                    $("#contentTemplateId").val() +
                                    "&elementGroup=" +
                                    sel.val();
                            });
                        });
                    }
                };
            }
        ])
        .service("emailTest", [
            "skyword",
            function(skyword) {
                return {
                    init: function() {
                        function showFields() {
                            var email = $("#emailSelect").attr("value");
                            if (email === "-1") {
                                $(
                                    ".programId, .string0, .videoCommentId, .bigDecimal, .agencyId, .accountId, .programAccountId, .contentRequestId, .discussionId, .string1, .stringList0, .stringList1, .integer, .boolean, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "programSetup") {
                                $(".programId,.string0,.bigDecimal").show();
                                $(
                                    ".agencyId, .accountId, .videoCommentId, .programAccountId, .contentRequestId, .discussionId, .string1, .stringList0, .stringList1, .integer, .boolean, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "authorWelcome") {
                                $(" .accountId").show();
                                $(
                                    ".programId,.string0,.videoCommentId, .bigDecimal,.agencyId, .programAccountId, .contentRequestId, .discussionId, .string1, .stringList0, .stringList1, .integer, .boolean, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (
                                email === "resetPassword" ||
                                email === "accountLocked"
                            ) {
                                $(" .accountId, .string0, .agencyId").show();
                                $(
                                    ".programId,.bigDecimal, .videoCommentId, .programAccountId, .contentRequestId, .discussionId, .string1, .stringList0, .stringList1, .integer, .boolean, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (
                                email === "applicationApproved" ||
                                email === "applicationRejected" ||
                                email === "invitationAccepted"
                            ) {
                                $(" .programAccountId").show();
                                $(
                                    ".programId,.string0,.videoCommentId, .bigDecimal,.accountId,.agencyId,.contentRequestId, .discussionId, .string1, .stringList0, .stringList1, .integer, .boolean, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (
                                email === "articleApproved" ||
                                email === "articleRemoved" ||
                                email === "writerProposalAccepted" ||
                                email === "writerProposalDeclined" ||
                                email === "writerAssignmentOffered" ||
                                email === "deliverContentViaEmail"
                            ) {
                                $(" .contentRequestId").show();
                                $(
                                    ".programId,.string0,.videoCommentId, .bigDecimal,.accountId,.agencyId, .programAccountId, .discussionId, .string1, .stringList0, .stringList1, .integer, .boolean, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (
                                email === "articleApprovedCopyPaste" ||
                                email === "articleRepublishedCopyPaste" ||
                                email === "articleReturned" ||
                                email === "articleRemovedManager" ||
                                email === "articleDeclined" ||
                                email === "writerAssignmentWithdrawn" ||
                                email === "writerArticleWithdrawn"
                            ) {
                                $(" .contentRequestId, .string0").show();
                                $(
                                    ".programId,.videoCommentId, .bigDecimal,.accountId,.agencyId, .programAccountId, .discussionId, .string1, .stringList0, .stringList1, .integer, .boolean, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "additionalPayment") {
                                $(
                                    ".accountId, .programId, .bigDecimal, .string0"
                                ).show();
                                $(
                                    " .contentRequestId, .videoCommentId, .agencyId,.contentRequestId, .programAccountId, .discussionId, .string1, .stringList0, .stringList1, .integer, .boolean, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "newManagerAccount") {
                                $(
                                    ".accountId, .programId, .string0, .string1"
                                ).show();
                                $(
                                    " .contentRequestId, .videoCommentId,  .bigDecimal, .agencyId,.contentRequestId, .programAccountId, .discussionId, .stringList0, .stringList1, .integer, .boolean, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "newManagerAccountMultiple") {
                                $(
                                    ".accountId, .agencyId, .stringList0, .stringList1"
                                ).show();
                                $(
                                    " .contentRequestId, .videoCommentId, .bigDecimal, .programId,.contentRequestId, .programAccountId, .discussionId, .string0, .string1, .integer, .boolean, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "newManagerRole") {
                                $(".accountId, .programId, .string0").show();
                                $(
                                    " .contentRequestId, .videoCommentId, .bigDecimal, .agencyId,.contentRequestId, .programAccountId, .discussionId, .string1, .stringList0, .stringList1, .integer, .boolean, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "newManagerRoleMultiple") {
                                $(
                                    ".accountId, .agencyId, .stringList0, .stringList1, .string0"
                                ).show();
                                $(
                                    " .contentRequestId, .videoCommentId, .bigDecimal, .programId,.contentRequestId, .programAccountId, .discussionId, .string1, .integer, .boolean, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "newAgencyAdminAccount") {
                                $(" .accountId, .agencyId, .string0").show();
                                $(
                                    ".programId,.bigDecimal,.videoCommentId, .programAccountId,.contentRequestId, .discussionId, .string1, .stringList0, .stringList1, .integer, .boolean, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "newAgencyAdminPrivileges") {
                                $(" .accountId, .agencyId, .string0").show();
                                $(
                                    ".programId,.bigDecimal,.videoCommentId, .programAccountId,.contentRequestId, .discussionId, .string1, .stringList0, .stringList1, .integer, .boolean, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "writerInvitation") {
                                $(".programAccountId,.boolean").show();
                                $(
                                    ".accountId, .agencyId, .videoCommentId, .string0,.programId,.bigDecimal,.contentRequestId, .discussionId, .string1, .stringList0, .stringList1, .integer, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "writerMultipleAssignmentOffered") {
                                $(
                                    ".accountId,.programId, .string0, .integer"
                                ).show();
                                $(
                                    ".agencyId,.bigDecimal,.videoCommentId, .contentRequestId, .discussionId, .string1, .stringList0, .stringList1,.programAccountId,.boolean, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "writerArticleReopened") {
                                $(
                                    " .contentRequestId, .string0, .integer"
                                ).show();
                                $(
                                    ".programId,.bigDecimal,.videoCommentId, .accountId,.agencyId, .programAccountId, .discussionId, .string1, .stringList0, .stringList1, .boolean, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "W9Required") {
                                $(".accountId, .programId").show();
                                $(
                                    " .contentRequestId, .string0, .videoCommentId, .agencyId,.contentRequestId, .programAccountId, .discussionId, .string1, .stringList0, .stringList1, .integer, .boolean, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "externalContentReview") {
                                $(
                                    ".accountId, .contentRequestId, .boolean"
                                ).show();
                                $(
                                    " .string0, .agencyId, .videoCommentId, .programAccountId, .discussionId, .string1, .stringList0, .stringList1, .integer, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "singleUseVerificationCode") {
                                $(".accountId, .string0").show();
                                $(
                                    ".contentRequestId, .boolean, .videoCommentId, .agencyId, .programAccountId, .discussionId, .string1, .stringList0, .stringList1, .integer, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "twoFactorComplete") {
                                $(".accountId").show();
                                $(
                                    ".contentRequestId, .boolean, .videoCommentId, .agencyId, .programAccountId, .discussionId, .string0, .string1, .stringList0, .stringList1, .integer, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "twoFactorUpdated") {
                                $(".accountId, .string0").show();
                                $(
                                    ".contentRequestId, .boolean, .videoCommentId, .agencyId, .programAccountId, .discussionId, .string1, .stringList0, .stringList1, .integer, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "publishFailed") {
                                $(
                                    ".contentRequestId, .string0, .string1"
                                ).show();
                                $(
                                    ".accountId, .boolean, .videoCommentId, .agencyId, .programAccountId, .discussionId, .stringList0, .stringList1, .integer, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "purchaseFailed") {
                                $(
                                    ".contentRequestId, .string0, .bigDecimal"
                                ).show();
                                $(
                                    ".accountId, .boolean, .videoCommentId, .agencyId, .programAccountId, .discussionId, .stringList0, .stringList1, .integer, .string1, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "discussionTopic") {
                                $(".discussionId, .accountId").show();
                                $(
                                    ".boolean, .videoCommentId, .agencyId, .programAccountId, .contentRequestId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "discussionReply") {
                                $(".discussionId, .accountId").show();
                                $(
                                    ".boolean, .videoCommentId, .agencyId, .programAccountId, .contentRequestId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "videoComment") {
                                $(".videoCommentId, .accountId").show();
                                $(
                                    ".boolean, .discussionId, .agencyId, .programAccountId, .contentRequestId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "invitedToBid") {
                                $(" .contentRequestId, .accountId").show();
                                $(
                                    ".programId,.string0,.videoCommentId, .bigDecimal,.agencyId, .programAccountId, .discussionId, .string1, .stringList0, .stringList1, .integer, .boolean, .rfpBidId, .approvedByAccountId, .hoursTilSubmission. .packageId"
                                ).hide();
                            }
                            if (email === "bidSubmitted") {
                                $(".rfpBidId, .accountId").show();
                                $(
                                    ".programId, .boolean, .discussionId, .agencyId, .programAccountId, .contentRequestId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "rfpClosed") {
                                $(".contentRequestId, .accountId").show();
                                $(
                                    ".programId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "bidAccepted") {
                                $(".rfpBidId, .accountId").show();
                                $(
                                    ".programId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .contentRequestId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "submissionApproved") {
                                $(".contentRequestId").show();
                                $(
                                    ".accountId, .programId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "videoPayment") {
                                $(
                                    ".contentRequestId, .accountId, .approvedByAccountId"
                                ).show();
                                $(
                                    ".programId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "offeredAssignmentExpired") {
                                $(".contentRequestId, .accountId").show();
                                $(
                                    ".programId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "assignmentDiscarded") {
                                $(".contentRequestId, .accountId").show();
                                $(
                                    ".programId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "assignmentExpiredMissedDeadline") {
                                $(".contentRequestId, .accountId").show();
                                $(
                                    ".programId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "offeredAssignmentReminder") {
                                $(".contentRequestId, .accountId").show();
                                $(
                                    ".programId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "submissionDeadlineReminder") {
                                $(
                                    ".contentRequestId, .accountId, .hoursTilSubmission"
                                ).show();
                                $(
                                    ".programId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .packageId"
                                ).hide();
                            }
                            if (email === "ideaSubmitted") {
                                $(".contentRequestId, .accountId").show();
                                $(
                                    ".programId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "ideaDeclined") {
                                $(".contentRequestId").show();
                                $(
                                    ".accountId, .programId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "ideaToAssignment") {
                                $(".contentRequestId, .accountId").show();
                                $(
                                    ".programId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "rfpBidDeclined") {
                                $(
                                    ".contentRequestId, .accountId, .string0"
                                ).show();
                                $(
                                    ".programId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "rfpBidReturned") {
                                $(
                                    ".contentRequestId, .accountId, .string0"
                                ).show();
                                $(
                                    ".programId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "rfpBidInterestExpiration") {
                                $(".contentRequestId, .accountId").show();
                                $(
                                    ".programId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "offeredAssignmentDeclined") {
                                $(".contentRequestId, .accountId").show();
                                $(
                                    ".programId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "assignmentUnclaimed") {
                                $(".contentRequestId, .accountId").show();
                                $(
                                    ".programId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "contentPublishedForProgramUser") {
                                $(".contentRequestId, .accountId").show();
                                $(
                                    ".programId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "ideasApproved") {
                                $(".programId, .accountId").show();
                                $(
                                    ".contentRequestId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "ideasReadyForReview") {
                                $(".programId, .accountId").show();
                                $(
                                    ".contentRequestId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "aiGeneratedContentRatings") {
                                $(
                                    ".contentRequestId, .accountId, .programId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission, .packageId"
                                ).hide();
                            }
                            if (email === "contentPackageProcessed") {
                                $(".packageId, .programId, .accountId").show();
                                $(
                                    ".contentRequestId, .boolean, .discussionId, .agencyId, .programAccountId, .stringList0, .stringList1, .integer, .string1,.string0, .bigDecimal, .rfpBidId, .approvedByAccountId, .hoursTilSubmission"
                                ).hide();
                            }
                        }

                        //onReady
                        $(function() {
                            var selectedVal = $("#selectedVal").val();

                            $("option[value='" + selectedVal + "']").attr(
                                "selected",
                                "true"
                            );
                            showFields();

                            $("#emailSelect").change(function() {
                                showFields();
                            });
                        });
                    }
                };
            }
        ]);
})();

app.service("keywords", [
    "skyword",
    "validationUtil",
    "call",
    function(skyword, validationUtil, call) {
        return {
            init: function() {
                var keywordInput,
                    categoryId,
                    loadOriginal = false;

                function updateZebra(tableId) {
                    $("#" + tableId + " tr:odd").attr("class", "odd");
                    $("#" + tableId + " tr:even").attr("class", "even");
                }

                //onReady
                $(function() {
                    /*
                     * addKeyword.jspf
                     */
                    $("#selectedContentTypeId").on("change", function() {
                        skyword.populateCategories(
                            "categoryDiv",
                            "selectedCategoryId",
                            "selectedContentTypeId",
                            $("#programId").val(),
                            false,
                            $("#select-dropdown").val(),
                            "selectedCategoryId",
                            false
                        );
                    });
                    $("body").on(
                        "change",
                        ".sw-modal #contentTypeId",
                        null,
                        function() {
                            var urlAction = "findCategories.action",
                                options = {
                                    cache: false,
                                    type: "POST",
                                    data: $("#formSubmit").serialize(),
                                    url: urlAction,
                                    success: function(data) {
                                        $("#select-categories").html(data);
                                    }
                                };
                            call.ajax(urlAction, options);
                            return false;
                        }
                    );

                    /*
                     * searchKeywordList.jspf
                     */
                    $("body").on("keypress", "#searchText", null, function(e) {
                        //Do ajax call on ajax version of page
                        if (e.which == 13) {
                            if (0 !== $("#search-keywords-button").length) {
                                $("#search-keywords-button").click();
                                return false;
                            }
                        }
                    });

                    $("#searchText").on("click", function() {
                        $(this)
                            .val("")
                            .off("click")
                            .removeClass("default");
                    });

                    $("html").on("click", "#btn-reinstate", null, function() {
                        var urlAction = "reinstateKeywordAjax.action",
                            selectedStatus = parseInt(
                                $("#selectedStatusId").val(),
                                10
                            ),
                            checked = $("input.checkboxStyle:checked"),
                            options = {
                                cache: false,
                                type: "POST",
                                url: urlAction,
                                data: $("#results-form").serialize(),
                                success: function(data) {
                                    $("#search-keywords-button").click();
                                    validationUtil.parse(data);
                                },
                                complete: function(d) {
                                    skyword.enableButtons();
                                    console.log(
                                        "selectedStatus " + selectedStatus
                                    );
                                    if (-1 === selectedStatus) {
                                        checked.each(function() {
                                            var c = $(this),
                                                reinstate = $(
                                                    "#hidden-reinstate"
                                                ).val(),
                                                td = c
                                                    .parent()
                                                    .siblings(".keywordState");
                                            td.html(reinstate);
                                            c.prop("checked", false);
                                        });
                                    } else if (1 !== selectedStatus) {
                                        checked.each(function() {
                                            var tr = $(this)
                                                .parent()
                                                .parent();
                                            tr.fadeOut("fast", function() {
                                                $(this).remove();
                                            });
                                        });
                                        updateZebra("searchKeywordsData");
                                    }
                                }
                            };

                        if ($("#results-form").serialize() != "") {
                            call.ajax(urlAction, options);
                        } else {
                            skyword.enableButtons();
                        }
                        return false;
                    });
                    $("html").on("click", "#btn-retire", null, function() {
                        var contentTypeId,
                            selectedCategoryId,
                            selectedStatusId,
                            searchText,
                            formData = $("#results-form").serialize();
                        console.log(formData);
                        if ("" !== formData) {
                            contentTypeId = $("#selectedContentTypeId").val();
                            selectedCategoryId = $("#selectedCategoryId").val();
                            selectedStatusId = $("#selectedStatusId").val();
                            searchText = $("#searchText").val();
                            skyword.setAjaxContainer(".container");
                            skyword.setLastAjaxCall(
                                "searchKeywordListAjax.action?selectedContentTypeId=" +
                                    contentTypeId +
                                    "&selectedCategoryId=" +
                                    selectedCategoryId +
                                    "&selectedStatusId=" +
                                    selectedStatusId +
                                    "&searchText=" +
                                    searchText
                            );
                            call.overlay(
                                "retireKeywordAjax.action",
                                $("#results-form").serialize(),
                                650,
                                null
                            );
                        } else {
                            skyword.enableButtons();
                        }
                        return false;
                    });

                    $("html").on("click", "#btn-deactivate", null, function() {
                        var urlAction = "deactivateKeywordAjax.action",
                            selectedStatus = parseInt(
                                $("#selectedStatusId").val(),
                                10
                            ),
                            checked = $("input.checkboxStyle:checked"),
                            options = {
                                cache: false,
                                type: "POST",
                                url: urlAction,
                                data: $("#results-form").serialize(),
                                success: function(data) {
                                    $("#search-keywords-button").click();
                                    validationUtil.parse(data);
                                },
                                complete: function(d) {
                                    skyword.enableButtons();
                                    console.log(
                                        "selectedStatus " + selectedStatus
                                    );
                                    if (-1 === selectedStatus) {
                                        checked.each(function() {
                                            var c = $(this),
                                                deactivate = $(
                                                    "#hidden-deactivate"
                                                ).val(),
                                                td = c
                                                    .parent()
                                                    .siblings(".keywordState");
                                            td.html(deactivate);
                                            c.prop("checked", false);
                                        });
                                    } else if (3 !== selectedStatus) {
                                        checked.each(function() {
                                            var tr = $(this)
                                                .parent()
                                                .parent();
                                            tr.fadeOut("fast", function() {
                                                $(this).remove();
                                            });
                                        });
                                        updateZebra("searchKeywordsData");
                                    }
                                }
                            };

                        if ($("#results-form").serialize() != "") {
                            call.ajax(urlAction, options);
                        } else {
                            skyword.enableButtons();
                        }
                        return false;
                    });

                    $("html").on("click", "#inline-close", null, function() {
                        $("#update-status-box").slideUp();
                        skyword.enableButtons();
                        $("#proposal-button-row").slideDown();
                        return false;
                    });

                    $("html").on(
                        "click",
                        "#search-keywords-button",
                        null,
                        function() {
                            $("#success-message").hide();
                            $("#success-box").hide();
                            $("#searchKeywordsData")
                                .addClass("whiteout")
                                .before(
                                    '<div class="clear centerText" style="position:relative; top:12px; z-index:10;"><div id="loading-message" class="warning padding10 message"><span class="icon-None"></span>&nbsp;' +
                                        $("#loadingText").val() +
                                        "</div></div>"
                                );
                            var urlAction = "searchKeywordListAjax.action",
                                options = {
                                    cache: false,
                                    type: "POST",
                                    data: $("#search-form").serialize(),
                                    url: urlAction,
                                    success: function(data) {
                                        $(".container").html(data);
                                        $("#searchKeywordsData").removeClass(
                                            "whiteout"
                                        );
                                        skyword.setupDisplaytagAjax();
                                        $(".loading").slideUp("slow");
                                        skyword.enableButtons();
                                    }
                                };
                            call.ajax(urlAction, options);
                            return false;
                        }
                    );

                    /*
                     * manualKeywords.jsp
                     */
                    $(".link-manualkeywords-makeactive").click(function() {
                        var kwId = $(this)
                                .parent()
                                .find("input#id")
                                .val(),
                            candId = $(this)
                                .parent()
                                .find("input#candId")
                                .val(),
                            target = $(this).parent(),
                            urlAction = "updateManualKeywordAjax.action",
                            options = {
                                cache: false,
                                type: "POST",
                                url: urlAction,
                                data: {
                                    keywordId: kwId,
                                    command: "activate",
                                    candidateId: candId
                                },
                                success: function(message) {
                                    target.html(message);
                                },
                                error: function(message) {
                                    console.log(message);
                                }
                            };
                        call.ajax(urlAction, options);
                        return false;
                    });

                    $(".link-manualkeywords-donotchange").click(function() {
                        var kwId = $(this)
                                .parent()
                                .find("input#id")
                                .val(),
                            candId = $(this)
                                .parent()
                                .find("input#candId")
                                .val(),
                            target = $(this).parent(),
                            urlAction = "updateManualKeywordAjax.action",
                            options = {
                                cache: false,
                                type: "POST",
                                url: urlAction,
                                data: {
                                    keywordId: kwId,
                                    command: "donotchange",
                                    candidateId: candId
                                },
                                success: function(message) {
                                    target.html(message);
                                },
                                error: function(message) {
                                    console.log(message);
                                }
                            };
                        call.ajax(urlAction, options);
                        return false;
                    });
                });
            }
        };
    }
]);

(function() {
    app.factory("formBuilder", [
        "$rootScope",
        "$compile",
        "$timeout",
        "taxonomy",
        function($rootScope, $compile, $timeout, taxonomy) {
            return {
                render: function(delay) {
                    if (!delay) {
                        delay = 1;
                    }
                    $timeout(function() {
                        var scope = $rootScope.$new();
                        $rootScope.$apply(function() {
                            var form = $("form-element");
                            $compile(form)(scope);
                        });
                    }, delay);
                },
                setupSelectSingle: function() {
                    // Init
                    $timeout(function() {
                        $(
                            ".form-builder.select-single-element select"
                        ).selectBoxIt();
                        $(
                            ".form-builder.select-single-element select"
                        ).selectBoxIt("refresh");
                        $(
                            ".form-builder.select-single-element select"
                        ).selectBoxIt("close");
                        console.log("Service Single, 2ms");
                    }, 200);
                },

                multiSelect: function(id, timeout) {
                    try {
                        var time = timeout;
                        time = "undefined" !== typeof timeout ? time : 1000;
                        $timeout(function() {
                            // var id = "#" + scope.form.id;
                            $(id).multiSelect({
                                keepOrder: false,
                                afterSelect: function() {
                                    $(this).change();
                                },
                                afterDeselect: function() {
                                    $(this).change();
                                }
                            });
                        }, time);
                    } catch (e) {}
                },
                setupSelectMultiple: function() {
                    try {
                        $timeout(function() {
                            $(
                                ".form-builder.select-multiple-element select"
                            ).multiSelect({
                                keepOrder: false,
                                afterSelect: function() {
                                    $(this).change();
                                },
                                afterDeselect: function() {
                                    $(this).change();
                                }
                            });
                            var selectMultipleElement = angular.element(
                                ".form-builder.select-multiple-element select"
                            );
                            selectMultipleElement
                                .siblings("span.selectboxit-container")
                                .hide();
                            console.log("Service Multiple, 1ms");
                        }, 100);

                        // Hide visual bugfixes created by selectboxit().
                    } catch (e) {}
                }
            };
        }
    ]);
})();

app.directive("fileupload", [
    "$timeout",
    "call",
    "$translate",
    "$compile",
    "$rootScope",
    "TemplateFactory",
    "SkyFactory",
    "$http",
    function(
        $timeout,
        call,
        $translate,
        $compile,
        $rootScope,
        TemplateFactory,
        SkyFactory,
        $http
    ) {
        return {
            restrict: "EA",
            replace: true,
            templateUrl: "/partials/directives/upload.html",
            scope: {
                ngModel: "=",
                change: "&"
            },
            link: function(scope, element, attrs) {
                var formData = {},
                    body = $("body"),
                    ideaAttachment = false;
                if (undefined !== attrs.params) {
                    formData = JSON.parse(attrs.params);
                }

                // console.info(attrs);
                // console.info(formData);

                if (undefined !== formData.ideaAttachment) {
                    // console.log("IDea Attachment Found!");
                    ideaAttachment = true;
                }

                scope.$watch("ngModel", function(newValue, oldValue) {
                    if (newValue === undefined) {
                        element.attr("upload-state", "default");
                    }
                });

                scope.cancel = function() {
                    if (scope.crop === "true") {
                        $("#fileUpload-WYSIWYG").show();
                        $("#selectImg-WYSIWYG").show();
                        $(".uploadModule").css({ bottom: "30px" });
                        $(
                            "div.uploadContainer.fileUploadSpan.fileUploadNew"
                        ).css({ height: "75px" });

                        if (scope.showAttachmentInfo == "true") {
                            $("div.right.stockPhotoNotDam").hide();
                            $("a.assetLibraryNotDam").hide();
                        }
                    }
                    element.attr("upload-state", "default");
                    // scope used here will solve https://skyword.atlassian.net/browse/SKY-12724
                    scope.jqXHR.abort();
                    return false;
                };
                scope.remove = function() {
                    element.attr("upload-state", "default");
                    scope.ngModel = null;
                    return false;
                };

                //functions for toggling error message if stock photo or asset link is clicked
                body.on("click", "a.stock-photo", function() {
                    localStorage.setItem(
                        "ImageSelectionStartTime",
                        new Date().getTime()
                    );
                    scope.error = null;
                    $("div.uploadContainer.fileUploadSpan.fileUploadNew").css({
                        height: "75px"
                    });
                    localStorage.setItem("uploadUsing", "stockPhoto");
                    localStorage.setItem("uploadUsingId", $(this).attr("id"));
                    scope.$apply();
                });

                body.on("click", "a.asset-Library-Link", function() {
                    scope.error = null;
                    $("div.uploadContainer.fileUploadSpan.fileUploadNew").css({
                        height: "75px"
                    });
                    localStorage.setItem("uploadUsing", "asset");
                    localStorage.setItem("uploadUsingId", $(this).attr("id"));
                    scope.$apply();
                });

                //initalize scope parameters
                scope.elementid = attrs.elementid;
                scope.filetype = attrs.filetype;
                scope.filename = attrs.filename;
                scope.localUrl = attrs.localurl;
                scope.crop = attrs.crop;
                scope.isAssignmentAttachment = attrs.isassignmentattachment;
                scope.createNewIdea = attrs.createnewidea;
                if (formData.fileExtensions === "") {
                    formData.fileExtensions = ".png, .jpg, .jpeg, .jpe, .gif";
                }
                scope.fileExtensions = formData.fileExtensions;
                if (
                    scope.crop == "true" ||
                    scope.isAssignmentAttachment == "true"
                ) {
                    //because we need in bytes and we are given KB
                    scope.maxsize = attrs.maxsize * 1000;
                } else {
                    scope.maxsize = attrs.maxsize;
                }
                scope.showAttachmentInfo = attrs.showattachmentinfo;

                if (attrs.isembedded == "true") {
                    scope.isEmbedded = true;
                } else {
                    scope.isEmbedded = false;
                }

                scope.contentTemplateElementDataId =
                    attrs.contentTemplateElementDataId;
                scope.filteredfilesize = "32 mb";
                scope.isDigitalAsset = attrs.digitalasset;
                scope.contentTemplateElementId = attrs.contentTemplateElementId;
                scope.multiple = attrs.multiple === "true" ? true : false;
                if (scope.multiple) {
                    $(element)
                        .find("input")
                        .attr("multiple", "multiple");
                } else {
                    $(element)
                        .find("input")
                        .removeAttr("multiple");
                }
                $timeout(
                    function() {
                        if (
                            undefined !== attrs.filename &&
                            null != attrs.filename &&
                            "" !== attrs.filename
                        ) {
                            scope.filename = attrs.filename.substring(
                                attrs.filename.lastIndexOf("/"),
                                attrs.filename.length
                            );
                            element.attr("upload-state", "complete");
                            scope.$apply();
                        }
                    },
                    800,
                    false
                );
                var status = element.parent().find(".info"),
                    feedback = element.parent().find(".progress"),
                    bar = feedback.find(".bar"),
                    bytes = status.find(".bytes");
                // CT-609 - This function and "dropZone: null" is to disable the ability to drag an drop files on attachment uploaders.
                $(".fileUploadSpan").bind("dragover drop", function(e) {
                    e.preventDefault();
                    return false;
                });

                $(element)
                    .find("input")
                    .on("change", async function(e) {
                        if (scope.multiple) {
                            scope.change();
                        }
                    });
                if (!scope.multiple) {
                    $(element)
                        .find("input")
                        .fileupload({
                            pasteZone: null,
                            dropZone: null,
                            dataType: "json",
                            paramName: "file",
                            url: attrs.uploadurl,
                            formData: formData,
                            beforeSend: function(request) {
                                request.setRequestHeader(
                                    "csrfToken",
                                    angular.element("#cToken").attr("value")
                                );
                                request.setRequestHeader(
                                    "pageLoadTime",
                                    angular
                                        .element("#pageLoadTime")
                                        .attr("value")
                                );
                            },
                            add: function(e, data) {
                                //immediately send the file
                                bar.show();
                                var totalSize;

                                //get file extension
                                var fname = data.files[0].name.toLowerCase();
                                var extStart = fname.lastIndexOf(".");
                                var extEnd = fname.length;
                                var fileExt = fname.slice(extStart, extEnd);
                                console.log("FILE EXTENSION:" + fileExt);

                                if (data.files[0].size > scope.maxsize) {
                                    //error if file size is too large
                                    scope.error = {
                                        filesizelimit: true
                                    };
                                    $(
                                        "div.uploadContainer.fileUploadSpan.fileUploadNew"
                                    ).css({ height: "145px" });
                                    scope.$apply();
                                } else if (
                                    !SkyFactory.isUndefinedOrNull(
                                        formData.fileExtensions
                                    ) &&
                                    formData.fileExtensions.indexOf(fileExt) ===
                                        -1
                                ) {
                                    //error for if in create content or in create asset and file type uploaded is not allowed
                                    console.log("IN UPLOAD FILE ERROR");
                                    scope.error = { extensions: true };
                                    scope.error.fileExtensions =
                                        formData.fileExtensions;
                                    $(
                                        "div.uploadContainer.fileUploadSpan.fileUploadNew"
                                    ).css({ height: "145px" });
                                    $translate(
                                        "angular.error.invalidfiletype",
                                        {
                                            filename: fname
                                        }
                                    ).then(function(txt) {
                                        scope.error.translatedfileext = txt;
                                    });
                                } else {
                                    //if no errors, go ahead and upload file
                                    $("div.right.stockPhotoNotDam").hide();
                                    $("a.assetLibraryNotDam").hide();
                                    console.log("Upload data:", data);
                                    var jqXHR = data.submit();
                                    scope.jqXHR = jqXHR;
                                    if (data.files[0].size < 1000000) {
                                        totalSize =
                                            Math.round(
                                                data.files[0].size / 1000
                                            ) + " KB";
                                    } else {
                                        totalSize =
                                            Math.round(
                                                data.files[0].size / 1000
                                            ) /
                                                1000 +
                                            " MB";
                                    }
                                    scope.filename = data.files[0].name;
                                    scope.filessize = totalSize;
                                    scope.$apply();
                                }
                                window.localStorage.removeItem("uploadUsing");
                                window.localStorage.removeItem("uploadUsingId");
                            },
                            done: function(e, data) {
                                //console.log(data);
                                //console.log(data.result);
                                scope.error = null;
                                // TODO: ngModel should be renamed to attachment
                                scope.ngModel = data.result;
                                scope.localUrl = data.result.localUrl;
                                if (scope.crop === "true") {
                                    element.attr("upload-state", "default");
                                    $("#fileUpload-WYSIWYG").show();
                                    $(".uploadModule").css({
                                        bottom: "30px"
                                    });
                                    $("#selectImg-WYSIWYG").show();
                                    $(
                                        "div.uploadContainer.fileUploadSpan.fileUploadNew"
                                    ).css({ height: "75px" });

                                    if (scope.showAttachmentInfo == "true") {
                                        $("div.right.stockPhotoNotDam").show();
                                        $("a.assetLibraryNotDam").show();
                                    }
                                } else if (
                                    scope.isAssignmentAttachment === "true"
                                ) {
                                    element.attr("upload-state", "default");
                                } else {
                                    //this case is for resources on create assignment
                                    element.attr("upload-state", "complete");
                                }
                                scope.$apply();

                                try {
                                    if (scope.isEmbedded) {
                                        element
                                            .closest(".attachmentContainer")
                                            .hide();
                                    }
                                } catch (exception) {
                                    console.log(exception);
                                }

                                //if uploaded content is an image, and we want to crop, call overlay
                                if (
                                    scope.crop === "true" &&
                                    scope.ngModel.isImage == true
                                ) {
                                    $.colorbox({
                                        height: 850,
                                        width: 1050,
                                        href:
                                            "/partials/directives/fileupload-crop-overlay.html",
                                        onComplete: function() {
                                            $rootScope.$apply(function() {
                                                var content = $(
                                                    "#cboxLoadedContent"
                                                );
                                                $compile(content)(scope);
                                            });
                                        }
                                    });

                                    scope.jqXHR.abort();
                                    return false;
                                } else if (
                                    scope.isAssignmentAttachment === "true"
                                ) {
                                    //ASSIGNMENT ELEMENT
                                    var assignmentPromise;
                                    if ("true" === scope.createNewIdea) {
                                        assignmentPromise = TemplateFactory.getIdeaAttachmentNoData(
                                            scope.ngModel.id
                                        );
                                    } else {
                                        assignmentPromise = ideaAttachment
                                            ? TemplateFactory.getIdeaAttachment(
                                                  scope.ngModel.id,
                                                  scope.ngModel
                                                      .contentTemplateElementDataId
                                              )
                                            : TemplateFactory.getAttachmentInfoHtml(
                                                  scope.ngModel.id,
                                                  scope.ngModel
                                                      .contentTemplateElementDataId
                                              );
                                    }

                                    assignmentPromise.then(function(html) {
                                        //console.log(formData.contentTemplateElementId);
                                        //console.log("createNewIdea " + ("true" === scope.createNewIdea));
                                        var ac =
                                            "true" === scope.createNewIdea
                                                ? jQuery(
                                                      "div.attachmentContainer[data-cte=" +
                                                          formData.contentTemplateElementId +
                                                          "]"
                                                  )
                                                : jQuery(
                                                      "div.attachmentContainer[data-cted=" +
                                                          scope.ngModel
                                                              .contentTemplateElementDataId +
                                                          "]"
                                                  );
                                        var subContainer = ac.find(">div");

                                        //hide file upload span so we can re-init incase they remove the image
                                        subContainer
                                            .find(".fileUploadSpan")
                                            .hide();
                                        //append new html into div
                                        subContainer.append(html.data);
                                        ac.addClass("attachmentLoaded");
                                        var attachment = subContainer.find(
                                            ".attachmentId"
                                        );
                                        ac.siblings("input.attachmentId").val(
                                            attachment.val()
                                        );
                                        if ("true" === scope.createNewIdea) {
                                            var att = jQuery(
                                                "input#attachment-" +
                                                    formData.contentTemplateElementId
                                            );
                                            att.val(attachment.val());
                                            att.trigger("input");
                                        }
                                    });
                                } else if (
                                    scope.isAssignmentAttachment == "false" &&
                                    scope.crop === "true" &&
                                    scope.ngModel.isImage == false
                                ) {
                                    //is a media attachment that is video or doc
                                    var mediaPromise = TemplateFactory.getAttachmentInfoHtml(
                                        scope.ngModel.id,
                                        scope.ngModel
                                            .contentTemplateElementDataId
                                    );
                                    mediaPromise.then(function(html) {
                                        var ac = $(
                                            "div.attachmentContainer[data-cted=" +
                                                scope.ngModel
                                                    .contentTemplateElementDataId +
                                                "]"
                                        );
                                        var subContainer = ac.find(">div");
                                        //console.log(html);
                                        //hide file upload span so we can re-init incase they remove the image
                                        subContainer
                                            .find(".fileUploadSpan")
                                            .hide();
                                        //append new html into div
                                        subContainer.append(html.data);
                                        ac.addClass("attachmentLoaded");
                                        var attachment = subContainer.find(
                                            ".attachmentId"
                                        );
                                        ac.siblings("input.attachmentId").val(
                                            attachment.val()
                                        );
                                    });
                                }
                            },
                            success: function(result, textStatus, jqXHR) {},
                            error: function(jqXHR, textStatus, errorThrown) {
                                if (errorThrown != "abort") {
                                    scope.error = {
                                        error: jqXHR.responseText
                                    };
                                    scope.cancel();
                                    $("div.right.stockPhotoNotDam").show();
                                    $("a.assetLibraryNotDam").show();
                                    scope.$apply();
                                }
                            },
                            progressall: function(e, data) {
                                if (scope.crop === "true") {
                                    $("#fileUpload-WYSIWYG").hide();
                                    $("#selectImg-WYSIWYG").hide();
                                    if (scope.isEmbedded) {
                                        $(".uploadModule").css({
                                            bottom: "10px"
                                        });
                                    } else {
                                        $(".uploadModule").css({
                                            bottom: "30px"
                                        });
                                    }

                                    if (scope.showAttachmentInfo == "true") {
                                        $("div.right.stockPhotoNotDam").hide();
                                        $("a.assetLibraryNotDam").hide();
                                    }
                                } else if (
                                    scope.isassignmentattachment == "true"
                                ) {
                                    $(".uploadModule").css({
                                        bottom: "20px"
                                    });
                                }

                                element.attr("upload-state", "progress");
                                var progress = parseInt(
                                    (data.loaded / data.total) * 100,
                                    10
                                );
                                scope.error = null;
                                feedback.show();
                                feedback
                                    .addClass("active")
                                    .removeClass("error");
                                bar.find("span").css("width", progress + "%");
                                scope.loaded = parseInt(data.loaded / 1000);
                                scope.total = parseInt(data.total / 1000);
                                scope.$apply();
                                //Leaving this in case anyone changes their minds
                                //bytes.html(progress + "%");
                            }
                        });
                }
            }
        };
    }
]);

(function() {
    app.service("contentSampleAdd", [
        "$http",
        function($http) {
            return {
                createContentSamples: createContentSamples,
                getAWS: getAWS
            };

            function createContentSamples(contentSampleDto) {
                var url = "/API/content-sample/upload";
                return $http.put(url, contentSampleDto);
            }

            function getAWS() {
                var url = "/API/content-sample/upload/aws";
                return $http.get(url);
            }
        }
    ]);
})();

app.service("common", [
    "skyword",
    "validation",
    "call",
    function(skyword, validation, call) {
        return {
            init: function() {
                //onReady
                $(function() {
                    if (
                        RegExp("(ContentTypeModify).(action)").test(
                            window.location.pathname
                        ) ||
                        RegExp("(WriteArticles).(action)").test(
                            window.location.pathname
                        ) ||
                        RegExp("(CreateDigitalAssets).(action)").test(
                            window.location.pathname
                        )
                    ) {
                        function initialize() {
                            $("#contentTypeModify-button")
                                .addClass("disabled")
                                .attr("disabled", "disabled");
                            if ($("#selectedContentTypeId").val() != -1) {
                                $("#selectedCategoryId")
                                    .removeClass("disabled")
                                    .removeAttr("disabled");
                                if ($("#selectedCategoryId").val() == -1) {
                                    updateContentType();
                                }
                            }
                        }

                        function updateContentType() {
                            var urlAction =
                                "getContentTypeAjax.action?contentTypeId=" +
                                $("#selectedContentTypeId").val();
                            options = {
                                cache: false,
                                type: "POST",
                                url: urlAction,
                                success: function(data) {
                                    var json,
                                        available,
                                        claimDeadline,
                                        submitDeadline,
                                        publishDeadlinejson,
                                        targetPage = $("#targetPage").val();
                                    skyword.populateCategories(
                                        "selectedCategoryIdDiv",
                                        "selectedCategoryId",
                                        $("#selectedContentTypeId").val(),
                                        $("#program-id").val(),
                                        false,
                                        $("#category-header-value").val(),
                                        "selectedCategoryId",
                                        true
                                    );
                                    json = $.parseJSON(data);
                                }
                            };
                            call.ajax(urlAction, options);
                        }

                        initialize();

                        $("#selectedContentTypeId").on("change", function() {
                            $("#contentTypeModify-button")
                                .addClass("disabled")
                                .attr("disabled", "disabled");
                            $("#selectedCategoryId")
                                .removeClass("disabled")
                                .removeAttr("disabled");
                            if ($("#selectedContentTypeId").val() != -1) {
                                updateContentType();
                            } else {
                                $("#selectedCategoryId_chzn").html("");
                            }
                        });

                        $("html").on(
                            "change",
                            "#selectedCategoryId",
                            function() {
                                if ($("#profile-incomplete").val() == "true") {
                                } else {
                                    if (
                                        $("#selectedCategoryId").val() != -1 ||
                                        location?.pathname?.includes(
                                            "importContentTypeModify"
                                        )
                                    ) {
                                        $("#contentTypeModify-button")
                                            .removeClass("disabled")
                                            .removeAttr("disabled");
                                    } else {
                                        $("#contentTypeModify-button")
                                            .addClass("disabled")
                                            .attr("disabled", "disabled");
                                    }
                                }
                            }
                        );

                        if ($("#profile-incomplete").val() == "true") {
                        } else {
                            if ($("#selectedCategoryId").val() != -1) {
                                $("#contentTypeModify-button")
                                    .removeClass("disabled")
                                    .removeAttr("disabled");
                                $("#selectedCategoryId_chzn")
                                    .removeClass("disabled")
                                    .removeAttr("disabled");
                            } else {
                                $("#contentTypeModify-button")
                                    .addClass("disabled")
                                    .attr("disabled", "disabled");
                            }
                        }

                        $("body").on(
                            "click",
                            "#contentTypeModify-button",
                            function() {
                                $("#contentTypeModify-button")
                                    .addClass("disabled")
                                    .attr("disabled", "disabled");
                                var form = $("form");
                                if (
                                    form.attr("action") ===
                                    "/socialPublishing.action"
                                ) {
                                    window.location.href =
                                        "/socialPublishing.action";
                                    return false;
                                }
                                var urlAction =
                                        "/managerContentTypeModify.action" ===
                                        form.attr("action")
                                            ? "/managerContentTypeModifyAjax.action?nextPage=" +
                                              $("#actionName").val()
                                            : "/contentTypeModifyAjax.action?nextPage=" +
                                              $("#actionName").val(),
                                    getPost = form.attr("method"),
                                    options = {
                                        cache: false,
                                        type: getPost,
                                        data: form.serialize(),
                                        url: urlAction,
                                        success: function(data) {
                                            validation.parse(data);
                                        }
                                    };
                                call.ajax(urlAction, options);
                                return false;
                            }
                        );
                    }

                    $("body").on(
                        "click",
                        "#contentTypeChange-button",
                        null,
                        function() {
                            var urlAction = "/contentTypeChangeAjax.action",
                                getPost = $("form").attr("method"),
                                options = {
                                    cache: false,
                                    type: getPost,
                                    data: $(
                                        "#contentTypeChangeForm"
                                    ).serialize(),
                                    url: urlAction,
                                    success: function(data) {
                                        jQuery.colorbox.close();
                                        validation.parse(data);
                                    }
                                };

                            call.ajax(urlAction, options);
                            return false;
                        }
                    );

                    skyword.displayWriterCompensationData();
                });
            }
        };
    }
]);

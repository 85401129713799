app.directive("attachment", [
    "$rootScope",
    function($rootScope) {
        return {
            restrict: "A",
            scope: {
                type: "@",
                model: "=ngModel"
            },
            link: function(scope, element, attrs, controller) {
                $rootScope.$broadcast("addVideo", {
                    id: attrs.id,
                    externalValueId: attrs.externalValueId,
                    fileName: attrs.fileName,
                    fileSize: attrs.fileSize
                });
            }
        };
    }
]);
